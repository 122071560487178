<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div>
        {{ content }}
      </div>
      <div
        v-if="!isUserExploitantAdmin"
        v-html="moreDetailsLink"
        class="mt-2"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="grey darken-3"
        @click="$emit('cancel')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        v-if="isUserExploitantAdmin"
        color="primary"
        @click="openSubscribeLink()"
      >
        {{ confirmText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SUBSCRIBE_LINK } from '@/settings';

export default {
  name: 'SubscriptionCard',
  props: {
    title: String,
    content: String,
    confirmText: String,
  },
  computed: {
    isUserExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    moreDetailsLink() {
      return this.$gettext(`Only exploitant owner and admin can upgrade subscription. Check our <a
        href="https://clearance.aero/preparation-missions-drone/tarifs/" target="_blank">our
        article on subscription offers</a> for more details about prices and formulas.`);
    },
  },
  methods: {
    openSubscribeLink() {
      window.open(SUBSCRIBE_LINK, '_blank');
      this.$emit('cancel');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

</style>
