<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <span v-translate>Cancel the notification</span>
    </v-card-title>
    <v-card-text>
      <span v-translate>Are you sure you want to cancel this notification?</span>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        @click="$emit('close-form')"
      >
        <span v-translate>Cancel</span>
      </v-btn>
      <v-btn
        text
        color="primary"
        :loading="loading"
        @click="cancelPrefectureNotification"
      >
        <span v-translate>Confirm</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { GET_CONSTRAINTS_ANALYSIS_NS } from '@/store/flights';

export default {
  name: 'NotifAlphaTangoPrefectureCancel',
  props: {
    flightId: Number,
    notificationId: Number,
  },
  data() {
    return { loading: false };
  },
  methods: {
    cancelPrefectureNotification() {
      this.loading = true;
      APIService.cancelPrefectureNotif(this.flightId, this.notificationId)
        .then(({ data }) => {
          this.showMessage(data, 'success');
        })
        .finally(() => {
          this.loading = false;
          this.$emit('close-form');
          this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flightId });
        });
    },
  },
};
</script>
