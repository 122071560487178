<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-container>
        <div class="headline mb-4">
          {{ structure.long_name }}
        </div>
        <!-- Texts for dronists -->
        <template>
          <span
            class="body-1"
            v-translate
          >
            Texts for dronists settings
          </span>
          <div class="mt-3">
            <v-switch
              v-model="activateParticularAirspaceRequirements"
              :label="labels.activateTermsAndConditionRequirements"
              color="primary"
            />
            <v-textarea
              :label="labels.termsAndCondtionsRequirements"
              v-model="particularAirspaceRequirements"
              required
              :hint="hints.termsAndConditionsRequirements"
              :disabled="!activateParticularAirspaceRequirements"
            />
          </div>
          <div class="mt-3">
            <v-textarea
              :label="labels.approvalProcess"
              v-model="approvalProcess"
              required
              :hint="hints.approvalProcess"
              rows="3"
            />
          </div>
          <div class="mt-3">
            <v-textarea
              :label="labels.permanentRequirements"
              v-model="extraAirspaceRequirements"
              required
              :hint="hints.permanentRequirements"
              rows="3"
            />
          </div>
          <div class="mt-3">
            <v-textarea
              v-model="tacticalContactProtocol"
              :label="labels.tacticalContactProtocol"
              :hint="hints.tacticalContactProtocol"
              rows="3"
            />
          </div>
        </template>
        <template>
          <v-divider />
          <span
            class="body-1"
            v-translate
          >
            Saved messages settings
          </span>
          <!-- Particular requirements list -->
          <v-card class="pa-2 mb-2 mt-3">
            <v-list-group
              v-model="expandRequirements"
              color=""
            >
              <template v-slot:activator>
                <v-row
                  no-gutters
                  align="center"
                  justify="space-between"
                >
                  <span class="body-1">
                    <translate>Particular requirements</translate>
                  </span>
                  <v-btn
                    text
                    icon
                    small
                    @click.stop="addParticularRequirement()"
                  >
                    <v-icon color="grey darken-1">mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </template>
              <v-list
                subheader
                class="overflow-y-auto sub-list px-4"
              >
                <v-list-group
                  v-for="(particularRequirement, id) in airspaceParticularRequirements"
                  :key="id"
                  append-icon="mdi-playlist-edit"
                >
                  <template v-slot:activator>
                    <v-list-item class="pa-0">
                      <v-list-item-content class="pa-0">
                        <v-text-field
                          v-model="particularRequirement.title"
                          :rules="[rules.required]"
                          :placeholder="placeholders.title"
                          @click.stop
                          class="body-2 list-item__text-field"
                          maxlength="50"
                        />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          text
                          icon
                          small
                          @click.stop="deleteParticularRequirement(particularRequirement.title)"
                        >
                          <v-icon small>mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <v-container class="pr-1 pt-0 pb-0 pl-4 list-item__text-area">
                    <v-textarea
                      v-model="particularRequirement.content"
                      :rules="[rules.requiredContent]"
                      :placeholder="placeholders.content"
                      full-width
                      class="body-2"
                    />
                  </v-container>
                </v-list-group>
              </v-list>
            </v-list-group>
          </v-card>
          <!-- Saved Chat Messages -->
          <v-card class="pa-2 mb-2 mt-3">
            <v-list-group
              v-model="expandChatMessages"
              color=""
            >
              <template v-slot:activator>
                <v-row
                  no-gutters
                  align="center"
                  justify="space-between"
                >
                  <span class="body-1">
                    <translate>Saved chat messages</translate>
                  </span>
                  <v-btn
                    text
                    icon
                    small
                    @click.stop="addChatMessage()"
                  >
                    <v-icon color="grey darken-1">mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </template>
              <v-list
                subheader
                class="overflow-y-auto sub-list px-4"
              >
                <v-list-group
                  v-for="(chatMessage, id) in savedChatMessages"
                  :key="id"
                  append-icon="mdi-playlist-edit"
                >
                  <template v-slot:activator>
                    <v-list-item class="pa-0">
                      <v-list-item-content class="pa-0">
                        <v-text-field
                          v-model="chatMessage.title"
                          :rules="[rules.required]"
                          :placeholder="placeholders.title"
                          @click.stop
                          class="body-2 list-item__text-field"
                          maxlength="50"
                        />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          text
                          icon
                          small
                          @click.stop="deleteChatMessage(chatMessage.title)"
                        >
                          <v-icon small>mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <v-container class="pr-1 pt-0 pb-0 pl-4 list-item__text-area">
                    <v-textarea
                      v-model="chatMessage.content"
                      :rules="[rules.requiredContent]"
                      :placeholder="placeholders.content"
                      full-width
                      class="body-2"
                    />
                  </v-container>
                </v-list-group>
              </v-list>
            </v-list-group>
          </v-card>
          <!-- Internal notes ATS -->
          <v-card class="pa-2 my-2 mt-1">
            <v-list-group
              v-model="expandATSInternalNote"
              color=""
            >
              <template v-slot:activator>
                <v-row
                  no-gutters
                  align="center"
                  justify="space-between"
                >
                  <span class="body-1">
                    <translate>Internal note</translate>
                  </span>
                  <v-btn
                    text
                    icon
                    small
                    @click.stop="addATSInternalNote()"
                  >
                    <v-icon color="grey darken-1">mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </template>
              <v-list
                subheader
                class="overflow-y-auto sub-list px-4"
              >
                <v-list-group
                  v-for="(ATSinternalNote, id) in ATSinternalNotes"
                  :key="id"
                  append-icon="mdi-playlist-edit"
                >
                  <template v-slot:activator>
                    <v-list-item class="pa-0">
                      <v-list-item-content class="pa-0">
                        <v-text-field
                          v-model="ATSinternalNote.title"
                          :rules="[rules.required]"
                          :placeholder="placeholders.title"
                          @click.stop
                          class="body-2 list-item__text-field"
                          maxlength="50"
                        />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          text
                          icon
                          small
                          @click.stop="deleteATSInternalNote(ATSinternalNote.title)"
                        >
                          <v-icon small>mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <v-container class="pr-1 pt-0 pb-0 pl-4 list-item__text-area">
                    <v-textarea
                      v-model="ATSinternalNote.content"
                      :rules="[rules.requiredContent]"
                      :placeholder="placeholders.content"
                      full-width
                      class="body-2"
                    />
                  </v-container>
                </v-list-group>
              </v-list>
            </v-list-group>
          </v-card>
        </template>
        <!-- Approval dates settings -->
        <template>
          <v-divider />
          <span
            class="body-1"
            v-translate
          >
            Dates settings
          </span>
          <div class="mt-3">
            <v-text-field
              :label="labels.approvalNumberDays"
              :hint="hints.approvalNumberDays"
              v-model="maxNumberDays"
              type="number"
              :suffix="suffixes.days"
              :rules="[rules.integer]"
            />
          </div>
          <div class="mt-3">
            <v-text-field
              :label="labels.approvalNoticeDays"
              :hint="hints.approvalNoticeDays"
              v-model="minNoticeDays"
              type="number"
              :suffix="suffixes.days"
              :rules="[rules.integer]"
            />
          </div>
        </template>
        <!-- Activation settings -->
        <template v-if="activationRequired">
          <v-divider />
          <span
            class="body-1"
            v-translate
          >
            Activation required
          </span>
          <div class="mt-3">
            <v-text-field
              :label="labels.activationNumberDays"
              :hint="hints.activationNumberDays"
              v-model="activationNumberDays"
              type="number"
              :suffix="suffixes.days"
              :rules="[rules.integer]"
            />
          </div>
          <div class="mt-3">
            <v-row align="center">
              <!-- Activation notice days -->
              <v-col
                cols="12"
                md="3"
                class="mt-1"
              >
                <v-text-field
                  :label="labels.activationMinNoticeDays"
                  :hint="hints.activationMinNoticeDays"
                  v-model="activationMinNoticeDays"
                  type="number"
                  :suffix="suffixes.days"
                  :rules="[rules.integer]"
                />
              </v-col>
              <!-- Activation tolerance notice time -->
              <v-col
                cols="5"
                md="4"
              >
                <div class="body-2 text--secondary label">
                  {{ labels.activationToleranceMinNoticeTime }}
                </div>
                <TimePicker
                  :time="activationToleranceMinNoticeTime"
                  @update:time="activationToleranceMinNoticeTime = $event"
                  clearable
                />
              </v-col>
              <!-- Activation notice time -->
              <v-col
                cols="5"
                md="4"
              >
                <div class="body-2 text--secondary label">
                  {{ labels.activationMinNoticeTime }}
                </div>
                <TimePicker
                  :time="activationMinNoticeTime"
                  @update:time="activationMinNoticeTime = $event"
                  clearable
                />
              </v-col>
              <v-col cols="1">
                <v-tooltip
                  bottom
                  max-width="420px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ texts.activationTimeExplain }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3">
            <v-text-field
              :label="labels.activationMaxNoticeDays"
              :hint="hints.activationMaxNoticeDays"
              v-model="activationMaxNoticeDays"
              type="number"
              :suffix="suffixes.days"
              :rules="[rules.integer]"
            />
          </div>
        </template>
        <!-- Working days notice setting -->
        <template>
          <v-divider />
          <span
            class="body-1"
            v-translate
          >
            Notice settings
          </span>
          <div class="mt-3">
            <v-switch
              v-model="requireWorkingNoticeDays"
              :label="labels.requireWorkingNoticeDays"
              color="primary"
            />
          </div>
        </template>
        <!-- Zones settings -->
        <template>
          <v-divider />
          <span
            class="body-1"
            v-translate
          >
            Zones settings
          </span>
          <div class="mt-3">
            <v-text-field
              :label="labels.maxZonesNumber"
              :hint="hints.maxZonesNumber"
              v-model="maxZonesNumber"
              type="number"
              :suffix="suffixes.areas"
              :rules="[rules.integer]"
            />
            <v-text-field
              :label="labels.maxSurface"
              :hint="hints.maxSurface"
              v-model="maxSurface"
              type="number"
              suffix="km²"
              :rules="[rules.integer]"
            />
            <v-text-field
              :label="labels.maxSurfaceByZone"
              :hint="hints.maxSurfaceByZone"
              v-model="maxSurfaceByZone"
              type="number"
              suffix="km²"
              :rules="[rules.integer]"
            />
            <v-text-field
              :label="labels.maxExtent"
              :hint="hints.maxExtent"
              v-model="maxExtent"
              type="number"
              suffix="km"
              :rules="[rules.integer]"
            />
          </div>
        </template>
        <!-- Decisions settings -->
        <template v-if="!generateApprovalProtocolSheet">
          <v-divider />
          <span
            class="body-1"
            v-translate
          >
            Decisions settings
          </span>
          <!-- Activate Incomplete Status -->
          <div class="d-flex align-center">
            <v-switch
              v-model="activatedIncompleteStatus"
              :label="labels.activatedIncompleteStatus"
              color="primary"
            />
            <v-tooltip
              top
              color="info"
              max-width="300"
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  v-on="tooltip"
                  class="ml-1"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                {{ texts.activatedIncompleteStatus }}
              </span>
            </v-tooltip>
          </div>
          <!-- Activate forwarding mission sheets -->
          <div
            v-if="!isAuthorityFullScreenInterface"
            class="d-flex align-center"
          >
            <v-switch
              v-model="forwardMissionSheetActivated"
              :label="labels.forwardMissionSheetSwitch"
              color="primary"
            />
            <v-tooltip
              top
              color="info"
              max-width="300"
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  v-on="tooltip"
                  class="ml-1"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                {{ texts.forwardMissionSheetActivated }}
              </span>
            </v-tooltip>
          </div>
          <!-- Emails list -->
          <v-card
            v-show="forwardMissionSheetActivated"
            class="pa-2 mb-2"
          >
            <v-list-group
              v-model="expandEmailList"
              color=""
            >
              <template v-slot:activator>
                <v-row
                  no-gutters
                  align="center"
                  justify="space-between"
                >
                  <span class="body-1">
                    <translate>Emails list</translate>
                  </span>
                  <v-btn
                    text
                    icon
                    small
                    @click.prevent="addForwardEmail()"
                  >
                    <v-icon color="grey darken-1">mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </template>
              <v-list class="px-4">
                <v-list-item
                  v-for="(email, id) in forwardEmails"
                  :key="id"
                  class="pa-0"
                >
                  <v-list-item-content class="pa-0">
                    <v-text-field
                      v-model="forwardEmails[id]"
                      :rules="[rules.required, rules.email]"
                      :placeholder="placeholders.email"
                      maxlength="256"
                      class="body-2 pt-0"
                    />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      text
                      icon
                      small
                      @click.stop.prevent="deleteForwardEmail(email)"
                    >
                      <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-card>
        </template>
        <!-- Approval categories -->
        <v-divider />
        <span
          class="body-1"
          v-translate
        >
          Approval categories
        </span>
        <ApprovalCategoriesTable :approvalCategories.sync="approvalCategories" />
        <!-- Confirm -->
        <v-row
          no-gutters
          justify="start"
          class="mt-5"
        >
          <v-btn
            depressed
            color="primary"
            @click="checkChangeTermsAndConditions()"
            :disabled="!valid"
            :loading="loading"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
    <!-- Dialog reset exploitant agreement -->
    <v-dialog
      v-model="showDialogResetExploitantsAgreement"
      max-width="500px"
      :retain-focus="false"
      persistent
    >
      <v-card
        class="default--dialog__card"
        max-height="600px"
        elevation="0"
      >
        <v-card-title>
          <translate>
            Delete all exploitants' validations of terms and conditions?
          </translate>
        </v-card-title>
        <v-card-text>
          <span>
            {{ labels.clickingYes }}
            <br>
            <br>
            {{ labels.clickingNo }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            class="px-2"
            @click="noResetExploitantsAgreement()"
          >
            <translate>No</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            class="px-2"
            @click="yesResetExploitantsAgreement()"
          >
            <translate>Yes</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from '@/services/api';

import { GET_AUTHORITY_STRUCTURES_NS } from '@/store/structures';

import ApprovalCategoriesTable from '@/components/Settings/Authorities/ApprovalCategoriesTable.vue';
import TimePicker from '@/components/TimePicker.vue';

export default {
  name: 'StructureForm',
  components: {
    ApprovalCategoriesTable,
    TimePicker,
  },
  props: {
    structure: {
      type: Object,
      required: true,
    },
    structureSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      loading: false,
      maxNumberDays: this.structureSettings.approval_max_nbr_days,
      minNoticeDays: this.structureSettings.approval_notice_days,
      approvalProcess: this.structureSettings.approval_process,
      activatedIncompleteStatus: (this.structureSettings.activated_incomplete_status),
      extraAirspaceRequirements: this.structureSettings.extra_airspace_requirements,
      tacticalContactProtocol: this.structureSettings.tactical_contact_protocol,
      savedChatMessages: JSON.parse(JSON.stringify(this.structure.airspace_saved_chat_messages)),
      airspaceParticularRequirements: JSON.parse(
        JSON.stringify(this.structure.airspace_particular_requirements),
      ),
      forwardEmails: JSON.parse(JSON.stringify(this.structure.airspace_forward_emails)),
      ATSinternalNotes: JSON.parse(JSON.stringify(this.structure.airspace_ats_internal_notes)),
      rules: {
        required: (value) => !!value || '',
        requiredContent: (value) => !!value || this.$gettext('Required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || '';
        },
        integer: (value) => (
          value ? value > 0 || this.$gettext('A positive integer is required') : true
        ),
      },
      forwardMissionSheetActivated: (
        this.structureSettings.forward_mission_sheet_activated
      ),
      expandRequirements: false,
      expandEmailList: false,
      expandChatMessages: false,
      expandATSInternalNote: false,
      activateParticularAirspaceRequirements: (
        this.structureSettings.validate_conditions_before_first_approval
      ),
      particularAirspaceRequirements: this.structureSettings.terms_and_conditions,
      showDialogResetExploitantsAgreement: false,
      resetExploitantsAgreement: false,
      activationNumberDays: this.structureSettings.activation_max_nbr_days,
      activationMinNoticeDays: this.structureSettings.activation_min_notice_days,
      activationToleranceMinNoticeTime: this.structureSettings.activation_tolerance_min_notice_time,
      activationMinNoticeTime: this.structureSettings.activation_min_notice_time,
      activationMaxNoticeDays: this.structureSettings.activation_max_notice_days,
      requireWorkingNoticeDays: this.structureSettings.require_working_notice_days,
      maxZonesNumber: this.structureSettings.max_flight_zones_number,
      maxSurface: (
        this.structureSettings.max_approval_surface
          ? this.structureSettings.max_approval_surface / 1000000
          : this.structureSettings.max_approval_surface
      ),
      maxExtent: (
        this.structureSettings.max_approval_bbox_side
          ? this.structureSettings.max_approval_bbox_side / 1000
          : this.structureSettings.max_approval_bbox_side
      ),
      maxSurfaceByZone: (
        this.structureSettings.max_zone_surface
          ? this.structureSettings.max_zone_surface / 1000000
          : this.structureSettings.max_zone_surface
      ),
      approvalCategories: JSON.parse(JSON.stringify(
        this.structure.active_airspace_approval_categories,
      )),
    };
  },
  computed: {
    labels() {
      return {
        activateTermsAndConditionRequirements: this.$gettext(`Prior agreement of terms and
          conditions required before first authorization request`),
        termsAndCondtionsRequirements: this.$gettext('Terms and Conditions'),
        activatedIncompleteStatus: this.$gettext(`Enable incomplete status for approval request's
          decisions`),
        approvalProcess: this.$gettext('Approval Process'),
        approvalNumberDays: this.$gettext('Maximum day range of a authorization request'),
        approvalNoticeDays: this.$gettext('Minimum prior notice for the authorization request'),
        permanentRequirements: this.$gettext('Permanent Requirements'),
        tacticalContactProtocol: this.$gettext('Tactical Contact Protocol'),
        forwardMissionSheetSwitch: this.$gettext('Activate forwarding mission sheets'),
        clickingYes: this.$gettext(`Clicking yes forces all exploitants, including those who have
          already submitted authorization requests, to validate the new terms and conditions.`),
        clickingNo: this.$gettext(`Clicking no forces it only to those who never accepted the old
          conditions.`),
        activationNumberDays: this.$gettext('Maximum day range of a activation request'),
        activationMinNoticeDays: this.$gettext('Minimum prior notice for the activation request'),
        activationToleranceMinNoticeTime: this.$gettext(`Time notice tolerance for the activation
          request`),
        activationMinNoticeTime: this.$gettext('Time notice limit for the activation request'),
        activationMaxNoticeDays: this.$gettext('Maximum prior notice for the activation request'),
        requireWorkingNoticeDays: this.$gettext(
          'Notice required in working days (excluding weekends and public holidays)',
        ),
        maxZonesNumber: this.$gettext('Maximum zones allowed for an approval'),
        maxSurface: this.$gettext('Maximum surface'),
        maxExtent: this.$gettext('Maximum extent'),
        maxSurfaceByZone: this.$gettext('Maximum surface for one zone of an approval'),
      };
    },
    hints() {
      return {
        termsAndConditionsRequirements: this.$gettext(`Text displayed only before the first
          authorization request, it must be read and validate before submitting the authorization
          request.`),
        approvalProcess: this.$gettext(`Message displayed to the dronists before they submit an
          authorization request. You can indicate specific messages that are useful to you in
          processing the authorization request and that the dronists will transmit to you, or give
          details of the request processing process, e.g. its duration.`),
        approvalNumberDays: this.$gettext(`Dronists will not be able to ask for a bigger dates
          range.`),
        approvalNoticeDays: this.$gettext(`Dronists will not be able to send authorization requests
          beyond this number of days.`),
        permanentRequirements: this.$gettext(`Requirements that apply to any flight authorization
          in this zone. This text is reminded to dronists in the details of their authorization
          request after having submitted it and in their mission sheet.`),
        tacticalContactProtocol: this.$gettext(`Indicate the procedure you want dronists to
            follow in case the flight requires direct contact with the control tower before takeoff
            (e.g. the control tower number to call).`),
        activationNumberDays: this.$gettext(`Dronists will not be able to ask for a bigger dates
          range.`),
        activationMinNoticeDays: this.$gettext(`Dronists will not be able to send activation
          requests beyond this number of days.`),
        activationMaxNoticeDays: this.$gettext(`Dronists will not be able to send activation
          requests before this number of days.`),
        maxZonesNumber: this.$gettext(`Dronists will not be able to request an approval with more
          zones then this number.`),
        maxSurface: this.$gettext(`Dronists will not be able to request an approval with the sum of
          all zones surface greater than this number.`),
        maxExtent: this.$gettext(`Dronists will not be able to request an approval with length of
          the maximum side of the smallest square containing all the zones greater than this
          number.`),
        maxSurfaceByZone: this.$gettext(`Dronists will not be able to request an approval with the
          surface of one zone greater than this number.`),
      };
    },
    texts() {
      return {
        activatedIncompleteStatus: this.$gettext(`Display another button for approval decisions.
          You can ask additional informations of the dronist before processing the approval.`),
        forwardMissionSheetActivated: this.$gettext(`Display another button for approval decisions.
          You can forward the mission sheet to ask for an outside advice.`),
        activationTimeExplain: this.$gettext(`Min and tolerance notice time needed min notice days.
          Tolerance notice time can be fill only if min notice time is filled. If tolerance notice
          time is filled, dronists can submit activation during the tolerance and min notice time
          during the latest possible day to submit. Nevertheless these activations will be
          automatically refused if there are not processed manually.`),
      };
    },
    placeholders() {
      return {
        title: this.$gettext('Title'),
        content: this.$gettext('Content'),
        email: this.$gettext('Email'),
      };
    },
    suffixes() {
      return {
        days: this.$gettext('days'),
        areas: this.$gettext('areas'),
      };
    },
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
    activationRequired() {
      return this.$store.getters['structures/activationRequired'];
    },
    trackingActivated() {
      return this.$store.getters['authentication/trackingActivated'];
    },
    generateApprovalProtocolSheet() {
      return this.$store.getters['structures/structureSettings'].generate_approval_protocol_sheet;
    },
  },
  methods: {
    buildStructureParams() {
      return {
        airspace_particular_requirements: this.airspaceParticularRequirements,
        airspace_saved_chat_messages: this.savedChatMessages,
        airspace_forward_emails: this.forwardEmails,
        airspace_ats_internal_notes: this.ATSinternalNotes,
        airspace_approval_categories: this.approvalCategories,
        reset_exploitants_agreement: this.resetExploitantsAgreement,
        structure_settings: {
          approval_notice_days: this.minNoticeDays || null,
          approval_process: this.approvalProcess,
          require_working_notice_days: this.requireWorkingNoticeDays,
          approval_max_nbr_days: this.maxNumberDays || null,
          validate_conditions_before_first_approval: this.activateParticularAirspaceRequirements,
          forward_mission_sheet_activated: this.forwardMissionSheetActivated,
          activated_incomplete_status: this.activatedIncompleteStatus,
          terms_and_conditions: this.particularAirspaceRequirements,
          tactical_contact_protocol: this.tacticalContactProtocol,
          extra_airspace_requirements: this.extraAirspaceRequirements,
          activation_max_nbr_days: this.activationNumberDays || null,
          activation_min_notice_days: this.activationMinNoticeDays || null,
          activation_tolerance_min_notice_time: this.activationToleranceMinNoticeTime || null,
          activation_min_notice_time: this.activationMinNoticeTime || null,
          activation_max_notice_days: this.activationMaxNoticeDays || null,
          max_flight_zones_number: this.maxZonesNumber || null,
          max_approval_surface: this.maxSurface ? this.maxSurface * 1000000 : null,
          max_approval_bbox_side: this.maxExtent ? this.maxExtent * 1000 : null,
          max_zone_surface: this.maxSurfaceByZone ? this.maxSurfaceByZone * 1000000 : null,
        },
      };
    },
    checkChangeTermsAndConditions() {
      if (
        (
          this.activateParticularAirspaceRequirements
          !== this.structureSettings.validate_conditions_before_first_approval
          || this.particularAirspaceRequirements !== this.structureSettings.terms_and_conditions
        )
        && this.activateParticularAirspaceRequirements
      ) {
        this.showDialogResetExploitantsAgreement = true;
      } else {
        this.confirmUpdate();
      }
    },
    noResetExploitantsAgreement() {
      this.showDialogResetExploitantsAgreement = false;
      this.resetExploitantsAgreement = false;
      this.confirmUpdate();
    },
    yesResetExploitantsAgreement() {
      this.showDialogResetExploitantsAgreement = false;
      this.resetExploitantsAgreement = true;
      this.confirmUpdate();
    },
    async confirmUpdate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const params = this.buildStructureParams();
        await API.editStructureParameters(this.structure.id, params)
          .then(() => {
            this.showMessage(
              this.$gettext('Parameters updated successfully'),
              'success',
            );
            this.$store.dispatch(GET_AUTHORITY_STRUCTURES_NS);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.showMessage(
          this.$gettext(`Les valeurs rentrées ne permettent pas la mise à jour. Vérifiez notamment
            les textes des différentes consignes, qui ne doivent pas être vides.`),
        );
      }
    },
    addParticularRequirement() {
      this.expandRequirements = true;
      this.airspaceParticularRequirements.push({
        title: '',
        content: '',
      });
    },
    deleteParticularRequirement(particularRequirementTitle) {
      const index = this.airspaceParticularRequirements.findIndex(
        (r) => r.title === particularRequirementTitle,
      );
      if (index !== undefined) {
        this.airspaceParticularRequirements.splice(index, 1);
      }
    },
    addChatMessage() {
      this.expandChatMessages = true;
      this.savedChatMessages.push({
        title: '',
        content: '',
      });
    },
    deleteChatMessage(chatMessagetTitle) {
      const index = this.savedChatMessages.findIndex((r) => r.title === chatMessagetTitle);
      if (index !== undefined) {
        this.savedChatMessages.splice(index, 1);
      }
    },
    addForwardEmail() {
      this.expandEmailList = 0;
      this.forwardEmails.push('');
    },
    deleteForwardEmail(email) {
      const index = this.forwardEmails.findIndex((e) => e === email);
      if (index !== undefined) {
        this.forwardEmails.splice(index, 1);
      }
    },
    addATSInternalNote() {
      this.expandATSInternalNotes = true;
      this.ATSinternalNotes.push({ title: '', content: '' });
    },
    deleteATSInternalNote(title) {
      const index = this.ATSinternalNotes.findIndex((r) => r.title === title);
      if (index > -1) this.ATSinternalNotes.splice(index, 1);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.scrollable {
  overflow: auto;
}

.sub-list {

  .v-list-group /deep/ .v-list-group__header {
    padding-left: 4px;
    padding-right: 0px;
  }

  .v-list-group--active /deep/ .v-list-group__header__append-icon .v-icon {
    color: $color-primary;
    transform: rotate(0deg);
  }

  .v-list-group--active {
    background: #f5f5f5;
  }

  .v-expansion-panel /deep/ .v-expansion-panel__header {
    padding-right: 10px;
  }

  .list-item__text-field,
  .forward-email__text-field {
    max-width: 350px;
    padding: 0px;

    /deep/ .v-text-field__details {
      display: none;
    }
  }

  .list-item__text-area {
    background-color: #f5f5f5;
  }

}

.v-text-field /deep/ .v-label {
  font-size: 1.2em;
}

.label {
  top: 6px;
  position: relative;
}

</style>
