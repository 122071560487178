<template>
  <div>
    <v-card
      outlined
      class="pa-2 ma-4 grey lighten-3"
      width="250px"
    >
      <div
        @click="openLivestreamPlayer(livestream)"
        class="cursor-pointer"
      >
        <v-img
          v-if="livestream.thumbnail_url"
          height="150"
          width="250"
          :src="livestream.thumbnail_url"
        />
        <v-card
          v-else
          height="150"
          width="250"
          class="d-flex justify-center align-center flex-column"
        >
          <v-icon>mdi-progress-clock</v-icon>
          <span
            class="mt-2 text-center max-width"
            v-translate
          >
            Waiting for thumbnail generation...
          </span>
        </v-card>
      </div>
      <v-row
        no-gutters
        align="center"
        class="mt-1"
      >
        <v-col cols="2">
          <v-btn
            icon
            @click="openLivestreamPlayer(livestream)"
          >
            <template v-if="isCurrentlyLive(livestream)">
              <v-icon color="red">mdi-play-circle</v-icon>
            </template>
            <template v-else>
              <v-icon>mdi-play-circle-outline</v-icon>
            </template>
          </v-btn>
        </v-col>
        <v-col cols="9">
          <v-tooltip
            top
            max-width="580px"
          >
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="truncated"
              >
                <span :class="{'font-weight-medium': isCurrentlyLive(livestream)}">
                  {{ livestream.label }}
                </span>
              </div>
            </template>
            <span class="text-break">
              {{ livestream.label }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="1">
          <div class="d-flex justify-center pr-1">
            <v-btn
              icon
              @click.stop="editLivestream(livestream.id)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
      >
        <v-col cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click.stop="dialogLink=true"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </template>
            <span v-translate>Public video link</span>
          </v-tooltip>
        </v-col>
        <v-col cols="9">
          <span class="caption">
            {{ livestream.start | toLocaleDatetime }}
          </span>
        </v-col>
        <v-col cols="1">
          <div class="d-flex justify-center pr-1">
            <v-btn
              v-if="livestream.status === 'ended'"
              @click.stop="deleteLivestream(livestream.id)"
              icon
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- Dialog livestream link -->
    <v-dialog
      v-model="dialogLink"
      width="580"
      :retain-focus="false"
      persistent
    >
      <v-card
        class="default--dialog__card"
        max-height="800px"
        elevation="0"
      >
        <v-card-title>
          <translate>Livestream link</translate>
          <v-icon @click="dialogLink=false">
            close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <!-- Copy public link -->
          <div class="mt-2">
            <div>
              <translate>This livestream is watchable on the URL below:</translate>
            </div>
            <div>
              <a
                id="livestream-url"
                :href="livestream.front_livestream_url"
                target="_blank"
              >
                {{ livestream.front_livestream_url }}
              </a>
              <v-icon
                class="ml-2"
                color="primary"
                @click="copyUrl()"
              >
                mdi-content-copy
              </v-icon>
            </div>
          </div>
          <!-- Reload public link -->
          <div class="mt-8">
            <span>
              {{ texts.reloadPublicLink }}
            </span>
            <div>
              <v-checkbox
                v-model="reloadPublicLink"
                :label="labels.reloadPublicLink"
              />
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            :disabled="!reloadPublicLink"
            :loading="loadingReloadPublicLink"
            @click="reloadLivestreamPublicLink()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import {
  ADD_OR_UPDATE_LIVESTREAM_NS,
  SET_SELECTED_LIVESTREAM_NS,
  SET_LIVESTREAM_TO_DELETE_NS,
} from '@/store/livestreams';

export default {
  name: 'Livestream',
  props: {
    livestream: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogLink: false,
      reloadPublicLink: false,
      loadingReloadPublicLink: false,
    };
  },
  computed: {
    texts() {
      return {
        reloadPublicLink: this.$gettext(`You can reload the public link of the live. Thus, people
          having the previous link should have the new link to watch live again.`),
      };
    },
    labels() {
      return { reloadPublicLink: this.$gettext('Reload live public link') };
    },
  },
  methods: {
    isCurrentlyLive(livestream) {
      return livestream.status === 'live';
    },
    openLivestreamPlayer(livestream) {
      const routeData = this.$router.resolve({
        name: 'livestream',
        params: { name: livestream.public_name },
      });
      window.open(routeData.href, '_blank');
    },
    editLivestream(livestreamId) {
      this.$store.commit(SET_SELECTED_LIVESTREAM_NS, livestreamId);
    },
    deleteLivestream(livestreamId) {
      this.$store.commit(SET_LIVESTREAM_TO_DELETE_NS, livestreamId);
    },
    copyUrl() {
      const elem = document.createElement('textarea');
      elem.value = this.livestream.front_livestream_url;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);
      this.showMessage(this.$gettext('URL copied in the clipboard'), 'success');
    },
    closeDialogLink() {
      this.dialogLink = false;
      this.reloadPublicLink = false;
      this.loadingReloadPublicLink = false;
    },
    reloadLivestreamPublicLink() {
      this.loadingReloadPublicLink = true;
      APIService.reloadLivestreamPublicLink(this.livestream.id)
        .then(({ data }) => {
          this.showMessage(this.$gettext('New livestream public link created'), 'success');
          this.$store.commit(ADD_OR_UPDATE_LIVESTREAM_NS, { livestream: data });
          this.closeDialogLink();
        })
        .finally(() => {
          this.loadingReloadPublicLink = false;
        });
    },
  },
};
</script>

<style scoped>
.max-width {
  max-width: 180px;
}

</style>
