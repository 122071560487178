<template>
  <v-skeleton-loader
    :loading="validationLoading"
    type="article, list-item-two-line, actions"
  >
    <template v-if="canBeEntrustedWithoutProcessingConstraints && !requireStripe">
      <EntrustPaperworkForm
        :flight="flight"
        :price="price"
        @close-form="closeForm()"
      />
    </template>
    <template v-else-if="!canBeEntrustedWithoutProcessingConstraints">
      <v-card class="default--dialog__card">
        <v-card-title>
          <span v-translate>
            Entrust us the paperwork
          </span>
        </v-card-title>
        <v-card-text>
          <template v-if="!canBeEntrusted">
            <p class="body-2">
              <span v-translate>
                The following elements are required in order to entrust the mission's procedures:
              </span>
            </p>
            <p class="subtitle-1">
            <ul>
              <template v-for="(error, index) in validationErrors">
                <li
                  :key="index"
                  class="py-1"
                >
                  <span>
                    {{ error.message }}
                  </span>
                  <template v-if="error.details">
                    <span>:</span>
                    <span class="ml-1 primary--text font-italic">
                      {{ error.details }}
                    </span>
                  </template>
                </li>
              </template>
            </ul>
            </p>
          </template>
          <template v-else>
            <p class="body-2">
              {{ texts.constraintsAlreadyProcessed }}
            </p>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            class="px-3"
            @click="closeForm()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            class="px-3"
            :disabled="!canBeEntrusted"
            @click="entrustPaperwork()"
          >
            <translate v-if="!canBeEntrusted">OK</translate>
            <translate v-else>Continue</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <template v-else-if="requireStripe">
      <div>
        <AskCookieStripe
          :title="$gettext('Entrust us the paperwork')"
          :dialogText="texts.stripeDialog"
          @close-form="$emit('close-form')"
        />
      </div>
    </template>

  </v-skeleton-loader>
</template>

<script>
import APIService from '@/services/api';

import EntrustPaperworkForm from '@/components/Flights/EntrustPaperworkForm.vue';
import AskCookieStripe from '@/components/Flights/RequestApproval/AskCookieStripe.vue';

export default {
  name: 'EntrustPaperworkValidations',
  components: {
    EntrustPaperworkForm,
    AskCookieStripe,
  },
  props: {
    flight: { type: Object, required: true },
    price: { type: Number, required: false },
  },
  data() {
    return {
      validationLoading: false,
      canBeEntrusted: undefined,
      validationErrors: [],
    };
  },
  computed: {
    canBeEntrustedWithoutProcessingConstraints() {
      return this.canBeEntrusted && !this.validationErrors.length;
    },
    texts() {
      return {
        constraintsAlreadyProcessed: this.$gettext(`Clicking on actions button, you have already
          processed yourself the paperworks, you need to wait return of authorities now. Do you
          really want to entrust to Clearance your paperworks?`),
        stripeDialog: this.$gettext(`To access your entrusted paperworks payment form, please
          accept the cookies from our payment solution Stripe.`),
      };
    },
    hasAcceptedCookieStripe() {
      return this.$store.getters['authentication/hasAcceptedCookieStripe'];
    },
    requireStripe() {
      if (this.hasAcceptedCookieStripe) {
        return false;
      }
      const { subscription } = this.$store.state.authentication.user;
      if (subscription) {
        return !!this.price && !subscription.pay_entrusted_procedures_without_stripe;
      }
      return !!this.price;
    },
  },
  mounted() {
    this.checkFlightCanBeEntrusted();
  },
  methods: {
    checkFlightCanBeEntrusted() {
      this.validationLoading = true;
      APIService.checkFlightCanBeEntrusted(this.flight.id)
        .then(({ data }) => {
          this.canBeEntrusted = data.can_be_entrusted;
          this.validationErrors = data.validation_errors;
        }).finally(() => {
          this.validationLoading = false;
        });
    },
    entrustPaperwork() {
      this.validationErrors = [];
    },
    closeForm() {
      this.$emit('close-form');
    },
    openStripeCookiePanel() {
      window.axeptioSDK.requestConsent('Stripe');
    },
  },
};
</script>
