<template>
  <v-container
    fluid
    fill-height
    class="white black--text pa-0 scrollable"
  >
    <!-- Authorities settings -->
    <template v-if="isUserAuthority">
      <SettingsAuthorites />
    </template>
    <!-- Dronists settings -->
    <template v-if="isUserDronist || isDronistUSSP || isAuthorityUSSP">
      <SettingsDronists />
    </template>
  </v-container>
</template>

<script>
import { REFRESH_USER_DATA_NS } from '@/store/authentication';
import { GET_EXPLOITATION_DETAILS_NS } from '@/store/exploitants';

import SettingsAuthorites from '@/components/Settings/Authorities/SettingsAuthorities.vue';
import SettingsDronists from '@/components/Settings/Dronists/SettingsDronists.vue';

export default {
  name: 'Settings',
  components: {
    SettingsAuthorites,
    SettingsDronists,
  },
  computed: {
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isUserDronist() {
      return this.$store.getters['authentication/isUserDronist'];
    },
    isAuthorityUSSP() {
      return this.$store.getters['authentication/isAuthorityUSSP'];
    },
    isDronistUSSP() {
      return this.$store.getters['authentication/isDronistUSSP'];
    },
    exploitationId() {
      return this.$store.state.authentication.user.exploitation_id;
    },
  },
  created() {
    this.$store.dispatch(REFRESH_USER_DATA_NS)
      .then(() => {
        if (this.isUserDronist) {
          this.getExploitationDetails();
        }
      });
  },
  methods: {
    async getExploitationDetails() {
      await this.$store.dispatch(GET_EXPLOITATION_DETAILS_NS, this.exploitationId);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.scrollable {
  overflow: auto;
}

</style>
