<template>
  <v-img
    :src="backgroundImage"
    class="fill-height background"
  >
    <div class="d-flex main-layout">
      <v-col
        fill-height
        v-if="!(drawer && isMobileBreakpoint)"
        :lg="drawer ? 5 : 12"
        class="left-layout"
      >
        <div class="d-flex flex-column justify-center align-center left-layout__wrapper">
          <div class="align-center justify-center">
            <v-img
              :src="image.src"
              eager
            />
          </div>
          <span
            v-html="image.text"
            class="mt-4 white--text text-center"
          />
          <!-- --------- Action buttons: Login / Signup ----------- -->
          <div
            v-if="!drawer"
            class="mt-5 d-flex flex-column flex-grow-0 order__action-buttons"
          >
            <v-btn
              large
              color="primary"
              @click="openDrawerLogin()"
              class="my-2"
            >
              <translate>Se connecter</translate>
            </v-btn>
            <v-btn
              large
              @click="openDrawerSignup()"
              class="my-2 primary--text"
            >
              <translate>Créer un compte gratuitement</translate>
            </v-btn>
          </div>
        </div>
      </v-col>
      <!-- ------------------------ Drawer ------------------------>
      <v-col
        v-if="drawer"
        :lg="drawer ? 7 : 0"
        class="drawer d-flex flex-column right-layout"
      >
        <!-- ---------- Login component ----------- -->
        <v-row
          v-if="login"
          no-gutters
          justify="center"
          class="flex-column px-3 px-sm-6 px-md-2 px-lg-10"
        >
          <Login
            class="mx-sm-5 d-flex flex-column"
            :isDeltaLogin="isDeltaLogin"
            :deltaLoginError="deltaLoginError"
            @createAccount="openDrawerSignup()"
          />
        </v-row>
        <!-- ---------- Signup component ----------- -->
        <v-row
          v-if="signup"
          no-gutters
          justify="center"
          class="flex-column px-3 px-sm-6 px-md-2 px-lg-10"
        >
          <Signup
            class="mx-sm-5 d-flex flex-column"
            @login="openDrawerLogin()"
          />
        </v-row>
      </v-col>
    </div>
  </v-img>
</template>

<script>
import Login from '@/components/Home/Login.vue';
import Signup from '@/components/Home/Signup.vue';

export default {
  name: 'Home',
  components: {
    Login,
    Signup,
  },
  data() {
    return {
      drawer: false,
      login: false,
      signup: false,
      isDeltaLogin: false,
      deltaLoginError: '',
    };
  },
  computed: {
    breakpoints() {
      const { xs, sm, md, lg, xl } = this.$vuetify.breakpoint;
      return { xs, sm, md, lg, xl };
    },
    activeBreakpoint() {
      return Object.keys(this.breakpoints).find((key) => this.breakpoints[key]);
    },
    isMobileBreakpoint() {
      return ['xs', 'sm'].includes(this.activeBreakpoint);
    },
    pulicFolder() {
      return process.env.BASE_URL;
    },
    backgroundImage() {
      return `${this.pulicFolder}background.png`;
    },
    image() {
      return {
        src: `${this.pulicFolder}28-anim-home-01.png`,
        text: this.$gettext(`Prepare your drone missions in web or mobile version<br>Complete and
          automated regulatory analysis<br>Authorization requests at your fingertips, updated
          authorities contacts<br>Dedicated support for your missions, handling of your
          authorization requests and centralization of documents`),
      };
    },
  },
  created() {
    if (this.$store.state.authentication.logged === true) {
      this.$router.push('/map');
    } else {
      const originUrl = window.location.origin;
      if (originUrl === process.env.VUE_APP_DELTA_ORIGIN_URL) {
        this.isDeltaLogin = true;
        this.deltaLoginError = this.$route.query?.error;
        if (this.deltaLoginError) {
          this.$router.replace({
            path: this.$route.path,
            query: {},
          });
        }
      }
    }
    if (!this.isMobileBreakpoint || this.isDeltaLogin) {
      this.drawer = true;
      this.login = true;
    }
  },
  methods: {
    openDrawerLogin() {
      this.drawer = true;
      this.login = true;
      this.signup = false;
    },
    openDrawerSignup() {
      this.drawer = true;
      this.signup = true;
      this.login = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.main-layout {
  height: 100%;
}

.left-layout {
  overflow-y: auto;
  &__wrapper {
    min-height: 100%;
  }
}

.right-layout {
  overflow-y: auto;
}

.drawer {
  background: white;
}

.order {
  @media only screen and (min-height: 580px) {
    &__slideshow {
      order: 1;
    }
    &__action-buttons {
      order: 2;
    }
  }
  @media only screen and (max-height: 580px) {
    &__slideshow {
      order: 2;
      margin-top: 32px;
    }
    &__action-buttons {
      order: 1;
    }
  }
}

</style>
