<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-row
      no-gutters
      wrap
      justify="space-around"
    >
      <v-col class="medium-width">
        <!-- Company official Name -->
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            :md="display.md_label"
            :lg="display.lg_label"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              Company Name
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="display.md_field"
            :lg="display.lg_field"
          >
            <v-text-field
              v-model="officialName"
              :placeholder="placeholders.officialName"
              :rules="[rules.required]"
              class="billing-details__input ma-0"
              :class="compact ? 'body-2' : 'body1'"
            />
          </v-col>
        </v-row>
        <!-- Billing address-->
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            :md="display.md_label"
            :lg="display.lg_label"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              Address 1
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="display.md_field"
            :lg="display.lg_field"
          >
            <v-text-field
              v-model="address1"
              :placeholder="placeholders.address1"
              :rules="[rules.required]"
              class="billing-details__input ma-0"
              :class="compact ? 'body-2' : 'body1'"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            :md="display.md_label"
            :lg="display.lg_label"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              Address 2
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="display.md_field"
            :lg="display.lg_field"
          >
            <v-text-field
              v-model="address2"
              :placeholder="placeholders.address2"
              class="billing-details__input ma-0"
              :class="compact ? 'body-2' : 'body1'"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            :md="display.md_label"
            :lg="display.lg_label"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              Postal code
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="display.md_field"
            :lg="display.lg_field"
          >
            <v-text-field
              v-model="postalCode"
              :placeholder="placeholders.postalCode"
              :rules="[rules.under12]"
              class="billing-details__input ma-0"
              :class="compact ? 'body-2' : 'body1'"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            :md="display.md_label"
            :lg="display.lg_label"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              City
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="display.md_field"
            :lg="display.lg_field"
          >
            <v-text-field
              v-model="city"
              :placeholder="placeholders.city"
              :rules="[rules.required]"
              class="billing-details__input ma-0"
              :class="compact ? 'body-2' : 'body1'"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            :md="display.md_label"
            :lg="display.lg_label"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              Country
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="display.md_field"
            :lg="display.lg_field"
          >
            <v-autocomplete
              v-model="country"
              :placeholder="placeholders.country"
              :rules="[rules.required]"
              :items="countries"
              item-text="Name"
              item-value="Code"
              :no-data-text="$gettext('No country found')"
              class="billing-details__input ma-0"
              :class="compact ? 'body-2' : 'body1'"
              clearable
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            :md="display.md_label"
            :lg="display.lg_label"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              Email
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="display.md_field"
            :lg="display.lg_field"
          >
            <v-text-field
              v-model="email"
              :rules="[rules.emailValid]"
              :placeholder="placeholders.email"
              class="billing-details__input ma-0"
              :class="compact ? 'body-2' : 'body1'"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            :md="display.md_label"
            :lg="display.lg_label"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              VAT Number
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="display.md_field"
            :lg="display.lg_field"
          >
            <v-text-field
              v-model="vatNumber"
              :placeholder="placeholders.vatNumber"
              :rules="[rules.under13]"
              class="billing-details__input ma-0"
              :class="compact ? 'body-2' : 'body1'"
            />
          </v-col>
        </v-row>
        <!-- Confirm -->
        <v-row
          no-gutters
          class="my-5"
          justify="center"
          v-if="!noAction"
        >
          <v-btn
            large
            depressed
            class="py-2 grey lighten-4 primary--text wrap-button"
            @click="updateExploitation()"
            :loading="loading"
          >
            {{ actionButtonText }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import API from '@/services/api';
import COUNTRIES from '@/services/countries';

import { GET_EXPLOITATION_DETAILS_NS } from '@/store/exploitants';
import CUSTOM_HANDLING_API_CODES from '@/main';

export default {
  name: 'BillingInformationForm',
  props: {
    optional: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    noAction: {
      type: Boolean,
      default: false,
    },
    actionButton: String,
    showMessages: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      exploitation_id: 0,
      officialName: null,
      address1: null,
      address2: null,
      postalCode: null,
      city: null,
      country: null,
      countries: COUNTRIES,
      email: null,
      vatNumber: null,
      defaultDisplay: {
        lg_label: 2,
        lg_field: 10,
        md_label: 3,
        md_field: 9,
      },
      compactDisplay: {
        lg_label: 4,
        lg_field: 8,
        md_label: 6,
        md_field: 6,
      },
    };
  },
  computed: {
    actionButtonText() {
      return this.actionButton ? this.actionButton : this.$gettext('Validate changes');
    },
    display() {
      if (this.compact) {
        return this.compactDisplay;
      }
      return this.defaultDisplay;
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    exploitationDetails() {
      return this.$store.state.exploitants.exploitationDetails;
    },
    exploitationId() {
      return this.userData.exploitation_id;
    },
    placeholders() {
      return {
        officialName: this.$gettext('Official name of your company...'),
        address1: this.$gettext('Address line 1...'),
        address2: this.$gettext('Address line 2...'),
        postalCode: this.$gettext('Postal code...'),
        city: this.$gettext('City...'),
        country: this.$gettext('Select a country...'),
        email: this.$gettext('Email of the customer'),
        vatNumber: this.$gettext('VAT number of the customer'),
      };
    },
    rules() {
      return {
        required: (v) => (!!v || this.optional) || this.$gettext('This field is required.'),
        under12: (v) => (!!v && v.length <= 12)
          || !v
          || this.$gettext('Must be under 12 characters.'),
        under13: (v) => (!!v && v.length <= 13)
          || !v
          || this.$gettext('Must be under 13 characters.'),
        emailValid: (
          (value) => {
            if (!value) {
              return true;
            }
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailIsValid = true;
            if (!pattern.test(value)) {
              emailIsValid = this.$gettext('Email invalid.');
            }
            return emailIsValid;
          }
        ),
      };
    },
  },
  watch: {
    exploitationDetails(newValue) {
      this.officialName = newValue.official_name;
      this.email = newValue.billing_email;
      this.vatNumber = newValue.vat_number;
      if (newValue.billing_address) {
        this.address1 = newValue.billing_address.address1;
        this.address2 = newValue.billing_address.address2;
        this.postalCode = newValue.billing_address.postal_code;
        this.city = newValue.billing_address.city;
        this.country = newValue.billing_address.country;
      }
    },
    valid(newValue, oldValue) {
      if (newValue) {
        this.$emit('form-valid', true);
      } else if (!newValue && oldValue !== newValue) {
        this.$emit('form-valid', false);
      }
    },
  },
  created() {
    this.getExploitationDetails(this.exploitationId);
  },
  methods: {
    async getExploitationDetails(exploitationId) {
      await this.$store.dispatch(GET_EXPLOITATION_DETAILS_NS, exploitationId);
    },
    buildExploitationInfos() {
      const payload = {};
      payload.official_name = this.officialName;
      payload.vat_number = this.vatNumber ? this.vatNumber : null;
      payload.billing_email = this.email;
      if (this.address1 || this.address2 || this.postalCode || this.city || this.country) {
        payload.billing_address = {};
        payload.billing_address.address1 = this.address1;
        payload.billing_address.address2 = this.address2;
        payload.billing_address.postal_code = this.postalCode;
        payload.billing_address.city = this.city;
        payload.billing_address.country = this.country;
      }
      return payload;
    },
    updateExploitation() {
      if ((this.address2 || this.postalCode) && !(this.address1 && this.city && this.country)) {
        this.showMessage(this.$gettext(`If you want to fill in an address, at least Address 1, City
          and Country are required.`));
        return;
      }
      if (this.$refs.form.validate()) {
        const payload = this.buildExploitationInfos();
        this.loading = true;
        API.updateExploitation(this.exploitationId, payload)
          .then(() => {
            if (this.showMessages) {
              this.showMessage(
                this.$gettext('The changes have been taken into account.'),
                'success',
              );
            }
          })
          .catch((e) => {
            if (this.showMessages) {
              const code = e.response?.data?.code;
              const message = e.response?.data?.detail || e.error.message;
              if (code === CUSTOM_HANDLING_API_CODES.STRIPE_CUSTOMER_UPDATE) {
                this.showMessage(message, 'error');
              }
            }
          })
          .finally(() => {
            this.loading = false;
            this.$emit('completed');
          });
      } else {
        this.showMessage(this.$gettext('The data filled in are not correct.'));
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.billing-details {
  &__input {
    /deep/ input::placeholder {
      color: lighten($color-info, 30%);
    }
  }
}
.wrap-button,
.wrap-button /deep/ .v-btn__content {
  max-width: 100%;
  height: fit-content !important;
  white-space: normal;
  line-height: 1.6;
}

</style>
