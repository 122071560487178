<template>
  <div>
    <div
      class="cursor-pointer text-decoration-underline mb-2 d-inline"
      @click="expanded = !expanded"
      v-translate
    >
      Learn more
    </div>
    <!-- Extra description -->
    <div v-if="expanded">
      <!-- Reason -->
      <div
        v-if="constraint.reason"
        class="mb-2"
      >
        <div class="d-flex align-center">
          <v-icon color="info">mdi-information-variant</v-icon>
          <span
            class="ml-2 font-weight-medium"
            v-translate
          >
            Why this constraint
          </span>
        </div>
        <div class="ml-8">
          {{ constraint.reason }}
        </div>
      </div>
      <!-- Flight Areas -->
      <div
        v-if="hasManyAreas && hasFlightAreas"
        class="mb-2"
      >
        <div class="d-flex align-center">
          <v-icon color="info">mdi-vector-square</v-icon>
          <span
            class="ml-2 font-weight-medium"
            v-translate
          >
            Impacted zones
          </span>
        </div>
        <ul class="ml-2">
          <li
            v-for="(flightAreaName, index) in getFlightAreaNames"
            :key="index"
          >
            {{ flightAreaName }}
          </li>
        </ul>
      </div>
      <!-- Additional Contacts -->
      <div
        v-if="constraint.contacts.length"
        class="mb-2"
      >
        <div class="d-flex align-center">
          <v-icon color="info">mdi-phone-outline</v-icon>
          <span
            class="ml-2 font-weight-medium"
            v-translate
          >
            Additional contacts
          </span>
        </div>
        <FlightConstraintInfoList :infoList="constraint.contacts" />
      </div>
      <!-- Additional Information -->
      <div
        v-if="constraint.extra_infos.length"
        class="mb-2"
      >
        <div class="d-flex align-center">
          <v-icon color="info">mdi-magnify-plus-outline</v-icon>
          <span
            class="ml-2 font-weight-medium"
            v-translate
          >
            Additional information
          </span>
        </div>
        <FlightConstraintInfoList :infoList="constraint.extra_infos" />
      </div>
      <!-- Clearance Articles -->
      <div
        v-if="constraint.clearance_articles.length"
        class="mb-2"
      >
        <div class="d-flex align-center">
          <v-icon color="info">mdi-book-open-outline</v-icon>
          <span
            v-translate
            class="ml-2 font-weight-medium"
          >
            Clearance articles
          </span>
        </div>
        <ul class="ml-2">
          <li
            v-for="(article, index) in constraint.clearance_articles"
            :key="index"
          >
            <a
              :href="article.url"
              target="_blanck"
            >
              {{ article.name }}
            </a>
          </li>
        </ul>
      </div>
      <!-- Source Data Details -->
      <div v-if="constraint.info_source_data">
        <div class="d-flex align-center">
          <v-icon color="info">mdi-clipboard-check-outline</v-icon>
          <span
            v-translate
            class="ml-2 font-weight-medium"
          >
            Data source
          </span>
        </div>
        <div class="ml-8">
          {{ constraint.info_source_data }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlightConstraintInfoList from '@/components/Flights/FlightConstraintInfoList.vue';

export default {
  name: 'FlightConstraintExtraDescription',
  components: { FlightConstraintInfoList },
  props: {
    constraint: Object,
    flight: Object,
    panelExpanded: Number,
  },
  data() {
    return { expanded: false };
  },
  computed: {
    hasManyAreas() {
      return this.flight.areas.length > 1;
    },
    hasFlightAreas() {
      return this.constraint.flight_area_ids.length;
    },
    getFlightAreaNames() {
      return this.flight.areas.filter(
        (flightArea) => this.constraint.flight_area_ids.includes(flightArea.id),
      ).map((flightArea) => flightArea.name);
    },
  },
  watch: {
    panelExpanded(newValue) {
      if (newValue === undefined) {
        this.expanded = false;
      }
    },
  },
};
</script>
