<template>
  <div>
    <div
      class="flight-constraint"
      :class="highlighted"
    >
      <v-expansion-panels
        v-model="panelExpanded"
        flat
        class="dense__expansion-panel"
      >
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header :class="highlighted">
            <div
              @mouseenter="constraintIdHover=constraint.id"
              @mouseleave="constraintIdHover=null"
              class="dense__expansion-panel__header-content d-flex align-center
                justify-space-between"
            >
              <div class="d-flex align-center">
                <v-icon
                  size="24px"
                  :class="getIconColorClass"
                >
                  {{ getIcon }}
                </v-icon>
                <span class="ml-2 secondary--text py-2">
                  {{ constraint.name }}
                </span>
              </div>
              <div class="d-flex align-center">
                <!-- Notice days -->
                <v-chip
                  v-if="isImpacting && noticeDaysToDisplay"
                  label
                  small
                  text-color="white"
                  color="yellow darken-3"
                  class="mr-1"
                >
                  {{ noticeDaysToDisplay }}
                </v-chip>
                <!-- Internal flight constraint note -->
                <v-tooltip
                  top
                  max-width="680px"
                  :disabled="isMobileBreakpoint"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.native.stop="openDialogNote=true"
                    >
                      mdi-comment-edit-outline
                    </v-icon>
                  </template>
                  <span v-if="constraint.note">
                    {{ constraint.note }}
                  </span>
                  <span
                    v-else
                    v-translate
                  >
                    Write an internal note for this flight constraint
                  </span>
                </v-tooltip>
                <!-- Update status -->
                <FlightStatusIcon
                  v-if="showStatusBadge"
                  withTooltip
                  clickable
                  :status="getConstraintStatus"
                  theme="constraints"
                  :loading="loadingUpdateStatus"
                  @status-icon-clicked="() => updateStatus()"
                />
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-3">
            <!-- Description -->
            <FlightConstraintUSKDescription
              v-if="canBeProcessedThroughUSK"
              :constraint="constraint"
              :flight="flight"
              :capability="approvalCapability"
              :isErrorInFetchCapability="isErrorInFetchCapability"
              :panelExpanded="panelExpanded"
              :loadingCapability="loadingCapability"
            />
            <FlightConstraintDescription
              v-else
              :constraint="constraint"
              :capability="capability"
              :flight="flight"
              :panelExpanded="panelExpanded"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Constraint dronist note -->
      <div
        v-if="constraint.note"
        class="mx-2 pb-2"
      >
        <v-alert
          text
          border="left"
          class="body-2 font-italic ma-0"
        >
          <span
            class="font-weight-medium"
            v-translate
          >
            Personal note:
          </span>
          <div
            ref="note"
            @click="noteCollapsed = !noteCollapsed"
            class="note"
            :class="{
            'with-overflow': noteOverflowing,
            'collapsed': noteCollapsed,
          }"
          >
            {{ constraint.note }}
          </div>
        </v-alert>
      </div>
      <!-- Prefecture notifications -->
      <div
        v-if="hasPrefectureNotifications"
        class="px-2 pb-2"
      >
        <NotifAlphaTangoPrefectureList
          :flightId="flight.id"
          :notifications="flight.prefectureNotifications || []"
          :highlight="highlighted === 'highlight'"
        />
      </div>
      <!-- Army notifications -->
      <div
        v-if="hasArmyNotifications"
        class="px-2 pb-2"
      >
        <NotifAlphaTangoArmyList
          :flight="flight"
          :notifications="flight.armyNotifications || []"
          :highlight="highlighted === 'highlight'"
        />
      </div>
      <!-- Approvals -->
      <div
        v-if="hasApprovals"
        class="px-2 pb-2"
      >
        <FlightApprovalsList
          :flight="flight"
          :constraint="constraint"
          :capability="approvalCapability"
          :highlighted="highlighted"
        />
      </div>
      <!-- Email request list-->
      <div v-if="hasEmailRequest">
        <EmailRequestList
          class="pb-2 px-2"
          :flight="flight"
          :constraint="constraint"
          :highlighted="highlighted === 'highlight'"
        />
      </div>
      <!-- Specific alert -->
      <div
        v-if="showSpecificAlert"
        class="px-2 pb-2"
      >
        <v-alert
          type="info"
          icon="mdi-information-outline"
          color="info"
          outlined
          class="pa-2 ma-0 body-2"
        >
          {{ constraint.specific_alert }}
        </v-alert>
      </div>
      <!-- Action buttons -->
      <div
        v-if="showActionButtonContainer"
        class="action-container px-2"
      >
        <FlightConstraintActions
          :flight="flight"
          :constraint="constraint"
          :capability="capability"
          @show-subscribe-dialog="() => showSubscribeDialog=true"
        />
      </div>
    </div>
    <!-- Loader -->
    <div
      v-if="loadingCapability || loadingElements"
      class="white"
    >
      <v-progress-linear indeterminate />
    </div>
    <!-- Dialog for flight constraint note -->
    <v-dialog
      v-model="openDialogNote"
      width="650px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span v-translate="{name: constraint.name}">
            Write an internal note for the constraint "%{ name }"
          </span>
        </v-card-title>
        <v-card-text>
          <span v-translate>This note will never be transmit and only available for you.</span>
          <v-textarea
            light
            hide-details
            v-model="note"
            :placeholder="placeholders.note"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="openDialogNote=false"
          >
            <span v-translate>Close</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="updateNote()"
            :loading="loadingUpdateNote"
          >
            <span v-translate>Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Subscribe dialog -->
    <v-dialog
      v-model="showSubscribeDialog"
      width="650px"
      persistent
      :retain-focus="false"
    >
      <SubscriptionCard
        :title="subscribeDialogText.title"
        :content="subscribeDialogText.content"
        :confirmText="subscribeDialogText.confirm"
        @cancel="showSubscribeDialog=false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { differenceInCalendarDays } from 'date-fns';

import APIService from '@/services/api';

import {
  CAPABILITY_CATEGORY,
  CONSTRAINTS_CATEGORY,
  CONSTRAINTS_PROCESS_THROUGH,
  CONSTRAINT_DICT_ICON,
  MILITARY_CONSTRAINTS_CODES,
} from '@/settings';

import {
  ADD_CONSTRAINT_SELECTED_NS,
  REMOVE_CONSTRAINT_SELECTED_NS,
  SET_CONSTRAINT_HOVER_NS,
  SET_FLIGHT_APPROVAL_PROPERTY_NS,
  SET_FLIGHT_ARMY_NOTIFICATIONS_NS,
  SET_FLIGHT_CONSTRAINT_NOTE_NS,
  SET_FLIGHT_CONSTRAINT_STATUS_NS,
  SET_FLIGHT_PREFECTURE_NOTIFICATIONS_NS,
  UPDATE_FLIGHT_APPROVALS_NS,
  UPDATE_FLIGHT_REQUEST_EMAIL_NS,
} from '@/store/flights';
import { SET_BOUNDING_BOX_NS } from '@/store/map';
import {
  CLEAR,
  PROCESSING,
  HANDLED,
  INCOMPLETE,
  NOT_HANDLED,
  REFUSED,
  RESERVES,
  CANCELLED,
} from '@/store/status';

import EmailRequestList from '@/components/Flights/EmailRequest/EmailRequestList.vue';
import FlightApprovalsList from '@/components/Flights/FlightApprovalsList.vue';
import FlightConstraintActions from '@/components/Flights/FlightConstraintActions.vue';
import FlightConstraintDescription from '@/components/Flights/FlightConstraintDescription.vue';
import FlightConstraintUSKDescription from '@/components/Flights/FlightConstraintUSKDescription.vue';
import FlightStatusIcon from '@/components/StatusIcon/FlightStatusIcon.vue';
import NotifAlphaTangoArmyList from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoArmyList.vue';
import NotifAlphaTangoPrefectureList from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoPrefectureList.vue';
import SubscriptionCard from '@/components/Flights/SubscriptionCard.vue';

const iconClass = {
  [NOT_HANDLED]: 'error',
  [REFUSED]: 'error',
  [CLEAR]: 'success',
  [PROCESSING]: 'primary',
  [INCOMPLETE]: 'warning',
  [RESERVES]: 'warning',
  [HANDLED]: 'grey',
};

export default {
  name: 'FlightConstraint',
  components: {
    EmailRequestList,
    FlightApprovalsList,
    FlightConstraintActions,
    FlightConstraintDescription,
    FlightConstraintUSKDescription,
    FlightStatusIcon,
    NotifAlphaTangoArmyList,
    NotifAlphaTangoPrefectureList,
    SubscriptionCard,
  },
  props: {
    constraint: Object,
    flight: Object,
    constraintsExpanded: Number,
  },
  data() {
    return {
      panelExpanded: undefined,
      loadingCapability: false,
      capability: null,
      isErrorInFetchCapability: false,
      openDialogNote: false,
      note: this.constraint.note,
      loadingUpdateNote: false,
      noteOverflowing: undefined,
      noteCollapsed: true,
      loadingUpdateStatus: false,
      loadingElements: false,
      hasPrefectureNotifications: false,
      hasArmyNotifications: false,
      hasApprovals: false,
      hasEmailRequest: false,
      showSubscribeDialog: false,
    };
  },
  computed: {
    isGeneralistorPilotOrManager() {
      return this.$store.getters['authentication/isGeneralistPilotOrManager'];
    },
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    placeholders() {
      return { note: this.$gettext('Write an internal note for the flight constraint') };
    },
    constraintIdHover: {
      get() {
        return this.$store.state.flights.constraintIdHover;
      },
      set(newValue) {
        this.$store.dispatch(SET_CONSTRAINT_HOVER_NS, newValue);
      },
    },
    highlighted() {
      return (
        this.constraintIdHover === this.constraint.id
        && this.panelExpanded === undefined
      ) ? 'highlight' : 'white-background';
    },
    isImpacting() {
      return this.constraint.category === CONSTRAINTS_CATEGORY.IMPACTING;
    },
    isOtherImpacting() {
      return this.constraint.category === CONSTRAINTS_CATEGORY.OTHER_IMPACTING;
    },
    isArmy() {
      const constraintsCodes = ['Off_Sight'] + MILITARY_CONSTRAINTS_CODES;
      return constraintsCodes.includes(this.constraint.code_type);
    },
    isAgglomeration() {
      return ['AGG', 'AGG_NEARBY', 'People_Gathering'].includes(this.constraint.code_type);
    },
    canBeProcess() {
      return this.constraint.process_through !== '';
    },
    canBeProcessedThroughClearance() {
      return this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.CLEARANCE;
    },
    canBeProcessedThroughUSK() {
      return this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.USK;
    },
    isIndependantStatus() {
      return (
        this.constraint.delegatee_constraint
          ? this.constraint.delegatee_constraint.independant_status
          : true
      );
    },
    getConstraintStatus() {
      if (
        !this.canBeProcess
        && this.isIndependantStatus
      ) {
        // remap status values for constraints that can't be processed through Clearance
        // CLEAR and RESERVES means CLEAR, and NOT_HANDLED otherwise
        switch (this.constraint.status) {
          case CLEAR:
          case RESERVES:
            return CLEAR;
          case HANDLED:
            return HANDLED;
          default:
            return NOT_HANDLED;
        }
      }
      return this.constraint.status;
    },
    getIconColorClass() {
      if (!this.isImpacting) {
        return 'info--text';
      }
      return `${iconClass[this.constraint.status]}--text`;
    },
    getIcon() {
      return CONSTRAINT_DICT_ICON[this.constraint.code_type] || 'icon-alert_empty';
    },
    isApprovalCapability() {
      return this.capability?.category === CAPABILITY_CATEGORY.APPROVAL_REQUEST;
    },
    approvalCapability() {
      return this.isApprovalCapability ? this.capability.approval_request : null;
    },
    isEmailRequestCapability() {
      return this.capability?.category === CAPABILITY_CATEGORY.EMAIL_REQUEST;
    },
    emailRequestCapability() {
      return this.isEmailRequestCapability ? this.capability.email_request : null;
    },
    noticeDaysToRespect() {
      if (
        !this.approvalCapability
        || this.getConstraintStatus !== NOT_HANDLED
      ) {
        return null;
      }
      return this.approvalCapability.notice_days;
    },
    remainingDaysFromNoticeDays() {
      if (!this.noticeDaysToRespect) return null;

      const flightStart = new Date(this.flight.date_start);
      // Handle timezones differences
      flightStart.setMinutes(flightStart.getMinutes() + flightStart.getTimezoneOffset());
      const today = new Date();
      const remainingDays = differenceInCalendarDays(flightStart, today);
      return remainingDays - this.noticeDaysToRespect;
    },
    noticeDaysToDisplay() {
      if (!this.noticeDaysToRespect) return null;
      return this.getRemainingDaysTextToDisplay(this.remainingDaysFromNoticeDays);
    },
    isContactRestrictedAccount() {
      return this.$store.state.authentication.user.contact_restricted_account;
    },
    isThalesAgglo() {
      return (
        this.isAgglomeration
        && this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.THALES
      );
    },
    showStatusBadge() {
      return (
        !this.loadingCapability
        && (
          (
            this.isImpacting
            && this.isIndependantStatus
            && !this.approvalCapability
            && !this.isThalesAgglo
          )
          || this.isOtherImpacting
        )
        && !this.isContactRestrictedAccount
        && !this.isGeneralistorPilotOrManager
      );
    },
    showSpecificAlert() {
      if (this.canBeProcessedThroughUSK && !this.approvalCapability) return false;
      return this.isImpacting && this.constraint.specific_alert;
    },
    showActionButtonContainer() {
      if (
        (this.isApprovalCapability && !this.approvalCapability)
        || (this.isEmailRequestCapability && !this.emailRequestCapability)
      ) {
        return false;
      }

      return (
        !this.flight.is_archived
        && Object.values(CAPABILITY_CATEGORY).includes(this.capability?.category)
        && !this.loadingElements
      );
    },
    subscribeDialogText() {
      return {
        title: this.$gettext('Sign up for a subscription'),
        content: this.$gettext('Notification feature is exclusive to subscribers.'),
        confirm: this.$gettext('Discover our offers'),
      };
    },
    constraintGeometry() {
      const constraintToDisplay = this.$store.getters['flights/constraintsToDisplay'].find(
        (c) => c.id === this.constraint.id,
      );
      if (constraintToDisplay?.geometry?.features?.length) {
        return this.$turf.featureCollection(constraintToDisplay.geometry.features.flat());
      }

      return null;
    },
  },
  watch: {
    panelExpanded(newValue) {
      if (newValue === 0) {
        this.$store.dispatch(ADD_CONSTRAINT_SELECTED_NS, this.constraint.id)
          .then(() => {
            if (this.constraintGeometry) {
              this.$store.dispatch(SET_BOUNDING_BOX_NS, { fromGeom: this.constraintGeometry });
            }
          });
      } else {
        this.$store.dispatch(REMOVE_CONSTRAINT_SELECTED_NS, this.constraint.id);
      }
    },
    constraintsExpanded(newValue) {
      if (newValue === undefined) {
        this.panelExpanded = undefined;
      }
    },
    isApprovalCapability(newValue) {
      if (newValue) {
        this.fetchApprovals();
      }
    },
    isEmailRequestCapability(newValue) {
      if (newValue) {
        this.fetchEmailRequest();
      }
    },
  },
  created() {
    if (this.isAgglomeration) {
      this.loadingElements = true;
      APIService.getPrefectureNotifications(this.flight.id)
        .then(({ data }) => {
          this.$store.commit(
            SET_FLIGHT_PREFECTURE_NOTIFICATIONS_NS,
            { flightId: this.flight.id, prefectureNotifications: data },
          );
          if (data.length) {
            this.hasPrefectureNotifications = true;
          }
        })
        .finally(() => {
          this.loadingElements = false;
        });
    } else if (this.isArmy) {
      this.loadingElements = true;
      APIService.getArmyNotifications(this.flight.id)
        .then(({ data }) => {
          this.$store.commit(
            SET_FLIGHT_ARMY_NOTIFICATIONS_NS,
            { flightId: this.flight.id, armyNotifications: data },
          );
          if (data.length) {
            this.hasArmyNotifications = true;
          }
        })
        .finally(() => {
          this.loadingElements = false;
        });
    }

    if (this.canBeProcess) {
      this.loadingCapability = true;
      APIService.getConstraintCapabilities(this.flight.id, this.constraint.id)
        .then(({ data }) => {
          this.capability = data;
        })
        .catch(() => {
          this.isErrorInFetchCapability = true;
          if (this.canBeProcessedThroughUSK) {
            this.fetchApprovals();
          }
        })
        .finally(() => {
          this.loadingCapability = false;
        });
    }
  },
  mounted() {
    const noteElement = this.$refs.note;
    if (noteElement) {
      this.noteOverflowing = noteElement.offsetHeight < noteElement.scrollHeight;
    }
  },
  methods: {
    getRemainingDaysTextToDisplay(remainingDays) {
      if (remainingDays < 8 && remainingDays > 0) {
        return this.$gettextInterpolate(
          this.$gettext('%{days} days left'),
          { days: remainingDays + 1 },
        );
      }
      if (remainingDays === 0) {
        return this.$gettext('Last day for request approval');
      }
      if (remainingDays < 0) {
        return this.$gettext('Notice not respected');
      }
      return null;
    },
    updateNote() {
      this.loadingUpdateNote = true;
      const payload = {
        flightId: this.flight.id,
        constraintId: this.constraint.id,
        note: this.note,
      };
      APIService.patchFlightConstraint(payload)
        .then(() => {
          this.$store.commit(SET_FLIGHT_CONSTRAINT_NOTE_NS, payload);
          this.showMessage(
            this.$gettext('Internal flight constraint note updated successfully.'),
            'success',
          );
        })
        .finally(() => {
          this.openDialogNote = false;
          this.loadingUpdateNote = false;
        });
    },
    updateStatus() {
      const status = this.getConstraintStatus === CLEAR ? NOT_HANDLED : CLEAR;
      this.loadingUpdateStatus = true;
      this.$store.dispatch(SET_FLIGHT_CONSTRAINT_STATUS_NS, {
        flightId: this.flight.id,
        constraintId: this.constraint.id,
        constraintStatus: status,
      })
        .then(() => {
          this.$emit('update-flight-status');
        })
        .finally(() => {
          this.loadingUpdateStatus = false;
        });
    },
    async fetchApprovals() {
      this.loadingElements = true;
      const { data: approvals } = await APIService.getFlightApprovals(
        this.flight.id, this.constraint.structure_id,
      );
      if (approvals?.length) {
        this.hasApprovals = true;
        this.updateFlightApprovals(approvals);
        this.fetchTakeOffAuthorizations(approvals);
        this.syncExternalEvents(approvals);
      }
      this.loadingElements = false;
    },
    updateFlightApprovals(approvals) {
      const approvalsUpdated = approvals.map((approval) => {
        const approvalUpdated = { ...approval };
        approvalUpdated.constraint_id = this.constraint.id;
        approvalUpdated.capability = this.approvalCapability;
        if (approvalUpdated.events) {
          approvalUpdated.events_documents = approvalUpdated.events.filter(
            (event) => event.document && !event.document.unavailable,
          ).map((event) => ({ event_id: event.id, ...event.document }));
        }
        return approvalUpdated;
      });
      this.$store.commit(
        UPDATE_FLIGHT_APPROVALS_NS, { flightId: this.flight.id, approvals: approvalsUpdated },
      );
    },
    fetchTakeOffAuthorizations(approvals) {
      approvals.filter((approval) => approval.take_off_authorization_id).forEach((approval) => {
        APIService.getTakeOffAuthorization(approval.take_off_authorization_id)
          .then(({ data }) => {
            this.$store.commit(
              SET_FLIGHT_APPROVAL_PROPERTY_NS,
              {
                flightId: this.flight.id,
                approvalId: approval.id,
                propertyKey: 'take_off_authorization',
                propertyValue: data,
              },
            );
          });
      });
    },
    syncExternalEvents(approvals) {
      approvals.filter((approval) => approval.external_id && approval.status !== CANCELLED)
        .forEach((approval) => {
          APIService.synchronizeApprovalExternalDocuments(approval.id)
            .then(({ data }) => {
              const events = data;
              if (events) {
                this.$store.commit(
                  SET_FLIGHT_APPROVAL_PROPERTY_NS,
                  {
                    flightId: this.flight.id,
                    approvalId: approval.id,
                    propertyKey: 'events',
                    propertyValue: events,
                  },
                );
                const eventsDocuments = events.filter(
                  (event) => event.document && !event.document.unavailable,
                ).map((event) => ({ event_id: event.id, ...event.document }));
                this.$store.commit(
                  SET_FLIGHT_APPROVAL_PROPERTY_NS,
                  {
                    flightId: this.flight.id,
                    approvalId: approval.id,
                    propertyKey: 'events_documents',
                    propertyValue: eventsDocuments,
                  },
                );
              }
            });
        });
    },
    fetchEmailRequest() {
      this.loadingElements = true;
      APIService.getEmailRequest(this.flight.id, this.constraint.structure_id)
        .then(({ data }) => {
          if (data?.length > 0) {
            const emailRequests = data.map((email) => ({
              ...email,
              constraint_id: this.constraint.id,
              capability: this.emailRequestCapability,
            }));
            this.$store.commit(
              UPDATE_FLIGHT_REQUEST_EMAIL_NS, {
                flightId: this.flight.id,
                newEmailRequests: emailRequests,
              },
            );
            this.hasEmailRequest = true;
          }
        })
        .finally(() => {
          this.loadingElements = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight-constraint {
  border-radius: 4px;
}
.flight-constraint>div:first-of-type {
  .v-expansion-panel {
    border-radius: 4px 4px 0px 0px !important;
  }
}
.flight-constraint>div:only-of-type {
  .v-expansion-panel {
    border-radius: 4px !important;
    .v-expansion-panel-header {
      border-radius: 4px !important;
    }
  }
}
.flight-constraint>div:last-of-type {
  border-radius: 0px 0px 4px 4px !important;
}
.white-background {
  background: white;
}
.note {
  margin: 0px;
  color: black;
  white-space: pre-line;
  &.with-overflow {
    cursor: pointer;
  }
  &.collapsed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

</style>
