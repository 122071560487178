<template>
  <v-container class="pa-0">
    <div class="details-card pa-3">
      <!-- Details  -->
      <v-row
        no-gutters
        align="center"
      >
        <!-- Name -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            NAME
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          {{ drone.name }}
        </v-col>
        <!-- Model -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            MODEL
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          {{ drone.model_name }}
        </v-col>
        <!-- Manufacturer -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            MANUFACTURER
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          {{ drone.manufacturer }}
        </v-col>
        <!-- Specific class -->
        <template v-if="drone.specific_class">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              CLASS
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            <span>
              {{ drone.specific_class }}
            </span>
          </v-col>
        </template>
        <!-- Category -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            CATEGORY
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          {{ droneCategory }}
        </v-col>
        <!-- Serial number -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            SERIAL NUMBER
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          {{ drone.serial_number }}
        </v-col>
        <!-- AlphaTango ID -->
        <template v-if="drone.alpha_tango_id">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              ALPHATANGO IDENTIFIER
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            UAS-FR-{{ drone.alpha_tango_id }}
          </v-col>
        </template>
        <!-- Identification mark -->
        <template v-if="drone.identification_mark">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              IDENTIFICATION MARK
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            {{ drone.identification_mark }}
          </v-col>
        </template>
        <!-- DJI controller ID -->
        <template v-if="drone.dji_controller_id">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              DJI CONTROLLER NUMBER
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            {{ drone.dji_controller_id }}
          </v-col>
        </template>
        <!-- Remote ID -->
        <template v-if="drone.remote_id_fr">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              REMOTE ID (FR)
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            {{ drone.remote_id_fr }}
          </v-col>
        </template>
        <template v-if="drone.remote_id_eu">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              REMOTE ID (EU)
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            {{ drone.remote_id_eu }}
          </v-col>
        </template>
        <!-- Weight -->
        <template v-if="drone.weight">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              WEIGHT
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            {{ drone.weight }} kg
          </v-col>
        </template>
        <!-- Wind speed -->
        <template v-if="drone.windspeed">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              MAX WIND
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            {{ drone.windspeed }} km/h
          </v-col>
        </template>
        <!-- HSS autorisation -->
        <template v-if="drone.hss_authorization">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              HSS REFERENCE
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            {{ drone.hss_authorization }}
          </v-col>
          <!--  HSS date start -->
          <template v-if="drone.hss_date_start">
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                HSS DATE START
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              {{ drone.hss_date_start | date }}
            </v-col>
          </template>
          <!--  HSS date end -->
          <template v-if="drone.hss_date_end">
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                HSS DATE END
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              {{ drone.hss_date_end | date }}
            </v-col>
          </template>
        </template>
        <!-- Captive -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            CAPTIVE
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          <span
            v-if="drone.captive"
            v-translate
          >
            Yes
          </span>
          <span
            v-else
            v-translate
          >
            No
          </span>
        </v-col>
      </v-row>
      <!-- Entities  -->
      <v-row
        v-if="subExploitantsAvailable"
        no-gutters
        align="center"
      >
        <v-col
          cols="5"
          sm="2"
        >
          <span class="details-card__label text-uppercase">
            {{ entityName.singular }}
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="10"
        >
          <div class="chips-group-flex-wrap">
            <v-chip
              v-for="(subExploitant, index) in subExploitants"
              :key="index"
              :input-value="true"
              light
              small
            >
              {{ subExploitant.name }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <!-- Livestream URL -->
      <v-row
        v-if="drone.livestream_rtmp_url && showLivestreamUrl"
        no-gutters
        align="center"
      >
        <v-col
          cols="12"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            LIVESTREAM URL
          </span>
        </v-col>
        <v-col
          cols="11"
          :sm="sizeLivestreamUrl"
        >
          <div
            class="cursor-pointer"
            :class="{
              'truncated_text': truncatedUrl,
              'wraped_text': !truncatedUrl,
            }"
            @click="truncatedUrl=!truncatedUrl"
          >
            {{ drone.livestream_rtmp_url }}
          </div>
        </v-col>
        <v-col cols="1">
          <v-icon
            color="primary"
            @click="copyLiveUrl()"
          >
            mdi-content-copy
          </v-icon>
        </v-col>
      </v-row>
      <!-- Documents  -->
      <div>
        <DroneDocuments :drone="drone" />
      </div>
    </div>
    <!-- Footer  -->
    <template v-if="canUpdateorDeleteDrone">
      <v-row
        no-gutters
        class="details-card__footer px-3"
      >
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            text
            icon
            @click="$emit('edit-drone', drone)"
          >
            <v-icon>
              icon-edit_line
            </v-icon>
          </v-btn>
          <v-btn
            text
            icon
            @click="$emit('delete-drone', drone)"
          >
            <v-icon>
              icon-bin_line
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import { DRONE_CATEGORIES, display } from '@/settings';

import DroneDocuments from '@/components/Drones/DroneDocuments.vue';

export default {
  name: 'Drone',
  components: { DroneDocuments },
  props: { drone: Object },
  data() {
    return { truncatedUrl: true };
  },
  computed: {
    canUpdateorDeleteDrone() {
      return (
        this.$store.getters['authentication/isUserExploitantAdmin']
        || this.$store.getters['authentication/isUserExploitantManager']
      );
    },
    droneCategory() {
      return DRONE_CATEGORIES.find((c) => c.value === this.drone.category).label;
    },
    subExploitantsAvailable() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    subExploitants() {
      return this.$store.state.authentication.userSubExploitants.filter(
        (s) => this.drone.sub_exploitants.includes(s.id),
      );
    },
    display() {
      return this.$store.state.application.display;
    },
    bigDisplay() {
      return this.display === display.BIG;
    },
    sizeLivestreamUrl() {
      return !this.truncatedUrl || this.bigDisplay ? '9' : '4';
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
    showLivestreamUrl() {
      return !this.$store.getters['exploitants/hideLivestreamsFeature'];
    },
  },
  methods: {
    copyLiveUrl() {
      const elem = document.createElement('textarea');
      elem.value = this.drone.livestream_rtmp_url;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);
      this.showMessage(this.$gettext('URL copied in the clipboard'), 'success');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.wraped_text {
  white-space: normal !important;
  word-break: break-all;
}
.truncated_text {
  max-width: 80vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
