<template>
  <v-row
    no-gutters
    class="details-card"
  >
    <!-- Approval details -->
    <v-col
      cols="12"
      class="px-4 pt-4"
    >
      <v-row no-gutters>
        <!-- Identifier -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            MISSION
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.display_identifier }}
        </v-col>
        <!-- Address -->
        <v-col
          cols="12"
          md="2"
          class="pr-1 d-flex align-center"
        >
          <span
            class="details-card__label"
            v-translate
          >
            ADDRESS
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
          class="d-flex align-center justify-space-between"
        >
          <span v-if="approval.address">
            {{ approval.address }}
          </span>
          <span v-else>
            {{ approval.commune }}
          </span>
          <v-btn
            text
            icon
            small
            @click="$emit('center-on-approval')"
          >
            <v-icon color="primary">icon-geoloc_line</v-icon>
          </v-btn>
        </v-col>
        <!-- Dates -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            DATES
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ approval.flight.date_start | date }}
          -
          {{ approval.flight.date_end | date }}
        </v-col>
        <!-- Hours -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            HOUR
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ approval.flight.time_start | hour }} - {{ approval.flight.time_end | hour }} loc.
        </v-col>
        <!-- Duration -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EXPECTED DURATION
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ approval.flight.expected_duration | hour }}
        </v-col>
        <!-- In/off sight -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            FLIGHT
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <span
            v-if="approval.flight.pilot_in_sight"
            v-translate
          >
            in sight
          </span>
          <span
            v-else
            class="error--text font-weight-bold"
            v-translate
          >
            off sight
          </span>
        </v-col>
        <hr class="separator" />
        <!-- Zone information -->
        <SubApprovalsDetails
          :approval="approval"
          @center-on-sub-approval="(geometry) => centerOnArea(geometry)"
          class="max-width"
        />
        <!-- Pilot information -->
        <hr class="separator" />
        <!-- Pilot name -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PILOT
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_name }}
        </v-col>
        <!-- Pilot email -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EMAIL
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_email }}
        </v-col>
        <!-- Pilot phone number -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PHONE NUMBER
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_phone_number | phone }}
        </v-col>
        <!-- Details gave by dronist -->
        <hr class="separator" />
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            DETAILS
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.details }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SubApprovalsDetails from '@/components/Approvals/SubApprovalsDetails.vue';

export default {
  name: 'OpinionApprovalDetails',
  components: { SubApprovalsDetails },
  props: { approval: Object },
  computed: {
    maxAltitude() {
      return Math.max(...(this.approval.sub_approvals.map((s) => s.elevation + s.height)));
    },
  },
  methods: {
    centerOnArea(geometry) {
      this.$emit('center-on-area', geometry);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.max-width {
  width: 100%;
}

</style>
