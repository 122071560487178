<template>
  <v-row
    no-gutters
    class="main-container"
  >
    <!-- --------------- HEADER ------------------ -->
    <v-card
      dark
      flat
      class="d-flex align-center justify-space-between flex-wrap flex-lg-nowrap mx-3 mt-2"
      color="transparent"
    >
      <!-- --------------- Title ------------------ -->
      <div class="d-flex align-center align-self-stretch flex-wrap ma-2">
        <v-toolbar-title>
          <v-icon>mdi-chart-line</v-icon>
          <translate class="ml-2 text-uppercase">Activity tracking</translate>
        </v-toolbar-title>
        <div class="pt-1">
          <span class="ml-4">
            <span class="font-weight-bold">
              {{ resultAPI.total_flights || '' }}
            </span>
            <translate>missions</translate>
          </span>
          <span class="ml-4">
            <span class="font-weight-bold">
              {{ resultAPI.total_hours || '' }}
            </span>
            <translate>heures</translate>
          </span>
        </div>
      </div>
      <!-- --------------- Filters ------------------ -->
      <div class="d-flex align-center justify-end order-3 order-lg-2 flex-grow-1 flex-lg-grow-0
          flex-md-nowrap flex-wrap ma-md-2 mb-2">
        <!-- ---- Dates ---- -->
        <div class="mx-3 mt-md-0 mt-2 flex-grow-1 align-self-stretch d-flex">
          <translate class="mr-3 body-2 v-label theme--dark align-self-center">
            Date range
          </translate>
          <v-select
            class="pa-0 ma-0"
            v-model="datesRange"
            item-text="label"
            item-value="key"
            :items="datesRangeItems"
            return-object
            hide-details
          />
        </div>
        <!-- ---- Pilots ---- -->
        <div
          class="mx-3 mt-md-0 mt-2 flex-grow-1 align-self-stretch"
          v-if="isUserEnterprise"
        >
          <v-select
            :loading="pilots.loadingPilots"
            :items="pilots.pilots"
            v-model="pilot_ids"
            item-text="full_name"
            item-value="id"
            multiple
            :placeholder="$gettext('Télépilotes')"
            class="ma-0 pa-0"
            hide-details
          />
        </div>
        <!-- ---- Tags ---- -->
        <div
          class="mx-3 mt-md-0 mt-2 flex-grow-1 align-self-stretch"
          v-if="isUserEnterprise"
        >
          <v-select
            :items="tags"
            v-model="selectedTags"
            multiple
            :placeholder="$gettext('Tags')"
            class="ma-0 pa-0"
            hide-details
          />
        </div>
        <!-- ---- Only closed ---- -->
        <div class="mx-3 mt-md-0 mt-2 pt-1 align-self-stretch d-flex align-center">
          <v-switch
            v-model="onlyClosed"
            hide-details
            color="primary"
            class="pa-0 ma-0"
          />
          <translate class="ml-2 body-2 v-label theme--dark">Only closed missions</translate>
        </div>
        <!-- ---- Reset filters ---- -->
        <div
          class="mx-3 mt-md-0 mt-2 align-self-stretch d-flex align-center"
          v-if="isUserEnterprise"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-if="!isMobileBreakpoint"
                @click="resetFilters()"
                dark
                icon
                outlined
                small
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <span
                v-else
                v-translate
                class="reset-link pt-1 body-2 v-label theme--dark"
                @click="resetFilters()"
              >
                Cancel filters
              </span>
            </template>
            <span>
              <translate>Cancel filters</translate>
            </span>
          </v-tooltip>
        </div>
      </div>
      <!-- --------------- Export ------------------ -->
      <div
        v-if="canExportCSV"
        class="align-self-stretch flex-grow-1 d-flex justify-end order-2 order-lg-3 ma-2"
      >
        <v-btn
          class="mx-4 font-weight-regular align-self-center"
          color="success"
          @click="exportCSVDialog=true"
        >
          <translate>CSV Export</translate>
        </v-btn>
      </div>
      <v-dialog
        v-model="exportCSVDialog"
        width="600"
      >
        <CSVExportForm @close-form="exportCSVDialog=false" />
      </v-dialog>
    </v-card>
    <!-- --------------- CHARTS ------------------ -->
    <div class="pb-4">
      <v-row
        v-for="(chart, index) in charts"
        :key="index"
        no-gutters
        class="flex-wrap px-4"
      >
        <v-col
          col="10"
          lg="12"
          class="pa-4"
        >
          <v-card
            class="d-flex align-center justify-center"
            min-height="350"
            light
          >
            <div class="chart-container">
              <div class="subtitle-2 px-8 pt-8 align-self-start">
                {{ chart.title }}
              </div>
              <template v-if="isChartDataReady">
                <template v-if="chart.gChart.dataAvailable">
                  <v-container class="flex-grow-1">
                    <GChart
                      :type="chart.gChart.type"
                      :data="chart.gChart.data"
                      :options="chart.gChart.options"
                    />
                  </v-container>
                </template>
                <template v-else>
                  <div class="pt-8 flex-grow-1">
                    {{ chart.gChart.noDataText }}
                  </div>
                </template>
              </template>
              <!-- LOADING  -->
              <template v-else>
                <div class="pt-8 flex-grow-1">
                  <v-progress-circular
                    color="primary"
                    indeterminate
                  />
                </div>
              </template>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-row>
</template>

<script>
import { GChart } from 'vue-google-charts';

import API from '@/services/api';
import { debounce } from '@/services/api.helper';

import { FETCH_TAG_NAMES_NS } from '@/store/exploitants';
import { GET_SIMPLIFIED_PILOTS_NS } from '@/store/pilots';

import CSVExportForm from '@/components/Activity/CSVExportForm.vue';

export default {
  name: 'Activity',
  components: { CSVExportForm },
  GChart,
  data() {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      datesRange: { key: 'All', label: this.$gettext('All Data') },
      datesRangeItems: [
        { key: 'All', label: this.$gettext('All') },
        { key: 'LastYear', label: this.$gettext('Last year') },
        { key: 'ThisYear', label: this.$gettext('This year') },
        { key: 'LastQuarter', label: this.$gettext('Last quarter') },
        { key: 'ThisQuarter', label: this.$gettext('This quarter') },
        { key: 'LastMonth', label: this.$gettext('Last month') },
        { key: 'ThisMonth', label: this.$gettext('This month') },
      ],
      pilot_ids: [],
      selectedTags: [],
      onlyClosed: false,
      isChartDataReady: false,
      hoursPerScenarioChart: { title: this.$gettext('Flight hours by scenario') },
      hoursPerDomainChart: { title: this.$gettext('Flight hours by activity domain') },
      hoursPerPilotChart: { title: this.$gettext('Flight hours by pilot') },
      hoursPerDroneChart: { title: this.$gettext('Flight hours by drone') },
      resultAPI: {
        domains_hours: [['Domain', 0]],
        total_flights: 0,
        total_hours: 0,
        total_flights_closed: 0,
        total_hours_closed: 0,
      },
      exportCSVDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    isUserEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    canExportCSV() {
      return (
        !this.$store.getters['authentication/userNotSubscribed'] && this.user.is_exploitant_admin
      );
    },
    charts() {
      let chartData = [
        this.hoursPerScenarioChart,
        this.hoursPerDomainChart,
        this.hoursPerPilotChart,
        this.hoursPerDroneChart,
      ];
      if (chartData.some((a) => (a === null))) { chartData = []; }
      return chartData;
    },
    pilots() {
      return this.$store.state.pilots;
    },
    tags() {
      return this.$store.state.exploitants.tagNames;
    },
    filterParameters() {
      return {
        dates_range: this.datesRange.key,
        is_archived: this.onlyClosed ? 'True' : null,
        pilots: this.pilot_ids.join() || null,
        tags: this.selectedTags.join() || null,
        sub_exploitant_id: this.$store.state.authentication.selectedSubExploitant,
      };
    },
  },
  watch: {
    filterParameters(newFilters) {
      this.isChartDataReady = false;
      this.fetchFromAPI({
        filters: newFilters,
        callback: this.fetchFromAPICallback,
      });
    },
  },
  created() {
    this.$store.dispatch(GET_SIMPLIFIED_PILOTS_NS, null);
    if (this.isUserEnterprise) {
      this.fetchFlightTags();
    }
    this.fetchFromAPI({
      filters: this.filterParameters,
      callback: this.fetchFromAPICallback,
    });
  },
  methods: {
    resetFilters() {
      this.datesRange = { key: 'All', label: this.$gettext('All Data') };
      this.pilot_ids = [];
      this.selectedTags = [];
      this.onlyClosed = false;
    },
    fetchFromAPI: debounce(
      (args) => {
        const { filters, callback } = args;
        API.getSubscriberActivityStats(filters)
          .then(({ data }) => callback(data));
      },
      3000, // debounce for 3s
    ),
    fetchFromAPICallback(data) {
      this.resultAPI = data;
      this.updateCharts();
    },
    formatHoursByScenarioData(hoursByScenario) {
      return [
        ...hoursByScenario.s0_hours ? [['S0', hoursByScenario.s0_hours]] : [],
        ...hoursByScenario.s1_hours ? [['S1', hoursByScenario.s1_hours]] : [],
        ...hoursByScenario.s2_hours ? [['S2', hoursByScenario.s2_hours]] : [],
        ...hoursByScenario.s3_hours ? [['S3', hoursByScenario.s3_hours]] : [],
        ...hoursByScenario.s4_hours ? [['S4', hoursByScenario.s4_hours]] : [],
        ...hoursByScenario.open_hours ? [['Open Category', hoursByScenario.open_hours]] : [],
        ...hoursByScenario.a1_hours ? [['A1', hoursByScenario.a1_hours]] : [],
        ...hoursByScenario.a2_hours ? [['A2', hoursByScenario.a2_hours]] : [],
        ...hoursByScenario.a3_hours ? [['A3', hoursByScenario.a3_hours]] : [],
        ...hoursByScenario.sts01_hours ? [['sts01', hoursByScenario.sts01_hours]] : [],
        ...hoursByScenario.sts02_hours ? [['sts02', hoursByScenario.sts02_hours]] : [],
        ...hoursByScenario.hss_hours ? [[this.$gettext('Outside of standard scenario'), hoursByScenario.hss_hours]] : [],
        ...hoursByScenario.unknown_hours ? [[this.$gettext('Unknown'), hoursByScenario.unknown_hours]] : [],
      ];
    },
    getChartOptions(type, dataLength) {
      const options = {
        BarChart: {
          legend: 'none',
          hAxis: { viewWindow: { min: 0 } },
          vAxis: { textStyle: { fontSize: 13 } },
          height: (dataLength * 48) + 52,
        },
        PieChart: { height: 450 },
      };
      return options[type] || {};
    },
    makeGoogleChart({ type, axis, data, noDataText }) {
      return {
        type,
        options: this.getChartOptions(type, data.length),
        data: [axis].concat(data),
        dataAvailable: data.length,
        noDataText,
      };
    },
    updateCharts() {
      this.hoursPerScenarioChart.gChart = this.makeGoogleChart({
        type: 'BarChart',
        axis: [this.$gettext('Scenario'), this.$gettext('Hours')],
        data: this.formatHoursByScenarioData(this.resultAPI.hours_by_scenario),
        noDataText: this.$gettext('No flight hours data available to display this chart.'),
      });
      this.hoursPerDomainChart.gChart = this.makeGoogleChart({
        type: 'PieChart',
        axis: [this.$gettext('Activity domain'), this.$gettext('Hours')],
        data: this.resultAPI.domains_hours,
        noDataText: this.$gettext('No flight hours data available to display this chart.'),
      });
      this.hoursPerPilotChart.gChart = this.makeGoogleChart({
        type: 'BarChart',
        axis: [this.$gettext('Pilot'), this.$gettext('Hours')],
        data: this.resultAPI.pilot_hours,
        noDataText: this.$gettext('No data available to display this chart.'),
      });
      this.hoursPerDroneChart.gChart = this.makeGoogleChart({
        type: 'BarChart',
        axis: [this.$gettext('Drone'), this.$gettext('Hours')],
        data: this.resultAPI.drone_hours,
        noDataText: this.$gettext('No data available to display this chart.'),
      });
      this.isChartDataReady = true;
    },
    fetchFlightTags() {
      this.$store.dispatch(FETCH_TAG_NAMES_NS);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.main-container {
  max-height: calc(100vh - 64px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}

.chart-container {
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset-link {
  cursor: pointer;
  text-decoration: underline;
}

</style>
