<template>
  <v-card class="default--dialog__card">
    <!-- Title -->
    <v-card-title>
      <div>
        <translate
          v-if="isUpdateForm"
          key="update"
        >
          Update tag
        </translate>
        <translate
          v-else
          key="create"
        >
          Create tag
        </translate>
      </div>
    </v-card-title>
    <!-- Form -->
    <v-card-text>
      <v-form
        ref="form"
        v-model="formValid"
        lazy-validation
        class="pa-2"
      >
        <!-- Name -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.name }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-text-field
              v-model="tag.name"
              :placeholder="placeholders.name"
              :rules="[rules.name]"
              single-line
              class="pa-0"
            />
          </v-col>
        </v-row>
        <!-- Sub exploitant -->
        <v-row
          v-if="hasManySubExploitants"
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.subExploitant }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-autocomplete
              v-model="tag.subExploitant"
              :items="subExploitants"
              :loading="subExploitantsLoading"
              :placeholder="placeholders.subExploitant"
              :rules="[rules.subExploitant]"
              :no-data-text="placeholders.emptyResults"
              item-text="name"
              item-value="id"
              class="p-0"
            />
          </v-col>
        </v-row>
        <!-- Constraint type -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.constraintType }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-autocomplete
              v-model="tag.constraintType"
              :items="tagConstraintTypes"
              :placeholder="placeholders.constraintType"
              :no-data-text="placeholders.emptyResults"
              item-text="label"
              item-value="name"
              class="p-0"
              clearable
            />
          </v-col>
        </v-row>
        <v-alert
          type="info"
          color="primary"
          text
        >
          {{ hints.constraintType }}
        </v-alert>
      </v-form>
    </v-card-text>
    <!-- Actions -->
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="confirm()"
        :disabled="!formValid"
        :loading="confirmLoading"
      >
        <translate>Confirm</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { GET_ALL_SUB_EXPLOITANTS_NS } from '@/store/exploitants';

export default {
  name: 'FlightTagForm',
  props: {
    tagToUpdate: {
      type: Object,
      required: false,
    },
    tagConstraintTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tag: {
        name: '',
        subExploitant: '',
        constraintType: '',
      },
      formValid: true,
      confirmLoading: false,
    };
  },
  computed: {
    isUpdateForm() {
      return !!this.tagToUpdate;
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
    hasSubExploitants() {
      return this.$store.getters['exploitants/hasSubExploitants'];
    },
    hasManySubExploitants() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    isExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    allSubExploitants() {
      return this.$store.state.exploitants.allSubExploitants;
    },
    userSubExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    subExploitants() {
      if (this.isExploitantAdmin) {
        return this.allSubExploitants;
      }
      return this.userSubExploitants;
    },
    subExploitantsLoading() {
      return this.$store.state.exploitants.allSubExploitantsLoading;
    },
    labels() {
      return {
        name: this.$gettext('Name *'),
        subExploitant: this.entityName.singular,
        constraintType: this.$gettext('Constraint type'),
      };
    },
    placeholders() {
      return {
        name: this.$gettext('Tag name'),
        subExploitant: this.$gettextInterpolate(
          this.$gettext('%{entityName} linked to the tag.'),
          { entityName: this.entityName.singular },
        ),
        constraintType: this.$gettext('Constraint type for an automatic tag'),
        emptyResults: this.$gettext('No result'),
      };
    },
    hints() {
      return {
        constraintType: this.$gettext(`If you choose a constraint type, the tag will be added
          automatically to new missions with constraints corresponding to this type.`),
      };
    },
    rules() {
      return {
        name: (value) => {
          let isValid = true;
          if (value.length > 32) {
            isValid = this.$gettext('Do not exceed 32 characters.');
          } else if (!value.trim().length) {
            isValid = this.$gettext('This field is required.');
          }
          return isValid;
        },
        subExploitant: (value) => {
          let isValid = true;
          if (this.hasManySubExploitants) {
            isValid = !!value || this.$gettext('This field is required.');
          }
          return isValid;
        },
      };
    },
    currentConstraintType() {
      return this.tag.constraintType;
    },
  },
  watch: {
    currentConstraintType(newValue) {
      if (newValue && !this.tag.name) {
        this.tag.name = this.tagConstraintTypes.find(
          (constraintType) => constraintType.name === newValue,
        )?.suggested_tag_name;
      }
    },
  },
  created() {
    if (this.tagToUpdate) {
      this.tag = {
        name: this.tagToUpdate.name,
        subExploitant: this.tagToUpdate.sub_exploitant,
        constraintType: this.tagToUpdate.constraint_type,
      };
    }
    if (this.hasSubExploitants) {
      if (this.isExploitantAdmin) {
        this.$store.dispatch(GET_ALL_SUB_EXPLOITANTS_NS);
      }
      if (!this.hasManySubExploitants && !this.tagToUpdate) {
        this.tag.subExploitant = this.userSubExploitants[0].id;
      }
    }
  },
  methods: {
    buildPayload() {
      return {
        name: this.tag.name,
        sub_exploitant: this.tag.subExploitant,
        constraint_type: this.tag.constraintType,
      };
    },
    confirm() {
      if (!this.$refs.form.validate()) return;
      this.confirmLoading = true;
      let action;
      const payload = this.buildPayload();
      if (this.isUpdateForm) {
        action = this.updateFlightTag(payload);
      } else {
        action = this.createFlightTag(payload);
      }
      action
        .then(() => {
          this.$emit('reload');
          this.$emit('close');
        })
        .finally(() => { this.confirmLoading = false; });
    },
    updateFlightTag(payload) {
      return APIService.updateFlightTag(this.tagToUpdate.id, payload)
        .then(() => {
          this.showMessage(this.$gettext('Tag updated successfully.'), 'success');
        });
    },
    createFlightTag(payload) {
      return APIService.createFlightTag(payload)
        .then(() => {
          this.showMessage(this.$gettext('Tag created successfully.'), 'success');
        });
    },
  },
};
</script>
