/**
 * Create HTML element add adds it to parent container
 * @param {String} tagName HTML tag
 * @param {Object} properties element properties
 * @param {Object} container element container
 */
const create = (tagName, properties = {}, container) => {
  const el = window.document.createElement(tagName);
  Object.keys(properties).forEach((p) => { el[p] = properties[p]; });
  if (container) container.appendChild(el);
  return el;
};

const DEFAULT_OPTIONS = {
  iconClass: 'icon material-icons',
  iconMaterialClass: 'reply',
};

class MapAreasHistoryControl {
  constructor(options) {
    this._options = { ...DEFAULT_OPTIONS, ...options };

    this._container = create('div', { className: 'mapboxgl-ctrl mapboxgl-ctrl-group' });
    this._container.addEventListener('contextmenu', (e) => e.preventDefault());
    this._createHistoryButton();
  }

  _createHistoryButton() {
    this._historyButton = create(
      'button',
      { className: 'mapboxgl-ctrl-icon list-maps-button' },
      this._container,
    );

    create('i', {
      className: this._options.iconClass,
      innerText: this._options.iconMaterialClass,
    }, this._historyButton);
  }

  onAdd(map) {
    this._historyButton.addEventListener('click', (e) => {
      e.preventDefault();
      map.parent.restoreArea();
    });
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
  }
}

export default MapAreasHistoryControl;
