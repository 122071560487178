<template>
  <v-container
    pa-0
    px-3
    class="user"
  >
    <v-row class="user__details px-3">
      <div>
        <span class="user__detail user__detail--fixed primary--text">USER ID</span>
        {{ user.id }}
      </div>
    </v-row>
    <v-row
      justify="end"
      class="user__footer px-2"
    >
      <v-btn
        text
        icon
        :loading="loading"
        @click="hijackUser(user)"
      >
        <v-icon>group</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { defaultRoutes } from '@/settings';

import { HIJACK_USER_NS } from '@/store/authentication';

export default {
  name: 'User',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { loading: false };
  },
  computed: {
    userAuthentificated() {
      return this.$store.state.authentication.user;
    },
  },
  methods: {
    async hijackUser(user) {
      this.loading = true;
      await this.$store.dispatch(HIJACK_USER_NS, { userId: user.id });
      const userType = this.userAuthentificated && this.userAuthentificated.user_type;
      const interfaceVersion = this.userAuthentificated.authority_interface_version;
      this.loading = false;
      // add specific routes for the user logged
      this.$router.resetRoutes();
      this.$router.addUserRoutes(userType, interfaceVersion);
      // go to the default route for the user type
      this.$router.push(this.$route.query.redirect || defaultRoutes[userType]);
      window.location.reload();
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.user {
  &__details {
    padding: 1rem 0;
  }
  &__detail {
    display: inline-block;
    font-size: 0.65rem;
    font-weight: bold;
    &--fixed {
      width: 5rem;
    }
  }
  &__details {
    border-top: 1px solid rgba(0, 0, 0, .12);
    background-color: white;
    color: $color-secondary;
  }
  &__footer {
    background-color: $color-primary;
    color: white;
  }
}

</style>
