<template>
  <div>
    <!-- Approval Request Button -->
    <template v-if="isApprovalCapability">
      <RequestApprovalActions
        :flight="flight"
        :constraint="constraint"
        :capability="approvalCapability"
      />
    </template>
    <!-- AlphaTango Prefecture Action Buttons -->
    <template v-else-if="isPrefectureNotifCapability">
      <NotifAlphaTangoPrefectureActions
        :flight="flight"
        :constraint="constraint"
        @show-subscribe-dialog="() => $emit('show-subscribe-dialog')"
      />
    </template>
    <!-- AlphaTango Army Action Buttons -->
    <template v-else-if="isArmyNotifCapability">
      <NotifAlphaTangoArmyActions
        :flight="flight"
        :constraint="constraint"
        @show-subscribe-dialog="() => $emit('show-subscribe-dialog')"
      />
    </template>
    <!-- Send Email Request Button -->
    <template v-else-if="isEmailRequestCapability">
      <EmailRequestAction
        :flight="flight"
        :constraint="constraint"
        :capability="emailRequestCapability"
        @show-subscribe-dialog="() => $emit('show-subscribe-dialog')"
      />
    </template>
    <!-- Download Protocol Forms Button -->
    <template v-else-if="isProcotolFormLinksCapability">
      <LayoutFlightConstraintAction
        id="download-form-btn"
        ignoreFlightEnded
      >
        <template
          slot="action-button"
          slot-scope="{disabled}"
        >
          <div class="pb-2">
            <v-btn
              color="success"
              class="main-button__font my-0"
              @click="downloadLinks()"
              :disabled="disabled"
              block
            >
              <span
                v-if="protocolFormLinks.length > 1"
                v-translate="{n: protocolFormLinks.length}"
              >
                Download the %{n} forms
              </span>
              <span
                v-else
                v-translate
              >
                Download form
              </span>
            </v-btn>
          </div>
        </template>
      </LayoutFlightConstraintAction>
    </template>
  </div>
</template>

<script>
import { CAPABILITY_CATEGORY, CONSTRAINTS_CATEGORY, CONSTRAINTS_PROCESS_THROUGH } from '@/settings';

import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

import EmailRequestAction from '@/components/Flights/EmailRequest/EmailRequestAction.vue';
import NotifAlphaTangoArmyActions from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoArmyActions.vue';
import NotifAlphaTangoPrefectureActions from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoPrefectureActions.vue';
import RequestApprovalActions from '@/components/Flights/RequestApproval/RequestApprovalActions.vue';

export default {
  name: 'FlightConstraintActions',
  components: {
    LayoutFlightConstraintAction,
    EmailRequestAction,
    NotifAlphaTangoArmyActions,
    NotifAlphaTangoPrefectureActions,
    RequestApprovalActions,
  },
  props: {
    flight: Object,
    constraint: Object,
    capability: Object,
  },
  computed: {
    category() {
      return this.capability.category;
    },
    isApprovalCapability() {
      return this.category === CAPABILITY_CATEGORY.APPROVAL_REQUEST;
    },
    approvalCapability() {
      return this.capability.approval_request;
    },
    isEmailRequestCapability() {
      return this.category === CAPABILITY_CATEGORY.EMAIL_REQUEST;
    },
    emailRequestCapability() {
      return this.capability.email_request;
    },
    isProcotolFormLinksCapability() {
      return (
        this.constraint.category === CONSTRAINTS_CATEGORY.IMPACTING
        && this.capability.category === CAPABILITY_CATEGORY.PROTOCOL_FORM_LINKS
      );
    },
    protocolFormLinks() {
      return this.capability.protocol_form_links;
    },
    isArmyNotifCapability() {
      return this.category === CAPABILITY_CATEGORY.ARMY_NOTIF;
    },
    isPrefectureNotifCapability() {
      return (
        this.category === CAPABILITY_CATEGORY.PREFECTURE_NOTIF
        && this.constraint.process_through !== CONSTRAINTS_PROCESS_THROUGH.THALES
      );
    },
  },
  methods: {
    downloadLinks() {
      this.protocolFormLinks.forEach((link) => {
        const a = document.createElement('a');
        a.href = link;
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
      });
    },
  },
};
</script>
