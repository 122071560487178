<template>
  <v-card class="default--dialog__card">
    <!-- Title  -->
    <v-card-title>
      <span>
        <div>
          <v-icon
            color="primary"
            class="pr-2"
          >
            mdi-delete-outline
          </v-icon>
          <translate>Delete livestream</translate>
        </div>
      </span>
      <v-icon @click="close()">
        close
      </v-icon>
    </v-card-title>
    <!-- Text -->
    <v-card-text>
      <span>
        {{ texts.clickingAction }}
      </span>
      <br>
      <span>
        {{ texts.livestreamDeletion}}
      </span>
    </v-card-text>
    <!-- Actions  -->
    <v-card-actions>
      <v-btn
        @click="deleteLivestream()"
        :loading="livestreamDeleteLoading"
        color="primary"
      >
        <translate>Validate</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import {
  SET_LIVESTREAM_TO_DELETE_NS,
  DELETE_LIVESTREAM_NS,
} from '@/store/livestreams';

export default {
  name: 'LivestreamDelete',
  data() {
    return { livestreamDeleteLoading: false };
  },
  computed: {
    livestream() {
      return this.$store.getters['livestreams/livestreamToDelete'];
    },
    texts() {
      return {
        clickingAction: this.$gettext(`By clicking, your livestream will be deleted of all our
          platform and this action can not be undone.`),
        livestreamDeletion: this.$gettextInterpolate(
          this.$gettext("Are you sure to delete the livestream '%{label}'?"),
          { label: this.livestream ? this.livestream.label : '' },
          true,
        ),
      };
    },
  },
  methods: {
    deleteLivestream() {
      this.livestreamDeleteLoading = true;
      APIService.deleteLivestream(this.livestream.id)
        .then(() => {
          this.showMessage(this.$gettext('Livestream was deleted successfully'), 'success');
          this.$store.dispatch(DELETE_LIVESTREAM_NS, this.livestream.id);
          this.close();
        })
        .finally(() => {
          this.livestreamDeleteLoading = false;
        });
    },
    close() {
      this.$store.commit(SET_LIVESTREAM_TO_DELETE_NS, undefined);
    },
  },
};
</script>
