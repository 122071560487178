<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <span>
        {{ title }}
      </span>
    </v-card-title>
    <v-card-text class="pt-5 pl-5">
      <span>
        {{ dialogText }}
      </span>
    </v-card-text>
    <v-card-actions class="d-flex justify-center pb-3">
      <v-btn
        class="mx-3"
        @click="cancelButtonAction()"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        @click="openStripeCookiePanel()"
        color="primary"
      >
        <translate>Handle my cookies</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'AskCookieStripe',
  props: {
    title: {
      type: String,
      required: true,
    },
    dialogText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasAcceptedCookieStripe() {
      return this.$store.getters['authentication/hasAcceptedCookieStripe'];
    },
  },
  unmounted() {
    this.cancelButtonAction();
  },
  methods: {
    cancelButtonAction() {
      this.$emit('close-form');
    },
    openStripeCookiePanel() {
      window.axeptioSDK.requestConsent('stripe');
    },
  },
};
</script>
