<template>
  <div class="selection-actions mt-2">
    <v-alert
      dense
      text
      color="info"
      rounded="0"
      class="ma-0 text-center"
    >
      <div class="d-flex align-center">
        <div class="d-flex justify-start flex-grow-1 selection-actions--text">
          <translate
            :translate-n="flights.length"
            translate-plural="%{ flights.length } flights selected"
            class="caption"
          >
            %{ flights.length } flight selected
          </translate>
        </div>
        <div class="d-flex justify-end">
          <v-tooltip
            v-for="(action, index) in actionsAvailable"
            :key="index"
            top
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                light
                @click="action.action()"
              >
                <v-icon light>
                  {{ action.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ action.text }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'MultipleActions',
  props: {
    flights: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      actions: [
        {
          type: 'archive',
          action: () => this.$emit('archive'),
          icon: 'mdi-archive-arrow-down-outline',
          text: this.$gettext('Archive'),
        },
        {
          type: 'delete',
          action: () => this.$emit('delete'),
          icon: 'mdi-delete-outline',
          text: this.$gettext('Delete'),
        },
        {
          type: 'export_kml',
          action: () => this.$emit('export-kml'),
          icon: 'mdi-map',
          text: this.$gettext('Export flights area in Google Earth format (KML/KMZ)'),
        },
        {
          type: 'constraint_sheets',
          action: () => this.$emit('generate-constraint-sheets'),
          icon: 'mdi-file-word',
          text: this.$gettext('Generate constraint sheets'),
        },
      ],
    };
  },
  computed: {
    isGeneralistPilot() {
      return this.$store.getters['authentication/isGeneralistPilot'];
    },
    actionsAvailable() {
      if (this.isGeneralistPilot) return this.actions.filter((a) => a.type !== 'delete');

      return this.actions;
    },
  },
};
</script>
