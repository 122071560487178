<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Delete drone</translate>
    </v-card-title>
    <v-card-text>
      <div
        v-if="loadingCheckCanBeDeleted"
        class="d-flex flex-column align-center"
      >
        <p v-translate>Check if drone is deletable...</p>
        <v-progress-circular
          indeterminate
          color="primary"
          :size="30"
          :width="2"
        />
      </div>
      <div v-else>
        <!-- Drone can be deleted -->
        <p v-if="canBeDeleted">
          {{ texts.deleteConfirmation }}
        </p>
        <!-- Drone can't be deleted -->
        <template v-else>
          <p>
            {{ texts.deleteImpossible }}
          </p>
          <!-- But drone can be archived -->
          <template v-if="droneToDelete.active">
            <p>
              {{ texts.archiveConfirmation }}
            </p>
          </template>
        </template>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <div v-if="!loadingCheckCanBeDeleted">
        <v-btn
          key="delete"
          v-if="canBeDeleted"
          :loading="loading"
          @click="deleteDrone()"
          text
          color="primary"
        >
          <translate>Confirm</translate>
        </v-btn>
        <v-btn
          key="archive"
          v-else-if="droneToDelete.active"
          :loading="loading"
          @click="archive()"
          text
          color="primary"
        >
          <translate>Not propose in future missions</translate>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { GET_DRONES_NS } from '@/store/drones';

export default {
  name: 'DroneDelete',
  props: {
    droneToDelete: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingCheckCanBeDeleted: false,
      canBeDeleted: true,
    };
  },
  computed: {
    texts() {
      return {
        deleteConfirmation: this.$gettextInterpolate(
          this.$gettext('Do you really want to delete the drone %{name}?'),
          { name: this.droneToDelete ? this.droneToDelete.name : '' },
          true,
        ),
        deleteImpossible: this.$gettextInterpolate(
          this.$gettext("The drone %{name} is already linked to a mission and can't be deleted."),
          { name: this.droneToDelete ? this.droneToDelete.name : '' },
          true,
        ),
        archiveConfirmation: this.$gettext(`You can however archive this drone if you don't want it
          to be proposed for your future missions.`),
      };
    },
  },
  created() {
    this.loadingCheckCanBeDeleted = true;
    APIService.canDroneBeDeleted(this.droneToDelete.id)
      .then(({ data }) => {
        this.canBeDeleted = data.can_be_deleted;
      })
      .finally(() => {
        this.loadingCheckCanBeDeleted = false;
      });
  },
  methods: {
    close() {
      this.$store.dispatch(GET_DRONES_NS);
      this.$emit('close');
    },
    deleteDrone() {
      this.loading = true;
      APIService.deleteDrone(this.droneToDelete.id)
        .then(() => {
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    archive() {
      this.loading = true;
      APIService.updateDrone(this.droneToDelete.id, { active: false })
        .then(() => {
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
