<template>
  <v-snackbar
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    v-model="snackbar.display"
    class="snackbar"
    top
  >
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-icon
          v-if="snackbar.color === 'success'"
          class="pr-2"
          color="white"
        >
          done
        </v-icon>
        <v-icon
          v-if="snackbar.color === 'error'"
          class="pr-2"
          color="white"
        >
          error
        </v-icon>
        <v-icon
          v-if="snackbar.color === 'info'"
          class="pr-2"
          color="white"
        >
          feedback
        </v-icon>
        <div
          v-if="snackbar.readHtml === true"
          v-html="sanitizedSnackbarMessage"
          class="link-html"
        />
        <div v-else>
          {{ snackbar.message }}
        </div>
      </div>
      <v-btn
        text
        @click.native="hideSnackbar()"
      >
        OK
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import sanitizeHtml from 'sanitize-html';

import { HIDE_SNACKBAR_NS } from '@/store/application';

export default {
  name: 'Snackbar',
  computed: {
    snackbar() {
      return this.$store.state.application.snackbar;
    },
    sanitizedSnackbarMessage() {
      if (this.snackbar.message) {
        const sanitized = sanitizeHtml(this.snackbar.message, {
          allowedTags: ['a'],
          allowedAttributes: { a: ['href', 'target'] },
        });
        return sanitized;
      }
      return '';
    },
  },
  watch: {
    snackbar: {
      // Custom timeout for the snackbar, as the timeout prop of the vuetify
      // snackbar brings an error due to prop mutated outside mutation handler.
      handler(newValue) {
        if (newValue.display) {
          if (newValue.long === true) {
            setTimeout(this.hideSnackbar, 1800000); // 30 min
          } else if (newValue.color === 'error') {
            setTimeout(this.hideSnackbar, 60000); // 1 min
          } else {
            setTimeout(this.hideSnackbar, 30000); // 30 s
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    hideSnackbar() {
      this.$store.dispatch(HIDE_SNACKBAR_NS);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.link-html ::v-deep a {
  color: $color-white;
}

</style>
