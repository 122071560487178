<template>
  <div>
    <!-- --------------- Upload new document ---------------- -->
    <template v-if="!documents.length">
      <div
        v-if="!documentsReadOnly"
        class="documents__input light"
      >
        <template v-if="!uploadLoading">
          <v-icon
            class="mdi-rotate-270 pr-1"
            color="primary"
          >
            mdi-attachment
          </v-icon>
          <label
            for="file-input"
            class="documents__input-label"
          >
            <translate>UPLOAD DOCUMENT</translate>
          </label>
          <input
            id="file-input"
            class="hidden"
            type="button"
            @click="uploadDialog = true"
            :disabled="documents.length>=maxNumberDocuments"
          >
        </template>
        <v-progress-linear
          v-else
          :indeterminate="true"
          class="documents__input-loader"
          color="primary"
        />
      </div>
    </template>
    <!-- --------------- Documents expansion panel ---------------- -->
    <div
      v-else
      class="mt-2"
    >
      <v-expansion-panels light>
        <v-expansion-panel class="documents">
          <div>
            <v-expansion-panel-header class="documents__header">
              <span class="details-card__label">
                {{ documents.length }} {{ documents.length > 1 ? "DOCUMENTS" : "DOCUMENT" }}
              </span>
            </v-expansion-panel-header>
          </div>
          <v-expansion-panel-content expand-icon="keyboard_arrow_down">
            <div
              v-for="(obj, index) in droneDocumentsCategory"
              :key="index"
            >
              <span class="info--text text--lighten-1 pl-2">
                {{ obj.categoryDisplayed }} :
              </span>
              <template v-for="document in documents">
                <template v-if="document.category === obj.category">
                  <v-chip
                    :key="document.id"
                    class="documents__chip caption"
                    :close="!documentsReadOnly"
                    @click:close="documentToDelete=document.id;deletionDialog=true"
                  >
                    <v-tooltip
                      top
                      color="info"
                      open-delay="500"
                    >
                      <template v-slot:activator="{ on }">
                        <span
                          v-on="on"
                          class="documents__link-label"
                          @click="downloadDocument(document.id, document.name)"
                        >
                          {{ document.name }}
                        </span>
                      </template>
                      <span class="documents">
                        {{ document.name }}
                      </span>
                    </v-tooltip>
                    <span v-if="document.expiration_date">
                      &nbsp;({{ document.expiration_date }})
                    </span>
                  </v-chip>
                </template>
              </template>
            </div>
            <!-- --------------- Upload new document ---------------- -->
            <div
              v-if="!documentsReadOnly"
              :class="{'disabled':documents.length>=maxNumberDocuments}"
              class="documents__input light"
            >
              <template v-if="!uploadLoading">
                <v-tooltip
                  :disabled="documents.length<maxNumberDocuments"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-icon
                        class="mdi-rotate-270 pr-1"
                        color="primary"
                        :disabled="documents.length>=maxNumberDocuments"
                      >
                        mdi-attachment
                      </v-icon>
                      <label
                        for="file-input"
                        class="documents__input-label"
                      >
                        <translate>UPLOAD DOCUMENT</translate>
                      </label>
                    </div>
                  </template>
                  <span v-if="documents.length>=maxNumberDocuments">
                    <translate :translate-params="{number: maxNumberDocuments}">
                      Only %{number} documents can be uploaded.
                    </translate>
                  </span>
                </v-tooltip>
                <input
                  id="file-input"
                  class="hidden"
                  type="button"
                  @click="uploadDialog=true"
                  :disabled="documents.length>=maxNumberDocuments"
                >
              </template>
              <v-progress-linear
                v-else
                :indeterminate="true"
                class="documents__input-loader"
                color="primary"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- --------------- Confirm delete document ---------------- -->
      <v-dialog
        v-model="deletionDialog"
        max-width="420"
        persistent
        :retain-focus="false"
      >
        <v-card class="default--dialog__card">
          <v-card-title>
            <translate>Confirmation required</translate>
          </v-card-title>
          <v-card-text>
            <translate>Are you sure you want to delete this document ?</translate>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="info"
              @click="documentToDelete=null;deletionDialog=false"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              :loading="deletionLoading"
              text
              color="primary"
              @click="deleteDocument()"
            >
              <translate>Confirm</translate>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- -------- Upload new document dialog -------- -->
    <v-dialog
      v-model="uploadDialog"
      max-width="500px"
      persistent
      :retain-focus="false"
    >
      <v-form v-model="validForm">
        <v-card class="default--dialog__card">
          <v-card-title>
            <translate>Upload document</translate>
          </v-card-title>
          <v-card-text>
            <v-select
              :items="documentCategories"
              v-model='documentCategory'
              :label="labels.documentCategory"
              item-text="label"
              item-value="value"
              hide-details
              class="py-4"
              prepend-icon="mdi-file-outline"
              dense
            />
            <v-menu
              v-model="showCalendar"
              :close-on-content-click="false"
              offset-y
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="expirationDate"
                  :label="labels.expirationDate"
                  hide-details
                  prepend-icon="event"
                  v-bind="attrs"
                  v-on="on"
                  class="py-4"
                  readonly
                />
              </template>
              <v-date-picker
                v-model="expirationDate"
                @input="showCalendar=false"
                no-title
                locale="fr"
                first-day-of-week="1"
                scrollable
                class="elevation-0"
              />
            </v-menu>
            <v-file-input
              v-model="fileInput"
              :label="labels.fileInput"
              :rules="uploadedFilesRules"
              class="py-4"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="info"
              @click="closeUploadDialog()"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="uploadDocument()"
              :disabled="!validForm"
              :loading="uploadLoading"
            >
              <translate>Confirm</translate>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import { MAX_DOCUMENT_SIZE_MB, MAX_NUMBER_OF_DOCUMENTS } from '@/settings';

import {
  ADD_DRONE_DOCUMENT_NS,
  DELETE_DRONE_DOCUMENT_NS,
} from '@/store/drones';

export default {
  name: 'DroneDocuments',
  props: {
    drone: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uploadDialog: false,
      validForm: true,
      uploadLoading: false,
      documentCategory: '',
      showCalendar: false,
      expirationDate: null,
      fileInput: null,
      documentToDelete: null,
      deletionDialog: false,
      deletionLoading: false,
      maxDocumentSize: MAX_DOCUMENT_SIZE_MB.drone,
      maxNumberDocuments: MAX_NUMBER_OF_DOCUMENTS.drone,
    };
  },
  computed: {
    documentsReadOnly() {
      const isAdmin = this.$store.getters['authentication/isUserExploitantAdmin'];
      const isManager = this.$store.getters['authentication/isUserExploitantManager'];
      return !(isAdmin || isManager);
    },
    documents() {
      return this.drone.documents && this.drone.documents.length ? this.drone.documents : [];
    },
    droneDocumentsCategory() {
      if (this.documents.length) {
        const category = [];
        this.documents.forEach((d) => {
          if (!category.map((c) => c.category).includes(d.category)) {
            category.push({
              category: d.category,
              categoryDisplayed: d.category_displayed,
            });
          }
        });
        return category.sort((a, b) => a.categoryDisplayed > b.categoryDisplayed);
      }
      return [];
    },
    documentCategories() {
      return this.$store.state.drones.documentCategories;
    },
    labels() {
      return {
        documentCategory: this.$gettext('Choose document category'),
        expirationDate: this.$gettext('Document expiration date'),
        fileInput: this.$gettext('Choose a file'),
      };
    },
    uploadedFilesRules() {
      return [
        (value) => !!value || this.$gettext('You need to choose a file!'),
        (value) => !value || value.size < this.maxDocumentSize * 1000000
          || this.$gettextInterpolate(
            this.$gettext('File size should be less than %{ size } MB !'),
            { size: this.maxDocumentSize },
          ),
      ];
    },
  },
  methods: {
    uploadDocument() {
      if (this.fileInput) {
        const formData = new FormData();
        formData.append('file', this.fileInput);
        formData.append('related_id', this.drone.id);
        formData.append('related_type', 'drone');
        formData.append('category', this.documentCategory);
        if (this.expirationDate) {
          formData.append('expiration_date', this.expirationDate);
        }
        this.uploadLoading = true;
        APIService.uploadDocument(formData)
          .then(({ data }) => {
            this.$store.dispatch(ADD_DRONE_DOCUMENT_NS, { droneId: this.drone.id, document: data })
              .then(() => {
                this.showMessage(this.$gettext('Document upload successfully.'), 'success');
              });
          })
          .finally(() => {
            this.uploadLoading = false;
            this.closeUploadDialog();
          });
      }
    },
    downloadDocument(documentId, documentName) {
      APIService.downloadDocument(documentId)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    deleteDocument() {
      this.deletionLoading = true;
      APIService.deleteDocument(this.documentToDelete)
        .then(() => {
          this.$store.dispatch(
            DELETE_DRONE_DOCUMENT_NS,
            { droneId: this.drone.id, documentId: this.documentToDelete },
          )
            .then(() => {
              this.showMessage(this.$gettext('Document deleted.'), 'success');
            });
        })
        .finally(() => {
          this.documentToDelete = null;
          this.deletionLoading = false;
          this.deletionDialog = false;
        });
    },
    closeUploadDialog() {
      this.uploadDialog = false;
      this.documentCategory = '';
      this.showCalendar = false;
      this.expirationDate = null;
      this.fileInput = null;
    },
  },
};
</script>
