<template>
  <v-card
    class="mx-auto "
    max-width="400"
    min-width="200"
    max-height="460"
    tile
    light
  >
    <!-- Notifications list -->
    <template>
      <v-list
        v-if="allNotifications.length"
        light
      >
        <v-list-item
          three-line
          v-for="(notification, index) in allNotifications"
          @click.prevent="selectNotification(notification)"
          :value="notification.id"
          :key="index"
        >
          <v-list-item-content :class="notification.getStyleClass()">
            <v-badge
              :value="!notification.viewed"
              dot
              color="red"
              offset-x="16"
              offset-y="30"
            >
              <v-list-item-title>
                {{ notification.getTitle() }}
              </v-list-item-title>
            </v-badge>
            <v-list-item-subtitle>
              {{ notification.getFirstLine() }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="line-height">
              <span class="multilines">
                {{ notification.getSecondLine() }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-text
        v-else
        class="text-center"
        key="no-notif"
      >
        <translate>No notifications received.</translate>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { SET_ACTIVE_TAB_NS } from '@/store/application';
import { MARK_NOTIFICATIONS_READ_NS } from '@/store/notifications';

export default {
  name: 'NotificationsList',
  props: { closed: Boolean },
  computed: {
    allNotifications() {
      return this.$store.state.notifications.notifications;
    },
    newNotifications() {
      return this.$store.getters['notifications/newNotifications'];
    },
    hasNewNotifications() {
      return this.newNotifications.length !== 0;
    },
    readNotifications() {
      return this.$store.getters['notifications/readNotifications'];
    },
    isUserHijacked() {
      return this.$store.state.authentication.hijackedUserId !== null;
    },
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
  },
  watch: {
    closed(newValue) {
      if (newValue && !this.isUserHijacked && this.hasNewNotifications) {
        this.markNotificationRead();
      }
    },
  },
  methods: {
    selectNotification(notification) {
      notification.atClick(
        this.dispatchStoreAction,
        this.subscribeStoreMutation,
        this.navigate,
      );
      if (this.isMobileBreakpoint) {
        if (this.hasNewNotifications) this.markNotificationRead();

        this.$emit('close');
      }
    },
    navigate(tab, route, fullScreenRoute, params = {}) {
      if (this.isAuthorityFullScreenInterface) {
        if (this.$route.name !== fullScreenRoute) {
          this.$router.push({ name: fullScreenRoute, params });
        }
      } else {
        this.$store.dispatch(SET_ACTIVE_TAB_NS, tab);
        if (this.$route.name !== route) {
          this.$router.push({ name: route });
        }
      }
    },
    dispatchStoreAction(action, params) {
      return this.$store.dispatch(action, params);
    },
    subscribeStoreMutation({ mutationName, callback, params }) {
      return this.$store.subscribe((mutation) => {
        if (mutation.type === mutationName) {
          callback(params);
        }
      });
    },
    markNotificationRead() {
      const notificationIds = this.newNotifications.map((n) => n.id);
      this.$store.dispatch(MARK_NOTIFICATIONS_READ_NS, { notificationIds });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.new-notification {
  font-weight: 500;
}
.read-notification {
  font-weight: 350;
  opacity: 0.8;
}
.line-height {
  line-height: 1.2rem;
}

</style>
