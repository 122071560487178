<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Demande de confirmation</translate>
    </v-card-title>
    <v-card-text>
      <translate :translate-params="{email: userEmail}">
        Un export des données va être préparé et envoyé à l'adresse de votre compte (%{email}).
        Vous devriez le recevoir d'ici quelques minutes.
      </translate>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="$emit('close-form')">
        <translate>No</translate>
      </v-btn>
      <v-btn
        class="white--text"
        color="primary"
        :loading="exportRunning"
        @click="confirmExport()"
      >
        <translate>Yes</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import API from '@/services/api';

export default {
  name: 'CSVExportForm',
  data() {
    return { exportRunning: false };
  },
  computed: {
    userEmail() {
      return this.$store.state.authentication.user.email;
    },
  },
  methods: {
    async confirmExport() {
      this.exportRunning = true;
      await API.sendFlightsExportRequest()
        .then(({ data }) => {
          this.showMessage(data, 'success');
        })
        .finally(() => {
          this.exportRunning = false;
          this.$emit('close-form');
        });
    },
  },
};
</script>
