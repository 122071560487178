<template>
  <v-expansion-panels
    v-if="notams.length"
    v-model="notamsExpanded"
    class="dense__expansion-panel dark"
  >
    <v-expansion-panel
      class="flight__status"
      :class="getStatusClass"
    >
      <v-expansion-panel-header>
        <v-row
          no-gutters
          align="center"
          class="dense__expansion-panel__header-content"
        >
          <div
            class="analysis-item-number"
            :class="getNumberColorClass"
          >
            {{ notams.length }}
          </div>
          <div class="ml-2 white--text">
            <span
              v-if="category === 'important'"
              v-translate
            >
              IMPORTANT NOTAM
            </span>
            <span
              v-else-if="category === 'interesting'"
              v-translate
            >
              INTERESTING NOTAM
            </span>
            <span
              v-else
              v-translate
            >
              IRRELEVANT NOTAM
            </span>
          </div>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mt-3">
        <div
          v-for="notam in notams"
          :key="notam.id"
          class="flight-notam-container"
        >
          <FlightNotam
            :notam="notam"
            :notamsExpanded="notamsExpanded"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { NOTAMS_CATEGORY } from '@/settings';

import FlightNotam from '@/components/Flights/FlightNotam.vue';

export default {
  name: 'FlightNotamsList',
  components: { FlightNotam },
  props: {
    category: String,
    notams: Array,
    flightStatus: String,
  },
  data() {
    return { notamsExpanded: undefined };
  },
  computed: {
    getStatusClass() {
      return { [this.flightStatus]: true };
    },
    getNumberColorClass() {
      return this.category === NOTAMS_CATEGORY.IMPORTANT ? 'error--text' : 'primary--text';
    },
  },
  methods: {},
};
</script>

<style
  lang="scss"
  scoped
>
.flight {
  background-color: $color-primary;
  &__status {
    color: $color-white;
    &.planned {
      background-color: $flight-planned-background-color;
    }
    &.processing {
      background-color: $flight-processing-background-color;
    }
    &.canceled {
      background-color: $flight-canceled-background-color;
    }
    &.error {
      background-color: $flight-error-background-color;
    }
    &.action_needed {
      background-color: $flight-action_needed-background-color;
    }
    &.archived {
      background-color: $flight-archived-background-color;
    }
    &.done {
      background-color: $flight-archived-background-color;
    }
    &.confirmed {
      background-color: $flight-confirmed-background-color;
    }
    &.clear {
      background-color: $flight-clear-background-color;
    }
    &.reserves {
      background-color: $flight-reserves-background-color;
    }
    &.refused {
      background-color: $flight-refused-background-color;
    }
  }
}
.v-expansion-panel {
  box-shadow: none;
}
.v-expansion-panel ::v-deep {
  border-radius: 2px;
  overflow: hidden;

  .v-expansion-panel__header {
    padding: 0 8px 0 0;
    min-height: 40px;
  }
}
.v-expansion-panel-content ::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
.flight-notam-container {
  margin-top: 12px;

  &:first-child {
    margin-top: 0px;
  }
}

</style>
