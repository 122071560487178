<template>
  <LayoutListingMap :displayButtonAdd="false">
    <template
      slot="listing"
      slot-scope="{display, toggleBiggerDisplay}"
    >
      <OpinionFilters
        :display="display"
        @toggle-filter="() => toggleBiggerDisplay()"
      />
      <OpinionListing
        class="pa-2"
        :display="display"
        @center-on-approval="() => centerOnApproval()"
        @center-on-area="(area) => centerOnArea(area)"
      />
    </template>
    <MapboxComponent
      slot="map"
      slot-scope="{display}"
      :display="display"
    />
  </LayoutListingMap>
</template>

<script>
import LayoutListingMap from '@/layouts/LayoutListingMap.vue';

import { ZOOM_TO_AREA_NS } from '@/store/map';
import { SET_OPINION_SELECTED_NS } from '@/store/opinions';

import Map from '@/components/Map/Map.vue';
import OpinionListing from '@/components/Opinions/OpinionListing.vue';
import OpinionFilters from '@/components/Opinions/OpinionFilters.vue';

export default {
  name: 'MapVisitor',
  components: {
    LayoutListingMap,
    MapboxComponent: Map,
    OpinionListing,
    OpinionFilters,
  },
  mounted() {
    const { idSelected } = this.$route.query;
    if (idSelected) {
      const opinionId = parseInt(idSelected, 10);
      this.$store.dispatch(SET_OPINION_SELECTED_NS, opinionId);
    }
  },
  methods: {
    centerOnApproval() {
      const opinionArea = this.$store.getters['opinions/opinionArea'];
      this.$store.dispatch(ZOOM_TO_AREA_NS, opinionArea);
    },
    centerOnArea(area) {
      this.$store.dispatch(ZOOM_TO_AREA_NS, area);
    },
  },
};

</script>
