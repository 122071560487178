<template>
  <v-card
    width="360"
    height="360"
    class="px-5 pt-5"
  >
    <v-card-title class="d-flex justify-center">
      <div class="d-flex align-center">
        <v-icon
          class="mr-3"
          color="primary"
        >
          mdi-tag
        </v-icon>
        <translate>My affiliate code</translate>
      </div>
    </v-card-title>
    <v-divider />
    <!-- Print the affiliate code -->
    <v-card-text>
      <v-row
        class="ma-5 mt-1"
        justify="center"
      >
        <span class="affiliate-code">
          {{ affiliateCode }}
        </span>
      </v-row>
      <span class="black--text font-italic">
        <translate>Share your affiliate code and save 25 euros HT on your next invoice.</translate>
        <br>
        <translate>This code is usable only for the first subscription of a customer.</translate>
      </span>
      <v-row
        justify="space-between"
        align="center"
        class="mt-5 mx-1"
      >
        <v-icon
          large
          @click="copyMailMission()"
        >
          mdi-content-copy
        </v-icon>
        <!-- For each social network, create a share link -->
        <ShareNetwork
          v-for="(network, index) in networks"
          :network="network.network"
          :key="index"
          :url="sharing.url"
          :title="sharing.title"
          :quote="sharing.quote"
          :hashtags="sharing.hashtags"
        >
          <v-icon
            x-large
            :color="network.color"
          >
            {{ network.icon }}
          </v-icon>
        </ShareNetwork>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as ShareNetwork from 'vue-social-sharing';

export default {
  name: 'AffiliateInformation',
  components: ShareNetwork,
  props: { affiliateCode: String },
  data() {
    return {
      sharing: {
        url: 'https://clearance.aero',
        title: this.$gettextInterpolate(
          this.$gettext(`Hello, find my promotion of 25€ to analyse drone flights on
            @ClearanceAero: %{affiliateCode}. Enjoy it!`),
          { affiliateCode: this.affiliateCode },
        ),
        quote: this.$gettextInterpolate(
          this.$gettext(`Hello, find my promotion of 25€ to analyse drone flights on Clearance:
            %{affiliateCode}. Enjoy it!`),
          { affiliateCode: this.affiliateCode },
        ),
        hashtags: 'ClearanceDrone,UAV,pilotededrone ',
        twitterUser: 'ClearanceAero',
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook-box', color: '#1877f2' },
        { network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: '#25d366' },
      ],
    };
  },
  methods: {
    copyMailMission() {
      const elem = document.createElement('textarea');
      elem.value = this.affiliateCode;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);
      this.showMessage(
        this.$gettext('Your affiliate code have been copied in the clipboard'),
        'success',
      );
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.affiliate-code {
  color: $color-primary;
  font-size: 30px;
  font-weight: 800;
  text-align: center;
}

</style>
