<template>
  <v-expansion-panels
    v-model="detailsPanelExpanded"
    light
    flat
    class="dense__expansion-panel"
  >
    <v-expansion-panel expand-icon="keyboard_arrow_down">
      <!-- Header -->
      <v-expansion-panel-header>
        <span class="dense__expansion-panel__header-content">
          <v-icon
            class="pr-2"
            color="info"
          >
            mdi-clipboard-text
          </v-icon>
          <span v-translate>Flight details</span>
        </span>
      </v-expansion-panel-header>
      <!-- Content -->
      <v-expansion-panel-content>
        <div class="details-card pt-2">
          <!-- Flight details -->
          <v-row no-gutters>
            <!-- Number -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                FLIGHT NUMBER
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.id }}
              </span>
            </v-col>
            <!-- Name -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                NAME
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.name }}
              </span>
            </v-col>
            <!-- Dates -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                DATES
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.date_start | dateShort }} - {{ flight.date_end | dateShort }}
              </span>
            </v-col>
            <!-- In/Off sight -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                FLIGHT
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <translate v-if="flight.pilot_in_sight">in sight</translate>
              <translate v-else>off sight</translate>
            </v-col>
            <!-- Duration -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                EXPECTED DURATION
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.expected_duration | hour }}
              </span>
            </v-col>
            <!-- Hours -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                HOUR
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.time_start | hour }} - {{ flight.time_end | hour }}
              </span>
            </v-col>
            <!-- Max elevation -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                MAX ELEVATION
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.elevation | meter }}
              </span>
            </v-col>
            <!-- Max height -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                MAX HEIGHT
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.flying_height | meter }}
              </span>
            </v-col>
            <!-- Latitude -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                LATITUDE
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ latitude | coord }} ({{latitude | coordDMS(false)}})
              </span>
            </v-col>
            <!-- Longitude -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                LONGITUDE
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ longitude | coord }} ({{longitude | coordDMS(true)}})
              </span>
            </v-col>
          </v-row>
          <!-- Zones details -->
          <v-row no-gutters>
            <hr class="separator" />
            <!-- Address -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                ADDRESS
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="10"
            >
              <span v-if="flight.address">
                {{ flight.address }}
              </span>
              <span v-else>
                {{ flight.commune }}
              </span>
            </v-col>
            <!-- Flight areas -->
            <template v-for="(area, index) in flight.areas">
              <hr
                :key="`separator-${index}`"
                class="separator"
              />
              <v-row
                no-gutters
                :key="`area-${index}`"
              >
                <!-- Flight area name -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    ZONE
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <div class="d-flex align-center">
                    <span>
                      {{ area.name }}
                    </span>
                    <v-btn
                      icon
                      small
                      class="flight-listing__action-button"
                      @click="centerOnFlightArea(area.id)"
                      @mouseenter="setFlightAreaHover(area.id)"
                      @mouseleave="setFlightAreaHover(null)"
                    >
                      <v-icon>mdi-map-marker-outline</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <!-- Flight area height -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    HEIGHT
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <span>
                    {{ area.height | meter }}
                  </span>
                </v-col>
                <!-- Flight area elevation -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    ELEVATION
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <span>
                    {{ area.elevation | meter }}
                  </span>
                </v-col>
                <!-- Flight area surface -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    SURFACE
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <span>
                    {{ area.surface | squareMeter }}
                  </span>
                </v-col>
                <!-- Flight area extent -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    EXTENT
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <span>
                    {{ area.max_extent | scaleMeter }}
                  </span>
                </v-col>
              </v-row>
            </template>
          </v-row>
          <!-- Internal note -->
          <v-row
            v-if="flight.details"
            no-gutters
          >
            <hr class="separator" />
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                INTERNAL NOTE
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="10"
            >
              <div class="flight-details--value multilines">
                {{ flight.details }}
              </div>
            </v-col>
          </v-row>
          <!-- Pilots -->
          <template v-for="pilot in flight.pilots">
            <hr
              :key="pilot.id"
              class="separator"
            />
            <v-row
              no-gutters
              :key="'pilot-' + pilot.id"
            >
              <!-- Pilot name -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  PILOT
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ pilot.full_name }}
                </span>
              </v-col>
              <!-- Pilot email -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  EMAIL
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ pilot.email }}
                </span>
              </v-col>
              <!-- Pilot phone number -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  PHONE NUMBER
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ pilot.phone_number | phone }}
                </span>
              </v-col>
            </v-row>
          </template>
          <!-- Drones -->
          <template v-for="(drone) in flight.drones">
            <hr
              :key="drone.id"
              class="separator"
            />
            <v-row
              no-gutters
              :key="'drone-' + drone.id"
            >
              <!-- Drone label -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  DRONE
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ drone.label }}
                </span>
              </v-col>
              <!-- Drone manufacturer -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  MANUFACTURER
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ drone.manufacturer }}
                </span>
              </v-col>
              <!-- Drone model -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  MODEL
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ drone.model_name }}
                </span>
              </v-col>
              <!-- Drone category -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  CATEGORY
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ getDroneCategory(drone.category) }}
                </span>
              </v-col>
              <!-- Drone weight -->
              <template v-if="drone.weight">
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    WEIGHT
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <span>
                    {{ drone.weight }} kg
                  </span>
                </v-col>
              </template>
            </v-row>
          </template>
          <!-- Sub exploitants -->
          <template v-if="hasSubExploitant">
            <hr class="separator" />
            <v-row
              no-gutters
              align="center"
            >
              <v-col
                cols="5"
                sm="2"
              >
                <span class="details-card__label text-uppercase">
                  {{ entityName.singular }}
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <v-chip
                  color="grey lighten-3"
                  class="mr-1"
                >
                  {{ subExploitant }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
          <!-- Tags -->
          <v-row
            v-if="isUserEnterprise && flight.tags && flight.tags.length"
            no-gutters
          >
            <hr class="separator" />
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                TAGS
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="10"
            >
              <template v-for="(tag, index) in flight.tags">
                <v-chip
                  :key="index"
                  color="grey lighten-3"
                  class="mr-1"
                >
                  {{ tag }}
                </v-chip>
              </template>
            </v-col>
          </v-row>
          <!-- Mail resumed -->
          <v-row no-gutters>
            <hr class="separator" />
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                EMAIL APPROVAL REQUEST PROJECT
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="10"
            >
              <div
                class="multilines"
                :class="{
                  'text-truncate': !readMore,
                  'cursor-pointer' : !readMore
                  }"
                @click="readMore=true"
              >
                {{ mailResumed }}
              </div>
            </v-col>
          </v-row>
        </div>
        <v-spacer />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { filterCoordDMS } from '@/filters/coord';
import { filterPhoneNumber } from '@/filters/phone';

import { DRONE_CATEGORIES } from '@/settings';

import { SET_FLIGHT_AREA_HOVER_NS, ZOOM_TO_AREA_NS } from '@/store/map';

export default {
  name: 'FlightDetails',
  props: {
    flight: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      detailsPanelExpanded: undefined,
      readMore: false,
      screenshotLoading: false,
    };
  },
  computed: {
    mailResumed() {
      // Format flight date to human readable date
      const dateStart = this.dateLocalFormat(this.flight.date_start);
      const dateEnd = this.dateLocalFormat(this.flight.date_end);
      const coordinateE = this.longitude ? this.longitude.toFixed(4) : '';
      const coordinateN = this.latitude ? this.latitude.toFixed(4) : '';
      const latitudeDMS = filterCoordDMS(this.latitude, false);
      const longitudeDMS = filterCoordDMS(this.longitude, true);
      const mailPartVol = this.$gettextInterpolate(
        this.$gettext(`Bonjour,<br><br>Je me permets de vous contacter pour une demande
          d'autorisation de travail aérien par drone.<br><br>Voici les détails du vol prévu :<br>
          <br>Exploitant : %{company}<br>Adresse : %{address}<br>Coordonnées géographiques :
          %{coordinateN}N %{coordinateE}E (%{latitudeDMS} %{longitudeDMS})<br>Élévation du sol au
          centre de la zone : %{elevation} m<br>Hauteur sol maximale : %{flyingHeight} m<br><br>
          Début de la plage de dates de vol : %{dateStart}<br>Fin de la plage de dates de vol :
          %{dateEnd}<br>Début de la plage horaire : %{timeStart}<br>Fin de la plage horaire :
          %{timeEnd}<br>Durée prévue : %{duration}<br><br>`),
        {
          company: this.flight.company_name,
          address: this.flight.address,
          coordinateN,
          coordinateE,
          latitudeDMS,
          longitudeDMS,
          elevation: this.flight.elevation || '',
          flyingHeight: this.flight.flying_height,
          dateStart,
          dateEnd,
          timeStart: this.flight.time_start,
          timeEnd: this.flight.time_end,
          duration: this.flight.expected_duration,
        },
        true,
      );

      // Add drones details
      let mailPartDrone = '';
      if (this.flight.drones.length > 1) {
        mailPartDrone += this.$gettext('<br>Drones list of the flight:<br>');
      }
      this.flight.drones.forEach((drone) => {
        mailPartDrone += this.$gettextInterpolate(
          this.$gettext('<br>Drone: %{label}<br>'),
          { label: drone.label },
          true,
        );
      });
      if (mailPartDrone !== '') {
        mailPartDrone += '<br>';
      }

      // Creation contact for each pilot
      let mailPartPilot = '';
      if (this.flight.pilots.length > 1) {
        mailPartPilot += this.$gettext('<br>Liste des télépilotes de la mission :<br>');
      }
      this.flight.pilots.forEach((pilot) => {
        mailPartPilot += this.$gettextInterpolate(
          this.$gettext(`<br>Télépilote : %{name}<br>
            Numéro de téléphone du télépilote : %{phoneNumber}<br>
            Email du télépilote : %{email}<br>`),
          {
            name: pilot.full_name,
            phoneNumber: filterPhoneNumber(pilot.phone_number),
            email: pilot.email,
          },
          true,
        );
      });
      if (mailPartPilot === '') {
        mailPartPilot = this.$gettext(`<br>[ Indiquez ici les coordonnées permettant de joindre le
          pilote (nom, mail et numéro de téléphone) ]<br>`);
      }
      mailPartPilot += this.$gettext(`<br>Je suis bien évidemment à votre disposition si vous
        souhaitez des renseignements supplémentaires pour étudier cette demande.<br><br>
        Cordialement,`);
      let mail = mailPartVol + mailPartDrone + mailPartPilot;
      mail = mail.replaceAll('\n', ' ');
      mail = mail.replaceAll('<br>', '\n');
      // Delete the indentation in the multiline string
      return mail.replace(/  +/g, ' ');
    },
    isUserEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    latitude() {
      return this.flight.lat;
    },
    longitude() {
      return this.flight.lng;
    },
    hasSubExploitant() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    subExploitant() {
      if (this.hasSubExploitant) {
        const subExploitant = this.$store.state.authentication.userSubExploitants.find(
          (s) => s.id === this.flight.sub_exploitant,
        );
        return subExploitant?.name || '';
      }
      return '';
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  methods: {
    getDroneCategory(value) {
      const category = DRONE_CATEGORIES.find((c) => c.value === value);
      if (category) {
        return category.label;
      }
      return value;
    },
    dateLocalFormat(dateToFormat) {
      const dateFormatted = new Date(dateToFormat);
      // Handle timezones differences
      dateFormatted.setMinutes(dateFormatted.getMinutes() + dateFormatted.getTimezoneOffset());
      const optionDate = { year: 'numeric', month: 'long', day: 'numeric' };
      return dateFormatted.toLocaleDateString(this.$store.getters['application/currentLanguage'], optionDate);
    },
    centerOnFlightArea(areaId) {
      const features = this.$store.getters['flights/flightAreasSelected'];
      const area = features.find((feature) => areaId === feature.id);
      this.$store.dispatch(ZOOM_TO_AREA_NS, area.geometry);
    },
    setFlightAreaHover(areaId) {
      this.$store.dispatch(SET_FLIGHT_AREA_HOVER_NS, areaId);
    },
  },
};
</script>
