<template>
  <!-- Flight documents expansion panel -->
  <div>
    <v-expansion-panels
      v-model="panelExpanded"
      light
      flat
      class="dense__expansion-panel mt-2"
    >
      <v-expansion-panel class="documents">
        <v-expansion-panel-header>
          <div class="row flex-nowrap no-gutters align-center">
            <span class="dense__expansion-panel__header-content flex-grow-1">
              <v-icon
                class="mr-2"
                color="info"
              >
                mdi-file-document-outline
              </v-icon>
              <translate>Documents (exploitation, drones, pilots, mission)</translate>
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2">
          <!-- Exploitant documents -->
          <template v-if="exploitantDocuments.length">
            <RelatedFlightDocuments
              ref="exploitantRelatedDocuments"
              key="exploitantDocuments"
              type="exploitant"
              :documents="exploitantDocuments"
            />
            <v-divider />
          </template>
          <!-- Drones documents -->
          <template v-if="dronesWithDocuments.length">
            <template v-for="(drone, index) in dronesWithDocuments">
              <RelatedFlightDocuments
                ref="droneRelatedDocuments"
                :key="`${index}-drone`"
                type="drone"
                :documents="drone.documents"
                :name="drone.label"
              />
            </template>
            <v-divider />
          </template>
          <!-- Pilots documents -->
          <template v-if="pilotsWithDocuments.length">
            <template v-for="(pilot, index) in pilotsWithDocuments">
              <RelatedFlightDocuments
                ref="pilotRelatedDocuments"
                :key="`${index}-pilot`"
                type="pilot"
                :documents="pilot.documents"
                :name="pilot.full_name"
              />
            </template>
            <v-divider />
          </template>
          <!-- Flight Approvals Mission Sheet -->
          <template v-if="approvalsDocuments.length">
            <template v-for="(approvalDocuments, index) in approvalsDocuments">
              <FlightApprovalDocuments
                ref="approvalsDocuments"
                :key="`${index}-approval`"
                :constraintName="approvalDocuments.constraint_name"
                :approvalIdentifier="approvalDocuments.approval_identifier"
                :missionSheet="approvalDocuments.approval_mission_sheet"
                :eventsDocuments="approvalDocuments.approval_events_documents"
                class="my-2"
              />
            </template>
            <v-divider />
          </template>
          <!-- Flight documents -->
          <v-expansion-panels
            v-model="flightDocumentsPanel"
            light
            class="my-2"
          >
            <v-expansion-panel expand-icon="keyboard_arrow_down">
              <v-expansion-panel-header>
                <span class="dense__expansion-panel__header-content">
                  {{ texts.flightsDocumentsTitle }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-2">
                <!-- Flight constraint sheet -->
                <div
                  key="constraintSheet"
                  class="mt-2"
                >
                  <span
                    class="info--text text--lighten-1 pl-2 mr-1"
                    v-translate
                  >
                    Mission file:
                  </span>
                  <div
                    v-if="flightConstraintSheetAvailable"
                    key="constraintSheetAvailable"
                  >
                    <v-chip class="documents__chip caption">
                      <span
                        class="documents__link-label"
                        @click="downloadConstraintSheet(flight.id)"
                      >
                        {{ flightConstraintSheetFilename }}
                      </span>
                      <span class="font-italic info--text text--lighten-2 pl-1">
                        <translate>generated:</translate>
                        <span>
                          {{ flightConstraintSheetGenerationDate }}
                        </span>
                      </span>
                    </v-chip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          depressed
                          x-small
                          fab
                          :disabled="flightConstraintSheetAsked"
                          :loading="generationLoading"
                          @click.stop.prevent="showFlightConstraintSheetDialog=true"
                        >
                          <v-icon class="mdi-18px">
                            {{ detailsConstraintSheet.buttonIcon }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ detailsConstraintSheet.description }}
                      </span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="flightConstraintSheet.to_update"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="warning"
                          class="ml-1"
                        >
                          mdi-alert-outline
                        </v-icon>
                      </template>
                      <span>
                        {{ texts.updateConstraintSheet }}
                      </span>
                    </v-tooltip>
                  </div>
                  <div
                    v-else
                    key="constraintSheetNotAvailable"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          rounded
                          depressed
                          max-height="32px"
                          :disabled="flightConstraintSheetAsked"
                          :loading="generationLoading"
                          @click.stop.prevent="showFlightConstraintSheetDialog=true"
                        >
                          <div class="d-flex align-center">
                            <v-icon class="mr-1 mdi-18px">
                              {{ detailsConstraintSheet.buttonIcon }}
                            </v-icon>
                            <span class="caption">
                              {{ detailsConstraintSheet.buttonText }}
                            </span>
                          </div>
                        </v-btn>
                      </template>
                      <span>
                        {{ detailsConstraintSheet.description }}
                      </span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="flightConstraintSheetFailed"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="error"
                          class="ml-1"
                        >
                          mdi-alert
                        </v-icon>
                      </template>
                      <translate>Mission file genetation failed, you can try it later.</translate>
                    </v-tooltip>
                  </div>
                </div>
                <!-- --------------- Uploaded documents ---------------- -->
                <div class="mt-2">
                  <template v-for="document in flightDocuments">
                    <v-chip
                      :key="document.id"
                      class="documents__chip caption"
                      close
                      @click:close="documentToDelete=document.id;deletionDialog=true"
                    >
                      <span
                        class="documents__link-label"
                        @click="downloadDocument(document.id, document.name)"
                      >
                        {{ document.name }}
                      </span>
                    </v-chip>
                  </template>
                </div>
                <!-- --------------- Upload new document ---------------- -->
                <div
                  :class="{'disabled':flightDocuments.length>=maxNumberDocuments}"
                  class="documents__input light"
                >
                  <template v-if="!uploadLoading">
                    <v-tooltip
                      :disabled="flightDocuments.length<maxNumberDocuments"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-icon
                            class="mdi-rotate-270 pr-1"
                            color="primary"
                            :disabled="flightDocuments.length>=maxNumberDocuments"
                          >
                            mdi-attachment
                          </v-icon>
                          <label
                            for="file-input"
                            class="documents__input-label"
                          >
                            <translate>UPLOAD DOCUMENT TO THE MISSION</translate>
                          </label>
                        </div>
                      </template>
                      <span v-if="flightDocuments.length>=maxNumberDocuments">
                        <translate :translate-params="{number: maxNumberDocuments}">
                          Only %{number} documents can be uploaded.
                        </translate>
                      </span>
                    </v-tooltip>
                    <input
                      id="file-input"
                      :disabled="flightDocuments.length>=maxNumberDocuments"
                      type="file"
                      class="hidden"
                      @change="(event) => uploadDocument(event.target.files[0])"
                    >
                    <span
                      v-if="sizeExeeded"
                      class="error--text font-italic"
                    >
                      &nbsp;&nbsp;
                      <translate :translate-params="{size: maxDocumentSize}">
                        File should be under %{size}Mb
                      </translate>
                    </span>
                  </template>
                  <v-progress-linear
                    v-else
                    :indeterminate="true"
                    class="documents__input-loader"
                    color="primary"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
          <!-- Download all documents  -->
          <v-tooltip
            top
            max-width="680px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="success"
                class="main-button__font truncated_button"
                block
                :loading="downloadLoading"
                @click.native.stop="downloadDialog=true"
              >
                <v-icon class="pr-2">
                  mdi-download
                </v-icon>
                <span>
                  {{ texts.downloadDocumentsLabel }}
                </span>
              </v-btn>

            </template>
            <span>
              {{ texts.downloadDocumentsTooltip }}
            </span>
          </v-tooltip>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Flight contraint sheet form -->
    <v-dialog
      v-model="showFlightConstraintSheetDialog"
      max-width="700"
      persistent
      :retain-focus="false"
    >
      <FlightConstraintSheetForm
        :flights="[flight]"
        @close="showFlightConstraintSheetDialog=false"
      />
    </v-dialog>
    <!-- --------------- Confirm delete document ---------------- -->
    <v-dialog
      v-model="deletionDialog"
      max-width="420"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Confirmation required</translate>
        </v-card-title>
        <v-card-text>
          <translate>Are you sure you want to delete this document?</translate>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="documentToDelete=null;deletionDialog=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            :loading="deletionLoading"
            text
            color="primary"
            @click="deleteDocument()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- -------- Download documents -------- -->
    <v-dialog
      v-model="downloadDialog"
      width="650"
      persistent
      :retain-focus="false"
    >
      <MyDocuments
        :downloadDocuments="true"
        @close-mydocuments="downloadDialog=false"
        @choose-mydocuments="(documents) => downloadZipDocuments(documents)"
      />
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import { MAX_DOCUMENT_SIZE_MB, MAX_NUMBER_OF_DOCUMENTS } from '@/settings';
import { DONE } from '@/store/status';

import {
  ADD_FLIGHT_DOCUMENT_NS,
  DELETE_FLIGHT_DOCUMENT_NS,
} from '@/store/flights';

import FlightConstraintSheetForm from '@/components/Flights/Documents/FlightConstraintSheetForm.vue';
import RelatedFlightDocuments from '@/components/Flights/Documents/RelatedFlightDocuments.vue';
import FlightApprovalDocuments from '@/components/Flights/Documents/FlightApprovalDocuments.vue';
import MyDocuments from '@/components/MyDocuments/MyDocuments.vue';

export default {
  name: 'FlightDocuments',
  components: {
    FlightConstraintSheetForm,
    RelatedFlightDocuments,
    FlightApprovalDocuments,
    MyDocuments,
  },
  props: {
    flightId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      panelExpanded: undefined,
      flightDocumentsPanel: 0,
      uploadLoading: false,
      sizeExeeded: false,
      documentToDelete: null,
      deletionDialog: false,
      deletionLoading: false,
      downloadDialog: false,
      downloadLoading: false,
      maxDocumentSize: MAX_DOCUMENT_SIZE_MB.flight,
      maxNumberDocuments: MAX_NUMBER_OF_DOCUMENTS.flight,
      generationLoading: false,
      showFlightConstraintSheetDialog: false,
    };
  },
  computed: {
    flight() {
      return this.$store.getters['flights/flightSelected'];
    },
    flightName() {
      return this.flight?.name || '';
    },
    flightDocuments() {
      return this.flight?.documents || [];
    },
    flightConstraintSheet() {
      return this.flight?.constraint_sheet;
    },
    flightConstraintSheetAsked() {
      return ['todo', 'processing'].includes(this.flightConstraintSheet?.status);
    },
    flightConstraintSheetAvailable() {
      return this.flightConstraintSheet?.status === 'done';
    },
    flightConstraintSheetFailed() {
      return this.flightConstraintSheet?.status === 'failed';
    },
    flightConstraintSheetFilename() {
      return this.$gettextInterpolate(
        this.$gettext('%{id}_dossier_mission.docx'),
        { id: this.flight?.id || '' },
      );
    },
    flightConstraintSheetGenerationDate() {
      if (this.flightConstraintSheetAvailable) {
        return this.datetimeLocalFormat(this.flightConstraintSheet.updated_at);
      }
      return '';
    },
    exploitantDocuments() {
      return this.$store.getters['exploitants/documents'];
    },
    drones() {
      return this.flight?.drones || [];
    },
    dronesWithDocuments() {
      if (this.drones.length) {
        return this.drones.filter((d) => d.documents.length);
      }
      return [];
    },
    pilots() {
      return this.flight?.pilots || [];
    },
    pilotsWithDocuments() {
      if (this.pilots.length) {
        return this.pilots.filter((p) => p.documents.length);
      }
      return [];
    },
    approvalsDocuments() {
      return this.$store.getters['flights/approvalsDocuments'].filter(
        (a) => a.approval_mission_sheet?.status === DONE || a.approval_events_documents?.length,
      );
    },
    texts() {
      const numberOfDocuments = (
        this.flightConstraintSheetAvailable ? (this.flightDocuments.length + 1)
          : this.flightDocuments.length
      );
      return {
        flightsDocumentsTitle: (
          numberOfDocuments
            ? this.$gettextInterpolate(
              this.$ngettext(
                '%{n} Document of the mission',
                '%{n} Documents of the mission',
                numberOfDocuments,
              ),
              { n: numberOfDocuments },
            )
            : this.$gettext('Documents of the mission')
        ),
        downloadDocumentsLabel: this.$gettext('DOWNLOAD ALL DOCUMENTS'),
        downloadDocumentsTooltip: this.$gettext('Download all documents of this section in a zip'),
        updateConstraintSheet: this.$gettext(`You need to update your constraint sheet because of
          some updates on your flight`),
      };
    },
    detailsConstraintSheet() {
      if (this.flightConstraintSheetAsked) {
        return {
          description: this.$gettext('Constraint sheet generating...'),
          buttonIcon: 'mdi-file-word',
          buttonText: this.$gettext('Constraint sheet generating...'),
        };
      }
      if (this.flightConstraintSheetAvailable) {
        return {
          description: this.$gettext('Update constraint sheet'),
          buttonIcon: 'mdi-refresh',
          buttonText: '',
        };
      }
      return {
        description: this.$gettext('Generate constraint sheet'),
        buttonIcon: 'mdi-file-word',
        buttonText: this.$gettext('Generate constraint sheet'),
      };
    },
  },
  watch: {
    panelExpanded(newValue) {
      if (newValue === undefined) {
        const exploitantPanel = this.$refs.exploitantRelatedDocuments;
        if (exploitantPanel) {
          exploitantPanel.closePanel();
        }
        const dronePanels = this.$refs.droneRelatedDocuments;
        if (dronePanels) {
          dronePanels.forEach((panel) => {
            panel.closePanel();
          });
        }
        const pilotPanels = this.$refs.pilotRelatedDocuments;
        if (pilotPanels) {
          pilotPanels.forEach((panel) => {
            panel.closePanel();
          });
        }
      } else {
        this.flightDocumentsPanel = 0;
      }
    },
  },
  methods: {
    uploadDocument(file) {
      this.sizeExeeded = false;
      if (file) {
        if (file.size > this.maxDocumentSize * 1000000) {
          this.sizeExeeded = true;
          return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('related_id', this.flightId);
        formData.append('related_type', 'flight');
        formData.append('category', '');
        this.uploadLoading = true;
        this.sizeExeeded = false;
        APIService.uploadDocument(formData)
          .then(({ data }) => {
            this.$store.dispatch(
              ADD_FLIGHT_DOCUMENT_NS, { flightId: this.flightId, document: data },
            )
              .then(() => {
                this.showMessage(this.$gettext('Document upload successfully.'), 'success');
              });
          })
          .finally(() => {
            this.uploadLoading = false;
          });
      }
    },
    downloadDocument(documentId, documentName) {
      APIService.downloadDocument(documentId)
        .then(({ data }) => {
          this.navigatorDownloadDocuments(data, documentName);
        });
    },
    deleteDocument() {
      this.deletionLoading = true;
      APIService.deleteDocument(this.documentToDelete)
        .then(() => {
          this.$store.dispatch(
            DELETE_FLIGHT_DOCUMENT_NS,
            { flightId: this.flightId, documentId: this.documentToDelete },
          )
            .then(() => {
              this.showMessage(this.$gettext('Document deleted.'), 'success');
            });
        })
        .finally(() => {
          this.documentToDelete = null;
          this.deletionLoading = false;
          this.deletionDialog = false;
        });
    },
    datetimeLocalFormat(datetimeToFormat) {
      const datetimeFormatted = new Date(datetimeToFormat);
      const optionDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return datetimeFormatted.toLocaleString(
        this.$store.getters['application/currentLanguage'],
        optionDate,
      );
    },
    downloadConstraintSheet(flightId) {
      APIService.downloadFlightWordSheet(flightId)
        .then(({ data }) => {
          this.navigatorDownloadDocuments(data, this.flightConstraintSheetFilename);
        });
    },
    navigatorDownloadDocuments(data, filename) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    documentsSelectedForDownload(documents) {
      const data = {
        documents: [],
        approvals_mission_sheet: [],
        constraint_sheet: false,
      };
      documents.forEach((document) => {
        if (document.type === 'constraintSheet') {
          data.constraint_sheet = true;
        } else if (document.type === 'missionSheet') {
          data.approvals_mission_sheet.push(document.documentId);
        } else {
          data.documents.push(document.documentId);
        }
      });
      data.documents = JSON.stringify(data.documents);
      data.approvals_mission_sheet = JSON.stringify(data.approvals_mission_sheet);
      return data;
    },
    downloadZipDocuments(documents) {
      this.downloadLoading = true;
      const params = this.documentsSelectedForDownload(documents);
      APIService.downloadFlightZipDocuments(this.flightId, params)
        .then(({ data }) => {
          this.navigatorDownloadDocuments(data, `${this.flight.id}_${this.flightName}.zip`);
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
  },
};
</script>
