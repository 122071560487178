<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="py-2 px-3"
          :class="highlighted"
        >
          <v-img
            :src="alphaTangoLogoPath"
            max-width="24"
          />
          <span class="pl-2">
            <span
              v-translate="{count: notificationsCount}"
              :translate-n="notificationsCount"
              translate-plural="%{ count } AlphaTango Notifications"
            >
              %{ count } AlphaTango Notification
            </span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="notification-container">
          <v-list
            dense
            class="pt-0"
          >
            <template v-for="(notification, index) in notifications">
              <div
                :key="index"
                class="px-3"
              >
                <v-divider class="ma-0" />
              </div>
              <v-list-item
                :key="notification.id"
                class="pa-0 mx-3"
              >
                <v-list-item-content>
                  <v-row
                    align="center"
                    no-gutters
                    class="py-2"
                  >
                    <v-col
                      :cols="getSizeColumn[0]"
                      class="px-3"
                    >
                      <v-row
                        align="center"
                        no-gutters
                      >
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <v-row
                            align="center"
                            no-gutters
                          >
                            <v-icon
                              small
                              :color="getIconColor(notification.status)"
                            >
                              {{ getIcon(notification.status) }}
                            </v-icon>
                            <span
                              class="notification-status pl-2"
                              :class="`${getIconColor(notification.status)}--text`"
                            >
                              {{ getNotificationStatusText(notification.status) }}
                            </span>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="9"
                          lg="10"
                          class="notification-dates"
                          :class="paddingForPrefectureInterface"
                        >
                          {{ getNotificationDatesText(notification) }}
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      :cols="getSizeColumn[1]"
                      class="pr-3"
                    >
                      <v-row
                        no-gutters
                        align="center"
                        justify="end"
                      >
                        <template v-if="!isUserPrefecture">
                          <!-- ---- Cancel AlphaTango Prefecture Notification button ----- -->
                          <v-tooltip
                            bottom
                            v-if="notification.status !== 'cancelled'"
                          >
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                <v-btn
                                  icon
                                  x-small
                                  @click.stop="openCancelNotificationDialog(notification.id)"
                                  :disabled="new Date(notification.datetime_start) <= new Date()"
                                >
                                  <v-icon small>mdi-cancel</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <template v-if="new Date(notification.datetime_start) <= new Date()">
                              <span v-translate>This notification can no longer be updated</span>
                            </template>
                            <template v-else>
                              <span v-translate>Cancel notification</span>
                            </template>
                          </v-tooltip>
                          <!-- ---- AlphaTango Prefecture Notification link ----- -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                :href="notification.alpha_tango_url"
                                target="_blank"
                                class="text-decoration-none pl-2"
                                v-on="on"
                              >
                                <v-icon small>mdi-launch</v-icon>
                              </a>
                            </template>
                            <span v-translate>Open on AlphaTango</span>
                          </v-tooltip>
                        </template>
                        <!-- ---- AlphaTango Prefecture Notification link ----- -->
                        <v-tooltip
                          bottom
                          v-if="notification.document"
                        >
                          <template v-slot:activator="{ on }">
                            <div
                              v-on="on"
                              class="pl-2"
                            >
                              <v-btn
                                icon
                                x-small
                                @click.stop="downloadDocument(notification.document)"
                                :loading="downloadDocumentLoading"
                              >
                                <v-icon small>mdi-download</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span v-translate>Download pdf</span>
                        </v-tooltip>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- ---- Cancel AlphaTango Prefecture Notification popup ----- -->
    <v-dialog
      v-model="showCancelAlphaTangoPrefectureDialog"
      width="700px"
      :retain-focus="false"
      persistent
    >
      <NotifAlphaTangoPrefectureCancel
        :flightId="flightId"
        :notificationId="notificationIdToCancel"
        @close-form="() => closeCancelNotificationDialog()"
      />
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import APIService from '@/services/api';

import NotifAlphaTangoPrefectureCancel from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoPrefectureCancel.vue';

const NOTIFICATION_ICON_STYLE = {
  sent: {
    icon: 'mdi-checkbox-marked-circle-outline',
    color: 'success',
  },
  outdated: {
    icon: 'mdi-alert-circle-outline',
    color: 'warning',
  },
  cancelled: {
    icon: 'mdi-close-circle-outline',
    color: 'grey',
  },
};

export default {
  name: 'NotifAlphaTangoPrefectureList',
  components: { NotifAlphaTangoPrefectureCancel },
  props: {
    flightId: Number,
    notifications: Array,
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showCancelAlphaTangoPrefectureDialog: false,
      notificationIdToCancel: null,
      downloadDocumentLoading: false,
    };
  },
  computed: {
    notificationsCount() {
      return this.notifications.length;
    },
    alphaTangoLogoPath() {
      return `${process.env.BASE_URL}alphatango_orange_100.png`;
    },
    notificationStatusTranslations() {
      return {
        sent: this.$gettext('Sent'),
        outdated: this.$gettext('Outdated'),
        cancelled: this.$gettext('Cancelled'),
      };
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    getSizeColumn() {
      return [
        this.isUserPrefecture ? 11 : 10,
        this.isUserPrefecture ? 1 : 2,
      ];
    },
    paddingForPrefectureInterface() {
      return this.isUserPrefecture ? 'pl-4' : '';
    },
    highlighted() {
      if (this.highlight) {
        return 'highlight';
      }
      return '';
    },
  },
  methods: {
    getIcon(status) {
      const style = NOTIFICATION_ICON_STYLE[status];
      if (style) {
        return style.icon;
      }
      return null;
    },
    getIconColor(status) {
      const style = NOTIFICATION_ICON_STYLE[status];
      if (style) {
        return style.color;
      }
      return null;
    },
    getNotificationStatusText(status) {
      return this.notificationStatusTranslations[status];
    },
    getNotificationDatesText(notification) {
      const sendDate = format(
        new Date(notification.notification_datetime),
        'dd MMMM yyyy', { locale: fr },
      );
      const flightDateStart = format(
        new Date(notification.datetime_start),
        'dd MMMM yyyy', { locale: fr },
      );
      const flightDateEnd = format(
        new Date(notification.datetime_end),
        'dd MMMM yyyy', { locale: fr },
      );
      if (flightDateStart === flightDateEnd) {
        return this.$gettextInterpolate(
          this.$gettext('Sent on %{sendDate} to fly on %{flightDate}'),
          { sendDate, flightDate: flightDateStart },
        );
      }
      return this.$gettextInterpolate(
        this.$gettext(
          'Sent on %{sendDate} to fly between %{flightDateStart} and %{flightDateEnd}',
        ),
        { sendDate, flightDateStart, flightDateEnd },
      );
    },
    openCancelNotificationDialog(notificationId) {
      this.showCancelAlphaTangoPrefectureDialog = true;
      this.notificationIdToCancel = notificationId;
    },
    closeCancelNotificationDialog() {
      this.showCancelAlphaTangoPrefectureDialog = false;
      this.notificationIdToCancel = null;
    },
    downloadDocument(notificationDocument) {
      this.downloadDocumentLoading = true;
      APIService.downloadDocument(notificationDocument.id)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', notificationDocument.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.downloadDocumentLoading = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.notification-status::before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  opacity: .12;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
}
.notification-dates {
  opacity: 0.75;
}
.disabled {
  cursor: not-allowed;
  color: gray
}
.v-expansion-panel-content ::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
.notification-container {
  max-height: 500px;
  overflow-y: scroll
}

</style>
