<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate
        :translate-n="flights.length"
        translate-plural="Generate flights constraint sheet"
      >
        Generate flight constraint sheet
      </translate>
    </v-card-title>
    <v-card-text>
      <span v-html="texts.textConfirmScenario" />
      <v-select
        class="py-4"
        :label="$gettext('Confirm scenario')"
        v-model="scenarioDeclared"
        :items="scenarioItems"
        hide-details
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        text
        color="primary"
        :disabled="!scenarioDeclared"
        :loading="loadingGeneration"
        @click="generation()"
      >
        <translate>Generate</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { SET_FLIGHT_CONSTRAINT_SHEET_STATUS_NS } from '@/store/flights';

export default {
  name: 'FlightConstraintSheetForm',
  props: {
    flights: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      scenarioDeclared: null,
      scenarioItems: [
        { text: 'Scenario 1', value: 's1' },
        { text: 'Scenario 2', value: 's2' },
        { text: 'Scenario 3', value: 's3' },
      ],
      loadingGeneration: false,
    };
  },
  computed: {
    texts() {
      return {
        textConfirmScenario: this.$gettext(`To generate the flight constraint sheet you need to
          confirm the mission scenario. More informations are available in the DGAC <a
          href="https://www.ecologie.gouv.fr/sites/default/files/Guide_categorie_Specifique_0.pdf"
          >guide</a>`),
      };
    },
    isSingleFlight() {
      return this.flights.length === 1;
    },
    flight() {
      return this.isSingleFlight && this.flights[0];
    },
    flightIds() {
      return this.flights.map((flight) => flight.id);
    },
  },
  created() {
    if (this.isSingleFlight) {
      const availableScenarios = this.scenarioItems.map((item) => item.value);
      const declaredScenario = this.flight?.declared_scenario?.toLowerCase() || null;
      const scenario = this.flight?.scenario?.toLowerCase() || null;
      if (
        declaredScenario
          && availableScenarios.includes(declaredScenario)
      ) {
        this.scenarioDeclared = declaredScenario;
      } else if (
        scenario
          && availableScenarios.includes(scenario.toLowerCase())
      ) {
        this.scenarioDeclared = scenario;
      } else {
        this.scenarioDeclared = null;
      }
    } else {
      this.scenarioDeclared = null;
    }
  },
  methods: {
    generation() {
      if (this.isSingleFlight) {
        this.generateFlightConstraintSheet();
      } else {
        this.bulkGenerateFlightsConstraintSheet();
      }
    },
    generateFlightConstraintSheet() {
      this.loadingGeneration = true;
      APIService.generateFlightConstraintSheet(
        this.flight.id,
        this.scenarioDeclared,
      )
        .then(() => {
          this.$store.dispatch(SET_FLIGHT_CONSTRAINT_SHEET_STATUS_NS, {
            flightId: this.flight.id,
            constraintSheetStatus: 'processing',
          });
          this.showMessage(this.$gettext('The document is being generated'), 'success');
          this.$emit('close');
        })
        .finally(() => {
          this.loadingGeneration = false;
        });
    },
    bulkGenerateFlightsConstraintSheet() {
      this.loadingGeneration = true;
      APIService.generateFlightsConstraintSheet(
        this.flightIds,
        this.scenarioDeclared,
      )
        .then(() => {
          this.flightIds.forEach((flightId) => {
            this.$store.dispatch(SET_FLIGHT_CONSTRAINT_SHEET_STATUS_NS, {
              flightId,
              constraintSheetStatus: 'processing',
            });
          });
          this.showMessage(this.$gettext('Documents are being generated'), 'success');
          this.$emit('close');
        })
        .finally(() => {
          this.loadingGeneration = false;
        });
    },
  },
};
</script>
