<template>
  <div class="sub-exploitants-list">
    <v-container class="px-1 px-md-3 flex-grow-0">
      <div class="d-flex justify-space-between mb-4">
        <div class="headline">
          {{ entityName.plural }}
        </div>
        <v-btn
          @click="dialog=true"
          color="primary"
          depressed
        >
          <translate :translate-params="{entity: entityName.singular}">
            + Add %{ entity }
          </translate>
        </v-btn>
      </div>
      <div>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$gettext('Search')"
          single-line
          hide-details
        />
        <v-data-table
          ref="datatable"
          item-key="id"
          :headers="headers"
          :items="subExploitants"
          :loading="loading"
          :search="search"
          class="mt-4"
          :class="{transparent: false}"
          :expanded="expanded"
          single-expand
          :loading-text="labels.loadingText"
          :no-data-text="labels.noResultText"
          :no-results-text="labels.noResultText"
          :mobile-breakpoint="singleColumnBreakpoint"
          :footer-props="{
            'items-per-page-text': $gettext('Lines per page:'),
            'items-per-page-all-text': $gettext('All'),
          }"
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          />
          <template
            slot="item"
            slot-scope="props"
          >
            <tr
              class="cursor-pointer"
              @click="selectItem(props.item)"
              :class="getClass(props.item)"
            >
              <td>
                {{ props.item.name }}
              </td>
              <td>
                {{ props.item.flights_count }}
              </td>
              <td>
                {{ props.item.pilots_count }}
              </td>
              <td>
                {{ props.item.drones_count }}
              </td>
              <td>
                <v-btn
                  icon
                  @click.stop="openDialogUpdateEntity(props.item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn
                  icon
                  @click.stop="openDialogDeleteEntity(props.item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-progress-linear
                v-if="loadingSubExploitantDetails"
                color="primary"
                indeterminate
              />
              <div
                v-else
                class="pa-2"
              >
                <v-container class="pa-0">
                  <div class="pa-3">
                    <!-- Details  -->
                    <v-row
                      v-if="item.alpha_tango_uid"
                      no-gutters
                    >
                      <v-col
                        cols="4"
                        sm="2"
                      >
                        <span
                          class="details-card__label"
                          v-translate
                        >
                          AlphaTango UID
                        </span>
                      </v-col>
                      <v-col
                        cols="8"
                        sm="10"
                      >
                        <span>
                          {{ item.alpha_tango_uid }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      no-gutters
                      class="mt-2"
                    >
                      <v-col
                        cols="4"
                        sm="2"
                      >
                        <span
                          class="details-card__label"
                          v-translate
                        >
                          Pilots
                        </span>
                      </v-col>
                      <v-col
                        cols="8"
                        sm="4"
                      >
                        <template v-if="item.pilots">
                          <div
                            v-for="(pilot, index) in item.pilots"
                            :key="index"
                          >
                            {{ pilot.full_name }}
                          </div>
                        </template>
                        <div
                          v-else
                          v-translate
                        >
                          No pilots
                        </div>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="2"
                      >
                        <span
                          class="details-card__label"
                          v-translate
                        >
                          Drones
                        </span>
                      </v-col>
                      <v-col
                        cols="8"
                        sm="4"
                      >
                        <template v-if="item.drones">
                          <div
                            v-for="(drone, index) in item.drones"
                            :key="index"
                          >
                            {{ drone.label }}
                          </div>
                        </template>
                        <div
                          v-else
                          v-translate
                        >
                          No drones
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </div>
            </td>
          </template>
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }}
            <span v-translate>from</span>
            {{ items.itemsLength }}
          </template>
        </v-data-table>
      </div>
    </v-container>
    <!-- Dialog action on entity -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      :retain-focus="false"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate
            v-if="entityToUpdate !== null"
            :translate-params="{
              name: entityToUpdate.name,
              entity: entityName.singular.toLowerCase()
            }"
            key="update"
          >
            Update %{ entity } '%{ name }'
          </translate>
          <translate
            v-else-if="entityToDelete !== null"
            :translate-params="{
              name: entityToDelete.name,
              entity: entityName.singular.toLowerCase()
            }"
            key="delete"
          >
            Delete %{ entity } '%{ name }'
          </translate>
          <translate
            v-else
            key="create"
            :translate-params="{entity: entityName.singular.toLowerCase()}"
          >
            Create %{ entity }
          </translate>
        </v-card-title>
        <v-card-text>
          <div v-if="entityToDelete !== null">
            <translate
              v-if="canBeDeleted"
              :translate-params="{name: entityToDelete.name}"
            >
              Are you sure you want to delete '%{name}'?
            </translate>
            <div v-else>
              <v-icon
                class="mr-2"
                color="warning"
              >
                mdi-alert
              </v-icon>
              <translate :translate-params="{entity: entityName.singular}">
                %{ entity } deletable only if it is not link with any flights, pilots nor drones.
              </translate>
            </div>
          </div>
          <v-form
            v-model="valid"
            v-else
          >
            <v-text-field
              v-model="name"
              class="p-0"
              :label="labels.name"
              :placeholder="placeholders.name"
              :rules="rules.required"
            />
            <v-text-field
              v-model="alphaTangoUID"
              class="p-0 mt-4"
              type="number"
              :label="labels.alphaTangoUID"
              :placeholder="placeholders.alphaTangoUID"
              :hint="hints.alphaTangoUID"
              persistent-hint
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="actionOnClick()"
            :disabled="actionButtonDisabled"
            :loading="loadingActionEntity"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import {
  GET_USER_SUB_EXPLOITANTS_NS,
  GET_USER_SUB_EXPLOITANT_DETAILS_NS,
} from '@/store/authentication';

export default {
  name: 'SubExploitantsSettings',
  data() {
    return {
      valid: false,
      dialog: false,
      name: '',
      alphaTangoUID: null,
      entityToUpdate: null,
      entityToDelete: null,
      headers: [
        {
          text: this.$gettext('Name'),
          value: 'name',
          width: '35%',
        },
        {
          text: this.$gettext('Flights count'),
          value: 'flights_count',
          width: '20%',
        },
        {
          text: this.$gettext('Pilots count'),
          value: 'pilots_count',
          width: '20%',
        },
        {
          text: this.$gettext('Drones count'),
          value: 'drones_count',
          width: '15%',
        },
        {
          text: '',
          value: 'update',
          width: '5%',
          sortable: false,
          align: 'center',
        },
        {
          text: '',
          value: 'delete',
          width: '5%',
          sortable: false,
          align: 'center',
        },
      ],
      search: '',
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
      loadingActionEntity: false,
      loadingSubExploitantDetails: false,
      expanded: [],
      rules: {
        required: [
          (value) => !!value || this.$gettext('A value is required.'),
        ],
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.authentication.userSubExploitantsLoading;
    },
    subExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    exploitationAlphatangoUid() {
      return this.hasManyAlphatangoUids ? null : this.exploitationAlphatangoUids[0] || null;
    },
    exploitationAlphatangoUids() {
      return this.$store.state.exploitants.exploitationDetails.alphatango_uids;
    },
    hasManyAlphatangoUids() {
      return this.$store.getters['exploitants/hasManyAlphatangoUids'];
    },
    labels() {
      return {
        loadingText: this.$gettext('Loading...'),
        noResultText: this.$gettext('No result found'),
        name: this.$gettext('Name *'),
        alphaTangoUID: this.$gettext('AlphaTango UID'),
      };
    },
    placeholders() {
      return {
        name: this.$gettext('Enter a new name'),
        alphaTangoUID: this.$gettext('Enter AlphaTango UID'),
      };
    },
    hints() {
      return {
        alphaTangoUID: this.$gettext(
          'Change this field only if it is different from the exploitant',
        ),
      };
    },
    canBeDeleted() {
      return (
        this.entityToDelete?.flights_count === 0
        && this.entityToDelete?.pilots_count === 0
        && this.entityToDelete?.drones_count === 0
      );
    },
    actionButtonDisabled() {
      return (
        (!this.valid && (this.entityToDelete !== null && !this.canBeDeleted))

      );
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  created() {
    this.getSubExploitants();
    this.alphaTangoUID = this.exploitationAlphatangoUid;
  },
  methods: {
    async getSubExploitants() {
      await this.$store.dispatch(GET_USER_SUB_EXPLOITANTS_NS);
    },
    selectItem(item) {
      if (this.expanded.length && this.expanded[0].id === item.id) {
        this.expanded = [];
      } else {
        this.expanded = [item];
      }

      if (this.expanded.length) {
        this.getSubExploitantDetails(this.expanded[0].id);
      }
    },
    getClass(item) {
      if (this.expanded[0]?.id === item.id) {
        return 'selected';
      }
      return '';
    },
    async getSubExploitantDetails(subExploitantId) {
      this.loadingSubExploitantDetails = true;
      const pilotsPromise = await APIService.getSubExploitantPilots(subExploitantId);
      const pilots = pilotsPromise.data;
      this.$store.commit(GET_USER_SUB_EXPLOITANT_DETAILS_NS, { subExploitantId, pilots });
      const dronesPromise = await APIService.getSubExploitantDrones(subExploitantId);
      const drones = dronesPromise.data;
      this.$store.commit(GET_USER_SUB_EXPLOITANT_DETAILS_NS, { subExploitantId, drones });
      this.loadingSubExploitantDetails = false;
    },
    openDialogUpdateEntity(entity) {
      this.entityToUpdate = entity;
      this.name = this.entityToUpdate.name;
      this.alphaTangoUID = this.entityToUpdate.alpha_tango_uid;
      this.dialog = true;
    },
    openDialogDeleteEntity(entity) {
      this.entityToDelete = entity;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.name = '';
      this.alphaTangoUID = this.exploitationAlphatangoUid;
      this.entityToUpdate = null;
      this.entityToDelete = null;
    },
    actionOnClick() {
      this.loadingActionEntity = true;
      let promise;
      let message;
      if (this.entityToUpdate !== null) {
        promise = APIService.updateSubExloitant(
          this.entityToUpdate.id, this.name, this.alphaTangoUID,
        );
        message = this.$gettext('Successful upgrade.');
      } else if (this.entityToDelete !== null) {
        promise = APIService.deleteSubExloitant(this.entityToDelete.id);
        message = this.$gettext('Successful deletion.');
      } else {
        promise = APIService.createSubExploitant(this.name, this.alphaTangoUID);
        message = this.$gettext('Successful creation.');
      }
      promise.then(() => {
        this.getSubExploitants();
        if (this.expanded.length) {
          this.getSubExploitantDetails(this.expanded[0].id);
        }
        this.showMessage(message, 'success');
      })
        .finally(() => {
          this.loadingActionEntity = false;
          this.closeDialog();
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.sub-exploitants-list ::v-deep .v-data-table__wrapper>table>tbody>tr:not(:last-child).selected {
  >td {
    border-bottom: 0;
    background-color: lighten($color: $color-info, $amount: 70);
  }
  +td {
    border-bottom: thin solid rgba(0, 0, 0, .12);
    background-color: lighten($color: $color-info, $amount: 70);
  }
}

</style>
