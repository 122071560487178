<template>
  <v-card
    class="mx-auto"
    max-width="500"
    min-width="200"
    max-height="460"
    tile
    light
  >
    <template>
      <v-list
        v-if="trackingAlerts.length"
        light
      >
        <v-list-item
          three-line
          v-for="(trackingAlert, index) in trackingAlerts"
          :value="trackingAlert.id"
          :key="index"
          @click.prevent="selectAlert(trackingAlert)"
          :disabled="isDisabled(trackingAlert)"
        >
          <v-list-item-content :class="trackingAlert.read ? 'read' : 'new'">
            <v-badge
              :value="!trackingAlert.read"
              dot
              color="red"
              offset-x="16"
              offset-y="30"
            >
              <v-list-item-title>
                <span>
                  {{ getAlertTitle(trackingAlert) }}
                </span>
              </v-list-item-title>
            </v-badge>
            <v-list-item-subtitle>
              {{ trackingAlert.created_at | toLocaleDatetime }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ getAlertText(trackingAlert) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-text
        v-else
        class="text-center"
        key="no-alert"
      >
        <translate>No alert received.</translate>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { OPEN_DRONES_TRACKING_POPUP_NS } from '@/store/map';

export default {
  name: 'TrackingAlertList',
  computed: {
    trackingAlerts() {
      return this.$store.state.map.trackingAlerts;
    },
    trackingPositions() {
      return this.$store.state.map.trackingPositions;
    },
  },
  methods: {
    isProximityAlert(trackingAlert) {
      return trackingAlert.alert_type === 'proximity';
    },
    getTrackerIds(trackingAlert) {
      const trackerIds = (
        this.isProximityAlert(trackingAlert)
          ? trackingAlert.proximity_alert.trackers
          : [trackingAlert.tracker_alert.tracker_id]
      );
      return trackerIds.map((id) => `tracker-${id}`);
    },
    isTrackingPositionsExists(trackingAlert) {
      if (this.trackingPositions.length === 0) {
        return false;
      }
      const ids = this.getTrackerIds(trackingAlert);
      return this.trackingPositions.some((t) => ids.includes(t.tracker_id));
    },
    isDisabled(trackingAlert) {
      return !this.isTrackingPositionsExists(trackingAlert);
    },
    getAlertTitle(trackingAlert) {
      if (this.isProximityAlert(trackingAlert)) return this.$gettext('Proximity alert');

      const { tracker_alert: trackerAlert } = trackingAlert;
      switch (trackerAlert.type) {
        case 'drone_detected':
          return this.$gettext('Drone detected');
        case 'unknown_drone':
          return this.$gettext('Unknown drone');
        case 'unknown_approval':
          return this.$gettext('Unknown approval');
        case 'area':
          return this.$gettext('Outside of authorized area');
        case 'height':
          return this.$gettext('Height authorized exceeded');
        default:
          return this.$gettext('Unknown alert type');
      }
    },
    getAlertText(trackingAlert) {
      if (this.isProximityAlert(trackingAlert)) {
        return this.$gettextInterpolate(
          this.$gettext('%{n} trackers has been detected with a distance under 500 meters.'),
          { n: trackingAlert.proximity_alert.trackers.length },
        );
      }
      const { tracker_alert: trackerAlert } = trackingAlert;
      switch (trackerAlert.type) {
        case 'unknown_approval':
          return this.$gettextInterpolate(
            this.$gettext('Exploitant %{commercialName} | Contact %{phoneNumber}'),
            {
              commercialName: trackerAlert.exploitant_commercial_name || '',
              phoneNumber: trackerAlert.exploitant_phone_number || '',
            },
          );
        case 'area':
        case 'height':
          return this.$gettextInterpolate(
            this.$gettext('Approval %{identifier} | Contact %{phoneNumber}'),
            {
              identifier: trackerAlert.approval_identifier || '',
              phoneNumber: trackerAlert.pilot_phone_number || '',
            },
          );
        default:
          return '';
      }
    },
    selectAlert(trackingAlert) {
      const trackerIds = this.getTrackerIds(trackingAlert);
      this.$store.dispatch(OPEN_DRONES_TRACKING_POPUP_NS, trackerIds);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.new {
  font-weight: 500;
}
.read {
  font-weight: 350;
  opacity: 0.8;
}

</style>
