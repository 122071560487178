<template>
  <div>
    <!-- Instruction -->
    <div
      v-if="constraint.instruction"
      class="mb-2"
    >
      <div class="d-flex align-center">
        <v-icon color="info">mdi-lock-outline</v-icon>
        <span
          class="ml-2 font-weight-medium"
          v-translate
        >
          Instruction
        </span>
      </div>
      <div class="ml-8">
        {{ constraint.instruction }}
      </div>
    </div>
    <!-- Notice explained -->
    <div
      v-if="constraint.notice_explain"
      class="mb-2"
    >
      <div class="d-flex align-center">
        <v-icon color="info">mdi-alarm</v-icon>
        <span
          class="ml-2 font-weight-medium"
          v-translate
        >
          Notice
        </span>
      </div>
      <div class="ml-8">
        {{ constraint.notice_explain }}
      </div>
    </div>
    <!-- Tips -->
    <div
      v-if="constraint.tips.length"
      class="mb-2"
    >
      <div class="d-flex align-center">
        <v-icon color="info">mdi-lightbulb-on-outline</v-icon>
        <span
          class="ml-2 font-weight-medium"
          v-translate
        >
          Tips
        </span>
      </div>
      <ul class="ml-2">
        <li
          v-for="(tip, index) in constraint.tips"
          :key="index"
        >
          {{ tip }}
        </li>
      </ul>
    </div>
    <!-- Clearance comment -->
    <ClearanceComment
      v-if="isImpacting"
      :constraint="constraint"
      :capability="capability"
      :flightId="flight.id"
      class="pb-2"
    />
    <FlightConstraintExtraDescription
      :constraint="constraint"
      :flight="flight"
      :panelExpanded="panelExpanded"
    />
  </div>
</template>

<script>
import { CONSTRAINTS_CATEGORY } from '@/settings';

import ClearanceComment from '@/components/Flights/ClearanceComment.vue';
import FlightConstraintExtraDescription from '@/components/Flights/FlightConstraintExtraDescription.vue';

export default {
  name: 'FlightConstraintDescription',
  components: {
    ClearanceComment,
    FlightConstraintExtraDescription,
  },
  props: {
    constraint: Object,
    capability: Object,
    flight: Object,
    panelExpanded: Number,
  },
  computed: {
    isImpacting() {
      return this.constraint.category === CONSTRAINTS_CATEGORY.IMPACTING;
    },
  },
};
</script>
