<template>
  <v-card>
    <!-- HEADER -->
    <v-card-title class="headline font-weight-regular primary white--text mb-4">
      <div class="pb-1">
        <v-img
          :src="thalesLogoPath"
          max-width="150"
        />
      </div>
      <div class="pb-1 pl-6">
        <div
          class="body-2"
          v-translate
        >
          Additional information
        </div>
        <div
          class="caption"
          v-translate
        >
          for transmission to the Civil Aviation Authority and/or the Prefecture
        </div>
      </div>
    </v-card-title>
    <v-card-text v-if="approvalPeriodIsValid">
      <!-- DELEGATION WARNING -->
      <v-alert
        type="warning"
        icon="mdi-alert-outline"
        text
      >
        {{ alerts.delegateToThales }}
      </v-alert>
      <!-- LINK TO HELP -->
      <div class="mb-4 d-flex align-center">
        <v-icon class="px-4">
          mdi-help-circle-outline
        </v-icon>
        <span v-html="hints.linkHelpArticle" />
      </div>
      <v-stepper
        v-model="step"
        vertical
      >
        <!-- STEP 1: FLIGHT AREAS SELECTION -->
        <template v-if="hasManyAreas">
          <v-stepper-step
            :complete="step > 1"
            step="1"
            editable
          >
            <span v-translate>Select Areas</span>
            <div
              v-if="step > 1 && flightAreasSelectedNames"
              class="mt-2"
            >
              <v-chip
                v-for="(areaName, index) in flightAreasSelectedNames"
                :key="index"
                class="mr-1"
              >
                {{ areaName }}
              </v-chip>
            </div>
          </v-stepper-step>
          <v-stepper-content step="1">
            <FlightAreasSelection
              :flightId="flight.id"
              :structureId="structureId"
              :flightAreasSelected.sync="flightAreasSelected"
              :flightAreasSelectedNames.sync="flightAreasSelectedNames"
              :initSelection="false"
            />
            <div class="d-flex justify-end align-center pt-3">
              <v-btn
                depressed
                class="mx-3"
                @click="close()"
              >
                <span v-translate>Cancel</span>
              </v-btn>
              <v-btn
                :disabled="!flightAreasSelected.length || flightAreasSelected.length > 1"
                depressed
                color="primary"
                class="white--text"
                @click="step=2"
              >
                <span v-translate>Continue</span>
              </v-btn>
            </div>
            <template v-if="flightAreasSelected.length && flightAreasSelected.length > 1">
              <div class="pt-2 caption error--text font-italic text-right">
                <span v-translate>
                  Only one area is allowed at a time per approval request
                </span>
              </div>
            </template>
          </v-stepper-content>
        </template>
        <!-- STEP 2  -->
        <v-stepper-step :step="hasManyAreas ? 2 : ''">
          <span v-translate>Submit approval request</span>
        </v-stepper-step>
        <v-stepper-content :step="hasManyAreas ? 2 : ''">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="mx-2"
          >
            <!-- PILOT -->
            <v-row
              align="center"
              class="my-2"
            >
              <v-col
                cols="12"
                class="py-2"
              >
                <span
                  class="subtitle-1 primary--text section-header"
                  v-translate
                >
                  PILOT
                </span>
              </v-col>
              <v-col
                cols="12"
                class="py-"
              >
                <v-autocomplete
                  v-model="pilotId"
                  :rules="[rules.required]"
                  :items="flightPilots"
                  item-text="full_name"
                  item-value="id"
                  :no-data-text="$gettext('No pilot found')"
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>
            <!-- EXPLOITANT -->
            <v-row
              align="center"
              class="my-2"
            >
              <v-col cols="12">
                <span
                  class="subtitle-1 primary--text section-header"
                  v-translate
                >
                  EXPLOITANT
                </span>
              </v-col>
              <!-- GENDER -->
              <v-col
                cols="2"
                class="py-2"
              >
                <span class="body-2 primary--text">
                  {{ labels.exploitantGender }}
                </span>
              </v-col>
              <v-col
                cols="10"
                class="py-2"
              >
                <v-radio-group
                  v-model="exploitantGender"
                  :rules="[rules.required]"
                  row
                  hide-details
                  class="pa-0 ma-0"
                >
                  <v-radio
                    label="Madame"
                    value="F"
                    class="gender_label"
                  />
                  <v-radio
                    label="Monsieur"
                    value="H"
                    class="gender_label"
                  />
                </v-radio-group>
              </v-col>
              <!-- FIRST NAME -->
              <v-col
                cols="2"
                class="py-0"
              >
                <span class="body-2 primary--text">
                  {{ labels.exploitantFirstName }}
                </span>
              </v-col>
              <v-col
                cols="4"
                class="py-0 pr-5"
              >
                <v-text-field
                  v-model="exploitantFirstName"
                  :rules="[rules.required]"
                  class="pa-0 ma-0"
                />
              </v-col>
              <!-- LAST NAME -->
              <v-col
                cols="2"
                class="py-0 pl-5"
              >
                <span class="body-2 primary--text">
                  {{ labels.exploitantLastName }}
                </span>
              </v-col>
              <v-col
                cols="4"
                class="py-0"
              >
                <v-text-field
                  v-model="exploitantLastName"
                  :rules="[rules.required]"
                  class="pa-0 ma-0"
                />
              </v-col>
              <!-- TELEPHONE -->
              <v-col
                cols="2"
                class="py-0"
              >
                <span class="body-2 primary--text">
                  {{ labels.exploitantPhoneNumber }}
                </span>
              </v-col>
              <v-col
                cols="4"
                class="py-0 pr-5"
              >
                <v-text-field
                  v-model="exploitantPhoneNumber"
                  :rules="[rules.required, rules.phoneNumber]"
                  class="pa-0 ma-0"
                />
              </v-col>
              <!-- EMAIL -->
              <v-col
                cols="2"
                class="py-0 pl-5"
              >
                <span class="body-2 primary--text">
                  {{ labels.exploitantEmail }}
                </span>
              </v-col>
              <v-col
                cols="4"
                class="py-0"
              >
                <v-text-field
                  v-model="exploitantEmail"
                  :rules="[rules.required]"
                  class="pa-0 ma-0"
                />
              </v-col>
              <!-- COMPANY -->
              <v-col
                cols="2"
                class="pt-0"
              >
                <span class="body-2 primary--text">
                  {{ labels.exploitantCompany }}
                </span>
              </v-col>
              <v-col
                cols="4"
                class="pt-0 pr-5"
              >
                <v-text-field
                  v-model="exploitantCompany"
                  class="pa-0 ma-0"
                />
              </v-col>
              <!-- AlphaTango UID -->
              <v-col
                cols="2"
                class="py-0 pl-5"
              >
                <span class="body-2 primary--text">
                  {{ labels.exploitantATUid }}
                </span>
              </v-col>
              <v-col
                cols="4"
                class="py-0"
              >
                <v-text-field
                  v-model="exploitantATUid"
                  :rules="[rules.required]"
                  :hint="hints.exploitantATUid"
                  persistent-hint
                  type="number"
                  class="exploitant-ATUid pa-0 ma-0"
                />
              </v-col>
            </v-row>
            <!-- DECLARANT -->
            <v-row
              align="center"
              class="my-2"
            >
              <v-col cols="12">
                <span
                  class="subtitle-1 primary--text section-header"
                  v-translate
                >
                  DECLARANT
                </span>
              </v-col>
              <!-- FIRST NAME -->
              <v-col
                cols="2"
                class="pt-0"
              >
                <span class="body-2 primary--text">
                  {{ labels.declarantFirstName }}
                </span>
              </v-col>
              <v-col
                cols="4"
                class="pt-0 pr-5"
              >
                <v-text-field
                  v-model="declarantFirstName"
                  :rules="[rules.required]"
                  class="pa-0 ma-0"
                />
              </v-col>
              <!-- LAST NAME -->
              <v-col
                cols="2"
                class="pt-0 pl-5"
              >
                <span class="body-2 primary--text">
                  {{ labels.declarantLastName }}
                </span>
              </v-col>
              <v-col
                cols="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="declarantLastName"
                  :rules="[rules.required]"
                  class="pa-0 ma-0"
                />
              </v-col>
            </v-row>
            <!-- MISSION -->
            <v-row
              align="center"
              class="my-2"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                <span
                  class="subtitle-1 primary--text section-header"
                  v-translate
                >
                  MISSION
                </span>
              </v-col>
              <!-- NAME -->
              <v-col
                cols="3"
                class="py-1"
              >
                <span class="body-2 primary--text">
                  {{ labels.missionName }}
                </span>
              </v-col>
              <v-col
                cols="9"
                class="py-1"
              >
                <v-text-field
                  v-model="missionName"
                  :rules="[rules.required, rules.missionNameLength]"
                  class="pa-0 ma-0"
                />
              </v-col>
              <!-- DESCRIPTION -->
              <v-col
                cols="12"
                class="pt-1 pb-0"
              >
                <v-textarea
                  v-model="missionDescription"
                  :label="labels.missionDescription"
                  :rules="[rules.required, rules.descriptionLength]"
                  rows="3"
                  outlined
                >
                  <template slot="append">
                    <v-tooltip
                      bottom
                      color="info"
                      max-width=580
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          class="small-icon"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <div
                        v-for="(line, index) in tooltips.missionDescription.split('<br>')"
                        :key="index"
                      >
                        {{ line }}
                      </div>
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-col>
              <!-- ADDRESS -->
              <v-col
                cols="12"
                class="py-0"
              >
                <v-textarea
                  v-model="address"
                  :label="labels.address"
                  :rules="[rules.required]"
                  rows="1"
                  outlined
                >
                  <template slot="append">
                    <v-tooltip
                      bottom
                      color="info"
                      max-width=580
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          class="small-icon"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <div>
                        <template v-for="(line, index) in tooltips.address.split('<br>')">
                          <div :key="index">
                            {{ line }}
                          </div>
                        </template>
                      </div>
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-col>
              <!-- CHECKBOXES OFF-SIGHT AGG PEOPLE GATHERING ZONE ILLUMINATED -->
              <v-col
                cols="12"
                class="py-0"
              >
                <v-checkbox
                  v-model="offSight"
                  :label="labels.offSight"
                  hide-details
                  class="primary-label mt-0 pt-0"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-checkbox
                  v-model="peopleGathering"
                  :label="labels.peopleGathering"
                  hide-details
                  class="primary-label"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-checkbox
                  v-model="agglo"
                  :label="labels.agglo"
                  hide-details
                  class="primary-label"
                />
              </v-col>
              <v-col
                cols="12"
                class="pt-0 mb-4"
              >
                <v-checkbox
                  v-model="zoneIlluminated"
                  :label="labels.zoneIlluminated"
                  hide-details
                  class="primary-label"
                />
              </v-col>
            </v-row>
            <!-- AERONEF -->
            <v-row
              align="center"
              class="my-2"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                <span
                  class="subtitle-1 primary--text section-header"
                  v-translate
                >
                  AIRCRAFT
                </span>
              </v-col>
              <!-- DRONE -->
              <v-col
                cols="3"
                class="py-0 mb-5"
              >
                <span class="body-2 primary--text">
                  {{ labels.drone }}
                </span>
              </v-col>
              <v-col
                cols="9"
                class="py-0"
              >
                <v-autocomplete
                  v-model="droneId"
                  :rules="[rules.required, rules.droneRegistredAT]"
                  :items="flightDrones"
                  item-text="label"
                  item-value="id"
                  :no-data-text="$gettext('No drone found')"
                  outlined
                />
              </v-col>
              <v-col
                cols="3"
                class="pb-0"
              />
              <v-col
                cols="9"
                class="py-0"
              >
                <div class="pb-6">
                  <span>
                    {{ hints.additionnalDrone }}
                  </span>
                </div>
              </v-col>
              <!-- DRONE LUMINOUS  -->
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-checkbox
                  v-model="droneLuminous"
                  :label="labels.droneLuminous"
                  hide-details
                  class="primary-label mt-0 pt-0"
                />
              </v-col>
              <!-- DRONE WEIGHT AND HORIZONTAL DISTANCE -->
              <v-col
                cols="6"
                class="pb-2 pb-1 pr-6"
              >
                <span class="body-2 primary--text">
                  {{ labels.droneWeight }}
                </span>
              </v-col>
              <v-col
                cols="6"
                class="pb-2 pb-1 pl-6"
              >
                <span class="body-2 primary--text">
                  {{ labels.horizontalDistance }}
                </span>
              </v-col>
              <v-col
                cols="6"
                class="pt-0 pb-2 pr-6"
              >
                <v-text-field
                  v-model="droneWeight"
                  :rules="[rules.required]"
                  type="number"
                  suffix="kg"
                  class="ma-0 pa-0"
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="6"
                class="pt-0 pb-2 pl-6"
              >
                <v-text-field
                  v-model="horizontalDistance"
                  :rules="[rules.required]"
                  type="number"
                  suffix="m"
                  class="ma-0 pa-0"
                  hide-details="auto"
                />
              </v-col>
              <!-- TRACKER ID -->
              <v-col
                cols="3"
                class="py-1"
              >
                <span class="body-2 primary--text">
                  {{ labels.trackerID }}
                </span>
              </v-col>
              <v-col
                cols="9"
                class="py-1"
              >
                <v-text-field
                  v-model="trackerID"
                  :placeholder="placeholders.trackerID"
                  :rules="[rules.trackerFormat]"
                  class="ma-0"
                >
                  <template slot="append">
                    <v-tooltip
                      bottom
                      color="info"
                      max-width=580
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          class="small-icon"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <div>
                        <template v-for="(line, index) in tooltips.trackerID.split('<br>')">
                          <div :key="index">
                            {{ line }}
                          </div>
                        </template>
                      </div>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <!-- ACTIVITY DECLARATION -->
              <v-col
                cols="12"
                class="py-0"
              >
                <span class="body-2 primary--text">
                  {{ labels.activityDeclarationFile }}
                </span>
              </v-col>
              <v-col
                cols="12"
                class="py-2"
              >
                <v-file-input
                  v-model="activityDeclarationFile"
                  :placeholder="availableActivityDeclarationFile ?
                    '': placeholders.activityDeclarationFile"
                  :rules="availableActivityDeclarationFile ? [] :[rules.required]"
                  accept="application/pdf"
                  prepend-icon=""
                  append-icon="$file"
                  truncate-length="80"
                  outlined
                  hide-details
                >
                  <template
                    slot="prepend-inner"
                    v-if="!activityDeclarationFile"
                  >
                    <div
                      v-if="availableActivityDeclarationFile"
                      class="mt-1 pr-4 black--text"
                    >
                      {{ availableActivityDeclarationFile.name }}
                    </div>
                    <div
                      v-else
                      class="mt-1 pr-4 black--text"
                    >
                      {{ availableActivityDeclarationFile }}
                    </div>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <!-- NIGHT FLYING ALERT -->
            <v-row
              v-if="showNightFlyingAlert"
              align="center"
            >
              <v-col cols="12">
                <v-alert
                  text
                  type="error"
                  icon="mdi-alert-octagon-outline"
                  class="body-2 mb-0"
                >
                  {{ alerts.night_flying }}
                </v-alert>
              </v-col>
            </v-row>
            <!-- ALERT BEFORE CONFIRMATION -->
            <v-row align="center">
              <v-col cols="12">
                <v-alert
                  text
                  color="primary"
                  class="body-2"
                >
                  {{ alerts.confirmation }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
          <!-- ---------------- ACTIONS ---------------- -->
          <div class="d-flex justify-end align-center pb-2">
            <v-btn
              depressed
              class="mx-3"
              @click="close()"
            >
              <span v-translate>Cancel</span>
            </v-btn>
            <v-btn
              :disabled="!valid"
              depressed
              color="primary"
              class="white--text mx-3"
              @click="sendApproval()"
            >
              <span v-translate>Confirm</span>
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
    <!-- APPROVAL MAX PERIOD EXCEEDED -->
    <v-card-text v-else>
      <p class="my-2 error--text">
        <span>
          {{ texts.maxPeriod }}
        </span>
        <br>
        <span>
          {{ texts.editFlight }}
        </span>
      </p>
      <div class="d-flex justify-end align-center pb-2">
        <v-btn
          class="mx-3"
          @click="close()"
        >
          <span v-translate>Cancel</span>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { filterHour } from '@/filters/hour';

import { CONSTRAINTS_CATEGORY } from '@/settings';

import { ERROR } from '@/store/status';

import FlightAreasSelection from '@/components/Flights/RequestApproval/FlightAreasSelection.vue';

export default {
  name: 'RequestApprovalFormThales',
  components: { FlightAreasSelection },
  props: {
    flight: Object,
    constraintId: Number,
    structureId: Number,
    flightPilots: Array,
    flightDrones: Array,
    approvalPeriodIsValid: Boolean,
    approvalMaxDays: { type: Number, required: false },
  },
  data() {
    const pilotId = this.flightPilots.length === 1 ? this.flightPilots[0].id : null;
    const droneId = this.flightDrones.length === 1 ? this.flightDrones[0].id : null;
    const droneWeight = this.flightDrones.length === 1 ? this.flightDrones[0].weight : null;
    const hasManyAreas = this.flight.areas.length > 1;
    return {
      hasManyAreas,
      step: hasManyAreas ? 1 : '',
      flightAreasSelected: [],
      flightAreasSelectedNames: [],
      valid: true,
      pilotId,
      exploitantGender: null,
      exploitantFirstName: null,
      exploitantLastName: null,
      exploitantPhoneNumber: null,
      exploitantEmail: null,
      exploitantCompany: null,
      exploitantATUid: null,
      declarantLastName: null,
      declarantFirstName: null,
      missionName: this.flight.name,
      missionDescription: null,
      address: this.flight.address,
      offSight: !this.flight.pilot_in_sight,
      peopleGathering: this.flight.people_gathering,
      agglo: this.flight.constraintsAnalysis.some(
        (c) => c.category === CONSTRAINTS_CATEGORY.IMPACTING && c.code_type === 'AGG',
      ),
      zoneIlluminated: false,
      droneId,
      droneWeight,
      horizontalDistance: null,
      droneLuminous: false,
      trackerID: null,
      activityDeclarationFile: null,
      rules: {
        required: (value) => !!value || this.$gettext('Fill in a value'),
        missionNameLength: (v) => (v && v.length <= 50) || this.$gettext(
          'Can not exceed 50 characters.',
        ),
        phoneNumber: (v) => /^((\+|00)33\s?|0)[1-9](\s?\d{2}){4}$/.test(v) || this.$gettext(
          'Invalid format',
        ),
        descriptionLength: (v) => (v && v.length <= 250) || this.$gettext(
          'Can not exceed 250 characters.',
        ),
        droneRegistredAT: (v) => (
          (this.getDroneByID(v) && Boolean(this.getDroneByID(v).alpha_tango_id)) || this.$gettext(
            'This drone has not a valid Alpha Tango Id',
          )
        ),
        trackerFormat: (v) => /^\w{8}-(\w{4}-){3}\w{12}$/.test(v) || !v || this.$gettext(
          'Invalid format',
        ),
      },
    };
  },
  computed: {
    approvalIdInError() {
      return this.flight.approvals?.find(
        (a) => a.constraint_id === this.constraintId && a.status === ERROR,
      )?.id;
    },
    exploitationDetails() {
      return this.$store.state.exploitants.exploitationDetails;
    },
    availableActivityDeclarationFile() {
      if (!this.activityDeclarationFile) {
        if (this.exploitationDetails && this.exploitationDetails.last_declaration) {
          const document = this.exploitationDetails.last_declaration;
          const isPDF = document.name.split('.').pop().toLowerCase() === 'pdf';
          const hasExpired = document.expiration_date
            ? new Date(document.expiration_date) < new Date() : false;
          if (isPDF && !hasExpired) {
            return document;
          }
        }
      }
      return null;
    },
    thalesLogoPath() {
      return `${process.env.BASE_URL}Thales-logo.svg`;
    },
    labels() {
      return {
        exploitantGender: this.$gettext('Gender *'),
        exploitantFirstName: this.$gettext('First Name *'),
        exploitantLastName: this.$gettext('Last Name *'),
        exploitantPhoneNumber: this.$gettext('Phone Number *'),
        exploitantEmail: this.$gettext('Email *'),
        exploitantCompany: this.$gettext('Company'),
        exploitantATUid: this.$gettext('AlphaTango UID *'),
        declarantFirstName: this.$gettext('First Name *'),
        declarantLastName: this.$gettext('Last Name *'),
        missionName: this.$gettext('Intitulé de la mission *'),
        missionDescription: this.$gettext('Mission Description *'),
        address: this.$gettext('Address and site description *'),
        offSight: this.$gettext('Off sight'),
        peopleGathering: this.$gettext('Flight located less than 150 m from a people gathering'),
        agglo: this.$gettext('Flight located less than 50 m from an agglomeration'),
        zoneIlluminated: this.$gettext('Zone survolée éclairée'),
        drone: this.$gettext('Drone to be used for the mission *'),
        droneWeight: this.$gettext('Total UAV weight (kg) *'),
        horizontalDistance: this.$gettext('Maximal distance from the pilot (m) *'),
        droneLuminous: this.$gettext('Drone equipped with a light system'),
        trackerID: this.$gettext('GPS tracker identifier'),
        activityDeclarationFile: this.$gettext('Receipt of statement of situation (PDF)'),
      };
    },
    placeholders() {
      return {
        trackerID: '00000000-0000-0000-0000-000000000000',
        activityDeclarationFile: this.$gettext('Choose a file'),
      };
    },
    tooltips() {
      return {
        missionDescription: this.$gettext(`Indicate here any relevant information allowing
          the airspace authorities and the prefecture to better understand operations nature.
          Precise:<br>- days and timetables planned, motivating these choices if necessary
          (for example closed days for a museum, or closed hours for a school) -<br>reasons for
          the mission : what is being filmed, for which final customer, what for, etc.`),
        address: this.$gettext(`Indicate here the site address and give relevant informations.
          Indicate:<br>- if the flight takes place above public space (*) (if so, describe
          measures taken to secure public space during the flights) -<br>if other sites are
          planned during the same missions (separated notifications shall be made for these other
          sites)<br> (*) Reminder: public space in urban areas consists in public streets as well
          as places open to public, i.e. which access is open (beaches, public parks...) or
          possible under obligations if any person who would like to do so can fulfill these
          obligations (for example by buying a ticket).`),
        trackerID: this.$gettext(`If you have a sensor provided by Thales that you plan to use
          for the flight, please indicate its number here. Otherwise leave the field blank.`),
      };
    },
    hints() {
      return {
        additionnalDrone: this.$gettext(`Only one drone can be declared, the other possible drones
          can be detailed in the mission description. If you don't have drones on your account,
          please go to the 'Drones' table to create one or import your drones from AlphaTango.`),
        linkHelpArticle: this.$gettext(`You need help to request approval? Find our
          <a
            target="_blank"
            href="https://clearance.aero/deposer-une-demande-de-vol-de-drone-aux-aeroports-de-rennes-et-lille/"
          >
            article
          </a>`),
        exploitantATUid: this.$gettext(`Number called "UID identifier to be communicated to
          delegates" in the Delegation section of the Alpha Tango website.`),
      };
    },
    alerts() {
      return {
        delegateToThales: this.$gettext(`Before submitting your request, check if you have
          declared Thales as delegation on AlphaTango.`),
        night_flying: this.$gettextInterpolate(
          this.$gettext(`Your flight is scheduled to take place at night, the flight area should
            be illuminated and the drone used should be equipped with a light system. If your
            flight complies with these conditions, you must tick the relevant checkboxes before
            confirming your request, otherwise it will be refused. Restricting your flight time
            between %{ start } and %{ end } will allowed you to fly during the day and avoid this
            constraint.`),
          {
            start: filterHour(this.flight.night_flying_end_time),
            end: filterHour(this.flight.night_flying_start_time),
          },
        ),
        confirmation: this.$gettext(`By confirming, an approval request will be send to the air
          navigation service and a notification will be send to the prefecture if required. You
          will receive a confirmation email as soon as your requests will study.`),
      };
    },
    texts() {
      return {
        maxPeriod: this.$gettextInterpolate(
          this.$gettext(`Requests for agreement from the authority responsible for this area may
            not exceed exceed a duration of %{ maxDays } days.`),
          { maxDays: this.approvalMaxDays || '' },
        ),
        editFlight: this.$gettext(`You can modify your mission and edit the dates or times in
          order to submit your approval request.`),
      };
    },
    showNightFlyingAlert() {
      return this.flight.night_flying && !(this.zoneIlluminated && this.droneLuminous);
    },
    hasManyAlphatangoUids() {
      return this.$store.getters['exploitants/hasManyAlphatangoUids'];
    },
  },
  watch: {
    droneId(newValue) {
      const drone = this.getDroneByID(newValue);
      if (drone) {
        this.droneWeight = drone.weight;
      }
    },
  },
  created() {
    this.exploitantGender = this.exploitationDetails.main_contact.gender;
    this.exploitantFirstName = this.exploitationDetails.main_contact.first_name;
    this.exploitantLastName = this.exploitationDetails.main_contact.last_name;
    this.exploitantPhoneNumber = this.exploitationDetails.main_contact.phone_number;
    this.exploitantEmail = this.exploitationDetails.main_contact.email;
    this.exploitantCompany = this.exploitationDetails.commercial_name;
    if (!this.hasManyAlphatangoUids) {
      [this.exploitantATUid] = this.exploitationDetails.alphatango_uids;
    }

    const { pilots } = this.$store.state.pilots;
    const connectedPilotId = this.$store.state.authentication.user.contact_id;
    const connectedPilot = pilots.find((p) => p.id === connectedPilotId);
    this.declarantFirstName = connectedPilot.first_name;
    this.declarantLastName = connectedPilot.last_name;
  },
  methods: {
    getDroneByID(id) {
      return this.flightDrones.find((d) => d.id === id);
    },
    sendApproval() {
      if (this.$refs.form.validate()) {
        let availableActivityDeclarationFile = null;
        if (this.availableActivityDeclarationFile) {
          availableActivityDeclarationFile = this.availableActivityDeclarationFile.id;
        }
        const externalPayload = {
          exploitant_gender: this.exploitantGender,
          exploitant_firstname: this.exploitantFirstName,
          exploitant_lastname: this.exploitantLastName,
          exploitant_phone_number: this.exploitantPhoneNumber,
          exploitant_email: this.exploitantEmail,
          exploitant_company: this.exploitantCompany,
          exploitant_at_uid: this.exploitantATUid,
          declarant_firstname: this.declarantFirstName,
          declarant_lastname: this.declarantLastName,
          mission_name: this.missionName,
          mission_description: this.missionDescription,
          address: this.address,
          off_sight: this.offSight,
          people_gathering: this.peopleGathering,
          agglo: this.agglo,
          zone_illuminated: this.zoneIlluminated,
          drone_id: this.droneId,
          drone_luminous: this.droneLuminous,
          drone_weight: this.droneWeight,
          horizontal_distance: this.horizontalDistance,
          tracker_id: this.trackerID,
          available_activity_declaration: availableActivityDeclarationFile,
        };
        const payload = {
          pilotId: this.pilotId,
          approvalDetails: this.missionDescription,
          flightAreasIds: this.hasManyAreas ? this.flightAreasSelected : [],
          externalPayload,
          activityDeclarationFile: this.activityDeclarationFile,
        };
        if (this.approvalIdInError) {
          this.retryCreateApproval(payload);
        } else {
          this.createApproval(payload);
        }
      } else {
        this.showMessage(
          this.$gettext(`Errors find in form and marked in red. Correct them before trying
            again.`),
          'error',
        );
      }
    },
    createApproval(payload) {
      this.$emit('create-approval', payload);
    },
    retryCreateApproval(payload) {
      this.$emit('retry-create-approval', this.approvalIdInError, payload);
    },
    close() {
      this.step = 1;
      this.flightAreasSelected = [];
      this.flightAreasSelectedNames = [];
      this.$emit('close-form');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.section-header {
  text-transform: uppercase;
}
.gender_label ::v-deep .v-label {
  font-size: 90%;
}
.small-icon {
  font-size: 130%;
  padding-left: 10px;
}
.primary-label ::v-deep .v-label {
  font-size: 90%;
  color: $color-primary;
}
.exploitant-ATUid ::v-deep .v-messages__message {
  line-height: 16px;
}

</style>
