<template>
  <div class="fill-height">
    <!-- ----------- Flight create / edit Form ------------ -->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="primary--form py-2"
      :class="{ 'mr-2': !isMobileBreakpoint }"
    >
      <!-- Header -->
      <v-row class="primary--form__row ma-0">
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <div class="flight-name__field">
                <StatusIcon status="planned" />
                <v-text-field
                  v-model="flight.name"
                  :rules="rules.name"
                  :placeholder="placeholders.name"
                  class="text-field_primary--text pa-0"
                />
                <div class="form-close">
                  <div :class="closeFormTextDisplay">
                    <translate>
                      Cancel
                    </translate>
                  </div>
                  <v-btn
                    icon
                    @click="close()"
                  >
                    <v-icon color="primary">close</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-row class="primary--form__row pb-2 ma-0">
        <v-col cols="12">
          <!-- ----- Dates - Period - Duration ---- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <v-date-picker
                v-model="flightDates"
                no-title
                required
                full-width
                class="elevation-0"
                range
                locale="fr"
                first-day-of-week="1"
                :min="today"
              />
            </v-col>
          </v-row>
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 pb-4"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label pt-2">
                PERIOD
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <v-input
                class="pt-2"
                hide-details
              >
                <span v-translate>From</span>
                &nbsp;
                <span>{{ flight.date_start | date }}</span>
                &nbsp;
                <span v-translate>to</span>
                &nbsp;
                <span>{{ flight.date_end | date }}</span>
              </v-input>
            </v-col>
          </v-row>
          <!-- ----- Pilots ---- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label">
                PILOT
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <v-autocomplete
                v-model="flight.pilots"
                :loading="loadingPilots"
                :items="pilotsAllowed"
                item-text="full_name"
                item-value="id"
                :no-data-text="$gettext('No pilot found')"
                :rules="rules.requiredList"
                class="pa-0"
                multiple
              />
            </v-col>
          </v-row>
          <!-- --------- SubExploitant ----------- -->
          <v-row
            v-if="hasManySubExploitants"
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <span class="primary--form__label text-uppercase">
                {{ entityName.singular }}
              </span>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <v-select
                v-model="flight.sub_exploitant"
                :rules="rules.subExploitant"
                :items="subExploitants"
                item-text="name"
                item-value="id"
                class="pa-0"
              />
            </v-col>
          </v-row>
          <!-- --------- Action Buttons (Create / Edit) ----------- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1 mt-2"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <FlightFormActions
                :isEditing="isEditing"
                :valid="valid"
                :loading="loading"
                @create-flight="createFlight()"
                @update-flight="updateFlight()"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { format } from 'date-fns';

import API from '@/services/api';

import {
  APPLICATION_STATUS,
  APPLICATION_DISPLAY,
  SET_STATUS_NS,
} from '@/store/application';
import { UPDATE_DISPLAY_ONLY_MY_FLIGHTS_NS } from '@/store/authentication';
import {
  GET_FLIGHT_DETAILS_NS,
  SET_SIMPLIFIED_FLIGHT_FORM_OPEN_NS,
  UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS,
} from '@/store/flights';
import { MAP_STATUS, SET_MAP_STATUS_NS } from '@/store/map';
import { GET_SIMPLIFIED_PILOTS_NS } from '@/store/pilots';
import { featureCollectionToMultiPolygon } from '@/components/Map/MapboxGL.helper';

import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import FlightFormActions from '@/components/Flights/FlightFormActions.vue';

import { BIG_DISPLAY_COLUMN_SIZES, MEDIUM_DISPLAY_COLUMN_SIZES } from '@/layouts/SplitFormResponsiveSizes';

export default {
  name: 'FlightFormSimplified',
  components: {
    StatusIcon,
    FlightFormActions,
  },
  props: {
    clientStructureName: {
      type: String,
      required: false,
      default: '',
    },
    flightToEdit: { type: Object },
  },
  data() {
    return {
      valid: true,
      flight: null,
      loading: false,
      flightDates: [],
      rules: {
        name: [
          (v) => !!v || this.$gettext('A name is required'),
        ],
        subExploitant: [
          (v) => !!v || this.$gettextInterpolate(
            this.$gettext('%{ entity } to select'),
            { entity: this.entityName.singular },
          ),
        ],
        requiredList: [
          (value) => !!value.length || this.$gettext('At least one value is required.'),
        ],
      },
      today: format(new Date(), 'yyyy-MM-dd'),
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    placeholders() {
      return { name: this.$gettext("Flight's name...") };
    },
    display() {
      return this.$store.state.application.display;
    },
    closeFormTextDisplay() {
      if (this.display === APPLICATION_DISPLAY.BIG) {
        return 'd-none d-md-flex';
      }
      return 'd-none d-lg-flex';
    },
    formColumns() {
      if (this.display === APPLICATION_DISPLAY.BIG) {
        return BIG_DISPLAY_COLUMN_SIZES;
      }
      return MEDIUM_DISPLAY_COLUMN_SIZES;
    },
    hasManySubExploitants() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    subExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    flightArea() {
      const featureCollection = this.$store.state.map.currentArea;
      return featureCollectionToMultiPolygon(featureCollection);
    },
    loadingPilots() {
      return this.$store.state.pilots.loadingPilots;
    },
    pilotsAllowed() {
      return this.$store.state.pilots.pilots.filter((pilot) => !pilot.is_archived);
    },
    contactIdLogged() {
      return this.$store.state.authentication.user.contact_id;
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
    isEditing() {
      return this.flightToEdit !== null;
    },
  },
  watch: {
    display(newValue) {
      if (newValue === 'SMALL') {
        this.close();
      }
    },
    flightDates(newValue) {
      this.$set(this.flight, 'date_start', newValue[0]);
      this.$set(this.flight, 'date_end', newValue.length > 1 ? newValue[1] : newValue[0]);
      if (this.flight.date_start > this.flight.date_end) {
        [this.flight.date_start, this.flight.date_end] = [
          this.flight.date_end, this.flight.date_start];
      }
    },
  },
  created() {
    this.$store.commit(SET_SIMPLIFIED_FLIGHT_FORM_OPEN_NS, true);
    if (this.flightToEdit) {
      this.flight = { ...this.flightToEdit };
      this.flight.pilots = this.flightToEdit.pilot_ids;
    } else {
      this.flight = this.buildDefaultFlight();
    }
    this.flightDates = [this.flight.date_start, this.flight.date_end];
    this.$store.dispatch(GET_SIMPLIFIED_PILOTS_NS);
  },
  destroyed() {
    this.$store.commit(SET_SIMPLIFIED_FLIGHT_FORM_OPEN_NS, false);
    this.$emit('destroy');
  },
  methods: {
    defaultEndDate() {
      const date = new Date();
      date.setDate(date.getDate() + 14);
      return format(date, 'yyyy-MM-dd');
    },
    defaultStartDate() {
      const date = new Date();
      date.setDate(date.getDate() + 8);
      return format(date, 'yyyy-MM-dd');
    },
    buildDefaultFlight() {
      let date = new Date();
      date = format(date, 'dd/MM/yyyy');
      return {
        name: `${date} ${this.clientStructureName}`,
        date_start: this.defaultStartDate(),
        date_end: this.defaultEndDate(),
        pilots: [this.contactIdLogged],
        sub_exploitant: this.hasManySubExploitants ? null : this.subExploitants[0].id,
      };
    },
    buildFlight() {
      const payload = {};
      Object.keys(this.flight).forEach((key) => { payload[key] = this.flight[key]; });
      payload.area = { ...this.flightArea };
      return payload;
    },
    createFlight() {
      if (this.flightArea === null) {
        this.showMessage(this.$gettext('Draw the flying area'));
        return;
      }
      if (!this.$refs.form.validate()) {
        this.showMessage(this.$gettext('Errors found in form. Please correct them.'));
        return;
      }
      this.loading = true;
      const payload = this.buildFlight();
      API.createGeneralistFlight(payload)
        .then(({ data }) => {
          this.$store.commit(UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS, { flightId: data.id });
          this.$store.dispatch(
            GET_FLIGHT_DETAILS_NS,
            {
              flightId: data.id,
              reload: true,
              documents: false,
              created: true,
            },
          )
            .then(() => {
              this.showCreatedFlightMessage(data);
              this.$emit('reset-flight-to-edit');
              this.$emit('update-id-selected', data.id);
              this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
              this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
            })
            .catch(() => {
              // flight not found
              this.$store.dispatch(UPDATE_DISPLAY_ONLY_MY_FLIGHTS_NS, false)
                .then(() => {
                  this.$store.dispatch(
                    GET_FLIGHT_DETAILS_NS,
                    { flightId: data.id, reload: true },
                  )
                    .then(() => {
                      this.showCreatedFlightMessage(data);
                      this.$emit('reset-flight-to-edit');
                      this.$emit('update-id-selected', data.id);
                      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
                      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
                    });
                });
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showCreatedFlightMessage(data) {
      if (data.generalist_flight_warning) {
        this.showMessage(data.generalist_flight_warning, 'warning');
      } else {
        this.showMessage(
          this.$gettextInterpolate(
            this.$gettext('Flight n°%{id} created.'),
            { id: data.id },
          ),
          'success',
        );
      }
    },
    prepareFlightToUpdate() {
      const payload = {};
      // we build payload only with non null values
      // because API trigger error if they're sent
      payload.date_start = this.flight.date_start;
      payload.date_end = this.flight.date_end;
      payload.pilots = this.flight.pilots;
      payload.name = this.flight.name;
      payload.area = { ...this.flightArea };
      payload.sub_exploitant = this.flight.sub_exploitant;

      return payload;
    },
    updateFlight() {
      if (this.flightArea === null) {
        this.showMessage(this.$gettext('Draw the flying area'));
        return;
      }
      if (!this.$refs.form.validate()) {
        this.showMessage(this.$gettext('Errors found in form. Please correct them.'));
        return;
      }
      this.loading = true;
      const payload = this.prepareFlightToUpdate();
      API.updateGeneralistFlight(this.flight.id, payload)
        .then(({ data }) => {
          this.$store.commit(UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS, { flightId: data.id });
          this.$store.dispatch(GET_FLIGHT_DETAILS_NS, {
            flightId: data.id,
            updateGeom: true,
            documents: false,
            analysis: true,
            created: true,
          })
            .then(() => {
              this.showMessage(
                this.$gettextInterpolate(
                  this.$gettext('Flight n°%{ id } updated.'),
                  { id: data.id },
                ),
                'success',
              );
              this.$emit('reset-flight-to-edit');
              this.$emit('update-id-selected', data.id);
              const outdatedNotifsArmyCount = data.outdated_army_notifs_count;
              const outdatedNotifsPrefectureCount = data.outdated_prefecture_notifs_count;
              const cancelledNotifsArmyCount = data.cancelled_army_notifs_count;
              if (outdatedNotifsArmyCount > 0 || outdatedNotifsPrefectureCount > 0
                || cancelledNotifsArmyCount > 0) {
                this.$emit('show-update-alphatango-alert', {
                  outdatedNotifsArmyCount,
                  outdatedNotifsPrefectureCount,
                  cancelledNotifsArmyCount,
                });
              }
              this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
              this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('reset-flight-to-edit');
      this.$emit('update-id-selected', '');
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.text-field_primary--text ::v-deep .v-text-field__slot input {
  color: $color-primary;
}

.flight-name__field {
  display: flex;
  align-items: center;
  gap: 16px;
  .form-close {
    color: $color-primary;
    text-align: end;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    padding-left: 8px;
  }
}

</style>
