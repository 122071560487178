<template>
  <div class="trace-listing">
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="traces"
      :loading="loading"
      single-expand
      :options.sync="options"
      :server-items-length="itemsLength"
      :expanded.sync="expanded"
      :hide-default-footer="smallDisplay"
      :class="{bigDisplay: bigDisplay, smallDisplay: !bigDisplay, transparent: true}"
      :footer-props="{
        'items-per-page-text': $gettext('Lines per page:'),
        'items-per-page-all-text': $gettext('All'),
        'items-per-page-options': [25, 50, 100, -1],
      }"
      :loading-text="$gettext('Traces loading...')"
      :no-data-text="$gettext('No traces found')"
      item-key="id"
      :no-results-text="$gettext('No traces found')"
      dark
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <tr
          v-if="props && props.item"
          :class="getStatusClass(props)"
          class="cursor-pointer text-left"
          @click="selectItem(props)"
          @mouseenter="idHover = props.item.id"
          @mouseleave="idHover = null"
        >
          <td
            v-if="headers.find(e => e.value == 'id')"
            class="font-weight-bold pl-2"
          >
            {{ props.item.id }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'approval_identifiers')"
            class="truncated cell"
          >
            <div
              v-if="props.item.approvals && props.item.approvals.length"
              :key="`${props.item.id}-hasApprovals`"
            >
              <v-chip
                v-for="(approval, index) in props.item.approvals"
                :key="index"
                light
                small
                :text-color="props.isExpanded ? 'info' : 'white'"
                class="approval-chip mx-1"
                :class="getApprovalChipClass(props.isExpanded, approval)"
              >
                <template v-if="approval.identifier">
                  {{ approval.identifier }}
                </template>
              </v-chip>
            </div>
            <span
              v-else
              v-translate
              :key="`${props.item.id}-hasNoApprovals`"
            >
              No approval
            </span>
          </td>
          <td v-if="headers.find(e => e.value == 'device_id')">
            {{ props.item.device_id }}
          </td>
          <td v-if="headers.find(e => e.value == 'day')">
            {{ props.item.first_detection | date }}
          </td>
          <td v-if="headers.find(e => e.value == 'start_hour')">
            {{ formatTime(props.item.first_detection) }}
          </td>
          <td v-if="headers.find(e => e.value == 'end_hour')">
            {{ formatTime(props.item.last_detection) }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'action')"
            class="text-right action-expand"
          >
            <v-btn
              icon
              :class="getStatusClass(props)"
            >
              <v-icon v-if="!props.isExpanded">
                keyboard_arrow_down
              </v-icon>
              <v-icon v-else>
                keyboard_arrow_up
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pa-0"
        >
          <v-skeleton-loader
            v-if="loadingDetails"
            light
            type="list-item-three-line"
          />
          <TrackerTrace
            v-else
            :trace="item"
            :key="`trace-${item.id}`"
            @center-on-trace="() => $emit('center-on-area', item.geometry)"
          />
        </td>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        <span v-translate="
          {pageStart: items.pageStart, pageStop: items.pageStop, length: items.itemsLength}
        ">
          %{ pageStart } - %{ pageStop } of %{ length }
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { APPLICATION_DISPLAY, SET_DISPLAY_NS } from '@/store/application';
import { SET_PAGINATION_NS, SET_TRACE_HOVER_NS, SET_TRACE_SELECTED_NS } from '@/store/traces';

import TrackerTrace from '@/components/TrackerTraces/TrackerTrace.vue';
import makeTrackerTraceListingHeaders from '@/components/TrackerTraces/TrackerTraceListingHeader';

export default {
  name: 'TrackerTracesListing',
  components: { TrackerTrace },
  data() {
    const {
      smallHeaders,
      mediumHeaders,
      bigHeaders,
    } = makeTrackerTraceListingHeaders(this.$gettext);
    return {
      smallHeaders,
      mediumHeaders,
      bigHeaders,
    };
  },
  computed: {
    activeTab() {
      return this.$store.getters['application/activeTab'];
    },
    loading() {
      return this.$store.state.traces.tableItemsLoading;
    },
    traces() {
      return this.loading ? [] : this.$store.getters['traces/tableItems'];
    },
    itemsLength() {
      return this.$store.state.traces.itemsLength;
    },
    loadingDetails() {
      return this.$store.state.traces.traceDetailsLoading;
    },
    options: {
      get() {
        return this.$store.state.traces.pagination;
      },
      set(newValue) {
        this.$store.dispatch(SET_PAGINATION_NS, newValue);
      },
    },
    display() {
      return this.$store.state.application.display;
    },
    bigDisplay() {
      return this.display === APPLICATION_DISPLAY.BIG;
    },
    mediumDisplay() {
      return this.display === APPLICATION_DISPLAY.MEDIUM;
    },
    smallDisplay() {
      return this.display === APPLICATION_DISPLAY.SMALL;
    },
    headers() {
      switch (this.display) {
        case APPLICATION_DISPLAY.BIG:
          return this.bigHeaders;
        case APPLICATION_DISPLAY.SMALL:
          return this.smallHeaders;
        case APPLICATION_DISPLAY.MEDIUM:
        default:
          return this.mediumHeaders;
      }
    },
    idHover: {
      get() {
        return this.$store.state.map.featureIdHovered.trace;
      },
      set(newValue) {
        if (!this.idSelected || this.idSelected !== newValue) {
          this.$store.dispatch(SET_TRACE_HOVER_NS, newValue);
        }
      },
    },
    expanded: {
      get() {
        return [this.$store.getters['traces/traceSelected']];
      },
      set() {},
    },
    idSelected: {
      get() {
        return this.$store.state.map.featureIdSelected.trace;
      },
      set(newValue) {
        this.$store.dispatch(SET_TRACE_SELECTED_NS, newValue);
      },
    },
  },
  watch: {
    display(newValue) {
      if (newValue === 'SMALL') {
        Object.keys(this.$refs.datatable.expanded).forEach((k) => {
          if (k !== newValue) {
            delete this.$refs.datatable.expanded[k];
          }
        });
        this.idSelected = null;
      }
    },
    activeTab() {
      this.idSelected = null;
    },
  },
  methods: {
    getStatusClass(props) {
      return { selected: props.isExpanded, highlighted: props.item.is_active };
    },
    getApprovalChipClass(isExpanded, approval) {
      return isExpanded ? '' : approval.status;
    },
    selectItem(props) {
      if (this.loading) {
        return;
      }
      this.smallDisplay && this.$store.dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.MEDIUM);
      if (props.isExpanded === false) {
        this.idSelected = props.item.id;
      } else {
        this.idSelected = null;
      }
    },
    formatTime(datetime) {
      return new Date(datetime).toLocaleTimeString(
        this.$store.getters['application/currentLanguage'],
        { hour: '2-digit', minute: '2-digit' },
      );
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.trace-listing ::v-deep .v-data-table__wrapper>table> {
  tbody>tr,
  tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  tbody>tr>td>button {
    background-color: white;
    color: $color-secondary;
    &.selected {
      color: $color-white;
      background-color: $color-primary;
    }
    &.highlighted {
      color: $color-secondary;
      background-color: $flight-highlighted-background-color;
    }
  }
  tbody>tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 15px solid $color-secondary;
      height: 60px;
    }
    &:first-child td {
      height: 45px;
    }
  }
  tbody>tr:hover:not(.selected) {
    background-color: lighten($color: $color-info, $amount: 50);
  }
}

.approval-chip {
  &.accepted {
    background-color: $accepted-background-color;
  }
  &.incomplete {
    background-color: $incomplete-background-color;
  }
  &.refused {
    background-color: $refused-background-color;
  }
  &.submitted {
    background-color: $submitted-background-color;
  }
  &.reserves {
    background-color: $reserves-background-color;
  }
  &.pending {
    background-color: $pending-background-color;
  }
}

</style>
