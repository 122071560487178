<template>
  <v-tooltip
    max-width="640"
    v-model="show"
    :attach="attachSelector"
    :disabled="disabled"
    :color="color"
    :open-on-click="isMobileBreakpoint"
    :open-on-hover="!isMobileBreakpoint"
    :nudge-bottom="nudgeBottom"
    :bottom="bottom"
    :top="top"
    :right="right"
    :left="left"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <slot
      name="tooltip-content"
      @click.stop="show=false"
    />
  </v-tooltip>
</template>

<script>
export default {
  // Tooltip component adapted to mobile display
  // Tooltip is attached to it's activator component
  // Tooltip opens and closes on click for mobile; and on hover for desktop
  name: 'LayoutTooltip',
  props: {
    // attachSelector: #{id of the element in activator slot}
    // that the tooltip must attach to.
    attachSelector: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'info lighten-2',
    },
    nudgeBottom: {
      type: Number,
      default: 5,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  data() { return { show: false }; },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
  },
};
</script>
