<template>
  <div class="invoice-list">
    <div class="headline mb-4">
      <translate>Invoices listing</translate>
    </div>
    <div v-if="hasNextInvoice">
      <v-card
        max-width="440"
        class="px-5"
      >
        <v-skeleton-loader
          :loading="userRefreshing || invoiceDetailsLoading"
          type="list-item@2"
        >
          <v-card-title>
            <span v-translate>Prochaine facture :</span>
          </v-card-title>
          <v-card-text>
            <template v-if="!invoiceDetailsError">
              <p class="d-flex flex-wrap">
                {{ nextInvoiceDetails }}
              </p>
            </template>
            <template v-else-if="invoiceDetailsError">
              <span
                v-translate
                class="body-2 font-italic error--text text--darken-4"
              >
                Couldn't retrieve next invoice details.
              </span>
            </template>
          </v-card-text>
        </v-skeleton-loader>
      </v-card>
    </div>
    <div>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$gettext('Search')"
        single-line
        hide-details
      />
      <v-data-table
        ref="datatable"
        :headers="headers"
        :items="invoices"
        :loading="loading"
        :search="search"
        single-expand
        calculate-widths
        :loading-text="$gettext('Invoices loading...')"
        :no-data-text="$gettext('No invoices found')"
        item-key="id"
        :no-results-text="$gettext('No invoices found')"
        :mobile-breakpoint="singleColumnBreakpoint"
        :footer-props="{
          'items-per-page-text': $gettext('Lines per page:'),
          'items-per-page-all-text': $gettext('All'),
          'items-per-page-options': [25, 50, 100, -1],
        }"
      >
        <template v-slot:[`item.refunded`]="{ item }">
          <v-chip
            v-if="item.refunded"
            x-small
            label
            color="green"
            text-color="white"
            class="mr-2 px-1"
          >
            <translate>Refunded</translate>
          </v-chip>
          <v-chip
            v-else
            label
            x-small
            color="primary"
            text-color="white"
            class="mr-2 px-1"
          >
            <translate>Paid</translate>
          </v-chip>
        </template>
        <template v-slot:[`item.download`]="{ item }">
          <v-btn
            icon
            :loading="loadingDownload(item.id)"
            @click="downloadInvoice(item)"
          >
            <v-icon color="info">icon-download</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import API from '@/services/api';

import { REFRESH_SUBSCRIPTION_DATA_NS } from '@/store/exploitants';

export default {
  name: 'InvoiceListing',
  data() {
    return {
      headers: [
        {
          text: this.$gettext('Status'),
          value: 'refunded',
          width: '5%',
          align: 'center',
          class: 'pl-3',
        },
        {
          text: this.$gettext('Invoice number'),
          value: 'number',
          width: '10%',
        },
        {
          text: this.$gettext('Description'),
          value: 'description',
          width: '30%',
        },
        {
          text: this.$gettext('Amount'),
          value: 'amount',
          width: '10%',
          align: 'center',
        },
        {
          text: this.$gettext('Creation date'),
          value: 'created_at',
          width: '10%',
          align: 'center',
        },
        {
          text: this.$gettext('Payment date'),
          value: 'paid_at',
          width: '10%',
          align: 'center',
        },
        {
          text: this.$gettext('Download'),
          value: 'download',
          width: '5%',
          sortable: false,
          align: 'center',
        },
      ],
      invoices: [],
      search: '',
      loading: false,
      downloadingInvoiceId: null,
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
    };
  },
  computed: {
    userRefreshing() {
      return this.$store.state.authentication.userRefreshing;
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    exploitationId() {
      return this.userData.exploitation_id;
    },
    subscription() {
      return this.userData.subscription;
    },
    subscriptionPlan() {
      return this.subscription.subscription_plan;
    },
    hasCancelDate() {
      return this.subscription.cancel_at;
    },
    userOldPlans() {
      return ['FF_M', 'FF_Y', 'INDEP_M', 'INDEP_Y'].includes(this.subscriptionPlan.code);
    },
    userNewPlans() {
      return ['PER_SEAT_M', 'PER_SEAT_Y'].includes(this.subscriptionPlan.code);
    },
    hasNextInvoice() {
      return (
        !this.subscription.trial
        && !this.hasCancelDate
        && (this.userOldPlans || this.userNewPlans)
      );
    },
    invoiceDetailsError() {
      return this.$store.state.exploitants.subscriptionDetailsError;
    },
    seatsNumber() {
      return this.$store.state.exploitants.subscriptionSeatsNumber || '';
    },
    nextPaymentAttemptDate() {
      return this.$store.state.exploitants.subscriptionNextPaymentAttemptDate || '';
    },
    priceWithTax() {
      return (this.$store.state.exploitants.subscriptionPriceWithTax !== null
        ? this.$store.state.exploitants.subscriptionPriceWithTax : '');
    },
    nextInvoiceDetails() {
      let seatsDetails = '';
      if (this.userNewPlans) {
        seatsDetails = this.$gettextInterpolate(
          this.$gettext('%{seatsNumber} %{pilot}.'),
          {
            seatsNumber: this.seatsNumber,
            pilot: this.$ngettext('pilot', 'pilots', this.seatsNumber),
          },
        );
      }
      return this.$gettextInterpolate(
        this.$gettext(`%{seatsDetails} Next invoice (payment attempt on
          %{nextPaymentAttemptDate}) : %{priceWithTax} € TTC.`),
        {
          seatsDetails,
          nextPaymentAttemptDate: this.nextPaymentAttemptDate,
          priceWithTax: this.priceWithTax,
        },
      );
    },
    invoiceDetailsLoading() {
      return this.$store.state.exploitants.subscriptionDetailsLoading;
    },
  },
  created() {
    this.refreshInvoiceInformations();
    this.getExploitantInvoices(this.exploitationId);
  },
  methods: {
    async getExploitantInvoices(exploitantId) {
      this.loading = true;
      await API.getExploitantInvoices(exploitantId)
        .then(({ data }) => {
          this.invoices = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async downloadInvoice(item) {
      this.downloadingInvoiceId = item.id;
      await API.downloadExploitantInvoice(this.exploitationId, item.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettext('facture.pdf')
          );
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.downloadingInvoiceId = null;
        });
    },
    loadingDownload(invoiceId) {
      return invoiceId === this.downloadingInvoiceId;
    },
    refreshInvoiceInformations() {
      if (this.hasNextInvoice) {
        this.$store.dispatch(REFRESH_SUBSCRIPTION_DATA_NS);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.pilot-list table.v-data-table {
  border-collapse: initial;
}

.pilot-list {
  tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 10px solid $color-secondary;
      height: 50px !important;
    }
    &:first-child td {
      height: 40px !important;
    }
  }
}

</style>
