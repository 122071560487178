<template>
  <div>
    <!-- Time Picker for mobile screens -->
    <template v-if="isMobileDevice">
      <v-text-field
        v-model="timeString"
        class="pa-0 time-picker__text-field"
        type="time"
        :rules="rules"
      >
        <template slot="append">
          <v-icon small>
            {{ appendIcon }}
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <!-- Time Picker for Desktop screens -->
    <template v-else>
      <v-input
        class="pa-0 v-text-field primary--text"
        :class="{'v-input--is-focused': vueTimePickerActive }"
        :value="timeString"
        :rules="rules"
      >
        <div class="time-picker__wrapper">
          <vue-timepicker
            v-model="timeObject"
            format="HH:mm"
            :minute-interval="minuteInterval"
            :drop-direction="dropDirection"
            :hide-clear-button="!clearable"
            ref="vueTimePicker"
          />
          <v-icon
            :color="vueTimePickerActive ? 'primary' : ''"
            small
          >
            {{ appendIcon }}
          </v-icon>
        </div>
      </v-input>
    </template>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

export default {
  name: 'TimePicker',
  components: { VueTimepicker },
  props: {
    time: {
      type: String,
      required: false,
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'mdi-clock-outline',
    },
    minuteInterval: {
      type: Number,
      required: false,
      default: 1,
    },
    dropDirection: {
      type: String,
      required: false,
      default: 'bottom',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return { vueTimePickerActive: false };
  },
  computed: {
    timeString: {
      get() {
        return this.time?.substring(0, 5);
      },
      set(newTime) {
        let value = '';
        if (newTime) {
          value = `${newTime}:00`;
        }
        this.$emit('update:time', value);
      },
    },
    timeObject: {
      get() {
        if (this.time) {
          const [HH, mm] = this.time.split(':');
          return { HH, mm };
        }
        return null;
      },
      set(newTime) {
        let value = '';
        const { HH, mm } = newTime;
        if (HH && mm) {
          value = [HH, mm, '00'].join(':');
        }
        this.$emit('update:time', value);
      },
    },
    isMobileDevice() {
      const { userAgent } = navigator;
      const mobileAgent = (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
      );
      return userAgent && mobileAgent.test(userAgent);
    },
  },
  mounted() {
    if (this.$refs.vueTimePicker) {
      this.$watch(
        () => this.$refs.vueTimePicker.isActive,
        (value) => {
          this.vueTimePickerActive = value;
        },
      );
    }
  },
};
</script>

<style
  lang="scss"
  scoped
>
.time-picker__text-field ::v-deep {
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.time-picker__wrapper {
  display: flex;
  width: 100%;

  ::v-deep .vue__time-picker {
    flex-grow: 1;
    .dropdown ul li:not([disabled]).active,
    .dropdown ul li:not([disabled]).active:hover,
    .dropdown ul li:not([disabled]).active:focus {
      color: $color-white;
      background: $color-primary;
    }
    input.display-time {
      width: 100%;
      border: none;
      padding: 0px;
    }
    input.display-time:focus {
      outline: none;
    }
  }
}

</style>
