<template>
  <v-list-item-content>
    <v-row
      align="center"
      no-gutters
    >
      <v-col
        cols="3"
        class="px-3"
      >
        <v-row
          align="center"
          no-gutters
        >
          <!-- Status icon -->
          <v-icon
            small
            :color="getIconColor"
          >
            {{ getIcon }}
          </v-icon>
          <!-- Status -->
          <span
            :class="`${getIconColor}--text`"
            class="email-status pl-2"
          >{{ statusText }}</span>
        </v-row>
      </v-col>
      <!-- Information -->
      <v-col cols="9">
        <v-row
          align="center"
          no-gutters
        >
          <span class="email-info">
            {{ alertText }}
          </span>
        </v-row>
      </v-col>
    </v-row>
  </v-list-item-content>
</template>

<script>

import { DONE, FAILED } from '@/store/status';

const EMAIL_STATUS_STYLE = {
  sent: {
    icon: 'mdi-checkbox-marked-circle-outline',
    color: 'success',
  },
  todo: {
    icon: 'mdi-dots-horizontal-circle-outline',
    color: 'blue',
  },
  error: {
    icon: 'mdi-alert-circle-outline',
    color: 'error',
  },
};

export default {
  name: 'EmailRequest',
  props: { emailRequest: Object },
  computed: {
    alertType() {
      switch (this.emailRequest.status) {
        case FAILED:
          return 'error';
        case DONE:
          return 'sent';
        default:
          return 'todo';
      }
    },
    alertText() {
      switch (this.emailRequest.status) {
        case DONE:
          return this.texts.sent;
        default:
          return this.texts.sending;
      }
    },
    sentEmailRequestDateString() {
      if (this.emailRequest?.sent_at) {
        const datetime = new Date(this.emailRequest.sent_at);
        return this.$gettextInterpolate(
          this.$gettext('%{ date } to %{ time }'),
          {
            date: datetime.toLocaleDateString(this.$store.getters['application/currentLanguage']),
            time: datetime.toLocaleTimeString(this.$store.getters['application/currentLanguage']),
          },
        );
      }
      return '';
    },
    texts() {
      return {
        sending: this.$gettextInterpolate(
          this.$gettext('to %{ email }'),
          { email: this.emailRequest.to },
        ),
        sent: this.$gettextInterpolate(
          this.$gettext('to %{ email } on %{ date }'),
          {
            email: this.emailRequest.to,
            date: this.sentEmailRequestDateString,
          },
        ),
      };
    },
    statusText() {
      switch (this.emailRequest.status) {
        case DONE:
          return this.$gettext('Sent');
        case FAILED:
          return this.$gettext('Failed');
        default:
          return this.$gettext('Sending');
      }
    },
    getIcon() {
      return EMAIL_STATUS_STYLE[this.alertType].icon;
    },
    getIconColor() {
      return EMAIL_STATUS_STYLE[this.alertType].color;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.email-status::before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  left: 0;
  opacity: .12;
  position: absolute;
  right: 0;
  top: 0;
  content: '';
}

.email-info {
  opacity: 0.75;
  word-break: break-word;
}

</style>
