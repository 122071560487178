<template>
  <div
    class="primary--form"
    :class="{ 'mr-2': !isMobileBreakpoint }"
  >
    <v-overlay :value="setMapLoading">
      <v-progress-circular indeterminate />
    </v-overlay>
    <!-- Header  -->
    <v-row
      no-gutters
      align="center"
      class="primary--form__header px-4 py-2"
    >
      <v-col
        cols="7"
        sm="8"
        class="text-left subtitle-1"
      >
        <translate>Third party exclusion zone powered by Clearance</translate>
      </v-col>
      <v-col
        cols="5"
        sm="4"
        class="text-right pr-1"
      >
        <span v-translate>Close</span>
        <v-btn
          icon
          dark
          @click="closeDialog=true"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Form  -->
    <v-form
      ref="form"
      v-model="valid"
      class="primary--form__container pa-2"
    >
      <!-- Subscription disclaimer -->
      <v-row
        v-if="!isSubscriptionActive"
        class="px-2"
        align="center"
        no-gutters
      >
        <v-col cols="12">
          <v-alert
            :class="style.subscriptionDisclaimer"
            color="primary"
            outlined
            dense
          >
            <span v-html="texts.subscriptionDisclaimer" />
          </v-alert>
        </v-col>
      </v-row>
      <!-- Details text -->
      <v-row
        class="px-2"
        align="center"
        no-gutters
      >
        <v-col cols="12">
          <v-alert
            :class="style.introductionText"
            color="info"
            text
            dense
          >
            <span v-html="texts.introduction" />
          </v-alert>
        </v-col>
      </v-row>
      <!-- Scenario -->
      <v-row
        :class="style.formRow"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <span class="primary--form__label">
            {{ labels.scenario }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="9"
        >
          <v-select
            v-model="scenario"
            :items="scenarios"
            single-line
            class="mt-0"
          />
        </v-col>
      </v-row>
      <!-- Flight Height -->
      <v-row
        :class="style.formRow"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <span class="primary--form__label">
            {{ labels.flyingHeight }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="9"
        >
          <v-text-field
            v-model="flyingHeight"
            :hint="hints.flyingHeight"
            :rules="rules.positiveNumber"
            type="number"
            suffix="m"
            class="mt-0"
          />
        </v-col>
      </v-row>
      <!-- Drone Speed -->
      <v-row
        :class="style.formRow"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <span class="primary--form__label">
            {{ labels.droneSpeed }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="9"
        >
          <v-text-field
            v-model="droneSpeed"
            :hint="hints.droneSpeed"
            :rules="rules.positiveNumber"
            type="number"
            suffix="m/s"
            class="mt-0"
          />
        </v-col>
      </v-row>
      <!-- Drone weight -->
      <v-row
        :class="style.formRow"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <span class="primary--form__label">
            {{ labels.droneWeight }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="9"
        >
          <v-text-field
            v-model="droneWeight"
            :hint="hints.droneWeight"
            :rules="rules.positiveNumber"
            type="number"
            suffix="kg"
            class="mt-0"
          />
        </v-col>
      </v-row>
      <!-- Drone Protection Device -->
      <v-row
        :class="style.formRow"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          class="d-flex"
        >
          <v-checkbox
            v-model="droneProtectionDevice"
            :label="labels.droneProtectionDevice"
            class="my-2 py-1"
            hide-details
          />
        </v-col>
      </v-row>
      <!-- Error exclusion zone -->
      <v-row
        v-if="flightExclusionZoneError"
        no-gutters
        class="my-2 mx-1 mt-4 px-1"
      >
        <v-col cols="12">
          <v-alert
            v-model="alert"
            color="error"
            icon="mdi-minus-circle"
            outlined
          >
            {{ flightExclusionZoneError }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="stretch"
        class="my-2 mx-1"
      >
        <!-- Map markers -->
        <v-col
          cols="12"
          lg="6"
          order="2"
          order-lg="1"
          class="px-1 pt-1 pt-lg-0"
        >
          <v-card
            class="fill-height"
            elevation="0"
            outlined
          >
            <!-- Title  -->
            <div class="px-2 pt-4 d-flex align-center">
              <v-icon
                color="primary"
                class="pr-1"
              >
                mdi-map-marker-outline
              </v-icon>
              <span class="primary--form__label">
                <translate>Place on the map</translate>
              </span>
            </div>
            <!-- Map markers buttons -->
            <v-card-text>
              <v-row
                no-gutters
                align="center"
                :class="style.mapMarkerRow"
              >
                <v-btn
                  :class="style.mapMarkerIcon"
                  @click="addMapMarker(markers.pilotPosition.id)"
                >
                  <v-img
                    max-height="32"
                    max-width="32"
                    :src="markers.pilotPosition.localIcon"
                  />
                </v-btn>
                <translate :class="style.mapMarkerLabel">Pilot positions</translate>
                <v-btn
                  v-if="markers.pilotPosition.mapMarkersCount > 0"
                  @click="removeLastMapMarker(markers.pilotPosition.id)"
                  icon
                >
                  <v-icon>
                    icon-bin_line
                  </v-icon>
                </v-btn>
              </v-row>
              <v-row
                no-gutters
                align="center"
                :class="style.mapMarkerRow"
              >
                <v-btn
                  :class="style.mapMarkerIcon"
                  @click="addMapMarker(markers.takeOffPoint.id)"
                >
                  <v-img
                    max-height="32"
                    max-width="32"
                    :src="markers.takeOffPoint.localIcon"
                  />
                </v-btn>
                <translate :class="style.mapMarkerLabel">Take off points</translate>
                <v-btn
                  v-if="markers.takeOffPoint.mapMarkersCount > 0"
                  @click="removeLastMapMarker(markers.takeOffPoint.id)"
                  icon
                >
                  <v-icon>
                    icon-bin_line
                  </v-icon>
                </v-btn>
              </v-row>
              <v-row
                no-gutters
                align="center"
                :class="style.mapMarkerRow"
              >
                <v-btn
                  :class="style.mapMarkerIcon"
                  @click="addMapMarker(markers.landingPoint.id)"
                >
                  <v-img
                    max-height="32"
                    max-width="32"
                    :src="markers.landingPoint.localIcon"
                  />
                </v-btn>
                <translate :class="style.mapMarkerLabel">Landing points</translate>
                <v-btn
                  v-if="markers.landingPoint.mapMarkersCount > 0"
                  @click="removeLastMapMarker(markers.landingPoint.id)"
                  icon
                >
                  <v-icon>
                    icon-bin_line
                  </v-icon>
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Legend -->
        <v-col
          cols="12"
          lg="6"
          order="1"
          order-lg="2"
          class="px-1 pb-1 pb-lg-0"
        >
          <v-card
            class="pa-4 fill-height d-flex flex-column justify-center"
            elevation="0"
            outlined
          >
            <div class="d-flex py-2 align-center">
              <div :class="style.legendSymbolFlightArea" />
              <p
                :class="style.legendLabelFlightArea"
                v-translate
              >
                Flight area
              </p>
            </div>
            <div class="d-flex py-2 align-center">
              <div :class="style.legendSymbolExclusionZone" />
              <p :class="style.legendLabelExclusionZone">
                <translate>Third party exclusion zone of</translate>
                <b>
                  {{ bufferRadius }} m
                </b>
                <br />
                <span
                  class="caption"
                  v-translate
                >
                  computed with information above
                </span>
              </p>
            </div>
            <div
              class="d-flex py-2 align-center"
              v-if="displayLegendForbiddenZone"
            >
              <div :class="style.legendSymbolForbiddenZone" />
              <p :class="style.legendLabelForbiddenZone">
                <translate>Non-regulatory area</translate>
                <span>
                  (scenario <b>{{ scenario }}</b>)
                </span>
                <br />
                <span class="caption">
                  <translate>Distant pilot-drone greater than</translate>
                  <b>
                    {{ maxDistanceDronePilot }} m
                  </b>
                </span>
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- Info forbidden zone -->
      <v-row
        v-if="typeLegendForbiddenZone"
        no-gutters
        class="my-2 mx-1 mt-4 px-1"
      >
        <v-col cols="12">
          <v-alert
            v-model="alert"
            :color="typeLegendForbiddenZone.color"
            :icon="typeLegendForbiddenZone.icon"
            outlined
            dismissible
            @click.close="closeAlert()"
          >
            {{ typeLegendForbiddenZone.text }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- Action buttons -->
      <LayoutTooltip :disabled="isSubscriptionActive">
        <template
          slot="activator"
          slot-scope="{ on }"
        >
          <div v-on="on">
            <v-row
              class="px-2 mt-2"
              no-gutters
            >
              <v-col
                cols="12"
                lg="6"
                class="px-1 my-1 my-lg-0"
              >
                <v-btn
                  class="info--text"
                  block
                  depressed
                  :loading="screenshotLoading"
                  @click="takeScreenShot()"
                  :disabled="!isSubscriptionActive"
                >
                  <v-icon
                    small
                    color="info"
                    class="pr-2"
                  >
                    mdi-camera
                  </v-icon>
                  <translate>Screenshot</translate>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                lg="6"
                class="px-1 my-1 my-lg-0"
              >
                <v-btn
                  class="info--text"
                  block
                  depressed
                  @click="downloadKml()"
                  :loading="downloadLoading"
                  :disabled="!valid || downloadLoading || !isSubscriptionActive"
                >
                  <v-icon
                    small
                    color="info"
                    class="pr-2"
                  >
                    mdi-download
                  </v-icon>
                  <translate>Download KML file</translate>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="px-2 mt-1 mt-lg-2"
              no-gutters
            >
              <v-col
                cols="12"
                class="px-1"
              >
                <v-btn
                  class="white--text"
                  color="green"
                  block
                  @click="saveExclusionZone({ close: false })"
                  :loading="saveLoading"
                  :disabled="!valid || saveLoading || !isSubscriptionActive"
                >
                  <translate>Save</translate>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
        <template slot="tooltip-content">
          <span
            class="caption"
            v-translate
          >
            Functionnality requiring an active subscription
          </span>
        </template>
      </LayoutTooltip>
    </v-form>
    <!-- Close dialog -->
    <v-dialog
      v-model="closeDialog"
      max-width="740"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Third party exclusion zone</translate>
        </v-card-title>
        <v-card-text>
          <span class="body-1">
            <translate v-if="isSubscriptionActive">
              Do you want to save the current third party exclusion zone before leaving?
            </translate>
            <translate v-else>
              Are you sure you want to quit?
            </translate>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mx-2 my-1"
            @click="closeDialog=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            class="mx-2 my-1"
            :color="isSubscriptionActive ? '' : 'primary'"
            @click="close()"
          >
            <translate v-if="isSubscriptionActive">Close without saving</translate>
            <translate v-else>Quit</translate>
          </v-btn>
          <v-btn
            v-if="isSubscriptionActive"
            class="mx-2 my-1"
            color="primary"
            :disabled="!valid"
            :loading="saveLoading"
            @click="saveExclusionZone({ close: true })"
          >
            <translate>Save and close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { kml } from '@tmcw/togeojson';

import CUSTOM_HANDLING_API_CODES from '@/main';

import LayoutTooltip from '@/layouts/LayoutTooltip.vue';

import APIService from '@/services/api';
import { debounce } from '@/services/api.helper';

import {
  SET_ACTIVE_TAB_NS,
  APPLICATION_TAB,
  SET_STATUS_NS,
  APPLICATION_STATUS,
  SET_LOADING_SNACKBAR_NS,
  takeApplicationScreenshot,
} from '@/store/application';
import { SET_FLIGHT_SELECTED_NS } from '@/store/flights';
import {
  SET_FLIGHT_EXCLUSION_ZONE_NS,
  ADD_EXCLUSION_ZONE_MARKER_NS,
  REMOVE_LAST_EXCLUSION_ZONE_MARKER_NS,
  CLEAR_EXCLUSION_ZONE_MARKERS_NS,
  SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_NS,
  SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_NS,
  SET_MAP_DRAW_AREA_NS,
  SET_MAP_AREA_NS,
  SET_MAP_STATUS_NS,
  MAP_STATUS,
  SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE_NS,
  SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP_NS,
} from '@/store/map';

import { featureCollectionToMultiPolygon } from '@/components/Map/MapboxGL.helper';

export const EXCLUSION_ZONE_KML_FEATURES_PROPERTIES = {
  flightArea: {
    id: 'flight-area',
    name: 'Zone de vol',
    description: 'Zone de vol',
    fill: '#1d6ff2',
    'fill-opacity': 0.2,
    stroke: '#1d6ff2',
    'stroke-width': 4.5,
  },
  exclusionArea: {
    id: 'exclusion-area',
    name: "Zone d'exclusion des tiers",
    fill: '#ed1c24',
    'fill-opacity': 0.2,
    stroke: '#ed1c24',
    'stroke-width': 4.5,
  },
  radiusLines: {
    stroke: '#ed1c24',
    'stroke-width': 3,
  },
  radiusLinesCenters: { 'marker-symbol': 'radius-lines-centers' },
};

function isValidPolygon(geometry) {
  return (
    (geometry.type === 'Polygon' && geometry.coordinates[0].length > 3)
    || (geometry.type === 'MultiPolygon' && geometry.coordinates[0].every((c) => c.length > 3))
  );
}

export function parseExclusionZoneKml(kmlData, markerIds, turf) {
  const parsedKML = new DOMParser().parseFromString(kmlData, 'text/xml');
  const geojson = kml(parsedKML);
  const { features } = geojson;
  let flightArea;
  let exclusionArea;
  const markers = [];
  const radiusLines = [];
  const radiusLinesCenters = [];
  features.forEach((feature) => {
    if (feature.properties.id === EXCLUSION_ZONE_KML_FEATURES_PROPERTIES.flightArea.id) {
      // Set flight area
      flightArea = feature;
      if (flightArea.geometry && flightArea.geometry.type === 'GeometryCollection') {
        const flightAreaGeometriesFeatures = [];
        flightArea.geometry.geometries.forEach((geometry) => {
          if (isValidPolygon(geometry)) {
            flightAreaGeometriesFeatures.push(turf.feature(geometry));
          }
        });
        flightArea = turf.featureCollection(flightAreaGeometriesFeatures);
      }
    } else if (feature.properties.id === EXCLUSION_ZONE_KML_FEATURES_PROPERTIES.exclusionArea.id) {
      // Set exclusion area
      exclusionArea = feature;
      if (exclusionArea.geometry && exclusionArea.geometry.type === 'GeometryCollection') {
        const exclusionAreaGeometriesFeatures = [];
        exclusionArea.geometry.geometries.forEach((geometry) => {
          if (isValidPolygon(geometry)) {
            exclusionAreaGeometriesFeatures.push(turf.feature(geometry));
          }
        });
        exclusionArea = turf.featureCollection(exclusionAreaGeometriesFeatures);
      }
    } else if (markerIds.includes(feature.properties.id)) {
      // Set markers positions (pilot, take-off and landing)
      markers.push({ markerId: feature.properties.id, point: feature.geometry });
    } else {
      // Set radius Lines and centers
      if (feature.geometry.type === 'LineString') {
        radiusLines.push(feature);
      }
      if (feature.geometry.type === 'Point') {
        radiusLinesCenters.push(feature);
      }
    }
  });
  const exclusionZoneData = {
    flightArea,
    exclusionArea,
    markers,
    radiusLines: turf.featureCollection(radiusLines),
    radiusLinesCenters: turf.featureCollection(radiusLinesCenters),
  };
  return exclusionZoneData;
}

export default {
  name: 'FlightExclusionZoneForm',
  components: { LayoutTooltip },
  props: {
    flight: {
      type: Object,
      required: true,
    },
  },
  data() {
    let formData;
    const exclusionZoneToEdit = this.flight.exclusion_zone;
    if (exclusionZoneToEdit) {
      formData = {
        scenario: exclusionZoneToEdit.flight_scenario,
        flyingHeight: exclusionZoneToEdit.flying_height,
        droneSpeed: exclusionZoneToEdit.drone_speed,
        droneWeight: exclusionZoneToEdit.drone_weight,
        droneProtectionDevice: exclusionZoneToEdit.drone_protection_device,
      };
    } else {
      formData = {
        scenario: this.flight.scenario || 'S3',
        flyingHeight: this.flight.flying_height,
        droneSpeed: 2,
        droneWeight: this.flight.maximum_drone_weight,
        droneProtectionDevice: false,
      };
    }
    return {
      exclusionZoneToEdit,
      ...formData,
      closeDialog: false,
      valid: true,
      setMapLoading: false,
      screenshotLoading: false,
      downloadLoading: false,
      saveLoading: false,
      exclusionArea: null,
      radiusLines: null,
      radiusLinesCenters: null,
      scenarios: [
        { value: 'S1', text: 'Scenario S1' },
        { value: 'S2', text: 'Scenario S2' },
        { value: 'S3', text: 'Scenario S3' },
        { value: 'STS-01', text: 'Scenario STS-01' },
        { value: 'STS-02', text: 'Scenario STS-02' },
      ],
      alert: true,
      rules: { positiveNumber: [(v) => v > 0 || this.$gettext('A positive integer is required.')] },
      style: {
        subscriptionDisclaimer: 'body-2 mt-4 mb-0',
        introductionText: 'body-2 mt-4 mb-1',
        formRow: 'mx-1 px-2 py-2 py-md-0',
        mapMarkerRow: 'px-0 py-2',
        mapMarkerIcon: 'map-marker-icon',
        mapMarkerLabel: 'pl-2 body-1',
        legendSymbolFlightArea: 'mr-2 flex-shrink-0 zet-legend-symbol-flight-area',
        legendLabelFlightArea: 'mb-0 zet-legend-label-flight-area',
        legendSymbolExclusionZone: 'mr-2 flex-shrink-0 zet-legend-symbol-exclusion-zone',
        legendLabelExclusionZone: 'mb-0 zet-legend-label-exclusion-zone',
        legendSymbolForbiddenZone: 'mr-2 flex-shrink-0 zet-legend-symbol-forbidden-zone',
        legendLabelForbiddenZone: 'mb-0 zet-legend-label-forbidden-zone',
      },
      kmlFeaturesProperties: EXCLUSION_ZONE_KML_FEATURES_PROPERTIES,
      maxDistanceDronePilot: 0.1,
      bufferRadius: 0,
      flightExclusionZoneError: null,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    isSubscriptionActive() {
      return this.$store.getters['authentication/isSubscriptionActive'];
    },
    allMapMarkers() {
      return this.$store.getters['map/mapMarkers'];
    },
    texts() {
      return {
        introduction: this.$gettext(`For S1 and S3 scenarios, an exploitant must ensure that no
          third party enters in a area called "third party exclusion zone". You can use this tool
          to obtain the minimum dimensions of this zone and its ground representation, depending on
          your flight parameters and in accordance with the calculation described in <a
          href="https://www.ecologie.gouv.fr/sites/default/files/Guide_categorie_Specifique_0.pdf"
          target="_blank">the DSAC specific category guide</a> (Annexe 7). <a target="_blank"
          href="https://clearance.aero/calculer-la-zone-dexclusion-des-tiers-dun-vol-de-drone/">
          Learn more about third party exclusion zone</a>.`),
        subscriptionDisclaimer: this.$gettext(`This module allows you to prepare a document
            justifying your in of a third-party exclusion zone (<a
            href="https://clearance.aero/calculer-la-zone-dexclusion-des-tiers-dun-vol-de-drone"
            target="_blank">detailed article</a>). However, KML recording and export features
            require an active subscription.`),
      };
    },
    labels() {
      return {
        scenario: this.$gettext('Flight scenario'),
        flyingHeight: this.$gettext('Flight max height'),
        droneSpeed: this.$gettext('Drone speed'),
        droneWeight: this.$gettext('Drone weight'),
        droneProtectionDevice: this.$gettext('Drone equipped with a third party protection device'),
      };
    },
    hints() {
      return {
        flyingHeight: this.$gettext('Maximum flight height above gound'),
        droneSpeed: this.$gettext('Maximum horizontal speed of the drone'),
        droneWeight: this.$gettext('Weight of the drone'),
      };
    },
    markers() {
      return this.$store.state.map.exclusionZoneMarkers;
    },
    markersPilotsCount() {
      return this.markers.pilotPosition.mapMarkersCount;
    },
    flightArea() {
      const featureCollection = this.$store.state.map.currentArea;
      return featureCollectionToMultiPolygon(featureCollection);
    },
    displayLegendForbiddenZone() {
      return this.$store.state.map.displayForbiddenZone;
    },
    displayAlertForbiddenZone() {
      return (
        this.displayLegendForbiddenZone
          || this.markersPilotsCount === 0
          || this.percentageForbiddenZone === 0
          || this.percentageForbiddenZone === null
      );
    },
    percentageForbiddenZone() {
      return this.$store.state.map.exclusionZoneForbiddenAreaPercentage;
    },
    typeLegendForbiddenZone() {
      if (!this.markersPilotsCount) {
        return {
          color: 'primary',
          text: this.$gettextInterpolate(
            this.$gettext(`Please indicate pilot's positions on the map to check the respect of the
              legal distance (%{ buffer } m between pilot and drone in %{ scenario } scenario)`),
            { buffer: this.maxDistanceDronePilot, scenario: this.scenario },
          ),
          icon: 'mdi-alert-circle',
        };
      }
      if (this.percentageForbiddenZone > 0) {
        return {
          color: 'warning',
          text: this.$gettextInterpolate(
            this.$gettext(`%{ percentage } % of your flight area is %{ buffer } m away of pilot
              positions`),
            { percentage: this.percentageForbiddenZone, buffer: this.maxDistanceDronePilot },
          ),
          icon: 'mdi-alert',
        };
      }
      if (this.percentageForbiddenZone === 0) {
        return {
          color: 'success',
          text: this.$gettextInterpolate(
            this.$gettext(`Maximum distance between drone and pilot (%{ buffer } m in %{ scenario }
              scenario) are not respect in all flight area`),
            { buffer: this.maxDistanceDronePilot, scenario: this.scenario },
          ),
          icon: 'mdi-check-bold',
        };
      }
      return undefined;
    },
    isExclusionUpdatedFromMap() {
      return this.$store.state.map.exclusionZoneUpdatedFromMap;
    },
  },
  watch: {
    flightArea(newArea) {
      if (newArea) {
        this.debouncedUpdateExclusionZone({ that: this, flightArea: newArea });
        this.$store.dispatch(
          SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_NS,
          {
            flightArea: this.flightArea,
            buffer: this.maxDistanceDronePilot,
          },
        );
      } else {
        this.clearFlightExclusionArea();
        this.$store.dispatch(CLEAR_EXCLUSION_ZONE_MARKERS_NS);
      }
    },
    scenario() {
      this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
    },
    flyingHeight() {
      this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
    },
    droneSpeed() {
      this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
    },
    droneWeight() {
      this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
    },
    tags() {
      this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
    },
    droneProtectionDevice() {
      this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
    },
    maxDistanceDronePilot(newValue) {
      this.alert = true;
      this.$store.dispatch(
        SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_NS,
        {
          flightArea: this.flightArea,
          buffer: newValue,
        },
      );
    },
    displayLegendForbiddenZone() {
      if (this.displayAlertForbiddenZone) {
        this.alert = true;
      }
    },
    displayAlertForbiddenZone(newValue) {
      this.alert = newValue;
    },
    markersPilotsCount() {
      this.alert = true;
    },
    isExclusionUpdatedFromMap(newValue) {
      if (newValue) {
        this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
        this.$store.dispatch(SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP_NS, false);
      }
    },
  },
  async created() {
    if (this.exclusionZoneToEdit) {
      await this.setExclusionZoneToEditMap();
    }
    if (!this.flightArea) {
      const area = this.$turf.multiPolygon(this.flight.areas.map((a) => a.geometry.coordinates));
      this.$store.dispatch(SET_MAP_DRAW_AREA_NS, area);
    }
  },
  mounted() {
    this.$refs.form.validate();
  },
  methods: {
    debouncedUpdateExclusionZone: debounce(
      ({ that, flightArea }) => that.updateExclusionZone(flightArea),
      500, // debounce for 0.5s
    ),
    closeAlert() {
      this.$store.dispatch(SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_NS, { flightArea: null });
    },
    formatFlightArea(flightArea) {
      const area = { ...flightArea };
      if (area.coordinates) {
        area.coordinates = this.formatFlightAreaCoordinates(area.coordinates);
      }
      return area;
    },
    formatFlightAreaCoordinates(coordinates) {
      return coordinates.map((coord) => {
        if (typeof coord === 'string') {
          return JSON.parse(coord);
        }
        if (typeof coord === 'number') {
          return coord;
        }
        return this.formatFlightAreaCoordinates(coord);
      });
    },
    formatMarkerPositions(markers) {
      const positions = [];
      if (markers?.length) {
        markers.forEach((marker) => {
          const { lng, lat } = marker.getLngLat();
          const point = this.$turf.point([lng, lat]);
          positions.push(point.geometry);
        });
      }
      return positions;
    },
    formatExclusionZoneParameters() {
      const data = {
        flying_height: this.flyingHeight ? this.flyingHeight : null,
        drone_speed: this.droneSpeed ? this.droneSpeed : null,
        drone_weight: this.droneWeight ? this.droneWeight : null,
        drone_protection_device: this.droneProtectionDevice,
        flight_scenario: this.scenario,
        pilots_positions: this.formatMarkerPositions(this.allMapMarkers['pilot-position']),
        take_off_positions: this.formatMarkerPositions(this.allMapMarkers['take-off-point']),
        landing_positions: this.formatMarkerPositions(this.allMapMarkers['landing-point']),
        flight_area: this.formatFlightArea(this.flightArea),
      };
      return data;
    },
    async updateExclusionZone(area) {
      this.flightExclusionZoneError = null;
      if (!this.valid) {
        this.clearFlightExclusionArea();
      } else {
        this.$store.commit(SET_LOADING_SNACKBAR_NS, true);
        await APIService.flightExclusionZoneGeometry(
          this.flight.id,
          this.formatExclusionZoneParameters(),
        )
          .then((response) => {
            this.bufferRadius = response?.data?.exclusion_area_distance;
            if (response?.data?.maximal_distance_to_drone) {
              this.maxDistanceDronePilot = response?.data?.maximal_distance_to_drone;
            }
            if (response?.data?.covered_percentage !== null) {
              // Transform the covered percentage to uncovered percentage
              this.$store.dispatch(
                SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE_NS,
                100 - response.data.covered_percentage,
              );
            }
            if (this.bufferRadius) {
              const exclusionZone = this.$turf.buffer(area, this.bufferRadius, { units: 'meters' });
              this.makeBufferRadiusLines(
                exclusionZone.geometry,
                area,
                this.bufferRadius,
              );
              this.$store.dispatch(SET_FLIGHT_EXCLUSION_ZONE_NS, exclusionZone);
              this.$gettextInterpolate(
                this.$gettext('Third exclusion zone with distance of %{bufferRadius} m'),
                { bufferRadius: this.bufferRadius },
              );
            }
          })
          .catch((e) => {
            const code = e.response?.data?.code;
            if (code === CUSTOM_HANDLING_API_CODES.EXCLUSION_AREA_INVALID_CRITERIA) {
              this.clearFlightExclusionArea();
              this.$store.dispatch(SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE_NS, null);
              this.flightExclusionZoneError = e.response.data.detail;
            }
          })
          .finally(() => {
            this.$store.commit(SET_LOADING_SNACKBAR_NS, false);
          });
      }
    },
    makeBufferRadiusLines(exclusionGeometry, flightGeometry, radius) {
      let polygons = [];
      const linesFeatures = [];
      const linesCentersFeatures = [];
      if (exclusionGeometry.type === 'Polygon') {
        polygons = [exclusionGeometry.coordinates];
      } else if (exclusionGeometry.type === 'MultiPolygon') {
        polygons = exclusionGeometry.coordinates;
      }
      polygons.forEach((polygon) => {
        // Find longest segment in each exclusion area
        const coords = polygon[0];
        let longestDistance = 0;
        let longestSegmentStart;
        let longestSegmentEnd;
        for (let i = 0; i < (coords.length - 1); i += 1) {
          const segmentStart = coords[i];
          const segmentEnd = coords[i + 1];
          const distance = this.$turf.distance(segmentStart, segmentEnd);
          if (distance > longestDistance) {
            longestDistance = distance;
            longestSegmentStart = segmentStart;
            longestSegmentEnd = segmentEnd;
          }
        }
        // Find corresponding longest segment in flight area (nearest)
        const flightPoints = this.$turf.explode(flightGeometry);
        const nearestToLongestSegmentStart = this.$turf.nearestPoint(
          longestSegmentStart, flightPoints,
        );
        const nearestToLongestSegmentEnd = this.$turf.nearestPoint(longestSegmentEnd, flightPoints);
        // Drawing a perpendicular line between the exclusion zone segment and the flight
        const bufferRadiusLineStart = this.$turf.center(
          this.$turf.points([longestSegmentStart, longestSegmentEnd]),
        );
        const bufferRadiusLineEnd = this.$turf.center(
          this.$turf.featureCollection([nearestToLongestSegmentStart, nearestToLongestSegmentEnd]),
        );
        const bufferRadiusLine = this.$turf.lineString([
          this.$turf.getCoord(bufferRadiusLineStart),
          this.$turf.getCoord(bufferRadiusLineEnd),
        ]);
        bufferRadiusLine.properties = this.kmlFeaturesProperties.radiusLines;
        linesFeatures.push(bufferRadiusLine);
        // Adding a label with radius value in the middle of the line
        const bufferRadiusLineCenter = this.$turf.center(bufferRadiusLine);
        bufferRadiusLineCenter.properties = {
          name: `${radius} m`,
          ...this.kmlFeaturesProperties.radiusLinesCenters,
        };
        linesCentersFeatures.push(bufferRadiusLineCenter);
      });
      if (linesFeatures.length) {
        this.radiusLines = this.$turf.featureCollection(linesFeatures);
        this.radiusLinesCenters = this.$turf.featureCollection(linesCentersFeatures);
        this.$store.dispatch(
          SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_NS,
          {
            lines: this.radiusLines,
            centers: this.radiusLinesCenters,
          },
        );
      }
    },
    addMapMarker(id) {
      if (this.flightArea) {
        const center = this.$turf.pointOnFeature(this.flightArea);
        this.$store.dispatch(
          ADD_EXCLUSION_ZONE_MARKER_NS,
          { markerId: id, point: center.geometry },
        ).then(() => {
          if (id === this.markers.pilotPosition.id) {
            this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
          }
          this.$store.dispatch(
            SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_NS,
            {
              flightArea: this.flightArea,
              buffer: this.maxDistanceDronePilot,
            },
          );
        });
      } else {
        this.showMessage(this.$gettext('Draw a flight area before adding markers'), 'error');
      }
    },
    removeLastMapMarker(id) {
      this.$store.dispatch(REMOVE_LAST_EXCLUSION_ZONE_MARKER_NS, { markerId: id })
        .then(() => {
          if (id === this.markers.pilotPosition.id) {
            this.debouncedUpdateExclusionZone({ that: this, flightArea: this.flightArea });
          }
          this.$store.dispatch(
            SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_NS,
            {
              flightArea: this.flightArea,
              buffer: this.maxDistanceDronePilot,
            },
          );
        });
    },
    async takeScreenShot() {
      this.screenshotLoading = true;
      takeApplicationScreenshot()
        .then((canvas) => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
          const kmlName = this.$gettextInterpolate(
            this.$gettext('%{name}_exclusion_zone.jpeg'),
            { name: this.flight.id },
          );
          a.download = kmlName;
          a.click();
        })
        .finally(() => {
          this.screenshotLoading = false;
        });
    },
    saveExclusionZone({ close = false }) {
      this.saveLoading = true;
      APIService.uploadFlightExclusionZone(
        this.flight.id,
        this.formatExclusionZoneParameters(),
      )
        .then(() => {
          this.showMessage(
            this.$gettext('Third party exclusion zone saved.'),
            'success',
          );
          if (close) this.close();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    async downloadKml() {
      this.downloadLoading = true;
      await APIService.downloadFlightExclusionAreaKML(
        this.flight.id,
        this.formatExclusionZoneParameters(),
      )
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          const kmlName = this.$gettextInterpolate(
            this.$gettext('%{name}_exclusion_zone.kml'),
            { name: this.flight.id },
          );
          link.setAttribute('download', kmlName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    clearFlightExclusionArea() {
      this.$store.dispatch(SET_FLIGHT_EXCLUSION_ZONE_NS, null);
      this.$store.dispatch(
        SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_NS, { lines: null, centers: null },
      );
      this.$store.dispatch(
        SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_NS, { flightArea: null, buffer: null },
      );
    },
    close() {
      this.clearFlightExclusionArea();
      this.$store.dispatch(CLEAR_EXCLUSION_ZONE_MARKERS_NS);
      this.$store.dispatch(SET_MAP_AREA_NS, null);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
      this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.MISSION);
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_FLIGHT_SELECTED_NS, { flightId: this.flight.id, reload: true });
    },
    async setExclusionZoneToEditMap() {
      this.setMapLoading = true;
      await APIService.downloadFlightExclusionZone(this.flight.id)
        .then((response) => {
          const fileReader = new FileReader();
          fileReader.readAsText(response.data);
          fileReader.addEventListener('load', () => {
            const markerIds = Object.values(this.markers).map((m) => m.id);
            const {
              flightArea,
              markers,
            } = parseExclusionZoneKml(fileReader.result, markerIds, this.$turf);
            this.$store.dispatch(SET_MAP_DRAW_AREA_NS, flightArea);
            markers.forEach((marker) => {
              this.$store.dispatch(ADD_EXCLUSION_ZONE_MARKER_NS, marker);
            });
          });
        })
        .catch(() => {
          this.showMessage(
            this.$gettext('Error during third party exclusion previously saved loading.'),
            'error',
          );
        })
        .finally(() => {
          this.setMapLoading = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.map-marker-icon {
  min-height: 42px !important;
  min-width: 42px !important;
  padding: 8px 0px !important;
}

</style>
