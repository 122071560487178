import Vue from 'vue';

import APIService from '@/services/api';

const namespace = 'cotations';

const FETCH_COTATIONS = 'FETCH_COTATIONS';
const GET_COTATIONS = 'GET_COTATIONS';
const SET_COTATIONS = 'SET_COTATIONS';
const RESET_COTATIONS = 'RESET_COTATIONS';

export const GET_COTATIONS_NS = `${namespace}/${GET_COTATIONS}`;
export const RESET_COTATIONS_NS = `${namespace}/${RESET_COTATIONS}`;

function initialState() {
  return {
    cotations: [],
    loadingCotations: false,
    loadingCotationsError: null,
  };
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    [RESET_COTATIONS](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [FETCH_COTATIONS](state) {
      Vue.set(state, 'loadingCotations', true);
    },
    [SET_COTATIONS](state, { cotations = [], loadingCotationsError }) {
      Vue.set(state, 'loadingCotations', false);
      Vue.set(state, 'cotations', cotations);
      Vue.set(state, 'loadingCotationsError', loadingCotationsError);
    },
  },
  actions: {
    [RESET_COTATIONS]({ commit }) {
      commit(RESET_COTATIONS);
    },
    [GET_COTATIONS]({ commit }) {
      commit(FETCH_COTATIONS);
      APIService.getCotations()
        .then(({ data }) => {
          commit(SET_COTATIONS, { cotations: data });
        })
        .catch((error) => {
          commit(SET_COTATIONS, { loadingCotationsError: error });
        });
    },
  },
};
