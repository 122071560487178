<template>
  <v-row
    no-gutters
    id="layout-listing"
  >

    <v-col
      v-if="navigationDrawerVisible"
      cols="12"
      sm="12"
      md="4"
      lg="3"
      xl="2"
      id="navigation-drawer"
      class="pa-5"
    >
      <!-- Navigation drawer slot -->
      <slot
        name="navigation-drawer"
        :switchDisplay="switchDisplay"
      />
    </v-col>
    <v-col
      v-if="contentsVisible"
      cols="12"
      sm="12"
      md="8"
      lg="9"
      xl="10"
      id="contents"
      class="py-5 px-3 px-md-5"
      :class="{ 'extra-left-padding': !isMobileBreakpoint }"
    >
      <!-- Button to switch back to drawer for mobile -->
      <div
        v-if="isMobileBreakpoint"
        class="mb-2"
      >
        <v-icon @click="switchDisplay()">
          mdi-arrow-left
        </v-icon>
      </div>
      <!-- Active content slot -->
      <slot name="contents" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LayoutSettings',
  props: {
    activateContent: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return { contentActivated: false };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    navigationDrawerVisible() {
      return !this.isMobileBreakpoint || !this.contentActivated;
    },
    contentsVisible() {
      return !this.isMobileBreakpoint || this.contentActivated;
    },
  },
  mounted() {
    if (this.activateContent) {
      // Mount layout with the content activated by default
      this.contentActivated = true;
    }
  },
  methods: {
    switchDisplay() {
      this.contentActivated = !this.contentActivated;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
#layout-listing {
  min-height: 100%;
  max-width: 100%;
}
#navigation-drawer {
  border-right: 1px lighten($color-info, 62) solid;
}
#contents.extra-left-padding {
  padding-left: 2.2em !important;
}

</style>
