<template>
  <v-combobox
    v-model="selectedTags"
    :items="tags"
    :search-input.sync="search"
    :rules="maxLengthRule"
    :label="label"
    :placeholder="placeholder"
    :hint="hint"
    multiple
    hide-selected
    persistent-hint
    class="mt-0"
    @change="changeTags()"
  >
    <template v-slot:no-data>
      <v-list-item v-if="search">
        <span v-translate>Aucun résultat pour</span>&nbsp;
        <v-chip>
          <span class="truncated-chip-content">
            {{ search }}
          </span>
        </v-chip>&nbsp;
        <span v-translate>. Tapez</span>&nbsp;
        <kbd>
          <span v-translate>Entrée</span>
        </kbd>&nbsp;
        <span v-translate>pour l'ajouter.</span>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item, parent, selected }">
      <v-chip
        v-if="item"
        :input-value="selected"
      >
        <span class="pr-2 truncated-chip-content">
          {{ item }}
        </span>
        <v-icon
          small
          @click="parent.selectItem(item)"
        >
          close
        </v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-chip>
          {{ item }}
        </v-chip>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'FlightTags',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTags: this.selected,
      search: null,
      hint: this.$gettext('Choose a tag or type to add a new one.'),
      maxLengthRule: [(values) => {
        let tagIsValid = true;
        values.forEach((v) => {
          if (v.length > 32) {
            tagIsValid = this.$gettext('Do not exceed 32 characters.');
          } else if (!v.trim().length) {
            tagIsValid = this.$gettext('There are no characters.');
          }
        });
        return tagIsValid;
      }],
    };
  },
  computed: {
    tags() {
      return this.$store.state.exploitants.tagNames;
    },
  },
  watch: {
    selected(newSelected) {
      this.selectedTags = newSelected;
    },
  },
  methods: {
    changeTags() {
      this.$emit('update-selected', this.selectedTags.filter((s) => s.length));
    },
  },
};
</script>
