<template>
  <div class="constraints-popup px-2 pt-0 pt-md-2">
    <!-- TITLE  -->
    <div class="subtitle-1 px-2 text-center">
      {{ constraintsPopupTexts.title }}
    </div>
    <!-- LOADER -->
    <div
      v-if="loading"
      class="d-flex justify-center pt-2"
    >
      <v-progress-circular
        size="24"
        width="2"
        indeterminate
      />
    </div>
    <template v-else>
      <div class="body-2 pt-2">
        <div class="py-1">
          <span
            class="text--secondary"
            v-translate
          >
            Constraints analysis at point:
          </span>
          <span class="text-no-wrap">
            <span>
              {{ contraintsPoint.lat | coordDMS(false)}}
            </span>
            <span>
              {{ contraintsPoint.lng | coordDMS(true)}}
            </span>
          </span>
        </div>
        <div v-if="elevation">
          <span
            class="text--secondary"
            v-translate
          >
            Ground elevation:
          </span>
          <span>
            {{ elevation | meter }}
          </span>
        </div>
      </div>
      <div v-if="constraints.length">
        <!-- HEADER  -->
        <div class="body-2 py-2 text--secondary">
          <span>
            {{ constraintsPopupTexts.header }}
          </span>
          <span v-if="!lockedResults">
            {{ constraintsPopupTexts.headerExtra }}
          </span>
        </div>
        <!-- CONSTRAINTS LIST  -->
        <div class="constraints-list">
          <v-alert
            v-for="(constraint, index) in constraints"
            :key="index"
            class="my-2 mx-0 pa-0 grey lighten-4"
            :color="getConstraintColor(constraint.height_min)"
            border="left"
            colored-border
          >
            <v-list-item dense>
              <v-list-item-action class="mr-3">
                <v-icon>
                  {{ getConstraintIcon(constraint.code_type) }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content class="body-2 text--primary">
                <span>
                  {{ constraint.name }}
                </span>
              </v-list-item-content>
              <v-list-item-action
                v-if="!lockedResults"
                class="caption text--secondary font-italic"
              >
                <span>
                  {{ constraint.height_min | meter }}
                </span>
                <span v-if="constraint.code_type === 'CTR' && !constraint.is_military_ctr">
                  <translate>or 50 m</translate>
                </span>
              </v-list-item-action>
            </v-list-item>
          </v-alert>
        </div>
      </div>
      <!-- DETAILS FOR NO RESULTS  -->
      <div
        v-else-if="details"
        class="py-4 body-2 text--secondary"
      >
        {{ details }}
      </div>
      <div
        v-else
        class="pt-4 body-2 text--secondary"
      >
        {{ constraintsPopupTexts.noConstraintsDetected }}
      </div>
    </template>
    <div>
      <!-- CREATE FLIGHT  -->
      <div
        v-if="!lockedResults && !loading && !details.length"
        class="mt-2 d-flex align-center justify-end"
      >
        <div class="pr-2 pl-4 caption text--primary">
          {{ constraintsPopupTexts.createFlight }}
        </div>
        <div>
          <v-btn
            @click.stop="setStatusCreateFlight()"
            color="pa-2 red"
            elevation="0"
            small
            dark
            fab
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-else
        class="mt-2 caption text-center text--primary font-italic"
      >
        <div v-if="constraints.length">
          {{ constraintsPopupTexts.createFlightLocked }}
        </div>
      </div>
    </div>
    <!-- MORE INFOS  -->
    <div
      v-if="!loading && !details.length"
      class="px-3 py-2"
    >
      <a
        class="d-flex"
        @click.stop="expandInfos = !expandInfos"
      >
        <v-icon small>
          mdi-information-outline
        </v-icon>
        <span class="caption text--secondary text-decoration-underline ml-1">
          {{ constraintsPopupTexts.moreInfoTitle }}
        </span>
      </a>
      <v-expand-transition>
        <div
          v-show="expandInfos"
          v-html="constraintsPopupTexts.moreInfo"
          class="pt-2"
        />
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import API from '@/services/api';
import store from '@/store/';
import { REFRESH_LAYER_POP_UP_NS } from '@/store/map';

import { CONSTRAINT_DICT_ICON } from '@/settings';

export default Vue.extend({
  store,
  props: { id: String },
  data() {
    return {
      loading: true,
      lockedResults: undefined,
      constraints: [],
      details: '',
      elevation: undefined,
      expandInfos: false,
    };
  },
  computed: {
    contraintsPoint() {
      return this.$store.state.map.contraintsPoint;
    },
    constraintsPopupTexts() {
      return {
        title: this.$gettext('Constraint areas'),
        header: this.$gettextInterpolate(
          this.$ngettext(
            'A constraint area is located here.',
            '%{n} constraint areas are located here.',
            this.constraints.length,
          ),
          { n: this.constraints.length },
        ),
        headerExtra: this.$ngettext(
          'Flight restrictions may therefore be present starting from the indicated flying height.',
          'Flight restrictions may therefore be present starting from the indicated flying heights.',
          this.constraints.length,
        ),
        createFlight: this.$gettext('Create a mission to get a detailed analysis'),
        createFlightLocked: this.$gettext('Subscribe for a detailed analysis outside registered areas.'),
        noConstraintsDetected: this.$gettext('No constraint areas detected'),
        moreInfoTitle: this.$gettext('More info'),
        moreInfo: this.$gettext('<div>The constraint areas detected by Clearance can sometimes differ'
          + ' from those displayed by Geoportail, this is can be due to the lack of official'
          + ' and exploitable data sources for certain types of areas. See for example our'
          + ' <a href="https://clearance.aero/vols-de-drone-en-agglomeration-noubliez-pas-la-declaration/" target="_blank">'
          + ' article on agglomerations</a>.</div>'),
      };
    },
  },
  watch: {
    contraintsPoint(newValue) {
      this.getConstraintsAtPoint(newValue);
    },
  },
  mounted() {
    this.getConstraintsAtPoint(this.contraintsPoint);
  },
  methods: {
    getConstraintsAtPoint(position) {
      this.loading = true;
      this.constraints = [];
      API.getConstraintsAtPoint({ lng: position.lng, lat: position.lat })
        .then(({ data }) => {
          this.lockedResults = data.locked_results;
          this.constraints = data.constraints;
          this.details = data.details;
          this.elevation = data.elevation;
          this.$store.dispatch(REFRESH_LAYER_POP_UP_NS, { layerId: this.id, popupId: this.id });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getConstraintIcon(codeType) {
      return CONSTRAINT_DICT_ICON[codeType] || 'icon-alert_empty';
    },
    getConstraintColor(height) {
      if (this.lockedResults) {
        return 'grey darken-1';
      }
      if (height === 0) {
        return 'red';
      }
      if (height <= 120) {
        return 'orange';
      }
      return 'green';
    },
    setStatusCreateFlight() {
      const position = this.$turf.point([
        this.contraintsPoint.lng,
        this.contraintsPoint.lat,
      ]);
      const area = this.$turf.buffer(position, 30, { units: 'meters' });
      this.$emit('set-status-create-flight', area);
    },
  },
});
</script>

<style lang="scss">
@use "sass:map";

.constraints.mapboxgl-popup {
  @include disable-text-selection;

  .constraints-popup {
    min-width: 320px;
  }

  .mapboxgl-popup-content {
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
  }

  .mapboxgl-popup-close-button {
    height: 24px;
    width: 24px;
    margin: 4px;
    padding: 4px;
    border-radius: 50%;
    font-size: 16px;
  }

  .constraints-list {
    max-height: 340px;
    overflow-y: scroll;
  }

}

</style>
