<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
  >
    <v-progress-circular
      indeterminate
      color="white"
      :size="70"
      :width="3"
    />
  </v-container>
</template>

<script>
import { defaultRoutes } from '@/settings';

import { LOGIN_SESAME_NS } from '@/store/authentication';

export default {
  name: 'TokenConnection',
  data() {
    return { sesame: '', idSelected: null };
  },
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
  },
  methods: {
    async loginToken() {
      if (this.sesame !== undefined) {
        await this.$store.dispatch(LOGIN_SESAME_NS, { sesame: this.sesame });

        const userType = this.user && this.user.user_type;
        const interfaceVersion = this.user.authority_interface_version;
        this.$router.addUserRoutes(userType, interfaceVersion);

        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else if (this.$store.state.authentication && this.$store.state.authentication.logged) {
          let route = defaultRoutes[userType];
          if (this.idSelected) {
            route += `?idSelected=${this.idSelected}`;
          }
          this.$router.push(route);
        }
      }
    },
  },
  created() {
    this.sesame = this.$route.query.sesame;
    this.idSelected = this.$route.query.idSelected;
    this.loginToken();
  },
};
</script>
