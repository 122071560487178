import APIService from '@/services/api';

import { WEBSOCKET_PAYLOAD_TYPES } from '@/settings';

import { SET_SNACKBAR_MESSAGE_NS, SHOW_SNACKBAR_NS } from '@/store/application';
import { SET_APPROVAL_MISSION_SHEET_NS } from '@/store/approvals';
import { GET_WEBSOCKET_AUTHENT_NS } from '@/store/authentication';
import {
  SET_FLIGHT_CONSTRAINT_SHEET_STATUS_NS,
  SET_FLIGHT_APPROVAL_MISSION_SHEET_NS,
  UPDATE_FLIGHT_REQUEST_EMAIL_NS,
} from '@/store/flights';
import { ADD_OR_UPDATE_LIVESTREAM_NS } from '@/store/livestreams';
import {
  FORMAT_DRONES_LAST_POSITION_NS,
  FORMAT_PLANES_LAST_POSITION_NS,
  SET_TRACKERS_ALERTS_NS,
} from '@/store/map';
import { STORE_NEW_NOTIFICATION_NS } from '@/store/notifications';

const WEBSOCKET_SERVER_URL = process.env.VUE_APP_WEBSOCKET_SERVER_URL;

function onNewNotification(store, data) {
  store.dispatch(STORE_NEW_NOTIFICATION_NS, { newNotification: data });
  store.dispatch(
    SET_SNACKBAR_MESSAGE_NS,
    {
      message: data.payload?.notification_title || 'Nouvelle notification reçue',
      color: 'success',
      long: true,
    },
  );
  store.dispatch(SHOW_SNACKBAR_NS);
}

function onNewPositions(store, data) {
  store.dispatch(FORMAT_DRONES_LAST_POSITION_NS, data.positions);
}

function onNewTrackerAlert(store, data) {
  store.dispatch(SET_TRACKERS_ALERTS_NS, data.alerts);
}

function onNewPlanePositions(store, data) {
  store.dispatch(FORMAT_PLANES_LAST_POSITION_NS, data.positions);
}

function onAuthorityMissionSheetUpdate(store, data) {
  store.dispatch(SET_APPROVAL_MISSION_SHEET_NS, {
    approvalId: data.approval_id,
    missionSheet: data.mission_sheet,
  });
}

function onDronistMissionSheetUpdate(store, data) {
  store.dispatch(SET_FLIGHT_APPROVAL_MISSION_SHEET_NS, {
    flightId: data.flight_id,
    approvalId: data.approval_id,
    missionSheet: data.mission_sheet,
  });
}

function onApprovalMissionSheetUpdate(store, data) {
  if (store.getters['authentication/isUserAuthority']) {
    onAuthorityMissionSheetUpdate(store, data);
  } else {
    onDronistMissionSheetUpdate(store, data);
  }
}

function onFlightConstraintSheetUpdate(store, data) {
  store.dispatch(SET_FLIGHT_CONSTRAINT_SHEET_STATUS_NS, {
    flightId: data.flight_id,
    constraintSheetStatus: data.constraint_sheet.status,
    updatedAt: data.constraint_sheet.updated_at,
    toUpdate: data.to_update,
  });
}

function onEmailRequestUpdate(store, data) {
  store.dispatch(UPDATE_FLIGHT_REQUEST_EMAIL_NS, {
    flightId: data.flight_id,
    emailRequest: data.email_request,
  });
}

function onLivestreamUpdate(store, data) {
  store.dispatch(ADD_OR_UPDATE_LIVESTREAM_NS, { livestream: data.livestream });
}

function setupWebsocketConnection(store, websocketToken) {
  const socketConnection = new WebSocket(WEBSOCKET_SERVER_URL);

  socketConnection.onmessage = (e) => {
    const data = JSON.parse(e.data);
    const { payload_type: payloadType } = data;
    if (!payloadType) return;

    switch (payloadType) {
      case WEBSOCKET_PAYLOAD_TYPES.TRACKING:
        onNewPositions(store, data);
        break;
      case WEBSOCKET_PAYLOAD_TYPES.TRACKING_ALERT:
        onNewTrackerAlert(store, data);
        break;
      case WEBSOCKET_PAYLOAD_TYPES.TRACKING_PLANES:
        onNewPlanePositions(store, data);
        break;
      case WEBSOCKET_PAYLOAD_TYPES.MISSION_SHEET_UPDATE:
        onApprovalMissionSheetUpdate(store, data);
        break;
      case WEBSOCKET_PAYLOAD_TYPES.CONSTRAINT_SHEET_UPDATE:
        onFlightConstraintSheetUpdate(store, data);
        break;
      case WEBSOCKET_PAYLOAD_TYPES.EMAIL_REQUEST_UPDATE:
        onEmailRequestUpdate(store, data);
        break;
      case WEBSOCKET_PAYLOAD_TYPES.LIVESTEAM_UPDATE:
        onLivestreamUpdate(store, data);
        break;
      default:
        onNewNotification(store, data);
    }
  };

  // on connect send token to authentify
  socketConnection.addEventListener('open', () => {
    socketConnection.send(JSON.stringify({ token: websocketToken }));
    APIService.sendWebsocketLog(true);
  });

  socketConnection.addEventListener('close', () => {
    store.dispatch(GET_WEBSOCKET_AUTHENT_NS);
  });
}

const websocketNotificationsPlugin = (store) => {
  store.watch(
    (state) => state.authentication.websocketToken,
    (token) => {
      if (token === '') return;
      // Open websocket connection and setup it
      setupWebsocketConnection(store, token);
    },
  );
};

export default websocketNotificationsPlugin;
