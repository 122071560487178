<template>
  <v-dialog
    v-model="showLayerImportDialog"
    max-width="620"
    persistent
  >
    <v-card class="default--dialog__card">
      <v-card-title v-translate>
        Import my data
      </v-card-title>
      <v-card-text class="py-0">
        <v-stepper
          v-model="e1"
          class="kml-import__stepper"
        >
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
              class="pl-1"
            >
              <translate>Import</translate>
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="e1 > 2"
              :disabled="importValid"
              :editable="!importValid && !loading"
              :rules="[() => valid]"
              step="2"
              class="px-6"
            >
              <translate>Data</translate>
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="e1 > 3"
              step="3"
              class="pr-2"
            >
              <translate>Validation</translate>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <!-- ---------------- Step 1 ---------------- -->
            <v-stepper-content
              step="1"
              class="pa-0 mb-2"
            >
              <v-card
                color="grey lighten-4"
                min-height="280px"
                elevation="0"
              >
                <v-row
                  align="center"
                  justify="space-between"
                >
                  <v-col>
                    <p class="px-8 pt-4 pb-1 mb-1">
                      {{ texts.uploadDataMessage }}
                    </p>
                    <div
                      v-if="kmlFile == null"
                      class="px-8 mt-8"
                    >
                      <label
                        for="my-data-file-input"
                        class="form__kml-file-input-label primary--text d-flex justify-end"
                        v-translate
                      >
                        Import a KML
                      </label>
                      <input
                        id="my-data-file-input"
                        ref="kmluploader"
                        accept=".kml"
                        class="hidden"
                        type="file"
                        @click="resetKmlUploader()"
                        @change="(event) => uploadKmlFile(event.target.files[0])"
                      />
                    </div>
                    <div
                      v-else
                      class="d-flex mx-8 mt-5 style-white-borders"
                    >
                      <v-col
                        cols="3"
                        class="pl-4 pr-2"
                        v-translate
                      >
                        Chosen file :
                      </v-col>
                      <v-col
                        v-if="kmlFile != null"
                        cols="9"
                        class="pl-0 pr-4 primary--text"
                      >
                        {{ kmlFileName }}
                      </v-col>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <div class="d-flex align-center justify-end">
                <!-- ---------------- Actions ---------------- -->
                <v-btn
                  text
                  color="info"
                  class="mt-3"
                  @click.native="close()"
                >
                  <translate>Cancel</translate>
                </v-btn>
                <v-btn
                  :disabled="importValid"
                  :loading="sendingImportRequest"
                  text
                  color="primary"
                  class="mt-3"
                  @click="proceedToData()"
                >
                  <translate>Next</translate>
                </v-btn>
              </div>
            </v-stepper-content>
            <!-- ---------------- Step 2 ---------------- -->
            <v-stepper-content
              step="2"
              class="pa-0 mb-2"
            >
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-card
                  class="form-extension"
                  color="grey lighten-4"
                  min-height="280px"
                  elevation="0"
                >
                  <!-- Name -->
                  <v-row
                    no-gutters
                    align="center"
                    class="px-4"
                  >
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <translate class="primary--text font-weight-medium">
                        Name of the layer
                      </translate>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        v-model="layerName"
                        class="pa-0"
                        :rules="[nameRequired]"
                      />
                    </v-col>
                  </v-row>
                  <!-- Default visibility -->
                  <v-row
                    no-gutters
                    align="center"
                    justify="start"
                  >
                    <v-checkbox
                      v-model="layerVisibility"
                      color="primary"
                      class="ml-4"
                      hide-details
                    >
                      <template v-slot:label>
                        <span
                          class="body-2 primary--text font-weight-medium"
                          v-translate
                        >
                          Visible by default
                        </span>
                      </template>
                    </v-checkbox>
                  </v-row>
                  <p class="pl-4 pt-4 pb-1">
                    {{ texts.entityFoundMessage }}
                    «<span
                      v-if="kmlFile != null"
                      class="font-weight-medium"
                    >
                      {{ kmlFileName }}
                    </span>»
                    <br>
                    <span class="caption font-italic grey--text text--darken-1">
                      {{ texts.selectedEntityMessage }}
                    </span>
                  </p>
                  <!-- --------- Points ----------- -->
                  <template class="form-extension">
                    <v-checkbox
                      v-if="pointFeatures.length"
                      key="point-selection"
                      v-model="selectedTypes"
                      :label="`${pointFeatures.length} ${pointsLabel}`"
                      :rules="[selectedTypeRequired]"
                      value="point"
                      class="pl-4 pb-2"
                      color="primary"
                      hide-details
                    />
                    <!-- Label and Zone color -->
                    <v-row
                      v-if="selectedTypes.includes('point')"
                      class="mx-4"
                    >
                      <v-col
                        cols="11"
                        sm="5"
                      >
                        <v-checkbox
                          :disabled="noPointLabel"
                          v-model="selectedLabel"
                          color="primary"
                          class="mr-4 mb-10"
                          value="pointLabel"
                          @change="entityNull()"
                          hide-details
                        >
                          <template v-slot:label>
                            <translate
                              class="body-2 font-weight-medium"
                              :class="{'primary--text' : !noPointLabel}"
                            >
                              Display a label
                            </translate>
                          </template>
                        </v-checkbox>
                        <v-select
                          v-if="selectedLabel.includes('pointLabel')&& !noPointLabel"
                          v-model="entityLabels.pointLabel"
                          :items="entityListLabels.pointLabel"
                          :no-data-text="texts.noFieldFound"
                        >
                          <template v-slot:label>
                            {{ texts.labelField }}
                          </template>
                        </v-select>
                        <span
                          v-if="noPointLabel"
                          class="grey--text text--darken-1"
                        >
                          {{ texts.noFieldFound }}
                        </span>
                      </v-col>
                      <v-col
                        class="p-0"
                        cols="11"
                        sm="7"
                        md="7"
                      >
                        <translate class="primary--text font-weight-medium pb-2">
                          Fill color
                        </translate>
                        <div class="align-self-start">
                          <v-color-picker
                            v-model="entityColors.fillColorPoint"
                            mode="rgba"
                            hide-inputs
                            height="60"
                            canvas-height="40"
                            class="mt-2"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <!-- --------- Polygons ----------- -->
                    <v-checkbox
                      v-if="polygonFeatures.length"
                      key="polygon-selection"
                      v-model="selectedTypes"
                      :label="`${polygonFeatures.length} ${polygonsLabel}`"
                      :rules="[selectedTypeRequired]"
                      value="polygon"
                      class="pl-4 pb-2"
                      color="primary"
                      hide-details
                    />
                    <!-- Label and Zone color -->
                    <v-row
                      v-if="selectedTypes.includes('polygon')"
                      class="mx-4"
                    >
                      <v-col
                        cols="11"
                        sm="5"
                      >
                        <v-checkbox
                          :disabled="noPolygonLabel"
                          v-model="selectedLabel"
                          color="primary"
                          class="mr-4 mb-10"
                          value="polygonLabel"
                          @change="entityNull()"
                          hide-details
                        >
                          <template v-slot:label>
                            <translate
                              class="body-2 font-weight-medium"
                              :class="{'primary--text' : !noPolygonLabel}"
                            >
                              Display a label
                            </translate>
                          </template>
                        </v-checkbox>
                        <v-select
                          v-if="selectedLabel.includes('polygonLabel') && !noPolygonLabel"
                          v-model="entityLabels.polygonLabel"
                          :items="entityListLabels.polygonLabel"
                          :no-data-text="texts.noFieldFound"
                        >
                          <template v-slot:label>
                            {{ texts.labelField }}
                          </template>
                        </v-select>
                        <span
                          v-if="noPolygonLabel"
                          class="grey--text text--darken-1"
                        >
                          {{ texts.noFieldFound }}
                        </span>
                      </v-col>
                      <v-col
                        class="p-0"
                        cols="11"
                        sm="7"
                        md="7"
                      >
                        <translate class="primary--text font-weight-medium pb-2">
                          Fill color
                        </translate>
                        <div class="align-self-start">
                          <v-color-picker
                            v-model="entityColors.fillColorPolygon"
                            mode="rgba"
                            hide-inputs
                            height="60"
                            canvas-height="40"
                            class="mt-2"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <!-- --------- Lines ----------- -->
                    <v-checkbox
                      v-if="lineFeatures.length"
                      key="line-selection"
                      v-model="selectedTypes"
                      :label="`${lineFeatures.length} ${linesLabel}`"
                      :rules="[selectedTypeRequired]"
                      value="line"
                      class="pl-4 pb-2"
                      color="primary"
                      hide-details
                    />
                    <!-- Label and Zone color -->
                    <v-row
                      v-if="selectedTypes.includes('line')"
                      class="mx-4"
                    >
                      <v-col
                        cols="11"
                        sm="5"
                      >
                        <v-checkbox
                          :disabled="noLineLabel"
                          v-model="selectedLabel"
                          color="primary"
                          class="mr-4 mb-10"
                          value="lineLabel"
                          @change="entityNull()"
                          hide-details
                        >
                          <template v-slot:label>
                            <span
                              class="body-2 font-weight-medium"
                              :class="{'primary--text' : !noLineLabel}"
                            >
                              <translate>Display a label</translate>
                            </span>
                          </template>
                        </v-checkbox>
                        <v-select
                          v-if="selectedLabel.includes('lineLabel') && !noLineLabel"
                          v-model="entityLabels.lineLabel"
                          :items="entityListLabels.lineLabel"
                          :no-data-text="texts.noFieldFound"
                        >
                          <template v-slot:label>
                            {{ texts.labelField }}
                          </template>
                        </v-select>
                        <span
                          v-if="noLineLabel"
                          class="grey--text text--darken-1"
                        >
                          {{ texts.noFieldFound }}
                        </span>
                      </v-col>
                      <v-col
                        class="p-0"
                        cols="11"
                        sm="7"
                        md="7"
                      >
                        <translate class="primary--text font-weight-medium pb-2">
                          Fill color
                        </translate>
                        <div class="align-self-start">
                          <v-color-picker
                            v-model="entityColors.fillColorLine"
                            mode="rgba"
                            hide-inputs
                            height="60"
                            canvas-height="40"
                            class="mt-2"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <p
                      v-if="!valid && selectedTypeRequired !== true"
                      class="mt-3 mb-0 px-4 caption error--text"
                    >
                      {{ texts.selectedTypeRequiredMessage }}
                    </p>
                  </template>
                </v-card>
              </v-form>
              <div class="d-flex justify-end align-center">
                <!-- ---------------- Actions ---------------- -->
                <v-btn
                  text
                  color="info"
                  class="mt-3"
                  @click.native="close()"
                >
                  <translate>Cancel</translate>
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  text
                  color="primary"
                  class="mt-3"
                  @click="proceedToValidation()"
                >
                  <translate>Import</translate>
                </v-btn>
              </div>
            </v-stepper-content>
            <!-- ---------------- Step 3 ---------------- -->
            <v-stepper-content
              step="3"
              class="pa-0 mb-2"
            >
              <v-card
                color="grey lighten-4"
                min-height="280px"
                elevation="0"
              >
                <v-row
                  no-gutters
                  align="center"
                  class="px-4"
                >
                  <v-col>
                    <p class="pl-4 pt-4 pb-1 mb-1">
                      {{ texts.recapIntroMessage }}
                    </p>
                    <ul class="ml-4 mt-0 mb-1 pr-3">
                      <li class="mb-2">
                        <div>
                          {{ texts.layerName }}
                          <span class="font-weight-medium ml-1">
                            {{ layerName }}
                          </span>
                        </div>
                      </li>
                      <li class="mb-2">{{ layerVisibilityMessage }}</li>
                      <li class="mb-2">{{ texts.recapLabelChosen }}</li>
                      <ul class="ml-4 mt-0 mb-1 pr-3">
                        <li v-if="selectedTypes.includes('point')">
                          <translate>Point :</translate>
                          <span class="font-weight-medium ml-1">
                            <template v-if="entityLabels.pointLabel">
                              {{ entityLabels.pointLabel }}
                            </template>
                            <span
                              v-else
                              v-translate
                            >
                              No label selected
                            </span>
                          </span>
                        </li>
                        <li v-if="selectedTypes.includes('polygon')">
                          <translate>Polygon :</translate>
                          <span class="font-weight-medium ml-1">
                            <template v-if="entityLabels.polygonLabel">
                              {{ entityLabels.polygonLabel }}
                            </template>
                            <span
                              v-else
                              v-translate
                            >
                              No label selected
                            </span>
                          </span>
                        </li>
                        <li v-if="selectedTypes.includes('line')">
                          <translate>Line :</translate>
                          <span class="font-weight-medium ml-1">
                            <template v-if="entityLabels.lineLabel">
                              {{ entityLabels.lineLabel }}
                            </template>
                            <span
                              v-else
                              v-translate
                            >
                              No label selected
                            </span>
                          </span>
                        </li>
                      </ul>
                    </ul>
                    <v-alert
                      type="info"
                      text
                      class="mt-3 mb-2 py-2 mx-3 grey--text text--darken-2 caption"
                    >
                      {{ texts.delayMessage }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card>
              <div class="d-flex align-center justify-end">
                <!-- ---------------- Actions ---------------- -->
                <v-btn
                  :disabled="loading"
                  text
                  color="info"
                  class="mt-3"
                  @click.native="close()"
                >
                  <translate>Cancel</translate>
                </v-btn>
                <v-btn
                  :loading="loading"
                  :disabled="!valid"
                  text
                  color="primary"
                  class="mt-3"
                  @click="createLayer()"
                >
                  <translate>Import</translate>
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { kml } from '@tmcw/togeojson';

import { RESET_DATA_STORES_NS } from '@/store/application';
import { SHOW_LAYER_IMPORT_DIALOG_NS } from '@/store/map';

import API from '@/services/api';

export default {
  name: 'ImportMapLayerData',
  data() {
    return {
      e1: 1,
      valid: false,
      importValid: true,
      layerName: '',
      selectedTypes: [],
      selectedLabel: [],
      layerVisibility: true,
      sendingImportRequest: false,
      loading: false,
      uploadedFeaturesFromKml: [],
      kmlFileName: null,
      kmlFile: null,
      entityColors: {
        fillColorPoint: { r: 0, g: 0, b: 255, a: 0.2 },
        fillColorPolygon: { r: 0, g: 0, b: 255, a: 0.2 },
        fillColorLine: { r: 0, g: 0, b: 255, a: 0.2 },
      },
      entityLabels: {
        pointLabel: null,
        polygonLabel: null,
        lineLabel: null,
      },
      entityListLabels: {
        pointLabel: null,
        polygonLabel: null,
        lineLabel: null,
      },
    };
  },
  computed: {
    showLayerImportDialog() {
      return this.$store.state.map.showLayerImportDialog;
    },
    polygonFeatures() {
      if (this.uploadedFeaturesFromKml) {
        const features = this.$turf.featureCollection(
          this.uploadedFeaturesFromKml.filter((feature) => feature.geometry.type === 'Polygon'),
        );
        // Remove z coordinates if necessary
        return this.$turf.truncate(features, { coordinates: 2 }).features;
      }
      return [];
    },
    polygonsLabel() {
      if (this.polygonFeatures.length > 1) {
        return this.$gettext('Polygones');
      }
      return this.$gettext('Polygone');
    },
    noPolygonLabel() {
      return !this.entityListLabels.polygonLabel;
    },
    pointFeatures() {
      const features = this.$turf.featureCollection(
        this.uploadedFeaturesFromKml.filter((feature) => feature.geometry.type === 'Point'),
      );
      // Remove z coordinates if necessary
      return this.$turf.truncate(features, { coordinates: 2 }).features;
    },
    pointsLabel() {
      if (this.pointFeatures.length > 1) {
        return this.$gettext('Points');
      }
      return this.$gettext('Point');
    },
    noPointLabel() {
      return !this.entityListLabels.pointLabel;
    },
    lineFeatures() {
      if (this.uploadedFeaturesFromKml) {
        const features = this.$turf.featureCollection(
          this.uploadedFeaturesFromKml.filter((feature) => feature.geometry.type === 'LineString'),
        );
        // Remove z coordinates if necessary
        return this.$turf.truncate(features, { coordinates: 2 }).features;
      }
      return [];
    },
    linesLabel() {
      if (this.lineFeatures.length > 1) {
        return this.$gettext('Lines');
      }
      return this.$gettext('Line');
    },
    noLineLabel() {
      return !this.entityListLabels.lineLabel;
    },
    nameRequired() {
      return this.layerName !== '' || this.texts.nameRequiredMessage;
    },
    selectedTypeRequired() {
      return this.selectedTypes.length > 0 || this.texts.selectedTypeRequiredMessage;
    },
    layerVisibilityMessage() {
      if (this.layerVisibility) {
        return this.$gettext('Layer display by default');
      }
      return this.$gettext('Layer not visible by default');
    },
    texts() {
      return {
        uploadDataMessage: this.$gettext(`To add your data as a layer on the map, choose a kml 
        file to import.`),
        selectedTypeRequiredMessage: this.$gettext('A choice is required!'),
        entityFoundMessage: this.$gettext('The entities found in the file'),
        selectedEntityMessage: this.$gettext('Please select the entities to be imported'),
        labelField: this.$gettext('Field used for the label'),
        recapIntroMessage: this.$gettext('Summary of data added:'),
        recapLabelChosen: this.$gettext('Label based on the field:'),
        layerName: this.$gettext("Layer's name:"),
        nameRequiredMessage: this.$gettext('A name is required'),
        noFieldFound: this.$gettext('No field found'),
        delayMessage: this.$gettext(`It can take up to 15 minutes to load the new layer. The name 
        of the layer in the list of layers appears well before the geometry is displayed on the 
        map.`),
      };
    },
  },
  watch: {
    kmlFileName(newValue) {
      if (newValue) {
        this.layerName = this.kmlFileName.split('.').shift();
      }
    },
  },
  methods: {
    entityNull() {
      if (!this.selectedLabel.includes('pointLabel')) {
        this.entityLabels.pointLabel = null;
      }
      if (!this.selectedLabel.includes('polygonLabel')) {
        this.entityLabels.polygonLabel = null;
      }
      if (!this.selectedLabel.includes('lineLabel')) {
        this.entityLabels.lineLabel = null;
      }
    },
    proceedToData() {
      this.importValid = false;
      this.valid = true;
      this.e1 = 2;
    },
    proceedToValidation() {
      if (this.$refs.form.validate()) {
        this.e1 = 3;
      }
    },
    resetKmlUploader() {
      this.$refs.kmluploader.value = null;
    },
    addKeyPropertiesToEntityLabel(kmlToGeoJson, geomType) {
      let entityLabel = null;
      const featuresContain = kmlToGeoJson.features.filter(
        (feature) => feature.geometry.type === geomType,
      );
      if (
        featuresContain.length !== 0 && Object.keys(featuresContain[0].properties).length !== 0
      ) {
        entityLabel = Object.keys(featuresContain[0].properties);
      }
      return entityLabel;
    },
    uploadKmlFile(kmlFile) {
      if (kmlFile) {
        this.sendingImportRequest = true;
        const fileReader = new FileReader();
        fileReader.readAsText(kmlFile);
        fileReader.addEventListener('load', () => {
          const parsedKML = new DOMParser().parseFromString(fileReader.result, 'text/xml');
          const kmlToGeoJson = kml(parsedKML);
          // Filter GeoJSON: only keep point, polygon and line features
          kmlToGeoJson.features = kmlToGeoJson.features.filter(
            (feature) => (
              feature.geometry
              && ['Point', 'Polygon', 'LineString'].includes(feature.geometry.type)
            ),
          );
          const nbrFeatures = kmlToGeoJson.features.length;
          if (nbrFeatures === 0) {
            this.showMessage(
              this.$gettext('No entities of type Polygon, Point or Line were found in the file.'),
              'error',
            );
          } else {
            // Retrieve properties
            this.entityListLabels.pointLabel = this.addKeyPropertiesToEntityLabel(
              kmlToGeoJson, 'Point',
            );
            this.entityListLabels.polygonLabel = this.addKeyPropertiesToEntityLabel(
              kmlToGeoJson, 'Polygon',
            );
            this.entityListLabels.lineLabel = this.addKeyPropertiesToEntityLabel(
              kmlToGeoJson, 'LineString',
            );

            this.kmlFile = kmlFile;
            this.kmlFileName = kmlFile.name;
            this.uploadedFeaturesFromKml = kmlToGeoJson.features;
            this.importValid = false;
          }
        });
      }
      this.sendingImportRequest = false;
    },
    fillColorString(fillColor) {
      return `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, ${fillColor.a.toFixed(4)})`;
    },
    buildCustomDataLayersParams() {
      const formData = new FormData();
      formData.append('file', this.kmlFile);
      formData.append('name', this.layerName);
      formData.append('identifier', this.layerName);
      formData.append('description', this.layerName);
      formData.append('default_visibility ', this.layerVisibility);

      const features = [];
      this.selectedTypes.forEach((type) => {
        if (type === 'point') {
          features.push({
            type: 'circle',
            color: this.fillColorString(this.entityColors.fillColorPoint),
            label: this.entityLabels.pointLabel,
          });
        }

        if (type === 'line') {
          features.push({
            type: 'line',
            color: this.fillColorString(this.entityColors.fillColorLine),
            label: this.entityLabels.lineLabel,
          });
        }

        if (type === 'polygon') {
          features.push({
            type: 'fill',
            color: this.fillColorString(this.entityColors.fillColorPolygon),
            label: this.entityLabels.polygonLabel,
          });
        }
      });
      formData.append('features', JSON.stringify(features));
      return formData;
    },
    close() {
      this.e1 = 1;
      this.valid = true;
      this.importValid = true;
      this.layerName = null;
      this.selectedTypes = [];
      this.selectedLabel = [];
      this.layerVisibility = true;
      this.loading = false;
      this.sendingImportRequest = false;
      this.uploadedFeaturesFromKml = [];
      this.kmlFileName = null;
      this.kmlFile = null;
      this.entityColors = {
        fillColorPoint: { r: 0, g: 0, b: 255, a: 0.2 },
        fillColorPolygon: { r: 0, g: 0, b: 255, a: 0.2 },
        fillColorLine: { r: 0, g: 0, b: 255, a: 0.2 },
      };
      this.entityLabels = {
        pointLabel: null,
        polygonLabel: null,
        lineLabel: null,
      };
      this.entityListLabels = {
        pointLabel: null,
        polygonLabel: null,
        lineLabel: null,
      };
      this.$store.commit(SHOW_LAYER_IMPORT_DIALOG_NS, false);
    },
    async createLayer() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const params = this.buildCustomDataLayersParams();
        await API.createCustomDataLayer(params)
          .then(() => {
            this.showMessage(this.$gettext('Layer created successfully.'), 'success');
            this.close();
            this.$store.dispatch(RESET_DATA_STORES_NS);
            this.$router.push({ path: '/' });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};

</script>

<style
  lang="scss"
  scoped
>
.kml-import__stepper {
  box-shadow: none !important
}

.point-section {
  margin-left: 2em;
  margin-right: 2em;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0em;
}

.style-white-borders {
  border-radius: 10px;
  background-color: white;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.form-extension {
  max-height: 600px;
  overflow-y: scroll;
}

</style>
