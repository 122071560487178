<template>
  <div class="fill-height">
    <!-- ----------- Flight create / edit Form ------------ -->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="primary--form py-2"
      :class="{ 'mr-2': !isMobileBreakpoint }"
    >
      <!-- Header -->
      <v-row class="primary--form__row ma-0">
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <div class="flight-name__field">
                <StatusIcon status="planned" />
                <v-text-field
                  v-model="flight.name"
                  :rules="rules.name"
                  :placeholder="placeholders.name"
                  class="text-field_primary--text pa-0"
                />
                <div class="form-close">
                  <div :class="closeFormTextDisplay">
                    <translate>
                      Cancel
                    </translate>
                  </div>
                  <v-btn
                    icon
                    @click="close()"
                  >
                    <v-icon color="primary">close</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-row class="primary--form__row pb-2 ma-0">
        <v-col cols="12">
          <!-- ----- Dates ---- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <v-date-picker
                v-model="flightDates"
                no-title
                required
                full-width
                class="elevation-0"
                range
                locale="fr"
                first-day-of-week="1"
              />
            </v-col>
          </v-row>
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 pb-4"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label pt-2">
                PERIOD
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <v-input
                class="pt-2"
                hide-details
              >
                <span>
                  {{ flight.date_start | date }}
                  -
                  {{ flight.date_end | date }}
                </span>
              </v-input>
            </v-col>
          </v-row>
          <!-- ----- Hour Start ---- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label">
                START TIME
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <TimePicker
                :time="flight.time_start"
                @update:time="flight.time_start = $event"
                :rules="rules.required"
              />
            </v-col>
          </v-row>
          <!-- ----- Hour End ---- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label">
                END TIME
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <TimePicker
                :time="flight.time_end"
                @update:time="flight.time_end = $event"
                :rules="rules.required"
              />
            </v-col>
          </v-row>
          <!-- ----- Duration ---- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label">
                FLIGHT DURATION
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <TimePicker
                :time="expectedDuration"
                @update:time="expectedDuration = $event"
                :rules="rules.required"
                :minuteInterval="15"
                appendIcon="mdi-timelapse"
              />
            </v-col>
          </v-row>
          <!-- ----- Drones ---- -->
          <v-row
            v-if="hasDrones"
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label">
                DRONE
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <v-autocomplete
                v-model="flight.drone_ids"
                :loading="loadingDrones"
                :items="drones"
                item-text="label"
                item-value="id"
                :no-data-text="$gettext('No drone found')"
                class="pa-0"
                multiple
              >
                <template
                  v-if="drones.length > 1"
                  v-slot:prepend-item
                >
                  <SelectAllSlot
                    :allSelected="allDronesSelected"
                    @update:allSelected="allDronesSelected = $event"
                  />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- ----- Pilots ---- -->
          <v-row
            v-if="hasPilots"
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label">
                PILOTE
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <v-autocomplete
                v-model="flight.pilot_ids"
                :loading="loadingPilots"
                :items="pilots"
                item-text="full_name"
                item-value="id"
                :no-data-text="$gettext('No pilot found')"
                class="pa-0"
                multiple
              >
                <template
                  v-if="pilots.length > 1"
                  v-slot:prepend-item
                >
                  <SelectAllSlot
                    :allSelected="allPilotsSelected"
                    @update:allSelected="allPilotsSelected = $event"
                  />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- ----- Off Sight - People Gathering ---- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.duo.cols"
              :sm="formColumns.duo.sm"
              :md="formColumns.duo.md"
              :lg="formColumns.duo.lg"
              :xl="formColumns.duo.xl"
            >
              <v-radio-group
                v-model="pilotInSight"
                :rules="rules.inSight"
                class="pa-0"
                row
              >
                <v-radio :value="true">
                  <template v-slot:label>
                    <div
                      class="primary--form__label"
                      :class="pilotInSight === true ? '' : 'inherit-color'"
                      v-translate
                    >
                      FLIGHT IN SIGHT
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="false">
                  <template v-slot:label>
                    <div
                      class="primary--form__label"
                      :class="pilotInSight === false ? '' : 'inherit-color'"
                      v-translate
                    >
                      FLIGHT OFF SIGHT
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              :cols="formColumns.duo.cols"
              :sm="formColumns.duo.sm"
              :md="formColumns.duo.md"
              :lg="formColumns.duo.lg"
              :xl="formColumns.duo.xl"
            >
              <v-checkbox
                v-model="flight.people_gathering"
                class="pa-0"
              >
                <template v-slot:label>
                  <div
                    class="primary--form__label"
                    v-translate
                  >
                    GATHERING OF PEOPLE
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- --------- SubExploitant ----------- -->
          <v-row
            v-if="subExploitantsAvailable"
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <span class="primary--form__label text-uppercase">
                {{ entityName.singular }}
              </span>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <v-select
                v-model="flight.sub_exploitant"
                :rules="rules.subExploitant"
                :items="subExploitants"
                item-text="name"
                item-value="id"
                class="pa-0"
              />
            </v-col>
          </v-row>
          <!-- --------- Tags ----------- -->
          <v-row
            v-if="isUserEnterprise"
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <translate class="primary--form__label">
                TAGS
              </translate>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <FlightTags
                :selected="flight.tags"
                @update-selected="(selected) => (flight.tags = selected)"
              />
            </v-col>
          </v-row>
          <!-- --------- Internal Note (details) ----------- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <span
                class="primary--form__label"
                v-translate
              >
                PRIVATE MEMO
              </span>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <v-textarea
                v-model="flight.details"
                rows="2"
                :placeholder="placeholders.internalNote"
              />
            </v-col>
          </v-row>
          <!-- --------- Flight Area Alert and KML Import ----------- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-4"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <div class="d-flex align-center justify-center">
                <v-icon
                  class="pr-2"
                  color="primary"
                >
                  mdi-map-plus
                </v-icon>
                <div class="primary--text">
                  <span>
                    {{ flightAreasAlert }}
                  </span>
                  <v-icon
                    class="pr-1"
                    color="black"
                    small
                  >
                    mdi-pencil
                  </v-icon>
                  <template v-if="!isMobileBreakpoint">
                    <translate>or</translate>
                    <label
                      for="kml-file-input"
                      class="ml-1"
                    >
                      <span
                        v-translate
                        class="form__kml-file-input-label"
                      >
                        upload a KML file
                      </span>.
                    </label>
                    <input
                      id="kml-file-input"
                      ref="kmluploader"
                      class="hidden"
                      accept=".kml"
                      type="file"
                      @click="resetKmlUploader()"
                      @change="(event) => uploadKmlFile(event.target.files[0])"
                    />
                    <div>
                      <span v-translate>Tip:</span>
                      <span class="font-weight-medium px-1">CTRL+Z</span>
                      <span v-translate>or click on</span>
                      <v-icon
                        color="black"
                        small
                        class="px-1"
                      >
                        mdi-reply
                      </v-icon>
                      <span v-translate>to remove the last area update.</span>
                    </div>
                  </template>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- --------- Flight Areas ----------- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.label.cols"
              :sm="formColumns.label.sm"
              :md="formColumns.label.md"
              :lg="formColumns.label.lg"
              :xl="formColumns.label.xl"
            >
              <span class="primary--form__label">
                {{ flightAreasLabel }}
              </span>
            </v-col>
            <v-col
              :cols="formColumns.input.cols"
              :sm="formColumns.input.sm"
              :md="formColumns.input.md"
              :lg="formColumns.input.lg"
              :xl="formColumns.input.xl"
            >
              <FlightFormAreas
                :flightId="flightToEdit? flightToEdit.id : undefined"
                :flightAreas="flightAreas"
                @update-flight-areas-names="updateFlightAreasNames()"
              />
              <v-row
                no-gutters
                align="center"
                class="mt-2"
              >
                <v-col cols="5">
                  <span
                    class="primary--form__label"
                    v-translate
                  >
                    GLOBAL SURFACE
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  :class="{'text--disabled': !flightAreas.length}"
                >
                  {{ globalFlightAreasSurface }}
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align="center"
              >
                <v-col cols="5">
                  <span
                    class="primary--form__label"
                    v-translate
                  >
                    GLOBAL EXTENT
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  :class="{'text--disabled': !flightAreas.length}"
                >
                  {{ globalFlightAreasMaxExtent }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- --------- Clip Flight to network ----------- -->
          <v-row
            v-if="userRestrictFlights"
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 py-1"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-checkbox
                    v-on="on"
                    v-model="restrictFlight"
                    class="mt-1"
                  >
                    <template v-slot:label>
                      <div class="primary--form__label">
                        <translate>CLIP FLIGHT ACCORDING TO NETWORK</translate>
                      </div>
                    </template>
                  </v-checkbox>
                </template>
                <span>
                  {{ translatedBoxClipNetwork }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <!-- Night flying info -->
          <v-row
            v-if="isNightFlying"
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 pt-2 pb-1"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <v-skeleton-loader
                v-if="loadingNightFlyingTimes"
                type="list-item-two-line"
              />
              <v-alert
                v-else
                icon="mdi-information-outline"
                outlined
                border="left"
                color="warning"
              >
                <div>
                  {{ texts.nightFlying }}
                </div>
                <div>
                  {{ texts.dodgeNightFlying }}
                </div>
              </v-alert>
            </v-col>
          </v-row>
          <!-- --------- Action Buttons (Create / Edit) ----------- -->
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="px-1 px-sm-3 pt-2 pb-1"
          >
            <v-col
              :cols="formColumns.single.cols"
              :sm="formColumns.single.sm"
              :md="formColumns.single.md"
              :lg="formColumns.single.lg"
              :xl="formColumns.single.xl"
            >
              <FlightFormActions
                :isEditing="isEditing"
                :valid="valid"
                :loading="loading"
                @create-flight="createFlight()"
                @update-flight="updateFlight()"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <!-- ----------- Subscription dialog ------------ -->
    <v-dialog
      v-model="showSubscribeDialog"
      width="650"
    >
      <SubscriptionCard
        :title="subscriptionDialogText.title"
        :content="subscriptionDialogText.content"
        :confirm-text="subscriptionDialogText.confirmText"
        @cancel="showSubscribeDialog=false"
      />
    </v-dialog>
    <!-- ----------- First Flights limit dialog ------------ -->
    <v-dialog
      v-model="showLimitSubscriptionDialog"
      width="650"
    >
      <SubscriptionCard
        :title="limitSubscriptionDialogText.title"
        :content="limitSubscriptionDialogText.content"
        :confirm-text="limitSubscriptionDialogText.confirmText"
        @cancel="showLimitSubscriptionDialog=false"
      />
    </v-dialog>
    <!-- --------- Enterprise KML Import Dialog ---------- -->
    <v-dialog
      v-model="showKmlImportDialog"
      max-width="650"
      persistent
    >
      <KmlImportForm
        :features="uploadedFeaturesFromKml"
        :flight="flight"
        :initialPilotInSight="pilotInSight"
        :restrictFlight="userRestrictFlights ? restrictFlight : null"
        :file-name="kmlFileName"
        @close="showKmlImportDialog=false"
        @import="$emit('update-id-selected', null)"
        @error="(error) => handleCustomErrors(error)"
        @validate-form="$refs.form.validate()"
      />
    </v-dialog>
  </div>
</template>

<script>
import { kml } from '@tmcw/togeojson';

import CUSTOM_HANDLING_API_CODES from '@/main';

import API from '@/services/api';

import { debounce } from '@/services/api.helper';

import {
  APPLICATION_STATUS,
  APPLICATION_DISPLAY,
  APPLICATION_SPLIT,
  SET_LOADED_KML_NS,
  SET_STATUS_NS,
  SET_ACTIVE_SPLIT_NS,
} from '@/store/application';
import { UPDATE_DISPLAY_ONLY_MY_FLIGHTS_NS } from '@/store/authentication';
import { GET_SIMPLIFIED_DRONES_NS } from '@/store/drones';
import { FETCH_TAG_NAMES_NS } from '@/store/exploitants';
import {
  GET_FLIGHT_DETAILS_NS,
  SET_FLIGHT_CONSTRAINTS_NS,
  SET_SLICE_FLIGHT_AREA_NS,
  SET_UPDATING_FLIGHT_ID_NS,
  UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS,
} from '@/store/flights';
import {
  MAP_STATUS,
  SET_MAP_STATUS_NS,
  SET_FLIGHT_AREAS_NAME_NS,
  SET_FLIGHT_AREAS_MEASUREMENTS_INFO_NS,
} from '@/store/map';
import { GET_SIMPLIFIED_PILOTS_NS } from '@/store/pilots';

import FlightTags from '@/components/Flights/FlightTags.vue';
import KmlImportForm from '@/components/Flights/KmlImportForm.vue';
import SubscriptionCard from '@/components/Flights/SubscriptionCard.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import TimePicker from '@/components/TimePicker.vue';
import FlightFormAreas from '@/components/Flights/FlightFormAreas.vue';
import FlightFormActions from '@/components/Flights/FlightFormActions.vue';
import SelectAllSlot from '@/components/SelectAllSlot.vue';

import { BIG_DISPLAY_COLUMN_SIZES, MEDIUM_DISPLAY_COLUMN_SIZES } from '@/layouts/SplitFormResponsiveSizes';

export default {
  name: 'FlightForm',
  components: {
    TimePicker,
    FlightTags,
    KmlImportForm,
    SubscriptionCard,
    StatusIcon,
    FlightFormAreas,
    FlightFormActions,
    SelectAllSlot,
  },
  props: {
    flightToEdit: { type: Object },
    originalFlight: { type: Object },
  },
  data() {
    return {
      valid: true,
      flight: null,
      loading: false,
      flightDates: [],
      rules: {
        required: [
          (value) => !!value || this.$gettext('A value is required'),
        ],
        name: [
          (v) => !!v || this.$gettext('A name for the mission is required'),
        ],
        inSight: [
          (v) => v !== null || this.$gettext('The flight visibility must be selected'),
        ],
        subExploitant: [
          (v) => !!v || this.$gettextInterpolate(
            this.$gettext('%{ entity } to select'),
            { entity: this.entityName.singular },
          ),
        ],
      },
      showSubscribeDialog: false,
      showLimitSubscriptionDialog: false,
      showKmlImportDialog: false,
      uploadedFeaturesFromKml: [],
      kmlFileName: null,
      restrictFlight: true,
      polygonValid: false,
      pilotInSight: null,
      flightAreas: [],
      loadingNightFlyingTimes: false,
      nightFlyingTimes: [],
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    isSubscriptionActive() {
      return this.$store.getters['authentication/isSubscriptionActive'];
    },
    isLimitedToSingleArea() {
      return !this.isSubscriptionActive;
    },
    isUserEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    placeholders() {
      return {
        name: this.$gettext('Mission name'),
        internalNote: this.$gettext('It will be visible only by you and never transmit'),
      };
    },
    display() {
      return this.$store.state.application.display;
    },
    closeFormTextDisplay() {
      if (this.display === APPLICATION_DISPLAY.BIG) {
        return 'd-none d-md-flex';
      }
      return 'd-none d-lg-flex';
    },
    formColumns() {
      if (this.display === APPLICATION_DISPLAY.BIG) {
        return BIG_DISPLAY_COLUMN_SIZES;
      }
      return MEDIUM_DISPLAY_COLUMN_SIZES;
    },
    subExploitantsAvailable() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    subExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    currentArea() {
      return this.$store.state.map.currentArea;
    },
    defaultFlyingHeight() {
      return this.$store.state.authentication.user.default_flying_height;
    },
    defaultFlyingTime() {
      return this.$store.state.authentication.user.default_flying_time;
    },
    drones() {
      return this.$store.state.drones.drones.filter(
        (drone) => drone.active || this.flight?.drone_ids.includes(drone.id),
      );
    },
    loadingDrones() {
      return this.$store.state.drones.loadingDrones;
    },
    pilots() {
      return this.$store.state.pilots.pilots.filter(
        (pilot) => !pilot.is_archived || this.flight?.pilot_ids.includes(pilot.id),
      );
    },
    loadingPilots() {
      return this.$store.state.pilots.loadingPilots;
    },
    isEditing() {
      return this.flightToEdit !== null;
    },
    hasPilots() {
      return this.$store.state.pilots.pilots.length > 0;
    },
    hasDrones() {
      return this.$store.state.drones.drones.length > 0;
    },
    allPilotsSelected: {
      get() {
        return this.flight.pilot_ids.length === this.pilots.length;
      },
      set(newValue) {
        if (newValue) {
          this.flight.pilot_ids = this.pilots.map((pilot) => pilot.id);
        } else {
          this.flight.pilot_ids = [];
        }
      },
    },
    allDronesSelected: {
      get() {
        return this.flight.drone_ids.length === this.drones.length;
      },
      set(newValue) {
        if (newValue) {
          this.flight.drone_ids = this.drones.map((drone) => drone.id);
        } else {
          this.flight.drone_ids = [];
        }
      },
    },
    expectedDuration: {
      get() {
        if (this.flightToEdit || this.originalFlight) {
          return this.flight.expected_duration;
        }
        return this.defaultFlyingTime;
      },
      set(newValue) {
        this.flight.expected_duration = newValue;
      },
    },
    subscriptionDialogText() {
      return {
        title: this.$gettext('Sign up for a subscription'),
        content: this.$gettext(`Your current offer does not allow you to create missions outside
          partner areas. Discover our offers and choose the one that suits you.`),
        confirmText: this.$gettext('Discover our offers'),
      };
    },
    limitSubscriptionDialogText() {
      return {
        title: this.$gettext('Upgrade subscription'),
        content: this.$gettext(`You have reached your subscription's limit of new missions.
          Increase the limit by changing your subscription.`),
        confirmText: this.$gettext('Change my subscription'),
      };
    },
    userRestrictFlights() {
      return this.$store.state.authentication.user.restrict_flights;
    },
    translatedBoxClipNetwork() {
      return this.$gettext(`Box to uncheck if you don't want the flight area to be automatically
        clipped according to your network.'`);
    },
    flightAreasLabel() {
      return this.$ngettext(
        'FLIGHT AREA',
        'FLIGHT AREAS',
        this.isLimitedToSingleArea ? 1 : 2,
      );
    },
    flightAreasValid() {
      if (this.flightAreas.length) {
        return this.flightAreas.every(
          (flightArea) => this.$turf.kinks(flightArea, 0).features.length === 0,
        );
      }
      return false;
    },
    flightAreasAlert() {
      if (this.isEditing) {
        return this.$ngettext(
          'Edit your mission area by drawing on the map',
          'Edit your mission areas by drawing on the map',
          this.isLimitedToSingleArea ? 1 : 2,
        );
      }
      return this.$ngettext(
        'To create the mission, you must draw your flight area on the map',
        'To create the mission, you must draw your flight areas on the map',
        this.isLimitedToSingleArea ? 1 : 2,
      );
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
    globalFlightAreasGeometry() {
      return this.$turf.multiPolygon(this.flightAreas.map((a) => a.geometry.coordinates));
    },
    globalFlightAreasSurface() {
      if (this.flightAreas.length) {
        return this.getGeometryArea(this.globalFlightAreasGeometry);
      }
      return '0 m²';
    },
    globalFlightAreasMaxExtent() {
      if (this.flightAreas.length) {
        return this.getGeometryMaxExtent(this.globalFlightAreasGeometry);
      }
      return '0 m';
    },
    isNightFlying() {
      if (!this.nightFlyingTimes.length) return false;

      const [startNightTime, endNightTime] = this.nightFlyingTimes;
      return (
        this.flight.time_end < this.flight.time_start
        || this.flight.time_end > startNightTime
        || this.flight.time_start < endNightTime
      );
    },
    texts() {
      return {
        nightFlying: this.isNightFlying ? this.$gettextInterpolate(
          this.$gettext(`The night times for the flight dates selected are between %{ duskTime }
            and %{ dawnTime }.`),
          {
            duskTime: this.nightFlyingTimes.at(0).slice(0, -3),
            dawnTime: this.nightFlyingTimes.at(1).slice(0, -3),
          },
        ) : '',
        dodgeNightFlying: this.$gettext(`To dodge the 'Night flying' constraint, you can change the
          flight times.`),
      };
    },
  },
  watch: {
    display(newValue) {
      if (newValue === APPLICATION_DISPLAY.SMALL) {
        this.close();
      }
    },
    flightDates(newValue) {
      this.$set(this.flight, 'date_start', newValue[0]);
      this.$set(this.flight, 'date_end', newValue.length > 1 ? newValue[1] : newValue[0]);
      if (this.flight.date_start > this.flight.date_end) {
        [this.flight.date_start, this.flight.date_end] = [
          this.flight.date_end, this.flight.date_start];
      }
      this.debouncedUpdateNightFlyingTimes({ that: this });
    },
    pilotInSight(newValue) {
      this.flight.pilot_in_sight = newValue;
    },
    currentArea(newValue) {
      if (newValue) {
        const newFeatures = newValue.features;
        // remove deleted features
        this.flightAreas = this.flightAreas.filter(
          (feature) => newFeatures.find((f) => f.id === feature.id),
        );
        // update or add with new features
        newFeatures.forEach((newFeature, index) => {
          let oldFeature;
          let oldFeatureIndex = this.flightAreas.findIndex((f) => f.id === newFeature.id);
          if (oldFeatureIndex > -1) {
            oldFeature = this.flightAreas[oldFeatureIndex];
          } else {
            oldFeature = { properties: {} };
            oldFeatureIndex = (this.flightAreas.push(oldFeature) - 1);
          }
          const updatedFeature = { ...newFeature };
          updatedFeature.properties = {
            ...newFeature.properties,
            ...oldFeature.properties,
          };
          if (!updatedFeature.properties.name) {
            updatedFeature.properties.name = `Zone ${index + 1}`;
          }
          if (!updatedFeature.properties.height) {
            updatedFeature.properties.height = this.defaultFlyingHeight;
          }
          this.flightAreas[oldFeatureIndex] = updatedFeature;
        });
      } else {
        this.flightAreas = [];
      }
      if (this.isLimitedToSingleArea && this.flightAreas.length > 1) {
        this.showMessage(
          this.$gettext(
            `You can only create a mission with one area. A subscription is required to
            create missions with multiple areas.`,
          ),
          'error',
        );
        this.flightAreas = [this.flightAreas[0]];
      }
    },
    flightAreas(newValue, oldValue) {
      this.updateFlightAreasNames();
      this.updateFlightAreasMeasurements();
      if (oldValue.length === 0 || (oldValue.length && newValue.length === 0)) {
        this.debouncedUpdateNightFlyingTimes({ that: this });
      }
    },
  },
  created() {
    if (this.flightToEdit) {
      this.flight = { ...this.flightToEdit };
      delete this.flight.flying_height;
      this.pilotInSight = this.flightToEdit.pilot_in_sight;
      this.$store.commit(SET_UPDATING_FLIGHT_ID_NS, this.flightToEdit.id);
    } else if (this.originalFlight) {
      const today = new Date();
      let dateStart = new Date(this.originalFlight.date_start);
      let dateEnd = new Date(this.originalFlight.date_end);
      if (today > dateStart) {
        dateStart = this.defaultStartDate();
        dateEnd = this.defaultEndDate();
      }
      this.flight = {
        name: `${this.originalFlight.name} (copie)`,
        date_start: dateStart.toISOString().slice(0, 10),
        date_end: dateEnd.toISOString().slice(0, 10),
        time_start: this.originalFlight.time_start,
        time_end: this.originalFlight.time_end,
        pilot_ids: this.originalFlight.pilots.filter(
          (pilot) => !pilot.is_archived,
        ).map((pilot) => pilot.id),
        drone_ids: this.originalFlight.drones.filter(
          (drone) => drone.active,
        ).map((drone) => drone.id),
        details: this.originalFlight.details,
        pilot_in_sight: this.originalFlight.pilot_in_sight,
        people_gathering: this.originalFlight.people_gathering,
        status: 'planned',
        expected_duration: this.originalFlight.expected_duration,
        tags: this.originalFlight.tags,
        sub_exploitant: this.originalFlight.sub_exploitant,
      };
      this.pilotInSight = this.originalFlight.pilot_in_sight;
    } else {
      this.flight = this.buildDefaultFlight();
    }
    this.flightDates = [this.flight.date_start, this.flight.date_end];

    this.$store.dispatch(GET_SIMPLIFIED_DRONES_NS);
    this.$store.dispatch(GET_SIMPLIFIED_PILOTS_NS);
  },
  destroyed() {
    this.$store.commit(SET_UPDATING_FLIGHT_ID_NS, undefined);
    this.$store.commit(SET_SLICE_FLIGHT_AREA_NS, undefined);
  },
  methods: {
    defaultEndDate() {
      const date = new Date();
      date.setDate(date.getDate() + 14);
      return date;
    },
    defaultStartDate() {
      const date = new Date();
      date.setDate(date.getDate() + 8);
      return date;
    },
    buildDefaultFlight() {
      return {
        date_start: this.defaultStartDate().toISOString().slice(0, 10),
        date_end: this.defaultEndDate().toISOString().slice(0, 10),
        time_start: '08:30',
        time_end: '16:30',
        drone_ids: this.drones.length === 1 ? [this.drones[0].id] : [],
        pilot_ids: this.pilots.length === 1 ? [this.pilots[0].id] : [],
        name: '',
        details: '',
        status: 'planned',
        pilot_in_sight: null,
        people_gathering: false,
        expected_duration: '00:15:00',
        tags: [],
        sub_exploitant: null,
      };
    },
    buildFlight() {
      const payload = {};
      // we build payload only with non null values
      // because API trigger error if they're sent
      Object.keys(this.flight).forEach((key) => {
        if (
          key === 'sub_exploitant'
          && this.subExploitants.length === 1
          && this.flight.sub_exploitant === null
        ) {
          payload.sub_exploitant = this.subExploitants[0].id;
        } else if (this.flight[key] !== null) {
          payload[key] = this.flight[key];
        }
      });
      payload.areas = this.flightAreas.map((flightArea) => ({
        name: flightArea.properties.name,
        height: flightArea.properties.height,
        geometry: flightArea.geometry,
      }));
      if (this.userRestrictFlights) {
        payload.disable_restrict_flights = !this.restrictFlight;
      }
      payload.create_contraints = false;
      return payload;
    },
    validateForm() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch(SET_ACTIVE_SPLIT_NS, APPLICATION_SPLIT.LISTING);
        this.showMessage(this.$gettext(
          'Errors were found in the information you provided. Please check them.',
        ));
        return false;
      }
      if (this.currentArea === null) {
        this.$store.dispatch(SET_ACTIVE_SPLIT_NS, APPLICATION_SPLIT.MAP);
        this.showMessage(this.$gettext(
          'Please indicate a flight area. You can use the pencil button on the right of the screen.',
        ));
        return false;
      }
      if (!this.flightAreasValid) {
        this.$store.dispatch(SET_ACTIVE_SPLIT_NS, APPLICATION_SPLIT.MAP);
        this.showMessage(this.$gettext('Please create a non-overlapping flight area.'));
        return false;
      }
      return true;
    },
    createFlight() {
      if (!this.validateForm()) {
        return;
      }
      this.loading = true;
      const payload = this.buildFlight();
      API.createFlight(payload)
        .then(({ data }) => {
          this.$store.commit(UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS, { flightId: data.id });
          this.$store.dispatch(
            GET_FLIGHT_DETAILS_NS,
            {
              flightId: data.id,
              reload: true,
              analysis: false,
              documents: false,
              created: true,
            },
          )
            .then(() => {
              this.showMessage(
                this.$gettextInterpolate(
                  this.$gettext('Mission n°%{id} created.'),
                  { id: data.id },
                ),
                'success',
              );
              this.close({ selectedId: data.id });
              if (data.within_registered_area
              && this.$store.state.authentication.user.display_request_approval_alert
              ) {
                this.$emit('show-request-approval-alert');
              }
              this.updateTagNames(this.flight.tags);
              this.showWarningMessagePastFlightDates(
                this.flight.date_start,
                this.flight.time_start,
                this.flight.date_end,
                this.flight.time_end,
              );
              this.$store.dispatch(SET_FLIGHT_CONSTRAINTS_NS, { flightId: data.id });
              this.loading = false;
            })
            .catch(() => {
            // flight not found
              this.$store.dispatch(UPDATE_DISPLAY_ONLY_MY_FLIGHTS_NS, false)
                .then(() => {
                  this.$store.dispatch(
                    GET_FLIGHT_DETAILS_NS,
                    { flightId: data.id, reload: true },
                  )
                    .then(() => {
                      this.showMessage(
                        this.$gettextInterpolate(
                          this.$gettext('Mission n°%{id} created.'),
                          { id: data.id },
                        ),
                        'success',
                      );
                      this.close({ selectedId: data.id });
                      if (
                        data.within_registered_area
                        && this.$store.state.authentication.user.display_request_approval_alert
                      ) {
                        this.$emit('show-request-approval-alert');
                      }
                      this.updateTagNames(this.flight.tags);
                    })
                    .finally(() => {
                      this.loading = false;
                    });
                });
            });
        })
        .catch((error) => {
          this.handleCustomErrors(error);
          this.loading = false;
        });
    },
    prepareFlightToUpdate() {
      const payload = {};
      // we build payload only with non null values
      // because API trigger error if they're sent
      payload.date_start = this.flight.date_start;
      payload.date_end = this.flight.date_end;
      payload.time_start = this.flight.time_start;
      payload.time_end = this.flight.time_end;
      payload.drone_ids = this.flight.drone_ids;
      payload.pilot_ids = this.flight.pilot_ids;
      payload.flying_height = this.flight.flying_height;
      payload.name = this.flight.name;
      payload.status = this.flight.status;
      payload.pilot_in_sight = this.flight.pilot_in_sight;
      payload.people_gathering = this.flight.people_gathering;
      payload.details = this.flight.details;
      payload.expected_duration = this.flight.expected_duration;
      payload.tags = this.flight.tags;
      payload.areas = this.flightAreas.map((flightArea) => ({
        id: flightArea.properties.id,
        name: flightArea.properties.name,
        height: flightArea.properties.height,
        geometry: flightArea.geometry,
      }));
      if (this.userRestrictFlights) {
        payload.disable_restrict_flights = !this.restrictFlight;
      }
      payload.sub_exploitant = this.flight.sub_exploitant;
      payload.create_contraints = false;
      return payload;
    },
    updateFlight() {
      if (!this.validateForm()) {
        return;
      }
      this.loading = true;
      const payload = this.prepareFlightToUpdate();
      this.$emit('update-id-selected', null);
      API.updateFlight(this.flight.id, payload)
        .then(({ data }) => {
          this.$store.commit(UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS, { flightId: data.id });
          this.$store.dispatch(GET_FLIGHT_DETAILS_NS, {
            flightId: data.id,
            updateGeom: true,
            documents: true,
            analysis: false,
            created: true,
          })
            .then(() => {
              this.showMessage(
                this.$gettextInterpolate(
                  this.$gettext('Mission n°%{id} modified.'),
                  { id: data.id },
                ),
                'success',
              );
              this.close({ selectedId: data.id });
              const outdatedNotifsArmyCount = data.outdated_army_notifs_count;
              const outdatedNotifsPrefectureCount = data.outdated_prefecture_notifs_count;
              const cancelledNotifsArmyCount = data.cancelled_army_notifs_count;
              if (outdatedNotifsArmyCount > 0 || outdatedNotifsPrefectureCount > 0
                || cancelledNotifsArmyCount > 0) {
                this.$emit('show-update-alphatango-alert', {
                  outdatedNotifsArmyCount,
                  outdatedNotifsPrefectureCount,
                  cancelledNotifsArmyCount,
                });
              }
              this.updateTagNames(this.flight.tags);
              const approvalsToUpdate = data.structures_with_pending_external_approvals;
              if (approvalsToUpdate.length) {
                this.$emit('show-update-external-approvals-alert', approvalsToUpdate);
              }
              this.$store.dispatch(SET_FLIGHT_CONSTRAINTS_NS, { flightId: data.id });
            })
            .finally(() => {
              this.showWarningMessagePastFlightDates(
                this.flight.date_start,
                this.flight.time_start,
                this.flight.date_end,
                this.flight.time_end,
              );
              this.loading = false;
            });
        })
        .catch((error) => {
          this.handleCustomErrors(error);
          this.loading = false;
        });
    },
    handleCustomErrors(error) {
      const codeError = error.response.data.code;
      if (codeError && codeError === CUSTOM_HANDLING_API_CODES.UNACTIVE_ACCOUNT) {
        this.showSubscribeDialog = true;
      }
      if (codeError && codeError === CUSTOM_HANDLING_API_CODES.FLIGHT_CREDIT_LIMIT) {
        this.showLimitSubscriptionDialog = true;
      }
    },
    updateFlightAreasNames() {
      if (this.flightAreas.length > 1) {
        const data = { type: 'FeatureCollection', features: this.flightAreas };
        this.$store.dispatch(SET_FLIGHT_AREAS_NAME_NS, data);
      }
    },
    updateFlightAreasMeasurements() {
      const data = {
        type: 'FeatureCollection',
        features: this.flightAreas.filter((feature) => feature.geometry.coordinates.length).map(
          (feature) => {
            const point = this.$turf.centroid(feature.geometry);
            point.properties.measurements = this.getFlightAreaMeasurements(feature.geometry);
            return point;
          },
        ),
      };
      this.$store.dispatch(SET_FLIGHT_AREAS_MEASUREMENTS_INFO_NS, data);
    },
    getFlightAreaMeasurements(geometry) {
      return this.$gettextInterpolate(
        this.$gettext('Surface : %{ surface }\nMax extent : %{ extent }'),
        {
          surface: this.getGeometryArea(geometry),
          extent: this.getGeometryMaxExtent(geometry),
        },
      );
    },
    getGeometryArea(geometry) {
      const areaMeters = Math.round(this.$turf.area(geometry));
      if (areaMeters / 10000 > 10) {
        const areaKilometers = (areaMeters / 1000000).toFixed(1);
        return `${areaKilometers} km²`;
      }
      return `${areaMeters} m²`;
    },
    getGeometryMaxExtent(geometry) {
      const [west, south, east, north] = this.$turf.bbox(geometry);
      const distanceHorizontal = this.$turf.distance([west, south], [east, south]);
      const distanceVertical = this.$turf.distance([west, south], [west, north]);
      const maxExtentKilometers = Math.max(distanceHorizontal, distanceVertical);
      if (maxExtentKilometers < 1) {
        const maxExtentMeters = Math.round(maxExtentKilometers * 1000);
        return `${maxExtentMeters} m`;
      }
      return `${maxExtentKilometers.toFixed(1)} km`;
    },
    close({ selectedId = '' } = {}) {
      this.$emit('reset-flight-to-edit');
      this.$emit('update-id-selected', selectedId);
      const emptyFeatureCollection = { type: 'FeatureCollection', features: [] };
      this.$store.dispatch(SET_FLIGHT_AREAS_NAME_NS, emptyFeatureCollection);
      this.$store.dispatch(SET_FLIGHT_AREAS_MEASUREMENTS_INFO_NS, emptyFeatureCollection);
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
    },
    resetKmlUploader() {
      this.$refs.kmluploader.value = null;
    },
    uploadKmlFile(kmlFile) {
      if (kmlFile) {
        const fileReader = new FileReader();
        fileReader.readAsText(kmlFile);
        fileReader.addEventListener('load', () => {
          const parsedKML = new DOMParser().parseFromString(fileReader.result, 'text/xml');
          const kmlToGeoJson = kml(parsedKML);
          // Filter GeoJSON: only keep point, polygon and multipolygon features
          kmlToGeoJson.features = kmlToGeoJson.features.filter(
            (feature) => (
              ['LineString', 'Point', 'Polygon'].includes(feature.geometry.type)
              || (
                feature.geometry.type === 'GeometryCollection'
                && feature.geometry.geometries.length === feature.geometry.geometries.filter(
                  (g) => g.type === 'Polygon',
                ).length
              )
            ),
          );
          kmlToGeoJson.features = this.cleanFeatures(kmlToGeoJson.features);
          const nbrFeatures = kmlToGeoJson.features.length;
          const featuresContainPoints = kmlToGeoJson.features.find(
            (feature) => feature.geometry.type === 'Point',
          );
          const featuresContainLines = kmlToGeoJson.features.find(
            (feature) => feature.geometry.type === 'LineString',
          );
          const featuresContainPolygon = kmlToGeoJson.features.filter(
            (feature) => feature.geometry.type === 'Polygon',
          );
          const featuresContainMultiPolygon = kmlToGeoJson.features.filter(
            (feature) => feature.geometry.type === 'GeometryCollection',
          );
          if (
            this.isEditing
            && featuresContainPolygon.length === 1
            && !this.isUserEnterprise
          ) {
            kmlToGeoJson.features[0].geometry.coordinates[0] = featuresContainPolygon[0].geometry
              .coordinates[0].map((coordinate) => [coordinate[0], coordinate[1]]);
            this.$store.dispatch(SET_LOADED_KML_NS, kmlToGeoJson);
            return;
          }
          // If file content is different than a single polygon feature
          if (
            (
              this.isUserEnterprise
              && (
                featuresContainPoints
                || featuresContainLines
                || featuresContainMultiPolygon
              )
            ) || featuresContainPolygon.length > 1
            || featuresContainPolygon.length === 0
          ) {
            if (!this.isUserEnterprise) {
              this.showMessage(
                this.$gettext(`Invalid file. The KML must contain only one entity of type Polygon.
                  To import more than one, an enterprise subscription is required.`),
                'error',
              );
              return;
            }
            if (this.isUserEnterprise) {
              if (nbrFeatures === 0) {
                this.showMessage(
                  this.$gettext('No Polygon or Point or Line entity was found in the file.'),
                  'error',
                );
                return;
              }
              if (
                (
                  nbrFeatures <= 2
                  || featuresContainPoints
                  || featuresContainLines
                  || featuresContainMultiPolygon
                ) && this.isEditing
              ) {
                this.showMessage(
                  this.$gettext(
                    'Please import a KML file with only one polygon to modify your mission.',
                  ),
                  'error',
                );
                return;
              }
              kmlToGeoJson.features.filter((ele) => ele.geometry.type === 'Polygon')
                .map(
                  (ele) => {
                    if (
                      JSON.stringify(
                        ele.geometry.coordinates[0][ele.geometry.coordinates[0].length - 1],
                      )
                        !== JSON.stringify(ele.geometry.coordinates[0][0])) {
                      ele.geometry.coordinates[0].push(ele.geometry.coordinates[0][0]);
                    }
                    return 0;
                  },
                );
              this.kmlFileName = kmlFile.name;
              this.uploadedFeaturesFromKml = kmlToGeoJson.features;
              this.showKmlImportDialog = true;
            }
          // else, if the file contains a single polygon feature add it to the map via the store
          } else {
            const polygon = kmlToGeoJson.features.find(
              (feature) => feature.geometry.type === 'Polygon',
            );
            polygon.geometry.coordinates[0] = polygon.geometry.coordinates[0].map(
              (coordinate) => [coordinate[0], coordinate[1]],
            );
            const featurePolygon = {
              type: 'FeatureCollection',
              features: [polygon],
            };
            this.$store.dispatch(SET_LOADED_KML_NS, featurePolygon);
          }
        });
      }
    },
    cleanFeatures(features) {
      const cleanedFeatures = [];
      const cleanedFeaturesName = [];
      features.forEach((feature) => {
        let i = 2;
        let extraName = feature.properties.name;
        while (cleanedFeaturesName.includes(extraName) === true) {
          extraName = `${feature.properties.name}_${i}`;
          i += 1;
        }
        const cleanedFeature = feature;
        cleanedFeature.properties.name = extraName;
        cleanedFeatures.push(cleanedFeature);
        cleanedFeaturesName.push(extraName);
      });
      return cleanedFeatures;
    },
    showWarningMessagePastFlightDates(dateStart, timeStart, dateEnd, timeEnd) {
      const today = new Date();
      const startDate = new Date(dateStart);
      const startTime = timeStart.split(':').map((e) => parseInt(e, 10));
      startDate.setHours(startTime[0], startTime[1]);
      const endDate = new Date(dateEnd);
      const endTime = timeEnd.split(':').map((e) => parseInt(e, 10));
      endDate.setHours(endTime[0], endTime[1]);
      if (endDate < today) {
        this.showMessage(
          this.$gettext('Attention: The flight dates have already passed.'),
          'warning',
        );
        return;
      }
      if (startDate < today) {
        this.showMessage(
          this.$gettext('Attention: The start date of the flight has already passed.'),
          'warning',
        );
      }
    },
    debouncedUpdateNightFlyingTimes: debounce(
      ({ that }) => that.updateNightFlyingTimes(),
      1000, // debounce for 1s
    ),
    updateNightFlyingTimes() {
      if (this.flight.date_start && this.flight.date_end && this.flightAreas.length) {
        this.loadingNightFlyingTimes = true;
        API.nightFlyingTimes(
          this.$turf.centroid(this.globalFlightAreasGeometry).geometry,
          this.flight.date_start,
          this.flight.date_end,
        )
          .then(({ data }) => {
            this.nightFlyingTimes = [data.night_flying_start_time, data.night_flying_end_time];
          })
          .finally(() => {
            this.loadingNightFlyingTimes = false;
          });
      } else {
        this.nightFlyingTimes = [];
      }
    },
    updateTagNames(newTags) {
      const oldTags = this.$store.state.exploitants.tagNames;
      if (newTags?.find((t) => !oldTags.includes(t))) {
        this.$store.dispatch(FETCH_TAG_NAMES_NS);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.text-field_primary--text ::v-deep .v-text-field__slot input {
  color: $color-primary;
}

.flight-name__field {
  display: flex;
  align-items: center;
  gap: 16px;
  .form-close {
    color: $color-primary;
    text-align: end;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    padding-left: 8px;
  }
}

</style>
