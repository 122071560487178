<template>
  <div>
    <v-skeleton-loader
      v-if="loadingCheckCanRequest"
      light
      type="list-item-three-line, image, actions"
    />
    <v-card
      v-else
      class="default--dialog__card"
      max-height="800px"
      elevation="0"
    >
      <v-card-title>
        <span v-translate>Take off authorization</span>
      </v-card-title>
      <v-card-text>
        <TakeOffAuthorizationDetails
          :takeOffAuthorization="takeOffAuthorization"
          newMessageDisabled
        />
        <v-divider />
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="px-2"
        >
          <v-textarea
            v-model="message"
            :rules="[rules.required]"
            :placeholder="placeholders.message"
            full-width
            rows="2"
          />
        </v-form>
        <div
          v-if="isOutsideApprovalDates"
          class="error--text"
        >
          {{ isOutsideApprovalDatesText }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="info"
          @click="close()"
        >
          <span v-translate>close</span>
        </v-btn>
        <v-btn
          v-if="canRequestTakeOffAuthorization"
          key="submit"
          color="primary"
          :disabled="isOutsideApprovalDates || !valid"
          :loading="loadingSubmit"
          @click="requestTakeOffAuthorization()"
        >
          <span v-translate>Request a take off authorization</span>
        </v-btn>
        <v-btn
          v-else
          text
          key="send-message"
          color="primary"
          :disabled="isOutsideApprovalDates || !valid"
          :loading="loadingSendMessage"
          @click="sendTakeOffAuthorizationMessage()"
        >
          <span v-translate>Send message</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import APIService from '@/services/api';

import TakeOffAuthorizationDetails from '@/components/TakeOffAuthorizations/TakeOffAuthorizationDetails.vue';

export default {
  name: 'TakeOffAuthorizationDronist',
  components: { TakeOffAuthorizationDetails },
  props: { takeOffAuthorization: Object },
  data() {
    return {
      loadingCheckCanRequest: false,
      valid: false,
      message: '',
      loadingSubmit: false,
      loadingSendMessage: false,
      rules: { required: (value) => !!value || this.$gettext('A message is required') },
      canRequestTakeOffAuthorization: false,
      isOutsideApprovalDates: false,
    };
  },
  computed: {
    isOutsideApprovalDatesText() {
      if (this.canRequestTakeOffAuthorization) {
        return this.$gettext(
          'Take off authorisation should be requested only during approval days.',
        );
      }
      return this.$gettext(
        'Take off authorisation messages are only allowed during approval days.',
      );
    },
    placeholders() {
      return { message: this.$gettext('Write a message...') };
    },
  },
  created() {
    this.loadingCheckCanRequest = true;
    APIService.checkCanRequestTakeOffAuthorization(this.takeOffAuthorization.id)
      .then(({ data }) => {
        this.canRequestTakeOffAuthorization = data.required;
        this.isOutsideApprovalDates = !data.inside_allowed_dates;
      })
      .finally(() => {
        this.loadingCheckCanRequest = false;
      });
  },
  methods: {
    requestTakeOffAuthorization() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loadingSubmit = true;
      APIService.requestTakeOffAuthorization(
        this.takeOffAuthorization.id, { message: this.message },
      )
        .then(({ data }) => {
          this.$emit('update-take-off-authorization', data);
          this.showMessage(
            this.$gettext('Take off authorization submitted successfully'), 'success',
          );
          this.close();
        })
        .finally(() => {
          this.loadingSubmit = false;
        });
    },
    sendTakeOffAuthorizationMessage() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loadingSendMessage = true;
      APIService.sendTakeOffAuthorizationMessage(
        this.takeOffAuthorization.id, { message: this.message },
      )
        .then(({ data }) => {
          this.$emit('update-take-off-authorization', data);
          this.$refs.form.reset();
        })
        .finally(() => {
          this.loadingSendMessage = false;
        });
    },
    close() {
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>
