<template>
  <div class="flight-incidents">
    <v-container class="px-1 px-md-3 flex-grow-0">
      <!-- Header -->
      <div class="header pb-4">
        <div class="header headline">
          <translate>Flight incidents</translate>
        </div>
        <div class="header buttons">
          <v-btn
            @click="openForm()"
            color="primary"
            depressed
            key="add"
          >
            <translate>+ Report incident</translate>
          </v-btn>
          <v-btn
            v-if="isManagerOrAdmin"
            :disabled="incidentsLoading"
            @click="dialogExport=true"
            color="primary"
            depressed
            key="export"
          >
            <translate>Export</translate>
          </v-btn>
        </div>
      </div>
      <!-- Announcement -->
      <v-row
        v-if="isAdmin"
        no-gutters
        align="center"
        class="py-3"
      >
        <v-col
          cols="12"
          md="4"
        >
          <span
            class="body-2"
            v-translate
          >
            Incident instruction
          </span>
          <v-tooltip
            bottom
            color="info"
            max-width="580px"
            open-on-click
            :open-on-hover="false"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="small-icon ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span v-html="texts.announcement" />
          </v-tooltip>
        </v-col>
        <v-col
          cols="10"
          md="6"
          class="truncated"
        >
          <span
            v-if="flightIncidentAnnouncement"
            class="cell"
          >
            {{ flightIncidentAnnouncement.text }}
          </span>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-end"
        >
          <template v-if="!loadingFlightIncidentAnnouncement">
            <v-btn
              v-if="flightIncidentAnnouncement"
              icon
              @click="flightIncidentAnnouncementViewerDialog=true"
            >
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="openFlightIncidentAnnouncementDialog()"
            >
              <v-icon>icon-edit_line</v-icon>
            </v-btn>
            <v-btn
              v-if="flightIncidentAnnouncement"
              icon
              @click="flightIncidentAnnouncementDeletionDialog=true"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-col>
      </v-row>
      <!-- Table filters -->
      <div class="py-4">
        <div class="d-flex align-center justify-space-between">
          <span class="py-1 subtitle-2 info--text">{{ labels.filters }}</span>
          <v-btn
            v-if="filters.search || filters.dateStart || filters.dateEnd"
            @click="resetFilters()"
            small
            text
          >
            <span class="text-decoration-underline">
              {{ labels.cancelFilters }}
            </span>
          </v-btn>
        </div>
        <v-row
          no-gutters
          align="center"
        >
          <!-- Search field  -->
          <v-col
            class="py-3 pr-sm-4"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              :label="labels.search"
              class="ma-0 pa-0"
              hide-details
            />
          </v-col>
          <!-- Date start filter -->
          <v-col
            class="py-3 pr-sm-4"
            cols="12"
            sm="3"
          >
            <v-menu
              v-model="dateStartMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              content-class="white text-center"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :value="filters.dateStart | date"
                  :label="labels.dateStart"
                  append-icon="mdi-calendar"
                  class="ma-0 pa-0"
                  hide-details
                  readonly
                />
              </template>
              <v-date-picker
                v-model="filters.dateStart"
                no-title
                scrollable
                locale="fr"
                first-day-of-week="1"
                @input="dateStartMenu=false"
              />
            </v-menu>
          </v-col>
          <!-- Date end filter -->
          <v-col
            class="py-3"
            cols="12"
            sm="3"
          >
            <v-menu
              v-model="dateEndMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              content-class="white text-center"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :value="filters.dateEnd | date"
                  :label="labels.dateEnd"
                  append-icon="mdi-calendar"
                  class="ma-0 pa-0"
                  hide-details
                  readonly
                />
              </template>
              <v-date-picker
                v-model="filters.dateEnd"
                no-title
                scrollable
                locale="fr"
                first-day-of-week="1"
                @input="dateEndMenu=false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <!-- Data Table -->
        <v-data-table
          class="mt-4"
          :headers="headers"
          :items="incidents"
          :options.sync="options"
          :server-items-length="incidentsTotal"
          :loading="incidentsLoading"
          :loading-text="labels.loadingText"
          :no-data-text="labels.noResultText"
          :no-results-text="labels.noResultText"
          :mobile-breakpoint="singleColumnBreakpoint"
          :footer-props="{
            'items-per-page-text': $gettext('Lines per page:'),
            'items-per-page-all-text': $gettext('All'),
            'items-per-page-options': [25, 50, 100, -1],
          }"
          @click:row="(item) => expandIncident(item)"
          :expanded.sync="expanded"
          single-expand
          light
        >
          <!-- td created at formatted  -->
          <template v-slot:[`item.created_at`]="{ item }">
            <span>
              {{ item.created_at | toLocaleDate }}
            </span>
          </template>
          <!-- td incident date formatted  -->
          <template v-slot:[`item.incident_date`]="{ item }">
            <span>
              {{ item.incident_date | date }}
            </span>
          </template>
          <!-- td sub-exploitants chips  -->
          <template
            v-if="hasSubExploitants"
            v-slot:[`item.sub_exploitants`]="{ item }"
          >
            <div
              class="chips-group-flex-wrap"
              :class="{'justify-end': isMobileBreakpoint}"
            >
              <v-chip
                v-for="(subExploitant, key) in item.sub_exploitants"
                :key="key"
                x-small
              >
                {{ subExploitant }}
              </v-chip>
            </div>
          </template>
          <!-- td edit incident  -->
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              icon
              :disabled="incidentDetailsLoading"
              @click.stop="openForm(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <!-- Incident details expanded row -->
          <template v-slot:expanded-item="{ headers }">
            <td
              v-if="incidentDetailsLoading"
              :colspan="headers.length"
              class="height-0"
            >
              <v-progress-linear
                color="primary"
                indeterminate
              />
            </td>
            <td
              v-else-if="incidentExpandedDetails"
              :colspan="headers.length"
            >
              <div class="px-4 pb-4">
                <!-- Location -->
                <v-row
                  class="pa-1"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <span class="details-card__label">
                      {{ labels.incidentLocation }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    {{ incidentExpandedDetails.location }}
                  </v-col>
                </v-row>
                <!-- Flight -->
                <v-row
                  v-if="incidentExpandedDetails.flight_label"
                  class="pa-1"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <span class="details-card__label">
                      {{ labels.incidentFlight }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    {{ incidentExpandedDetails.flight_label }}
                  </v-col>
                </v-row>
                <!-- Drone -->
                <v-row
                  no-gutters
                  class="pa-1"
                >
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <span class="details-card__label">
                      {{ labels.incidentDrone }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    {{ incidentExpandedDetails.drone_identifier }}
                  </v-col>
                </v-row>
                <!-- Description -->
                <v-row
                  no-gutters
                  class="pa-1"
                >
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <span class="details-card__label">
                      {{ labels.incidentDescription }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    {{ incidentExpandedDetails.description }}
                  </v-col>
                </v-row>
                <!-- Weather -->
                <v-row
                  no-gutters
                  class="pa-1"
                >
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <span class="details-card__label">
                      {{ labels.incidentWeather }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    {{ incidentExpandedDetails.weather }}
                  </v-col>
                </v-row>
                <!-- Consequence -->
                <v-row
                  no-gutters
                  class="pa-1"
                >
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <span class="details-card__label">
                      {{ labels.incidentConsequence }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    {{ getConsequenceDisplay(incidentExpandedDetails.consequence) }}
                  </v-col>
                </v-row>
                <!-- Immediate measure -->
                <v-row
                  no-gutters
                  class="pa-1"
                >
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <span class="details-card__label">
                      {{ labels.incidentImmediateMeasure }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    {{ incidentExpandedDetails.immediate_measure }}
                  </v-col>
                </v-row>
                <!-- Attachements -->
                <v-row
                  class="pa-1"
                  v-if="incidentExpandedDetails.attachments.length"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <span class="details-card__label">
                      {{ labels.incidentAttachments }}
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    <v-chip
                      v-for="(document, index) in incidentExpandedDetails.attachments"
                      class="documents__chip caption"
                      :disabled="documentDownloadLoading[index]"
                      :key="index"
                    >
                      <span
                        class="documents__link-label"
                        @click="downloadDocument(index, document.id, document.name)"
                      >
                        {{ document.name }}
                      </span>
                      <span class="pl-2">
                        <v-progress-circular
                          v-if="documentDownloadLoading[index]"
                          indeterminate
                          size="16"
                          width="2"
                        />
                      </span>
                    </v-chip>
                  </v-col>
                </v-row>
              </div>
            </td>
          </template>
          <!-- Footer slot  -->
          <template v-slot:[`footer.page-text`]="items">
            <span v-translate="
                {pageStart: items.pageStart, pageStop: items.pageStop, length: items.itemsLength}
              ">
              %{ pageStart } - %{ pageStop } of %{ length }
            </span>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <!-- Create or update form -->
    <v-dialog
      v-if="dialogForm"
      v-model="dialogForm"
      max-width="1000px"
      :retain-focus="false"
      :fullscreen="isMobileBreakpoint"
      persistent
    >
      <FlightIncidentForm
        :incidentToUpdate="incidentToUpdate"
        :flightIncidentAnnouncementText="flightIncidentAnnouncementViewer"
        @close="closeForm()"
        @reload="getFlightIncidents()"
      />
    </v-dialog>
    <!-- Export dialog -->
    <v-dialog
      v-model="dialogExport"
      max-width="700px"
      :retain-focus="false"
      :fullscreen="isMobileBreakpoint"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          {{ labels.export }}
        </v-card-title>
        <v-card-text class="pt-3">
          <div
            v-if="exportDisabled"
            class="error--text font-italic"
          >
            {{ texts.exportErrors }}
          </div>
          <div v-else>
            {{ texts.exportDetails }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="dialogExport=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="exportLoading"
            :disabled="exportDisabled"
            @click="exportFlightIncidents()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Announcement viewer dialog -->
    <v-dialog
      v-if="flightIncidentAnnouncementViewerDialog"
      v-model="flightIncidentAnnouncementViewerDialog"
      max-width="840px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title v-translate>Instruction viewer</v-card-title>
        <v-card-text>
          <v-sheet
            light
            outlined
            class="pa-12"
          >
            <vue-markdown
              :source="flightIncidentAnnouncementViewer"
              :html="false"
              xhtml-out
              typographer
              linkify
              breaks
              show
              :emoji="false"
              :anchorAttributes="{ target: '_blank' }"
              class="markdown"
            />
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="flightIncidentAnnouncementViewerDialog=false"
          >
            <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Announcement creation and update dialog-->
    <v-dialog
      v-model="flightIncidentAnnouncementDialog"
      max-width="840px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span
            v-if="flightIncidentAnnouncement"
            key="update"
            v-translate
          >
            Update instruction
          </span>
          <span
            v-else
            key="create"
            v-translate
          >
            Create instruction
          </span>
        </v-card-title>
        <v-card-text>
          <span v-html="texts.announcement" />
          <v-form
            lazy-validation
            v-model="validFlightIncidentAnnouncement"
            class="mt-2"
          >
            <v-textarea
              v-model="flightIncidentAnnouncementText"
              :label="labels.announcement"
              :rules="rules.required"
              rows="15"
            />
          </v-form>
          <translate>You can preview the text by clicking here:</translate>
          <v-btn
            icon
            @click="flightIncidentAnnouncementViewerDialog=true"
          >
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeFlightIncidentAnnouncementDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingFlightIncidentAnnouncement"
            :disabled="!validFlightIncidentAnnouncement"
            @click="createOrUpdateFlightIncidentAnnouncement()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Announcement deletion dialog-->
    <v-dialog
      v-if="flightIncidentAnnouncementDeletionDialog"
      v-model="flightIncidentAnnouncementDeletionDialog"
      max-width="840px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Delete instruction</translate>
        </v-card-title>
        <v-card-text>
          <translate>Are you sure you want to delete this instruction?</translate>
          <v-sheet
            light
            outlined
            class="mt-2 pa-12"
          >
            <vue-markdown
              :source="flightIncidentAnnouncement.text"
              html
              xhtml-out
              typographer
              linkify
              breaks
              show
              :emoji="false"
              :anchorAttributes="{ target: '_blank' }"
              class="markdown"
            />
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="flightIncidentAnnouncementDeletionDialog=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingFlightIncidentAnnouncement"
            @click="deleteFlightIncidentAnnouncement()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';

import APIService from '@/services/api';

import { FLIGHT_INCIDENT_CONSEQUENCES } from '@/settings';

import FlightIncidentForm from '@/components/Settings/Dronists/FlightIncidentForm.vue';

const EXPORT_COUNT_LIMIT = 100;

export default {
  name: 'FlightIncidents',
  components: {
    VueMarkdown,
    FlightIncidentForm,
  },
  data() {
    return {
      incidentToUpdate: null,
      dialogForm: false,
      dialogExport: false,
      incidents: [],
      incidentsTotal: 0,
      incidentsLoading: false,
      dateStartMenu: false,
      dateEndMenu: false,
      filters: {
        search: null,
        dateStart: null,
        dateEnd: null,
      },
      options: {
        itemsPerPage: 25,
        page: 1,
        sortBy: ['id'],
        sortDesc: [true],
      },
      expanded: [],
      incidentDetailsLoading: false,
      incidentExpandedDetails: null,
      documentDownloadLoading: [],
      exportLoading: false,
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
      loadingFlightIncidentAnnouncement: false,
      flightIncidentAnnouncement: null,
      flightIncidentAnnouncementText: '',
      flightIncidentAnnouncementDialog: false,
      validFlightIncidentAnnouncement: false,
      flightIncidentAnnouncementViewerDialog: false,
      flightIncidentAnnouncementDeletionDialog: false,
      rules: {
        required: [
          (v) => !!v || this.$gettext('This field is required.'),
        ],
      },
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    isAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    isManagerOrAdmin() {
      return this.isAdmin || this.$store.getters['authentication/isUserExploitantManager'];
    },
    hasSubExploitants() {
      return this.$store.getters['exploitants/hasSubExploitants'];
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
    headers() {
      return [
        { text: this.$gettext('Id'), value: 'id' },
        { text: this.$gettext('Reporter'), value: 'reporter' },
        { text: this.$gettext('Reported at'), value: 'created_at' },
        { text: this.$gettext('Incident date'), value: 'incident_date' },
        { text: this.$gettext('Title'), value: 'title', width: '20%' },
        ...(
          this.hasSubExploitants ? [
            { text: this.entityName.plural, value: 'sub_exploitants', sortable: false },
          ] : []
        ),
        { text: '', value: 'edit', sortable: false },
      ];
    },
    labels() {
      return {
        filters: this.$gettext('Filters'),
        cancelFilters: this.$gettext('Cancel filters'),
        search: this.$gettext('Search'),
        dateStart: this.$gettext('Start date'),
        dateEnd: this.$gettext('End date'),
        loadingText: this.$gettext('Flight incidents loading...'),
        noResultText: this.$gettext('No flight incidents found'),
        incidentLocation: this.$gettext('Location'),
        incidentFlight: this.$gettext('Mission'),
        incidentDrone: this.$gettext('Drone'),
        incidentDescription: this.$gettext('Description'),
        incidentWeather: this.$gettext('Weather condition'),
        incidentConsequence: this.$gettext('Consequence'),
        incidentImmediateMeasure: this.$gettext('Immediate measure'),
        incidentAttachments: this.$gettext('Attachements'),
        export: this.$gettext('Export flight incidents'),
        announcement: this.$gettext('Instruction text'),
      };
    },
    texts() {
      return {
        exportDetails: this.$gettextInterpolate(
          this.$gettext(`An export of all flight incidents data of this table with their
            attachments will be prepared and sent to you at the email address linked to your
            account (%{email}). You should get it in a few minutes.`),
          { email: this.$store.state.authentication.user.email },
        ),
        exportErrors: this.$gettextInterpolate(
          this.$gettext(`Exports of flight incidents cannot exceed %{limit} flight incidents.
          You can use filters to limit the data table results and try again.`),
          { limit: EXPORT_COUNT_LIMIT },
        ),
        announcement: this.$gettext(`This text will be displayed for all your pilots on top of
          flight incident form.<br>You can use <a href="https://fr.wikipedia.org/wiki/Markdown"
          target="_blank">Markdown</a> to customize your instruction.`),
      };
    },
    subExploitantSelected() {
      return this.$store.state.authentication.selectedSubExploitant;
    },
    queryParams() {
      const { itemsPerPage, sortBy, sortDesc, page } = this.options;
      const params = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        q: this.filters.search,
        date_start: this.filters.dateStart,
        date_end: this.filters.dateEnd,
        sub_exploitant_id: this.subExploitantSelected,
      };
      return params;
    },
    exportDisabled() {
      return this.incidents.length > EXPORT_COUNT_LIMIT;
    },
    flightIncidentAnnouncementViewer() {
      return this.flightIncidentAnnouncementText || this.flightIncidentAnnouncement?.text || '';
    },
  },
  watch: {
    queryParams: {
      handler() { this.getFlightIncidents(); },
      deep: true,
    },
  },
  created() {
    this.getFlightIncidentAnnouncement();
  },
  methods: {
    getFlightIncidentAnnouncement() {
      APIService.getFlightIncidentAnnouncement()
        .then(({ data }) => {
          this.flightIncidentAnnouncement = data;
        });
    },
    expandIncident(item) {
      if (this.expanded.length && this.expanded[0].id === item.id) {
        this.expanded = [];
      } else {
        this.expanded = [item];
        this.getFlightIncidentDetails(item.id);
      }
    },
    async openForm(item = null) {
      if (item) {
        if (this.incidentExpandedDetails?.id !== item.id) {
          this.expanded = [item];
          await this.getFlightIncidentDetails(item.id);
        }
        this.incidentToUpdate = { ...this.incidentExpandedDetails };
      }
      this.dialogForm = true;
    },
    closeForm() {
      this.dialogForm = false;
      this.incidentToUpdate = null;
    },
    getFlightIncidents() {
      this.expanded = [];
      this.incidentExpandedDetails = null;
      this.incidentsLoading = true;
      APIService.getFlightIncidents(this.queryParams)
        .then(({ data }) => {
          this.incidents = data.results;
          this.incidentsTotal = data.count;
        })
        .finally(() => {
          this.incidentsLoading = false;
        });
    },
    async getFlightIncidentDetails(id) {
      this.incidentDetailsLoading = true;
      await APIService.getFlightIncidentDetails(id)
        .then(({ data }) => {
          this.incidentExpandedDetails = data;
        })
        .finally(() => {
          this.incidentDetailsLoading = false;
        });
    },
    exportFlightIncidents() {
      this.exportLoading = true;
      APIService.exportFlightIncidents({
        q: this.filters.search,
        date_start: this.filters.dateStart,
        date_end: this.filters.dateEnd,
        sub_exploitant_id: this.subExploitantSelected,
      })
        .then(() => {
          this.showMessage(
            this.$gettext('You will receive an email with your export soon.'), 'success',
          );
        })
        .finally(() => {
          this.exportLoading = false;
          this.dialogExport = false;
        });
    },
    downloadDocument(index, documentId, documentName) {
      this.documentDownloadLoading[index] = true;
      this.$forceUpdate();
      APIService.downloadDocument(documentId)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.documentDownloadLoading[index] = false;
          this.$forceUpdate();
        });
    },
    resetFilters() {
      this.filters = {
        search: null,
        dateStart: null,
        dateEnd: null,
      };
    },
    getConsequenceDisplay(consequence) {
      return FLIGHT_INCIDENT_CONSEQUENCES.find((c) => c.value === consequence)?.label || '';
    },
    openFlightIncidentAnnouncementDialog() {
      if (this.flightIncidentAnnouncement) {
        this.flightIncidentAnnouncementText = this.flightIncidentAnnouncement.text;
      }
      this.flightIncidentAnnouncementDialog = true;
    },
    closeFlightIncidentAnnouncementDialog() {
      this.flightIncidentAnnouncementDialog = false;
      this.loadingFlightIncidentAnnouncement = false;
      this.flightIncidentAnnouncementText = '';
    },
    createOrUpdateFlightIncidentAnnouncement() {
      this.loadingFlightIncidentAnnouncement = true;
      let promise;
      if (this.flightIncidentAnnouncement) {
        promise = APIService.updateFlightIncidentAnnouncement(
          { id: this.flightIncidentAnnouncement.id, text: this.flightIncidentAnnouncementText },
        );
      } else {
        promise = APIService.createFlightIncidentAnnouncement(
          { text: this.flightIncidentAnnouncementText },
        );
      }
      promise
        .then(({ data }) => {
          this.flightIncidentAnnouncement = data;
          this.showMessage(this.$gettext('Instruction updated successfully'), 'success');
        })
        .finally(() => {
          this.closeFlightIncidentAnnouncementDialog();
        });
    },
    deleteFlightIncidentAnnouncement() {
      this.loadingFlightIncidentAnnouncement = true;
      APIService.deleteFlightIncidentAnnouncement(this.flightIncidentAnnouncement.id)
        .then(() => {
          this.flightIncidentAnnouncementDeletionDialog = false;
          this.flightIncidentAnnouncement = null;
          this.showMessage(this.$gettext('Instruction deleted'), 'success');
        })
        .finally(() => {
          this.loadingFlightIncidentAnnouncement = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight-incidents {

  .header {

    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    flex-wrap: wrap;

    .headline {
      flex-grow: 1;
    }
    .buttons {
      justify-content: flex-end;
      flex-wrap: wrap-reverse;
    }
  }

  ::v-deep .v-data-table__wrapper>table>tbody>tr.v-data-table__expanded {
    &.v-data-table__expanded__row>td {
      border-bottom: 0;
      background-color: lighten($color: $color-info, $amount: 70);
    }
    &.v-data-table__expanded__content>td {
      border-bottom: thin solid rgba(0, 0, 0, .12);
      background-color: lighten($color: $color-info, $amount: 70);
    }
  }

  .height-0 {
    height: 0;
  }
}

</style>
