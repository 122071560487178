/* eslint import/prefer-default-export: 0 */

/**
 * Add a JWT Authorization header to every axios request
 * Need to be linked to Vuex store and thus access user's token
 *
 * @param {Object} authenticationState
 * Vuex sub-state object that store authentication data
 */
export const addTokenToHeader = (authenticationState) => (config) => {
  const { token } = authenticationState;
  const result = { ...config };
  if (token) {
    result.headers.Authorization = `JWT ${token}`;
  }
  return result;
};

export const manageExpiredSignature = (logoutFunction) => (error) => {
  if (error?.response?.status === 401) {
    logoutFunction();
  }
};

/**
 * Debounce a function:
 * Only call it after previous calls have stopped for a certain time period.
 * (Ignore those previous calls)
 * @param {Function} f the function to debounce
 * @param {Number} t the debounce time in milliseconds
 */
export function debounce(f, t) {
  // eslint-disable-next-line
  return function (args) {
    // eslint-disable-next-line
    let previousCall = this.lastCall;
    this.lastCall = Date.now();
    if (previousCall && ((this.lastCall - previousCall) <= t)) {
      clearTimeout(this.lastCallTimer);
    }
    this.lastCallTimer = setTimeout(() => f(args), t);
  };
}

/**
 * Skip the response of old promises
 * If a promise is not the last promise called, it's response will not be returned
 * (Only applied to promises sharing the same key)
 * @param {Promise} p the promise
 */
export function skipOldPromiseResults(p) {
  // eslint-disable-next-line
  return function ({ key, args }) {
    // eslint-disable-next-line
    if (!this.lastCalls) {
      this.lastCalls = {};
    }
    if (!this.lastAppliedCalls) {
      this.lastAppliedCalls = {};
    }
    // Remember the last promise to be called (among promises sharing the same key)
    this.lastCalls[key] = Date.now();
    return p(args)
      .then((result) => {
        if (this.lastCalls[key] !== this.lastAppliedCalls[key]) {
          this.lastAppliedCalls[key] = this.lastCalls[key];
          return result;
        }
        // If old promise do not return response
        return undefined;
      });
  };
}
