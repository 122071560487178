<template>
  <v-expansion-panels
    light
    class="mt-4"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span
          v-if="fullLabel"
          class="details-card__label"
          v-translate
        >
          DECISIONS OF THE APPROVAL REQUEST
        </span>
        <span
          v-else
          class="details-card__label"
          v-translate
        >
          DECISIONS
        </span>
        <template v-slot:actions>
          <v-progress-circular
            v-if="loadingDecisions"
            indeterminate
            color="primary"
            :size="20"
            :width="2"
          />
          <v-icon v-else>keyboard_arrow_down</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content expand-icon="keyboard_arrow_down">
        <!-- Sub approvals without decisions -->
        <v-skeleton-loader
          v-if="loadingDecisions"
          light
          type="text"
        />
        <v-col
          v-else-if="subApprovalsWithoutDecision.length"
          class="d-flex my-2 align-center outlined"
          :class="getStatusClass('submitted')"
          @mouseenter="setSubApprovalsHover(subApprovalsWithoutDecision.map((s) => s.id))"
          @mouseleave="setSubApprovalsHover([])"
        >
          <StatusIcon status="submitted" />
          <div class="ml-4">
            <translate
              :translate-n="subApprovalsWithoutDecision.length"
              :translate-params="
              { name: subApprovalsWithoutDecision.map((s) => s.identifier).join(', ') }
            "
              translate-plural="Untreated zones: %{ name }"
            >
              Untreated zone: %{ name }
            </translate>
          </div>
        </v-col>
        <!-- Decisions -->
        <v-col
          v-for="(decision, index) in decisions"
          :key="index"
          class="d-flex flex-column justify-center my-2 outlined"
          :class="getStatusClass(decision.status)"
          @mouseenter="setSubApprovalsHover(decision.sub_approvals)"
          @mouseleave="setSubApprovalsHover([])"
        >
          <div class="d-flex align-center">
            <StatusIcon :status="decision.status" />
            <div class="ml-4">
              <div>
                {{ getDecisionText(decision) }}
              </div>
              <div v-if="decision.flying_height">
                <span
                  v-translate
                  class="font-weight-medium"
                >
                  Flying height restriction:
                </span>
                <span>
                  {{ decision.flying_height | meterAndFeet }}
                </span>
              </div>
              <!-- Period restriction -->
              <div v-if="decision.date_start || decision.date_end">
                <span
                  v-translate
                  class="font-weight-medium"
                >
                  Period allowed:
                </span>
                <span>
                  {{ decision.date_start || flight.date_start | date }}
                  - {{ decision.date_end || flight.date_end | date }}
                </span>
              </div>
              <!-- Time restriction -->
              <div v-if="decision.time_start || decision.time_end">
                <span
                  v-translate
                  class="font-weight-medium"
                >
                  Time range allowed:
                </span>
                <span>
                  {{ decision.time_start || flight.time_start | hour }}
                  - {{ decision.time_end || flight.time_end | hour }} loc.
                </span>
                <span>
                  ({{ decision.time_start_utc || flight.time_start_utc | hour }}
                  - {{ decision.time_end_utc || flight.time_end_utc | hour }} UTC)
                </span>
              </div>
              <div
                v-if="decision.comment"
                @click="updateCommentsTruncated(index)"
              >
                <span
                  v-translate
                  class="font-weight-medium"
                >
                  Comment added:
                </span>
                <span class="multilines">
                  {{ truncateText('comment', index, decision.comment) }}
                </span>
              </div>
              <div
                v-if="decision.authority_note"
                @click="updateNotesTruncated(index)"
              >
                <span
                  v-translate
                  class="font-weight-medium"
                >
                  Note:
                </span>
                <span class="multilines">
                  {{ truncateText('note', index, decision.authority_note) }}
                </span>
              </div>
              <div
                v-if="decision.extra_requirements"
                @click="updateRequirementsTruncated(index)"
              >
                <span
                  v-translate
                  class="font-weight-medium"
                >
                  Particular requirements:
                </span>
                <span class="multilines">
                  {{ truncateText('requirement', index, decision.extra_requirements) }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="canUpdatePeriod"
            class="d-flex justify-end clickable-text"
          >
            <span
              text
              @click="dialogUpdatePeriod=true; decisionToUpdate=decision;"
              v-translate
            >
              Update decision period
            </span>
          </div>
        </v-col>
        <v-col
          v-if="canCloseApproval"
          class="d-flex my-2 align-center justify-end clickable-text"
        >
          <span
            text
            @click="dialogCloseApproval=true"
            v-translate
          >
            Close the approval
          </span>
        </v-col>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- Update period dialog -->
    <v-dialog
      v-if="decisionToUpdate !== null"
      v-model="dialogUpdatePeriod"
      width="850"
      :retain-focus="false"
      persistent
    >
      <ChangeApprovalDecisionPeriod
        :flight="approval.flight"
        :decision="decisionToUpdate"
        @update="getDecisions()"
        @close="dialogUpdatePeriod=false; decisionToUpdate=null"
      />
    </v-dialog>
    <!-- Close approval dialog -->
    <v-dialog
      v-model="dialogCloseApproval"
      width="500px"
      :retain-focus="false"
      persistent
    >
      <v-card
        class="default--dialog__card"
        max-height="800px"
        elevation="0"
      >
        <v-card-title>
          <span v-translate>Close the approval</span>
        </v-card-title>
        <v-card-text>
          <p v-translate>Are you sure you want to close this approval?</p>
          <p>
            {{ texts.explanationCloseApproval }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="dialogCloseApproval=false"
            :loading="loadingApprovalClose"
          >
            <span v-translate>Cancel</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="loadingApprovalClose"
            @click="closeApproval()"
          >
            <span v-translate>Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panels>
</template>

<script>
import APIService from '@/services/api';

import { SET_APPROVAL_IS_CLOSED_NS } from '@/store/approvals';
import { SET_APPROVAL_AREA_HOVERS_NS } from '@/store/map';
import { ACCEPTED, INCOMPLETE, RESERVES } from '@/store/status';

import ChangeApprovalDecisionPeriod from '@/components/Approvals/ChangeApprovalDecisionPeriod.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'AuthorityDecisionsDetails',
  components: {
    ChangeApprovalDecisionPeriod,
    StatusIcon,
  },
  props: {
    approval: Object,
    subApprovals: {
      type: Array,
      required: false,
      default: () => [],
    },
    fullLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    forActivationId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      loadingDecisions: false,
      decisions: [],
      commentsTruncated: [],
      notesTruncated: [],
      requirementsTruncated: [],
      dialogCloseApproval: false,
      loadingApprovalClose: false,
      dialogUpdatePeriod: false,
      decisionToUpdate: null,
    };
  },
  computed: {
    texts() {
      return {
        explanationCloseApproval: this.$gettext(`By closing it, this request for approval will
          become archived for the telepilot. To fly again, he will submit another approval
          request.`),
      };
    },
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
    structureSettings() {
      return this.$store.getters['structures/structureSettings'];
    },
    approvalCloseActivated() {
      return this.structureSettings.approval_close_activated;
    },
    canCloseApproval() {
      return this.approvalCloseActivated && !this.approval.is_closed;
    },
    approvalsStatusDict() {
      return this.$store.state.status.approvalsStatusDict;
    },
    subApprovalsWithoutDecision() {
      const subApprovalIds = this.decisions.map((d) => d.sub_approvals).flat();
      return this.subApprovals.filter((s) => !subApprovalIds.includes(s.id));
    },
    flight() {
      return this.approval.flight;
    },
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
    generateApprovalProtocolSheet() {
      return this.structureSettings.generate_approval_protocol_sheet;
    },
    canUpdatePeriod() {
      return (
        this.isAuthorityAdminOrManager
        && this.forActivationId === undefined
        && (
          this.approval.status === RESERVES
          || (
            [INCOMPLETE, ACCEPTED].includes(this.approval.status)
            && this.generateApprovalProtocolSheet
          )
        )
      );
    },
  },
  created() {
    this.getDecisions();
  },
  methods: {
    getDecisions() {
      this.loadingDecisions = true;
      let promise;
      if (this.forActivationId) {
        promise = APIService.getActivationApprovalDecisions(this.forActivationId);
      } else {
        promise = APIService.getApprovalDecisions(this.approval.id);
      }
      promise
        .then(({ data }) => {
          this.decisions = data;
          this.commentsTruncated = this.decisions.map(() => true);
          this.notesTruncated = this.decisions.map(() => true);
          this.requirementsTruncated = this.decisions.map(() => true);
        })
        .finally(() => {
          this.loadingDecisions = false;
        });
    },
    getDecisionText(decision) {
      const numberSubApprovals = decision.sub_approvals.length;
      const status = (
        this.approvalsStatusDict.find((a) => a.value === decision.status)?.text
        || this.$gettext('Unkown decision')
      );
      return this.$gettextInterpolate(
        this.$ngettext(
          "The zone %{ zones } status is '%{ status }'.",
          "The zones %{ zones } status is '%{ status }'.",
          numberSubApprovals,
        ),
        {
          zones: this.subApprovals.filter(
            (s) => decision.sub_approvals.includes(s.id),
          ).map((s) => s.identifier).join(', '),
          status,
        },
      );
    },
    getStatusClass(status) {
      return { [status]: true, 'cursor-pointer': !this.isAuthorityFullScreenInterface };
    },
    setSubApprovalsHover(ids) {
      if (this.isAuthorityFullScreenInterface) return;
      this.$store.dispatch(SET_APPROVAL_AREA_HOVERS_NS, ids);
    },
    updateCommentsTruncated(index) {
      this.commentsTruncated[index] = !this.commentsTruncated[index];
      this.$forceUpdate();
    },
    updateNotesTruncated(index) {
      this.notesTruncated[index] = !this.notesTruncated[index];
      this.$forceUpdate();
    },
    updateRequirementsTruncated(index) {
      this.requirementsTruncated[index] = !this.requirementsTruncated[index];
      this.$forceUpdate();
    },
    truncateText(type, index, text) {
      if (text.length > 220) {
        if (type === 'comment' && this.commentsTruncated.at(index)) {
          return `${text.slice(0, 220)} [...]`;
        }
        if (type === 'note' && this.notesTruncated.at(index)) {
          return `${text.slice(0, 220)} [...]`;
        }
        if (type === 'requirement' && this.requirementsTruncated.at(index)) {
          return `${text.slice(0, 220)} [...]`;
        }
      }
      return text;
    },
    closeApproval() {
      this.loadingApprovalClose = true;
      APIService.closeApproval(this.approval.id)
        .then(({ data }) => {
          this.$store.commit(
            SET_APPROVAL_IS_CLOSED_NS, { approvalId: this.approval.id, isClosed: true },
          );
          this.showMessage(data, 'success');
        })
        .finally(() => {
          this.loadingApprovalClose = false;
          this.dialogCloseApproval = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.outlined {
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  &.accepted {
    border-color: $accepted-background-color;
  }
  &.incomplete {
    border-color: $incomplete-background-color;
  }
  &.refused {
    border-color: $refused-background-color;
  }
  &.reserves {
    border-color: $reserves-background-color;
  }
  &.pending {
    border-color: $pending-background-color;
  }
  &.submitted, &.awaiting_validation {
    border-color: $submitted-background-color;
  }
}
.clickable-text {
  cursor: pointer;
  font-style: italic;
  text-decoration: underline;
}

</style>
