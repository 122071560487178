<template>
  <div>
    <v-card
      v-if="!showInactivatedDronesDialog"
      class="default--dialog__card"
    >
      <v-card-title>
        <translate>Demande de confirmation</translate>
      </v-card-title>
      <v-card-text>
        <div>
          <strong>
            {{ text.importAlphaTangoTitle }}
          </strong>
          <div class="my-2">
            <translate>
              Notez que pour que l'import des drones depuis Alpha Tango soit possible, vous devez :
            </translate>
          </div>
          <div>
            <ul>
              <li>
                <translate>
                  Déclarer Clearance comme votre délégataire sur le site Alpha Tango.
                </translate>
              </li>
              <li>
                {{ text.alphaTangoId }}
              </li>
              <li>
                {{ text.edNumber }}
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="hasManyAlphatangoUids"
          class="mt-6"
        >
          <span>
            {{ text.multipleAlphatangoUid }}
          </span>
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              cols="12"
              sm="8"
            >
              <v-select
                v-model="selectedAlphatangoUid"
                :items="exploitationAlphatangoUids"
                :label="$gettext('AlphaTango UID')"
              >
                <!-- Display items on open -->
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ getItemText(item) }}
                  </span>
                </template>
                <!-- Display items on select -->
                <template v-slot:item="{ item }">
                  {{ getItemText(item) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('close-form')">
          <translate>No</translate>
        </v-btn>
        <v-btn
          class="white--text"
          color="primary"
          :loading="importRunning"
          :disabled="disabledImport"
          @click="confirmImport()"
        >
          <translate>Yes</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- Action inactive drones -->
    <v-card
      v-else
      class="default--dialog__card"
    >
      <v-card-title key="inactivate-drones-toolbar">
        <translate>Rendre inactif les drones non importés</translate>
      </v-card-title>
      <v-card-text key="inactivate-drones-text">
        <div>
          <strong>
            {{ text.inactivatedDrone }}
          </strong>
        </div>
        <div class="mt-1">
          {{ text.inactivatedDroneNoDeleteIt }}
        </div>
        <div class="mt-1">
          <translate>
            Drones concernés par la modification :
          </translate>
          <ul>
            <li
              v-for="(drone, key) in unknownDrones"
              :key="key"
            >
              {{ labelDroneData(drone) }}
            </li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions key="inactive-actions">
        <v-btn @click="inactivatedDrones()">
          <translate>Yes, inactivated drones</translate>
        </v-btn>
        <v-btn
          class="white--text"
          color="primary"
          @click="closeInactivatedDronesDialog()"
        >
          <translate>No, don't inactivated drones</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import API from '@/services/api';

import { GET_DRONES_NS } from '@/store/drones';

export default {
  name: 'ImportAlphaTangoForm',
  data() {
    return {
      importRunning: false,
      message: null,
      idsDronesImported: [],
      showInactivatedDronesDialog: false,
      selectedAlphatangoUid: null,
      alphatangoExploitantsNames: null,
    };
  },
  computed: {
    text() {
      return {
        importAlphaTangoTitle: this.$gettext(`Souhaitez-vous réellement importer la liste des
          drones depuis votre compte AlphaTango ?`),
        alphaTangoId: this.$gettext(`Enregistrer vos drones sur Alpha Tango (Alpha Tango ne nous
          permet pas de récupérer automatiquement des informations sur des drones non enregistrés).
          Vos drones doivent donc avoir un numéro d'enregistrement (format UAS-FR-XXX).`),
        edNumber: this.$gettext(`Indiquer votre numéro d'exploitant (AlphaTango UID). Vous pouvez
           le faire sur la page "Mon entreprise" (lien dans menu en haut à droite de la page).`),
        inactivatedDrone: this.$gettext(`Ces drones ne sont pas déclarés sur AlphaTango,
          voulez-vous les rendre inactifs sur Clearance ?`),
        inactivatedDroneNoDeleteIt: this.$gettext(`Les drones inactifs ne sont pas supprimés, ils
          apparaissent toujours dans le suivi d'activité, vous y avez encore accès pour les rendre
          actifs à nouveau. Ils ne sont par contre pas proposés pour les prochaines missions.`),
        multipleAlphatangoUid: this.$gettext(`Plusieurs identifiants alphatango
          sont liés à votre compte, veuillez sélectionner celui pour lequel vous souhaitez
          importer les drones.`),
      };
    },
    drones() {
      return this.$store.state.drones.drones;
    },
    unknownDrones() {
      if (this.idsDronesImported.length && this.drones.length) {
        let existingActiveDronesIds = this.drones.filter((d) => d.active);
        if (this.hasManyAlphatangoUids) {
          existingActiveDronesIds = existingActiveDronesIds.filter(
            (d) => d.exploitant_alphatango_uid === this.selectedAlphatangoUid,
          );
        }
        return existingActiveDronesIds.filter((d) => !this.idsDronesImported.includes(d.id));
      }
      return [];
    },
    exploitationAlphatangoUids() {
      return this.$store.state.exploitants.exploitationDetails.alphatango_uids;
    },
    hasManyAlphatangoUids() {
      return this.$store.getters['exploitants/hasManyAlphatangoUids'];
    },
    disabledImport() {
      if (!this.hasManyAlphatangoUids) {
        return false;
      }
      return !(this.selectedAlphatangoUid);
    },
  },
  mounted() {
    if (this.hasManyAlphatangoUids) {
      API.getAlphatangoExploitantNames()
        .then(({ data }) => {
          this.alphatangoExploitantsNames = data;
        });
    }
  },
  methods: {
    async confirmImport() {
      this.importRunning = true;
      const alphatangoUid = this.hasManyAlphatangoUids
        ? this.selectedAlphatangoUid : this.exploitationAlphatangoUids[0];
      await API.importDronesAlphaTango(alphatangoUid)
        .then(({ data }) => {
          this.message = data.message;
          this.idsDronesImported = data.ids;
          if (this.unknownDrones.length) {
            this.showInactivatedDronesDialog = true;
          } else {
            this.closeInactivatedDronesDialog();
          }
        })
        .finally(() => {
          this.importRunning = false;
        });
    },
    closeInactivatedDronesDialog() {
      this.showMessage(this.message, 'success', false, true);
      this.$store.dispatch(GET_DRONES_NS);
      this.importRunning = false;
      this.$emit('close-form');
      this.showInactivatedDronesDialog = false;
    },
    inactivatedDrones() {
      const ids = this.unknownDrones.map((d) => d.id);
      API.inactivatedDrones(ids)
        .then(({ data }) => {
          const nbDronesInactivated = data.ids_inactivated.length;
          const message = this.$ngettext(
            // singular
            ' %{nbDronesInactivated} drone est passé au statut inactif.',
            // plural
            ' %{nbDronesInactivated} drones sont passés au statut inactif.',
            nbDronesInactivated,
          );
          this.message += this.$gettextInterpolate(
            message,
            { nbDronesInactivated },
          );
        })
        .finally(() => {
          this.closeInactivatedDronesDialog();
        });
    },
    labelDroneData(drone) {
      let message = this.$gettextInterpolate(
        '%{name} (modèle %{model}',
        { name: drone.name, model: drone.model_name },
        true,
      );
      if (drone.serial_number) {
        message += this.$gettextInterpolate(
          ', numéro de série %{serialNumber}',
          { serialNumber: drone.serial_number },
          true,
        );
      }
      if (drone.alpha_tango_id) {
        message += this.$gettextInterpolate(
          ', identifiant AlphaTango UAS-FR-%{alphaTangoId}',
          { alphaTangoId: drone.alpha_tango_id },
        );
      }
      message += ')';
      return message;
    },
    getItemText(alphatangoUid) {
      if (this.alphatangoExploitantsNames) {
        const result = this.alphatangoExploitantsNames.find((n) => n.uid === alphatangoUid) || '';
        if (result) {
          return `${result.uid} - ${result.name}`;
        }
        return this.$gettextInterpolate(
          this.$gettext("%{ alphatangoUid } - No Clearance's delegant found"),
          { alphatangoUid },
        );
      }
      return alphatangoUid;
    },
  },
};
</script>
