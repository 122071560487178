<template>
  <v-card
    max-height="900px"
    elevation="0"
  >
    <v-card-title class="headline font-weight-regular primary white--text mb-4">
      <div class="d-flex align-center">
        <div>
          <v-img
            :src="structureExternalOperatorLogoPath"
            max-width="100px"
          />
        </div>
        <div class="pl-6">
          <v-row
            no-gutters
            class="body-2"
          >
            {{ header.title }}
          </v-row>
          <v-row
            no-gutters
            class="caption"
          >
            {{ header.subtitle }}
          </v-row>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-container class="pa-0">
        <!-- Flight -->
        <v-row
          wrap
          no-gutters
          class="details-card"
        >
          <template v-if="approval.display_identifier">
            <v-col cols="3">
              <span
                class="details-card__label"
                v-translate
              >
                FLIGHT NUMBER
              </span>
            </v-col>
            <v-col cols="9">
              <span>
                {{ approval.display_identifier }}
              </span>
            </v-col>
          </template>
          <v-col
            cols="3"
            sm="3"
          >
            <span
              class="details-card__label"
              v-translate
            >
              TITLE
            </span>
          </v-col>
          <v-col
            cols="9"
            sm="3"
          >
            <span>
              {{ externalDetails.title }}
            </span>
          </v-col>
          <v-col
            cols="3"
            sm="3"
          >
            <span
              class="details-card__label"
              v-translate
            >
              HEIGHT
            </span>
          </v-col>
          <v-col
            cols="9"
            sm="3"
          >
            <span>
              {{ externalDetails.maxHeight }}m
            </span>
          </v-col>
          <v-col
            cols="3"
            sm="3"
          >
            <span
              class="details-card__label"
              v-translate
            >
              DATE
            </span>
          </v-col>
          <v-col
            cols="9"
            sm="3"
          >
            <span>
              {{ getDate(externalDetails.period.startDate, externalDetails.period.endDate) }}
            </span>
          </v-col>
          <v-col
            cols="3"
            sm="3"
          >
            <span
              class="details-card__label"
              v-translate
            >
              MAX DRONE WEIGHT
            </span>
          </v-col>
          <v-col
            cols="9"
            sm="3"
          >
            <span>
              {{ externalDetails.operations.maxWeight }}kg
            </span>
          </v-col>
          <v-col
            cols="3"
            sm="3"
          >
            <span
              class="details-card__label"
              v-translate
            >
              HOUR
            </span>
          </v-col>
          <v-col
            cols="9"
            sm="3"
          >
            <span>
              {{ getHours(
                externalDetails.period.startDate, externalDetails.period.endDate,
                externalDetails.period.startTime, externalDetails.period.endTime
              ) }}
            </span>
          </v-col>
          <template v-if="isThalesApproval">
            <v-col
              cols="3"
              sm="3"
            >
              <span
                class="details-card__label"
                v-translate
              >
                DISTANCE DRONE PILOT
              </span>
            </v-col>
            <v-col
              cols="9"
              sm="3"
            >
              <span>
                {{ externalDetails.distancePilotToUav }}m
              </span>
            </v-col>
          </template>
          <v-col
            cols="3"
            sm="3"
          >
            <span
              class="details-card__label"
              v-translate
            >
              FLIGHT INSIGHT
            </span>
          </v-col>
          <v-col
            cols="9"
            sm="3"
          >
            <span>
              {{ yesNoLabel(externalDetails.operations.isVLOS) }}
            </span>
          </v-col>
          <template v-if="!isThalesApproval">
            <v-col
              cols="3"
              sm="3"
            >
              <span
                class="details-card__label"
                v-translate
              >
                NIGHT FLIGHT
              </span>
            </v-col>
            <v-col
              cols="9"
              sm="3"
            >
              <span>
                {{ yesNoLabel(externalDetails.operations.isAeronauticalNight) }}
              </span>
            </v-col>
          </template>
          <v-col
            cols="3"
            sm="3"
          >
            <span
              class="details-card__label"
              v-translate
            >
              FLIGHT IN POPULATED AREA
            </span>
          </v-col>
          <v-col
            cols="9"
            sm="3"
          >
            <span>
              {{ yesNoLabel(externalDetails.operations.isPopulatedZone) }}
            </span>
          </v-col>
          <template v-if="!isThalesApproval">
            <v-col
              cols="3"
              sm="3"
            >
              <span
                class="details-card__label"
                v-translate
              >
                CAPTIVE FLIGHT
              </span>
            </v-col>
            <v-col
              cols="9"
              sm="3"
            >
              <span>
                {{ yesNoLabel(externalDetails.operations.isCaptiveFlight) }}
              </span>
            </v-col>
          </template>
          <template
            v-if="isThalesApproval && externalDetails.operations.isZoneIlluminated !== null"
          >
            <v-col
              cols="3"
              sm="3"
            >
              <span
                class="details-card__label"
                v-translate
              >
                FLIGHT IN LUMINOUS AREA
              </span>
            </v-col>
            <v-col
              cols="9"
              sm="3"
            >
              <span>
                {{ yesNoLabel(externalDetails.operations.isZoneIlluminated) }}
              </span>
            </v-col>
          </template>
          <hr class="separator">
          <!-- Description -->
          <v-row no-gutters>
            <v-col cols="3">
              <span
                class="details-card__label"
                v-translate
              >
                DESCRIPTION
              </span>
            </v-col>
            <v-col cols="9">
              <span>
                {{ externalDetails.description }}
              </span>
            </v-col>
          </v-row>
          <!-- Address -->
          <template v-if="isThalesApproval">
            <hr class="separator">
            <v-row no-gutters>
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  ADDRESS
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ externalDetails.addressDescription }}
                </span>
              </v-col>
            </v-row>
          </template>
          <hr class="separator" />
          <!-- Exploitant -->
          <v-row no-gutters>
            <v-col cols="3">
              <span
                class="details-card__label"
                v-translate
              >
                DECLARANT
              </span>
            </v-col>
            <v-col cols="9">
              <span>
                {{ externalDetails.applicant.firstName }}
                {{ externalDetails.applicant.lastName }}
              </span>
            </v-col>
            <template v-if="isThalesApproval">
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  GENDER
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ genderLabel(externalDetails.applicant.gender) }}
                </span>
              </v-col>
            </template>
            <v-col cols="3">
              <span
                class="details-card__label"
                v-translate
              >
                ENTERPRISE
              </span>
            </v-col>
            <v-col cols="9">
              <span>
                {{ externalDetails.applicant.businessName }}
              </span>
            </v-col>
            <v-col cols="3">
              <span
                class="details-card__label"
                v-translate
              >
                EMAIL
              </span>
            </v-col>
            <v-col cols="9">
              <span>
                {{ externalDetails.applicant.email }}
              </span>
            </v-col>
            <v-col cols="3">
              <span
                class="details-card__label"
                v-translate
              >
                PHONE NUMBER
              </span>
            </v-col>
            <v-col cols="9">
              <span>
                {{ externalDetails.applicant.phone }}
              </span>
            </v-col>
            <template v-if="isThalesApproval">
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  REGISTRATION NUMBER
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ externalDetails.applicant.registrationNumber }}
                </span>
              </v-col>
            </template>
          </v-row>
          <template v-if="!isThalesApproval">
            <hr class="separator" />
            <v-row no-gutters>
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  EXPLOITANT
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ externalDetails.operator.firstName }}
                  {{ externalDetails.operator.lastName }}
                </span>
              </v-col>
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  ENTERPRISE
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ externalDetails.operator.businessName }}
                </span>
              </v-col>
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  EMAIL
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ externalDetails.operator.email }}
                </span>
              </v-col>
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  PHONE NUMBER
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ externalDetails.operator.phone }}
                </span>
              </v-col>
            </v-row>
          </template>
          <!-- Pilot -->
          <template v-for="(contact, index) in externalDetails.contacts">
            <hr
              :key="'contact-separator- ' + index"
              class="separator"
            >
            <v-row
              no-gutters
              :key="'contact-' + index"
            >
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  PILOT
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ contact.firstName }} {{ contact.lastName}}
                </span>
              </v-col>
              <template v-if="isThalesApproval && contact.gender !== null">
                <v-col cols="3">
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    GENDER
                  </span>
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ genderLabel(contact.gender) }}
                  </span>
                </v-col>
              </template>
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  EMAIL
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ contact.email }}
                </span>
              </v-col>
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  PHONE NUMBER
                </span>
              </v-col>
              <v-col cols="9">
                <span>
                  {{ contact.phone }}
                </span>
              </v-col>
            </v-row>
          </template>
          <!-- Drone -->
          <template v-for="(aircraft, index) in externalDetails.aircrafts">
            <hr
              :key="'aircraft-separator- ' + index"
              class="separator"
            >
            <v-row
              no-gutters
              :key="'aircraft-' + index"
            >
              <v-col cols="3">
                <span
                  class="details-card__label"
                  v-translate
                >
                  DRONE
                </span>
              </v-col>
              <v-col cols="9">
                <span v-if="!isThalesApproval">
                  {{ aircraft.description }}
                </span>
                <span v-if="isThalesApproval">
                  {{ aircraft.type }}
                </span>
              </v-col>
              <template v-if="aircraft.registrationNumber">
                <v-col cols="3">
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    NUMBER
                  </span>
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ aircraft.registrationNumber }}
                  </span>
                </v-col>
              </template>
              <template v-if="isThalesApproval && aircraft.trackerId !== ''">
                <v-col cols="3">
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    TRACKER
                  </span>
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ aircraft.trackerId }}
                  </span>
                </v-col>
              </template>
              <template v-if="isThalesApproval && aircraft.luminous !== null">
                <v-col cols="3">
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    LUMINOUS
                  </span>
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ yesNoLabel(aircraft.luminous) }}
                  </span>
                </v-col>
              </template>
              <template v-if="aircraft.weight">
                <v-col cols="3">
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    WEIGHT
                  </span>
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ aircraft.weight }}kg
                  </span>
                </v-col>
              </template>
              <template v-if="aircraft.description">
                <v-col cols="3">
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    DESCRIPTION
                  </span>
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ aircraft.description }}
                  </span>
                </v-col>
              </template>
              <template v-if="aircraft.model">
                <v-col cols="3">
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    MODEL
                  </span>
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ aircraft.model }}
                  </span>
                </v-col>
              </template>
              <template v-if="aircraft.manufacturer">
                <v-col cols="3">
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    MANUFACTURER
                  </span>
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ aircraft.manufacturer }}
                  </span>
                </v-col>
              </template>
            </v-row>
          </template>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <div class="d-flex justify-end align-center pb-2">
        <v-btn
          class="mr-3"
          @click="$emit('close-form')"
        >
          <span v-translate>Quit</span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { EXTERNAL_OPERATORS } from '@/settings';

export default {
  name: 'ExternalApprovalDetails',
  props: {
    approval: Object,
    capability: Object,
  },
  computed: {
    usspIdentifier() {
      return this.capability.ussp_identifier;
    },
    externalDetails() {
      return this.approval.external_sent_payload;
    },
    structureExternalOperatorLogoPath() {
      if (this.usspIdentifier === EXTERNAL_OPERATORS.USK) {
        return `${process.env.BASE_URL}USK.png`;
      } if (this.usspIdentifier === EXTERNAL_OPERATORS.THALES) {
        return `${process.env.BASE_URL}Thales-logo.svg`;
      }
      return null;
    },
    isThalesApproval() {
      return this.usspIdentifier === EXTERNAL_OPERATORS.THALES;
    },
    header() {
      return {
        title: this.$gettext('Approval details'),
        subtitle: (
          this.isThalesApproval
            ? this.$gettext('for the partner zone (Thales platform)')
            : this.$gettextInterpolate(
              this.$gettext('for the partner zone managed by %{name} (U-SpaceKeeper platform)'),
              { name: this.approval.authority_name || '' },
            )
        ),
      };
    },
  },
  methods: {
    getDate(startDate, endDate) {
      let dateStart = new Date(startDate);
      // Handle timezones differences
      dateStart.setMinutes(dateStart.getMinutes() + dateStart.getTimezoneOffset());
      dateStart = dateStart.toLocaleDateString(
        this.$store.getters['application/currentLanguage'],
      );
      let dateEnd = new Date(endDate);
      // Handle timezones differences
      dateEnd.setMinutes(dateEnd.getMinutes() + dateEnd.getTimezoneOffset());
      dateEnd = dateEnd.toLocaleDateString(
        this.$store.getters['application/currentLanguage'],
      );
      return `${dateStart} - ${dateEnd}`;
    },
    getHours(startDate, endDate, startTime, endTime) {
      const startDateTime = new Date(`${startDate} ${startTime}Z`);
      const endDateTime = new Date(`${endDate} ${endTime}Z`);
      const localTimeStart = startDateTime.toLocaleTimeString(
        this.$store.getters['application/currentLanguage'],
      ).substring(0, 5);
      const localTimeEnd = endDateTime.toLocaleTimeString(
        this.$store.getters['application/currentLanguage'],
      ).substring(0, 5);
      return `${localTimeStart} - ${localTimeEnd}`;
    },
    yesNoLabel(boolean) {
      if (boolean) {
        return this.$gettext('Yes');
      }
      return this.$gettext('No');
    },
    genderLabel(gender) {
      if (gender === 'MALE') {
        return 'M';
      }
      return 'F';
    },
  },
};
</script>
