<template>
  <div>
    <v-expansion-panels
      light
      flat
      v-model="toolsPanelExpanded"
      class="dense__expansion-panel mt-2"
    >
      <v-expansion-panel expand-icon="keyboard_arrow_down">
        <!-- Flight Tools -->
        <v-expansion-panel-header>
          <span class="dense__expansion-panel__header-content">
            <v-icon
              class="pr-2"
              color="info"
            >
              mdi-menu
            </v-icon>
            <translate>Tools (screenshot, email project, flight area export ...)</translate>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- DOWNLOAD SCREENSHOT -->
          <v-row
            align="center"
            no-gutters
            class="pt-3"
          >
            <v-col
              cols="12"
              md="7"
              class="pb-1 pb-md-0 pr-0 pr-md-2"
            >
              <translate>Download map screenshot</translate>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="pb-2 pb-md-0"
            >
              <v-btn
                color="success"
                class="main-button__font truncated_button"
                block
                :loading="screenshotLoading"
                @click.stop="makeMapScreenshot()"
              >
                <span class="d-flex align-center">
                  <v-icon class="mr-1">mdi-camera</v-icon>
                  <translate>Screenshot</translate>
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <!-- EMAIL COPY TO CLIPBOARD  -->
          <v-row
            align="center"
            no-gutters
            class="pt-2"
          >
            <v-col
              cols="12"
              md="7"
              class="pb-1 pb-md-0 pr-0 pr-md-2"
            >
              <translate>Copy mail body to the clipboard</translate>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="pb-2 pb-md-0"
            >
              <v-btn
                color="success"
                class="main-button__font truncated_button"
                block
                @click.stop.prevent="copyMailMission()"
              >
                <span class="d-flex align-center">
                  <v-icon class="mr-1">mdi-email-outline</v-icon>
                  <translate>Email</translate>
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <!-- DOWNLOAD KML/KMZ  -->
          <v-row
            align="center"
            no-gutters
            class="pt-2"
          >
            <v-col
              cols="12"
              md="7"
              class="pb-1 pb-md-0 pr-0 pr-md-2"
            >
              <translate>Export your flight area in Google Earth format (KML/KMZ)</translate>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="pb-2 pb-md-0"
            >
              <v-btn
                color="success"
                class="main-button__font truncated_button"
                block
                @click="flightAreaExportDialog=true"
              >
                <span class="d-flex align-center">
                  <v-icon class="mr-1">mdi-map</v-icon>
                  <translate>Google Earth (KML/KMZ)</translate>
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <!-- GENERATE EXCLUSION ZONE  -->
          <v-row
            align="center"
            no-gutters
            class="pt-2"
          >
            <v-col
              cols="12"
              md="7"
              class="pb-1 pb-md-0 pr-0 pr-md-2"
            >
              <template v-if="flightExclusionZone">
                <v-chip class="documents__chip caption">
                  <span
                    class="documents__link-label"
                    @click="downloadFlightExclusionZone()"
                  >
                    {{ flightExclusionZone.name }}
                  </span>
                  <span class="font-italic info--text text--lighten-2 pl-1">
                    <translate>generated:</translate>
                    <span>
                      {{ flightExclusionZoneDate }}
                    </span>
                  </span>
                  <v-progress-circular
                    v-if="downloadFlightExclusionZoneLoading"
                    indeterminate
                    color="info"
                    class="ml-2"
                    size="14"
                    width="2"
                  />
                </v-chip>
              </template>
              <template v-else>
                <translate>Create a third party exclusion</translate>
              </template>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="pb-2 pb-md-0"
            >
              <v-btn
                color="success"
                class="main-button__font truncated_button"
                block
                :disabled="flightCollectionLoading"
                @click.stop.prevent="$emit('create-exclusion-zone', flight)"
              >
                <span class="d-flex align-center">
                  <v-icon class="mr-1">mdi-vector-square</v-icon>
                  <translate>Third party exclusion zone</translate>
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Flight Area Export Dialog -->
    <v-dialog
      v-model="flightAreaExportDialog"
      max-width="500"
      :retain-focus="false"
      persistent
    >
      <FlightAreaExport
        :flights="[flight]"
        @close="flightAreaExportDialog=false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { filterCoordDMS } from '@/filters/coord';
import { filterPhoneNumber } from '@/filters/phone';

import APIService from '@/services/api';

import { takeMapScreenshot } from '@/store/application';

import FlightAreaExport from '@/components/Flights/FlightAreaExport.vue';

export default {
  name: 'FlightTools',
  components: { FlightAreaExport },
  props: {
    flight: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toolsPanelExpanded: undefined,
      screenshotLoading: false,
      flightAreaExportDialog: false,
      downloadFlightExclusionZoneLoading: false,
    };
  },
  computed: {
    latitude() {
      if (this.flight.center && this.flight.center.coordinates) {
        return this.flight.center.coordinates[1];
      }
      return '';
    },
    longitude() {
      if (this.flight.center && this.flight.center.coordinates) {
        return this.flight.center.coordinates[0];
      }
      return '';
    },
    mailResumed() {
      // Format flight date to human readable date
      const dateStart = this.dateLocalFormat(this.flight.date_start);
      const dateEnd = this.dateLocalFormat(this.flight.date_end);
      const coordinateE = this.longitude ? this.longitude.toFixed(4) : '';
      const coordinateN = this.latitude ? this.latitude.toFixed(4) : '';
      const latitudeDMS = filterCoordDMS(this.latitude, false);
      const longitudeDMS = filterCoordDMS(this.longitude, true);
      const mailPartVol = this.$gettextInterpolate(
        this.$gettext(`Bonjour,<br><br>Je me permets de vous contacter pour une demande
          d'autorisation de travail aérien par drone.<br><br>Voici les détails du vol prévu :<br>
          <br>Exploitant : %{company}<br>Adresse : %{address}<br>Coordonnées géographiques :
          %{coordinateN}N %{coordinateE}E (%{latitudeDMS} %{longitudeDMS})<br>Élévation du sol au
          centre de la zone : %{elevation} m<br>Hauteur sol maximale : %{flyingHeight} m<br><br>
          Début de la plage de dates de vol : %{dateStart}<br>Fin de la plage de dates de vol :
          %{dateEnd}<br>Début de la plage horaire : %{timeStart}<br>Fin de la plage horaire :
          %{timeEnd}<br>Durée prévue : %{duration}<br><br>`),
        {
          company: this.flight.company_name,
          address: this.flight.address,
          coordinateN,
          coordinateE,
          latitudeDMS,
          longitudeDMS,
          elevation: this.flight.elevation,
          flyingHeight: this.flight.flying_height,
          dateStart,
          dateEnd,
          timeStart: this.flight.time_start,
          timeEnd: this.flight.time_end,
          duration: this.flight.expected_duration,
        },
        true,
      );

      // Add drones details
      let mailPartDrone = '';
      if (this.flight.drones.length > 1) {
        mailPartDrone += this.$gettext('<br>Drones list of the flight:<br>');
      }
      this.flight.drones.forEach((drone) => {
        mailPartDrone += this.$gettextInterpolate(
          this.$gettext('<br>Drone: %{label}<br>'),
          { label: drone.label },
          true,
        );
      });
      if (mailPartDrone !== '') {
        mailPartDrone += '<br>';
      }

      // Creation contact for each pilot
      let mailPartPilot = '';
      if (this.flight.pilots.length > 1) {
        mailPartPilot += this.$gettext('<br>Liste des télépilotes de la mission :<br>');
      }
      this.flight.pilots.forEach((pilot) => {
        mailPartPilot += this.$gettextInterpolate(
          this.$gettext(`<br>Télépilote : %{name}<br>
            Numéro de téléphone du télépilote : %{phoneNumber}<br>
            Email du télépilote : %{email}<br>`),
          {
            name: pilot.full_name,
            phoneNumber: filterPhoneNumber(pilot.phone_number),
            email: pilot.email,
          },
          true,
        );
      });
      if (mailPartPilot === '') {
        mailPartPilot = this.$gettext(`<br>[ Indiquez ici les coordonnées permettant de joindre le
          pilote (nom, mail et numéro de téléphone) ]<br>`);
      }
      mailPartPilot += this.$gettext(`<br>Je suis bien évidemment à votre disposition si vous
        souhaitez des renseignements supplémentaires pour étudier cette demande.<br><br>
        Cordialement,`);
      let mail = mailPartVol + mailPartDrone + mailPartPilot;
      mail = mail.replaceAll('\n', ' ');
      mail = mail.replaceAll('<br>', '\n');
      // Delete the indentation in the multiline string
      return mail.replace(/  +/g, ' ');
    },
    flightExclusionZone() {
      return this.flight.exclusion_zone;
    },
    flightExclusionZoneDate() {
      if (this.flightExclusionZone) {
        return this.datetimeLocalFormat(
          this.flightExclusionZone.updated_at,
        );
      }
      return '';
    },
    flightCollectionLoading() {
      return this.$store.state.flights.flightsCollectionLoading;
    },
  },
  methods: {
    makeMapScreenshot() {
      this.screenshotLoading = true;
      // Add coordinates of flight center
      const map = document.getElementById('map');
      const centerInfoDiv = document.createElement('div');
      centerInfoDiv.innerText = `Lat ${this.latitude} | Long ${this.longitude}`;
      centerInfoDiv.setAttribute('style', 'position: relative; margin: 10px;');
      map.appendChild(centerInfoDiv);
      // Take screenshot
      takeMapScreenshot()
        .then((canvas) => {
          const a = document.createElement('a');
          // toDataURL default is PNG, we convert to JPEG
          a.href = canvas
            .toDataURL('image/jpeg')
            .replace('image/jpeg', 'image/octet-stream');
          a.download = this.$gettext('flight_area.jpeg');
          a.click();
        })
        .finally(() => {
          this.screenshotLoading = false;
        });
      // Remove coordinates of flight center
      map.removeChild(centerInfoDiv);
    },
    dateLocalFormat(dateToFormat) {
      const dateFormatted = new Date(dateToFormat);
      // Handle timezones differences
      dateFormatted.setMinutes(dateFormatted.getMinutes() + dateFormatted.getTimezoneOffset());
      const optionDate = { year: 'numeric', month: 'long', day: 'numeric' };
      return dateFormatted.toLocaleDateString(this.$store.getters['application/currentLanguage'], optionDate);
    },
    datetimeLocalFormat(datetimeToFormat) {
      const datetimeFormatted = new Date(datetimeToFormat);
      const optionDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return datetimeFormatted.toLocaleString(this.$store.getters['application/currentLanguage'], optionDate);
    },
    copyMailMission() {
      const elem = document.createElement('textarea');
      elem.value = this.mailResumed;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);
      this.showMessage(
        this.$gettext('A mail project has been copied in the clipboard'),
        'success',
      );
    },
    downloadFlightExclusionZone() {
      this.downloadFlightExclusionZoneLoading = true;
      APIService.downloadFlightExclusionZone(this.flight.id)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          const filename = this.flightExclusionZone.name;
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.downloadFlightExclusionZoneLoading = false;
        });
    },
  },
};
</script>
