<template>
  <div class="d-flex align-center justify-end mr-1">
    <!-- Trackers -->
    <div
      v-if="trackingActivated"
      class="mr-6"
    >
      <v-menu
        v-if="isUserAuthority"
        v-model="menuTrackingAlert"
        :open-on-hover="false"
        close-on-content-click
        eager
        dark
        offset-y
        nudge-bottom="10"
        close-delay="75"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            large
          >
            <div class="d-flex align-center">
              <v-badge
                :value="nbUnreadTrackingAlerts"
                :content="nbUnreadTrackingAlerts"
                color="red"
                offset-x="15"
                offset-y="10"
              >
                <v-icon size="27">mdi-shield-alert-outline</v-icon>
              </v-badge>
            </div>
          </v-btn>
        </template>
        <TrackingAlertList />
      </v-menu>
      <v-badge
        v-if="activeTrackers"
        :color="activeTrackers >= 2 ? 'red' : 'yellow'"
        class="ml-2"
      >
        <span
          class="font-weight-medium"
          v-translate
        >
          Active trackers
        </span>
        <template v-slot:badge>
          <span
            class="font-weight-medium"
            :class="{ 'info--text' : activeTrackers < 2 }"
          >
            {{ activeTrackers }}
          </span>
        </template>
      </v-badge>
    </div>
    <!-- Hijack actions -->
    <v-btn
      v-if="hijackedUserId"
      class="mr-4"
      color="secondary"
      @click="releaseHijackedUser()"
    >
      <translate>Release</translate>
      <span class="ml-1">
        {{ hijackedUserName }}
      </span>
    </v-btn>
    <!-- Subscribe Button  -->
    <template v-if="showSubscribeButton">
      <v-tooltip
        v-if="isTrialPeriod"
        bottom
        color="primary"
        max-width="400px"
        :close-delay="1500"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="blue"
            class="mr-4"
            @click="openSubscribeLink()"
          >
            <translate
              v-if="beforeTrialPeriod"
              key="beforeTrialPeriod"
            >
              Trial period
            </translate>
            <translate
              v-else
              key="ongoingTrialPeriod"
            >
              Subscribe
            </translate>
            <div
              v-if="ongoingTrialPeriod"
              class="d-sm-inline text-none"
            >
              {{ userSubscriptionTexts.ongoingTrialPeriod }}
            </div>
          </v-btn>
        </template>
        <span v-html="userSubscriptionTexts.trialPeriodFunctionnalities" />
      </v-tooltip>
      <v-btn
        v-else
        color="blue"
        class="mx-2"
        @click="openSubscribeLink()"
      >
        <translate>Subscribe</translate>
      </v-btn>
    </template>
    <!-- SubExploitants Select-->
    <div
      v-if="subExploitantsAvailable"
      class="mr-4 d-flex justify-end"
    >
      <v-autocomplete
        :items="subExploitants"
        v-model="selectedSubExploitant"
        class="sub-exploitants body-2"
        clear-icon="mdi-close mdi-18px"
        background-color="secondary"
        item-text="name"
        item-value="id"
        :placeholder="entityName.plural"
        clearable
        solo
        dense
        hide-details
        :no-data-text="$gettext('No result found')"
      />
    </div>
  </div>
</template>

<script>
import { SUBSCRIBE_LINK } from '@/settings';

import { SET_SELECTED_SUB_EXPLOITANT_NS, RELEASE_HIJACKED_USER_NS } from '@/store/authentication';
import { GET_DRONES_NS } from '@/store/drones';
import { GET_ALL_FLIGHTS_NS } from '@/store/flights';
import { MARK_ALERTS_READ_NS } from '@/store/map';
import { GET_PILOTS_NS } from '@/store/pilots';

import TrackingAlertList from '@/components/Header/TrackingAlertList.vue';

export default {
  name: 'HeaderAddOns',
  components: { TrackingAlertList },
  data() {
    return { menuTrackingAlert: false };
  },
  computed: {
    activeTrackers() {
      return this.$store.getters['map/trackersCount'];
    },
    trackingActivated() {
      return this.$store.getters['authentication/trackingActivated'];
    },
    nbUnreadTrackingAlerts() {
      return this.$store.getters['map/nbUnreadTrackingAlerts'];
    },
    hijackedUserId() {
      return this.$store.state.authentication.hijackedUserId;
    },
    hijackedUserName() {
      return this.$store.state.authentication.hijackedUserName;
    },
    isUserExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    showSubscribeButton() {
      const isUserDronist = this.$store.getters['authentication/isUserDronist'];
      const userNotSubscribed = this.$store.getters['authentication/userNotSubscribed'];
      return isUserDronist && userNotSubscribed && this.isUserExploitantAdmin;
    },
    userSubscription() {
      return this.$store.state.authentication.user.subscription;
    },
    isTrialPeriod() {
      return this.userSubscription && this.userSubscription.trial;
    },
    beforeTrialPeriod() {
      return (
        this.userSubscription
        && this.userSubscription.trial
        && this.userSubscription.trial_end_date === null
      );
    },
    ongoingTrialPeriod() {
      return this.userSubscription?.trial && this.userSubscription?.trial_end_date;
    },
    userSubscriptionTexts() {
      return {
        ongoingTrialPeriod: this.$gettextInterpolate(
          this.$gettext('- End of trial period the %{endTrialDate}'),
          { endTrialDate: this.ongoingTrialPeriod ? this.userSubscription?.trial_end_date : '' },
        ),
        trialPeriodFunctionnalities: this.$gettext(`30 days of trial period of all functionnalities
          (except entrust paperwork and paying approval) after the creation of your first mission
          (see <a style="color: white;" href="https://clearance.aero/tarifs/" target="_blank">our
          article on userSubscription offers</a>).`),
      };
    },
    subExploitantsAvailable() {
      return (
        this.$store.getters['exploitants/hasSubExploitants']
        && this.$store.getters['authentication/hasManySubExploitants']
      );
    },
    subExploitants() {
      // Create a deep copy of the object
      const subExploitants = JSON.parse(
        JSON.stringify(this.$store.state.authentication.userSubExploitants),
      );
      if (this.isUserExploitantAdmin && subExploitants && subExploitants.length) {
        const name = this.$gettextInterpolate(
          this.$gettext('No %{ entity }'),
          { entity: this.entityName.singular.toLowerCase() },
        );
        subExploitants.push({ id: -1, name });
      }
      return subExploitants.sort((a, b) => a.name > b.name);
    },
    selectedSubExploitant: {
      get() {
        return this.$store.state.authentication.selectedSubExploitant;
      },
      set(newValue) {
        this.$store.commit(SET_SELECTED_SUB_EXPLOITANT_NS, newValue);
      },
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  watch: {
    selectedSubExploitant() {
      this.$store.dispatch(GET_ALL_FLIGHTS_NS, { updateBoundingBox: true });
      this.$store.dispatch(GET_PILOTS_NS);
      this.$store.dispatch(GET_DRONES_NS);
    },
    menuTrackingAlert(newValue, oldValue) {
      if (
        oldValue
        && !newValue
        && this.hijackedUserId === null
        && this.nbUnreadTrackingAlerts > 0
      ) {
        this.markAlertAsRead();
      }
    },
  },
  methods: {
    async releaseHijackedUser() {
      await this.$store.dispatch(RELEASE_HIJACKED_USER_NS);
      this.$router.push('/map/users');
      window.location.reload();
    },
    openSubscribeLink() {
      window.open(SUBSCRIBE_LINK, '_parent').location.reload();
    },
    markAlertAsRead() {
      const structureId = this.$store.state.structures.items.at(0).id;
      const ids = this.$store.state.map.trackingAlerts.filter(
        (t) => t.read === false,
      ).map((t) => t.id);
      this.$store.dispatch(MARK_ALERTS_READ_NS, { structureId, ids });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.sub-exploitants ::v-deep input::placeholder {
  color: $color-white;
}

</style>
