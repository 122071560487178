/* eslint-disable class-methods-use-this */

import { NOTIFICATION_TYPES } from '@/settings';

import AuthorityActivationNotification from '@/components/Header/notifications/AuthorityActivationNotification';
import AuthorityApprovalNotification from '@/components/Header/notifications/AuthorityApprovalNotification';
import AuthorityFlightEnRouteNotification from '@/components/Header/notifications/AuthorityFlightEnRouteNotification';
import DronistTakeOffAuthorizationNotification from '@/components/Header/notifications/DronistTakeOffAuthorizationNotification';

/**
 * NotificationFactory create a notification object according to a given type.
 *
 * @class NotificationFactory
 */
class NotificationFactory {
  create(rawNotification) {
    const { payload_type: notificationType } = rawNotification;
    if (
      [
        NOTIFICATION_TYPES.AUTHORITY_NEW_APPROVAL,
        NOTIFICATION_TYPES.AUTHORITY_APPROVAL_AUTOMATICALLY_PROCESSED,
        NOTIFICATION_TYPES.AUTHORITY_APPROVAL_MODIFIED_BY_DRONE_COMPANY,
        NOTIFICATION_TYPES.AUTHORITY_APPROVAL_REVALIDATED,
        NOTIFICATION_TYPES.AUTHORITY_NEW_TAKE_OFF_AUTHORIZATION,
      ].includes(notificationType)
    ) return new AuthorityApprovalNotification(rawNotification);

    if (
      [
        NOTIFICATION_TYPES.AUTHORITY_FLIGHT_EN_ROUTE,
        NOTIFICATION_TYPES.AUTHORITY_FLIGHT_LANDED,
      ].includes(notificationType)
    ) return new AuthorityFlightEnRouteNotification(rawNotification);

    if (
      [
        NOTIFICATION_TYPES.AUTHORITY_NEW_ACTIVATION,
        NOTIFICATION_TYPES.AUTHORITY_ACTIVATION_MODIFIED_BY_DRONE_COMPANY,
      ].includes(notificationType)
    ) return new AuthorityActivationNotification(rawNotification);

    if (notificationType === NOTIFICATION_TYPES.DRONIST_TAKE_OFF_AUTHORIZATION_DECISION) {
      return new DronistTakeOffAuthorizationNotification(rawNotification);
    }

    return null;
  }
}

export default NotificationFactory;
