<template>
  <div>
    <!-- All RTE restricted zones -->
    <v-container class="px-1 px-md-3 flex-grow-0">
      <div class="headline mb-4">
        <translate>Impact of restricted zones</translate>
      </div>
      <div>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$gettext('Search')"
          single-line
          hide-details
        />
        <v-data-table
          ref="datatable"
          item-key="id"
          :headers="headers"
          :items="structures"
          :loading="loadingStructures"
          :search="search"
          class="mt-4"
          :class="{transparent: false}"
          :loading-text="labels.loadingText"
          :no-data-text="labels.noResultText"
          :no-results-text="labels.noResultText"
          :mobile-breakpoint="singleColumnBreakpoint"
          :footer-props="{
              'items-per-page-text': $gettext('Lines per page:'),
              'items-per-page-all-text': $gettext('All'),
            }"
          light
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          />
          <template v-slot:[`item.constraint_name`]="{ item }">
            <span>
              {{ item.constraint_name }}
            </span>
          </template>
          <template v-slot:[`item.code_type`]="{ item }">
            <span>
              {{ item.code_type }}
            </span>
          </template>
          <template v-slot:[`item.public_tips`]="{ item }">
            <span>
              {{ item.public_tips }}
            </span>
          </template>
          <template v-slot:[`item.impacting_color`]="{ item }">
            <span class="d-flex align-center justify-space-between">
              <v-chip
                small
                label
                :color="item.impacting_color === 'red' ? 'red' : 'orange'"
                text-color="white"
                class="px-2 pt-1"
                :key="item.impacting_color === 'red' ? 'colorRed' : 'colorOrange'"
              >
                <div v-if="item.impacting_color === 'red'">
                  <v-icon
                    small
                    class="pb-1 mr-2"
                  >
                    mdi-do-not-disturb
                  </v-icon>
                  <translate class="pt-1">Blocking</translate>
                </div>
                <div v-else>
                  <v-icon
                    small
                    class="pb-1 mr-2"
                  >
                    mdi-alert
                  </v-icon>
                  <translate>Action needed</translate>
                </div>
              </v-chip>
              <v-btn
                icon
                @click="openDialogUpdateImpactingColor(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </span>
          </template>
          <template v-slot:[`footer.page-text`]="items">
            <translate :translate-params="{
                pageStart: items.pageStart,
                pageStop: items.pageStop,
                itemsLength: items.itemsLength
              }">
              %{ pageStart } - %{ pageStop } from %{ itemsLength }
            </translate>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <v-divider />
    <!-- Dialog update structure impact -->
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      max-width="700px"
      :retain-focus="false"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Change impact</translate>
        </v-card-title>
        <v-card-text>
          <span>
            <translate :translate-params="{ structureName: structure.name }">
              Are you sure to change the impact of %{ structureName } from
            </translate>
            <v-chip
              small
              label
              :color="structure.impacting_color === 'red' ? 'red' : 'orange'"
              text-color="white"
              class="px-1 mx-1"
              :key="structure.impacting_color === 'red' ? 'colorRed' : 'colorOrange'"
            >
              <div v-if="structure.impacting_color === 'red'">
                <v-icon
                  small
                  class="pb-1"
                >
                  mdi-do-not-disturb
                </v-icon>
                <translate class="pt-1 mr-2">Blocking</translate>
              </div>
              <div v-else>
                <v-icon
                  small
                  class="pb-1 mr-2"
                >
                  mdi-alert
                </v-icon>
                <translate>Action needed</translate>
              </div>
            </v-chip>
            <translate class="px-1">into</translate>
            <v-chip
              small
              label
              :color="structure.impacting_color === 'red' ? 'orange' : 'red'"
              text-color="white"
              class="px-1 mx-1"
              :key="structure.impacting_color === 'red' ? 'colorOrange' : 'colorRed'"
            >
              <div v-if="structure.impacting_color === 'red'">
                <v-icon
                  small
                  class="pb-1 mr-2"
                >
                  mdi-alert
                </v-icon>
                <translate>Action needed</translate>
              </div>
              <div v-else>
                <v-icon
                  small
                  class="pb-1 mr-2"
                >
                  mdi-do-not-disturb
                </v-icon>
                <translate class="pt-1">Blocking</translate>
              </div>
            </v-chip>
            ?
          </span>
          <p class="mt-6 mb-0">
            <v-icon class="mr-2 pb-1">
              mdi-information-outline
            </v-icon>
            <translate>
              Please note that the update of the color on the map will take 7 days.
            </translate>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="updateStructureImpact()"
            :loading="loadingStructureUpdate"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

export default {
  name: 'StructureImpacting',
  data() {
    return {
      loadingStructures: false,
      structures: [],
      search: '',
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
      structure: {
        id: null,
        constraint_name: null,
      },
      dialog: false,
      loadingStructureUpdate: false,
    };
  },
  computed: {
    labels() {
      return {
        loadingText: this.$gettext('Zones loading...'),
        noResultText: this.$gettext('No zone found'),
      };
    },
    headers() {
      return [
        {
          text: this.$gettext('Structure Name'),
          value: 'constraint_name',
          width: '30%',
        },
        {
          text: this.$gettext('Type'),
          value: 'code_type',
          width: '7%',
        },
        {
          text: this.$gettext('Public tips'),
          value: 'public_tips',
          width: '45%',
        },
        {
          text: this.$gettext('Impact'),
          value: 'impacting_color',
          width: '18%',
        },
      ];
    },
  },
  async created() {
    this.getStructuresImpacting();
  },
  methods: {
    async getStructuresImpacting() {
      this.loadingStructures = true;
      await APIService.getStructuresImpacting()
        .then(({ data }) => {
          this.structures = data;
        })
        .finally(() => {
          this.loadingStructures = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.structure = {
        id: null,
        name: null,
      };
    },
    openDialogUpdateImpactingColor(item) {
      this.structure.id = item.id;
      this.structure.name = item.constraint_name;
      this.dialog = true;
    },
    async updateStructureImpact() {
      this.loadingStructureUpdate = true;
      await APIService.updateStructureImpact(this.structure.id)
        .then(({ data }) => {
          const index = this.structures.findIndex((s) => s.id === data.id);
          this.structures.splice(index, 1, data);
        })
        .finally(() => {
          this.loadingStructureUpdate = false;
          this.closeDialog();
        });
    },
  },
};
</script>
