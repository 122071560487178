import Vue from 'vue';
import * as html2canvas from 'html2canvas';

/* eslint-disable import/no-cycle */
import { RESET_PILOTS_NS } from '@/store/pilots';
import { RESET_DRONES_NS } from '@/store/drones';
import { RESET_MAP_NS } from '@/store/map';
import { RESET_FLIGHTS_NS } from '@/store/flights';
import { RESET_EXPLOITANTS_NS } from '@/store/exploitants';
import { RESET_COTATIONS_NS } from '@/store/cotations';
import { RESET_APPROVALS_NS } from '@/store/approvals';
import { RESET_ACTIVATIONS_NS } from '@/store/activations';

const namespace = 'application';
const SET_CURRENT_BREAKPOINT = 'SET_CURRENT_BREAKPOINT';
const SET_STATUS = 'SET_STATUS';
const SET_DISPLAY = 'SET_DISPLAY';
const SET_ACTIVE_SPLIT = 'SET_SPLIT';
const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE';
const SET_AVAILABLE_UPDATE = 'SET_AVAILABLE_UPDATE';
const RESET_DATA_STORES = 'RESET_DATA_STORES';
const RESET_APPLICATION = 'RESET_APPLICATION';
const SET_LOADED_KML = 'SET_LOADED_KML';
const FORCE_SETTINGS_MENU_OPEN = 'FORCE_SETTINGS_MENU_OPEN';
const SET_LOADING_SNACKBAR = 'SET_LOADING_SNACKBAR';

export const SET_CURRENT_BREAKPOINT_NS = `${namespace}/${SET_CURRENT_BREAKPOINT}`;
export const SET_STATUS_NS = `${namespace}/${SET_STATUS}`;
export const SET_DISPLAY_NS = `${namespace}/${SET_DISPLAY}`;
export const SET_ACTIVE_SPLIT_NS = `${namespace}/${SET_ACTIVE_SPLIT}`;
export const SET_ACTIVE_TAB_NS = `${namespace}/${SET_ACTIVE_TAB}`;
export const SHOW_SNACKBAR_NS = `${namespace}/${SHOW_SNACKBAR}`;
export const HIDE_SNACKBAR_NS = `${namespace}/${HIDE_SNACKBAR}`;
export const SET_SNACKBAR_MESSAGE_NS = `${namespace}/${SET_SNACKBAR_MESSAGE}`;
export const SET_AVAILABLE_UPDATE_NS = `${namespace}/${SET_AVAILABLE_UPDATE}`;
export const RESET_DATA_STORES_NS = `${namespace}/${RESET_DATA_STORES}`;
export const RESET_APPLICATION_NS = `${namespace}/${RESET_APPLICATION}`;
export const SET_LOADED_KML_NS = `${namespace}/${SET_LOADED_KML}`;
export const FORCE_SETTINGS_MENU_OPEN_NS = `${namespace}/${FORCE_SETTINGS_MENU_OPEN}`;
export const SET_LOADING_SNACKBAR_NS = `${namespace}/${SET_LOADING_SNACKBAR}`;

export const APPLICATION_STATUS = {
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update',
};

export const APPLICATION_DISPLAY = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  BIG: 'BIG',
};

export const APPLICATION_SPLIT = {
  LISTING: 'listing',
  MAP: 'map',
};

export const APPLICATION_TAB = {
  MISSION: 'mission',
  MISSION_EXCLUSION_ZONE: 'mission-exlusion-zone',
  PILOT: 'pilot',
  DRONE: 'drone',
  APPROVAL: 'approval',
  ACTIVATION: 'activation',
  LAYER: 'layer',
  TRACE: 'trace',
};

export function getPageFromNextPreviousURL(nextURL, previousURL, count, rowsPerPage) {
  const NONE = 'None';
  // if previousURL is None or undefined, we are at the first page
  if (previousURL === NONE || previousURL === undefined) return 1;

  // if nextURL is None, we are at the last page
  if (nextURL === NONE) return Math.ceil(count / rowsPerPage);

  // else, we need to calc the page based on the previous url + 1
  const offsetIndex = previousURL.indexOf('offset=');
  // previous url doesn't have offset, so it's first page, so we are in second
  if (offsetIndex === -1) return 2;

  const offsetSubstring = previousURL.substring(previousURL.indexOf('offset=') + 'offset='.length);
  const offset = offsetSubstring.substring(0, offsetSubstring.indexOf('&'));
  const previousPage = Math.trunc(offset / rowsPerPage) + 1;
  return previousPage + 1;
}

export function hideHubspotChatBot() {
  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
    window.HubSpotConversations.widget.remove();
  } else {
    window.hsConversationsOnReady = [
      () => { window.hsConversationsSettings = { loadImmediately: false }; },
    ];
  }
}

export function restoreHubspotChatBot() {
  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
    window.HubSpotConversations.widget.load();
  }
}

export function takeApplicationScreenshot({ mapOnly } = { mapOnly: false }) {
  // Hide mapbox constrols except the scale
  const controls = document.getElementsByClassName('mapboxgl-control-container')[0];
  const { children } = controls;
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    if (child.className !== 'mapboxgl-ctrl-bottom-left') {
      child.style.visibility = 'hidden';
    }
  }
  const logo = document.getElementsByClassName('mapboxgl-ctrl-logo')[0];
  logo.style.visibility = 'hidden';

  const application = document.getElementById('splits-container');
  const map = document.getElementById('map');

  // Make the screenshot
  let screenshotTarget;
  if (mapOnly) {
    screenshotTarget = map;
  } else {
    screenshotTarget = application;
  }
  const screenshotPromise = html2canvas(screenshotTarget, { scale: 2 });

  // Show mapbox controls
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    child.style.visibility = 'visible';
  }
  logo.style.visibility = 'visible';

  return screenshotPromise;
}

export function takeMapScreenshot() {
  return takeApplicationScreenshot({ mapOnly: true });
}

function initialState() {
  return {
    // current vuetify breakpoint (xs, sm, md, lg, xl)
    currentBreakpoint: undefined,
    status: APPLICATION_STATUS.READ,
    display: APPLICATION_DISPLAY.MEDIUM,
    activeSplit: APPLICATION_SPLIT.LISTING,
    activeTab: APPLICATION_TAB.MISSION,
    update_available: false,
    forceSettingsMenuOpen: false,
    snackbar: {
      timeout: -1,
      message: null,
      display: false,
      color: 'info',
      long: false,
      readHtml: false,
    },
    loadingSnackbar: false,
    loadedKml: null,
  };
}

/**
 * This substate store the different data about the application
 *
 * * status is about CRUD operations
 * Useful to know if we are creating a new flight/approval/other
 *
 * * display is about the display's size
 *
 * * map status indicates wheter we can add a new area to the map
 *
 * When we are creating a new pilot or drone,
 * the application status is CREATE
 * but the map status is still READ.
 */
export default {
  namespaced: true,
  state: initialState,
  getters: {
    isMobileBreakpoint: (state) => ['xs', 'sm'].includes(state.currentBreakpoint),
    activeTab: (state) => state.activeTab,
    currentLanguage: () => Vue.config.language.replace('_', '-'),
  },
  mutations: {
    [RESET_APPLICATION](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [SET_CURRENT_BREAKPOINT](state, breakpoint) {
      Vue.set(state, 'currentBreakpoint', breakpoint);
    },
    [SET_STATUS](state, newStatus) {
      Vue.set(state, 'status', newStatus);
    },
    [SET_DISPLAY](state, newDisplay) {
      Vue.set(state, 'display', newDisplay);
    },
    [SET_ACTIVE_SPLIT](state, newSplit) {
      Vue.set(state, 'activeSplit', newSplit);
    },
    [SHOW_SNACKBAR](state) {
      Vue.set(state.snackbar, 'display', true);
    },
    [HIDE_SNACKBAR](state) {
      Vue.set(state.snackbar, 'display', false);
    },
    [SET_SNACKBAR_MESSAGE](state, { message, color, long, readHtml }) {
      Vue.set(state.snackbar, 'message', message);
      Vue.set(state.snackbar, 'color', color);
      Vue.set(state.snackbar, 'long', long);
      Vue.set(state.snackbar, 'readHtml', readHtml);
    },
    [SET_AVAILABLE_UPDATE](state, available) {
      Vue.set(state, 'update_available', available);
    },
    [SET_LOADED_KML](state, geoJson) {
      Vue.set(state, 'loadedKml', geoJson);
    },
    [SET_ACTIVE_TAB](state, newTab) {
      Vue.set(state, 'activeTab', newTab);
    },
    [FORCE_SETTINGS_MENU_OPEN](state, isOpen) {
      Vue.set(state, 'forceSettingsMenuOpen', isOpen);
    },
    [SET_LOADING_SNACKBAR](state, value) {
      Vue.set(state, 'loadingSnackbar', value);
    },
  },
  actions: {
    [RESET_DATA_STORES]({ commit }) {
      commit(RESET_APPLICATION);
      commit(RESET_MAP_NS, null, { root: true });
      commit(RESET_EXPLOITANTS_NS, null, { root: true });
      commit(RESET_FLIGHTS_NS, null, { root: true });
      commit(RESET_PILOTS_NS, null, { root: true });
      commit(RESET_DRONES_NS, null, { root: true });
      commit(RESET_COTATIONS_NS, null, { root: true });
      commit(RESET_APPROVALS_NS, null, { root: true });
      commit(RESET_ACTIVATIONS_NS, null, { root: true });
    },
    [RESET_APPLICATION]({ commit }) {
      commit(RESET_APPLICATION);
    },
    [SET_STATUS]({ commit }, status) {
      commit(SET_STATUS, status);
    },
    [SET_DISPLAY]({ commit }, status) {
      commit(SET_DISPLAY, status);
    },
    [SET_ACTIVE_SPLIT]({ commit }, split) {
      commit(SET_ACTIVE_SPLIT, split);
    },
    [SHOW_SNACKBAR]({ commit }) {
      commit(SHOW_SNACKBAR);
    },
    [HIDE_SNACKBAR]({ commit }) {
      commit(HIDE_SNACKBAR);
    },
    [SET_SNACKBAR_MESSAGE](
      { commit },
      { message, color = 'info', long = false, readHtml = false },
    ) {
      commit(SET_SNACKBAR_MESSAGE, { message, color, long, readHtml });
    },
    [SET_AVAILABLE_UPDATE]({ commit }, available) {
      commit(SET_AVAILABLE_UPDATE, available);
    },
    [SET_LOADED_KML]({ commit }, geoJson) {
      commit(SET_LOADED_KML, geoJson);
    },
    [SET_ACTIVE_TAB]({ commit }, tab) {
      commit(SET_ACTIVE_TAB, tab);
    },
    [FORCE_SETTINGS_MENU_OPEN]({ commit }, isOpen) {
      commit(FORCE_SETTINGS_MENU_OPEN, isOpen);
    },
  },
};
