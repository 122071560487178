<template>
  <div class="d-flex align-center">
    <v-badge
      v-if="subApproval.is_above_psa"
      color="red"
      left
      inline
      content="!"
      class="small-badge"
    />
    <translate
      v-if="numberOfPSABreached(subApproval)"
      :translate-n="numberOfPSABreached(subApproval)"
      :translate-params="{ numberOfPSABreached: numberOfPSABreached(subApproval) }"
      translate-plural="%{ numberOfPSABreached } PSAs breached"
      key="nbPSABreached"
    >
      %{ numberOfPSABreached } PSA breached
    </translate>
    <translate
      v-else
      key="noPSABreached"
    >
      No PSA breached
    </translate>
    <v-tooltip
      bottom
      content-class="psa-comparison"
    >
      <template v-slot:activator="{ on }">
        <v-icon
          light
          v-on="on"
          class="ml-2 cursor-pointer"
        >
          mdi-information-outline
        </v-icon>
      </template>
      <template v-if="hasPSAComparisons(subApproval)">
        <div
          v-for="(psaComparison, index) in subApproval.psa_comparisons"
          :key="index"
          class="py-2"
        >
          <!-- PSA breached -->
          <template v-if="psaComparison.alt_diff < 0">
            <div class="details-card__label color-error">
              {{ psaComparison.name }} ({{ psaComparison.code }})
            </div>
            <div class="error--text">
              <translate :translate-params="{ height: -psaComparison.alt_diff }">
                The flight area is planned to be %{ height } m above the PSA.
              </translate>
            </div>
          </template>
          <!-- PSA below -->
          <div
            v-else
            class="black--text"
          >
            <div class="details-card__label">
              {{ psaComparison.name }} ({{ psaComparison.code }})
            </div>
            <div>
              <translate :translate-params="{ height: psaComparison.alt_diff }">
                The flight is planned to be %{ height } m below the PSA.
              </translate>
            </div>
          </div>
        </div>
      </template>
      <span
        v-else
        v-translate
        class="black--text"
      >
        No intersection with PSA.
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'PSAComparison',
  props: { subApproval: Object },
  methods: {
    hasPSAComparisons(subApproval) {
      return subApproval.psa_comparisons?.length;
    },
    numberOfPSABreached(subApproval) {
      return subApproval.psa_comparisons?.filter((p) => p.alt_diff < 0).length || 0;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.psa-comparison ::v-deep {
  background-color: $color-white;
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, .42);
}

</style>
