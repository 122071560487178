<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row
        no-gutters
        justify="space-around"
        class="flex-wrap mt-5"
      >
        <v-col
          cols="12"
          sm="10"
          md="8"
        >
          <v-card>
            <v-card-title
              primary-title
              class="text-center"
            >
              <h3 class="headline mb-0 ">
                <translate>Change password</translate>
              </h3>
            </v-card-title>
            <v-card-text>
              <v-container
                fluid
                pb-1
              >
                <v-row
                  no-gutters
                  align="center"
                >
                  <v-col cols="4">
                    <v-subheader v-translate>Password</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                      :rules="rules.minLength"
                      :type="showPassword ? 'text' : 'password'"
                      name="input-10-1"
                      :label="$gettext('Password')"
                      :hint="$gettext('At least 8 characters')"
                      @click:append="showPassword=!showPassword"
                    />
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  justify="center"
                  class="mt-5"
                >
                  <v-btn
                    large
                    color="white"
                    to="/"
                    class="mx-4"
                  >
                    <translate>Back</translate>
                  </v-btn>
                  <v-btn
                    large
                    color="primary"
                    :loading="loading"
                    :disabled="loading"
                    class="mx-4"
                    @click="createNewPassword()"
                  >
                    <translate>Change password</translate>
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import API from '@/services/api';

import { defaultRoutes } from '@/settings';

import { LOGIN_NS } from '@/store/authentication';

export default {
  name: 'newPassword',
  data() {
    return {
      valid: true,
      loading: false,
      password: '',
      token: '',
      email: '',
      username: '',
      showPassword: false,
      rules: { minLength: [(v) => v.length >= 8 || this.$gettext('At least 8 characters.')] },
    };
  },
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
  },
  created() {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
    this.username = this.$route.query.username;
  },
  methods: {
    createNewPassword() {
      if (this.$refs.form.validate()) {
        const payload = {
          token: this.token,
          password: this.password,
          email: this.email,
        };
        this.loading = true;
        API.resetPasswordConfirm(payload)
          .then(async () => {
            this.showMessage(this.$gettext('Password updated.'), 'success');
            await this.$store.dispatch(LOGIN_NS, {
              username: this.username,
              password: this.password,
            });
            const userType = this.user && this.user.user_type;
            const interfaceVersion = this.user.authority_interface_version;
            this.$router.addUserRoutes(userType, interfaceVersion);
            this.$router.push(defaultRoutes[userType]);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
