<template>
  <div>
    <div class="d-flex">
      <!-- Search field -->
      <div class="trace-filters flex-grow-1 d-flex justify-end">
        <v-text-field
          v-if="mediumDisplay || bigDisplay"
          v-model="q"
          :append-icon="q ? null : 'search'"
          class="searchbox"
          :placeholder="$gettext('Search')"
          dark
          solo
          flat
          clearable
          hide-details
          @keyup.enter="updateResult()"
          @click:append="updateResult()"
        />
      </div>
      <!-- Filters button -->
      <div
        class="trace-filters"
        v-if="mediumDisplay"
      >
        <v-btn
          text
          small
          dark
          pa-0
          @click="toggleFilter()"
        >
          <span
            class="text-decoration-underline"
            v-translate
          >
            Filters
          </span>
          <span
            v-if="activeFilters"
            class="ml-1"
          >
            ({{ activeFilters }})
          </span>
        </v-btn>
      </div>
    </div>
    <!-- Filters big display -->
    <div
      class="d-flex"
      v-if="bigDisplay"
    >
      <!-- Date from -->
      <div class="trace-filters flex-grow-1">
        <v-menu
          ref="menuFrom"
          :close-on-content-click="true"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          content-class="white text-center"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFrom"
              :label="$gettext('Start')"
              append-icon="arrow_drop_down"
              dark
              readonly
              v-on="on"
              hide-details
              class="small-dense__input"
            />
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
            locale="fr"
            first-day-of-week="1"
            @input="$refs.menuFrom.save(dateFrom)"
          />
        </v-menu>
      </div>
      <!-- Date to -->
      <div class="trace-filters flex-grow-1">
        <v-menu
          ref="menuTo"
          :close-on-content-click="true"
          :return-value.sync="dateTo"
          transition="scale-transition"
          content-class="white text-center"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateTo"
              :label="$gettext('End')"
              append-icon="arrow_drop_down"
              dark
              readonly
              v-on="on"
              hide-details
              class="small-dense__input"
            />
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
            locale="fr"
            first-day-of-week="1"
            @input="$refs.menuTo.save(dateTo)"
          />
        </v-menu>
      </div>
      <!-- Cancel filters -->
      <div class="trace-filters flex-grow-1 d-flex justify-end">
        <v-btn
          text
          small
          dark
          @click="resetFilters()"
        >
          <span
            class="text-decoration-underline"
            v-translate
          >
            Cancel filters
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/services/api.helper';

import { display } from '@/settings';

import { GET_TRACES_NS, RESET_FILTERS_NS, SET_FILTERS_NS } from '@/store/traces';

export default {
  name: 'TrackerTraceFilters',
  props: { display: String },
  computed: {
    bigDisplay() {
      return this.display === display.BIG;
    },
    mediumDisplay() {
      return this.display === display.MEDIUM;
    },
    activeFilters() {
      return this.$store.getters['traces/activeFilters'];
    },
    dateFrom: {
      get() {
        return this.$store.state.traces.filters.dateFrom;
      },
      set(newValue) {
        this.$store.commit(SET_FILTERS_NS, { dateFrom: newValue });
      },
    },
    dateTo: {
      get() {
        return this.$store.state.traces.filters.dateTo;
      },
      set(newValue) {
        this.$store.commit(SET_FILTERS_NS, { dateTo: newValue });
      },
    },
    q: {
      get() {
        return this.$store.state.traces.filters.q;
      },
      set(newValue) {
        this.$store.commit(SET_FILTERS_NS, { q: newValue || null });
      },
    },
  },
  watch: {
    dateFrom() {
      this.debouncedUpdateResult({ that: this });
    },
    dateTo() {
      this.debouncedUpdateResult({ that: this });
    },
    q() {
      this.debouncedUpdateResult({ that: this });
    },
  },
  methods: {
    debouncedUpdateResult: debounce(
      ({ that }) => that.updateResult(),
      1000, // debounce for 1s
    ),
    async updateResult() {
      await this.$store.dispatch(GET_TRACES_NS, {});
    },
    resetFilters() {
      this.$store.commit(RESET_FILTERS_NS);
    },
    toggleFilter() {
      this.$emit('toggle-filter');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.trace-filters {
  margin: 14px 8px 4px 0px;
}

</style>
