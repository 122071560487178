<template>
  <!-- ----- ACCOUNT CREDENTIALS ------- -->
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <div class="d-flex justify-space-between align-baseline flex-wrap-reverse">
      <div
        class="headline black--text"
        v-translate
      >
        Create a free account
      </div>
      <span
        class="link flex-grow-1 text-right"
        @click="$emit('login')"
        v-translate
      >
        I already have an account
      </span>
    </div>
    <div class="py-sm-5">
      <v-text-field
        v-model="username"
        :label="labels.username"
        :rules="rules.name"
        :error="backendErrors.username.error"
        :error-messages="backendErrors.username.message"
      />
      <v-text-field
        v-model="password"
        :append-icon="showPassword ? 'visibility_off' : 'visibility'"
        :rules="rules.minLength"
        :type="showPassword ? 'text' : 'password'"
        name="input-10-1"
        :label="labels.password"
        :hint="hints.password"
        @click:append="showPassword = !showPassword"
      />
      <v-text-field
        v-model="email"
        :label="labels.email"
        :rules="rules.email"
        :error="backendErrors.email.error"
        :error-messages="backendErrors.email.message"
      />
      <v-text-field
        v-model="commercialName"
        :label="labels.commercialName"
        :rules="rules.required"
      />
    </div>
    <!-- -------- PILOT INFO -------- -->
    <div class="d-flex justify-space-between align-baseline flex-wrap-reverse">
      <div
        class="headline black--text"
        v-translate
      >
        Main pilot
      </div>
      <span
        class="info--text font-italic flex-grow-1 text-right"
        v-translate
      >
        You must declare a first pilot
      </span>
    </div>
    <div class="py-sm-5">
      <v-text-field
        v-model="lastName"
        :label="labels.lastName"
        :rules="rules.required"
      />
      <v-text-field
        v-model="firstName"
        :label="labels.firstName"
        :rules="rules.required"
      />
      <v-select
        v-model="gender"
        :label="labels.gender"
        :items="genders"
      />
      <v-text-field
        v-model="phoneNumber"
        :label="labels.phoneNumber"
        :rules="rules.phoneNumber"
      />
      <v-checkbox
        class="pl-2 mt-1"
        v-model="agreementTermOfUse"
        color="primary"
        :rules="rules.terms"
      >
        <template v-slot:label>
          <div>
            <translate>I agree the</translate>
            <a
              target="_blank"
              @click.stop
              href="https://clearance.aero/cgu-cgv/"
              v-translate
              class="ml-1"
            >
              Terms and Conditions of Use
            </a>
          </div>
        </template>
      </v-checkbox>
      <v-checkbox
        class="pl-2 my-0"
        v-model="subscribedToNewsletter"
        color="primary"
      >
        <template v-slot:label>
          <span>
            {{ texts.newsletter }}
          </span>
        </template>
      </v-checkbox>
    </div>
    <v-btn
      large
      color="primary"
      @click="createAccount()"
      :loading="loading"
      :disabled="loading || !valid"
      class="full-width"
    >
      <translate>CREATE ACCOUNT</translate>
    </v-btn>
  </v-form>
</template>

<script>
import CUSTOM_HANDLING_API_CODES from '@/main';

import API from '@/services/api';

import { defaultRoutes } from '@/settings';

import { LOGIN_NS } from '@/store/authentication';

export default {
  name: 'Signup',
  data() {
    return {
      valid: true,
      loading: false,
      username: '',
      password: '',
      email: '',
      commercialName: '',
      showPassword: false,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      genders: ['H', 'F'],
      gender: 'H',
      agreementTermOfUse: false,
      subscribedToNewsletter: false,
      firstSignup: true,
      rules: {
        required: [(value) => !!value || this.$gettext('This field is required.')],
        minLength: [(v) => v.length >= 8 || this.$gettext('At least 8 characters.')],
        name: [
          (v) => !!v || this.$gettext('Please enter a username.'),
          (v) => (
            !/[ °!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(v)
            || this.$gettext(`Username must consist of regular characters and should not contain
              whitespace.`)
          ),
        ],
        password: [
          (v) => !!v || this.$gettext('Please enter a password.'),
        ],
        phoneNumber: [
          (v) => !!v || this.$gettext('Phone number is required.'),
          (value) => {
            const pattern = /^((\+|00)33\s?|0)[1-9](\s?\d{2}){4}$/; // https://manual.limesurvey.org/Using_regular_expressions/fr#French_phone_number
            const patternNC = /^(\s?\d{2}){3}$/;
            // eslint-disable-next-line
            const patternInternational = /^((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/;
            return (
              pattern.test(value)
              || patternNC.test(value)
              || patternInternational.test(value)
              || this.$gettext('Phone number invalid.')
            );
          },
        ],
        email: [
          (v) => !!v || this.$gettext('An email is required.'),
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$gettext('Email invalid.');
          },
        ],
        terms: [
          (v) => v || this.$gettext('You have to accept the terms of use to create an account'),
        ],
      },
      backendErrors: {
        username: {
          error: false,
          message: '',
        },
        email: {
          error: false,
          message: '',
        },
      },
    };
  },
  computed: {
    labels() {
      return {
        username: this.$gettext('Username'),
        password: this.$gettext('Password'),
        email: this.$gettext('Email'),
        commercialName: this.$gettext('Company name'),
        lastName: this.$gettext('Last name'),
        firstName: this.$gettext('First name'),
        gender: this.$gettext('Gender'),
        phoneNumber: this.$gettext('Phone number'),
      };
    },
    hints() {
      return { password: this.$gettext('At least 8 characters.') };
    },
    texts() {
      return {
        newsletter: this.$gettext(`I agree to receive Clearance newsletter (once a month, we
          promise 😉)`),
      };
    },
  },
  watch: {
    username() {
      this.backendErrors.username.error = false;
      this.backendErrors.username.message = '';
    },
    email() {
      this.backendErrors.email.error = false;
      this.backendErrors.email.message = '';
    },
  },
  methods: {
    buildAccount() {
      return {
        username: this.username,
        password: this.password,
        email: this.email,
        commercial_name: this.commercialName,
        last_name: this.lastName,
        first_name: this.firstName,
        gender: this.gender,
        phone_number: this.phoneNumber,
        subscribed_to_newsletter: this.subscribedToNewsletter,
      };
    },
    async createAccount() {
      const formValid = this.$refs.form.validate();
      if (formValid) {
        const payload = this.buildAccount();
        this.loading = true;
        await API.createAccount(payload)
          .then(({ data }) => {
            this.showMessage(
              this.$gettextInterpolate(
                this.$gettext('User %{username} created.'),
                { username: data.username },
              ),
              'success',
            );
            this.$store.dispatch(LOGIN_NS, {
              username: this.username,
              password: this.password,
            })
              .then(() => {
                const { user } = this.$store.state.authentication;
                const userType = user && user.user_type;
                const interfaceVersion = user.authority_interface_version;
                this.$router.addUserRoutes(userType, interfaceVersion);
                this.$router.push(
                  { path: defaultRoutes[userType], query: { firstSignup: 'true' } },
                );
              });
          })
          .catch((error) => {
            // If backend code error for email or username that already exist
            if (error.response.data.code === CUSTOM_HANDLING_API_CODES.USERNAME_ALREADY_EXISTS) {
              this.backendErrors.username.error = true;
              this.backendErrors.username.message = error.response.data.detail;
            }
            if (error.response.data.code === CUSTOM_HANDLING_API_CODES.EMAIL_ALREADY_EXISTS) {
              this.backendErrors.email.error = true;
              this.backendErrors.email.message = error.response.data.detail;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.full-width {
  width: 100%
}

.link {
  color: $color-primary;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}

</style>
