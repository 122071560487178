<template>
  <div class="d-flex align-center">
    <FlightStatusIcon
      :status="approval.status"
      :isClosed="approval.is_closed"
      theme="approvals"
      withTooltip
      tooltipBottom
    />
    <div class="pl-2">
      <template v-if="approval.authority_type === 'prefecture'">
        <span v-translate>Exchanges with</span>
        <span>
          {{ approval.authority_name }}
        </span>
      </template>
      <template v-else>
        <span v-translate>Approval</span>
        <span
          v-if="approval.is_closed"
          class="pl-1"
          v-translate
        >
          archived
        </span>
        <span v-else-if="approval.display_status">
          {{ approval.display_status.toLowerCase() }}
        </span>
        <span v-if="approval.display_identifier">
          {{ approval.display_identifier }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import FlightStatusIcon from '@/components/StatusIcon/FlightStatusIcon.vue';

export default {
  name: 'ApprovalItem',
  components: { FlightStatusIcon },
  props: { approval: Object },
};
</script>
