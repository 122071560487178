<template>
  <div v-if="displayTakeOffAuthorizationAlert">
    <!-- Alert take off authorization -->
    <v-row
      no-gutters
      class="details-card"
    >
      <v-col cols="12">
        <v-alert
          color="warning"
          class="mb-0"
          text
        >
          <template slot="prepend">
            <v-icon
              color="warning"
              class="pr-4"
            >
              mdi-cellphone-basic
            </v-icon>
          </template>
          <v-row
            no-gutters
            align="center"
          >
            <v-col
              cols="12"
              sm="8"
            >
              <span v-translate>
                A pilot is ready to take off and asks for your authorization.
              </span>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="d-flex justify-sm-end"
            >
              <v-btn
                @click="dialogUpdateStatus = true"
                color="warning"
                outlined
              >
                <span v-translate>ANSWER</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog
      v-if="dialogUpdateStatus"
      v-model="dialogUpdateStatus"
      persistent
      width="650"
      height="800"
      :retain-focus="false"
    >
      <ChangeTakeOffAuthorizationStatusForm
        :takeOffAuthorization="takeOffAuthorization"
        :loadingTakeOffAuthorizationDetails="loadingTakeOffAuthorizationDetails"
        @close="() => dialogUpdateStatus = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { SUBMITTED } from '@/store/status';

import ChangeTakeOffAuthorizationStatusForm from '@/components/TakeOffAuthorizations/ChangeTakeOffAuthorizationStatusForm.vue';

export default {
  name: 'TakeOffAuthorizationAlertSubmitted',
  components: { ChangeTakeOffAuthorizationStatusForm },
  props: {
    takeOffAuthorization: Object,
    loadingTakeOffAuthorizationDetails: Boolean,
  },
  data() {
    return { dialogUpdateStatus: false };
  },
  computed: {
    displayTakeOffAuthorizationAlert() {
      return this.takeOffAuthorization.status === SUBMITTED;
    },
  },
};
</script>
