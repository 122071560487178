<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Reactivate my subscription</translate>
    </v-card-title>
    <v-card-text>
      <v-list
        three-line
        disabled
        class="pb-0"
      >
        <!-- Current formule -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center flex-wrap mb-2">
              <translate class="formule-title">
                Current formule:
              </translate>
              <v-icon
                color="primary"
                class="ml-2 mr-1 mdi-rotate-90"
              >
                {{ currentFormule.icon }}
              </v-icon>
              <span>
                {{ currentFormule.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle class="d-flex align-center flex-wrap">
              <span v-html="currentFormule.description" />
              &mdash;
              <span v-html="currentFormule.price" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        class="px-3"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="px-3"
        @click="reactivateSubscription()"
        :loading="reactivateLoading"
      >
        <translate>Confirm the reactivation</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from '@/services/api';

import { REFRESH_USER_DATA_NS } from '@/store/authentication';

export default {
  name: 'SubscriptionPlanCancel',
  props: {
    currentFormule: {
      type: Object,
      required: true,
    },
  },
  data() { return { reactivateLoading: false }; },
  methods: {
    reactivateSubscription() {
      this.reactivateLoading = true;
      API.reactivateSubscription()
        .then(() => {
          this.showMessage(this.$gettext('Subscription recativated successfully.'), 'success');
          this.$store.dispatch(REFRESH_USER_DATA_NS);
        })
        .finally(() => {
          this.reactivateLoading = false;
          this.$emit('close');
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.formule-title {
  text-transform: uppercase;
}

</style>
