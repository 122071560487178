import Vue from 'vue';

import APIService from '@/services/api';

const namespace = 'users';

const FETCH_USERS = 'FETCH_USERS';
const GET_USERS = 'GET_USERS';
const SET_USERS = 'SET_USERS';
const RESET_USERS = 'RESET_USERS';

export const GET_USERS_NS = `${namespace}/${GET_USERS}`;
export const RESET_USERS_NS = `${namespace}/${RESET_USERS}`;

function initialState() {
  return {
    users: [],
    loadingUsers: false,
    loadingUsersError: {},
    totalItems: 0,
  };
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    [RESET_USERS](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [FETCH_USERS](state) {
      Vue.set(state, 'loadingUsers', true);
    },
    [SET_USERS](state, { users = [], totalItems = 0, loadingUsersError }) {
      Vue.set(state, 'loadingUsers', false);
      Vue.set(state, 'users', users);
      Vue.set(state, 'totalItems', totalItems);
      Vue.set(state, 'loadingUsersError', loadingUsersError);
    },
  },
  actions: {
    [RESET_USERS]({ commit }) {
      commit(RESET_USERS);
    },
    [GET_USERS]({ commit }) {
      commit(FETCH_USERS);

      APIService
        .getUsers()
        .then(({ data }) => {
          commit(SET_USERS, {
            users: data,
            totalItems: data.length,
          });
        })
        .catch((error) => {
          commit(SET_USERS, { loadingUsersError: error });
        });
    },
  },
};
