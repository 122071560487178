<template>
  <div class="d-flex flex-wrap mt-4">
    <div
      v-if="loading"
      class="d-flex flex-grow-1 justify-center"
    >
      <v-progress-circular
        size="24"
        width="2"
        color="primary"
        indeterminate
      />
    </div>
    <template
      v-else-if="livestreams.length"
      v-for="livestream in livestreams"
    >
      <Livestream
        :livestream="livestream"
        :key="livestream.id"
      />
    </template>
    <div v-else>
      <span v-translate>No livestream available.</span>
    </div>
  </div>
</template>

<script>
import Livestream from '@/components/Livestreams/Livestream.vue';

export default {
  name: 'LivestreamsList',
  components: { Livestream },
  computed: {
    livestreams() {
      return this.$store.state.livestreams.livestreams;
    },
    loading() {
      return this.$store.state.livestreams.loadingLivestreams;
    },
  },
};
</script>
