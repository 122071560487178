<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Ask for an approval</translate>
    </v-card-title>
    <v-card-text>
      <p>
        {{ labels.creation }}
      </p>
      <p
        class="font-weight-bold"
        v-translate
      >
        Your approval request is not sent yet.
      </p>
      <p>
        {{ labels.toSubmit }}
      </p>
      <p v-translate>
        The approval request in the airspace of our partner airports is free*.
      </p>
      <p>
        {{ labels.approvalMention }}
      </p>
      <p>
        {{ labels.enstrust }}
      </p>
      <p class="font-italic">
        <span>
          {{ labels.paiement }}
        </span>
        <a
          target="_blank"
          href="https://clearance.aero/comment-deposer-une-demande-daccord-aupres-dun-aerodrome-exploite-par-edeis/"
          v-translate
        >
          article.
        </a>
      </p>
    </v-card-text>
    <v-card-actions>
      <v-checkbox
        v-model="neverShowAgain"
        :label="labels.neverShowAgain"
        color="primary"
        class="px-3 py-0 ma-0"
        hide-details
      />
      <v-btn
        text
        color="info"
        class="px-3"
        @click.native="closeDialog()"
      >
        <translate>Close</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from '@/services/api';

import { STOP_REQUEST_APPROVAL_ALERTS_NS } from '@/store/authentication';

export default {
  name: 'RequestApprovalAlert',
  data() {
    return { neverShowAgain: false };
  },
  computed: {
    labels() {
      return {
        neverShowAgain: this.$gettext('Do not display this message anymore'),
        creation: this.$gettext(`You just created a mission in the airspace of a partner airport.
          You can directly ask for an approval (free of charge*).`),
        toSubmit: this.$gettext(`In order to file your approval request, click on "REQUEST
          APPROVAL" button in the "CONSTRAINTS" section.`),
        approvalMention: this.$gettext(`Unless otherwise mentioned, this approval request relates
          only to this constraint. If other constraints are mentioned, you have to make other
          declarations or authorization requests.`),
        enstrust: this.$gettext(`If you want Clearance to handle all the necessary paperwork for
          your declarations and authorization requests, you have the possibility to entrust us by
          clicking on the corresponding button. This optional service comes at a cost.`),
        paiement: this.$gettext(`* Some airports handled by Edeis need an approval payment to
          process flights authorization. You can pay directly on Clearance but the price is decided
          by Edeis. More details on`),
      };
    },
    contactId() {
      return this.$store.state.authentication.user.contact_id;
    },
  },
  methods: {
    closeDialog() {
      if (this.neverShowAgain) {
        API.updateContactSettings(this.contactId, { display_request_approval_alert: false })
          .then(() => {
            this.$store.commit(STOP_REQUEST_APPROVAL_ALERTS_NS);
            this.$emit('close');
          });
      } else {
        this.$emit('close');
      }
    },
  },
};

</script>

<style
  lang="scss"
  scoped
>
</style>
