<template>
  <v-card>
    <v-card-title class="headline font-weight-regular primary white--text">
      <span v-translate>Approval request</span>
    </v-card-title>
    <v-card-text
      v-if="approvalPeriodIsValid"
      class="pt-5"
    >
      <span
        v-if="flightPilots.length < 1"
        class="error--text"
        v-translate
      >
        You need to create a pilot before submitting a flight request.
      </span>
      <div
        v-if="structureProcess"
        class="my-4"
      >
        <span
          v-translate
          class="font-weight-bold"
        >
          Important requirements transmitted by the relevant authority:
        </span>
        <span class="multilines">
          {{ structureProcess }}
        </span>
      </div>
      <!-- Structure settings -->
      <div v-if="hasStructureSettings">
        <v-alert
          color="primary"
          icon="mdi-information-outline"
          outlined
          border="left"
          class="text-sm-body-2 mt-2 font-weight-medium"
        >
          <span v-translate>Requirements for an approval request:</span>
          <ul>
            <li
              v-if="maxFlightAreas"
              key="maxFlightAreas"
              :class="checkMaxFlightAreas ? '' : 'error--text'"
            >
              <span
                v-translate="{ maxFlightAreas }"
                :translate-n="maxFlightAreas"
                translate-plural="Only %{maxFlightAreas} areas by approval is allowed."
              >
                Only %{maxFlightAreas} area by approval is allowed.
              </span>
            </li>
            <li
              v-if="maxSurface"
              key="maxSurface"
              :class="checkMaxSurface ? '' : 'error--text'"
            >
              <span v-translate>
                The approval global surface can't exceeded
              </span>
              <span>
                {{ maxSurface | squareMeter }}.
              </span>
            </li>
            <li
              v-if="maxAreaSurface"
              key="maxAreaSurface"
              :class="checkMaxAreaSurface ? '' : 'error--text'"
            >
              <span v-translate>
                The surface of an area of the approval can't exceeded
              </span>
              <span>
                {{ maxAreaSurface | squareMeter }}.
              </span>
            </li>
            <li
              v-if="maxExtent"
              key="maxExtent"
              :class="checkMaxExtent ? '' : 'error--text'"
            >
              <span v-translate>The approval global extent can't exceeded</span>
              <span>
                {{ maxExtent | scaleMeter }}.
              </span>
            </li>
            <li
              v-if="droneFieldsAreRequired"
              key="droneFieldsRequired"
              :class="checkDroneFieldsRequired ? '' : 'error--text'"
            >
              <span>
                {{ texts.droneFieldsRequirements }}
              </span>
              <span v-if="!checkDroneFieldsRequired">
                {{ texts.fillDroneFieldsRequired }}
              </span>
            </li>
          </ul>
        </v-alert>
      </div>
      <!-- Approval form -->
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="mb-4"
      >
        <FlightAreasSelection
          :flightId="flight.id"
          :structureId="constraint.structure_id"
          :flightAreasSelected.sync="flightAreasSelected"
          :maxExtent="maxExtent"
          :maxSurface="maxSurface"
          :maxAreaSurface="maxAreaSurface"
          class="mb-6"
        />
        <v-autocomplete
          v-model="pilotId"
          :items="flightPilots"
          item-text="full_name"
          item-value="id"
          :no-data-text="texts.pilotNoData"
          persistent-hint
          :label="texts.pilotLabel"
          :rules="rules.required"
        />
        <v-autocomplete
          v-model="droneIds"
          :items="flightDrones"
          item-text="label"
          item-value="id"
          :no-data-text="texts.dronesNoData"
          :hint="texts.dronesHint"
          :label="texts.dronesLabel"
          :readonly="flightHasDrones"
          :append-icon="flightHasDrones ? '' : '$dropdown'"
          :rules="rules.required"
          multiple
          class="mb-4"
        />
        <v-select
          v-model="scenario"
          :items="scenarios"
          persistent-hint
          :label="texts.scenarioLabel"
          :rules="rules.required"
        />
        <span>
          {{ texts.labelDetails }}
        </span>
        <v-textarea
          v-model="approvalDetails"
          outlined
          :rules="rules.required"
        />
        <span v-if="droneFieldsAreRequired">
          {{ texts.droneFieldsRequiredNote }}
        </span>
        <span v-else-if="droneFieldsAreEnquired">
          {{ texts.droneFieldsEnquiredNote }}
        </span>
      </v-form>
      <!-- Payment of request approval -->
      <div
        v-if="displayPaymentForm"
        class="mb-4"
      >
        <div class="px-1 pb-2">
          <span>
            {{ texts.edeisReclamation }}
          </span>
          <a
            target="_blank"
            @click.stop
            href="https://clearance.aero/comment-deposer-une-demande-daccord-aupres-dun-aerodrome-exploite-par-edeis/"
          >
            <span v-translate>Read more</span>
          </a>
        </div>
        <RequestApprovalPaymentForm
          ref="approvalRequestPaymentForm"
          :structureId="constraint.structure_id"
          :approvalId="approvalAwaitingPaymentId"
          :approvalPilotId="pilotId"
          :approvalDroneIds="droneIds"
          :approvalDetails="approvalDetails"
          :flightAreasIds="flightAreasSelected"
          :declaredScenario="scenario"
          v-on="$listeners"
          @invoice-loaded="() => invoiceDetailsLoading = false"
          @forms-valid="(isValid) => updatePaymentFormsValid(isValid)"
          @approval-payment-succeeded="() => cancelButtonAction()"
          @fail-payment="failPayment"
        />
      </div>
      <div v-else-if="capability.payment_required">
        <span
          v-if="isEdeisPaidByClearance"
          class="my-2 bold"
        >
          {{ texts.handleByClearance }}
        </span>
        <span
          v-else
          class="my-2 bold"
        >
          {{ texts.totalDiscount }}
        </span>
      </div>
      <span class="my-2">
        {{ texts.submitInfo }}
      </span>
      <br>
      <span v-if="displayPaymentForm">
        {{ texts.notMetropolitan }}
      </span>
    </v-card-text>
    <v-card-text v-else>
      <p class="my-2 error--text">
        <span>
          {{ texts.maxPeriod }}
        </span>
        <br>
        <span>
          {{ texts.editFlight }}
        </span>
      </p>
    </v-card-text>
    <v-card-actions class="d-flex justify-center pb-3">
      <v-btn
        :disabled="createApprovalLoading"
        class="mx-3"
        @click="cancelButtonAction()"
      >
        <span v-translate>Cancel</span>
      </v-btn>
      <v-btn
        :disabled="isConfirmButtonDisabled"
        class="white--text mx-3"
        color=primary
        :loading="createApprovalLoading"
        @click="confirmButtonAction()"
      >
        <span v-translate>Confirm</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DRONE_FIELD_LABELS } from '@/settings';
import { AWAITING_PAYMENT } from '@/store/status';

import FlightAreasSelection from '@/components/Flights/RequestApproval/FlightAreasSelection.vue';
import RequestApprovalPaymentForm from '@/components/Flights/RequestApproval/RequestApprovalPaymentForm.vue';

export default {
  name: 'RequestApprovalForm',
  components: {
    FlightAreasSelection,
    RequestApprovalPaymentForm,
  },
  props: {
    flight: Object,
    flightAreas: Array,
    flightPilots: Array,
    flightDrones: Array,
    approvalPeriodIsValid: Boolean,
    approvalMaxDays: Number,
    structureProcess: String,
    capability: Object,
    constraint: Object,
    discount: Number,
  },
  data() {
    const pilotId = this.flightPilots.length === 1 ? this.flightPilots[0].id : null;
    const droneIds = this.flight.drones.length > 0 ? this.flight.drones.map((d) => d.id) : null;
    const scenarios = [
      ...this.$store.getters['authentication/isUserEnterprise']
        ? [{ value: 's0', text: this.$gettext('Scenario S0') }] : [],
      { value: 's1', text: this.$gettext('Scenario S1') },
      { value: 's2', text: this.$gettext('Scenario S2') },
      { value: 's3', text: this.$gettext('Scenario S3') },
      { value: 'a1', text: this.$gettext('Open category A1') },
      { value: 'a2', text: this.$gettext('Open category A2') },
      { value: 'a3', text: this.$gettext('Open category A3') },
      { value: 'sts01', text: this.$gettext('Scenario STS-01') },
      { value: 'sts02', text: this.$gettext('Scenario STS-02') },
      { value: 'hss', text: this.$gettext('Non-standard scenarios (e.g. LUC)') },
    ];
    let scenario = this.flight.declared_scenario || this.flight.scenario;
    if (scenario) {
      scenario = scenario.toLowerCase();
      if (!scenarios.map((s) => s.value).includes(scenario)) scenario = null;
    }
    return {
      pilotId,
      droneIds,
      approvalDetails: '',
      paymentFormsValid: false,
      createApprovalLoading: false,
      invoiceDetailsLoading: true,
      valid: false,
      rules: {
        required: [
          (v) => !!v || this.$gettext('This field is required.'),
        ],
      },
      flightAreasSelected: [],
      maxFlightAreas: this.capability.max_flight_zones_number,
      maxSurface: this.capability.max_surface,
      maxExtent: this.capability.max_bbox_side,
      maxAreaSurface: this.capability.max_zone_surface,
      droneFieldsEnquired: this.capability.drone_fields_enquired,
      droneFieldsRequired: this.capability.drone_fields_required,
      scenarios,
      scenario,
    };
  },
  computed: {
    approvalAwaitingPaymentId() {
      return this.flight.approvals?.find(
        (approval) => (
          approval.status === AWAITING_PAYMENT
          && approval.constraint_id === this.constraint.id
        ),
      )?.id;
    },
    flightHasDrones() {
      return this.flight.drones.length > 0;
    },
    hasManyAreas() {
      return this.flightAreas.length > 1;
    },
    selectionFlightAreas() {
      return this.flightAreas.filter((a) => this.flightAreasSelected.includes(a.id));
    },
    globalFlightAreasSelectedGeometry() {
      return this.$turf.multiPolygon(this.selectionFlightAreas.map((a) => a.geometry.coordinates));
    },
    checkMaxFlightAreas() {
      return (
        this.maxFlightAreas && this.selectionFlightAreas.length
          ? this.selectionFlightAreas.length <= this.maxFlightAreas
          : true
      );
    },
    checkMaxSurface() {
      return (
        this.maxSurface && this.selectionFlightAreas.length
          ? this.$turf.area(this.globalFlightAreasSelectedGeometry) <= this.maxSurface
          : true
      );
    },
    checkMaxExtent() {
      return (
        this.maxExtent && this.selectionFlightAreas.length
          ? this.getGeometryMaxExtent(this.globalFlightAreasSelectedGeometry) <= this.maxExtent
          : true
      );
    },
    checkMaxAreaSurface() {
      if (this.maxAreaSurface && this.selectionFlightAreas.length) {
        const maxSurface = Math.max(...this.selectionFlightAreas.map(
          (a) => this.$turf.area(a.geometry),
        ));
        return maxSurface <= this.maxAreaSurface;
      }
      return true;
    },
    droneFieldsAreEnquired() {
      return this.droneFieldsEnquired?.length > 0;
    },
    droneFieldsAreRequired() {
      return this.droneFieldsRequired?.length > 0;
    },
    checkDroneFieldsRequired() {
      if (this.droneFieldsAreRequired) {
        const drones = this.flightDrones.filter((drone) => this.droneIds?.includes(drone.id));
        return drones.every((drone) => this.droneFieldsRequired.every((field) => !!drone[field]));
      }
      return true;
    },
    droneFieldsEnquiredDisplay() {
      if (this.droneFieldsAreEnquired) {
        return this.droneFieldsEnquired.map((field) => DRONE_FIELD_LABELS[field]).join(', ');
      }
      return '';
    },
    droneFieldsRequiredDisplay() {
      if (this.droneFieldsAreRequired) {
        return this.droneFieldsRequired.map((field) => DRONE_FIELD_LABELS[field]).join(', ');
      }
      return '';
    },
    isConfirmButtonDisabled() {
      if (this.hasManyAreas && this.flightAreasSelected.length === 0) return true;
      let isDisabled = false;
      if (this.displayPaymentForm) {
        isDisabled = !this.paymentFormsValid || this.invoiceDetailsLoading;
      }
      if (!isDisabled && this.hasStructureSettings) {
        isDisabled = !(
          this.checkMaxFlightAreas
          && this.checkMaxSurface
          && this.checkMaxExtent
          && this.checkMaxAreaSurface
          && this.checkDroneFieldsRequired
        );
      }
      return isDisabled || !this.valid;
    },
    displayPaymentForm() {
      return this.capability.payment_required
        && this.discount !== 100
        && !this.isEdeisPaidByClearance;
    },
    texts() {
      return {
        pilotLabel: this.$gettext('Pilot involved in the mission *'),
        pilotNoData: this.$gettext('No pilot found'),
        dronesLabel: this.$gettext('Drones used for the mission *'),
        dronesNoData: this.$gettext('No drone found'),
        dronesHint: (this.flightHasDrones ? this.$gettext(
          'These drones are linked to you flight and will be used for the approval request',
        ) : ''
        ),
        scenarioLabel: this.$gettext('Declared scenario *'),
        labelDetails: this.$gettext(`Details of your flight (will be transferred to authority in
          charge of your approval) *:`),
        edeisReclamation: this.$gettextInterpolate(
          this.$gettext(`%{ name } required the paiement of this invoice before the request
          approval in this space area. Any questions or complaints about billing should be directed
          to the following email: reclamations.drones@edeis.com.`),
          { name: this.capability.structure_name },
          true,
        ),
        maxPeriod: this.$gettextInterpolate(
          this.$gettext(`Requests for agreement from the authority responsible for this area may
            not exceed exceed a duration of %{ maxDays } days.`),
          { maxDays: this.approvalMaxDays || '' },
        ),
        totalDiscount: this.$gettext(`You don't have to pay this approval thank to a discount
        gave by the authority.`),
        handleByClearance: this.$gettext(`The payment of this approval is handled in your
        subscription.`),
        submitInfo: this.$gettext(`By confirming, an approval request will be send to the
          authority in charge of this area. You will receive a confirmation email as soon as your
          request will study.`),
        editFlight: this.$gettext(`You can modify your mission and edit the dates or times in
          order to submit your approval request.`),
        notMetropolitan: this.$gettext(`If your company is based outside metropolitan France,
          please contact us at contact@clearance.aero.`),
        droneFieldsRequirements: this.$gettextInterpolate(
          this.$gettext('Information required for each selected drone: %{ fields }.'),
          { fields: this.droneFieldsRequiredDisplay },
        ),
        fillDroneFieldsRequired: this.$gettext(
          'You can fill the required drone information id by updating them in drones tab.',
        ),
        droneFieldsRequiredNote: this.$gettextInterpolate(
          this.$gettext(`The following information of the drones related to your flight will
            be transferred to authority in charge of your approval: %{ fields }.`),
          { fields: this.droneFieldsRequiredDisplay },
        ),
        droneFieldsEnquiredNote: this.$gettextInterpolate(
          this.$gettext(`The following information of the drones related to your flight will
            be transferred to authority in charge of your approval if filled: %{ fields }.`),
          { fields: this.droneFieldsEnquiredDisplay },
        ),
      };
    },
    payload() {
      return {
        pilotId: this.pilotId,
        droneIds: this.droneIds,
        approvalDetails: this.approvalDetails,
        flightAreasIds: this.hasManyAreas ? this.flightAreasSelected : [],
        declaredScenario: this.scenario,
      };
    },
    hasStructureSettings() {
      return (
        this.maxFlightAreas !== null
        || this.maxSurface !== null
        || this.maxExtent !== null
        || this.maxAreaSurface !== null
        || this.droneFieldsAreRequired === true
      );
    },
    isEdeisPaidByClearance() {
      return this.$store.state.authentication.user?.subscription?.edeis_payment_handle_by_clearance;
    },
  },
  created() {
    if (this.displayPaymentForm && this.approvalAwaitingPaymentId === undefined) {
      const payload = {
        pilotId: this.$store.state.authentication.user.contact_id,
        approvalDetails: this.$gettext('Waiting payment'),
        flightAreasIds: [],
      };
      this.$emit('create-awaiting-payment-approval', payload);
    }
  },
  unmounted() {
    this.cancelButtonAction();
  },
  methods: {
    updatePaymentFormsValid(value) {
      this.paymentFormsValid = value;
    },
    confirmButtonAction() {
      if (!this.$refs.form.validate()) {
        this.showMessage(
          this.$gettext('Errors found in form. Please fill all necessary fields.'),
          'error',
        );
        return;
      }
      this.createApprovalLoading = true;
      if (this.displayPaymentForm) {
        this.$refs.approvalRequestPaymentForm.payInvoice();
      } else if (this.approvalAwaitingPaymentId && this.discount === 100) {
        this.updateApprovalAwaitingPayment();
      } else {
        this.createApproval();
      }
    },
    cancelButtonAction() {
      this.createApprovalLoading = false;
      if (this.displayPaymentForm) {
        this.$refs.approvalRequestPaymentForm.reset();
      }
      this.$emit('close-form');
    },
    createApproval() {
      this.createApprovalLoading = false;
      this.$emit(
        'create-approval',
        this.payload,
      );
    },
    updateApprovalAwaitingPayment() {
      this.createApprovalLoading = false;
      this.$emit(
        'update-approval-awaiting-payment',
        this.approvalAwaitingPaymentId,
        this.payload,
      );
    },
    failPayment({ resetPaymentForm = true }) {
      this.createApprovalLoading = false;
      if (resetPaymentForm) {
        this.$refs.approvalRequestPaymentForm.reset();
      }
    },
    getGeometryMaxExtent(geometry) {
      const [west, south, east, north] = this.$turf.bbox(geometry);
      const distanceHorizontal = this.$turf.distance([west, south], [east, south]);
      const distanceVertical = this.$turf.distance([west, south], [west, north]);
      const maxExtentKilometers = Math.max(distanceHorizontal, distanceVertical);
      const maxExtentMeters = maxExtentKilometers * 1000;
      return maxExtentMeters;
    },
  },
};
</script>
