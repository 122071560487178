<template>
  <v-card v-if="statusForUpdate">
    <v-card-title
      class="headline"
      :class="`${currentStatusContent.color}--text`"
    >
      {{ currentStatusContent.title }}
    </v-card-title>
    <v-col cols="12">
      <!-- Zone selection -->
      <SubApprovalsSelection
        v-if="hasManySubApprovals && !oneDecisionByApproval"
        :subApprovals="approval.sub_approvals"
        :subApprovalSelected="subApprovalSelected"
        class="px-2 mb-4"
      />
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="px-2"
      >
        <!-- Approval category -->
        <template v-if="canApplyRestriction && approvalCategories.length">
          <div
            class="subtitle-2 mb-1"
            key="approvalCategory"
            v-translate
          >
            Approval category
          </div>
          <v-combobox
            v-model="approvalCategory"
            :items="approvalCategories"
            return-object
            clearable
            outlined
            :placeholder="$gettext('Select approval category')"
            @change="applyApprovalCategory()"
          >
            <template v-slot:selection="{ item }">
              <v-chip
                v-if="item"
                small
                color="primary"
              >
                <span class="white--text font-weight-medium">
                  {{ item.name }}
                </span>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-chip
                small
                color="primary"
              >
                <span class="white--text font-weight-medium">
                  {{ item.name }}
                </span>
              </v-chip>
            </template>
          </v-combobox>
        </template>
        <!-- Emails list -->
        <template v-if="statusForUpdate === 'pending'">
          <v-combobox
            v-model="forwardMissionSheetRecipientsSelected"
            :items="forwardMissionSheetRecipients"
            :search-input.sync="search"
            :label="$gettext('Recipients')"
            :rules="rules.recipients"
            hide-selected
            multiple
            small-chips
            @click:append="search=null"
            outlined
          >
            <template
              v-slot:no-data
              v-if="!isSearching"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-html="texts.searchMail" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, parent, selected }">
              <v-chip
                v-if="item"
                :input-value="selected"
              >
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                >
                  close
                </v-icon>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-chip>
                  {{ item }}
                </v-chip>
              </v-list-item-content>
            </template>
          </v-combobox>
        </template>
        <!-- Message for the dronist -->
        <div
          class="subtitle-2 mb-1"
          key="messageForDronist"
        >
          {{ currentStatusContent.placeholder }}
        </div>
        <!-- Text area -->
        <div class="d-flex">
          <v-textarea
            v-model="message"
            :placeholder="currentStatusContent.placeholder"
            :rules="rules[statusForUpdate]"
            outlined
            rows="6"
          />
          <!-- Particular requirements menu -->
          <template v-if="!isUserPrefecture">
            <div class="d-flex flex-column justify-end pb-8">
              <SavedMessagesMenu
                :isParticularRequirements="canApplyRestriction"
                :isChatMessages="!canApplyRestriction"
                :text.sync="message"
              />
            </div>
          </template>
        </div>
        <!-- Restriction for reserves -->
        <template v-if="canApplyRestriction">
          <div
            class="subtitle-2"
            v-translate
          >
            Restriction
          </div>
          <!-- Height -->
          <v-row
            no-gutters
            align="center"
          >
            <v-col cols="4">
              <span v-translate>Max flying height</span>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="flyingHeight"
                :key="`approval-${approval.id}`"
                type="number"
                suffix="m"
              />
            </v-col>
          </v-row>
          <!-- Period -->
          <v-row
            no-gutters
            align="center"
          >
            <v-col cols="4">
              <span v-translate>Period allowed</span>
            </v-col>
            <v-col cols="8">
              <v-menu
                ref="periodRestrictionMenu"
                v-model="periodRestrictionMenu"
                :return-value.sync="periodRestriction"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="periodRestrictionText"
                    append-outer-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  />
                </template>
                <v-date-picker
                  v-model="periodRestriction"
                  locale="fr"
                  first-day-of-week="1"
                  scrollable
                  no-title
                  range
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="info"
                    @click="periodRestrictionMenu = false"
                  >
                    <span v-translate>Cancel</span>
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    :disabled="periodRestriction.length !== 2"
                    @click="$refs.periodRestrictionMenu.save(periodRestriction)"
                  >
                    <span v-translate>OK</span>
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Time -->
          <v-row
            no-gutters
            align="center"
          >
            <v-col cols="4">
              <div v-translate>Time range allowed</div>
            </v-col>
            <v-col
              cols="4"
              class="pr-2"
            >
              <!-- Time start -->
              <TimePicker
                :time="timeStartRestriction"
                @update:time="timeStartRestriction = $event"
                key="timeStart"
              />
            </v-col>
            <v-col
              cols="4"
              class="pl-2"
            >
              <TimePicker
                :time="timeEndRestriction"
                @update:time="timeEndRestriction = $event"
                key="timeEnd"
              />
            </v-col>
          </v-row>
        </template>
        <!-- Tactical contact -->
        <v-row
          v-if="shouldDisplayTacticalContactCheckbox"
          no-gutters
          align="center"
        >
          <v-col cols="4">
            <span v-translate>Tactical contact required</span>
          </v-col>
          <v-col
            cols="8"
            class="d-flex"
          >
            <v-checkbox
              v-model="tacticalContactRequired"
              color="primary"
            />
          </v-col>
        </v-row>
        <!-- Take off authorization -->
        <div
          v-if="shouldDisplayTakeOffAuthorizationSwitch"
          class="d-flex align-center"
        >
          <span v-translate>Take off authorization required before take off</span>
          <v-switch
            v-model="takeOffAuthorizationRequired"
            class="ml-6"
          />
        </div>
        <!-- Internal ATS note -->
        <template v-if="!isUserPrefecture">
          <hr class="separator" />
          <v-expansion-panels v-model="atsNotePanel">
            <v-expansion-panel class="mb-3">
              <v-expansion-panel-header>
                <span v-translate>Note for internal use by air traffic control.</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex">
                  <v-textarea
                    v-model="atsNote"
                    class="pt-0 mt-0"
                    rows="3"
                    :rules="rules.atsNote"
                  />
                  <div class="d-flex flex-column justify-end pb-5">
                    <SavedMessagesMenu
                      isATSInternalNotes
                      :text.sync="atsNote"
                    />
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <!-- Processed by -->
        <v-row
          v-if="generateApprovalProtocolSheet"
          no-gutters
          align="center"
        >
          <v-col cols="4">
            <span
              class="font-weight-bold"
              v-translate
            >
              Processed by
            </span>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="superviserName"
              :placeholder="$gettext('Authority name')"
              type="text"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="info"
        text
        @click.native="$emit('close-form')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        text
        :color="currentStatusContent.color"
        :disabled="!valid || subApprovalSelected.length === 0"
        :loading="updateStatusLoading"
        @click.native="setStatus(statusForUpdate)"
      >
        <translate>Confirm</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { format } from 'date-fns';

import {
  SET_APPROVAL_STATUS_NS,
  GET_APPROVAL_DETAILS_NS,
  VALIDATE_PROTOCOL_SHEET_NS,
} from '@/store/approvals';
import { ACCEPTED, INCOMPLETE, PENDING, RESERVES, SUBMITTED } from '@/store/status';

import SubApprovalsSelection from '@/components/Approvals/SubApprovalsSelection.vue';
import SavedMessagesMenu from '@/components/SavedMessagesMenu.vue';
import TimePicker from '@/components/TimePicker.vue';

export default {
  name: 'ChangeApprovalStatusForm',
  components: {
    SubApprovalsSelection,
    SavedMessagesMenu,
    TimePicker,
  },
  props: {
    approval: Object,
    statusForUpdate: String,
    updateStatusLoading: Boolean,
  },
  data() {
    const { flight } = this.approval;
    return {
      loading: false,
      valid: false,
      atsNote: this.approval.ats_internal_note,
      tacticalContactRequired: this.approval.tactical_contact_required,
      dialogStatusContent: {
        accepted: {
          title: this.$gettext('Authorize approval'),
          placeholder: this.$gettext('You can send a message to the pilot.'),
          color: 'success',
        },
        reserves: {
          title: this.$gettext('Authorize approval with restriction'),
          placeholder: this.$gettext('Instructions to be given to the pilot.'),
          color: 'warning',
        },
        refused: {
          title: this.$gettext('Refuse approval'),
          placeholder: this.$gettext('Explain to pilot the reasons of your refusal.'),
          color: 'error',
        },
        pending: {
          title: this.$gettext('Send mission sheet'),
          placeholder: this.$gettext('Additional information.'),
          color: 'primary',
        },
        incomplete: {
          title: this.$gettext('Ask for additional information'),
          placeholder: this.$gettext('Explain to the pilot the additional information.'),
          color: 'blue-grey',
        },
      },
      rules: {
        reserves: [
          (v) => !!v || this.$gettext('Please explain your acceptance condition.'),
        ],
        refused: [
          (v) => !!v || this.$gettext('A message to explain your refusal is required.'),
        ],
        incomplete: [
          (v) => !!v || this.$gettext('A message to request additional information is required.'),
        ],
        accepted: [],
        submitted: [],
        recipients: [
          (v) => v.length !== 0 || this.$gettext('Required.'),
          (values) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailIsValid = true;
            values.forEach((value) => {
              if (!pattern.test(value)) {
                emailIsValid = this.$gettext('Invalid email.');
              }
            });
            return emailIsValid;
          },
        ],
        atsNote: [],
      },
      search: null,
      message: '',
      subApprovalSelected: [],
      flyingHeight: Math.max(...this.approval.sub_approvals.map((s) => s.height)),
      periodRestriction: [flight.date_start, flight.date_end],
      periodRestrictionMenu: false,
      timeStartRestriction: flight.time_start,
      timeEndRestriction: flight.time_end,
      takeOffAuthorizationRequired: false,
      forwardMissionSheetRecipientsSelected: [],
      superviserName: '',
      approvalCategory: null,
      atsNotePanel: undefined,
    };
  },
  computed: {
    flight() {
      return this.approval.flight;
    },
    structureDetails() {
      return this.$store.getters['structures/structureDetails'];
    },
    forwardMissionSheetRecipients() {
      return this.structureDetails.airspace_forward_emails || [];
    },
    approvalCategories() {
      return this.structureDetails.active_airspace_approval_categories || [];
    },
    structureSettings() {
      return this.$store.getters['structures/structureSettings'];
    },
    defaultSuperviserName() {
      const { superviser } = this.$store.state.authentication.user;
      let name = '';
      if (superviser.first_name?.length) name = superviser.first_name;
      if (superviser.last_name?.length) {
        if (name.length) name += ' ';
        name += superviser.last_name;
      }
      return name;
    },
    tacticalContactProtocol() {
      return this.structureSettings.tactical_contact_protocol;
    },
    takeOffAuthorizationActivated() {
      return this.structureSettings.take_off_authorization_activated;
    },
    defaultAnnualDecision() {
      return this.structureSettings.default_annual_decision;
    },
    generateApprovalProtocolSheet() {
      return this.structureSettings.generate_approval_protocol_sheet;
    },
    oneDecisionByApproval() {
      return this.structureSettings.one_decision_by_approval;
    },
    periodRestrictionText() {
      const dateStart = format(new Date(this.periodRestriction[0]), 'dd/MM/yyy');
      if (this.periodRestriction.length === 1) return `${dateStart} -`;

      const dateEnd = format(new Date(this.periodRestriction[1]), 'dd/MM/yyy');
      return `${dateStart} - ${dateEnd}`;
    },
    shouldDisplayTacticalContactCheckbox() {
      return this.canApplyRestriction && !this.takeOffAuthorizationActivated;
    },
    shouldDisplayTakeOffAuthorizationSwitch() {
      return this.canApplyRestriction && this.takeOffAuthorizationActivated;
    },
    isSearching() {
      return this.search === null || this.search === '';
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    hasManySubApprovals() {
      return this.approval.sub_approvals.length > 1;
    },
    texts() {
      return {
        searchMail: this.$gettextInterpolate(
          this.$gettext('No result for %{search}. Input <kbd>Enter</kbd> to add it.'),
          { search: this.search || '' },
        ),
      };
    },
    canApplyRestriction() {
      return (
        this.statusForUpdate === RESERVES
        || (this.statusForUpdate === INCOMPLETE && this.generateApprovalProtocolSheet)
      );
    },
    currentStatusContent() {
      return this.dialogStatusContent[this.statusForUpdate];
    },
  },
  watch: {
    statusForUpdate(value) {
      if (value === INCOMPLETE && this.generateApprovalProtocolSheet) {
        this.rules.atsNote = [(v) => !!v || this.$gettext('This field is required.')];
        this.atsNotePanel = 0;
      } else {
        this.rules.atsNote = [];
      }
    },
    tacticalContactRequired(value) {
      if (value) {
        this.insertContent(this.tacticalContactProtocol);
      }
    },
    takeOffAuthorizationRequired(value) {
      if (value) {
        this.insertContent(this.tacticalContactProtocol);
      }
    },
    periodRestriction(newValue) {
      if (newValue.length === 2) {
        const [dateStart, dateEnd] = newValue;
        if (dateStart > dateEnd) {
          this.periodRestriction = [dateEnd, dateStart];
        }
      }
    },
  },
  created() {
    if (this.isUserPrefecture) {
      this.dialogStatusContent.accepted.title = this.$gettext('Accept');
      this.dialogStatusContent.reserves.title = this.$gettext('Accept with restrictions');
      this.dialogStatusContent.refused.title = this.$gettext('Refuse');
    }
    if (this.generateApprovalProtocolSheet) {
      this.dialogStatusContent.accepted.title = this.$gettext('Validate protocol sheet');
      this.dialogStatusContent.incomplete.title = this.$gettext('Ask for protocol sheet signature');
      this.dialogStatusContent.incomplete.placeholder = (
        this.$gettext('Instructions to be given to the pilot.')
      );
      if (this.statusForUpdate === INCOMPLETE) {
        this.rules.atsNote = [(v) => !!v || this.$gettext('This field is required.')];
        this.atsNotePanel = 0;
      }
    }
    if (this.oneDecisionByApproval) {
      this.subApprovalSelected = this.approval.sub_approvals.map((s) => s.id);
    } else if (!this.hasManySubApprovals) {
      this.subApprovalSelected.push(this.approval.sub_approvals.at(0).id);
    } else {
      this.subApprovalSelected = this.approval.sub_approvals.filter(
        (s) => s.status === SUBMITTED,
      ).map((s) => s.id);
    }
    if (this.defaultAnnualDecision) {
      const yearEnd = `${new Date().getFullYear()}-12-31`;
      this.periodRestriction[1] = yearEnd;
    }
    this.forwardMissionSheetRecipientsSelected = this.forwardMissionSheetRecipients;
    this.superviserName = this.defaultSuperviserName;
  },
  methods: {
    insertContent(content) {
      if (!this.message.includes(content)) {
        if (this.message.length) this.message += '\n\n';
        this.message += content;
      }
    },
    applyApprovalCategory() {
      if (!this.approvalCategory) return;
      this.message = this.approvalCategory.extra_requirements;
      const approvalHeight = Math.max(...this.approval.sub_approvals.map((s) => s.height));
      if (this.approvalCategory.height) {
        this.flyingHeight = Math.min(approvalHeight, this.approvalCategory.height);
      } else {
        this.flyingHeight = approvalHeight;
      }
      if (this.takeOffAuthorizationActivated) {
        this.takeOffAuthorizationRequired = this.approvalCategory.tactical_contact_required;
      } else {
        this.tacticalContactRequired = this.approvalCategory.tactical_contact_required;
      }
      this.atsNote = this.approvalCategory.ats_internal_note;
      if (this.atsNote) this.atsNotePanel = 0;
    },
    buildRestrictionPayload() {
      const payload = {};
      if (this.shouldDisplayTacticalContactCheckbox) {
        payload.tactical_contact_required = this.tacticalContactRequired;
      }
      if (this.shouldDisplayTakeOffAuthorizationSwitch) {
        payload.take_off_authorization_required = this.takeOffAuthorizationRequired;
      }
      if (
        this.flyingHeight
        && this.approval.sub_approvals.filter(
          (s) => this.subApprovalSelected.includes(s.id),
        ).some((s) => s.height !== this.flyingHeight)
      ) {
        payload.new_flying_height = this.flyingHeight;
      }
      if (this.periodRestriction.at(0) !== this.flight.date_start) {
        payload.new_date_start = this.periodRestriction.at(0);
      }
      if (this.periodRestriction.at(1) !== this.flight.date_end || this.defaultAnnualDecision) {
        payload.new_date_end = this.periodRestriction.at(1);
      }
      if (this.timeStartRestriction !== this.flight.time_start) {
        payload.new_time_start = this.timeStartRestriction;
      }
      if (this.timeEndRestriction !== this.flight.time_end) {
        payload.new_time_end = this.timeEndRestriction;
      }
      if (this.approvalCategory) payload.category = this.approvalCategory.id;

      return payload;
    },
    buildPayload(status) {
      let payload = {
        status,
        message: this.message,
        ats_internal_note: this.atsNote,
        sub_approval_ids: this.subApprovalSelected,
        superviser_name: this.superviserName,
      };
      if (this.canApplyRestriction) {
        const restrictionPayload = this.buildRestrictionPayload();
        payload = { ...payload, ...restrictionPayload };
      }
      if (status === PENDING && this.forwardMissionSheetRecipientsSelected.length) {
        payload.recipients = this.forwardMissionSheetRecipientsSelected;
      }

      return payload;
    },
    buildPayloadProtocolSheetValidation() {
      return {
        message: this.message,
        ats_internal_note: this.atsNote,
        superviser_name: this.superviserName,
      };
    },
    async setStatus(status) {
      if (this.$refs.form.validate()) {
        this.$emit('update:updateStatusLoading', true);
        try {
          if (status === ACCEPTED && this.generateApprovalProtocolSheet) {
            const payload = this.buildPayloadProtocolSheetValidation();
            await this.$store.dispatch(
              VALIDATE_PROTOCOL_SHEET_NS,
              { approvalId: this.approval.id, payload },
            );
          } else {
            const payload = this.buildPayload(status);
            await this.$store.dispatch(
              SET_APPROVAL_STATUS_NS, { id: this.approval.id, payload },
            );
          }
          this.showMessage(this.$gettext('Decision emitted'), 'success');
          this.$emit('update:statusForUpdate', '');
          this.message = '';
          this.valid = false;
        } catch (error) {
          const message = error?.response?.data?.detail || this.$gettext('Error during decision');
          this.showMessage(message, 'error');
        } finally {
          this.$store.dispatch(GET_APPROVAL_DETAILS_NS, { approvalId: this.approval.id });
          this.$emit('update:updateStatusLoading', false);
          this.$emit('close-form');
        }
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.link {
  text-decoration: underline;
  cursor: pointer;
}
.attachment {
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, .42);
}

</style>
