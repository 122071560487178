<template>
  <div>
    <v-list-item>
      <v-list-item-action>
        <v-checkbox v-model="checked" />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <span
            v-translate
            :class="checked ? 'primary--text' : 'text--secondary'"
          >
            Select all
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div class="ma-0 px-4">
      <v-divider class="my-1" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SelectAllSlot',
  props: {
    allSelected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    checked: {
      get() {
        return this.allSelected;
      },
      set(value) {
        this.$emit('update:allSelected', value);
      },
    },
  },
};
</script>
