<template>
  <v-card v-if="statusForUpdate">
    <v-card-title
      class="headline"
      :class="`${dialogStatusContent[statusForUpdate].color}--text`"
    >
      {{ dialogStatusContent[statusForUpdate].title }}
    </v-card-title>
    <v-col cols="12">
      <div
        v-if="withDetails"
        class="px-4 mb-6"
      >
        <v-row no-gutters>
          <v-col cols="2">
            <span
              class="caption font-weight-medium"
              v-translate
            >
              MISSION
            </span>
          </v-col>
          <v-col cols="10">
            {{ activation.approval.display_identifier }}
          </v-col>
          <v-col cols="2">
            <span
              class="caption font-weight-medium"
              v-translate
            >
              DATES
            </span>
          </v-col>
          <v-col cols="10">
            <span>
              {{ activation.date_start | date }} - {{ activation.date_end | date }}
            </span>
          </v-col>
          <v-col cols="2">
            <span
              class="caption font-weight-medium"
              v-translate
            >
              HOURS
            </span>
          </v-col>
          <v-col cols="10">
            {{ activation.time_start | hour }} - {{ activation.time_end | hour }}
          </v-col>
        </v-row>
      </div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="px-2"
      >
        <!-- Text area -->
        <v-textarea
          v-model="message"
          :label="dialogStatusContent[statusForUpdate].placeholder"
          :rules="rules[statusForUpdate]"
          outlined
          rows="6"
        />
        <template v-if="statusForUpdate === 'accepted'">
          <!-- Time -->
          <v-row
            v-if="!loadingAllowedHours"
            no-gutters
            align="center"
          >
            <v-col cols="4">
              <div v-translate>Time range allowed</div>
            </v-col>
            <v-col cols="8">
              <!-- Time start -->
              <TimePicker
                :time="timeStartRestriction"
                @update:time="timeStartRestriction = $event"
                key="timeStart"
                :minute-interval="5"
                :rules="rules.required"
                dropDirection="up"
              />
            </v-col>
            <v-col
              cols="8"
              offset="4"
            >
              <!-- Time end -->
              <TimePicker
                :time="timeEndRestriction"
                @update:time="timeEndRestriction = $event"
                key="timeEnd"
                :minute-interval="5"
                :rules="rules.required"
                dropDirection="up"
              />
            </v-col>
          </v-row>
          <v-col v-else>
            <v-progress-linear
              :indeterminate="true"
              color="primary"
            />
          </v-col>
        </template>
      </v-form>
    </v-col>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="info"
        text
        @click.native="$emit('close-form')"
      >
        <span v-translate>Cancel</span>
      </v-btn>
      <v-btn
        text
        :color="dialogStatusContent[statusForUpdate].color"
        :disabled="!valid"
        :loading="loading || loadingAllowedHours"
        @click.native="setStatus(statusForUpdate)"
      >
        <span v-translate>Confirm</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { SET_ACTIVATION_STATUS_NS } from '@/store/activations';
import { ACCEPTED } from '@/store/status';

import TimePicker from '@/components/TimePicker.vue';

export default {
  name: 'ChangeActivationStatusForm',
  components: { TimePicker },
  props: {
    activation: Object,
    statusForUpdate: String,
    withDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      dialogStatusContent: {
        accepted: {
          title: this.$gettext('Authorize activation'),
          placeholder: this.$gettext('You can send a message to the pilot.'),
          color: 'success',
        },
        refused: {
          title: this.$gettext('Refuse activation'),
          placeholder: this.$gettext('Explain to pilot the reasons of your refusal.'),
          color: 'error',
        },
      },
      rules: {
        refused: [
          (v) => !!v || this.$gettext('A message to explain your refusal is required.'),
        ],
        accepted: [],
      },
      message: '',
      timeStartRestriction: '',
      timeEndRestriction: '',
      loadingAllowedHours: false,
    };
  },
  created() {
    this.getAllowedHours();
  },
  methods: {
    setStatus(status) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const payload = { status, message: this.message };
        if (status === ACCEPTED) {
          if (this.activation.time_start !== this.timeStartRestriction) {
            payload.time_start = this.timeStartRestriction;
          }
          if (this.activation.time_end !== this.timeEndRestriction) {
            payload.time_end = this.timeEndRestriction;
          }
        }
        this.$store.dispatch(SET_ACTIVATION_STATUS_NS, { id: this.activation.id, payload })
          .then(() => {
            this.showMessage(this.$gettext('Activation updated.'), 'success');
            this.$emit('close-form');
            this.message = '';
            this.valid = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getAllowedHours() {
      this.loadingAllowedHours = true;
      APIService.getActivationAllowedHours(this.activation.id)
        .then(({ data }) => {
          this.timeStartRestriction = data.time_start;
          this.timeEndRestriction = data.time_end;
        })
        .finally(() => {
          this.loadingAllowedHours = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.max-height {
  max-height: 120px;
}

</style>
