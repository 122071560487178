<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <span v-translate>AlphaTango Notification</span>
    </v-card-title>
    <v-skeleton-loader
      v-if="notifInfoLoading"
      type="list-item-two-line, list-item-three-line, list-item-two-line, list-item, actions "
    />
    <template v-else>
      <v-card-text v-if="canRequestNotifications">
        <v-alert
          text
          border="left"
          class="body-2"
        >
          <!-- Introduction -->
          <div class="mb-2">
            {{ texts.notifInfoIntro }}
          </div>
          <!-- Constraints involved -->
          <div class="mb-2">
            {{ texts.notifInfoConstraints }}
          </div>
          <!-- Notification info about number created -->
          <div class="mb-1">
            {{ texts.notifInfoNumber }}
          </div>
          <!-- Notification info about send date -->
          <div class="mb-1">
            {{ texts.notifInfoSendDate }}
          </div>
          <!-- Notification info about cancellation possibility -->
          <div class="mb-2">
            {{ texts.notifInfoCancellation }}
          </div>
          <!-- Notification info about flight edit instructions -->
          <div>
            {{ texts.notifInfoFlightEdit }}
          </div>
        </v-alert>
        <v-form
          ref="form"
          v-model="valid"
        >
          <!-- Dates -->
          <div class="d-flex flex-wrap">
            <v-menu
              ref="menu"
              v-model="datesMenu"
              :return-value.sync="dateRange"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :rules="[rules.datesRequired]"
                  :label="labels.dateRange+' *'"
                  append-outer-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                />
              </template>
              <v-date-picker
                v-model="dateRange"
                :min="minDatePicker"
                locale="fr"
                first-day-of-week="1"
                scrollable
                no-title
                range
              >
                <v-spacer />
                <v-btn
                  text
                  color="info"
                  @click="datesMenu=false"
                >
                  <span v-translate>Cancel</span>
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  :disabled="dateRange.length !== 2"
                  @click="recalculateNotifInfos()"
                >
                  <span v-translate>OK</span>
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <!-- Alert for dates notice warning -->
          <v-alert
            v-if="notifInfoNoticeWarning"
            outlined
            text
            dense
            type="warning"
            class="caption"
            icon="mdi-alert-outline"
          >
            {{ texts.notifInfoNoticeWarning }}
          </v-alert>
          <!-- Alert for dates notice error -->
          <v-alert
            v-if="notifInfoNoticeError"
            outlined
            text
            dense
            type="error"
            class="caption"
            icon="mdi-alert-circle-outline"
          >
            {{ texts.notifInfoNoticeError }}
          </v-alert>

          <!-- ---------- Useful information ---------- -->
          <v-textarea
            v-model="requestData.additionalInfo"
            :label="labels.additionalInfo"
            :placeholder="placeholders.additionalInfo"
            class=""
            rows="2"
          />
          <!-- ---------- Main pilot ---------- -->
          <v-autocomplete
            v-model="requestData.mainPilotId"
            :items="flight.pilots"
            :label="labels.mainPilot"
            item-value="id"
            item-text="full_name"
            :no-data-text="$gettext('No pilot found')"
            @change="updateOtherPilots()"
            class="mr-4"
            persistent-hint
          />
          <!-- ---------- Other pilots ---------- -->
          <div
            v-if="flight.pilots.length > 1"
            class="d-flex align-center"
          >
            <v-textarea
              v-model="requestData.otherPilots"
              :label="labels.otherPilots"
              :placeholder="placeholders.otherPilots"
              class="caption"
              auto-grow
              rows="3"
            />
            <v-tooltip
              bottom
              color="info"
              max-width=580
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="small-icon"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <div
                v-for="(line, index) in tooltips.otherPilots.split('<br>')"
                :key="index"
              >
                {{ line }}
              </div>
            </v-tooltip>
          </div>
          <!-- -------------- Declarant --------------- -->
          <v-row
            no-gutters
            class="d-flex flex-wrap"
          >
            <v-col
              cols="12"
              sm="3"
            >
              <v-autocomplete
                v-model="connectedPilotId"
                :items="exploitationPilots"
                :label="labels.declarant+' *'"
                item-value="id"
                item-text="full_name"
                :no-data-text="$gettext('No pilot found')"
                @change="updateDeclarant()"
                class="mr-4"
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="requestData.lastName"
                :label="labels.lastName+' *'"
                :placeholder="placeholders.lastName"
                :rules="[rules.required]"
                class="pr-1"
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="requestData.firstName"
                :label="labels.firstName+' *'"
                :placeholder="placeholders.firstName"
                :rules="[rules.required]"
                class="pl-2 pr-1"
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="requestData.occupation"
                :rules="[rules.occupation]"
                :label="labels.occupation"
                :placeholder="placeholders.occupation"
                class="pl-2"
              />
            </v-col>
          </v-row>
          <v-alert
            class="declarant-alert mb-0"
            color="grey lighten-3"
          >
            <div
              v-for="(line, index) in texts.declarantAlert.split('<br>')"
              :key="index"
              class="mb-3"
            >
              {{ line }}
            </div>
            <div class="declaration-date pr-2">
              {{ today }}
            </div>
          </v-alert>
          <v-alert
            v-if="this.update"
            text
            border="left"
            class="body-2 mt-2"
            type="warning"
          >
            {{ texts.cancelOutdatedNotification }}
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-text v-else>
        <NotifAlphaTangoRequirements
          :recipient="$gettext('to Ministry of the Armed Forces')"
          :flightIsHSS="flightIsHSS"
          :missingData="missingData"
        />
      </v-card-text>
      <!-- Ignore agglo constraint -->
      <v-checkbox
        v-if="flightIsHSS"
        :label="$gettext('I consider my flight outside of agglomeration')"
        v-model="ignoreAgglomeration"
        color="primary"
        class="mx-4 mt-0"
        :disabled="hasMissingData"
        hide-details
      />
      <!-- Send HSS Notif -->
      <v-checkbox
        v-if="flightIsHSS && hssNotificationsAllowed"
        :label="$gettext('I want to notify a outside standard scenario flight')"
        v-model="sendHSSNotif"
        color="primary"
        class="mx-4 mt-0"
        :disabled="hasMissingData"
        hide-details
      />

      <v-card-actions class="py-3">
        <v-spacer />
        <v-btn
          class="mx-3"
          @click="$emit('close-form')"
        >
          <span v-translate>Cancel</span>
        </v-btn>
        <v-btn
          class="white--text mx-3"
          color="primary"
          :disabled="isConfirmButtonDisabled"
          :loading="sendingFlightNotifyRequest"
          @click="notifyFlightArmy()"
        >
          <span v-translate>Send</span>
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import CUSTOM_HANDLING_API_CODES from '@/main';

import { filterPhoneNumber } from '@/filters/phone';

import APIService from '@/services/api';

import { CONSTRAINTS_CATEGORY, MILITARY_CONSTRAINTS_CODES } from '@/settings';

import { GET_CONSTRAINTS_ANALYSIS_NS } from '@/store/flights';

import NotifAlphaTangoRequirements from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoRequirements.vue';

export default {
  name: 'NotifAlphaTangoArmyForm',
  components: { NotifAlphaTangoRequirements },
  props: {
    flight: Object,
    update: {
      type: Boolean,
      default: false,
    },
    notificationsToUpdate: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    const today = new Date();
    const { pilots } = this.$store.state.pilots;
    const connectedPilotId = this.$store.state.authentication.user.contact_id;
    const connectedPilot = pilots.find((p) => p.id === connectedPilotId);
    let mainPilot = this.flight.pilots.find((p) => p.id === connectedPilotId);
    if (!mainPilot) {
      mainPilot = this.flight.pilots.at(0);
    }
    const mainPilotId = mainPilot?.id || null;
    const requestData = {
      dateStart: new Date(this.flight.date_start) < today ? null : this.flight.date_start,
      dateEnd: new Date(this.flight.date_end) < today ? null : this.flight.date_end,
      additionalInfo: '',
      mainPilotId,
      otherPilots: '',
      lastName: connectedPilot ? connectedPilot.last_name : '',
      firstName: connectedPilot ? connectedPilot.first_name : '',
      occupation: connectedPilot ? connectedPilot.function : '',
    };
    return {
      notifInfo: {
        numberNotifications: undefined,
        notificationsPerZonePerDay: [],
        notificationDateStart: undefined,
        isMultizone: undefined,
        isBigZone: undefined,
      },
      notifInfoLoading: false,
      notifInfoNoticeError: false,
      notifInfoNoticeWarning: false,
      sendingFlightNotifyRequest: false,
      ignoreAgglomeration: false,
      sendHSSNotif: false,
      valid: true,
      requestData,
      dateRange: [requestData.dateStart, requestData.dateEnd],
      minDatePicker: format(today, 'yyyy-MM-dd'),
      datesMenu: false,
      today: format(new Date(), 'd MMMM yyyy', { locale: fr }),
      rules: {
        required: (value) => !!value || '',
        datesRequired: (value) => (!!value.split(' - ')[0] && !!value.split(' - ')[1]) || '',
        occupation: (value) => (
          value.length < 50 || this.$gettext('Max length of 50 characters exceeded')
        ),
      },
      connectedPilotId: this.$store.state.authentication.user.contact_id,
    };
  },
  computed: {
    dateRangeText() {
      const range = [this.requestData.dateStart, this.requestData.dateEnd];
      return range.join(' - ');
    },
    labels() {
      return {
        dateRange: this.$gettext('Dates range'),
        additionalInfo: this.$gettext('Additional information'),
        mainPilot: this.$gettext('Main pilot involved for the notification'),
        otherPilots: this.$gettext('Other pilots'),
        declarant: this.$gettext('Declarant involved for the notification'),
        lastName: this.$gettext('Declarant last name'),
        firstName: this.$gettext('Declarant first name'),
        occupation: this.$gettext('Declarant function'),
      };
    },
    placeholders() {
      return {
        additionalInfo: this.$gettext('Other information'),
        otherPilots: this.$gettext('Other pilots involved in the mission'),
        lastName: this.$gettext('Declarant last name'),
        firstName: this.$gettext('Declarant first name'),
        occupation: this.$gettext('Declarant function'),
      };
    },
    tooltips() {
      return {
        otherPilots: this.$gettext(`The contact details of the other telepilots taking part will be
          added to the useful information transmetted in the AlphaTango notification`),
      };
    },
    texts() {
      return {
        notifInfoIntro: this.$gettext(`By clicking on send, Clearance can schedule notifications
          to the Ministry of the Armed Forces for this mission, if you have declared Clearance as
          the delegate on AlphaTango.`),
        notifInfoConstraints: this.$gettextInterpolate(
          this.$ngettext(
            'Notification valid for the constraint: %{constraints}',
            'Notification valid for the constraints: %{constraints}',
            this.constraintsInvolved.length,
          ),
          { constraints: this.constraintsInvolvedJoined },
        ),
        cancelOutdatedNotification: this.$gettext(
          `Current outdated notifications will be cancelled and replaced.
          If they have already been sent they will be cancelled if it's still possible and the new
          ones will have to comply with the prior notice.`,
        ),
        notifInfoNumber: this.notifInfoNumberText,
        notifInfoSendDate: this.$ngettext(
          'The notification will probably be sent one day or two days before the flight date.',
          'Notifications are generally sent one day or two days before the flight day.',
          this.notifInfo.numberNotifications,
        ),
        notifInfoCancellation: this.$ngettext(
          'It will be possible to cancel the transmission of the unsent notification even after confirmation.',
          'It will be possible to cancel the transmission of unsent notifications even after confirmation.',
          this.notifInfo.numberNotifications,
        ),
        notifInfoFlightEdit: this.$gettext(`If you change your mission, you will need to cancel
          and then reactivate notifications.`),
        notifInfoNoticeWarning: this.$gettextInterpolate(
          this.$ngettext(
            'Only flight day scheduled at %{date} will be notified, as it respects the date notice.',
            'Only flight days scheduled from %{date} onwards will be notified, as they respect the date notice.',
            this.isSingleDayNotifications ? 1 : 2,
          ),
          { date: this.notifInfo.notificationDateStart },
        ),
        notifInfoNoticeError: this.$gettext(`None of the flight days in your date range meet
          AlphaTango's notice requirements.`),
        declarantAlert: this.$gettext(`I certify that the information provided is true and I
          undertake to carry out the flights under the conditions described.
          <br>I acknowledge that I have been informed that the email acknowledging receipt of this
          declaration doesn't mean that the flight is legally possible and that is my
          responsability to check this and obtain any additional agreements that may be required
          <br>I undertake to comply with all the regulatory provisions applicable to the planned
          operations, and in particular:
          <br>- comply with the requirements applicable to aircraft and to the conditions of their
          exploitation; in particular, not operate the aircraft in conditions where there would be
          a risk to other aircrafts or to persons and properties on the ground, including in the
          event of a probable breakdown
          <br>- respect the image, privacy and private property rights of others
          <br>I declare that I have taken out insurance to cover the risks associated with the
          planned operations.`),
      };
    },
    constraintsInvolved() {
      const constraintsCodes = ['Off_Sight'] + MILITARY_CONSTRAINTS_CODES;
      return this.impactingConstraints.filter(
        (c) => constraintsCodes.includes(c.code_type),
      ).map((c) => c.name);
    },
    constraintsInvolvedJoined() {
      const constraintsInvolved = [...this.constraintsInvolved];
      if (constraintsInvolved.length === 0) return '';
      if (constraintsInvolved.length === 1) return constraintsInvolved[0];

      const lastConstraint = constraintsInvolved.pop();
      return (
        `${constraintsInvolved.join(', ')} ${this.$gettext('and')} ${lastConstraint}`
      );
    },
    notifInfoNumberText() {
      if (this.notifInfoLoading) {
        return '';
      }
      let notifInfoNumberText;
      if (this.notifInfo.isMultizone) {
        if (this.notifInfo.isBigZone) {
          notifInfoNumberText = this.notifInfoForBigMultiZones;
        } else {
          notifInfoNumberText = this.notifInfoForSmallMultiZones;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.notifInfo.isBigZone) {
          notifInfoNumberText = this.notifInfoForBigSingleZone;
        } else {
          notifInfoNumberText = this.notifInfoForSmallSingleZone;
        }
      }
      return notifInfoNumberText;
    },
    isSingleDayNotifications() {
      if (!this.notifInfo.notificationsPerZonePerDay) {
        return undefined;
      }
      const totalNotificationsPerDay = this.notifInfo.notificationsPerZonePerDay
        .map((notificationPerZonePerDay) => notificationPerZonePerDay.number_notifs)
        .reduce((a, b) => a + b, 0);
      return this.notifInfo.numberNotifications === totalNotificationsPerDay;
    },
    notifInfoForSmallSingleZone() {
      if (this.notifInfo.numberNotifications === 1) {
        return this.$gettext(`A notification will be sent when the flight date notice required by
          AlphaTango is respected.`);
      }
      if (this.isSingleDayNotifications) {
        return this.$gettextInterpolate(
          this.$gettext(`%{n} notifications will be sent when the notice required by AlphaTango is
            respected to cover your hour range.`),
          { n: this.notifInfo.numberNotifications },
        );
      }
      return this.$gettextInterpolate(
        this.$gettext(`%{n} notifications will be sent (%{nPerDay} per day) as and when the notice
          required by AlphaTango is respected to cover your date range.`),
        {
          n: this.notifInfo.numberNotifications,
          nPerDay: this.notifInfo.notificationsPerZonePerDay[0]?.number_notifs,
        },
      );
    },
    notifInfoForBigSingleZone() {
      if (this.isSingleDayNotifications) {
        return this.$gettextInterpolate(
          this.$gettext(`Your mission area is too big to send a single notification so %{n}
            notifications will be sent when the notice required by AlphaTango is respected to cover
            the entire the area of your flight zone and hour range.`),
          { n: this.notifInfo.numberNotifications },
        );
      }
      return this.$gettextInterpolate(
        this.$gettext(`Your mission area is too big to send a single notification so %{n}
          notifications will be sent (%{nPerDay} per day) as and when the notice required by
          AlphaTango is respected to cover your date range and the entire area of your flight
          zone.`),
        {
          n: this.notifInfo.numberNotifications,
          nPerDay: this.notifInfo.notificationsPerZonePerDay[0]?.number_notifs,
        },
      );
    },
    notifInfoForSmallMultiZones() {
      if (this.isSingleDayNotifications) {
        return this.$gettextInterpolate(
          this.$gettext(`%{n} notifications will be sent (%{nPerZone} per zone) when the notice
            required by AlphaTango is respected to cover your %{nZones} flight areas and hour
            range.`),
          {
            n: this.notifInfo.numberNotifications,
            nPerZone: this.notifInfo.notificationsPerZonePerDay[0]?.number_notifs,
            nZones: this.notifInfo.notificationsPerZonePerDay.length,
          },
        );
      }
      return this.$gettextInterpolate(
        this.$gettext(`%{n} notifications will be sent (%{nPerDayPerZone} per day per zone) as and
          when the notice required by AlphaTango is respected to cover your date range and your
          %{nZones} flight areas.`),
        {
          n: this.notifInfo.numberNotifications,
          nPerDayPerZone: this.notifInfo.notificationsPerZonePerDay[0]?.number_notifs,
          nZones: this.notifInfo.notificationsPerZonePerDay.length,
        },
      );
    },
    notifInfoForBigMultiZones() {
      // Construct notif info start
      const infoStart = this.$gettextInterpolate(
        this.$gettext(`Areas of your flight zone are too big to send a single notification per
          zone, %{n} notifications will be the sent`),
        { n: this.notifInfo.numberNotifications },
      );
      // Construct notif info details
      let infoDetails = this.notifInfo.notificationsPerZonePerDay
        .map((notificationPerZonePerDay) => {
          if (this.isSingleDayNotifications) {
            return this.$gettextInterpolate(
              this.$gettext('%{nPerZone} for the zone %{nameZone}'),
              {
                nPerZone: notificationPerZonePerDay.number_notifs,
                nameZone: notificationPerZonePerDay.name,
              },
            );
          }
          return this.$gettextInterpolate(
            this.$gettext('%{nPerDayPerZone} per day for the zone %{nameZone}'),
            {
              nPerDayPerZone: notificationPerZonePerDay.number_notifs,
              nameZone: notificationPerZonePerDay.name,
            },
          );
        });
      const lastDetail = infoDetails.pop();
      infoDetails = (
        `${infoDetails.join(', ')} ${this.$gettext('and')} ${lastDetail}`
      );
      // Construct notif info end
      let infoEnd;
      if (this.isSingleDayNotifications) {
        infoEnd = this.$gettextInterpolate(
          this.$gettext(`This will be done when the notice required by AlphaTango is respected to
            cover your %{nZones} flight areas and your hour range.`),
          { nZones: this.notifInfo.notificationsPerZonePerDay.length },
        );
      } else {
        infoEnd = this.$gettextInterpolate(
          this.$gettext(`This will be done as and when the notice required by AlphaTango is
            respected to cover your date range and your %{nZones} flight areas.`),
          { nZones: this.notifInfo.notificationsPerZonePerDay.length },
        );
      }
      return `${infoStart}: ${infoDetails}. ${infoEnd}`;
    },
    hssNotificationsAllowed() {
      return this.$store.getters['authentication/hssNotificationsAllowed'];
    },
    exploitationAlphaTangoUids() {
      return this.$store.state.exploitants.exploitationDetails.alphatango_uids;
    },
    exploitationPilots() {
      return this.$store.getters['pilots/activePilots'];
    },
    flightHasPilots() {
      return this.flight.pilots.length > 0;
    },
    flightHasDrones() {
      return this.flight.drones.length > 0;
    },
    impactingConstraints() {
      return this.flight.constraintsAnalysis.filter(
        (c) => c.category === CONSTRAINTS_CATEGORY.IMPACTING,
      );
    },
    flightIsHSS() {
      return (
        (
          this.flight.people_gathering
          || this.impactingConstraints.find((c) => c.code_type === 'AGG') !== undefined
        )
        && this.impactingConstraints.find((c) => c.code_type === 'Off_Sight') !== undefined
      );
    },
    missingData() {
      return {
        missingATNumber: !this.exploitationAlphaTangoUids.length,
        missingDrone: !this.flightHasDrones,
        missingPilot: !this.flightHasPilots,
      };
    },
    hasMissingData() {
      return Object.values(this.missingData).includes(true);
    },
    canRequestNotifications() {
      return (
        !this.hasMissingData
        && (
          !this.flightIsHSS
          || this.ignoreAgglomeration
          || this.sendHSSNotif
        )
      );
    },
    requestPayload() {
      let { additionalInfo } = this.requestData;
      if (this.requestData.otherPilots) {
        additionalInfo += this.$gettextInterpolate(
          this.$gettext('\nOther pilots:\n%{ otherPilots }'),
          { otherPilots: this.requestData.otherPilots },
          true,
        );
      }
      return {
        date_start: this.requestData.dateStart,
        date_end: this.requestData.dateEnd,
        main_pilot_id: this.requestData.mainPilotId,
        additional_info: additionalInfo,
        declarant_last_name: this.requestData.lastName,
        declarant_first_name: this.requestData.firstName,
        declarant_occupation: this.requestData.occupation,
      };
    },
    isConfirmButtonDisabled() {
      return (
        !this.canRequestNotifications
        || this.sendingFlightNotifyRequest
        || !this.valid
        || this.notifInfoLoading
      );
    },
  },
  watch: {
    ignoreAgglomeration(value) {
      if (value && this.sendHSSNotif === value) {
        this.sendHSSNotif = !value;
      }
    },
    sendHSSNotif(value) {
      if (value && this.ignoreAgglomeration === value) {
        this.ignoreAgglomeration = !value;
      }
    },
    dateRange(range) {
      [this.requestData.dateStart, this.requestData.dateEnd] = range;
      if (this.requestData.dateStart > this.requestData.dateEnd) {
        [
          this.requestData.dateStart,
          this.requestData.dateEnd,
        ] = [
          this.requestData.dateEnd,
          this.requestData.dateStart,
        ];
      }
    },
    canRequestNotifications(newValue) {
      if (newValue) {
        this.getArmyNotifInfo();
        this.updateOtherPilots();
      }
    },
  },
  mounted() {
    if (this.canRequestNotifications) {
      this.getArmyNotifInfo();
      this.updateOtherPilots();
    }
  },
  methods: {
    getArmyNotifInfo() {
      this.notifInfoLoading = true;
      this.notifInfoNoticeError = false;
      this.notifInfoNoticeWarning = false;
      APIService.getArmyNotificationsInfo(
        this.flight.id,
        this.requestData.dateStart,
        this.requestData.dateEnd,
      )
        .then(({ data }) => {
          (
            {
              number_notifications: this.notifInfo.numberNotifications,
              notifications_per_zone_per_day: this.notifInfo.notificationsPerZonePerDay,
              is_multizone: this.notifInfo.isMultizone,
              is_big_zone: this.notifInfo.isBigZone,
            } = data
          );
          this.setNotifInfoDateStartAndWarning(data.notification_date_start);
        })
        .catch((error) => {
          if (error.response.data.code === CUSTOM_HANDLING_API_CODES.NO_NOTIFICATION) {
            this.notifInfoNoticeError = true;
            this.dateRange = [];
          }
        })
        .finally(() => {
          this.notifInfoLoading = false;
        });
    },
    setNotifInfoDateStartAndWarning(dateStartData) {
      let dateStart = new Date(dateStartData);
      // Handle timezones differences and only keep date
      dateStart.setMinutes(dateStart.getMinutes() + dateStart.getTimezoneOffset());
      [dateStart] = dateStart.toISOString().split('T');
      // Check if there is a notice warning
      if (dateStart !== this.requestData.dateStart) {
        this.notifInfoNoticeWarning = true;
        this.requestData.dateStart = dateStart;
        this.dateRange = [this.requestData.dateStart, this.requestData.dateEnd];
      }
      // Set date start in notif info
      this.notifInfo.notificationDateStart = dateStart;
    },
    recalculateNotifInfos() {
      this.$refs.menu.save(this.dateRange);
      this.datesMenu = false;
      this.getArmyNotifInfo();
    },
    notifyFlightArmy() {
      if (this.$refs.form.validate()) {
        this.sendingFlightNotifyRequest = true;
        let promise;
        if (this.update) {
          promise = APIService.updateArmyNotifs(
            this.flight.id,
            this.notificationsToUpdate.map((n) => n.id),
            this.requestPayload,
            this.ignoreAgglomeration,
          );
        } else {
          promise = APIService.notifyArmy(
            this.flight.id, this.requestPayload, this.ignoreAgglomeration,
          );
        }
        promise.then(({ data }) => {
          this.showMessage(data, 'success');
        })
          .finally(() => {
            this.sendingFlightNotifyRequest = false;
            this.$emit('close-form');
            this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id });
          });
      }
    },
    updateOtherPilots() {
      let otherPilots = '';
      this.flight.pilots.forEach((pilot) => {
        if (pilot.id !== this.requestData.mainPilotId) {
          const civility = pilot.gender === 'F' ? 'MME' : 'M';
          otherPilots += this.$gettextInterpolate(
            this.$gettext(`- Civility: %{civility}; Name: %{lastName}; First name: %{firstName};
            Phone number: %{phone}; Email: %{email}\n`),
            {
              civility,
              lastName: pilot.last_name,
              firstName: pilot.first_name,
              phone: filterPhoneNumber(pilot.phone_number),
              email: pilot.email,
            },
          );
        }
      });
      this.requestData.otherPilots = otherPilots;
    },
    updateDeclarant() {
      const declarant = this.exploitationPilots.find((p) => p.id === this.connectedPilotId);
      this.requestData.lastName = declarant.last_name;
      this.requestData.firstName = declarant.first_name;
      this.requestData.occupation = declarant.function;
    },
  },
};

</script>

<style
  lang="scss"
  scoped
>
.v-text-field ::v-deep .v-label {
  color: $color-info;
}
.declarant-alert {
  color: black;
  padding: 0.8em;
  font-size: 90%;
}
.declaration-date {
  float: right;
  font-style: oblique;
  color: $color-info;
}

</style>
