<template>
  <v-row no-gutters>
    <!-- Alert dialog -->
    <v-dialog
      v-model="alert"
      max-width="520"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Before take off</translate>
        </v-card-title>
        <v-card-text>
          <div
            v-if="loadingCheckCanTakeOff"
            class="d-flex flex-column align-center mt-2"
            key="loading"
          >
            <p v-translate>Checking for the presence of another drones...</p>
            <v-progress-circular
              indeterminate
              color="primary"
              :size="30"
              :width="2"
            />
          </div>
          <div
            v-else
            class="body-1"
            key="information"
          >
            <p v-if="insideUSpaceArea">
              {{ texts.insideUSpaceArea }}
            </p>
            <p v-if="constraintsBeforeTakeOff.length">
              <translate>As a reminder, here are the constraints on your flight:</translate>
            <ul
              v-for="(constraint, index) in constraintsBeforeTakeOff"
              :key="index"
            >
              <li>
                {{ constraint.name }}
              </li>
            </ul>
            </p>
            <p>
              {{ texts.authorizations }}
            </p>
            <v-alert
              v-if="planesProximity"
              color="warning"
              icon="mdi-alert-outline"
              outlined
              border="left"
              class="text-sm-body-2 mt-2 font-weight-medium"
            >
              <span v-translate>There are planes closed to your flight area.</span>
            </v-alert>
            <v-alert
              v-if="!canTakeOff"
              :color="insideUSpaceArea ? 'red' : 'warning'"
              :icon="insideUSpaceArea ? 'mdi-do-not-disturb' : 'mdi-alert-outline'"
              outlined
              border="left"
              class="text-sm-body-2 mt-2 font-weight-medium"
            >
              <translate>
                Warning: another drone is may be already flying in your area.
              </translate>
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            class="px-2"
            @click="$emit('close')"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            class="px-2"
            :disabled="!canTakeOff && insideUSpaceArea"
            @click="proceedToTakeOff()"
          >
            <v-icon
              color="primary"
              class="mr-2"
            >
              mdi-airplane-takeoff
            </v-icon>
            <span v-translate>TAKE OFF</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Chrono dialog -->
    <v-dialog
      v-model="chrono"
      persistent
      max-width="420"
    >
      <v-card tile>
        <v-card-title class="d-flex justify-center align-center">
          <div
            v-if="flightAuthorizations.length"
            class="mb-2"
          >
            <span v-translate>Flight authorization:</span>
            <span>
              {{ flightAuthorizationIdentifiersDisplay }}
            </span>
          </div>
          <span class="display-3 secondary--text pb-5">
            {{ stringDuration }}
          </span>
        </v-card-title>
        <v-card-actions class="d-flex flex-column justify-center align-center">
          <v-btn
            text
            key="land"
            class="mb-2 px-3"
            color="primary"
            :loading="landLoading"
            :disabled="loadingCheckCanTakeOff"
            @click="land({ closeFlight: false })"
          >
            <v-icon
              color="primary"
              class="mr-2"
            >
              mdi-airplane-landing
            </v-icon>
            <span v-translate>LAND</span>
          </v-btn>
          <v-btn
            text
            key="land-and-close"
            class="mb-5 mx-0"
            color="primary"
            :loading="landLoading"
            @click="land({ closeFlight: true })"
          >
            <v-icon
              color="primary"
              class="mr-2"
            >
              icon-archive
            </v-icon>
            <span v-translate>LAND AND CLOSE MISSION</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import APIService from '@/services/api';

import { SET_FLIGHT_LANDED_NS } from '@/store/flights';

export default {
  name: 'FlightEnRoute',
  props: {
    flight: {
      type: Object,
      required: true,
    },
    resume: Boolean,
    constraints: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loadingCheckCanTakeOff: false,
      canTakeOff: true,
      planesProximity: false,
      alert: true,
      chrono: false,
      running: false,
      started: null,
      startTime: null,
      landLoading: false,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
      flightAuthorizations: [],
    };
  },
  computed: {
    texts() {
      return {
        insideUSpaceArea: this.$gettext(`You are inside a U-Space area. You have to emit an
          electronic signal during all your flying time. An unique identifier will be given to you
          by clicking on 'Take off'.`),
        authorizations: this.$gettext(`Taking off, you certified that you have handled all
          constraints of your flight and you will respect specific instructions of differents
          authorities.`),
      };
    },
    stringDuration() {
      const hh = `${this.duration.h}`.padStart(2, '0');
      const mm = `${this.duration.m}`.padStart(2, '0');
      const ss = `${this.duration.s}`.padStart(2, '0');
      return `${hh}:${mm}:${ss}`;
    },
    constraintsBeforeTakeOff() {
      return this.constraints.filter((c) => c.code_type !== 'U-SPACE');
    },
    insideUSpaceArea() {
      return this.constraints.some((c) => c.code_type === 'U-SPACE');
    },
    flightAuthorizationIdentifiersDisplay() {
      return this.flightAuthorizations.map((f) => f.identifier).join(', ');
    },
  },
  async created() {
    if (this.resume) {
      this.alert = false;
      this.chrono = true;
      if (this.flight.effective_duration) {
        const [hh, mm, ss] = this.flight.effective_duration.split(':');
        this.startChrono({ hh, mm, ss });
      } else {
        const [hh, mm, ss] = this.flight.expected_duration.split(':');
        this.startChrono({ hh, mm, ss });
      }
      if (this.insideUSpaceArea) {
        await this.getFlightAuthorizations();
      }
    } else {
      this.loadingCheckCanTakeOff = true;
      this.alert = true;
      this.chrono = false;
      APIService.checkCanTakeOff(this.flight.id)
        .then(({ data }) => {
          this.canTakeOff = data.can_take_off;
          this.planesProximity = data.planes_proximity;
        })
        .finally(() => {
          this.loadingCheckCanTakeOff = false;
        });
    }
  },
  methods: {
    proceedToTakeOff() {
      this.alert = false;
      this.chrono = true;
      this.takeOff();
    },
    async takeOff() {
      this.startChrono();
      await APIService.takeOffFlight(this.flight.id);
      if (this.insideUSpaceArea) {
        await this.getFlightAuthorizations();
      }
    },
    land({ closeFlight = false }) {
      this.landLoading = true;
      APIService.landFlight(this.flight.id, this.stringDuration)
        .then(({ data }) => {
          this.$store.commit(SET_FLIGHT_LANDED_NS, { flight: data.flight });
          this.$emit('land-flight', { closeFlight });
          this.stopChrono();
          this.chrono = false;
        })
        .finally(() => {
          this.landLoading = false;
        });
    },
    startChrono(delay) {
      // Use delay to start chrono with a prior elapsed duration
      this.running = true;
      this.startTime = new Date();
      if (delay) {
        this.startTime.setUTCHours(this.startTime.getUTCHours() - delay.hh);
        this.startTime.setUTCMinutes(this.startTime.getUTCMinutes() - delay.mm);
        this.startTime.setUTCSeconds(this.startTime.getUTCSeconds() - delay.ss);
      }
      this.chronoStarted = setInterval(this.chronoRunning, 1000);
    },
    stopChrono() {
      clearInterval(this.chronoStarted);
    },
    chronoRunning() {
      const timeElapsed = new Date(new Date() - this.startTime);
      this.duration.h = timeElapsed.getUTCHours();
      this.duration.m = timeElapsed.getUTCMinutes();
      this.duration.s = timeElapsed.getUTCSeconds();
    },
    async getFlightAuthorizations() {
      await APIService.getFlightAuthorizations(this.flight.id)
        .then(({ data }) => {
          this.flightAuthorizations = data;
        });
    },
  },
};
</script>
