<template>
  <div>
    <div>
      <div class="headline mb-6">
        <translate>History of approvals</translate>
      </div>
      <!-- Tab approvals history -->
      <div>
        <span v-translate>Tab of approvals numbers history</span>
        <v-simple-table
          dense
          class="mt-2"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(history, index) in approvalsHistory"
                  :key="index"
                  class="text-center"
                >
                  {{ history.year }}
                </th>
                <th
                  class="text-center"
                  v-translate
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="(history, index) in approvalsHistory"
                  :key="index"
                  class="text-center"
                >
                  {{ history.total }}
                </td>
                <td class="text-center">
                  {{ totalApprovals }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- Google chart -->
      <div
        v-if="!loadingChart"
        class="mt-5"
      >
        <span
          v-show="chartReady"
          v-translate
        >
          Nombre de demandes d'accord durant les 12 derniers mois
        </span>
        <GChart
          v-show="chartReady"
          ref="gChart"
          :type="chart.type"
          :data="chart.data"
          :options="chart.options"
          @ready="onChartReady"
          :resizeDebounce="500"
        />
      </div>
      <div
        v-if="loadingChart && !chartReady"
        class="d-flex align-center justify-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
    </div>
    <!-- Exploitants listing -->
    <div class="mt-15">
      <div class="headline mb-4">
        <translate>Exploitants listing of</translate>
        {{ structure.long_name }}
      </div>
      <v-text-field
        v-model="q"
        :append-icon="q ? null : 'search'"
        :label="$gettext('Search exploitant name')"
        clearable
        single-line
        hide-details
        @keyup.enter="updateResult"
        @click:append="updateResult"
      />
      <v-data-table
        ref="datatable"
        :headers="headers"
        :items="exploitants"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        single-expand
        calculate-widths
        :loading-text="$gettext('Exploitants loading...')"
        :no-data-text="$gettext('No exploitants found.')"
        item-key="id"
        :no-results-text="$gettext('No exploitants found.')"
        :mobile-breakpoint="singleColumnBreakpoint"
        :footer-props="{
          'items-per-page-text': $gettext('Lines per page:'),
          'items-per-page-all-text': $gettext('All'),
          'items-per-page-options': [25, 50, 100, -1],
        }"
      >
        <template v-slot:[`item.last_approval_date`]="{ item }">
          <span>
            {{ item.last_approval_date | dateShort }}
          </span>
        </template>
        <template v-slot:[`item.is_approval_notice_period_bypass`]="{ item }">
          <v-chip
            v-if="item.custom_exploitant_structure_link.is_approval_notice_period_bypass"
            x-small
            label
            color="green"
            text-color="white"
            class="mr-2 px-1"
            key="yesBypass"
          >
            <translate>Yes</translate>
          </v-chip>
          <v-chip
            v-else
            label
            x-small
            color="primary"
            text-color="white"
            class="mr-2 px-1"
            key="noBypass"
          >
            <translate>No</translate>
          </v-chip>
        </template>
        <template v-slot:[`item.edeis_payment_handle_by_clearance`]="{ item }">
          <v-chip
            x-small
            label
            :color="item.edeis_payment_handle_by_clearance ? 'green' : 'primary'"
            text-color="white"
            class="mr-2 px-1"
            :key="item.edeis_payment_handle_by_clearance ? 'yesClearancePayment'
            : 'noClearancePayment'"
          >
            <translate v-if="item.edeis_payment_handle_by_clearance">Yes</translate>
            <translate v-else>No</translate>
          </v-chip>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <span>
            {{ item.note }}
          </span>
        </template>
        <template v-slot:[`item.exploitant_options`]="{ item }">
          <v-menu
            offset-y
            open-on-hover
            nudge-bottom="1"
            close-delay="75"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="ma-0 flex-shrink-1"
                large
                v-on="on"
              >
                <v-icon color="info">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <!-- Add bypass notice period -->
              <v-list-item
                v-if="hasBypassNoticePeriodButton(item.custom_exploitant_structure_link)"
                data-tour="bypassNoticePeriod"
                @click="openBypassNoticePeriodDialog(item)"
              >
                <v-icon>mdi-skip-next</v-icon>
                <v-list-item-title class="ml-3">
                  <translate key="bypassNoticePeriod">Bypass notice period</translate>
                </v-list-item-title>
              </v-list-item>
              <!-- Delete bypass notice period -->
              <v-list-item
                v-if="item.custom_exploitant_structure_link.is_approval_notice_period_bypass"
                data-tour="deleteBypassNoticePeriod"
                @click="openDeleteBypassNoticePeriodDialog(item)"
              >
                <v-icon>icon-bin_line</v-icon>
                <v-list-item-title class="ml-3">
                  <translate key="deleteBypassNoticePeriod">Delete bypass notice period</translate>
                </v-list-item-title>
              </v-list-item>
              <!-- Exploitant's note -->
              <v-list-item
                data-tour="exploitantNote"
                @click="openEditExploitantNoteDialog(item)"
              >
                <v-icon>mdi-note-outline</v-icon>
                <v-list-item-title class="ml-3">
                  <translate key="editExploitantNote">Edit exploitant's note</translate>
                </v-list-item-title>
              </v-list-item>
              <!-- Discount approvals -->
              <v-list-item
                v-if="structure.approval_payment_required"
                data-tour="approvalDiscount"
                @click="openDiscountDialog(item)"
              >
                <v-icon>mdi-percent</v-icon>
                <v-list-item-title class="ml-3">
                  <translate key="approvalDiscount">Apply/update discount for approvals</translate>
                </v-list-item-title>
              </v-list-item>
              <!-- Delete discount approvals -->
              <v-list-item
                v-if="structure.approval_payment_required && item.approval_discount !== '-'"
                data-tour="approvalDiscountDelete"
                @click="openDeleteDiscountDialog(item)"
              >
                <v-icon>icon-bin_line</v-icon>
                <v-list-item-title class="ml-3">
                  <translate key="approvalDiscountDelete">Delete the discount</translate>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }}
          <span v-translate>de</span>
          {{ items.itemsLength }}
        </template>
      </v-data-table>
    </div>
    <!-- Dialog discount approvals -->
    <v-dialog
      v-if="exploitantToEdit"
      v-model="showDiscountDialog"
      max-width="480"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span v-translate>Apply/update discount</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <span>
              {{ applyDiscountLabel }}
            </span>
            <v-text-field
              v-model="discount"
              :rules="rules.discount"
              class="pl-2"
              type="number"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDiscountDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="!valid || discount === 0"
            :loading="loadingApplyDiscount"
            @click="applyDiscount()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog delete discount approvals -->
    <v-dialog
      v-if="exploitantToEdit"
      v-model="showDeleteDiscountDialog"
      max-width="480"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span v-translate>Delete discount</span>
        </v-card-title>
        <v-card-text>
          <span>
            {{ deleteDiscountLabel }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDeleteDiscountDialog()"
          >
            <translate>No</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="deleteDiscount()"
            :loading="loadingDeleteDiscount"
          >
            <translate>Yes</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog delete bypass notice period -->
    <v-dialog
      v-if="exploitantToEdit"
      v-model="showDeleteBypassNoticePeriodeDialog"
      max-width="680"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <div>
            <span v-translate>Delete bypass notice period for</span>
            <span>
              {{ exploitantToEdit.commercial_name }}
            </span>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeBypassNoticePeriodDialog()"
          >
            <translate>No</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="callExploitantStructureInfosAPI()"
            :loading="loadingActionButtons"
          >
            <translate>Yes</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog bypass notice period -->
    <v-dialog
      v-if="exploitantToEdit"
      v-model="showBypassNoticePeriodeDialog"
      max-width="680"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <div>
            <span v-translate>Create a bypass for your notice period for</span>
            <span>
              {{ exploitantToEdit.commercial_name }}
            </span>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeBypassNoticePeriodDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="callExploitantStructureInfosAPI()"
            :loading="loadingActionButtons"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog note exploitant -->
    <v-dialog
      v-if="exploitantToEdit"
      v-model="showExploitantNoteDialog"
      max-width="680"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <div>
            <span v-translate>Create a note regarding</span>
            <span>
              {{ exploitantToEdit.commercial_name }}
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-textarea
            :placeholder="$gettext('Rédiger une note interne concernant l\'exploitant...')"
            rows="2"
            :value="exploitantToEdit.custom_exploitant_structure_link.note"
            v-model="noteExploitant"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeEditExploitantNoteDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="red"
            :loading="loadingActionButtons"
            @click="deleteExploitantNote()"
          >
            <translate>Delete</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="loadingActionButtons"
            @click="callExploitantStructureInfosAPI()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts';

import { debounce } from '@/services/api.helper';

import {
  GET_EXPLOITANTS_LIST_NS,
  RESET_EXPLOITANTS_LIST_NS,
  SET_FILTERS_NS,
  SET_PAGINATION_NS,
  UPDATE_PAGINATION_NS,
} from '@/store/exploitants';

import API from '@/services/api';

export default {
  name: 'ExploitantsStatistics',
  components: { GChart },
  props: {
    structure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$gettext('Exploitant'),
          value: 'commercial_name',
          width: '8%',
          class: 'pl-3',
        },
        {
          text: this.$gettext('Approvals number'),
          value: 'approvals_number',
          width: '8%',
          align: 'center',
        },
        {
          text: this.$gettext('Approvals number of this year'),
          value: 'this_year_approvals_number',
          width: '8%',
          align: 'center',
        },
        {
          text: this.$gettext('Last approval date'),
          value: 'last_approval_date',
          width: '8%',
          align: 'center',
        },
        {
          text: this.$gettext('Bypass notice period'),
          value: 'is_approval_notice_period_bypass',
          width: '8%',
          align: 'center',
        },
        {
          text: this.$gettext('Approval payment handle by Clearance'),
          value: 'edeis_payment_handle_by_clearance',
          width: '8%',
          align: 'center',
        },
        {
          text: this.$gettext("Exploitant's note"),
          value: 'custom_exploitant_structure_link.note',
          width: '14%',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          value: 'exploitant_options',
          width: '5%',
          sortable: false,
          align: 'center',
        },
      ],
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
      showBypassNoticePeriodeDialog: false,
      showDeleteBypassNoticePeriodeDialog: false,
      showExploitantNoteDialog: false,
      showDiscountDialog: false,
      showDeleteDiscountDialog: false,
      isApprovalNoticePeriodBypass: false,
      loadingActionButtons: false,
      noteExploitant: '',
      exploitantToEdit: null,
      discount: 0,
      valid: false,
      rules: {
        discount: [
          (v) => (v > 0 && v <= 100) || this.$gettext('Value between 0 and 100 is required'),
        ],
      },
      loadingChart: false,
      chart: {
        type: 'ColumnChart',
        data: [],
        options: {
          legend: 'none',
          pieSliceText: 'value',
        },
      },
      totalApprovals: null,
      chartReady: false,
      approvalsHistory: [],
      loadingApplyDiscount: false,
      loadingDeleteDiscount: false,
    };
  },
  computed: {
    exploitants() {
      return this.$store.getters['exploitants/tableItems'];
    },
    itemsLength() {
      return this.$store.state.exploitants.itemsLength;
    },
    loading() {
      return this.$store.state.exploitants.loadingExploitantsCollection;
    },
    options: {
      get() {
        return this.$store.state.exploitants.pagination;
      },
      set(newValue) {
        this.debouncedSetPagination({ that: this, newValue });
      },
    },
    q: {
      get() {
        return this.$store.state.exploitants.filters.q;
      },
      set(newValue) {
        this.resetToFirstPage();
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.exploitants.filters,
          q: newValue,
        });
      },
    },
    applyDiscountLabel() {
      if (this.exploitantToEdit) {
        return this.$gettextInterpolate(
          this.$gettext(`Discount percentage to apply for the next approvals created by
            %{ exploitantCommercialName }:`),
          { exploitantCommercialName: this.exploitantToEdit.commercial_name },
          true,
        );
      }
      return '';
    },
    deleteDiscountLabel() {
      if (this.exploitantToEdit) {
        return this.$gettextInterpolate(
          this.$gettext(`Delete the discount percentage for the next approvals created by
            %{ exploitantCommercialName }?`),
          { exploitantCommercialName: this.exploitantToEdit.commercial_name },
          true,
        );
      }
      return '';
    },
  },
  watch: {
    q() {
      this.debouncedUpdateResult({ that: this });
    },
  },
  created() {
    this.loadingChart = true;
    this.chartReady = false;
    this.$store.dispatch(RESET_EXPLOITANTS_LIST_NS);
    this.getApprovalsHistory();
    this.getGraphStructure();
    if (this.structure.approval_payment_required) {
      this.createDiscountHeader();
    }
  },
  methods: {
    debouncedSetPagination: debounce(
      ({ that, newValue }) => that.setPagination(newValue),
      1000, // debounce for 1s
    ),
    setPagination(newValue) {
      this.$store.dispatch(SET_PAGINATION_NS, {
        pagination: newValue,
        structureId: this.structure.id,
      });
    },
    debouncedUpdateResult: debounce(
      ({ that }) => that.updateResult(),
      1000, // debounce for 1s
    ),
    updateResult() {
      this.$store.dispatch(GET_EXPLOITANTS_LIST_NS, this.structure.id);
    },
    callExploitantStructureInfosAPI() {
      this.loadingActionButtons = true;
      if (!this.exploitantToEdit.custom_exploitant_structure_link) {
        this.createExploitantStructureInfos();
      } else {
        this.updateExploitantStructureInfos();
      }
    },
    resetToFirstPage() {
      this.$store.commit(UPDATE_PAGINATION_NS, {
        ...this.$store.state.exploitants.filters,
        page: 1,
      });
    },
    createDiscountHeader() {
      this.headers.splice(
        this.headers.length - 1,
        0,
        {
          text: this.$gettext('Discount percentage (%)'),
          value: 'approval_discount',
          width: '3%',
          align: 'center',
        },
      );
    },
    openBypassNoticePeriodDialog(exploitant) {
      this.exploitantToEdit = exploitant;
      this.isApprovalNoticePeriodBypass = true;
      this.showBypassNoticePeriodeDialog = true;
    },
    closeBypassNoticePeriodDialog() {
      this.showBypassNoticePeriodeDialog = false;
      this.isApprovalNoticePeriodBypass = false;
      this.exploitantToEdit = null;
    },
    openEditExploitantNoteDialog(exploitant) {
      this.exploitantToEdit = exploitant;
      this.noteExploitant = this.exploitantToEdit.custom_exploitant_structure_link.note;
      this.showExploitantNoteDialog = true;
    },
    closeEditExploitantNoteDialog() {
      this.showExploitantNoteDialog = false;
      this.noteExploitant = '';
      this.exploitantToEdit = null;
    },
    deleteExploitantNote() {
      this.noteExploitant = '';
      this.callExploitantStructureInfosAPI();
    },
    hasBypassNoticePeriodButton(customExploitantStructureLink) {
      return (!customExploitantStructureLink.is_approval_notice_period_bypass);
    },
    buildExploitantStructureInfos() {
      let note;
      let isApprovalNoticePeriodBypass;
      if (!this.exploitantToEdit.custom_exploitant_structure_link) {
        note = this.noteExploitant;
        isApprovalNoticePeriodBypass = this.isApprovalNoticePeriodBypass;
      } else if (this.showBypassNoticePeriodeDialog || this.showDeleteBypassNoticePeriodeDialog) {
        isApprovalNoticePeriodBypass = this.isApprovalNoticePeriodBypass;
        note = this.exploitantToEdit.custom_exploitant_structure_link.note;
      } else {
        isApprovalNoticePeriodBypass = (
          this.exploitantToEdit.custom_exploitant_structure_link.is_approval_notice_period_bypass
        );
        note = this.noteExploitant;
      }
      const payload = {
        is_approval_notice_period_bypass: isApprovalNoticePeriodBypass,
        note,
      };
      return payload;
    },
    async createExploitantStructureInfos() {
      const payload = this.buildExploitantStructureInfos();
      await API.createCustomExploitantStructureLink(
        this.exploitantToEdit.id, this.structure.id, payload,
      )
        .then(() => {
          this.updateResult();
        })
        .finally(() => {
          this.loadingActionButtons = false;
        });
      this.closeBypassNoticePeriodDialog();
      this.closeDeleteBypassNoticePeriodDialog();
      this.closeEditExploitantNoteDialog();
    },
    openDeleteBypassNoticePeriodDialog(exploitant) {
      this.exploitantToEdit = exploitant;
      this.isApprovalNoticePeriodBypass = false;
      this.showDeleteBypassNoticePeriodeDialog = true;
    },
    closeDeleteBypassNoticePeriodDialog() {
      this.showDeleteBypassNoticePeriodeDialog = false;
      this.isApprovalNoticePeriodBypass = true;
      this.exploitantToEdit = null;
    },
    async updateExploitantStructureInfos() {
      const payload = this.buildExploitantStructureInfos();
      await API.updateCustomExploitantStructureLink(
        this.exploitantToEdit.id, this.structure.id, payload,
      )
        .then(() => {
          this.updateResult();
        })
        .finally(() => {
          this.loadingActionButtons = false;
        });
      this.closeBypassNoticePeriodDialog();
      this.closeDeleteBypassNoticePeriodDialog();
      this.closeEditExploitantNoteDialog();
    },
    openDiscountDialog(exploitant) {
      this.exploitantToEdit = exploitant;
      this.discount = (
        this.exploitantToEdit.approval_discount !== '-'
          ? this.exploitantToEdit.approval_discount : 0
      );
      this.showDiscountDialog = true;
    },
    closeDiscountDialog() {
      this.showDiscountDialog = false;
      this.discount = 0;
      this.exploitantToEdit = null;
    },
    async applyDiscount() {
      this.loadingApplyDiscount = true;
      const method = this.exploitantToEdit.approval_discount !== '-' ? 'patch' : 'post';
      await API.applyApprovalDiscount(
        method, this.exploitantToEdit.id, this.structure.id, this.discount,
      )
        .then(() => {
          this.updateResult();
        })
        .finally(() => {
          this.loadingApplyDiscount = false;
        });
      this.closeDiscountDialog();
    },
    openDeleteDiscountDialog(exploitant) {
      this.exploitantToEdit = exploitant;
      this.showDeleteDiscountDialog = true;
    },
    closeDeleteDiscountDialog() {
      this.showDeleteDiscountDialog = false;
      this.exploitantToEdit = null;
    },
    async deleteDiscount() {
      this.loadingDeleteDiscount = true;
      await API.deleteApprovalDiscount(this.exploitantToEdit.id, this.structure.id)
        .then(() => {
          this.updateResult();
        })
        .finally(() => {
          this.loadingDeleteDiscount = false;
        });
      this.closeDeleteDiscountDialog();
    },
    toGoogleChartData: (header, body) => [header].concat(body),
    async getGraphStructure() {
      await API.getStructureGraph(this.structure.id)
        .then(({ data }) => {
          this.chart.data = this.toGoogleChartData(
            [this.$gettext('Month'), this.$gettext('Number of approvals this month')],
            data.approvals_months,
          );
        })
        .finally(() => {
          this.loadingChart = false;
        });
    },
    onChartReady(chart, google) {
      const data = google.visualization.arrayToDataTable(this.chart.data);
      const view = new google.visualization.DataView(data);
      view.setColumns(
        [0, 1, { calc: 'stringify', sourceColumn: 1, type: 'string', role: 'annotation' }],
      );
      chart.draw(view, this.chart.options);
      this.chart.data = view;
      this.chartReady = true;
    },
    async getApprovalsHistory() {
      await API.getApprovalsHistory(this.structure.id)
        .then(({ data }) => {
          this.approvalsHistory = data.approvals_history;
          this.totalApprovals = data.total;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

</style>
