<template>
  <div>
    <!-- Capability alert -->
    <template v-if="!loadingCapability">
      <!-- Capability failed -->
      <v-alert
        v-if="isErrorInFetchCapability"
        class="my-1 body-2 mb-2"
        type="error"
        outlined
        dense
      >
        {{ texts.notFoundDescription }}
      </v-alert>
      <!-- Structure not concerned  -->
      <v-alert
        v-else-if="notConcerned"
        class="my-1 body-2 mb-2"
        type="info"
        outlined
        dense
      >
        {{ texts.notConcernedDescription }}
      </v-alert>
      <!-- Request approval conditions errors  -->
      <v-alert
        v-for="(error, index) in errors"
        :key="index"
        class="my-1 body-2 mb-2"
        type="error"
        outlined
        dense
      >
        {{ error }}
      </v-alert>
    </template>
    <!-- Instruction -->
    <div class="mb-2">
      <div class="d-flex align-center">
        <v-icon color="info">mdi-lock-outline</v-icon>
        <span
          class="ml-2 font-weight-medium"
          v-translate
        >
          Instruction
        </span>
      </div>
      <div class="ml-8">
        <!-- Approval process description  -->
        <div>
          {{ texts.approvalDescription }}
        </div>
        <!-- Description for activations -->
        <div v-if="displayActivationText">
          {{ texts.activationDescription }}
        </div>
      </div>
    </div>
    <!-- Notice explained -->
    <div
      v-if="noticePeriod"
      class="mb-2"
    >
      <div class="d-flex align-center">
        <v-icon color="info">mdi-alarm</v-icon>
        <span
          class="ml-2 font-weight-medium"
          v-translate
        >
          Notice
        </span>
      </div>
      <div class="ml-8">
        {{ noticePeriod }}
      </div>
    </div>
    <!-- Tips -->
    <div
      v-if="isNearbyConstraint"
      class="mb-2"
    >
      <div class="d-flex align-center">
        <v-icon color="info">mdi-lightbulb-on-outline</v-icon>
        <span
          class="ml-2 font-weight-medium"
          v-translate
        >
          Tips
        </span>
      </div>
      <ul class="ml-2">
        <li>
          {{ texts.nearbyDisclaimer }}
        </li>
      </ul>
    </div>
    <!-- Clearance comment -->
    <ClearanceComment
      :constraint="constraint"
      :flightId="flight.id"
      class="mb-2"
    />
    <div
      class="cursor-pointer text-decoration-underline mb-2 d-inline"
      @click="expanded = !expanded"
      v-translate
    >
      Learn more
    </div>
    <!-- Extra description -->
    <div v-if="expanded">
      <!-- Reason -->
      <div class="mb-2">
        <div class="d-flex align-center">
          <v-icon color="info">mdi-information-variant</v-icon>
          <span
            class="ml-2 font-weight-medium"
            v-translate
          >
            Why this constraint
          </span>
        </div>
        <div class="ml-8">
          {{ texts.reason }}
        </div>
      </div>
      <!-- Flight Areas -->
      <div
        v-if="hasManyAreas && hasFlightAreas"
        class="mb-2"
      >
        <div class="d-flex align-center">
          <v-icon color="info">mdi-vector-square</v-icon>
          <span
            class="ml-2 font-weight-medium"
            v-translate
          >
            Impacted zones
          </span>
        </div>
        <ul class="ml-2">
          <li
            v-for="(flightAreaName, index) in getFlightAreaNames"
            :key="index"
          >
            {{ flightAreaName }}
          </li>
        </ul>
      </div>
      <!-- Additional Contacts -->
      <div
        v-if="contacts.length"
        class="mb-2"
      >
        <div class="d-flex align-center">
          <v-icon color="info">mdi-phone-outline</v-icon>
          <span
            class="ml-2 font-weight-medium"
            v-translate
          >
            Additional contacts (transmitted by U-SpaceKeeper)
          </span>
        </div>
        <ul class="ml-2">
          <li
            v-for="(contact, index) in contacts"
            :key="index"
          >
            {{ contact.label }} : {{ contact.value }}
          </li>
        </ul>
      </div>
      <!-- Additional Information -->
      <div
        v-if="information.length"
        class="mb-2"
      >
        <div class="d-flex align-center">
          <v-icon color="info">mdi-magnify-plus-outline</v-icon>
          <span
            class="ml-2 font-weight-medium"
            v-translate
          >
            Additional information (transmitted by U-SpaceKeeper)
          </span>
        </div>
        <ul class="ml-2">
          <li
            v-for="(extraInfo, index) in information"
            :key="index"
          >
            <div
              v-if="extraInfo.title"
              class="cursor-pointer text-decoration-underline primary--text d-inline"
              @click="extraInfoDialog = true; extraInfoContentDialog = extraInfo"
            >
              {{ extraInfo.title }}
            </div>
            <span
              v-else
              v-html="extraInfo.content"
            />
          </li>
        </ul>
      </div>
      <!-- Clearance Articles -->
      <div class="mb-2">
        <div class="d-flex align-center">
          <v-icon color="info">mdi-book-open-outline</v-icon>
          <span
            v-translate
            class="ml-2 font-weight-medium"
          >
            Clearance articles
          </span>
        </div>
        <ul class="ml-2">
          <li>
            <a
              target="_blank"
              href="https://clearance.aero/demande-daccord-aux-aeroports-u-space-keeper-via-clearance/"
              v-translate
            >
              How to request an approval in a U-SpaceKeeper airspace?
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Extra info dialog -->
    <v-dialog
      v-if="extraInfoContentDialog"
      v-model="extraInfoDialog"
      max-width="820px"
      @click:outside="extraInfoDialog = false; extraInfoContentDialog = null"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          {{ extraInfoContentDialog.title }}
        </v-card-title>
        <v-card-text class="pa-4">
          <div class="px-4 pb-2">
            <span v-html="extraInfoContentDialog.content" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CONSTRAINTS_CATEGORY } from '@/settings';

import { NOT_HANDLED } from '@/store/status';

import ClearanceComment from '@/components/Flights/ClearanceComment.vue';

export default {
  name: 'FlightConstraintUSKDescription',
  components: { ClearanceComment },
  props: {
    constraint: Object,
    flight: Object,
    capability: Object,
    isErrorInFetchCapability: Boolean,
    panelExpanded: Number,
    loadingCapability: Boolean,
  },
  data() {
    return {
      expanded: false,
      extraInfoDialog: false,
      extraInfoContentDialog: null,
    };
  },
  computed: {
    isNearbyConstraint() {
      return this.constraint.category === CONSTRAINTS_CATEGORY.NEARBY;
    },
    USKAnalysis() {
      return this.flight.USKConstraintsAnalysis.find(
        (a) => a.structure_ids.includes(this.constraint.structure_id),
      );
    },
    information() {
      return this.USKAnalysis?.information || [];
    },
    noticePeriod() {
      if (this.USKAnalysis?.approval_decision_min) {
        const date = new Date(this.USKAnalysis.approval_decision_min).toLocaleDateString(
          this.$store.getters['application/currentLanguage'],
        );
        return this.$gettextInterpolate(
          this.$gettext('Submit your request approval before: %{ date }.'),
          { date },
        );
      }
      return null;
    },
    contacts() {
      return this.USKAnalysis?.contacts || [];
    },
    errors() {
      if (this.constraint.status !== NOT_HANDLED || !this.USKAnalysis) return [];

      return this.USKAnalysis.errors;
    },
    notConcerned() {
      return !this.isErrorInFetchCapability && this.capability === null;
    },
    displayActivationText() {
      return this.capability?.activation.required;
    },
    hasManyAreas() {
      return this.flight.areas.length > 1;
    },
    hasFlightAreas() {
      return this.constraint.flight_area_ids.length;
    },
    getFlightAreaNames() {
      return this.flight.areas.filter(
        (flightArea) => this.constraint.flight_area_ids.includes(flightArea.id),
      ).map((flightArea) => flightArea.name);
    },
    texts() {
      return {
        reason: this.$gettextInterpolate(
          this.$gettext(`You flight is planned near a zone requiring an approval request. The
            authorities %{ authority } responsible for this zone use the platform U-SpaceKeeper.`),
          { authority: this.notConcerned ? '' : this.USKAnalysis.authority_name },
          true,
        ),
        notConcernedDescription: this.$gettext(`Considering the period and the specific conditions
          of this mission, the platform U-SpaceKeeper has indicated that it is not impacted by this
          constraint, and therefore does not require an approval request from the authorities
          responsible for this area.`),
        notFoundDescription: this.$gettext(`An error has occured when trying to get information
          from U-SpaceKeeper plateform. Please try later or contact our support if the problem
          persists.`),
        nearbyDisclaimer: this.$gettext(`Given the characteristics of your mission, Clearance
          analysis indicates that this flight does not require a specific authorization for this
          constraint. The U-SpaceKeeper platform can however receive an approval request if you
          consider it necessary.`),
        approvalDescription: this.$gettext(`You must submit an approval request
          to the authority responsible of this air zone by clicking on the "Submit an approval
          request" below.`),
        activationDescription: this.$gettext(`This approval request makes it possible
          to establish a protocol of agreement, which is not the authority's final agreement. You
          must submit an activation request, generally the day before each actual flight day, in
          order to obtain an agreement for the latter. See the information provided by the
          U-SpaceKeeper platform below for more details.`),
      };
    },
  },
  watch: {
    panelExpanded(newValue) {
      if (newValue === undefined) {
        this.expanded = false;
      }
    },
  },
};
</script>
