<template>
  <div
    class="d-flex flex-column"
    :class="`${fullHeight ? 'full-height-max' : ''} ${highlighted}`"
  >
    <div
      class="chat pa-2 flex-grow-1"
      :class="`${fullHeight ? '' : 'fixed-height'} ${highlighted}`"
      id="chat"
      ref="chat"
    >
      <!-- Discussion feed -->
      <v-row
        v-for="event in reversedMessagesList"
        :key="'chat-' + event.id"
        :justify="getJustifyOption(event)"
        no-gutters
      >
        <v-col
          cols="12"
          :sm="getSizeMessage(event)"
          class="pb-2"
        >
          <v-row
            :class="`chat__header__${getClass(event)}`"
            no-gutters
          >
            <v-col
              class="chat__header__datetime px-2 py-0"
              :key="'date-' + event.id"
            >
              <span
                v-if="event.is_user_event"
                class="chat__header__creator"
              >
                {{ event.creator }}
              </span>
              {{ getDateFromTimestamp(event.created_at) }}
              {{ getHourFromTimestamp(event.created_at) }}
            </v-col>
          </v-row>
          <v-row
            :class="getStyleDetailMessage(event)"
            class="rounded"
            no-gutters
          >
            <!-- Message -->
            <template v-if="event.user_group === 'authority_ussps'">
              <v-col>
                <div v-html="event.message" />
              </v-col>
            </template>
            <template v-else>
              <v-col class="multilines">
                {{ event.message }}
              </v-col>
            </template>
            <!-- Attached documents -->
            <template v-if="event.document">
              <v-col
                cols="12"
                class="pt-1 d-flex flex-wrap"
                :class="event.is_user_event ? 'justify-end' : 'justify-start'"
              >
                <v-tooltip
                  :disabled="!event.document.unavailable"
                  max-width="580"
                  right
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-chip
                        :class="`chat__documents__${getClass(event)}`"
                        class="ma-1"
                        :disabled="event.document.unavailable"
                        outlined
                      >
                        <v-icon
                          small
                          left
                        >
                          mdi-paperclip
                        </v-icon>
                        <span
                          class="caption chat__documents__name"
                          @click="downloadDocument(event.document, event.id)"
                        >
                          {{ event.document.name }}
                        </span>
                        <span
                          v-if="event.document.unavailable"
                          class="caption font-italic"
                        >
                          &nbsp;-&nbsp;
                          <span v-translate>
                            unavailable
                          </span>
                          <v-icon small>
                            mdi-information
                          </v-icon>
                        </span>
                      </v-chip>
                    </div>
                  </template>
                  <span>
                    {{ getUnavailableDocumentMessage(event.document) }}
                  </span>
                </v-tooltip>
              </v-col>
            </template>
            <!-- Viewed at check icon -->
            <template v-if="showEventViewedAt(event)">
              <v-col
                cols="12"
                class="d-flex justify-end"
              >
                <LayoutTooltip bottom>
                  <template
                    slot="activator"
                    slot-scope="{ on }"
                  >
                    <span v-on="on">
                      <v-icon
                        color="white"
                        small
                      >
                        <template v-if="event.viewed_at">
                          mdi-check-all
                        </template>
                        <template v-else>
                          mdi-check
                        </template>
                      </v-icon>
                    </span>
                  </template>
                  <template
                    v-if="event.viewed_at"
                    slot="tooltip-content"
                  >
                    <span>
                      {{ getEventViewedAtDatetime(event.viewed_at) }}
                    </span>
                  </template>
                  <template
                    slot="tooltip-content"
                    v-else
                  >
                    <span v-translate>Transmitted</span>
                  </template>
                </LayoutTooltip>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- Send new message -->
    <v-row
      v-if="!newMessageDisabled"
      class="pb-2 flex-grow-0"
      no-gutters
    >
      <v-col cols="12">
        <v-form v-model="valid">
          <div class="px-4">
            <v-divider />
            <div class="d-flex">
              <!-- Message text area -->
              <div class="flex-grow-1 pr-2">
                <v-textarea
                  light
                  hide-details
                  v-model="currentMessage"
                  :placeholder="$gettext('Rédiger un message...')"
                  :loading="sendMessageLoading"
                  :disabled="sendMessageLoading"
                  ref="chatTextArea"
                  class="pt-0"
                  rows="3"
                />
                <!-- Attached documents -->
                <div
                  v-if="allAttachedDocuments.length"
                  class="pt-2"
                >
                  <v-chip
                    v-for="(file, index) in allAttachedDocuments"
                    :key="index"
                    close-icon="mdi-close"
                    @click:close="removeFile(index, file.documentId)"
                    :disabled="sendMessageLoading"
                    class="ma-1"
                    :class="{'error--text': fileSizeError(file)}"
                    small
                    close
                  >
                    {{ file.name }}
                  </v-chip>
                </div>
                <span
                  v-if="attachedFilesErrors"
                  class="caption error--text font-italic pl-2"
                >
                  {{ attachedFilesErrors }}
                </span>
              </div>
              <div class="d-flex flex-column justify-space-between align-end">
                <!-- Send button -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      @click="sendMessage()"
                      :disabled="!valid || sendMessageLoading"
                      :loading="sendMessageLoading"
                    >
                      <v-icon
                        v-on="on"
                        :class="{'primary--text': chatTextAreaActive}"
                      >
                        icon-send
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-translate>Send message</span>
                </v-tooltip>
                <div>
                  <!-- Attach document button -->
                  <v-tooltip
                    v-if="!withoutNewMessageAttachment"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <!-- File input menu for dronist -->
                        <v-menu
                          v-if="isUserDronist"
                          dark
                          offset-y
                          open-on-hover
                          close-delay="75"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              large
                              v-on="on"
                            >
                              <v-icon size="28">
                                mdi-attachment mdi-rotate-270
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list light>
                            <v-list-item
                              data-tour="myDocuments"
                              @click="openMyDocumentsDialog()"
                            >
                              <v-list-item-title class="ml-3">
                                <v-icon class="pr-2">mdi-file</v-icon>
                                <translate key="my-documents">My documents</translate>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              data-tour="fromComputer"
                              @click="openFromComputerInput()"
                            >
                              <v-list-item-title class="ml-3">
                                <v-icon class="pr-2">mdi-folder-open</v-icon>
                                <translate key="from-computer">From computer</translate>
                                <v-file-input
                                  ref="attachFileInput"
                                  v-model="attachedFiles"
                                  multiple
                                  hide-input
                                  prepend-icon=""
                                  class="ma-0 pa-0"
                                />
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <!-- File input for authority and visitors -->
                        <v-file-input
                          v-else
                          ref="attachFileInput"
                          v-model="attachedFiles"
                          :rules="attachedFilesRules"
                          :disabled="sendMessageLoading"
                          class="attach_file_input pa-0 ma-0"
                          multiple
                          hide-input
                        />
                      </div>
                    </template>
                    <span v-translate>Attach document</span>
                  </v-tooltip>
                  <!-- Add saved message menu button -->
                  <template v-if="isUserAuthority && !isUserPrefecture">
                    <SavedMessagesMenu
                      isChatMessages
                      :text.sync="currentMessage"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <!-- My Documents  -->
    <v-dialog
      v-model="myDocumentsDialog"
      max-width="500px"
      :retain-focus="false"
      persistent
    >
      <MyDocuments
        @close-mydocuments="myDocumentsDialog=false"
        @choose-mydocuments="(documents) => attachMyDocuments(documents)"
      />
    </v-dialog>
  </div>
</template>

<script>
import API from '@/services/api';

import { USER_TYPES, EXTERNAL_OPERATORS } from '@/settings';

import MyDocuments from '@/components/MyDocuments/MyDocuments.vue';
import SavedMessagesMenu from '@/components/SavedMessagesMenu.vue';
import LayoutTooltip from '@/layouts/LayoutTooltip.vue';

const DOCUMENT_NOT_AVAILABLE_API_CODE = 'DOCUMENT_UNAVAILABLE';

export default {
  name: 'Chat',
  components: {
    MyDocuments,
    SavedMessagesMenu,
    LayoutTooltip,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    newMessageDisabled: {
      type: Boolean,
      default: false,
    },
    atSendMessage: {
      type: Function,
      default: async () => {},
    },
    withoutNewMessageAttachment: {
      type: Boolean,
      default: false,
    },
    maxAttachmentSize: {
      type: Number,
      required: false,
    },
    isExternalChat: {
      type: Boolean,
      default: false,
    },
    viewedAtEnabled: {
      type: Boolean,
      default: false,
    },
    atMarkMessagesAsViewed: {
      type: Function,
      default: async () => {},
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: true,
      messagesList: [],
      currentMessage: '',
      sendMessageLoading: false,
      chatTextAreaActive: false,
      attachedFiles: [],
      attachedFilesErrors: '',
      attachedFilesRules: [
        (files) => !files.find((f) => f.size > this.maxAttachmentSize * 1000000)
          || this.$gettextInterpolate(
            this.$gettext('File size should be less than %{ size } MB !'),
            { size: this.maxAttachmentSize },
          ),
      ],
      myDocumentsDialog: false,
      myDocuments: [],
    };
  },
  computed: {
    reversedMessagesList() {
      return this.messagesList.slice().reverse();
    },
    isUserDronist() {
      return this.$store.getters['authentication/isUserDronist'];
    },
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    allAttachedDocuments() {
      const allDocuments = this.myDocuments.concat(this.attachedFiles);
      return allDocuments.sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    userDronist() {
      return [USER_TYPES.SUBSCRIBERS, USER_TYPES.EXPLOITANTS, USER_TYPES.DRONIST_USSPS];
    },
    userAuthority() {
      return [USER_TYPES.AUTHORITIES, USER_TYPES.AUTHORITY_USSPS];
    },
    highlighted() {
      if (this.highlight) {
        return 'highlight';
      }
      return '';
    },
  },
  watch: {
    events(newMessages) {
      if (newMessages) {
        this.messagesList = newMessages.slice();
      }
    },
  },
  mounted() {
    if (this.events) {
      this.messagesList = this.events.slice();
      // Mark messages as viewed
      if (this.viewedAtEnabled) {
        const eventsViewedIds = this.events.filter(
          (event) => event.is_user_event && !event.is_mine && !event.viewed_at,
        ).map((event) => event.id);
        if (eventsViewedIds.length) {
          this.atMarkMessagesAsViewed(eventsViewedIds);
        }
      }
    }
    // Scroll chat down
    const chat = document.getElementById('chat');
    const scrollToBottom = () => {
      if (chat.scrollHeight === 0) {
        setTimeout(() => {
          chat.scrollTop = chat.scrollHeight;
        }, 100);
      } else {
        chat.scrollTop = chat.scrollHeight;
      }
    };
    const observer = new MutationObserver(scrollToBottom);
    const config = { childList: true };
    observer.observe(chat, config);
    // If new message enabled
    if (!this.newMessageDisabled) {
      // Change color of send icon if text area is active
      if (this.$refs.chatTextArea) {
        this.$watch(
          () => this.$refs.chatTextArea.isFocused,
          (val) => {
            this.chatTextAreaActive = val;
          },
        );
      }
      // Get File input errors
      if (this.$refs.attachFileInput) {
        this.$watch(
          () => this.$refs.attachFileInput.errorBucket,
          (errors) => {
            if (errors) {
              this.attachedFilesErrors = errors.join(', ');
            }
          },
        );
      }
    }
  },
  methods: {
    getSizeMessage(event) {
      return event.is_user_event ? 10 : 12;
    },
    getPaddingMessage(event) {
      return event.is_user_event ? 'pa-2' : 'px-2 py-0';
    },
    getStyleDetailMessage(event) {
      return `chat__details__${this.getClass(event)} ${this.getPaddingMessage(event)}`;
    },
    isMyMessage(event) {
      return event.is_mine;
    },
    getClass(event) {
      if (event.is_user_event) {
        if (this.isMyMessage(event)) {
          return 'me';
        }
        if (this.isExternalChat) {
          if (this.userDronist.includes(event.user_group)) {
            return 'dronist';
          }
          if (this.userAuthority.includes(event.user_group)) {
            return 'authority';
          }
        }
        return 'them';
      }
      return 'auto';
    },
    getJustifyOption(event) {
      if (this.isMyMessage(event)) {
        return 'end';
      }
      return 'start';
    },
    showEventViewedAt(event) {
      return this.viewedAtEnabled && event.is_user_event && event.is_mine;
    },
    getDateFromTimestamp(timestamp) {
      return new Date(timestamp).toLocaleDateString(this.$store.getters['application/currentLanguage']);
    },
    getHourFromTimestamp(timestamp) {
      return new Date(timestamp)
        .toLocaleTimeString(
          this.$store.getters['application/currentLanguage'],
          { hour: '2-digit', minute: '2-digit' },
        );
    },
    getEventViewedAtDatetime(timestamp) {
      return this.$gettextInterpolate(
        this.$gettext('Viewed on %{ date } at %{ time }'),
        {
          date: this.getDateFromTimestamp(timestamp),
          time: this.getHourFromTimestamp(timestamp),
        },
      );
    },
    getDocumentPlateform(documentOrigin) {
      if (documentOrigin.includes(EXTERNAL_OPERATORS.USK)) {
        return 'U-SpaceKeeper';
      }
      if (documentOrigin.includes(EXTERNAL_OPERATORS.DK)) {
        return 'DroneKeeper';
      }
      return '';
    },
    getUnavailableDocumentMessage(eventDocument) {
      const documentPlatform = this.getDocumentPlateform(eventDocument.origin);
      const documentPlatformDetails = (
        documentPlatform
          ? this.$gettextInterpolate(
            this.$gettext('on the platform %{ platform }'),
            { platform: documentPlatform },
          )
          : ''
      );
      return this.$gettextInterpolate(
        this.$gettext(`This document is no longer available %{ platformDetails }. This may be due
          to the deletion of the document or its replacement by a newer version sent later on.`),
        { platformDetails: documentPlatformDetails },
      );
    },
    fileSizeError(file) {
      return file.size > this.maxAttachmentSize * 1000000;
    },
    removeFile(index, id) {
      // File from my documents
      if (id) {
        for (let i = 0; i < this.myDocuments.length; i += 1) {
          if (this.myDocuments[i].documentId === id) {
            this.myDocuments.splice(i, 1);
            break;
          }
        }
      // File from computer
      } else {
        let nbAttachedFiles = 0;
        for (let i = 0; i < index; i += 1) {
          if (this.allAttachedDocuments[i].documentId === undefined) {
            nbAttachedFiles += 1;
          }
        }
        this.attachedFiles.splice(nbAttachedFiles, 1);
      }
    },
    downloadDocument(eventDocument, eventId) {
      API.downloadDocument(eventDocument.id, eventId)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', eventDocument.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(async (error) => {
          const response = JSON.parse(await error.response.data.text());
          if (response.code === DOCUMENT_NOT_AVAILABLE_API_CODE) {
            this.showMessage(this.getUnavailableDocumentMessage(eventDocument), 'error');
          } else {
            this.showMessage(response.detail, 'error');
          }
        });
    },
    openFromComputerInput() {
      this.attachedFiles = [];
      this.$refs.attachFileInput.$refs.input.click();
    },
    openMyDocumentsDialog() {
      this.myDocuments = [];
      this.myDocumentsDialog = true;
    },
    attachMyDocuments(myDocuments) {
      this.myDocuments = myDocuments;
    },
    sendMessage() {
      if (this.currentMessage !== '' || this.allAttachedDocuments.length) {
        this.sendMessageLoading = true;
        this.atSendMessage(this.currentMessage, this.attachedFiles, this.myDocuments)
          .then((events) => {
            this.currentMessage = '';
            this.attachedFiles = [];
            this.myDocuments = [];
            const nbDataSent = events.length - this.messagesList.length;
            for (let i = 0; i < nbDataSent; i += 1) {
              this.messagesList.unshift(events[nbDataSent - i - 1]);
            }
            this.$emit('messages-sent', events);
          })
          .finally(() => {
            this.sendMessageLoading = false;
          });
      } else {
        this.showMessage(this.$gettext("Don't send empty message."), 'error');
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.full-height-max {
  height: 100%;
  max-height: 100%;
}

.fixed-height {
  max-height: 380px;
}

.attach_file_input :v-deep .v-input__prepend-outer {
  margin: 0px;
}
.v-input__append-inner {
  color: $color-info;
}

</style>
