<template>
  <v-form
    v-model="valid"
    class="d-flex flex-column justify-space-between full-height"
  >
    <div>
      <v-row
        no-gutters
        align="center"
      >
        <v-col
          cols="12"
          class="mb-2"
        >
          {{ labels.fullName }}
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            dense
            v-model="fullName"
            :rules="rules.fullName"
            :placeholder="placeholders.fullName"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
      >
        <v-col
          cols="12"
          class="my-2"
        >
          {{ labels.card }}
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <!-- Stripe card element -->
            <div
              ref="card"
              class="py-2 card-field"
              :class="{ 'card-field-error': cardError }"
            />
          </v-row>
          <v-row no-gutters>
            <!-- Error messages for Stripe card element -->
            <div
              ref="card-errors"
              class="v-messages error--text px-2 mb-3"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="mt-4">
      <div class="d-flex py-1">
        {{ texts.cancel }}
      </div>
      <v-btn
        block
        color="primary"
        :loading="subscribeLoading"
        :disabled="!valid || !cardComplete"
        @click="subscribe()"
      >
        <translate>Subscribe</translate>
      </v-btn>
      <div class="caption pt-3">
        {{ texts.confirming }}
      </div>
    </div>
  </v-form>
</template>

<script>
import API from '@/services/api';

export default {
  name: 'CheckoutForm',
  props: {
    planCode: String,
    quantity: Number,
    expectedTotal: Number,
    promoCode: String,
    affiliateCode: String,
  },
  data() {
    return {
      stripe: undefined,
      card: undefined,
      valid: false,
      cardComplete: false,
      cardError: false,
      fullName: null,
      subscribeLoading: false,
      style: {
        base: { fontSize: '16px' },
        invalid: {
          iconColor: '#a2253e',
          color: '#a2253e',
        },
      },
      loadStripe: undefined,
    };
  },
  computed: {
    labels() {
      return {
        fullName: this.$gettext('Full name'),
        card: this.$gettext('Card'),
      };
    },
    placeholders() {
      return { fullName: this.$gettext('Card owner full name') };
    },
    texts() {
      return {
        cancel: this.$gettext(`You can cancel future renewals of your subscription at any time
          from your account settings. The subscription will then remain active until the end of
          the current period.`),
        confirming: this.$gettext(`By confirming your subscription, you authorise Clearance to
          debit your card for this and future payment and future payments in accordance with its
          terms.`),
      };
    },
    rules() {
      return { fullName: [(v) => !!v || this.$gettext('Required')] };
    },
  },
  created() {
    if (this.$store.getters['authentication/hasAcceptedCookieStripe']) {
      // eslint-disable-next-line global-require
      this.loadStripe = require('@stripe/stripe-js/pure').loadStripe;
    }
  },
  mounted() {
    this.setupCardElement();
  },
  methods: {
    subscribe() {
      this.subscribeLoading = true;
      this.createPaymentMethod()
        .then((result) => {
          if (result.error) {
            this.showMessage(result, 'error');
            this.subscribeLoading = false;
          } else {
            this.createSubscription(result.paymentMethod.id);
          }
        });
    },
    createPaymentMethod() {
      return this.stripe.createPaymentMethod(
        {
          type: 'card',
          card: this.card,
          billing_details: { name: this.fullName },
        },
      );
    },
    createSubscription(paymentMethodId) {
      return API.createSubscription({
        plan_code: this.planCode,
        quantity: this.quantity,
        promo_code: this.promoCode,
        payment_method_id: paymentMethodId,
        expected_total: this.expectedTotal,
        affiliate_code: this.affiliateCode,
      })
        .then((response) => response.data)
        // Add the addional details we need to the result
        .then((result) => ({
          subscription: result.subscription,
          checkoutId: result.checkout_id || null,
          paymentMethodId,
        }))
        // Handle additional authentication if needed
        .then(this.handleAdditionnalAuthentication)
        // requires_payment_method error (attaching card to a Customer succeeded but charge failed)
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        // Error occured
        .catch((e) => {
          const message = e.response?.data?.detail || e.error.message;
          this.displayCardError(message);
          this.subscribeLoading = false;
        });
    },
    async handleAdditionnalAuthentication({ subscription, paymentMethodId, checkoutId }) {
      if (subscription && subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription };
      }
      const paymentIntent = subscription.latest_invoice.payment_intent;
      if (paymentIntent.status === 'requires_action') {
        return this.stripe.confirmCardPayment(
          paymentIntent.client_secret,
          { payment_method: paymentMethodId },
        ).then((result) => {
          if (result.error) {
            throw result;
          } else if (result.paymentIntent.status === 'succeeded') {
            const intervalId = window.setInterval(() => {
              API.getCheckoutSessionStatus(checkoutId)
                .then((response) => {
                  if (response.data.status === 'success') {
                    window.clearInterval(intervalId);
                    this.onSubscriptionComplete({ awaitWebhook: false });
                  }
                });
            }, 1000);
            return { subscription, awaitWebhook: true };
          }
          const message = this.$gettext('An error has occured and payment was not completed');
          // eslint-disable-next-line no-throw-literal
          throw { error: { message } };
        });
      }
      // No customer action needed
      return { subscription };
    },
    handleRequiresPaymentMethod({ subscription, awaitWebhook = false }) {
      if (subscription && subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { awaitWebhook };
      }
      if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        // eslint-disable-next-line no-throw-literal
        throw { error: { message: this.$gettext('Your card was declined.') } };
      }
      return { awaitWebhook };
    },
    onSubscriptionComplete({ awaitWebhook = false }) {
      if (!awaitWebhook) {
        this.subscribeLoading = false;
        this.$router.push({ name: 'subscription-success' });
        this.$router.go(); // refresh
      }
    },
    displayCardError(message) {
      const cardErrors = this.$refs['card-errors'];
      cardErrors.textContent = message;
      if (message !== '') {
        this.cardError = true;
      }
    },
    async setupCardElement() {
      this.stripe = await this.loadStripe(process.env.VUE_APP_STRIPE_API_KEY);
      const elements = this.stripe.elements();
      this.card = elements.create(
        'card',
        {
          hidePostalCode: true,
          style: this.style,
        },
      );
      this.card.mount(this.$refs.card);
      this.card.on('change', (e) => {
        this.cardError = false;
        this.cardComplete = e.complete;
        if (e.error) {
          this.displayCardError(e.error.message);
        } else {
          this.displayCardError('');
        }
      });
    },
  },
};
</script>

<style
  lang='scss'
  scoped
>
.full-height {
  height: 100%;
}
.card-field {
  height: 40px;
  width: 100%;
  padding: 0px 12px;
  margin: 0px 0px 4px;
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, .42);
}

.card-field-error {
  border-width: 2px !important;
  border-color: $color-error  !important;
}

</style>
