<template>
  <div class="approval-categories-list">
    <div class="d-flex justify-end">
      <v-btn
        small
        depressed
        class="primary--text"
        @click="openDialogEdition()"
      >
        <span v-translate>+ Add new category</span>
      </v-btn>
    </div>
    <v-data-table
      ref="datatable"
      item-key="id"
      :headers="headers"
      :items="approvalCategories"
      :expanded="expanded"
      single-expand
      sort-by="name"
      :class="{transparent: false}"
      :no-data-text="$gettext('No category created')"
      hide-default-footer
      :items-per-page="-1"
    >
      <template
        slot="item"
        slot-scope="props"
      >
        <tr
          class="cursor-pointer"
          @click="selectCategory(props.item)"
          :class="getClass(props.item)"
        >
          <td>
            {{ props.item.name }}
          </td>
          <td>
            <v-btn
              icon
              @click.stop="openDialogEdition(props.item)"
            >
              <v-icon class="pr-3">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="openDialogDeletion(props.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="pa-2">
            <v-container class="pa-0">
              <div class="pa-2">
                <v-row no-gutters>
                  <!-- Extra requirements -->
                  <v-col cols="4">
                    <span class="details-card__label">
                      {{ labels.extraRequirements }}
                    </span>
                  </v-col>
                  <v-col cols="8">
                    <span v-if="item.extra_requirements">
                      {{ item.extra_requirements }}
                    </span>
                    <span
                      v-else
                      class="text--secondary font-italic"
                      v-translate
                    >
                      Not filled
                    </span>
                  </v-col>
                  <!-- ATS internal note -->
                  <v-col cols="4">
                    <span class="details-card__label">
                      {{ labels.atsInternalNote }}
                    </span>
                  </v-col>
                  <v-col cols="8">
                    <span v-if="item.ats_internal_note">
                      {{ item.ats_internal_note }}
                    </span>
                    <span
                      v-else
                      class="text--secondary font-italic"
                      v-translate
                    >
                      Not filled
                    </span>
                  </v-col>
                  <!-- Max flying height -->
                  <v-col cols="4">
                    <span class="details-card__label">
                      {{ labels.height }}
                    </span>
                  </v-col>
                  <v-col cols="8">
                    <span v-if="item.height">
                      {{ item.height }} m
                    </span>
                    <span
                      v-else
                      class="text--secondary font-italic"
                      v-translate
                    >
                      Not filled
                    </span>
                  </v-col>
                  <!-- Tactical contact required -->
                  <v-col cols="4">
                    <span class="details-card__label">
                      {{ labels.tacticalContactProtocol }}
                    </span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      v-if="item.tactical_contact_required"
                      v-translate
                      key="yesTactical"
                    >
                      Yes
                    </span>
                    <span
                      v-else
                      v-translate
                      key="noTactical"
                    >
                      No
                    </span>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </div>
        </td>
      </template>
    </v-data-table>
    <!-- Creation/edition dialog -->
    <v-dialog
      v-if="dialogEdition"
      v-model="dialogEdition"
      max-width="1000px"
      :retain-focus="false"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span
            v-if="categoryToEdit.oldName.length"
            v-translate="{name: categoryToEdit.oldName}"
            key="updateCategory"
          >
            Edit category '%{ name }'
          </span>
          <span
            v-else
            v-translate
            key="createCategory"
          >
            Create category
          </span>
        </v-card-title>
        <v-card-text>
          <p v-if="!categoryToEdit.oldName.length">
            {{ texts.creation }}
          </p>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="px-2"
          >
            <!-- Name -->
            <v-text-field
              :label="labels.name"
              v-model="categoryToEdit.name"
            />
            <!-- Extra requirements -->
            <v-textarea
              :label="labels.extraRequirements"
              v-model="categoryToEdit.extra_requirements"
              rows="3"
            />
            <!-- ATS internal note -->
            <v-textarea
              :label="labels.atsInternalNote"
              v-model="categoryToEdit.ats_internal_note"
              rows="3"
            />
            <!-- Height -->
            <v-text-field
              :label="labels.height"
              v-model="categoryToEdit.height"
              type="number"
              suffix="m"
              :rules="[rules.integer]"
            />
            <!-- Tactical contact -->
            <div class="d-flex align-center">
              <v-checkbox
                :label="labels.tacticalContactProtocol"
                v-model="categoryToEdit.tactical_contact_required"
                color="primary"
              />
              <v-tooltip
                bottom
                color="info"
                max-width="680"
                open-on-hover
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-icon class="small-icon ml-2 cursor-pointer">mdi-information-outline</v-icon>
                  </span>
                </template>
                <div>
                  {{ texts.tacticalContactTooltip }}
                </div>
              </v-tooltip>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDialogEdition()"
          >
            <span v-translate>Cancel</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="!valid"
            @click="editCategory()"
          >
            <span v-translate>Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Deletion dialog -->
    <v-dialog
      v-if="dialogDeletion"
      v-model="dialogDeletion"
      max-width="500px"
      :retain-focus="false"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title v-translate="{name: categoryToEdit.name}">
          Delete category '%{ name }'
        </v-card-title>
        <v-card-text>
          <p v-translate>Are you sure you want to delete this category?</p>
          <p>
            {{ texts.deletion }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDialogDeletion()"
          >
            <span v-translate>Cancel</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="deleteCategory()"
          >
            <span v-translate>Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ApprovalCategoriesTable',
  props: { approvalCategories: Array },
  data() {
    return {
      headers: [
        { text: this.$gettext('Category'), value: 'name', width: '80%' },
        { value: 'actions', sortable: false, width: '10%' },
      ],
      expanded: [],
      valid: true,
      dialogEdition: false,
      categoryToEdit: null,
      dialogDeletion: false,
      rules: {
        integer: (value) => (
          value ? value > 0 || this.$gettext('A positive integer is required') : true
        ),
      },
    };
  },
  computed: {
    labels() {
      return {
        name: this.$gettext('Category name'),
        extraRequirements: this.$gettext('Particular requirements'),
        height: this.$gettext('Max flying height'),
        tacticalContactProtocol: this.$gettext('Tactical contact required'),
        atsInternalNote: this.$gettext('Note for internal use by air traffic control'),
      };
    },
    texts() {
      return {
        creation: this.$gettext(`This category will appear in approval restriction decision dialog.
          You can select it to fill automatically all fields of this form. The category name will
          appear in approvals listing.`),
        deletion: this.$gettext(`Delete a category will only archive the category. The name will be
          not available longer. The process and linked will stay on approvals already processed.`),
        tacticalContactTooltip: this.$gettext(`If you check this box and if the field 'Tactical
          contact protocol' above in the form is filled, it will be automatically added in the
          message on category selection in the decision dialog.`),
      };
    },
  },
  methods: {
    selectCategory(category) {
      if (this.expanded.at(0)?.name === category.name) {
        this.expanded = [];
      } else {
        this.expanded = [category];
      }
    },
    getClass(category) {
      if (this.expanded.at(0)?.name === category.name) {
        return 'selected';
      }
      return '';
    },
    findCategoryIndex(categoryName) {
      return this.approvalCategories.findIndex((c) => c.name === categoryName);
    },
    openDialogEdition(category = null) {
      if (category) this.categoryToEdit = JSON.parse(JSON.stringify(category));
      else {
        this.categoryToEdit = {
          name: '',
          extra_requirements: '',
          ats_internal_note: '',
          height: null,
          tactical_contact_required: false,
        };
      }
      this.categoryToEdit.oldName = this.categoryToEdit.name;
      this.dialogEdition = true;
      this.expanded = [];
    },
    closeDialogEdition() {
      this.dialogEdition = false;
      this.categoryToEdit = null;
      this.$forceUpdate();
    },
    editCategory() {
      if (!this.$refs.form.validate()) return;
      if (this.categoryToEdit.oldName.length) {
        const index = this.findCategoryIndex(this.categoryToEdit.oldName);
        this.$set(this.approvalCategories, index, this.categoryToEdit);
      } else this.approvalCategories.push(this.categoryToEdit);
      this.$emit('update:approvalCategories', this.approvalCategories);
      this.closeDialogEdition();
    },
    openDialogDeletion(category) {
      this.categoryToEdit = category;
      this.dialogDeletion = true;
    },
    closeDialogDeletion() {
      this.dialogDeletion = false;
      this.categoryToEdit = null;
    },
    deleteCategory() {
      const index = this.findCategoryIndex(this.categoryToEdit.name);
      this.approvalCategories.splice(index, 1);
      this.$emit('update:approvalCategories', this.approvalCategories);
      this.closeDialogDeletion();
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.approval-categories-list ::v-deep .v-data-table__wrapper>table>tbody>tr:not(:last-child).selected {
  >td {
    border-bottom: 0;
    background-color: lighten($color: $color-info, $amount: 70);
  }
  +td {
    border-bottom: thin solid rgba(0, 0, 0, .12);
    background-color: lighten($color: $color-info, $amount: 70);
  }
}

</style>
