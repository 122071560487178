<template>
  <LayoutListingMap>
    <template
      slot="listing"
      slot-scope="{display}"
    >
      <v-tabs
        v-model="active"
        color="white"
        background-color="secondary"
        dark
        grow
        slider-color="primary"
        class="default--tabs"
      >
        <v-tab
          href="#tab-users"
          ripple
          @click="openUsersTab()"
        >
          <translate>Users</translate>
          ({{ users.totalItems }})
        </v-tab>
        <v-tab-item
          value="tab-users"
          style="{position: relative}"
          class="px-3 pb-3"
        >
          <UserListing :display="display" />
        </v-tab-item>
      </v-tabs>
      <v-row
        v-if="versionProjects.length"
        no-gutters
        justify="space-between"
        class="flex-wrap-1"
      >
        <v-col
          v-for="(versionProject, index) in versionProjects"
          :key="index"
          cols="5"
          class="ma-2"
        >
          <v-card class="pa-4">
            <translate
              :translate-params="{ project: stringToLowerCase(versionProject.project_display) }"
              class="font-weight-bold"
            >
              Last commit of %{project}:
            </translate>
            <br>
            <span v-translate>Date:</span>
            <span>
              {{ versionProject.commit_date | toLocaleDatetime }}
            </span>
            <br>
            <span v-translate>Name:</span>
            <span
              v-html="versionProject.commit_name"
              class="ml-1"
            />
          </v-card>
        </v-col>
      </v-row>
    </template>
    <MapboxComponent
      slot="map"
      slot-scope="{display, map_status}"
      :display="display"
      :map_status="map_status"
      :area-to-edit="areaToEdit"
      :area-to-zoom="areaToZoom"
      @reset-area-to-zoom="resetAreaToZoom()"
      @update-area="(area) => currentArea=area"
    />
  </LayoutListingMap>
</template>

<script>
import APIService from '@/services/api';

import LayoutListingMap from '@/layouts/LayoutListingMap.vue';

import { APPLICATION_STATUS, SET_STATUS_NS } from '@/store/application';
import { MAP_STATUS, SET_MAP_STATUS_NS } from '@/store/map';
import { GET_USERS_NS } from '@/store/users';

import MapboxComponent from '@/components/Map/Map.vue';
import UserListing from '@/components/Users/UserListing.vue';

export default {
  name: 'MapUsers',
  components: {
    LayoutListingMap,
    MapboxComponent,
    UserListing,
  },
  data() {
    return {
      active: '',
      mapState: 'approvals',
      currentArea: null,
      areaToEdit: null,
      areaToZoom: null,
      versionProjects: [],
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.name'(newVal) {
      this.changeTab(newVal);
    },
  },
  mounted() {
    this.changeTab(this.$route.name);
    this.fetchUsers();
    this.fetchVersionProjects();
  },
  methods: {
    fetchUsers() {
      this.$store.dispatch(GET_USERS_NS);
    },
    fetchVersionProjects() {
      APIService.versionProjects()
        .then(({ data }) => {
          this.versionProjects = data;
        });
    },
    openUsersTab() {
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
      this.$router.push('/map/users');
    },
    changeTab(tabName) {
      if (tabName === 'users') {
        this.mapState = tabName;
      }
      switch (tabName) {
        case 'users':
          this.active = `tab-${tabName}`;
          break;
        default:
      }
    },
    resetAreaToZoom() {
      this.areaToZoom = null;
    },
    stringToLowerCase(string) {
      if (string) {
        return string.toLowerCase();
      }
      return string;
    },
  },
};

</script>
