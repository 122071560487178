<template>
  <v-expansion-panels
    class="external-information"
    multiple
    focusable
  >
    <v-expansion-panel
      v-for="(info, index) in information"
      :key="index"
    >
      <v-expansion-panel-header
        class="d-flex font-weight-medium info--text body-2"
        color="grey lighten-3"
      >
        <span
          v-if="info.title"
          class="capitalize"
        >
          {{ info.title }}
        </span>
        <span
          v-else
          v-translate
        >
          Conditions and requirements
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        class="px-6 py-3"
        color="grey lighten-3"
      >
        <span
          v-html="info.content"
          class="hr-style"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  name: 'FlightConstraintExternalInformation',
  props: { information: { type: Array } },
};
</script>

<style
  lang="scss"
  scoped
>
.external-information {
  ::v-deep .v-expansion-panel::before {
    box-shadow: none;
  }
  :first-child {
    ::v-deep .v-expansion-panel-header {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
    ::v-deep .v-expansion-panel-header--active {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
  }
  :last-child {
    ::v-deep .v-expansion-panel-header {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    ::v-deep .v-expansion-panel-header--active {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  ::v-deep .v-expansion-panel-header,
  ::v-deep .v-expansion-panel-header--active {
    min-height: 40px;
  }
  ::v-deep .v-expansion-panel-content {
    border-radius: 0px 0px 4px 4px;
    .v-expansion-panel-content__wrap {
      padding: 0px;
    }
  }
}
.capitalize:first-letter {
  text-transform: uppercase;
}
.hr-style {
  ::v-deep hr {
    border-style: inset;
  }
}

</style>
