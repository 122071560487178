export default function makeFlightListingHeaders(gettext) {
  const statusHeader = {
    text: gettext('Statut'),
    value: 'status',
    class: 'px-0',
  };
  const idHeader = {
    text: gettext('N°'),
    value: 'id',
    class: 'pl-0',
  };
  const nameHeader = {
    text: gettext('Mission'),
    value: 'name',
    class: 'pl-0',
  };
  const periodHeader = {
    text: gettext('Period'),
    value: 'date_start',
    class: 'pl-0',
  };
  const heightHeader = {
    text: gettext('Height'),
    value: 'flying_height',
    class: 'pl-0',
    align: 'center',
  };
  const hourHeader = {
    text: gettext('Hour'),
    value: 'time_start',
    sortable: false,
    class: 'pl-0',
  };
  const actionsHeader = {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'end',
  };

  return {
    smallHeaders: [
      { ...statusHeader, width: '20%' },
      { ...idHeader, width: '80%' },
    ],
    mobileHeaders: [
      { ...statusHeader, width: '10%' },
      { ...idHeader, width: '10%' },
      { ...nameHeader, width: '80%' },
    ],
    mediumHeaders: [
      { ...statusHeader, width: '7%' },
      { ...idHeader, width: '9%' },
      { ...nameHeader, width: '58%' },
      { ...periodHeader, width: '22%' },
      { ...actionsHeader, width: '4%' },
    ],
    bigHeaders: [
      { ...statusHeader, width: '5%' },
      { ...idHeader, width: '6%' },
      { ...nameHeader, width: '50%' },
      { ...periodHeader, width: '16%' },
      { ...heightHeader, width: '8%' },
      { ...hourHeader, width: '12%' },
      { ...actionsHeader, width: '1%' },
    ],
  };
}
