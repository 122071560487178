import APIService from '@/services/api';

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

import { MAPBOX_TOKEN } from '@/settings';

mapboxgl.accessToken = MAPBOX_TOKEN;

const DMS_PATTERN = /^[ ]*(?:Lat: )?(\d+\.?\d*°\d+\.?\d*'\d+\.?\d*''[ENSWO]?)[. ]*(?:Lng: )?(\d+\.?\d*°\d+\.?\d*'\d+\.?\d*''[ENSWO]?)[ ]*$/i;
const DECIMAL_PATTERN = /^[ ]*(?:Lat: )?(-?\d+\.?\d*[ENSWO]?)[. ]+(?:Lng: )?(-?\d+\.?\d*[ENSWO]?)[ ]*$/i;
const RAW_DEGREE_PATTERN = /^(\d+\.?\d*)°(\d+\.?\d*)'(\d+\.?\d*)''([ENSWO]?)$/i;
/* given a query in the form "lng, lat" or "lat, lng" returns the matching
* geographic coordinate(s) as search results in carmen geojson format,
* https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
*/
const coordinatesGeocoder = (query) => {
  function parseDegreesWithDirection(rawDegrees) {
    // 4.5335N -> {"value": 4.5335, "coordinate": "latitude"}
    // 4.5335 -> {"value": 4.5335, "coordinate": "unknown"}
    // 104.5335 -> {"value": 104.5335, "coordinate": "longitude"} (since > 90)
    // -104.5335N -> {"value": -104.5335, "coordinate": "longitude"} (since < -90)

    const direction = rawDegrees[rawDegrees.length - 1];
    let value = null;
    // No direction provided, trying to guess based on the value
    if (['N', 'S', 'W', 'E', 'O'].includes(direction) === false) {
      let coordinate = 'unknown';
      if (Number(rawDegrees) > 90 || Number(rawDegrees) < -90) { coordinate = 'longitude'; }
      return {
        value: Number(Number(rawDegrees).toFixed(5)),
        coordinate,
      };
    }
    value = Number(Number(rawDegrees.substring(0, rawDegrees.length - 1)).toFixed(5));
    if (direction === 'N') {
      return { value, coordinate: 'latitude' };
    }
    if (direction === 'S') {
      return { value: Math.min(value, value * (-1)), coordinate: 'latitude' };
    }
    if (direction === 'W') {
      return { value: Math.min(value, value * (-1)), coordinate: 'longitude' };
    }
    if (direction === 'O') {
      return { value: Math.min(value, value * (-1)), coordinate: 'longitude' };
    }
    if (direction === 'E') {
      return { value, coordinate: 'longitude' };
    }
    return null;
  }

  function convertTodecimal(rawDegrees) {
    const parts = rawDegrees.match(RAW_DEGREE_PATTERN);
    if (parts.length < 5) {
      return null;
    }
    const value = Number(parts[1]) + (Number(parts[2]) / 60) + (Number(parts[3]) / 3600);
    return parseDegreesWithDirection(value + parts[4]);
  }

  function coordinateFeature(lng, lat) {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: `Lat: ${lat}, Long: ${lng}`, // eslint-disable-line camelcase
      place_type: ['coordinate'], // eslint-disable-line camelcase
      properties: {},
      type: 'Feature',
    };
  }

  const geocodes = [];
  let degrees1 = null;
  let degrees2 = null;

  // Prepare query by 'correcting' some characters
  const sanitizedQuery = query
    .replace(/,/g, '.')
    .replace(/"/g, "''")
    .replace(/’/g, "'");

  // match anything which looks like a decimal degrees coordinate pair.
  // Two formats accepted : decimal, or with degrees / minutes / seconds
  // Case degrees / minutes / seconds (2°32'4.23'') or decimal (2.54)
  const matchesMinutesSeconds = sanitizedQuery.match(DMS_PATTERN);
  const matchesDecimal = sanitizedQuery.match(DECIMAL_PATTERN);
  if (matchesMinutesSeconds) {
    degrees1 = convertTodecimal(matchesMinutesSeconds[1]);
    degrees2 = convertTodecimal(matchesMinutesSeconds[2]);
  } else if (matchesDecimal) {
    degrees1 = parseDegreesWithDirection(matchesDecimal[1]);
    degrees2 = parseDegreesWithDirection(matchesDecimal[2]);
  }

  if (!degrees1 || !degrees2) { return null; }

  if (degrees1.coordinate === 'longitude' || degrees2.coordinate === 'latitude') {
    geocodes.push(coordinateFeature(degrees1.value, degrees2.value));
  } else if (degrees1.coordinate === 'latitude' || degrees2.coordinate === 'longitude') {
    geocodes.push(coordinateFeature(degrees2.value, degrees1.value));
  } else {
    geocodes.push(coordinateFeature(degrees2.value, degrees1.value));
    geocodes.push(coordinateFeature(degrees1.value, degrees2.value));
  }

  return geocodes;
};

// to store last query and last result features to avoid asynchronous queries conflicts
let _lastQuery = '';
let _lastExternalGeocoderFeatures = [];
// to store getter function used to check if user is allowed to use external geocoder
let _checkClientStructuresGeocodingAllowed;

const clientStructuresGeocoder = async (initialQuery) => {
  if (!_checkClientStructuresGeocodingAllowed()) {
    return new Promise((resolve) => resolve([]));
  }

  const query = initialQuery.replace('🔲 ', '').replace('➖ ', '');
  _lastQuery = query;

  if (!query || query.length < 3) {
    return new Promise((resolve) => resolve([]));
  }

  const loadingIcon = document.getElementsByClassName('mapboxgl-ctrl-geocoder--icon-loading')[0];
  loadingIcon.style.display = 'block';
  return APIService.clientStructuresGeocoding({ code: query })
    .then(({ data }) => {
      if (query === _lastQuery) {
        const externalFeatures = data.map((result) => ({
          type: 'Feature',
          center: result.marker_position.coordinates,
          place_name: result.type === 'site' ? `🔲 ${result.code}` : `➖ ${result.code}`,
          place_type: ['locality'],
          bbox: turf.bbox(result.envelope),
        }));
        _lastExternalGeocoderFeatures = externalFeatures;
        return externalFeatures;
      }
      return _lastExternalGeocoderFeatures;
    })
    .catch(() => [])
    .finally(() => {
      loadingIcon.style.display = 'none';
    });
};

function getCustomGeocoder(checkClientStructuresGeocodingAllowed) {
  const geocoderParams = {
    accessToken: mapboxgl.accessToken,
    countries: 'fr,re,mq,gp,gf,yt,mc,nc,pf,mf,pm,tf,wf', // all french territories
    localGeocoder: coordinatesGeocoder,
    externalGeocoder: clientStructuresGeocoder,
    mapboxgl,
    limit: 10,
  };
  _checkClientStructuresGeocodingAllowed = checkClientStructuresGeocodingAllowed;
  return new MapboxGeocoder(geocoderParams);
}

export default getCustomGeocoder;
