<template>
  <v-tooltip
    v-if="withTooltip"
    :top="!tooltipBottom"
    :bottom="tooltipBottom"
    max-width="640"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-badge
          v-model="displayPSABadge"
          color="red"
          top
          left
          overlap
          class="small-badge"
        >
          <span slot="badge">!</span>
          <template v-if="clickable">
            <v-btn
              v-if="loading"
              :class="getClass"
              class="fixed-icon-24"
              loading
              text
              icon
              @click.stop="handleClicked()"
            >
              <v-icon :class="getClass">
                {{ getIcon }}
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              class="fixed-icon-24"
              text
              icon
              @click.stop="handleClicked()"
            >
              <v-icon :class="getClass">
                {{ getIcon }}
              </v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-icon
              :class="getClass"
              @click.stop="handleClicked()"
            >
              {{ getIcon }}
            </v-icon>
          </template>
        </v-badge>
      </div>
    </template>
    <span>
      {{ getTooltip }}
    </span>
  </v-tooltip>
  <v-badge
    v-else
    v-model="displayPSABadge"
    color="red"
    top
    left
    overlap
    class="small-badge"
  >
    <span slot="badge">!</span>
    <v-icon :class="getClass">
      {{ getIcon }}
    </v-icon>
  </v-badge>
</template>
<script>

import {
  PLANNED, PROCESSING, SUBMITTED,
  CANCELLED, CANCELED, ERROR, ACTION_NEEDED,
  DONE, CONFIRMED, CLEAR, RESERVES,
  REFUSED,
  INCOMPLETE,
  NOT_HANDLED,
  HANDLED,
  ACCEPTED,
  PENDING,
  AWAITING_VALIDATION,
} from '@/store/status';

const ICONS = {
  fly_wait: { line: 'icon-fly_wait_line', flat: 'icon-fly_wait_flat' },
  fly_ok: { line: 'icon-fly_ok_line', flat: 'icon-fly_ok_flat' },
  fly_okbut: { line: 'icon-fly_okbut_line', flat: 'icon-fly_okbut_flat' },
  fly_nok: { line: 'icon-fly_nok_line', flat: 'icon-fly_nok_flat' },
  drone: { line: 'icon-drone', flat: 'icon-drone' },
  chat: { line: 'icon-chat_line', flat: 'icon-chat_flat' },
  fly_select: { line: 'icon-fly_select_vide', flat: 'icon-fly_select_vide' },
  handled: { line: 'mdi-information-outline', flat: 'mdi-information' },
  incomplete: { line: 'mdi-alert-circle-outline', flat: 'mdi-alert-circle' },
  cancelled: { line: 'icon-fly_wait_line', flat: 'icon-fly_wait_flat' },
};

export const THEMES = {
  DEFAULT: 'default',
  CONSTRAINTS: 'constraints',
  APPROVALS: 'approvals',
};

const CONSTRAINT_CLEAR_TEXT = "Vous avez déclaré que vous avez obtenu l'autorisation.";
const CONSTRAINT_NOT_HANDLED_TEXT = "À cocher lorsque vous aurez obtenu l'autorisation (pense-bête). Cocher cette case n'envoie pas de notification ni de demande d'accord.";

export const STATUS_CONFIG = {
  [THEMES.DEFAULT]: { // flights theme
    [PLANNED]: { icon: ICONS.fly_wait, text: 'Planifié' },
    [PROCESSING]: { icon: ICONS.fly_wait, text: 'En traitement' },
    [CLEAR]: { icon: ICONS.fly_ok, text: 'Vérifié' },
    [RESERVES]: { icon: ICONS.fly_okbut, text: 'Avec restriction' },
    [ERROR]: { icon: ICONS.fly_ok, text: 'Erreur' },
    [REFUSED]: { icon: ICONS.fly_nok, text: 'Refusé' },
    [ACTION_NEEDED]: { icon: ICONS.incomplete, text: 'Action nécessaire' },
    [CONFIRMED]: { icon: ICONS.drone, text: 'Confirmé' },
    [DONE]: { icon: ICONS.fly_ok, text: 'Fait' },
    [CANCELED]: { icon: ICONS.cancelled, text: 'Annulé' },
    [CANCELLED]: { icon: ICONS.cancelled, text: 'Annulé' },
  },
  [THEMES.CONSTRAINTS]: {
    [REFUSED]: { icon: ICONS.fly_nok, text: 'Refusée' },
    [CLEAR]: { icon: ICONS.fly_ok, text: CONSTRAINT_CLEAR_TEXT },
    [INCOMPLETE]: { icon: ICONS.chat, text: 'Incomplète' },
    [RESERVES]: { icon: ICONS.fly_okbut, text: 'Avec restriction' },
    [NOT_HANDLED]: { icon: ICONS.fly_select, text: CONSTRAINT_NOT_HANDLED_TEXT },
    [HANDLED]: { icon: ICONS.handled, text: 'Gérée' },
  },
  [THEMES.APPROVALS]: {
    [SUBMITTED]: { icon: ICONS.fly_wait, text: 'Déposée' },
    [AWAITING_VALIDATION]: { icon: ICONS.fly_wait, text: 'En attente de validation' },
    [REFUSED]: { icon: ICONS.fly_nok, text: 'Refusée' },
    [INCOMPLETE]: { icon: ICONS.incomplete, text: 'Incomplète' },
    [ACCEPTED]: { icon: ICONS.fly_ok, text: 'Acceptée' },
    [RESERVES]: { icon: ICONS.fly_okbut, text: 'Avec restriction' },
    [PENDING]: { icon: ICONS.fly_wait, text: 'En traitement' },
    [CANCELED]: { icon: ICONS.cancelled, text: 'Annulé' },
    [CANCELLED]: { icon: ICONS.cancelled, text: 'Annulé' },
    [ERROR]: { icon: ICONS.fly_nok, text: 'Erreur' },
  },
};

export default {
  name: 'FlightStatusIcon',
  props: {
    status: String,
    withNews: Boolean,
    withText: Boolean,
    withTooltip: Boolean,
    tooltipBottom: { type: Boolean, default: () => false },
    breachedPSAs: Boolean,
    reverseColor: Boolean,
    isUserAuthority: Boolean,
    linear: Boolean,
    tooltip: String,
    theme: { type: String, default: () => 'default' },
    loading: Boolean,
    clickable: Boolean,
    forceDisplayBadge: { type: Boolean, default: () => false },
    isClosed: Boolean,
  },
  computed: {
    config() {
      return STATUS_CONFIG[this.theme];
    },
    getIcon() {
      const iconStyle = this.linear ? 'line' : 'flat';
      const detail = this.config[this.status];
      return detail?.icon[iconStyle] || ICONS.fly_wait.flat;
    },
    displayPSABadge() {
      return this.forceDisplayBadge || (this.isUserAuthority && this.breachedPSAs);
    },
    getClass() {
      if (this.reverseColor) {
        return { 'white--text': true };
      }
      let style;
      switch (this.theme) {
        case THEMES.CONSTRAINTS:
          style = this.constraintsTheme();
          break;
        case THEMES.APPROVALS:
          style = this.approvalsTheme();
          break;
        default:
          style = this.defaultTheme();
      }
      return style;
    },
    getStatusDetail() {
      let text = this.config[this.status]?.text || this.$gettext('Unknown');
      if (this.isClosed) {
        text += ` (${this.config[CANCELLED].text})`;
      }
      return text;
    },
    getTooltip() {
      return this.tooltip || this.getStatusDetail;
    },
  },
  methods: {
    handleClicked() {
      this.$emit('status-icon-clicked', this);
    },
    constraintsTheme() {
      let style;
      switch (this.status) {
        case INCOMPLETE:
          style = 'warning--text';
          break;
        case REFUSED:
          style = 'error--text';
          break;
        case CLEAR:
          style = 'success--text';
          break;
        case RESERVES:
          style = 'warning--text';
          break;
        case NOT_HANDLED:
          style = 'error--text';
          break;
        case HANDLED:
          style = 'grey--text';
          break;
        default:
          style = 'error--text';
      }
      return { [style]: true };
    },
    approvalsTheme() {
      if (this.isClosed) {
        return { 'grey--text': true };
      }
      let style;
      switch (this.status) {
        case INCOMPLETE:
          style = 'warning--text';
          break;
        case REFUSED:
          style = 'error--text';
          break;
        case CANCELED:
        case CANCELLED:
          style = 'grey--text';
          break;
        case ACCEPTED:
          style = 'success--text';
          break;
        case RESERVES:
          style = 'warning--text';
          break;
        case AWAITING_VALIDATION:
        case SUBMITTED:
          style = 'primary--text';
          break;
        case PENDING:
          style = 'primary--text';
          break;
        default:
          style = 'error--text';
      }
      return { [style]: true };
    },
    defaultTheme() {
      return { 'white--text': true };
    },
  },
};
</script>
