var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{staticClass:"dense__expansion-panel mt-2",attrs:{"light":"","flat":""},model:{value:(_vm.panelExpanded),callback:function ($$v) {_vm.panelExpanded=$$v},expression:"panelExpanded"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"row flex-nowrap no-gutters align-center"},[_c('span',{staticClass:"dense__expansion-panel__header-content flex-grow-1"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"}},[_vm._v(" mdi-file-document-outline ")]),_c('translate',[_vm._v("Mission file")])],1)])]),_c('v-expansion-panel-content',{staticClass:"pt-3"},[(_vm.flightConstraintSheetAvailable)?_c('div',{key:"constraintSheetAvailable"},[_c('v-chip',{staticClass:"documents__chip caption",attrs:{"disabled":_vm.downloadLoading}},[_c('span',{staticClass:"documents__link-label",on:{"click":function($event){return _vm.downloadConstraintSheet(_vm.flight.id)}}},[_vm._v(" "+_vm._s(_vm.flightConstraintSheetFilename)+" ")]),_c('span',{staticClass:"font-italic info--text text--lighten-2 pl-1"},[_c('translate',[_vm._v("generated:")]),_c('span',[_vm._v(" "+_vm._s(_vm.flightConstraintSheetGenerationDate)+" ")])],1)]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","depressed":"","x-small":"","fab":"","disabled":_vm.flightConstraintSheetAsked,"loading":_vm.generationLoading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.generateFlightConstraintSheet.apply(null, arguments)}}},on),[_c('v-icon',{staticClass:"mdi-18px"},[_vm._v(" "+_vm._s(_vm.detailsConstraintSheet.buttonIcon)+" ")])],1)]}}],null,false,4192392674)},[_c('span',[_vm._v(" "+_vm._s(_vm.detailsConstraintSheet.description)+" ")])]),(_vm.flightConstraintSheet.to_update)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"color":"warning"}},on),[_vm._v(" mdi-alert-outline ")])]}}],null,false,3728097068)},[_c('span',[_vm._v(" "+_vm._s(_vm.texts.updateConstraintSheet)+" ")])]):_vm._e()],1):_c('div',{key:"constraintSheetNotAvailable"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","rounded":"","depressed":"","max-height":"32px","disabled":_vm.flightConstraintSheetAsked || _vm.generationLoading,"loading":_vm.generationLoading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.generateFlightConstraintSheet.apply(null, arguments)}}},on),[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1 mdi-18px"},[_vm._v(" "+_vm._s(_vm.detailsConstraintSheet.buttonIcon)+" ")]),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.detailsConstraintSheet.buttonText)+" ")])],1)])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.detailsConstraintSheet.description)+" ")])]),(_vm.flightConstraintSheetFailed)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"color":"error"}},on),[_vm._v(" mdi-alert ")])]}}],null,false,1251475091)},[_c('translate',[_vm._v("Mission file genetation failed, you can try it later.")])],1):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }