const PRIMARY = '#1D6FF2';
const SECONDARY = '#09304A';
const ERROR = '#A2253E';
const INFO = '#424242';
const SUCCESS = '#058D7A';
const WARNING = '#FF7300';

export default {
  themes: {
    light: {
      primary: PRIMARY,
      secondary: SECONDARY,
      error: ERROR,
      info: INFO,
      success: SUCCESS,
      warning: WARNING,
    },
    dark: {
      primary: PRIMARY,
      secondary: SECONDARY,
      error: ERROR,
      info: INFO,
      success: SUCCESS,
      warning: WARNING,
    },
  },
};
