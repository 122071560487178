<template>
  <v-data-table
    ref="datatable"
    v-model="selectedRows"
    :show-select="!isSingleColumn && !smallDisplay"
    :headers="headers"
    :items="flights"
    :loading="loading"
    :server-items-length="itemsLength"
    :options.sync="options"
    calculate-widths
    class="flight-listing"
    :class="{bigDisplay: bigDisplay, smallDisplay: !bigDisplay, transparent: true}"
    :footer-props="{
      'items-per-page-text': $gettext('Lines per page:'),
      'items-per-page-all-text': $gettext('All'),
      'items-per-page-options': [25, 50, 100, -1],
    }"
    dark
    :loading-text="$gettext('Loading flights...')"
    :no-data-text="labels.noFlightFound"
    item-key="id"
    :no-results-text="labels.noFlightFound"
    :mobile-breakpoint="singleColumnBreakpoint"
  >
    <!-- Action on selected rows -->
    <template
      v-if="selectedRows.length"
      v-slot:top
    >
      <MultipleActions
        :flights="selectedRows"
        @archive="multipleActions('close-flight')"
        @delete="multipleActions('delete-flights')"
        @export-kml="multipleActions('export-flights-area')"
        @generate-constraint-sheets="multipleActions('generate-constraint-sheets')"
      />
    </template>
    <!-- Loader -->
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    />
    <!-- Select all rows checkbox -->
    <template v-slot:[`header.data-table-select`]="{ on, props }">
      <div class="ma-0 pa-0">
        <v-simple-checkbox
          v-bind="props"
          v-on="on"
          class="pl-2 ma-0"
        />
      </div>
    </template>
    <template
      slot="item"
      slot-scope="props"
    >
      <!-- Single column compacted row: for narrow mobile screens -->
      <template v-if="isSingleColumn">
        <tr
          :class="getStatusClass(props)"
          @click="selectItem(props)"
          :key="props.item.id"
        >
          <td class="truncated cell">
            <v-row
              no-gutters
              align="center"
            >
              <v-col
                cols="2"
                class="pl-2"
              >
                <FlightStatusIcon
                  :status="props.item.status"
                  :force-display-badge="props.item.approval_can_be_created === true"
                  :tooltip="computeTooltipMessage(props.item)"
                  with-tooltip
                />
              </v-col>
              <v-col
                cols="2"
                class="font-weight-bold"
              >
                {{ props.item.id }}
              </v-col>
              <v-col
                cols="8"
                class="truncated"
              >
                {{ props.item.name }}
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
      <!-- Multicolumn row: for wider mobile screens and desktop screens-->
      <template v-else>
        <tr
          :class="getStatusClass(props)"
          class="cursor-pointer"
          @click="selectItem(props)"
          @mouseenter="idHover=props.item.id"
          @mouseleave="idHover=null"
          :key="props.item.id"
        >
          <!-- Select row checkbox -->
          <td v-if="!smallDisplay">
            <v-checkbox
              :input-value="props.isSelected"
              @change="props.select($event)"
              @click="doNotExpand=true"
              hide-details
              class="pl-2 ma-0"
            />
          </td>
          <!-- Status icon -->
          <td
            v-if="headers.find(e => e.value == 'status')"
            class="pl-2"
          >
            <FlightStatusIcon
              :status="props.item.status"
              :force-display-badge="props.item.approval_can_be_created === true"
              :tooltip="computeTooltipMessage(props.item)"
              with-tooltip
            />
          </td>
          <!-- Id -->
          <td
            v-if="headers.find(e => e.value == 'id')"
            class="font-weight-bold"
          >
            {{ props.item.id }}
          </td>
          <!-- Name -->
          <td
            v-if="headers.find(e => e.value == 'name')"
            class="truncated cell"
          >
            <v-tooltip
              top
              max-width="40%"
            >
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  class="d-flex pr-2"
                >
                  <div class="truncated">
                    <v-chip
                      v-if="props.item.was_entrusted"
                      label
                      x-small
                      color="white primary--text"
                      class="mr-2 px-1"
                    >
                      <span class="icon-logo_clearance_flat clearance-logo__icon" />
                      <translate>Entrusted</translate>
                    </v-chip>
                    <span class="mr-1">
                      {{ props.item.name }}
                    </span>
                  </div>
                  <div
                    v-if="isUserEnterprise"
                    class="chips-group-flex-nowrap justify-end truncated"
                  >
                    <v-chip
                      v-for="tag in props.item.tags"
                      :key="tag.id"
                      x-small
                      color="white primary--text"
                      class="px-1 py-2"
                    >
                      <span class="font-weight-medium">
                        {{ tag }}
                      </span>
                    </v-chip>
                  </div>
                </div>
              </template>
              <span>
                {{ props.item.name }}
                <template v-if="isUserEnterprise">
                  <v-chip
                    v-for="tag in props.item.tags"
                    :key="tag.id"
                    x-small
                    color="white"
                    outlined
                    class="ml-2 px-2 py-2"
                  >
                    {{ tag }}
                  </v-chip>
                </template>
                <span v-if="props.item.was_entrusted">
                  <hr class="separator white mr-5 my-1" />
                  <translate>
                    Clearance handles procedures of this flight for you.
                  </translate>
                </span>
                <span v-else-if="props.item.approval_can_be_created === true">
                  <hr class="separator white mr-5 my-1" />
                  <span>
                    {{ texts.requestApproval }}
                  </span>
                </span>
              </span>
            </v-tooltip>
          </td>
          <!-- Period -->
          <td
            v-if="headers.find(e => e.value === 'date_start')"
            class="truncated cell"
          >
            {{ props.item.date_start | dateShort }} - {{ props.item.date_end | dateShort }}
          </td>
          <!-- Height -->
          <td
            v-if="headers.find(e => e.value === 'flying_height')"
            class="truncated cell text-center"
          >
            {{ props.item.flying_height | meter }}
          </td>
          <!-- Time -->
          <td
            v-if="headers.find(e => e.value === 'time_start')"
            class="truncated cell"
          >
            {{ props.item.time_start | hour }} - {{ props.item.time_end | hour }}
          </td>
          <!-- ------ Actions buttons ---------- -->
          <td
            v-if="headers.find(e => e.value === 'actions')"
            class="flight-listing__actions-cell"
          >
            <v-icon>mdi-dots-vertical</v-icon>
            <div
              v-if="idHover === props.item.id"
              :class="getStatusClass(props)"
              class="flight-listing__actions-container"
            >
              <FlightActions
                :flight="props.item"
                :actionFromFlightListing="true"
                @weather-flight="doNotExpand = true; $emit('weather-flight', props.item)"
                @center-on-flight="doNotExpand = true; $emit('center-on-flight', props.item)"
                @edit-flight="doNotExpand = true; $emit('edit-flight', props.item)"
                @duplicate-flight="doNotExpand = true; $emit('duplicate-flight', props.item)"
                @close-flight="
                  doNotExpand = true; $emit('close-flight', [props.item]); selectedRows=[];
                "
                @delete-flights="
                  doNotExpand = true; $emit('delete-flights', [props.item]); selectedRows=[];
                "
              />
            </div>
          </td>
        </tr>
      </template>
    </template>
    <template v-slot:footer>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      />
    </template>
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }}
      <span v-translate>de</span>
      {{ items.itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
import {
  APPLICATION_DISPLAY,
  APPLICATION_SPLIT,
  SET_ACTIVE_SPLIT_NS,
} from '@/store/application';
import {
  SET_FILTERS_NS,
  GET_FLIGHT_DETAILS_NS,
  SET_FLIGHT_HOVER_NS,
  SET_FLIGHT_SELECTED_NS,
  SET_FLIGHT_STATUS_NS,
  SET_PAGINATION_NS,
} from '@/store/flights';

import FlightActions from '@/components/Flights/FlightActions.vue';
import makeFlightListingHeaders from '@/components/Flights/FlightListingHeaders';
import FlightStatusIcon from '@/components/StatusIcon/FlightStatusIcon.vue';
import MultipleActions from '@/components/Flights/MultipleActions.vue';

export default {
  name: 'FlightListing',
  components: {
    FlightActions,
    FlightStatusIcon,
    MultipleActions,
  },
  data() {
    const {
      mobileHeaders,
      smallHeaders,
      mediumHeaders,
      bigHeaders,
    } = makeFlightListingHeaders(this.$gettext);
    return {
      doNotExpand: false,
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
      selectedRows: [],
      mobileHeaders,
      smallHeaders,
      mediumHeaders,
      bigHeaders,
    };
  },
  computed: {
    labels() {
      return {
        noFlightFound: this.$gettext(`No flight found. You can create it clicking red button
          below.`),
      };
    },
    texts() {
      return {
        requestApproval: this.$gettext(`You have an approval request that is not transmitted yet.
          You can send it by clicking on the Request Approval button in the constraints section of
          the flight.`),
      };
    },
    isUserEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    display() {
      return this.$store.state.application.display;
    },
    bigDisplay() {
      return this.display === APPLICATION_DISPLAY.BIG;
    },
    mediumDisplay() {
      return this.display === APPLICATION_DISPLAY.MEDIUM;
    },
    smallDisplay() {
      return this.display === APPLICATION_DISPLAY.SMALL;
    },
    headers() {
      if (this.isSingleColumn) {
        return this.mobileHeaders;
      }
      if (this.bigDisplay) {
        return this.bigHeaders;
      }
      if (this.smallDisplay) {
        return this.smallHeaders;
      }
      return this.mediumHeaders;
    },
    isSingleColumn() {
      if (!this.$vuetify.breakpoint.width) return false;
      return this.$vuetify.breakpoint.width < this.singleColumnBreakpoint;
    },
    itemsLength() {
      return this.$store.state.flights.itemsLength;
    },
    options: {
      get() {
        return this.$store.state.flights.pagination;
      },
      set(newValue) {
        this.$store.dispatch(SET_PAGINATION_NS, newValue);
      },
    },
    flights() {
      return this.$store.getters['flights/tableItems'];
    },
    loading() {
      return this.$store.state.flights.tableItemsLoading;
    },
    idSelected: {
      get() {
        return this.$store.state.map.featureIdSelected.flight;
      },
      set(newValue) {
        this.$store.dispatch(SET_FLIGHT_SELECTED_NS, { flightId: newValue, reload: true });
        this.$store.dispatch(SET_ACTIVE_SPLIT_NS, APPLICATION_SPLIT.LISTING);
      },
    },
    idHover: {
      get() {
        return this.$store.state.map.featureIdHovered.flight;
      },
      set(newValue) {
        this.$store.dispatch(SET_FLIGHT_HOVER_NS, newValue);
      },
    },
    flightFilters() {
      return this.$store.state.flights.filters;
    },
  },
  watch: {
    display(newValue) {
      if (newValue === 'SMALL') {
        this.idSelected = null;
      }
    },
    flightFilters() {
      this.selectedRows = [];
    },
  },
  mounted() {
    this.selectFlightFromRoute();
  },
  methods: {
    getStatusClass(props) {
      const isSelected = this.idSelected || false;
      const parent = isSelected ? 'selected' : 'shrunk';
      const child = this.getDisplayStatus(props.item);

      return {
        [parent]: true,
        [child]: true,
      };
    },
    getDisplayStatus({ is_archived: isArchived, status }) {
      const localStatus = status === 'error' ? 'error_status' : status;
      return isArchived ? 'archived' : localStatus;
    },
    setStatus(item) {
      return function updateStatus(status) {
        this.$store.dispatch(SET_FLIGHT_STATUS_NS, { id: item.id, status });
      };
    },
    computeTooltipMessage(flight) {
      if (flight.approval_can_be_created) {
        return this.$gettext(`You have an approval request that is not transmitted yet. You can
          send it by clicking on the Request Approval button in the constraints section of the
          flight.`);
      }
      return '';
    },
    selectItem(props) {
      if (this.loading) {
        return;
      }
      if (this.doNotExpand) {
        // Avoid handling unwanted click event
        this.doNotExpand = false;
        return;
      }
      this.idHover = null;
      this.idSelected = props.item.id;
    },
    selectFlightFromRoute() {
      if (this.$route.query.idSelected !== undefined) {
        const flightId = parseInt(this.$route.query.idSelected, 10);
        this.$store.dispatch(GET_FLIGHT_DETAILS_NS, { flightId })
          .then(() => {
            this.idSelected = flightId;
          })
          .catch(() => {
            // If flight not found try to look in the archives
            this.$store.dispatch(SET_FILTERS_NS, {
              ...this.$store.state.flights.filters,
              isArchived: null,
            })
              .then(() => {
                this.$store.dispatch(GET_FLIGHT_DETAILS_NS, { flightId, reload: true })
                  .then(() => {
                    this.idSelected = flightId;
                  })
                  .catch(() => {
                    this.showMessage(
                      this.$gettextInterpolate(
                        this.$gettext('Flight n° %{flightId} can not be found'),
                        { flightId },
                      ),
                      'error',
                    );
                  });
              });
          });
      }
    },
    multipleActions(action) {
      this.doNotExpand = true;
      this.$emit(action, this.selectedRows);
      this.selectedRows = [];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
// Rows colors according to status
.flight-listing ::v-deep .v-data-table__wrapper>table>tbody>tr,
.flight-listing ::v-deep .v-data-table__wrapper>table>tbody>tr:hover,
.flight-listing__actions-container {
  background-color: $color-white;
  color: $color-secondary;
  &.shrunk, &.selected {
    color: $color-white;
    background-color: $color-primary;

    &.planned {
      background-color: $flight-planned-background-color;
    }
    &.processing {
      background-color: $flight-processing-background-color;
    }
    &.canceled {
      color: $color-info;
      background-color: $flight-canceled-background-color;
    }
    &.error_status {
      background-color: $flight-error-background-color;
    }
    &.action_needed {
      background-color: $flight-action_needed-background-color;
    }
    &.archived {
      color: $color-white;
      background-color: $flight-archived-background-color;
    }
    &.done {
      color: $color-white;
      background-color: $flight-archived-background-color;
    }
    &.confirmed {
      background-color: $flight-confirmed-background-color;
    }
    &.clear {
      background-color: $flight-clear-background-color;
    }
    &.reserves {
      background-color: $flight-reserves-background-color;
    }
    &.refused {
      background-color: $flight-refused-background-color;
    }
    &.highlighted {
      background-color: $flight-highlighted-background-color;
    }
    &.accepted {
      background-color: $accepted-background-color;
    }
    &.incomplete {
      background-color: $incomplete-background-color;
    }
    &.submitted {
      background-color: $submitted-background-color;
    }
    &.pending {
      background-color: $pending-background-color;
    }
  }
}

.flight-listing ::v-deep .v-data-table__wrapper>table> {
  // Table rows
  tbody>tr:not(.v-datatable__expand-row),
  thead>tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 15px solid $color-secondary;
      height: 60px;
    }
    &:first-child td {
      height: 45px;
    }
  }
}

// Flight action icons on hover
.flight-listing ::v-deep .flight-listing__actions {
  &-cell {
    min-width: 32px;
    position: relative;
  }
  &-container {
    position: absolute;
    top: 0;
    right: 4px;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}
.flight-listing ::v-deep .selection-actions {
  height: min-content;
  background-color: white;
  border: none;
  padding: 0px;
  margin: 0px;
  position: sticky;
  top: 1px;
  z-index: 2;
  &--text {
    text-decoration: underline;
  }
}

</style>
