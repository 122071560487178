import Vue from 'vue';
import store from '@/store';

const filterDate = (value) => {
  if (!value) return '';
  const day = value.substr(value.lastIndexOf('-') + 1, 2);
  const month = value.substr(value.indexOf('-') + 1, 2);
  const year = value.substr(0, 4);
  return `${day}/${month}/${year}`;
};

const filterDateShort = (value) => {
  if (!value) return '';
  const day = value.substr(value.lastIndexOf('-') + 1, 2);
  const month = value.substr(value.indexOf('-') + 1, 2);
  const year = value.substr(2, 2);
  return `${day}/${month}/${year}`;
};

export const localeDate = (value) => {
  if (!value) return '';
  return new Date(value).toLocaleDateString(store.getters['application/currentLanguage']);
};

export const localeDateTime = (value) => {
  if (!value) return '';
  return new Date(value).toLocaleString(
    store.getters['application/currentLanguage'],
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  );
};

Vue.filter('date', filterDate);
Vue.filter('dateShort', filterDateShort);

// Only use if initial value is in UTC
Vue.filter('toLocaleDate', localeDate);
Vue.filter('toLocaleDatetime', localeDateTime);
