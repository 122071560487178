<template>
  <v-container class="pa-0">
    <div class="details-card pa-3">
      <!-- Details  -->
      <v-row no-gutters>
        <!-- Last name -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            NAME
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          <span>
            {{ pilot.last_name }}
          </span>
        </v-col>
        <!-- First name -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            FIRST NAME
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          <span>
            {{ pilot.first_name }}
          </span>
        </v-col>
        <!-- Gender -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            GENDER
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          <span>
            {{ pilot.gender }}
          </span>
        </v-col>
        <!-- Operation -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            OPERATION
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
        >
          <span>
            {{ pilot.function }}
          </span>
        </v-col>
        <!-- Email -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EMAIL
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="10"
        >
          <span>
            {{ pilot.email }}
          </span>
        </v-col>
        <!-- Birthday -->
        <template v-if="pilot.birthday">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              BIRTHDAY
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            <span>
              {{ pilot.birthday | date }}
            </span>
          </v-col>
        </template>
        <!-- Birth place -->
        <template v-if="pilot.birth_place">
          <v-col
            cols="5"
            sm="2"
          >
            <span
              class="details-card__label"
              v-translate
            >
              BIRTHDAY PLACE
            </span>
          </v-col>
          <v-col
            cols="7"
            sm="4"
          >
            <span>
              {{ pilot.birth_place }}
            </span>
          </v-col>
        </template>
        <!-- Phone number -->
        <v-col
          cols="5"
          sm="2"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PHONE NUMBER
          </span>
        </v-col>
        <v-col
          cols="7"
          sm="4"
          class="pr-0 pr-sm-3"
        >
          <span>
            {{ pilot.phone_number | phone }}
          </span>
        </v-col>
      </v-row>
      <!-- Entities  -->
      <v-row
        v-if="subExploitantsAvailable"
        no-gutters
        align="center"
      >
        <v-col
          cols="3"
          sm="2"
        >
          <span class="details-card__label text-uppercase">
            {{ entityName.singular }}
          </span>
        </v-col>
        <v-col
          cols="9"
          sm="10"
        >
          <div class="chips-group-flex-wrap">
            <v-chip
              v-for="(subExploitant, index) in subExploitants"
              :key="index"
              :input-value="true"
              light
              small
            >
              {{ subExploitant.name }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <!-- Documents  -->
      <div>
        <PilotDocuments :pilot="pilot" />
      </div>
    </div>
    <!-- Footer  -->
    <template v-if="canUpdateOrDeletePilot">
      <v-row
        no-gutters
        class="details-card__footer"
      >
        <v-col
          cols="12"
          class="text-right"
        >
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                text
                icon
                @click="tryToEdit('edit')"
                v-on="on"
              >
                <v-icon>icon-edit_line</v-icon>
              </v-btn>
            </template>
            <span v-translate>Update pilot</span>
          </v-tooltip>
          <v-tooltip
            v-if="isAdminOrManager"
            top
            color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                text
                icon
                @click="tryToEdit('delete')"
                v-on="on"
              >
                <v-icon>icon-bin_line</v-icon>
              </v-btn>
            </template>
            <span v-translate>Delete the pilot</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <!-- Dialog edit/delete unauthorized -->
    <v-dialog
      v-model="showUnauthorizedDialog"
      max-width="480"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span v-translate>Editing an administrator</span>
        </v-card-title>
        <v-card-text>
          <div class="mb-2">
            <translate>
              In order to edit an administrator, you must be an administrator yourself.
            </translate>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="showUnauthorizedDialog=false"
          >
            <translate>Ok</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PilotDocuments from '@/components/Pilots/PilotDocuments.vue';

export default {
  name: 'Pilot',
  components: { PilotDocuments },
  props: { pilot: Object },
  data() {
    return { showUnauthorizedDialog: false };
  },
  computed: {
    valid() {
      return this.pilot && this.pilot.is_valid;
    },
    isUserExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    isSubExploitantManager() {
      return this.$store.getters['authentication/isUserExploitantManager'];
    },
    isAdminOrManager() {
      return this.isUserExploitantAdmin || this.isSubExploitantManager;
    },
    canUpdateOrDeletePilot() {
      return this.isAdminOrManager || this.pilotConnected;
    },
    pilotConnected() {
      return this.pilot.id === this.$store.state.authentication.user.contact_id;
    },
    subExploitantsAvailable() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    subExploitants() {
      return this.$store.state.authentication.userSubExploitants.filter(
        (s) => this.pilot.sub_exploitants.includes(s.id),
      );
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  methods: {
    tryToEdit(type) {
      if (!this.isUserExploitantAdmin && this.pilot.admin_privileges) {
        this.showUnauthorizedDialog = true;
      } else if (type === 'delete') {
        this.$emit('delete-pilot', this.pilot);
      } else {
        this.$emit('edit-pilot', this.pilot);
      }
    },
  },
};
</script>
