<template>
  <div class="body-2 info--text">
    <template v-if="prefectureNotUsingAT">
      <span v-html="texts.prefectureNotUsingAT " />
    </template>
    <template v-else>
      <div
        v-if="hasMissingData"
        class="mb-2"
      >
        <span>
          {{ texts.cantNotify }}
        </span>
        <ul class="mt-4">
          <li v-if="missingData.missingATNumber">
            {{ texts.missingATNumber }}
          </li>
          <li v-if="missingData.missingDrone">
            {{ texts.missingDrone }}
          </li>
          <li v-if="missingData.missingPilot">
            {{ texts.missingPilot }}
          </li>
        </ul>
      </div>
      <div
        v-if="flightIsHSS"
        class="mb-2"
      >
        <span>
          {{ texts.flightIsHSS }}
        </span>
        <span v-if="!hssNotificationsAllowed">
          {{ texts.hssNotificationsNotAllowed }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NotifAlphaTangoRequirements',
  props: {
    recipient: String,
    flightIsHSS: Boolean,
    prefectureNotUsingAT: Boolean,
    missingData: Object,
  },
  computed: {
    hasMissingData() {
      return (
        this.missingData.missingDrone
        || this.missingData.missingATNumber
        || this.missingData.missingPilot
      );
    },
    hssNotificationsAllowed() {
      return this.$store.getters['authentication/hssNotificationsAllowed'];
    },
    texts() {
      return {
        prefectureNotUsingAT: this.$gettext(`Online flight notifications are currently not possible
          for this prefecture: please send them a CERFA 15476*03 sheet directly with this
          <a target="_blank"
          href="https://www.demarches-simplifiees.fr/commencer/declaration-de-survol-drone-scenario-s3-pref06">
          link</a>.`),
        cantNotify: this.$gettextInterpolate(
          this.$gettext(`Clearance can not send for you %{ recipient } notifications regarding this
            mission. To make AlphaTango notifications possible you have to:`),
          { recipient: this.recipient },
        ),
        missingATNumber: this.$gettext(`Indicate your exploitant number AlphaTango (UID number).
          You can do it on the "My company" page (link in top right menu).`),
        missingDrone: this.$gettext(`At least one drone must be affected to the mission, and the
          affected drones must be registered on AlphaTango website.`),
        missingPilot: this.$gettext(`At least one pilot must be affected to this mission, and the
          affected pilots must have a phone number.`),
        flightIsHSS: this.$gettext(`Off sight flight in agglomeration are outside of national
          standard scenario.`),
        hssNotificationsNotAllowed: this.$gettext(`Please contact support@clearance.aero if you
          have this type of authorisation or if you want it.`),
      };
    },
  },
};
</script>
