<template>
  <div>
    <div
      class="mt-4 font-weight-medium pb-1 black--text body-2"
      v-translate
    >
      Requirement
    </div>
    <v-alert
      color="warning"
      text
    >
      <div class="body-2 black--text">
        {{ takeOffAuthorization.instruction }}
      </div>
    </v-alert>
    <div
      class="mt-4 font-weight-medium pb-1 black--text body-2"
      v-translate
    >
      Messages
    </div>
    <Chat
      :events="takeOffAuthorization.events"
      :newMessageDisabled="newMessageDisabled"
      :atSendMessage="(message) => sendTakeOffAuthorizationMessage(message)"
      withoutNewMessageAttachment
      viewedAtEnabled
      :atMarkMessagesAsViewed="(ids) => markTakeOffAuthorizationMessagesAsViewed(ids)"
    />
  </div>
</template>

<script>
import APIService from '@/services/api';

import Chat from '@/components/Chat.vue';

export default {
  name: 'TakeOffAuthorizationDetails',
  components: { Chat },
  props: {
    takeOffAuthorization: Object,
    newMessageDisabled: Boolean,
  },
  computed: {
    isUserHijacked() {
      return this.$store.state.authentication.hijackedUserId !== null;
    },
  },
  methods: {
    async sendTakeOffAuthorizationMessage(message) {
      return APIService.sendTakeOffAuthorizationMessage(this.takeOffAuthorization.id, { message })
        .then(({ data }) => {
          this.$emit('update-take-off-authorization', data);
          return data.events;
        });
    },
    markTakeOffAuthorizationMessagesAsViewed(ids) {
      if (this.isUserHijacked) return;
      APIService.markTakeOffAuthorizationMessagesAsViewed(this.takeOffAuthorization.id, ids);
    },
  },
};
</script>
