<template>
  <v-card
    class="default--dialog__card"
    max-height="800px"
    elevation="0"
  >
    <v-card-title>
      <span v-translate>Activation Request</span>
    </v-card-title>
    <v-card-text>
      <!-- Select approval -->
      <template v-if="approval === null && approvals.length > 1">
        <v-select
          v-model="approvalSelected"
          :items="approvals"
          :label="labels.approvalSelected"
          item-value="id"
          return-object
        >
          <template v-slot:selection="{ item }">
            <ApprovalItem :approval="item" />
          </template>
          <template v-slot:item="{ item }">
            <ApprovalItem :approval="item" />
          </template>
        </v-select>
      </template>
      <!-- USK article -->
      <v-alert
        v-if="isUSKActivation"
        color="primary"
        icon="mdi-information-outline"
        outlined
        border="left"
        class="text-sm-body-2 mt-2 font-weight-medium"
      >
        <a
          target="_blank"
          href="https://clearance.aero/demande-daccord-aux-aeroports-u-space-keeper-via-clearance/"
          class="pb-2"
          v-translate
        >
          How to request an activation in a U-SpaceKeeper airspace?
        </a>
      </v-alert>
      <!-- Approval information -->
      <v-alert
        color="primary"
        icon="mdi-information-outline"
        outlined
        border="left"
        class="text-sm-body-2 mt-2 font-weight-medium"
      >
        <v-skeleton-loader
          v-if="loadingCapabilities"
          type="text@3"
        />
        <div v-else>
          <div v-translate>Approval periods allowed:</div>
          <ul>
            <li
              v-for="(datetime, index) in allowedDatetimes"
              :key="index"
            >
              <div>
                <span v-translate>Date range:</span>
                <span>
                  {{ datetime.at(0) | date }} - {{ datetime.at(1) | date }}
                </span>
              </div>
              <div>
                <span v-translate>Time range:</span>
                <span>
                  {{ getHourFromTimestamp(datetime.at(0).slice(0, -1)) }}
                  - {{ getHourFromTimestamp(datetime.at(1).slice(0, -1)) }}
                </span>
              </div>
            </li>
          </ul>
          <div>
            {{ texts.disabledCalendarDays }}
          </div>
        </div>
      </v-alert>
      <!-- Capability -->
      <v-alert
        :color="isActivationRequestAllowed ? 'primary' : 'error'"
        icon="mdi-information-outline"
        outlined
        border="left"
        class="text-sm-body-2 mt-2 font-weight-medium"
      >
        <v-skeleton-loader
          v-if="loadingCapabilities"
          type="text@5"
        />
        <div v-else-if="failCapabilityMessage">
          {{ failCapabilityMessage }}
        </div>
        <div v-else-if="isBetweenApprovalDatetimes">
          <div v-if="capability.max_nb_days">
            {{ texts.duration }}
          </div>
          <template v-if="isActivationRequestAllowed">
            <div v-if="isProgrammed">
              {{ texts.programmed }}
            </div>
            <div v-else>
              <span
                v-translate
                key="allowed"
              >
                Your activation request will be sent directly.
              </span>
              <div
                v-if="!isToleranceNoticeDaysRespected"
                class="warning--text"
                key="toleranceExplain"
              >
                {{ texts.toleranceExplain }}
              </div>
            </div>
          </template>
          <div v-else>
            <div v-if="!isDurationRespected">
              <span
                v-translate
                key="durationTooLong"
              >
                Your activation request doesn't respect maximum activation duration.
              </span>
            </div>
            <div v-if="!isNoticeDaysRespected">
              {{ texts.outdated }}
              <template v-if="noticeTime">
                &nbsp; {{ texts.outdatedTime }}
              </template>
            </div>
          </div>
        </div>
        <div
          v-else
          v-translate
        >
          Selected period is not in approval period allowed.
        </div>
      </v-alert>
      <v-form v-model="valid">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-row
                wrap
                class="primary--form__row"
              >
                <v-col cols="6">
                  <v-date-picker
                    v-model="dates"
                    :allowed-dates="(val) => allowedDates(val, this)"
                    :min="minCalendarDate"
                    no-title
                    required
                    full-width
                    range
                    class="elevation-0"
                    locale="fr"
                    first-day-of-week="1"
                  />
                </v-col>
                <v-col cols="6">
                  <v-col cols="12">
                    <v-row
                      class="ma-0"
                      align="center"
                      wrap
                    >
                      <v-col cols="4">
                        <span
                          class="primary--form__label"
                          v-translate
                        >
                          PERIOD
                        </span>
                      </v-col>
                      <v-col
                        v-if="dates && dates.length"
                        cols="8"
                      >
                        <v-row
                          class="ma-0 mb-2"
                          no-gutters
                        >
                          <v-col cols="4">
                            <span v-translate>Du</span>
                          </v-col>
                          <v-col cols="8">
                            {{ dates[0] | date }}
                          </v-col>
                        </v-row>
                        <v-row
                          class="ma-0 mb-2"
                          no-gutters
                        >
                          <v-col cols="4">
                            <span v-translate>au</span>
                          </v-col>
                          <v-col cols="8">
                            {{ dates[dates.length - 1] | date }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row
                      class="ma-0"
                      align="center"
                      wrap
                    >
                      <v-col cols="4">
                        <span
                          class="primary--form__label"
                          v-translate
                        >
                          HOUR
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-row>
                          <v-col
                            cols="3"
                            class="text-center"
                          >
                            <span v-translate>de</span>
                          </v-col>
                          <v-col
                            cols="6"
                            class="py-0"
                          >
                            <TimePicker
                              :time="startTime"
                              @update:time="startTime = $event"
                              :rules="[rules.required]"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="3"
                            class="text-center"
                          >
                            <span v-translate>à</span>
                          </v-col>
                          <v-col
                            cols="6"
                            class="py-0"
                          >
                            <TimePicker
                              :time="endTime"
                              @update:time="endTime = $event"
                              :rules="[rules.required]"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close-form')"
      >
        <span v-translate>Cancel</span>
      </v-btn>
      <v-btn
        text
        color="primary"
        :disabled="!valid || loadingCapabilities || !isActivationRequestAllowed"
        :loading="loadingSubmitActivation"
        @click="submitActivation()"
      >
        <span v-translate>Send</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { format } from 'date-fns';

import APIService from '@/services/api';
import { debounce } from '@/services/api.helper';

import { CONSTRAINTS_PROCESS_THROUGH } from '@/settings';

import { ACCEPTED, RESERVES } from '@/store/status';

import ApprovalItem from '@/components/Approvals/ApprovalItem.vue';
import TimePicker from '@/components/TimePicker.vue';

export default {
  name: 'RequestActivationForm',
  components: {
    ApprovalItem,
    TimePicker,
  },
  props: {
    flight: Object,
    approval: {
      type: Object,
      required: false,
      default: null,
    },
    constraint: Object,
    activation: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    const approvals = this.flight.approvals.filter((a) => (
      a.constraint_id === this.constraint.id
      && [ACCEPTED, RESERVES].includes(a.status)
      && !a.is_closed
    ));
    return {
      approvals,
      approvalSelected: this.approval || approvals.at(0),
      valid: false,
      dates: [],
      startTime: null,
      endTime: null,
      rules: { required: (v) => v !== '' || this.$gettext('Please fill in this field.') },
      loadingSubmitActivation: false,
      loadingCapabilities: false,
      capability: {},
      failCapabilityMessage: '',
    };
  },
  computed: {
    labels() {
      return { approvalSelected: this.$gettext("Select approval for the activation's request") };
    },
    texts() {
      return {
        disabledCalendarDays: this.$gettext(`Activation requests are only allowed in approval
          dates and hours range.`),
        duration: this.$gettextInterpolate(
          this.$gettext('Maximum activation duration allowed: %{ hour } hours'),
          { hour: this.capability.max_nb_days ? this.capability.max_nb_days * 24 : '' },
        ),
        programmed: this.$gettextInterpolate(
          this.$gettext('Your activation request will be sent automatically on %{ date }.'),
          { date: this.programmedDate },
        ),
        toleranceExplain: this.$gettextInterpolate(
          this.$gettext(`Activation submitted after %{ noticeTime } will not necessarily be
            processed by the authority concerned.`),
          { noticeTime: this.toleranceNoticeTime },
        ),
        outdated: this.$gettextInterpolate(
          this.$gettext(
            'For the selected dates, you should request activation no later than: %{ date }',
          ),
          { date: this.noticeDate },
        ),
        outdatedTime: this.$gettextInterpolate(
          this.$gettext('before %{ time } (local hour)'), { time: this.noticeTime },
        ),
      };
    },
    isActivationToUpdate() {
      return this.activation !== null;
    },
    allowedDatetimes() {
      return this.capability.allowed_datetimes || [];
    },
    validations() {
      return this.capability.validations || {};
    },
    isToleranceNoticeDaysRespected() {
      return this.validations.tolerance_notice_days;
    },
    isNoticeDaysRespected() {
      return this.validations.notice_days;
    },
    noticeDate() {
      if (!this.capability.notice_days) return '';

      const minDate = new Date(this.dates[0]);
      minDate.setMinutes(minDate.getMinutes() + minDate.getTimezoneOffset());
      minDate.setDate(minDate.getDate() - this.capability.notice_days);

      return this.getDateFromTimestamp(minDate);
    },
    toleranceNoticeTime() {
      if (!this.capability.tolerance_notice_time) return '';
      const time = this.capability.tolerance_notice_time;
      return time.substr(0, time.lastIndexOf(':'));
    },
    noticeTime() {
      if (!this.capability.notice_time) return '';
      const time = this.capability.notice_time;
      return time.substr(0, time.lastIndexOf(':'));
    },
    isMaxNoticeDaysRespected() {
      return this.validations.max_notice_days;
    },
    isDurationRespected() {
      return this.validations.max_nb_days;
    },
    isBetweenApprovalDatetimes() {
      return this.validations.allowed_datetimes;
    },
    isActivationRequestAllowed() {
      return (
        this.isNoticeDaysRespected
        && this.isDurationRespected
        && this.isBetweenApprovalDatetimes
        && this.failCapabilityMessage === ''
      );
    },
    isProgrammed() {
      return !this.isMaxNoticeDaysRespected && this.isNoticeDaysRespected;
    },
    programmedDate() {
      if (!this.capability.max_notice_days) return '';

      const maxDate = new Date(`${this.dates[0]}T${this.startTime}`);
      maxDate.setDate(maxDate.getDate() - this.capability.max_notice_days);

      return this.getDateFromTimestamp(maxDate);
    },
    isUSKActivation() {
      return this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.USK;
    },
    minCalendarDate() {
      return format(new Date(), 'yyyy-MM-dd');
    },
  },
  watch: {
    dates(newValue) {
      if (newValue?.length === 2 && newValue[0] > newValue[1]) {
        this.dates = [newValue[1], newValue[0]];
      }
      this.debouncedGetCapabilities({ that: this });
    },
    startTime(newValue) {
      if (newValue) this.debouncedGetCapabilities({ that: this });
    },
    endTime(newValue) {
      if (newValue) this.debouncedGetCapabilities({ that: this });
    },
    approvalSelected(newValue) {
      if (newValue) this.debouncedGetCapabilities({ that: this });
    },
  },
  created() {
    this.updateFormValue();
  },
  methods: {
    allowedDates: (val, that) => (
      that.allowedDatetimes
        .map((datetimeList) => [
          new Date(datetimeList.at(0).slice(0, -1)), new Date(datetimeList.at(1).slice(0, -1)),
        ])
        .some((datetimeList) => {
          const date = new Date(val);
          const dateStart = datetimeList.at(0);
          dateStart.setHours(0); dateStart.setMinutes(0); dateStart.setSeconds(0);
          const dateEnd = datetimeList.at(1);
          dateEnd.setHours(23); dateEnd.setMinutes(59); dateEnd.setSeconds(59);
          if (dateStart <= date && dateEnd >= date) return true;

          return false;
        })
    ),
    debouncedGetCapabilities: debounce(
      ({ that }) => that.getCapabilities(),
      300, // debounce for 0.3s
    ),
    getDateFromTimestamp(timestamp) {
      return new Date(timestamp).toLocaleDateString(
        this.$store.getters['application/currentLanguage'],
      );
    },
    getHourFromTimestamp(timestamp) {
      return new Date(timestamp).toLocaleTimeString(
        this.$store.getters['application/currentLanguage'],
        { hour: '2-digit', minute: '2-digit' },
      );
    },
    computeMinCalendarDate(date) {
      const today = new Date();
      if (new Date(date) > today) return date;

      return format(today, 'yyyy-MM-dd');
    },
    updateFormValue() {
      if (this.isActivationToUpdate) {
        this.dates = [
          this.computeMinCalendarDate(this.activation.date_start),
          this.activation.date_end,
        ];
        this.startTime = this.activation.time_start;
        this.endTime = this.activation.time_end;
      } else {
        const minDate = this.computeMinCalendarDate(this.flight.date_start);
        const dates = [minDate];
        // Handle night flying
        if (this.flight.time_end < this.flight.time_start) {
          const minDateAddOneDay = new Date(minDate);
          minDateAddOneDay.setDate(minDateAddOneDay.getDate() + 1);
          dates.push(format(minDateAddOneDay, 'yyyy-MM-dd'));
        }
        this.dates = dates;
        this.startTime = this.flight.time_start;
        this.endTime = this.flight.time_end;
      }
    },
    getCapabilities() {
      this.loadingCapabilities = true;
      this.failCapabilityMessage = '';
      APIService.getExternalActivationCapabilities(
        this.approvalSelected.id,
        this.dates[0],
        this.dates[this.dates.length - 1],
        this.startTime,
        this.endTime,
      )
        .then((response) => {
          if (response) this.capability = response.data;
        })
        .catch((error) => {
          this.failCapabilityMessage = (
            error?.response?.data?.detail
            || this.$gettext('Error during activation dates validation')
          );
        })
        .finally(() => {
          this.loadingCapabilities = false;
        });
    },
    submitActivation() {
      if (this.isActivationToUpdate) {
        this.updateActivation();
      } else {
        this.createActivation();
      }
    },
    buildPayload() {
      return {
        approval: this.approvalSelected.id,
        date_start: this.dates[0],
        date_end: this.dates[this.dates.length - 1],
        time_start: this.startTime,
        time_end: this.endTime,
      };
    },
    createActivation() {
      this.loadingSubmitActivation = true;
      const payload = this.buildPayload();
      APIService.postActivation(payload)
        .then(({ data }) => {
          if (data.status === 'error') {
            const message = (
              data.events[0]?.message
              || this.$gettext('Activation could not be created')
            );
            this.showMessage(message, 'error');
          } else {
            this.showMessage(this.$gettext('Activation created successfully'), 'success');
          }
          this.$emit('create-activation', this.approvalSelected, data);
        })
        .finally(() => {
          this.loadingSubmitActivation = false;
        });
    },
    updateActivation() {
      this.loadingSubmitActivation = true;
      const payload = this.buildPayload();
      APIService.updateActivation(this.activation.id, payload)
        .then(({ data }) => {
          if (data.status === 'error') {
            const message = (
              data.events[0]?.message
              || this.$gettext('Activation could not be updated')
            );
            this.showMessage(message, 'error');
          } else {
            this.showMessage(this.$gettext('Activation updated successfully'), 'success');
          }
          this.$emit('update-activation', data);
        })
        .finally(() => {
          this.loadingSubmitActivation = false;
        });
    },
  },
};
</script>
