<template>
  <v-expansion-panels
    light
    flat
    v-model="detailsPanelExpanded"
  >
    <v-expansion-panel
      expand-icon="keyboard_arrow_down"
      class="dense__expansion-panel"
    >
      <!-- Flight Details -->
      <v-expansion-panel-header>
        <span class="dense__expansion-panel__header-content">
          <v-icon
            class="pr-2"
            color="info"
          >
            mdi-clipboard-text
          </v-icon>
          <span v-translate>Flight details</span>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- FLIGHT DETAILS -->
        <div class="details-card pt-2">
          <v-row no-gutters>
            <!-- Number -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                FLIGHT NUMBER
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.id }}
              </span>
            </v-col>
            <!-- Name -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                NAME
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.name }}
              </span>
            </v-col>
            <!-- Dates -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                DATES
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ flight.date_start | dateShort }} - {{ flight.date_end | dateShort }}
              </span>
            </v-col>
            <!-- Latitude -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                LATITUDE
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ latitude | coord }} ({{latitude | coordDMS(false)}})
              </span>
            </v-col>
            <!-- Longitude -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                LONGITUDE
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="4"
            >
              <span>
                {{ longitude | coord }} ({{longitude | coordDMS(true)}})
              </span>
            </v-col>
          </v-row>
          <!-- Zones -->
          <v-row no-gutters>
            <hr class="separator" />
            <!-- Address -->
            <v-col
              cols="5"
              sm="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                ADDRESS
              </span>
            </v-col>
            <v-col
              cols="7"
              sm="10"
            >
              <span v-if="flight.address">
                {{ flight.address }}
              </span>
              <span v-else>
                {{ flight.commune }}
              </span>
            </v-col>
            <!-- Flight areas -->
            <template v-for="(area, index) in flight.areas">
              <hr
                :key="`separator-${index}`"
                class="separator"
              />
              <v-row
                no-gutters
                :key="`area-${index}`"
              >
                <!-- Flight area name -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    ZONE
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <div class="d-flex align-center">
                    <span>
                      {{ area.name }}
                    </span>
                    <v-btn
                      icon
                      small
                      class="flight-listing__action-button"
                      @click="centerOnFlightArea(area.id)"
                      @mouseenter="setFlightAreaHover(area.id)"
                      @mouseleave="setFlightAreaHover(null)"
                    >
                      <v-icon>mdi-map-marker-outline</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <!-- Flight area height -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    HEIGHT
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <span>
                    {{ area.height | meter }}
                  </span>
                </v-col>
                <!-- Flight area surface -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    SURFACE
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <span>
                    {{ area.surface | squareMeter }}
                  </span>
                </v-col>
                <!-- Flight area extent -->
                <v-col
                  cols="5"
                  sm="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    EXTENT
                  </span>
                </v-col>
                <v-col
                  cols="7"
                  sm="10"
                >
                  <span>
                    {{ area.max_extent | scaleMeter }}
                  </span>
                </v-col>
              </v-row>
            </template>
          </v-row>
          <!-- Pilots -->
          <template v-for="pilot in flight.pilots">
            <hr
              :key="pilot.id"
              class="separator"
            />
            <v-row
              no-gutters
              :key="'pilot-' + pilot.id"
            >
              <!-- Pilot name -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  PILOT
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ pilot.full_name }}
                </span>
              </v-col>
              <!-- Pilot email -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  EMAIL
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ pilot.email }}
                </span>
              </v-col>
              <!-- Pilot phone number -->
              <v-col
                cols="5"
                sm="2"
              >
                <span
                  class="details-card__label"
                  v-translate
                >
                  PHONE NUMBER
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <span>
                  {{ pilot.phone_number | phone }}
                </span>
              </v-col>
            </v-row>
          </template>
          <!-- Sub exploitants -->
          <template v-if="hasSubExploitant">
            <hr class="separator" />
            <v-row
              no-gutters
              align="center"
            >
              <v-col
                cols="5"
                sm="2"
              >
                <span class="details-card__label text-uppercase">
                  {{ entityName.singular }}
                </span>
              </v-col>
              <v-col
                cols="7"
                sm="10"
              >
                <v-chip
                  color="grey lighten-3"
                  class="mr-1"
                >
                  {{ subExploitant }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
        </div>
        <v-spacer />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { SET_FLIGHT_AREA_HOVER_NS, ZOOM_TO_AREA_NS } from '@/store/map';

export default {
  name: 'FlightDetailsSimplified',
  props: { flight: Object },
  data() {
    return { detailsPanelExpanded: undefined };
  },
  computed: {
    latitude() {
      return this.flight.lat;
    },
    longitude() {
      return this.flight.lng;
    },
    hasSubExploitant() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    subExploitant() {
      if (this.hasSubExploitant) {
        const subExploitant = this.$store.state.authentication.userSubExploitants.find(
          (s) => s.id === this.flight.sub_exploitant,
        );
        return subExploitant?.name || '';
      }
      return '';
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  methods: {
    centerOnFlightArea(areaId) {
      const features = this.$store.getters['flights/flightAreasSelected'];
      const area = features.find((feature) => areaId === feature.id);
      this.$store.dispatch(ZOOM_TO_AREA_NS, area.geometry);
    },
    setFlightAreaHover(areaId) {
      this.$store.dispatch(SET_FLIGHT_AREA_HOVER_NS, areaId);
    },
  },
};
</script>
