<template>
  <v-row
    no-gutters
    align="center"
  >
    <!-- Approval center and status -->
    <v-col
      cols="12"
      md="4"
      class="px-3"
    >
      <div class="d-flex align-center">
        <StatusIcon
          :status="approval.status"
          :isClosed="approval.is_closed"
          :transparentBackground="false"
        />
        <span
          class="pl-3 pr-1 subtitle-1"
          v-translate
        >
          FLIGHT
        </span>
        <template v-if="approvalLoading">
          <v-skeleton-loader
            type="text"
            width="87"
            class="mt-1 pl-1"
          />
        </template>
        <template v-else>
          <span class="subtitle-1 font-weight-medium">
            {{ approval.display_identifier }}
          </span>
          <span
            v-if="approval.is_closed"
            class="subtitle-1 font-weight-medium pl-1"
            v-translate
          >
            CANCELLED
          </span>
          <span class="subtitle-1 font-weight-medium pl-1">
            ({{ approval.company_name }})
          </span>
          <div
            v-if="approval.category_name"
            class="pl-2"
          >
            <v-chip
              small
              color="primary"
            >
              <span class="white--text font-weight-medium">
                {{ approval.category_name }}
              </span>
            </v-chip>
          </div>
        </template>
      </div>
    </v-col>
    <!-- Actions buttons -->
    <v-col
      cols="12"
      md="4"
      class="px-2"
    >
      <!-- Approval decisions button -->
      <template v-if="!approval.is_closed">
        <v-chip
          v-if="isAuthorityAdminOrManager"
          outlined
          color="primary"
        >
          <span
            class="subtitle-1 font-weight-medium pr-2"
            v-translate
          >
            YOUR ANSWER
          </span>
          <v-skeleton-loader
            v-if="approvalDetailsLoading || loadingStructures"
            type="text"
            width="74"
            class="mt-1 pl-1"
          />
          <!-- Decision buttons for protocol sheet -->
          <template v-else-if="generateApprovalProtocolSheet">
            <!-- Refuse button -->
            <v-tooltip
              v-if="approval.status !== 'refused'"
              bottom
              color="error"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="openDialogStatus('refused')"
                >
                  <StatusIcon status="refused" />
                </v-btn>
              </template>
              <translate>Refuse approval.</translate>
            </v-tooltip>
            <!-- Ask for protocol sheet button -->
            <v-tooltip
              bottom
              color="blue-grey"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="openDialogStatus('incomplete')"
                >
                  <StatusIcon status="incomplete" />
                </v-btn>
              </template>
              <translate>Ask for protocol sheet signature.</translate>
            </v-tooltip>
            <!-- Validation protocol sheet button -->
            <v-tooltip
              v-if="['awaiting_validation', 'incomplete'].includes(approval.status)"
              bottom
              color="success"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="openDialogStatus('accepted')"
                >
                  <StatusIcon status="accepted" />
                </v-btn>
              </template>
              <translate>Validate protocol sheet.</translate>
            </v-tooltip>
          </template>
          <!-- Decision buttons for classic authority -->
          <template v-else>
            <!-- Refuse button -->
            <v-tooltip
              bottom
              color="error"
              v-if="approval.status !== 'refused'"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="openDialogStatus('refused')"
                >
                  <StatusIcon status="refused" />
                </v-btn>
              </template>
              <translate v-if="isUserPrefecture">Refuse flight notification.</translate>
              <translate v-else>Refuse approval.</translate>
            </v-tooltip>
            <!-- Incomplete button -->
            <v-tooltip
              v-if="activatedIncompleteStatus"
              bottom
              color="blue-grey"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="openDialogStatus('incomplete')"
                >
                  <StatusIcon status="incomplete" />
                </v-btn>
              </template>
              <translate>Ask for additional information.</translate>
            </v-tooltip>
            <!-- Accept with reserves button -->
            <v-tooltip
              bottom
              color="warning"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="openDialogStatus('reserves')"
                >
                  <StatusIcon status="reserves" />
                </v-btn>
              </template>
              <translate v-if="isUserPrefecture">
                Accept flight notification with restrictions.
              </translate>
              <translate v-else>Authorize approval with restriction.</translate>
              <span v-translate></span>
            </v-tooltip>
            <!-- Accept button -->
            <v-tooltip
              bottom
              color="success"
              v-if="approval.status !== 'accepted'"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="openDialogStatus('accepted')"
                >
                  <StatusIcon status="accepted" />
                </v-btn>
              </template>
              <translate v-if="isUserPrefecture">Accept flight notification.</translate>
              <translate v-else>Authorize approval.</translate>
            </v-tooltip>
          </template>
        </v-chip>
        <v-chip
          v-else
          outlined
          color="primary"
        >
          <span class="subtitle-1 font-weight-medium pr-2">
            <span
              v-if="approval.en_route_by_superviser"
              key="landing"
              v-translate
            >
              MARK AS LANDING
            </span>
            <span
              v-else
              key="takeoff"
              v-translate
            >
              MARK AS EN ROUTE
            </span>
          </span>
          <ChangeApprovalEnRoute
            :approval="approval"
            transparentBackground
          />
        </v-chip>
      </template>
    </v-col>
    <!-- Approval request opinion button -->
    <v-col
      cols="12"
      md="3"
      class="pl-7"
    >
      <v-chip
        v-if="!approval.is_closed && isAuthorityAdminOrManager"
        outlined
        color="primary"
        @click="dialogOpinion=true"
      >
        <v-icon
          left
          color="primary"
        >
          mdi-plus-circle
        </v-icon>
        <span
          class="subtitle-1 font-weight-medium pl-1"
          v-translate
        >
          REQUEST ANOTHER OPINION
        </span>
      </v-chip>
    </v-col>
    <!-- Close button -->
    <v-col
      cols="12"
      md="1"
      class="d-flex justify-end"
    >
      <v-icon @click="close()">
        close
      </v-icon>
    </v-col>
    <!-- Dialog to change approval status -->
    <v-dialog
      v-model="dialogStatus"
      width="650"
      height="800"
      persistent
      :retain-focus="false"
    >
      <ChangeApprovalStatusForm
        :approval="approval"
        :statusForUpdate.sync="statusForUpdate"
        :updateStatusLoading.sync="updateStatusLoading"
        @close-form="() => dialogStatus = false"
      />
    </v-dialog>
    <!-- Dialog to send opinion -->
    <v-dialog
      v-model="dialogOpinion"
      width="650"
      height="800"
      persistent
      :retain-focus="false"
    >
      <OpinionForm @close-form="() => dialogOpinion = false" />
    </v-dialog>
  </v-row>
</template>

<script>
import { RESET_DATA_STORES_NS } from '@/store/application';

import ChangeApprovalEnRoute from '@/components/Approvals/ChangeApprovalEnRoute.vue';
import ChangeApprovalStatusForm from '@/components/Approvals/ChangeApprovalStatusForm.vue';
import OpinionForm from '@/components/Opinions/OpinionForm.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'ApprovalHeader',
  components: {
    ChangeApprovalEnRoute,
    ChangeApprovalStatusForm,
    OpinionForm,
    StatusIcon,
  },
  props: {
    approval: Object,
    approvalLoading: Boolean,
    approvalDetailsLoading: Boolean,
  },
  data() {
    return {
      dialogStatus: false,
      statusForUpdate: null,
      updateStatusLoading: false,
      dialogOpinion: false,
    };
  },
  computed: {
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
    structureSettings() {
      return this.$store.getters['structures/structureSettings'];
    },
    generateApprovalProtocolSheet() {
      return this.structureSettings.generate_approval_protocol_sheet;
    },
    activatedIncompleteStatus() {
      return this.structureSettings.activated_incomplete_status;
    },
    loadingStructures() {
      return this.$store.state.structures.loadingStructures;
    },
  },
  methods: {
    openDialogStatus(status) {
      this.statusForUpdate = status;
      this.dialogStatus = true;
    },
    close() {
      this.$store.dispatch(RESET_DATA_STORES_NS);
      this.$router.push({ name: 'approvals' });
    },
  },
};
</script>
