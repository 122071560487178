import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import activations from '@/store/activations';
import application from '@/store/application';
import approvals from '@/store/approvals';
import authentication from '@/store/authentication';
import cotations from '@/store/cotations';
import drones from '@/store/drones';
import exploitants from '@/store/exploitants';
import flights from '@/store/flights';
import livestreams from '@/store/livestreams';
import map from '@/store/map';
import mapDataWatcherPlugin from '@/store/mapPlugin';
import notifications from '@/store/notifications';
import opinions from '@/store/opinions';
import pilots from '@/store/pilots';
import status from '@/store/status';
import structures from '@/store/structures';
import users from '@/store/users';
import traces from '@/store/traces';
import websocketNotificationsPlugin from '@/store/websocketNotificationsPlugin';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    activations,
    application,
    approvals,
    authentication,
    cotations,
    drones,
    exploitants,
    flights,
    livestreams,
    map,
    notifications,
    opinions,
    pilots,
    status,
    structures,
    users,
    traces,
  },
  plugins: [
    mapDataWatcherPlugin,
    websocketNotificationsPlugin,
    createPersistedState({
      key: 'clearance-store',
      paths: [
        'authentication',
      ],
    }),
  ],
});
