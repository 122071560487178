<template>
  <v-card
    outlined
    elevation="0"
  >
    <div class="pt-4 px-4 pb-0 d-flex justify-space-between">
      <span
        class="primary--text body-2 font-weight-medium"
        v-translate
      >
        AUTHORISATION OUTSIDE THE STANDARD SCENARIO
      </span>
      <span class="caption">
        <v-tooltip
          bottom
          color="info"
          max-width=680
          open-on-click
          :open-on-hover="false"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon class="small-icon pl-2 cursor-pointer">mdi-information-outline</v-icon>
            </span>
          </template>
          <div v-html="tooltips.hss" />
        </v-tooltip>
      </span>
    </div>
    <v-card-text class="pt-2">
      <v-row
        no-gutters
        align="center"
      >
        <v-col
          cols="12"
          sm="3"
        >
          <span
            class="primary--form__label"
            v-translate
          >
            REFERENCE
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="9"
        >
          <v-text-field
            v-model="hss_authorization_form"
            class="ma-0 p-0"
          />
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <span
            class="primary--form__label"
            v-translate
          >
            STARTING VALIDITY
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          class="pr-0 pr-sm-5"
        >
          <v-menu
            ref="hssDateStartMenu"
            v-model="hssDateStartMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="!hss_authorization_form"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="hss_date_start_form"
                class="ma-0 p-0"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="hss_date_start_form"
              no-title
              scrollable
              locale="fr"
              first-day-of-week="1"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="hssDateStartMenu = false"
              >
                <translate>Cancel</translate>
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.hssDateStartMenu.save(hss_date_start_form)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <span
            class="primary--form__label"
            v-translate
          >
            ENDING VALIDITY
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-menu
            ref="hssDateEndMenu"
            v-model="hssDateEndMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="!hss_authorization_form"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="hss_date_end_form"
                class="ma-0 p-0"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="hss_date_end_form"
              no-title
              scrollable
              locale="fr"
              first-day-of-week="1"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="hssDateEndMenu=false"
              >
                <translate>Cancel</translate>
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.hssDateEndMenu.save(hss_date_end_form)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>

export default {
  name: 'HssAuthorizationForm',
  props: {
    hss_authorization: {
      type: String,
      required: false,
      default: '',
    },
    hss_date_start: {
      type: String,
      required: false,
      default: '',
    },
    hss_date_end: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      alphatangoExploitantsNames: null,
      hssDateStartMenu: false,
      hssDateEndMenu: false,
    };
  },
  computed: {
    text() {
      return { test: 'test' };
    },
    hss_authorization_form: {
      get() {
        return this.hss_authorization;
      },
      set(value) {
        this.$emit('update:hss_authorization', value);
        if (!value) {
          this.hss_date_start_form = null;
          this.hss_date_end_form = null;
        }
      },
    },
    hss_date_start_form: {
      get() {
        return this.hss_date_start;
      },
      set(value) {
        this.$emit('update:hss_date_start', value);
      },
    },
    hss_date_end_form: {
      get() {
        return this.hss_date_end;
      },
      set(value) {
        this.$emit('update:hss_date_end', value);
      },
    },
    tooltips() {
      return {
        hss: this.$gettext(`Indicate here the references (No. and date) of the authorisation of the
          notified flights may be performed with the aircraft concerned. This may be:<br>- an
          operating permit issued in accordance with Article 12 of Regulation (EU) 2019/947<br>- a
          UAS Operator Certificate (LUC)<br>- a national authorisation remaining valid until its
          expiry<br>- for certified aircraft: a laissez-passer or a certificate of airworthiness
          <br>WARNING: the European UAS operator registration number (FRAxxxxxxx for France) and
          the aircraft registration number (UAS-FR-xxxxxxx) don't constitute a valid authorisation
          number: see above the expected authorisation types.<br>If any doubt exists, contact
          alphatango-assistance-pp-bf@aviation-civile.gouv.fr`),
      };
    },
  },

};

</script>
