<template>
  <div>
    <ul class="ml-2">
      <li
        v-for="(info, index) in infoList"
        :key="index"
      >
        <a
          v-if="isLinkInfo(info)"
          :href="info.content"
          target="_blank"
        >
          {{ info.label }}
        </a>
        <div
          v-else-if="isLongTextInfo(info)"
          class="cursor-pointer text-decoration-underline primary--text d-inline"
          @click="longInfoDialog = true; longInfoContentDialog = info"
        >
          {{ info.label }}
        </div>
        <span v-else>
          <span v-if="info.label">
            {{ info.label }} :
          </span>
          <span>
            {{ info.content }}
          </span>
        </span>
      </li>
    </ul>
    <!-- Long text info dialog -->
    <v-dialog
      v-if="longInfoContentDialog"
      v-model="longInfoDialog"
      max-width="820px"
      @click:outside="longInfoDialog = false; longInfoContentDialog = null"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          {{ longInfoContentDialog.label }}
        </v-card-title>
        <v-card-text class="pa-4">
          <div class="px-4 pb-2 multilines">
            {{ longInfoContentDialog.content }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CONSTRAINT_INFO_TYPE } from '@/settings';

export default {
  name: 'FlightConstraintInfoList',
  props: { infoList: Array },
  data() {
    return {
      longInfoDialog: false,
      longInfoContentDialog: null,
    };
  },
  methods: {
    isLinkInfo(info) {
      return info.type === CONSTRAINT_INFO_TYPE.LINK;
    },
    isLongTextInfo(info) {
      return info.type === CONSTRAINT_INFO_TYPE.LONG_TEXT;
    },
  },
};
</script>
