<template>
  <div class="sub-exploitants-list">
    <!-- All flying times -->
    <v-container class="px-1 px-md-3 flex-grow-0">
      <div class="flying-times header mb-4">
        <div class="headline">
          <translate>Flying Time</translate>
        </div>
        <div class="flying-times buttons">
          <v-btn
            @click="dialog=true"
            color="primary"
            depressed
            :loading="loadingPilots"
            key="add"
          >
            <translate>+ Add a flying time</translate>
          </v-btn>
          <v-btn
            v-if="isManagerOrAdmin"
            @click="dialogExport=true"
            color="primary"
            depressed
            key="export"
            :disabled="flyingTimes.length === 0"
          >
            <translate>Export</translate>
          </v-btn>
        </div>
      </div>
      <div>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$gettext('Search')"
          single-line
          hide-details
        />
        <v-data-table
          ref="datatable"
          item-key="id"
          :headers="headers"
          :items="flyingTimes"
          :loading="loadingFlyingTimes"
          :search="search"
          class="mt-4"
          :class="{transparent: false}"
          :loading-text="labels.loadingText"
          :no-data-text="labels.noResultText"
          :no-results-text="labels.noResultText"
          :mobile-breakpoint="singleColumnBreakpoint"
          :footer-props="{
            'items-per-page-text': $gettext('Lines per page:'),
            'items-per-page-all-text': $gettext('All'),
          }"
          light
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          />
          <template v-slot:[`item.category`]="{ item }">
            <v-chip
              label
              x-small
              color="primary"
              class="px-1"
            >
              <span v-if="item.is_manager">manager</span>
              <span v-else-if="item.is_admin">admin</span>
              <span v-else>pilot</span>
            </v-chip>
          </template>
          <template v-slot:[`item.entities`]="{ item }">
            <div
              class="chips-group-flex-wrap"
              :class="{'justify-end': isMobileBreakpoint}"
            >
              <v-chip
                v-for="(subExploitant, key) in item.sub_exploitants"
                :key="key"
                x-small
              >
                {{ subExploitant }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>
              {{ item.created_at | toLocaleDate }}
            </span>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <span>
              {{ item.date | date }}
            </span>
          </template>
          <template v-slot:[`item.duration`]="{ item }">
            <span>
              {{ item.duration | hour }}
            </span>
          </template>
          <template v-slot:[`item.comment`]="{ item }">
            <span>
              {{ item.comment }}
            </span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <div class="d-flex">
              <v-btn
                icon
                :loading="loadingPilots"
                @click="openDialogUpdateFlyingTime(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="openDialogDelete(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }}
            <span v-translate>from</span>
            {{ items.itemsLength }}
          </template>
        </v-data-table>
      </div>
    </v-container>
    <v-divider />
    <!-- Stats by user -->
    <v-container class="px-1 px-md-3 flex-grow-0">
      <div class="d-flex justify-space-between my-4">
        <div class="headline">
          <translate>Flying time statistics by pilot</translate>
        </div>
      </div>
      <div>
        <v-text-field
          v-model="searchStats"
          append-icon="search"
          :label="$gettext('Search')"
          single-line
          hide-details
        />
        <v-data-table
          ref="datatable-stats"
          item-key="id"
          :headers="headersStatistics"
          :items="stats"
          :loading="loadingFlyingTimeStats"
          :search="searchStats"
          class="mt-4"
          :class="{transparent: false}"
          :loading-text="labels.loadingText"
          :no-data-text="labels.noResultText"
          :no-results-text="labels.noResultText"
          :mobile-breakpoint="singleColumnBreakpoint"
          :footer-props="{
            'items-per-page-text': $gettext('Lines per page:'),
            'items-per-page-all-text': $gettext('All'),
          }"
          light
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          />
          <template v-slot:[`item.sub_exploitants`]="{ item }">
            <div
              class="chips-group-flex-wrap"
              :class="{'justify-end': isMobileBreakpoint}"
            >
              <v-chip
                v-for="(subExploitant, key) in item.sub_exploitants"
                :key="key"
                x-small
              >
                {{ subExploitant.name }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.nb_flights_this_year`]="{ item }">
            <span>
              {{ item.nb_flights_this_year || 0 }}
            </span>
          </template>
          <template v-slot:[`item.duration_this_year`]="{ item }">
            <span>
              {{ item.duration_this_year || '00:00:00' | hour }}
            </span>
          </template>
          <template v-slot:[`item.nb_flights_one_year_ago`]="{ item }">
            <span>
              {{ item.nb_flights_one_year_ago || 0 }}
            </span>
          </template>
          <template v-slot:[`item.duration_one_year_ago`]="{ item }">
            <span>
              {{ item.duration_one_year_ago || '00:00:00' | hour }}
            </span>
          </template>
          <template v-slot:[`item.nb_flights_two_years_ago`]="{ item }">
            <span>
              {{ item.nb_flights_two_years_ago || 0 }}
            </span>
          </template>
          <template v-slot:[`item.duration_two_years_ago`]="{ item }">
            <span>
              {{ item.duration_two_years_ago || '00:00:00' | hour }}
            </span>
          </template>
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }}
            <span v-translate>from</span>
            {{ items.itemsLength }}
          </template>
        </v-data-table>
      </div>
    </v-container>
    <!-- Dialog action on flying time -->
    <v-dialog
      v-model="dialog"
      max-width="700px"
      :retain-focus="false"
      :fullscreen="isMobileBreakpoint"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <div>
            <translate
              v-if="flyingTime.id"
              key="update"
            >
              Update flying time
            </translate>
            <translate
              v-else
              key="create"
            >
              Input new flying time
            </translate>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="pa-2"
          >
            <v-row
              v-if="isManagerOrAdmin"
              no-gutters
              align="center"
              class="my-1"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <span class="primary--form__label">
                  {{ labels.pilot }}
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-autocomplete
                  v-model="flyingTime.pilot"
                  :loading="loadingPilots"
                  :items="pilots"
                  item-text="full_name"
                  item-value="id"
                  :no-data-text="$gettext('No pilot found')"
                  :rules="rules.required"
                  class="pa-0"
                />
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="my-1"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <span class="primary--form__label">
                  {{ labels.date }}
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-date-picker
                  v-model="flyingTime.date"
                  no-title
                  required
                  full-width
                  :max="today"
                  class="elevation-0 py-2"
                  locale="fr"
                  first-day-of-week="1"
                />
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="my-1"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <span class="primary--form__label">
                  {{ labels.nbFlights }}
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-text-field
                  v-model="flyingTime.nbFlights"
                  single-line
                  type="number"
                  :rules="rules.required"
                  class="pa-0"
                />
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="mx-1"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <span class="primary--form__label">
                  {{ labels.duration }}
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <TimePicker
                  :time="flyingTime.duration"
                  @update:time="flyingTime.duration = $event"
                  :rules="rules.required"
                  :minuteInterval="15"
                  dropDirection="up"
                  appendIcon="mdi-timelapse"
                />
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="my-1"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <span class="primary--form__label">
                  {{ labels.comment }}
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-textarea
                  v-model="flyingTime.comment"
                  rows="2"
                  class="pa-0"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="actionOnClick()"
            :loading="loadingActionOnFlyingTime"
            :disabled="!valid"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog delete flying time -->
    <v-dialog
      v-model="dialogDelete"
      max-width="700px"
      :retain-focus="false"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Delete flying time</translate>
        </v-card-title>
        <v-card-text>
          <translate>Are you sure to delete this flying time?</translate>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDialogDelete()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="deleteFlyingTime()"
            :loading="loadingDelete"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog export flying times -->
    <v-dialog
      v-model="dialogExport"
      max-width="700px"
      :retain-focus="false"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Export flying times</translate>
        </v-card-title>
        <v-card-text>
          <span>
            {{ texts.export }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="dialogExport=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="exportFlyingTimes()"
            :loading="loadingExport"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import TimePicker from '@/components/TimePicker.vue';

import APIService from '@/services/api';

import { GET_SIMPLIFIED_PILOTS_NS } from '@/store/pilots';

export default {
  name: 'FlyingTime',
  components: { TimePicker },
  data() {
    return {
      loadingFlyingTimes: false,
      flyingTimes: [],
      search: '',
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
      flyingTime: {
        id: null,
        pilot: null,
        date: null,
        nbFlights: 0,
        duration: '00:15:00',
        comment: '',
      },
      dialog: false,
      valid: false,
      rules: {
        required: [
          (value) => !!value || this.$gettext('A value is required.'),
        ],
      },
      loadingActionOnFlyingTime: false,
      dialogDelete: false,
      loadingDelete: false,
      loadingFlyingTimeStats: false,
      stats: [],
      stats_years: undefined,
      searchStats: '',
      dialogExport: false,
      loadingExport: false,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    headers() {
      return [
        {
          text: this.$gettext('Category'),
          value: 'category',
          width: '8%',
        },
        {
          text: this.$gettext('Creator'),
          value: 'creator',
          width: '15%',
        },
        {
          text: this.$gettext('Pilot'),
          value: 'contact_full_name',
          width: '15%',
        },
        {
          text: this.entityName.plural,
          value: 'entities',
          width: '17%',
        },
        {
          text: this.$gettext('Created at'),
          value: 'created_at',
          width: '10%',
        },
        {
          text: this.$gettext('Flight date'),
          value: 'date',
          width: '12%',
        },
        {
          text: this.$gettext('Number of flights'),
          value: 'nb_flights',
          width: '5%',
        },
        {
          text: this.$gettext('Duration'),
          value: 'duration',
          width: '5%',
        },
        {
          text: this.$gettext('Comment'),
          value: 'comment',
          width: '16%',
        },
        {
          text: '',
          value: 'edit',
          width: '20%',
          sortable: false,
        },
      ];
    },
    headersStatistics() {
      return [
        {
          text: this.$gettext('Pilot'),
          value: 'full_name',
          width: '15%',
        },
        {
          text: this.entityName.plural,
          value: 'sub_exploitants',
          width: '25%',
        },
        {
          text: `${this.$gettext('Number of flights')} ${this.stats_years?.this_year || ''}`,
          value: 'nb_flights_this_year',
          width: '10%',
        },
        {
          text: `${this.$gettext('Duration of flights')} ${this.stats_years?.this_year || ''}`,
          value: 'duration_this_year',
          width: '10%',
        },
        {
          text: `${this.$gettext('Number of flights')} ${this.stats_years?.one_year_ago || ''}`,
          value: 'nb_flights_one_year_ago',
          width: '10%',
        },
        {
          text: `${this.$gettext('Duration of flights')} ${this.stats_years?.one_year_ago || ''}`,
          value: 'duration_one_year_ago',
          width: '10%',
        },
        {
          text: `${this.$gettext('Number of flights')} ${this.stats_years?.two_years_ago || ''}`,
          value: 'nb_flights_two_years_ago',
          width: '10%',
        },
        {
          text: `${this.$gettext('Duration of flights')} ${this.stats_years?.two_years_ago || ''}`,
          value: 'duration_two_years_ago',
          width: '10%',
        },
      ];
    },
    today() {
      const date = new Date();
      return format(date, 'yyyy-MM-dd');
    },
    loadingPilots() {
      return this.$store.state.pilots.loadingPilots;
    },
    pilots() {
      return this.$store.getters['pilots/pilotsOrderingByFullName'];
    },
    isManagerOrAdmin() {
      return (
        this.$store.getters['authentication/isUserExploitantAdmin']
        || this.$store.getters['authentication/isUserExploitantManager']
      );
    },
    labels() {
      return {
        loadingText: this.$gettext('Flying times loading...'),
        noResultText: this.$gettext('No flying times found'),
        pilot: this.$gettext('Pilot'),
        date: this.$gettext('Date of flights'),
        nbFlights: this.$gettext('Number of flights'),
        duration: this.$gettext('Total duration'),
        comment: this.$gettext('Comment'),
      };
    },
    texts() {
      return {
        export: this.$gettextInterpolate(
          this.$gettext(`An export of all data of your flying times will be prepared and sent to
            you at the email address linked to your account (%{email}). You should get it in a few
            minutes.`),
          { email: this.$store.state.authentication.user.email },
        ),
      };
    },
    subExploitantSelected() {
      return this.$store.state.authentication.selectedSubExploitant;
    },
    contactIdConnected() {
      return this.$store.state.authentication.user.contact_id;
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  watch: {
    subExploitantSelected() {
      this.getFlyingTimes();
      this.getFlyingTimeStats();
    },
  },
  async created() {
    if (this.isManagerOrAdmin) {
      this.$store.dispatch(GET_SIMPLIFIED_PILOTS_NS);
    }
    this.flyingTime.pilot = this.contactIdConnected;
    this.getFlyingTimes();
    this.getFlyingTimeStats();
    this.flyingTime.date = this.today;
  },
  methods: {
    async getFlyingTimes() {
      this.loadingFlyingTimes = true;
      await APIService.getFlyingTimes(this.subExploitantSelected)
        .then(({ data }) => {
          this.flyingTimes = data;
        })
        .finally(() => {
          this.loadingFlyingTimes = false;
        });
    },
    async getFlyingTimeStats() {
      this.loadingFlyingTimeStats = true;
      await APIService.getFlyingTimeStats(this.subExploitantSelected)
        .then(({ data }) => {
          this.stats = data.stats;
          this.stats_years = data.years;
        })
        .finally(() => {
          this.loadingFlyingTimeStats = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.flyingTime = {
        id: null,
        pilot: this.contactIdConnected,
        date: this.today,
        nbFlights: 0,
        duration: '00:15:00',
        comment: '',
      };
    },
    openDialogUpdateFlyingTime(item) {
      this.flyingTime.id = item.id;
      this.flyingTime.pilot = item.contact;
      this.flyingTime.date = item.date;
      this.flyingTime.nbFlights = item.nb_flights;
      this.flyingTime.duration = item.duration;
      this.flyingTime.comment = item.comment;
      this.dialog = true;
    },
    actionOnClick() {
      if (this.$refs.form.validate()) {
        this.loadingActionOnFlyingTime = true;
        let promise;
        let message;
        if (this.flyingTime.id) {
          promise = APIService.updateFlyingTime(
            this.flyingTime.id,
            this.flyingTime.pilot,
            this.flyingTime.date,
            this.flyingTime.nbFlights,
            this.flyingTime.duration,
            this.flyingTime.comment,
          );
          message = this.$gettext('Flying time successfully updated.');
        } else {
          promise = APIService.createFlyingTime(
            this.flyingTime.pilot,
            this.flyingTime.date,
            this.flyingTime.nbFlights,
            this.flyingTime.duration,
            this.flyingTime.comment,
          );
          message = this.$gettext('Flying time successfully created.');
        }
        promise.then(() => {
          this.getFlyingTimes();
          this.getFlyingTimeStats();
          this.showMessage(message, 'success');
        })
          .finally(() => {
            this.loadingActionOnFlyingTime = false;
            this.closeDialog();
          });
      }
    },
    openDialogDelete(id) {
      this.flyingTime.id = id;
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.dialogDelete = false;
      this.flyingTime.id = null;
    },
    deleteFlyingTime() {
      this.loadingDelete = true;
      APIService.deleteFlyingTime(this.flyingTime.id)
        .then(() => {
          this.getFlyingTimes();
          this.getFlyingTimeStats();
          this.showMessage(
            this.$gettext('Flying time successfully deleted.'),
            'success',
          );
        })
        .finally(() => {
          this.loadingDelete = false;
          this.closeDialogDelete();
        });
    },
    exportFlyingTimes() {
      this.loadingExport = true;
      APIService.exportFlyingTimes()
        .then(() => {
          this.showMessage(
            this.$gettext('You will receive an email with your export soon.'),
            'success',
          );
        })
        .finally(() => {
          this.dialogExport = false;
          this.loadingExport = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flying-times {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 16px;

  &.header {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &.buttons {
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
  }
}

</style>
