<template>
  <v-expansion-panels
    v-model="panelExpanded"
    light
    class="my-2"
  >
    <v-expansion-panel expand-icon="keyboard_arrow_down">
      <v-expansion-panel-header>
        <span class="dense__expansion-panel__header-content">
          {{ title }}
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mt-2">
        <div
          v-for="(obj, index) in documentsPerCategories"
          :key="index"
        >
          <span class="info--text text--lighten-1 pl-2">
            {{ obj.categoryName }} :
          </span>
          <template v-for="document in obj.documents">
            <v-chip
              :key="document.id"
              class="documents__chip caption"
            >
              <span
                class="documents__link-label"
                @click="downloadDocument(document.id, document.name)"
              >
                {{ document.name }}
              </span>
              <span v-if="document.expiration_date">
                &nbsp;({{ document.expiration_date }})
              </span>
            </v-chip>
          </template>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import APIService from '@/services/api';

export default {
  name: 'RelatedFlightDocuments',
  props: {
    type: String,
    documents: {
      type: Array,
      required: true,
      default: () => [],
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return { panelExpanded: undefined };
  },
  computed: {
    titles() {
      const numberOfDocuments = this.documents.length;
      return {
        exploitant: this.$gettextInterpolate(
          this.$ngettext(
            '%{n} Document of the exploitant',
            '%{n} Documents of the exploitant',
            numberOfDocuments,
          ),
          { n: numberOfDocuments },
        ),
        drone: this.$gettextInterpolate(
          this.$ngettext(
            '%{n} Document of the drone %{ name }',
            '%{n} Documents of the drone %{ name }',
            numberOfDocuments,
          ),
          { n: numberOfDocuments, name: this.name },
        ),
        pilot: this.$gettextInterpolate(
          this.$ngettext(
            '%{n} Document of the pilot %{ name }',
            '%{n} Documents of the pilot %{ name }',
            numberOfDocuments,
          ),
          { n: numberOfDocuments, name: this.name },
        ),
      };
    },
    title() {
      return this.titles[this.type];
    },
    exploitantDocumentCategories() {
      return this.$store.state.exploitants.documentCategories;
    },
    droneDocumentCategories() {
      return this.$store.state.drones.documentCategories;
    },
    pilotDocumentCategories() {
      return this.$store.state.pilots.documentCategories;
    },
    categories() {
      return {
        exploitant: this.exploitantDocumentCategories,
        drone: this.droneDocumentCategories,
        pilot: this.pilotDocumentCategories,
      };
    },
    category() {
      return this.categories[this.type];
    },
    documentsPerCategories() {
      return this.category
        .map((c) => ({
          categoryName: c.label,
          value: c.value,
          documents: this.documents
            .filter((d) => d.category === c.value)
            .sort((a, b) => (new Date(a.created_at) - new Date(b.created_at))),
        }))
        .filter((obj) => obj.documents.length);
    },
  },
  methods: {
    downloadDocument(documentId, documentName) {
      APIService.downloadDocument(documentId)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    closePanel() {
      this.panelExpanded = undefined;
    },
  },
};
</script>
