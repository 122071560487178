<template>
  <div>
    <div v-if="hasManyAreas">
      <span v-translate>
        Your flight has many areas. You have to select areas concerned by this approval *:
      </span>
      <v-row
        align="center"
        class="py-2"
      >
        <v-col
          v-for="(area, index) in flightAreas"
          :key="index"
          cols="12"
          sm="4"
        >
          <v-item
            v-slot="{ toggle }"
            :value="area"
          >
            <div :class="getClass(area)">
              <v-card
                :disabled="isDisabled(area)"
                :elevation="isDisabled(area) ? 0 : 1"
                @click="toggle(); selectArea(area.id);"
                class="pa-4"
              >
                <div
                  v-if="isItemDisabled(area)"
                  class="on-map"
                >
                  <span
                    v-if="area.has_approval"
                    v-translate
                  >
                    Has approval
                  </span>
                  <span
                    v-else-if="!area.impacting_structure"
                    v-translate
                  >
                    No approval required
                  </span>
                  <span
                    v-else
                    v-translate
                  >
                    Geometry too big
                  </span>
                </div>
                <div class="d-flex justify-center">
                  <v-img
                    v-if="area.image"
                    :src="area.image"
                  />
                </div>
                <!-- Info flight area -->
                <v-row
                  no-gutters
                  align="center"
                  class="mt-4"
                >
                  <v-col cols="10">
                    <span>
                      {{ area.name }}
                    </span>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-center"
                  >
                    <v-tooltip
                      v-if="isItemDisabled(area)"
                      max-width="320px"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="info-area"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span v-if="isDisabled(area)">
                        {{ getExplainDisabled(area) }}
                      </span>
                      <span v-else>
                        {{ texts.areaErrorGeometry }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <!-- Geometry info -->
                <v-row
                  no-gutters
                  align="center"
                >
                  <v-col cols="10">
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <v-col cols="6">
                        <span v-translate>Surface</span>
                      </v-col>
                      <v-col cols="6">
                        {{ getGeometrySurface(area.geometry) | squareMeter }}
                      </v-col>
                    </v-row>
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <v-col cols="6">
                        <span v-translate>Extent</span>
                      </v-col>
                      <v-col cols="6">
                        {{ getGeometryMaxExtent(area.geometry) | scaleMeter }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="!(isDisabled(area) || checkValidGeometry(area.geometry))"
                    cols="2"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      icon
                      small
                      :loading="area.slicingButtonLoading"
                      @click.stop="editFlightArea(area.id, index)"
                      class="icon-clickable"
                    >
                      <v-icon color="black">mdi-content-cut mdi-rotate-315</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-item>
        </v-col>
      </v-row>
    </div>
    <!-- Global geometry information -->
    <div
      v-if="!loading"
      class="pl-2"
    >
      <v-row
        no-gutters
        align-center
        class="font-weight-medium"
        :class="globalSurfaceRespected ? 'primary--text' : 'error--text'"
      >
        <v-col cols="5">
          <translate v-if="hasManyAreas">Global surface of selected areas</translate>
          <translate v-else>Global surface of area</translate>
        </v-col>
        <v-col cols="7">
          {{ getGeometrySurface(this.globalFlightAreasSelectedGeometry) | squareMeter }}
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align-center
        class="font-weight-medium"
        :class="globalExtentRespected ? 'primary--text' : 'error--text'"
      >
        <v-col cols="5">
          <span
            v-if="hasManyAreas"
            v-translate
          >
            Global extent of selected areas
          </span>
          <span
            v-else
            v-translate
          >
            Global extent of area
          </span>
        </v-col>
        <v-col cols="7">
          {{ getGeometryMaxExtent(this.globalFlightAreasSelectedGeometry) | scaleMeter }}
        </v-col>
      </v-row>
      <!-- Slice area if only one area and error geometry -->
      <div v-if="!(globalSurfaceRespected && globalExtentRespected) && !hasManyAreas">
        <span>
          {{ texts.oneAreaErrorGlobalGeometry }}
        </span>
        <v-btn
          icon
          small
          :loading="slicingButtonLoading"
          @click.stop="editFlightArea(flightAreas.at(0).id)"
        >
          <v-icon>mdi-content-cut mdi-rotate-315</v-icon>
        </v-btn>
      </div>
    </div>
    <v-skeleton-loader
      v-else
      type="image"
    />
  </div>
</template>

<script>
import APIService from '@/services/api';

import { SET_SLICE_FLIGHT_AREA_NS } from '@/store/flights';

import variables from '@/styles/variables.scss';

import { getStaticImageUrl } from '@/components/Map/MapboxGL.helper';

export default {
  name: 'FlightAreasSelection',
  props: {
    flightId: Number,
    structureId: Number,
    flightAreasSelected: Array,
    flightAreasSelectedNames: {
      type: Array,
      required: false,
    },
    initSelection: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxExtent: {
      type: Number,
      required: false,
      default: null,
    },
    maxSurface: {
      type: Number,
      required: false,
      default: null,
    },
    maxAreaSurface: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      flightAreas: [],
      slicingButtonLoading: false,
    };
  },
  computed: {
    texts() {
      return {
        oneAreaErrorGlobalGeometry: this.$gettext(`Your flight area doesn't respect requirements
          for an approval request. You can slice it by clicking on`),
        areaErrorGeometry: this.$gettext(`This flight area doesn't respect requirements for an
          approval request. You can slice it by clicking on the scissors icon below.`),
      };
    },
    hasManyAreas() {
      return this.flightAreas.length > 1;
    },
    mapPreviewProperties() {
      const color = variables.flightSubmittedBackgroundColor;
      return {
        stroke: color,
        'stroke-width': 3,
        'stroke-opacity': 1,
        fill: color,
        'fill-opacity': 0.2,
      };
    },
    selection: {
      get() {
        return this.flightAreasSelected;
      },
      set(value) {
        this.$emit('update:flightAreasSelected', value);
      },
    },
    selectionFlightAreas() {
      return this.flightAreas.filter((a) => this.selection.includes(a.id));
    },
    selectionNames() {
      return this.selectionFlightAreas.map((a) => a.name);
    },
    globalFlightAreasSelectedGeometry() {
      return this.$turf.multiPolygon(this.selectionFlightAreas.map((a) => a.geometry.coordinates));
    },
    globalExtentRespected() {
      return this.checkExtent(this.globalFlightAreasSelectedGeometry);
    },
    globalSurfaceRespected() {
      let globalSurfaceRespected = true;
      const globalSurface = this.getGeometrySurface(this.globalFlightAreasSelectedGeometry);
      if (this.maxSurface) {
        globalSurfaceRespected = globalSurface <= this.maxSurface;
      }
      if (this.maxAreaSurface && !this.hasManyAreas) {
        globalSurfaceRespected = globalSurfaceRespected && globalSurface <= this.maxAreaSurface;
      }
      return globalSurfaceRespected;
    },
  },
  watch: {
    selectionNames(newValue) {
      this.$emit('update:flightAreasSelectedNames', newValue);
    },
  },
  async created() {
    this.loading = true;
    await APIService.getFlightAreasDetails(this.flightId, this.structureId)
      .then(({ data }) => {
        this.flightAreas = data.map(
          (a) => ({ ...a, slicingButtonLoading: false }),
        ).sort((a, b) => this.isDisabled(a) > this.isDisabled(b));
        if (this.initSelection) {
          this.selection = this.flightAreas.filter((a) => !this.isDisabled(a)).map((a) => a.id);
        } else {
          this.selection = [];
        }
        this.generateImages();
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getOverlayGeojson(geometry) {
      return {
        type: 'Feature',
        properties: this.mapPreviewProperties,
        geometry,
      };
    },
    generateImages() {
      for (let i = 0; i < this.flightAreas.length; i += 1) {
        const flightArea = this.flightAreas.at(i);
        const mapPreviewGeojson = this.getOverlayGeojson(flightArea.geometry);
        flightArea.image = getStaticImageUrl({
          overlayGeojson: mapPreviewGeojson,
          clearProperties: false,
          width: 800,
          height: 400,
        });
      }
    },
    getClass(flightArea) {
      if (this.isDisabled(flightArea)) {
        return 'disabled';
      }
      if (this.selection.includes(flightArea.id)) {
        if (!this.checkValidGeometry(flightArea.geometry)) {
          return 'error';
        }
        return 'active';
      }
      return 'not-active';
    },
    selectArea(areaId) {
      if (this.selection.includes(areaId)) {
        const index = this.selection.findIndex((id) => id === areaId);
        this.selection.splice(index, 1);
      } else {
        this.selection.push(areaId);
      }
    },
    isDisabled(flightArea) {
      return flightArea.has_approval || !flightArea.impacting_structure;
    },
    isItemDisabled(flightArea) {
      return this.isDisabled(flightArea) || !this.checkValidGeometry(flightArea.geometry);
    },
    getExplainDisabled(flightArea) {
      if (flightArea.has_approval) {
        return this.$gettext(`You already have submitted an approval for this flight area so it not
          concerned by an approval request for this constraint.`);
      }
      return this.$gettext(`This flight area is outside of responsability area of authorities
          concerned by this constraint so you don't have to request approval for this area.`);
    },
    getGeometrySurface(geometry) {
      return Math.round(this.$turf.area(geometry));
    },
    getGeometryMaxExtent(geometry) {
      const [west, south, east, north] = this.$turf.bbox(geometry);
      const distanceHorizontal = this.$turf.distance([west, south], [east, south]);
      const distanceVertical = this.$turf.distance([west, south], [west, north]);
      const maxExtentKilometers = Math.max(distanceHorizontal, distanceVertical);
      const maxExtentMeters = maxExtentKilometers * 1000;
      return Math.round(maxExtentMeters) || 0;
    },
    checkSurface(geometry) {
      const surface = this.maxAreaSurface ? this.maxAreaSurface : this.maxSurface;
      return surface ? this.getGeometrySurface(geometry) <= surface : true;
    },
    checkExtent(geometry) {
      return this.maxExtent ? this.getGeometryMaxExtent(geometry) <= this.maxExtent : true;
    },
    checkValidGeometry(geometry) {
      return this.checkExtent(geometry) && this.checkSurface(geometry);
    },
    editFlightArea(flightAreaId, index = null) {
      if (index) {
        this.flightAreas[index].slicingButtonLoading = true;
      } else {
        this.slicingButtonLoading = true;
      }
      APIService.checkAreaCanBeDeleted(this.flightId, flightAreaId)
        .then(({ data }) => {
          if (data) {
            this.$store.commit(SET_SLICE_FLIGHT_AREA_NS, {
              flightAreaId,
              maxExtent: this.maxExtent,
              maxSurface: this.maxAreaSurface ? this.maxAreaSurface : this.maxSurface,
            });
          } else {
            this.showMessage(
              this.$gettext('You can not slicing an area linked to an approval.'),
              'error',
            );
          }
        })
        .finally(() => {
          if (index) {
            this.flightAreas[index].slicingButtonLoading = false;
          } else {
            this.slicingButtonLoading = false;
          }
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.active {
  border: 4px solid $color-primary;
  border-radius: 6px;
}
.not-active {
  border: 4px solid transparent;
}
.disabled {
  border: 4px solid $color-disabled;
  border-radius: 6px;
}
.error {
  border: 4px solid $color-error;
  border-radius: 6px;
  user-select: none;
}
.info-area {
  color: black;
  pointer-events: auto;
}
.on-map {
  background-color: $color-disabled;
  color: black;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-bottom-left-radius: 6px;
  padding: 4px 8px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.icon-clickable {
  pointer-events: auto;
  user-select: auto;
}

</style>
