<template>
  <v-container class="px-1 px-md-3">
    <div class="d-flex align-center justify-space-between mb-4">
      <div class="headline">
        <translate>My company</translate>
      </div>
    </div>
    <v-skeleton-loader
      v-if="loadingExploitationDetails || loadingAnnouncement"
      type="list-item-two-line, image, actions"
    />
    <div v-else>
      <!-- My company -->
      <!-- Commercial name -->
      <template>
        <template v-if="commercialNameEditing">
          <v-row
            no-gutters
            align="center"
            class="pb-1 pt-3 pt-md-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <div
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                Commercial name
              </div>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-form
                lazy-validation
                v-model="commercialNameValid"
              >
                <v-text-field
                  v-model="commercialName"
                  :placeholder="placeholders.commercialName"
                  :rules="rules.commercialName"
                  class="p-0"
                />
              </v-form>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            justify="end"
            align="center"
          >
            <v-btn
              class="grey lighten-4 info--text mx-2 my-1"
              depressed
              @click="commercialNameEditing=false; commercialNameNewValue=commercialName;"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              class="grey lighten-4 primary--text mx-2 my-1"
              depressed
              :disabled="!commercialNameValid"
              :loading="commercialNameLoading"
              @click="changeCommercialName()"
            >
              <translate>Validate commercial name</translate>
            </v-btn>
          </v-row>
        </template>
        <template v-else>
          <v-row
            no-gutters
            align="center"
            class="py-3"
          >
            <v-col
              cols="12"
              md="4"
            >
              <div
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                Commercial name
              </div>
            </v-col>
            <v-col
              cols="11"
              md="7"
            >
              {{ commercialName }}
            </v-col>
            <v-col
              v-if="isExploitantAdmin"
              cols="1"
              class="d-flex justify-end"
            >
              <v-btn
                icon
                @click="commercialNameEditing=true"
              >
                <v-icon>icon-edit_line</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </template>
      <!-- AlphaTango UID -->
      <template v-if="!hasManyAlphaTangoUids">
        <template v-if="alphaTangoUidEditing">
          <v-row
            no-gutters
            align="center"
            class="pb-1 pt-3 pt-md-0"
          >
            <v-col
              cols="12"
              md="4"
              class="d-flex align-center"
            >
              <span
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                AlphaTango UID
              </span>
              <v-tooltip
                bottom
                color="info"
                max-width="580px"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="small-icon ml-2"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  {{ texts.alphaTangoUidHelpText }}
                </span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-form
                lazy-validation
                v-model="alphaTangoUidValid"
              >
                <v-text-field
                  v-model="alphaTangoUid"
                  :placeholder="placeholders.alphaTangoUid"
                  :rules="rules.alphaTangoUid"
                  class="p-0"
                />
              </v-form>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            justify="end"
            align="center"
          >
            <v-btn
              class="grey lighten-4 info--text mx-2 my-1"
              depressed
              @click="alphaTangoUidEditing=false; alphaTangoUidNewValue=alphaTangoUid;"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              class="grey lighten-4 primary--text mx-2 my-1"
              depressed
              :disabled="!alphaTangoUidValid"
              :loading="alphaTangoUidLoading"
              @click="changeAlphaTangoUid()"
            >
              <translate>Validate AlphaTango UID</translate>
            </v-btn>
          </v-row>
        </template>
        <template v-else>
          <v-row
            no-gutters
            align="center"
            class="py-3"
          >
            <v-col
              cols="12"
              md="4"
              class="d-flex align-center"
            >
              <span
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                AlphaTango UID
              </span>
              <v-tooltip
                bottom
                color="info"
                max-width="580px"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="small-icon ml-2"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  {{ texts.alphaTangoUidHelpText }}
                </span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="11"
              md="7"
            >
              {{ alphaTangoUid }}
            </v-col>
            <v-col
              v-if="isExploitantAdmin"
              cols="1"
              class="d-flex justify-end"
            >
              <v-btn
                icon
                @click="alphaTangoUidEditing=true"
              >
                <v-icon>icon-edit_line</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </template>
      <!-- If many AlphaTango UIDs -->
      <v-row
        v-else
        no-gutters
        align="center"
        class="py-3"
      >
        <v-col
          cols="12"
          md="4"
        >
          <span
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            AlphaTango UIDs
          </span>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-list-group
            v-model="expandAlphaTangoUidsList"
            :color="null"
          >
            <template v-slot:activator>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
              >
                <translate>Display AlphaTango UIDs</translate>
                <v-btn
                  v-if="isExploitantAdmin"
                  icon
                  small
                  @click.stop="addAlphaTangoUidDialog=true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-row>
            </template>
            <v-list class="px-4">
              <v-list-item
                v-for="(item, index) in exploitationAlphaTangoUidsAndName"
                :key="index"
                class="pa-0"
              >
                <v-list-item-content class="pa-0">
                  {{ item.name }}
                </v-list-item-content>
                <v-list-item-action v-if="isExploitantAdmin">
                  <v-btn
                    icon
                    small
                    @click="openAlphaTangoUidDeletionDialog(item.uid)"
                  >
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-list-group>
        </v-col>
      </v-row>
      <!-- HSS authorization -->
      <template v-if="canEditHssAuthorization">
        <v-row
          no-gutters
          align="center"
          class="py-3"
        >
          <v-col
            cols="12"
            md="4"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              HSS authorization
            </div>
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            {{exploitationDetails.hss_authorization }}
            ({{exploitationDetails.hss_date_start | date}}
            - {{exploitationDetails.hss_date_end | date}})
          </v-col>
          <v-col
            cols="1"
            class="d-flex justify-end"
          >
            <v-btn
              icon
              @click="openHssDialog()"
            >
              <v-icon>icon-edit_line</v-icon>
            </v-btn>

          </v-col>
        </v-row>
      </template>
      <!-- Announcement -->
      <v-row
        v-if="displayAnnouncements"
        no-gutters
        align="center"
        class="py-3"
      >
        <v-col
          cols="12"
          md="4"
        >
          <span
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            Announcement
          </span>
          <v-tooltip
            bottom
            color="info"
            max-width="580px"
            open-on-click
            :open-on-hover="false"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="small-icon ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span v-html="texts.announcement" />
          </v-tooltip>
        </v-col>
        <v-col
          cols="10"
          md="6"
          class="truncated"
        >
          <span
            v-if="announcement"
            class="cell"
          >
            {{ announcement.text }}
          </span>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-end"
        >
          <v-btn
            v-if="announcement"
            icon
            @click="announcementViewerDialog=true"
          >
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="openAnnouncementDialog()"
          >
            <v-icon>icon-edit_line</v-icon>
          </v-btn>
          <v-btn
            v-if="announcement"
            icon
            @click="announcementDeletionDialog=true"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- Generalist mission times -->
      <template v-if="canEditGeneralistFlightTimes">
        <v-row
          no-gutters
          align="start"
          class="py-3"
        >
          <v-col
            cols="12"
            md="4"
            class="py-2"
          >
            <div
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              Generalist mission
            </div>
          </v-col>
          <v-col
            :cols="generalistFlightTimesEditing ? `12` : `11`"
            :md="generalistFlightTimesEditing ? `8` : `7`"
          >
            <!-- Generalist mission time start -->
            <v-row
              no-gutters
              align="start"
            >
              <v-col
                cols="5"
                md="2"
                class="py-2"
              >
                <div
                  class="body-2 grey--text text--darken-2"
                  v-translate
                >
                  Time start
                </div>
              </v-col>
              <v-col
                cols="7"
                md="10"
              >
                <template v-if="generalistFlightTimesEditing">
                  <TimePicker
                    :time="generalistFlightTimeStart"
                    @update:time="generalistFlightTimeStart = $event"
                    :rules="rules.required"
                  />
                </template>
                <template v-else>
                  <div class="py-2">
                    {{ generalistFlightTimeStart | hour2 }}
                  </div>
                </template>
              </v-col>
            </v-row>
            <!-- Generalist mission time end -->
            <v-row
              no-gutters
              align="start"
              class="py-2"
            >
              <v-col
                cols="5"
                md="2"
                class="py-2"
              >
                <div
                  class="body-2 grey--text text--darken-2"
                  v-translate
                >
                  Time end
                </div>
              </v-col>
              <v-col
                cols="7"
                md="10"
              >
                <template v-if="generalistFlightTimesEditing">
                  <TimePicker
                    :time="generalistFlightTimeEnd"
                    @update:time="generalistFlightTimeEnd = $event"
                    :rules="rules.required"
                  />
                </template>
                <template v-else>
                  <div class="py-2">
                    {{ generalistFlightTimeEnd | hour2 }}
                  </div>
                </template>
              </v-col>
            </v-row>
            <!-- Generalist mission times changes validation -->
            <v-row
              v-if="generalistFlightTimesEditing"
              no-gutters
              justify="end"
            >
              <v-btn
                class="grey lighten-4 info--text mx-2 my-1"
                depressed
                @click="generalistFlightTimesEditing=false"
              >
                <translate>Cancel</translate>
              </v-btn>
              <v-btn
                class="grey lighten-4 primary--text mx-2 my-1"
                depressed
                :disabled="!generalistFlightTimesValid"
                :loading="generalistFlightTimesChangesLoading"
                @click="changeGeneralistFlightTimes()"
              >
                <translate>Validate</translate>
              </v-btn>
            </v-row>
          </v-col>
          <v-col
            v-if="!generalistFlightTimesEditing"
            cols="1"
            class="py-2 d-flex justify-end"
          >
            <v-btn
              text
              icon
              class="edit-icon"
              @click="generalistFlightTimesEditing=true"
            >
              <v-icon>icon-edit_line</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <hr class="separator" />
      <!-- Documents -->
      <div class="mt-8">
        <div class="headline">
          <translate>Documents</translate>
        </div>
        <v-row
          no-gutters
          align="start"
          justify="space-between"
          class="py-3"
        >
          <v-col
            cols="12"
            md="9"
          >
            <ExploitationDocuments
              :exploitantId="exploitationId"
              :readOnly="!isExploitantAdmin"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pl-md-8 pt-4 pt-md-0"
          >
            <v-card
              elevation="0"
              color="grey lighten-3"
            >
              <v-card-text>
                {{ texts.insuranceAdDescription }}
              </v-card-text>
              <v-card-actions class="d-flex justify-center pb-4">
                <v-btn
                  color="primary"
                  :href="partnerLink()"
                  target="_blank"
                  text
                >
                  <translate>Read more</translate>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- AlphaTangoUID creation dialog -->
    <v-dialog
      v-if="addAlphaTangoUidDialog"
      v-model="addAlphaTangoUidDialog"
      max-width="580px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title v-translate>Add an AlphaTango UID</v-card-title>
        <v-card-text>
          <span>
            {{ texts.createAlphaTangoUid }}
          </span>
          <v-form
            lazy-validation
            v-model="alphaTangoUidValid"
          >
            <v-text-field
              v-model="alphaTangoUidNewValue"
              :label="labels.alphaTangoUid"
              :rules="rules.alphaTangoUid"
              class="p-0"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeAddAlphaTangoUidDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!alphaTangoUidValid"
            @click="addAlphaTangoUid()"
            :loading="alphaTangoUidLoading"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- AlphaTangoUID deletion dialog-->
    <v-dialog
      v-if="deleteAlphaTangoUidDialog"
      v-model="deleteAlphaTangoUidDialog"
      max-width="580px"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title v-translate>Remove an AlphaTango UID</v-card-title>
        <v-card-text>
          <translate :translate-params="{alphaTangoUid: alphaTangoUidToDelete}">
            Are you sure to delete this AlphaTango UID %{ alphaTangoUid }?
          </translate>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDeleteAlphaTangoUidDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :loading="alphaTangoUidLoading"
            @click="removeAlphaTangoUid()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Announcement viewer dialog -->
    <v-dialog
      v-if="announcementViewerDialog"
      v-model="announcementViewerDialog"
      max-width="840px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title v-translate>Announcement viewer</v-card-title>
        <v-card-text>
          <v-sheet
            light
            outlined
            class="pa-12"
          >
            <vue-markdown
              :source="announcementViewer"
              :html="false"
              xhtml-out
              typographer
              linkify
              breaks
              show
              :emoji="false"
              :anchorAttributes="{ target: '_blank' }"
              class="markdown"
            />
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="announcementViewerDialog=false"
          >
            <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Announcement creation and update dialog-->
    <v-dialog
      v-model="announcementDialog"
      max-width="840px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span
            v-if="announcement"
            key="update"
            v-translate
          >
            Update announcement
          </span>
          <span
            v-else
            key="create"
            v-translate
          >
            Create announcement
          </span>
        </v-card-title>
        <v-card-text>
          <span v-html="texts.announcement" />
          <v-form
            lazy-validation
            v-model="validAnnouncement"
            class="mt-2"
          >
            <v-textarea
              v-model="announcementText"
              :label="labels.announcement"
              :rules="rules.required"
              rows="15"
            />
          </v-form>
          <translate>You can preview the text by clicking here:</translate>
          <v-btn
            icon
            @click="announcementViewerDialog=true"
          >
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeAnnouncementDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingAnnouncementAction"
            :disabled="!validAnnouncement"
            @click="createAnnouncements()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Announcement deletion dialog-->
    <v-dialog
      v-if="announcementDeletionDialog"
      v-model="announcementDeletionDialog"
      max-width="840px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Delete announcement</translate>
        </v-card-title>
        <v-card-text>
          <translate>Are you sure you want to delete this announcement?</translate>
          <v-sheet
            light
            outlined
            class="mt-2 pa-12"
          >
            <vue-markdown
              :source="announcement.text"
              html
              xhtml-out
              typographer
              linkify
              breaks
              show
              :emoji="false"
              :anchorAttributes="{ target: '_blank' }"
              class="markdown"
            />
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="announcementDeletionDialog=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingAnnouncementAction"
            @click="deleteAnnouncements()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- HSS Authorization dialog-->
    <v-dialog
      v-model="showHssDialog"
      max-width="640px"
    >
      <v-card class="default--dialog__card">
        <HssAuthorizationForm
          :hss_authorization.sync="hssAuthorizationNewValue"
          :hss_date_start.sync="hssDateStartNewValue"
          :hss_date_end.sync="hssDateEndNewValue"
        />
        <v-card-actions>
          <v-btn
            class="grey lighten-4 info--text mx-2 my-1"
            depressed
            @click="showHssDialog=false;"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            class="grey lighten-4 primary--text mx-2 my-1"
            depressed
            :loading="hssAuthorizationLoading"
            @click="changeHssAuthorization()"
          >
            <translate>Validate hss authorization</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueMarkdown from 'vue-markdown';

import API from '@/services/api';

import { URL_AIRCOURTAGE_PARTNER, URL_WELCOME_AIRCOURTAGE } from '@/settings';

import {
  ADD_ALPHATANGO_UID_NS,
  GET_EXPLOITATION_DETAILS_NS,
  GET_LATEST_ANNOUNCEMENT_NS,
  REMOVE_ALPHATANGO_UID_NS,
  CHANGE_GENERALIST_FLIGHT_TIMES_NS,
} from '@/store/exploitants';

import ExploitationDocuments from '@/components/Settings/Dronists/ExploitationDocuments.vue';
import HssAuthorizationForm from '@/components/Drones/HssAuthorization.vue';
import TimePicker from '@/components/TimePicker.vue';

export default {
  name: 'Exploitation',
  components: {
    VueMarkdown,
    ExploitationDocuments,
    HssAuthorizationForm,
    TimePicker,
  },
  data() {
    return {
      commercialNameNewValue: null,
      commercialNameEditing: false,
      commercialNameValid: false,
      commercialNameLoading: false,
      alphaTangoUidNewValue: null,
      alphaTangoUidEditing: false,
      alphaTangoUidValid: false,
      alphaTangoUidLoading: false,
      hssAuthorizationNewValue: null,
      hssAuthorizationLoading: false,
      hssDateStartNewValue: null,
      hssDateEndNewValue: null,
      expandAlphaTangoUidsList: false,
      alphaTangoExploitantsNames: [],
      addAlphaTangoUidDialog: false,
      deleteAlphaTangoUidDialog: false,
      alphaTangoUidToDelete: null,
      loadingAnnouncement: false,
      announcementViewerDialog: false,
      announcementDialog: false,
      validAnnouncement: false,
      announcementText: null,
      loadingAnnouncementAction: false,
      announcementDeletionDialog: false,
      showHssDialog: false,
      generalistFlightTimesEditing: false,
      generalistFlightTimeStartNewValue: null,
      generalistFlightTimeEndNewValue: null,
      generalistFlightTimesChangesLoading: false,
      rules: {
        alphaTangoUid: [
          (v) => (!Number.isNaN(Number(v)) || v === '') || this.$gettext('A valid number is required.'),
          (v) => (v > 0 || v === '') || this.$gettext('A valid number is required.'),
          (v) => v <= 2147483647 || this.$gettext('A more small number is required.'), // backend limit
        ],
        commercialName: [
          (v) => !!v.trim() || this.$gettext('Company name is required.'),
        ],
        required: [
          (v) => !!v || this.$gettext('This field is required.'),
        ],
      },
    };
  },
  computed: {
    texts() {
      return {
        alphaTangoUidHelpText: this.$gettext(`Number called "UID identifier to be communicated to
          delegates" in the Delegation section of the "My operator activity" page of your the
          AlphaTango account.`),
        createAlphaTangoUid: this.$gettext(`By adding a new AlphaTango UID, you will be able to
          import drones linked to this AlphaTango account.`),
        insuranceAdDescription: this.$gettext(`You are not insured yet? Thanks to our partnership
          with Air Courtage get 10% discount on your insurance with your Clearance subscription.`),
        announcement: this.$gettext(`This text will be displayed for all your pilots.<br>You can
          use <a href="https://fr.wikipedia.org/wiki/Markdown" target="_blank">Markdown</a> to
          customize your announcement.`),
      };
    },
    labels() {
      return {
        alphaTangoUid: this.$gettext('AlphaTango UID'),
        announcement: this.$gettext('Announcement text'),
      };
    },
    placeholders() {
      return {
        commercialName: this.$gettext('Usual name of your company...'),
        alphaTangoUid: this.$gettext('Your UID number, provided by DGAC'),
        hssAuthorization: this.$gettext('Authorization for out of standard scenario flights'),
      };
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    exploitationId() {
      return this.userData.exploitation_id;
    },
    exploitationDetails() {
      return this.$store.state.exploitants.exploitationDetails;
    },
    loadingExploitationDetails() {
      return !Object.keys(this.exploitationDetails).length;
    },
    isExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    hasManyAlphaTangoUids() {
      return this.$store.getters['exploitants/hasManyAlphatangoUids'];
    },
    commercialName: {
      get() {
        return this.exploitationDetails.commercial_name;
      },
      set(newValue) {
        this.commercialNameNewValue = newValue;
      },
    },
    exploitationAlphaTangoUids() {
      return this.$store.state.exploitants.exploitationDetails.alphatango_uids;
    },
    alphaTangoUid: {
      get() {
        return this.exploitationAlphaTangoUids?.length ? this.exploitationAlphaTangoUids[0] : '';
      },
      set(newValue) {
        this.alphaTangoUidNewValue = newValue;
      },
    },
    exploitationAlphaTangoUidsAndName() {
      return this.exploitationAlphaTangoUids.map((uid) => {
        if (!this.alphaTangoExploitantsNames.length) {
          return { uid, name: uid };
        }
        const alphaTangoExploitantsName = this.alphaTangoExploitantsNames.find(
          (n) => n.uid === uid,
        );
        const name = (
          alphaTangoExploitantsName
            ? alphaTangoExploitantsName.name
            : this.$gettext("No Clearance's delegant found")
        );
        return { uid, name: `${uid} - ${name}` };
      });
    },
    displayAnnouncements() {
      return this.$store.getters['exploitants/displayAnnouncements'] && this.isExploitantAdmin;
    },
    canEditHssAuthorization() {
      return this.$store.getters['exploitants/hasExploitantHssAuthorization'] && this.isExploitantAdmin;
    },
    announcement() {
      return this.$store.state.exploitants.announcement;
    },
    announcementViewer() {
      if (this.announcementText) {
        return this.announcementText;
      }
      if (this.announcement) {
        return this.announcement.text;
      }
      return '';
    },
    canEditGeneralistFlightTimes() {
      return this.$store.getters['exploitants/hasGeneralistFlightTimes'] && this.isExploitantAdmin;
    },
    generalistFlightTimeStart: {
      get() {
        return this.$store.getters['exploitants/generalistFlightTimeStart'];
      },
      set(newValue) {
        this.generalistFlightTimeStartNewValue = newValue;
      },
    },
    generalistFlightTimeEnd: {
      get() {
        return this.$store.getters['exploitants/generalistFlightTimeEnd'];
      },
      set(newValue) {
        this.generalistFlightTimeEndNewValue = newValue;
      },
    },
    generalistFlightTimesValid() {
      return this.generalistFlightTimeStartNewValue && this.generalistFlightTimeStartNewValue;
    },
  },
  created() {
    this.getExploitationDetails();
    if (this.hasManyAlphaTangoUids) {
      this.getAlphaTangoExploitantNames();
    }
    if (this.displayAnnouncements) {
      this.loadingAnnouncement = true;
      this.$store.dispatch(GET_LATEST_ANNOUNCEMENT_NS)
        .finally(() => {
          this.loadingAnnouncement = false;
        });
    }
  },
  methods: {
    async getExploitationDetails() {
      await this.$store.dispatch(GET_EXPLOITATION_DETAILS_NS, this.exploitationId);
    },
    changeCommercialName() {
      this.commercialNameLoading = true;
      API.updateExploitation(this.exploitationId, { commercial_name: this.commercialNameNewValue })
        .then(() => {
          this.showMessage(this.$gettext('The changes have been taken into account.'), 'success');
          this.getExploitationDetails();
        })
        .finally(() => {
          this.commercialNameEditing = false;
          this.commercialNameLoading = false;
        });
    },
    changeAlphaTangoUid() {
      this.alphaTangoUidLoading = true;
      const alphatangoUids = this.alphaTangoUidNewValue === '' ? [] : [this.alphaTangoUidNewValue];
      API.updateExploitation(this.exploitationId, { alphatango_uids: alphatangoUids })
        .then(() => {
          this.showMessage(this.$gettext('The changes have been taken into account.'), 'success');
          this.getExploitationDetails();
        })
        .finally(() => {
          this.alphaTangoUidEditing = false;
          this.alphaTangoUidLoading = false;
        });
    },
    changeHssAuthorization() {
      this.hssAuthorizationLoading = true;
      API.updateExploitation(this.exploitationId,
        {
          hss_authorization: this.hssAuthorizationNewValue,
          hss_date_start: this.hssDateStartNewValue,
          hss_date_end: this.hssDateEndNewValue,
        })
        .then(() => {
          this.showMessage(this.$gettext('The changes have been taken into account.'), 'success');
          this.getExploitationDetails();
        })
        .finally(() => {
          this.showHssDialog = false;
          this.hssAuthorizationLoading = false;
        });
    },
    getAlphaTangoExploitantNames() {
      API.getAlphaTangoExploitantNames()
        .then(({ data }) => {
          this.alphaTangoExploitantsNames = data;
        });
    },
    addAlphaTangoUid() {
      this.alphaTangoUidLoading = true;
      API.addAlphaTangoUid(this.exploitationId, this.alphaTangoUidNewValue)
        .then(() => {
          this.$store.dispatch(ADD_ALPHATANGO_UID_NS, Number(this.alphaTangoUidNewValue))
            .then(() => {
              this.showMessage(
                this.$gettext('AlphaTango UID added successfully.'),
                'success',
              );
            });
          this.expandAlphaTangoUidsList = true;
          this.getAlphaTangoExploitantNames();
        })
        .finally(() => {
          this.closeAddAlphaTangoUidDialog();
        });
    },
    removeAlphaTangoUid() {
      this.alphaTangoUidLoading = true;
      API.removeAlphaTangoUid(this.exploitationId, this.alphaTangoUidToDelete)
        .then(() => {
          this.$store.dispatch(REMOVE_ALPHATANGO_UID_NS, this.alphaTangoUidToDelete)
            .then(() => {
              this.showMessage(
                this.$gettext('AlphaTango UID removed successfully.'),
                'success',
              );
            });
        })
        .finally(() => {
          this.closeDeleteAlphaTangoUidDialog();
        });
    },
    closeAddAlphaTangoUidDialog() {
      this.addAlphaTangoUidDialog = false;
      this.alphaTangoUidLoading = false;
      this.alphaTangoUidNewValue = null;
    },
    openAlphaTangoUidDeletionDialog(alphaTangoUid) {
      this.alphaTangoUidToDelete = alphaTangoUid;
      this.deleteAlphaTangoUidDialog = true;
    },
    closeDeleteAlphaTangoUidDialog() {
      this.deleteAlphaTangoUidDialog = false;
      this.alphaTangoUidLoading = false;
      this.alphaTangoUidToDelete = null;
    },
    openAnnouncementDialog() {
      if (this.announcement) {
        this.announcementText = this.announcement.text;
      }
      this.announcementDialog = true;
    },
    closeAnnouncementDialog() {
      this.announcementDialog = false;
      this.loadingAnnouncementAction = false;
      this.announcementText = null;
    },
    createAnnouncements() {
      this.loadingAnnouncementAction = true;
      API.createAnnouncements(this.announcementText)
        .then(() => {
          this.showMessage(
            this.$gettext('Announcement created successfully.'),
            'success',
          );
          this.$store.dispatch(GET_LATEST_ANNOUNCEMENT_NS);
        })
        .finally(() => {
          this.closeAnnouncementDialog();
        });
    },
    deleteAnnouncements() {
      this.loadingAnnouncementAction = true;
      API.deleteAnnouncements()
        .then(() => {
          this.showMessage(
            this.$gettext('Announcement deleted successfully.'),
            'success',
          );
          this.$store.dispatch(GET_LATEST_ANNOUNCEMENT_NS);
        })
        .finally(() => {
          this.announcementDeletionDialog = false;
          this.loadingAnnouncementAction = false;
        });
    },
    partnerLink() {
      let link;
      if (!this.$store.getters['authentication/userNotSubscribed']) {
        link = `${URL_AIRCOURTAGE_PARTNER}${this.userData.insurance_code}`;
      } else {
        link = URL_WELCOME_AIRCOURTAGE;
      }
      return link;
    },
    openHssDialog() {
      this.showHssDialog = true;
      this.hssAuthorizationNewValue = this.exploitationDetails.hss_authorization;
      this.hssDateStartNewValue = this.exploitationDetails.hss_date_start;
      this.hssDateEndNewValue = this.exploitationDetails.hss_date_end;
    },
    changeGeneralistFlightTimes() {
      this.generalistFlightTimesChangesLoading = true;
      const data = {
        generalist_flight_time_start: this.generalistFlightTimeStartNewValue,
        generalist_flight_time_end: this.generalistFlightTimeEndNewValue,
      };
      API.changeGeneralistFlightTimes(this.exploitationId, data)
        .then(() => {
          this.$store.commit(CHANGE_GENERALIST_FLIGHT_TIMES_NS, data);
          this.showMessage(
            this.$gettext('Generalist mission times updated successfully.'),
            'success',
          );
          this.generalistFlightTimesEditing = false;
        })
        .finally(() => {
          this.generalistFlightTimesChangesLoading = false;
        });
    },
  },
};
</script>
