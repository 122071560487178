<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    :retain-focus="false"
    :fullscreen="isMobileBreakpoint"
    @keydown.esc="close()"
    class="pa-2"
  >
    <v-card
      class="default--dialog__card"
      elevation="0"
    >
      <v-card-title class="d-flex">
        <span v-if="flight">
          {{ texts.missionTitle}}
        </span>
        <v-icon @click="close()">close</v-icon>
      </v-card-title>
      <v-card-text class="pt-3">
        <template v-if="loading">
          <v-skeleton-loader
            type="card-heading, table-row-divider, list-item-three-line, table-row-divider@2"
            class="pa-2"
          />
        </template>
        <template v-else-if="globalWeather.length">
          <div class="d-flex justify-center align-center">
            <v-slide-group
              v-model="slider"
              show-arrows
            >
              <v-slide-item
                v-for="(weatherDay, index) in globalWeather"
                :key="index"
                v-slot="{ active }"
              >
                <v-card
                  :color="active ? 'grey lighten-2' : 'white'"
                  width="120"
                  @click="switchSlider(index)"
                  elevation="0"
                  class="ma-4"
                  :class="weatherDay.available ? '' : 'unavailable'"
                >
                  <v-row
                    no-gutters
                    justify="center"
                  >
                    {{ titleToLocalDate(weatherDay.date) }}
                  </v-row>
                  <v-row
                    no-gutters
                    justify="center"
                  >
                    <v-img
                      :src="srcImg(weatherDay.weather.icon)"
                      :max-width="100"
                    />
                  </v-row>
                  <v-row
                    no-gutters
                    justify="space-around"
                  >
                    <span>
                      {{ weatherDay.min_temp | degree }}
                    </span>
                    <span class="font-weight-medium">
                      {{ weatherDay.max_temp | degree }}
                    </span>
                  </v-row>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
          <v-divider />
          <div
            v-if="daySelected"
            class="pa-2"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="3"
                class="d-flex justify-center"
              >
                <v-img
                  :src="srcImg(daySelected.weather.icon)"
                  contain
                  aspect-ratio="2"
                />
                <span class="text-h3">
                  {{ daySelected.moy_temp | degree }}
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pl-4"
              >
                <v-row no-gutters>
                  <span v-translate>Lever du soleil :</span>&nbsp;
                  <span class="font-weight-medium">
                    {{ daySelected.sunrise | hour }}
                  </span>
                </v-row>
                <v-row no-gutters>
                  <span v-translate>Coucher du soleil :</span>&nbsp;
                  <span class="font-weight-medium">
                    {{ daySelected.sunset | hour }}
                  </span>
                </v-row>
                <v-row no-gutters>
                  <span v-translate>Vent :</span>&nbsp;
                  <span class="font-weight-medium">
                    {{ daySelected.wind_speed }} km/h
                  </span>
                </v-row>
                <v-row no-gutters>
                  <span v-translate>Précipitation :</span>&nbsp;
                  <span class="font-weight-medium">
                    {{ daySelected.precipitation }} %
                  </span>
                </v-row>
                <v-row no-gutters>
                  <span v-translate>Couverture nuageuse basse altitude :</span>&nbsp;
                  <span class="font-weight-medium">
                    {{ daySelected.clouds }} %
                  </span>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                class="align-center justify-end pr-6"
              >
                <span class="d-flex justify-end font-weight-medium text-end">
                  {{ flight.post_code }} {{ flight.commune }}
                </span>
                <span class="d-flex justify-end">
                  {{ localDate(daySelected.date) }}
                </span>
                <span class="d-flex justify-end">
                  {{ daySelected.weather.description }}
                </span>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="pt-5"
            >
              <v-tabs
                v-model="activeTab"
                grow
                color="primary"
                show-arrows
                centered
                class="mb-2"
              >
                <v-tabs-slider />
                <template v-for="(tab, index) in tabsInfo">
                  <v-tab
                    :href="tab.href"
                    :key="index"
                  >
                    {{ tab.title }}
                  </v-tab>
                </template>
              </v-tabs>
              <v-col>
                <WeatherHourly
                  :hourlyData="hourlyDataSelected"
                  :dataType="activeTab"
                  :flightTimeStart="flight.time_start"
                  :flightTimeEnd="flight.time_end"
                />
              </v-col>
            </v-row>
          </div>
          <div class="d-flex justify-end">
            <a
              class="font-italic grey--text"
              href="https://www.weatherbit.io/"
              target="_blank"
            >
              Weatherbit.io
            </a>
          </div>
        </template>
        <template v-else>
          <div class="text-center pa-3">
            <translate>
              Les données météo ne sont pas disponibles pour cette mission ou ces dates de vol
              (les informations météo sont généralement accessibles pour les 10 jours à venir).
            </translate>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import APIService from '@/services/api_hedwige';

import WeatherHourly from '@/components/Weather/WeatherHourly.vue';

export default {
  name: 'Weather',
  components: { WeatherHourly },
  data() {
    return {
      flight: null,
      dialog: false,
      loading: false,
      globalWeather: [],
      slider: 0,
      activeTab: 'temp',
      tabsInfo: [
        {
          href: '#wind',
          title: this.$gettext('Vent (km/h)'),
        },
        {
          href: '#precipitation',
          title: this.$gettext('Précipitation (%)'),
        },
        {
          href: '#clouds',
          title: this.$gettext('Couverture nuageuse (%)'),
        },
        {
          href: '#temp',
          title: this.$gettext('Température (°C)'),
        },
        {
          href: '#visibility',
          title: this.$gettext('Visibilité (km)'),
        },
      ],
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    texts() {
      return {
        missionTitle: this.$gettextInterpolate(
          this.$gettext('Mission %{id} %{name}'),
          { id: this.flight.id, name: this.flight.name },
          true,
        ),
      };
    },
    daySelected() {
      if (this.globalWeather.length && this.slider !== null) {
        return this.globalWeather[this.slider];
      }
      return null;
    },
    hourlyDataSelected() {
      if (this.daySelected && this.daySelected.available) {
        switch (this.activeTab) {
          case 'precipitation':
            return this.daySelected.hourly.map((data) => ({
              time: data.time,
              value: data.precipitation,
            }));
          case 'clouds':
            return this.daySelected.hourly.map((data) => ({
              time: data.time,
              value: data.clouds,
            }));
          case 'temp':
            return this.daySelected.hourly.map((data) => ({
              time: data.time,
              value: data.temp,
            }));
          case 'visibility':
            return this.daySelected.hourly.map((data) => ({
              time: data.time,
              value: data.visibility,
            }));
          default:
            return this.daySelected.hourly.map((data) => ({
              time: data.time,
              value: {
                speed: data.wind_speed,
                dir: data.wind_dir,
                gust: data.wind_gust_speed,
              },
            }));
        }
      }
      return [];
    },
    isSubscriptionActive() {
      return this.$store.getters['authentication/isSubscriptionActive'];
    },
    websocketToken() {
      return this.$store.state.authentication.websocketToken;
    },
  },
  methods: {
    openDialog(flight) {
      this.loading = true;
      this.slider = 0;
      this.activeTab = 'wind';
      this.flight = flight;
      this.dialog = true;
    },
    async displayWeatherDialog(flight) {
      this.openDialog(flight);
      await APIService.getGlobalWeather(
        this.flight.date_start,
        this.flight.date_end,
        this.flight.lat,
        this.flight.lng,
        this.isSubscriptionActive ? 1 : 0,
        this.websocketToken,
      )
        .then(({ data }) => {
          this.globalWeather = data;
        })
        .catch(() => {
          this.globalWeather = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
      this.flight = null;
      this.globalWeather = [];
    },
    srcImg(icon) {
      return `https://www.weatherbit.io/static/img/icons/${icon}.png`;
    },
    titleToLocalDate(date) {
      const localDate = new Date(date);
      // Handle timezones differences
      localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());
      return localDate.toLocaleDateString(
        this.$store.getters['application/currentLanguage'],
        {
          weekday: 'short',
          day: 'numeric',
          month: 'long',
        },
      );
    },
    localDate(date) {
      const localDate = new Date(date);
      // Handle timezones differences
      localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());
      return localDate.toLocaleDateString(
        this.$store.getters['application/currentLanguage'],
        {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        },
      );
    },
    switchSlider(index) {
      this.slider = index;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.unavailable {
  filter: grayscale(80%);
  opacity: 0.5;
}

</style>
