<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    />
    <div class="d-flex justify-center align-center">
      <StatusIcon
        v-if="!loading && currentPOI && currentPOI.properties !== undefined"
        :status="currentPOI.properties.status"
        :isClosed="currentPOI.properties.is_closed"
      />
      <span
        v-if="!loading"
        class="popup-id"
      >
        {{ objectIdentifier }}
      </span>
      <span
        v-if="!loading && currentPOI && currentPOI.properties !== undefined"
        class="mr-2 popup-company"
      >
        <span v-if="isUserDronist">
          {{ currentPOI.properties.name }}
        </span>
        <div
          v-else
          class="d-flex flex-column align-start justify-center"
        >
          <div>
            {{ currentPOI.properties.company_name }}
          </div>
          <div v-if="currentPOI.properties.flight">
            {{ currentPOI.properties.flight.date_start | date }}
            -
            {{ currentPOI.properties.flight.date_end | date }}
          </div>
          <div v-else-if="currentSubState === 'activations'">
            {{ currentPOI.properties.date_start | date }}
            -
            {{ currentPOI.properties.date_end | date }}
          </div>
        </div>
      </span>
    </div>
    <div
      v-if="isUserEnterprise && currentPOI && currentPOI.properties && currentPOI.properties.tags"
      class="mt-1 text-center"
    >
      <v-chip
        v-for="tag in currentPOI.properties.tags"
        :key="tag.id"
        x-small
        class="mr-2 px-2 py-2"
      >
        {{ tag }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import store from '@/store/';

import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default Vue.extend({
  // here, we link to the store in hard
  // because the popup is not a component but a Vue instance
  // this popup is used by the map, and is out of scope of our Vue App
  // our only link to data is the store,
  // so we use it to be as reactive as the main Vue App
  store,
  components: { StatusIcon },
  computed: {
    isUserDronist() {
      return this.$store.getters['authentication/isUserDronist'];
    },
    isUserEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    currentSubState() {
      return this.$store.state.route.name;
    },
    loading() {
      if (this.$store.state[this.currentSubState] === undefined) {
        return false;
      }
      return this.$store.state[this.currentSubState].loading;
    },
    idHover() {
      return this.$store.state.map.featureIdHovered.flight;
    },
    mapCenters() {
      if (['approval-page', 'approvals', 'layers', 'traces'].includes(this.currentSubState)) {
        return this.$store.getters['approvals/mapCenters'];
      }
      if (['flights', 'pilots', 'drones'].includes(this.currentSubState)) {
        return this.$store.getters['flights/mapCenters'];
      }
      if (this.currentSubState === 'opinions') {
        return this.$store.getters['opinions/mapCenters'];
      }
      if (this.currentSubState === 'activations') {
        return this.$store.getters['activations/mapCenters'];
      }
      return undefined;
    },
    currentPOI() {
      if (
        this.mapCenters === undefined
        || this.mapCenters.features === undefined
      ) {
        return undefined;
      }
      return this.mapCenters.features.find((item) => item.id === this.idHover) || {};
    },
    objectIdentifier() {
      if (this.currentPOI?.properties) {
        if (['approval-page', 'approvals', 'layers', 'opinions', 'activations', 'traces'].includes(
          this.currentSubState,
        )) {
          return this.currentPOI.properties.display_identifier?.split('-').pop();
        }
        return this.currentPOI.id;
      }
      return '';
    },
  },
});
</script>

<style scoped>
.popup-id,
.popup-company {
  margin-left: 0.5rem;
  vertical-align: middle;
}
.popup-id {
  font-weight: bold;
}

</style>
