var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pilot-list"},[_c('div',{staticClass:"d-flex align-center justify-space-between mt-4"},[_c('v-text-field',{staticClass:"searchbox",attrs:{"append-icon":_vm.search ? null : 'search',"placeholder":_vm.$gettext('Recherche'),"dark":"","solo":"","flat":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-switch',{staticClass:"small-dense__switch mx-4",attrs:{"label":_vm.$gettext('See all'),"color":"primary","dark":"","hide-details":""},model:{value:(_vm.seeAllPilots),callback:function ($$v) {_vm.seeAllPilots=$$v},expression:"seeAllPilots"}})],1),_c('v-data-table',{ref:"datatable",class:{transparent: true},attrs:{"headers":_vm.tableHeaders,"items":_vm.pilots,"loading":_vm.loading,"search":_vm.search,"custom-filter":_vm.searchWithSubExploitants,"expanded":_vm.expanded,"dark":"","single-expand":"","calculate-widths":"","loading-text":_vm.$gettext('Chargement des pilotes...'),"no-data-text":_vm.labels.noPilotFound,"item-key":"id","no-results-text":_vm.labels.noPilotFound,"mobile-breakpoint":_vm.singleColumnBreakpoint,"footer-props":{
      'items-per-page-text': _vm.$gettext('Lignes par page :'),
      'items-per-page-all-text': _vm.$gettext('Tous'),
    },"sort-desc":_vm.sortDesc},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [(_vm.isSingleColumn)?[_c('tr',{staticClass:"cursor-pointer",class:_vm.getClass(props),on:{"click":function($event){return _vm.selectItem(props)}}},[_c('td',{staticClass:"truncated cell"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[(props.item.is_valid)?[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"2"}},[(props.item.is_main_contact || props.item.has_web_account)?_c('v-tooltip',{attrs:{"color":"white","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var tooltip = ref.on;
return [_c('v-badge',{attrs:{"value":props.item.id === _vm.userContactId,"color":"red","overlap":"","dot":""}},[_c('v-icon',_vm._g({},tooltip),[_vm._v(" "+_vm._s(_vm.getPilotIcon(props.item).icon)+" ")])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-color-text"},[_vm._v(" "+_vm._s(_vm.getPilotIcon(props.item).tooltip)+" ")])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.getPilotProfile(props.item))?_c('v-chip',{staticClass:"px-1 ml-2",attrs:{"label":"","x-small":"","color":"white primary--text"}},[_vm._v(" "+_vm._s(_vm.getPilotProfile(props.item))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"truncated",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(props.item.last_name)+" ")]),_c('v-col',{staticClass:"truncated",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(props.item.first_name)+" ")])]:[_c('v-icon',[_vm._v("info")]),_vm._v("  "),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Attention données manquantes")])]],2)],1)])]:[_c('tr',{staticClass:"cursor-pointer",class:_vm.getClass(props),on:{"click":function($event){return _vm.selectItem(props)}}},[(props.item.is_valid)?[_c('td',{staticClass:"pl-3"},[(props.item.is_main_contact || props.item.has_web_account)?_c('v-tooltip',{attrs:{"color":"white","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var tooltip = ref.on;
return [_c('v-badge',{attrs:{"value":props.item.id === _vm.userContactId,"color":"red","overlap":"","dot":""}},[_c('v-icon',_vm._g({},tooltip),[_vm._v(" "+_vm._s(_vm.getPilotIcon(props.item).icon)+" ")])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-color-text"},[_vm._v(" "+_vm._s(_vm.getPilotIcon(props.item).tooltip)+" ")])]):_vm._e()],1),_c('td',[(_vm.getPilotProfile(props.item))?_c('v-chip',{staticClass:"px-1 ml-2",attrs:{"color":"white primary--text","x-small":"","label":""}},[_vm._v(" "+_vm._s(_vm.getPilotProfile(props.item))+" ")]):_vm._e()],1),_c('td',{staticClass:"truncated cell"},[_vm._v(" "+_vm._s(props.item.last_name)+" ")]),_c('td',{staticClass:"truncated cell"},[_vm._v(" "+_vm._s(props.item.first_name)+" ")]),(_vm.subExploitantsAvailable)?_c('td',{staticClass:"truncated cell"},[_c('div',{staticClass:"chips-group-flex-nowrap"},_vm._l((_vm.pilotsSubExploitants(props.item)),function(subExploitant,index){return _c('v-chip',{key:index,attrs:{"color":"white primary--text","x-small":""}},[_c('span',[_vm._v(" "+_vm._s(subExploitant.name)+" ")])])}),1)]):_vm._e()]:[_c('td'),_c('td',{staticClass:"pl-3"},[_c('v-icon',[_vm._v("info")]),_vm._v("  "),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Attention")])],1),_c('td',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Données manquantes")])]],2)]]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticClass:"pa-0",class:item.is_archived ? 'archived' : '',attrs:{"colspan":headers.length}},[_c('Pilot',{attrs:{"pilot":item},on:{"edit-pilot":function (data) { return _vm.$emit('edit-pilot', data); },"delete-pilot":function (data) { return _vm.$emit('delete-pilot', data); }}})],1)]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("de")]),_vm._v(" "+_vm._s(items.itemsLength)+" ")]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }