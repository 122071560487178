<template>
  <div
    v-if="hourlyData.length"
    class="table-container"
  >
    <div
      v-if="isWindData"
      class="gust-label"
    >
      <span
        v-translate
        class="pb-4"
      >
        Rafales
      </span>
    </div>
    <v-simple-table
      dense
      class="overflow-table"
      id="weather-table"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(data, index) in hourlyData"
              :key="index"
              class="fixed-th"
            >
              <span>{{ formatTime(data.time) }}h</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(data, index) in hourlyData"
              :key="index"
              class="text-center"
              :class="getBackgroundColor(data.time)"
            >
              <span v-if="!isWindData">
                {{ data.value }}
              </span>
              <div v-else>
                <v-icon>
                  {{ dirWindIcon(data.value.dir) }}
                </v-icon>
                <span class="wind-speed">
                  {{ data.value.speed }}
                </span>
              </div>
            </td>
          </tr>
          <tr v-if="isWindData">
            <td
              v-for="(data, index) in hourlyData"
              :key="index + '-gust'"
              class="text-center"
              :class="getBackgroundColor(data.time)"
            >
              <span class="wind-speed">
                {{ data.value.gust }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
  <div v-else>
    <div class="mt-4">
      <translate>
        Ces informations météorologiques sont disponibles uniquement avec un abonnement actif.
      </translate>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeatherHourly',
  props: {
    hourlyData: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    flightTimeStart: {
      type: String,
      required: true,
    },
    flightTimeEnd: {
      type: String,
      required: true,
    },
  },
  computed: {
    isWindData() {
      return this.dataType === 'wind';
    },
  },
  watch: {
    hourlyData(newValue) {
      if (newValue) {
        this.scrollToFlightHours();
      }
    },
    dataType(newValue) {
      if (newValue) {
        this.scrollToFlightHours();
      }
    },
  },
  mounted() {
    this.scrollToFlightHours();
  },
  methods: {
    formatTime(time) {
      return Number(time.split(':')[0]);
    },
    dirWindIcon(dir) {
      switch (dir) {
        case 'N':
          return 'mdi-arrow-down';
        case 'S':
          return 'mdi-arrow-up';
        case 'E':
          return 'mdi-arrow-left';
        case 'O':
          return 'mdi-arrow-right';
        case 'SSO':
        case 'OSO':
        case 'SO':
          return 'mdi-arrow-up mdi-rotate-45';
        case 'SSE':
        case 'ESE':
        case 'SE':
          return 'mdi-arrow-up mdi-rotate-315';
        case 'NNE':
        case 'ENE':
        case 'NE':
          return 'mdi-arrow-up mdi-rotate-225';
        case 'NNO':
        case 'ONO':
        case 'NO':
          return 'mdi-arrow-up mdi-rotate-135';
        default:
          return 'mdi-weather-windy';
      }
    },
    getBackgroundColor(time) {
      const hour = parseInt(time.split(':')[0], 10);
      const hourStart = parseInt(this.flightTimeStart.split(':')[0], 10);
      const hourEnd = parseInt(this.flightTimeEnd.split(':')[0], 10);
      if (hourStart <= hourEnd) {
        if (hour <= hourEnd && hour >= hourStart) {
          return 'flight-hour';
        }
      } else if (hour <= hourEnd || hour >= hourStart) {
        return 'flight-hour';
      }
      return '';
    },
    scrollToFlightHours() {
      this.$nextTick(() => {
        const flightHours = document.getElementsByClassName('flight-hour');
        if (flightHours.length) {
          const flightHourEnd = flightHours[flightHours.length - 1];
          flightHourEnd.scrollIntoView(false);
        }
      });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.overflow-table {
  width: 100%;
  min-width: 0;
  ::v-deep .v-data-table__wrapper {
    padding-bottom: 8px;
  }
}
.flight-hour {
  background-color: lighten($color-primary, 35%);
}
.table-container {
  max-width: 100%;
  display: flex;
}
.fixed-th {
  width: 24px;
  text-align: center;
}
.gust-label {
  display: flex;
  align-items: flex-end;
  padding-right: 12px;
}
.wind-speed {
  display: inline-block;
  min-width: 15px;
}

</style>
