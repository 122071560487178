<template>
  <div>
    <template v-if="hasRefusedCookieBeamer">
      <v-list light>
        <v-list-item-content class="mx-8">
          {{ texts.beamerDescription }}
          <div
            @click="openBeamerCookiePanel"
            class="text-decoration-underline cursor-pointer my-2"
          >
            {{ texts.beamerCookieLink }}
          </div>
        </v-list-item-content>
      </v-list>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BeamerCookies',
  computed: {
    hasRefusedCookieBeamer() {
      return !this.$store.getters['authentication/hasAcceptedCookieBeamer'];
    },
    texts() {
      return {
        beamerDescription: this.$gettext(`We would like to show you here the news features added
          to the platform.`),
        beamerCookieLink: this.$gettext(`If you want to see them, you can accept the cookies from
          our partner Beamer.`),
      };
    },
  },
  methods: {
    openBeamerCookiePanel() {
      window.axeptioSDK.requestConsent('Beamer');
    },
  },
};
</script>
