<template>
  <v-container class="px-1 px-md-3">
    <div class="headline mb-4">
      <translate>Billing information</translate>
    </div>
    <BillingInformationForm />
  </v-container>
</template>

<script>
import BillingInformationForm from '@/components/Settings/Dronists/Subscription/BillingInformationForm.vue';

export default {
  name: 'BillingInformation',
  components: { BillingInformationForm },
};
</script>
