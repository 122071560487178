/*
  Sizes to use for v-col containing the fields of a form
  Forms included in the listing split of a LayoutListingMap page

  * single -> for rows with a single column (like a calendar field or an alert or an action button)
  * duo ->  for rows with a two equal sized columns (like two checkboxes or radis)
  * label -> the column containing the label of the field (for rows with one field)
  * input -> the column containing the input field (for rows with one field)
*/

// Sizes when the lising split is in BIG display
export const BIG_DISPLAY_COLUMN_SIZES = {
  single: {
    cols: 12,
    sm: 12,
    md: 12,
    lg: 10,
    xl: 10,
  },
  duo: {
    cols: 12,
    sm: 12,
    md: 12,
    lg: 5,
    xl: 5,
  },
  label: {
    cols: 12,
    sm: 3,
    md: 3,
    lg: 3,
    xl: 3,
  },
  input: {
    cols: 12,
    sm: 9,
    md: 9,
    lg: 7,
    xl: 7,
  },
};

// Sizes when the lising split is in MEDIUM display
export const MEDIUM_DISPLAY_COLUMN_SIZES = {
  single: {
    cols: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  duo: {
    cols: 12,
    sm: 6,
    md: 12,
    lg: 6,
    xl: 6,
  },
  label: {
    cols: 12,
    sm: 3,
    md: 12,
    lg: 3,
    xl: 3,
  },
  input: {
    cols: 12,
    sm: 9,
    md: 12,
    lg: 9,
    xl: 9,
  },
};
