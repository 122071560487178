<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate
        v-if="fromSubscription"
        key="sub"
      >
        Enterprise formula
      </translate>
      <translate
        v-else
        key="func"
      >
        Enterprise functionality
      </translate>
    </v-card-title>
    <v-card-text>
      <!-- Contact form -->
      <div
        v-if="!displayCalendarLink"
        key="contactForm"
      >
        <div
          v-if="!fromSubscription"
          class="mb-4"
        >
          <template>
            {{ texts.enterpriseBegin }}
            <a href='mailto:contact@clearance.aero'>contact@clearance.aero</a>.
            {{ texts.enterpriseEnd }}
            <a href='https://clearance.aero/tarifs/'>https://clearance.aero/tarifs/</a>.
          </template>
        </div>
        <div>
          {{ texts.contactUs }}
        </div>
        <v-textarea
          v-model="message"
          outlined
          :label="labels.textArea"
          class="mt-4"
        />
      </div>
      <!-- Calendar form -->
      <div
        v-else
        key="calendarForm"
      >
        <div v-translate>Email has been sent successfully, thank you for your interest.</div>
        <div>
          <span>
            {{ texts.appointement }}
          </span>
          <a
            href="https://meetings.hubspot.com/florent-mainfroy"
            target="_blank"
          >
            https://meetings.hubspot.com/florent-mainfroy
          </a>.
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="close()"
      >
        <span
          v-if="!displayCalendarLink"
          v-translate
          key="cancelButton"
        >
          Cancel
        </span>
        <span
          v-else
          v-translate
          key="quitButton"
        >
          Quit
        </span>
      </v-btn>
      <v-btn
        v-if="!displayCalendarLink"
        color="primary"
        text
        @click="sendWarnManagersUpgradeEnterprise()"
        :loading="loadingSubscriptionUpdate"
      >
        <translate>Contact us</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { WARN_MANAGERS_UPGRADE_TO_ENTERPRISE_NS } from '@/store/exploitants';

export default {
  name: 'SubscriptionPlanEnterprise',
  props: { fromSubscription: Boolean },
  data() {
    return {
      message: '',
      displayCalendarLink: false,
    };
  },
  computed: {
    loadingSubscriptionUpdate() {
      return this.$store.state.exploitants.subscriptionWarnUpgradeLoading;
    },
    labels() {
      return { textArea: this.$gettext('This message will be sent by mail to our team') };
    },
    texts() {
      return {
        contactUs: this.$gettext(`You can be contacted by our commercial team to explain your needs
          and discuss about our offer. Please ask any questions below ; if you prefer, you can
          contact us at contact@clearance.aero.`),
        enterpriseBegin: this.$gettext(`Only enterprise account can add pilots. For more
          informations, contact us at`),
        enterpriseEnd: this.$gettext('You can check our pricing at'),
        appointement: this.$gettext(`Our team will contact you quickly or you can make an
          appointment directly with us by clicking on this link:`),
      };
    },
  },
  methods: {
    sendWarnManagersUpgradeEnterprise() {
      this.$store.dispatch(WARN_MANAGERS_UPGRADE_TO_ENTERPRISE_NS, { message: this.message })
        .then(() => {
          this.displayCalendarLink = true;
        });
    },
    close() {
      this.$emit('close');
      this.message = '';
      this.displayCalendarLink = false;
    },
  },
};
</script>
