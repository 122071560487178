export const display = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  BIG: 'BIG',
};

export const USER_TYPES = {
  EXPLOITANTS: 'exploitants', // free accounts
  SUBSCRIBERS: 'subscribers', // paid accounts
  AUTHORITIES: 'authorities', // airports accounts
  MANAGERS: 'managers', // Clearance managers
  ADMIN: 'superuser', // Clearance Admin
  AUTHORITY_USSPS: 'authority_ussps', // External authority U-Space Service Providers (Thales etc)
  DRONIST_USSPS: 'dronist_ussps', // External dronist U-Space Service Providers (dronkeeper etc)
  VISITORS: 'visitors', // visitors accounts
};

export const AUTHORITY_TYPES = {
  AIRPORT: 'airport',
  PREFECTURE: 'prefecture',
  INVITED: 'invited',
};

export const INTERFACE_VERSIONS = {
  SPLIT_SCREEN: 'split_screen',
  FULL_SCREEN: 'full_screen',
};

export const AUTHORITY_USER_ROLES = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  CONTROLLER: 'controller',
};

export const AUTHORITY_PRIVILEGES = {
  APPROVALS: 'approvals',
  ACTIVATIONS: 'activations',
};

export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZG1pcmV0IiwiYSI6ImNpd3VwNnFmdTAwdWsyeXFiOTQ2d29nY3EifQ.-uC7w8nbUZS55HQd0eocQw'; // noqa
export const MAPBOX_USERNAME = 'dmiret';
export const MAPBOX_DEFAULT_STYLE_ID = 'ciwup38c900qp2qoibfs4htrh';

export const defaultRoutes = {
  [USER_TYPES.AUTHORITIES]: '/map/approvals',
  [USER_TYPES.SUBSCRIBERS]: '/map/flights',
  [USER_TYPES.EXPLOITANTS]: '/map/flights',
  [USER_TYPES.MANAGERS]: '/map/flights',
  [USER_TYPES.ADMIN]: '/map/users',
  [USER_TYPES.AUTHORITY_USSPS]: '/map/flights',
  [USER_TYPES.DRONIST_USSPS]: '/map/flights',
  [USER_TYPES.VISITORS]: '/map/opinions',
};

export const SUBSCRIBE_LINK = '/#/settings?tabSelected=subscription&state=showPlans';
export const PRICES_LINK = 'https://clearance.aero/tarifs/';
export const TAKE_OFF_ARTICLE_LINK = 'https://clearance.aero/faire-decoller-son-drone-avec-clearance/';
export const SCENARIO_ARTICLE_LINK = 'https://clearance.aero/infographie-la-future-reglementation-drone-europeenne/';
export const LIVESTREAMS_ARTICLE_LINK = 'https://clearance.aero/live-ou-diffusion-video-en-direct-de-vos-vols-de-drone/';
export const RESSOURCES_ARTICLES_LINK = 'https://clearance.aero/ressources/';

export const CONSTRAINTS_CATEGORY = {
  IMPACTING: 'impacting',
  NEARBY: 'nearby',
  OTHER_IMPACTING: 'other_impacting',
};

export const CONSTRAINTS_PROCESS_THROUGH = {
  CLEARANCE: 'CLEARANCE',
  THALES: 'THALES',
  USK: 'USK',
};

export const CONSTRAINT_INFO_TYPE = {
  TEXT: 'text',
  LINK: 'link',
  LONG_TEXT: 'long_text',
};

export const CAPABILITY_CATEGORY = {
  APPROVAL_REQUEST: 'approval_request',
  EMAIL_REQUEST: 'email_request',
  PROTOCOL_FORM_LINKS: 'protocol_form_links',
  ARMY_NOTIF: 'army_notif',
  PREFECTURE_NOTIF: 'prefecture_notif',
};

export const NOTAMS_CATEGORY = {
  IMPORTANT: 'important',
  NOT_CHECKED: 'not_checked',
  INTERESTING: 'interesting',
  IRRELEVANT: 'irrelevant',
};

export const CONSTRAINT_DICT_ICON = {
  AD: 'icon-alert_airport',
  AGG: 'icon-alert_city',
  AGG_NEARBY: 'icon-alert_city',
  CTR: 'icon-alert_airport',
  D: 'icon-alert_danger',
  FP: 'icon-alert_epad',
  People_Gathering: 'mdi-account-multiple',
  GATHERINGPEOPLE: 'mdi-account-multiple',
  Height: 'mdi-arrow-up-bold-circle',
  HEIGHT: 'mdi-arrow-up-bold-circle',
  HP: 'icon-alert-heliport',
  HW: 'icon-alert_road',
  INFO: 'icon-alert_info', // to remove ? @2019O321
  NAT: 'icon-alert_park', // new @2019O321
  NAT_APB: 'icon-alert_park',
  NOTAM: 'icon-alert_notam',
  NZ: 'icon-alert_park',
  Off_Sight: 'icon-horsvue', // new @2019O321
  OFFSIGHT: 'icon-horsvue', // new @2019O321
  Night_Flying: 'mdi-brightness-2',
  NIGHTFLYING: 'mdi-brightness-2',
  P: 'icon-alert_prohib',
  ZICAD: 'icon-nophoto', // new @2019O321
  PRDZ: 'icon-alert_restrict', // Icon for P, R, D or ZIT
  R: 'icon-alert_restrict',
  'R-CUSTOM': 'icon-alert_airport',
  RW: 'icon-alert_railway',
  ULM: 'icon-alert_ulm',
  VOLTAC: 'icon-warzone', // new @2019O321
  SETBA: 'icon-warzone', // new @2019O321
  ITBA: 'icon-warzone', // new @2019O321
  IGVMA: 'icon-warzone', // new @2019O321
  SEBAH: 'icon-warzone', // new @2019O321
  SEHE: 'icon-warzone', // new @2019O321
  SEBAHB: 'icon-warzone',
  MILITARY: 'icon-warzone', // Icon for VOLTAC, SETBA, ITBA, IGVMA, SEBAH, SEHE, SEBAHB
  OBS: 'mdi-tower-fire',
  NAT_2000: 'icon-alert_park',
  ZIT: 'icon-alert_prohib',
  ZSM: 'mdi-language-swift',
  SUP_AIP: 'icon-alert_notam',
  PRIVATE: 'mdi-shield-lock',
  'U-SPACE': 'mdi-alpha-u-circle',
  SEVESO: 'mdi-biohazard',
};

export const DRONE_CATEGORIES = [
  { label: 'Autogire', value: 'autogyro' },
  { label: 'Avion, aile, planeur (motorisé)', value: 'aircraft' },
  { label: 'Aéromodèle de vol circulaire', value: 'model_aircraft_control_line' },
  { label: 'Aéromodèle de vol libre', value: 'model_aircraft_free_flight' },
  { label: 'Aéronef à ailes battantes', value: 'flapping_wings' },
  { label: 'Aérostat captif', value: 'tethered_aerostat' },
  { label: 'Convertible / combiné', value: 'convertible' },
  { label: 'Dirigeable', value: 'airship' },
  { label: 'Hélicoptère', value: 'helicopter' },
  { label: 'Montgolfière', value: 'hot_air_balloon' },
  { label: 'Multirotors', value: 'multicopter' },
  { label: 'Parachute, parapente', value: 'parachute' },
  { label: 'Paramoteur', value: 'paramotor' },
  { label: 'Planeur, aile (non motorisé)', value: 'sailplane' },
];

export const DRONE_CLASSES = [
  { label: 'C0', value: 'C0' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
  { label: 'C3', value: 'C3' },
  { label: 'C4', value: 'C4' },
  { label: 'C5', value: 'C5' },
  { label: 'C6', value: 'C6' },
];

export const GEOPORTAIL_LAYERS_DICT_ICONS = {
  Agriculture: 'mdi-corn',
  'Culture et patrimoine': 'mdi-food-croissant',
  'Développement durable, énergie': 'mdi-hydro-power',
  'Fonds de carte': 'mdi-map',
  'International et Europe': 'mdi-earth',
  'Territoires et transports': 'mdi-road-variant',
  'Économie et statistique': 'mdi-chart-bar',
};

export const EXTERNAL_OPERATORS = { THALES: 'THALES', USK: 'USK', DK: 'DK' };

export const MILITARY_CONSTRAINTS_CODES = [
  'VOLTAC', 'SETBA', 'ITBA', 'IGVMA', 'SEBAH', 'SEHE', 'SEBAHB',
];

export const MAX_DOCUMENT_SIZE_MB = {
  flight: 5,
  exploitant_non_subscriber: 5,
  exploitant_subscriber: 50,
  approval: 20,
  opinion: 20,
  activation: 20,
  drone: 5,
  pilot: 5,
  flight_incident: 20,
};
export const MAX_NUMBER_OF_DOCUMENTS = {
  flight: 5,
  exploitant_non_subscriber: 5,
  exploitant_subscriber: 50,
  drone: 5,
  pilot: 5,
  flight_incident: 10,
};

export const URL_WELCOME_AIRCOURTAGE = 'https://www.air-assurances.eu/drone';
export const URL_AIRCOURTAGE_PARTNER = 'https://www.air-assurances.com/droneonline?xo=';

export const COOKIES_NAME = {
  STRIPE: 'stripe',
  BEAMER: 'beamer',
  HUBSPOT: 'hubspot',
};

export const CONTACT_PROFILES = [
  { label: 'Administrateur', value: 'admin_privileges' },
  { label: 'Manager', value: 'is_manager' },
  { label: 'Télépilote', value: 'standard' },
];

export const CUSTOM_CONTACT_PROFILES = [
  { label: 'Spécialiste', value: 'standard' },
  { label: 'Généraliste', value: 'rte_generalist' },
];

export const NOTIFICATION_TYPES = {
  AUTHORITY_NEW_APPROVAL: 'AUTHORITY_NEW_APPROVAL',
  AUTHORITY_APPROVAL_AUTOMATICALLY_PROCESSED: 'AUTHORITY_APPROVAL_AUTOMATICALLY_PROCESSED',
  AUTHORITY_APPROVAL_MODIFIED_BY_DRONE_COMPANY: 'AUTHORITY_APPROVAL_MODIFIED_BY_DRONE_COMPANY',
  AUTHORITY_APPROVAL_REVALIDATED: 'AUTHORITY_APPROVAL_REVALIDATED',
  AUTHORITY_NEW_TAKE_OFF_AUTHORIZATION: 'AUTHORITY_NEW_TAKE_OFF_AUTHORIZATION',
  AUTHORITY_FLIGHT_EN_ROUTE: 'AUTHORITY_FLIGHT_EN_ROUTE',
  AUTHORITY_FLIGHT_LANDED: 'AUTHORITY_FLIGHT_LANDED',
  AUTHORITY_NEW_ACTIVATION: 'AUTHORITY_NEW_ACTIVATION',
  AUTHORITY_ACTIVATION_MODIFIED_BY_DRONE_COMPANY: 'AUTHORITY_ACTIVATION_MODIFIED_BY_DRONE_COMPANY',
  DRONIST_TAKE_OFF_AUTHORIZATION_DECISION: 'DRONIST_TAKE_OFF_AUTHORIZATION_DECISION',
};

export const WEBSOCKET_PAYLOAD_TYPES = {
  ...NOTIFICATION_TYPES,
  TRACKING: 'TRACKING',
  TRACKING_ALERT: 'TRACKING_ALERT',
  TRACKING_PLANES: 'TRACKING_PLANES',
  MISSION_SHEET_UPDATE: 'MISSION_SHEET_UPDATE',
  LIVESTEAM_UPDATE: 'LIVESTEAM_UPDATE',
  CONSTRAINT_SHEET_UPDATE: 'CONSTRAINT_SHEET_UPDATE',
  EMAIL_REQUEST_UPDATE: 'EMAIL_REQUEST_UPDATE',
};

export const URL_DL_QUICK_START_MANUAL = 'https://drive.google.com/uc?export=download&id=1clZ2yMit6NSpkr7OOiZL0N716QySgRcO';
export const URL_DL_HELP_MANUAL_GENERALIST = 'https://drive.google.com/uc?export=download&id=1RFLaDFtTV0Wrw6HGTIIMKTGSZQ4880z4';
export const URL_DL_HELP_MANUAL_DRONIST = 'https://drive.google.com/uc?export=download&id=1eZ4s7FZQLeDuSCDQBa6BWEUd_sEQaDHt';
export const URL_DL_HELP_MANUAL_AUTHORITY = 'https://drive.google.com/uc?export=download&id=1DmWd5NSUCMxrlnSpFX_gdyDcbc3CMA-1';
export const URL_DL_HELP_MANUAL_AUTHORITY_FULL_SCREEN = 'https://drive.google.com/uc?export=download&id=1tFA25O9SpHWdOnPEYXhB_FL4U7PN0gaF';

export const FLIGHT_INCIDENT_CONSEQUENCES = [
  { label: 'Dommage corporel', value: 'personal_injury' },
  { label: 'Casse du drone ou d’accessoires', value: 'drone_breaking' },
  { label: 'Dommage sur ouvrage électrique', value: 'electrical_damage' },
  { label: 'Dommage sur des matériels au sol', value: 'ground_equipment_damage' },
];

export const DRONE_FIELD_LABELS = {
  serial_number: 'Numéro de série',
  remote_id_fr: 'Signalement électronique français',
  remote_id_eu: 'Signalement électronique européen',
  dji_controller_id: 'Numéro contrôleur dji',
};
