<template>
  <v-badge
    v-if="withText"
    v-model="displayImportantApprovalInfo"
    color="red"
    top
    left
    overlap
    content="!"
    class="small-badge"
  >
    <v-icon :class="getClass">
      {{ getIcon }}
    </v-icon>
    <span class="status-detail">
      {{ getStatusDetail }}
    </span>
  </v-badge>
  <v-tooltip
    v-else-if="withTooltip"
    top
  >
    <template v-slot:activator="{ on }">
      <v-badge
        v-model="displayImportantApprovalInfo"
        color="red"
        top
        left
        overlap
        content="!"
        class="small-badge"
      >
        <v-icon
          :class="getClass"
          v-on="on"
        >
          {{ getIcon }}
        </v-icon>
      </v-badge>
    </template>
    <span>
      {{ getTooltip }}
    </span>
  </v-tooltip>
  <v-icon
    v-else
    :class="getClass"
  >
    {{ getIcon }}
  </v-icon>
</template>
<script>

import {
  ACCEPTED, SUBMITTED, INCOMPLETE, REFUSED, RESERVES, CLOSED, PENDING, DONE, CHECKED, PLANNED,
  CLEAR, CONFIRMED, ACTION_NEEDED, PROCESSING, AWAITING_VALIDATION,
} from '@/store/status';

export default {
  name: 'StatusIcon',
  props: {
    status: String,
    withNews: Boolean,
    withText: Boolean,
    withTooltip: Boolean,
    breachedPSAs: Boolean,
    noMaxElevation: Boolean,
    reverseColor: Boolean,
    linear: Boolean,
    transparentBackground: Boolean,
    tooltip: String,
    isClosed: Boolean,
    isInsideImpactingZone: Boolean,
  },
  computed: {
    getIcon() {
      switch (this.status) {
        case SUBMITTED:
        case CHECKED:
        case PLANNED:
        case PROCESSING:
          if (this.linear) {
            return 'icon-fly_wait_line';
          }
          return 'icon-fly_wait_flat';
        case ACTION_NEEDED:
          if (this.linear) {
            return 'mdi-alert-circle-outline';
          }
          return 'mdi-alert-circle';
        case INCOMPLETE:
          if (this.linear) {
            return 'mdi-clock-alert-outline';
          }
          return 'mdi-clock-alert';
        case ACCEPTED:
        case DONE:
          if (this.linear) {
            return 'icon-fly_ok_line';
          }
          return 'icon-fly_ok_flat';
        case REFUSED:
          if (this.linear) {
            return 'icon-fly_nok_line';
          }
          return 'icon-fly_nok_flat';
        case RESERVES:
          if (this.linear) {
            return 'icon-fly_okbut_line';
          }
          return 'icon-fly_okbut_flat';
        case CLEAR:
          return this.linear ? 'icon-fly_ok_line' : 'icon-fly_ok_flat';
        case CONFIRMED:
          return 'icon-add_fly';
        case CLOSED:
        case PENDING:
          return 'mdi-send-circle';
        case AWAITING_VALIDATION:
          return this.linear ? 'mdi-clock-check-outline' : 'mdi-clock-check';
        default:
          return 'icon-fly_wait_flat';
      }
    },
    displayImportantApprovalInfo() {
      return this.breachedPSAs || this.noMaxElevation || this.isInsideImpactingZone;
    },
    getClass() {
      if (this.isClosed) {
        return { 'blue-grey--text': !this.transparentBackground };
      }
      switch (this.status) {
        case SUBMITTED:
        case CHECKED:
        case PLANNED:
        case PROCESSING:
        case AWAITING_VALIDATION:
          return {
            // primary: this.reverseColor,
            'primary--text': !this.transparentBackground,
          };
        case INCOMPLETE:
          return { 'blue-grey--text': !this.transparentBackground };
        case ACCEPTED:
        case DONE:
          return {
            // success: this.reverseColor,
            'success--text': !this.transparentBackground,
          };
        case REFUSED:
          return {
            // error: this.reverseColor,
            'error--text': !this.transparentBackground,
          };
        case ACTION_NEEDED:
        case RESERVES:
          return {
            // warning: this.reverseColor,
            'warning--text': !this.transparentBackground,
          };
        case CLEAR:
        case CONFIRMED:
          return { 'success--text': !this.transparentBackground };
        case PENDING:
          return { 'blue-grey--text': !this.transparentBackground };
        case CLOSED:
        default:
          return {
            // info: this.reverseColor,
            'info--text': !this.transparentBackground,
          };
      }
    },
    getStatusDetail() {
      let text;
      switch (this.status) {
        case SUBMITTED:
          text = this.$gettext('Submitted');
          break;
        case ACTION_NEEDED:
        case INCOMPLETE:
          text = this.$gettext('Incomplete');
          break;
        case ACCEPTED:
          text = this.$gettext('Authorized');
          break;
        case REFUSED:
          text = this.$gettext('Refused');
          break;
        case RESERVES:
          text = this.$gettext('Authorized with restriction');
          break;
        case PENDING:
          text = this.$gettext('Processing');
          break;
        case AWAITING_VALIDATION:
          text = this.$gettext('Awaiting validation');
          break;
        default:
          text = this.$gettext('Flight in unknown status');
          break;
      }
      if (this.isClosed) {
        text += ` ${this.$gettext('(archived)')}`;
      }
      return text;
    },
    getTooltip() {
      return this.tooltip || this.getStatusDetail;
    },
  },
};
</script>

<style scoped>
.status-detail {
  margin-left: 0.5rem;
  vertical-align: text-bottom;
}

</style>
