import { render, staticRenderFns } from "./StripeOnboarding.vue?vue&type=template&id=3e8ed07a&scoped=true&"
import script from "./StripeOnboarding.vue?vue&type=script&lang=js&"
export * from "./StripeOnboarding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e8ed07a",
  null
  
)

export default component.exports