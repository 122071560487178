<template>
  <v-card color="primary">
    <v-card-text class="pt-3">
      <span
        v-translate
        class="white--text"
      >
        Items verified by Clearance
      </span>
      <v-divider dark />
      <v-skeleton-loader
        v-if="loading"
        type="list-item-avatar, divider, list-item-avatar, divider, list-item-avatar, divider"
      />
      <div
        v-for="(constraint, index) in verifiedConstraints"
        :key="index"
        class="my-4"
      >
        <v-expansion-panels class="dense__expansion-panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="dense__expansion-panel__header-content d-flex align-center">
                <v-icon
                  size="24px"
                  color="info"
                >
                  {{ (getIcon(constraint.code_type)) }}
                </v-icon>
                <div class="ml-2">
                  {{ constraint.name }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="font-italic pt-4">
              {{ constraint.description }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { CONSTRAINT_DICT_ICON } from '@/settings';

export default {
  name: 'FlightItemsVerified',
  data() {
    return {
      loading: false,
      verifiedConstraints: [],
    };
  },
  created() {
    this.getClearanceVerifiedConstraints();
  },
  methods: {
    getClearanceVerifiedConstraints() {
      this.loading = true;
      APIService.getVerifiedConstraints()
        .then(({ data }) => {
          this.verifiedConstraints = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getIcon(code) {
      return CONSTRAINT_DICT_ICON[code] || 'icon-alert_empty';
    },
  },
};
</script>
