<template>
  <v-container fluid>
    <div
      v-if="loading"
      class="decision-loader"
    >
      <v-skeleton-loader type="list-item-avatar, list-item-three-line" />
    </div>
    <div v-else>
      <v-expansion-panels
        light
        class="expansion-panel"
        v-model="panelExpanded"
      >
        <v-expansion-panel expand-icon="keyboard_arrow_down">
          <v-expansion-panel-header>
            <v-row
              align="center"
              class="truncated__row"
            >
              <span class="pr-2">
                <StatusIcon
                  :status="flightApproval.status"
                  withTooltip
                />
              </span>
              <span
                class="text-uppercase font-weight-bold body-1 truncated"
                :class="`${colorStatus[flightApproval.status]}--text`"
              >
                {{ flightApproval.authority_name }}
              </span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ApprovalChat
              :approval="flightApproval"
              :newMessageDisabled="true"
              :isExternalChat="true"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import APIService from '@/services/api';

import ApprovalChat from '@/components/Approvals/ApprovalChat.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'AuthorityDecision',
  components: {
    ApprovalChat,
    StatusIcon,
  },
  props: {
    approvalId: {
      type: Number,
      required: true,
    },
    flightApprovalId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      panelExpanded: 0,
      flightApproval: undefined,
      colorStatus: {
        accepted: 'success',
        reserves: 'warning',
        refused: 'error',
        submitted: 'primary',
        pending: 'primary',
      },
    };
  },
  async created() {
    this.loading = true;
    await APIService.getFlightApproval(this.approvalId, this.flightApprovalId)
      .then(({ data }) => {
        this.flightApproval = data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style
  lang="scss"
  scoped
>
.decision-loader {
  background: white;
  border-radius: 4px;
  padding-top: 12px;
}

</style>
