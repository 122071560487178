<template>
  <v-container class="px-0 px-sm-2">
    <div class="headline mb-4">
      <translate>Payment method</translate>
    </div>
    <!-- Free users -->
    <v-alert
      v-if="userNotSubscribed"
      :value="true"
      key="free-users"
      text
      type="info"
      color="primary lighten-1"
      max-width="480"
    >
      <translate>
        You're not currently subscribed.
      </translate>
    </v-alert>
    <!-- Enterprise and Formation users -->
    <v-alert
      v-else-if="userEnterprise || userFormation"
      :value="true"
      key="formation-enterprise-users"
      text
      type="info"
      color="primary lighten-1"
      max-width="480"
    >
      {{ paymentMethodSupportText }}
    </v-alert>
    <!-- Other subscribed users -->
    <template v-else>
      <!-- Success message for credit card change -->
      <v-alert
        v-if="cardChangeState === 'success'"
        :value="true"
        dismissible
        text
        type="success"
        color="green darken-1"
        max-width="480"
      >
        <translate>Credit card changed successfully.</translate>
      </v-alert>
      <v-card
        max-width="480"
        class="px-5 py-5"
      >
        <v-card-title>
          <v-container class="pa-0">
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="mb-4"
            >
              <v-icon
                color="primary"
                class="mr-4"
              >
                mdi-credit-card
              </v-icon>
              <span
                v-translate
                class="title font-weight-regular"
              >
                Credit card
              </span>
            </v-row>
            <!-- Credit card info -->
            <v-row
              no-gutters
              justify="center"
              align="center"
            >
              <v-skeleton-loader
                :loading="paymentMethodDetailsLoading"
                type="text, text"
                height="48"
                width="140"
                :class="{'pt-3': paymentMethodDetailsLoading}"
              >
                <template v-if="!paymentMethodDetailsError">
                  <v-row
                    no-gutters
                    justify="center"
                    align="center"
                  >
                    <span
                      v-translate
                      class="body-2 mr-2 info--text font-italic"
                    >
                      Number:
                    </span>
                    <v-icon>mdi-dots-horizontal</v-icon>
                    <span class="body-1 ml-2">
                      {{ lastDigits }}
                    </span>
                  </v-row>
                  <v-row
                    no-gutters
                    justify="center"
                    align="center"
                  >
                    <span
                      v-translate
                      class="body-2 mr-2 info--text font-italic"
                    >
                      Expiration:
                    </span>
                    <span class="body-1">
                      {{ expirationDate }}
                    </span>
                  </v-row>
                </template>
                <template v-else>
                  <span
                    v-translate
                    class="body-2 font-italic error--text text--darken-4"
                  >
                    Couldn't retreive credit card details.
                  </span>
                </template>
              </v-skeleton-loader>
            </v-row>
          </v-container>
        </v-card-title>
        <!-- Credit card change -->
        <v-card-actions class="d-flex justify-center mb-2">
          <v-btn
            @click="tryChangePaymentMethod()"
            :loading="changeLoading"
            color="primary"
            class="px-5"
          >
            <translate>Change payment method</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog
        v-model="showStipeCookieDialog"
        max-width="1200"
      >
        <AskCookieStripe
          :title="$gettext('Modify my bank card')"
          :dialogText="texts.stripeDialog"
          @close-form="showStipeCookieDialog=false"
        />
      </v-dialog>

      <!-- Disclaimer -->
      <div
        class="mt-5 py-2 caption info--text font-italic text--lighten-2"
        style="max-width: 500px"
      >
        <v-icon
          small
          class="mb-1"
        >
          mdi-information-outline
        </v-icon>
        <translate>
          Clearance ne stocke pas vos coordonnées bancaires. Le bouton ci-dessus vous redirige vers
          notre prestataire externe Stripe qui gère de manière sécurisée vos coordonnées bancaires
          ainsi que vos paiements.
        </translate>
      </div>
    </template>
  </v-container>
</template>

<script>
import API from '@/services/api';

import AskCookieStripe from '@/components/Flights/RequestApproval/AskCookieStripe.vue';

export default {
  name: 'PaymentMethod',
  components: { AskCookieStripe },
  props: {
    cardChangeState: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      lastDigits: null,
      expirationDate: null,
      paymentMethodDetailsLoading: true,
      paymentMethodDetailsError: false,
      changeLoading: false,
      loadStripe: undefined,
      showStipeCookieDialog: false,
    };
  },
  computed: {
    texts() {
      return {
        stripeDialog: this.$gettext(`To change your payment method, please accept the
        cookies from our payment solution Stripe.`),
      };
    },
    userNotSubscribed() {
      return this.$store.getters['authentication/userNotSubscribed'];
    },
    userEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    userFormation() {
      return this.subscriptionPlan.code === 'FORMATION';
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    subscription() {
      return this.userData.subscription;
    },
    subscriptionPlan() {
      return this.subscription.subscription_plan;
    },
    paymentMethodSupportText() {
      return this.$gettext(`Please contact support@clearance.aero for information or changes
        regarding your payment method.`);
    },
    hasAcceptedCookieStripe() {
      return this.$store.getters['authentication/hasAcceptedCookieStripe'];
    },
  },
  created() {
    if (this.$store.getters['authentication/hasAcceptedCookieStripe']) {
      // eslint-disable-next-line global-require
      this.loadStripe = require('@stripe/stripe-js/pure').loadStripe;
    }
  },
  watch: {
    hasAcceptedCookieStripe(value) {
      if (value) {
        this.showStipeCookieDialog = false;
      }
    },
  },
  mounted() {
    if (!this.userNotSubscribed && !(this.userEnterprise || this.userFormation)) {
      API.getPaymentMethodDetails()
        .then(({ data }) => {
          this.lastDigits = data.last_digits;
          this.expirationDate = data.expiration_date;
        })
        .catch(() => {
          this.paymentMethodDetailsError = true;
        })
        .finally(() => {
          this.paymentMethodDetailsLoading = false;
        });
    }
  },
  methods: {
    tryChangePaymentMethod() {
      if (this.hasAcceptedCookieStripe) {
        this.changePaymentMethod();
      } else {
        this.showStipeCookieDialog = true;
      }
    },
    async changePaymentMethod() {
      this.changeLoading = true;
      try {
        const { data } = await API.changePaymentMethod();
        const stripe = await this.loadStripe(process.env.VUE_APP_STRIPE_API_KEY);
        stripe.redirectToCheckout({ sessionId: data.checkout_session_id });
      } catch (e) {
        if (e.response && e.response.data) {
          this.showMessage(
            this.$gettextInterpolate(
              this.$gettext('Une erreur est survenue: %{error}.'),
              { error: e.response.data },
              true,
            ),
            'error',
          );
        } else {
          this.showMessage(
            this.$gettext('Une erreur est survenue, veuillez réessayer plus tard.'),
            'error',
          );
        }
      } finally {
        this.changeLoading = false;
      }
    },
  },
};
</script>
