<template>
  <div>
    <!-- ---- Request Activation Button ----- -->
    <LayoutFlightConstraintAction
      id="request-activation"
      :flight="flight"
      ignoreFlightEnded
      :disabledExtra="requestActivationDisabledExtra"
      :disabledExtraTooltip="requestActivationDisabledExtraTooltip"
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <v-btn
          color="success"
          class="main-button__font my-0"
          :disabled="disabled || sendingApproval || loadingDecisions"
          :loading="sendingApproval || loadingDecisions"
          @click.stop="dialog=true"
          block
        >
          <span v-translate>Submit an activation request</span>
          <template v-slot:loader>
            <span
              v-if="sendingApproval"
              v-translate
            >
              Sending approval...
            </span>
            <span
              v-else-if="loadingDecisions"
              v-translate
            >
              Checking approval dates...
            </span>
            <v-progress-circular
              class="ml-2"
              indeterminate
              :size="20"
              :width="2"
            />
          </template>
        </v-btn>
      </template>
    </LayoutFlightConstraintAction>
    <!-- ---- Request Activation Dialog ----- -->
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="820px"
      :retain-focus="false"
      persistent
    >
      <RequestActivationForm
        :flight="flight"
        :approval="approval"
        :constraint="constraint"
        @close-form="() => dialog = false"
        @create-activation="(approval, activation) => createActivation(approval, activation)"
      />
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import { SET_FLIGHT_APPROVAL_PROPERTY_NS } from '@/store/flights';
import { ACCEPTED, RESERVES } from '@/store/status';

import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

import RequestActivationForm from '@/components/Flights/RequestApproval/RequestActivationForm.vue';

export default {
  name: 'RequestActivationActionCreate',
  components: {
    LayoutFlightConstraintAction,
    RequestActivationForm,
  },
  props: {
    flight: Object,
    approval: {
      type: Object,
      required: false,
      default: null,
    },
    constraint: Object,
    sendingApproval: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loadingDecisions: true,
      dialog: false,
    };
  },
  computed: {
    approvals() {
      return (
        this.approval
          ? [this.approval]
          : this.flight.approvals.filter((a) => (
            a.constraint_id === this.constraint.id && [ACCEPTED, RESERVES].includes(a.status)
          ))
      );
    },
    requestActivationDisabledExtra() {
      if (this.loadingDecisions) return false;

      const today = new Date();
      today.setHours(0); today.setMinutes(0); today.setSeconds(0);
      return !this.approvals.some(
        (a) => a.decisions?.some((d) => new Date(d.date_end || this.flight.date_end) >= today),
      );
    },
    requestActivationDisabledExtraTooltip() {
      if (!this.requestActivationDisabledExtra) return '';

      return this.$gettext("The approval's dates have passed.");
    },
  },
  created() {
    this.loadingDecisions = true;
    const promises = [];
    this.approvals.forEach((a) => {
      promises.push(
        APIService.getApprovalDecisions(a.id)
          .then(({ data }) => {
            this.$store.commit(
              SET_FLIGHT_APPROVAL_PROPERTY_NS,
              {
                flightId: this.flight.id,
                approvalId: a.id,
                propertyKey: 'decisions',
                propertyValue: data,
              },
            );
          }),
      );
    });
    Promise.all(promises)
      .then(() => {
        this.loadingDecisions = false;
      });
  },
  methods: {
    createActivation(approval, activation) {
      this.$store.commit(SET_FLIGHT_APPROVAL_PROPERTY_NS, {
        flightId: this.flight.id,
        approvalId: approval.id,
        propertyKey: 'activations',
        propertyValue: [...approval.activations, activation],
      });
      this.$store.commit(SET_FLIGHT_APPROVAL_PROPERTY_NS, {
        flightId: this.flight.id,
        approvalId: approval.id,
        propertyKey: 'global_status_activations',
        propertyValue: activation.global_status_activations,
      });
      this.$emit('action-on-approval');
      this.dialog = false;
    },
  },
};
</script>
