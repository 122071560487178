<template>
  <v-card class="default--dialog__card">
    <v-card-title class="headline">
      <span v-translate>Take off authorization</span>
      <v-icon @click="$emit('close')">close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        v-if="loadingTakeOffAuthorizationDetails || loadingDetails"
        type="list-item-three-line"
      />
      <TakeOffAuthorizationDetails
        v-else
        :takeOffAuthorization="takeOffAuthorizationDetails || takeOffAuthorization"
        newMessageDisabled
      />
      <div
        v-translate
        class="font-weight-medium pb-1 black--text body-2"
      >
        Message
      </div>
      <v-form
        ref="form"
        v-model="valid"
      >
        <!-- Text area -->
        <v-textarea
          v-model="message"
          outlined
          rows="4"
          :placeholder="placeholders.explainDecision"
          :rules="rules[decisionStatus]"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="error"
        depressed
        :loading="loadingDecision"
        :disabled="disabledDecision('refused')"
        @click="updateDecision('refused')"
      >
        <span v-translate>Refuse</span>
      </v-btn>
      <v-btn
        color="success"
        depressed
        :loading="loadingDecision"
        :disabled="disabledDecision('accepted')"
        @click="updateDecision('accepted')"
      >
        <span v-translate>Authorize</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { SET_APPROVAL_TAKE_OFF_AUTHORIZATION_NS } from '@/store/approvals';
import { ACCEPTED } from '@/store/status';

import TakeOffAuthorizationDetails from '@/components/TakeOffAuthorizations/TakeOffAuthorizationDetails.vue';

export default {
  name: 'ChangeTakeOffAuthorizationStatusForm',
  components: { TakeOffAuthorizationDetails },
  props: {
    takeOffAuthorization: Object,
    loadingTakeOffAuthorizationDetails: Boolean,
    retrieveTakeOffAuthorizationDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      message: '',
      rules: {
        refused: [
          (v) => !!v || this.$gettext('A message to explain your refusal is required.'),
        ],
        accepted: [],
      },
      decisionStatus: ACCEPTED,
      loadingDecision: false,
      loadingDetails: false,
      takeOffAuthorizationDetailsRetrieved: null,
    };
  },
  computed: {
    placeholders() {
      return { explainDecision: this.$gettext('Explain the reason of your decision to the pilot') };
    },
    takeOffAuthorizationDetails() {
      return (
        this.retrieveTakeOffAuthorizationDetails
          ? this.takeOffAuthorizationDetailsRetrieved
          : this.takeOffAuthorization
      );
    },
  },
  created() {
    if (this.retrieveTakeOffAuthorizationDetails) {
      this.loadingDetails = true;
      APIService.getTakeOffAuthorization(this.takeOffAuthorization.id)
        .then(({ data }) => {
          this.takeOffAuthorizationDetailsRetrieved = data;
          this.$store.commit(
            SET_APPROVAL_TAKE_OFF_AUTHORIZATION_NS,
            { approvalId: data.approval_id, takeOffAuthorization: data },
          );
        })
        .finally(() => {
          this.loadingDetails = false;
        });
    }
  },
  methods: {
    disabledDecision(status) {
      return (
        this.takeOffAuthorization.status === status
        || this.loadingTakeOffAuthorizationDetails
        || this.loadingDetails
      );
    },
    updateDecision(status) {
      this.decisionStatus = status;
      this.$nextTick(() => {
        if (!this.$refs.form.validate()) return;

        this.loadingDecision = true;
        const payload = {
          status: this.decisionStatus,
          message: this.message,
        };
        APIService.updateTakeOffAuthorizationDecision(this.takeOffAuthorizationDetails.id, payload)
          .then(({ data }) => {
            this.$store.commit(
              SET_APPROVAL_TAKE_OFF_AUTHORIZATION_NS,
              {
                approvalId: this.takeOffAuthorizationDetails.approval_id,
                takeOffAuthorization: data,
              },
            );
            this.$emit('close');
          })
          .finally(() => {
            this.loadingDecision = false;
          });
      });
    },
  },
};
</script>
