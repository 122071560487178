<template>
  <v-expansion-panels
    v-model="panelExpanded"
    light
    flat
    class="dense__expansion-panel mt-2"
  >
    <v-expansion-panel class="documents">
      <v-expansion-panel-header>
        <span class="dense__expansion-panel__header-content">
          <v-badge
            :value="currentLivestreams.length"
            class="mr-2"
            color="red"
            offset-x="6"
            offset-y="6"
            right
            dot
          >
            <v-icon color="info">
              mdi-video-wireless-outline
            </v-icon>
          </v-badge>
          <translate>Livestreams</translate>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mt-2">
        <div class="d-flex flex-wrap">
          <template v-for="livestream in livestreams">
            <Livestream
              :livestream="livestream"
              :key="livestream.id"
            />
          </template>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Livestream from '@/components/Livestreams/Livestream.vue';

export default {
  name: 'FlightLivestreams',
  components: { Livestream },
  props: { livestreams: Array },
  data() {
    return { panelExpanded: undefined };
  },
  computed: {
    currentLivestreams() {
      return this.livestreams.filter((livestream) => livestream.status === 'live');
    },
  },
};
</script>
