var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header py-4"},[_c('div',{staticClass:"header headline"},[_c('translate',[_vm._v("Tags")])],1),_c('div',{staticClass:"header buttons"},[(_vm.isManagerOrAdmin)?_c('v-btn',{key:"add",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.openForm()}}},[_c('translate',[_vm._v("+ add tag")])],1):_vm._e()],1)]),_c('div',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.texts.search,"single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tags,"search":_vm.search,"loading":_vm.tagsLoading,"loading-text":_vm.texts.loadingText,"no-data-text":_vm.texts.noResultText,"no-results-text":_vm.texts.noResultText,"mobile-breakpoint":_vm.singleColumnBreakpoint,"footer-props":{
        'items-per-page-text': _vm.$gettext('Lines per page:'),
        'items-per-page-all-text': _vm.$gettext('All'),
        'items-per-page-options': [25, 50, 100, -1],
      },"calculate-widths":""},scopedSlots:_vm._u([(_vm.hasSubExploitants)?{key:"item.sub_exploitant_name",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item.sub_exploitant_name)+" ")])]}}:null,{key:"item.constraint_type_name",fn:function(ref){
      var item = ref.item;
return [(item.is_constraint_auto_tag)?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"primary","label":"","x-small":""}},[_vm._v(" auto ")]):_vm._e(),_vm._v(" "+_vm._s(item.constraint_type_name)+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.isManagerOrAdmin)?_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openForm(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","loading":_vm.deleteLoading},on:{"click":function($event){$event.stopPropagation();return _vm.openDialogDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]}}],null,true)})],1),(_vm.dialogForm)?_c('v-dialog',{attrs:{"max-width":"680","retain-focus":false,"fullscreen":_vm.isMobileBreakpoint,"persistent":""},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_c('FlightTagForm',{attrs:{"tagToUpdate":_vm.tagToUpdate,"tagConstraintTypes":_vm.tagConstraintTypes},on:{"reload":function($event){return _vm.getFlightTags()},"close":function () { return _vm.closeForm(); }}})],1):_vm._e(),(_vm.dialogDelete)?_c('v-dialog',{attrs:{"max-width":"420","retain-focus":false,"fullscreen":_vm.isMobileBreakpoint,"persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{staticClass:"default--dialog__card"},[_c('v-card-title',[_c('translate',[_vm._v("Delete tag")])],1),_c('v-card-text',[_c('div',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.texts.deleteConfirmation))]),_c('div',[_vm._v(_vm._s(_vm.texts.deleteConfirmationDetails))])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"info"},on:{"click":function($event){return _vm.closeDialogDelete()}}},[_c('translate',[_vm._v("Cancel")])],1),_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.deleteLoading},on:{"click":function($event){return _vm.deleteTag()}}},[_c('translate',[_vm._v("Confirm")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }