var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"opinion-listing"},[_c('v-data-table',{ref:"datatable",class:{bigDisplay: _vm.bigDisplay, smallDisplay: !_vm.bigDisplay, transparent: true},attrs:{"headers":_vm.headers,"items":_vm.opinions,"loading":_vm.loading,"single-expand":"","options":_vm.options,"server-items-length":_vm.itemsLength,"expanded":_vm.expanded,"hide-default-footer":_vm.smallDisplay,"footer-props":{
      'items-per-page-text': _vm.$gettext('Lines per page:'),
      'items-per-page-all-text': _vm.$gettext('All'),
      'items-per-page-options': [25, 50, 100, -1],
    },"loading-text":_vm.$gettext('Loading flights...'),"no-data-text":_vm.$gettext('No flight found.'),"item-key":"id","no-results-text":_vm.$gettext('No flight found.'),"dark":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{staticClass:"cursor-pointer",class:_vm.getStatusClass(props),on:{"click":function($event){return _vm.selectItem(props)},"mouseenter":function($event){_vm.idHover = props.item.id},"mouseleave":function($event){_vm.idHover = null}}},[_c('td',{staticClass:"pl-3"},[_c('StatusIcon',{attrs:{"status":props.item.status,"transparentBackground":props.isExpanded,"isClosed":props.item.approval.is_closed}})],1),_c('td',{staticClass:"opinion-listing__item-identifier"},[(props.item.approval.display_identifier)?[_vm._v(" "+_vm._s(props.item.approval.display_identifier)+" ")]:_vm._e()],2),((_vm.mediumDisplay || _vm.bigDisplay))?_c('td',[_vm._v(" "+_vm._s(props.item.approval.company_name)+" ")]):_vm._e(),(_vm.bigDisplay)?_c('td',[_vm._v(" "+_vm._s(props.item.approval.commune)+" ")]):_vm._e(),(_vm.mediumDisplay || _vm.bigDisplay)?_c('td',[_vm._v(" "+_vm._s(_vm._f("dateShort")(props.item.approval.flight.date_start))+" - "+_vm._s(_vm._f("dateShort")(props.item.approval.flight.date_end))+" ")]):_vm._e(),(_vm.bigDisplay)?_c('td',[_vm._v(" "+_vm._s(_vm._f("hour")(props.item.approval.flight.time_start))+" - "+_vm._s(_vm._f("hour")(props.item.approval.flight.time_end))+" ")]):_vm._e(),(_vm.bigDisplay)?_c('td',[_vm._v(" "+_vm._s(_vm._f("meter")(props.item.approval.max_flying_height))+" ")]):_vm._e(),(_vm.mediumDisplay || _vm.bigDisplay)?_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"black--text",attrs:{"icon":""}},[(!props.isExpanded)?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_c('v-icon',[_vm._v("keyboard_arrow_up")])],1)],1):_vm._e()])]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[(_vm.loadingDetails)?_c('v-skeleton-loader',{attrs:{"light":"","type":"list-item-three-line"}}):_c('OpinionVisitorDetails',{attrs:{"opinion":item},on:{"center-on-approval":function () { return _vm.$emit('center-on-approval'); },"center-on-area":function (area) { return _vm.$emit('center-on-area', area); }}})],1)]}},{key:"footer.page-text",fn:function(items){return [_c('span',{directives:[{name:"translate",rawName:"v-translate",value:(
        {pageStart: items.pageStart, pageStop: items.pageStop, length: items.itemsLength}
      ),expression:"\n        {pageStart: items.pageStart, pageStop: items.pageStop, length: items.itemsLength}\n      "}]},[_vm._v(" %{ pageStart } - %{ pageStop } of %{ length } ")])]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }