<template>
  <div class="trace-popup">
    <div class="pa-2 subtitle-1 text-center">
      <span v-translate>Trace</span>
      <span>
        {{ trace.id }}
      </span>
    </div>
    <v-row
      v-for="(data, index) in traceData"
      :key="index"
      no-gutters
    >
      <v-col
        cols="5"
        class="pa-2 subtitle-2"
      >
        {{ data.label }}
      </v-col>
      <v-col
        cols="7"
        class="pa-2 body-2"
        :class="data.class"
      >
        {{ data.value }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';

import store from '@/store/';

export default Vue.extend({
  store,
  computed: {
    trace() {
      return this.$store.getters['traces/traceHover'];
    },
    traceData() {
      return [
        {
          label: this.$gettext('Drone ID'),
          value: this.trace.device_id,
          class: 'font-weight-medium word-break',
        },
        {
          label: this.$gettext('First detection'),
          value: this.formatDate(this.trace.first_detection),
        },
        {
          label: this.$gettext('Last detection'),
          value: this.formatDate(this.trace.last_detection),
        },
        {
          label: this.$gettext('First longitude'),
          value: this.trace.longitude_first_detection,
        },
        {
          label: this.$gettext('First latitude'),
          value: this.trace.latitude_first_detection,
        },
        {
          label: this.$gettext('Max altitude'),
          value: `${Math.round(this.trace.altitude_max)} m`,
        },
      ];
    },
  },
  methods: {
    formatDate(datetimeToFormat) {
      const datetimeFormatted = new Date(datetimeToFormat);
      return datetimeFormatted.toLocaleString(
        this.$store.getters['application/currentLanguage'],
      );
    },
  },
});
</script>

<style lang="scss">
@use "sass:map";

.tracking-trace.mapboxgl-popup {

  min-width: 320px;

  .mapboxgl-popup-content {
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
  }
}
.word-break {
  word-break: break-all;
}

</style>
