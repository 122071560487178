<template>
  <div class="px-3">
    <!-- Areas without decision -->
    <v-row
      v-if="areasWithoutDecision.length"
      align="center"
      class="my-2 outlined submitted"
      @mouseenter="setAreasHover(areasWithoutDecision.map((a) => a.id))"
      @mouseleave="setAreasHover([])"
    >
      <v-col
        cols="1"
        order="1"
        class="d-flex justify-center"
      >
        <FlightStatusIcon
          status="submitted"
          theme="approvals"
        />
      </v-col>
      <v-col
        cols="12"
        sm="10"
        order="3"
        order-sm="2"
        class="d-flex flex-column justify-center"
      >
        <translate
          :translate-n="areasWithoutDecision.length"
          :translate-params="{ name: areasWithoutDecision.map((a) => a.name).join(', ') }"
          translate-plural="Zones awaiting treatments: %{ name }"
        >
          Zone awaiting treatment: %{ name }
        </translate>
      </v-col>
      <v-col
        cols="1"
        order="2"
        order-sm="3"
        class="d-flex justify-center"
      >
        <v-btn
          icon
          small
          @click="centerOnAreasWithoutDecisions()"
        >
          <v-icon color="primary">icon-geoloc_line</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Decisions -->
    <v-row
      v-for="(decision, index) in decisions"
      :key="index"
      align="center"
      class="my-2 outlined"
      :class="{[decision.status]: true}"
      @mouseenter="setDecisionHover(decision.sub_approvals)"
      @mouseleave="setDecisionHover([])"
    >
      <v-col
        cols="1"
        order="1"
        class="d-flex justify-center"
      >
        <FlightStatusIcon
          :status="decision.status"
          theme="approvals"
        />
      </v-col>
      <v-col
        cols="12"
        sm="10"
        order="3"
        order-sm="2"
        class="d-flex flex-column justify-center"
      >
        <div>
          {{ getDecisionText(decision) }}
        </div>
        <div v-if="decision.flying_height">
          <span
            v-translate
            class="font-weight-medium"
          >
            Flying height restriction:
          </span>
          <span>
            {{ decision.flying_height | meter }}
          </span>
        </div>
        <!-- Period restriction -->
        <div v-if="decision.date_start || decision.date_end">
          <span
            v-translate
            class="font-weight-medium"
          >
            Period allowed:
          </span>
          <span>
            {{ decision.date_start || flight.date_start | date }}
            - {{ decision.date_end || flight.date_end | date }}
          </span>
        </div>
        <!-- Time restriction -->
        <div v-if="decision.time_start || decision.time_end">
          <span
            v-translate
            class="font-weight-medium"
          >
            Time range allowed:
          </span>
          <span>
            {{ decision.time_start || flight.time_start | hour }}
            - {{ decision.time_end || flight.time_end | hour }}
          </span>
        </div>
        <div v-if="decision.comment">
          <span
            v-translate
            class="font-weight-medium"
          >
            Authority comment:
          </span>
          <p
            :ref="`comment-${decision.id}`"
            class="requirements"
            :class="{
              'with-overflow': commentsOverflowing[index],
              'collapsed': commentsCollapsed[index],
            }"
            @click="commentsCollapsed[index] = !commentsCollapsed[index]; $forceUpdate();"
          >
            {{ decision.comment }}
          </p>
          <div
            v-if="commentsOverflowing[index] && commentsCollapsed[index]"
            class="cursor-pointer text-right pt-1"
            @click="commentsCollapsed[index] = false; $forceUpdate();"
          >
            <span
              v-translate
              class="font-italic info--text text-decoration-underline"
            >
              see all
            </span>
          </div>
        </div>
        <div v-if="decision.extra_requirements">
          <span
            v-translate
            class="font-weight-medium"
          >
            Particular requirements:
          </span>
          <p
            :ref="`requirement-${decision.id}`"
            class="requirements"
            :class="{
              'with-overflow': requirementsOverflowing[index],
              'collapsed': requirementsCollapsed[index],
            }"
            @click="requirementsCollapsed[index] = !requirementsCollapsed[index]; $forceUpdate();"
          >
            {{ decision.extra_requirements }}
          </p>
          <div
            v-if="requirementsOverflowing[index] && requirementsCollapsed[index]"
            class="cursor-pointer text-right pt-1"
            @click="requirementsCollapsed[index] = false; $forceUpdate();"
          >
            <span
              v-translate
              class="font-italic info--text text-decoration-underline"
            >
              see all
            </span>
          </div>
        </div>
      </v-col>
      <v-col
        cols="1"
        order="2"
        order-sm="3"
        class="d-flex justify-center"
      >
        <v-btn
          icon
          small
          @click="centerOnDecision(decision.sub_approvals)"
        >
          <v-icon color="primary">icon-geoloc_line</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SET_APPROVAL_FLIGHT_AREAS_HOVER_NS, ZOOM_TO_AREA_NS } from '@/store/map';

import FlightStatusIcon from '@/components/StatusIcon/FlightStatusIcon.vue';

export default {
  name: 'FlightApprovalDecisions',
  components: { FlightStatusIcon },
  props: {
    approval: Object,
    flight: Object,
    areas: Array,
    decisions: Array,
  },
  data() {
    return {
      commentsOverflowing: this.decisions.map(() => undefined),
      commentsCollapsed: this.decisions.map(() => true),
      requirementsOverflowing: this.decisions.map(() => undefined),
      requirementsCollapsed: this.decisions.map(() => true),
    };
  },
  computed: {
    approvalsStatusDict() {
      return this.$store.state.status.approvalsStatusDict;
    },
    areasWithoutDecision() {
      const flightAreaIdsWithDecision = this.decisions.map(
        (d) => d.sub_approvals.map((s) => s.flight_area_id),
      ).flat();
      return this.areas.filter((a) => !flightAreaIdsWithDecision.includes(a.id));
    },
  },
  mounted() {
    const refElements = Object.keys(this.$refs);
    for (let i = 0; i < this.decisions.length; i += 1) {
      const decisionId = this.decisions.at(i).id;
      if (refElements.includes(`comment-${decisionId}`)) {
        this.commentsOverflowing[i] = this.checkVerticalOverflow(
          this.$refs[`comment-${decisionId}`][0],
        );
      }
      if (refElements.includes(`requirement-${decisionId}`)) {
        this.requirementsOverflowing[i] = this.checkVerticalOverflow(
          this.$refs[`requirement-${decisionId}`][0],
        );
      }
    }
    this.$forceUpdate();
  },
  methods: {
    checkVerticalOverflow(element) {
      return element.offsetHeight < element.scrollHeight;
    },
    getZoneNames(subApprovals) {
      const { features } = this.$store.getters['flights/mapApprovalAreas'];
      return subApprovals.map(
        (s) => features.find((f) => s.flight_area_id === f.id)?.properties?.name || '',
      ).join(', ');
    },
    getDecisionText(decision) {
      const numberSubApprovals = decision.sub_approvals.length;
      const status = (
        this.approvalsStatusDict.find((a) => a.value === decision.status)?.text
        || this.$gettext('Unkown decision')
      );
      return this.$gettextInterpolate(
        this.$ngettext(
          "The zone %{ zones } status is '%{ status }'",
          "The zones %{ zones } status is '%{ status }'",
          numberSubApprovals,
        ),
        {
          status,
          zones: this.getZoneNames(decision.sub_approvals),
        },
      );
    },
    setDecisionHover(subApprovals) {
      const flightAreaIds = subApprovals.map((s) => s.flight_area_id);
      this.$store.dispatch(SET_APPROVAL_FLIGHT_AREAS_HOVER_NS, flightAreaIds);
    },
    setAreasHover(areaIds) {
      this.$store.dispatch(SET_APPROVAL_FLIGHT_AREAS_HOVER_NS, areaIds);
    },
    centerOnDecision(subApprovals) {
      const flightAreaIds = subApprovals.map((s) => s.flight_area_id);
      this.centerOnFlightAreas(flightAreaIds);
    },
    centerOnAreasWithoutDecisions() {
      this.centerOnFlightAreas(this.areasWithoutDecision.map((a) => a.id));
    },
    centerOnFlightAreas(flightAreaIds) {
      const { features } = this.$store.getters['flights/mapApprovalAreas'];
      const areas = features.filter((feature) => flightAreaIds.includes(feature.id));
      const geometry = this.$turf.multiPolygon(areas.map((a) => a.geometry.coordinates));
      this.$store.dispatch(ZOOM_TO_AREA_NS, geometry);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.outlined {
  border-style: solid;
  border-width: thin;
  border-radius: 6px;
  &.accepted {
    border-color: $accepted-background-color;
  }
  &.incomplete {
    border-color: $flight-action_needed-background-color;
  }
  &.refused {
    border-color: $refused-background-color;
  }
  &.reserves {
    border-color: $reserves-background-color;
  }
  &.submitted, &.pending, &.awaiting_validation {
    border-color: $submitted-background-color;
  }
}
.requirements {
  margin: 0px;
  color: black;
  white-space: pre-line;
  &.with-overflow {
    cursor: pointer;
  }
  &.collapsed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

</style>
