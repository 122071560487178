<template>
  <v-row
    no-gutters
    class="details-card"
  >
    <!-- Approval details -->
    <v-col
      cols="12"
      :md="approvalPageIsSimplified ? 6 : 12"
      class="px-4 pt-4"
    >
      <!-- Inside impacting zone -->
      <div
        v-if="approval.inside_impacting_zone"
        class="mb-2"
      >
        <v-icon color="red">mdi-alert-circle</v-icon>
        <span
          v-translate
          class="ml-1"
        >
          This approval is located in an impacting zone
        </span>
      </div>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            MISSION
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.display_identifier }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1 d-flex align-center"
        >
          <span
            class="details-card__label"
            v-translate
          >
            ADDRESS
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
          class="d-flex align-center justify-space-between"
        >
          <span v-if="approval.address">
            {{ approval.address }}
          </span>
          <span v-else>
            {{ approval.commune }}
          </span>
          <v-btn
            text
            icon
            small
            @click="centerOnApproval()"
          >
            <v-icon color="primary">icon-geoloc_line</v-icon>
          </v-btn>
        </v-col>
        <template v-if="isUserAuthority && !isUserPrefecture && hasManySubApprovals">
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              LATITUDE
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ approval.lat | coord }} ({{ approval.lat | coordDMS(false) }})
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              LONGITUDE
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ approval.lng | coord }} ({{ approval.lng | coordDMS(true) }})
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              ALTITUDE MAX
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ maxAltitude | meterAndFeet  }}
          </v-col>
        </template>
        <template>
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              DATES
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <span>
              {{ flight.date_start | date }} - {{ flight.date_end | date }}
            </span>
          </v-col>
        </template>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            LOCAL HOUR
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ flight.time_start | hour }} - {{ flight.time_end | hour }} loc.
        </v-col>
        <!-- UTC hour -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            UTC HOUR
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ flight.time_start_utc | hour }} - {{ flight.time_end_utc | hour }} UTC
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EXPECTED DURATION
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ flight.expected_duration | hour }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            FLIGHT
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <span
            v-if="flight.pilot_in_sight"
            v-translate
          >
            in sight
          </span>
          <span
            v-else
            class="error--text font-weight-bold"
            v-translate
          >
            off sight
          </span>
        </v-col>
        <template v-if="flight.night_flying">
          <v-col cols="2">
            <span
              class="details-card__label"
              v-translate
            >
              NIGHT FLIGHT
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <translate>yes</translate>
          </v-col>
        </template>
        <!-- Scenario -->
        <template v-if="approval.declared_scenario">
          <v-col cols="2">
            <span
              class="details-card__label"
              v-translate
            >
              SCENARIO
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ declaredScenario }}
          </v-col>
        </template>
        <hr class="separator" />
        <!-- Zone information -->
        <v-col cols="12">
          <SubApprovalsDetails
            :approval="approval"
            @center-on-sub-approval="(geom) => centerOnSubApprovalFullScreenInterface(geom)"
          />
        </v-col>
        <hr class="separator" />
        <!-- Pilot information -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PILOT
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_name }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EMAIL
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_email }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PHONE NUMBER
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_phone_number | phone }}
        </v-col>
        <!-- Drones information -->
        <template v-if="displayDronesInfo">
          <v-row
            no-gutters
            v-for="(drone, index) in approval.drones"
            :key="index"
          >
            <hr class="separator" />
            <!-- Remote id -->
            <v-col
              cols="12"
              md="2"
              class="pr-1"
            >
              <span
                class="details-card__label"
                v-translate
              >
                REMOTE ID (FR)
              </span>
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              {{ drone.remote_id_fr }}
            </v-col>
            <!-- Manufacturer -->
            <v-col
              cols="12"
              md="2"
              class="pr-1"
            >
              <span
                class="details-card__label"
                v-translate
              >
                MANUFACTURER
              </span>
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              {{ drone.manufacturer }}
            </v-col>
            <!-- Model -->
            <v-col
              cols="12"
              md="2"
              class="pr-1"
            >
              <span
                class="details-card__label"
                v-translate
              >
                MODEL
              </span>
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              {{ drone.model_name }}
            </v-col>
          </v-row>
        </template>
        <hr class="separator" />
        <!-- Exploitant information -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EXPLOITANT
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.company_name }}
        </v-col>
        <!-- Exploitant phone number -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PHONE NUMBER
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.exploitant.phone_number | phone }}
        </v-col>
        <!-- Exploitant AT UID -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            ALPHATANGO UID
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <span>
            {{ approval.exploitant.alphatango_uids.join(", ") }}
          </span>
        </v-col>
        <!-- Exploitant authority note -->
        <template v-if="exploitantNote">
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              INTERNAL MEMO
            </span>
          </v-col>
          <v-col
            cols="12"
            md="10"
          >
            {{ exploitantNote }}
          </v-col>
        </template>
        <hr class="separator" />
        <!-- Details gave by dronist -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            DETAILS
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.details }}
        </v-col>
        <!-- ATS internal note -->
        <template v-if="isUserAuthority && !isUserPrefecture">
          <hr class="separator" />
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              INTERNAL NOTE
            </span>
          </v-col>
          <v-col
            cols="12"
            md="10"
            class="d-flex justify-space-between"
          >
            <span>
              {{ approval.ats_internal_note }}
            </span>
            <v-btn
              text
              icon
              color="black"
              @click="editATSNoteDialog=true"
            >
              <v-icon>icon-edit_line</v-icon>
            </v-btn>
          </v-col>
        </template>
        <!-- Mission sheet -->
        <template v-if="isAuthorityFullScreenInterface && approval.mission_sheet">
          <hr class="separator" />
          <v-col
            cols="12"
            md="2"
            class="pr-1 d-flex align-center"
          >
            <span
              class="details-card__label"
              v-translate
            >
              MISSION SHEET
            </span>
          </v-col>
          <v-col
            cols="12"
            md="10"
          >
            <div class="d-flex">
              <v-menu
                absolute
                style="max-width: 600px"
              >
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="tooltip">
                        <v-btn
                          text
                          icon
                          v-on="menu"
                          class="m-0 p-0"
                          :loading="loadingMissionSheetDownload"
                          :disabled="!missionSheetAvailable"
                        >
                          <v-icon>icon-download</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <translate
                      v-if="missionSheetAvailable"
                      key="missionSheetAvailable"
                    >
                      Download mission sheet.
                    </translate>
                    <translate
                      v-else
                      key="missionSheetUnAvailable"
                    >
                      The mission sheet is being generated. You will download it quickly.
                    </translate>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(format, index) in missionSheetFormat"
                    :key="index"
                  >
                    <v-list-item-title
                      class="cursor-pointer"
                      @click="downloadMissionSheet(format.format)"
                    >
                      {{ format.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-col>
    <v-col
      v-if="isAuthorityFullScreenInterface"
      cols="12"
      :md="approvalPageIsSimplified ? 6 : 12"
      class="px-4 pt-4"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <!-- Take off authorization alert -->
          <TakeOffAuthorizationAlertSubmitted
            v-if="displayTakeOffAuthorization"
            :takeOffAuthorization="takeOffAuthorization"
            :loadingTakeOffAuthorizationDetails="loadingTakeOffAuthorizationDetails"
            class="mb-4"
          />
          <!-- Intersecting approvals -->
          <IntersectingApprovals :approvalId="approval.id" />
          <!-- Decisions -->
          <AuthorityDecisionsDetails
            v-if="approvalPageIsSimplified && !approvalDetailsLoading"
            :approval="approval"
            :subApprovals="approval.sub_approvals"
          />
          <!-- Take off authorization -->
          <ApprovalTakeOffAuthorizationPanel
            v-if="displayTakeOffAuthorization"
            :takeOffAuthorization="takeOffAuthorization"
            :loadingTakeOffAuthorizationDetails="loadingTakeOffAuthorizationDetails"
          />
          <!-- Exploitant documents  -->
          <hr class="separator" />
          <v-expansion-panels
            v-model="panelDocuments"
            light
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span
                  class="details-card__label"
                  v-translate
                >
                  EXPLOITATION'S DOCUMENTS
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content expand-icon="keyboard_arrow_down">
                <ExploitationDocuments
                  :exploitantId="approval.exploitant.id"
                  :readOnly="true"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- AlphaTango notifications  -->
          <template v-if="isUserPrefecture && prefectureNotifications.length">
            <hr class="separator" />
            <NotifAlphaTangoPrefectureList
              :flightId="flight.id"
              :notifications="prefectureNotifications"
            />
          </template>
        </v-col>
      </v-row>
    </v-col>
    <!-- Edit ATS note dialog  -->
    <v-dialog
      v-model="editATSNoteDialog"
      width="500"
    >
      <EditAtsNoteForm
        :approval="approval"
        @close-form="editATSNoteDialog=false"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import APIService from '@/services/api';

import { SET_APPROVAL_TAKE_OFF_AUTHORIZATION_NS } from '@/store/approvals';
import { GET_EXPLOITANT_DOCUMENTS_NS } from '@/store/exploitants';
import { DONE } from '@/store/status';

import AuthorityDecisionsDetails from '@/components/Approvals/AuthorityDecisionsDetails.vue';
import EditAtsNoteForm from '@/components/Approvals/EditAtsNoteForm.vue';
import IntersectingApprovals from '@/components/Approvals/IntersectingApprovals.vue';
import SubApprovalsDetails from '@/components/Approvals/SubApprovalsDetails.vue';
import NotifAlphaTangoPrefectureList from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoPrefectureList.vue';
import ExploitationDocuments from '@/components/Settings/Dronists/ExploitationDocuments.vue';
import ApprovalTakeOffAuthorizationPanel from '@/components/TakeOffAuthorizations/ApprovalTakeOffAuthorizationPanel.vue';
import TakeOffAuthorizationAlertSubmitted from '@/components/TakeOffAuthorizations/TakeOffAuthorizationAlertSubmitted.vue';

export default {
  name: 'ApprovalDetails',
  components: {
    AuthorityDecisionsDetails,
    EditAtsNoteForm,
    IntersectingApprovals,
    SubApprovalsDetails,
    NotifAlphaTangoPrefectureList,
    ExploitationDocuments,
    ApprovalTakeOffAuthorizationPanel,
    TakeOffAuthorizationAlertSubmitted,
  },
  props: { approval: Object },
  data() {
    return {
      editATSNoteDialog: false,
      panelDocuments: false,
      missionSheetFormat: [
        { title: this.$gettext('Mission sheet (PDF)'), format: 'PDF' },
        { title: this.$gettext('Mission sheet (Word)'), format: 'Word' },
      ],
      loadingMissionSheetDownload: false,
      loadingTakeOffAuthorizationDetails: false,
      scenarios: {
        s0: this.$gettext('Scenario S0'),
        s1: this.$gettext('Scenario S1'),
        s2: this.$gettext('Scenario S2'),
        s3: this.$gettext('Scenario S3'),
        a1: this.$gettext('Open category A1'),
        a2: this.$gettext('Open category A2'),
        a3: this.$gettext('Open category A3'),
        sts01: this.$gettext('Scenario STS-01'),
        sts02: this.$gettext('Scenario STS-02'),
        hss: this.$gettext('Non-standard scenarios'),
      },
      exploitantNote: '',
    };
  },
  computed: {
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
    isAuthorityController() {
      return this.$store.getters['authentication/isAuthorityController'];
    },
    hasSimplifiedInterface() {
      return this.$store.getters['authentication/hasSimplifiedInterface'];
    },
    approvalPageIsSimplified() {
      return this.hasSimplifiedInterface && this.isAuthorityController;
    },
    flight() {
      return this.approval.flight;
    },
    approvalDetailsLoading() {
      return (
        this.$store.state.approvals.approvalDetailsLoading
        || this.flight === undefined
      );
    },
    hasManySubApprovals() {
      return this.approval.sub_approvals?.length > 1;
    },
    maxAltitude() {
      return Math.max(...(this.approval.sub_approvals.map((s) => s.elevation + s.height)));
    },
    prefectureNotifications() {
      return this.flight.prefecture_notifications || [];
    },
    missionSheetAvailable() {
      return this.approval.mission_sheet?.status === DONE;
    },
    takeOffAuthorization() {
      return this.approval?.take_off_authorization || null;
    },
    displayTakeOffAuthorization() {
      return (
        this.isAuthorityFullScreenInterface
        && this.isAuthorityController
        && this.takeOffAuthorization !== null
      );
    },
    declaredScenario() {
      return this.scenarios[this.approval.declared_scenario] || this.$gettext('Unknown scenario');
    },
    displayDronesInfo() {
      return this.approval.drones?.length;
    },
  },
  created() {
    if (this.displayTakeOffAuthorization) {
      this.getTakeOffAuthorizationDetails();
    }
  },
  mounted() {
    if (this.isAuthorityFullScreenInterface && this.approval.exploitant.id) {
      this.$store.dispatch(GET_EXPLOITANT_DOCUMENTS_NS, this.approval.exploitant.id);
    }
    APIService.getCustomExploitantStructureLinkNote(
      this.approval.structure_id, this.approval.exploitant.id,
    )
      .then(({ data }) => {
        this.exploitantNote = data.note;
      });
  },
  methods: {
    centerOnSubApprovalFullScreenInterface(geometry) {
      this.$emit('show-map-dialog', { selectedSubApprovalGeometry: geometry });
    },
    centerOnApproval() {
      if (this.isAuthorityFullScreenInterface) {
        this.$emit('show-map-dialog', { centerOnflight: true });
      } else {
        this.$emit('center-on-approval');
      }
    },
    downloadWord() {
      this.loadingMissionSheetDownload = true;
      APIService.downloadApprovalWordSheet(this.approval.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettext('mission_sheet.docx')
          );
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.loadingMissionSheetDownload = false;
        });
    },
    downloadPDF() {
      this.loadingMissionSheetDownload = true;
      APIService.downloadApprovalPDFSheet(this.approval.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettext('mission_sheet.pdf')
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.loadingMissionSheetDownload = false;
        });
    },
    downloadMissionSheet(format) {
      switch (format) {
        case 'Word':
          return this.downloadWord();
        default:
          return this.downloadPDF();
      }
    },
    getTakeOffAuthorizationDetails() {
      this.loadingTakeOffAuthorizationDetails = true;
      APIService.getTakeOffAuthorization(this.takeOffAuthorization.id)
        .then(({ data }) => {
          this.$store.commit(
            SET_APPROVAL_TAKE_OFF_AUTHORIZATION_NS,
            { approvalId: data.approval_id, takeOffAuthorization: data },
          );
        })
        .finally(() => {
          this.loadingTakeOffAuthorizationDetails = false;
        });
    },
  },
};
</script>
