import Vue from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const filterPhoneNumber = (value) => {
  if (!value) return '';
  // Remove all white spaces
  const phoneNumber = value.replace(/\s/g, '');

  // Skip first three digits if phone number is odd
  const isOdd = phoneNumber.length % 2 !== 0;
  const startIndex = isOdd ? 3 : 0;

  let pairDigits = [];
  for (let i = startIndex; i < phoneNumber.length; i += 2) {
    pairDigits.push(phoneNumber.slice(i, i + 2));
  }

  // Add skipped digits if necessary
  if (isOdd) {
    pairDigits = [phoneNumber.slice(0, startIndex)].concat(pairDigits);
  }

  return pairDigits.join(' ');
};

Vue.filter('phone', filterPhoneNumber);
