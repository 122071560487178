<template>
  <div class="fill-height">
    <div
      class="flight-approval"
      :class="{ 'mr-2': !isMobileBreakpoint }"
    >
      <!-- Approval Header  -->
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="px-sm-3 py-3 flex-grow-0"
        :class="getHeaderClass"
      >
        <v-col cols="12">
          <div class="flight-approval__header">
            <FlightStatusIcon
              :status="approval.status"
              :isClosed="approval.is_closed"
              theme="approvals"
              reverseColor
              withTooltip
              tooltipBottom
            />
            <div class="flex-grow-1 font-weight-medium">
              <template v-if="approval.authority_type === 'prefecture'">
                <span v-translate>Exchanges with</span>
              </template>
              <template v-else>
                <template v-if="approval.display_identifier">
                  <span>
                    {{ approval.display_identifier }}
                  </span>
                  <span class="px-1"> - </span>
                </template>
                <span v-translate>Approval</span>
                <span
                  v-if="approval.is_closed"
                  class="pl-1"
                  v-translate
                >
                  cancelled
                </span>
                <span v-else-if="approval.display_status">
                  {{ approval.display_status.toLowerCase() }}
                </span>
                <span class="px-1"> - </span>
              </template>
              <template>
                <span>
                  {{ approval.authority_name }}
                </span>
              </template>
            </div>
            <!-- Download approval sheet -->
            <div v-if="canDownloadApprovalSheet">
              <v-tooltip
                bottom
                :disabled="isMobileBreakpoint"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      text
                      icon
                      dark
                      :loading="loadingApprovalSheet"
                      :disabled="loadingApprovalSheet || !approvalSheetAvailable"
                      @click="downloadApprovalSheet()"
                    >
                      <v-icon color="white">icon-download</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ downloadMissionSheetTooltip }}</span>
              </v-tooltip>
            </div>
            <div class="form-close">
              <div :class="closeFormTextDisplay">
                <span v-translate>
                  Close
                </span>
              </div>
              <v-btn
                icon
                dark
                @click="close()"
              >
                <v-icon color="white">close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- Approval details  -->
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="px-sm-3 py-3 flex-grow-1"
      >
        <v-col cols="12">
          <FlightApprovalDetails
            :approval="approval"
            :flight="flight"
            :constraint="constraint"
            :capability="capability"
            @action-on-approval="() => actionOnApproval = true"
            class="flex-grow-1"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import APIService from '@/services/api';

import { APPLICATION_DISPLAY } from '@/store/application';
import { GET_CONSTRAINTS_ANALYSIS_NS, SET_FLIGHT_APPROVAL_ID_SELECTED_NS } from '@/store/flights';
import { DONE } from '@/store/status';

import FlightStatusIcon from '@/components/StatusIcon/FlightStatusIcon.vue';
import FlightApprovalDetails from '@/components/Flights/FlightApprovalDetails.vue';

export default {
  name: 'FlightApproval',
  components: {
    FlightStatusIcon,
    FlightApprovalDetails,
  },
  data() {
    return {
      loadingApprovalSheet: false,
      actionOnApproval: false,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    display() {
      return this.$store.state.application.display;
    },
    closeFormTextDisplay() {
      if (this.display === APPLICATION_DISPLAY.BIG) {
        return 'd-none d-md-flex';
      }
      return 'd-none d-lg-flex';
    },
    flight() {
      return this.$store.getters['flights/flightSelected'];
    },
    approval() {
      return this.$store.getters['flights/flightApprovalSelected'];
    },
    constraint() {
      return this.flight.constraintsAnalysis.find((c) => c.id === this.approval.constraint_id);
    },
    capability() {
      return this.approval.capability;
    },
    canDownloadApprovalSheet() {
      return this.approval.mission_sheet != null;
    },
    approvalSheetAvailable() {
      return this.approval.mission_sheet?.status === DONE;
    },
    downloadMissionSheetTooltip() {
      if (this.approvalSheetAvailable) {
        return this.$gettext('Download the mission sheet PDF');
      }
      return this.$gettext(
        'The mission form is being generated. You should be able to download it in a few minutes',
      );
    },
    getHeaderClass() {
      if (this.approval.is_closed) {
        return 'cancelled';
      }
      return this.approval.status;
    },
  },
  destroyed() {
    this.close();
  },
  methods: {
    downloadApprovalSheet() {
      this.loadingApprovalSheet = true;
      APIService.downloadApprovalPDFSheet(this.approval.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettext('mission_sheet.pdf')
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.loadingApprovalSheet = false;
        });
    },
    close() {
      if (this.actionOnApproval) {
        this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id });
      }
      this.$store.commit(SET_FLIGHT_APPROVAL_ID_SELECTED_NS, null);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight-approval {
  background-color: white;
  color: rgba(0, 0, 0, .87);
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    .form-close {
      text-align: end;
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      flex-shrink: 0;
      padding-left: 8px;
    }
  }
  .row {
    display: flex;
    flex-direction: column;
    .col-12 {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    &.submitted, &.pending, &.awaiting_validation {
      color: $color-white;
      background-color: $submitted-background-color;
    }
    &.accepted {
      color: $color-white;
      background-color: $accepted-background-color;
    }
    &.incomplete {
      color: $color-white;
      background-color: $flight-action_needed-background-color;
    }
    &.refused, &.error {
      color: $color-white;
      background-color: $refused-background-color;
    }
    &.reserves {
      color: $color-white;
      background-color: $reserves-background-color;
    }
    &.cancelled {
      color: $color-white;
      background-color: $flight-archived-background-color;
    }
  }
}

</style>
