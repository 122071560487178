import Vue from 'vue';

import APIService from '@/services/api';

const namespace = 'livestreams';

const GET_LIVESTREAMS = 'GET_LIVESTREAMS';
const SET_LIVESTREAMS = 'SET_LIVESTREAMS';
const SET_LIVESTREAMS_LOADING = 'SET_LIVESTREAMS_LOADING';
const ADD_OR_UPDATE_LIVESTREAM = 'ADD_OR_UPDATE_LIVESTREAM';
const DELETE_LIVESTREAM = 'DELETE_LIVESTREAM';
const SET_LIVESTREAM_TO_DELETE = 'SET_LIVESTREAM_TO_DELETE';
const SET_LIVESTREAM_TO_STOP = 'SET_LIVESTREAM_TO_STOP';
const SET_SELECTED_LIVESTREAM = 'SET_SELECTED_LIVESTREAM';
const SET_LIVESTREAM_PLAYING = 'SET_LIVESTREAM_PLAYING';
const SET_LIVESTREAM_STARTED = 'SET_LIVESTREAM_STARTED';

export const GET_LIVESTREAMS_NS = `${namespace}/${GET_LIVESTREAMS}`;
export const SET_LIVESTREAMS_NS = `${namespace}/${SET_LIVESTREAMS}`;
export const ADD_OR_UPDATE_LIVESTREAM_NS = `${namespace}/${ADD_OR_UPDATE_LIVESTREAM}`;
export const DELETE_LIVESTREAM_NS = `${namespace}/${DELETE_LIVESTREAM}`;
export const SET_LIVESTREAM_TO_DELETE_NS = `${namespace}/${SET_LIVESTREAM_TO_DELETE}`;
export const SET_LIVESTREAM_TO_STOP_NS = `${namespace}/${SET_LIVESTREAM_TO_STOP}`;
export const SET_SELECTED_LIVESTREAM_NS = `${namespace}/${SET_SELECTED_LIVESTREAM}`;
export const SET_LIVESTREAM_PLAYING_NS = `${namespace}/${SET_LIVESTREAM_PLAYING}`;
export const SET_LIVESTREAM_STARTED_NS = `${namespace}/${SET_LIVESTREAM_STARTED}`;

function initialState() {
  return {
    livestreams: [],
    selectedLivestreamId: undefined,
    livestreamIdToDelete: undefined,
    livestreamIdToStop: undefined,
    livestreamPlayingId: undefined,
    loadingLivestreams: false,
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    currentLivestreams: (state) => state.livestreams.filter((l) => l.status === 'live'),
    selectedLivestream: (state) => state.livestreams.find(
      (l) => l.id === state.selectedLivestreamId,
    ),
    livestreamToDelete: (state) => state.livestreams.find(
      (l) => l.id === state.livestreamIdToDelete,
    ),
    livestreamToStop: (state) => state.livestreams.find(
      (l) => l.id === state.livestreamIdToStop,
    ),
    livestreamPlaying: (state) => state.livestreams.find(
      (l) => l.id === state.livestreamPlayingId,
    ),
    latestLivestreams: (state) => state.livestreams.slice(0, 4),
  },
  mutations: {
    [SET_LIVESTREAMS](state, livestreams) {
      Vue.set(state, 'livestreams', livestreams);
    },
    [SET_LIVESTREAMS_LOADING](state, value) {
      Vue.set(state, 'loadingLivestreams', value);
    },
    [ADD_OR_UPDATE_LIVESTREAM](state, { livestream }) {
      const existingLivestreamIndex = state.livestreams.findIndex((n) => n.id === livestream.id);
      if (existingLivestreamIndex === -1) {
        state.livestreams.unshift(livestream);
      } else {
        Vue.set(state.livestreams, existingLivestreamIndex, livestream);
      }
    },
    [DELETE_LIVESTREAM](state, livestreamId) {
      const existingLivestreamIndex = state.livestreams.findIndex((n) => n.id === livestreamId);
      if (existingLivestreamIndex !== -1) {
        const { livestreams } = state;
        livestreams.splice(existingLivestreamIndex, 1);
        Vue.set(state, 'livestreams', livestreams);
      }
    },
    [SET_LIVESTREAM_TO_DELETE](state, livestreamId) {
      Vue.set(state, 'livestreamIdToDelete', livestreamId);
    },
    [SET_LIVESTREAM_TO_STOP](state, livestreamId) {
      Vue.set(state, 'livestreamIdToStop', livestreamId);
    },
    [SET_SELECTED_LIVESTREAM](state, livestreamId) {
      Vue.set(state, 'selectedLivestreamId', livestreamId);
    },
    [SET_LIVESTREAM_PLAYING](state, livestreamId) {
      Vue.set(state, 'livestreamPlayingId', livestreamId);
    },
    [SET_LIVESTREAM_STARTED](state, livestreamId) {
      const existingLivestreamIndex = state.livestreams.findIndex(
        (livestream) => livestream.id === livestreamId,
      );
      if (existingLivestreamIndex !== -1) {
        Vue.set(state.livestreams[existingLivestreamIndex], 'started', false);
      }
    },
  },
  actions: {
    [GET_LIVESTREAMS]({ commit }) {
      commit(SET_LIVESTREAMS_LOADING, true);
      APIService.getLivestreams()
        .then(({ data }) => {
          commit(SET_LIVESTREAMS, data);
          const currentLivestream = data.find((livestream) => livestream.status === 'live');
          commit(SET_LIVESTREAM_PLAYING, currentLivestream ? currentLivestream.id : undefined);
        })
        .finally(() => {
          commit(SET_LIVESTREAMS_LOADING, false);
        });
    },
    [ADD_OR_UPDATE_LIVESTREAM]({ commit }, { livestream }) {
      commit(ADD_OR_UPDATE_LIVESTREAM, { livestream });
      if (!livestream.update) {
        commit(SET_SELECTED_LIVESTREAM, livestream.id);
        if (livestream.status === 'live') {
          commit(SET_LIVESTREAM_PLAYING, livestream.id);
        }
      }
    },
    [DELETE_LIVESTREAM]({ commit }, livestreamId) {
      commit(DELETE_LIVESTREAM, livestreamId);
      commit(SET_LIVESTREAM_PLAYING, undefined);
    },
  },
};
