/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

import { APPLICATION_TAB } from '@/store/application';
import { RESET_FILTERS_NS, SET_FLIGHT_SELECTED_NS } from '@/store/flights';

import Notification from '@/components/Header/notifications/Notification';

/**
 * DronistTakeOffAuthorizationNotification class derived from Notification,
 * represent a notification about an approval with a flight en route.
 *
 * @class DronistTakeOffAuthorizationNotification
 * @extends {Notification}
 */
class DronistTakeOffAuthorizationNotification extends Notification {
  constructor(rawNotification) {
    super(rawNotification);
    this.tab = `${APPLICATION_TAB.MISSION}s`;
    const { payload } = rawNotification;
    this._flightId = payload.flight_id;
  }

  async atClick(dispatchStoreAction, subscribeStoreMutation) {
    try {
      await this.selectFlight({ dispatchStoreAction, that: this });
    } catch (error) {
      // If not found we need to reset filters
      dispatchStoreAction(RESET_FILTERS_NS);
      this.unsubscribe = subscribeStoreMutation({
        mutationName: 'flights/SET_FLIGHTS_COLLECTION',
        callback: this.selectFlight,
        params: { dispatchStoreAction, that: this },
      });
    }
  }

  async selectFlight({ dispatchStoreAction, that }) {
    await dispatchStoreAction(SET_FLIGHT_SELECTED_NS, { flightId: that._flightId, reload: true });
    if (that.unsubscribe) {
      // Remove subscription to mutation if filters were reset
      that.unsubscribe();
    }
  }
}

export default DronistTakeOffAuthorizationNotification;
