<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Delete pilot</translate>
    </v-card-title>
    <v-card-text>
      <div
        v-if="loadingCheckCanBeDeleted"
        class="d-flex flex-column align-center"
      >
        <p v-translate>Check if pilot is deletable...</p>
        <v-progress-circular
          indeterminate
          color="primary"
          :size="30"
          :width="2"
        />
      </div>
      <div v-else>
        <!-- Pilot can be deleted -->
        <template v-if="canBeDeleted">
          <p>
            {{ deleteConfirmation }}
          </p>
          <template v-if="isPerSeatSubscription && !pilotToDelete.is_archived">
            <PilotChangeProrate
              :pilotToRemoveId="pilotToDelete.id"
              @prorate-loading="prorateLoaded=false"
              @prorate-loaded="prorateLoaded=true"
            />
          </template>
        </template>
        <!-- Pilot can't be deleted -->
        <template v-else>
          <p>
            {{ deleteImpossible }}
          </p>
          <!-- But pilot can be archived -->
          <template v-if="!pilotToDelete.is_archived && !pilotToDelete.is_main_contact">
            <p>
              {{ archiveConfirmation }}
            </p>
            <template v-if="isPerSeatSubscription">
              <PilotChangeProrate
                :pilotToRemoveId="pilotToDelete.id"
                :archiveMode="true"
                @prorate-loading="prorateLoaded=false"
                @prorate-loaded="prorateLoaded=true"
              />
            </template>
          </template>
        </template>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <div v-if="!loadingCheckCanBeDeleted">
        <v-btn
          key="delete"
          v-if="canBeDeleted"
          :loading="loading"
          :disabled="isPerSeatSubscription && !prorateLoaded && !pilotToDelete.is_archived"
          @click="deletePilot()"
          text
          color="primary"
        >
          <translate>Confirm</translate>
        </v-btn>
        <v-btn
          key="archive"
          v-else-if="!pilotToDelete.is_archived && !pilotToDelete.is_main_contact"
          :loading="loading"
          :disabled="(isPerSeatSubscription && !prorateLoaded)"
          @click="archive()"
          text
          color="primary"
        >
          <translate>Not propose in future missions</translate>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { GET_PILOTS_NS } from '@/store/pilots';

import PilotChangeProrate from '@/components/Pilots/PilotChangeProrate.vue';

export default {
  name: 'PilotDelete',
  components: { PilotChangeProrate },
  props: {
    pilotToDelete: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      prorateLoaded: false,
      loading: false,
      loadingCheckCanBeDeleted: false,
      canBeDeleted: true,
    };
  },
  computed: {
    userSubscription() {
      return this.$store.state.authentication.user.subscription;
    },
    isPerSeatSubscription() {
      return !this.userSubscription.trial
        && ['PER_SEAT_M', 'PER_SEAT_Y'].includes(this.userSubscription.subscription_plan.code);
    },
    deleteConfirmation() {
      return this.$gettextInterpolate(
        this.$gettext('Are you sure you want to delete the pilot %{firstName} %{lastName} ?'),
        {
          firstName: this.pilotToDelete.first_name,
          lastName: this.pilotToDelete.last_name,
        },
        true,
      );
    },
    deleteImpossible() {
      if (this.pilotToDelete.is_main_contact) {
        if (this.pilotToDelete.gender === 'F') {
          return this.$gettextInterpolate(
            this.$pgettext(
              'Féminin',
              "The pilot %{firstName} %{lastName} is your main contact and can't be deleted.",
            ),
            { firstName: this.pilotToDelete.first_name, lastName: this.pilotToDelete.last_name },
          );
        }
        return this.$gettextInterpolate(
          this.$pgettext(
            'Masculin',
            "The pilot %{firstName} %{lastName} is your main contact and can't be deleted.",
          ),
          { firstName: this.pilotToDelete.first_name, lastName: this.pilotToDelete.last_name },
          true,
        );
      }
      if (this.pilotToDelete.gender === 'F') {
        return this.$gettextInterpolate(
          this.$pgettext(
            'Féminin',
            "The pilot %{fName} %{lName} is already linked to a mission and can't be deleted.",
          ),
          { fName: this.pilotToDelete.first_name, lName: this.pilotToDelete.last_name },
          true,
        );
      }
      return this.$gettextInterpolate(
        this.$pgettext(
          'Masculin',
          "The pilot %{firstName} %{lastName} is already linked to a mission and can't be deleted.",
        ),
        { firstName: this.pilotToDelete.first_name, lastName: this.pilotToDelete.last_name },
        true,
      );
    },
    archiveConfirmation() {
      if (this.pilotToDelete.gender === 'F') {
        return this.$pgettext(
          'Féminin',
          `You can however archive this pilot if you don't want her to be proposed for your future
            missions.`,
        );
      }
      return this.$pgettext(
        'Masculin',
        `You can however archive this pilot if you don't want him to be proposed for your future
          missions.`,
      );
    },
  },
  created() {
    this.loadingCheckCanBeDeleted = true;
    APIService.canPilotBeDeleted(this.pilotToDelete.id)
      .then(({ data }) => {
        this.canBeDeleted = data.can_be_deleted;
      })
      .finally(() => {
        this.loadingCheckCanBeDeleted = false;
      });
  },
  methods: {
    close() {
      this.$store.dispatch(GET_PILOTS_NS);
      this.$emit('close');
    },
    deletePilot() {
      this.loading = true;
      APIService.deletePilot(this.pilotToDelete.id)
        .then(() => {
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    archive() {
      this.loading = true;
      APIService.updatePilot(
        this.pilotToDelete.id,
        { is_archived: true, set_has_web_account: false, admin_prileges: false },
      )
        .then(() => {
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
