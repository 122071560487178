<template>
  <div>
    <template v-if="!isDeltaLogin">
      <template v-if="!resetPassword">
        <div class="headline mb-5 black--text">
          <span v-translate>Connection</span>
        </div>
        <!-- Login form -->
        <v-form
          ref="form"
          lazy-validation
          @submit="login()"
        >
          <v-text-field
            :label="$gettext('Nom d\'utilisateur ou email')"
            v-model="username"
            :rules="usernameRules"
            required
            class="black--text"
            autofocus
            @keyup.enter="login()"
          />
          <v-text-field
            :label="$gettext('Mot de passe')"
            :type="hiddenPassword ? 'password' : 'text'"
            v-model="password"
            :rules="passwordRules"
            required
            class="black--text mb-2"
            @keyup.enter="login()"
            :append-icon="hiddenPassword ? 'visibility' : 'visibility_off'"
            @click:append="hiddenPassword = !hiddenPassword"
          />
          <!-- Reset password -->
          <span
            @click="resetPassword=true"
            class="cursor-pointer primary--text"
            v-translate
          >
            Forgot your password?
          </span>
        </v-form>
        <div class="mt-5 login-buttons">
          <v-btn
            text
            color="primary"
            @click="$emit('createAccount')"
          >
            <translate>Create a free account</translate>
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click.prevent="login()"
            type="submit"
            :disabled="!valid || loading"
            :loading="loading"
          >
            <translate>Login</translate>
          </v-btn>
        </div>
      </template>
      <template v-if="resetPassword">
        <ResetPassword @back-to-login="resetPassword=false" />
      </template>
    </template>
    <template v-else>
      <div class="text-h4 mb-8">
        {{ deltaLoginTexts.title }}
      </div>
      <p class="body-1 font-weight-light">
        {{ deltaLoginTexts.details }}
      </p>
      <v-alert
        v-if="deltaLoginError"
        class="mt-4"
        type="error"
        outlined
        text
      >
        {{ getDeltaLoginErrorMessage(deltaLoginError) }}
      </v-alert>
      <div
        v-else-if="deltaLoginLoading"
        class="mt-4"
      >
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </div>

    </template>
  </div>
</template>

<script>
import { LOGIN_NS, LOGOUT_NS } from '@/store/authentication';

import ResetPassword from '@/components/Home/ResetPassword.vue';

export default {
  name: 'Login',
  props: {
    isDeltaLogin: Boolean,
    deltaLoginError: String,
  },
  components: { ResetPassword },
  data() {
    return {
      valid: true,
      loading: false,
      username: '',
      usernameRules: [
        (v) => !!v || this.$gettext("Le nom d'utilisateur est requis"),
      ],
      password: '',
      passwordRules: [
        (v) => !!v || this.$gettext('Le mot de passe est requis'),
      ],
      hiddenPassword: true,
      resetPassword: false,
      deltaLoginLoading: false,
    };
  },
  computed: {
    deltaLoginTexts() {
      return {
        title: this.$gettext('Welcome to the DELTA platform'),
        details: this.$gettextInterpolate(
          this.$gettext('If you have any login problems please contact %{email}'),
          { email: process.env.VUE_APP_DELTA_LOGIN_CONTACT },
        ),
      };
    },
    deltaLoginErrorMessages() {
      return {
        AUTH_USER_UNKNOWN: this.$gettext(
          `Your DELTA account has not been created. Please contact your DELTA administrator at the
           email : rte-drones@rte-france.com`,
        ),
        AUTH_IDP_DENIED: this.$gettext(
          'Your identity provider system has denied your authentication',
        ),
        AUTH_EXPIRED: this.$gettext('Your authentication is no longer valid'),
        AUTH_ASSERTION_INVALID: this.$gettext(
          "The assertion provided by your identity provider couldn't be processed",
        ),
        AUTH_ERROR_UNKNOWN: this.$gettext('The authentication has failed unexpectedly. Please contact rte-drones@rte-france.com'),
      };
    },
  },
  created() {
    if (this.isDeltaLogin) {
      if (this.deltaLoginError) {
        this.$store.dispatch(LOGOUT_NS);
      } else {
        this.deltaLogin();
      }
    }
  },
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store.dispatch(LOGIN_NS, {
          username: this.username,
          password: this.password,
        });
        this.loading = false;
        const userType = (
          this.$store.state.authentication.user
          && this.$store.state.authentication.user.user_type
        );
        const interfaceVersion = this.$store.state.authentication.user.authority_interface_version;
        // add specific routes for the user logged
        this.$router.addUserRoutes(userType, interfaceVersion);
        // go to the default route for the user type
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else if (this.$store.state.authentication && this.$store.state.authentication.logged) {
          window.location.reload();
        }
      }
    },
    deltaLogin() {
      this.deltaLoginLoading = true;
      let loginUrl = process.env.VUE_APP_DELTA_LOGIN_URL;
      if (this.$route.query.redirect) {
        loginUrl = `${loginUrl}/?next=${this.$route.query.redirect}`;
      }
      window.location = loginUrl;
    },
    getDeltaLoginErrorMessage(errorCode) {
      return this.deltaLoginErrorMessages[errorCode];
    },
  },
};
</script>

<style scoped>
.login-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 16px;
}

</style>
