<template>
  <LayoutTooltip
    :disabled="!flightConstraintActionDisabled"
    :attachSelector="`#${id}`"
  >
    <template
      slot="activator"
      slot-scope="{ on }"
    >
      <div
        v-on="on"
        :id="id"
      >
        <slot
          name="action-button"
          :disabled="flightConstraintActionDisabled"
        />
      </div>
    </template>
    <template slot="tooltip-content">
      <span class="caption">
        {{ flightConstraintActionDisabledTooltip }}
      </span>
    </template>
  </LayoutTooltip>
</template>

<script>
import LayoutTooltip from '@/layouts/LayoutTooltip.vue';

export default {
  name: 'LayoutFlightConstraintAction',
  components: { LayoutTooltip },
  props: {
    id: String,
    flight: {
      type: Object,
      required: false,
    },
    availableToGeneralist: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledExtra: {
      type: Boolean,
      required: false,
    },
    disabledExtraTooltip: {
      type: String,
      required: false,
    },
    ignoreFlightEnded: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    isGeneralistOrGeneralistManager() {
      return this.$store.getters['authentication/isGeneralistPilotOrManager'];
    },
    flightConstraintActionRestricted() {
      return (
        this.isContactRestrictedAccount
        || (this.isGeneralistOrGeneralistManager && !this.availableToGeneralist)
      );
    },
    flightConstraintActionDisabled() {
      return (
        this.flightConstraintActionRestricted
        || this.disabledExtra
        || this.flightHasEnded
      );
    },
    flightConstraintActionDisabledTooltip() {
      if (this.flightConstraintActionRestricted) {
        return this.$gettext(`You have a limited user account, you don't have the rights for this
          action.`);
      }
      if (this.disabledExtra) {
        return this.disabledExtraTooltip;
      }
      if (this.flightHasEnded) {
        return this.$gettext("The flight's dates have passed.");
      }
      return '';
    },
    flightHasEnded() {
      if (this.flight && !this.ignoreFlightEnded) {
        const today = new Date();
        today.setHours(0); today.setMinutes(0); today.setSeconds(0);
        return new Date(this.flight.date_end) < today;
      }
      return false;
    },
    isContactRestrictedAccount() {
      return this.$store.state.authentication.user.contact_restricted_account;
    },
  },
};
</script>
