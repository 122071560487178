<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Confirmation needed</translate>
    </v-card-title>
    <v-card-text>
      <div>
        <p
          v-html="texts.confirming"
          class="body-2 entrust-paragraph"
        />
        <template v-if="price">
          <p>
            <translate :translate-params="{price: price}">
              This service comes at a price of %{ price } € HT.
            </translate>
          </p>
          <p v-if="displayPaymentForm">
            {{ refundedLabel }}
          </p>
        </template>
        <template v-else>
          <p>
            {{ texts.changePrice }}
          </p>
        </template>
      </div>
      <v-form v-model="valid">
        <v-textarea
          v-model="entrustInfo"
          :placeholder="entrustInfoPlaceholder"
          :rules="[required]"
          outlined
          class="body-2"
        />
      </v-form>
      <EntrustPaperworkPaymentForm
        v-if="displayPaymentForm"
        ref="entrustPaperworkPaymentForm"
        :flightId="flight.id"
        :price="price"
        :entrustInfo="entrustInfo"
        @invoice-loaded="invoiceDetailsLoading=false"
        @forms-valid="updatePaymentFormsValid"
        @entrust-paperwork-payment-succeeded="paymentSucceeded()"
        @fail-payment="failPayment"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        @click="$emit('close-form')"
        :disabled="loadingPaymentSucceeded"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        color="primary"
        class="px-5"
        :disabled='isConfirmButtonDisabled'
        :loading="loadingPaymentSucceeded || loading"
        @click="confirmButtonAction()"
      >
        <translate>Confirm</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from '@/services/api';

import { GET_FLIGHT_DETAILS_NS } from '@/store/flights';

import EntrustPaperworkPaymentForm from '@/components/Flights/EntrustPaperworkPaymentForm.vue';

export default {
  name: 'EntrustPaperworkForm',
  components: { EntrustPaperworkPaymentForm },
  props: {
    flight: { type: Object, required: true },
    price: { type: Number, required: false },
  },
  data() {
    return {
      valid: true,
      entrustInfo: '',
      loading: false,
      required: ((value) => !!value || this.$gettext('Field required.')),
      invoiceDetailsLoading: false,
      paymentFormsValid: false,
      loadingPaymentSucceeded: false,
    };
  },
  computed: {
    entrustInfoPlaceholder() {
      return this.$gettext(`Please precise the goal of the flight and give us all relevant
        informations.`);
    },
    isConfirmButtonDisabled() {
      if (this.displayPaymentForm) {
        return (
          this.invoiceDetailsLoading
          || !this.paymentFormsValid
          || !this.valid
          || this.loading
        );
      }
      return !this.valid || this.loading;
    },
    displayPaymentForm() {
      const { subscription } = this.$store.state.authentication.user;
      if (subscription) {
        return !!this.price && !subscription.pay_entrusted_procedures_without_stripe;
      }
      return !!this.price;
    },
    refundedLabel() {
      return this.$gettext(`If Clearance is unable to obtain the necessary authorisations for your
        flight, we will refund the amount of the invoice.`);
    },
    texts() {
      return {
        confirming: this.$gettextInterpolate(
          this.$gettext(`By clicking on "Confirm", you ask Clearance to handle all necessary
            paperwork on your behalf in order to allow you to comply to all relevant regulations
            related to the constraints displayed on the flight details page "Identified
            Constraints" section). You can find more details on our <a
            href=%{link} target="_blank">article</a>.`),
          { link: 'https://clearance.aero/confiez-vos-demarches-de-vol-de-drone-a-clearance/' },
        ),
        changePrice: this.$gettext(`The additional pricing for this service has probably been
          determined in your contract. Feel free to reach out at support@clearance.aero if
          necessary.`),
      };
    },
  },
  created() {
    if (this.displayPaymentForm) {
      this.invoiceDetailsLoading = true;
    }
  },
  methods: {
    confirmButtonAction() {
      if (this.displayPaymentForm) {
        this.loadingPaymentSucceeded = true;
        this.$refs.entrustPaperworkPaymentForm.payInvoice();
      } else {
        this.entrustUsWithThePapperwork();
      }
    },
    paymentSucceeded() {
      if (this.displayPaymentForm) {
        this.loadingPaymentSucceeded = false;
        this.$refs.entrustPaperworkPaymentForm.reset();
      }
      this.$emit('close-form');
      this.$store.dispatch(GET_FLIGHT_DETAILS_NS, { flightId: this.flight.id });
    },
    async entrustUsWithThePapperwork() {
      this.loading = true;
      let entrustInfo = '';
      if (this.price) {
        entrustInfo = this.$gettextInterpolate(
          this.$gettext('%{info}\nThe price computed for the entrusted paperwork is: %{price} HT.'),
          {
            info: this.entrustInfo,
            price: this.price,
          },
        );
      } else {
        entrustInfo = this.$gettextInterpolate(
          this.$gettext('%{info}\nThe price for the entrusted paperwork is hidden for this user.'),
          { info: this.entrustInfo },
        );
      }
      await API.entrustMission(this.flight.id, entrustInfo)
        .then(({ data }) => {
          this.showMessage(data.message, 'success');
          this.$emit('close-form');
          this.$store.dispatch(GET_FLIGHT_DETAILS_NS, { flightId: this.flight.id });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePaymentFormsValid(value) {
      this.paymentFormsValid = value;
    },
    failPayment({ resetPaymentForm = true }) {
      this.loadingPaymentSucceeded = false;
      if (resetPaymentForm) {
        this.$refs.entrustPaperworkPaymentForm.reset();
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.entrust-paragraph {
  line-height: 1.2rem;
}

</style>
