<template>
  <div>
    <v-card class="default--dialog__card">
      <v-card-title>
        <span v-translate>Send an approval</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <!-- ---------- From email name ---------- -->
          <v-text-field
            :label="labels.fromEmail"
            v-model="fromEmail"
            :rules="[rules.required, rules.noSpecialCharacters]"
            outlined
          />
          <!-- ---------- Response Mail Adress ---------- -->
          <v-combobox
            v-model="replyTo.selected"
            :items="replyTo.items"
            :search-input.sync="replyTo.search"
            :rules="[rules.emailRequired, rules.emailValid]"
            hide-selected
            multiple
            small-chips
            class="pt-2 ma-0"
            @click:append="resetSearch('replyTo')"
            outlined
          >
            <template v-slot:label>
              <span class="font-weight-bold">
                {{ labels.replyTo }}
              </span>
            </template>
            <template
              v-slot:no-data
              v-if="isSearching.replyTo"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-translate>No result for</span>&nbsp;
                    <v-chip>
                      {{ replyTo.search }}
                    </v-chip>&nbsp;
                    <span v-translate>. Press</span>&nbsp;
                    <kbd>
                      <span v-translate>Enter</span>
                    </kbd>&nbsp;
                    <span v-translate>to add it.</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, parent, selected }">
              <v-chip
                v-if="item"
                :input-value="selected"
              >
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                >
                  close
                </v-icon>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-chip>
                  {{ item }}
                </v-chip>
              </v-list-item-content>
            </template>
          </v-combobox>
          <div class="pb-2">
            <span v-translate>The next approval will be send by email</span>
          </div>
          <!-- ---------- Recipient Mail Adresses ---------- -->
          <v-combobox
            v-model="recipient.selected"
            :items="recipient.items"
            :search-input.sync="recipient.search"
            :rules="[rules.emailRequired, rules.emailValid]"
            :label="labels.recipient"
            hide-selected
            multiple
            small-chips
            class="ma-0"
            @click:append="resetSearch('recipient')"
            outlined
          >
            <template
              v-slot:no-data
              v-if="isSearching.recipient"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-translate>No result for</span>&nbsp;
                    <v-chip>
                      {{ recipient.search }}
                    </v-chip>&nbsp;
                    <span v-translate>. Press</span>&nbsp;
                    <kbd>
                      <span v-translate>Enter</span>
                    </kbd>&nbsp;
                    <span v-translate>to add it.</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, parent, selected }">
              <v-chip
                v-if="item"
                :input-value="selected"
              >
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                >
                  close
                </v-icon>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-chip>
                  {{ item }}
                </v-chip>
              </v-list-item-content>
            </template>
          </v-combobox>
          <!-- ---------- Carbon Copy Mail Adresses ---------- -->
          <v-combobox
            v-model="carbonCopy.selected"
            :items="carbonCopy.items"
            :search-input.sync="carbonCopy.search"
            :rules="[rules.emailValid]"
            :label="labels.carbonCopy"
            hide-selected
            multiple
            small-chips
            class="ma-0"
            @click:append="resetSearch('carbonCopy')"
            outlined
          >
            <template
              v-slot:no-data
              v-if="isSearching.carbonCopy"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-translate>No result for</span>&nbsp;
                    <v-chip>
                      {{ carbonCopy.search }}
                    </v-chip>&nbsp;
                    <span v-translate>. Press</span>&nbsp;
                    <kbd>
                      <span v-translate>Enter</span>
                    </kbd>&nbsp;
                    <span v-translate>to add it.</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, parent, selected }">
              <v-chip
                v-if="item"
                :input-value="selected"
              >
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                >
                  close
                </v-icon>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-chip>
                  {{ item }}
                </v-chip>
              </v-list-item-content>
            </template>
          </v-combobox>
          <!-- ---------- Mail Subject ---------- -->
          <v-text-field
            v-model="subject"
            :label="labels.subject"
            :rules="[rules.required]"
            class="my-2"
            outlined
          />
          <!-- ---------- Mail body ---------- -->
          <v-textarea
            :label="labels.body"
            v-model="body"
            :rules="[rules.required]"
            auto-grow
            outlined
            class="my-0"
          />
          <!-- ---------- Attached files -------- -->
          <span class="caption">
            {{ labels.attachments }}
          </span>
          <v-row
            no-gutters
            class="attachment"
          >
            <template v-if="screenshotLoading || kmlLoading">
              <v-progress-linear
                indeterminate
                size="64"
              />
            </template>
            <template v-else>
              <v-col
                cols="11"
                class="pt-1"
              >
                <template v-if="screenshot">
                  <v-chip
                    small
                    close
                    close-icon="mdi-close"
                    class="ma-1"
                    @click:close="screenshot=null"
                  >
                    <span
                      class="link"
                      @click="downloadFile(screenshot)"
                    >
                      {{ screenshot.name }}
                    </span>
                  </v-chip>
                </template>
                <template v-if="kmlFile">
                  <v-chip
                    small
                    close
                    close-icon="mdi-close"
                    class="ma-1"
                    @click:close="kmlFile=null"
                  >
                    <span
                      class="link"
                      @click="downloadFile(kmlFile)"
                    >
                      {{ kmlFile.name }}
                    </span>
                  </v-chip>
                </template>
                <template v-if="allAttachedDocuments.length">
                  <v-chip
                    v-for="(file, index) in allAttachedDocuments"
                    :key="index"
                    close-icon="mdi-close"
                    @click:close="removeFile(index, file.documentId)"
                    class="ma-1"
                    small
                    close
                  >
                    {{ file.name }}
                  </v-chip>
                </template>
              </v-col>
              <v-col cols="1">
                <v-menu
                  dark
                  offset-y
                  open-on-hover
                  close-delay="75"
                  :loading="screenshotLoading"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon size="28">
                        mdi-attachment mdi-rotate-270
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list light>
                    <v-list-item
                      data-tour="myDocuments"
                      @click="openMyDocumentsDialog()"
                    >
                      <v-list-item-title class="ml-3">
                        <v-icon class="pr-2">mdi-file</v-icon>
                        <span
                          v-translate
                          key="my-documents"
                        >
                          My documents
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      data-tour="fromComputer"
                      @click="openFromComputerInput()"
                    >
                      <v-list-item-title class="ml-3">
                        <v-icon class="pr-2">mdi-folder-open</v-icon>
                        <span
                          v-translate
                          key="from-computer"
                        >
                          From computer
                        </span>
                        <v-file-input
                          ref="attachFileInput"
                          v-model="attachments"
                          multiple
                          hide-input
                          prepend-icon=""
                          class="ma-0 pa-0"
                        />
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </template>
          </v-row>
          <!-- ---------- Receive email copy -------- -->
          <v-row no-gutters>
            <v-checkbox
              v-model="sendUserEmailCopy"
              :label="labels.sendUserEmailCopy"
              hide-details
            />
          </v-row>
        </v-form>
      </v-card-text>
      <!-- Confirmation -->
      <v-card-actions>
        <v-btn
          text
          @click="$emit('close-form')"
        >
          <span v-translate>Cancel</span>
        </v-btn>
        <v-btn
          class="white--text px-5"
          color="primary"
          :disabled="!valid || sendingEmailRequest || screenshotLoading || kmlLoading"
          :loading="sendingEmailRequest"
          @click="sendEmailRequest()"
        >
          <span v-translate>Confirm</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- My document dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      :retain-focus="false"
      persistent
    >
      <MyDocuments
        @close-mydocuments="dialog=false"
        @choose-mydocuments="(documents) => attachMyDocuments(documents)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { filterCoordDMS } from '@/filters/coord';
import { filterPhoneNumber } from '@/filters/phone';

import APIService from '@/services/api';

import { takeMapScreenshot } from '@/store/application';
import { GET_CONSTRAINTS_ANALYSIS_NS } from '@/store/flights';

import MyDocuments from '@/components/MyDocuments/MyDocuments.vue';

export default {
  name: 'EmailRequestForm',
  components: { MyDocuments },
  props: {
    flight: Object,
    constraint: Object,
    capability: Object,
  },
  data() {
    return {
      fromEmail: null,
      replyTo: {
        selected: [],
        items: [],
        search: null,
      },
      recipient: {
        selected: [],
        items: [],
        search: null,
      },
      carbonCopy: {
        selected: [],
        items: [],
        search: null,
      },
      subject: null,
      body: null,
      attachments: [],
      screenshotLoading: true,
      screenshot: null,
      kmlFile: null,
      kmlLoading: true,
      sendUserEmailCopy: true,
      sendingEmailRequest: false,
      valid: true,
      rules: {
        required: (v) => v.length !== 0 || this.$gettext('Please fill this field'),
        noSpecialCharacters: (
          (value) => {
            let noSpecialCharacters = true;
            const pattern = /[,;\]:")<>.[\\@(]/;
            if (pattern.test(value)) {
              noSpecialCharacters = this.$gettext('Special characters are not allowed');
            }
            return noSpecialCharacters;
          }),
        emailRequired: (v) => v.length !== 0 || this.$gettext(
          'Please fill at least one email.',
        ),
        emailValid: (
          (values) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailIsValid = true;
            values.forEach((value) => {
              if (!pattern.test(value)) {
                emailIsValid = this.$gettext('Invalid email.');
              }
            });
            return emailIsValid;
          }
        ),
      },
      dialog: null,
      myDocuments: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    exploitant() {
      return this.$store.state.exploitants.exploitationDetails;
    },
    boundingBoxIsUpdating() {
      return this.$store.state.map.boundingBoxIsUpdating;
    },
    isSearching() {
      return {
        replyTo: this.replyTo.search !== null && this.replyTo.search !== '',
        recipient: this.recipient.search !== null && this.recipient.search !== '',
        carbonCopy: this.carbonCopy.search !== null && this.carbonCopy.search !== '',
      };
    },
    labels() {
      return {
        fromEmail: this.$gettext('Sender name *:'),
        replyTo: this.$gettext('You will receive a response at this email *:'),
        recipient: this.$gettext('To *:'),
        carbonCopy: this.$gettext('CC:'),
        subject: this.$gettext('Subject *:'),
        body: this.$gettext('Body *:'),
        attachments: this.$gettext('Attachments:'),
        sendUserEmailCopy: this.$gettext('To receive a copy of the request'),
      };
    },
    allAttachedDocuments() {
      const allDocuments = this.myDocuments.concat(this.attachments);
      return allDocuments.sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    latitude() {
      if (this.flight.center && this.flight.center.coordinates) {
        return this.flight.center.coordinates[1];
      }
      return '';
    },
    longitude() {
      if (this.flight.center && this.flight.center.coordinates) {
        return this.flight.center.coordinates[0];
      }
      return '';
    },
  },
  watch: {
    boundingBoxIsUpdating(value) {
      if (value === false) {
        this.makeMapScreenshot();
      }
    },
  },
  created() {
    this.fromEmail = this.user.contact_full_name;
    const userEmail = this.user.email;
    this.replyTo.selected = [userEmail];
    this.replyTo.items = [userEmail];
    this.recipient.selected = this.capability.contact_emails;
    this.recipient.items = this.capability.contact_emails;
    this.carbonCopy.selected = this.capability.carbon_copy_emails;
    this.carbonCopy.items = this.capability.carbon_copy_emails;
    this.subject = this.$gettextInterpolate(
      this.$gettext('Flight evolution #%{flightId} Exploitant %{commercialName}'),
      { flightId: this.flight.id, commercialName: this.exploitant.commercial_name },
      true,
    );
    this.body = this.getEmailBody();
  },
  mounted() {
    this.getKmlFile();
  },
  methods: {
    resetSearch(field) {
      this[field].search = null;
    },
    dateLocalFormat(dateToFormat) {
      const dateFormatted = new Date(dateToFormat);
      // Handle timezones differences
      dateFormatted.setMinutes(dateFormatted.getMinutes() + dateFormatted.getTimezoneOffset());
      const optionDate = { year: 'numeric', month: 'long', day: 'numeric' };
      return dateFormatted.toLocaleDateString(
        this.$store.getters['application/currentLanguage'], optionDate,
      );
    },
    getEmailBody() {
      // Format flight date to human readable date
      const coordinateN = this.latitude.toFixed(4);
      const coordinateE = this.longitude.toFixed(4);
      const latitudeDMS = filterCoordDMS(this.latitude, false);
      const longitudeDMS = filterCoordDMS(this.longitude, true).toString();
      const dateStart = this.dateLocalFormat(this.flight.date_start);
      const dateEnd = this.dateLocalFormat(this.flight.date_end);
      let mailPartVol = this.$gettextInterpolate(
        this.$gettext(`Hello,<br><br>I would like to contact you for a request approval of aerial
          work by drone in the area of %{constraintName}.<br><br>
          Here are the flight details:<br><br>Exploitant: %{commercialName}<br>Adress:
          %{address}<br>Geographic coordinates: %{coordinateN}N %{coordinateE}E (%{latitudeDMS}
          %{longitudeDMS})<br>Ground elevation in the center of the flight area: %{elevation} m
          <br>Maximal elevation from the ground: %{flyingHeight} m<br>`),
        {
          constraintName: this.constraint.name,
          commercialName: this.flight.company_name,
          address: this.flight.address,
          coordinateE,
          coordinateN,
          latitudeDMS,
          longitudeDMS,
          elevation: this.flight.elevation,
          flyingHeight: this.flight.flying_height,
        },
        true,
      );
      if (
        this.constraint.radial !== null
        && this.constraint.distance_nm !== null
      ) {
        mailPartVol += this.$gettextInterpolate(
          this.$gettext('<br>QDR against "%{structure}": %{radial}° - %{distance} NM<br>'),
          {
            structure: this.constraint.name,
            radial: this.constraint.radial,
            distance: this.constraint.distance_nm.toFixed(1),
          },
          true,
        );
      }
      mailPartVol += this.$gettextInterpolate(
        this.$gettext(`<br>Start of the flight range: %{dateStart}<br>
          End of the flight range: %{dateEnd}<br>
          Start of the hour range: %{timeStart}<br>
          End of the hour range: %{timeEnd}<br>
          Expected duration: %{expectedDuration}<br>`),
        {
          dateStart,
          dateEnd,
          timeStart: this.flight.time_start,
          timeEnd: this.flight.time_end,
          expectedDuration: this.flight.expected_duration,
        },
      );

      // Add drones details
      let mailPartDrone = '';
      if (this.flight.drones.length > 1) {
        mailPartDrone += this.$gettext('<br>Drones list of the flight:<br>');
      }
      this.flight.drones.forEach((drone) => {
        mailPartDrone += this.$gettextInterpolate(
          this.$gettext('<br>Drone: %{label}<br>'),
          { label: drone.label },
          true,
        );
      });

      // Creation contact for each pilot
      let mailPartPilot = '';
      if (this.flight.pilots.length > 1) {
        mailPartPilot += this.$gettext('<br>Pilot list of the flight:<br>');
      }
      this.flight.pilots.forEach((pilot) => {
        mailPartPilot += this.$gettextInterpolate(
          this.$gettext(`<br>Pilot: %{name}<br>
            Pilot's phone number: %{phoneNumber}<br>
            Pilot's email: %{email}<br>`),
          {
            name: pilot.full_name,
            phoneNumber: filterPhoneNumber(pilot.phone_number),
            email: pilot.email,
          },
          true,
        );
      });
      if (mailPartPilot === '') {
        mailPartPilot += this.$gettextInterpolate(
          this.$gettext(`<br>Pilot: %{name}<br>
            Pilot's phone number: %{phoneNumber}<br>
            Pilot's email: %{email}<br><br>`),
          {
            name: this.user.contact_full_name,
            phoneNumber: this.user.contact_phone_number,
            email: this.user.contact_email,
          },
          true,
        );
      }
      mailPartPilot += this.$gettext(`<br>I stay of course available if you need more information
        to examine the request.<br><br>
        Regards,<br><br>`);
      mailPartPilot += this.user.contact_full_name;
      let mail = mailPartVol + mailPartDrone + mailPartPilot;
      mail = mail.replaceAll('\n', ' ');
      mail = mail.replaceAll('<br>', '\n');
      // Delete the indentation in the multiline string
      return mail.replace(/  +/g, ' ');
    },
    getKmlFile() {
      this.kmlLoading = true;
      APIService.downloadFlightAreaKML(this.flight.id, { no_name: true, type: 'kml' })
        .then(({ data }) => {
          this.kmlFile = {
            name: this.$gettext('flight_area.kml'),
            href: window.URL.createObjectURL(new Blob([data])),
          };
        })
        .finally(() => {
          this.kmlLoading = false;
        });
    },
    makeMapScreenshot() {
      this.screenshotLoading = true;
      // Make the screenshot
      takeMapScreenshot()
        .then((canvas) => {
          this.screenshot = {
            name: this.$gettext('evolution_area.jpeg'),
            href: canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream'),
          };
        })
        .finally(() => {
          this.screenshotLoading = false;
        });
    },
    downloadFile(file) {
      if (file) {
        const a = document.createElement('a');
        a.href = file.href;
        a.download = file.name;
        a.click();
      }
    },
    async prepareEmailRequestData() {
      const formData = new FormData();
      let headers = {};
      formData.append('from_email', this.fromEmail);
      formData.append('recipient', this.recipient.selected);
      formData.append('reply_to', this.replyTo.selected);
      if (this.carbonCopy.selected.length) {
        formData.append('carbon_copy', this.carbonCopy.selected);
      }
      formData.append('subject', this.subject);
      formData.append('body', this.body);
      formData.append('constraint_id', this.constraint.id);
      formData.append('send_user_email_copy', this.sendUserEmailCopy ? 1 : 0);
      this.attachments.forEach(
        (file, i) => formData.append(`document_${i + 1}`, file),
      );
      const myDocumentsIds = [];
      if (this.myDocuments.length) {
        this.myDocuments.forEach((document) => {
          myDocumentsIds.push(document.documentId);
        });
        formData.append('my_documents', JSON.stringify(myDocumentsIds));
      }
      if (this.screenshot) {
        const blob = await fetch(this.screenshot.href)
          .then((r) => r.blob());
        const file = new File([blob], this.screenshot.name);
        formData.append('screenshot', file);
      }
      if (this.kmlFile) {
        const blob = await fetch(this.kmlFile.href)
          .then((r) => r.blob());
        const file = new File([blob], this.kmlFile.name);
        formData.append('kml_file', file);
      }
      if (this.attachments || this.screenshot || this.myDocuments) {
        headers = { 'Content-Type': 'multipart/form-data' };
      } else {
        headers = { 'Content-Type': 'application/json' };
      }
      return [formData, headers];
    },
    async sendEmailRequest() {
      this.sendingEmailRequest = true;
      const [formData, headers] = await this.prepareEmailRequestData();
      APIService.sendConstraintEmailRequest(this.flight.id, formData, headers)
        .then(({ data }) => {
          this.showMessage(data, 'success');
        })
        .finally(() => {
          this.sendingEmailRequest = false;
          this.$emit('close-form');
          this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id });
        });
    },
    openFromComputerInput() {
      this.attachedFiles = [];
      this.$refs.attachFileInput.$refs.input.click();
    },
    openMyDocumentsDialog() {
      this.dialog = true;
      this.myDocuments = [];
    },
    attachMyDocuments(myDocuments) {
      this.myDocuments = myDocuments;
    },
    removeFile(index, id) {
      if (id) {
        for (let i = 0; i < this.myDocuments.length; i += 1) {
          if (this.myDocuments[i].documentId === id) {
            this.myDocuments.splice(i, 1);
            break;
          }
        }
      } else {
        let nbAttachedFiles = 0;
        for (let i = 0; i < index; i += 1) {
          if (this.allAttachedDocuments[i].documentId === undefined) {
            nbAttachedFiles += 1;
          }
        }
        this.attachments.splice(nbAttachedFiles, 1);
      }
    },
  },
};

</script>

<style
  lang="scss"
  scoped
>
.link {
  text-decoration: underline;
  cursor: pointer;
}
.attachment {
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, .42);
}

</style>
