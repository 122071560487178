<template>
  <div id="map">
    <!-- Slot to add a legend to the map  -->
    <slot
      name="map-legend"
      :className="'map-legend'"
    />
    <!-- Slot to add an action button on the bottom of the map  -->
    <slot
      name="action-button"
      :className="'action-button'"
    />
    <!-- in mobile constraint popus are shown in a dialog instead of a popup on the map -->
    <v-dialog
      v-if="isMobileBreakpoint"
      v-model="showConstraintPopupDialog"
      transition="dialog-bottom-transition"
      fullscreen
      eager
    >
      <v-card light>
        <div class="pa-2">
          <div class="pt-2">
            <v-icon @click="closeConstraintsPopupDialog()">
              mdi-arrow-left
            </v-icon>
          </div>
          <div id="constraint-popup-dialog" />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  MAP_STATUS,
  REFRESH_MAP_DATA_NS,
  RESIZE_MAP_NS,
  REMOVE_MAP_LAYER_POPUPS_ID_NS,
} from '@/store/map';

import FlightPopup from '@/components/Map/Popup/FlightPopup.vue';
import ConstraintsPopup from '@/components/Map/Popup/ConstraintsPopup.vue';
import ConstraintsPopupClientStructure from '@/components/Map/Popup/ConstraintsPopupClientStructure.vue';
import TrackingPopup from '@/components/Map/Popup/TrackingPopup.vue';
import TracePopup from '@/components/Map/Popup/TracePopup.vue';

/* eslint-disable-next-line */
Object.defineProperty(Array.prototype, 'flat', {
  value(depth = 1) {
    return this.reduce(
      (flat, toFlatten) => flat.concat(
        (Array.isArray(toFlatten) && (depth > 1)) ? toFlatten.flat(depth - 1) : toFlatten,
      ), [],
    );
  },
});

export default {
  props: [
    'display',
    'splitActive',
  ],
  data() {
    return {
      flightPopup: null,
      constraintPopup: null,
      constraintPopupId: null,
      showConstraintPopupDialog: false,
      trackingPopups: [],
      tracePopup: null,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    flightPopupId() {
      return this.$store.getters['map/flightPopupId'];
    },
    constraintsPointPopup() {
      return this.$store.getters['map/constraintsPointPopup'];
    },
    constraintsClientStructurePopup() {
      return this.$store.getters['map/constraintsClientStructurePopup'];
    },
    trackingPopupsIds() {
      return this.$store.getters['map/trackingPopups'];
    },
    tracePopupId() {
      return this.$store.getters['map/tracePopup'];
    },
    mapStatus() {
      return this.$store.state.map.map_status;
    },
  },
  watch: {
    splitActive() {
      this.$store.dispatch(RESIZE_MAP_NS);
    },
    mapStatus(newStatus) {
      if (MAP_STATUS.WRITE === newStatus) {
        this.customizeControls();
      }
    },
    flightPopupId(newId) {
      this.mountFlightPopup(newId);
    },
    constraintsPointPopup(newId) {
      this.mountConstraintsPointPopup(newId);
    },
    constraintsClientStructurePopup(newId) {
      this.mountConstraintsClientStructurePopup(newId);
    },
    trackingPopupsIds(newIds, oldIds = []) {
      const addedIds = newIds.filter((id) => !oldIds.includes(id));
      addedIds.forEach((id) => {
        this.mountTrackingPopup(id);
      });
      const removedIds = oldIds.filter((id) => !newIds.includes(id));
      removedIds.forEach((id) => {
        this.destroyTrackingPopup(id);
      });
    },
    tracePopupId(newId) {
      this.mountTracePopup(newId);
    },
  },
  mounted() {
    this.$store.dispatch(REFRESH_MAP_DATA_NS);
  },
  methods: {
    mountFlightPopup(id) {
      if (!id && this.flightPopup) {
        this.flightPopup.$destroy();
        this.flightPopup = null;
      } else if (id && !this.flightPopup) {
        this.flightPopup = new FlightPopup().$mount(`#${id}`);
      }
    },
    mountConstraintsPointPopup(id) {
      if (!id && this.constraintPopup) {
        this.showConstraintPopupDialog = false;
        this.constraintPopup.$el.parentNode.removeChild(this.constraintPopup.$el);
        this.constraintPopup.$destroy();
        this.constraintPopup = null;
      } else if (id && !this.constraintPopup) {
        this.showConstraintPopupDialog = true;
        this.createDialogPopupContainer(id);
        this.constraintPopup = new ConstraintsPopup({ propsData: { id } })
          .$on('set-status-create-flight', (area) => {
            this.closeConstraintsPopupDialog();
            this.$emit('set-status-create-flight', area);
          })
          .$mount(`#${id}`);
      }
      this.constraintPopupId = id;
    },
    mountConstraintsClientStructurePopup(id) {
      if (!id && this.constraintPopup) {
        this.showConstraintPopupDialog = false;
        this.constraintPopup.$el.parentNode.removeChild(this.constraintPopup.$el);
        this.constraintPopup.$destroy();
        this.constraintPopup = null;
      } else if (id && !this.constraintPopup) {
        this.showConstraintPopupDialog = true;
        this.createDialogPopupContainer(id);
        this.constraintPopup = new ConstraintsPopupClientStructure({ propsData: { id } })
          .$on('set-status-create-flight-generalist', (name, area) => {
            this.closeConstraintsPopupDialog();
            this.$emit('set-status-create-flight-generalist', name, area);
          })
          .$mount(`#${id}`);
      }
      this.constraintPopupId = id;
    },
    mountTrackingPopup(id) {
      const trackingPopup = new TrackingPopup({ propsData: { id } }).$mount(`#${id}`);
      this.trackingPopups.push(trackingPopup);
    },
    destroyTrackingPopup(removedId) {
      const index = this.trackingPopups.findIndex((p) => p.id === removedId);
      if (index !== -1) {
        this.trackingPopups[index].$destroy();
        this.trackingPopups.splice(index, 1);
      }
    },
    mountTracePopup(id) {
      if (!id && this.tracePopup) {
        this.tracePopup.$el.parentNode.removeChild(this.tracePopup.$el);
        this.tracePopup.$destroy();
        this.tracePopup = null;
      } else if (id && !this.tracePopup) {
        this.tracePopup = new TracePopup().$mount(`#${id}`);
      }
    },
    createDialogPopupContainer(id) {
      if (this.isMobileBreakpoint) {
        const popupDialog = document.querySelector('#constraint-popup-dialog');
        const popupContainer = document.createElement('div');
        popupContainer.setAttribute('id', id);
        popupDialog.appendChild(popupContainer);
      }
    },
    closeConstraintsPopupDialog() {
      if (this.isMobileBreakpoint) {
        this.$store.commit(
          REMOVE_MAP_LAYER_POPUPS_ID_NS,
          { popupId: this.constraintPopupId, layerId: this.constraintPopupId },
        );
      }
    },
    customizeControls() {
      const drawerIcon = (
        document.body.getElementsByClassName('mapbox-gl-draw_polygon').length
          ? document.body.getElementsByClassName('mapbox-gl-draw_polygon')[0]
          : null
      );
      if (drawerIcon) {
        drawerIcon.title = this.$gettext('Draw the flying area');
      }
      const trashIcon = (
        document.body.getElementsByClassName('mapbox-gl-draw_trash').length
          ? document.body.getElementsByClassName('mapbox-gl-draw_trash')[0]
          : null
      );
      if (trashIcon) {
        trashIcon.title = this.$gettext('Delete the selected area');
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
#map, .map {
  width: 100%;
  height: 100%;
  color: black;
}

::v-deep .mapbox-gl-draw_polygon {
  background-size: 65%;
  background-image: url("/icone_dessin.png");
}

::v-deep .mapboxgl-ctrl-top-right {
  max-width: 95vw;
  .mapboxgl-ctrl-group {
    max-width: 100%;
    overflow-y: auto;
    form {
      max-width: 100%;
    }
    max-height: 30vh;
    @media (min-width: 960px) {
      max-height: 60vh;
    }
  }
  .suggestions {
    max-height: 60vh;
    overflow-y: scroll;
  }
}

.map-legend {
  position: absolute;
  bottom: 36px;
  right: 10px;
  padding: 6px 8px 6px 8px;
  border-radius: 4px;
  background: white;
  color: initial;
  box-shadow: 0 0 0 2px #0000001a;
}

.action-button {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 255;
  padding: 24px 16px;
  ::v-deep .v-btn {
    color: white;
    font-weight: 500;
    font-family: "Roboto", Arial, sans-serif;
  }
}

</style>
