<template>
  <div>
    <!-- MobileBreakpoint: collapsed menu in a single button for all groups -->
    <template v-if="isMobileBreakpoint">
      <v-menu
        key="collapsed-menu"
        eager
        dark
        bottom
        left
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            large
          >
            <v-badge
              :value="newNotificationsCount"
              :content="newNotificationsCount"
              color="red"
              offset-x="15"
              offset-y="10"
              left
            >
              <v-icon :size="iconSize">mdi-dots-vertical</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list class="info">
          <template v-for="(group, index) in groups">
            <!-- Groups of type menu with an items list  -->
            <template v-if="group.type === 'list-menu'">
              <template v-for="item in group.items">
                <v-list-item
                  :key="item.key"
                  @click="item.atClick"
                >
                  <v-icon :size="iconSize">
                    {{ item.icon }}
                  </v-icon>
                  <v-list-item-title class="ml-3">
                    <span :key="item.key">
                      {{ item.title }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </template>
            <!-- Groups of type menu with a custom component -->
            <template v-if="group.type === 'custom-menu'">
              <v-list-item
                :key="group.key"
                :id="group.iconId"
                @click="openCustomMenuDialog(group, index)"
              >
                <v-badge
                  v-if="group.iconWithBadge"
                  :value="group.iconBadgeValue"
                  :content="group.iconBadgeText"
                  color="red"
                  offset-x="15"
                  offset-y="10"
                >
                  <v-icon :size="iconSize">
                    {{ group.icon }}
                  </v-icon>
                </v-badge>
                <template v-else>
                  <v-icon :size="iconSize">
                    {{ group.icon }}
                  </v-icon>
                </template>
                <v-list-item-title class="ml-3">
                  <span :key="group.key">
                    {{ group.title }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-divider
              v-if="index < groups.length - 1"
              :key="index"
              class="mx-2"
            />
          </template>
        </v-list>
      </v-menu>
      <v-dialog
        v-model="showCustomMenuDialog"
        content-class="header-action__custom-menu__dialog"
        transition="slide-x-reverse-transition"
        fullscreen
      >
        <v-card light>
          <v-icon
            class="ma-2"
            @click="showCustomMenuDialog = false"
          >
            mdi-arrow-left
          </v-icon>
          <component
            :is="groups[customMenuDialogIndex].component"
            v-bind="groups[customMenuDialogIndex].componentProperties"
            @close="() => showCustomMenuDialog = false"
          />
        </v-card>
      </v-dialog>
    </template>
    <!-- Larger screens: each group in a seperate action button  -->
    <template v-else>
      <template v-for="group in groups">
        <v-menu
          :key="group.key"
          v-model="menusValues[group.key]"
          :open-on-hover="group.openOnHover"
          :close-on-content-click="group.closeOnContentClick"
          eager
          dark
          offset-y
          nudge-bottom="10"
          close-delay="75"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              large
            >
              <div :id="group.iconId">
                <v-badge
                  v-if="group.iconWithBadge"
                  :value="group.iconBadgeValue"
                  :content="group.iconBadgeText"
                  color="red"
                  offset-x="15"
                  offset-y="10"
                >
                  <v-icon :size="iconSize">
                    {{ group.icon }}
                  </v-icon>
                </v-badge>
                <template v-else>
                  <v-icon :size="iconSize">
                    {{ group.icon }}
                  </v-icon>
                </template>
              </div>
            </v-btn>
          </template>
          <!-- Group of type menu with an items list  -->
          <template v-if="group.type === 'list-menu'">
            <v-list class="primary">
              <template v-for="item in group.items">
                <v-list-item
                  :key="item.key"
                  @click="item.atClick"
                  :data-tour="item.dataTourKey"
                >
                  <v-icon :size="iconSize">
                    {{ item.icon }}
                  </v-icon>
                  <v-list-item-title class="ml-3">
                    <span :key="item.key">
                      {{ item.title }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </template>
          <!-- Groups of type menu with a custom inner component -->
          <template v-if="group.type === 'custom-menu'">
            <component
              :is="group.component"
              v-bind="group.componentProperties"
            />
          </template>
        </v-menu>
      </template>
    </template>
    <v-dialog
      v-model="showAnnouncementsDialog"
      :retain-focus="false"
      max-width="800"
    >
      <Announcement @close-announcements="showAnnouncementsDialog=false" />
    </v-dialog>
  </div>
</template>

<script>
import {
  RESSOURCES_ARTICLES_LINK,
  URL_DL_HELP_MANUAL_DRONIST,
  URL_DL_HELP_MANUAL_AUTHORITY,
  URL_DL_QUICK_START_MANUAL,
  URL_DL_HELP_MANUAL_GENERALIST,
  URL_DL_HELP_MANUAL_AUTHORITY_FULL_SCREEN,
} from '@/settings';

import { GET_LIVESTREAMS_NS } from '@/store/livestreams';
import { GET_NOTIFICATIONS_NS } from '@/store/notifications';

import Announcement from '@/components/Header/Announcement.vue';
import LivestreamsHeaderList from '@/components/Livestreams/LivestreamsHeaderList.vue';
import NotificationsList from '@/components/Header/NotificationsList.vue';
import BeamerCookies from '@/components/Header/BeamerCookies.vue';

const GROUP_TYPES = {
  /*
    list-menu type is a header action that opens a menu with a list of actions
    * items: [] the list of item elements in the menu each item should have a key, a title
      an icon and an atClick action.

    -> See buildListMenuGroup method to build a menu of this type
  */
  LIST_MENU: 'list-menu',
  /*
    custom-menu type is a header action that opens a menu with a custom component
    * component: Vue component that should be displayed inside the menu for larger screens
      or in a fullscreen dialog for mobile breakpoint screens

    -> See buildCustomMenuGroup method to build a menu of this type
  */
  CUSTOM_MENU: 'custom-menu',
};

const GROUP_KEYS = {
  ACCOUNT: 'account',
  HELP: 'help',
  LIVESTREAMS: 'livestreams',
  NOTIFICATIONS: 'notifications',
  BEAMER: 'beamer',
};

export default {
  name: 'HeaderActionsMenu',
  components: {
    Announcement,
    LivestreamsHeaderList,
    NotificationsList,
    BeamerCookies,
  },
  data() {
    return {
      showCustomMenuDialog: false,
      customMenuDialogIndex: 0,
      menusValues: {},
      iconSize: '',
      showAnnouncementsDialog: false,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    isAuthenticated() {
      return this.$store.state.authentication.logged;
    },
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
    isUserDronist() {
      return this.$store.getters['authentication/isUserDronist'];
    },
    isUserVisitor() {
      return this.$store.getters['authentication/isUserVisitor'];
    },
    isDeltaOrigin() {
      return window.location.origin === process.env.VUE_APP_DELTA_ORIGIN_URL;
    },
    canAccessArticles() {
      return this.$store.getters['authentication/isAdminOrSpecialistPilot'];
    },
    showAnnouncements() {
      return (
        this.$store.getters['exploitants/displayAnnouncements']
        && this.isAuthenticated && this.isUserDronist
      );
    },
    showLivestreams() {
      return (
        !this.$store.getters['exploitants/hideLivestreamsFeature']
        && !this.isMobileBreakpoint && this.isAuthenticated && this.isUserDronist
      );
    },
    displayDronistNotificationsList() {
      return this.$store.getters['authentication/displayDronistNotificationsList'];
    },
    showNotifications() {
      return (
        this.isAuthenticated
        && (
          this.isUserAuthority
          || (
            this.displayDronistNotificationsList
            && !this.isGeneralistOrGeneralistManager
          )
        )
      );
    },
    groupsOrder() {
      return [
        GROUP_KEYS.ACCOUNT,
        GROUP_KEYS.HELP,
        GROUP_KEYS.NOTIFICATIONS,
        GROUP_KEYS.LIVESTREAMS,
        GROUP_KEYS.BEAMER,
      ];
    },
    groups() {
      const groups = [];
      // Account menu
      if (this.isAuthenticated) {
        groups.push(this.accountGroup);
      }
      // Help menu
      groups.push(this.helpGroup);
      // Livestreams menu
      if (this.showLivestreams) {
        groups.push(this.livestreamsGroup);
      }
      // Notifications menu
      if (this.showNotifications) {
        groups.push(this.notificationsGroup);
      }
      // Beamer menu
      if (this.isAuthenticated && !this.isGeneralistOrGeneralistManager) {
        groups.push(this.beamerGroup);
      }
      groups.sort(
        (a, b) => this.groupsOrder.indexOf(a.key) - this.groupsOrder.indexOf(b.key),
      );
      return groups;
    },
    accountGroup() {
      const accountGroup = this.buildListMenuGroup({
        key: GROUP_KEYS.ACCOUNT,
        title: this.$gettext('Account'),
        icon: 'mdi-account-circle-outline',
      });
      if (this.isUserDronist && !this.isGeneralistOrGeneralistManager) {
        accountGroup.items.push({
          key: 'activity-tracking',
          title: this.$gettext('Activity tracking'),
          icon: 'mdi-chart-line',
          atClick: () => this.goto('/activity'),
          dataTourKey: 'activityTracking',
        });
      }
      if (!this.isUserVisitor) {
        accountGroup.items.push({
          key: 'settings',
          title: this.$gettext('Settings'),
          icon: 'icon-admin_line',
          atClick: () => this.goto('/settings'),
          dataTourKey: 'settingsPageLink',
        });
      }
      if (!this.isDeltaOrigin) {
        accountGroup.items.push({
          key: 'logout',
          title: this.$gettext('Log out'),
          icon: 'icon-unlog',
          atClick: () => this.goto('/logout'),
        });
      }
      return accountGroup;
    },
    helpGroup() {
      const helpGroup = this.buildListMenuGroup({
        key: GROUP_KEYS.HELP,
        title: this.$gettext('Help'),
        icon: 'help_outline',
      });
      if (this.showAnnouncements) {
        helpGroup.items.push(
          {
            key: GROUP_TYPES.CUSTOM_MENU,
            title: this.$gettext('Help'),
            icon: 'mdi-chat-outline',
            atClick: () => this.openAnnouncementsDialog(),
          },
        );
      }
      helpGroup.items.push({
        key: 'help-manual',
        title: this.$gettext('Help manual'),
        icon: 'mdi-book-open',
        atClick: () => this.getHelp(),
      });
      if (this.isAuthenticated && this.isUserDronist) {
        if (!this.isGeneralistOrGeneralistManager) {
          helpGroup.items.push(
            {
              key: 'quick-start-guide',
              title: this.$gettext('Quick start guide'),
              icon: 'mdi-book-plus',
              atClick: () => this.downloadQuickStartGuide(),
            },
          );
        }
        helpGroup.items.push(
          {
            key: 'guided-tour',
            title: this.$gettext('Guided tour'),
            icon: 'mdi-walk',
            atClick: () => this.$emit('start-tour'),
          },
        );
        if (this.canAccessArticles) {
          helpGroup.items.push(
            {
              key: 'articles',
              title: this.$gettext('Resources & Tutorials'),
              icon: 'mdi-folder-text',
              atClick: () => this.openArticlesLink(),
            },
          );
        }
      }
      return helpGroup;
    },
    livestreamsGroup() {
      return this.buildCustomMenuGroup({
        key: GROUP_KEYS.LIVESTREAMS,
        title: this.$gettext('Livestreams'),
        icon: 'mdi-video-wireless-outline',
        iconWithBadge: true,
        iconBadgeValue: this.currentLivestreamsCount,
        iconBadgeText: this.currentLivestreamsCount,
        component: LivestreamsHeaderList,
      });
    },
    notificationsGroup() {
      return this.buildCustomMenuGroup({
        key: GROUP_KEYS.NOTIFICATIONS,
        title: this.$gettext('Notifications'),
        icon: 'mdi-bell-outline',
        iconWithBadge: true,
        iconBadgeValue: this.newNotificationsCount,
        iconBadgeText: this.newNotificationsCount,
        openOnHover: false,
        closeOnContentClick: false,
        component: NotificationsList,
        componentProperties: { closed: !this.menusValues.notifications },
      });
    },
    beamerGroup() {
      return this.buildCustomMenuGroup({
        key: GROUP_KEYS.BEAMER,
        title: this.$gettext('News'),
        icon: 'mdi-bullhorn-outline',
        iconId: 'beamerButton',
        component: BeamerCookies,
        openComponentInDialog: this.hasRefusedCookieBeamer,
      });
    },
    forceAccountMenuOpen() {
      return this.$store.state.application.forceSettingsMenuOpen;
    },
    currentLivestreamsCount() {
      return this.$store.getters['livestreams/currentLivestreams'].length;
    },
    newNotificationsCount() {
      return this.$store.getters['notifications/newNotifications'].length;
    },
    hasRefusedCookieBeamer() {
      return !this.$store.getters['authentication/hasAcceptedCookieBeamer'];
    },
    isGeneralistOrGeneralistManager() {
      return this.$store.getters['authentication/isGeneralistPilotOrManager'];
    },
  },
  watch: {
    forceAccountMenuOpen(newValue) {
      if (newValue) {
        this.menusValues.account = true;
        this.$forceUpdate();
      }
    },
    newNotificationsCount(newValue) {
      const favicons = document.querySelectorAll("link[rel~='icon'], link[class='favicon']");
      if (newValue > 0) {
        for (let i = 0; i < favicons.length; i += 1) {
          const favicon = favicons[i];
          const href = favicon.href.split('/').pop();
          favicon.href = `${process.env.BASE_URL}img/icons_with_badge/${href}`;
        }
      } else {
        for (let i = 0; i < favicons.length; i += 1) {
          const favicon = favicons[i];
          favicon.href = `${process.env.BASE_URL}img/icons/${favicon.href.split('/').pop()}`;
        }
      }
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      if (this.isUserAuthority) {
        this.$store.dispatch(GET_NOTIFICATIONS_NS);
      }
      if (this.isUserDronist) {
        this.$store.dispatch(GET_LIVESTREAMS_NS);
      }
    }
    this.$nextTick(() => {
      // refresh icon size due to a bug on firefox
      this.iconSize = '27';
    });
  },
  methods: {
    buildListMenuGroup({
      key,
      title,
      icon,
      iconId = '',
      items = [],
      openOnHover = true,
      closeOnContentClick = true,
    }) {
      return {
        type: GROUP_TYPES.LIST_MENU,
        key,
        title,
        icon,
        iconId,
        items,
        openOnHover,
        closeOnContentClick,
      };
    },
    buildCustomMenuGroup({
      key,
      title,
      icon,
      iconId = '',
      iconWithBadge = false,
      iconBadgeValue,
      iconBadgeText,
      openOnHover = true,
      closeOnContentClick = true,
      component,
      componentProperties = {},
      openComponentInDialog = true,
    }) {
      return {
        type: GROUP_TYPES.CUSTOM_MENU,
        key,
        title,
        icon,
        iconId,
        iconWithBadge,
        iconBadgeValue,
        iconBadgeText,
        openOnHover,
        closeOnContentClick,
        component,
        componentProperties,
        openComponentInDialog,
      };
    },
    openCustomMenuDialog(group, index) {
      if (group.openComponentInDialog) {
        this.showCustomMenuDialog = true;
        this.customMenuDialogIndex = index;
      }
    },
    goto(route) {
      this.$router.push(route);
    },
    getHelp() {
      if (this.isAuthenticated) {
        this.downloadHelp();
      } else {
        this.$emit('play-help-video');
      }
    },
    downloadHelp() {
      if (this.isGeneralistOrGeneralistManager) {
        window.open(URL_DL_HELP_MANUAL_GENERALIST, '_self');
      } else if (this.isUserDronist) {
        window.open(URL_DL_HELP_MANUAL_DRONIST, '_self');
      } else if (this.isUserAuthority) {
        if (this.isAuthorityFullScreenInterface) {
          window.open(URL_DL_HELP_MANUAL_AUTHORITY_FULL_SCREEN, '_self');
        } else {
          window.open(URL_DL_HELP_MANUAL_AUTHORITY, '_self');
        }
      }
    },
    downloadQuickStartGuide() {
      window.open(URL_DL_QUICK_START_MANUAL, '_self');
    },
    openArticlesLink() {
      window.open(RESSOURCES_ARTICLES_LINK, '_blank').location.reload();
    },
    openAnnouncementsDialog() {
      this.showAnnouncementsDialog = true;
    },
    closeLivestreamMenu() {
      this.menusValues[GROUP_KEYS.LIVESTREAMS] = false;
    },
  },
};
</script>

<style lang="scss">
.header-action__custom-menu__dialog.v-dialog--fullscreen {
  padding: 0px;
}

</style>
