/* eslint-disable class-methods-use-this */

class Notification {
  constructor(rawNotification) {
    if (this.constructor === Notification) {
      throw new Error("Notification class can't be instantiated.");
    }
    this.id = rawNotification.id;
    this.viewed = rawNotification.viewed || false;
    const { payload } = rawNotification;
    this.title = payload.notification_title;
    this.firstLine = payload.first_line;
    this.secondLine = payload.second_line;
  }

  getStyleClass() {
    return this.viewed ? 'read-notification' : 'new-notification';
  }

  markAsRead() {
    this.viewed = true;
  }

  getTitle() {
    return this.title;
  }

  getFirstLine() {
    return this.firstLine;
  }

  getSecondLine() {
    return this.secondLine;
  }

  atClick() {
    throw new Error("Method 'atClick()' must be implemented.");
  }

  atReceive() {}
}

export default Notification;
