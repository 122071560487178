import axios from 'axios';

const WEATHER_API_URL = process.env.VUE_APP_WEATHER_API_URL;

class APIService {
  constructor() {
    this._instance = axios.create({ baseURL: WEATHER_API_URL });
  }

  /**
   * Set the current interceptor for JWT Authorization header
   *
   * @param {Function} interceptor
   * Function adding Authorization JWT
   */
  setInterceptorRequestJWT(interceptor) {
    this._interceptor = interceptor;
    this._addCurrentInterceptor();
  }

  /**
   * Set the interceptor to be used when signature expire
   *
   * @param {Function} manageExpiredSignature
   * Function to manage application state when signature expire
   */
  setInterceptorResponseExpiredSignature(manageExpiredSignature) {
    this._instance.interceptors.response.use(
      (response) => response,
      (error) => {
        manageExpiredSignature(error);
        return Promise.reject(error);
      },
    );
  }

  setInterceptorApiErrors(handleApiError) {
    this._instance.interceptors.response.use(
      (response) => response,
      (error) => {
        handleApiError(error);
        return Promise.reject(error);
      },
    );
  }

  /**
   * Add the current interceptor for JWT header in the axios interceptors
   */
  _addCurrentInterceptor() {
    this._interceptorTokenID = this._instance.interceptors.request.use(this._interceptor);
  }

  /**
   * Remove the current interceptor for JWT header in the axios interceptors
   */
  _removeCurrentInspector() {
    this._instance.interceptors.request.eject(this._interceptorTokenID);
  }

  getGlobalWeather(dateStart, dateEnd, lat, lng, isSubscribed, token) {
    return this._instance.request({
      method: 'get',
      url: 'global-weather',
      params: {
        date_start: dateStart,
        date_end: dateEnd,
        lat,
        lng,
        subscribed: isSubscribed,
        token,
      },
    });
  }
}

export default new APIService();
