var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('div',{staticClass:"pt-4 px-4 pb-0 d-flex justify-space-between"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"primary--text body-2 font-weight-medium"},[_vm._v(" AUTHORISATION OUTSIDE THE STANDARD SCENARIO ")]),_c('span',{staticClass:"caption"},[_c('v-tooltip',{attrs:{"bottom":"","color":"info","max-width":"680","open-on-click":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{staticClass:"small-icon pl-2 cursor-pointer"},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.tooltips.hss)}})])],1)]),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"primary--form__label"},[_vm._v(" REFERENCE ")])]),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-text-field',{staticClass:"ma-0 p-0",model:{value:(_vm.hss_authorization_form),callback:function ($$v) {_vm.hss_authorization_form=$$v},expression:"hss_authorization_form"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"primary--form__label"},[_vm._v(" STARTING VALIDITY ")])]),_c('v-col',{staticClass:"pr-0 pr-sm-5",attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{ref:"hssDateStartMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":!_vm.hss_authorization_form},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-0 p-0",attrs:{"readonly":""},model:{value:(_vm.hss_date_start_form),callback:function ($$v) {_vm.hss_date_start_form=$$v},expression:"hss_date_start_form"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.hssDateStartMenu),callback:function ($$v) {_vm.hssDateStartMenu=$$v},expression:"hssDateStartMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"fr","first-day-of-week":"1"},model:{value:(_vm.hss_date_start_form),callback:function ($$v) {_vm.hss_date_start_form=$$v},expression:"hss_date_start_form"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.hssDateStartMenu = false}}},[_c('translate',[_vm._v("Cancel")])],1),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.hssDateStartMenu.save(_vm.hss_date_start_form)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"primary--form__label"},[_vm._v(" ENDING VALIDITY ")])]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{ref:"hssDateEndMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":!_vm.hss_authorization_form},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-0 p-0",attrs:{"readonly":""},model:{value:(_vm.hss_date_end_form),callback:function ($$v) {_vm.hss_date_end_form=$$v},expression:"hss_date_end_form"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.hssDateEndMenu),callback:function ($$v) {_vm.hssDateEndMenu=$$v},expression:"hssDateEndMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"fr","first-day-of-week":"1"},model:{value:(_vm.hss_date_end_form),callback:function ($$v) {_vm.hss_date_end_form=$$v},expression:"hss_date_end_form"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.hssDateEndMenu=false}}},[_c('translate',[_vm._v("Cancel")])],1),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.hssDateEndMenu.save(_vm.hss_date_end_form)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }