var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-list"},[_c('div',{staticClass:"headline mb-4"},[_c('translate',[_vm._v("Invoices listing")])],1),(_vm.hasNextInvoice)?_c('div',[_c('v-card',{staticClass:"px-5",attrs:{"max-width":"440"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.userRefreshing || _vm.invoiceDetailsLoading,"type":"list-item@2"}},[_c('v-card-title',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Prochaine facture :")])]),_c('v-card-text',[(!_vm.invoiceDetailsError)?[_c('p',{staticClass:"d-flex flex-wrap"},[_vm._v(" "+_vm._s(_vm.nextInvoiceDetails)+" ")])]:(_vm.invoiceDetailsError)?[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"body-2 font-italic error--text text--darken-4"},[_vm._v(" Couldn't retrieve next invoice details. ")])]:_vm._e()],2)],1)],1)],1):_vm._e(),_c('div',[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$gettext('Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{ref:"datatable",attrs:{"headers":_vm.headers,"items":_vm.invoices,"loading":_vm.loading,"search":_vm.search,"single-expand":"","calculate-widths":"","loading-text":_vm.$gettext('Invoices loading...'),"no-data-text":_vm.$gettext('No invoices found'),"item-key":"id","no-results-text":_vm.$gettext('No invoices found'),"mobile-breakpoint":_vm.singleColumnBreakpoint,"footer-props":{
        'items-per-page-text': _vm.$gettext('Lines per page:'),
        'items-per-page-all-text': _vm.$gettext('All'),
        'items-per-page-options': [25, 50, 100, -1],
      }},scopedSlots:_vm._u([{key:"item.refunded",fn:function(ref){
      var item = ref.item;
return [(item.refunded)?_c('v-chip',{staticClass:"mr-2 px-1",attrs:{"x-small":"","label":"","color":"green","text-color":"white"}},[_c('translate',[_vm._v("Refunded")])],1):_c('v-chip',{staticClass:"mr-2 px-1",attrs:{"label":"","x-small":"","color":"primary","text-color":"white"}},[_c('translate',[_vm._v("Paid")])],1)]}},{key:"item.download",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","loading":_vm.loadingDownload(item.id)},on:{"click":function($event){return _vm.downloadInvoice(item)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("icon-download")])],1)]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }