<template>
  <div>
    <!-- Email request button -->
    <LayoutFlightConstraintAction
      id="email-request-btn"
      :flight="flight"
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <div class="pb-2">
          <v-btn
            color="success"
            class="main-button__font my-0"
            @click.stop="openEmailRequest()"
            :disabled="disabled"
            block
          >
            <translate v-if="emailRequest">
              Send a new approval request by email
            </translate>
            <translate v-else>
              Send an approval request by email
            </translate>
          </v-btn>
        </div>
      </template>
    </LayoutFlightConstraintAction>
    <!-- Email request dialog -->
    <v-dialog
      v-model="showEmailRequestDialog"
      persistent
      width="800px"
      :retain-focus="false"
    >
      <EmailRequestForm
        v-if="showEmailRequestDialog"
        :flight="flight"
        :constraint="constraint"
        :capability="capability"
        @close-form="() => closeEmailRequestDialog()"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  ADD_CONSTRAINT_SELECTED_NS,
  REMOVE_CONSTRAINT_SELECTED_NS,
} from '@/store/flights';
import {
  HIDE_FLIGHTS_NS,
  SET_BOUNDING_BOX_NS,
  SHOW_FLIGHTS_NS,
} from '@/store/map';

import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

import EmailRequestForm from '@/components/Flights/EmailRequest/EmailRequestForm.vue';

export default {
  name: 'EmailRequestAction',
  components: {
    LayoutFlightConstraintAction,
    EmailRequestForm,
  },
  props: {
    flight: {
      type: Object,
      required: true,
    },
    constraint: Object,
    capability: Object,
  },
  data() {
    return { showEmailRequestDialog: false };
  },
  computed: {
    emailRequest() {
      return (
        this.flight.emailRequests?.find(
          (email) => email.constraint_id === this.constraint.id,
        ) || null
      );
    },
    constraintGeometry() {
      const constraintToDisplay = this.$store.getters['flights/constraintsToDisplay'].find(
        (c) => c.id === this.constraint.id,
      );
      if (constraintToDisplay?.geometry?.features?.length) {
        return this.$turf.featureCollection(constraintToDisplay.geometry.features.flat());
      }
      return null;
    },
  },
  methods: {
    openEmailRequest() {
      if (
        !(this.$store.state.authentication.user.subscription.active || this.flight.was_entrusted)
      ) {
        this.$emit('show-subscribe-dialog');
      } else {
        this.$store.dispatch(HIDE_FLIGHTS_NS);
        this.$store.dispatch(ADD_CONSTRAINT_SELECTED_NS, this.constraint.id)
          .then(() => {
            if (this.constraintGeometry) {
              this.$store.dispatch(SET_BOUNDING_BOX_NS, { fromGeom: this.constraintGeometry });
            }
          });
        this.showEmailRequestDialog = true;
      }
    },
    closeEmailRequestDialog() {
      this.showEmailRequestDialog = false;
      this.$store.dispatch(SHOW_FLIGHTS_NS);
      this.$store.dispatch(REMOVE_CONSTRAINT_SELECTED_NS, this.constraint.id);
    },
  },
};
</script>
