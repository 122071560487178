<template>
  <div>
    <!-- --------- Flight Areas list fields ----------- -->
    <div
      v-if="flightAreas.length"
      class="flight-areas"
    >
      <v-row
        v-for="(flightArea, index) in flightAreas"
        :key="index"
        no-gutters
        justify="center"
      >
        <v-col cols="4">
          <v-text-field
            v-model="flightArea.properties.name"
            :rules="rules.required"
            :placeholder="placeholders.flightAreaName"
            :hint="placeholders.flightAreaName"
            :key="`name-${index}`"
            @change="$emit('update-flight-areas-names')"
            class="pr-4 py-0"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="flightArea.properties.height"
            :rules="rules.flyingHeight"
            :placeholder="placeholders.flightAreaHeight"
            :hint="placeholders.flightAreaHeight"
            :key="`height-${index}`"
            type="number"
            suffix="m"
            class="py-0"
          />
        </v-col>
        <v-col cols="5">
          <div class="flight-areas__actions">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="zoomOnArea(flightArea.geometry)"
                  icon
                >
                  <v-icon>mdi-map-marker-outline</v-icon>
                </v-btn>
              </template>
              <span v-translate>center on area</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="selectArea(flightArea.id)"
                  icon
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span v-translate>edit area</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="openSlicingAreaDialog(flightArea)"
                  icon
                >
                  <v-icon>mdi-content-cut mdi-rotate-315</v-icon>
                </v-btn>
              </template>
              <span v-translate>slice area</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="deleteFlightArea(flightArea)"
                  icon
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span v-translate>delete area</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <template v-else>
      <v-row
        no-gutters
        justify="center"
      >
        <v-col cols="5">
          <v-text-field
            :placeholder="placeholders.flightAreaName"
            key="name-empty"
            class="pr-4 py-0"
            disabled
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            :placeholder="placeholders.flightAreaHeight"
            key="height-empty"
            type="number"
            suffix="m"
            class="pr-4 py-0"
            disabled
          />
        </v-col>
        <v-col cols="4">
          <v-tooltip
            right
            max-width="320"
          >
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="flight-area__actions"
              >
                <v-icon>mdi-information-outline</v-icon>
              </div>
            </template>
            <span>
              {{ flightAreasTooltip }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <!-- Dialog slicing area -->
    <v-dialog
      v-model="slicingAreaDialog"
      max-width="420"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <div>
            <translate>Slicing area</translate>
            <span v-if="areaToSlice && areaToSlice.properties && areaToSlice.properties.name">
              '{{ areaToSlice.properties.name }}'
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <translate>
            Slicing this flight area into subareas depending on max extent or max surface.
          </translate>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="mt-4"
          >
            <v-text-field
              v-model="slicingExtent"
              :rules="rules.slicingValue"
              :placeholder="placeholders.flightAreaExtent"
              :hint="placeholders.flightAreaExtent"
              persistent-hint
              key="slicingWithExtent"
              type="number"
              suffix="m"
              class="py-0"
            />
            <v-text-field
              v-model="slicingSurface"
              :rules="rules.slicingValue"
              :placeholder="placeholders.flightAreaSurface"
              :hint="placeholders.flightAreaSurface"
              persistent-hint
              key="slicingWithSurface"
              type="number"
              suffix="m²"
              class="py-0"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeSlicingAreaDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            :loading="loadingSlicingArea"
            text
            color="primary"
            :disabled="!valid"
            @click="sliceArea()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from '@/services/api';

import {
  APPLICATION_SPLIT,
  SET_ACTIVE_SPLIT_NS,
  SET_LOADING_SNACKBAR_NS,
} from '@/store/application';
import { SET_SLICE_FLIGHT_AREA_NS } from '@/store/flights';
import {
  DELETE_MAP_DRAW_AREA_NS,
  SELECT_MAP_DRAW_AREA_NS,
  SET_FLIGHT_AREAS_NAME_NS,
  SET_MAP_DRAW_AREA_NS,
  ZOOM_TO_AREA_NS,
} from '@/store/map';

export default {
  name: 'FlightFormAreas',
  props: {
    flightId: {
      type: Number,
      required: false,
      default: undefined,
    },
    flightAreas: Array,
  },
  data() {
    return {
      rules: {
        required: [
          (value) => !!value || this.$gettext('A value is required'),
        ],
        flyingHeight: [
          (v) => v > 0 || this.$gettext('A positive number greater than zero is required'),
        ],
        slicingValue: [
          (v) => (v.length ? v > 0 || this.$gettext('A positive number greater than zero is required') : true),
        ],
      },
      areaToSlice: undefined,
      slicingAreaDialog: false,
      valid: false,
      slicingExtent: '',
      slicingSurface: '',
      loadingSlicingArea: false,
    };
  },
  computed: {
    placeholders() {
      return {
        flightAreaName: this.$gettext('Area name'),
        flightAreaHeight: this.$gettext('Max height'),
        flightAreaExtent: this.$gettext('Area extent'),
        flightAreaSurface: this.$gettext('Area surface'),
      };
    },
    flightAreasTooltip() {
      return this.$gettext(`Add your flight zones by drawing them on the map. You can then specify
        their name and maximum flight height.`);
    },
    distancePointsGrid() {
      let distancePoints;
      if (this.slicingExtent && this.slicingSurface) {
        distancePoints = Math.min(this.slicingExtent, Math.sqrt(this.slicingSurface));
      } else if (this.slicingExtent) {
        distancePoints = this.slicingExtent;
      } else {
        distancePoints = Math.sqrt(this.slicingSurface);
      }
      distancePoints = Math.floor(distancePoints);
      return distancePoints;
    },
  },
  created() {
    const data = { type: 'FeatureCollection', features: this.flightAreas };
    this.$store.dispatch(SET_FLIGHT_AREAS_NAME_NS, data);
  },
  watch: {
    flightAreas(newValue) {
      if (newValue.length) {
        const sliceAreaInfoFromApproval = this.$store.state.flights.sliceFlightArea;
        if (sliceAreaInfoFromApproval) {
          const sliceAreaFromApproval = this.flightAreas.find(
            (a) => a.properties.id === sliceAreaInfoFromApproval.flightAreaId,
          );
          this.selectArea(sliceAreaFromApproval.id);
          this.slicingExtent = sliceAreaInfoFromApproval.maxExtent || '';
          this.slicingSurface = sliceAreaInfoFromApproval.maxSurface || '';
          this.openSlicingAreaDialog(sliceAreaFromApproval, false);
          this.$store.commit(SET_SLICE_FLIGHT_AREA_NS, undefined);
        }
      }
    },
  },
  methods: {
    zoomOnArea(geometry) {
      this.$store.dispatch(ZOOM_TO_AREA_NS, geometry);
      // switch to map split on mobile display
      this.$store.dispatch(SET_ACTIVE_SPLIT_NS, APPLICATION_SPLIT.MAP);
    },
    selectArea(areaId) {
      this.$store.dispatch(SELECT_MAP_DRAW_AREA_NS, areaId);
    },
    async checkAreaCanBeSlicing(areaFeature) {
      if (!(this.flightId && areaFeature.properties?.id)) return true;

      const { data: canSliceArea } = await API.checkAreaCanBeDeleted(
        this.flightId,
        areaFeature.properties.id,
      );
      return canSliceArea;
    },
    async openSlicingAreaDialog(areaFeature, check = true) {
      let canSliceArea = true;
      if (check) {
        this.$store.commit(SET_LOADING_SNACKBAR_NS, true);
        canSliceArea = await this.checkAreaCanBeSlicing(areaFeature);
        this.$store.commit(SET_LOADING_SNACKBAR_NS, false);
      }
      if (!canSliceArea) {
        this.showMessage(
          this.$gettext('You can not slicing an area linked to an approval.'),
          'error',
        );
      } else {
        this.areaToSlice = areaFeature;
        this.slicingAreaDialog = true;
      }
    },
    closeSlicingAreaDialog() {
      this.slicingAreaDialog = false;
      this.loadingSlicingArea = false;
      this.areaToSlice = undefined;
      this.slicingExtent = '';
      this.slicingSurface = '';
    },
    sliceArea() {
      this.loadingSlicingArea = true;
      if (!this.slicingExtent && !this.slicingSurface) {
        this.loadingSlicingArea = false;
        this.showMessage(
          this.$gettext('At least one valid value is required'),
          'error',
        );
        return;
      }

      const { properties: { name, height }, id, geometry } = this.areaToSlice;
      // Add a buffer around the geometry to place points of grid in all the geometry
      const bbox = this.$turf.bbox(geometry);
      const pointGrid = this.$turf.pointGrid(bbox, this.distancePointsGrid, { units: 'meters' });
      const voronoiPolygons = this.$turf.voronoi(pointGrid, { bbox });
      const intersectPolygon = voronoiPolygons.features.map(
        (f) => this.$turf.intersect(f, geometry),
      ).filter((f) => f !== null);

      // MultiPolygon to Polygon
      const polygons = [];
      intersectPolygon.forEach((f) => {
        const type = this.$turf.getType(f);
        if (type === 'Polygon') {
          polygons.push(f);
        } else {
          f.geometry.coordinates.forEach((c) => {
            const polygon = this.$turf.polygon(c);
            polygons.push(polygon);
          });
        }
      });
      // Update properties of polygons
      for (let i = 0; i < polygons.length; i += 1) {
        polygons[i].properties = {
          name: `${name}-${i + 1}`,
          height,
        };
      }
      // Display all areas drawn on the map
      this.flightAreas.filter(
        (a) => a.id !== id,
      ).forEach((flightArea) => polygons.push(flightArea));
      const allFlightAreas = this.$turf.featureCollection(polygons);
      this.$store.dispatch(SET_MAP_DRAW_AREA_NS, allFlightAreas)
        .then(() => {
          this.closeSlicingAreaDialog();
        });
    },
    async deleteFlightArea(areaFeature) {
      if (this.flightId && areaFeature.properties?.id) {
        this.$store.commit(SET_LOADING_SNACKBAR_NS, true);
        await API.checkAreaCanBeDeleted(
          this.flightId,
          areaFeature.properties.id,
        )
          .then(({ data }) => {
            if (data) {
              this.$store.dispatch(DELETE_MAP_DRAW_AREA_NS, areaFeature.id);
            } else {
              this.showMessage(
                this.$gettext('You can not delete the latest area linked to an approvals.'),
                'error',
              );
            }
          })
          .finally(() => {
            this.$store.commit(SET_LOADING_SNACKBAR_NS, false);
          });
      } else {
        this.$store.dispatch(DELETE_MAP_DRAW_AREA_NS, areaFeature.id);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight-areas {
  max-height: 500px;
  overflow-y: auto;
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    height: 100%;
    padding-bottom: 16px;
  }
}

</style>
