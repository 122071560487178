<template>
  <v-dialog
    v-model="openDialog"
    max-width="620"
    persistent
  >
    <v-card
      class="default--dialog__card"
      max-height="800px"
    >
      <v-card-title>
        <translate>Edit a layer</translate>
      </v-card-title>
      <v-card-text>
        <span>
          {{ texts.explain }}
        </span>
        <div class="mt-5 mx-2">
          <div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="name"
                class="pa-0"
                :rules="[rules.name, rules.length]"
                :label="$gettext('Layer name')"
              />
            </v-form>
          </div>
          <div class="d-flex align-center justify-space-between">
            <div>
              <div
                v-for="(colorEditable, index) in colorsEditable"
                :key="index"
              >
                <v-input
                  v-if="colorEditable.condition"
                  hide-details
                  class="mb-4 align-center"
                >
                  <div
                    class="v-color-picker__dot mr-0"
                    slot="prepend"
                  >
                    <div :style="colorEditable.style" />
                  </div>
                  <span class="mb-0">
                    {{ colorEditable.text }}
                  </span>
                  <v-icon
                    slot="append"
                    @click="editColor=colorEditable.type"
                    :color="colorEditable.editing"
                  >
                    mdi-pencil
                  </v-icon>
                </v-input>
              </div>
            </div>
            <div
              v-if="editColor !== null"
              class="px-4"
            >
              <v-color-picker
                v-model="colors[editColor]"
                mode="rgba"
                hide-inputs
                height="80"
                canvas-height="40"
                elevation="2"
              />
            </div>
          </div>
          <div class="d-flex ml-1">
            <v-checkbox v-model="defaultVisibility">
              <template v-slot:label>
                <v-input hide-details>
                  <span
                    class="mb-0"
                    v-translate
                  >
                    Layer visible by default
                  </span>
                </v-input>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="info"
          @click="close()"
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="editLayer()"
        >
          <translate>Confirm</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import APIService from '@/services/api';

import { RESET_DATA_STORES_NS } from '@/store/application';
import { SHOW_CUSTOM_DATA_LAYERS_EDIT_DIALOG_NS } from '@/store/map';

export default {
  name: 'EditMapLayerData',
  data() {
    return {
      loading: false,
      valid: true,
      name: '',
      defaultVisibility: false,
      editColor: null,
      colors: {
        point: { r: 0, g: 0, b: 255, a: 0.2 },
        line: { r: 0, g: 0, b: 255, a: 0.2 },
        polygon: { r: 0, g: 0, b: 255, a: 0.2 },
      },
      rules: {
        name: (v) => !!v || this.$gettext('Name is required'),
        length: (v) => (!!v && v.length < 255) || this.$gettext('Max size exceeded'),
      },
    };
  },
  computed: {
    texts() {
      return {
        explain: this.$gettextInterpolate(
          this.$gettext(`You can edit the default visibility and the color of each layer type of
            the layer '%{ name }'.`),
          { name: this.groupName },
        ),
      };
    },
    groupLayers() {
      return this.$store.state.map.editCustomDataLayers;
    },
    openDialog() {
      return this.groupLayers !== undefined;
    },
    groupName() {
      return this.openDialog ? this.groupLayers.name : '';
    },
    layers() {
      return this.openDialog ? this.groupLayers.layers : [];
    },
    layersType() {
      return this.layers.map((layer) => layer.layerType);
    },
    isPoint() {
      return this.layersType.includes('circle');
    },
    point() {
      return this.isPoint && this.layers.find((layer) => layer.layerType === 'circle');
    },
    pointDotStyle() {
      return `background: ${this.colorToStringRGBA(this.colors.point)};`;
    },
    isLine() {
      return this.layersType.includes('line');
    },
    line() {
      return this.isLine && this.layers.find((layer) => layer.layerType === 'line');
    },
    lineDotStyle() {
      return `background: ${this.colorToStringRGBA(this.colors.line)};`;
    },
    isPolygon() {
      return this.layersType.includes('fill');
    },
    polygon() {
      return this.isPolygon && this.layers.find((layer) => layer.layerType === 'fill');
    },
    polygonDotStyle() {
      return `background: ${this.colorToStringRGBA(this.colors.polygon)};`;
    },
    colorsEditable() {
      return [
        {
          type: 'point',
          condition: this.isPoint,
          style: this.pointDotStyle,
          text: this.$gettext('Edit points color'),
          editing: this.editColor === 'point' ? 'primary' : '',
        },
        {
          type: 'line',
          condition: this.isLine,
          style: this.lineDotStyle,
          text: this.$gettext('Edit lines color'),
          editing: this.editColor === 'line' ? 'primary' : '',
        },
        {
          type: 'polygon',
          condition: this.isPolygon,
          style: this.polygonDotStyle,
          text: this.$gettext('Edit polygons color'),
          editing: this.editColor === 'polygon' ? 'primary' : '',
        },
      ];
    },
  },
  watch: {
    openDialog(newValue) {
      if (newValue) {
        this.name = this.groupName;
        this.defaultVisibility = this.groupLayers.visibility;
        if (this.point) {
          this.colors.point = this.stringColorToRGBA(this.point);
          this.editColor = 'point';
        }
        if (this.line) {
          this.colors.line = this.stringColorToRGBA(this.line);
          this.editColor = this.editColor === null ? 'line' : this.editColor;
        }
        if (this.polygon) {
          this.colors.polygon = this.stringColorToRGBA(this.polygon);
          this.editColor = this.editColor === null ? 'polygon' : this.editColor;
        }
      } else {
        this.name = '';
        this.defaultVisibility = false;
        this.editColor = null;
      }
    },
  },
  methods: {
    close() {
      this.$store.commit(SHOW_CUSTOM_DATA_LAYERS_EDIT_DIALOG_NS, undefined);
    },
    stringColorToRGBA(layer) {
      const rgba = layer.style.paint[`${layer.layerType}-color`].match(/\d+.\d+|\d+/g);
      return {
        r: rgba[0],
        g: rgba[1],
        b: rgba[2],
        a: rgba[3],
      };
    },
    colorToStringRGBA(fillColor) {
      const { r, g, b, a } = fillColor;
      return `rgba(${r}, ${g}, ${b}, ${Number(a).toFixed(4)})`;
    },
    buildPayload() {
      return {
        identifier: this.groupLayers.identifier,
        name: this.name,
        color_point: this.isPoint ? this.colorToStringRGBA(this.colors.point) : null,
        color_line: this.isLine ? this.colorToStringRGBA(this.colors.line) : null,
        color_polygon: this.isPolygon ? this.colorToStringRGBA(this.colors.polygon) : null,
        visibility: this.defaultVisibility,
      };
    },
    editLayer() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const payload = this.buildPayload();
        APIService.editCustomDataLayer(payload)
          .then(() => {
            this.showMessage(this.$gettext('Layer updated successfully.'), 'success');
            this.close();
            this.$store.dispatch(RESET_DATA_STORES_NS);
            this.$router.push({ path: '/' });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};

</script>

<style scoped>
.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

</style>
