import Vue from 'vue';

import APIService from '@/services/api';

const namespace = 'pilots';

const FETCH_PILOTS = 'FETCH_PILOTS';
const GET_PILOTS = 'GET_PILOTS';
const GET_SIMPLIFIED_PILOTS = 'GET_SIMPLIFIED_PILOTS';
const SYNCHRONIZE_PILOTS = 'SYNCHRONIZE_PILOTS';
const SET_PILOTS = 'SET_PILOTS';
const RESET_PILOTS = 'RESET_PILOTS';
const ADD_PILOT_DOCUMENT = 'ADD_PILOT_DOCUMENT';
const DELETE_PILOT_DOCUMENT = 'DELETE_PILOT_DOCUMENT';

export const GET_PILOTS_NS = `${namespace}/${GET_PILOTS}`;
export const GET_SIMPLIFIED_PILOTS_NS = `${namespace}/${GET_SIMPLIFIED_PILOTS}`;
export const RESET_PILOTS_NS = `${namespace}/${RESET_PILOTS}`;
export const ADD_PILOT_DOCUMENT_NS = `${namespace}/${ADD_PILOT_DOCUMENT}`;
export const DELETE_PILOT_DOCUMENT_NS = `${namespace}/${DELETE_PILOT_DOCUMENT}`;

function initialState() {
  return {
    pilots: [],
    loadingPilots: false,
    loadingPilotsError: {},
    documentCategories: [
      { label: 'Certificat ou attestation d’aptitude ', value: 'aptitude_certificate' },
      { label: 'Attestation de formation pratique ', value: 'pratical_certificate' },
      { label: 'Livret de progression pratique ', value: 'pratical_booklet' },
      { label: 'Évaluation périodique théorique ', value: 'theoretical_evaluation' },
      { label: 'Évaluation périodique pratique ', value: 'periodic_evaluation' },
      { label: 'Autre type de document ', value: '' },
    ],
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    activePilots: (state) => state.pilots.filter((p) => !p.is_archived),
    pilotsOrderingByFullName: (state) => [...state.pilots].sort(
      (a, b) => a.full_name > b.full_name,
    ),
  },
  mutations: {
    [RESET_PILOTS](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [FETCH_PILOTS](state) {
      Vue.set(state, 'loadingPilots', true);
    },
    [SYNCHRONIZE_PILOTS](state, pilots) {
      const currentPilots = state.pilots;
      pilots.forEach((pilot) => {
        const existingPilotIndex = currentPilots.findIndex((p) => p.id === pilot.id);
        if (existingPilotIndex !== -1) {
          currentPilots[existingPilotIndex] = { ...currentPilots[existingPilotIndex], ...pilot };
        } else {
          currentPilots.push(pilot);
        }
      });
      Vue.set(state, 'loadingPilots', false);
      Vue.set(state, 'pilots', currentPilots);
    },
    [SET_PILOTS](state, { pilots = [], loadingPilotsError }) {
      Vue.set(state, 'loadingPilots', false);
      Vue.set(state, 'pilots', pilots);
      Vue.set(state, 'loadingPilotsError', loadingPilotsError);
    },
    [ADD_PILOT_DOCUMENT](state, { pilotId, document }) {
      const index = state.pilots.findIndex((p) => p.id === pilotId);
      if (index !== -1) {
        const { documents } = state.pilots[index];
        documents.push(document);
        Vue.set(state.pilots[index], 'documents', documents);
      }
    },
    [DELETE_PILOT_DOCUMENT](state, { pilotId, documentId }) {
      const index = state.pilots.findIndex((p) => p.id === pilotId);
      if (index !== -1) {
        const { documents } = state.pilots[index];
        const indexDocument = documents.findIndex((d) => d.id === documentId);
        if (indexDocument !== -1) {
          documents.splice(indexDocument, 1);
          Vue.set(state.pilots[index], 'documents', documents);
        }
      }
    },
  },
  actions: {
    [RESET_PILOTS]({ commit }) {
      commit(RESET_PILOTS);
    },
    [GET_SIMPLIFIED_PILOTS]({ state, commit, rootState }, isArchived = false) {
      if (!state.pilots.length) {
        const subExploitantId = rootState.authentication.selectedSubExploitant;
        commit(FETCH_PILOTS);
        APIService.getSimplifiedPilots({ isArchived, subExploitantId })
          .then(({ data }) => {
            commit(SYNCHRONIZE_PILOTS, data);
          });
      }
    },
    [GET_PILOTS]({ commit, rootState }) {
      commit(FETCH_PILOTS);
      const subExploitantId = rootState.authentication.selectedSubExploitant;
      APIService
        .getPilots({ subExploitantId })
        .then(({ data }) => {
          commit(SET_PILOTS, { pilots: data.results });
        })
        .catch((error) => {
          commit(SET_PILOTS, { loadingPilotsError: error });
        });
    },
    [ADD_PILOT_DOCUMENT]({ commit }, { pilotId, document }) {
      commit(ADD_PILOT_DOCUMENT, { pilotId, document });
    },
    [DELETE_PILOT_DOCUMENT]({ commit }, { pilotId, documentId }) {
      commit(DELETE_PILOT_DOCUMENT, { pilotId, documentId });
    },
  },
};
