<template>
  <div>
    <!-- Map Static Preview  -->
    <v-card class="pa-2">
      <v-img :src="mapPreviewSrc">
        <div class="d-flex justify-end pa-2">
          <v-btn
            icon
            elevation="2"
            class="white"
            :loading="mapLoading"
            @click="showMapDialog({ centerOnflight: false })"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </v-img>
    </v-card>
    <!-- Mapbox Map Dialog  -->
    <v-dialog
      v-model="map"
      width="1200"
      eager
    >
      <div id="map-dialog">
        <MapboxComponent />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  HIDE_FLIGHTS_NS,
  RESIZE_MAP_NS,
  SET_MAP_ACTIVATION_SELECTED_NS,
  ZOOM_TO_AREA_NS,
} from '@/store/map';
import { GET_STRUCTURES_AIP_GEOMETRIES_NS } from '@/store/structures';

import {
  flightSubmittedBackgroundColor,
  flightAcceptedBackgroundColor,
  flightRefusedBackgroundColor,
} from '@/styles/variables.scss';

import Map from '@/components/Map/Map.vue';
import { getStaticImageUrl } from '@/components/Map/MapboxGL.helper';

const STATUS_MATCHING_COLOR = {
  submitted: flightSubmittedBackgroundColor,
  accepted: flightAcceptedBackgroundColor,
  refused: flightRefusedBackgroundColor,
};

export default {
  name: 'ActivationMap',
  components: { MapboxComponent: Map },
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      map: false,
      initialDialogOpen: true,
    };
  },
  computed: {
    activationArea() {
      return this.$store.getters['activations/activationArea'];
    },
    mapLoading() {
      return (
        this.$store.state.activations.detailsLoading
        || !this.$store.state.map.isMapDataLoaded
      );
    },
    mapPreviewGeojson() {
      let geojson = {};
      if (this.activationArea) {
        geojson = JSON.parse(JSON.stringify(this.activationArea));
      }
      if (geojson.type === 'FeatureCollection') {
        for (let i = 0; i < geojson.features.length; i += 1) {
          const color = (
            STATUS_MATCHING_COLOR[geojson.features[i].properties.status]
            || flightSubmittedBackgroundColor
          );
          geojson.features[i].properties = {
            stroke: color,
            'stroke-width': 3,
            'stroke-opacity': 1,
            fill: color,
            'fill-opacity': 0.2,
          };
        }
      }
      return geojson;
    },
    mapPreviewSrc() {
      if (this.mapPreviewGeojson) {
        return getStaticImageUrl({
          overlayGeojson: this.mapPreviewGeojson,
          clearProperties: false,
          width: 800,
          height: 400,
        });
      }
      return '';
    },
  },
  watch: {
    map(newValue) {
      if (newValue && this.$store.state.structures.structuresAIPGeometries.features.length === 0) {
        this.$store.dispatch(GET_STRUCTURES_AIP_GEOMETRIES_NS);
      }
    },
  },
  methods: {
    showMapDialog({ centerOnflight = false, selectedSubApprovalGeometry = null }) {
      this.map = true;
      this.$nextTick(() => {
        if (this.initialDialogOpen || centerOnflight || selectedSubApprovalGeometry) {
          if (this.initialDialogOpen) {
            this.$store.dispatch(RESIZE_MAP_NS);
            this.$store.dispatch(HIDE_FLIGHTS_NS);
            this.setMapSelectedActivation();
          }
          if (centerOnflight) {
            this.$store.dispatch(ZOOM_TO_AREA_NS, this.activationArea);
          } else if (selectedSubApprovalGeometry) {
            this.$store.dispatch(ZOOM_TO_AREA_NS, selectedSubApprovalGeometry);
          } else if (this.initialDialogOpen) {
            this.$store.dispatch(ZOOM_TO_AREA_NS, this.activationArea);
          }
          this.initialDialogOpen = false;
        }
      });
    },
    setMapSelectedActivation() {
      this.$store.dispatch(SET_MAP_ACTIVATION_SELECTED_NS, this.activationArea);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
#map-dialog {
  height: 800px;
  position: relative;
}

</style>
