<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate
        :translate-n="flights.length"
        translate-plural="Flights area export"
      >
        Flight area export
      </translate>
    </v-card-title>
    <v-card-text>
      <span>
        {{ texts.explainKML }}
      </span>
      <div class="d-flex justify-center">
        <v-radio-group
          row
          v-model="format"
        >
          <v-radio
            v-for="(item, index) in itemsFormat"
            :key="index"
            :label="item.title"
            :value="item.format"
          />
        </v-radio-group>
      </div>
      <div v-if="!isSingleFlight">
        <span>
          {{ texts.explainExportFormat }}
        </span>
        <div class="d-flex justify-center">
          <v-radio-group
            row
            v-model="exportFormat"
          >
            <v-radio
              v-for="(item, index) in itemsExportFormat"
              :key="index"
              :label="item.title"
              :value="item.format"
            />
          </v-radio-group>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        :loading="loadingExport"
        text
        color="primary"
        @click="exportArea()"
      >
        <translate>Confirm</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

export default {
  name: 'FlightAreaExport',
  props: {
    flights: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      format: 'kml',
      itemsFormat: [
        { title: 'KML', format: 'kml' },
        { title: 'KMZ', format: 'kmz' },
      ],
      exportFormat: 'zip',
      itemsExportFormat: [
        { title: this.$gettext('Zip file'), format: 'zip' },
        { title: this.$gettext('Single file'), format: 'file' },
      ],
      loadingExport: false,
    };
  },
  computed: {
    texts() {
      return {
        explainKML: this.$gettext(`Select file format of export. This file will be open either on
          Google Earth.`),
        explainExportFormat: this.$gettext(`Select return file type. Zip choice will returned a
          zip file containing a file for each flight. Single file choice will return a file
          containing all selected flights.`),
      };
    },
    isSingleFlight() {
      return this.flights.length === 1;
    },
    flight() {
      return this.isSingleFlight && this.flights[0];
    },
    flightsId() {
      return this.flights.map((flight) => flight.id);
    },
  },
  methods: {
    exportArea() {
      if (this.isSingleFlight) {
        this.exportFlightArea();
      } else {
        this.exportFlightsArea();
      }
    },
    exportFlightArea() {
      this.loadingExport = true;
      APIService.downloadFlightAreaKML(
        this.flight.id,
        { no_name: false, type: this.format },
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettextInterpolate(
              this.$gettext('flight_area.%{ format }'),
              { format: this.format },
            )
          );
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$emit('close');
        })
        .finally(() => {
          this.loadingExport = false;
        });
    },
    exportFlightsArea() {
      this.loadingExport = true;
      APIService.downloadFlightsAreaKML(this.flightsId, this.format, this.exportFormat)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettextInterpolate(
              this.$gettext('flights_area.%{ format }'),
              { format: this.exportFormat === 'zip' ? this.exportFormat : this.format },
            )
          );
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$emit('close');
        })
        .finally(() => {
          this.loadingExport = false;
        });
    },
  },
};
</script>
