import Vue from 'vue';

import APIService from '@/services/api';

/* eslint-disable import/no-cycle */
import { APPLICATION_TAB, getPageFromNextPreviousURL } from '@/store/application';
import {
  SELECTABLE_FEATURE_KEYS,
  SET_MAP_FEATURE_HOVERED_NS,
  SET_MAP_FEATURE_SELECTED_NS,
} from '@/store/map';
import { GET_AUTHORITY_STRUCTURES_NS } from '@/store/structures';

const namespace = 'approvals';

const GET_APPROVALS = 'GET_APPROVALS';
const GET_APPROVALS_GEOMETRY = 'GET_APPROVALS_GEOMETRY';
const GET_ALL_APPROVALS = 'GET_ALL_APPROVALS';
const GET_TABLE_ITEMS_APPROVALS = 'GET_TABLE_ITEMS_APPROVALS';
const FETCH_APPROVALS_COLLECTION = 'FETCH_APPROVALS_COLLECTION';
const SET_APPROVALS_COLLECTION = 'SET_APPROVALS_COLLECTION';
const SET_APPROVALS_COLLECTION_LOADED = 'SET_APPROVALS_COLLECTION_LOADED';
const FETCH_TABLE_ITEMS = 'FETCH_TABLE_ITEMS';
const SET_TABLE_ITEMS = 'SET_TABLE_ITEMS';
const SET_SHOW_ONLY_APPROVALS_TO_PROCESS = 'SET_SHOW_ONLY_APPROVALS_TO_PROCESS';
const GET_APPROVAL_DETAILS = 'GET_APPROVAL_DETAILS';
const SET_APPROVAL_DETAILS = 'SET_APPROVAL_DETAILS';
const SET_APPROVAL_DETAILS_LOADING = 'SET_APPROVAL_DETAILS_LOADING';

const SET_APPROVAL_STATUS = 'SET_APPROVAL_STATUS';
const VALIDATE_PROTOCOL_SHEET = 'VALIDATE_PROTOCOL_SHEET';
const SET_APPROVAL_EN_ROUTE = 'SET_APPROVAL_EN_ROUTE';
const SET_APPROVAL_EN_ROUTE_BY_SUPERVISER = 'SET_APPROVAL_EN_ROUTE_BY_SUPERVISER';
const SET_PAGINATION = 'SET_PAGINATION';
const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
const SET_FILTERS = 'SET_FILTERS';
const RESET_FILTERS = 'RESET_FILTERS';
const SET_DEFAULT_PAGINATION = 'SET_DEFAULT_PAGINATION';
const SET_APPROVAL_SELECTED = 'SET_APPROVAL_SELECTED';
const SET_APPROVAL_HOVER = 'SET_APPROVAL_HOVER';
const RESET_APPROVALS = 'RESET_APPROVALS';
const SET_APPROVAL_MISSION_SHEET = 'SET_APPROVAL_MISSION_SHEET';
const SET_APPROVAL_TAKE_OFF_AUTHORIZATION = 'SET_APPROVAL_TAKE_OFF_AUTHORIZATION';
const SET_APPROVAL_IS_CLOSED = 'SET_APPROVAL_IS_CLOSED';
const SET_APPROVAL_MESSAGES_READED = 'SET_APPROVAL_MESSAGES_READED';

export const GET_ALL_APPROVALS_NS = `${namespace}/${GET_ALL_APPROVALS}`;
export const GET_TABLE_ITEMS_APPROVALS_NS = `${namespace}/${GET_TABLE_ITEMS_APPROVALS}`;
export const GET_APPROVAL_DETAILS_NS = `${namespace}/${GET_APPROVAL_DETAILS}`;
export const SET_APPROVAL_SELECTED_NS = `${namespace}/${SET_APPROVAL_SELECTED}`;
export const SET_APPROVAL_HOVER_NS = `${namespace}/${SET_APPROVAL_HOVER}`;
export const SET_APPROVAL_STATUS_NS = `${namespace}/${SET_APPROVAL_STATUS}`;
export const VALIDATE_PROTOCOL_SHEET_NS = `${namespace}/${VALIDATE_PROTOCOL_SHEET}`;
export const SET_PAGINATION_NS = `${namespace}/${SET_PAGINATION}`;
export const SET_FILTERS_NS = `${namespace}/${SET_FILTERS}`;
export const RESET_FILTERS_NS = `${namespace}/${RESET_FILTERS}`;
export const SET_DEFAULT_PAGINATION_NS = `${namespace}/${SET_DEFAULT_PAGINATION}`;
export const RESET_APPROVALS_NS = `${namespace}/${RESET_APPROVALS}`;
export const SET_APPROVAL_DETAILS_NS = `${namespace}/${SET_APPROVAL_DETAILS}`;
export const SET_APPROVAL_EN_ROUTE_NS = `${namespace}/${SET_APPROVAL_EN_ROUTE}`;
export const SET_APPROVAL_EN_ROUTE_BY_SUPERVISER_NS = `${namespace}/${SET_APPROVAL_EN_ROUTE_BY_SUPERVISER}`;
export const SET_APPROVAL_MISSION_SHEET_NS = `${namespace}/${SET_APPROVAL_MISSION_SHEET}`;
export const SET_APPROVAL_TAKE_OFF_AUTHORIZATION_NS = `${namespace}/${SET_APPROVAL_TAKE_OFF_AUTHORIZATION}`;
export const SET_APPROVAL_IS_CLOSED_NS = `${namespace}/${SET_APPROVAL_IS_CLOSED}`;
export const SET_APPROVAL_MESSAGES_READED_NS = `${namespace}/${SET_APPROVAL_MESSAGES_READED}`;

const NOT_FOUND_ERROR = 'NOT FOUND';

export function updateSubApprovals(subApprovals, updatedSubApprovals) {
  updatedSubApprovals.forEach((updatedSubApproval) => {
    const subApproval = subApprovals.find((s) => s.id === updatedSubApproval.id);
    const updatedProperties = { ...subApproval, ...updatedSubApproval };
    Object.keys(updatedSubApproval).forEach((key) => {
      Vue.set(subApproval, key, updatedProperties[key]);
    });
  });
}

function updateApproval(state, { approvalId, properties }) {
  const approval = state.approvalsCollection.find((a) => a.id === approvalId);
  if (!approval) return;
  if (Object.keys(properties).includes('sub_approvals') && approval.sub_approvals?.length) {
    updateSubApprovals(approval.sub_approvals, properties.sub_approvals);
    // eslint-disable-next-line no-param-reassign
    delete properties.sub_approvals;
  }
  const updatedProperties = { ...approval, ...properties };
  Object.keys(properties).forEach((key) => {
    Vue.set(approval, key, updatedProperties[key]);
  });
}

const defaultFilters = {
  status: '',
  exploitant: '',
  dateFrom: '',
  dateTo: '',
  q: null,
  isClosed: 'False',
  tacticalContactRequired: null,
  takeOffAuthorization: null,
  enRoute: null,
  insideImpactingZone: null,
  showOnlyApprovalsToProcess: null,
};

function initialState() {
  return {
    approvalsCollection: [],
    approvalsCollectionLoading: false,
    approvalsCollectionLoaded: false,
    itemsLength: 0,
    pagination: {
      itemsPerPage: 25,
      page: 1,
      sortBy: ['-id'],
      sortDesc: [false],
    },
    tableIds: [], // Ordered list of flight ids according to pagination
    tableItemsLoading: false,
    tableItemsLoadingError: {},
    filters: { ...defaultFilters },
    approvalDetailsLoading: false,
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    tableItems: (state) => {
      const tableItems = [];
      state.approvalsCollection.forEach((approval) => {
        const index = state.tableIds.indexOf(approval.id);
        if (index !== -1) {
          tableItems[index] = approval;
        }
      });
      return tableItems;
    },
    isTableItemsLoading: (state) => state.tableItemsLoading,
    mapCenters: (state, getters) => {
      let features = state.approvalsCollection.map((approval) => {
        const {
          id,
          status,
          is_closed: isClosed,
          company_name: companyName,
          flight,
          display_identifier: displayIdentifier,
          center: geometry,
        } = approval;
        return {
          type: 'Feature',
          id,
          properties: {
            status,
            is_closed: isClosed,
            company_name: companyName,
            flight,
            display_identifier: displayIdentifier,
            is_authority: true, // needed for incomplete status
          },
          geometry,
        };
      });
      if (getters.approvalSelected) {
        features = features.filter((f) => f.id !== getters.approvalSelected.id);
      }
      return { type: 'FeatureCollection', features };
    },
    mapAreas: (state, getters) => {
      let features = [];
      state.approvalsCollection.map((approval) => approval.sub_approvals?.forEach(
        (subApproval) => {
          const { id, geometry, identifier: name } = subApproval;
          const { id: approvalId, en_route: enRoute, is_closed: isClosed, status } = approval;
          features.push({
            type: 'Feature',
            id,
            properties: {
              approval_id: approvalId,
              en_route: enRoute,
              is_closed: isClosed,
              name,
              status,
              is_authority: true, // needed for incomplete status
            },
            geometry,
          });
        },
      ));
      if (getters.approvalSelected) {
        features = features.filter(
          (f) => f.properties.approval_id !== getters.approvalSelected.id,
        );
      }
      return { type: 'FeatureCollection', features };
    },
    approvalSelected: (state, getters, rootState) => state.approvalsCollection.find(
      (approval) => approval.id === rootState.map.featureIdSelected.flight,
    ),
    approvalArea: (state, getters) => {
      const features = [];
      const { approvalSelected } = getters;
      if (approvalSelected) {
        approvalSelected.sub_approvals.forEach((subApproval) => {
          const { status, identifier, geometry } = subApproval;
          features.push({
            type: 'Feature',
            id: subApproval.id,
            properties: {
              status,
              name: identifier,
              height: `${subApproval.height} m`,
              is_closed: approvalSelected.is_closed,
              is_authority: true, // needed for incomplete status
            },
            geometry,
          });
        });
      }
      return { type: 'FeatureCollection', features };
    },
    approvalHover: (state, getters, rootState) => state.approvalsCollection.find(
      (approval) => approval.id === rootState.map.featureIdHovered.flight,
    ),
    activeFilters: (state) => {
      let activeFilters = 0;
      const { filters } = state;
      Object.keys(defaultFilters).forEach((key) => {
        if (key === 'showOnlyApprovalsToProcess') {
          if (filters[key] === true) activeFilters += 1;
        } else if (filters[key] !== defaultFilters[key]) {
          activeFilters += 1;
        }
      });
      return activeFilters;
    },
  },
  mutations: {
    [RESET_APPROVALS](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [FETCH_APPROVALS_COLLECTION](state) {
      Vue.set(state, 'tableIds', []);
      Vue.set(state, 'approvalsCollectionLoading', true);
    },
    [SET_APPROVALS_COLLECTION_LOADED](state) {
      Vue.set(state, 'approvalsCollectionLoaded', true);
    },
    [SET_APPROVALS_COLLECTION](
      state,
      {
        approvalsCollection = [],
        approvalsCollectionLoading,
      },
    ) {
      Vue.set(state, 'approvalsCollection', approvalsCollection);
      Vue.set(state, 'approvalsCollectionLoading', approvalsCollectionLoading);
    },
    [FETCH_TABLE_ITEMS](state) {
      Vue.set(state, 'tableItemsLoading', true);
    },
    [SET_TABLE_ITEMS](state, { items = [], tableItemsLoadingError }) {
      items.forEach((item) => {
        const index = state.approvalsCollection.findIndex((approval) => approval.id === item.id);
        const approval = state.approvalsCollection[index];
        const properties = { ...approval, ...item };
        Vue.set(state.approvalsCollection, index, properties);
      });
      Vue.set(state, 'tableIds', items.map((i) => i.id));
      Vue.set(state, 'tableItemsLoadingError', tableItemsLoadingError);
      Vue.set(state, 'tableItemsLoading', false);
    },
    [SET_APPROVAL_DETAILS_LOADING](state) {
      Vue.set(state, 'approvalDetailsLoading', true);
    },
    [SET_APPROVAL_DETAILS](state, { approval, approvalId }) {
      updateApproval(state, { approvalId, properties: approval });
      Vue.set(state, 'approvalDetailsLoading', false);
    },
    [SET_APPROVAL_EN_ROUTE](state, { approvalId, enRoute }) {
      const index = state.approvalsCollection.findIndex((f) => f.id === approvalId);
      if (index !== -1) {
        const feature = { ...state.approvalsCollection[index] };
        feature.flight.en_route = enRoute;
        Vue.set(state.approvalsCollection, index, feature);
      }
    },
    [SET_APPROVAL_EN_ROUTE_BY_SUPERVISER](state, { approvalId, enRoute }) {
      updateApproval(state, { approvalId, properties: { en_route_by_superviser: enRoute } });
    },
    [SET_PAGINATION](state, payload) {
      Vue.set(state, 'pagination', payload.pagination);
    },
    [UPDATE_PAGINATION](state, { itemsLength, page }) {
      if (itemsLength !== undefined) {
        Vue.set(state, 'itemsLength', itemsLength);
      }
      if (page !== undefined) {
        Vue.set(state.pagination, 'page', page);
      }
    },
    [SET_FILTERS](state, payload) {
      Vue.set(state, 'filters', payload.filters);
    },
    [RESET_FILTERS](state) {
      Vue.set(state, 'filters', { ...defaultFilters });
      Vue.set(state.filters, 'showOnlyApprovalsToProcess', false);
    },
    [SET_APPROVAL_MISSION_SHEET](state, { approvalId, missionSheet }) {
      updateApproval(
        state,
        { approvalId, properties: { mission_sheet: missionSheet } },
      );
    },
    [SET_APPROVAL_TAKE_OFF_AUTHORIZATION](state, { approvalId, takeOffAuthorization }) {
      updateApproval(
        state,
        { approvalId, properties: { take_off_authorization: takeOffAuthorization } },
      );
    },
    [SET_APPROVAL_IS_CLOSED](state, { approvalId, isClosed }) {
      updateApproval(
        state,
        { approvalId, properties: { is_closed: isClosed } },
      );
    },
    [SET_APPROVAL_MESSAGES_READED](state, { approvalId }) {
      updateApproval(state, { approvalId, properties: { unread_message_for_authority: false } });
    },
    [SET_SHOW_ONLY_APPROVALS_TO_PROCESS](state, value) {
      Vue.set(state.filters, 'showOnlyApprovalsToProcess', value);
    },
  },
  actions: {
    [RESET_APPROVALS]({ commit }) {
      commit(RESET_APPROVALS);
    },
    async [GET_ALL_APPROVALS]({ state, commit, dispatch }, { updateBoundingBox }) {
      if (state.pagination.page === 1) {
        await dispatch(GET_APPROVALS, { updateBoundingBox, reload: true });
      } else {
        commit(UPDATE_PAGINATION, { itemsLength: state.itemsLength, page: 1 });
        await dispatch(GET_APPROVALS_GEOMETRY, { updateBoundingBox: true, reload: true });
      }
    },
    [GET_TABLE_ITEMS_APPROVALS]({ dispatch }, { updateBoundingBox }) {
      dispatch(GET_APPROVALS, { updateBoundingBox, reload: false });
    },
    async [GET_APPROVALS_GEOMETRY](
      { commit, state, dispatch, rootState, rootGetters, getters },
      { updateBoundingBox = true, reload = false },
    ) {
      if (!state.approvalsCollectionLoaded || reload) {
        const {
          dateFrom,
          dateTo,
          status,
          exploitant,
          q,
          isClosed,
          tacticalContactRequired,
          takeOffAuthorization,
          enRoute,
          insideImpactingZone,
          showOnlyApprovalsToProcess,
        } = state.filters;
        const { sortBy, sortDesc } = state.pagination;
        commit(FETCH_APPROVALS_COLLECTION);
        await APIService.getApprovalsGeoCollection({
          ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
          status,
          exploitant_id: exploitant,
          date_min: dateFrom,
          date_max: dateTo,
          q,
          is_closed: isClosed,
          tactical_contact_required: tacticalContactRequired,
          take_off_authorization: takeOffAuthorization,
          en_route: enRoute,
          inside_impacting_zone: insideImpactingZone,
          only_approvals_to_process: showOnlyApprovalsToProcess,
        })
          .then(async (response) => {
            if (response) {
              const { data } = response;
              commit(SET_APPROVALS_COLLECTION, {
                approvalsCollection: data.results,
                approvalsCollectionLoading: false,
              });
              commit(SET_APPROVALS_COLLECTION_LOADED);
              if (
                updateBoundingBox === true
                && rootGetters['application/activeTab'] === APPLICATION_TAB.APPROVAL
              ) {
                if (data.count === 0) {
                  await dispatch(GET_AUTHORITY_STRUCTURES_NS, {}, { root: true });
                  if (rootState.structures.items.length) {
                    const structures = rootState.structures.items;
                    const bbox = [
                      [
                        Math.min(...structures.map((s) => s.long_min)),
                        Math.min(...structures.map((s) => s.lat_min)),
                      ],
                      [
                        Math.max(...structures.map((s) => s.long_max)),
                        Math.max(...structures.map((s) => s.lat_max)),
                      ],
                    ];
                    dispatch('map/SET_BOUNDING_BOX', { boundingBox: bbox }, { root: true });
                  }
                } else {
                  dispatch('map/SET_BOUNDING_BOX', { fromGeom: getters.mapAreas }, { root: true });
                }
              }
            }
          })
          .catch((error) => {
            commit(SET_APPROVALS_COLLECTION, { approvalsCollectionLoading: error });
          });
      }
    },
    async [GET_APPROVALS](
      { commit, state, rootGetters, dispatch },
      { withId, updateBoundingBox = true, reload = false },
    ) {
      if (state.filters.showOnlyApprovalsToProcess == null) {
        const showOnlyApprovalsToProcess = rootGetters['authentication/showOnlyApprovalsToProcess'];
        commit(
          SET_SHOW_ONLY_APPROVALS_TO_PROCESS,
          showOnlyApprovalsToProcess,
        );
      }
      const { itemsPerPage, sortBy, sortDesc, page } = state.pagination;
      const {
        dateFrom,
        dateTo,
        status,
        exploitant,
        q,
        isClosed,
        tacticalContactRequired,
        takeOffAuthorization,
        enRoute,
        insideImpactingZone,
        showOnlyApprovalsToProcess,
      } = state.filters;
      commit(FETCH_TABLE_ITEMS);
      if (!state.approvalsCollectionLoaded || reload) {
        await dispatch(GET_APPROVALS_GEOMETRY, { updateBoundingBox, reload });
      }
      // Get and set table items according to current pagination or given id
      const getApprovalsParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        limit: itemsPerPage,
        status,
        date_min: dateFrom,
        date_max: dateTo,
        exploitant_id: exploitant,
        q,
        is_closed: isClosed,
        tactical_contact_required: tacticalContactRequired,
        take_off_authorization: takeOffAuthorization,
        en_route: enRoute,
        inside_impacting_zone: insideImpactingZone,
        only_approvals_to_process: showOnlyApprovalsToProcess,
      };
      if (withId) {
        getApprovalsParams.with_id = withId;
      } else {
        getApprovalsParams.offset = (page - 1) * itemsPerPage;
      }
      return APIService.getApprovals(getApprovalsParams)
        .then((response) => {
          if (response) {
            const { data } = response;
            const items = data.results;
            const itemsLength = data.count;
            let newPage;
            if (withId) {
              if (!items.find((approval) => approval.id === withId)) {
                throw new Error(NOT_FOUND_ERROR);
              }
              // Find new page where the approval with the given id is
              newPage = getPageFromNextPreviousURL(
                data.next,
                data.previous,
                data.count,
                itemsPerPage,
              );
            }
            commit(SET_TABLE_ITEMS, { items });
            commit(UPDATE_PAGINATION, { itemsLength, page: newPage });
          }
        })
        .catch((error) => {
          if (error.message === NOT_FOUND_ERROR) {
            throw error;
          }
          commit(SET_TABLE_ITEMS, { tableItemsLoadingError: error });
        });
    },
    async [GET_APPROVAL_DETAILS](
      { commit, getters, dispatch },
      { approvalId, reload = false },
    ) {
      commit(SET_APPROVAL_DETAILS_LOADING);
      if (getters.tableItems.findIndex((item) => item.id === approvalId) === -1 || reload) {
        await dispatch(GET_APPROVALS, { withId: approvalId, reload, updateBoundingBox: false });
        dispatch(
          SET_MAP_FEATURE_SELECTED_NS,
          { featureId: approvalId, key: SELECTABLE_FEATURE_KEYS.flight },
          { root: true },
        );
      }
      APIService.getApprovalDetail(approvalId)
        .then(({ data }) => {
          commit(SET_APPROVAL_DETAILS, {
            approvalId,
            approval: data,
          });
        });
    },
    async [VALIDATE_PROTOCOL_SHEET]({ commit }, { approvalId, payload }) {
      await APIService.validateProtocolSheet(approvalId, payload)
        .then(({ data }) => {
          commit(SET_APPROVAL_DETAILS, { approvalId, approval: data });
        });
    },
    async [SET_APPROVAL_STATUS]({ commit }, { id, payload }) {
      await APIService.createApprovalDecision(id, payload)
        .then(({ data }) => {
          commit(SET_APPROVAL_DETAILS, {
            approvalId: id,
            approval: data,
          });
        });
    },
    [SET_APPROVAL_SELECTED]({ dispatch }, approvalId) {
      dispatch(
        SET_MAP_FEATURE_SELECTED_NS,
        { featureId: approvalId, key: SELECTABLE_FEATURE_KEYS.flight },
        { root: true },
      );
      if (approvalId) {
        dispatch(GET_APPROVAL_DETAILS, { approvalId });
      }
    },
    [SET_APPROVAL_HOVER]({ dispatch }, approvalId) {
      dispatch(
        SET_MAP_FEATURE_HOVERED_NS,
        { featureId: approvalId, key: SELECTABLE_FEATURE_KEYS.flight },
        { root: true },
      );
    },
    async [SET_APPROVAL_DETAILS]({ commit }, { approvalId, data }) {
      commit(SET_APPROVAL_DETAILS, {
        approvalId,
        approval: data,
      });
    },
    [SET_APPROVAL_EN_ROUTE]({ commit }, { approvalId, enRoute }) {
      commit(SET_APPROVAL_EN_ROUTE, { approvalId, enRoute });
    },
    async [SET_APPROVAL_EN_ROUTE_BY_SUPERVISER]({ commit }, { approvalId, enRoute }) {
      await APIService.markApprovalAsEnRoute({ id: approvalId, enRoute })
        .then(() => {
          commit(SET_APPROVAL_EN_ROUTE_BY_SUPERVISER, { approvalId, enRoute });
        });
    },
    [SET_PAGINATION]({ commit, dispatch }, pagination) {
      dispatch(SET_APPROVAL_SELECTED, null);
      commit(SET_PAGINATION, { pagination });
      dispatch(GET_APPROVALS, {});
    },
    [SET_FILTERS]({ commit }, filters) {
      commit(SET_FILTERS, { filters });
      commit('authentication/SET_FILTER_APPROVALS_TO_PROCESS',
        { showOnlyApprovalsToProcess: filters.showOnlyApprovalsToProcess },
        { root: true });
    },
    [RESET_FILTERS]({ commit }) {
      commit(RESET_FILTERS);
      commit(
        'authentication/SET_FILTER_APPROVALS_TO_PROCESS',
        { showOnlyApprovalsToProcess: false },
        { root: true },
      );
    },
    [SET_DEFAULT_PAGINATION]({ state, commit, rootGetters }) {
      if (rootGetters['authentication/isAuthorityController']) {
        commit(SET_PAGINATION, {
          pagination: {
            ...state.pagination,
            sortBy: ['-airspace_controller_initial_status_ordering'],
          },
        });
      } else {
        commit(SET_PAGINATION, {
          pagination: {
            ...state.pagination,
            sortBy: ['-authority_initial_status_ordering'],
          },
        });
      }
    },
    [SET_APPROVAL_MISSION_SHEET]({ commit }, { approvalId, missionSheet }) {
      commit(SET_APPROVAL_MISSION_SHEET, { approvalId, missionSheet });
    },
  },
};
