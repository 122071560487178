<template>
  <div>
    <!-- Header -->
    <div class="header py-4">
      <div class="header headline">
        <translate>Tags</translate>
      </div>
      <div class="header buttons">
        <v-btn
          v-if="isManagerOrAdmin"
          @click="openForm()"
          color="primary"
          depressed
          key="add"
        >
          <translate>+ add tag</translate>
        </v-btn>
      </div>
    </div>
    <!-- Data table -->
    <div class="pa-2">
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="texts.search"
        single-line
      />
      <v-data-table
        :headers="headers"
        :items="tags"
        :search="search"
        :loading="tagsLoading"
        :loading-text="texts.loadingText"
        :no-data-text="texts.noResultText"
        :no-results-text="texts.noResultText"
        :mobile-breakpoint="singleColumnBreakpoint"
        :footer-props="{
          'items-per-page-text': $gettext('Lines per page:'),
          'items-per-page-all-text': $gettext('All'),
          'items-per-page-options': [25, 50, 100, -1],
        }"
        calculate-widths
      >
        <!-- td sub-exploitant chip  -->
        <template
          v-if="hasSubExploitants"
          v-slot:[`item.sub_exploitant_name`]="{ item }"
        >
          <v-chip x-small>
            {{ item.sub_exploitant_name }}
          </v-chip>
        </template>
        <!-- td auto tag tile  -->
        <template v-slot:[`item.constraint_type_name`]="{ item }">
          <v-chip
            v-if="item.is_constraint_auto_tag"
            color="primary"
            class="mr-2"
            label
            x-small
          >
            auto
          </v-chip>
          {{ item.constraint_type_name }}
        </template>
        <!-- td actions  -->
        <template v-slot:[`item.actions`]="{ item }">
          <div
            v-if="isManagerOrAdmin"
            class="d-flex justify-end"
          >
            <v-btn
              icon
              @click.stop="openForm(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              :loading="deleteLoading"
              @click.stop="openDialogDelete(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <!-- create or update tag form dialog -->
    <v-dialog
      v-if="dialogForm"
      v-model="dialogForm"
      max-width="680"
      :retain-focus="false"
      :fullscreen="isMobileBreakpoint"
      persistent
    >
      <FlightTagForm
        :tagToUpdate="tagToUpdate"
        :tagConstraintTypes="tagConstraintTypes"
        @reload="getFlightTags()"
        @close="() => closeForm()"
      />
    </v-dialog>
    <!-- Delete tag dialog -->
    <v-dialog
      v-if="dialogDelete"
      v-model="dialogDelete"
      max-width="420"
      :retain-focus="false"
      :fullscreen="isMobileBreakpoint"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Delete tag</translate>
        </v-card-title>
        <v-card-text>
          <div class="pb-1">{{ texts.deleteConfirmation }}</div>
          <div>{{ texts.deleteConfirmationDetails }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDialogDelete()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="deleteTag()"
            :loading="deleteLoading"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import FlightTagForm from '@/components/Settings/Dronists/FlightTagForm.vue';

export default {
  name: 'FlightTagsSettings',
  components: { FlightTagForm },
  data() {
    return {
      search: '',
      tagsLoading: false,
      tags: [],
      tagConstraintTypes: [],
      dialogForm: false,
      tagToUpdate: null,
      deleteLoading: false,
      dialogDelete: false,
      tagToDelete: null,
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    texts() {
      return {
        search: this.$gettext('Search'),
        loadingText: this.$gettext('Tags loading ...'),
        noResultText: this.$gettext('No tags found'),
        deleteConfirmation: this.$gettextInterpolate(
          this.$gettext('Are you sure you want to delete the tag "%{ name }" ?'),
          { name: this.tagToDelete?.name || '' },
        ),
        deleteConfirmationDetails: this.$gettextInterpolate(
          this.$gettext(`After confirmation, this tag will be removed from the flights to which
            it is associated %{ autoTagExta }.`),
          {
            autoTagExta: (
              this.tagToDelete?.is_constraint_auto_tag
                ? this.$gettext(', and will no longer be automatically applied')
                : ''
            ),
          },
        ),
      };
    },
    isManagerOrAdmin() {
      return (
        this.$store.getters['authentication/isUserExploitantAdmin']
        || this.$store.getters['authentication/isUserExploitantManager']
      );
    },
    hasSubExploitants() {
      return this.$store.getters['exploitants/hasSubExploitants'];
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
    headers() {
      return [
        { text: this.$gettext('Name'), value: 'name', width: '30%' },
        { text: this.$gettext('Flights'), value: 'flights_count', align: 'center' },
        ...(
          this.hasSubExploitants ? [
            { text: this.entityName.singular, value: 'sub_exploitant_name' },
          ] : []
        ),
        { text: this.$gettext('Constraint type'), value: 'constraint_type_name' },
        { text: '', value: 'actions', sortable: false },
      ];
    },
  },
  mounted() {
    this.getFlightTags();
    this.getFlightTagConstraintTypes();
  },
  methods: {
    getFlightTags() {
      this.tagsLoading = true;
      APIService.getFlightTags()
        .then(({ data }) => {
          this.tags = data;
        })
        .finally(() => {
          this.tagsLoading = false;
        });
    },
    openForm(item = null) {
      if (item) {
        this.tagToUpdate = { ...item };
      }
      this.dialogForm = true;
    },
    closeForm() {
      this.dialogForm = false;
      this.tagToUpdate = null;
    },
    openDialogDelete(tag) {
      this.tagToDelete = tag;
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.dialogDelete = false;
      this.tagToDelete = null;
    },
    deleteTag() {
      this.deleteLoading = true;
      APIService.deleteFlightTag(this.tagToDelete.id)
        .then(() => {
          this.getFlightTags();
          this.showMessage(this.$gettext('Tag successfully deleted.'), 'success');
        })
        .finally(() => {
          this.deleteLoading = false;
          this.closeDialogDelete();
        });
    },
    getFlightTagConstraintTypes() {
      APIService.getFlightTagConstraintTypes()
        .then(({ data }) => {
          this.tagConstraintTypes = data;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.header {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;

  .headline {
    flex-grow: 1;
  }
  .buttons {
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
  }
}

</style>
