<template>
  <v-card
    class="default--dialog__card"
    max-height="600px"
    elevation="0"
  >
    <v-card-title>
      <span v-translate="{name: capability.structure_name}">
        Terms and conditions for flights near %{ name }.
      </span>
    </v-card-title>
    <v-card-text>
      <v-alert
        outlined
        class="mt-2"
      >
        <vue-markdown
          :source="capability.terms_and_conditions"
          html
          xhtml-out
          typographer
          linkify
          breaks
          show
          :emoji="false"
          :anchorAttributes="{ target: '_blank' }"
          class="pa-2 markdown"
        />
      </v-alert>
      <v-checkbox
        v-model="agree"
        :label="labels.agreement"
        hide-details
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        class="px-2"
        @click="closeAgreementDialog()"
      >
        <span v-translate>Cancel</span>
      </v-btn>
      <v-btn
        color="primary"
        class="px-4"
        @click="agreeWithConditions()"
        :disabled="!agree"
        :loading="loading"
      >
        <span v-translate>Confirm</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VueMarkdown from 'vue-markdown';

import APIService from '@/services/api';

export default {
  name: 'RequestApprovalFormTermsAndConditions',
  components: { VueMarkdown },
  props: {
    capability: Object,
    structureId: Number,
  },
  data() {
    return {
      agree: false,
      loading: false,
    };
  },
  computed: {
    labels() {
      return {
        agreement: this.$gettextInterpolate(
          this.$gettext(`As representant of the exploitant %{ name }, I agree
            with the above terms and conditions.`),
          { name: this.exploitant.commercial_name },
          true,
        ),
      };
    },
    exploitant() {
      return this.$store.state.exploitants.exploitationDetails;
    },
  },
  methods: {
    closeAgreementDialog() {
      this.agree = false;
      this.$emit('close');
    },
    async agreeWithConditions() {
      this.loading = true;
      const payload = {
        exploitant: this.exploitant.id,
        structure: this.structureId,
        validation_date: new Date(),
      };
      await APIService.postValidationTermsConditions(payload)
        .then(() => {
          this.$emit('agree');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
