<template>
  <v-container
    fluid
    fill-height
    class="theme--light v-sheet pa-0"
  >
    <div class="approval-page">
      <v-toolbar
        class="approval-header"
        elevation="4"
      >
        <ApprovalHeader
          :approval="approval"
          :approvalLoading="approvalLoading"
          :approvalDetailsLoading="approvalDetailsLoading"
        />
      </v-toolbar>
      <v-row
        class="approval-row"
        no-gutters
      >
        <v-col
          class="approval-column details"
          cols="12"
          :md="approvalPageIsSimplified ? 12 : 4"
        >
          <template v-if="approvalDetailsLoading">
            <v-skeleton-loader
              type="list-item, list-item-three-line, divider, list-item-three-line, divider,
              list-item-two-line, divider, list-item"
              class="mt-2 mr-3"
            />
          </template>
          <template v-else>
            <ApprovalDetails
              :approval="approval"
              @show-map-dialog="(params) => showMapDialog(params)"
            />
          </template>
        </v-col>
        <template v-if="!approvalPageIsSimplified">
          <v-col
            class="approval-column chat elevation-4"
            cols="12"
            md="4"
          >
            <div class="approval-page">
              <AuthorityDecisionsDetails
                v-if="!approvalDetailsLoading"
                :approval="approval"
                :subApprovals="approval.sub_approvals"
              />
              <div
                class="py-3 caption"
                v-translate
              >
                DISCUSSIONS WITH PILOT
              </div>
              <v-card
                outlined
                class="approval-row pa-3"
              >
                <template v-if="approvalEventsLoading">
                  <div class="approval-page justify-space-between">
                    <v-skeleton-loader type="image, image, divider" />
                    <v-skeleton-loader type="list-item-three-line" />
                  </div>
                </template>
                <template v-else>
                  <ApprovalChat
                    :approval="approval"
                    :newMessageDisabled="newMessageDisabled"
                    fullHeight
                  />
                </template>
              </v-card>
            </div>
          </v-col>
          <v-col
            class="approval-column decisions d-flex flex-column"
            cols="12"
            md="4"
          >
            <!-- Map preview -->
            <ApprovalMap
              ref="approvalMap"
              :approval="approval"
              class="pa-2 mb-2"
            />
            <!-- Other authorities decisions -->
            <v-skeleton-loader
              v-if="approvalDetailsLoading"
              light
              type="list-item-avatar, list-item-three-line"
              class="pa-3 overflow-visible"
            />
            <div v-else-if="flightApprovalsIds.length">
              <div
                class="pl-3 pt-3 caption"
                v-translate
              >
                DECISIONS OF OTHER AUTHORITIES
              </div>
              <div
                v-for="(flightApprovalId, index) in flightApprovalsIds"
                :key="index"
              >
                <AuthorityDecision
                  :approvalId="approval.id"
                  :flightApprovalId="flightApprovalId"
                />
              </div>
            </div>
            <!-- Opinions -->
            <v-skeleton-loader
              v-if="loadingOpinions"
              light
              type="list-item-avatar, list-item-three-line"
              class="pa-3 overflow-visible"
            />
            <div v-else-if="opinions.length">
              <div
                class="pl-3 pt-3 caption"
                v-translate
              >
                OTHER OPINIONS REQUESTED
              </div>
              <div
                v-for="(opinion, index) in opinions"
                :key="index"
              >
                <Opinion
                  :opinion="opinion"
                  :newMessageDisabled="newMessageDisabled"
                />
              </div>
            </div>
            <div
              v-if="(
                !flightApprovalsIds.length
                && !opinions.length
                && !loadingOpinions
                && !approvalDetailsLoading
              )"
              class="d-flex flex-column justify-center align-center flex-grow-1"
            >
              <span
                v-translate
                class="body-1"
              >
                No opinion asked for approval
              </span>
              <span
                v-if="isAuthorityAdminOrManager"
                v-translate
                class="caption"
              >
                You can request another opinion by clicking on the button above
              </span>
            </div>
          </v-col>
        </template>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { SET_APPROVAL_SELECTED_NS } from '@/store/approvals';
import { GET_APPROVAL_OPINIONS_NS } from '@/store/opinions';
import { GET_AUTHORITY_STRUCTURES_NS } from '@/store/structures';

import ApprovalChat from '@/components/Approvals/ApprovalChat.vue';
import ApprovalDetails from '@/components/Approvals/ApprovalDetails.vue';
import ApprovalHeader from '@/components/Approvals/ApprovalHeader.vue';
import ApprovalMap from '@/components/Approvals/ApprovalMap.vue';
import AuthorityDecision from '@/components/Approvals/AuthorityDecision.vue';
import AuthorityDecisionsDetails from '@/components/Approvals/AuthorityDecisionsDetails.vue';
import Opinion from '@/components/Opinions/Opinion.vue';

export default {
  name: 'ApprovalPage',
  components: {
    ApprovalChat,
    ApprovalDetails,
    ApprovalHeader,
    ApprovalMap,
    AuthorityDecision,
    AuthorityDecisionsDetails,
    Opinion,
  },
  data() {
    return { loadingSelectApprovalDispatch: true };
  },
  computed: {
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
    isAuthorityController() {
      return this.$store.getters['authentication/isAuthorityController'];
    },
    hasSimplifiedInterface() {
      return this.$store.getters['authentication/hasSimplifiedInterface'];
    },
    approvalPageIsSimplified() {
      return this.hasSimplifiedInterface && this.isAuthorityController;
    },
    approvalLoading() {
      return Object.keys(this.approval).length === 0;
    },
    approvalDetailsLoading() {
      return (
        this.loadingSelectApprovalDispatch
        || this.approvalLoading
        || this.$store.state.approvals.approvalDetailsLoading
        || this.approval.flight === undefined
      );
    },
    approvalEventsLoading() {
      return (
        this.$store.state.approvals.approvalDetailsLoading
        || this.approval.events === undefined
      );
    },
    approval() {
      return this.$store.getters['approvals/approvalSelected'] || {};
    },
    approvalId() {
      return this.approval.id;
    },
    flightApprovalsIds() {
      return this.approval?.flight?.approvals?.filter((id) => id !== this.approvalId) || [];
    },
    loadingOpinions() {
      return this.$store.state.opinions.opinionsCollectionLoading;
    },
    opinions() {
      return this.$store.state.opinions.opinionsCollection;
    },
    newMessageDisabled() {
      return this.approval.is_closed || this.isAuthorityController;
    },
  },
  watch: {
    approvalId(newValue) {
      const currentRouteId = Number(this.$route.params.id);
      if (currentRouteId !== newValue) {
        this.$router.push({
          name: 'approval-page',
          params: { id: newValue },
        });
      }
    },
  },
  async mounted() {
    const id = Number(this.$route.params.id);
    if (id) {
      this.$store.dispatch(SET_APPROVAL_SELECTED_NS, id);
      this.loadingSelectApprovalDispatch = false;
      this.$store.dispatch(GET_APPROVAL_OPINIONS_NS, id);
    }
    await this.getAuthorityStructures();
  },
  methods: {
    async getAuthorityStructures() {
      await this.$store.dispatch(GET_AUTHORITY_STRUCTURES_NS);
    },
    showMapDialog(params) {
      this.$refs.approvalMap.showMapDialog(params);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.details {
  z-index: 2;
  background-color: white;
}

.chat {
  z-index: 1;
  background-color: white;
}

.decisions {
  background-color: #F2F2F2;
  background-clip: padding-box;
}
.approval-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.approval-header {
  flex-grow: 0;
  z-index: 3;
}
.approval-row {
  min-height: 0;
  flex-grow: 1;
}
.approval-column {
  height: 100%;
  overflow-y: scroll;
  padding: 12px;
}

</style>
