<template>
  <div
    :class="[ getStatusClass, { 'mr-2': !isMobileBreakpoint } ]"
    class="flight__status flight__container pa-3"
  >
    <!-- Flight header -->
    <v-row
      align="center"
      class="mb-3"
    >
      <!-- Status icon and name -->
      <v-col
        order="1"
        cols="11"
        sm="6"
        class="d-flex align-center py-1"
      >
        <FlightStatusIcon :status="flight.status" />
        <div class="ml-3 subtitle-2 truncated">
          {{ flight.id }} - {{ flight.name }}
        </div>
      </v-col>
      <!-- ------ Action icons ------ -->
      <v-col
        order="3"
        order-sm="2"
        cols="12"
        sm="5"
        class="py-1"
      >
        <FlightActions
          :flight="flight"
          class="d-flex justify-center justify-sm-end"
          tooltip-bottom
          @weather-flight="$emit('weather-flight', flight)"
          @center-on-flight="$emit('center-on-flight', flight)"
          @edit-flight="$emit('edit-flight', flight)"
          @close-flight="$emit('close-flight', flight)"
          @delete-flights="$emit('delete-flights', flight)"
        />
      </v-col>
      <!-- ------ Close ------ -->
      <v-col
        order="2"
        order-sm="3"
        cols="1"
        class="py-1"
      >
        <div
          class="d-flex justify-end"
          :class="{ 'pr-4': !isMobileBreakpoint }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                @click="close()"
                color="white"
                v-on="on"
              >
                mdi-close
              </v-icon>
            </template>
            <span v-translate>Close</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="selectedFlightLoading"
      type="list-item-two-line"
    />
    <template v-else>
      <!-- Details -->
      <FlightDetailsSimplified :flight="flight" />
      <!-- Documents -->
      <FlightDocumentsSimplified :flightId="flight.id" />
    </template>
    <!-- Loading constraints -->
    <div
      v-if="!isFlightAnalysisLoaded"
      class="light flight-constraints__loader"
    >
      <v-btn
        text
        loading
        color="white"
      />
      <span v-translate>Constraints loading...</span>
    </div>
    <!-- Analysis processing -->
    <template v-else-if="!flight.asp_was_analyzed">
      <v-alert
        key="constraints_not_analyzed"
        type="info"
        color="grey lighten-3"
        class="mt-2 mb-0 py-2 grey--text text--darken-2 caption"
      >
        <span v-translate>Constraints are analysing, please reload later</span>
      </v-alert>
    </template>
    <template v-else>
      <!-- Impacting constraints -->
      <FlightConstraintsList
        :flight="flight"
        category="impacting"
        :constraints="getAnalysisImpactingConstraints"
        class="mt-3"
      />
      <!-- Other constraints (impacting) -->
      <template v-if="getAnalysisOtherConstraints.length">
        <v-card flat>
          <v-alert
            class="mt-4 mb-2"
            type="error"
            icon="mdi-alert-circle"
            text
          >
            <div class="info--text">
              {{ otherImpactingConstraintsAlert }}
            </div>
          </v-alert>
        </v-card>
        <FlightConstraintsList
          v-if="getAnalysisOtherConstraints.length"
          :flight="flight"
          category="other_impacting"
          :constraints="getAnalysisOtherConstraints"
        />
      </template>
    </template>
  </div>
</template>
<script>
import { CONSTRAINTS_CATEGORY } from '@/settings';

import { SET_FLIGHT_SELECTED_NS } from '@/store/flights';

import FlightActions from '@/components/Flights/FlightActions.vue';
import FlightConstraintsList from '@/components/Flights/FlightConstraintsList.vue';
import FlightDetailsSimplified from '@/components/Flights/FlightDetailsSimplified.vue';
import FlightStatusIcon from '@/components/StatusIcon/FlightStatusIcon.vue';
import FlightDocumentsSimplified from '@/components/Flights/Documents/FlightDocumentsSimplified.vue';

export default {
  name: 'FlightSimplified',
  components: {
    FlightActions,
    FlightConstraintsList,
    FlightDetailsSimplified,
    FlightStatusIcon,
    FlightDocumentsSimplified,
  },
  props: { flight: Object },
  data() {
    return {
      generalistConstraintsExpanded: 0,
      otherConstraintsExpanded: null,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    display() {
      return this.$store.state.application.display;
    },
    getConstraintsAnalysis() {
      return this.flight.constraintsAnalysis || [];
    },
    getAnalysisImpactingConstraints() {
      return this.getConstraintsAnalysis.filter(
        (constraint) => constraint.category === CONSTRAINTS_CATEGORY.IMPACTING,
      );
    },
    getAnalysisOtherConstraints() {
      return this.getConstraintsAnalysis.filter(
        (constraint) => constraint.category === CONSTRAINTS_CATEGORY.OTHER_IMPACTING,
      );
    },
    getUIStatus() {
      const { is_archived: isArchived, status } = this.flight;
      return isArchived ? 'archived' : status;
    },
    getStatusClass() {
      return { [this.getUIStatus]: true };
    },
    selectedFlightLoading() {
      return this.$store.state.flights.isSelectedFlightDetailsLoading;
    },
    isFlightAnalysisLoaded() {
      return this.$store.state.flights.isSelectedFlightConstraintsAnalysisLoaded;
    },
    otherImpactingConstraintsAlert() {
      return this.$gettext(`Please note that this mission contains red infrastructures that cannot
        be flown over.`);
    },
  },
  watch: {
    display(newValue) {
      if (newValue === 'SMALL') {
        this.close();
      }
    },
  },
  methods: {
    close() {
      if (this.$route.query.idSelected !== undefined) {
        this.$router.replace({ query: null });
      }
      this.$store.dispatch(SET_FLIGHT_SELECTED_NS, { flightId: null, reload: true });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight {
  background-color: $color-primary;
  &__status {
    color: $color-white;
    &.planned {
      background-color: $flight-planned-background-color;
    }
    &.processing {
      background-color: $flight-processing-background-color;
    }
    &.canceled {
      background-color: $flight-canceled-background-color;
    }
    &.error {
      background-color: $flight-error-background-color;
    }
    &.action_needed {
      background-color: $flight-action_needed-background-color;
    }
    &.archived {
      background-color: $flight-archived-background-color;
    }
    &.done {
      background-color: $flight-archived-background-color;
    }
    &.confirmed {
      background-color: $flight-confirmed-background-color;
    }
    &.clear {
      background-color: $flight-clear-background-color;
    }
    &.reserves {
      background-color: $flight-reserves-background-color;
    }
    &.refused {
      background-color: $flight-refused-background-color;
    }
  }
  &__container {
    min-height: 100%;
    padding-bottom: 32px;
  }
}
.v-btn {
  min-width: 0;
}
.flight-constraints__loader {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

</style>
