<template>
  <v-card class="default--dialog__card">
    <!-- Title  -->
    <v-card-title>
      <span>
        <div>
          <v-icon
            color="primary"
            class="pr-2"
          >
            mdi-stop-circle-outline
          </v-icon>
          <translate>Stop livestream</translate>
        </div>
      </span>
      <v-icon @click="close()">
        close
      </v-icon>
    </v-card-title>
    <!-- Text -->
    <v-card-text v-if="livestream">
      <translate>
        You can stop your live on your application and this live will be stopped too.
      </translate>
      <br>
      <span>
        {{ texts.changeManually }}
      </span>
      <br>
      <br>
      <span>
        {{ texts.livestreamStop}}
      </span>
    </v-card-text>
    <!-- Actions  -->
    <v-card-actions>
      <v-btn
        @click="stopLivestream()"
        :loading="livestreamUpdateLoading"
        color="primary"
      >
        <translate>Validate</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import {
  ADD_OR_UPDATE_LIVESTREAM_NS,
  SET_LIVESTREAM_TO_STOP_NS,
} from '@/store/livestreams';

export default {
  name: 'LivestreamStop',
  data() {
    return { livestreamUpdateLoading: false };
  },
  computed: {
    livestream() {
      return this.$store.getters['livestreams/livestreamToStop'];
    },
    texts() {
      return {
        changeManually: this.$gettext(`If you are really sure to have finished your live but it is
          not shown as ended on our platform, you can use this option to change manually the status
          of the live to end.`),
        livestreamStop: this.$gettextInterpolate(
          this.$gettext("Are you sure to delete the livestream '%{label}'?"),
          { label: this.livestream.label },
          true,
        ),
      };
    },
  },
  methods: {
    stopLivestream() {
      this.livestreamUpdateLoading = true;
      const formData = new FormData();
      formData.append('status', 'ended');
      APIService.updateLivestream(this.livestream.id, formData)
        .then(({ data }) => {
          this.showMessage(this.$gettext('Livestream was stopped successfully'), 'success');
          this.close();
          this.$store.dispatch(ADD_OR_UPDATE_LIVESTREAM_NS, { livestream: data });
        })
        .finally(() => {
          this.livestreamUpdateLoading = false;
        });
    },
    close() {
      this.$store.commit(SET_LIVESTREAM_TO_STOP_NS, undefined);
    },
  },
};
</script>
