import Vue from 'vue';

const FOOT = 0.3048;
const filterMeterAndFeet = (value) => {
  if (value === undefined || value === null) return '';
  return `${value} m - ${Math.round(value / FOOT)} ft`;
};

const filterMeter = (value) => {
  if (value === undefined || value === null) return '';
  return `${value} m`;
};

const filterScaleMeter = (value) => {
  if (value === undefined || value === null) return '';
  if (value / 1000 > 10) {
    const square = Math.round(value / 1000).toFixed(1);
    return `${square} km`;
  }
  return `${value} m`;
};

const filterSquareMeter = (value) => {
  if (value === undefined || value === null) return '';
  if (value / 10000 > 10) {
    const square = (value / 1000000).toFixed(1);
    return `${square} km²`;
  }
  return `${value} m²`;
};

// eslint-disable-next-line import/prefer-default-export
export const filterFeet = (value) => `${Math.round(value / FOOT)}`;

Vue.filter('meter', filterMeter);
Vue.filter('meterAndFeet', filterMeterAndFeet);
Vue.filter('scaleMeter', filterScaleMeter);
Vue.filter('squareMeter', filterSquareMeter);
Vue.filter('filterFeet', filterFeet);
