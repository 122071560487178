<template>
  <v-card class="default--dialog__card">
    <!-- Title -->
    <v-card-title>
      <div>
        <translate
          v-if="isUpdateForm"
          key="update"
        >
          Update flight incident
        </translate>
        <translate
          v-else
          key="create"
        >
          Create flight incident
        </translate>
      </div>
    </v-card-title>
    <!-- Form -->
    <v-card-text>
      <!-- Announcement -->
      <v-alert
        v-if="flightIncidentAnnouncementText"
        color="primary"
        icon="mdi-information-outline"
        outlined
        border="left"
        class="mt-2 font-weight-medium"
      >
        <vue-markdown
          :source="flightIncidentAnnouncementText"
          :html="false"
          xhtml-out
          typographer
          linkify
          breaks
          show
          :emoji="false"
          :anchorAttributes="{ target: '_blank' }"
          class="markdown"
        />
      </v-alert>
      <v-form
        ref="form"
        v-model="formValid"
        lazy-validation
        class="pa-2"
      >
        <!-- Title -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.title }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-text-field
              v-model="incident.title"
              :placeholder="placeholders.title"
              :rules="[rules.required]"
              single-line
              class="pa-0"
            />
          </v-col>
        </v-row>
        <!-- Date -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.date }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              content-class="white text-center"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :value="incident.date | date"
                  :placeholder="placeholders.date"
                  :rules="[rules.required]"
                  append-icon="arrow_drop_down"
                  class="pa-0"
                  readonly
                />
              </template>
              <v-date-picker
                v-model="incident.date"
                :max="today"
                no-title
                scrollable
                locale="fr"
                first-day-of-week="1"
                @input="dateMenu=false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <!-- Location -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.location }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-text-field
              v-model="incident.location"
              :placeholder="placeholders.location"
              :rules="[rules.required]"
              single-line
              class="pa-0"
            />
          </v-col>
        </v-row>
        <!-- Mission -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.flight }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-autocomplete
              v-model="incident.flightId"
              :items="flights"
              :loading="flightsLoading"
              :placeholder="placeholders.flight"
              item-value="id"
              :item-text="getFlightText"
              :search-input.sync="flightSearch"
              :no-data-text="placeholders.emptyResults"
              clearable
              class="pa-0"
            />
          </v-col>
        </v-row>
        <!-- Drone selection -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.drone }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-row
              no-gutters
              align="center"
            >
              <v-col
                cols="12"
                sm="6"
                class="pr-sm-4"
              >
                <v-autocomplete
                  v-model="droneSelected"
                  :items="drones"
                  :loading="dronesLoading"
                  :placeholder="placeholders.drone"
                  return-object
                  :item-text="getDroneText"
                  :search-input.sync="droneSearch"
                  :no-data-text="placeholders.emptyResults"
                  clearable
                  @change="updateDroneInfo()"
                  class="pa-0"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pl-sm-4"
              >
                <v-text-field
                  v-model="incident.droneIdentifier"
                  :placeholder="placeholders.droneIdentifier"
                  :rules="[rules.required]"
                  :hint="hints.droneIdentifier"
                  persistent-hint
                  single-line
                  class="pa-0"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Current entity -->
        <v-row
          v-if="hasManySubExploitants"
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.subExploitants }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-autocomplete
              v-model="incident.subExploitantsIds"
              :items="subExploitants"
              :loading="subExploitantsLoading"
              :placeholder="placeholders.subExploitants"
              item-text="name"
              item-value="id"
              :no-data-text="placeholders.emptyResults"
              multiple
              class="p-0"
            />
          </v-col>
        </v-row>
        <!-- Description -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.description }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-textarea
              v-model="incident.description"
              :placeholder="placeholders.description"
              :rules="[rules.required]"
              :hint="hints.description"
              persistent-hint
              rows="3"
              class="pa-0"
            />
          </v-col>
        </v-row>
        <!-- Weather -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.weather }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-textarea
              v-model="incident.weather"
              :placeholder="placeholders.weather"
              :rules="[rules.required]"
              :hint="hints.weather"
              persistent-hint
              rows="3"
              class="pa-0"
            />
          </v-col>
        </v-row>
        <!-- Consequence -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.consequence }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-select
              :items="consequenceCategories"
              v-model="incident.consequence"
              :label="labels.documentCategory"
              item-text="label"
              item-value="value"
              :placeholder="placeholders.consequence"
              :rules="[rules.required]"
              persistent-hint
              class="pa-0"
            />
          </v-col>
        </v-row>
        <!-- Immediate measures -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.immediateMeasure }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-textarea
              v-model="incident.immediateMeasure"
              :placeholder="placeholders.immediateMeasure"
              :rules="[rules.required]"
              :hint="hints.immediateMeasure"
              persistent-hint
              rows="3"
              class="pa-0"
            />
          </v-col>
        </v-row>
        <!-- Attachments -->
        <v-row
          no-gutters
          align="center"
          class="py-2"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span class="primary--form__label">
              {{ labels.attachments }}
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-input
              class="v-text-field pa-0"
              :value="incident.attachments"
              :rules="[rules.attachmentsNumber, rules.attachmentsSize]"
            >
              <div class="flex-grow-1">
                <template v-if="!incident.attachments || !incident.attachments.length">
                  <span class="v-label text--disabled">
                    {{ placeholders.attachments }}
                  </span>
                </template>
                <template v-else>
                  <v-chip
                    v-for="(file, index) in incident.attachments"
                    :key="index"
                    close-icon="mdi-close"
                    @click:close="removeAttachment(index)"
                    class="ma-1"
                    small
                    close
                  >
                    {{ file.name }}
                  </v-chip>
                </template>
              </div>
              <template slot:prepend>
                <div class="pb-1">
                  <v-file-input
                    v-model="inputAttachments"
                    class="ma-0 pa-0"
                    hide-input
                    multiple
                  />
                </div>
              </template>
            </v-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- Actions -->
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="confirm()"
        :disabled="!formValid"
        :loading="confirmLoading"
      >
        <translate>Confirm</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { format } from 'date-fns';
import VueMarkdown from 'vue-markdown';

import APIService from '@/services/api';
import { debounce } from '@/services/api.helper';

import {
  FLIGHT_INCIDENT_CONSEQUENCES,
  MAX_DOCUMENT_SIZE_MB,
  MAX_NUMBER_OF_DOCUMENTS,
} from '@/settings';
import { GET_ALL_SUB_EXPLOITANTS_NS } from '@/store/exploitants';

export default {
  name: 'FlightIncidentForm',
  components: { VueMarkdown },
  props: {
    incidentToUpdate: {
      type: Object,
      required: false,
    },
    flightIncidentAnnouncementText: String,
  },
  data() {
    const maxDocs = MAX_NUMBER_OF_DOCUMENTS.flight_incident;
    const maxDocsSize = MAX_DOCUMENT_SIZE_MB.flight_incident;
    return {
      formValid: false,
      incident: {
        title: '',
        date: '',
        location: '',
        flightId: null,
        droneIdentifier: '',
        subExploitantsIds: [],
        description: '',
        weather: '',
        consequence: '',
        immediateMeasure: '',
        attachments: [],
      },
      consequenceCategories: FLIGHT_INCIDENT_CONSEQUENCES,
      dateMenu: false,
      today: format(new Date(), 'yyyy-MM-dd'),
      flights: [],
      flightsLoading: false,
      flightSearch: undefined,
      droneSelected: undefined,
      drones: [],
      dronesLoading: false,
      droneSearch: undefined,
      confirmLoading: false,
      rules: {
        required: (value) => !!value || this.$gettext('This field is required.'),
        attachmentsNumber: (value) => (
          value.length <= maxDocs || this.$gettextInterpolate(
            this.$gettext('Attachments are limited to %{ n } documents.'), { n: this.maxDocs },
          )
        ),
        attachmentsSize: (value) => (
          value.filter((d) => !d.id).every((doc) => doc.size < maxDocsSize * 1000000)
          || this.$gettextInterpolate(
            this.$gettext('Each attachment size should not exceed %{ s } MB.'), { s: maxDocsSize },
          )
        ),
      },
    };
  },
  computed: {
    isUpdateForm() {
      return !!this.incidentToUpdate;
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
    hasSubExploitants() {
      return this.$store.getters['exploitants/hasSubExploitants'];
    },
    hasManySubExploitants() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    isExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    isSubExploitantManager() {
      return this.$store.getters['authentication/isUserExploitantManager'];
    },
    allSubExploitants() {
      return this.$store.state.exploitants.allSubExploitants;
    },
    userSubExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    subExploitants() {
      if (this.isExploitantAdmin) {
        return this.allSubExploitants;
      }
      return this.userSubExploitants;
    },
    subExploitantsLoading() {
      return this.$store.state.exploitants.allSubExploitantsLoading;
    },
    labels() {
      return {
        title: this.$gettext('Title'),
        date: this.$gettext('Date'),
        location: this.$gettext('Location'),
        flight: this.$gettext('Mission'),
        drone: this.$gettext('Drone'),
        subExploitants: this.entityName.plural,
        description: this.$gettext('Description'),
        weather: this.$gettext('Weather flight condition'),
        consequence: this.$gettext('Consequence'),
        immediateMeasure: this.$gettext('Immediate measure'),
        attachments: this.$gettext('attachments'),
      };
    },
    placeholders() {
      return {
        title: this.$gettext('Title of the incident'),
        date: this.$gettext('Date of the incident'),
        location: this.$gettext('Location of the incident (address/worksite)'),
        flight: this.$gettext('Mission linked to the incident'),
        drone: this.$gettext('Drone linked to the incident'),
        droneIdentifier: this.$gettext('Drone identifier'),
        subExploitants: this.$gettextInterpolate(
          this.$gettext('%{entityName} linked to the incident.'),
          { entityName: this.entityName.plural },
        ),
        description: this.$gettext('Description for incident'),
        weather: this.$gettext('Description of weather conditions at the event'),
        consequence: this.$gettext('Consequence of incident'),
        immediateMeasure: this.$gettext('Immediate measure for the incident'),
        attachments: this.$gettext('Pictures or documents to link to the incident'),
        emptyResults: this.$gettext('No result'),
      };
    },
    hints() {
      return {
        droneIdentifier: this.$gettext(`Drone serial number and registration linked to the
          incident`),
        description: this.$gettext(`Description of the events, type of activity, chronological
          sequence, description of the environment, description of the resources used, description
          of the personal protective equipment used, etc`),
        weather: this.$gettext('Wind (km/h), rainfall (mm), temperature'),
        immediateMeasure: this.$gettext(`Curative actions, communication, services contacted,
          decisions, etc`),
      };
    },
    inputAttachments: {
      get() {
        return this.incident.attachments;
      },
      set(newValue) {
        this.incident.attachments = this.incident.attachments.concat(newValue);
      },
    },
  },
  watch: {
    flightSearch(value) {
      if (value !== this.getFlightTextById()) {
        this.flightsLoading = true;
        this.debouncedSearchFlights({ that: this });
      }
    },
    droneSearch(value) {
      if (value !== this.droneSelected?.label) {
        this.dronesLoading = true;
        this.debouncedSearchDrones({ that: this });
      }
    },
  },
  created() {
    if (this.incidentToUpdate) {
      this.incident = {
        title: this.incidentToUpdate.title,
        date: this.incidentToUpdate.incident_date,
        location: this.incidentToUpdate.location,
        flightId: this.incidentToUpdate.flight_id,
        droneIdentifier: this.incidentToUpdate.drone_identifier,
        subExploitantsIds: [...this.incidentToUpdate.sub_exploitants_ids],
        description: this.incidentToUpdate.description,
        weather: this.incidentToUpdate.weather,
        consequence: this.incidentToUpdate.consequence,
        immediateMeasure: this.incidentToUpdate.immediate_measure,
        attachments: [...this.incidentToUpdate.attachments],
      };
    }
    if (this.hasSubExploitants) {
      if (this.isExploitantAdmin) {
        this.$store.dispatch(GET_ALL_SUB_EXPLOITANTS_NS);
      }
      if (!this.hasManySubExploitants && !this.incidentToUpdate) {
        this.incident.subExploitantsIds = [this.userSubExploitants[0].id];
      }
    }
    this.searchFlights();
  },
  methods: {
    buildPayload() {
      const payload = {
        title: this.incident.title,
        incident_date: this.incident.date,
        location: this.incident.location,
        drone_identifier: this.incident.droneIdentifier,
        sub_exploitants_ids: this.incident.subExploitantsIds,
        description: this.incident.description,
        weather: this.incident.weather,
        consequence: this.incident.consequence,
        immediate_measure: this.incident.immediateMeasure,
      };
      if (this.incident.flightId) {
        payload.flight_id = this.incident.flightId;
      }
      if (this.isUpdateForm) {
        const updateAttachments = this.incident.attachments.filter((a) => a.id);
        if (updateAttachments) {
          payload.attachments_ids = updateAttachments.map((a) => a.id);
        } else {
          payload.attachments_ids = [];
        }
      }
      return payload;
    },
    confirm() {
      if (!this.$refs.form.validate()) return;
      this.confirmLoading = true;
      const payload = this.buildPayload();
      const newFiles = this.incident.attachments.filter((a) => !a.id);
      let action;
      if (this.isUpdateForm) {
        action = this.updateFlightIncident(payload, newFiles);
      } else {
        action = this.createFlightIncident(payload, newFiles);
      }
      action
        .then(() => {
          this.$emit('reload');
          this.$emit('close');
        })
        .finally(() => { this.confirmLoading = false; });
    },
    createFlightIncident(payload, files) {
      return APIService.createFlightIncident(payload, files)
        .then(() => {
          this.showMessage(this.$gettext('Flight incident created successfully.'), 'success');
        });
    },
    updateFlightIncident(payload, files) {
      return APIService.updateFlightIncident(this.incidentToUpdate.id, payload, files)
        .then(() => {
          this.showMessage(this.$gettext('Flight incident updated successfully.'), 'success');
        });
    },
    getFlightTextById() {
      const flight = this.flights.find((f) => f.id === this.incident.flightId);
      return this.getFlightText(flight);
    },
    getFlightText(flight) {
      return flight ? `${flight.id} - ${flight.name}` : '';
    },
    debouncedSearchFlights: debounce(
      ({ that }) => that.searchFlights(),
      1000, // debounce for 1s
    ),
    searchFlights() {
      APIService.searchFlights({ q: this.flightSearch })
        .then(({ data }) => {
          if (data) {
            this.flights = data.results.map((flight) => ({
              id: flight.id,
              name: flight.name,
            }));
          }
        })
        .finally(() => {
          this.flightsLoading = false;
        });
    },
    getDroneText(drone) {
      return drone?.label || '';
    },
    debouncedSearchDrones: debounce(
      ({ that }) => that.searchDrones(),
      1000, // debounce for 1s
    ),
    searchDrones() {
      APIService.searchDrones({ q: this.droneSearch })
        .then(({ data }) => {
          this.drones = data.results.map((drone) => ({
            label: drone.label,
            serialNumber: drone.serial_number,
            alphaTangoUID: drone.alpha_tango_id,
          }));
        })
        .finally(() => {
          this.dronesLoading = false;
        });
    },
    updateDroneInfo() {
      let droneIdentifier = this.droneSelected?.serialNumber || '';
      if (this.droneSelected?.alphaTangoUID) {
        if (droneIdentifier.length) droneIdentifier += ' - ';
        droneIdentifier += `UAS-FR-${this.droneSelected.alphaTangoUID}`;
      }
      this.incident.droneIdentifier = droneIdentifier;
    },
    removeAttachment(index) {
      this.incident.attachments.splice(index, 1);
    },
  },
};
</script>
