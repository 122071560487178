import Vue from 'vue';

import APIService from '@/services/api';

// eslint-disable-next-line import/no-cycle
import { getPageFromNextPreviousURL } from '@/store/application';
// eslint-disable-next-line import/no-cycle
import {
  SELECTABLE_FEATURE_KEYS,
  SET_MAP_FEATURE_HOVERED_NS,
  SET_MAP_FEATURE_SELECTED_NS,
} from '@/store/map';

const NOT_FOUND_ERROR = 'NOT FOUND';

const namespace = 'opinions';

const GET_OPINIONS_GEOMETRY = 'GET_OPINIONS_GEOMETRY';
const GET_OPINIONS = 'GET_OPINIONS';
const GET_OPINION_DETAILS = 'GET_OPINION_DETAILS';
const SET_OPINION_DETAILS = 'SET_OPINION_DETAILS';
const GET_APPROVAL_OPINIONS = 'GET_APPROVAL_OPINIONS';
const SET_OPINIONS_COLLECTION = 'SET_OPINIONS_COLLECTION';
const SET_OPINIONS_COLLECTION_LOADING = 'SET_OPINIONS_COLLECTION_LOADING';
const SET_OPINIONS_COLLECTION_LOADED = 'SET_OPINIONS_COLLECTION_LOADED';
const ADD_OPINION = 'ADD_OPINION';
const SET_OPINION_SELECTED = 'SET_OPINION_SELECTED';
const SET_OPINION_HOVER = 'SET_OPINION_HOVER';
const SET_FILTERS = 'SET_FILTERS';
const RESET_FILTERS = 'RESET_FILTERS';
const SET_PAGINATION = 'SET_PAGINATION';
const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
const SET_TABLE_ITEMS = 'SET_TABLE_ITEMS';
const SET_TABLE_ITEMS_LOADING = 'SET_TABLE_ITEMS_LOADING';
const SET_OPINION_DETAILS_LOADING = 'SET_OPINION_DETAILS_LOADING';

export const GET_OPINIONS_NS = `${namespace}/${GET_OPINIONS}`;
export const GET_OPINION_DETAILS_NS = `${namespace}/${GET_OPINION_DETAILS}`;
export const GET_APPROVAL_OPINIONS_NS = `${namespace}/${GET_APPROVAL_OPINIONS}`;
export const ADD_OPINION_NS = `${namespace}/${ADD_OPINION}`;
export const SET_OPINION_SELECTED_NS = `${namespace}/${SET_OPINION_SELECTED}`;
export const SET_OPINION_HOVER_NS = `${namespace}/${SET_OPINION_HOVER}`;
export const SET_FILTERS_NS = `${namespace}/${SET_FILTERS}`;
export const RESET_FILTERS_NS = `${namespace}/${RESET_FILTERS}`;
export const SET_PAGINATION_NS = `${namespace}/${SET_PAGINATION}`;

function updateOpinion(state, { opinionId, properties }) {
  const index = state.opinionsCollection.findIndex((f) => f.id === opinionId);
  const feature = { ...state.opinionsCollection[index] };
  const updatedProperties = { ...feature, ...properties };
  Vue.set(state.opinionsCollection, index, updatedProperties);
}

const defaultFilters = {
  q: null,
  dateFrom: '',
  dateTo: '',
  isClosed: 'False',
};

function initialState() {
  return {
    filters: { ...defaultFilters },
    pagination: {
      itemsPerPage: 25,
      page: 1,
      sortBy: ['-identifier'],
      sortDesc: [false],
    },
    opinionsCollection: [],
    opinionsCollectionLoading: false,
    opinionsCollectionLoaded: false,
    itemsLength: 0,
    tableIds: [],
    tableItemsLoading: false,
    opinionDetailsLoading: false,
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    tableItems: (state) => {
      const tableItems = [];
      state.opinionsCollection.forEach((opinion) => {
        const index = state.tableIds.indexOf(opinion.id);
        if (index !== -1) {
          tableItems[index] = opinion;
        }
      });
      return tableItems;
    },
    mapCenters: (state, getters, rootState, rootGetters) => {
      if (rootGetters['authentication/isUserVisitor']) {
        let features = state.opinionsCollection.map((opinion) => {
          const { approval, center: geometry, id, status } = opinion;
          const {
            display_identifier: identifier,
            company_name: companyName,
            is_closed: isClosed,
          } = approval;
          return {
            type: 'Feature',
            id,
            properties: {
              status,
              company_name: companyName,
              display_identifier: identifier,
              is_closed: isClosed,
            },
            geometry,
          };
        });
        if (getters.opinionSelected) {
          features = features.filter((f) => f.id !== getters.opinionSelected.id);
        }
        return { type: 'FeatureCollection', features };
      }
      return null;
    },
    mapAreas: (state, getters, rootState, rootGetters) => {
      if (rootGetters['authentication/isUserVisitor']) {
        let features = state.opinionsCollection.map((opinion) => {
          const { approval, geometry, id, status } = opinion;
          const {
            display_identifier: identifier,
            company_name: companyName,
            is_closed: isClosed,
          } = approval;
          return {
            type: 'Feature',
            id,
            properties: {
              status,
              display_identifier: identifier,
              company_name: companyName,
              is_closed: isClosed,
            },
            geometry,
          };
        });
        if (getters.opinionSelected) {
          features = features.filter((f) => f.id !== getters.opinionSelected.id);
        }
        return { type: 'FeatureCollection', features };
      }
      return null;
    },
    opinionSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters['authentication/isUserVisitor']) {
        return state.opinionsCollection.find(
          (opinion) => opinion.id === rootState.map.featureIdSelected.flight,
        );
      }
      return null;
    },
    opinionArea: (state, getters) => {
      const features = [];
      const { opinionSelected } = getters;
      if (opinionSelected) {
        const { approval, geometry, id, status } = opinionSelected;
        const {
          display_identifier: identifier,
          company_name: companyName,
          is_closed: isClosed,
        } = approval;
        features.push({
          type: 'Feature',
          id,
          properties: {
            status,
            display_identifier: identifier,
            company_name: companyName,
            is_closed: isClosed,
          },
          geometry,
        });
      }
      return { type: 'FeatureCollection', features };
    },
    activeFilters: (state) => {
      let activeFilters = 0;
      const { filters } = state;
      Object.keys(defaultFilters).forEach((key) => {
        if (filters[key] !== defaultFilters[key]) {
          activeFilters += 1;
        }
      });
      return activeFilters;
    },
  },
  mutations: {
    [SET_TABLE_ITEMS_LOADING](state, loading) {
      Vue.set(state, 'tableItemsLoading', loading);
    },
    [SET_OPINIONS_COLLECTION_LOADING](state, loading) {
      Vue.set(state, 'opinionsCollectionLoading', loading);
    },
    [SET_OPINIONS_COLLECTION_LOADED](state) {
      Vue.set(state, 'opinionsCollectionLoaded', true);
    },
    [SET_OPINION_DETAILS_LOADING](state) {
      Vue.set(state, 'opinionDetailsLoading', true);
    },
    [SET_OPINIONS_COLLECTION](state, opinionsCollection = []) {
      Vue.set(state, 'opinionsCollection', opinionsCollection);
    },
    [ADD_OPINION](state, opinion) {
      const collection = state.opinionsCollection;
      collection.unshift(opinion);
      Vue.set(state, 'opinionsCollection', collection);
    },
    [SET_OPINION_DETAILS](state, { opinion, opinionId }) {
      updateOpinion(state, { opinionId, properties: opinion });
      Vue.set(state, 'opinionDetailsLoading', false);
    },
    [SET_FILTERS](state, filters) {
      Vue.set(state, 'filters', { ...state.filters, ...filters });
    },
    [RESET_FILTERS](state) {
      Vue.set(state, 'filters', { ...defaultFilters });
    },
    [SET_PAGINATION](state, payload) {
      Vue.set(state, 'pagination', payload.pagination);
    },
    [UPDATE_PAGINATION](state, { itemsLength, page }) {
      if (itemsLength !== undefined) {
        Vue.set(state, 'itemsLength', itemsLength);
      }
      if (page !== undefined) {
        Vue.set(state.pagination, 'page', page);
      }
    },
    [SET_TABLE_ITEMS](state, { items = [] }) {
      items.forEach((item) => {
        updateOpinion(state, { opinionId: item.id, properties: { ...item } });
      });
      Vue.set(state, 'tableIds', items.map((i) => i.id));
      Vue.set(state, 'tableItemsLoading', false);
    },
  },
  actions: {
    async [GET_OPINIONS_GEOMETRY]({ commit, dispatch, getters, state }) {
      commit(SET_OPINIONS_COLLECTION_LOADING, true);
      const { sortBy, sortDesc } = state.pagination;
      const { filters } = state;

      const opinionParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        ...filters,
      };
      await APIService.getOpinionsGeometries({ ...opinionParams })
        .then(({ data }) => {
          commit(SET_OPINIONS_COLLECTION, data.results);
          const { mapAreas } = getters;
          if (mapAreas.features.length) {
            dispatch('map/SET_BOUNDING_BOX', { fromGeom: getters.mapAreas }, { root: true });
          }
          commit(SET_OPINIONS_COLLECTION_LOADED);
        })
        .finally(() => {
          commit(SET_OPINIONS_COLLECTION_LOADING, false);
        });
    },
    async [GET_OPINIONS]({ commit, dispatch, state }, { withId, reloadGeometries = true }) {
      commit(SET_TABLE_ITEMS_LOADING, true);
      if (reloadGeometries || !state.opinionsCollectionLoaded) {
        await dispatch(GET_OPINIONS_GEOMETRY);
      }
      const { itemsPerPage, sortBy, sortDesc, page } = state.pagination;
      const { filters } = state;

      const opinionParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        limit: itemsPerPage,
        ...filters,
      };
      if (withId) {
        opinionParams.withId = withId;
      } else {
        opinionParams.offset = (page - 1) * itemsPerPage;
      }
      await APIService.getOpinions({ ...opinionParams })
        .then(({ data }) => {
          const { count: itemsLength, results: items } = data;
          let newPage;
          if (withId) {
            if (!items.find((opinion) => opinion.id === withId)) {
              throw new Error(NOT_FOUND_ERROR);
            }
            // Find new page where the flight with the given id is
            newPage = getPageFromNextPreviousURL(
              data.next,
              data.previous,
              itemsLength,
              itemsPerPage,
            );
          }
          commit(SET_TABLE_ITEMS, { items });
          commit(UPDATE_PAGINATION, { itemsLength, page: newPage });
        })
        .finally(() => {
          commit(SET_TABLE_ITEMS_LOADING, false);
        });
    },
    async [GET_APPROVAL_OPINIONS]({ commit }, approvalId) {
      commit(SET_OPINIONS_COLLECTION_LOADING, true);
      await APIService.getApprovalOpinions(approvalId)
        .then(({ data }) => {
          commit(SET_OPINIONS_COLLECTION, data);
        })
        .finally(() => {
          commit(SET_OPINIONS_COLLECTION_LOADING, false);
        });
    },
    [ADD_OPINION]({ commit }, opinion) {
      commit(ADD_OPINION, opinion);
    },
    [SET_OPINION_SELECTED]({ dispatch }, opinionId) {
      dispatch(
        SET_MAP_FEATURE_SELECTED_NS,
        { featureId: opinionId, key: SELECTABLE_FEATURE_KEYS.flight },
        { root: true },
      );
      if (opinionId) {
        dispatch(GET_OPINION_DETAILS, opinionId);
      }
    },
    [SET_OPINION_HOVER]({ dispatch }, opinionId) {
      dispatch(
        SET_MAP_FEATURE_HOVERED_NS,
        { featureId: opinionId, key: SELECTABLE_FEATURE_KEYS.flight },
        { root: true },
      );
    },
    async [GET_OPINION_DETAILS]({ commit, dispatch, state }, opinionId) {
      commit(SET_OPINION_DETAILS_LOADING);
      if (!state.tableIds.some((id) => id === opinionId)) {
        await dispatch(GET_OPINIONS, { withId: opinionId, reloadGeometries: false });
      }
      await APIService.getOpinionDetails(opinionId)
        .then(({ data }) => {
          commit(SET_OPINION_DETAILS, { opinion: data, opinionId });
        });
    },
    [SET_PAGINATION]({ commit, dispatch }, pagination) {
      dispatch(SET_OPINION_SELECTED, null);
      commit(SET_PAGINATION, { pagination });
      dispatch(GET_OPINIONS, { reloadGeometries: false });
    },
  },
};
