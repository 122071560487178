<template>
  <v-expansion-panels
    v-model="panelExpanded"
    light
    class="dense__expansion-panel"
  >
    <v-expansion-panel
      expand-icon="keyboard_arrow_down"
      :class="highlighted"
    >
      <v-expansion-panel-header>
        <div
          @mouseenter="notamHover=true"
          @mouseleave="notamHover=false"
          class="dense__expansion-panel__header-content d-flex align-center justify-space-between"
        >
          <div class="d-flex align-center">
            <v-icon
              :class="getClass"
              size="24px"
            >
              icon-alert_notam
            </v-icon>
            <span class="ml-2 secondary--text">
              {{ notam.title }}
            </span>
          </div>
          <span
            v-if="displayCategory"
            class="flex-shrink-1 primary--text mr-1"
          >
            <span
              v-if="notam.category === 'important'"
              v-translate
            >
              Important
            </span>
            <span
              v-else
              v-translate
            >
              Unclassified
            </span>
          </span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-3">
        <!-- DETAILS -->
        <div class="px-4">
          <span
            class="caption font-weight-bold primary--text"
            v-translate
          >
            NOTAM CONTENT
          </span>
          <div v-html="formatNotamContent()" />
          <div>
            <span
              v-if="notam.min_alt !== 0"
              v-translate="{
                  minAlt: notam.min_alt.toFixed(0),
                  maxAlt: notam.max_alt.toFixed(0),
                }"
            >
              This constraint applies from %{minAlt} m to %{maxAlt} m.
            </span>
            <span
              v-else
              v-translate="{maxAlt: notam.max_alt.toFixed(0)}"
            >
              This constraint applies from the ground up to %{maxAlt} m.
            </span>
          </div>
          <span
            class="caption font-weight-bold primary--text"
            v-translate
          >
            AERODROME OR REGION LINKED TO NOTAM
          </span>
          <div>
            {{ notam.linked_structure_name }}
          </div>
          <span
            class="caption font-weight-bold primary--text"
            v-translate
          >
            VALIDITY PERIOD
          </span>
          <div>
            <span v-translate="{
                dateStart: getLocalDateTime(notam.date_start),
                dateEnd: getLocalDateTime(notam.date_end)
              }">
              From %{dateStart} to %{dateEnd} (local hour).
            </span>
          </div>
        </div>
        <v-divider />
        <!-- ORIGINAL TEXT -->
        <div class="px-4">
          <h2
            class="caption font-weight-bold primary--text"
            v-translate
          >
            ORIGINAL TEXT OF THE NOTAM
          </h2>
          <div>
            {{ notam.full_text }}
          </div>
        </div>
        <v-divider />
        <!-- EXTRA INFORMATION -->
        <div class="px-4">
          <h2
            class="caption font-weight-bold primary--text"
            v-translate
          >
            EXTRA INFORMATION
          </h2>
          <div v-html="formatExtraInformations()" />
        </div>
        <v-divider />
        <!-- ARTICLE -->
        <div class="px-4">
          <h2
            class="caption font-weight-bold primary--text"
            v-translate
          >
            DID YOU KNOW?
          </h2>
          <div>
            <span>
              {{ texts.didYouKnow }}
            </span>
            <a
              href="https://clearance.aero/ou-trouver-les-notam-et-les-sup-aip-quand-on-est-telepilote-de-drone/"
              target="_blank"
              v-translate
            >
              NOTAM for flights drone.
            </a>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { NOTAMS_CATEGORY } from '@/settings';

import { ADD_NOTAM_SELECTED_NS, REMOVE_NOTAM_SELECTED_NS } from '@/store/flights';
import { SET_BOUNDING_BOX_NS } from '@/store/map';

export default {
  name: 'FlightNotam',
  props: {
    notam: Object,
    notamsExpanded: Number,
  },
  data() {
    return {
      notamHover: false,
      panelExpanded: undefined,
    };
  },
  computed: {
    texts() {
      return {
        didYouKnow: this.$gettext(`The area shown on the map is the area related to the NOTAM
          itself; the NOTAM may be relevant to only a sub-area of the area (or in some cases, to a
          larger area). Be sure you understand the content of the NOTAM before the take off. Read
          more:`),
      };
    },
    highlighted() {
      return this.notamHover && this.panelExpanded === undefined ? 'highlight' : '';
    },
    getClass() {
      return this.notam.category === NOTAMS_CATEGORY.IMPORTANT ? 'error--text' : 'info--text';
    },
    displayCategory() {
      return [NOTAMS_CATEGORY.IMPORTANT, NOTAMS_CATEGORY.NOT_CHECKED].includes(this.notam.category);
    },
    notamGeometry() {
      const notamToDisplay = this.$store.getters['flights/notamsToDisplay'].find((n) => n.id === this.notam.id);
      if (notamToDisplay) {
        return notamToDisplay.geometry;
      }

      return null;
    },
  },
  watch: {
    panelExpanded(newValue) {
      if (newValue === 0) {
        this.$store.dispatch(ADD_NOTAM_SELECTED_NS, this.notam.id)
          .then(() => {
            if (this.notamGeometry) {
              this.$store.dispatch(SET_BOUNDING_BOX_NS, { fromGeom: this.notamGeometry });
            }
          });
      } else {
        this.$store.dispatch(REMOVE_NOTAM_SELECTED_NS, this.notam.id);
      }
    },
    notamsExpanded(newValue) {
      if (newValue === undefined) {
        this.panelExpanded = undefined;
      }
    },
  },
  methods: {
    getLocalDateTime(dateTime) {
      const localDateTime = new Date(dateTime);
      const date = localDateTime.toLocaleDateString(
        this.$store.getters['application/currentLanguage'],
      );
      const time = localDateTime.toLocaleTimeString(
        this.$store.getters['application/currentLanguage'],
      );

      return `${date} ${time}`;
    },
    formatExtraInformations() {
      if (this.notam?.extra_informations?.supaip_url) {
        const supaipUrl = this.notam.extra_informations.supaip_url;
        const supaipLink = `<a target="_blank" href="${supaipUrl}">${supaipUrl}</a>`;
        return `${this.$gettext('SUP AIP URL:')} ${supaipLink}`;
      }
      return this.$gettext('No extra information for this NOTAM.');
    },
    formatNotamContent() {
      if (
        !(
          this.notam
          && this.notam.extra_informations
          && this.notam.extra_informations.supaip_url
        )
      ) {
        return this.notam.content;
      }
      const supaipUrl = this.notam.extra_informations.supaip_url;
      const supAIPRegex = /SUP\s*AIP\s*(?:AIRAC)?\s*(\d*)\/(\d*)/;
      const matches = supAIPRegex.exec(this.notam.content);
      if (matches != null && matches[0]) {
        const start = this.notam.content.substring(0, matches.index);
        const linkText = this.notam.content.substring(
          matches.index, matches.index + matches[0].length,
        );
        const middle = `<a target="_blank" href="${supaipUrl}">${linkText}</a>`;
        const end = this.notam.content.substring(
          matches.index + matches[0].length,
          this.notam.content.length,
        );
        return start + middle + end;
      }
      return this.notam.content;
    },
  },
};
</script>
