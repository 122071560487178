<template>
  <v-container class="pa-0">
    <OpinionApprovalDetails
      :approval="approval"
      @center-on-approval="() => $emit('center-on-approval')"
      @center-on-area="(area) => $emit('center-on-area', area)"
    />
    <v-row
      no-gutters
      class="details-card px-4 pb-2"
    >
      <hr class="separator" />
      <v-expansion-panels
        light
        v-model="chatPanel"
        class="chat_panel"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              <v-icon
                color="primary"
                small
                class="pr-4"
              >
                mdi-bank
              </v-icon>
              <span class="details-card__label text-uppercase">
                <translate>DISCUSSIONS WITH</translate>
                <span>
                  {{ approval.authority_name }}
                </span>
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content expand-icon="keyboard_arrow_down">
            <OpinionChat
              :opinion="opinion"
              :newMessageDisabled="isClosed"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row
      no-gutters
      wrap
      class="details-card__footer px-3 py-1"
      :class="getStatusClass"
    >
      <v-col
        cols="6"
        class="d-flex align-center"
      >
        <StatusIcon
          :status="opinion.status"
          transparentBackground=""
          :isClosed="isClosed"
        />
        <span class="pl-2">
          {{ opinionStatusText }}
        </span>
      </v-col>
      <v-col
        v-if="!isClosed"
        cols="6"
        class="d-flex align-center justify-end"
      >
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              light
              @click="openStatusDialog('unfavorable')"
              :loading="loading"
              :disabled="opinionAlreadyRefused"
              class="white--text mr-2"
            >
              <v-icon size="28">mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
          <translate>Send an unfavourable opinion</translate>
        </v-tooltip>
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              light
              @click="openStatusDialog('favorable')"
              :loading="loading"
              :disabled="opinionAlreadyAccepted"
              class="white--text"
              icon
            >
              <v-icon size="28">mdi-check-circle-outline</v-icon>
            </v-btn>
          </template>
          <translate>Send a favourable opinion</translate>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- Dialog change opinion status -->
    <v-dialog
      v-if="dialogUpdateStatus"
      v-model="dialogUpdateStatus"
      width="650"
      height="800"
      :retain-focus="false"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title :class="`${dialogStatusContent[statusForUpdate].color}--text`">
          {{ dialogStatusContent[statusForUpdate].title }}
        </v-card-title>
        <v-col cols="12">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="px-2"
          >
            <v-textarea
              v-model="message"
              :label="dialogStatusContent[statusForUpdate].placeholder"
              :rules="rules[statusForUpdate]"
              outlined
              rows="6"
            />
          </v-form>
        </v-col>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeStatusDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            :loading="loading"
            text
            @click="updateStatus()"
            :class="`${dialogStatusContent[statusForUpdate].color}--text`"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import APIService from '@/services/api';

import { GET_OPINION_DETAILS_NS } from '@/store/opinions';
import { CLOSED } from '@/store/status';

import OpinionApprovalDetails from '@/components/Opinions/OpinionApprovalDetails.vue';
import OpinionChat from '@/components/Opinions/OpinionChat.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'OpinionVisitorDetails',
  components: {
    OpinionApprovalDetails,
    OpinionChat,
    StatusIcon,
  },
  props: { opinion: Object },
  data() {
    return {
      chatPanel: 0,
      loading: false,
      statusForUpdate: null,
      dialogUpdateStatus: false,
      message: '',
      dialogStatusContent: {
        favorable: {
          title: this.$gettext('Favourable opinion'),
          placeholder: this.$gettext('Explain to pilot the reasons of your approval.'),
          color: 'success',
        },
        unfavorable: {
          title: this.$gettext('Unfavourable opinion'),
          placeholder: this.$gettext('Explain your unfavourable opinion.'),
          color: 'error',
        },
      },
      rules: {
        favorable: [(v) => !!v || this.$gettext('Please explain your favourable opinion.')],
        unfavorable: [
          (v) => (
            !!v || this.$gettext('A message is required for an unfavourable opinion.')
          ),
        ],
      },
      valid: true,
    };
  },
  computed: {
    approval() {
      return this.opinion.approval;
    },
    isClosed() {
      return this.approval.is_closed;
    },
    opinionAlreadyAccepted() {
      return this.opinion.status === 'accepted';
    },
    opinionAlreadyRefused() {
      return this.opinion.status === 'refused';
    },
    getStatusClass() {
      return { [this.isClosed ? CLOSED : this.opinion.status]: true };
    },
    opinionStatusText() {
      let statusText = '';
      switch (this.opinion.status) {
        case 'accepted':
          statusText = this.$gettext('Favourable opinion');
          break;
        case 'refused':
          statusText = this.$gettext('Unfavourable opinion');
          break;
        default:
          statusText = this.$gettext('Opinion not given');
          break;
      }
      if (this.isClosed) {
        statusText += ` ${this.$gettext('(archived)')}`;
      }
      return statusText;
    },
  },
  methods: {
    openStatusDialog(status) {
      this.statusForUpdate = status;
      this.message = '';
      this.dialogUpdateStatus = true;
    },
    closeStatusDialog() {
      this.dialogUpdateStatus = false;
      this.message = '';
      this.statusForUpdate = null;
      this.loading = false;
    },
    async updateStatus() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await APIService.updateOpinionStatus(this.opinion.id, this.statusForUpdate, this.message)
          .then(() => {
            this.showMessage(this.$gettext('Opinion updated'), 'success');
            this.$store.dispatch(GET_OPINION_DETAILS_NS, this.opinion.id)
              .then(() => {
                this.closeStatusDialog();
              });
          })
          .finally(() => {
            this.closeStatusDialog();
          });
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.chat_panel ::v-deep .v-expansion-panel-content__wrap {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
}

</style>>
