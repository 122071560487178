<template>
  <div>
    <v-expansion-panels
      v-model="constraintsExpanded"
      class="dense__expansion-panel dark"
    >
      <v-expansion-panel
        class="flight__status"
        :class="getStatusClass"
      >
        <v-expansion-panel-header>
          <div class="dense__expansion-panel__header-content d-flex align-center
            justify-space-between flex-wrap">
            <div class="d-flex align-center mb-sm-0 mb-2">
              <div class="analysis-item-number">
                <span :class="getNumberConstraintsClass">
                  {{ constraints.length }}
                </span>
              </div>
              <div class="ml-2 white--text">
                <span
                  v-if="isImpacting"
                  v-translate
                >
                  CONSTRAINTS TO HANDLE
                </span>
                <span
                  v-else-if="isOtherImpacting"
                  v-translate
                >
                  OTHER CONSTRAINTS
                </span>
                <span
                  v-else
                  v-translate
                >
                  NON IMPACTFUL CONSTRAINTS
                </span>
              </div>
            </div>
            <!-- Entrust us paperwork -->
            <div
              v-if="isImpacting"
              class="mr-1"
            >
              <v-btn
                v-if="mayBeEntrusted"
                color="white"
                outlined
                class="font-weight-regular truncated_button"
                small
                @click.stop="openEntrustDialog()"
              >
                <template>
                  <span v-translate>Entrust us the paperwork</span>
                  <span
                    v-if="procedurePrice"
                    v-translate="{price: procedurePrice}"
                    class="ml-1"
                  >
                    (%{price}€ VAT excl.)
                  </span>
                </template>
              </v-btn>
              <span
                v-else-if="flight.was_entrusted"
                v-translate
                class="white--text font-weight-thin"
              >
                Entrusted to Clearance
              </span>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-3">
          <div
            v-for="constraint in constraints"
            :key="constraint.id"
            class="flight-constraint-container"
          >
            <FlightConstraint
              :constraint="constraint"
              :flight="flight"
              :constraintsExpanded="constraintsExpanded"
              @update-flight-status="() => updateFlightStatus()"
            />
          </div>
          <v-card
            v-if="!constraints.length"
            v-translate
            class="d-flex justify-center py-2"
          >
            No constraints to handle
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Entrust Paperworks Validation Dialog -->
    <v-dialog
      v-model="entrustUsDialog"
      max-width="1200"
    >
      <EntrustPaperworkValidations
        ref="entrustpaperworkvalidations"
        :flight="flight"
        :price="procedurePrice"
        @close-form="() => entrustUsDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { GET_FLIGHT_STATUS_NS } from '@/store/flights';
import {
  ACTION_NEEDED,
  CLEAR,
  ERROR,
  INCOMPLETE,
  NOT_HANDLED,
  PLANNED,
  PROCESSING,
  REFUSED,
  RESERVES,
} from '@/store/status';

import EntrustPaperworkValidations from '@/components/Flights/EntrustPaperworkValidations.vue';
import FlightConstraint from '@/components/Flights/FlightConstraint.vue';
import { CONSTRAINTS_CATEGORY } from '@/settings';

const priority = {
  [REFUSED]: 5,
  [INCOMPLETE]: 4,
  [PROCESSING]: 3,
  [NOT_HANDLED]: 2,
  [RESERVES]: 1,
  [CLEAR]: 0,
};

const constraintStatusToFlightStatus = {
  [REFUSED]: REFUSED,
  [INCOMPLETE]: ACTION_NEEDED,
  [RESERVES]: RESERVES,
  [NOT_HANDLED]: PLANNED,
  [PROCESSING]: PLANNED,
  [CLEAR]: CLEAR,
};

export default {
  name: 'FlightConstraintsList',
  components: {
    EntrustPaperworkValidations,
    FlightConstraint,
  },
  props: {
    flight: Object,
    category: String,
    constraints: Array,
  },
  data() {
    return {
      constraintsExpanded: 0,
      entrustUsDialog: false,
    };
  },
  computed: {
    isImpacting() {
      return this.category === CONSTRAINTS_CATEGORY.IMPACTING;
    },
    isOtherImpacting() {
      return this.category === CONSTRAINTS_CATEGORY.OTHER_IMPACTING;
    },
    getUIStatus() {
      const { is_archived: isArchived, status } = this.flight;
      return isArchived ? 'archived' : status;
    },
    getStatusClass() {
      return { [this.getUIStatus]: true };
    },
    getNumberConstraintsClass() {
      if (!this.isImpacting) {
        return 'grey--text';
      }
      return this.constraints.length ? 'error--text' : 'success--text';
    },
    isContactRestrictedAccount() {
      return this.$store.state.authentication.user.contact_restricted_account;
    },
    procedurePrice() {
      return this.flight.applicable_procedures_price;
    },
    mayBeEntrusted() {
      const {
        status,
        is_archived: isArchived,
        was_entrusted: wasEntrusted,
      } = this.flight;
      return (
        this.procedurePrice !== -1
        && !wasEntrusted
        && !isArchived
        && [PLANNED, ACTION_NEEDED, ERROR, PROCESSING].includes(status)
        && this.constraints.some(
          (constraint) => [INCOMPLETE, PROCESSING, NOT_HANDLED].includes(constraint.status),
        )
        && !this.isContactRestrictedAccount
      );
    },
  },
  created() {
    if (this.isOtherImpacting) {
      this.constraintsExpanded = undefined;
    }
  },
  methods: {
    openEntrustDialog() {
      this.entrustUsDialog = true;
      if (this.$refs.entrustpaperworkvalidations) {
        this.$refs.entrustpaperworkvalidations.checkFlightCanBeEntrusted();
      }
    },
    updateFlightStatus() {
      const { constraintsAnalysis } = this.flight;
      const impactingConstraints = constraintsAnalysis.filter(
        (c) => c.category === CONSTRAINTS_CATEGORY.IMPACTING,
      );
      const constraintsStatus = impactingConstraints.map((c) => c.status);
      constraintsStatus.sort((s1, s2) => priority[s2] - priority[s1]);
      const [winnerStatus] = constraintsStatus;
      if (this.flight.status !== constraintStatusToFlightStatus[winnerStatus]) {
        this.$store.dispatch(GET_FLIGHT_STATUS_NS, { flightId: this.flight.id });
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight {
  background-color: $color-primary;
  &__status {
    color: $color-white;
    &.planned {
      background-color: $flight-planned-background-color;
    }
    &.processing {
      background-color: $flight-processing-background-color;
    }
    &.canceled {
      background-color: $flight-canceled-background-color;
    }
    &.error {
      background-color: $flight-error-background-color;
    }
    &.action_needed {
      background-color: $flight-action_needed-background-color;
    }
    &.archived {
      background-color: $flight-archived-background-color;
    }
    &.done {
      background-color: $flight-archived-background-color;
    }
    &.confirmed {
      background-color: $flight-confirmed-background-color;
    }
    &.clear {
      background-color: $flight-clear-background-color;
    }
    &.reserves {
      background-color: $flight-reserves-background-color;
    }
    &.refused {
      background-color: $flight-refused-background-color;
    }
  }
}
.v-expansion-panel {
  box-shadow: none;
}
.v-expansion-panel ::v-deep {
  border-radius: 2px;
  overflow: hidden;

  .v-expansion-panel__header {
    padding: 0 8px 0 0;
    min-height: 40px;
  }
}
.v-expansion-panel-content ::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
.flight-constraint-container {
  margin-top: 12px;

  &:first-child {
    margin-top: 0px;
  }
}
.v-btn {
  min-width: 0;
}

</style>
