<template>
  <div class="grow">
    <v-expansion-panels
      v-model="takeOffAuthorizationPanel"
      light
      class="mt-4"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span
            class="details-card__label"
            v-translate
          >
            Take off authorization
          </span>
          <template v-slot:actions>
            <v-progress-circular
              v-if="loadingTakeOffAuthorizationDetails"
              indeterminate
              color="primary"
              :size="20"
              :width="2"
            />
            <v-icon v-else>keyboard_arrow_down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-if="!loadingTakeOffAuthorizationDetails">
            <div>
              <div
                class="mt-4 font-weight-medium pb-1 black--text body-2"
                v-translate
              >
                Decision
              </div>
              <v-row
                no-gutters
                align="center"
              >
                <v-col
                  cols="12"
                  sm="10"
                  class="d-flex"
                >
                  <TakeOffAuthorizationStatusIcon
                    :status="takeOffAuthorization.status"
                    disableTooltip
                  />
                  <span class="pl-1">
                    {{ takeOffAuthorizationDisplayStatus }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="d-flex justify-sm-end"
                >
                  <v-btn
                    v-if="!takeOffAuthorizationRequired"
                    @click="dialogUpdateStatus = true"
                    :color="getColorDecisionButton"
                    outlined
                  >
                    <span
                      v-if="takeOffAuthorization.status === 'submitted'"
                      v-translate
                      key="takeOffAuthorizationAnswer"
                    >
                      ANSWER
                    </span>
                    <span
                      v-else
                      v-translate
                      key="takeOffAuthorizationUpdateDecision"
                    >
                      UPDATE THE DECISION
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <TakeOffAuthorizationDetails
              :takeOffAuthorization="takeOffAuthorization"
              :newMessageDisabled="approvalIsClosed || takeOffAuthorizationRequired"
            />
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog
      v-if="dialogUpdateStatus"
      v-model="dialogUpdateStatus"
      persistent
      width="650"
      height="800"
      :retain-focus="false"
    >
      <ChangeTakeOffAuthorizationStatusForm
        :takeOffAuthorization="takeOffAuthorization"
        :loadingTakeOffAuthorizationDetails="loadingTakeOffAuthorizationDetails"
        @close="() => dialogUpdateStatus = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ACCEPTED, LANDING_REQUIRED, REFUSED, REQUIRED, SUBMITTED } from '@/store/status';

import TakeOffAuthorizationStatusIcon from '@/components/StatusIcon/TakeOffAuthorizationStatusIcon.vue';
import ChangeTakeOffAuthorizationStatusForm from '@/components/TakeOffAuthorizations/ChangeTakeOffAuthorizationStatusForm.vue';
import TakeOffAuthorizationDetails from '@/components/TakeOffAuthorizations/TakeOffAuthorizationDetails.vue';

export default {
  name: 'ApprovalTakeOffAuthorizationPanel',
  components: {
    TakeOffAuthorizationStatusIcon,
    ChangeTakeOffAuthorizationStatusForm,
    TakeOffAuthorizationDetails,
  },
  props: {
    takeOffAuthorization: Object,
    loadingTakeOffAuthorizationDetails: Boolean,
  },
  data() {
    return {
      takeOffAuthorizationPanel: null,
      dialogUpdateStatus: false,
    };
  },
  computed: {
    approval() {
      return this.$store.getters['approvals/approvalSelected'];
    },
    approvalIsClosed() {
      return this.approval.is_closed;
    },
    takeOffAuthorizationDisplayStatus() {
      switch (this.takeOffAuthorization.status) {
        case SUBMITTED:
          return this.$gettext('Take off authorization submitted');
        case ACCEPTED:
          return this.$gettext('Take off authorization granted');
        case REFUSED:
          return this.$gettext('Take off authorization refused');
        case LANDING_REQUIRED:
          return this.$gettext('Landing required');
        case REQUIRED:
          return this.$gettext('Take off authorization required');
        default:
          return this.$gettext('Take off authorization status unknown');
      }
    },
    takeOffAuthorizationRequired() {
      return this.takeOffAuthorization.status === REQUIRED;
    },
    getColorDecisionButton() {
      return this.takeOffAuthorization.status === SUBMITTED ? 'warning' : 'primary';
    },
  },
};
</script>
