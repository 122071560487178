<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="py-2 px-3"
          :class="highlighted"
        >
          <div class="d-flex align-center">
            <v-icon :color="getIconColor(approval.global_status_activations)">
              {{ getIcon(approval.global_status_activations) }}
            </v-icon>
            <span class="pl-2">
              <span
                v-translate="{ count: activationsCount }"
                :translate-n="activationsCount"
                translate-plural="%{ count } Activations"
              >
                %{ count } Activation
              </span>
              <span
                v-if="approval.display_identifier"
                v-translate="{ identifier: approval.display_identifier }"
                class="pl-1"
              >
                for the request %{ identifier }
              </span>
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list
            class="pt-0"
            :class="highlighted"
          >
            <template v-for="(activation, index) in activations">
              <div
                :key="'index-' + index"
                class="px-3"
              >
                <v-divider class="ma-0" />
              </div>
              <v-list-item
                :key="activation.id"
                class="mx-3"
                :class="getBackgroundColor(activation.status)"
              >
                <v-list-item-content>
                  <v-row
                    align="center"
                    no-gutters
                  >
                    <v-col
                      cols="10"
                      md="9"
                      xl="10"
                    >
                      <v-row
                        align="center"
                        no-gutters
                      >
                        <v-col
                          cols="4"
                          lg="3"
                          xl="2"
                        >
                          <v-row
                            align="center"
                            no-gutters
                          >
                            <v-icon :color="getIconColor(activation.status)">
                              {{ getIcon(activation.status) }}
                            </v-icon>
                            <span
                              class="pl-2"
                              :class="`${getIconColor(activation.status)}--text`"
                            >
                              {{ getActivationStatusText(activation.status) }}
                            </span>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="8"
                          lg="9"
                          xl="10"
                          class="activation-dates"
                        >
                          {{ getActivationDatesText(activation) }}
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="2"
                      md="3"
                      xl="2"
                      class="pr-3"
                    >
                      <v-row
                        no-gutters
                        align="center"
                        justify="end"
                      >
                        <!-- ---- Cancel Activation button ----- -->
                        <v-tooltip
                          v-if="
                            activation.status !== 'cancelled'
                            && activation.status !== 'refused'
                          "
                          bottom
                          key="cancel"
                        >
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <v-btn
                                icon
                                small
                                @click.stop="openCancelActivationDialog(activation.id)"
                                :disabled="new Date(activation.date_start) <= new Date()"
                              >
                                <v-icon small>mdi-cancel</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <template v-if="new Date(activation.date_start) <= new Date()">
                            <span v-translate>
                              This activation can no longer be updated
                            </span>
                          </template>
                          <template v-else>
                            <span v-translate>Cancel activation</span>
                          </template>
                        </v-tooltip>
                        <!-- ---- Update Activation button ----- -->
                        <v-tooltip
                          v-if="activation.status !== 'cancelled'"
                          bottom
                          key="update"
                        >
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <v-btn
                                icon
                                small
                                @click.stop="openUpdateActivationDialog(activation)"
                                :disabled="new Date(activation.date_start) <= new Date()"
                              >
                                <v-icon small>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <template v-if="new Date(activation.date_start) <= new Date()">
                            <span v-translate>
                              This activation can no longer be updated
                            </span>
                          </template>
                          <template v-else>
                            <span v-translate>Update activation</span>
                          </template>
                        </v-tooltip>
                        <!-- ---- Activation Chat ----- -->
                        <v-tooltip
                          v-if="showChat(activation)"
                          bottom
                          key="chat"
                        >
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <v-btn
                                icon
                                small
                                @click.stop="openActivationChatDialog(activation)"
                              >
                                <v-icon small>mdi-comment-outline</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span v-translate>
                            View discussions and events related to the activation
                          </span>
                        </v-tooltip>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Cancel activation dialog -->
    <v-dialog
      v-model="showCancelActivationDialog"
      width="500px"
      :retain-focus="false"
      persistent
    >
      <v-card
        class="default--dialog__card"
        max-height="800px"
        elevation="0"
      >
        <v-card-title>
          <span v-translate>Cancel Activation</span>
        </v-card-title>
        <v-card-text>
          <span v-translate>Are you sure you want to cancel this activation?</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeCancelActivationDialog()"
          >
            <span v-translate>No</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="loadingCancel"
            @click="cancelActivation()"
          >
            <span v-translate>Yes</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Update activation dialog -->
    <v-dialog
      v-model="showUpdateActivationDialog"
      width="820px"
      :retain-focus="false"
      persistent
    >
      <RequestActivationForm
        v-if="activationToUpdate"
        :flight="flight"
        :approval="approval"
        :constraint="constraint"
        :activation="activationToUpdate"
        @close-form="() => closeUpdateActivationDialog()"
        @update-activation="(activation) => updateActivation(activation)"
        :key="activationToUpdate.id"
      />
    </v-dialog>
    <!-- Activation chat dialog -->
    <v-dialog
      v-model="showActivationChatDialog"
      width="820px"
      :retain-focus="false"
      persistent
    >
      <v-card
        class="default--dialog__card"
        max-height="800px"
        elevation="0"
      >
        <v-card-title>
          <span v-translate>Activation discussions</span>
        </v-card-title>
        <v-card-text class="mt-3">
          <ActivationChat
            v-if="activationToDisplayChat"
            :activation="activationToDisplayChat"
            :newMessageDisabled="newMessageDisabled(activationToDisplayChat)"
            :key="activationToDisplayChat.id"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeActivationChatDialog()"
          >
            <span v-translate>close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { filterHour } from '@/filters/hour';

import APIService from '@/services/api';

import {
  GET_FLIGHT_STATUS_NS,
  UPDATE_FLIGHT_CONSTRAINT_STATUS_NS,
  SET_FLIGHT_APPROVAL_PROPERTY_NS,
} from '@/store/flights';
import { ACCEPTED, CANCELLED, ERROR, REFUSED, SUBMITTED, TO_SEND } from '@/store/status';

import ActivationChat from '@/components/Activations/ActivationChat.vue';
import RequestActivationForm from '@/components/Flights/RequestApproval/RequestActivationForm.vue';

const ACTIVATION_ICON_STYLE = {
  to_send: {
    icon: 'mdi-clock',
    color: 'primary',
    background_color_class: 'primary_background',
  },
  submitted: {
    icon: 'mdi-send-circle',
    color: 'primary',
    background_color_class: 'primary_background',
  },
  pending: {
    icon: 'mdi-dots-horizontal-circle',
    color: 'primary',
    background_color_class: 'primary_background',
  },
  accepted: {
    icon: 'mdi-checkbox-marked-circle',
    color: 'success',
    background_color_class: 'success_background',
  },
  reserves: {
    icon: 'icon-fly_okbut_flat',
    color: 'warning',
    background_color_class: 'warning_background',
  },
  refused: {
    icon: 'mdi-minus-circle',
    color: 'error',
    background_color_class: 'error_background',
  },
  error: {
    icon: 'mdi-alert-circle',
    color: 'warning',
    background_color_class: 'warning_background',
  },
  cancelled: {
    icon: 'mdi-close-circle',
    color: 'grey',
    background_color_class: 'grey_background',
  },
  incomplete: {
    icon: 'mdi-alert-circle',
    color: 'warning',
    background_color_class: 'warning_background',
  },
};

export default {
  name: 'FlightActivationList',
  components: {
    ActivationChat,
    RequestActivationForm,
  },
  props: {
    flight: Object,
    approval: Object,
    structure: Object,
    constraint: Object,
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showCancelActivationDialog: false,
      showUpdateActivationDialog: false,
      showActivationChatDialog: false,
      activationIdToCancel: null,
      activationToUpdate: null,
      activationToDisplayChat: null,
      loadingCancel: false,
    };
  },
  computed: {
    activations() {
      return this.approval.activations;
    },
    activationsCount() {
      return this.activations.filter((activation) => activation.status !== CANCELLED).length;
    },
    activationStatusTranslations() {
      return {
        to_send: this.$gettext('Programmed'),
        submitted: this.$gettext('Sent'),
        pending: this.$gettext('In progress'),
        accepted: this.$gettext('Accepted'),
        refused: this.$gettext('Refused'),
        error: this.$gettext('Error'),
        cancelled: this.$gettext('Cancelled'),
      };
    },
    highlighted() {
      if (this.highlight) {
        return 'highlight';
      }
      return '';
    },
  },
  methods: {
    getIcon(status) {
      const style = ACTIVATION_ICON_STYLE[status];
      if (style) {
        return style.icon;
      }
      return null;
    },
    getIconColor(status) {
      const style = ACTIVATION_ICON_STYLE[status];
      if (style) {
        return style.color;
      }
      return null;
    },
    getBackgroundColor(status) {
      const style = ACTIVATION_ICON_STYLE[status];
      if (style) {
        return style.background_color_class;
      }
      return null;
    },
    getActivationStatusText(status) {
      return this.activationStatusTranslations[status];
    },
    getActivationDatesText(activation) {
      const sendDate = format(
        new Date(activation.sent_at),
        'dd MMMM yyyy', { locale: fr },
      );
      const dateStart = format(
        new Date(activation.date_start),
        'dd MMMM yyyy', { locale: fr },
      );
      const dateEnd = format(
        new Date(activation.date_end),
        'dd MMMM yyyy', { locale: fr },
      );
      const timeStart = filterHour(activation.time_start);
      const timeEnd = filterHour(activation.time_end);

      if (activation.status === CANCELLED) {
        const cancelDate = format(
          new Date(activation.cancelled_at),
          'dd MMMM yyyy', { locale: fr },
        );
        if (dateStart === dateEnd) {
          return this.$gettextInterpolate(
            this.$gettext(`Cancelled at %{cancelDate} to fly on %{dateStart} from
              %{timeStart} to %{timeEnd}`),
            { cancelDate, dateStart, timeStart, timeEnd },
          );
        }
        return this.$gettextInterpolate(
          this.$gettext(`Cancelled at %{cancelDate} to fly between %{dateStart}
            and %{dateEnd} from %{timeStart} to %{timeEnd}`),
          { cancelDate, dateStart, dateEnd, timeStart, timeEnd },
        );
      }
      if (activation.status === TO_SEND) {
        const scheduledSendingDate = format(
          new Date(activation.scheduled_sending_date),
          'dd MMMM yyyy', { locale: fr },
        );
        if (dateStart === dateEnd) {
          return this.$gettextInterpolate(
            this.$gettext(`Will be sent on %{scheduledSendingDate} to fly on %{dateStart} from
              %{timeStart} to %{timeEnd}`),
            { scheduledSendingDate, dateStart, timeStart, timeEnd },
          );
        }
        return this.$gettextInterpolate(
          this.$gettext(`Will be sent on %{scheduledSendingDate} to fly between %{dateStart} and
            %{dateEnd} from %{timeStart} to %{timeEnd}`),
          { scheduledSendingDate, dateStart, dateEnd, timeStart, timeEnd },
        );
      }
      if ([ACCEPTED, SUBMITTED, REFUSED].includes(activation.status)) {
        if (dateStart === dateEnd) {
          return this.$gettextInterpolate(
            this.$gettext(`Sent on %{sendDate} to fly on %{dateStart} from
              %{timeStart} to %{timeEnd}`),
            { sendDate, dateStart, timeStart, timeEnd },
          );
        }
        return this.$gettextInterpolate(
          this.$gettext(`Sent on %{sendDate} to fly between %{dateStart}
            and %{dateEnd} from %{timeStart} to %{timeEnd}`),
          { sendDate, dateStart, dateEnd, timeStart, timeEnd },
        );
      }
      if (activation.status === ERROR) {
        if (dateStart === dateEnd) {
          return this.$gettextInterpolate(
            this.$gettext('Error to fly on %{dateStart} from %{timeStart} to %{timeEnd}'),
            { dateStart, timeStart, timeEnd },
          );
        }
        return this.$gettextInterpolate(
          this.$gettext(`Error to fly between %{dateStart} and %{dateEnd} from
            %{timeStart} to %{timeEnd}`),
          { dateStart, dateEnd, timeStart, timeEnd },
        );
      }
      if (dateStart === dateEnd) {
        return this.$gettextInterpolate(
          this.$gettext('Fly on %{dateStart} from %{timeStart} to %{timeEnd}'),
          { dateStart, timeStart, timeEnd },
        );
      }
      return this.$gettextInterpolate(
        this.$gettext('Fly between %{dateStart} and %{dateEnd} from %{timeStart} to %{timeEnd}'),
        { dateStart, dateEnd, timeStart, timeEnd },
      );
    },
    openCancelActivationDialog(activationId) {
      this.activationIdToCancel = activationId;
      this.showCancelActivationDialog = true;
    },
    closeCancelActivationDialog() {
      this.showCancelActivationDialog = false;
      this.activationIdToCancel = null;
    },
    cancelActivation() {
      this.loadingCancel = true;
      APIService.cancelActivation(this.activationIdToCancel)
        .then(({ data }) => {
          this.updateActivation(data, true);
          this.showMessage(this.$gettext('Activation cancelled successfully'), 'success');
        })
        .finally(() => {
          this.loadingCancel = false;
        });
    },
    openUpdateActivationDialog(activation) {
      this.activationToUpdate = activation;
      this.showUpdateActivationDialog = true;
    },
    closeUpdateActivationDialog() {
      this.showUpdateActivationDialog = false;
      this.activationToUpdate = null;
    },
    updateActivation(activation, cancelled = false) {
      const index = this.activations.findIndex((a) => a.id === activation.id);
      if (index !== -1) {
        const updatedActivations = [...this.activations];
        updatedActivations[index] = activation;
        this.$store.commit(SET_FLIGHT_APPROVAL_PROPERTY_NS, {
          flightId: this.flight.id,
          approvalId: this.approval.id,
          propertyKey: 'activations',
          propertyValue: updatedActivations,
        });
      }
      this.$store.commit(SET_FLIGHT_APPROVAL_PROPERTY_NS, {
        flightId: this.flight.id,
        approvalId: this.approval.id,
        propertyKey: 'global_status_activations',
        propertyValue: activation.global_status_activations,
      });

      if (cancelled) {
        this.closeCancelActivationDialog();
      } else {
        this.closeUpdateActivationDialog();
      }
      this.updateConstraint();
    },
    updateConstraint() {
      APIService.getFlightConstraintStatus(this.flight.id, this.constraint.id)
        .then(({ data }) => {
          this.$store.dispatch(UPDATE_FLIGHT_CONSTRAINT_STATUS_NS, {
            flightId: this.flight.id,
            constraintId: this.constraint.id,
            constraintStatus: data,
          });
          this.$store.dispatch(GET_FLIGHT_STATUS_NS, { flightId: this.flight.id });
        });
      this.$emit('action-on-approval');
    },
    openActivationChatDialog(activation) {
      this.activationToDisplayChat = activation;
      this.showActivationChatDialog = true;
    },
    closeActivationChatDialog() {
      this.activationToDisplayChat = false;
      this.showActivationChatDialog = null;
    },
    showChat(activation) {
      return activation.status !== TO_SEND;
    },
    newMessageDisabled(activation) {
      return (
        activation.status === CANCELLED
        || (
          activation.status === ERROR
          && !activation.external_id
        )
      );
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
.v-expansion-panel-content ::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
.primary_background {
  background-color: #1D6FF21E;
}
.success_background {
  background-color: #058D7A1E;
}
.warning_background {
  background-color: #FF73001E;
}
.error_background {
  background-color: #A2253E1E;
}
.grey_background {
  background-color: #9E9E9E1E;
}
.activation-dates {
  opacity: 0.75;
}
.disabled {
  cursor: not-allowed;
  color: gray
}

</style>
