<template>
  <v-dialog
    v-model="openDialog"
    v-if="openDialog"
    max-width="900"
    persistent
  >
    <v-skeleton-loader
      v-if="loadingLayers"
      type="card-heading, card-heading, actions"
    />
    <v-card
      v-else
      class="default--dialog__card overflow-y"
      elevation="0"
    >
      <v-card-title>
        <translate>Import Mobile Network layers</translate>
      </v-card-title>
      <v-card-text>
        <v-item-group>
          <v-container>
            <v-expansion-panels
              class="mt-1"
              v-model="panel"
              multiple
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="text-subtitle-2">
                    <translate>Metropole's mobile network</translate>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col
                      v-for="(value, index) in metropoleLayers"
                      :key="index"
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-item :value="value">
                        <div :class="getClass(value)">
                          <v-card
                            height="120"
                            class="pt-3"
                            @click="selectLayer(value)"
                          >
                            <div
                              v-if="value.disabled"
                              class="on-map"
                            >
                              <span v-translate>On map</span>
                            </div>
                            <v-card-text class="text-subtitle-2 text-center">{{ value.name }}
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels class="mt-1">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="text-subtitle-2">
                    <translate>Overseas's mobile network</translate>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col
                      v-for="(value, index) in overseasLayers"
                      :key="index"
                      cols="12"
                      md="4"
                    >
                      <v-item :value="value">
                        <div :class="getClass(value)">
                          <v-card
                            height="120"
                            class="pt-3"
                            @click="selectLayer(value)"
                          >
                            <div
                              v-if="value.disabled"
                              class="on-map"
                            >
                              <span v-translate>On map</span>
                            </div>
                            <v-card-text class="text-subtitle-2 text-center">{{ value.name }}
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-item-group>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="info"
          @click="close()"
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="!selectedLayersIds.length"
          :loading="loading"
          @click="addLayers()"
        >
          <translate>Confirm</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/services/api';

import { RESET_DATA_STORES_NS } from '@/store/application';
import { SHOW_MOBILE_NETWORK_LAYERS_IMPORT_DIALOG_NS } from '@/store/map';

export default {
  name: 'ImportMobileNetworkLayer.vue',
  data() {
    return {
      loadingLayers: true,
      metropoleLayers: [],
      overseasLayers: [],
      selectedLayers: [],
      loading: false,
      panel: [0],
    };
  },
  watch: {
    openDialog(newValue) {
      const metropoleIdentifiers = [
        'MOBILE_NETWORK_METROPOLE_20801',
        'MOBILE_NETWORK_METROPOLE_20810',
        'MOBILE_NETWORK_METROPOLE_20815',
        'MOBILE_NETWORK_METROPOLE_20820',
      ];
      if (newValue) {
        API.getMobileNetworkLayers()
          .then(({ data }) => {
            this.layers = data;
            Object.values(this.layers).forEach((layer) => {
              if (metropoleIdentifiers.find((identifier) => layer.identifier === identifier)) {
                this.metropoleLayers.push(layer);
              } else this.overseasLayers.push(layer);
            });
          })
          .finally(() => {
            this.loadingLayers = false;
          });
      }
    },
  },
  computed: {
    openDialog() {
      return this.$store.state.map.showMobileNetworkLayersImportDialog;
    },
    selectedLayersIds() {
      return this.selectedLayers.map((layer) => layer.identifier);
    },
  },
  methods: {
    getClass(layer) {
      if (layer.disabled) {
        return 'disabled';
      } if (this.selectedLayersIds.includes(layer.identifier)) {
        return 'active';
      }
      return '';
    },
    close() {
      this.$store.commit(SHOW_MOBILE_NETWORK_LAYERS_IMPORT_DIALOG_NS, false);
      this.loading = false;
      this.loadingLayers = false;
      this.metropoleLayers = [];
      this.overseasLayers = [];
      this.layers = [];
    },
    selectLayer(layer) {
      if (this.selectedLayersIds.includes(layer.identifier)) {
        const index = this.selectedLayers.findIndex((l) => l.identifier === layer.identifier);
        this.selectedLayers.splice(index, 1);
      } else {
        this.selectedLayers.push(layer);
      }
    },
    addLayers() {
      this.loading = true;
      API.addMobileNetworkLayers(this.selectedLayersIds)
        .then(() => {
          this.close();
          this.$store.dispatch(RESET_DATA_STORES_NS);
          this.$router.push({ path: '/' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.disabled {
  border: 4px solid green;
  border-radius: 6px;
  pointer-events: none;
}

.active {
  border: 4px solid $color-primary;
  border-radius: 6px;
}

.v-row {
  margin-right: 2rem;
  margin-left: 2rem;
}

.on-map {
  background-color: $color-success;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 4px 8px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

</style>
