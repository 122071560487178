<template>
  <div class="full-height d-flex flex-column">
    <v-container class="px-1 px-md-3 flex-grow-0">
      <div class="headline mb-4">
        <translate>Personnal data</translate>
      </div>
      <!-- COOKIES -->
      <v-row
        no-gutters
        align="end"
        class="pb-4"
        v-if="axeptioActivated"
      >
        <v-col
          cols="12"
          md="2"
          class="pb-2"
        >
          <div
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            Cookies
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="pb-2"
        >
          <div class="mt-4">
            <a
              class="cursor-pointer text-decoration-underline body-1 font-weight-light black--text"
              href="javascript:openAxeptioCookies()"
              v-translate
            >
              Change your cookie preferences
            </a>
          </div>
        </v-col>
      </v-row>
      <!-- Export of events journal -->
      <v-row
        no-gutters
        align="end"
        class="py-4"
        v-if="displayExportJournal"
      >
        <v-col
          cols="12"
          md="2"
          class="pb-2"
        >
          <div
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            Events journal
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="d-flex pb-2"
        >
          <div
            class="cursor-pointer text-decoration-underline body-1 font-weight-light"
            @click="requestExportJournalDialog=true"
            v-translate
          >
            Request the export of events journal
          </div>
        </v-col>
      </v-row>
      <!-- Deletion of account -->
      <v-row
        no-gutters
        align="end"
        class="py-4"
        v-if="isUserAdmin"
      >
        <v-col
          cols="12"
          md="2"
          class="pb-2"
        >
          <div
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            Account
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="d-flex pb-2"
        >
          <div
            class="cursor-pointer text-decoration-underline body-1 font-weight-light"
            @click="requestAccountDeletionDialog=true"
            v-translate
          >
            Request the deletion of the account
          </div>
        </v-col>
      </v-row>
      <div
        class="d-flex align-center"
        v-if="isUserDronist && mailjetNewsletterSub"
      >
        <v-progress-circular
          v-if="loadingNewsletterSubscription"
          indeterminate
          color="primary"
          :size="24"
          :width="2"
          class="mt-5 mr-2"
        />
        <v-checkbox
          v-else
          v-model="receivesNewsletter"
          hide-details
          @click="updateNewletterSubscription"
        />
        <span class="mt-5 body-2 grey--text text--darken-2">
          {{ texts.newsletter }}
        </span>
      </div>
      <!-- Other data request -->
      <v-row
        no-gutters
        align="end"
        class="py-4"
      >
        <v-col
          cols="12"
          md="12"
          class="pb-2"
        >
          <div
            class="font-weight-light"
            v-translate
          >
            For any other request about your personal data, contact us at dpd@clearance.aero
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- Terms and Privacy-->
    <template>
      <div class="px-1 px-md-3 pb-3 pt-3 pb-md-0 d-flex flex-column justify-end flex-grow-1">

        <div class="d-flex pt-8 pb-0">
          <a
            target="_blank"
            href="https://clearance.aero/cgu-cgv/"
            class="terms-link underline-on-hover"
            v-translate
          >
            Terms of use
          </a>
          <a
            target="_blank"
            href="https://clearance.aero/politique-de-confidentialite/"
            class="terms-link underline-on-hover"
            v-translate
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </template>
    <v-dialog
      v-model="requestExportJournalDialog"
      max-width="580"
      persistent
      :retain-focus="false"
    >
      <v-card
        class="default--dialog__card"
        max-height="800px"
        elevation="0"
      >
        <v-card-title>
          <translate>Export events journal</translate>
        </v-card-title>
        <v-card-text>
          <span>
            {{ texts.exportJournal }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="requestExportJournalDialog=false"
          >
            <translate>No</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="loadingExportJournal"
            @click="exportJournal()"
          >
            <translate>Yes</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Request account deletion dialog-->
    <v-dialog
      v-model="requestAccountDeletionDialog"
      max-width="580"
      persistent
      :retain-focus="false"
    >
      <RequestAccountDeletion @close="requestAccountDeletionDialog=false" />
    </v-dialog>
  </div>
</template>
<script>
import APIService from '@/services/api';

import { CHANGE_RECEIVES_NEWSLETTER_NS } from '@/store/authentication';

import RequestAccountDeletion from '@/components/Settings/Dronists/RequestAccountDeletion.vue';

export default {
  name: 'DataSettings',
  components: { RequestAccountDeletion },
  data() {
    return {
      requestExportJournalDialog: false,
      requestAccountDeletionDialog: false,
      loadingExportJournal: false,
      loadingNewsletterSubscription: false,
      receivesNewsletter: false,
      mailjetNewsletterSub: false,
    };
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    isUserDronist() {
      return this.$store.getters['authentication/isUserDronist'];
    },
    axeptioActivated() {
      return this.$store.getters['authentication/axeptioActivated'];
    },
    displayExportJournal() {
      return this.$store.getters['authentication/isUserEnterprise'] && this.isUserAdmin;
    },
    texts() {
      return {
        exportJournal: this.$gettextInterpolate(
          this.$gettext(`A data export will be prepared and sent to you at the email address
            linked to your account (%{email}). You should get it in a few minutes.`),
          { email: this.$store.state.authentication.user.email },
        ),
        newsletter: this.$gettext(`I agree to receive Clearance newsletter (once a month, we
          promise 😉)`),
      };
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    contactId() {
      if (this.isUserDronist) {
        return this.userData.contact_id;
      }
      return null;
    },
  },
  mounted() {
    if (this.isUserDronist) {
      this.mailjetNewsletterSub = this.userData.mailjet_newsletter_subscription_activated;
      this.receivesNewsletter = this.userData.contact_subscribed_to_newsletter;
    }
  },
  methods: {
    exportJournal() {
      this.loadingExportJournal = true;
      APIService.exportJournal()
        .then(() => {
          this.showMessage(
            this.$gettext('The events journal export request was sent successfully.'),
            'success',
          );
        })
        .finally(() => {
          this.requestExportJournalDialog = false;
          this.loadingExportJournal = false;
        });
    },
    updateNewletterSubscription() {
      this.loadingNewsletterSubscription = true;
      const payload = { subscribed_to_newsletter: this.receivesNewsletter };
      APIService.updateContactSettings(this.contactId, payload)
        .then(() => {
          this.$store.dispatch(CHANGE_RECEIVES_NEWSLETTER_NS, this.receivesNewsletter)
            .then(() => {
              this.showMessage(
                this.$gettext('Your choice has been taken into account'),
                'success',
              );
            });
        })
        .finally(() => {
          this.loadingNewsletterSubscription = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.full-height {
  height: 100%;
}

.terms-link {
  cursor: pointer;
  color: black;
  margin-right: 32px;
  font-weight: 300;
}
.medium-width {
  max-width: 1200px;
}

</style>
