<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <span v-translate>Notification AlphaTango</span>
    </v-card-title>
    <v-card-text>
      <template v-if="!canRequestNotifications">
        <NotifAlphaTangoRequirements
          :recipient="texts.recipient"
          :flightIsHSS="flightIsHSS"
          :prefectureNotUsingAT="flightIsInAlpesMaritimes"
          :missingData="missingData"
          class="pt-3"
        />
      </template>
      <v-skeleton-loader
        v-else-if="loadingNotifInfo"
        type="list-item-two-line, list-item-three-line, list-item-two-line, list-item, actions"
      />
      <template v-else>
        <v-row
          v-if="hasDronesOverWeightAndNotCaptive"
          no-gutters
        >
          <v-alert
            text
            border="left"
            color="warning"
            class="body-2 d-flex flex-grow-1"
          >
            <span v-translate>
              Drone used in agglomeration must weight less than 8 kg or be captive.
            </span>
          </v-alert>
        </v-row>
        <v-row no-gutters>
          <v-alert
            text
            border="left"
            class="body-2"
          >
            <div>
              {{ headerAlertText }}
            </div>
            <div v-if="flightIsHSS && notificationsToUpdate.length !== 1">
              {{ texts.hss }}
            </div>
            <div v-if="asApproval">
              {{ texts.needAdditionalInfo }}
            </div>
          </v-alert>
        </v-row>
        <v-form
          ref="form"
          v-model="valid"
        >
          <!-- Dates -->
          <div class="d-flex flex-wrap">
            <v-menu
              ref="menu"
              v-model="datesMenu"
              :return-value.sync="dateRange"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :rules="[rules.datesRequired]"
                  :label="labels.dateRange"
                  append-outer-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                />
              </template>
              <v-date-picker
                v-model="dateRange"
                :min="minDatePicker"
                locale="fr"
                first-day-of-week="1"
                scrollable
                no-title
                range
              >
                <v-spacer />
                <v-btn
                  text
                  color="info"
                  @click="datesMenu=false"
                >
                  <span v-translate>Cancel</span>
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  :disabled="dateRange.length !== 2"
                  @click="$refs.menu.save(dateRange)"
                >
                  <span v-translate>OK</span>
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <!-- ---------- Address and description ---------- -->
          <div class="d-flex">
            <v-textarea
              v-model="requestData.address"
              :label="labels.address"
              :placeholder="placeholders.address"
              :rules="[rules.required]"
              rows="2"
            />
            <v-tooltip
              bottom
              color="info"
              max-width=580
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="small-icon"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <div>
                <template v-for="(line, index) in tooltips.address.split('<br>')">
                  <div :key="index">
                    {{ line }}
                  </div>
                </template>
              </div>
            </v-tooltip>
          </div>
          <div
            v-if="longFlightPeriod"
            class="d-flex flex-nowrap"
          >
            <v-alert
              outlined
              text
              dense
              type="warning"
              icon="mdi-alert-outline"
              class="caption"
            >
              {{ texts.longFlightPeriodAlert }}
            </v-alert>
            <v-tooltip
              bottom
              color="info"
              max-width=580
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="small-icon"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <div>
                <template v-for="(line, index) in tooltips.longFlightPeriod.split('<br>')">
                  <div :key="index">
                    {{ line }}
                  </div>
                </template>
              </div>
            </v-tooltip>
          </div>
          <!-- ---------- Relevant information ---------- -->
          <div class="d-flex">
            <v-textarea
              v-model="requestData.additionalInfo"
              :label="labels.additionalInfo"
              :placeholder="placeholders.additionalInfo"
              :rules="[rules.required, rules.maxLength]"
              rows="2"
            />
            <v-tooltip
              bottom
              color="info"
              max-width=580
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="small-icon"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <div>
                <template v-for="(line, index) in tooltips.additionalInfo.split('<br>')">
                  <div :key="index">
                    {{ line }}
                  </div>
                </template>
              </div>
            </v-tooltip>
          </div>
          <!-- ---------- Main pilot ---------- -->
          <div class="d-flex">
            <v-autocomplete
              v-model="requestData.mainPilotId"
              :items="flight.pilots"
              :label="labels.mainPilot"
              item-value="id"
              item-text="full_name"
              :no-data-text="$gettext('No pilot found')"
              @change="updateOtherPilots();"
              class="mr-4"
              persistent-hint
            />
          </div>
          <!-- ---------- Other pilots ---------- -->
          <div
            v-if="flight.pilots.length > 1"
            class="d-flex"
          >
            <v-textarea
              v-model="requestData.otherPilots"
              :label="labels.otherPilots"
              :placeholder="placeholders.otherPilots"
              class="caption"
              auto-grow
              rows="3"
            />
            <v-tooltip
              bottom
              color="info"
              max-width=580
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="small-icon"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <div>
                <template v-for="(line, index) in tooltips.otherPilots.split('<br>')">
                  <div :key="index">
                    {{ line }}
                  </div>
                </template>
              </div>
            </v-tooltip>
          </div>
          <!-- -------------- Declarant --------------- -->
          <v-row
            no-gutters
            class="d-flex flex-wrap"
          >
            <v-col
              cols="12"
              sm="3"
            >
              <v-autocomplete
                v-model="connectedPilotId"
                :items="exploitationPilots"
                :label="labels.declarant"
                item-value="id"
                item-text="full_name"
                :no-data-text="$gettext('No pilot found')"
                @change="updateDeclarant()"
                class="mr-4"
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="requestData.lastName"
                :label="labels.lastName"
                :placeholder="placeholders.lastName"
                :rules="[rules.required]"
                class="pr-1"
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="requestData.firstName"
                :label="labels.firstName"
                :placeholder="placeholders.firstName"
                :rules="[rules.required]"
                class="pl-2 pr-1"
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="requestData.occupation"
                :rules="[rules.occupation]"
                :label="labels.occupation"
                :placeholder="placeholders.occupation"
                class="pl-2"
              />
            </v-col>
          </v-row>
          <v-alert
            class="declarant-alert mb-0"
            color="grey lighten-3"
          >
            <template v-for="(line, index) in texts.declarantAlert.split('<br>')">
              <div
                :key="index"
                class="declarant-alert__line"
              >
                {{ line }}
              </div>
            </template>
            <div>
              <span class="declaration-date pr-2">
                {{ today }}
              </span>
            </div>
          </v-alert>
        </v-form>
        <v-checkbox
          v-if="isUserHijacked"
          v-model="handledByAdmin"
          :label="labels.notifHandledByAdmin"
        />
      </template>
    </v-card-text>
    <!-- Send HSS Notif -->
    <v-checkbox
      v-if="flightIsHSS && hssNotificationsAllowed"
      :label="labels.flightIsHSS"
      v-model="sendHSSNotif"
      color="primary"
      class="mx-4 mt-0 px-2"
      :disabled="hasMissingData"
      hide-details
    />
    <v-card-actions class="py-3">
      <v-spacer />
      <v-btn
        class="mx-3"
        @click="$emit('close-form')"
      >
        <span v-translate>Cancel</span>
      </v-btn>
      <v-btn
        class="white--text mx-3 large"
        color="primary"
        :disabled="isConfirmButtonDisabled"
        :loading="sendingFlightNotifyRequest"
        @click="confirm()"
      >
        <span v-translate>Send</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { differenceInCalendarDays, format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { filterPhoneNumber } from '@/filters/phone';

import APIService from '@/services/api';

import { GET_CONSTRAINTS_ANALYSIS_NS } from '@/store/flights';

import NotifAlphaTangoRequirements from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoRequirements.vue';

export default {
  name: 'NotifAlphaTangoPrefectureForm',
  components: { NotifAlphaTangoRequirements },
  props: {
    flight: Object,
    update: {
      type: Boolean,
      default: false,
    },
    notificationsToUpdate: {
      type: Array,
      required: false,
      default: () => [],
    },
    asApproval: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    let minDate;
    const today = new Date();
    if (this.update && this.notificationsToUpdate.length === 1) {
      minDate = today;
    } else {
      minDate = new Date(today.setDate(today.getDate() + 6));
    }
    let requestData;
    const { pilots } = this.$store.state.pilots;
    const connectedPilotId = this.$store.state.authentication.user.contact_id;
    const connectedPilot = pilots.find((p) => p.id === connectedPilotId);
    let mainPilot = this.flight.pilots.find((p) => p.id === connectedPilotId);
    if (!mainPilot) {
      mainPilot = this.flight.pilots.at(0);
    }
    const mainPilotId = mainPilot?.id || null;
    if (
      this.update && this.notificationsToUpdate[0]
      && this.notificationsToUpdate[0].user_form_data
    ) {
      const pilotId = this.notificationsToUpdate[0].user_form_data.main_pilot_id;
      const dateStart = this.notificationsToUpdate[0].user_form_data.date_start;
      const dateEnd = this.notificationsToUpdate[0].user_form_data.date_end;
      requestData = {
        dateStart: new Date(dateStart) < minDate ? null : dateStart,
        dateEnd: new Date(dateEnd) < minDate ? null : dateStart,
        address: this.notificationsToUpdate[0].user_form_data.address,
        additionalInfo: this.notificationsToUpdate[0].user_form_data.additional_info,
        mainPilotId: this.flight.pilots.find((p) => p.id === pilotId) ? pilotId : mainPilotId,
        otherPilots: '',
        lastName: connectedPilot ? connectedPilot.last_name : '',
        firstName: connectedPilot ? connectedPilot.first_name : '',
        occupation: connectedPilot ? connectedPilot.function : '',
      };
    } else {
      requestData = {
        dateStart: new Date(this.flight.date_start) < minDate ? null : this.flight.date_start,
        dateEnd: new Date(this.flight.date_end) < minDate ? null : this.flight.date_end,
        address: this.flight.address,
        additionalInfo: '',
        mainPilotId,
        otherPilots: '',
        lastName: connectedPilot ? connectedPilot.last_name : '',
        firstName: connectedPilot ? connectedPilot.first_name : '',
        occupation: connectedPilot ? connectedPilot.function : '',
      };
    }
    return {
      notificationsInfos: {
        number_notifications: 0,
        list_areas_overlap: [],
        idsAreasNoCommuneFound: [],
        is_overlap: false,
      },
      valid: true,
      requestData,
      dateRange: [requestData.dateStart, requestData.dateEnd],
      minDatePicker: format(minDate, 'yyyy-MM-dd'),
      datesMenu: false,
      today: format(new Date(), 'd MMMM yyyy', { locale: fr }),
      rules: {
        required: (value) => !!value || '',
        datesRequired: (value) => (!!value.split(' - ')[0] && !!value.split(' - ')[1]) || '',
        maxLength: (value) => (
          value.length < 2800
          || this.$gettext('Max length of 2800 characters exceeded')
        ),
        occupation: (value) => (
          value.length < 50
          || this.$gettext('Max length of 50 characters exceeded')
        ),
      },
      loadingNotifInfo: false,
      sendingFlightNotifyRequest: false,
      connectedPilotId: this.$store.state.authentication.user.contact_id,
      sendHSSNotif: false,
      handledByAdmin: false,
    };
  },
  computed: {
    isUserHijacked() {
      return this.$store.state.authentication.hijackedUserId !== null;
    },
    flightAreasNumber() {
      return this.flight.areas.length;
    },
    flightAreasWithoutNotification() {
      if (this.notificationsData.idsAreasNoCommuneFound) {
        return this.flight.areas.filter(
          (area) => this.notificationsData.idsAreasNoCommuneFound.includes(area.id),
        );
      }
      return [];
    },
    headerAlertText() {
      let text = '';
      if (this.update) {
        if (this.notificationsToUpdate.length > 1) {
          text += this.$gettextInterpolate(
            this.$gettext(`When clicking in Send, %{n_notifs} not updated notifications will be
              cancelled and new will be sent.`),
            { n_notifs: this.notificationsToUpdate.length },
          );
        } else {
          text += this.$gettext(`When clicking in Send, the notification not updated will be
            cancelled and new will be sent.`);
        }
      }
      if (this.flightHasManyAreas) {
        text += this.$gettextInterpolate(
          this.$gettext('Your mission is composed of %{numberZones} zones.'),
          { numberZones: this.flight.areas.length },
        );
        text += ' ';
        if (this.notificationsData.areasOverlapCount > 1) {
          if (this.notificationsData.areasOverlapCount === this.flight.areas.length) {
            text += this.$gettext('Your flight zones are overlapping multiple departments:');
          } else {
            text += this.$gettextInterpolate(
              this.$gettext(`%{areasOverlapCount} of your flight zones are overlapping multiple
                departments:`),
              { areasOverlapCount: this.notificationsData.areasOverlapCount },
            );
          }
          text += ' ';
          let areaOverlapCount = 0;
          for (let i = 0; i < this.notificationsData.listAreasOverlap.length; i += 1) {
            if (this.notificationsData.listAreasOverlap[i].nb_departments > 1) {
              areaOverlapCount += 1;
              text += this.$gettextInterpolate(
                this.$gettext('the zone "%{areaName}" overlap %{nbDepartments} departments'),
                {
                  areaName: this.notificationsData.listAreasOverlap[i].area_name,
                  nbDepartments: this.notificationsData.listAreasOverlap[i].nb_departments,
                },
              );
              if (areaOverlapCount < this.notificationsData.areasOverlapCount - 1) {
                text += ', ';
              } else if (areaOverlapCount === this.notificationsData.areasOverlapCount - 1) {
                text += (` ${this.$gettext('and')} `);
              }
            }
          }
          text += '. ';
          text += this.$gettext('A notification will be sent for each department.');
          text += ' ';

          const nbOtherZones = this.flight.areas.length - this.notificationsData.areasOverlapCount;
          if (nbOtherZones) {
            text += this.$ngettext(
              'For the other zone,',
              'For the other zones,',
              nbOtherZones,
            );
            text += ' ';
            const nbOtherNotifs = (
              this.notificationsData.notifsTotalCount
              - this.notificationsData.notifsCountForAreasOverlap
            );
            if (nbOtherNotifs) {
              if (nbOtherNotifs === 1) {
                text += this.$gettext('one notification will be send.');
              } else {
                text += this.$gettextInterpolate(
                  this.$gettext('%{nbOtherNotifs} notifications will be send.'),
                  { nbOtherNotifs },
                );
              }
              text += ' ';
            }
            if (nbOtherNotifs === 0) {
              text += this.$ngettext(
                'it will be added to one of the precedent notifications.',
                'they will be added to one of the precedent notifications.',
                nbOtherZones,
              );
              text += ' ';
            }
          }
        } else if (this.notificationsData.areasOverlapCount === 1) {
          this.notificationsData.listAreasOverlap.forEach((zone) => {
            if (zone.nb_departments > 1) {
              text += this.$gettextInterpolate(
                this.$gettext(`Your zone %{areaName} overlap %{nbDepartments} departments. One
                  notification will be sent for each of these departments.`),
                {
                  areaName: zone.area_name,
                  nbDepartments: zone.nb_departments,
                },
              );
              text += ' ';
              const nbOtherZones = (
                this.flight.areas.length - this.notificationsData.areasOverlapCount
              );
              if (nbOtherZones) {
                text += this.$ngettext(
                  'For the other zone,',
                  'For the other zones,',
                  nbOtherZones,
                );
                text += ' ';
                const nbOtherNotifs = (
                  this.notificationsData.notifsTotalCount
                  - this.notificationsData.notifsCountForAreasOverlap
                );
                if (nbOtherNotifs) {
                  if (nbOtherNotifs === 1) {
                    text += this.$gettext('one notification will be send.');
                  } else {
                    text += this.$gettextInterpolate(
                      this.$gettext('%{nbOtherNotifs} notifications will be send.'),
                      { nbOtherNotifs },
                    );
                  }
                  text += ' ';
                }
                if (nbOtherNotifs === 0) {
                  text += this.$ngettext(
                    'it will be added to one of the precedent notifications.',
                    'they will be added to one of the precedent notifications.',
                    nbOtherZones,
                  );
                  text += ' ';
                }
              }
            }
          });
        }
      } else if (this.notificationsData.isOverlap) {
        this.notificationsData.listAreasOverlap.forEach((zone) => {
          if (zone.nb_departments > 1) {
            text += this.$gettextInterpolate(
              this.$gettext(`Your flight zone overlap %{nbOverlap} departments. One notification
                will be sent for each of these departments.`),
              { nbOverlap: zone.nb_departments },
            );
            text += ' ';
          }
        });
      }
      const notifForNotOverlapCount = (
        this.notificationsData.notifsTotalCount - this.notificationsData.notifsCountForAreasOverlap
      );
      const areasNotOverlapCount = (
        this.flight.areas.length - this.notificationsData.areasOverlapCount
      );
      if (notifForNotOverlapCount < areasNotOverlapCount) {
        text += this.$gettext('Some areas will be grouped by commune in a single notification.');
        text += ' ';
      }
      // Add information if no commune is found for an area intersecting a department
      if (this.flightAreasWithoutNotification.length > 0) {
        const areaNames = this.flightAreasWithoutNotification.map((area) => area.name);
        text += this.$ngettext(
          'The zone ',
          'The zones ',
          this.flightAreasWithoutNotification.length,
        );

        const lastArea = areaNames.pop();
        if (this.flightAreasWithoutNotification.length > 1) {
          text += (
            `${areaNames.join(', ')} ${this.$gettext('and')} ${lastArea}`
          );
        } else {
          text += lastArea;
        }

        text += this.$ngettext(
          ' will not be notified because no related commune has been found.',
          ' will not be notified because no related commune has been found.',
          this.flightAreasWithoutNotification.length,
        );
      }
      if (this.notificationsData.notifsTotalCount > 1) {
        text += this.$gettextInterpolate(
          this.$gettext(`In total, %{numberNotifs} notifications will be sent to the local
            prefecture (subject to a minimum notice period of 5 working days) to cover all your
            flight zone.`),
          { numberNotifs: this.notificationsData.notifsTotalCount },
        );
      } else if (this.notificationsData.notifsTotalCount === 1) {
        text += this.$gettext(`A notification will be sent to the local prefecture (subject to a
          minimum notice period of 5 working days) to cover all your flight zone.`);
      }

      return text;
    },
    dateRangeText() {
      const range = [this.requestData.dateStart, this.requestData.dateEnd];
      return range.join(' - ');
    },
    labels() {
      return {
        dateRange: this.$gettext('Dates range *'),
        address: this.$gettext('Address and site description *'),
        additionalInfo: this.$gettext('Relevant information *'),
        mainPilot: this.$gettext('Main pilot involved in the approval'),
        otherPilots: this.$gettext('Other pilots'),
        declarant: this.$gettext('Registrant associated with the request *'),
        lastName: `${this.$gettext('Declarant name')} *`,
        firstName: `${this.$gettext('Declarant first name')} *`,
        occupation: this.$gettext('Declarant function'),
        flightIsHSS: this.$gettext('I want to notify a outside standard scenario flight'),
        notifHandledByAdmin: this.$gettext('Notification handled by an admin'),
      };
    },
    placeholders() {
      return {
        address: this.$gettext(`Indicate here the site address and give all relevant indication.
          Click on information icon for detail about expected information.`),
        additionalInfo: this.$gettext('Additional information'),
        otherPilots: this.$gettext('Other pilots involved in the mission'),
        lastName: this.$gettext('Declarant name'),
        firstName: this.$gettext('Declarant first name'),
        occupation: this.$gettext('Declarant function'),
      };
    },
    tooltips() {
      return {
        address: this.$gettext(`Indicate here the site address and give relevant information.
          Indicate:<br>- if the flight takes place above public space (*) (if so, describe measures
          taken to secure public space during the flights)<br>- if other sites are planned during
          the same missions (separated notifications shall be made for these other sites)<br>(*)
          Reminder: public space in urban areas consists in public streets as well as places open
          to public, i.e. which access is open (beaches, public parks...) or possible under
          obligations if any person who would like to do so can fulfill these obligations (for
          example by buying a ticket).`),
        longFlightPeriod: this.$gettext(`Prefecture notification: the maximal duration is 7 days
          (first and last days included). Any notification for a longer period, due to a recurring
          and clearly identified operations need, must be accompanied by appropriate
          justifications. Exemples for such justifications are:<br>- Contract from the final
          customer<br>- Recurring use of a private flight area, with the explicit owner
          permission.`),
        additionalInfo: this.$gettext(`Indicate here any relevant information allowing the
          prefecture to better understand operations nature. Precise:<br>- days and timetables
          planned, motivating these choices if necessary (for example closed days for a museum, or
          closed hours for a school)<br>- reasons for the mission: what is being filmed, for which
          final customer, what for, etc.`),
        otherPilots: this.$gettext(`Contact information for other pilots involved in the mission
          will be added to the useful information section transmitted in the AlphaTango
          notification`),
      };
    },
    texts() {
      return {
        recipient: this.$gettext('the prefecture in place'),
        hss: this.$gettext(`This flight is outside of standard scenario, if you not respect minimal
          prior of notifications to prefectures, Alpha Tango will try to send them to Ministry of
          the Armed Forces.`),
        needAdditionalInfo: this.$gettext(`If the prefecture requires additional information or
          documents, you will receive an email to let you know and you will be able to bring these
          elements directly in your Clearance interface.`),
        declarantAlert: this.$gettext(`I hereby attest the sincerity of all declared information
          and I declare that the flights will take placed in described conditions.<br>I understand
          the email acknowledging the reception of the present declaration does not mean the flight
          complies with all applicable regulations, and that I have to make sure of it and if
          applicable get all necessary authorizations.<br>I will comply to all regulations relevant
          for planned operations, especially:<br>- I will comply to all regulations regarding
          aircrafts and their use, in particular: I will not use the aircraft in conditions that
          may cause a risk for other aircrafts, people or properties on the ground, including in
          case of probable malfunction<br>- I will respect image copyright, and the rights to
          privacy and property of other people<br>I attest that an insurance covering the risks
          associated with the planned operations has been taken out.`),
        longFlightPeriodAlert: this.$gettextInterpolate(
          this.$gettext(`Your mission lasts more than 7 days. Justify here the actual need and
            provide any justification document to %{email}`),
          { email: this.flight.prefecture_email },
        ),
      };
    },
    notificationsData() {
      const listAreasOverlap = this.notificationsInfos.list_areas_overlap;
      const areasOverlapCount = listAreasOverlap.length;
      const notifsCountForAreasOverlap = listAreasOverlap.map(
        (zone) => zone.nb_departments, 0,
      ).reduce((a, b) => a + b, 0);
      return {
        notifsTotalCount: this.notificationsInfos.number_notifications,
        listAreasOverlap,
        isOverlap: this.notificationsInfos.is_overlap,
        areasOverlapCount,
        notifsCountForAreasOverlap,
        idsAreasNoCommuneFound: this.notificationsInfos.ids_areas_no_commune_found,
      };
    },
    hssNotificationsAllowed() {
      return this.$store.getters['authentication/hssNotificationsAllowed'];
    },
    exploitationAlphaTangoUids() {
      return this.$store.state.exploitants.exploitationDetails.alphatango_uids;
    },
    exploitationPilots() {
      return this.$store.getters['pilots/activePilots'];
    },
    flightHasPilots() {
      return this.flight.pilots.length > 0;
    },
    flightDrones() {
      return this.flight.drones;
    },
    flightHasDrones() {
      return this.flightDrones.length > 0;
    },
    hasDronesOverWeightAndNotCaptive() {
      return this.flightDrones.some((d) => !d.captive && (d.weight === null || d.weight >= 8));
    },
    flightIsHSS() {
      return !this.flight.pilot_in_sight;
    },
    flightIsInAlpesMaritimes() {
      return this.flight.code_departement === '06';
    },
    flightHasManyAreas() {
      return this.flight.areas.length > 1;
    },
    longFlightPeriod() {
      const numberOfDays = differenceInCalendarDays(
        new Date(this.requestData.dateEnd),
        new Date(this.requestData.dateStart),
      );
      return numberOfDays >= 7;
    },
    missingData() {
      return {
        missingATNumber: !this.exploitationAlphaTangoUids.length,
        missingDrone: !this.flightHasDrones,
        missingPilot: !this.flightHasPilots,
      };
    },
    hasMissingData() {
      return Object.values(this.missingData).includes(true);
    },
    canRequestNotifications() {
      return (
        !this.hasMissingData
        && !this.flightIsInAlpesMaritimes
        && (!this.flightIsHSS || this.sendHSSNotif)
      );
    },
    isConfirmButtonDisabled() {
      return (
        !this.canRequestNotifications
        || this.sendingFlightNotifyRequest
        || !this.valid
        || this.loadingNotifInfo
      );
    },
    requestPayload() {
      let { additionalInfo } = this.requestData;
      if (this.requestData.otherPilots) {
        additionalInfo += this.$gettextInterpolate(
          this.$gettext('\nOther pilots:\n%{ otherPilots }'),
          { otherPilots: this.requestData.otherPilots },
          true,
        );
      }
      const payload = {
        date_start: this.requestData.dateStart,
        date_end: this.requestData.dateEnd,
        address: this.requestData.address,
        main_pilot_id: this.requestData.mainPilotId,
        additional_info: additionalInfo,
        declarant_last_name: this.requestData.lastName,
        declarant_first_name: this.requestData.firstName,
        declarant_occupation: this.requestData.occupation,
      };
      if (this.isUserHijacked) {
        payload.disclaimer = !this.handledByAdmin;
      }
      return payload;
    },
  },
  watch: {
    dateRange(range) {
      [this.requestData.dateStart, this.requestData.dateEnd] = range;
      if (this.requestData.dateStart > this.requestData.dateEnd) {
        [
          this.requestData.dateStart,
          this.requestData.dateEnd,
        ] = [
          this.requestData.dateEnd,
          this.requestData.dateStart,
        ];
      }
    },
  },
  mounted() {
    if (this.canRequestNotifications) {
      this.getNotificationsInfos();
      this.updateOtherPilots();
      if (this.isUserHijacked && this.flight.was_entrusted) {
        this.handledByAdmin = true;
      }
    }
  },
  methods: {
    getNotificationsInfos() {
      this.loadingNotifInfo = true;
      APIService.getPrefectureNotificationsInfo(this.flight.id)
        .then(({ data }) => {
          this.notificationsInfos = data;
        })
        .finally(() => {
          this.loadingNotifInfo = false;
        });
    },
    confirm() {
      if (this.asApproval) {
        this.requestApproval();
      } else {
        this.notifyFlightPrefecture();
      }
    },
    requestApproval() {
      this.sendingFlightNotifyRequest = true;
      this.$emit(
        'create-approval',
        {
          pilotId: this.requestPayload.main_pilot_id,
          approvalDetails: this.requestPayload.additional_info,
          prefectureNotifPayload: this.requestPayload,
        },
      );
    },
    notifyFlightPrefecture() {
      if (this.$refs.form.validate()) {
        this.sendingFlightNotifyRequest = true;
        if (this.update) {
          APIService.updatePrefectureNotifs(
            this.flight.id,
            this.notificationsToUpdate.map((n) => n.id),
            this.requestPayload,
          )
            .then(({ data }) => {
              this.showMessage(data, 'success');
            })
            .finally(() => {
              this.sendingFlightNotifyRequest = false;
              this.$emit('close-form');
              this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id });
            });
        } else {
          APIService.notifyPrefecture(
            this.flight.id,
            this.requestPayload,
          )
            .then(({ data }) => {
              this.showMessage(data, 'success');
            })
            .finally(() => {
              this.sendingFlightNotifyRequest = false;
              this.$emit('close-form');
              this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id });
            });
        }
      }
    },
    updateOtherPilots() {
      let otherPilots = '';
      this.flight.pilots.forEach((pilot) => {
        if (pilot.id !== this.requestData.mainPilotId) {
          const civility = pilot.gender === 'F' ? 'MME' : 'M';
          otherPilots += this.$gettextInterpolate(
            this.$gettext(`- Civility: %{civility}; Name: %{lastName}; First name: %{firstName};
              Phone number: %{phone}; Email: %{email}\n`),
            {
              civility,
              lastName: pilot.last_name,
              firstName: pilot.first_name,
              phone: filterPhoneNumber(pilot.phone_number),
              email: pilot.email,
            },
          );
        }
      });
      this.requestData.otherPilots = otherPilots;
    },
    updateDeclarant() {
      const declarant = this.exploitationPilots.find((p) => p.id === this.connectedPilotId);
      this.requestData.lastName = declarant.last_name;
      this.requestData.firstName = declarant.first_name;
      this.requestData.occupation = declarant.function;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.v-text-field ::v-deep .v-label {
  color: $color-info;
}
.small-icon {
  font-size: 130%;
  padding-left: 10px;
}
.declarant-alert {
  color: black;
  padding: 0.8em;
  font-size: 90%;
}
.declarant-alert__line {
  margin-bottom: 3px;
}
.declaration-date {
  float: right;
  font-style: oblique;
  color: $color-info;
}
.large {
  width: 145px;
}

</style>
