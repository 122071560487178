import Vue from 'vue';

import APIService from '@/services/api';

import NotificationFactory from '@/components/Header/notifications/NotificationFactory';

const factory = new NotificationFactory();

const namespace = 'notifications';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
const STORE_NEW_NOTIFICATION = 'STORE_NEW_NOTIFICATION';

export const GET_NOTIFICATIONS_NS = `${namespace}/${GET_NOTIFICATIONS}`;
export const MARK_NOTIFICATIONS_READ_NS = `${namespace}/${MARK_NOTIFICATIONS_READ}`;
export const STORE_NEW_NOTIFICATION_NS = `${namespace}/${STORE_NEW_NOTIFICATION}`;

function initialState() {
  return { notifications: [] };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    newNotifications: (state) => state.notifications.filter((n) => n.viewed === false),
    readNotifications: (state) => state.notifications.filter((n) => n.viewed === true),
  },
  mutations: {
    [SET_NOTIFICATIONS](state, notifications) {
      Vue.set(state, 'notifications', notifications);
    },
    [STORE_NEW_NOTIFICATION](state, { newNotification }) {
      if (!state.notifications.find((n) => n.id === newNotification.id)) {
        state.notifications.unshift(newNotification);
      }
    },
    [MARK_NOTIFICATIONS_READ](state, { notificationIds }) {
      notificationIds.forEach((id) => {
        const notif = state.notifications.find((obj) => obj.id === id);
        notif.markAsRead();
      });
    },
  },
  actions: {
    [GET_NOTIFICATIONS]({ commit }) {
      APIService.getNotifications({ last_n_days: 7 })
        .then(({ data }) => {
          const notifications = [];
          data.forEach((rawNotification) => {
            const notifification = factory.create(rawNotification);
            if (notifification) notifications.push(notifification);
          });
          commit(SET_NOTIFICATIONS, notifications);
        });
    },
    [STORE_NEW_NOTIFICATION]({ commit, dispatch }, { newNotification }) {
      const notification = factory.create(newNotification);
      if (notification) {
        notification.atReceive(dispatch);
        commit(STORE_NEW_NOTIFICATION, { newNotification: notification });
      }
    },
    [MARK_NOTIFICATIONS_READ]({ commit }, { notificationIds }) {
      commit(MARK_NOTIFICATIONS_READ, { notificationIds });
      APIService.markNotificationsRead({ ids: notificationIds });
    },
  },
};
