<template>
  <div class="full-height d-flex flex-column">
    <v-container class="px-1 px-md-3 flex-grow-0">
      <div class="headline mb-4">
        <translate>User</translate>
      </div>
      <!-- LANGUAGE -->
      <v-row
        no-gutters
        align="end"
        class="py-3"
      >
        <v-col
          cols="12"
          md="4"
          class="pb-2"
        >
          <div
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            Language
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="pb-2"
        >
          <v-select
            :items="getLang"
            v-model="$language.current"
            :label="$gettext('Choose a language')"
            single-line
            hide-details
            class="pa-0 ma-0"
            @change="setAPILanguage()"
          />
        </v-col>
      </v-row>
      <!-- USERNAME -->
      <v-row
        v-if="userData"
        no-gutters
        align="end"
        class="py-3"
      >
        <v-col
          cols="12"
          md="4"
          class="pb-2"
        >
          <div
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            Username
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="pb-2"
        >
          {{ userData.username }}
        </v-col>
      </v-row>
      <!-- DRONIST NAME -->
      <v-row
        v-if="userData && isUserDronist"
        no-gutters
        align="end"
        class="py-3"
      >
        <v-col
          cols="12"
          md="4"
          class="pb-2"
        >
          <div
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            Pilot name
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="pb-2"
        >
          {{ userData.contact_full_name }}
        </v-col>
      </v-row>
      <template v-if="userData">
        <!-- EMAIL -->
        <template v-if="emailEditing">
          <v-row
            no-gutters
            align="center"
            class="pb-1 pt-3 pt-md-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <div
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                Email
              </div>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-form
                lazy-validation
                v-model="emailValid"
              >
                <v-text-field
                  v-model="email"
                  :rules="rules.email"
                  class="p-0"
                />
              </v-form>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            justify="end"
            class="pb-3"
          >
            <v-btn
              class="grey lighten-4 info--text mx-2 my-1"
              depressed
              @click="emailEditing=false"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              class="grey lighten-4 primary--text mx-2 my-1"
              depressed
              :disabled="!emailValid"
              :loading="emailLoading"
              @click="changeEmail()"
            >
              <translate>Validate email</translate>
            </v-btn>
          </v-row>
        </template>
        <template v-else>
          <v-row
            no-gutters
            align="end"
            class="py-3"
          >
            <v-col
              cols="12"
              md="4"
              class="pb-2"
            >
              <div
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                Email
              </div>
            </v-col>
            <v-col
              cols="11"
              md="7"
              class="pb-2 truncated"
            >
              {{ email }}
            </v-col>
            <v-col
              cols="1"
              class="pb-2 d-flex justify-end"
            >
              <v-tooltip
                v-if="isUserDronist"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <translate>Edit your email account in pilots list</translate>
              </v-tooltip>
              <v-btn
                v-else
                text
                icon
                class="edit-icon"
                @click="emailEditing=true"
              >
                <v-icon>icon-edit_line</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <!-- Password -->
        <template v-if="canEditPassword">
          <template v-if="passwordEditing">
            <v-row
              no-gutters
              align="center"
              class="pb-1 pt-3 pt-md-0"
            >
              <v-col
                cols="12"
                md="4"
              >
                <div
                  class="body-2 grey--text text--darken-2"
                  v-translate
                >
                  Password
                </div>
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <v-form
                  v-model="passwordValid"
                  lazy-validation
                >
                  <v-text-field
                    :type="hiddenPassword ? 'password' : 'text'"
                    v-model="password"
                    :rules="rules.password"
                    :append-icon="hiddenPassword ? 'visibility' : 'visibility_off'"
                    @click:append="hiddenPassword=!hiddenPassword"
                    class="p-0"
                  />
                </v-form>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              justify="end"
              class="pb-3"
            >
              <v-btn
                class="grey lighten-4 info--text mx-2 my-1"
                depressed
                @click="passwordEditing=false"
              >
                <translate>Cancel</translate>
              </v-btn>
              <v-btn
                class="grey lighten-4 primary--text mx-2 my-1"
                depressed
                :disabled="!passwordValid"
                :loading="passwordLoading"
                @click="changePassword()"
              >
                <translate>Change password</translate>
              </v-btn>
            </v-row>
          </template>
          <template v-else>
            <v-row
              no-gutters
              align="end"
              class="py-3"
            >
              <v-col
                cols="12"
                md="4"
                class="pb-2"
              >
                <div
                  class="body-2 grey--text text--darken-2"
                  v-translate
                >
                  Password
                </div>
              </v-col>
              <v-col
                cols="11"
                md="7"
                class="pb-2"
              >
                ••••••••••
              </v-col>
              <v-col
                cols="1"
                class="pb-2 d-flex justify-end"
              >
                <v-btn
                  text
                  icon
                  class="edit-icon"
                  @click="passwordEditing=true"
                >
                  <v-icon>icon-edit_line</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </template>
      </template>
      <!-- Dronist additional settings -->
      <template v-if="isUserDronist">
        <!-- Receives newsletter -->
        <template v-if="isUserMainContact && mjNewsletterSubActivated == false">
          <v-row
            no-gutters
            align="end"
            :class="receivesNewsletterEditing ? 'pb-1 pt-3 pt-md-0' : 'pt-3 pb-5'"
          >
            <v-col
              :cols="receivesNewsletterEditing ? '12' : '11'"
              class="pb-2"
            >
              <v-checkbox
                v-model="receivesNewsletter"
                :disabled="!receivesNewsletterEditing"
                hide-details
              >
                <template v-slot:label>
                  <span>
                    {{ texts.newsletter }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              v-if="!receivesNewsletterEditing"
              cols="1"
              class="pb-2 d-flex justify-end"
            >
              <v-btn
                text
                icon
                class="edit-icon"
                @click="receivesNewsletterEditing=true"
              >
                <v-icon>icon-edit_line</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="receivesNewsletterEditing"
            no-gutters
            justify="end"
            class="pb-5"
          >
            <v-btn
              class="grey lighten-4 info--text mx-2 my-1"
              depressed
              @click="resetReceivesNewsletterEditing()"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              class="grey lighten-4 primary--text mx-2 my-1"
              depressed
              :loading="receivesNewsletterLoading"
              @click="changeReceivesNewsletter()"
            >
              <translate>Validate</translate>
            </v-btn>
          </v-row>
        </template>
      </template>
    </v-container>
  </div>
</template>

<script>
import API from '@/services/api';

import {
  CHANGE_EMAIL_NS,
  CHANGE_RECEIVES_NEWSLETTER_NS,
} from '@/store/authentication';

export default {
  name: 'UserSettings',
  data() {
    return {
      emailLoading: false,
      emailEditing: false,
      emailNewValue: null,
      emailValid: true,
      passwordLoading: false,
      passwordEditing: false,
      hiddenPassword: true,
      password: '',
      passwordValid: true,
      requestAccountDeletionDialog: false,
      mjNewsletterSubActivated: false,
      receivesNewsletterEditing: false,
      receivesNewsletterLoading: false,
      receivesNewsletter: null,
      rules: {
        email: [
          (v) => !!v || this.$gettext('Email required'),
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$gettext('Email invalid');
          },
        ],
        password: [
          (v) => !!v || this.$gettext('Password is required'),
        ],
      },
    };
  },
  computed: {
    texts() {
      return {
        newsletter: this.$gettext(`I agree to receive Clearance newsletter (once a month, we
          promise 😉)`),
      };
    },
    getLang() {
      return Object.keys(this.$language.available).map((currentValue) => (
        {
          value: currentValue,
          text: this.$language.available[currentValue],
        }
      ));
    },
    isUserDronist() {
      return this.$store.getters['authentication/isUserDronist'];
    },
    isUserAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    isUserMainContact() {
      return this.$store.getters['authentication/isUserExploitantMainContact'];
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    contactId() {
      return this.userData.contact_id;
    },
    canEditPassword() {
      return window.location.origin !== process.env.VUE_APP_DELTA_ORIGIN_URL;
    },
    email: {
      get() {
        return this.userData.email;
      },
      set(newValue) {
        this.emailNewValue = newValue;
      },
    },
  },
  mounted() {
    this.receivesNewsletter = this.userData.contact_subscribed_to_newsletter;
    this.mjNewsletterSubActivated = this.userData.mailjet_newsletter_subscription_activated;
  },
  methods: {
    async changeEmail() {
      const payload = { email: this.emailNewValue };
      this.emailLoading = true;
      await API.modifyUser(this.userData.id, payload)
        .then(() => {
          this.$store.dispatch(CHANGE_EMAIL_NS, this.emailNewValue)
            .then(() => {
              this.showMessage(this.$gettext('Email has been updated.'), 'success');
            });
        })
        .finally(() => {
          this.emailLoading = false;
          this.emailEditing = false;
        });
    },
    async changePassword() {
      const payload = { password: this.password };
      this.passwordLoading = true;
      await API.modifyUser(this.userData.id, payload)
        .then(() => {
          this.showMessage(this.$gettext('Password updated.'), 'success');
        })
        .finally(() => {
          this.passwordLoading = false;
          this.passwordEditing = false;
        });
    },
    resetReceivesNewsletterEditing() {
      this.receivesNewsletter = this.userData.contact_subscribed_to_newsletter;
      this.receivesNewsletterEditing = false;
    },
    async changeReceivesNewsletter() {
      this.receivesNewsletterLoading = true;
      const payload = { subscribed_to_newsletter: this.receivesNewsletter };
      await API.updateContactSettings(this.contactId, payload)
        .then(() => {
          this.$store.dispatch(CHANGE_RECEIVES_NEWSLETTER_NS, this.receivesNewsletter)
            .then(() => {
              this.showMessage(
                this.$gettext('Your choice has been taken into account'),
                'success',
              );
            });
        })
        .finally(() => {
          this.receivesNewsletterLoading = false;
          this.receivesNewsletterEditing = false;
        });
    },
    setAPILanguage() {
      API.setLanguage(this.$language.current);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.full-height {
  height: 100%;
}
.edit-icon {
  max-width: 24px;
  max-height: 24px;
}

</style>
