<template>
  <v-card
    class="mx-auto"
    max-width="400"
    min-width="200"
    max-height="460"
    tile
    light
  >
    <template>
      <!-- Loader -->
      <div
        v-if="loading"
        class="d-flex flex-column justify-center align-center pa-4"
      >
        <span v-translate>Loading livestreams...</span>
        <v-progress-circular
          size="24"
          width="2"
          color="primary"
          indeterminate
          class="mt-1"
        />
      </div>
      <template v-else>
        <v-list light>
          <!-- Demo video -->
          <v-list-item
            v-if="livestreams.length < 4"
            @click.prevent="openDemoLivestreamPlayer()"
          >
            <v-list-item-avatar class="mx-0 my-auto">
              <v-icon class="pr-3">mdi-play-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-translate>Livestream tutorial video</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Livestreams list -->
          <template v-for="(livestream, n) in livestreams">
            <v-hover
              v-slot="{ hover }"
              :key="n"
            >
              <v-list-item
                three-line
                @click.prevent="openLivestreamPlayer(livestream)"
                :value="livestream.id"
              >
                <v-list-item-avatar class="mx-0 my-auto">
                  <v-icon
                    v-if="livestream.status==='live'"
                    color="red"
                    class="pr-3"
                  >
                    mdi-play-circle
                  </v-icon>
                  <v-icon
                    v-if="livestream.status!=='live'"
                    class="pr-3"
                  >
                    mdi-play-circle-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content :class="getStyleClass(livestream)">
                  <v-list-item-title>
                    {{ livestream.label }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ livestream.start | toLocaleDatetime }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <template v-if="livestream.flight || livestream.flight_name">
                      {{ livestream.flight }} {{ livestream.flight_name }}
                    </template>
                    <template v-else>
                      <translate>Flight to be determined</translate>
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    v-show="hover && !selectedLivestream"
                    @click.stop="editLivestream(livestream.id)"
                  >
                    mdi-pencil-outline
                  </v-icon>
                  <v-icon
                    v-if="livestream.status === 'ended'"
                    v-show="hover && !livestreamToDelete"
                    @click.stop="deleteLivestream(livestream.id)"
                    class="mt-1"
                  >
                    mdi-delete-outline
                  </v-icon>
                  <v-icon
                    v-if="livestream.status === 'live'"
                    v-show="hover && !livestreamToStop"
                    @click.stop="stopLivestream(livestream.id)"
                    class="mt-1"
                  >
                    mdi-stop-circle-outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-hover>
          </template>
        </v-list>
        <!-- No livestreams -->
        <v-card-text
          v-if="!livestreams.length"
          class="text-center"
          key="no-notif"
        >
          <translate>No livestreams currently.</translate>
        </v-card-text>
      </template>
    </template>
  </v-card>
</template>

<script>
import {
  SET_LIVESTREAM_TO_DELETE_NS,
  SET_LIVESTREAM_TO_STOP_NS,
  SET_SELECTED_LIVESTREAM_NS,
} from '@/store/livestreams';

export default {
  name: 'LivestreamsHeaderList',
  computed: {
    livestreams() {
      return this.$store.getters['livestreams/latestLivestreams'];
    },
    selectedLivestream() {
      return this.$store.getters['livestreams/selectedLivestream'] !== undefined;
    },
    livestreamToDelete() {
      return this.$store.getters['livestreams/livestreamToDelete'] !== undefined;
    },
    livestreamToStop() {
      return this.$store.getters['livestreams/livestreamToStop'] !== undefined;
    },
    loading() {
      return this.$store.state.livestreams.loadingLivestreams;
    },
  },
  methods: {
    getStyleClass(livestream) {
      return livestream.status === 'live' ? 'current-livestream' : 'ended-livestream';
    },
    openLivestreamPlayer(livestream) {
      const routeData = this.$router.resolve({
        name: 'livestream',
        params: { name: livestream.public_name },
      });
      window.open(routeData.href, '_blank');
    },
    openDemoLivestreamPlayer() {
      const routeData = this.$router.resolve({ name: 'livestream-demo' });
      window.open(routeData.href, '_blank');
    },
    editLivestream(livestreamId) {
      this.$store.commit(SET_SELECTED_LIVESTREAM_NS, livestreamId);
    },
    deleteLivestream(livestreamId) {
      this.$store.commit(SET_LIVESTREAM_TO_DELETE_NS, livestreamId);
    },
    stopLivestream(livestreamId) {
      this.$store.commit(SET_LIVESTREAM_TO_STOP_NS, livestreamId);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.current-livestream {
  font-weight: 500;
}
.ended-livestream {
  font-weight: 350;
  opacity: 0.8;
}

</style>
