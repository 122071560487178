<template>
  <v-card>
    <!-- HEADER -->
    <v-card-title class="headline font-weight-regular primary white--text mb-4">
      <div class="pb-1">
        <v-img
          :src="uspacekeeperLogoPath"
          max-width="100"
        />
      </div>
      <div class="pb-1 pl-6">
        <div class="body-2">
          {{ header.title }}
        </div>
        <div class="caption">
          {{ header.subtitle }}
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <!-- EXTERNAL INFORMATION (capabilities)-->
      <v-row
        v-if="information.length"
        align="center"
        class="my-2"
      >
        <v-col
          cols="12"
          class="py-2"
        >
          <span
            class="subtitle-1 primary--text section-header"
            v-translate
          >
            REMINDER OF THE INSTRUCTIONS TRANSMITTED BY U-SPACEKEEPER
          </span>
        </v-col>
        <v-col
          cols="12"
          class="pt-0 pb-2"
        >
          <FlightConstraintExternalInformation :information="information" />
        </v-col>
      </v-row>
      <!-- APPROVAL FORM FOR USK -->
      <div v-if="approvalIsValid || approvalInError">
        <v-stepper
          v-model="step"
          vertical
        >
          <!-- STEP 1: FLIGHT AREAS SELECTION -->
          <template v-if="hasManyAreas">
            <v-stepper-step
              :complete="step > 1"
              step="1"
              editable
            >
              <span v-translate>Select Areas</span>
              <div
                v-if="step > 1 && flightAreasSelectedNames"
                class="mt-2"
              >
                <v-chip
                  v-for="areaName in flightAreasSelectedNames"
                  :key="areaName"
                  class="mr-1"
                >
                  {{ areaName }}
                </v-chip>
              </div>
            </v-stepper-step>
            <v-stepper-content step="1">
              <FlightAreasSelection
                :flightId="flight.id"
                :structureId="structureId"
                :flightAreasSelected.sync="flightAreasSelected"
                :flightAreasSelectedNames.sync="flightAreasSelectedNames"
              />
              <div class="d-flex justify-end align-center pt-3">
                <v-btn
                  depressed
                  class="mx-3"
                  @click="close()"
                >
                  <span v-translate>Cancel</span>
                </v-btn>
                <v-btn
                  :disabled="!flightAreasSelected.length"
                  depressed
                  color="primary"
                  class="white--text"
                  @click="step=2"
                >
                  <span v-translate>Continue</span>
                </v-btn>
              </div>
            </v-stepper-content>
          </template>
          <!-- STEP 2: USK FORM  -->
          <v-stepper-step :step="hasManyAreas ? 2 : ''">
            <span v-translate>Submit approval request</span>
          </v-stepper-step>
          <v-stepper-content :step="hasManyAreas ? 2 : ''">
            <!-- HELP ARTICLE  -->
            <v-alert
              color="primary"
              icon="mdi-information-outline"
              outlined
              border="left"
              class="text-sm-body-2 mt-2 font-weight-medium"
            >
              <a
                target="_blank"
                href="https://clearance.aero/demande-daccord-aux-aeroports-u-space-keeper-via-clearance/"
                class="pb-2"
                v-translate
              >
                How to request an approval in a U-SpaceKeeper airspace?
              </a>
            </v-alert>
            <!-- FORM FIELDS  -->
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="mx-2"
            >
              <!-- PILOTS -->
              <v-row
                align="center"
                class="my-2"
              >
                <v-col
                  cols="12"
                  class="py-2"
                >
                  <span
                    class="subtitle-1 primary--text section-header"
                    v-translate
                  >
                    TELEPILOTS
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-autocomplete
                    v-model="pilotsId"
                    :items="flightPilots"
                    :rules="[rules.pilotsRule]"
                    item-text="full_name"
                    item-value="id"
                    :no-data-text="$gettext('No pilot found')"
                    outlined
                    multiple
                  />
                </v-col>
              </v-row>
              <!-- EXPLOITANT -->
              <v-row
                align="center"
                class="my-2"
              >
                <v-col cols="12">
                  <span
                    class="subtitle-1 primary--text section-header"
                    v-translate
                  >
                    OPERATOR
                  </span>
                </v-col>
                <!-- FIRST NAME -->
                <v-col
                  cols="2"
                  class="py-0"
                >
                  <span class="body-2 primary--text">
                    {{ labels.exploitantFirstName }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0 pr-5"
                >
                  <v-text-field
                    v-model="exploitantFirstName"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- LAST NAME -->
                <v-col
                  cols="2"
                  class="py-0 pl-5"
                >
                  <span class="body-2 primary--text">
                    {{ labels.exploitantLastName }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="exploitantLastName"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- TELEPHONE -->
                <v-col
                  cols="2"
                  class="py-0"
                >
                  <span class="body-2 primary--text">
                    {{ labels.exploitantPhoneNumber }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0 pr-5"
                >
                  <v-text-field
                    v-model="exploitantPhoneNumber"
                    :rules="[rules.required, rules.phoneNumber]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- EMAIL -->
                <v-col
                  cols="2"
                  class="py-0 pl-5"
                >
                  <span class="body-2 primary--text">
                    {{ labels.exploitantEmail }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="exploitantEmail"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- COMPANY -->
                <v-col
                  cols="2"
                  class="pt-0"
                >
                  <span class="body-2 primary--text">
                    {{ labels.exploitantCompany }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="pt-0 pr-5"
                >
                  <v-text-field
                    v-model="exploitantCompany"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
              </v-row>
              <!-- DECLARANT -->
              <v-row
                align="center"
                class="my-2"
              >
                <v-col cols="12">
                  <span
                    class="subtitle-1 primary--text section-header"
                    v-translate
                  >
                    DECLARANT
                  </span>
                </v-col>
                <!-- FIRST NAME -->
                <v-col
                  cols="2"
                  class="py-0"
                >
                  <span class="body-2 primary--text">
                    {{ labels.declarantFirstName }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0 pr-5"
                >
                  <v-text-field
                    v-model="declarantFirstName"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- LAST NAME -->
                <v-col
                  cols="2"
                  class="py-0 pl-5"
                >
                  <span class="body-2 primary--text">
                    {{ labels.declarantLastName }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="declarantLastName"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- TELEPHONE -->
                <v-col
                  cols="2"
                  class="py-0"
                >
                  <span class="body-2 primary--text">
                    {{ labels.declarantPhoneNumber }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0 pr-5"
                >
                  <v-text-field
                    v-model="declarantPhoneNumber"
                    :rules="[rules.required, rules.phoneNumber]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- EMAIL -->
                <v-col
                  cols="2"
                  class="py-0 pl-5"
                >
                  <span class="body-2 primary--text">
                    {{ labels.declarantEmail }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="declarantEmail"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- COMPANY -->
                <v-col
                  cols="2"
                  class="pt-0"
                >
                  <span class="body-2 primary--text">
                    {{ labels.declarantCompany }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  class="pt-0 pr-5"
                >
                  <v-text-field
                    v-model="declarantCompany"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
              </v-row>
              <!-- AERONEF -->
              <v-row
                align="center"
                class="my-2"
              >
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <span
                    class="subtitle-1 primary--text section-header"
                    v-translate
                  >
                    AIRCRAFT
                  </span>
                </v-col>
                <!-- DRONE -->
                <v-col
                  cols="3"
                  class="py-1 mb-6"
                >
                  <span class="body-2 primary--text">
                    {{ labels.drone }}
                  </span>
                </v-col>
                <v-col
                  cols="9"
                  class="py-1"
                >
                  <v-autocomplete
                    v-model="dronesId"
                    :rules="[rules.dronesRule]"
                    :items="flightDrones"
                    item-text="label"
                    item-value="id"
                    :no-data-text="$gettext('No drone found')"
                    outlined
                    multiple
                  />
                </v-col>
              </v-row>
              <v-row class="mb-2">
                <!-- DRONE WEIGHT  -->
                <v-col
                  cols="6"
                  class="pt-2 pb-1 pr-6"
                >
                  <span class="body-2 primary--text">
                    {{ labels.droneWeight }}
                  </span>
                </v-col>
                <v-col
                  cols="6"
                  class="py-0 pr-6"
                >
                  <v-text-field
                    v-model="droneWeight"
                    :rules="[rules.required]"
                    type="number"
                    suffix="kg"
                    class="ma-0 pa-0"
                  />
                </v-col>
              </v-row>
              <!-- MISSION -->
              <v-row
                align="center"
                class="my-2"
              >
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <span
                    class="subtitle-1 primary--text section-header"
                    v-translate
                  >
                    MISSION
                  </span>
                </v-col>
                <!-- NAME -->
                <v-col
                  cols="3"
                  class="py-1"
                >
                  <span class="body-2 primary--text">
                    {{ labels.missionName }}
                  </span>
                </v-col>
                <v-col
                  cols="9"
                  class="py-1"
                >
                  <v-text-field
                    v-model="missionName"
                    :rules="[rules.required]"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <!-- DESCRIPTION -->
                <v-col
                  cols="12"
                  class="pt-1 pb-0"
                >
                  <v-textarea
                    v-model="missionDescription"
                    :label="labels.missionDescription"
                    :rules="[rules.required]"
                    rows="3"
                    outlined
                  >
                    <template slot="append">
                      <v-tooltip
                        bottom
                        color="info"
                        max-width=580
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="small-icon"
                            v-on="on"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <div>
                          {{ tooltips.missionDescription }}
                        </div>
                      </v-tooltip>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
              <!-- CHECKBOXES OFF-SIGHT NIGHT-FLIGHT POPULATED ZONE CAPTIVE DRONE IMAGE CAPTURES -->
              <v-row
                no-gutters
                class="mb-6"
              >
                <v-col
                  cols="7"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="offSight"
                    :label="labels.offSight"
                    hide-details
                    class="primary-label mt-0 pt-0"
                  />
                  <v-checkbox
                    v-model="populatedZone"
                    :label="labels.populatedZone"
                    hide-details
                    class="primary-label"
                  />
                </v-col>
                <v-col
                  cols="5"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="captiveFlight"
                    :label="labels.captiveFlight"
                    hide-details
                    class="primary-label mt-0 pt-0"
                  />
                </v-col>
              </v-row>
              <!-- DOCUMENTS  -->
              <v-row
                align="center"
                class="mb-3"
              >
                <v-col
                  cols="12"
                  class="py-2"
                >
                  <span
                    class="subtitle-1 primary--text section-header"
                    v-translate
                  >
                    AUTHORIZATIONS AND DEROGATIONS
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  class="py-2"
                >
                  <v-input
                    :hint="hints.documents"
                    persistent-hint
                    class="outlined-input"
                  >
                    <div class="flex-grow-1">
                      <template v-if="!allDocuments.length">
                        <span class="v-label theme--light">
                          {{ labels.documents }}
                        </span>
                      </template>
                      <template v-else>
                        <v-chip
                          v-for="(file, index) in allDocuments"
                          :key="index"
                          close-icon="mdi-close"
                          @click:close="removeDocument(index)"
                          class="ma-1"
                          small
                          close
                        >
                          {{ file.name }}
                        </v-chip>
                      </template>
                    </div>
                    <template slot:prepend>
                      <v-menu
                        dark
                        offset-y
                        open-on-hover
                        close-delay="75"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            icon
                          >
                            <v-icon size="28">
                              mdi-attachment mdi-rotate-270
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list light>
                          <v-list-item
                            data-tour="myDocuments"
                            @click="openMyDocumentsDialog()"
                          >
                            <v-list-item-title class="ml-3">
                              <v-icon class="pr-2">mdi-file</v-icon>
                              <span
                                v-translate
                                key="my-documents"
                              >
                                My documents
                              </span>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            data-tour="fromComputer"
                            @click="openFromComputerInput()"
                          >
                            <v-list-item-title class="ml-3">
                              <v-icon class="pr-2">mdi-folder-open</v-icon>
                              <span
                                v-translate
                                key="from-computer"
                              >
                                From computer
                              </span>
                              <v-file-input
                                ref="fileInput"
                                class="ma-0 pa-0"
                                v-model="inputDocuments"
                                prepend-icon=""
                                hide-input
                                multiple
                              />
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-input>
                </v-col>
              </v-row>
              <!-- AGREEMENTS OF THE STRUCTURE -->
              <v-alert
                v-if="agreements.length"
                color="grey lighten-3"
                class="declarant-alert"
              >
                {{ labels.declarantAgreements }}
                <ul
                  v-for="(line, index) in agreements"
                  :key="index"
                >
                  <li v-html="line" />
                </ul>
                <div>
                  <span class="declaration-date pr-2">
                    {{ today }}
                  </span>
                </div>
              </v-alert>
              <!-- ---------------- ACTIONS ---------------- -->
              <div class="d-flex justify-end align-center pt-3">
                <v-btn
                  depressed
                  class="mx-3"
                  @click="close()"
                >
                  <span v-translate>Cancel</span>
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  depressed
                  color="primary"
                  class="white--text mx-3"
                  @click="sendApproval()"
                >
                  <span v-translate>Confirm</span>
                </v-btn>
              </div>
            </v-form>
          </v-stepper-content>
        </v-stepper>
      </div>
      <!-- Errors texts from USK -->
      <div v-else>
        <div class="mt-2">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            type="error"
            :icon="null"
            outlined
            dense
          >
            {{ error }}
          </v-alert>
        </div>
        <div
          v-if="capability === null"
          class="ma-2 mb-6 body-1 info--text"
        >
          {{ texts.editFlight }}
        </div>
        <div class="d-flex justify-end align-center pb-2">
          <v-btn
            class="mx-3"
            @click="close()"
          >
            <span v-translate>Cancel</span>
          </v-btn>
        </div>
      </div>
    </v-card-text>
    <!-- MY DOCUMENTS DIALOG -->
    <v-dialog
      v-model="myDocumentsDialog"
      max-width="500px"
      :retain-focus="false"
      persistent
    >
      <MyDocuments
        @close-mydocuments="() => myDocumentsDialog = false"
        @choose-mydocuments="(documents) => addMyDocuments(documents)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { CONSTRAINTS_CATEGORY } from '@/settings';

import { ERROR } from '@/store/status';

import FlightAreasSelection from '@/components/Flights/RequestApproval/FlightAreasSelection.vue';
import FlightConstraintExternalInformation from '@/components/Flights/FlightConstraintExternalInformation.vue';
import MyDocuments from '@/components/MyDocuments/MyDocuments.vue';

export default {
  name: 'RequestApprovalFormUspacekeeper',
  components: {
    FlightAreasSelection,
    FlightConstraintExternalInformation,
    MyDocuments,
  },
  props: {
    flight: Object,
    constraintId: Number,
    flightPilots: Array,
    flightDrones: Array,
    capability: Object,
    structureId: Number,
  },
  data() {
    const hasManyAreas = this.flight.areas.length > 1;
    return {
      hasManyAreas,
      step: hasManyAreas ? 1 : '',
      flightAreasSelected: [],
      flightAreasSelectedNames: [],
      valid: false,
      pilotsId: [],
      exploitantFirstName: null,
      exploitantLastName: null,
      exploitantPhoneNumber: null,
      exploitantEmail: null,
      exploitantCompany: null,
      declarantFirstName: null,
      declarantLastName: null,
      declarantPhoneNumber: null,
      declarantEmail: null,
      declarantCompany: null,
      missionName: this.flight.name,
      missionDescription: null,
      offSight: null,
      populatedZone: null,
      captiveFlight: false,
      dronesId: [],
      droneWeight: null,
      allDocuments: [],
      myDocumentsDialog: false,
      today: format(new Date(), 'd MMMM yyyy', { locale: fr }),
      rules: {
        required: (value) => !!value || this.$gettext('Field required'),
        phoneNumber: (v) => /^((\+|00)33\s?|0)[1-9](\s?\d{2}){4}$/.test(v) || this.$gettext(
          'Invalid format',
        ),
        dronesRule: (value) => !!value.length || this.$gettext('At least one drone is required'),
        pilotsRule: (value) => !!value.length || this.$gettext(
          'At least one pilot is required',
        ),
      },
    };
  },
  computed: {
    uspacekeeperLogoPath() {
      return `${process.env.BASE_URL}USK.png`;
    },
    USKAnalysis() {
      return this.flight.USKConstraintsAnalysis?.find(
        (a) => a.structure_ids.includes(this.structureId),
      );
    },
    information() {
      return this.USKAnalysis?.information || [];
    },
    errors() {
      return this.USKAnalysis?.errors || [];
    },
    agreements() {
      return this.USKAnalysis?.agreements || [];
    },
    approvalInError() {
      return this.flight.approvals?.find(
        (a) => a.constraint_id === this.constraintId && a.status === ERROR,
      );
    },
    exploitationDetails() {
      return this.$store.state.exploitants.exploitationDetails;
    },
    header() {
      return {
        title: this.$gettext('Additional information'),
        subtitle: this.$gettextInterpolate(
          this.$gettext(`for transmission to the partner zone managed by %{name}
            (U-SpaceKeeper platform)`),
          { name: this.capability.authority_name },
        ),
      };
    },
    labels() {
      return {
        exploitantFirstName: this.$gettext('First name *'),
        exploitantLastName: this.$gettext('Last name *'),
        exploitantPhoneNumber: this.$gettext('Phone *'),
        exploitantEmail: this.$gettext('Email *'),
        exploitantCompany: this.$gettext('Company *'),
        declarantFirstName: this.$gettext('First name *'),
        declarantLastName: this.$gettext('Last name *'),
        declarantPhoneNumber: this.$gettext('Phone *'),
        declarantEmail: this.$gettext('Email *'),
        declarantCompany: this.$gettext('Company *'),
        missionName: this.$gettext('Title of the mission *'),
        missionDescription: this.$gettext('Description of the mission *'),
        offSight: this.$gettext('Out of sight'),
        populatedZone: this.$gettext('Flight near a gathering of people or in a populated area'),
        captiveFlight: this.$gettext('Flight operated by a drone in captive mode'),
        drone: this.$gettext('Drone used for the mission *'),
        droneWeight: this.$gettext('Maximum weight of drones used (kg) *'),
        documents: this.$gettext('Documents'),
        declarantAgreements: this.$gettext(`I certify the sincerity of the information declared and
          I undertake to carry out the flights under the conditions described below :`),
      };
    },
    hints() {
      return {
        documents: this.$gettext(`If applicable, you may attach to your application authorizations
          and derogartions that you have previously obtained`),
      };
    },
    tooltips() {
      return {
        missionDescription: this.$gettext(`This field allow you to describe the mission in more
          detail to the relevant authorities: number and duration of lifts, specific
          activities...`),
      };
    },
    texts() {
      return {
        editFlight: this.$gettext(`You can modify your mission and edit the dates or times in
          order to submit your approval request.`),
      };
    },
    approvalIsValid() {
      return !this.errors.length;
    },
    inputDocuments: {
      get() {
        return this.allDocuments.filter((d) => !d.documentId);
      },
      set(newValue) {
        this.allDocuments = this.allDocuments.concat(newValue);
      },
    },
    myDocuments: {
      get() {
        return this.allDocuments.filter((d) => d.documentId);
      },
      set(newValue) {
        this.allDocuments = this.allDocuments.concat(newValue);
      },
    },
  },
  watch: {
    dronesId(newValue) {
      this.droneWeight = 0;
      const droneWeights = newValue.map((id) => this.getDroneByID(id).weight);
      this.droneWeight = Math.max(...droneWeights);
    },
  },
  created() {
    if (
      this.approvalInError
      && Object.keys(this.approvalInError.external_sent_payload).length !== 0
    ) {
      const { external_sent_payload: externalSentPayload } = this.approvalInError;
      this.exploitantFirstName = externalSentPayload.operator.firstName;
      this.exploitantLastName = externalSentPayload.operator.lastName;
      this.exploitantPhoneNumber = externalSentPayload.operator.phone;
      this.exploitantEmail = externalSentPayload.operator.email;
      this.exploitantCompany = externalSentPayload.operator.businessName;
      this.declarantFirstName = externalSentPayload.applicant.firstName;
      this.declarantLastName = externalSentPayload.applicant.lastName;
      this.declarantPhoneNumber = externalSentPayload.applicant.phone;
      this.declarantEmail = externalSentPayload.applicant.email;
      this.declarantCompany = externalSentPayload.applicant.businessName;
      this.missionDescription = externalSentPayload.description;
      this.captiveFlight = externalSentPayload.operations.isCaptiveFlight;
    } else {
      this.exploitantFirstName = this.exploitationDetails.main_contact.first_name;
      this.exploitantLastName = this.exploitationDetails.main_contact.last_name;
      this.exploitantPhoneNumber = this.exploitationDetails.main_contact.phone_number;
      this.exploitantEmail = this.exploitationDetails.main_contact.email;
      this.exploitantCompany = this.exploitationDetails.commercial_name;
      this.declarantFirstName = this.exploitationDetails.main_contact.first_name;
      this.declarantLastName = this.exploitationDetails.main_contact.last_name;
      this.declarantPhoneNumber = this.exploitationDetails.main_contact.phone_number;
      this.declarantEmail = this.exploitationDetails.main_contact.email;
      this.declarantCompany = this.exploitationDetails.commercial_name;
    }
    this.pilotsId = this.flight.pilots.map((pilot) => pilot.id);
    this.dronesId = this.flight.drones.map((drone) => drone.id);
    this.offSight = !this.flight.pilot_in_sight;
    this.populatedZone = (
      this.flight.people_gathering
      || this.flight.constraintsAnalysis.some(
        (c) => c.category === CONSTRAINTS_CATEGORY.IMPACTING && c.code_type === 'AGG',
      )
    );
  },
  methods: {
    getDroneByID(id) {
      return this.flightDrones.find((d) => d.id === id);
    },
    preparedPayload() {
      let externalPayload = {};
      if (this.$refs.form.validate()) {
        externalPayload = {
          pilots_id: this.pilotsId,
          exploitant_firstname: this.exploitantFirstName,
          exploitant_lastname: this.exploitantLastName,
          exploitant_phone_number: this.exploitantPhoneNumber,
          exploitant_email: this.exploitantEmail,
          exploitant_company: this.exploitantCompany,
          declarant_firstname: this.declarantFirstName,
          declarant_lastname: this.declarantLastName,
          declarant_phone_number: this.declarantPhoneNumber,
          declarant_email: this.declarantEmail,
          declarant_company: this.declarantCompany,
          mission_name: this.missionName,
          mission_description: this.missionDescription,
          off_sight: this.offSight,
          populated_zone: this.populatedZone,
          captive_flight: this.captiveFlight,
          drones_id: this.dronesId,
          drone_max_weight: this.droneWeight,
        };
        return externalPayload;
      }
      return undefined;
    },
    sendApproval() {
      if (this.approvalInError) {
        this.retryCreateApproval();
      } else {
        this.createApproval();
      }
    },
    retryCreateApproval() {
      const externalPayload = this.preparedPayload();
      if (externalPayload) {
        this.$emit(
          'retry-create-approval',
          this.approvalInError.id,
          {
            pilotId: this.pilotsId[0],
            approvalDetails: this.missionDescription,
            flightAreasIds: this.hasManyAreas ? this.flightAreasSelected : [],
            externalPayload,
            myDocumentsIds: this.myDocuments.map((d) => d.documentId),
            attachedFiles: this.inputDocuments,
          },
        );
      }
    },
    createApproval() {
      const externalPayload = this.preparedPayload();
      if (externalPayload) {
        this.$emit(
          'create-approval',
          {
            pilotId: this.pilotsId[0],
            approvalDetails: this.missionDescription,
            flightAreasIds: this.hasManyAreas ? this.flightAreasSelected : [],
            externalPayload,
            myDocumentsIds: this.myDocuments.map((d) => d.documentId),
            attachedFiles: this.inputDocuments,
          },
        );
      }
    },
    openFromComputerInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    openMyDocumentsDialog() {
      this.myDocuments = [];
      this.myDocumentsDialog = true;
    },
    addMyDocuments(myDocuments) {
      this.myDocuments = myDocuments;
    },
    removeDocument(index) {
      this.allDocuments.splice(index, 1);
    },
    close() {
      this.step = 1;
      this.flightAreasSelected = [];
      this.flightAreasSelectedNames = [];
      this.$emit('close-form');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.section-header {
  font-size: 1.1rem !important;
  text-transform: uppercase;
}
.small-icon {
  font-size: 130%;
  padding-left: 10px;
}
.primary-label ::v-deep .v-label {
  font-size: 90%;
  color: $color-primary;
}
.declarant-alert {
  color: black;
  padding: 0.8em;
  font-size: 90%;
}
.declarant-alert__line {
  margin-bottom: 3px;
}
.declaration-date {
  float: right;
  font-style: oblique;
  color: $color-info;
}
.outlined-input {
  ::v-deep .v-input__slot {
    padding: 8px;
    border-style: solid;
    border-width: thin;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, .42);
  }
  ::v-deep .v-messages {
    padding-left: 8px;
  }
}

</style>
