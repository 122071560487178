<template>
  <v-container class="pa-0">
    <v-row
      no-gutters
      align="start"
      class="details-card pa-3"
    >
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <translate>NAME</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layer.name }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="pl-0 pl-sm-3 py-1"
      >
        <span class="details-card__label">
          <translate>CREATED AT</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layer.created_at | date }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <translate>VISIBLE BY DEFAULT</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerDefaultVisibility }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="pl-0 pl-sm-3 py-1"
      >
        <span class="details-card__label">
          <translate>UPDATED AT</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layer.updated_at | date }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <translate>DESCRIPTION</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="7"
        class="px-3 py-1"
      >
        {{ layer.description }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <translate>DATE START</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layer.date_start | date }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="pl-0 pl-sm-3 py-1"
      >
        <span class="details-card__label">
          <translate>DATE END</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layer.date_end | date }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <translate>AUTOMATIC APPROVALS</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerAutoText('accept') }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="pl-0 pl-sm-3 py-1"
      >
        <span class="details-card__label">
          <translate>AUTO APPROVAL FLOOR - CEILING</translate>&nbsp;({{ heightReference }})
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerHeightFloorText('accept') }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <translate>AUTOMATIC APPROVALS RESTRICTION</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerAutoText('restrict') }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="pl-0 pl-sm-3 py-1"
      >
        <span class="details-card__label">
          <translate>AUTO RESTRICTION APPROVAL FLOOR - CEILING</translate>
          &nbsp;({{ heightReference }})
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerHeightFloorText('restrict') }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <translate>AUTOMATIC APPROVALS REFUSE</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerAutoText('refuse') }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="pl-0 pl-sm-3 py-1"
      >
        <span class="details-card__label">
          <translate>AUTO REFUSE APPROVAL FLOOR - CEILING</translate>&nbsp;({{ heightReference }})
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerHeightFloorText('refuse') }}
      </v-col>
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <translate>AUTOMATIC INFORM CONTACT</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerAutoText('inform-contact') }}
      </v-col>
      <!-- ATS internal note -->
      <v-col
        cols="4"
        sm="3"
        class="pl-0 pl-sm-3 py-1"
      >
        <span class="details-card__label">
          <translate>AUTOMATIC ATS INTERNAL NOTE</translate>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerAutoText('ats-internal-note') }}
      </v-col>
      <!-- Impacting zone -->
      <v-col
        cols="4"
        sm="3"
        class="py-1"
      >
        <span class="details-card__label">
          <span v-translate>IMPACTING ZONE</span>
        </span>
      </v-col>
      <v-col
        cols="8"
        sm="3"
        class="px-3 py-1"
      >
        {{ layerAutoText('impacting-zone') }}
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="details-card__footer px-3"
      v-if="isAuthorityAdmin"
    >
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          text
          icon
          @click="$emit('edit-custom-layer', layer)"
        >
          <v-icon>icon-edit_line</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          @click="confirmDeleteDialog=true"
        >
          <v-icon>icon-bin_line</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Confirm delete layer -->
    <v-dialog
      v-model="confirmDeleteDialog"
      max-width="380"
    >
      <CustomLayerDelete
        :layerId="layer.id"
        @close="confirmDeleteDialog=false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { filterFeet } from '@/filters/meter';

import CustomLayerDelete from '@/components/CustomLayers/CustomLayerDelete.vue';

export default {
  name: 'CustomLayer',
  components: { CustomLayerDelete },
  props: {
    layer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { confirmDeleteDialog: false };
  },
  computed: {
    isAuthorityAdmin() {
      return this.$store.getters['authentication/isAuthorityAdmin'];
    },
    layerDefaultVisibility() {
      if (this.layer.default_visibility) {
        return this.$gettext('Yes');
      }
      return this.$gettext('No');
    },
    heightReference() {
      return this.layer.height_reference;
    },
  },
  methods: {
    layerAutoProcess(type) {
      switch (type) {
        case 'accept':
          return Boolean(this.layer.auto_accept_procedure);
        case 'restrict':
          return Boolean(this.layer.auto_restrict_procedure);
        case 'refuse':
          return Boolean(this.layer.auto_refuse_procedure);
        case 'inform-contact':
          return Boolean(this.layer.auto_inform_contact_procedure);
        case 'ats-internal-note':
          return Boolean(this.layer.auto_ats_internal_note_procedure);
        case 'impacting-zone':
          return Boolean(this.layer.auto_impacting_zone_procedure);
        default:
          return false;
      }
    },
    layerAutoText(type) {
      if (this.layerAutoProcess(type)) {
        return this.$gettext('Yes');
      }
      return this.$gettext('No');
    },
    layerHeightFloorText(type) {
      switch (type) {
        case 'accept':
          if (this.layerAutoProcess(type)) {
            const acceptLayer = this.layer.auto_accept_procedure;
            if (this.layer.height_display_unit === 'ft') {
              return `${filterFeet(acceptLayer.floor_limit)} - ${filterFeet(acceptLayer.height_limit)} ft`;
            }
            return `${acceptLayer.floor_limit} - ${acceptLayer.height_limit} m`;
          }
          break;
        case 'restrict':
          if (this.layerAutoProcess(type)) {
            const restrictLayer = this.layer.auto_restrict_procedure;
            if (this.layer.height_display_unit === 'ft') {
              return `${filterFeet(restrictLayer.floor_limit)} - ${filterFeet(restrictLayer.height_limit)} ft`;
            }
            return `${restrictLayer.floor_limit} - ${restrictLayer.height_limit} m`;
          }
          break;
        case 'refuse':
          if (this.layerAutoProcess(type)) {
            const refuseLayer = this.layer.auto_refuse_procedure;
            if (this.layer.height_display_unit === 'ft') {
              return `${filterFeet(refuseLayer.floor_limit)} - ${filterFeet(refuseLayer.height_limit)} ft`;
            }
            return `${refuseLayer.floor_limit} - ${refuseLayer.height_limit} m`;
          }
          break;
        default:
          return '';
      }
      return '';
    },
  },
};
</script>
