<template>
  <div>
    <v-container class="px-1 px-md-3 flex-grow-0">
      <div class="headline mb-4">
        <translate>Livestream</translate>
      </div>
      <div class="mb-4">
        <v-icon class="mr-1">mdi-information-outline</v-icon>
        <span
          class="caption"
          v-html="texts.linkToArticle"
        />
      </div>
      <v-row
        no-gutters
        align="center"
      >
        <v-col
          cols="12"
          md="3"
        >
          <div
            class="body-2 grey--text text--darken-2"
            v-translate
          >
            Livestream emails
          </div>
        </v-col>
        <v-col
          cols="12"
          md="9"
        >
          <v-card class="pa-2 mb-2">
            <v-list-group
              v-model="expandEmailLivestreamsList"
              color=""
            >
              <template v-slot:activator>
                <v-row
                  no-gutters
                  align="center"
                  justify="space-between"
                >
                  <span class="body-1">
                    <translate>Emails livestreams list</translate>
                  </span>
                  <v-btn
                    text
                    icon
                    small
                    @click.prevent="addEmailLivestreamDialog=true"
                  >
                    <v-icon color="grey darken-1">mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </template>
              <v-list class="px-4">
                <v-list-item
                  v-for="(livestreamEmail, index) in livestreamEmails"
                  :key="index"
                  class="pa-0"
                >
                  <v-list-item-content class="pa-0">
                    {{ livestreamEmail.email }}
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      text
                      icon
                      small
                      @click="openLivestreamEmailDeletionDialog(livestreamEmail)"
                    >
                      <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-card>
        </v-col>
      </v-row>
      <div class="body-2 grey--text text--darken-2 mt-4">
        {{ texts.createLivestreamEmail }}
      </div>
      <div class="mt-10">
        <div class="headline">
          <translate>List of livestreams</translate>
        </div>
        <LivestreamsList />
      </div>
    </v-container>
    <!-- Livestream creation dialog-->
    <v-dialog
      v-model="addEmailLivestreamDialog"
      max-width="580"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span v-translate>
            Create a livestream email
          </span>
        </v-card-title>
        <v-card-text>
          <span>
            {{ texts.createLivestreamEmail }}
          </span>
          <v-form
            ref="form"
            lazy-validation
            v-model="emailLivestreamValid"
          >
            <v-text-field
              v-model="emailLivestreamNewValue"
              :rules="rules.email"
              :label="labels.email"
              class="p-0"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeAddEmailLivestreamDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!emailLivestreamValid"
            :loading="emailLivestreamLoading"
            @click="addLivestreamEmail()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Livestream deletion dialog-->
    <v-dialog
      v-if="deleteEmailLivestreamDialog"
      v-model="deleteEmailLivestreamDialog"
      max-width="580"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span v-translate>
            Delete a livestream email
          </span>
        </v-card-title>
        <v-card-text>
          <translate :translate-params="{email: livestreamEmailToDelete.email}">
            Are you sure to delete this email %{email}?
          </translate>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeDeleteEmailLivestreamDialog()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :loading="emailLivestreamLoading"
            @click="deleteLivestreamEmail()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from '@/services/api';

import { LIVESTREAMS_ARTICLE_LINK } from '@/settings';

import { CREATE_EMAIL_LIVESTREAM_NS, DELETE_EMAIL_LIVESTREAM_NS } from '@/store/authentication';

import LivestreamsList from '@/components/Livestreams/LivestreamsList.vue';

export default {
  name: 'LivestreamSettings',
  components: { LivestreamsList },
  data() {
    return {
      expandEmailLivestreamsList: false,
      addEmailLivestreamDialog: false,
      emailLivestreamValid: false,
      emailLivestreamNewValue: '',
      emailLivestreamLoading: false,
      livestreamEmailToDelete: null,
      deleteEmailLivestreamDialog: false,
      rules: {
        email: [
          (v) => !!v || this.$gettext("L'email est requis"),
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$gettext('Email invalide');
          },
        ],
      },
    };
  },
  computed: {
    userData() {
      return this.$store.state.authentication.user;
    },
    livestreamEmails() {
      return this.userData.livestream_emails;
    },
    labels() {
      return { email: this.$gettext('Email') };
    },
    texts() {
      return {
        createLivestreamEmail: this.$gettext(`By adding a new livestream email, this one will
          receive a notification by email on livestream' start.`),
        linkToArticle: this.$gettextInterpolate(
          this.$gettext('<a href="%{ link }" target="_blank">More informations on livestreams</a>'),
          { link: LIVESTREAMS_ARTICLE_LINK },
        ),
      };
    },
  },
  methods: {
    closeAddEmailLivestreamDialog() {
      this.addEmailLivestreamDialog = false;
      this.emailLivestreamNewValue = null;
    },
    async addLivestreamEmail() {
      if (this.$refs.form.validate()) {
        this.emailLivestreamLoading = true;
        await API.createLivestreamEmail(this.emailLivestreamNewValue)
          .then(({ data }) => {
            this.$store.dispatch(CREATE_EMAIL_LIVESTREAM_NS, data)
              .then(() => {
                this.showMessage(
                  this.$gettext('Livestream email created successfully.'),
                  'success',
                );
              });
          })
          .finally(() => {
            this.closeAddEmailLivestreamDialog();
            this.emailLivestreamLoading = false;
            this.expandEmailLivestreamsList = true;
          });
      }
    },
    openLivestreamEmailDeletionDialog(livestreamEmail) {
      this.livestreamEmailToDelete = livestreamEmail;
      this.deleteEmailLivestreamDialog = true;
    },
    closeDeleteEmailLivestreamDialog() {
      this.deleteEmailLivestreamDialog = false;
      this.livestreamEmailToDelete = null;
    },
    async deleteLivestreamEmail() {
      this.emailLivestreamLoading = true;
      await API.deleteLivestreamEmail(this.livestreamEmailToDelete.id)
        .then(() => {
          this.$store.dispatch(DELETE_EMAIL_LIVESTREAM_NS, this.livestreamEmailToDelete.id)
            .then(() => {
              this.showMessage(
                this.$gettext('Livestream email deleted successfully.'),
                'success',
              );
            });
        })
        .finally(() => {
          this.closeDeleteEmailLivestreamDialog();
          this.emailLivestreamLoading = false;
        });
    },
  },
};
</script>
