<template>
  <!-- Add saved message menu button -->
  <v-menu
    offset-y
    :disabled="disabled"
  >
    <template v-slot:activator="{ on: menu }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            v-on="{ ...tooltip, ...menu }"
            class="mt-3"
          >
            mdi-dots-vertical
          </v-icon>
        </template>
        <span
          v-if="disabled"
          v-translate
          key="messageDisabled"
        >
          Saved messages disabled
        </span>
        <span
          v-else
          key="originalMessage"
        >
          {{ texts.tooltip }}
        </span>
      </v-tooltip>
    </template>
    <v-list>
      <span
        v-if="!savedMessages.length"
        class="px-3"
      >
        {{ texts.noMessage }}
      </span>
      <v-list-item
        v-for="(item, index) in savedMessages"
        :key="index"
      >
        <v-list-item-title
          class="body-2 cursor-pointer"
          @click="insertContent(item.content)"
        >
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'SavedMessagesMenu',
  props: {
    text: String,
    isATSInternalNotes: Boolean,
    isParticularRequirements: Boolean,
    isChatMessages: Boolean,
    disabled: Boolean,
  },
  computed: {
    structureDetails() {
      return this.$store.getters['structures/structureDetails'];
    },
    ATSinternalNotes() {
      return this.structureDetails.airspace_ats_internal_notes || [];
    },
    particularRequirements() {
      return this.structureDetails.airspace_particular_requirements || [];
    },
    savedChatMessages() {
      return this.structureDetails.airspace_saved_chat_messages || [];
    },
    savedMessages() {
      if (this.isATSInternalNotes) return this.ATSinternalNotes;
      if (this.isParticularRequirements) return this.particularRequirements;
      if (this.isChatMessages) return this.savedChatMessages;
      return [];
    },
    texts() {
      let tooltip = '';
      let noMessage = '';
      if (this.isATSInternalNotes) {
        tooltip = this.$gettext('Saved internal notes');
        noMessage = this.$gettext('No saved internal note.');
      } else if (this.isParticularRequirements) {
        tooltip = this.$gettext('Special instructions to input');
        noMessage = this.$gettext('No saved particular requirements.');
      } else if (this.isChatMessages) {
        tooltip = this.$gettext('Saved chat messages');
        noMessage = this.$gettext('No saved messages.');
      }
      return { noMessage, tooltip };
    },
  },
  methods: {
    insertContent(content) {
      if (!this.text.includes(content)) {
        let updatedText = this.text;
        if (updatedText.length) updatedText += '\n\n';
        updatedText += content;
        this.$emit('update:text', updatedText);
      }
    },
  },
};
</script>
