<template>
  <v-container
    fluid
    fill-height
    class="theme--light v-sheet pa-0"
  >
    <div class="activation-page">
      <!-- Activation header -->
      <v-toolbar
        class="activation-header"
        elevation="4"
      >
        <v-row
          no-gutters
          align="center"
        >
          <!-- Activation status -->
          <v-col
            cols="12"
            md="6"
            class="px-3"
          >
            <div class="d-flex align-center">
              <StatusIcon
                :status="activation.status"
                :breachedPSAs="approval.is_above_psa"
                :transparentBackground="false"
              />
              <span
                class="pl-3 pr-1 subtitle-1"
                v-translate
              >
                ACTIVATION
              </span>
              <template v-if="activationLoading">
                <v-skeleton-loader
                  type="text"
                  width="87"
                  class="mt-1 pl-1"
                />
              </template>
              <template v-else>
                <span class="subtitle-1 font-weight-medium">
                  {{ approval.display_identifier }}
                </span>
                <span class="subtitle-1 font-weight-medium pl-1">
                  ({{ approval.company_name }})
                </span>
                <div
                  v-if="approval.category_name"
                  class="pl-2"
                >
                  <v-chip
                    small
                    color="primary"
                  >
                    <span class="white--text font-weight-medium">
                      {{ approval.category_name }}
                    </span>
                  </v-chip>
                </div>
              </template>
            </div>
          </v-col>
          <!-- Activation action buttons -->
          <v-col
            cols="11"
            md="5"
            class="px-2"
          >
            <!-- Approval decisions button -->
            <v-chip
              v-if="isAuthorityAdminOrManager"
              outlined
              color="primary"
            >
              <span
                class="subtitle-1 font-weight-medium pr-2"
                v-translate
              >
                YOUR ANSWER
              </span>
              <template v-if="activationLoading">
                <v-skeleton-loader
                  type="text"
                  width="74"
                  class="mt-1 pl-1"
                />
              </template>
              <template v-else>
                <!-- Refuse button -->
                <v-tooltip
                  bottom
                  color="error"
                  v-if="activation.status !== 'refused'"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      v-on="on"
                      @click="openDialogStatus('refused')"
                    >
                      <StatusIcon status="refused" />
                    </v-btn>
                  </template>
                  <translate>Refuse activation</translate>
                </v-tooltip>
                <!-- Accept button -->
                <v-tooltip
                  bottom
                  color="success"
                  v-if="activation.status !== 'accepted'"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      v-on="on"
                      @click="openDialogStatus('accepted')"
                    >
                      <StatusIcon status="accepted" />
                    </v-btn>
                  </template>
                  <translate>Accept activation</translate>
                </v-tooltip>
                <!-- Update activation period -->
                <v-tooltip
                  top
                  key="update"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      v-on="on"
                      @click.stop="dialogUpdate=true"
                    >
                      <v-icon color="primary">mdi-clock</v-icon>
                    </v-btn>
                  </template>
                  <translate>Update activation period</translate>
                </v-tooltip>
              </template>
            </v-chip>
          </v-col>
          <!-- Close button -->
          <v-col
            cols="1"
            class="d-flex justify-end"
          >
            <v-icon @click="close()">
              close
            </v-icon>
          </v-col>
        </v-row>
      </v-toolbar>
      <!-- Activation page -->
      <v-row
        class="activation-row"
        no-gutters
      >
        <!-- Activation details -->
        <v-col
          class="activation-column details"
          cols="12"
          md="6"
        >
          <template v-if="activationLoading">
            <v-skeleton-loader
              type="list-item, list-item-three-line, divider, list-item-three-line, divider,
              list-item-two-line, divider, list-item"
              class="mt-2 mr-3"
            />
          </template>
          <template v-else>
            <ActivationDetails
              :activation="activation"
              @center-on-approval="() => centerOnApproval()"
              @center-on-sub-approval="(geometry) => centerOnSubApproval(geometry)"
            />
          </template>
        </v-col>
        <!-- Activation Discussions -->
        <v-col
          class="activation-column chat elevation-4"
          cols="12"
          md="6"
        >
          <!-- Map  -->
          <ActivationMap
            ref="activationMap"
            :activation="activation"
            class="pb-2"
          />
          <div
            class="py-3 caption"
            v-translate
          >
            DISCUSSIONS WITH PILOT
          </div>
          <v-card
            outlined
            class="pa-3"
          >
            <template v-if="activationLoading">
              <div class="activation-page justify-space-between">
                <v-skeleton-loader type="image, image, divider" />
                <v-skeleton-loader type="list-item-three-line" />
              </div>
            </template>
            <template v-else>
              <ActivationChat
                :activation="activation"
                :newMessageDisabled="newMessageDisabled"
                fullHeight
              />
            </template>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- Dialog to change activation status -->
    <v-dialog
      v-model="dialogStatus"
      width="650"
      height="800"
      persistent
    >
      <ChangeActivationStatusForm
        :activation="activation"
        :statusForUpdate="statusForUpdate"
        @close-form="closeDialogStatus()"
      />
    </v-dialog>
    <!-- Dialog to update period -->
    <v-dialog
      v-if="dialogUpdate"
      v-model="dialogUpdate"
      width="650"
      persistent
      :retain-focus="false"
    >
      <ChangeActivationPeriod
        :activation="activation"
        @close="dialogUpdate=false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { SET_ACTIVATION_SELECTED_NS } from '@/store/activations';
import { RESET_DATA_STORES_NS } from '@/store/application';
import { CANCELLED } from '@/store/status';
import { GET_AUTHORITY_STRUCTURES_NS } from '@/store/structures';

import ActivationChat from '@/components/Activations/ActivationChat.vue';
import ActivationDetails from '@/components/Activations/ActivationDetails.vue';
import ActivationMap from '@/components/Activations/ActivationMap.vue';
import ChangeActivationPeriod from '@/components/Activations/ChangeActivationPeriod.vue';
import ChangeActivationStatusForm from '@/components/Activations/ChangeActivationStatusForm.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'ActivationPage',
  components: {
    ActivationChat,
    ActivationDetails,
    ActivationMap,
    ChangeActivationPeriod,
    ChangeActivationStatusForm,
    StatusIcon,
  },
  data() {
    return {
      dialogStatus: false,
      statusForUpdate: undefined,
      dialogUpdate: false,
    };
  },
  computed: {
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
    activationLoading() {
      return (
        Object.keys(this.activation).length === 0
        || this.$store.state.activations.activationDetailsLoading
      );
    },
    activation() {
      return this.$store.getters['activations/activationSelected'] || {};
    },
    activationId() {
      return this.activation.id;
    },
    approval() {
      return this.activation.approval || {};
    },
    newMessageDisabled() {
      return this.activation.status === CANCELLED || !this.isAuthorityAdminOrManager;
    },
  },
  watch: {
    activationId(newValue) {
      const currentRouteId = Number(this.$route.params.id);
      if (currentRouteId !== newValue) {
        this.$router.push({
          name: 'activation-page',
          params: { id: newValue },
        });
      }
    },
  },
  async mounted() {
    this.$store.dispatch(SET_ACTIVATION_SELECTED_NS);
    const id = Number(this.$route.params.id);
    if (id) {
      this.$store.dispatch(SET_ACTIVATION_SELECTED_NS, id);
    }
    await this.getAuthorityStructures();
  },
  methods: {
    openDialogStatus(status) {
      this.statusForUpdate = status;
      this.dialogStatus = true;
    },
    closeDialogStatus() {
      this.dialogStatus = false;
      this.statusForUpdate = null;
    },
    async getAuthorityStructures() {
      await this.$store.dispatch(GET_AUTHORITY_STRUCTURES_NS);
    },
    close() {
      this.$store.dispatch(RESET_DATA_STORES_NS);
      this.$router.push({ name: 'activations' });
    },
    centerOnSubApproval(geometry) {
      this.$refs.activationMap.showMapDialog({ selectedSubApprovalGeometry: geometry });
    },
    centerOnApproval() {
      this.$refs.activationMap.showMapDialog({ centerOnflight: true });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.details {
  z-index: 2;
  background-color: white;
}
.chat {
  z-index: 1;
  background-color: white;
}
.activation-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.activation-header {
  flex-grow: 0;
  z-index: 3;
}
.activation-row {
  min-height: 0;
  flex-grow: 1;
}
.activation-column {
  height: 100%;
  overflow-y: scroll;
  padding: 12px;
}

</style>
