<template>
  <div class="opinion-listing">
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="opinions"
      :loading="loading"
      single-expand
      :options.sync="options"
      :server-items-length="itemsLength"
      :expanded.sync="expanded"
      :hide-default-footer="smallDisplay"
      :class="{bigDisplay: bigDisplay, smallDisplay: !bigDisplay, transparent: true}"
      :footer-props="{
        'items-per-page-text': $gettext('Lines per page:'),
        'items-per-page-all-text': $gettext('All'),
        'items-per-page-options': [25, 50, 100, -1],
      }"
      :loading-text="$gettext('Loading flights...')"
      :no-data-text="$gettext('No flight found.')"
      item-key="id"
      :no-results-text="$gettext('No flight found.')"
      dark
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <tr
          :class="getStatusClass(props)"
          class="cursor-pointer"
          @click="selectItem(props)"
          @mouseenter="idHover = props.item.id"
          @mouseleave="idHover = null"
        >
          <td class="pl-3">
            <StatusIcon
              :status="props.item.status"
              :transparentBackground="props.isExpanded"
              :isClosed="props.item.approval.is_closed"
            />
          </td>
          <td class="opinion-listing__item-identifier">
            <template v-if="props.item.approval.display_identifier">
              {{ props.item.approval.display_identifier }}
            </template>
          </td>
          <td v-if="(mediumDisplay || bigDisplay)">
            {{ props.item.approval.company_name }}
          </td>
          <td v-if="bigDisplay">
            {{ props.item.approval.commune }}
          </td>
          <td v-if="mediumDisplay || bigDisplay">
            {{ props.item.approval.flight.date_start | dateShort }} -
            {{ props.item.approval.flight.date_end | dateShort }}
          </td>
          <td v-if="bigDisplay">
            {{ props.item.approval.flight.time_start | hour }} -
            {{ props.item.approval.flight.time_end | hour }}
          </td>
          <td v-if="bigDisplay">
            {{ props.item.approval.max_flying_height | meter }}
          </td>
          <td
            v-if="mediumDisplay || bigDisplay"
            class="text-right"
          >
            <v-btn
              icon
              class="black--text"
            >
              <v-icon v-if="!props.isExpanded">keyboard_arrow_down</v-icon>
              <v-icon v-else>keyboard_arrow_up</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pa-0"
        >
          <v-skeleton-loader
            v-if="loadingDetails"
            light
            type="list-item-three-line"
          />
          <OpinionVisitorDetails
            v-else
            :opinion="item"
            @center-on-approval="() => $emit('center-on-approval')"
            @center-on-area="(area) => $emit('center-on-area', area)"
          />
        </td>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        <span v-translate="
          {pageStart: items.pageStart, pageStop: items.pageStop, length: items.itemsLength}
        ">
          %{ pageStart } - %{ pageStop } of %{ length }
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  APPLICATION_DISPLAY,
  SET_DISPLAY_NS,
} from '@/store/application';
import {
  SET_OPINION_HOVER_NS,
  SET_OPINION_SELECTED_NS,
  SET_PAGINATION_NS,
} from '@/store/opinions';
import { CLOSED } from '@/store/status';

import OpinionVisitorDetails from '@/components/Opinions/OpinionVisitorDetails.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

const initialHeaders = [
  {
    text: 'Statut',
    value: 'status',
    align: 'center',
    width: '36',
  },
  {
    text: 'N°',
    value: 'identifier',
    class: 'bold',
    width: '80',
  },
];

export default {
  name: 'OpinionListing',
  components: {
    OpinionVisitorDetails,
    StatusIcon,
  },
  data() {
    return { chatPanel: 0 };
  },
  computed: {
    display() {
      return this.$store.state.application.display;
    },
    bigDisplay() {
      return this.display === APPLICATION_DISPLAY.BIG;
    },
    mediumDisplay() {
      return this.display === APPLICATION_DISPLAY.MEDIUM;
    },
    smallDisplay() {
      return this.display === APPLICATION_DISPLAY.SMALL;
    },
    headers() {
      switch (this.display) {
        case APPLICATION_DISPLAY.BIG:
          return this.bigHeaders;
        case APPLICATION_DISPLAY.SMALL:
          return this.smallHeaders;
        case APPLICATION_DISPLAY.MEDIUM:
        default:
          return this.mediumHeaders;
      }
    },
    loading() {
      return this.$store.state.opinions.tableItemsLoading;
    },
    opinions() {
      return this.loading ? [] : this.$store.getters['opinions/tableItems'];
    },
    itemsLength() {
      return this.$store.state.opinions.itemsLength;
    },
    loadingDetails() {
      return this.$store.state.opinions.opinionDetailsLoading;
    },
    options: {
      get() {
        return this.$store.state.opinions.pagination;
      },
      set(newValue) {
        this.$store.dispatch(SET_PAGINATION_NS, newValue);
      },
    },
    expanded: {
      get() {
        return [this.$store.getters['opinions/opinionSelected']];
      },
      set() {},
    },
    idSelected: {
      get() {
        return this.$store.state.map.featureIdSelected.flight;
      },
      set(newValue) {
        this.$store.dispatch(SET_OPINION_SELECTED_NS, newValue);
      },
    },
    idHover: {
      get() {
        return this.$store.state.map.featureIdHovered.flight;
      },
      set(newValue) {
        if (!this.idSelected || this.idSelected !== newValue) {
          this.$store.dispatch(SET_OPINION_HOVER_NS, newValue);
        }
      },
    },
    smallHeaders() {
      return initialHeaders;
    },
    mediumHeaders() {
      const headers = initialHeaders.concat([
        {
          text: this.$gettext('Applicant'),
          value: 'company_name',
          width: '130',
        },
        {
          text: this.$gettext('Period'),
          value: 'date_start',
          width: '130',
          sortable: false,
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          width: '20',
        },
      ]);
      return headers;
    },
    bigHeaders() {
      let headers = [
        {
          text: this.$gettext('Status'),
          value: 'status',
        },
        {
          text: 'N°',
          value: 'identifier',
          class: 'bold',
          width: '80',
        },
      ];
      headers = headers.concat([
        {
          text: this.$gettext('Applicant'),
          value: 'company_name',
        },
        {
          text: this.$gettext('Town'),
          value: 'commune',
        },
        {
          text: this.$gettext('Period'),
          value: 'date_start',
          sortable: false,
          width: 150,
        },
        {
          text: this.$gettext('Hour'),
          sortable: false,
        },
        {
          text: this.$gettext('Height'),
          value: 'flying_height',
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          width: '20',
        },
      ]);
      return headers;
    },
  },
  watch: {
    display(newValue) {
      if (newValue === 'SMALL') {
        Object.keys(this.$refs.datatable.expanded).forEach((k) => {
          if (k !== newValue) {
            delete this.$refs.datatable.expanded[k];
          }
        });
        this.idSelected = null;
      }
    },
  },
  methods: {
    getStatusClass(props) {
      const status = props.item.approval.is_closed ? CLOSED : props.item.status;
      return {
        selected: props.isExpanded,
        [status]: props.isExpanded,
        highlighted: false,
      };
    },
    selectItem(props) {
      if (this.loading) {
        return;
      }
      this.smallDisplay && this.$store.dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.MEDIUM);
      if (props.isExpanded === false) {
        this.idSelected = props.item.id;
      } else {
        this.idSelected = null;
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.opinion-listing ::v-deep .v-data-table__wrapper>table> {
  tbody>tr,
  tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: white;
    color: $color-secondary;
    &.selected {
      color: $color-white;
      background-color: $color-primary;
      &.accepted {
        background-color: $accepted-background-color;
      }
      &.refused {
        background-color: $refused-background-color;
      }
      &.submitted {
        background-color: $submitted-background-color;
      }
      &.closed {
        background-color: $pending-background-color;
      }
    }
  }
  tbody>tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 15px solid $color-secondary;
      height: 60px;
    }
    &:first-child td {
      height: 45px;
    }
  }
  tbody>tr:hover:not(.highlighted):not(.selected) {
    background-color: lighten($color: $color-info, $amount: 50);
  }
  tbody>tr>td, thead>tr>th {
    &.opinion-listing__item-identifier {
      font-weight: bold;
    }
  }
}

</style>
