<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      {{ texts.title }}
    </v-card-title>
    <v-card-text>
      <div class="my-3">
        {{ texts.validation }}
      </div>
      <div class="my-3">
        {{ texts.constraints }}
      </div>
      <template v-if="!singleNotification">
        <div class="my-3">
          {{ texts.multipleNotifications }}
        </div>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="$emit('close-form')">
        <span v-translate>Cancel</span>
      </v-btn>
      <v-btn
        :loading="sendingFlightCancelNotifRequest"
        :disabled="sendingFlightCancelNotifRequest"
        class="white--text"
        color="primary"
        @click="cancelNotifyFlightArmy()"
      >
        <span v-translate>Confirm</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { CONSTRAINTS_CATEGORY, MILITARY_CONSTRAINTS_CODES } from '@/settings';

import { GET_CONSTRAINTS_ANALYSIS_NS } from '@/store/flights';

export default {
  name: 'NotifAlphaTangoArmyCancel',
  props: {
    flight: Object,
    notificationId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return { sendingFlightCancelNotifRequest: false };
  },
  computed: {
    singleNotification() {
      return this.notificationId !== undefined;
    },
    texts() {
      return {
        title: this.$ngettext(
          'Cancel notification',
          'Cancel notifications',
          this.singleNotification ? 1 : 2,
        ),
        validation: this.$ngettext(
          'Are you sure you want to cancel this notification transmission to the Army Ministry regarding this mission ?',
          'Are you sure you want to cancel all notifications transmission to the Army Ministry regarding this mission ?',
          this.singleNotification ? 1 : 2,
        ),
        constraints: this.$gettextInterpolate(
          this.$ngettext(
            'This cancellation regards the constraint: %{constraints}',
            'This cancellation regards these constraints: %{constraints}',
            this.constraintsInvolved.length,
          ),
          { constraints: this.constraintsInvolvedJoined },
        ),
        multipleNotifications: this.$gettext(`Please note that all the sent and programmed
          notifications will be cancelled. No new notification will be sent for this mission.`),
      };
    },
    impactingConstraints() {
      return this.flight.constraintsAnalysis.filter(
        (c) => c.category === CONSTRAINTS_CATEGORY.IMPACTING,
      );
    },
    constraintsInvolved() {
      const constraintsCodes = ['Off_Sight'] + MILITARY_CONSTRAINTS_CODES;
      return this.impactingConstraints.filter(
        (c) => constraintsCodes.includes(c.code_type),
      ).map((c) => c.name);
    },
    constraintsInvolvedJoined() {
      const constraintsInvolved = [...this.constraintsInvolved];
      if (constraintsInvolved.length === 0) return '';
      if (constraintsInvolved.length === 1) return constraintsInvolved[0];

      const lastConstraint = constraintsInvolved.pop();
      return (
        `${constraintsInvolved.join(', ')} ${this.$gettext('and')} ${lastConstraint}`
      );
    },
  },
  methods: {
    cancelNotifyFlightArmy() {
      this.sendingFlightCancelNotifRequest = true;
      APIService.cancelArmyNotif(this.flight.id, this.notificationId)
        .then(({ data }) => {
          this.showMessage(data, 'success');
        })
        .finally(() => {
          this.sendingFlightCancelNotifRequest = false;
          this.$emit('close-form');
          this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id });
        });
    },
  },
};
</script>
