<template>
  <v-row
    no-gutters
    justify="space-around"
    class="flex-wrap"
  >
    <v-col cols="6">
      Log out
    </v-col>
  </v-row>
</template>

<script>
import { LOGOUT_NS } from '@/store/authentication';

export default {
  name: 'logout',
  async created() {
    await this.$store.dispatch(LOGOUT_NS);
    this.$router.push('/');
  },
};

</script>
