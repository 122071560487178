<template>
  <div>
    <!-- ------- FILTERS BIG DISPLAY --------- -->
    <div
      v-if="bigDisplay"
      class="d-flex flex-wrap align-center"
    >
      <v-row
        class="mt-5"
        no-gutters
      >
        <v-col
          cols="12"
          class="d-flex flex-wrap align-center"
        >
          <v-row
            no-gutters
            align="center"
          >
            <!-- ------ DATE FROM ------ -->
            <v-col
              class="px-2"
              cols="12"
              sm="6"
              md="2"
            >
              <v-menu
                ref="menuFrom"
                :close-on-content-click="true"
                :return-value.sync="dateFrom"
                transition="scale-transition"
                content-class="white text-center"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="dateFrom"
                    :label="$gettext('Début')"
                    append-icon="arrow_drop_down"
                    class="small-dense__input"
                    dark
                    readonly
                    hide-details
                  />
                </template>
                <v-date-picker
                  v-model="dateFrom"
                  no-title
                  scrollable
                  locale="fr"
                  first-day-of-week="1"
                  @input="$refs.menuFrom.save(dateFrom)"
                />
              </v-menu>
            </v-col>
            <!-- ------ DATE TO ------ -->
            <v-col
              class="px-2"
              cols="12"
              sm="6"
              md="2"
            >
              <v-menu
                ref="menuTo"
                :close-on-content-click="true"
                :return-value.sync="dateTo"
                transition="scale-transition"
                content-class="white text-center"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="dateTo"
                    :label="$gettext('Fin')"
                    append-icon="arrow_drop_down"
                    class="small-dense__input"
                    dark
                    readonly
                    hide-details
                  />
                </template>
                <v-date-picker
                  v-model="dateTo"
                  no-title
                  scrollable
                  locale="fr"
                  first-day-of-week="1"
                  @input="$refs.menuTo.save(dateTo)"
                />
              </v-menu>
            </v-col>
            <!-- ------ CATEGORY PILOTS ------ -->
            <v-col
              class="px-2"
              cols="12"
              sm="6"
              md="2"
            >
              <v-autocomplete
                v-model="pilotsCategory"
                :label="$gettext('Category')"
                :items="contactProfiles"
                item-text="label"
                item-value="value"
                class="small-dense__input fix-height"
                :no-data-text="$gettext('No category found')"
                clearable
                multiple
                dark
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ truncText(item.label) }}
                  </span>
                </template>
                <template v-slot:append>
                  <v-chip
                    v-if="pilotsCategory.length > 1"
                    class="short"
                  >
                    +{{ pilotsCategory.length - 1 }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- ------ PILOTS ------ -->
            <v-col
              class="px-2"
              cols="12"
              sm="6"
              md="2"
            >
              <v-autocomplete
                v-model="pilots"
                :loading="loadingPilots"
                :label="$gettext('Télépilotes')"
                :items="pilotsList"
                item-text="full_name"
                item-value="id"
                class="small-dense__input fix-height"
                :no-data-text="$gettext('No pilot found')"
                clearable
                multiple
                dark
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ truncText(item.full_name) }}
                  </span>
                </template>
                <template v-slot:append>
                  <v-chip
                    v-if="pilots.length > 1"
                    class="short"
                  >
                    +{{ pilots.length - 1 }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- ------ DRONES ------ -->
            <v-col
              class="px-2"
              cols="12"
              sm="6"
              md="2"
            >
              <v-autocomplete
                v-model="drones"
                :loading="loadingDrones"
                :label="$gettext('Drones')"
                :items="dronesList"
                item-text="label"
                item-value="id"
                class="small-dense__input fix-height"
                :no-data-text="$gettext('No drone found')"
                clearable
                multiple
                dark
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ truncText(item.label) }}
                  </span>
                </template>
                <template v-slot:append>
                  <v-chip
                    v-if="drones.length > 1"
                    class="short"
                  >
                    +{{ drones.length - 1 }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- ------ TAGS ------ -->
            <v-col
              v-if="isUserEnterprise"
              class="px-2"
              cols="12"
              sm="6"
              md="2"
            >
              <v-autocomplete
                v-model="tags"
                :label="$gettext('Tags')"
                :items="tagNames"
                item-text="full_name"
                item-value="id"
                class="small-dense__input fix-height"
                :no-data-text="$gettext('No tag found')"
                clearable
                multiple
                dark
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ truncText(item) }}
                  </span>
                </template>
                <template v-slot:append>
                  <v-chip
                    v-if="tags.length > 1"
                    class="short"
                  >
                    +{{ tags.length - 1 }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <!-- ------ WITH ARCHIVES------ -->
        <div class="flight-filters">
          <v-switch
            v-model="withArchives"
            :label="$gettext('Inclure archives')"
            color="primary"
            class="small-dense__switch"
            dark
            hide-details
          />
        </div>
        <!-- ------ ENTRUSTED FLIGHTS------ -->
        <div class="flight-filters">
          <v-switch
            v-model="entrusted"
            :label="$gettext('Démarches confiées')"
            color="primary"
            class="small-dense__switch"
            dark
            hide-details
          />
        </div>
        <!-- ------ MY FLIGHTS ------ -->
        <div
          v-if="isUserEnterprise"
          class="flight-filters"
        >
          <v-switch
            v-model="myFlights"
            :label="$gettext('My flights')"
            color="primary"
            class="small-dense__switch"
            dark
            hide-details
          />
        </div>
        <!-- ------ CURRENT WEEK------ -->
        <div class="flight-filters">
          <v-switch
            v-model="currentWeek"
            :label="$gettext('Current week')"
            color="primary"
            class="small-dense__switch"
            dark
            hide-details
          />
        </div>
        <!-- ------ SEARCH BAR ------ -->
        <div class="flight-filters flex-grow-1 d-flex">
          <v-text-field
            v-model="q"
            :append-icon="q ? null : 'search'"
            class="searchbox"
            :placeholder="$gettext('Recherche')"
            dark
            solo
            flat
            clearable
            hide-details
            @keyup.enter="updateResult"
            @click:append="updateResult"
          />
        </div>
        <!-- ------ RESET FILTERS ------ -->
        <div class="flight-filters">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                dark
                small
                v-on="on"
                @click="resetFilters()"
              >
                mdi-close
              </v-icon>
            </template>
            <span
              class="caption"
              v-translate
            >
              Cancel filters
            </span>
          </v-tooltip>
        </div>
      </v-row>
    </div>

    <!-- ------- FILTERS MEDIUM DISPLAY --------- -->
    <div
      v-if="mediumDisplay"
      class="d-flex flex-wrap"
    >
      <div class="flight-filters flex-grow-1">
        <v-text-field
          v-model="q"
          :append-icon="q ? null : 'search'"
          class="searchbox"
          :placeholder="$gettext('Recherche')"
          dark
          solo
          flat
          clearable
          hide-details
          @keyup.enter="updateResult"
          @click:append="updateResult"
        />
      </div>
      <div class="flight-filters flex-grow-1 d-flex justify-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              small
              dark
              @click="seeAllFlight()"
            >
              <span
                class="text-decoration-underline"
                v-translate
              >
                Voir tout
              </span>
            </v-btn>
          </template>
          <span v-translate>
            Afficher toutes les missions y compris les missions archivées.
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              small
              dark
              @click="$emit('show-filters')"
            >
              <span
                class="text-decoration-underline mr-1"
                v-translate
              >
                Filtrer
              </span>
              <span v-if="activeFilters">
                ({{ activeFilters }})
              </span>
            </v-btn>
          </template>
          <span v-translate>Choisir les missions à afficher.</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/services/api.helper';

import { CONTACT_PROFILES, CUSTOM_CONTACT_PROFILES, display } from '@/settings';

import { UPDATE_DISPLAY_ONLY_MY_FLIGHTS_NS } from '@/store/authentication';
import {
  SET_FILTERS_NS,
  GET_ALL_FLIGHTS_NS,
  RESET_FILTERS_NS,
  UPDATE_PAGINATION_NS,
  RESET_FILTERS_ALL_FLIGHTS_NS,
} from '@/store/flights';

export default {
  name: 'FlightFilters',
  props: { display: String },
  computed: {
    isUserEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    bigDisplay() {
      return this.display === display.BIG;
    },
    mediumDisplay() {
      return this.display === display.MEDIUM;
    },
    smallDisplay() {
      return this.display === display.SMALL;
    },
    activeFilters() {
      return this.$store.getters['flights/activeFilters'];
    },
    isExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    hasManySubExploitants() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    hasCustomPilotProfile() {
      return this.$store.getters['exploitants/hasCustomPilotProfile'];
    },
    contactProfiles() {
      const accessibleProfiles = ['standard'];
      if (this.isExploitantAdmin) {
        accessibleProfiles.push('admin_privileges');
      }
      if (this.hasManySubExploitants) {
        accessibleProfiles.push('is_manager');
      }
      const profilesList = CONTACT_PROFILES.filter(
        (c) => accessibleProfiles.includes(c.value),
      );
      if (this.hasCustomPilotProfile) {
        profilesList.pop((profile) => (profile.value === 'standard'));
        CUSTOM_CONTACT_PROFILES.forEach((profile) => profilesList.push(profile));
      }
      return profilesList;
    },
    pilotsList() {
      return this.$store.getters['pilots/pilotsOrderingByFullName'];
    },
    loadingPilots() {
      return this.$store.state.pilots.loadingPilots;
    },
    dronesList() {
      return this.$store.getters['drones/dronesOrderingByName'];
    },
    loadingDrones() {
      return this.$store.state.drones.loadingDrones;
    },
    tagNames() {
      return this.$store.state.exploitants.tagNames;
    },
    dateFrom: {
      get() {
        return this.$store.state.flights.filters.dateFrom;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          dateFrom: newValue,
        });
      },
    },
    dateTo: {
      get() {
        return this.$store.state.flights.filters.dateTo;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          dateTo: newValue,
        });
      },
    },
    q: {
      get() {
        return this.$store.state.flights.filters.q;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          q: newValue || null,
        });
      },
    },
    withArchives: {
      get() {
        return this.$store.state.flights.filters.isArchived === null;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          isArchived: newValue === true ? null : false,
        });
      },
    },
    currentWeek: {
      get() {
        return this.$store.state.flights.filters.currentWeek;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          currentWeek: newValue,
        });
      },
    },
    entrusted: {
      get() {
        return this.$store.state.flights.filters.isEntrusted;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          isEntrusted: newValue,
        });
      },
    },
    myFlights: {
      get() {
        return this.$store.state.authentication.user.display_only_my_flights;
      },
      set(newValue) {
        this.$store.commit(UPDATE_PAGINATION_NS, {
          ...this.$store.state.flights.pagination,
          page: 1,
        });
        this.$store.dispatch(UPDATE_DISPLAY_ONLY_MY_FLIGHTS_NS, newValue);
      },
    },
    pilotsCategory: {
      get() {
        return this.$store.state.flights.filters.pilotsCategory;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          pilotsCategory: newValue,
        });
      },
    },
    pilots: {
      get() {
        return this.$store.state.flights.filters.pilots;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          pilots: newValue,
        });
      },
    },
    drones: {
      get() {
        return this.$store.state.flights.filters.drones;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          drones: newValue,
        });
      },
    },
    tags: {
      get() {
        return this.$store.state.flights.filters.tags;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.flights.filters,
          tags: newValue,
        });
      },
    },
  },
  watch: {
    dateFrom() {
      this.debouncedUpdateResult({ that: this });
    },
    dateTo() {
      this.debouncedUpdateResult({ that: this });
    },
    q() {
      this.debouncedUpdateResult({ that: this });
    },
    withArchives() {
      this.debouncedUpdateResult({ that: this });
    },
    currentWeek() {
      this.debouncedUpdateResult({ that: this });
    },
    entrusted() {
      this.debouncedUpdateResult({ that: this });
    },
    myFlights() {
      this.debouncedUpdateResult({ that: this });
    },
    pilotsCategory() {
      this.debouncedUpdateResult({ that: this });
    },
    pilots() {
      this.debouncedUpdateResult({ that: this });
    },
    drones() {
      this.debouncedUpdateResult({ that: this });
    },
    tags() {
      this.debouncedUpdateResult({ that: this });
    },
  },
  methods: {
    debouncedUpdateResult: debounce(
      ({ that }) => that.updateResult(),
      1000, // debounce for 1s
    ),
    updateResult() {
      // Reload flightsCollection
      this.$nextTick(() => {
        this.$store.dispatch(GET_ALL_FLIGHTS_NS, { updateBoundingBox: true });
      });
    },
    resetFilters() {
      this.$store.dispatch(RESET_FILTERS_NS);
    },
    seeAllFlight() {
      this.$store.dispatch(RESET_FILTERS_ALL_FLIGHTS_NS);
      if (this.myFlights === true) {
        this.$store.dispatch(UPDATE_DISPLAY_ONLY_MY_FLIGHTS_NS, false);
      }
    },
    truncText(text) {
      if (text.length > 20) {
        return `${text.slice(0, 20)}...`;
      }
      return text;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight-filters {
  margin: 14px 8px 4px 0px;
}

.fix-height {
  height: 35px;
}

.short {
  border-radius: 5px;
  padding: 3px;
  margin: 0px 6px 0px 0px;
  height: 20px;
}

.small-dense__input.v-input {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  .v-label {
    font-size: 12px;
  }
}

</style>
