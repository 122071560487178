import Vue from 'vue';
/* eslint-disable-next-line */
import VueGoogleCharts from 'vue-google-charts';
import VueTimepicker from 'vue2-timepicker';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import * as turf from '@turf/turf';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
// useful for syncing router state in vuex store
import { sync } from 'vuex-router-sync';
import VueTour from 'vue-tour';
// translation
import GetTextPlugin from 'vue-gettext';

import translations from '@/locale/translations.json';

import '@/styles/main.scss';

// import font, could be used in v-icon components
import '@/assets/fonts/glyphfont/style.css';

// filters that will be registered globally in Vue.js SPA
import '@/filters';

// colors
import theme from '@/styles/colors';

import App from '@/App.vue';

import router from '@/router';
import store from '@/store';
import {
  LOGOUT_NS,
  GET_WEBSOCKET_AUTHENT_NS,
  RESET_WEBSOCKET_LOG_NS,
  SET_BEAMER_CONFIG_NS,
  SET_ACCEPTED_COOKIE_NS,
  SET_HUBSPOT_CONFIG_NS,
} from '@/store/authentication';
import { SET_SNACKBAR_MESSAGE_NS, SHOW_SNACKBAR_NS, hideHubspotChatBot } from '@/store/application';
import APIService from '@/services/api';
import { addTokenToHeader, manageExpiredSignature } from '@/services/api.helper';

import { COOKIES_NAME } from '@/settings';

import 'vue-tour/dist/vue-tour.css';

const CUSTOM_HANDLING_API_CODES = {
  UNACTIVE_ACCOUNT: 'UNACTIVE_ACCOUNT',
  FLIGHT_CREDIT_LIMIT: 'FLIGHT_CREDIT_LIMIT',
  FLIGHT_NOT_DELETABLE: 'FLIGHT_NOT_DELETABLE',
  USERNAME_ALREADY_EXISTS: 'USERNAME_ALREADY_EXISTS',
  EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  DRONE_COMPATIBILITY_ERROR: 'DRONE_COMPATIBILITY_ERROR',
  NO_NOTIFICATION: 'NO_NOTIFICATION',
  STRIPE_CUSTOMER_UPDATE: 'STRIPE_CUSTOMER_UPDATE',
  EXCLUSION_AREA_INVALID_CRITERIA: 'EXCLUSION_AREA_INVALID_CRITERIA',
  PROMO_CODE_ERROR: 'promo_code_error',
};
export default CUSTOM_HANDLING_API_CODES;

const CREATE_SUBSCRIPTION_ENDPOINT = 'create-subscription';

Object.defineProperty(Vue.prototype, '$turf', { value: turf });

// Service workers, disabled in November 2018 because of a bug with Firefox.
// import './registerServiceWorker';

// config the API with store
APIService.setInterceptorRequestJWT(addTokenToHeader(store.state.authentication));
/* eslint-disable-next-line */
APIService.setInterceptorResponseExpiredSignature(
  manageExpiredSignature(() => store.dispatch(LOGOUT_NS)),
);

// Interceptor to add User-Client header (desktop or mobile)
APIService.setInterceptorRequestUserClient();

// Send errors to Sentry server
if (process.env.VUE_APP_ACTIVATE_SENTRY === '1') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
  });
}

async function catchErrorMessage(error) {
  if (!(error.request || error.request.response)) return null;

  if (
    error.response.data.code
    && Object.values(CUSTOM_HANDLING_API_CODES).find((e) => e === error.response.data.code)
  ) return null;

  // Display error message if response type is Blob (i.e. document download failed)
  if (error.request.response instanceof Blob) {
    const response = JSON.parse(await error.response.data.text());
    return response.detail;
  }

  const response = JSON.parse(error.request.response);
  return response.detail;
}

async function handleApiError(error) {
  // Allow option to disable this standard error handling.
  if (error.config && error.config.errorHandle === false) {
    // eslint-disable-next-line no-console
    console.log('errorHandle deactivated, returning promise rejection.');
    return null;
  }

  if (error.request.responseURL.split('/').pop() === CREATE_SUBSCRIPTION_ENDPOINT) return null;

  const message = await catchErrorMessage(error);
  // Activate snackbar message
  if (message) {
    store.dispatch(SET_SNACKBAR_MESSAGE_NS, {
      message,
      color: 'error',
    });
    store.dispatch(SHOW_SNACKBAR_NS);
  }

  return null;
}

// config the API to show errors in the snackbar.
APIService.setInterceptorApiErrors(handleApiError);

// Sync router in the store (state.route)
sync(store, router);

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth !== false) {
    if (!store.state.authentication.logged) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_GB: 'English',
    fr_FR: 'Français',
  },
  defaultLanguage: 'fr_FR',
  translations,
  silent: true,
});

Vue.use(Vuetify);
Vue.use(VueGoogleCharts);
Vue.use(VueTimepicker);
Vue.use(VueTour);

Vue.config.productionTip = false;

// add user routes if store say user is logged
if (store.state.authentication.logged === true) {
  router.addUserRoutes(
    store.state.authentication.user.user_type,
    store.state.authentication.user.authority_interface_version,
  );
}

Vue.mixin({
  methods: {
    showMessage(message, color = null, long = false, readHtml = false) {
      this.$store.dispatch(SET_SNACKBAR_MESSAGE_NS, { message, color, long, readHtml });
      this.$store.dispatch(SHOW_SNACKBAR_NS);
    },
    extractFileName(contentDispositionValue) {
      let filename = '';
      if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDispositionValue);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      return filename;
    },
  },
});

new Vue({
  router,
  store,
  vuetify: new Vuetify({ theme }),
  data() {
    return { refreshing: false };
  },
  mounted() {
    this.$store.dispatch(RESET_WEBSOCKET_LOG_NS);
    if (
      this.$store.state.authentication.logged === true
      && !this.$store.state.authentication.websocketToken
    ) {
      this.$store.dispatch(GET_WEBSOCKET_AUTHENT_NS);
    }
    window._axcb.push((axeptio) => {
      axeptio.on('cookies:complete', ((choices) => {
        this.$store.dispatch(
          SET_ACCEPTED_COOKIE_NS,
          { cookieName: COOKIES_NAME.BEAMER, value: choices.Beamer },
        );
        this.$store.dispatch(
          SET_ACCEPTED_COOKIE_NS,
          { cookieName: COOKIES_NAME.STRIPE, value: choices.stripe },
        );
        this.$store.dispatch(
          SET_ACCEPTED_COOKIE_NS,
          { cookieName: COOKIES_NAME.HUBSPOT, value: choices.hubspot },
        );
        if (choices.Beamer && this.$store.state.authentication.logged === true) {
          this.$store.dispatch(SET_BEAMER_CONFIG_NS);
        }
        if (choices.hubspot) {
          const originUrl = window.location.origin;
          if (
            this.$store.state.authentication.logged
            || (
              !this.$store.state.authentication.logged
              && originUrl !== process.env.VUE_APP_DELTA_ORIGIN_URL
            )
          ) {
            this.$store.dispatch(SET_HUBSPOT_CONFIG_NS);
          }
        } else {
          hideHubspotChatBot();
        }
        if (choices.stripe) {
          // Import stripe as a side effect
          // Allows Stripe to detect anomalous behavior that may be indicative of fraud.
          // eslint-disable-next-line global-require
          require('@stripe/stripe-js/pure');
        }
      }));
    });
    // Handle cookies import if no environment variable is set
    if (!this.$store.getters['authentication/axeptioActivated']) {
      this.$store.dispatch(
        SET_ACCEPTED_COOKIE_NS,
        { cookieName: COOKIES_NAME.BEAMER, value: 'beamer' },
      );
      this.$store.dispatch(
        SET_ACCEPTED_COOKIE_NS,
        { cookieName: COOKIES_NAME.STRIPE, value: 'stripe' },
      );
      this.$store.dispatch(
        SET_ACCEPTED_COOKIE_NS,
        { cookieName: COOKIES_NAME.HUBSPOT, value: 'hubspot' },
      );
      this.$store.dispatch(SET_HUBSPOT_CONFIG_NS);
      if (this.$store.state.authentication.logged === true) {
        this.$store.dispatch(SET_BEAMER_CONFIG_NS);
      }
      // eslint-disable-next-line global-require
      require('@stripe/stripe-js/pure');
    }
    // Uncomment to detect new service worker and send signal to skip waiting (i.e. refresh
    // tabs and use new version).
    // As of november 2018, it works with Chrome but does not in Firefox, so it was disabled.

    // var _this = this;
    // if (!('serviceWorker' in navigator)) return;
    // console.log('registering controller change event.');
    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   if (this.refreshing) return;
    //   this.refreshing = true;
    //   window.location.reload();
    // });

    // function listenForWaitingServiceWorker(reg, callback) {
    //   function awaitStateChange() {
    //     reg.installing.addEventListener('statechange', function() {
    //       if (this.state === 'installed') callback();
    //     });
    //   }
    //   if (!reg) return;
    //   if (reg.waiting) return callback();
    //   if (reg.installing) awaitStateChange();
    //   reg.addEventListener('updatefound', awaitStateChange);
    // }

    // navigator.serviceWorker.register('/service-worker.js')
    //   .then(function (registration) {
    //       // Track updates to the Service Worker.
    //     if (!navigator.serviceWorker.controller) {
    //       // The window client isn't currently controlled so it's a new service
    //       // worker that will activate immediately
    //       return;
    //     }

    //   listenForWaitingServiceWorker(registration, _this.showRefreshUI);
    // });
  },
  // methods: {
  //   showRefreshUI() {
  //     this.$store.dispatch(SET_AVAILABLE_UPDATE_NS, true);
  //   },
  // },
  render: (h) => h(App),
}).$mount('#app');
