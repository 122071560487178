<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          :loading="loading"
          @click="dialog = true"
        >
          <v-icon :color="getIconColor">
            {{ getIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span>
        {{ texts.tooltipText }}
      </span>
    </v-tooltip>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="650px"
      height="800px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          {{ texts.dialogTitle }}
        </v-card-title>
        <v-card-text>
          {{ texts.dialogText }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="dialog = false"
          >
            <span v-translate>Cancel</span>
          </v-btn>
          <v-btn
            :loading="loading"
            text
            color="primary"
            @click="updateApproval()"
          >
            <span v-translate>Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_APPROVAL_EN_ROUTE_BY_SUPERVISER_NS } from '@/store/approvals';

export default {
  name: 'ChangeApprovalEnRoute',
  props: {
    approval: Object,
    transparentBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      markAsEnRoute: false,
      dialog: false,
      loading: false,
    };
  },
  computed: {
    isEnRoute() {
      return this.approval.en_route_by_superviser;
    },
    getIcon() {
      return this.isEnRoute ? 'mdi-airplane-landing' : 'mdi-airplane-takeoff';
    },
    getIconColor() {
      return this.transparentBackground ? 'black' : 'white';
    },
    texts() {
      return {
        tooltipText: (
          this.isEnRoute
            ? this.$gettext('Mark approval as landing')
            : this.$gettext('Mark approval as en route')
        ),
        dialogTitle: this.$gettextInterpolate(
          (
            this.isEnRoute
              ? this.$gettext('Landing approval %{ identifier }')
              : this.$gettext('En route approval %{ identifier }')
          ),
          { identifier: this.approval.display_identifier },
        ),
        dialogText: (
          this.enRoute
            ? this.$gettext('Are you sure to mark approval as landing?')
            : this.$gettext('Are you sure to mark approval as en route?')
        ),
      };
    },
  },
  methods: {
    updateApproval() {
      this.loading = true;
      this.$store.dispatch(
        SET_APPROVAL_EN_ROUTE_BY_SUPERVISER_NS,
        { approvalId: this.approval.id, enRoute: !this.isEnRoute },
      )
        .finally(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
  },
};
</script>
