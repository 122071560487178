<template>
  <div>
    <div
      v-for="(approval, index) in activeApprovals"
      :key="index"
      class="cursor-pointer"
    >
      <div class="action-container px-1 py-1">
        <v-card>
          <v-list-item
            class="px-3 py-0"
            :class="highlighted"
            @click="openApprovalDetails(approval.id)"
            @mouseenter="setApprovalHover(approval)"
            @mouseleave="setApprovalHover(null)"
          >
            <div class="max-width d-flex flex-column justify-center py-2">
              <!-- Approval status and identifier  -->
              <div class="d-flex align-center">
                <div class="d-flex justify-start flex-grow-1">
                  <ApprovalItem :approval="approval" />
                </div>
                <!-- Approval Actions  -->
                <div class="d-flex align-center justify-end">
                  <!-- Take off authorization -->
                  <template v-if="approval.take_off_authorization_id">
                    <v-btn
                      icon
                      :disabled="!approval.take_off_authorization"
                      @click.stop="showTakeOffAuthorization(approval.take_off_authorization)"
                    >
                      <TakeOffAuthorizationStatusIcon
                        :status="getTakeOffAuthorizationStatus(approval)"
                        key="TakeOffAuthorizationStatusIcon"
                      />
                    </v-btn>
                  </template>
                  <!-- Zoom on approval area -->
                  <div v-if="flightHasManyAreas">
                    <v-btn
                      icon
                      dark
                      small
                      color="info"
                      @click.stop="centerOnApproval(approval)"
                    >
                      <v-icon>mdi-map-marker-outline</v-icon>
                    </v-btn>
                  </div>
                  <!-- Download approval sheet -->
                  <div v-if="canDownloadApprovalSheet(approval)">
                    <v-tooltip
                      bottom
                      :disabled="isMobileBreakpoint"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            text
                            icon
                            :loading="loadingApprovalSheets[index]"
                            :disabled="
                                loadingApprovalSheets[index] || !approvalSheetAvailable(approval)
                              "
                            @click.stop="downloadApprovalSheet(approval.id, index)"
                          >
                            <v-icon>icon-download</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>
                        {{ downloadMissionSheetTooltip(approval) }}
                      </span>
                    </v-tooltip>
                  </div>
                  <v-icon>mdi-chevron-right</v-icon>
                </div>
              </div>
              <!-- ---------- Approval Activations ------------ -->
              <div
                v-if="displayActivationsList(approval)"
                class="action-container py-2"
                :key="approval.id"
                @click.stop=""
              >
                <FlightActivationList
                  :flight="flight"
                  :approval="approval"
                  :constraint="constraint"
                  :highlight="activationsHighlighted(approval.id)"
                />
              </div>
            </div>
          </v-list-item>
        </v-card>
      </div>
    </div>
    <v-dialog
      v-if="showTakeOffAuthorizationDialog"
      v-model="showTakeOffAuthorizationDialog"
      width="820px"
      :retain-focus="false"
      persistent
    >
      <TakeOffAuthorizationDronist
        :takeOffAuthorization="selectedTakeOffAuthorization"
        @update-take-off-authorization="(data) => updateTakeOffAuthorization(data)"
        @close="() => hideTakeOffAuthorization()"
      />
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import {
  REMOVE_ALL_CONSTRAINTS_SELECTED_NS,
  REMOVE_ALL_NOTAMS_SELECTED_NS,
  SET_FLIGHT_APPROVAL_ID_SELECTED_NS,
  SET_FLIGHT_APPROVAL_PROPERTY_NS,
} from '@/store/flights';
import { SET_APPROVAL_FLIGHT_HOVER_NS, ZOOM_TO_AREA_NS } from '@/store/map';
import { CANCELLED, ERROR, AWAITING_PAYMENT, DONE } from '@/store/status';

import FlightActivationList from '@/components/Flights/FlightActivationList.vue';
import ApprovalItem from '@/components/Approvals/ApprovalItem.vue';
import TakeOffAuthorizationStatusIcon from '@/components/StatusIcon/TakeOffAuthorizationStatusIcon.vue';
import TakeOffAuthorizationDronist from '@/components/TakeOffAuthorizations/TakeOffAuthorizationDronist.vue';

export default {
  name: 'FlightApprovalsList',
  components: {
    FlightActivationList,
    ApprovalItem,
    TakeOffAuthorizationStatusIcon,
    TakeOffAuthorizationDronist,
  },
  props: {
    flight: Object,
    constraint: Object,
    capability: Object,
    highlighted: String,
  },
  data() {
    return {
      approvalHighlighted: null,
      loadingApprovalSheets: undefined,
      showTakeOffAuthorizationDialog: false,
      selectedTakeOffAuthorization: null,
    };
  },
  computed: {
    approvals() {
      return this.flight.approvals?.filter(
        (approval) => approval.constraint_id === this.constraint.id,
      ) || [];
    },
    activeApprovals() {
      return this.approvals.filter(
        (approval) => approval.status !== AWAITING_PAYMENT,
      ).sort((a) => a.is_closed);
    },
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    flightHasManyAreas() {
      return this.flight.areas.length > 1;
    },
  },
  created() {
    this.loadingApprovalSheets = this.activeApprovals.map(() => false);
  },
  methods: {
    canDownloadApprovalSheet(approval) {
      return approval.mission_sheet != null;
    },
    approvalSheetAvailable(approval) {
      return approval.mission_sheet?.status === DONE;
    },
    downloadMissionSheetTooltip(approval) {
      if (this.approvalSheetAvailable(approval)) {
        return this.$gettext('Download the mission sheet PDF');
      }
      return this.$gettext(
        'The mission form is being generated. You should be able to download it in a few minutes',
      );
    },
    downloadApprovalSheet(approvalId, index) {
      this.loadingApprovalSheets[index] = true;
      this.$forceUpdate();
      APIService.downloadApprovalPDFSheet(approvalId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettext('mission_sheet.pdf')
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.loadingApprovalSheets[index] = false;
          this.$forceUpdate();
        });
    },
    openApprovalDetails(approvalId) {
      this.$store.dispatch(
        SET_FLIGHT_APPROVAL_ID_SELECTED_NS,
        { flightId: this.flight.id, approvalId },
      );
      this.setApprovalHover(null);
      this.$store.commit(REMOVE_ALL_CONSTRAINTS_SELECTED_NS);
      this.$store.commit(REMOVE_ALL_NOTAMS_SELECTED_NS);
    },
    centerOnApproval(approval) {
      const areas = approval.areas.map((area) => area.id);
      const flightAreasSelected = this.$store.getters['flights/flightAreasSelected'];
      const areaFeatures = flightAreasSelected.filter((feature) => areas.includes(feature.id));
      const area = this.$turf.multiPolygon(areaFeatures.map((a) => a.geometry.coordinates));
      this.$store.dispatch(ZOOM_TO_AREA_NS, area);
    },
    setApprovalHover(approval) {
      this.approvalHighlighted = approval?.id;
      this.$store.dispatch(
        SET_APPROVAL_FLIGHT_HOVER_NS,
        { flightAreasIds: approval?.areas.map((area) => area.id) || [] },
      );
    },
    displayActivationsList(approval) {
      return approval.activations?.length && ![CANCELLED, ERROR].includes(approval.status);
    },
    activationsHighlighted(approvalId) {
      return this.highlighted === 'highlight' || this.approvalHighlighted === approvalId;
    },
    getTakeOffAuthorizationStatus(approval) {
      return approval.take_off_authorization?.status;
    },
    showTakeOffAuthorization(takeOffAuthorization) {
      this.showTakeOffAuthorizationDialog = true;
      this.selectedTakeOffAuthorization = takeOffAuthorization;
    },
    hideTakeOffAuthorization() {
      this.showTakeOffAuthorizationDialog = false;
      this.selectedTakeOffAuthorization = null;
    },
    updateTakeOffAuthorization(data) {
      this.$store.commit(
        SET_FLIGHT_APPROVAL_PROPERTY_NS,
        {
          flightId: this.flight.id,
          approvalId: data.approval_id,
          propertyKey: 'take_off_authorization',
          propertyValue: data,
        },
      );
      this.selectedTakeOffAuthorization = data;
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
.max-width {
  width: 100%;
}

</style>
