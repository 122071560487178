<template>
  <div class="client-constraints-popup pt-0 pt-md-2">
    <!-- LOADER -->
    <div
      v-if="loading"
      class="d-flex justify-center pt-2"
    >
      <v-progress-circular
        size="24"
        width="2"
        indeterminate
      />
    </div>
    <!-- TEXT IF ERROR  -->
    <div
      v-else-if="error"
      class="body-2 py-4"
    >
      <div class="text-center error--text">
        {{ constraintsPopupTexts.errorMessage }}
      </div>
    </div>
    <!-- FORCED BLOCKING STRUCTURE -->
    <div v-else-if="forceBlockingStructure">
      <div class="subtitle-2 pb-2 px-6 text-center">
        <span>
          {{ getType }}
        </span>
        <span class="font-weight-medium">
          {{ name }}
        </span>
      </div>
      <div class="text-center error--text pt-2">
        {{ constraintsPopupTexts.forceBlockingStructure }}
      </div>
    </div>
    <!-- RESULTS -->
    <div
      v-else
      class="body-2 py-2"
    >
      <!-- TITLE  -->
      <div class="subtitle-2 pb-2 px-6 text-center">
        <template v-if="!loading">
          <span>
            {{ getType }}
          </span>
          <span class="font-weight-medium">
            {{ name }}
          </span>
        </template>
      </div>
      <!-- HEADER  -->
      <!-- Lit IDR -->
      <div class="px-2 pb-1">
        <span
          class="text--secondary"
          v-translate
        >
          LIT IDR:
        </span>
        <span>
          {{ litIdr.join(', ') }}
        </span>
      </div>
      <div class="px-2 pb-1">
        <span class="text--secondary">
          {{ constraintsPopupTexts.infrastructureHeight }}
        </span>
        <span v-if="infrastructureHeight">
          {{ infrastructureHeight | meter }}.
        </span>
      </div>
      <div class="px-2 pb-1">
        <span class="text--secondary">
          {{ constraintsPopupTexts.inspectionHeight }}
        </span>
        <span>
          {{ inspectionHeight | meter }}.
        </span>
      </div>
      <!-- CONSTRAINTS LIST  -->
      <div v-if="constraints.length">
        <div class="px-2 text--secondary">
          <span>
            {{ constraintsPopupTexts.constraints }}
          </span>
        </div>
        <div class="constraints-list">
          <v-alert
            v-for="(constraint, index) in constraints"
            :key="index"
            class="my-2 mx-0 pa-0 grey lighten-4"
            :color="constraint.color"
            border="left"
            colored-border
          >
            <v-list-item dense>
              <v-list-item-action class="mr-3">
                <v-icon>
                  {{ getConstraintIcon(constraint.code_type) }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content class="body-2 text--primary">
                <span>
                  {{ constraint.name }}
                </span>
              </v-list-item-content>
            </v-list-item>
            <!-- PUBLIC TIP  -->
            <v-card
              v-if="isConstraintWithInfo(constraint)"
              class="grey lighten-4"
              flat
            >
              <v-card-text class="pt-0 pl-6 body-2">
                <vue-markdown
                  :key="index"
                  :source="constraint.public_tip"
                  class="markdown"
                  html
                  xhtml-out
                  typographer
                  linkify
                  breaks
                  show
                  :anchorAttributes="{ target: '_blank' }"
                />
              </v-card-text>
            </v-card>
          </v-alert>
        </div>
      </div>
      <!-- DETAILS FOR NO RESULTS  -->
      <div v-else>
        <v-alert
          class="my-2 mx-0 pa-0"
          color="green darken-3"
          border="left"
          colored-border
        >
          <v-list-item
            dense
            class="grey lighten-4"
          >
            <v-list-item-icon class="mr-3">
              <v-icon color="green darken-3">icon-fly_ok_flat</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2 text--primary">
              <span>
                {{ constraintsPopupTexts.noConstraintsDetected }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-alert>
      </div>
    </div>
    <v-row v-if="!loading">
      <!-- ITINERARY BUTTON  -->
      <v-col
        v-if="isMobileBreakpoint"
        cols="6"
      >
        <div class="d-flex align-center">
          <v-btn
            :href="itineraryUrl"
            target="_blank"
            color="primary"
            class="pa-2"
            elevation="0"
            small
            fab
          >
            <v-icon color="white">mdi-map-marker-path</v-icon>
          </v-btn>
          <div class="pl-2 caption text--primary">
            <span v-translate>
              Open the itinerary
            </span>
          </div>
        </div>
      </v-col>
      <!-- CREATE FLIGHT  -->
      <v-col :cols="isMobileBreakpoint ? 6 : 12">
        <div
          class="d-flex align-center justify-end"
          v-if="!forceBlockingStructure"
        >
          <div class="pr-2 caption text--primary">
            <span v-if="hasActiveDrones">
              {{ constraintsPopupTexts.createFlight }}
            </span>
            <span v-else>
              {{ constraintsPopupTexts.noActiveDrone }}
            </span>
          </div>
          <v-btn
            @click.stop="setStatusCreateFlight()"
            color="red"
            class="pa-2"
            elevation="0"
            small
            :dark="hasActiveDrones"
            fab
            :disabled="!hasActiveDrones"
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMarkdown from 'vue-markdown';

import API from '@/services/api';
import store from '@/store/';
import { REFRESH_LAYER_POP_UP_NS } from '@/store/map';

import { CONSTRAINT_DICT_ICON } from '@/settings';

export default Vue.extend({
  store,
  props: { id: String },
  components: { VueMarkdown },
  data() {
    return {
      loading: true,
      error: false,
      type: undefined,
      name: undefined,
      litIdr: [],
      infrastructureHeight: undefined,
      inspectionHeight: undefined,
      geometry: undefined,
      constraints: [],
      forceBlockingStructure: false,
    };
  },
  computed: {
    clientStructureId() {
      return this.$store.state.map.contraintsClientStructureId;
    },
    constraintsPopupTexts() {
      return {
        title: this.$gettext('Constraint areas'),
        infrastructureHeight: (
          this.infrastructureHeight ? this.$gettext('Infrastructure height :')
            : this.$gettext('Infrastructure height is unknown.')
        ),
        inspectionHeight: this.$gettext(
          'The analysis is performed for a drone flight at a maximum height of',
        ),
        constraints: this.$gettextInterpolate(
          this.$ngettext(
            'A constraint area is impacting this infrastructure.',
            '%{n} constraint areas are impacting this infrastructure.',
            this.constraints.length,
          ),
          { n: this.constraints.length },
        ),
        createFlight: this.$gettext('Create a mission'),
        noConstraintsDetected: this.$gettext('No constraint areas detected'),
        errorMessage: this.$gettext(
          'An error has occured when trying to retrieve constraint for this infrastructure',
        ),
        noActiveDrone: this.$gettext('No active drones to create a mission'),
        forceBlockingStructure: this.$gettext(`This infrastructure is not included in the
          constraints analysis`),
      };
    },
    getType() {
      switch (this.type) {
        case 'pylon':
          return this.$gettext('Electric pylon');
        case 'line':
          return this.$gettext('Electric line');
        case 'site':
          return this.$gettext('Electric site');
        default:
          return this.$gettext('Infrastructure');
      }
    },
    hasActiveDrones() {
      return !!this.$store.getters['drones/activeDrones'].length;
    },
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    itineraryUrl() {
      const point = this.$turf.centroid(this.geometry);
      const longitude = point.geometry.coordinates[0];
      const latitude = point.geometry.coordinates[1];
      return `https://www.google.com/maps/dir/?api=1&destination=${latitude}+${longitude}&travelmode=driving&dir_action=navigate`;
    },
  },
  watch: {
    clientStructureId(newValue) {
      this.getConstraintsForClientStructure(newValue);
    },
  },
  mounted() {
    this.getConstraintsForClientStructure(this.clientStructureId);
  },
  methods: {
    getConstraintsForClientStructure(clientStructureId) {
      if (clientStructureId) {
        this.loading = true;
        this.error = false;
        this.constraints = [];
        API.getConstraintsForClientStructure(clientStructureId)
          .then(({ data }) => {
            this.type = data.type;
            this.name = data.name;
            this.litIdr = data.lit_idr;
            this.infrastructureHeight = data.infrastructure_height;
            this.inspectionHeight = data.inspection_height;
            this.geometry = data.geometry;
            this.constraints = data.constraints;
            this.forceBlockingStructure = data.force_blocking_structure;
            this.$store.dispatch(REFRESH_LAYER_POP_UP_NS, { layerId: this.id, popupId: this.id });
          })
          .catch(() => {
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getConstraintIcon(codeType) {
      return CONSTRAINT_DICT_ICON[codeType] || 'icon-alert_empty';
    },
    isConstraintWithInfo(constraint) {
      return constraint.color === 'orange' && constraint.public_tip;
    },
    setStatusCreateFlight() {
      let flightName = '';
      switch (this.type) {
        case 'pylon':
          flightName = `${this.name} ${this.litIdr.at(0)}`;
          break;
        case 'line':
          flightName = this.litIdr.at(0);
          break;
        case 'site':
        default:
          flightName = this.name;
          break;
      }
      this.$emit('set-status-create-flight-generalist', flightName, this.geometry);
    },
  },
});
</script>

<style lang="scss">
@use "sass:map";

.client-constraints.mapboxgl-popup {
  @include disable-text-selection;

  .client-constraints-popup {
    min-width: 420px;
  }

  .mapboxgl-popup-content {
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
  }

  .mapboxgl-popup-close-button {
    height: 24px;
    width: 24px;
    margin: 4px;
    padding: 4px;
    border-radius: 50%;
    font-size: 16px;
  }

  .constraints-list {
    max-height: 340px;
    overflow-y: scroll;
  }
}

</style>
