var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[(_vm.displayTakeOffAuthorization)?_c('TakeOffAuthorizationAlertSubmitted',{staticClass:"details-card px-4 pt-4",attrs:{"takeOffAuthorization":_vm.takeOffAuthorization,"loadingTakeOffAuthorizationDetails":_vm.loadingTakeOffAuthorizationDetails}}):_vm._e(),_c('ApprovalDetails',{attrs:{"approval":_vm.approval},on:{"center-on-approval":function () { return _vm.$emit('center-on-approval'); },"center-on-area":function (area) { return _vm.$emit('center-on-area', area); }}}),_c('v-row',{staticClass:"details-card px-4 pb-2",attrs:{"no-gutters":""}},[_c('hr',{staticClass:"separator"}),_c('IntersectingApprovals',{attrs:{"approvalId":_vm.approval.id}}),_c('AuthorityDecisionsDetails',{attrs:{"approval":_vm.approval,"subApprovals":_vm.approval.sub_approvals}}),(_vm.displayTakeOffAuthorization)?_c('ApprovalTakeOffAuthorizationPanel',{attrs:{"takeOffAuthorization":_vm.takeOffAuthorization,"loadingTakeOffAuthorizationDetails":_vm.loadingTakeOffAuthorizationDetails}}):_vm._e(),_c('v-expansion-panels',{staticClass:"chat_panel my-4",attrs:{"light":""},model:{value:(_vm.chatPanel),callback:function ($$v) {_vm.chatPanel=$$v},expression:"chatPanel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"details-card__label"},[_vm._v(" DISCUSSIONS ")]),(_vm.approval.unread_message_for_authority && _vm.isAuthorityAdminOrManager)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"info","dense":""}},on),[_vm._v(" mdi-comment-text-outline ")])]}}],null,false,2609843735)},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Unread messages")])]):_vm._e()],1)]),_c('v-expansion-panel-content',{attrs:{"expand-icon":"keyboard_arrow_down"}},[(_vm.chatLoaded)?_c('ApprovalChat',{attrs:{"approval":_vm.approval,"newMessageDisabled":_vm.approvalNewMessageDisabled}}):_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":20,"width":2}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"details-card__footer px-3 py-1",class:_vm.getStatusClass,attrs:{"no-gutters":"","wrap":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('StatusIcon',{attrs:{"status":_vm.approval.status,"isClosed":_vm.isClosed,"withTooltip":"","withText":"","transparentBackground":""}})],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"absolute":"","offset-y":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('div',_vm._g({},tooltip),[_c('v-btn',_vm._g({staticClass:"m-0 p-0",attrs:{"text":"","icon":"","loading":_vm.loading,"disabled":_vm.updateStatusLoading || !_vm.missionSheetAvailable}},menu),[_c('v-icon',[_vm._v("icon-download")])],1)],1)]}}],null,true)},[(_vm.missionSheetAvailable)?_c('translate',{key:"missionSheetAvailable"},[_vm._v(" Download mission sheet. ")]):_c('translate',{key:"missionSheetUnavailable"},[_vm._v(" The mission sheet is being generated. You will download it quickly. ")])],1)]}}])},[(!_vm.updateStatusLoading)?_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.downloadMissionSheet(_vm.approval.id, item.format)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1):_vm._e()],1)],1),(!_vm.isClosed)?[(_vm.isAuthorityAdminOrManager)?_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"4"}},[(_vm.approval.status !== 'refused')?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogStatus('refused')}}},on),[_c('StatusIcon',{attrs:{"status":"refused","linear":"","transparentBackground":""}})],1)]}}],null,false,2557572129)},[_c('translate',[_vm._v("Refuse the approval.")])],1):_vm._e(),(_vm.activatedIncompleteStatus)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogStatus('incomplete')}}},on),[_c('StatusIcon',{attrs:{"status":"incomplete","linear":"","transparentBackground":""}})],1)]}}],null,false,4143329281)},[_c('translate',[_vm._v("Ask for additional informations.")])],1):_vm._e(),(_vm.forwardMissionSheetActivated)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogStatus('pending')}}},on),[_c('v-icon',[_vm._v("mdi-send-circle-outline")])],1)]}}],null,false,1355588253)},[_c('translate',[_vm._v("Forward mission sheet.")])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogStatus('reserves')}}},on),[_c('StatusIcon',{attrs:{"status":"reserves","linear":"","transparentBackground":""}})],1)]}}],null,false,3985136545)},[_c('translate',[_vm._v("Accept request with restrictions.")])],1),(_vm.approval.status !== 'accepted')?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogStatus('accepted')}}},on),[_c('StatusIcon',{attrs:{"status":"accepted","linear":"","transparentBackground":""}})],1)]}}],null,false,4024471521)},[_c('translate',[_vm._v("Accept the request.")])],1):_vm._e()],1):_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"4"}},[_c('ChangeApprovalEnRoute',{attrs:{"approval":_vm.approval}})],1)]:_vm._e()],2),_c('v-dialog',{attrs:{"width":"650","height":"800","persistent":""},model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}},[_c('ChangeApprovalStatusForm',{attrs:{"approval":_vm.approval,"statusForUpdate":_vm.statusForUpdate,"updateStatusLoading":_vm.updateStatusLoading},on:{"update:statusForUpdate":function($event){_vm.statusForUpdate=$event},"update:status-for-update":function($event){_vm.statusForUpdate=$event},"update:updateStatusLoading":function($event){_vm.updateStatusLoading=$event},"update:update-status-loading":function($event){_vm.updateStatusLoading=$event},"close-form":function () { return _vm.closeDialogStatus(); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }