<template>
  <div class="pilot-list">
    <div class="d-flex align-center justify-space-between mt-4">
      <v-text-field
        v-model="search"
        :append-icon="search ? null : 'search'"
        class="searchbox"
        :placeholder="$gettext('Recherche')"
        dark
        solo
        flat
        clearable
        hide-details
      />
      <v-switch
        v-model="seeAllPilots"
        :label="$gettext('See all')"
        color="primary"
        class="small-dense__switch mx-4"
        dark
        hide-details
      />
    </div>
    <v-data-table
      ref="datatable"
      :headers="tableHeaders"
      :items="pilots"
      :loading="loading"
      :search="search"
      :custom-filter="searchWithSubExploitants"
      :class="{transparent: true}"
      :expanded="expanded"
      dark
      single-expand
      calculate-widths
      :loading-text="$gettext('Chargement des pilotes...')"
      :no-data-text="labels.noPilotFound"
      item-key="id"
      :no-results-text="labels.noPilotFound"
      :mobile-breakpoint="singleColumnBreakpoint"
      :footer-props="{
        'items-per-page-text': $gettext('Lignes par page :'),
        'items-per-page-all-text': $gettext('Tous'),
      }"
      :sort-desc.sync="sortDesc"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <!-- Single column compacted row: for narrow mobile screens -->
        <template v-if="isSingleColumn">
          <tr
            :class="getClass(props)"
            class="cursor-pointer"
            @click="selectItem(props)"
          >
            <td class="truncated cell">
              <v-row
                no-gutters
                align="center"
                justify="center"
              >
                <template v-if="props.item.is_valid">
                  <v-col
                    cols="2"
                    class="d-flex align-center justify-center"
                  >
                    <v-tooltip
                      v-if="props.item.is_main_contact || props.item.has_web_account"
                      color="white"
                      top
                    >
                      <template v-slot:activator="{ on: tooltip }">
                        <v-badge
                          :value="props.item.id === userContactId"
                          color="red"
                          overlap
                          dot
                        >
                          <v-icon v-on="tooltip">
                            {{ getPilotIcon(props.item).icon}}
                          </v-icon>
                        </v-badge>
                      </template>
                      <span class="tooltip-color-text">
                        {{ getPilotIcon(props.item).tooltip }}
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="3">
                    <v-chip
                      v-if="getPilotProfile(props.item)"
                      label
                      x-small
                      color="white primary--text"
                      class="px-1 ml-2"
                    >
                      {{ getPilotProfile(props.item) }}
                    </v-chip>
                  </v-col>
                  <v-col
                    cols="4"
                    class="truncated"
                  >
                    {{ props.item.last_name }}
                  </v-col>
                  <v-col
                    cols="3"
                    class="truncated"
                  >
                    {{ props.item.first_name }}
                  </v-col>
                </template>
                <template v-else>
                  <v-icon>info</v-icon>&nbsp;
                  <span v-translate>Attention données manquantes</span>
                </template>
              </v-row>
            </td>
          </tr>
        </template>
        <!-- Multicolumn row: for wider mobile screens and desktop screens-->
        <template v-else>
          <tr
            :class="getClass(props)"
            class="cursor-pointer"
            @click="selectItem(props)"
          >
            <template v-if="props.item.is_valid">
              <td class="pl-3">
                <v-tooltip
                  v-if="props.item.is_main_contact || props.item.has_web_account"
                  color="white"
                  top
                >
                  <template v-slot:activator="{ on: tooltip }">
                    <v-badge
                      :value="props.item.id === userContactId"
                      color="red"
                      overlap
                      dot
                    >
                      <v-icon v-on="tooltip">
                        {{ getPilotIcon(props.item).icon}}
                      </v-icon>
                    </v-badge>
                  </template>
                  <span class="tooltip-color-text">
                    {{ getPilotIcon(props.item).tooltip }}
                  </span>
                </v-tooltip>
              </td>
              <td>
                <v-chip
                  v-if="getPilotProfile(props.item)"
                  color="white primary--text"
                  class="px-1 ml-2"
                  x-small
                  label
                >
                  {{ getPilotProfile(props.item) }}
                </v-chip>
              </td>
              <td class="truncated cell">
                {{ props.item.last_name }}
              </td>
              <td class="truncated cell">
                {{ props.item.first_name }}
              </td>
              <td
                v-if="subExploitantsAvailable"
                class="truncated cell"
              >
                <div class="chips-group-flex-nowrap">
                  <v-chip
                    v-for="(subExploitant, index) in pilotsSubExploitants(props.item)"
                    :key="index"
                    color="white primary--text"
                    x-small
                  >
                    <span>
                      {{ subExploitant.name }}
                    </span>
                  </v-chip>
                </div>
              </td>
            </template>
            <template v-else>
              <td />
              <td class="pl-3">
                <v-icon>info</v-icon>&nbsp;
                <span v-translate>Attention</span>
              </td>
              <td v-translate>Données manquantes</td>
            </template>
          </tr>
        </template>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          :class="item.is_archived ? 'archived' : ''"
          class="pa-0"
        >
          <Pilot
            :pilot="item"
            @edit-pilot="(data) => $emit('edit-pilot', data)"
            @delete-pilot="(data) => $emit('delete-pilot', data)"
          />
        </td>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }}
        <span v-translate>de</span>
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { APPLICATION_TAB } from '@/store/application';
import { GET_PILOTS_NS } from '@/store/pilots';

import Pilot from '@/components/Pilots/Pilot.vue';

export default {
  name: 'PilotListing',
  components: { Pilot },
  data() {
    return {
      search: '',
      expanded: [],
      index: null,
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
      seeAllPilots: false,
      sortDesc: [false],
    };
  },
  computed: {
    labels() {
      return {
        noPilotFound: this.$gettext(`Aucun pilote trouvé. Vous pouvez en créer un en cliquant sur
          le bouton rouge ci-dessous.`),
      };
    },
    isSingleColumn() {
      if (!this.$vuetify.breakpoint.width) return false;
      return this.$vuetify.breakpoint.width < this.singleColumnBreakpoint;
    },
    headers() {
      return {
        status: {
          text: this.$gettext('Status'),
          value: 'id',
          sort: (a, b) => this.statusSort(a, b),
          class: 'pl-3',
        },
        pilotCategory: {
          text: '',
          sortable: false,
        },
        firstName: {
          text: this.$gettext('First name'),
          value: 'first_name',
        },
        lastName: {
          text: this.$gettext('Last name'),
          value: 'last_name',
        },
        subExploitants: {
          text: this.entityName.plural,
          value: 'sub_exploitants',
          sortable: false,
        },
      };
    },
    tableHeaders() {
      if (this.subExploitantsAvailable && !this.isSingleColumn) {
        return [
          { ...this.headers.status, width: '12%' },
          { ...this.headers.pilotCategory, width: '16%' },
          { ...this.headers.lastName, width: '18%' },
          { ...this.headers.firstName, width: '18%' },
          { ...this.headers.subExploitants, width: '34%' },
        ];
      }
      return [
        { ...this.headers.status, width: '12%' },
        { ...this.headers.pilotCategory, width: '16%' },
        { ...this.headers.lastName, width: '36%' },
        { ...this.headers.firstName, width: '36%' },
      ];
    },
    pilots() {
      if (this.seeAllPilots) {
        return this.$store.state.pilots.pilots;
      }
      return this.$store.state.pilots.pilots.filter((pilot) => !pilot.is_archived);
    },
    loading() {
      return this.$store.state.pilots.loadingPilots;
    },
    activeTab() {
      return this.$store.getters['application/activeTab'];
    },
    userContactId() {
      return this.$store.state.authentication.user.contact_id;
    },
    subExploitantsAvailable() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    userSubExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    hasCustomPilotProfile() {
      return this.$store.getters['exploitants/hasCustomPilotProfile'];
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  watch: {
    activeTab() {
      if (this.activeTab !== APPLICATION_TAB.PILOT) {
        this.expanded = [];
        this.index = null;
      }
    },
  },
  created() {
    this.getPilots();
  },
  methods: {
    getPilots() {
      this.$store.dispatch(GET_PILOTS_NS);
    },
    selectItem(props) {
      if (this.expanded.length === 0) {
        this.expanded = [props.item];
        this.index = props.index;
      } else if (this.index === props.index) {
        this.expanded = [];
        this.index = null;
      } else {
        this.expanded = [props.item];
        this.index = props.index;
      }
    },
    getClass(props) {
      const parent = props.isExpanded ? 'selected' : 'not-selected';
      const child = props.item.is_archived ? 'archived' : 'not-archived';
      return {
        [parent]: true,
        [child]: true,
      };
    },
    searchWithSubExploitants(value, search, item) {
      if (!search) {
        return true;
      }
      const searchedSubExploitants = (
        this.userSubExploitants
          .filter((s) => s.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
          .map((s) => s.id)
      );
      return (
        (value && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1)
        || item.sub_exploitants.some((s) => searchedSubExploitants.includes(s))
      );
    },
    getPilotIcon(item) {
      let isCurrentAccount = '';
      if (item.id === this.userContactId) {
        isCurrentAccount = ` ( ${this.$gettext('Me')} )`;
      }
      if (item.is_main_contact) {
        return {
          icon: 'mdi-monitor-star',
          tooltip: this.$gettext('Main account') + isCurrentAccount,
        };
      }
      if (item.has_web_account) {
        if (item.restricted_account
          && !(
            item.is_manager === true
              || item.profile === 'rte_generalist'
          )) {
          return {
            icon: 'mdi-monitor-lock',
            tooltip: this.$gettext('Restricted user account') + isCurrentAccount,
          };
        }
        return {
          icon: 'mdi-monitor',
          tooltip: this.$gettext('Active user account') + isCurrentAccount,
        };
      }
      return {
        icon: '',
        tooltip: '',
      };
    },
    getPilotProfile(item) {
      if (item.is_main_contact) {
        return this.$gettext('owner');
      }
      if (item.admin_privileges) {
        return this.$gettext('admin');
      }
      if (item.is_manager) {
        return this.$gettext('manager');
      }
      if (this.hasCustomPilotProfile) {
        return item.profile === 'rte_generalist'
          ? this.$gettext('generalist') : this.$gettext('specialist');
      }
      return null;
    },
    pilotsSubExploitants(item) {
      return this.$store.state.authentication.userSubExploitants.filter(
        (s) => item.sub_exploitants.includes(s.id),
      );
    },
    statusSort(a, b) {
      // Sort Order : main contact > not restricted account > restricted account > no web account

      const pilots = this.pilots.filter((f) => [a, b].includes(f.id));
      const value = pilots.map((e) => {
        if (e.is_main_contact) {
          return 5;
        }
        if (!e.has_web_account) {
          return 2;
        }
        return e.restricted_account ? 3 : 4;
      });
      let result = value[0] - value[1];
      if (result) {
        if (this.sortDesc[0]) {
          result *= -1;
        }
        return result < 0 ? -1 : 1;
      }
      return result;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.pilot-list ::v-deep .v-data-table__wrapper>table>tbody> {
  tr,
  tr:hover,
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: white;
    color: $color-secondary;
    &.archived {
      background-color: $color-blue-grey;
      color: $color-white;
    }
    &.not-archived {
      background-color: $color-primary;
      color: $color-white;
    }
  }
  tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 10px solid $color-secondary;
      height: 50px;
    }
    &:first-child td {
      height: 40px;
    }
  }
}

.pilot-list ::v-deep .v-data-table tbody td {
  &.archived {
    .details-card__footer {
      background-color: $color-blue-grey;
    }
  }
}

.pilot-list ::v-deep .v-data-table table {
  border-collapse: initial;
}

.tooltip-color-text {
  color: $color-primary;
}

</style>
