<template>
  <v-expansion-panels
    v-model="panelExpanded"
    class="dense__expansion-panel"
  >
    <v-expansion-panel expand-icon="keyboard_arrow_down">
      <v-expansion-panel-header :class="highlighted">
        <span class="dense__expansion-panel__header-content">
          {{ title }}
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content :class="highlighted">
        <div :class="withUpload ? 'pa-4' : 'pt-2'">
          <!-- Mission Sheet -->
          <template v-if="missionSheetAvailable">
            <div class="pb-1">
              <span
                class="info--text text--lighten-1 pl-2 mr-1"
                v-translate
              >
                Mission sheet:
              </span>
              <v-chip class="documents__chip caption">
                <span
                  class="documents__link-label"
                  @click="downloadApprovalMissionSheet(
                    missionSheet.approval_id, missionSheet.name
                  )"
                >
                  {{ missionSheet.name }}
                </span>
              </v-chip>
            </div>
          </template>
          <!-- Events Documents -->
          <template v-if="eventsDocuments.length">
            <span
              class="info--text text--lighten-1 pl-2 mr-1"
              v-translate
            >
              Exchanged documents:
            </span>
            <v-chip
              v-for="(document, index) in eventsDocuments"
              :key="index"
              class="documents__chip caption"
            >
              <span
                class="documents__link-label"
                @click="downloadDocument(document.id, document.event_id, document.name)"
              >
                {{ document.name }}
              </span>
            </v-chip>
          </template>
          <!-- --------------- Upload new document ---------------- -->
          <div
            v-if="withUpload"
            class="documents__input light"
          >
            <template v-if="!uploadLoading">
              <div>
                <v-icon
                  class="mdi-rotate-270 pr-1"
                  color="primary"
                >
                  mdi-attachment
                </v-icon>
                <label
                  for="approval-file-input"
                  class="documents__input-label"
                >
                  <translate>SHARE A DOCUMENT FOR THIS REQUEST</translate>
                </label>
              </div>
              <input
                id="approval-file-input"
                type="file"
                class="hidden"
                @change="(event) => uploadDocument(event.target.files[0])"
              >
              <span
                v-if="sizeExeeded"
                class="error--text font-italic"
              >
                &nbsp;&nbsp;
                <translate :translate-params="{size: maxDocumentSize}">
                  File should be under %{size}Mb
                </translate>
              </span>
            </template>
            <v-progress-linear
              v-else
              :indeterminate="true"
              class="documents__input-loader"
              color="primary"
            />
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import APIService from '@/services/api';
import { MAX_DOCUMENT_SIZE_MB } from '@/settings';
import { DONE } from '@/store/status';

const MAX_APPROVAL_DOCUMENT_SIZE_MB = MAX_DOCUMENT_SIZE_MB.approval;

export default {
  name: 'FlightApprovalDocuments',
  props: {
    approvalId: {
      type: Number,
      required: false,
    },
    approvalIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    constraintName: {
      type: String,
      required: false,
      default: '',
    },
    missionSheet: {
      type: Object,
      default: undefined,
    },
    eventsDocuments: {
      type: Array,
      default: () => [],
    },
    withUpload: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panelExpanded: undefined,
      uploadLoading: false,
      sizeExeeded: false,
      maxDocumentSize: MAX_APPROVAL_DOCUMENT_SIZE_MB,
    };
  },
  computed: {
    title() {
      const missionSheetAvailable = this.missionSheetAvailable ? 1 : 0;
      const numberOfDocuments = missionSheetAvailable + this.eventsDocuments.length;
      if (this.approvalIdentifier || this.constraintName) {
        return `${this.$gettextInterpolate(
          this.$ngettext(
            '%{n} Document of the request %{identifier}',
            '%{n} Documents of the request %{identifier}',
            numberOfDocuments,
          ),
          { n: numberOfDocuments, identifier: this.approvalIdentifier },
        )} ${this.constraintName}`;
      }
      return this.$gettextInterpolate(
        this.$ngettext(
          '%{n} Document related to this request',
          '%{n} Documents related to this request',
          numberOfDocuments,
        ),
        { n: numberOfDocuments },
      );
    },
    missionSheetAvailable() {
      return this.missionSheet?.status === DONE;
    },
    highlighted() {
      if (this.highlight) {
        return 'highlight';
      }
      return '';
    },
  },
  methods: {
    navigatorDownloadDocument(data, filename) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadDocument(documentId, eventId, documentName) {
      APIService.downloadDocument(documentId, eventId)
        .then(({ data }) => {
          this.navigatorDownloadDocument(data, documentName);
        });
    },
    downloadApprovalMissionSheet(approvalId, filename) {
      APIService.downloadApprovalPDFSheet(approvalId)
        .then(({ data }) => {
          this.navigatorDownloadDocument(data, filename);
        });
    },
    uploadDocument(file) {
      this.sizeExeeded = false;
      if (file) {
        if (file.size > this.maxDocumentSize * 1000000) {
          this.sizeExeeded = true;
          return;
        }
        const payload = new FormData();
        payload.append('document', file);
        this.uploadLoading = true;
        this.sizeExeeded = false;
        APIService.approvalMessage(this.approvalId, payload)
          .then(({ data }) => {
            this.$emit('message-sent', data);
          })
          .finally(() => {
            this.uploadLoading = false;
          });
      }
    },
    closePanel() {
      this.panelExpanded = undefined;
    },
  },
};
</script>
