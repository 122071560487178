<template>
  <v-app-bar
    class="header"
    :color="headerBackgroundColor"
    app
    dark
    fixed
  >
    <!-- Clearance logo -->
    <v-toolbar-title>
      <a
        class="white--text main-title d-flex align-center clearance-logo"
        @click="resetAppAndMap()"
      >
        <template v-if="isDeltaOrigin">
          <v-img
            :src="RTELogoPath"
            max-width="42"
          />
          <div class="clearance-logo--delta">
            <span class="clearance-logo__text">
              DELTA
            </span>
            <span
              v-if="!isMobileBreakpoint"
              class="clearance-logo__powered"
              v-translate
            >
              by Clearance
            </span>
          </div>
        </template>
        <template v-else-if="isHologardeDadOrigin">
          <v-img
            :src="DADHologardeLogoPath"
            max-width="42"
          />
          <div class="clearance-logo--delta">
            <span class="clearance-logo__text">
              DAD
            </span>
            <span
              v-if="!isMobileBreakpoint"
              class="clearance-logo__powered"
              v-translate
            >
              by Clearance
            </span>
          </div>
        </template>
        <template v-else>
          <span class="icon-logo_clearance_flat clearance-logo__icon" />
          <span class="clearance-logo__text">
            CLEARANCE
          </span>
        </template>
      </a>
    </v-toolbar-title>
    <v-spacer />
    <div class="d-flex align-center justify-end">
      <!-- Extra info and actions in header  -->
      <HeaderAddOns v-if="!isMobileBreakpoint" />
      <!-- User name and commercial name  -->
      <span
        class="flex-shrink-0 mr-1"
        data-tour-step="0"
      >
        <span
          :class="{'d-none':userContactName}"
          class="d-sm-inline"
        >
          {{ userName }}
        </span>
        <span v-if="userContactName">
          <span
            :class="{'d-none':userContactName}"
            class="d-sm-inline mx-1"
          >
            -
          </span>
          <span>
            {{ userContactName }}
          </span>
        </span>
      </span>
      <!-- Main menues and actions  -->
      <HeaderActionsMenu
        ref="actionMenu"
        @play-help-video="helpVideoPlayer = true"
        @start-tour="startTour()"
      />
    </div>
    <!-- Snackbar -->
    <Snackbar />
    <!-- Dialog livestream -->
    <template>
      <!-- Livestream details dialog  -->
      <v-dialog
        :value="selectedLivestream"
        max-width="580"
        persistent
      >
        <LivestreamDetails />
      </v-dialog>
      <!-- Livestream delete dialog  -->
      <v-dialog
        :value="livestreamToDelete"
        max-width="580"
        persistent
      >
        <LivestreamDelete />
      </v-dialog>
      <!-- Livestream stop dialog  -->
      <v-dialog
        :value="livestreamToStop"
        max-width="580"
        persistent
      >
        <LivestreamStop />
      </v-dialog>
    </template>
    <!-- Help Video -->
    <v-dialog
      v-model="helpVideoPlayer"
      fullscreen
    >
      <v-overlay
        opacity="0.9"
        class="video-player pa-5"
      >
        <v-row
          no-gutters
          class="flex-column"
          justify="center"
        >
          <div class="align-self-end">
            <v-icon @click="helpVideoPlayer=false">mdi-close</v-icon>
          </div>
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="mt-2"
          >
            <v-responsive
              :aspect-ratio="16/9"
              max-width="1280"
              max-height="720"
            >
              <iframe
                v-if="helpVideoPlayer"
                class="video-player__frame"
                allowfullscreen
                src="https://youtube.com/embed/R-c4q5rqUb0?rel=0"
              />
            </v-responsive>
          </v-row>
        </v-row>
      </v-overlay>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { RESET_DATA_STORES_NS } from '@/store/application';

import HeaderAddOns from '@/components/Header/HeaderAddOns.vue';
import HeaderActionsMenu from '@/components/Header/HeaderActionsMenu.vue';
import LivestreamDetails from '@/components/Livestreams/LivestreamDetails.vue';
import LivestreamDelete from '@/components/Livestreams/LivestreamDelete.vue';
import LivestreamStop from '@/components/Livestreams/LivestreamStop.vue';
import Snackbar from '@/components/Header/Snackbar.vue';

export default {
  name: 'Header',
  components: {
    HeaderAddOns,
    HeaderActionsMenu,
    LivestreamDetails,
    LivestreamDelete,
    LivestreamStop,
    Snackbar,
  },
  data() {
    return { helpVideoPlayer: false };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    userName() {
      return this.$store.getters['authentication/userLongName'];
    },
    userContactName() {
      return this.$store.getters['authentication/userContactName'];
    },
    isMapDataLoaded() {
      return this.$store.state.map.isMapDataLoaded;
    },
    headerBackgroundColor() {
      if (this.$store.state.authentication.hijackedUserId) {
        return 'orange';
      }
      return 'primary';
    },
    isDeltaOrigin() {
      return window.location.origin === process.env.VUE_APP_DELTA_ORIGIN_URL;
    },
    isHologardeDadOrigin() {
      return window.location.origin === process.env.VUE_APP_DAD_HOLOGARDE_ORIGIN_URL;
    },
    RTELogoPath() {
      return `${process.env.BASE_URL}RTE.png`;
    },
    DADHologardeLogoPath() {
      return `${process.env.BASE_URL}dad_logo.png`;
    },
    selectedLivestream() {
      return this.$store.getters['livestreams/selectedLivestream'] !== undefined;
    },
    livestreamToDelete() {
      return this.$store.getters['livestreams/livestreamToDelete'] !== undefined;
    },
    livestreamToStop() {
      return this.$store.getters['livestreams/livestreamToStop'] !== undefined;
    },
  },
  watch: {
    isMapDataLoaded() {
      if (this.isMapDataLoaded && this.$route.query.firstSignup) {
        this.startTour();
      }
    },
    selectedLivestream() {
      this.$refs.actionMenu.closeLivestreamMenu();
    },
    livestreamToDelete() {
      this.$refs.actionMenu.closeLivestreamMenu();
    },
    livestreamToStop() {
      this.$refs.actionMenu.closeLivestreamMenu();
    },
  },
  methods: {
    startTour() {
      // Launch a UI tour (defined in MapDronists.vue)
      this.$tours.dronistTour.start();
    },
    resetAppAndMap() {
      this.$store.dispatch(RESET_DATA_STORES_NS);
      this.$router.push({ path: '/' });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.main-title {
  text-decoration: none;
}

.header {
  z-index: 203;
}

.video-player {

  overflow: auto;

  /deep/ .v-overlay__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__frame {
    border: none;
    width: 100%;
    height: 100%;
  }

}

.clearance-logo {
  overflow: hidden;
  height: 50px;
  &__icon {
    height: 50px;
    font-size: larger;
  }
  &__text {
    padding: 0px 4px;
    font-weight: 700;
    letter-spacing: 0.03em;
    display: inline-block;
  }
  &--delta {
    display: grid;
    padding: 0px 4px;
    line-height: 1.3;
    .clearance-logo__powered {
      padding: 0px 4px;
      font-size: x-small;
      font-style: italic;
    }
  }
}

.icon-logo_clearance_flat::before {
  height: 100%;
  line-height: 44px;
}

</style>
