import Vue from 'vue';

import APIService from '@/services/api';

const namespace = 'drones';

const FETCH_DRONES = 'FETCH_DRONES';
const GET_DRONES = 'GET_DRONES';
const GET_SIMPLIFIED_DRONES = 'GET_SIMPLIFIED_DRONES';
const SYNCHRONIZE_DRONES = 'SYNCHRONIZE_DRONES';
const SET_DRONES = 'SET_DRONES';
const RESET_DRONES = 'RESET_DRONES';
const ADD_DRONE_DOCUMENT = 'ADD_DRONE_DOCUMENT';
const DELETE_DRONE_DOCUMENT = 'DELETE_DRONE_DOCUMENT';

export const GET_DRONES_NS = `${namespace}/${GET_DRONES}`;
export const RESET_DRONES_NS = `${namespace}/${RESET_DRONES}`;
export const GET_SIMPLIFIED_DRONES_NS = `${namespace}/${GET_SIMPLIFIED_DRONES}`;
export const ADD_DRONE_DOCUMENT_NS = `${namespace}/${ADD_DRONE_DOCUMENT}`;
export const DELETE_DRONE_DOCUMENT_NS = `${namespace}/${DELETE_DRONE_DOCUMENT}`;

function initialState() {
  return {
    drones: [],
    loadingDrones: false,
    loadingDronesError: {},
    documentCategories: [
      {
        label: 'Attestation de conception ou de conformité ',
        value: 'design_certificate',
      },
      {
        label: 'Extrait du registre des aéronefs civils sans équipage à bord ',
        value: 'aircraft_register',
      },
      {
        label: 'Manuel d’utilisation ',
        value: 'user_manual',
      },
      {
        label: 'Manuel d’entretien ',
        value: 'maintenance_manual',
      },
      {
        label: 'Autre type de document ',
        value: '',
      },
    ],
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    dronesOrderingByName: (state) => [...state.drones].sort((a, b) => a.name > b.name),
    activeDrones: (state) => state.drones.filter((d) => d.active),
  },
  mutations: {
    [RESET_DRONES](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [FETCH_DRONES](state) {
      Vue.set(state, 'loadingDrones', true);
    },
    [SYNCHRONIZE_DRONES](state, drones) {
      const currentDrones = state.drones;
      drones.forEach((drone) => {
        const existingDroneIndex = currentDrones.findIndex((d) => d.id === drone.id);
        if (existingDroneIndex !== -1) {
          currentDrones[existingDroneIndex] = { ...currentDrones[existingDroneIndex], ...drone };
        } else {
          currentDrones.push(drone);
        }
      });
      Vue.set(state, 'loadingDrones', false);
      Vue.set(state, 'drones', currentDrones);
    },
    [SET_DRONES](state, { drones = [], loadingDronesError }) {
      Vue.set(state, 'loadingDrones', false);
      Vue.set(state, 'drones', drones);
      Vue.set(state, 'loadingDronesError', loadingDronesError);
    },
    [ADD_DRONE_DOCUMENT](state, { droneId, document }) {
      const index = state.drones.findIndex((d) => d.id === droneId);
      if (index !== -1) {
        const { documents } = state.drones[index];
        documents.push(document);
        Vue.set(state.drones[index], 'documents', documents);
      }
    },
    [DELETE_DRONE_DOCUMENT](state, { droneId, documentId }) {
      const index = state.drones.findIndex((d) => d.id === droneId);
      if (index !== -1) {
        const { documents } = state.drones[index];
        const indexDocument = documents.findIndex((d) => d.id === documentId);
        if (indexDocument !== -1) {
          documents.splice(indexDocument, 1);
          Vue.set(state.drones[index], 'documents', documents);
        }
      }
    },
  },
  actions: {
    [RESET_DRONES]({ commit }) {
      commit(RESET_DRONES);
    },
    [GET_SIMPLIFIED_DRONES]({ state, commit, rootState }, active = true) {
      if (!state.drones.length) {
        const subExploitantId = rootState.authentication.selectedSubExploitant;
        commit(FETCH_DRONES);
        APIService.getSimplifiedDrones({ active, subExploitantId })
          .then(({ data }) => {
            commit(SYNCHRONIZE_DRONES, data);
          });
      }
    },
    [GET_DRONES]({ commit, rootState }) {
      commit(FETCH_DRONES);
      const subExploitantId = rootState.authentication.selectedSubExploitant;
      APIService
        .getDrones({ subExploitantId })
        .then(({ data }) => {
          commit(SET_DRONES, { drones: data.results });
        })
        .catch((error) => {
          commit(SET_DRONES, { loadingDronesError: error });
        });
    },
    [ADD_DRONE_DOCUMENT]({ commit }, { droneId, document }) {
      commit(ADD_DRONE_DOCUMENT, { droneId, document });
    },
    [DELETE_DRONE_DOCUMENT]({ commit }, { droneId, documentId }) {
      commit(DELETE_DRONE_DOCUMENT, { droneId, documentId });
    },
  },
};
