<template>
  <v-expansion-panels
    v-model="intersectingApprovalsPanel"
    light
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="details-card__label">
          <translate
            :translate-n="intersectingApprovals.length"
            :translate-params="{ intersectingApprovalsCount: intersectingApprovals.length }"
            translate-plural="%{ intersectingApprovalsCount } other approvals at this place"
          >
            %{ intersectingApprovalsCount } other approval at this place
          </translate>
        </span>
        <template v-slot:actions>
          <v-progress-circular
            v-if="loadingIntersectingApprovals"
            indeterminate
            color="primary"
            :size="20"
            :width="2"
          />
          <v-icon v-else>keyboard_arrow_down</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <template v-if="intersectingApprovals.length">
          <v-col
            v-for="(intersectingApproval, index) in intersectingApprovals"
            :key="index"
            class="d-flex align-center my-1 outlined"
            :class="approvalHoverId === intersectingApproval.id ? 'hover': ''"
          >
            <div
              @mouseenter="setApprovalHover(intersectingApproval.id)"
              @mouseleave="setApprovalHover(null)"
              :class="isAuthorityFullScreenInterface ? '' : 'cursor-pointer'"
            >
              <translate
                :translate-params="{
                  identifier: intersectingApproval.display_identifier,
                  status: intersectingApproval.display_status,
                }"
                class="details-card__label"
              >
                Approval %{ identifier } (%{ status })
              </translate>
              <div>
                <translate>The mission is planned for</translate>
                <span class="font-weight-bold">
                  {{ intersectingApproval.flight.date_start | date }}
                  -
                  {{ intersectingApproval.flight.date_end | date }}
                </span>
                <translate>by company</translate>
                <span class="font-weight-bold">
                  {{ intersectingApproval.company_name }}
                </span>
                <translate>at a height of</translate>
                <span class="font-weight-bold">
                  {{ intersectingApproval.flying_height | meter }}.
                </span>
              </div>
            </div>
          </v-col>
        </template>
        <translate v-else>
          No intersecting approvals found
        </translate>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import APIService from '@/services/api';

import { SET_APPROVAL_HOVER_NS } from '@/store/approvals';

export default {
  name: 'IntersectingApprovals',
  props: { approvalId: Number },
  data() {
    return {
      intersectingApprovalsPanel: null,
      loadingIntersectingApprovals: false,
      intersectingApprovals: [],
    };
  },
  computed: {
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
    approvalHoverId() {
      return this.$store.state.map.featureIdHovered.flight;
    },
  },
  created() {
    this.loadingIntersectingApprovals = true;
    APIService.getIntersectingApprovals(this.approvalId)
      .then(({ data }) => {
        this.intersectingApprovals = data;
      })
      .finally(() => {
        this.loadingIntersectingApprovals = false;
      });
  },
  methods: {
    setApprovalHover(id) {
      if (!this.isAuthorityFullScreenInterface) {
        this.$store.dispatch(SET_APPROVAL_HOVER_NS, id);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.outlined {
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  border-color: white;
  &.hover {
    border-color: rgba(0, 0, 0, .42);
  }
}

</style>
