<template>
  <v-container
    fluid
    fill-height
    class="text-center theme--light v-sheet pa-0"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <div class="text-h2 mb-8 d-flex justify-center">
          <v-icon
            size="52"
            color="primary"
            class="mr-4"
          >
            mdi-email-send-outline
          </v-icon>
          <translate>Connection link sent</translate>
        </div>
        <p class="mx-auto text-h5 font-weight-light limit-size">
          <span>
            {{ texts.email }}
          </span>
          <span
            class="primary--text font-weight-regular"
            v-translate
          >
            15 minutes
          </span>.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import APIService from '@/services/api';

export default {
  name: 'TokenGeneration',
  data() {
    return { uuid: '', idSelected: null };
  },
  computed: {
    texts() {
      return {
        email: this.$gettext(`An email will be sent to your email address with a link to log in
          directly on Clearance interface in order to submit your opinion about a drone flight. The
          connection link will be available`),
      };
    },
  },
  created() {
    this.uuid = this.$route.query.uuid;
    this.idSelected = this.$route.query.idSelected;
    APIService.generateDjangoSesame(this.uuid, this.idSelected);
  },
};
</script>

<style
  lang="scss"
  scoped
>
.limit-size {
  max-width: 520px;
}

</style>
