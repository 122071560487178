<template>
  <v-container class="pa-0">
    <!-- Take authorization alert -->
    <TakeOffAuthorizationAlertSubmitted
      v-if="displayTakeOffAuthorization"
      :takeOffAuthorization="takeOffAuthorization"
      :loadingTakeOffAuthorizationDetails="loadingTakeOffAuthorizationDetails"
      class="details-card px-4 pt-4"
    />
    <!-- Approval details -->
    <ApprovalDetails
      :approval="approval"
      @center-on-approval="() => $emit('center-on-approval')"
      @center-on-area="(area) => $emit('center-on-area', area)"
    />
    <v-row
      no-gutters
      class="details-card px-4 pb-2"
    >
      <hr class="separator" />
      <!-- Intersecting approvals -->
      <IntersectingApprovals :approvalId="approval.id" />
      <!-- Decisions details -->
      <AuthorityDecisionsDetails
        :approval="approval"
        :subApprovals="approval.sub_approvals"
      />
      <!-- Take off authorization -->
      <ApprovalTakeOffAuthorizationPanel
        v-if="displayTakeOffAuthorization"
        :takeOffAuthorization="takeOffAuthorization"
        :loadingTakeOffAuthorizationDetails="loadingTakeOffAuthorizationDetails"
      />
      <!-- Approval chat -->
      <v-expansion-panels
        light
        v-model="chatPanel"
        class="chat_panel my-4"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between align-center">
              <span
                class="details-card__label"
                v-translate
              >
                DISCUSSIONS
              </span>
              <v-tooltip
                v-if="approval.unread_message_for_authority && isAuthorityAdminOrManager"
                top
              >
                <template v-slot:activator="{on}">
                  <v-icon
                    v-on="on"
                    color="info"
                    class="mr-2"
                    dense
                  >
                    mdi-comment-text-outline
                  </v-icon>
                </template>
                <span v-translate>Unread messages</span>
              </v-tooltip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content expand-icon="keyboard_arrow_down">
            <ApprovalChat
              v-if="chatLoaded"
              :approval="approval"
              :newMessageDisabled="approvalNewMessageDisabled"
            />
            <v-col
              v-else
              class="text-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                :size="20"
                :width="2"
              />
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <!-- Footer approval -->
    <v-row
      no-gutters
      wrap
      class="details-card__footer px-3 py-1"
      :class="getStatusClass"
    >
      <v-col cols="4">
        <StatusIcon
          :status="approval.status"
          :isClosed="isClosed"
          withTooltip
          withText
          transparentBackground=""
        />
      </v-col>
      <!-- Mission sheet download button -->
      <v-col
        cols="4"
        class="d-flex align-center justify-center"
      >
        <v-menu
          absolute
          offset-y
          max-width="600px"
        >
          <template v-slot:activator="{ on: menu }">
            <v-tooltip
              top
              color="primary"
            >
              <template v-slot:activator="{ on: tooltip }">
                <div v-on="tooltip">
                  <v-btn
                    text
                    icon
                    v-on="menu"
                    class="m-0 p-0"
                    :loading="loading"
                    :disabled="updateStatusLoading || !missionSheetAvailable"
                  >
                    <v-icon>icon-download</v-icon>
                  </v-btn>
                </div>
              </template>
              <translate
                v-if="missionSheetAvailable"
                key="missionSheetAvailable"
              >
                Download mission sheet.
              </translate>
              <translate
                v-else
                key="missionSheetUnavailable"
              >
                The mission sheet is being generated. You will download it quickly.
              </translate>
            </v-tooltip>
          </template>
          <v-list v-if="!updateStatusLoading">
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
            >
              <v-list-item-title
                class="cursor-pointer"
                @click="downloadMissionSheet(approval.id, item.format)"
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <template v-if="!isClosed">
        <!-- Actions on approval status -->
        <v-col
          v-if="isAuthorityAdminOrManager"
          cols="4"
          class="d-flex align-center justify-end"
        >
          <v-tooltip
            v-if="approval.status !== 'refused'"
            top
            color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openDialogStatus('refused')"
              >
                <StatusIcon
                  status="refused"
                  linear
                  transparentBackground
                />
              </v-btn>
            </template>
            <translate>Refuse the approval.</translate>
          </v-tooltip>
          <v-tooltip
            v-if="activatedIncompleteStatus"
            top
            color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openDialogStatus('incomplete')"
              >
                <StatusIcon
                  status="incomplete"
                  linear
                  transparentBackground
                />
              </v-btn>
            </template>
            <translate>Ask for additional informations.</translate>
          </v-tooltip>
          <v-tooltip
            v-if="forwardMissionSheetActivated"
            top
            color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openDialogStatus('pending')"
              >
                <v-icon>mdi-send-circle-outline</v-icon>
              </v-btn>
            </template>
            <translate>Forward mission sheet.</translate>
          </v-tooltip>
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openDialogStatus('reserves')"
              >
                <StatusIcon
                  status="reserves"
                  linear
                  transparentBackground
                />
              </v-btn>
            </template>
            <translate>Accept request with restrictions.</translate>
          </v-tooltip>
          <v-tooltip
            v-if="approval.status !== 'accepted'"
            top
            color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openDialogStatus('accepted')"
              >
                <StatusIcon
                  status="accepted"
                  linear
                  transparentBackground
                />
              </v-btn>
            </template>
            <translate>Accept the request.</translate>
          </v-tooltip>
        </v-col>
        <!-- Action on approval en route -->
        <v-col
          v-else
          cols="4"
          class="d-flex align-center justify-end"
        >
          <ChangeApprovalEnRoute :approval="approval" />
        </v-col>
      </template>
    </v-row>
    <!-- Dialog to change status -->
    <v-dialog
      v-model="dialogStatus"
      width="650"
      height="800"
      persistent
    >
      <ChangeApprovalStatusForm
        :approval="approval"
        :statusForUpdate.sync="statusForUpdate"
        :updateStatusLoading.sync="updateStatusLoading"
        @close-form="() => closeDialogStatus()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import APIService from '@/services/api';

import { SET_APPROVAL_TAKE_OFF_AUTHORIZATION_NS } from '@/store/approvals';
import { CLOSED, DONE } from '@/store/status';

import ApprovalChat from '@/components/Approvals/ApprovalChat.vue';
import ApprovalDetails from '@/components/Approvals/ApprovalDetails.vue';
import AuthorityDecisionsDetails from '@/components/Approvals/AuthorityDecisionsDetails.vue';
import ChangeApprovalEnRoute from '@/components/Approvals/ChangeApprovalEnRoute.vue';
import ChangeApprovalStatusForm from '@/components/Approvals/ChangeApprovalStatusForm.vue';
import IntersectingApprovals from '@/components/Approvals/IntersectingApprovals.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import ApprovalTakeOffAuthorizationPanel from '@/components/TakeOffAuthorizations/ApprovalTakeOffAuthorizationPanel.vue';
import TakeOffAuthorizationAlertSubmitted from '@/components/TakeOffAuthorizations/TakeOffAuthorizationAlertSubmitted.vue';

export default {
  name: 'Approval',
  components: {
    ApprovalChat,
    ApprovalDetails,
    AuthorityDecisionsDetails,
    ChangeApprovalEnRoute,
    ChangeApprovalStatusForm,
    IntersectingApprovals,
    StatusIcon,
    ApprovalTakeOffAuthorizationPanel,
    TakeOffAuthorizationAlertSubmitted,
  },
  props: { approval: Object },
  data() {
    return {
      dialogStatus: false,
      loading: false,
      updateStatusLoading: false,
      items: [
        { title: this.$gettext('Mission sheet (PDF)'), format: 'PDF' },
        { title: this.$gettext('Mission sheet (Word)'), format: 'Word' },
      ],
      statusForUpdate: null,
      chatPanel: undefined,
      loadingTakeOffAuthorizationDetails: false,
    };
  },
  computed: {
    getStatusClass() {
      return { [this.isClosed ? CLOSED : this.approval.status]: true };
    },
    chatLoaded() {
      return this.approval.events !== undefined;
    },
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
    isUserAuthorityController() {
      return this.$store.getters['authentication/isAuthorityController'];
    },
    isClosed() {
      return this.approval.is_closed;
    },
    missionSheetAvailable() {
      return this.approval.mission_sheet?.status === DONE;
    },
    approvalNewMessageDisabled() {
      return this.isClosed || !this.isAuthorityAdminOrManager;
    },
    takeOffAuthorization() {
      return this.approval.take_off_authorization;
    },
    displayTakeOffAuthorization() {
      return this.isUserAuthorityController && this.takeOffAuthorization !== null;
    },
    structureSettings() {
      return this.$store.getters['structures/structureSettings'];
    },
    activatedIncompleteStatus() {
      return this.structureSettings.activated_incomplete_status;
    },
    forwardMissionSheetActivated() {
      return this.structureSettings.forward_mission_sheet_activated;
    },
  },
  created() {
    if (this.displayTakeOffAuthorization) {
      this.getTakeOffAuthorizationDetails();
    }
  },
  methods: {
    openDialogStatus(status) {
      this.statusForUpdate = status;
      this.dialogStatus = true;
    },
    closeDialogStatus() {
      this.dialogStatus = false;
      this.statusForUpdate = null;
    },
    downloadWord(approvalId) {
      this.loading = true;
      APIService.downloadApprovalWordSheet(approvalId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettext('mission_sheet.docx')
          );
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadPDF(approvalId) {
      this.loading = true;
      APIService.downloadApprovalPDFSheet(approvalId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettext('mission_sheet.pdf')
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadMissionSheet(approvalId, format) {
      switch (format) {
        case 'Word':
          return this.downloadWord(approvalId);
        default:
          return this.downloadPDF(approvalId);
      }
    },
    getTakeOffAuthorizationDetails() {
      this.loadingTakeOffAuthorizationDetails = true;
      APIService.getTakeOffAuthorization(this.takeOffAuthorization.id)
        .then(({ data }) => {
          this.$store.commit(
            SET_APPROVAL_TAKE_OFF_AUTHORIZATION_NS,
            { approvalId: data.approval_id, takeOffAuthorization: data },
          );
        })
        .finally(() => {
          this.loadingTakeOffAuthorizationDetails = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.chat_panel ::v-deep .v-expansion-panel-content__wrap {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
}
.off-sight-style {
  font-weight: bold;
  color: red;
}

</style>
