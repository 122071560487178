var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":!_vm.tooltipBottom,"bottom":_vm.tooltipBottom},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flight-listing__action-button",attrs:{"text":"","icon":"","dark":"","small":"","disabled":_vm.disabledActions},on:{"click":function($event){return _vm.$emit('weather-flight', _vm.flight)}}},on),[_c('v-icon',[_vm._v("mdi-weather-partly-cloudy")])],1)]}}])},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Météo")])]),_c('v-tooltip',{attrs:{"top":!_vm.tooltipBottom,"bottom":_vm.tooltipBottom},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flight-listing__action-button",attrs:{"text":"","icon":"","dark":"","small":"","disabled":_vm.disabledActions},on:{"click":function($event){return _vm.$emit('center-on-flight', _vm.flight)}}},on),[_c('v-icon',[_vm._v("mdi-map-marker-outline")])],1)]}}])},[(_vm.flight.address)?_c('span',[_vm._v(" "+_vm._s(_vm.flight.address)+" ")]):(_vm.flight.commune)?_c('span',[_vm._v(" "+_vm._s(_vm.flight.commune)+" ")]):_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Zoom to mission ")])]),(!_vm.flight.is_archived)?_c('v-tooltip',{attrs:{"top":!_vm.tooltipBottom,"bottom":_vm.tooltipBottom},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flight-listing__action-button",attrs:{"text":"","icon":"","dark":"","small":"","loading":_vm.isSelectedFlightDetailsLoading,"disabled":_vm.disabledActions},on:{"click":function($event){return _vm.$emit('edit-flight', _vm.flight)}}},on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,false,312973830)},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Edit")])]):_vm._e(),(!_vm.simplifiedActions)?_c('v-tooltip',{attrs:{"top":!_vm.tooltipBottom,"bottom":_vm.tooltipBottom},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flight-listing__action-button",attrs:{"text":"","icon":"","dark":"","small":"","loading":_vm.isSelectedFlightDetailsLoading,"disabled":_vm.disabledActions},on:{"click":function($event){return _vm.$emit('duplicate-flight', _vm.flight)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,3329675740)},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Duplicate mission")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":!_vm.tooltipBottom,"bottom":_vm.tooltipBottom},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flight-listing__action-button",attrs:{"text":"","icon":"","dark":"","small":"","loading":_vm.isSelectedFlightDetailsLoading,"disabled":_vm.disabledActions},on:{"click":function($event){return _vm.$emit('close-flight', _vm.flight)}}},on),[_c('v-icon',[_vm._v("mdi-archive-arrow-down-outline")])],1)]}}])},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Archive")])]),(!_vm.isGeneralistPilot)?_c('v-tooltip',{attrs:{"top":!_vm.tooltipBottom,"bottom":_vm.tooltipBottom},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flight-listing__action-button",attrs:{"text":"","icon":"","dark":"","small":"","loading":_vm.isSelectedFlightDetailsLoading,"disabled":_vm.disabledActions},on:{"click":function($event){return _vm.$emit('delete-flights', _vm.flight)}}},on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,false,985338676)},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Delete")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }