<template>
  <div class="drone-list">
    <div class="d-flex align-center justify-space-between mt-4">
      <v-text-field
        v-model="search"
        :append-icon="search ? null : 'search'"
        class="searchbox"
        :placeholder="$gettext('Recherche')"
        dark
        solo
        flat
        clearable
        hide-details
      />
      <v-switch
        v-model="seeAllDrones"
        :label="$gettext('See all')"
        color="primary"
        class="small-dense__switch mx-4"
        dark
        hide-details
      />
    </div>
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="drones"
      :loading="loading"
      :search="search"
      :custom-filter="searchWithSubExploitants"
      :class="{transparent: true}"
      :expanded="expanded"
      dark
      single-expand
      calculate-widths
      :loading-text="$gettext('Chargement des drones...')"
      item-key="id"
      :no-results-text="labels.noDroneFound"
      :no-data-text="labels.noDroneFound"
      :mobile-breakpoint="singleColumnBreakpoint"
      :footer-props="{
        'items-per-page-text': $gettext('Lignes par page :'),
        'items-per-page-all-text': $gettext('Tous'),
      }"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <!-- Single column compacted row: for narrow mobile screens -->
        <template v-if="isSingleColumn">
          <tr
            :class="getClass(props)"
            class="cursor-pointer"
            @click="selectItem(props)"
          >
            <td class="truncated cell">
              <v-row
                no-gutters
                align="center"
                justify="center"
              >
                <v-col
                  cols="4"
                  class="pl-3"
                >
                  {{ props.item.name }}
                </v-col>
                <v-col cols="4">
                  {{ props.item.model_name }}
                </v-col>
                <v-col
                  cols="4"
                  v-if="props.item.alpha_tango_id"
                >
                  UAS-FR-{{ props.item.alpha_tango_id }}
                </v-col>
                <v-col
                  cols="4"
                  v-else
                />
              </v-row>
            </td>
          </tr>
        </template>
        <!-- Multicolumn row: for wider mobile screens and desktop screens-->
        <template v-else>
          <tr
            :class="getClass(props)"
            class="cursor-pointer"
            @click="selectItem(props)"
          >
            <td class="pl-3">
              {{ props.item.name }}
            </td>
            <td>
              {{ props.item.model_name }}
            </td>
            <td v-if="props.item.alpha_tango_id">
              UAS-FR-{{ props.item.alpha_tango_id }}
            </td>
            <td v-else />
          </tr>
        </template>
      </template>
      <template v-slot:expanded-item="{ headers, item}">
        <td
          :colspan="headers.length"
          class="pa-0"
          :class="{ archived: !item.active}"
        >
          <Drone
            :drone="item"
            @edit-drone="(data) => $emit('edit-drone', data)"
            @delete-drone="(data) => $emit('delete-drone', data)"
          />
        </td>
      </template>
      <!-- Import from Alpha Tango -->
      <div
        v-if="isExploitantAdmin"
        slot="footer"
        text-center
        class="d-flex flex-column align-center"
      >
        <v-btn
          class="ma-4 font-weight-regular"
          color="success"
          @click="importAlphaTangoDialog=true"
          data-tour="importDronesButton"
        >
          <translate>Importer depuis Alpha Tango</translate>
        </v-btn>
        <a
          target="_blank"
          href="https://clearance.aero/importer-ses-drones-depuis-alphatango/"
          class="white--text pb-2"
          v-translate
        >
          How to import your drones?
        </a>
        <v-dialog
          v-model="importAlphaTangoDialog"
          width="620"
          persistent
          :retain-focus="false"
        >
          <ImportAlphaTangoForm @close-form="importAlphaTangoDialog=false" />
        </v-dialog>
      </div>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }}
        <span v-translate>de</span>
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { APPLICATION_TAB } from '@/store/application';
import { GET_DRONES_NS } from '@/store/drones';

import Drone from '@/components/Drones/Drone.vue';
import ImportAlphaTangoForm from '@/components/Drones/ImportAlphaTangoForm.vue';

export default {
  name: 'DroneListing',
  components: {
    Drone,
    ImportAlphaTangoForm,
  },
  data() {
    return {
      headers: [
        {
          text: this.$gettext('Nom'),
          value: 'name',
          width: '33%',
          class: 'pl-3',
        },
        {
          text: this.$gettext('Modèle'),
          value: 'model_name',
          width: '33%',
        },
        {
          text: this.$gettext('Numéro Alpha Tango'),
          value: 'alpha_tango_id',
          width: '33%',
        },
      ],
      search: '',
      importAlphaTangoDialog: false,
      expanded: [],
      index: null,
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
      seeAllDrones: false,
    };
  },
  computed: {
    labels() {
      return {
        noDroneFound: this.$gettext(`Aucun drone trouvé. Vous pouvez en créer en cliquant sur le
          bouton "Importer depuis Alpha Tango" (nécessite un compte administrateur) ou en cliquant
          sur le bouton rouge ci-dessous.`),
      };
    },
    isSingleColumn() {
      if (!this.$vuetify.breakpoint.width) return false;
      return this.$vuetify.breakpoint.width < this.singleColumnBreakpoint;
    },
    drones() {
      if (this.seeAllDrones) {
        return this.$store.state.drones.drones;
      }
      return this.$store.state.drones.drones.filter((drone) => drone.active);
    },
    loading() {
      return this.$store.state.drones.loadingDrones;
    },
    isExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    activeTab() {
      return this.$store.getters['application/activeTab'];
    },
    subExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
  },
  watch: {
    activeTab() {
      if (this.activeTab !== APPLICATION_TAB.DRONE) {
        this.expanded = [];
        this.index = null;
      }
    },
  },
  created() {
    this.getDrones();
  },
  methods: {
    getDrones() {
      this.$store.dispatch(GET_DRONES_NS);
    },
    selectItem(props) {
      if (this.expanded.length === 0) {
        this.expanded = [props.item];
        this.index = props.index;
      } else if (this.index === props.index) {
        this.expanded = [];
        this.index = null;
      } else {
        this.expanded = [props.item];
        this.index = props.index;
      }
    },
    getClass(props) {
      const parent = props.isExpanded ? 'selected' : 'not-selected';
      const child = props.item.active ? 'not-archived' : 'archived';
      return {
        [parent]: true,
        [child]: true,
      };
    },
    searchWithSubExploitants(value, search, item) {
      if (!search) {
        return true;
      }
      const searchedSubExploitants = (
        this.subExploitants.filter((s) => s.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
          .map((s) => s.id)
      );
      return (
        (value && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1)
        || item.sub_exploitants.some((s) => searchedSubExploitants.includes(s))
      );
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.drone-list ::v-deep .v-data-table__wrapper>table>tbody> {
  tr,
  tr:hover,
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: white;
    color: $color-secondary;
    &.archived {
      background-color: $color-blue-grey;
      color: $color-white;
    }
    &.not-archived {
      background-color: $color-primary;
      color: $color-white;
    }
  }
  tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 10px solid $color-secondary;
      height: 50px;
    }
    &:first-child td {
      height: 40px;
    }
  }
  td.archived {
    .details-card__footer {
      background-color: $color-blue-grey;
    }
  }
}
.drone-list ::v-deep .v-data-table table {
  border-collapse: initial;
}

</style>
