<template>
  <div class="user-list">
    <div class="mt-4">
      <v-text-field
        v-model="search"
        :append-icon="search ? null : 'search'"
        class="searchbox"
        :placeholder="$gettext('Recherche')"
        dark
        solo
        flat
        clearable
        hide-details
      />
    </div>
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="users"
      :loading="loading"
      :search="search"
      :class="{transparent: true}"
      dark
      :expanded="expanded"
      single-expand
      :loading-text="$gettext('Chargement des utilisateurs...')"
      item-key="id"
      :no-results-text="$gettext('Aucun utilisateur trouvé.')"
      :no-data-text="$gettext('Aucun utilisateur trouvé.')"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <tr
          :class="getClass(props)"
          class="cursor-pointer"
          @click="selectItem(props)"
        >
          <td class="text-center" />
          <td>
            {{ props.item.username }}
          </td>
          <td>
            {{ props.item.email }}
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pa-0"
        >
          <User :user="item" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { GET_USERS_NS } from '@/store/users';

import User from '@/components/Users/User.vue';

export default {
  name: 'UserListing',
  components: { User },
  data() {
    return {
      headers: [{
        text: '',
        value: 'id',
        width: '60',
      }, {
        text: this.$gettext("Nom d'utilisateur"),
        value: 'username',
      }, {
        text: this.$gettext('Email'),
        value: 'email',
      }],
      search: '',
      expanded: [],
    };
  },
  computed: {
    users() {
      return this.$store.state.users.users;
    },
    loading() {
      return this.$store.state.users.loadingUsers;
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.$store.dispatch(GET_USERS_NS);
    },
    selectItem(props) {
      this.expanded = [props.item];
    },
    getClass(props) {
      return props.isExpanded ? 'selected' : 'not-selected';
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.user-list ::v-deep .v-data-table__wrapper>table>tbody>tr,
.user-list ::v-deep .v-data-table__wrapper>table>tbody>tr:hover {
  background-color: white;
  color: black;
  &.selected {
    color: $color-white;
    background-color: $color-primary;
  }
}

.user-list ::v-deep .v-data-table table {
  border-collapse: initial;
}

.user-list ::v-deep .v-data-table__wrapper>table>tbody> {
  tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 10px solid $color-secondary;
      height: 50px;
    }
    &:first-child td {
      height: 40px;
    }
  }
}

</style>
