import Vue from 'vue';

import APIService from '@/services/api';

/* eslint-disable import/no-cycle */
import {
  getPageFromNextPreviousURL,
  APPLICATION_DISPLAY,
  APPLICATION_SPLIT,
  SET_DISPLAY_NS,
  SET_ACTIVE_SPLIT_NS,
  SET_SNACKBAR_MESSAGE_NS,
  SHOW_SNACKBAR_NS,
} from '@/store/application';
import {
  SET_BOUNDING_BOX_NS,
  SET_MAP_FEATURE_SELECTED_NS,
  SET_MAP_FEATURE_HOVERED_NS,
  SELECTABLE_FEATURE_KEYS,
} from '@/store/map';
import { SUBMITTED } from '@/store/status';

const namespace = 'flights';

const GET_FLIGHTS = 'GET_FLIGHTS';
const GET_FLIGHTS_GEOMETRY = 'GET_FLIGHTS_GEOMETRY';
const GET_ALL_FLIGHTS = 'GET_ALL_FLIGHTS';
const GET_TABLE_ITEMS_FLIGHTS = 'GET_TABLE_ITEMS_FLIGHTS';
const FETCH_FLIGHTS_COLLECTION = 'FETCH_FLIGHTS_COLLECTION';
const SET_FLIGHTS_COLLECTION = 'SET_FLIGHTS_COLLECTION';
const FETCH_TABLE_ITEMS = 'FETCH_TABLE_ITEMS';
const SET_TABLE_ITEMS = 'SET_TABLE_ITEMS';

const GET_FLIGHT_META = 'GET_FLIGHT_META';
const SET_FLIGHT_META = 'SET_FLIGHT_META';
const SET_FLIGHT_META_LOADED = 'SET_FLIGHT_META_LOADED';
const GET_FLIGHT_STATUS = 'GET_FLIGHT_STATUS';
const SET_FLIGHT_STATUS = 'SET_FLIGHT_STATUS';
const GET_FLIGHT_DETAILS = 'GET_FLIGHT_DETAILS';
const SET_FLIGHT_DETAILS = 'SET_FLIGHT_DETAILS';
const SET_SELECTED_FLIGHT_DETAILS_LOADING = 'SET_SELECTED_FLIGHT_DETAILS_LOADING';
const SET_SELECTED_FLIGHT_DETAILS_LOADED = 'SET_SELECTED_FLIGHT_DETAILS_LOADED';
const SET_FLIGHT_CONSTRAINTS_ANALYSIS = 'SET_FLIGHT_CONSTRAINTS_ANALYSIS';
const SET_FLIGHT_NOTAMS_ANALYSIS = 'SET_FLIGHT_NOTAMS_ANALYSIS';
const SET_FLIGHT_USK_CONSTRAINTS_ANALYSIS = 'SET_FLIGHT_USK_CONSTRAINTS_ANALYSIS';
const SET_FLIGHT_PREFECTURE_NOTIFICATIONS = 'SET_FLIGHT_PREFECTURE_NOTIFICATIONS';
const SET_FLIGHT_ARMY_NOTIFICATIONS = 'SET_FLIGHT_ARMY_NOTIFICATIONS';
const SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED = 'SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED';
const SET_SELECTED_FLIGHT_NOTAMS_ANALYSIS_LOADED = 'SET_SELECTED_FLIGHT_NOTAMS_ANALYSIS_LOADED';
const GET_FLIGHT_DOCUMENTS = 'GET_FLIGHT_DOCUMENTS';
const ADD_FLIGHT_DOCUMENT = 'ADD_FLIGHT_DOCUMENT';
const DELETE_FLIGHT_DOCUMENT = 'DELETE_FLIGHT_DOCUMENT';
const SET_FLIGHT_DOCUMENTS = 'SET_FLIGHT_DOCUMENTS';
const SET_FLIGHT_LANDED = 'SET_FLIGHT_LANDED';
const ADD_FLIGHT_CREATED = 'ADD_FLIGHT_CREATED';
const UPDATE_FLIGHT_ID_UPDATED_OR_CREATED = 'UPDATE_FLIGHT_ID_UPDATED_OR_CREATED';
const REMOVE_FLIGHT = 'REMOVE_FLIGHT';
const SET_FLIGHT_CONSTRAINTS = 'SET_FLIGHT_CONSTRAINTS';
const SET_FLIGHT_ANALYZED = 'SET_FLIGHT_ANALYZED';
const UPDATE_FLIGHT_AFTER_CONSTRAINTS_CREATION = 'UPDATE_FLIGHT_AFTER_CONSTRAINTS_CREATION';
const UPDATE_FLIGHT_REQUEST_EMAIL = 'UPDATE_FLIGHT_REQUEST_EMAIL';

const SET_FLIGHT_CONSTRAINT_STATUS = 'SET_FLIGHT_CONSTRAINT_STATUS';
const SET_FLIGHT_CONSTRAINT_SHEET_STATUS = 'SET_FLIGHT_CONSTRAINT_SHEET_STATUS';
const UPDATE_FLIGHT_CONSTRAINT_STATUS = 'UPDATE_FLIGHT_CONSTRAINT_STATUS';
const SET_FLIGHT_CONSTRAINT_NOTE = 'SET_FLIGHT_CONSTRAINT_NOTE';
const SET_PAGINATION = 'SET_PAGINATION';
const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
const SET_FILTERS = 'SET_FILTERS';
const RESET_FILTERS = 'RESET_FILTERS';
const RESET_FILTERS_ALL_FLIGHTS = 'RESET_FILTERS_ALL_FLIGHTS';
const SET_FLIGHT_SELECTED = 'SET_FLIGHT_SELECTED';
const SET_FLIGHT_HOVER = 'SET_FLIGHT_HOVER';
const SET_CONSTRAINT_HOVER = 'SET_CONSTRAINT_HOVER';
const ADD_CONSTRAINT_SELECTED = 'ADD_CONSTRAINT_SELECTED';
const REMOVE_CONSTRAINT_SELECTED = 'REMOVE_CONSTRAINT_SELECTED';
const REMOVE_ALL_CONSTRAINTS_SELECTED = 'REMOVE_ALL_CONSTRAINTS_SELECTED';
const ADD_NOTAM_SELECTED = 'ADD_NOTAM_SELECTED';
const REMOVE_NOTAM_SELECTED = 'REMOVE_NOTAM_SELECTED';
const REMOVE_ALL_NOTAMS_SELECTED = 'REMOVE_ALL_NOTAMS_SELECTED';
const SET_FLIGHT_APPROVAL_ID_SELECTED = 'SET_FLIGHT_APPROVAL_ID_SELECTED';
const RESET_FLIGHTS = 'RESET_FLIGHTS';
const GET_ALL_ANALYSIS = 'GET_ALL_ANALYSIS';
const GET_CONSTRAINTS_ANALYSIS = 'GET_CONSTRAINTS_ANALYSIS';
const GET_GEOMETRY_CONSTRAINTS_ANALYSIS = 'GET_GEOMETRY_CONSTRAINTS_ANALYSIS';
const SET_GEOMETRY_CONSTRAINTS_ANALYSIS_LOADED = 'SET_GEOMETRY_CONSTRAINTS_ANALYSIS_LOADED';
const GET_GEOMETRY_NOTAMS_ANALYSIS = 'GET_GEOMETRY_NOTAMS_ANALYSIS';
const SET_GEOMETRY_NOTAMS_ANALYSIS_LOADED = 'SET_GEOMETRY_NOTAMS_ANALYSIS_LOADED';
const SET_FLIGHT_APPROVAL_MISSION_SHEET = 'SET_FLIGHT_APPROVAL_MISSION_SHEET';
const UPDATE_FLIGHT_APPROVALS = 'UPDATE_FLIGHT_APPROVALS';
const SET_FLIGHT_APPROVAL_PROPERTY = 'SET_FLIGHT_APPROVAL_PROPERTY';
const SET_SIMPLIFIED_FLIGHT_FORM_OPEN = 'SET_SIMPLIFIED_FLIGHT_FORM_OPEN';
const SET_UPDATING_FLIGHT_ID = 'SET_UPDATING_FLIGHT_ID';
const SET_SLICE_FLIGHT_AREA = 'SET_SLICE_FLIGHT_AREA';

export const GET_ALL_FLIGHTS_NS = `${namespace}/${GET_ALL_FLIGHTS}`;
export const GET_TABLE_ITEMS_FLIGHTS_NS = `${namespace}/${GET_TABLE_ITEMS_FLIGHTS}`;
export const SET_FLIGHT_META_NS = `${namespace}/${SET_FLIGHT_META}`;
export const GET_FLIGHT_META_NS = `${namespace}/${GET_FLIGHT_META}`;
export const GET_FLIGHT_STATUS_NS = `${namespace}/${GET_FLIGHT_STATUS}`;
export const SET_FLIGHT_META_LOADED_NS = `${namespace}/${SET_FLIGHT_META_LOADED}`;
export const GET_FLIGHT_DETAILS_NS = `${namespace}/${GET_FLIGHT_DETAILS}`;
export const SET_FLIGHT_SELECTED_NS = `${namespace}/${SET_FLIGHT_SELECTED}`;
export const SET_SELECTED_FLIGHT_DETAILS_LOADED_NS = `${namespace}/${SET_SELECTED_FLIGHT_DETAILS_LOADED}`;
export const ADD_FLIGHT_DOCUMENT_NS = `${namespace}/${ADD_FLIGHT_DOCUMENT}`;
export const DELETE_FLIGHT_DOCUMENT_NS = `${namespace}/${DELETE_FLIGHT_DOCUMENT}`;
export const SET_FLIGHT_HOVER_NS = `${namespace}/${SET_FLIGHT_HOVER}`;
export const SET_CONSTRAINT_HOVER_NS = `${namespace}/${SET_CONSTRAINT_HOVER}`;
export const ADD_CONSTRAINT_SELECTED_NS = `${namespace}/${ADD_CONSTRAINT_SELECTED}`;
export const REMOVE_CONSTRAINT_SELECTED_NS = `${namespace}/${REMOVE_CONSTRAINT_SELECTED}`;
export const REMOVE_ALL_CONSTRAINTS_SELECTED_NS = `${namespace}/${REMOVE_ALL_CONSTRAINTS_SELECTED}`;
export const ADD_NOTAM_SELECTED_NS = `${namespace}/${ADD_NOTAM_SELECTED}`;
export const REMOVE_NOTAM_SELECTED_NS = `${namespace}/${REMOVE_NOTAM_SELECTED}`;
export const REMOVE_ALL_NOTAMS_SELECTED_NS = `${namespace}/${REMOVE_ALL_NOTAMS_SELECTED}`;
export const SET_FLIGHT_APPROVAL_ID_SELECTED_NS = `${namespace}/${SET_FLIGHT_APPROVAL_ID_SELECTED}`;
export const SET_FLIGHT_STATUS_NS = `${namespace}/${SET_FLIGHT_STATUS}`;
export const SET_FLIGHT_LANDED_NS = `${namespace}/${SET_FLIGHT_LANDED}`;
export const SET_FLIGHT_CONSTRAINT_NOTE_NS = `${namespace}/${SET_FLIGHT_CONSTRAINT_NOTE}`;
export const SET_FLIGHT_CONSTRAINT_STATUS_NS = `${namespace}/${SET_FLIGHT_CONSTRAINT_STATUS}`;
export const UPDATE_FLIGHT_CONSTRAINT_STATUS_NS = `${namespace}/${UPDATE_FLIGHT_CONSTRAINT_STATUS}`;
export const SET_PAGINATION_NS = `${namespace}/${SET_PAGINATION}`;
export const UPDATE_PAGINATION_NS = `${namespace}/${UPDATE_PAGINATION}`;
export const SET_FILTERS_NS = `${namespace}/${SET_FILTERS}`;
export const RESET_FILTERS_NS = `${namespace}/${RESET_FILTERS}`;
export const RESET_FILTERS_ALL_FLIGHTS_NS = `${namespace}/${RESET_FILTERS_ALL_FLIGHTS}`;
export const RESET_FLIGHTS_NS = `${namespace}/${RESET_FLIGHTS}`;
export const SET_FLIGHT_CONSTRAINT_SHEET_STATUS_NS = `${namespace}/${SET_FLIGHT_CONSTRAINT_SHEET_STATUS}`;
export const SET_FLIGHT_APPROVAL_MISSION_SHEET_NS = `${namespace}/${SET_FLIGHT_APPROVAL_MISSION_SHEET}`;
export const UPDATE_FLIGHT_APPROVALS_NS = `${namespace}/${UPDATE_FLIGHT_APPROVALS}`;
export const SET_FLIGHT_APPROVAL_PROPERTY_NS = `${namespace}/${SET_FLIGHT_APPROVAL_PROPERTY}`;
export const SET_FLIGHT_CONSTRAINTS_NS = `${namespace}/${SET_FLIGHT_CONSTRAINTS}`;
export const UPDATE_FLIGHT_REQUEST_EMAIL_NS = `${namespace}/${UPDATE_FLIGHT_REQUEST_EMAIL}`;
export const UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS = `${namespace}/${UPDATE_FLIGHT_ID_UPDATED_OR_CREATED}`;
export const SET_SIMPLIFIED_FLIGHT_FORM_OPEN_NS = `${namespace}/${SET_SIMPLIFIED_FLIGHT_FORM_OPEN}`;
export const ADD_FLIGHT_CREATED_NS = `${namespace}/${ADD_FLIGHT_CREATED}`;
export const SET_UPDATING_FLIGHT_ID_NS = `${namespace}/${SET_UPDATING_FLIGHT_ID}`;
export const SET_SLICE_FLIGHT_AREA_NS = `${namespace}/${SET_SLICE_FLIGHT_AREA}`;
export const SET_FLIGHT_PREFECTURE_NOTIFICATIONS_NS = `${namespace}/${SET_FLIGHT_PREFECTURE_NOTIFICATIONS}`;
export const SET_FLIGHT_ARMY_NOTIFICATIONS_NS = `${namespace}/${SET_FLIGHT_ARMY_NOTIFICATIONS}`;
export const GET_CONSTRAINTS_ANALYSIS_NS = `${namespace}/${GET_CONSTRAINTS_ANALYSIS}`;
export const SET_FLIGHT_USK_CONSTRAINTS_ANALYSIS_NS = `${namespace}/${SET_FLIGHT_USK_CONSTRAINTS_ANALYSIS}`;

const MAX_FLIGHTS_FOR_GEO_REQUESTS = 1000;
const NOT_FOUND_ERROR = 'NOT FOUND';

const defaultFilters = {
  dateFrom: '',
  dateTo: '',
  q: null,
  isArchived: false,
  isEntrusted: false,
  currentWeek: false,
  pilotsCategory: [],
  pilots: [],
  drones: [],
  tags: [],
};

function updateFlight(state, { flightId, properties }) {
  const flight = state.flightsCollection.find((f) => f.id === flightId);
  const updatedProperties = { ...flight, ...properties };
  Object.keys(properties).forEach((key) => {
    Vue.set(flight, key, updatedProperties[key]);
    if (key === 'geometryConstraints') {
      Vue.set(state, 'geometryConstraintsLoaded', true);
    } else if (key === 'geometryNotams') {
      Vue.set(state, 'geometryNotamsLoaded', true);
    }
  });
}

function updateFlightApprovals(state, { flightId, approvals }) {
  const flightIndex = state.flightsCollection.findIndex((f) => f.id === flightId);
  const flight = { ...state.flightsCollection[flightIndex] };
  const { approvals: flightApprovals } = flight;
  if (flightApprovals) {
    approvals.forEach((approval) => {
      const approvalIndex = flightApprovals.findIndex(
        (a) => a === approval.id || a.id === approval.id,
      );
      if (approvalIndex === -1) {
        flightApprovals.push(approval);
      } else {
        Vue.set(flightApprovals, approvalIndex, approval);
      }
    });
    Vue.set(flight, 'approvals', flightApprovals);
    Vue.set(state.flightsCollection, flightIndex, flight);
  }
}

function updateFlightApprovalProperty(
  state, { flightId, approvalId, propertyKey, propertyValue },
) {
  const flightIndex = state.flightsCollection.findIndex((f) => f.id === flightId);
  const flight = state.flightsCollection[flightIndex];
  const { approvals } = flight;
  if (approvals) {
    const approvalIndex = approvals.findIndex((a) => a.id === approvalId);
    let approval;
    if (approvalIndex > -1) {
      approval = { ...approvals[approvalIndex] };
      Vue.set(approval, propertyKey, propertyValue);
      Vue.set(approvals, approvalIndex, approval);
      Vue.set(flight, 'approvals', approvals);
      Vue.set(state.flightsCollection, flightIndex, flight);
    }
  }
}

function initialState() {
  return {
    flightsCollection: [],
    flightsCollectionLoading: false,
    flightsCollectionLoaded: false,
    itemsLength: 0,
    pagination: {
      itemsPerPage: 25,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
    tableIds: [], // Ordered list of flight ids according to pagination
    tableItemsLoading: false,
    tableItemsLoadingError: {},
    constraintIdHover: null,
    constraintIdsSelected: [],
    notamIdsSelected: [],
    flightApprovalIdSelected: null,
    isSelectedFlightDetailsLoading: false,
    isSelectedFlightDetailsLoaded: false,
    isSelectedFlightConstraintsAnalysisLoaded: false,
    isSelectedFlightNotamsAnalysisLoaded: false,
    meta: { isFlightMetaLoaded: false },
    filters: { ...defaultFilters },
    geometryConstraintsLoaded: false,
    geometryNotamsLoaded: false,
    flightIdUpdatedOrCreated: undefined,
    simplifiedFlightFormOpen: false,
    updatingFlightId: undefined,
    sliceFlightArea: undefined,
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    tableItems: (state) => {
      const tableItems = [];
      state.flightsCollection.forEach((flight) => {
        const index = state.tableIds.indexOf(flight.id);
        if (index !== -1) {
          tableItems[index] = flight;
        }
      });
      return tableItems;
    },
    isTableItemsLoading: (state) => state.tableItemsLoading,
    mapCenters: (state) => {
      const features = state.flightsCollection.map((flight) => {
        const {
          id,
          center: geometry,
          en_route: enRoute,
          is_archived: isArchived,
          name,
          status,
          tags,
        } = flight;
        return {
          type: 'Feature',
          id,
          properties: {
            en_route: enRoute,
            is_archived: isArchived,
            name,
            status,
            tags,
          },
          geometry,
        };
      });
      return { type: 'FeatureCollection', features };
    },
    mapAreas: (state) => {
      const features = [];
      state.flightsCollection.map((flight) => flight.areas?.forEach((area) => {
        const { id, geometry, name } = area;
        const { id: flightId, en_route: enRoute, is_archived: isArchived, status } = flight;
        features.push({
          type: 'Feature',
          id,
          properties: {
            flight_id: flightId,
            en_route: enRoute,
            is_archived: isArchived,
            name,
            status,
          },
          geometry,
        });
      }));
      return { type: 'FeatureCollection', features };
    },
    flightSelected: (state, getters, rootState) => state.flightsCollection.find((f) => {
      if (f.id === rootState.map.featureIdSelected.flight) {
        return f;
      }
      return undefined;
    }),
    flightAreasSelected: (state, getters) => {
      if (getters.flightSelected) {
        const areas = getters.flightSelected.areas.map((a) => a.id);
        return getters.mapAreas.features.filter((feature) => areas.includes(feature.id));
      }
      return undefined;
    },
    flightApprovalSelected: (state, getters) => (
      getters.flightSelected?.approvals?.find(
        (approval) => approval.id === state.flightApprovalIdSelected,
      )
    ),
    approvalsDocuments: (state, getters) => {
      if (getters.flightSelected) {
        const flight = getters.flightSelected;
        if (flight?.approvals) {
          return flight.approvals.map((approval) => ({
            constraint_name: approval.constraint_name,
            approval_id: approval.id,
            approval_identifier: approval.display_identifier,
            approval_mission_sheet: approval.mission_sheet,
            approval_events_documents: approval.events_documents,
          }));
        }
      }
      return [];
    },
    mapApprovalAreas: (state, getters) => {
      const features = [];
      const flightAreasIdsDisplayed = [];
      const { flightAreasSelected, flightApprovalSelected: approval } = getters;
      if (flightAreasSelected && approval) {
        // Display areas with decision
        if (approval?.decisions?.length) {
          approval?.decisions.forEach((decision) => {
            const { status } = decision;
            decision.sub_approvals.forEach((subApproval) => {
              const feature = flightAreasSelected.find(
                (area) => area.id === subApproval.flight_area_id,
              );
              if (feature) {
                flightAreasIdsDisplayed.push(feature.id);
                let { name } = feature.properties;
                if (subApproval.identifier) {
                  name += ` (${subApproval.identifier})`;
                }
                features.push({
                  type: 'Feature',
                  id: feature.id,
                  properties: {
                    status,
                    name,
                    is_closed: approval.is_closed,
                  },
                  geometry: feature.geometry,
                });
              }
            });
          });
        }
        // Display areas without decision
        flightAreasSelected.forEach((feature) => {
          if (!flightAreasIdsDisplayed.includes(feature.id)) {
            features.push({
              type: 'Feature',
              id: feature.id,
              properties: {
                status: SUBMITTED,
                name: feature.properties.name,
                is_closed: approval.is_closed,
              },
              geometry: feature.geometry,
            });
          }
        });
      }
      return { type: 'FeatureCollection', features };
    },
    flightHover: (state, getters, rootState) => state.flightsCollection.find(
      (f) => f.id === rootState.map.featureIdHovered.flight,
    ),
    constraintsToDisplay: (state, getters) => {
      const { flightSelected } = getters;
      if (
        !flightSelected
        || !flightSelected.geometryConstraints
        || !state.geometryConstraintsLoaded
      ) {
        return [];
      }
      const { geometryConstraints } = flightSelected;
      return geometryConstraints.filter((c) => state.constraintIdsSelected.includes(c.id));
    },
    notamsToDisplay: (state, getters) => {
      const { flightSelected } = getters;
      if (!flightSelected || !flightSelected.geometryNotams || !state.geometryNotamsLoaded) {
        return [];
      }
      const { geometryNotams } = flightSelected;
      return geometryNotams.filter((n) => state.notamIdsSelected.includes(n.id));
    },
    constraintHover: (state, getters) => {
      const { flightSelected } = getters;
      if (
        !flightSelected
        || !flightSelected.geometryConstraints
        || !state.geometryConstraintsLoaded
      ) {
        return [];
      }
      const { geometryConstraints } = flightSelected;
      return geometryConstraints.find((c) => c.id === state.constraintIdHover);
    },
    activeFilters: (state, getters, rootState) => {
      let activeFilters = 0;
      const { filters } = state;
      Object.keys(defaultFilters).forEach((key) => {
        if (['pilotsCategory', 'pilots', 'drones', 'tags'].includes(key)) {
          if (filters[key].length) {
            activeFilters += 1;
          }
        } else if (filters[key] !== defaultFilters[key]) {
          activeFilters += 1;
        }
      });
      if (rootState.authentication.user.display_only_my_flights === true) {
        activeFilters += 1;
      }
      return activeFilters;
    },
    updatingFlight: (state) => state.flightsCollection.find((f) => f.id === state.updatingFlightId),
  },
  mutations: {
    [ADD_FLIGHT_CREATED](state, { flightId }) {
      const index = state.flightsCollection.findIndex((f) => f.id === flightId);
      if (index === -1) {
        const flight = { id: flightId };
        const collection = state.flightsCollection;
        collection.unshift(flight);
        Vue.set(state, 'flightsCollection', collection);
      }
    },
    [UPDATE_FLIGHT_ID_UPDATED_OR_CREATED](state, { flightId }) {
      Vue.set(state, 'flightIdUpdatedOrCreated', flightId);
    },
    [REMOVE_FLIGHT](state, { flightId }) {
      const index = state.flightsCollection.findIndex((f) => f.id === flightId);
      if (index !== -1) {
        const collection = state.flightsCollection;
        collection.splice(index, 1);
        Vue.set(state, 'flightsCollection', collection);
      }
    },
    [RESET_FLIGHTS](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [FETCH_FLIGHTS_COLLECTION](state) {
      Vue.set(state, 'tableIds', []);
      Vue.set(state, 'flightsCollectionLoading', true);
    },
    [SET_FLIGHTS_COLLECTION](
      state,
      {
        flightsCollection = [],
        flightsCollectionLoaded = false,
        flightsCollectionLoading,
      },
    ) {
      Vue.set(state, 'flightsCollection', flightsCollection);
      Vue.set(state, 'flightsCollectionLoaded', flightsCollectionLoaded);
      Vue.set(state, 'flightsCollectionLoading', flightsCollectionLoading);
    },
    [FETCH_TABLE_ITEMS](state) {
      Vue.set(state, 'tableItemsLoading', true);
    },
    [SET_TABLE_ITEMS](state, { items = [], tableItemsLoadingError }) {
      items.forEach((item) => {
        const index = state.flightsCollection.findIndex((flight) => flight.id === item.id);
        const flight = state.flightsCollection[index];
        const properties = { ...item, ...flight };
        Vue.set(state.flightsCollection, index, properties);
      });
      Vue.set(state, 'tableIds', items.map((i) => i.id));
      Vue.set(state, 'tableItemsLoadingError', tableItemsLoadingError);
      Vue.set(state, 'tableItemsLoading', false);
    },
    [SET_FLIGHT_META](state, meta) {
      Object.entries(meta).forEach(([key, value]) => {
        Vue.set(state.meta, key, value);
      });
    },
    [SET_FLIGHT_DETAILS](state, { flight, flightId }) {
      updateFlight(state, { flightId, properties: flight });
    },
    [SET_FLIGHT_DOCUMENTS](state, { flightId, documents }) {
      updateFlight(state, { flightId, properties: { documents } });
    },
    [ADD_FLIGHT_DOCUMENT](state, { flightId, document }) {
      const flight = state.flightsCollection.find((f) => f.id === flightId);
      if (flight) {
        const { documents } = flight;
        if (documents) {
          documents.push(document);
          updateFlight(state, { flightId, properties: { documents } });
        } else {
          updateFlight(
            state,
            { flightId, properties: { documents: [document] } },
          );
        }
      }
    },
    [DELETE_FLIGHT_DOCUMENT](state, { flightId, documentId }) {
      const indexFlight = state.flightsCollection.findIndex((f) => f.id === flightId);
      if (indexFlight !== -1) {
        const { documents } = state.flightsCollection[indexFlight];
        const indexDocument = documents.findIndex((d) => d.id === documentId);
        if (indexDocument !== -1) {
          documents.splice(indexDocument, 1);
          updateFlight(state, { flightId, properties: { documents } });
        }
      }
    },
    [SET_FLIGHT_CONSTRAINTS_ANALYSIS](state, { flightId, analysis }) {
      updateFlight(state, { flightId, properties: { constraintsAnalysis: analysis } });
    },
    [SET_FLIGHT_NOTAMS_ANALYSIS](state, { flightId, analysis }) {
      updateFlight(state, { flightId, properties: { notamsAnalysis: analysis } });
    },
    [SET_FLIGHT_USK_CONSTRAINTS_ANALYSIS](state, { flightId, analysis }) {
      updateFlight(state, { flightId, properties: { USKConstraintsAnalysis: analysis } });
    },
    [SET_FLIGHT_STATUS](state, { flightId, status }) {
      updateFlight(state, { flightId, properties: { status } });
    },
    [SET_FLIGHT_PREFECTURE_NOTIFICATIONS](state, { flightId, prefectureNotifications }) {
      updateFlight(state, { flightId, properties: { prefectureNotifications } });
    },
    [SET_FLIGHT_ARMY_NOTIFICATIONS](state, { flightId, armyNotifications }) {
      updateFlight(state, { flightId, properties: { armyNotifications } });
    },
    [SET_FLIGHT_LANDED](state, { flight }) {
      const {
        id: flightId,
        effective_date_start: effectiveDateStart,
        effective_date_end: effectiveDateEnd,
        effective_duration: effectiveDuration,
      } = flight;
      updateFlight(
        state,
        {
          flightId,
          properties: {
            en_route: false,
            effective_date_start: effectiveDateStart,
            effective_date_end: effectiveDateEnd,
            effective_duration: effectiveDuration,
          },
        },
      );
    },
    [SET_FLIGHT_CONSTRAINT_STATUS](state, { flightId, constraintId, constraintStatus }) {
      const flight = state.flightsCollection.find((f) => f.id === flightId);
      const { constraintsAnalysis } = flight;
      const constraint = constraintsAnalysis.find((c) => c.id === constraintId);
      const delegateeStructureContraints = constraintsAnalysis.filter((c) => (
        c.delegatee_constraint
          && c.delegatee_constraint.independant_status === false
          && c.delegatee_constraint.structure_id === constraint.structure_id
      ));
      Vue.set(constraint, 'status', constraintStatus);
      delegateeStructureContraints.forEach((c) => {
        Vue.set(c, 'status', constraintStatus);
      });
    },
    [SET_FLIGHT_CONSTRAINT_NOTE](state, { flightId, constraintId, note }) {
      const flight = state.flightsCollection.find((f) => f.id === flightId);
      const { constraintsAnalysis } = flight;
      const constraint = constraintsAnalysis.find((c) => c.id === constraintId);
      Vue.set(constraint, 'note', note);
    },
    [SET_FLIGHT_CONSTRAINT_SHEET_STATUS](
      state, { flightId, constraintSheetStatus, updatedAt = null, toUpdate = false },
    ) {
      const flight = state.flightsCollection.find((f) => f.id === flightId);
      let { constraintSheet } = flight;
      if (constraintSheet?.status) {
        Vue.set(constraintSheet, 'status', constraintSheetStatus);
        Vue.set(constraintSheet, 'to_update', toUpdate);
        if (updatedAt) {
          Vue.set(constraintSheet, 'updated_at', updatedAt);
        }
      } else {
        constraintSheet = {
          status: constraintSheetStatus,
          updated_at: updatedAt,
          to_update: toUpdate,
        };
        Vue.set(flight, 'constraint_sheet', constraintSheet);
      }
    },
    [SET_CONSTRAINT_HOVER](state, payload) {
      Vue.set(state, 'constraintIdHover', payload.constraintId);
    },
    [ADD_CONSTRAINT_SELECTED](state, payload) {
      const { constraintId } = payload;
      const index = state.constraintIdsSelected.findIndex((id) => id === constraintId);
      if (index === -1) {
        state.constraintIdsSelected.push(constraintId);
      }
    },
    [REMOVE_CONSTRAINT_SELECTED](state, payload) {
      const { constraintId } = payload;
      const index = state.constraintIdsSelected.findIndex((id) => id === constraintId);
      if (index !== -1) {
        state.constraintIdsSelected.splice(index, 1);
      }
    },
    [REMOVE_ALL_CONSTRAINTS_SELECTED](state) {
      Vue.set(state, 'constraintIdsSelected', []);
    },
    [ADD_NOTAM_SELECTED](state, payload) {
      const { notamId } = payload;
      const index = state.notamIdsSelected.findIndex((id) => id === notamId);
      if (index === -1) {
        state.notamIdsSelected.push(notamId);
      }
    },
    [REMOVE_NOTAM_SELECTED](state, payload) {
      const { notamId } = payload;
      const index = state.notamIdsSelected.findIndex((id) => id === notamId);
      if (index !== -1) {
        state.notamIdsSelected.splice(index, 1);
      }
    },
    [REMOVE_ALL_NOTAMS_SELECTED](state) {
      Vue.set(state, 'notamIdsSelected', []);
    },
    [SET_FLIGHT_APPROVAL_ID_SELECTED](state, id) {
      Vue.set(state, 'flightApprovalIdSelected', id);
    },
    [SET_FLIGHT_SELECTED](state) {
      Vue.set(state, 'constraintIdHover', null);
      Vue.set(state, 'constraintIdsSelected', []);
      Vue.set(state, 'notamIdsSelected', []);
    },
    [SET_SELECTED_FLIGHT_DETAILS_LOADING](state, loading) {
      Vue.set(state, 'isSelectedFlightDetailsLoading', loading);
    },
    [SET_SELECTED_FLIGHT_DETAILS_LOADED](state, isLoaded) {
      Vue.set(state, 'isSelectedFlightDetailsLoaded', isLoaded);
    },
    [SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED](state, isLoaded) {
      Vue.set(state, 'isSelectedFlightConstraintsAnalysisLoaded', isLoaded);
    },
    [SET_SELECTED_FLIGHT_NOTAMS_ANALYSIS_LOADED](state, isLoaded) {
      Vue.set(state, 'isSelectedFlightNotamsAnalysisLoaded', isLoaded);
    },
    [SET_FLIGHT_META_LOADED](state, isLoaded) {
      Vue.set(state.meta, 'isFlightMetaLoaded', isLoaded);
    },
    [SET_PAGINATION](state, payload) {
      Vue.set(state, 'pagination', payload.pagination);
    },
    [UPDATE_PAGINATION](state, { itemsLength, page }) {
      Vue.set(state, 'itemsLength', itemsLength);
      if (page !== undefined) {
        Vue.set(state.pagination, 'page', page);
      }
    },
    [SET_FILTERS](state, payload) {
      Vue.set(state, 'filters', payload.filters);
    },
    [RESET_FILTERS](state) {
      Vue.set(state, 'filters', { ...defaultFilters });
    },
    [RESET_FILTERS_ALL_FLIGHTS](state) {
      Vue.set(state, 'filters', { ...defaultFilters, isArchived: null });
    },
    [SET_GEOMETRY_CONSTRAINTS_ANALYSIS_LOADED](state, value) {
      Vue.set(state, 'geometryConstraintsLoaded', value);
    },
    [GET_GEOMETRY_CONSTRAINTS_ANALYSIS](state, { flightId, geometryConstraints }) {
      updateFlight(state, { flightId, properties: { geometryConstraints } });
    },
    [SET_GEOMETRY_NOTAMS_ANALYSIS_LOADED](state, value) {
      Vue.set(state, 'geometryNotamsLoaded', value);
    },
    [GET_GEOMETRY_NOTAMS_ANALYSIS](state, { flightId, geometryNotams }) {
      updateFlight(state, { flightId, properties: { geometryNotams } });
    },
    [UPDATE_FLIGHT_APPROVALS](state, { flightId, approvals }) {
      updateFlightApprovals(state, { flightId, approvals });
    },
    [SET_FLIGHT_APPROVAL_PROPERTY](state, { flightId, approvalId, propertyKey, propertyValue }) {
      updateFlightApprovalProperty(state, {
        flightId,
        approvalId,
        propertyKey,
        propertyValue,
      });
    },
    [SET_FLIGHT_ANALYZED](state, { flightId, aspWasAnalyzed }) {
      updateFlight(
        state, { flightId, properties: { asp_was_analyzed: aspWasAnalyzed } },
      );
    },
    [UPDATE_FLIGHT_AFTER_CONSTRAINTS_CREATION](
      state, { flightId, status, price, approvalCanBeCreated, tags },
    ) {
      updateFlight(
        state,
        {
          flightId,
          properties: {
            status,
            applicable_procedures_price: price,
            approval_can_be_created: approvalCanBeCreated,
            tags,
          },
        },
      );
    },
    [UPDATE_FLIGHT_REQUEST_EMAIL](state, { flightId, newEmailRequests }) {
      const flight = state.flightsCollection.find((f) => f.id === flightId);
      const { emailRequests } = flight;
      let emailRequestsUpdated = [];
      if (!emailRequests) {
        // First emails
        emailRequestsUpdated = newEmailRequests;
      } else {
        emailRequestsUpdated = emailRequests;
        newEmailRequests.forEach((emailRequest) => {
          const index = emailRequestsUpdated.findIndex(
            (email) => email.id === emailRequest.id,
          );
          if (index !== -1) {
            // Update from websocket
            emailRequestsUpdated[index].status = emailRequest.status;
            emailRequestsUpdated[index].sent_at = emailRequest.sent_at;
          } else {
            // Other emails
            emailRequestsUpdated = emailRequestsUpdated.concat(emailRequest);
          }
        });
      }
      updateFlight(state, {
        flightId,
        properties: { emailRequests: emailRequestsUpdated },
      });
    },
    [SET_SIMPLIFIED_FLIGHT_FORM_OPEN](state, value) {
      Vue.set(state, 'simplifiedFlightFormOpen', value);
    },
    [SET_UPDATING_FLIGHT_ID](state, value) {
      Vue.set(state, 'updatingFlightId', value);
    },
    [SET_SLICE_FLIGHT_AREA](state, payload) {
      Vue.set(state, 'sliceFlightArea', payload);
    },
  },
  actions: {
    [RESET_FLIGHTS]({ commit }) {
      commit(RESET_FLIGHTS);
    },
    [GET_ALL_FLIGHTS]({ state, dispatch, commit }, { updateBoundingBox }) {
      if (state.pagination.page === 1) {
        dispatch(GET_FLIGHTS, { updateBoundingBox, reload: true });
      } else {
        commit(UPDATE_PAGINATION, { itemsLength: state.itemsLength, page: 1 });
        dispatch(GET_FLIGHTS_GEOMETRY, { updateBoundingBox: true, reload: true });
      }
    },
    [GET_TABLE_ITEMS_FLIGHTS]({ dispatch }, { updateBoundingBox }) {
      dispatch(GET_FLIGHTS, { updateBoundingBox, reload: false });
    },
    async [GET_FLIGHTS_GEOMETRY](
      { commit, state, dispatch, rootState, getters },
      { updateBoundingBox = true, reload = false },
    ) {
      // Current pagination and filters
      const { sortBy, sortDesc } = state.pagination;
      const {
        dateFrom,
        dateTo,
        q,
        isArchived,
        isEntrusted,
        pilotsCategory,
        pilots,
        drones,
        tags,
        currentWeek,
      } = state.filters;
      const contact = (rootState.authentication.user.display_only_my_flights)
        ? rootState.authentication.user.contact_id : null;
      const subExploitantId = rootState.authentication.selectedSubExploitant;
      const getFlightsParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        date_min: dateFrom,
        date_max: dateTo,
        q,
        is_archived: isArchived,
        is_entrusted: isEntrusted,
        contact,
        pilots_category: pilotsCategory,
        pilots,
        drones,
        tags,
        sub_exploitant_id: subExploitantId,
        current_week: currentWeek,
      };
      // Get and set all flights if not loaded or must reload
      if (!state.flightsCollectionLoaded || reload) {
        commit(FETCH_FLIGHTS_COLLECTION);
        getFlightsParams.limit = MAX_FLIGHTS_FOR_GEO_REQUESTS;
        getFlightsParams.offset = 0;
        await APIService.getFlightsGeoCollection(getFlightsParams)
          .then((response) => {
            if (response) {
            // Results correspond to current q filter
              if (response.config.params.q === state.filters.q) {
                const { data } = response;
                commit(SET_FLIGHTS_COLLECTION, {
                  flightsCollection: data.results,
                  flightsCollectionLoaded: true,
                  flightsCollectionLoading: false,
                });
                if (updateBoundingBox === true && data.count && data.count > 0) {
                  dispatch(SET_BOUNDING_BOX_NS, { fromGeom: getters.mapAreas }, { root: true });
                }
              }
            }
          })
          .catch(() => {
            commit(SET_FLIGHTS_COLLECTION, { flightsCollectionLoading: false });
          });
      }
    },
    async [GET_FLIGHTS](
      { commit, state, dispatch, rootState },
      { withId, updateBoundingBox = true, reload = false },
    ) {
      // Current pagination and filters
      const { itemsPerPage, sortBy, sortDesc, page } = state.pagination;
      const {
        dateFrom,
        dateTo,
        q,
        isArchived,
        isEntrusted,
        pilotsCategory,
        pilots,
        drones,
        tags,
        currentWeek,
      } = state.filters;
      const contact = (rootState.authentication.user.display_only_my_flights)
        ? rootState.authentication.user.contact_id : null;
      const subExploitantId = rootState.authentication.selectedSubExploitant;
      const getFlightsParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        date_min: dateFrom,
        date_max: dateTo,
        q,
        is_archived: isArchived,
        is_entrusted: isEntrusted,
        contact,
        pilots_category: pilotsCategory,
        pilots,
        drones,
        tags,
        sub_exploitant_id: subExploitantId,
        current_week: currentWeek,
      };
      commit(FETCH_TABLE_ITEMS);
      // Get and set all flights if not loaded or must reload
      if (!state.flightsCollectionLoaded || reload) {
        await dispatch(GET_FLIGHTS_GEOMETRY, { updateBoundingBox, reload });
      }
      // Get and set table items according to current pagination or given flight id
      getFlightsParams.limit = itemsPerPage;
      if (withId) {
        getFlightsParams.with_id = withId;
      } else {
        getFlightsParams.offset = (page - 1) * itemsPerPage;
      }
      await APIService.getFlights(getFlightsParams)
        .then((response) => {
          if (response) {
            // Results correspond to current q filter
            if (response.config.params.q === state.filters.q) {
              const { data } = response;
              const items = data.results;
              const itemsLength = data.count;
              let newPage;
              if (withId) {
                if (!items.find((flight) => flight.id === withId)) {
                  throw new Error(NOT_FOUND_ERROR);
                }
                // Find new page where the flight with the given id is
                newPage = getPageFromNextPreviousURL(
                  data.next,
                  data.previous,
                  data.count,
                  itemsPerPage,
                );
              }
              dispatch(SET_TABLE_ITEMS, { items });
              commit(UPDATE_PAGINATION, { itemsLength, page: newPage });
            }
          }
        })
        .catch((error) => {
          if (error.message === NOT_FOUND_ERROR) {
            throw error;
          }
          commit(SET_TABLE_ITEMS, { tableItemsLoadingError: error });
        });
      // Display warning message if the flight created is not displayed in tabIds
      if (state.flightIdUpdatedOrCreated) {
        if (!state.tableIds.includes(state.flightIdUpdatedOrCreated)) {
          await APIService.getFlightsIds(getFlightsParams)
            .then(({ data }) => {
              // Delete it if is not in flightsCollection because of filters
              if (!data.includes(state.flightIdUpdatedOrCreated)) {
                commit(REMOVE_FLIGHT, { flightId: state.flightIdUpdatedOrCreated });
                dispatch(
                  SET_SNACKBAR_MESSAGE_NS,
                  {
                    message: `Attention : la mission que vous venez de créer ne sera pas
                      visible dans la liste des missions en raison de filtres actifs sur ce
                      tableau. Vous pouvez la retrouver en annulant les filtres.`,
                    color: 'warning',
                  },
                  { root: true },
                );
                dispatch(SHOW_SNACKBAR_NS, null, { root: true });
              } else {
                dispatch(
                  SET_SNACKBAR_MESSAGE_NS,
                  {
                    message: `Attention : la mission que vous venez de créer ne sera pas
                      visible dans la liste des missions en raison de la pagination du tableau.`,
                    color: 'warning',
                  },
                  { root: true },
                );
                dispatch(SHOW_SNACKBAR_NS, null, { root: true });
              }
            });
        }
        commit(UPDATE_FLIGHT_ID_UPDATED_OR_CREATED, { flightId: undefined });
      }
    },
    async [SET_TABLE_ITEMS]({ commit, dispatch, state }, { items }) {
      const flightIds = state.flightsCollection.map((f) => f.id);
      // Reload geometries if another flight have been created in other session
      if (items.some((f) => !flightIds.includes(f.id))) {
        await dispatch(GET_FLIGHTS_GEOMETRY, { updateBoundingBox: false, reload: true });
      }
      commit(SET_TABLE_ITEMS, { items });
    },
    [GET_FLIGHT_META]({ commit }) {
      commit(SET_FLIGHT_META_LOADED, false);
      APIService.getFlightsMetaActivityDomains()
        .then(({ data }) => {
          commit(SET_FLIGHT_META, data);
        })
        .finally(() => {
          commit(SET_FLIGHT_META_LOADED, true);
        });
    },
    async [GET_FLIGHT_DETAILS](
      { commit, getters, dispatch },
      {
        flightId,
        updateGeom = false,
        reload = false,
        analysis = true,
        documents = true,
        created = false,
      },
    ) {
      if (analysis) {
        commit(SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED, false);
        commit(SET_SELECTED_FLIGHT_NOTAMS_ANALYSIS_LOADED, false);
      }
      commit(SET_SELECTED_FLIGHT_DETAILS_LOADING, true);
      commit(SET_SELECTED_FLIGHT_DETAILS_LOADED, false);
      let updateGeomFlightCreated = false;
      if (getters.tableItems.findIndex((item) => item.id === flightId) === -1 || reload) {
        if (created) {
          commit(ADD_FLIGHT_CREATED, { flightId });
          updateGeomFlightCreated = true;
        } else {
          await dispatch(GET_FLIGHTS, { withId: flightId, reload, updateBoundingBox: false });
        }
      }
      const updateGeometry = updateGeom || updateGeomFlightCreated;
      await APIService.getFlightDetails(flightId, { no_geometry: !updateGeometry })
        .then(({ data }) => {
          commit(SET_FLIGHT_DETAILS, {
            flightId,
            flight: data,
          });
          commit(SET_SELECTED_FLIGHT_DETAILS_LOADING, false);
          commit(SET_SELECTED_FLIGHT_DETAILS_LOADED, true);
        });
      if (documents) {
        dispatch(GET_FLIGHT_DOCUMENTS, flightId);
      }
      if (analysis) {
        dispatch(GET_ALL_ANALYSIS, { flightId });
      }
    },
    [SET_FLIGHT_CONSTRAINTS_ANALYSIS]({ commit }, { flightId, analysis }) {
      commit(SET_FLIGHT_CONSTRAINTS_ANALYSIS, { flightId, analysis });
    },
    [SET_FLIGHT_NOTAMS_ANALYSIS]({ commit }, { flightId, analysis }) {
      commit(SET_FLIGHT_NOTAMS_ANALYSIS, { flightId, analysis });
    },
    [GET_ALL_ANALYSIS]({ commit, dispatch }, { flightId }) {
      APIService.getConstraintsAnalysis(flightId)
        .then(({ data }) => {
          dispatch(SET_FLIGHT_CONSTRAINTS_ANALYSIS, { flightId, analysis: data })
            .then(() => {
              commit(SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED, true);
            });
          dispatch(GET_GEOMETRY_CONSTRAINTS_ANALYSIS, { flightId });
        });
      APIService.getNotamsAnalysis(flightId)
        .then(({ data }) => {
          dispatch(SET_FLIGHT_NOTAMS_ANALYSIS, { flightId, analysis: data })
            .then(() => {
              commit(SET_SELECTED_FLIGHT_NOTAMS_ANALYSIS_LOADED, true);
            });
          dispatch(GET_GEOMETRY_NOTAMS_ANALYSIS, { flightId });
        });
    },
    [GET_GEOMETRY_CONSTRAINTS_ANALYSIS]({ commit }, { flightId }) {
      commit(SET_GEOMETRY_CONSTRAINTS_ANALYSIS_LOADED, false);
      APIService.getGeometryConstraintsAnalysis(flightId)
        .then(({ data }) => {
          commit(GET_GEOMETRY_CONSTRAINTS_ANALYSIS, { flightId, geometryConstraints: data });
        });
    },
    [GET_GEOMETRY_NOTAMS_ANALYSIS]({ commit }, { flightId }) {
      commit(SET_GEOMETRY_NOTAMS_ANALYSIS_LOADED, false);
      APIService.getGeometryNotamsAnalysis(flightId)
        .then(({ data }) => {
          commit(GET_GEOMETRY_NOTAMS_ANALYSIS, { flightId, geometryNotams: data });
        });
    },
    async [GET_FLIGHT_DOCUMENTS]({ commit }, flightId) {
      await APIService.getFlightDocuments(flightId).then(({ data }) => {
        commit(SET_FLIGHT_DOCUMENTS, { flightId, documents: data });
      });
    },
    [ADD_FLIGHT_DOCUMENT]({ commit }, { flightId, document }) {
      commit(ADD_FLIGHT_DOCUMENT, { flightId, document });
    },
    [DELETE_FLIGHT_DOCUMENT]({ commit }, { flightId, documentId }) {
      commit(DELETE_FLIGHT_DOCUMENT, { flightId, documentId });
    },
    [SET_FLIGHT_STATUS]({ commit }, { id, status }) {
      APIService.patchFlightStatus({ id, status }).then(() => {
        commit(SET_FLIGHT_STATUS, {
          flightId: id,
          status,
        });
      });
    },
    async [GET_FLIGHT_STATUS]({ commit }, { flightId }) {
      const { data: { status } } = await APIService.getFlightStatus({ flightId });
      await commit(SET_FLIGHT_STATUS, { flightId, status });
    },
    async [SET_FLIGHT_CONSTRAINT_STATUS]({ commit }, { flightId, constraintId, constraintStatus }) {
      await APIService.patchFlightConstraint({
        flightId,
        constraintId,
        status: constraintStatus,
      });
      await commit(SET_FLIGHT_CONSTRAINT_STATUS, {
        flightId,
        constraintId,
        constraintStatus,
      });
    },
    [UPDATE_FLIGHT_CONSTRAINT_STATUS](
      { commit, dispatch, getters },
      { flightId, constraintId, constraintStatus },
    ) {
      commit(SET_FLIGHT_CONSTRAINT_STATUS, {
        flightId,
        constraintId,
        constraintStatus,
      });
      if (getters.flightSelected?.id === flightId && getters.flightSelected.constraint_sheet) {
        const { constraint_sheet: contraintSheet } = getters.flightSelected;
        dispatch(
          SET_FLIGHT_CONSTRAINT_SHEET_STATUS,
          {
            flightId,
            constraintSheetStatus: contraintSheet.status,
            updatedAt: contraintSheet.updated_at,
            toUpdate: true,
          },
        );
      }
    },
    [SET_FLIGHT_SELECTED]({ commit, dispatch }, { flightId, reload }) {
      if (flightId) {
        dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.MEDIUM, { root: true });
      }
      dispatch(SET_ACTIVE_SPLIT_NS, APPLICATION_SPLIT.LISTING, { root: true });
      dispatch(
        SET_MAP_FEATURE_SELECTED_NS,
        { featureId: flightId, key: SELECTABLE_FEATURE_KEYS.flight },
        { root: true },
      );
      commit(SET_FLIGHT_SELECTED);
      commit(SET_SELECTED_FLIGHT_DETAILS_LOADED, false);
      commit(SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED, false);
      commit(SET_SELECTED_FLIGHT_NOTAMS_ANALYSIS_LOADED, false);
      if (flightId && reload) {
        dispatch(GET_FLIGHT_DETAILS, { flightId });
      }
    },
    [SET_FLIGHT_HOVER]({ state, dispatch }, flightId) {
      if (!state.flightsCollectionLoading && state.flightsCollectionLoaded) {
        dispatch(
          SET_MAP_FEATURE_HOVERED_NS,
          { featureId: flightId, key: SELECTABLE_FEATURE_KEYS.flight },
          { root: true },
        );
      }
    },
    [SET_CONSTRAINT_HOVER]({ commit }, constraintId) {
      commit(SET_CONSTRAINT_HOVER, { constraintId });
    },
    [ADD_CONSTRAINT_SELECTED]({ commit }, constraintId) {
      commit(ADD_CONSTRAINT_SELECTED, { constraintId });
    },
    [REMOVE_CONSTRAINT_SELECTED]({ commit }, constraintId) {
      commit(REMOVE_CONSTRAINT_SELECTED, { constraintId });
    },
    [ADD_NOTAM_SELECTED]({ commit }, notamId) {
      commit(ADD_NOTAM_SELECTED, { notamId });
    },
    [REMOVE_NOTAM_SELECTED]({ commit }, notamId) {
      commit(REMOVE_NOTAM_SELECTED, { notamId });
    },
    [SET_PAGINATION]({ commit, dispatch }, pagination) {
      commit(SET_PAGINATION, { pagination });
      dispatch(GET_FLIGHTS, {});
    },
    [SET_FILTERS]({ commit }, filters) {
      commit(SET_FILTERS, { filters });
    },
    [RESET_FILTERS]({ commit, dispatch }) {
      commit(RESET_FILTERS);
      dispatch('authentication/UPDATE_DISPLAY_ONLY_MY_FLIGHTS', false, { root: true });
    },
    [RESET_FILTERS_ALL_FLIGHTS]({ commit }) {
      commit(RESET_FILTERS_ALL_FLIGHTS);
    },
    [SET_FLIGHT_CONSTRAINT_SHEET_STATUS](
      { commit, getters }, { flightId, constraintSheetStatus, updatedAt = null, toUpdate = false },
    ) {
      if (getters.flightSelected && getters.flightSelected.id === flightId) {
        commit(
          SET_FLIGHT_CONSTRAINT_SHEET_STATUS,
          { flightId, constraintSheetStatus, updatedAt, toUpdate },
        );
      }
    },
    [SET_FLIGHT_APPROVAL_MISSION_SHEET](
      { commit, getters }, { flightId, approvalId, missionSheet },
    ) {
      if (getters.flightSelected && getters.flightSelected.id === flightId) {
        commit(
          SET_FLIGHT_APPROVAL_PROPERTY,
          { flightId, approvalId, propertyKey: 'mission_sheet', propertyValue: missionSheet },
        );
      }
    },
    async [SET_FLIGHT_CONSTRAINTS]({ commit, dispatch }, { flightId }) {
      commit(SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED, false);
      commit(SET_SELECTED_FLIGHT_NOTAMS_ANALYSIS_LOADED, false);
      dispatch(SET_FLIGHT_CONSTRAINTS_ANALYSIS, { flightId, analysis: [] });
      await APIService.postFlightConstraintsCreation(flightId)
        .then(() => {
          dispatch(GET_ALL_ANALYSIS, { flightId });
          commit(SET_FLIGHT_ANALYZED, { flightId, aspWasAnalyzed: true });
        })
        .catch(() => {
          commit(SET_FLIGHT_ANALYZED, { flightId, aspWasAnalyzed: false });
        });
      dispatch(UPDATE_FLIGHT_AFTER_CONSTRAINTS_CREATION, { flightId });
    },
    async [UPDATE_FLIGHT_AFTER_CONSTRAINTS_CREATION]({ commit }, { flightId }) {
      await APIService.getFlightPropertiesAfterConstraintsCreation(flightId)
        .then(({ data }) => {
          commit(UPDATE_FLIGHT_AFTER_CONSTRAINTS_CREATION, {
            flightId,
            status: data.status,
            price: data.price,
            approvalCanBeCreated: data.approval_can_be_created,
            tags: data.tags,
          });
        });
    },
    [UPDATE_FLIGHT_REQUEST_EMAIL]({ commit }, { flightId, emailRequest }) {
      commit(UPDATE_FLIGHT_REQUEST_EMAIL, { flightId, emailRequest });
    },
    [SET_FLIGHT_APPROVAL_ID_SELECTED]({ commit }, { flightId, approvalId }) {
      commit(SET_FLIGHT_APPROVAL_ID_SELECTED, approvalId);
      if (approvalId) {
        APIService.getApprovalDecisions(approvalId)
          .then(({ data }) => {
            commit(
              SET_FLIGHT_APPROVAL_PROPERTY,
              { flightId, approvalId, propertyKey: 'decisions', propertyValue: data },
            );
          });
      }
    },
    [GET_CONSTRAINTS_ANALYSIS]({ commit, dispatch }, { flightId, getStatus = true }) {
      commit(SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED, false);
      APIService.getConstraintsAnalysis(flightId)
        .then(({ data }) => {
          dispatch(SET_FLIGHT_CONSTRAINTS_ANALYSIS, { flightId, analysis: data })
            .then(() => {
              commit(SET_SELECTED_FLIGHT_CONSTRAINTS_ANALYSIS_LOADED, true);
            });
        });
      if (getStatus) {
        dispatch(GET_FLIGHT_STATUS, { flightId });
      }
    },
  },
};
