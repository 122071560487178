<template>
  <v-dialog
    v-model="openDialog"
    max-width="620"
    persistent
  >
    <v-card
      class="default--dialog__card"
      max-height="800px"
    >
      <v-card-title>
        <translate>Delete a layer</translate>
      </v-card-title>
      <v-card-text>
        <translate>Are you sure to delete the layer</translate>
        <span>
          '{{ layerName }}' ?
        </span>
        <div
          v-if="isGeoportailLayer"
          class="mt-1"
        >
          <translate>
            You can add this layer again by clicking on 'Add Geoportail layers'.
          </translate>
        </div>
        <div
          v-if="isMobileNetworkLayer"
          class="mt-1"
        >
          <translate>
            You can add this layer again by clicking on 'Add Mobile Network layers'.
          </translate>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="info"
          @click="close()"
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading"
          @click="deleteLayer()"
        >
          <translate>Confirm</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/services/api';

import { RESET_DATA_STORES_NS } from '@/store/application';
import { SHOW_LAYER_DELETE_DIALOG_NS } from '@/store/map';

export default {
  name: 'DeleteMapLayerData',
  data() {
    return { loading: false };
  },
  computed: {
    layer() {
      return this.$store.state.map.deleteLayerGroup;
    },
    openDialog() {
      return this.layer !== undefined;
    },
    layerName() {
      return this.openDialog ? this.layer.name : '';
    },
    isGeoportailLayer() {
      return this.layer ? this.layer.category === 'geoportail_wmts' : false;
    },
    isMobileNetworkLayer() {
      return this.layer ? this.layer.category === 'mobile_network' : false;
    },
  },
  methods: {
    close() {
      this.$store.commit(SHOW_LAYER_DELETE_DIALOG_NS, undefined);
    },
    deleteLayer() {
      this.loading = true;
      if (this.isMobileNetworkLayer) {
        API.deleteMobileNetworkLayers(this.layer.identifier)
          .then(() => {
            this.close();
            this.$store.dispatch(RESET_DATA_STORES_NS);
            this.$router.push({ path: '/' });
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (!this.isGeoportailLayer) {
        API.deleteCustomDataLayer(this.layer.identifier)
          .then(() => {
            this.close();
            this.$store.dispatch(RESET_DATA_STORES_NS);
            this.$router.push({ path: '/' });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        API.deleteGeoportailLayer(this.layer.identifier)
          .then(() => {
            this.close();
            this.$store.dispatch(RESET_DATA_STORES_NS);
            this.$router.push({ path: '/' });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};

</script>
