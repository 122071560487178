export default function makeTrackerTraceListingHeaders(gettext) {
  const idHeader = {
    text: gettext('N°'),
    value: 'id',
  };
  const approvalIdentifiersHeader = {
    text: gettext('Approval identifier'),
    value: 'approval_identifiers',
    sortable: false,
  };
  const electronicIdHeader = {
    text: gettext('Eletronic id'),
    value: 'device_id',
  };
  const dayHeader = {
    text: gettext('Day'),
    value: 'day',
  };
  const startHourHeader = {
    text: gettext('Start hour'),
    value: 'start_hour',
    sortable: false,
  };
  const endHourHeader = {
    text: gettext('End hour'),
    value: 'end_hour',
    sortable: false,
  };
  const actionHeader = {
    text: '',
    value: 'action',
    sortable: false,
    align: 'center',
  };

  return {
    smallHeaders: [
      { ...idHeader },
    ],
    mediumHeaders: [
      { ...idHeader },
      { ...approvalIdentifiersHeader },
      { ...electronicIdHeader },
      { ...dayHeader },
      { ...actionHeader },
    ],
    bigHeaders: [
      { ...idHeader },
      { ...approvalIdentifiersHeader },
      { ...electronicIdHeader },
      { ...dayHeader },
      { ...startHourHeader },
      { ...endHourHeader },
      { ...actionHeader },
    ],
  };
}
