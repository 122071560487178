<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Cancel my subscription</translate>
    </v-card-title>
    <v-card-text>
      <v-alert
        v-if="userOldPlans"
        dense
        text
        type="info"
        color="primary"
        class="body-2 py-3 mb-0"
      >
        {{ oldPlanChangeAlert }}
      </v-alert>
      <v-list
        three-line
        disabled
        class="pb-0"
      >
        <!-- Current formule -->
        <v-list-item class="disabled">
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center flex-wrap mb-2">
              <translate class="formule-title">
                Current formule:
              </translate>
              <v-icon
                color="primary"
                class="ml-2 mr-1 mdi-rotate-90"
              >
                {{ currentFormule.icon }}
              </v-icon>
              <span>
                {{ currentFormule.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle class="d-flex align-center flex-wrap">
              <span v-html="currentFormule.description" />
              &mdash;
              <span v-html="currentFormule.price" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <div class="disabled px-4">
          {{ textDateCancellation }}
        </div>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        class="px-3"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="px-3"
        @click="cancelSubscription()"
        :loading="cancelLoading"
      >
        <translate>Confirm the cancellation</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from '@/services/api';

import { REFRESH_USER_DATA_NS } from '@/store/authentication';

export default {
  name: 'SubscriptionPlanCancel',
  props: {
    currentFormule: {
      type: Object,
      required: true,
    },
  },
  data() { return { cancelLoading: false }; },
  computed: {
    userOldPlans() {
      return ['FF_M', 'FF_Y', 'INDEP_M', 'INDEP_Y'].includes(this.currentFormule.code);
    },
    oldPlanChangeAlert() {
      return this.$gettext(`Please note that your current subscription plan will no longer be
        available. If you choose to cancel your subscription you will not be able to return to
        your current plan.`);
    },
    nextPaymentAttemptDate() {
      return this.$store.state.exploitants.subscriptionNextPaymentAttemptDate || '';
    },
    textDateCancellation() {
      if (this.nextPaymentAttemptDate) {
        return this.$gettextInterpolate(
          this.$gettext("Your subscription will be cancelled on %{cancelDate} and won't renew."),
          { cancelDate: this.nextPaymentAttemptDate },
        );
      }
      return '';
    },
  },
  methods: {
    cancelSubscription() {
      this.cancelLoading = true;
      API.cancelSubscription()
        .then(() => {
          this.showMessage(this.$gettext('Subscription cancelled successfully.'), 'success');
          this.$store.dispatch(REFRESH_USER_DATA_NS);
        })
        .finally(() => {
          this.cancelLoading = false;
          this.$emit('close');
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.formule-title {
  text-transform: uppercase;
}
.disabled {
  opacity: 0.55;
}

</style>
