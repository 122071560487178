/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

import { APPLICATION_TAB } from '@/store/application';
import {
  GET_APPROVAL_DETAILS_NS,
  RESET_FILTERS_NS,
  SET_APPROVAL_SELECTED_NS,
} from '@/store/approvals';

import Notification from '@/components/Header/notifications/Notification';

/**
 * AuthorityApprovalNotification class derived from Notification,
 * represent a notification about an approval with a flight en route.
 *
 * @class AuthorityApprovalNotification
 * @extends {Notification}
 */
class AuthorityApprovalNotification extends Notification {
  constructor(rawNotification) {
    super(rawNotification);
    const { payload } = rawNotification;
    this._approvalId = payload.approval_id;
    this._tab = APPLICATION_TAB.APPROVAL;
    this._route = 'approvals';
    this._fullScreenRoute = 'approval-page';
  }

  async atClick(dispatchStoreAction, subscribeStoreMutation, navigate) {
    navigate(this._tab, this._route, this._fullScreenRoute, { id: this._approvalId });
    try {
      await this.selectApproval({ dispatchStoreAction, that: this });
    } catch (error) {
      // If not found we need to reset filters
      dispatchStoreAction(RESET_FILTERS_NS);
      this.unsubscribe = subscribeStoreMutation({
        mutationName: 'approvals/SET_APPROVALS_COLLECTION_LOADED',
        callback: this.selectApproval,
        params: { dispatchStoreAction, that: this },
      });
    }
  }

  async selectApproval({ dispatchStoreAction, that }) {
    await dispatchStoreAction(
      GET_APPROVAL_DETAILS_NS,
      { approvalId: that._approvalId, reload: true },
    );
    dispatchStoreAction(SET_APPROVAL_SELECTED_NS, that._approvalId);
    if (that.unsubscribe) {
      // Remove subscription to mutation if filters were reset
      that.unsubscribe();
    }
  }
}

export default AuthorityApprovalNotification;
