<template>
  <v-form
    v-model="valid"
    class="d-flex flex-column justify-space-between full-height"
  >
    <div>
      <v-card>
        <v-card-text>
          <v-row class="flex-nowrap">
            <v-col
              cols="12"
              md="6"
              class="px-5"
            >
              <!-- Invoice details -->
              <div class="text-h6 pb-1">
                <span v-translate>Invoice to pay</span>
              </div>
              <template v-if="!approvalInvoiceData">
                <v-skeleton-loader
                  type="text"
                  class="mt-1 px-2 pt-1"
                />
                <v-divider />
                <v-skeleton-loader
                  type="text"
                  class="px-2 pt-1"
                />
                <v-divider />
                <v-skeleton-loader
                  type="text"
                  class="px-2 pt-1"
                />
              </template>
              <template v-else>
                <v-row
                  no-gutters
                  class="py-3"
                  v-for="(line, i) in approvalInvoiceData.lines"
                  :key="i"
                >
                  <v-col class="d-flex justify-space-between align-center">
                    <div>
                      {{ line.description }}
                    </div>
                    <div class="text-right font-weight-medium">
                      {{ formatPrice(line.amount) }}
                    </div>
                  </v-col>
                </v-row>
                <template v-if="approvalInvoiceData.discount_amount">
                  <v-divider class="mt-0" />
                  <v-row
                    no-gutters
                    class="pb-3"
                  >
                    <v-col class="d-flex justify-space-between align-center font-weight-regular">
                      <div>
                        <span v-translate>Commercial discount</span>
                        <span>
                          ({{ approvalInvoiceData.discount_percent }} %)
                        </span>
                      </div>
                      <div class="text-right">
                        -{{ formatPrice(approvalInvoiceData.discount_amount) }}
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <v-divider class="mt-0" />
                <v-row
                  no-gutters
                  class="pb-3"
                >
                  <v-col class="d-flex justify-space-between align-center font-weight-regular">
                    <div>
                      <span v-translate>VAT</span>
                      <span>
                        ({{ approvalInvoiceData.tax_percent }} %)
                      </span>
                    </div>
                    <div class="text-right">
                      {{ formatPrice(approvalInvoiceData.tax_amount) }}
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <v-row
                  no-gutters
                  :class="classTotalTTC"
                >
                  <v-col class="d-flex justify-space-between align-center font-weight-bold">
                    <span v-translate>Total incl. VAT</span>
                    <div class="body-1 text-right">
                      {{ formatPrice(approvalInvoiceData.total) }}
                    </div>
                  </v-col>
                </v-row>
                <template v-if="approvalInvoiceData.balance">
                  <v-divider class="mt-0" />
                  <v-row
                    no-gutters
                    class="pb-3"
                  >
                    <v-col class="d-flex justify-space-between align-center font-weight-regular">
                      <span v-translate>Balance applied</span>
                      <div class="text-right">
                        {{ formatPrice(approvalInvoiceData.balance) }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-0" />
                  <v-row no-gutters>
                    <v-col class="d-flex justify-space-between align-center font-weight-bold">
                      <span v-translate>Amount due</span>
                      <div class="body-1 text-right">
                        {{ formatPrice(approvalInvoiceData.amount_due) }}
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </template>
              <v-divider />
              <!-- Payment method -->
              <v-row
                no-gutters
                align="center"
                class="pt-2"
              >
                <div
                  class="text-h6 pb-1"
                  v-translate
                >
                  Payment method
                </div>
                <v-col
                  cols="12"
                  class="my-2"
                >
                  {{ labels.fullName }}
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    v-model="fullName"
                    :rules="rules.fullName"
                    :placeholder="placeholders.fullName"
                    class="cardholder-name"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align="center"
              >
                <v-col
                  cols="12"
                  class="mb-2"
                >
                  {{ labels.card }}
                </v-col>
                <v-col cols="12">
                  <v-row no-gutters>
                    <div
                      ref="card"
                      class="py-2 card-field"
                      :class="{ 'card-field-error': cardError }"
                    />
                  </v-row>
                  <v-row no-gutters>
                    <div
                      ref="card-errors"
                      class="v-messages error--text px-2"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical />
            <!-- Billing information -->
            <v-col
              cols="12"
              md="6"
              class="px-6"
            >
              <div
                class="text-h6 pb-1"
                v-translate
              >
                Billing information
              </div>
              <BillingInformationForm
                ref="billingInformationForm"
                :compact="true"
                :no-action="true"
                @form-valid="(value) => updateBillingFormValid(value)"
              />
            </v-col>
          </v-row>
          <div
            class="caption mt-2 text-center"
            v-translate
          >
            By clicking on confirm, you authorise your credit card to be debited.
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-form>
</template>

<script>
import API from '@/services/api';

import CUSTOM_HANDLING_API_CODES from '@/main';

import BillingInformationForm from '@/components/Settings/Dronists/Subscription/BillingInformationForm.vue';

export default {
  name: 'RequestApprovalPaymentForm',
  components: { BillingInformationForm },
  props: {
    structureId: Number,
    approvalId: Number,
    approvalPilotId: Number,
    approvalDroneIds: Array,
    approvalDetails: String,
    flightAreasIds: Array,
    declaredScenario: String,
  },
  data() {
    return {
      stripe: undefined,
      loadStripe: undefined,
      card: undefined,
      valid: false,
      billingFormValid: false,
      cardComplete: false,
      cardError: false,
      fullName: null,
      approvalInvoiceData: null,
      style: {
        base: { fontSize: '16px' },
        invalid: {
          iconColor: '#a2253e',
          color: '#a2253e',
        },
      },
    };
  },
  computed: {
    labels() {
      return {
        fullName: this.$gettext('Full name'),
        card: this.$gettext('Card'),
      };
    },
    placeholders() {
      return { fullName: this.$gettext('Card owner full name') };
    },
    rules() {
      return { fullName: [(v) => !!v || this.$gettext('Required')] };
    },
    formsValid() {
      return this.billingFormValid && this.valid && this.cardComplete;
    },
    classTotalTTC() {
      if (this.approvalInvoiceData) {
        if (this.approvalInvoiceData.balance) {
          return 'pb-3';
        }
      }
      return '';
    },
  },
  watch: {
    formsValid(newValue, oldValue) {
      if (newValue) {
        this.$emit('forms-valid', true);
      } else if (!newValue && oldValue !== newValue) {
        this.$emit('forms-valid', false);
      }
    },
    approvalId(newValue) {
      if (newValue !== undefined) {
        this.createInvoice();
      }
    },
  },
  created() {
    if (this.$store.getters['authentication/hasAcceptedCookieStripe']) {
      // eslint-disable-next-line global-require
      this.loadStripe = require('@stripe/stripe-js/pure').loadStripe;
    }
  },
  mounted() {
    this.setupCardElement();
    if (this.approvalId !== undefined) {
      this.createInvoice();
    }
  },
  methods: {
    updateBillingFormValid(value) {
      this.billingFormValid = value;
    },
    createInvoice() {
      API.createApprovalInvoice(this.approvalId)
        .then(({ data }) => {
          this.approvalInvoiceData = data;
          this.$emit('invoice-loaded');
        });
    },
    formatPrice(number) {
      return `${number.toFixed(2)} €`;
    },
    payInvoice() {
      this.createPaymentMethod()
        .then((result) => {
          if (result.error) {
            this.$emit('fail-payment');
            this.showMessage(result, 'error');
          } else {
            this.confirmApprovalPaymentInvoice(result.paymentMethod.id);
          }
        });
    },
    createPaymentMethod() {
      return this.stripe.createPaymentMethod(
        {
          type: 'card',
          card: this.card,
          billing_details: { name: this.fullName },
        },
      );
    },
    confirmApprovalPaymentInvoice(paymentMethodId) {
      API.confirmApprovalPaymentInvoice(
        paymentMethodId,
        this.approvalInvoiceData.id,
        this.approvalInvoiceData.lines[0].description,
        this.approvalId,
        this.approvalPilotId,
        this.approvalDroneIds,
        this.approvalDetails,
        this.flightAreasIds,
        this.declaredScenario,
        this.structureId,
        this.$refs.billingInformationForm.buildExploitationInfos(),
      )
        .then((response) => response.data)
        // Add the addional details we need to the result
        .then((result) => ({
          invoice: result.invoice,
          paymentIntent: result.payment_intent,
          checkoutId: result.checkout_id || null,
          paymentMethodId,
        }))
        // Handle additional authentication if needed
        .then(this.handleAdditionnalAuthentication)
        // requires_payment_method error (attaching card to a Customer succeeded but charge failed)
        .then(this.handleRequiresPaymentMethod)
        .then(this.onPaymentComplete)
        // Error occured
        .catch((e) => {
          const code = e.response?.data?.code;
          const message = e.response?.data?.detail || e.error.message;
          if (code === CUSTOM_HANDLING_API_CODES.STRIPE_CUSTOMER_UPDATE) {
            this.showMessage(message, 'error');
            this.$emit('fail-payment', { resetPaymentForm: false });
          } else {
            this.displayCardError(message);
            this.$emit('fail-payment', { resetPaymentForm: true });
          }
        });
    },
    async handleAdditionnalAuthentication({
      invoice,
      paymentIntent,
      paymentMethodId,
      checkoutId,
    }) {
      if (invoice.paid) {
        return { invoice, paymentIntent };
      }
      if (paymentIntent.status === 'requires_action') {
        return this.stripe.confirmCardPayment(
          paymentIntent.client_secret,
          { payment_method: paymentMethodId },
        ).then((result) => {
          if (result.error) {
            throw result;
          } else if (result.paymentIntent.status === 'succeeded') {
            const intervalId = window.setInterval(() => {
              API.getCheckoutSessionStatus(checkoutId)
                .then((response) => {
                  if (response.data.status === 'success') {
                    window.clearInterval(intervalId);
                    this.onPaymentComplete({ awaitWebhook: false });
                  }
                });
            }, 1000);
            return { invoice, paymentIntent, awaitWebhook: true };
          }
          const message = this.$gettext('An error has occured and payment was not completed');
          // eslint-disable-next-line no-throw-literal
          throw { error: { message } };
        });
      }
      // No customer action needed
      return { invoice, paymentIntent };
    },
    handleRequiresPaymentMethod({ invoice, paymentIntent, awaitWebhook = false }) {
      if (invoice.paid) {
        return { awaitWebhook };
      }
      if (paymentIntent && paymentIntent.status === 'succeeded') {
        // paymentIntent is succeed, no customer actions required.
        return { awaitWebhook };
      }
      if (paymentIntent.status === 'requires_payment_method') {
        // eslint-disable-next-line no-throw-literal
        throw { error: { message: this.$gettext('Your card was declined.') } };
      }
      return { awaitWebhook };
    },
    onPaymentComplete({ awaitWebhook = false }) {
      if (!awaitWebhook) {
        this.$emit('approval-payment-succeeded');
        this.showMessage(
          this.$gettext('Payment succeeded, approval request submitted'),
          'success',
        );
      }
    },
    displayCardError(message) {
      const cardErrors = this.$refs['card-errors'];
      cardErrors.textContent = message;
      if (message !== '') {
        this.cardError = true;
      }
    },
    async setupCardElement() {
      this.stripe = await this.loadStripe(process.env.VUE_APP_STRIPE_API_KEY);
      const elements = this.stripe.elements();
      this.card = elements.create(
        'card',
        {
          hidePostalCode: true,
          style: this.style,
        },
      );
      this.card.mount(this.$refs.card);
      this.card.on('change', (e) => {
        this.cardError = false;
        this.cardComplete = e.complete;
        if (e.error) {
          this.displayCardError(e.error.message);
        } else {
          this.displayCardError('');
        }
      });
    },
    reset() {
      this.fullName = null;
      this.card = undefined;
      this.setupCardElement();
    },
  },
};
</script>

<style
  lang='scss'
  scoped
>
.full-height {
  height: 100%;
}
.card-field {
  height: 40px;
  width: 100%;
  padding: 0px 12px;
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, .42);
}

.card-field-error {
  border-width: 2px !important;
  border-color: $color-error  !important;
}

.cardholder-name {
  /deep/ .v-text-field__details {
    margin-bottom: 0px;
  }
  /deep/ .v-messages__message {
    text-align: end;
  }
}

</style>
