<template>
  <v-container fluid>
    <div
      v-if="loading"
      class="opinion-loader"
    >
      <v-skeleton-loader type="list-item-avatar, list-item-three-line" />
    </div>
    <div v-else>
      <v-expansion-panels
        light
        class="expansion-panel"
        v-model="panelExpanded"
      >
        <v-expansion-panel expand-icon="keyboard_arrow_down">
          <v-expansion-panel-header>
            <v-row
              align="center"
              class="truncated__row"
            >
              <span class="pr-2">
                <StatusIcon
                  :status="opinion.status"
                  withTooltip
                />
              </span>
              <span
                class="text-uppercase font-weight-bold body-1 truncated"
                :class="`${colorStatus[opinion.status]}--text`"
              >
                {{ opinion.invited_authority_name }}
              </span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <OpinionChat
              :opinion="opinion"
              :newMessageDisabled="newMessageDisabled"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import OpinionChat from '@/components/Opinions/OpinionChat.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'Opinion',
  components: {
    OpinionChat,
    StatusIcon,
  },
  props: {
    opinion: {
      type: Object,
      required: true,
    },
    newMessageDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panelExpanded: 0,
      colorStatus: {
        submitted: 'primary',
        accepted: 'success',
        refused: 'error',
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.opinions.opinionsCollectionLoading;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.opinion-loader {
  background: white;
  border-radius: 4px;
  padding-top: 12px;
}

</style>
