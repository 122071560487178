<template>
  <div>
    <!-- ------------ Update notify AlphaTango Ministry of the Armies button ----------- -->
    <LayoutFlightConstraintAction
      v-if="outdatedArmyNotifs.length"
      availableToGeneralist
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <div class="pb-2">
          <v-btn
            color='success'
            class="main-button__font"
            @click.stop="notifyAlphaTangoArmy({ update: true })"
            :disabled="disabled"
            block
          >
            {{ texts.updateAlphaTangoNotifsBtnText }}
          </v-btn>
        </div>
      </template>
    </LayoutFlightConstraintAction>
    <!-- ------------ Send AlphaTango Ministry of the Armies's notifications button ----------- -->
    <LayoutFlightConstraintAction
      id="army-btn"
      :flight="flight"
      availableToGeneralist
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <div class="pb-2">
          <v-btn
            :color="notifAlphaTangoArmyButtonColorClass"
            class="main-button__font"
            @click.stop="notifyAlphaTangoArmy({ update: false })"
            :disabled="disabled"
            block
          >
            {{ texts.sendNewAlphaTangoNotifsBtnText }}
          </v-btn>
        </div>
      </template>
    </LayoutFlightConstraintAction>
    <!-- ------------ Cancel AlphaTango Ministry of the Armies's notifications button --------- -->
    <LayoutFlightConstraintAction
      v-if="activeArmyNotifs.length"
      id="army-btn-cancel"
      :flight="flight"
      availableToGeneralist
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <div class="pb-2">
          <v-btn
            color="grey lighten-2"
            class="main-button__font"
            @click.stop="showCancelNotifAlphaTangoArmyDialog=true"
            :disabled="disabled"
            block
          >
            {{texts.cancelAlphaTangoNotifsBtnText}}
          </v-btn>
        </div>
      </template>
    </LayoutFlightConstraintAction>
    <!-- ------------ Update AlphaTango Ministry of the Armies's popup --------- -->
    <v-dialog
      v-model="showUpdateNotifAlphaTangoArmyDialog"
      width="880px"
      persistent
      :retain-focus="false"
    >
      <NotifAlphaTangoArmyForm
        v-if="!isGeneralistManager"
        :flight="flight"
        :update="true"
        :notificationsToUpdate="outdatedArmyNotifs"
        @close-form="() => showUpdateNotifAlphaTangoArmyDialog = false"
      />
      <NotifAlphaTangoArmySimplified
        v-else
        :flight="flight"
        :update="true"
        :notificationsToUpdate="outdatedArmyNotifs"
        @close-form="() => showUpdateNotifAlphaTangoArmyDialog = false"
      />
    </v-dialog>
    <!-- ---- Notify AlphaTango Ministry of the Armies popup ----- -->
    <v-dialog
      v-model="showNotifyAlphaTangoArmyDialog"
      width="700px"
      persistent
      :retain-focus="false"
    >
      <NotifAlphaTangoArmyForm
        v-if="!isGeneralistPilotOrManager"
        :flight="flight"
        @close-form="() => showNotifyAlphaTangoArmyDialog=false"
      />
      <NotifAlphaTangoArmySimplified
        v-else
        :flight="flight"
        @close-form="() => showNotifyAlphaTangoArmyDialog=false"
      />
    </v-dialog>
    <!-- ---- Cancel Notify AlphaTango Ministry of the Armies popup ----- -->
    <v-dialog
      v-model="showCancelNotifAlphaTangoArmyDialog"
      width="700px"
      persistent
      :retain-focus="false"
    >
      <NotifAlphaTangoArmyCancel
        v-if="!isGeneralistPilotOrManager"
        :flight="flight"
        @close-form="() => showCancelNotifAlphaTangoArmyDialog=false"
      />
      <NotifAlphaTangoArmyCancel
        v-else
        :flight="flight"
        @close-form="() => showCancelNotifAlphaTangoArmyDialog=false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { CONSTRAINTS_CATEGORY } from '@/settings';

import { OUTDATED, CANCELLED } from '@/store/status';

import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

import NotifAlphaTangoArmyCancel from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoArmyCancel.vue';
import NotifAlphaTangoArmyForm from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoArmyForm.vue';
import NotifAlphaTangoArmySimplified from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoArmySimplified.vue';

export default {
  name: 'NotifAlphaTangoArmyActions',
  components: {
    LayoutFlightConstraintAction,
    NotifAlphaTangoArmyCancel,
    NotifAlphaTangoArmyForm,
    NotifAlphaTangoArmySimplified,
  },
  props: {
    flight: Object,
    constraint: Object,
  },
  data() {
    return {
      showNotifyAlphaTangoArmyDialog: false,
      showCancelNotifAlphaTangoArmyDialog: false,
      showUpdateNotifAlphaTangoArmyDialog: false,
    };
  },
  computed: {
    armyNotifications() {
      return this.flight.armyNotifications || [];
    },
    isGeneralistManager() {
      return this.$store.getters['authentication/isGeneralistManager'];
    },
    isGeneralistPilotOrManager() {
      return this.$store.getters['authentication/isGeneralistPilotOrManager'];
    },
    notifAlphaTangoArmyButtonColorClass() {
      return (
        this.constraint.category === CONSTRAINTS_CATEGORY.NEARBY
          ? 'grey lighten-2'
          : 'success'
      );
    },
    outdatedArmyNotifs() {
      return this.armyNotifications.filter((notif) => notif.status === OUTDATED);
    },
    activeArmyNotifs() {
      return this.armyNotifications.filter((notif) => notif.status !== CANCELLED);
    },
    texts() {
      return {
        updateAlphaTangoNotifsBtnText:
          this.$ngettext(
            'Update notification',
            'Update notifications',
            this.outdatedArmyNotifs.length,
          ),
        cancelAlphaTangoNotifsBtnText:
          this.$ngettext(
            'Cancel your notification',
            'Cancel your notifications',
            this.activeArmyNotifs.length,
          ),
        sendNewAlphaTangoNotifsBtnText: (
          this.activeArmyNotifs.length)
          ? this.$gettext(
            'Send new flight notifications',
          )
          : this.$gettext(
            'Send flight notifications',
          ),
      };
    },
  },
  methods: {
    notifyAlphaTangoArmy({ update = false }) {
      if (
        !(this.$store.state.authentication.user.subscription.active || this.flight.was_entrusted)
      ) {
        this.$emit('show-subscribe-dialog');
      } else if (update) {
        this.showUpdateNotifAlphaTangoArmyDialog = true;
      } else {
        this.showNotifyAlphaTangoArmyDialog = true;
      }
    },
  },
};
</script>
