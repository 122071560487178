<template>
  <div>
    <translate>
      This request has many areas. You have to select areas concerned by the decision *
    </translate>
    <v-row
      align="center"
      class="py-2"
    >
      <v-col
        v-for="(subApproval, index) in subApprovals"
        :key="index"
        cols="12"
        sm="4"
      >
        <v-item
          v-slot="{ toggle }"
          :value="subApproval"
        >
          <div :class="getClass(subApproval.id)">
            <v-card
              @click="toggle();selectSubApproval(subApproval.id);"
              class="pa-4"
              elevation="1"
            >
              <div
                v-if="hasDecision(subApproval.status)"
                class="on-map"
                :class="{[subApproval.status]: true}"
              >
                <span>
                  {{ getStatusName(subApproval.status) }}
                </span>
              </div>
              <div class="d-flex flex-column pb-8">
                <span class="body-1 font-weight-medium mb-2">
                  {{ subApproval.identifier }}
                </span>
                <div>
                  <translate>Height</translate>
                  <span v-if="isUserPrefecture">
                    {{ subApproval.height | meter }}
                  </span>
                  <span v-else>
                    {{ subApproval.height | meterAndFeet }}
                  </span>
                </div>
                <div>
                  <translate>Elevation</translate>
                  <span v-if="subApproval.elevation === null">
                    <v-badge
                      color="red"
                      left
                      inline
                      content="!"
                      class="small-badge"
                    />
                    <translate>
                      Elevation not determined for this zone
                    </translate>
                  </span>
                  <div v-else>
                    <span v-if="isUserPrefecture">
                      {{ subApproval.elevation | meter }}
                    </span>
                    <span v-else>
                      {{ subApproval.elevation | meterAndFeet }}
                    </span>
                  </div>
                </div>
                <div v-if="
                  isUserPrefecture && (subApproval.radial || subApproval.distance_to_structure)
                ">
                  <translate>QDR</translate>
                  <span>
                    {{ subApproval.radial | degree }}
                    -
                    {{ subApproval.distance_to_structure | mile }}
                  </span>
                </div>
                <PSAComparison
                  v-if="!isUserPrefecture"
                  :subApproval="subApproval"
                />
              </div>
            </v-card>
          </div>
        </v-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SUBMITTED } from '@/store/status';

import PSAComparison from '@/components/Approvals/PSAComparison.vue';
import { STATUS_CONFIG, THEMES } from '@/components/StatusIcon/FlightStatusIcon.vue';

export default {
  name: 'SubApprovalsSelection',
  components: { PSAComparison },
  props: {
    subApprovals: Array,
    subApprovalSelected: Array,
  },
  computed: {
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    selection: {
      get() {
        return this.subApprovalSelected;
      },
      set(value) {
        this.$emit('update:subApprovalSelected', value);
      },
    },
  },
  methods: {
    getClass(id) {
      return this.selection.includes(id) ? 'active' : 'not-active';
    },
    hasDecision(status) {
      return status !== SUBMITTED;
    },
    getStatusName(status) {
      return STATUS_CONFIG[THEMES.APPROVALS][status]?.text || this.$gettext('Unknow status');
    },
    selectSubApproval(subApprovalId) {
      if (this.selection.includes(subApprovalId)) {
        const index = this.selection.findIndex((id) => id === subApprovalId);
        this.selection.splice(index, 1);
      } else {
        this.selection.push(subApprovalId);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.on-map::before {
  content: "";
  background-color: currentColor;
  border-radius: inherit;
  opacity: .12;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &.accepted {
    background-color: $accepted-background-color;
  }
  &.incomplete {
    background-color: $incomplete-background-color;
  }
  &.refused {
    background-color: $refused-background-color;
  }
  &.reserves {
    background-color: $reserves-background-color;
  }
  &.pending {
    background-color: $pending-background-color;
  }
}
.on-map {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  border-top-left-radius: 6px;
  padding: 4px 8px;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  &.accepted {
    color: $accepted-background-color;
  }
  &.incomplete {
    color: $incomplete-background-color;
  }
  &.refused {
    color: $refused-background-color;
  }
  &.reserves {
    color: $reserves-background-color;
  }
  &.pending {
    color: $pending-background-color;
  }
}
.active {
  border: 4px solid $color-primary;
  border-radius: 6px;
}
.not-active {
  border: 4px solid transparent;
}

</style>
