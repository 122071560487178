<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate
        :translate-n="flightsToDelete.length"
        translate-plural="Delete flights?"
      >
        Delete the flight?
      </translate>
    </v-card-title>
    <v-card-text>
      <div
        v-if="isSingleDeletion"
        key="singleDeletion"
      >
        <span v-html="texts.flightToDelete" />
        <div
          v-if="errorFlightDeletion"
          class="mt-2"
        >
          <div class="error--text">
            {{ errorFlightDeletion }}
          </div>
          <div
            class="font-weight-bold mt-1"
            v-translate
          >
            Nevertheless, you can archive your flight.
          </div>
        </div>
      </div>
      <div
        v-else
        key="multiDeletion"
      >
        <div
          v-if="loadingCheckDeletionAvailable"
          class="d-flex justify-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="20"
            :width="2"
          />
        </div>
        <div v-else>
          <div v-if="flightsCanNotBeDeleted.length">
            <div class="d-flex align-center">
              <span
                class="font-weight-bold"
                v-translate
              >
                These flights can not be deleted:
              </span>
              <v-tooltip
                top
                class="cursor-pointer"
              >
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon
                    v-on="tooltip"
                    class="ml-1"
                    small
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <div>
                  <span v-translate>
                    A flight having current process can't be deleted:
                  </span>
                  <ul
                    v-for="(reason, index) in texts.reasonsCanBeDeleted"
                    :key="index"
                  >
                    <li>
                      {{ reason }}
                    </li>
                  </ul>
                  <span v-translate>Nevertheless, they can be archived.</span>
                </div>
              </v-tooltip>
            </div>
            <ul
              v-for="(flightCanNotBeDeleted, index) in flightsCanNotBeDeleted"
              :key="index"
              class="mb-2"
            >
              <li>
                {{ flightCanNotBeDeleted.id }} {{ flightCanNotBeDeleted.name }}
              </li>
            </ul>
          </div>
          <div v-if="flightsCanBeDeleted.length">
            <span
              class="font-weight-bold"
              v-translate
            >
              These flights will be deleted:
            </span>
            <ul
              v-for="(flightCanBeDeleted, index) in flightsCanBeDeleted"
              :key="index"
              class="mb-2"
            >
              <li>
                {{ flightCanBeDeleted.id }} {{ flightCanBeDeleted.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="info"
        text
        @click="$emit('close')"
      >
        <translate>No</translate>
      </v-btn>
      <v-btn
        v-if="errorFlightDeletion"
        key="archiveFlight"
        color="primary"
        text
        @click="archiveFlight()"
      >
        <translate>Archive</translate>
      </v-btn>
      <v-btn
        v-else
        key="deleteFlight"
        color="primary"
        text
        @click="deletion()"
        :loading="loadingFlightsDeletion"
        :disabled="disableDeleteButton"
      >
        <translate>Yes, I agree</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { GET_DRONES_NS } from '@/store/drones';
import {
  GET_ALL_FLIGHTS_NS,
  SET_FLIGHT_SELECTED_NS,
  UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS,
} from '@/store/flights';
import { GET_LIVESTREAMS_NS } from '@/store/livestreams';
import { GET_PILOTS_NS } from '@/store/pilots';

export default {
  name: 'FlightDeletion',
  props: {
    flightsToDelete: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loadingCheckDeletionAvailable: false,
      loadingFlightsDeletion: false,
      errorFlightDeletion: null,
      flightIdsCanBeDeleted: [],
    };
  },
  computed: {
    texts() {
      return {
        flightToDelete: this.$gettextInterpolate(
          this.$gettext('Do you really want to delete flight n°%{id}: %{name}?'),
          {
            id: this.flight ? this.flight.id : '',
            name: this.flight ? this.flight.name : '',
          },
          true,
        ),
        reasonsCanBeDeleted: [
          this.$gettext('Entrusted paperworks'),
          this.$gettext('Army notifications'),
          this.$gettext('Prefecture notifications'),
          this.$gettext('Approval requests'),
        ],
      };
    },
    disableDeleteButton() {
      return (
        this.loadingCheckDeletionAvailable
        || (
          !this.isSingleDeletion
          && this.flightIdsCanBeDeleted.length === 0
        )
      );
    },
    isSingleDeletion() {
      return this.flightsToDelete.length === 1;
    },
    flight() {
      return this.isSingleDeletion && this.flightsToDelete[0];
    },
    flightIdsToCheck() {
      return this.flightsToDelete.map((flight) => flight.id);
    },
    flightsCanNotBeDeleted() {
      return this.flightsToDelete.filter(
        (flight) => !this.flightIdsCanBeDeleted.includes(flight.id),
      );
    },
    flightsCanBeDeleted() {
      return this.flightsToDelete.filter(
        (flight) => this.flightIdsCanBeDeleted.includes(flight.id),
      );
    },
  },
  created() {
    if (!this.isSingleDeletion) {
      this.loadingCheckDeletionAvailable = true;
      APIService.checkFlightsCanBeDeleted(this.flightIdsToCheck)
        .then(({ data }) => {
          this.flightIdsCanBeDeleted = data;
        })
        .finally(() => {
          this.loadingCheckDeletionAvailable = false;
        });
    }
  },
  methods: {
    deletion() {
      if (this.isSingleDeletion) {
        this.deleteFlight();
      } else {
        this.deleteFlights();
      }
    },
    deleteFlight() {
      this.loadingFlightsDeletion = true;
      APIService.deleteFlight(this.flight.id)
        .then(() => {
          this.$emit('close');
          this.updateDataAfterDeletion();
          this.$store.dispatch(SET_FLIGHT_SELECTED_NS, { flightId: '', reload: true });
        })
        .catch((error) => {
          this.errorFlightDeletion = error?.response?.data?.detail || null;
        })
        .finally(() => {
          this.loadingFlightsDeletion = false;
        });
    },
    archiveFlight() {
      this.$emit('archive', this.flight);
    },
    deleteFlights() {
      this.loadingFlightsDeletion = true;
      APIService.deleteFlights(this.flightIdsCanBeDeleted)
        .then(() => {
          this.$emit('close');
          this.updateDataAfterDeletion();
        })
        .finally(() => {
          this.loadingFlightsDeletion = false;
        });
    },
    updateDataAfterDeletion() {
      this.$store.commit(UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS, { flightId: undefined });
      this.$store.dispatch(GET_ALL_FLIGHTS_NS, { updateBoundingBox: false });
      // Update can_be_deleted properties of pilots and drones
      this.$store.dispatch(GET_PILOTS_NS);
      this.$store.dispatch(GET_DRONES_NS);
      this.$store.dispatch(GET_LIVESTREAMS_NS);
    },
  },
};
</script>
