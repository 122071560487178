<template>
  <div>
    <!-- ---- Cancel approval request button ----- -->
    <LayoutFlightConstraintAction
      id="cancel-approval"
      :flight="flight"
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <v-btn
          color="grey lighten-2"
          class="main-button__font my-0"
          :disabled="disabled"
          @click.stop="dialog=true"
          block
        >
          <span v-translate>Cancel approval request</span>
        </v-btn>
      </template>
    </LayoutFlightConstraintAction>
    <!-- ---- Cancel approval request dialog ----- -->
    <v-dialog
      v-model="dialog"
      width="700px"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span v-translate>Confirmation required</span>
        </v-card-title>
        <v-card-text>
          <span v-translate>Are you sure you want to cancel this request?</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="dialog=false"
          >
            <span v-translate>Cancel</span>
          </v-btn>
          <v-btn
            :loading="loading"
            text
            color="primary"
            @click="cancelApprovalRequest()"
          >
            <span v-translate>Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

import APIService from '@/services/api';

export default {
  name: 'RequestApprovalActionsCancel',
  components: { LayoutFlightConstraintAction },
  props: {
    approvalId: Number,
    flight: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    cancelApprovalRequest() {
      this.loading = true;
      APIService.cancelApprovalRequest(this.approvalId)
        .then(({ data }) => {
          const { approval } = data;
          this.$emit('update-approval', approval);
          this.showMessage(this.$gettext('Approval request cancelled successfully'), 'success');
        })
        .finally(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
  },
};
</script>
