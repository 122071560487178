import Vue from 'vue';

const filterCoord = (value) => {
  if (!value) return '';
  return Math.round(value * 10000) / 10000;
};

// Take decimal coordinates and return DMS format. Ex : 42°20"10.1'N
// eslint-disable-next-line import/prefer-default-export
export const filterCoordDMS = (coordinate, isLongitude) => {
  if (!coordinate) return '';

  let degree = coordinate;
  let dir = null;
  if (degree < 0) {
    dir = isLongitude ? 'W' : 'S';
  } else {
    dir = isLongitude ? 'E' : 'N';
  }
  const deg = 0 || (degree < 0 ? (degree = -degree) : degree);
  const min = 0 || (((degree += 1e-9) % 1) * 60);
  const sec = (0 || (((degree * 60) % 1) * 6000)) / 100;
  return `${Math.floor(deg)}°${Math.floor(min)}'${sec.toFixed(1)}"${dir}`;
};

Vue.filter('coord', filterCoord);

Vue.filter('coordDMS', filterCoordDMS);
