<template>
  <v-dialog
    v-model="openDialog"
    max-width="1080"
    persistent
  >
    <v-skeleton-loader
      v-if="loadingLayers"
      type="article, actions"
    />
    <v-card
      v-else
      class="default--dialog__card overflow-y"
      max-height="800px"
      elevation="0"
    >
      <v-card-title>
        <translate>Import Geoportail layers</translate>
      </v-card-title>
      <v-card-text>
        <div class="pa-3">
          <span>
            {{ explainText }}
          </span>
        </div>
        <div>
          <v-text-field
            v-model="search"
            class="px-3"
            :append-icon="search ? null : 'search'"
            :placeholder="$gettext('Recherche')"
            clearable
            single-line
            hide-details
          />
        </div>
        <v-item-group v-if="categories.size">
          <v-container>
            <v-expansion-panels
              v-for="(category, index) in categories"
              :key="index"
              class="mt-1"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <v-icon>
                      {{ iconCategory(category) }}
                    </v-icon>
                    <span class="flex-shrink-0 ml-3">
                      {{ category }}
                    </span>
                    <div>
                      <v-chip
                        v-for="(layerName, index) in layersSelectedPerCategory(category)"
                        :key="index"
                        small
                        outlined
                        color="primary"
                        class="ml-2 pa-2 my-1"
                      >
                        {{ layerName }}
                      </v-chip>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-2">
                  <v-row>
                    <v-col
                      v-for="(layer, index) in layersPerCategory(category)"
                      :key="index"
                      cols="12"
                      md="4"
                    >
                      <v-item
                        v-slot="{ toggle }"
                        :value="layer"
                      >
                        <div :class="getClass(layer)">
                          <v-card
                            height="200"
                            class="pa-4"
                            @click="toggle(); selectLayer(layer);"
                          >
                            <div
                              v-if="layer.disabled"
                              class="on-map"
                            >
                              <span v-translate>On map</span>
                            </div>
                            <div class="d-flex justify-center">
                              <v-img
                                :src="layer.thumbnail_url"
                                height="125"
                                width="250"
                              />
                            </div>
                            <div class="d-flex align-center justify-space-between mt-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <span
                                    v-on="on"
                                    class="flex-wrap-1 flex-shrink-1 truncated pr-1"
                                  >
                                    {{ layer.name }}
                                  </span>
                                </template>
                                <span>
                                  {{ layer.name }}
                                </span>
                              </v-tooltip>
                              <v-icon
                                @click.stop="openInformation(layer.information_url)"
                                class="flex-shrink-0"
                              >
                                mdi-information-outline
                              </v-icon>
                            </div>
                          </v-card>
                        </div>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-item-group>
        <div
          v-else
          class="d-flex justify-center mt-8"
        >
          <span v-translate>No result found</span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="info"
          @click="close()"
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="!selectedLayersIds.length"
          :loading="loading"
          @click="addLayers()"
        >
          <translate>Confirm</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/services/api';

import { GEOPORTAIL_LAYERS_DICT_ICONS } from '@/settings';

import { RESET_DATA_STORES_NS } from '@/store/application';
import { SHOW_GEOPORTAIL_LAYERS_IMPORT_DIALOG_NS } from '@/store/map';

export default {
  name: 'ImportGeoportailMapLayers.vue',
  data() {
    return {
      loadingLayers: false,
      search: null,
      layers: [],
      selectedLayers: [],
      loading: false,
    };
  },
  computed: {
    openDialog() {
      return this.$store.state.map.showGeoportailLayersImportDialog;
    },
    categories() {
      return new Set(this.filteredLayers.map((layer) => layer.category).sort((a, b) => a > b));
    },
    filteredLayers() {
      if (this.search && this.search.length) {
        return this.layers.filter(
          (layer) => layer.name.toLowerCase().includes(this.search.toLowerCase()),
        );
      }
      return this.layers;
    },
    selectedLayersIds() {
      return this.selectedLayers.map((layer) => layer.identifier);
    },
    explainText() {
      return this.$gettext(`You can personalize your background map. Select the layers you want to
        import into the map, then a new entry will appear in the legend of the map. Display new
        layers by clicking the checkbox. You can also delete this layer directly in the legend.`);
    },
  },
  watch: {
    openDialog(newValue) {
      if (newValue) {
        this.loadingLayers = true;
        API.getGeoportailLayers()
          .then(({ data }) => {
            this.layers = data;
          })
          .finally(() => {
            this.loadingLayers = false;
          });
      }
    },
  },
  methods: {
    getClass(layer) {
      if (layer.disabled) {
        return 'disabled';
      } if (this.selectedLayersIds.includes(layer.identifier)) {
        return 'active';
      }
      return 'not-active';
    },
    close() {
      this.$store.commit(SHOW_GEOPORTAIL_LAYERS_IMPORT_DIALOG_NS, false);
      this.loadingLayers = false;
      this.search = null;
      this.layers = [];
      this.selectedLayers = [];
      this.loading = false;
    },
    layersPerCategory(category) {
      return this.filteredLayers.filter(
        (layer) => layer.category === category,
      ).sort((a, b) => a.name > b.name);
    },
    layersSelectedPerCategory(category) {
      return this.selectedLayers.filter(
        (layer) => layer.category === category,
      ).map((layer) => layer.name).sort((a, b) => a > b);
    },
    iconCategory(category) {
      return GEOPORTAIL_LAYERS_DICT_ICONS[category] || 'icon-fly_wait_line';
    },
    openInformation(url) {
      window.open(url, '_blank');
    },
    selectLayer(layer) {
      if (this.selectedLayersIds.includes(layer.identifier)) {
        const index = this.selectedLayers.findIndex((l) => l.identifier === layer.identifier);
        this.selectedLayers.splice(index, 1);
      } else {
        this.selectedLayers.push(layer);
      }
    },
    addLayers() {
      this.loading = true;
      API.addGeoportailLayers(this.selectedLayersIds)
        .then(() => {
          this.close();
          this.$store.dispatch(RESET_DATA_STORES_NS);
          this.$router.push({ path: '/' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

</script>

<style
  lang="scss"
  scoped
>
.active {
  border: 4px solid $color-primary;
  border-radius: 6px;
}
.not-active {
  border: 4px solid transparent;
}
.disabled {
  border: 4px solid $color-success;
  border-radius: 6px;
  pointer-events: none;
}
.on-map {
  background-color: $color-success;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-bottom-left-radius: 6px;
  padding: 4px 8px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

</style>
