import Vue from 'vue';
import * as turf from '@turf/turf';

import APIService from '@/services/api';

// we only import action names to dispatch them, so by the time we use them,
// they are guaranteed to be resolved by webpacks even though it creates a circular import
/* eslint-disable import/no-cycle */
import MapHelper, {
  MODE,
  formatCustomLayerOutlineIdentifier,
  formatCustomLayerLabelsIdentifier,
} from '@/components/Map/MapboxGL.helper';
import {
  CUSTOM_LAYER_AREA_STYLE_IDENTIFIER,
  CUSTOM_LAYER_OUTLINE_STYLE_IDENTIFIER,
  LABELS_POLYGON_STYLE_IDENTIFIER,
  LABELS_CENTER_POLYGON_STYLE_IDENTIFIER,
} from '@/components/Map/MapLayerStyles';

import {
  APPLICATION_STATUS,
  APPLICATION_TAB,
  SHOW_SNACKBAR_NS,
  SET_LOADING_SNACKBAR_NS,
  SET_SNACKBAR_MESSAGE_NS,
  SET_STATUS_NS,
} from '@/store/application';
import { SET_APPROVAL_SELECTED_NS } from '@/store/approvals';
import { GET_COTATIONS_NS } from '@/store/cotations';
import { SET_FLIGHT_SELECTED_NS, SET_FLIGHT_APPROVAL_ID_SELECTED_NS } from '@/store/flights';
import { SET_OPINION_SELECTED_NS } from '@/store/opinions';
import { GET_AUTHORITY_STRUCTURES_NS } from '@/store/structures';

const namespace = 'map';

// LAYERS MAP
// CTR areas layers
const CTR_AREAS_SOURCE_NAME = 'CTR_AREAS_LAYER';
const CTR_AREAS_ONLY_FOR_SUBSCRIBERS_SOURCE_NAME = 'CTR_AREAS_LAYER_ONLY_FOR_SUBSCRIBERS';

// Flights layers
const FLIGHT_AREAS_SOURCE_NAME = 'FLIGHT_AREAS_LAYER';
const FLIGHT_AREAS_OUTLINE_SOURCE_NAME = 'FLIGHT_AREAS_OUTLINE_LAYER';
const FLIGHT_CENTERS_SOURCE_NAME = 'FLIGHT_CENTERS_LAYER';
// Hover flights layers
const FLIGHT_AREAS_HOVER_SOURCE_NAME = 'FLIGHT_AREAS_HOVER_LAYER';
const FLIGHT_AREAS_OUTLINE_HOVER_SOURCE_NAME = 'FLIGHT_AREAS_OUTLINE_HOVER_LAYER';
const FLIGHT_CENTERS_HOVER_SOURCE_NAME = 'FLIGHT_CENTERS_HOVER_LAYER';
// Selected flights layers
const FLIGHT_AREAS_SELECTED_SOURCE_NAME = 'FLIGHT_AREAS_SELECTED_LAYER';
const FLIGHT_AREAS_SELECTED_SOURCE_NAME_LABEL = 'FLIGHT_AREAS_SELECTED_LABEL_LAYER';
const FLIGHT_AREAS_SELECTED_SOURCE_NAME_HEIGHT_LABEL = 'FLIGHT_AREAS_SELECTED_HEIGHT_LABEL_LAYER';
const FLIGHT_AREA_SELECTED_HOVER_SOURCE_NAME = 'FLIGHT_AREA_SELECTED_HOVER';
const FLIGHT_AREA_SELECTED_OUTLINE_HOVER_SOURCE_NAME = 'FLIGHT_AREA_SELECTED_OUTLINE_HOVER';
const FLIGHT_AREAS_OUTLINE_SELECTED_SOURCE_NAME = 'FLIGHT_AREAS_OUTLINE_SELECTED_LAYER';
const FLIGHT_CENTERS_SELECTED_SOURCE_NAME = 'FLIGHT_CENTERS_SELECTED_LAYER';
const FLIGHT_AREA_MEASUREMENTS_INFO_SOURCE_NAME = 'FLIGHT_AREA_MEASUREMENTS_INFO_LAYER';

// Approval from flight detail
const APPROVAL_AREAS_SELECTED_SOURCE_NAME = 'APPROVAL_AREAS_SELECTED';
const APPROVAL_AREAS_SELECTED_OUTLINE_SOURCE_NAME = 'APPROVAL_AREAS_SELECTED_OUTLINE';

// Flights exclusion zone layers
const FLIGHT_EXCLUSION_LAYER_SOURCE_NAME = 'FLIGHT_EXCLUSION_LAYER';
const FLIGHT_EXCLUSION_OUTLINE_LAYER_SOURCE_NAME = 'FLIGHT_EXCLUSION_OUTLINE_LAYER';
const FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_SOURCE_NAME = 'FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_LAYER';
const FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_CENTERS_SOURCE_NAME = 'FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_CENTERS_LAYER';
const FLIGHT_EXCLUSION_FORBIDDEN_ZONE_LAYER = 'FLIGHT_EXCLUSION_FORBIDDEN_ZONE_LAYER';
const FLIGHT_EXCLUSION_FORBIDDEN_ZONE_OUTLINE_LAYER = 'FLIGHT_EXCLUSION_FORBIDDEN_ZONE_OUTLINE_LAYER';

// Constraints layers
const CONSTRAINT_HOVER_SOURCE_NAME = 'CONSTRAINT_HOVER_LAYER';
const CONSTRAINT_SELECTED_SOURCE_NAME = 'CONSTRAINT_SELECTED_LAYER';
const CONSTRAINT_SELECTED_LABEL_SOURCE_NAME = 'CONSTRAINT_SELECTED_LABEL_LAYER';

// NOTAM layers
const NOTAM_SELECTED_SOURCE_NAME = 'NOTAM_SELECTED_LAYER';

// Tracking layers
const DRONE_TRACKING_SOURCE_NAME = 'DRONE_TRACKING_LAYER';
const DRONE_TRACKING_DIRECTION_SOURCE_NAME = 'DRONE_TRACKING_LAYER_DIRECTION';
const PLANE_TRACKING_SOURCE_NAME = 'PLANE_TRACKING_LAYER';
const DRONE_TRACKING_TRACE_LINES_SOURCE_NAME = 'DRONE_TRACKING_TRACE_LINES_LAYER';
const DRONE_TRACKING_TRACE_LINES_SELECTED_SOURCE_NAME = 'DRONE_TRACKING_TRACE_LINES_SELECTED_LAYER';
const DRONE_TRACKING_TRACE_POINTS_SELECTED_SOURCE_NAME = 'DRONE_TRACKING_TRACE_POINTS_SELECTED_LAYER';
const DRONE_TRACKING_TRACE_CENTERS_SOURCE_NAME = 'DRONE_TRACKING_TRACE_CENTERS_LAYER';
const DRONE_TRACKING_TRACE_CENTERS_SELECTED_SOURCE_NAME = 'DRONE_TRACKING_TRACE_CENTERS_SELECTED_LAYER';
const DRONE_TRACKING_TRACE_LABELS_SOURCE_NAME = 'DRONE_TRACKING_TRACE_LABELS_LAYER';
const DRONE_TRACKING_TRACE_DASH_LINES_SOURCE_NAME = 'DRONE_TRACKING_TRACE_DASH_LINES_LAYER';
const DRONE_TRACKING_TRACE_DASH_LINES_SELECTED_SOURCE_NAME = 'DRONE_TRACKING_TRACE_DASH_LINES_SELECTED_LAYER';

// PSA layers
const PSA_ELEVATIONS_SOURCE_NAME = 'PSA_ELEVATIONS_LAYER';
const PSA_OUTLINES_SOURCE_NAME = 'PSA_OUTLINES_LAYER';

// Structures AIP layers
const STRUCTURES_AIP_SOURCE_NAME = 'AUTHORITY_AIP_AREAS_LAYER';
const STRUCTURES_AIP_OUTLINE_SOURCE_NAME = 'AUTHORITY_AIP_AREAS_OUTLINE_LAYER';
const STRUCTURES_AIP_LABEL_SOURCE_NAME = 'AUTHORITY_AIP_AREAS_LABEL_LAYER';

const SET_LAYERS_DATA = 'SET_LAYERS_DATA';
const CONSTRAINTS_POINT_POPUP = 'CONSTRAINTS_POINT_POPUP';
const CONSTRAINTS_CLIENT_STRUCTURE_POPUP = 'CONSTRAINTS_CLIENT_STRUCTURE_POPUP';
const SET_COTATIONS = 'SET_COTATIONS';
const SET_CONSTRAINT_HOVER = 'SET_CONSTRAINT_HOVER';
const SET_CTR_AREAS = 'SET_CTR_AREAS';
const SET_CTR_AREAS_ONLY_FOR_SUBSCRIBERS = 'SET_CTR_AREAS_ONLY_FOR_SUBSCRIBERS';
const SET_CONSTRAINT_SELECTED = 'SET_CONSTRAINT_SELECTED';
const SET_NOTAM_SELECTED = 'SET_NOTAM_SELECTED';
const SET_FLIGHT_AREAS_HOVER = 'SET_FLIGHT_AREAS_HOVER';
const SET_FLIGHT_AREA_HOVER = 'SET_FLIGHT_AREA_HOVER';
const SET_FLIGHT_AREAS_SELECTED = 'SET_FLIGHT_AREAS_SELECTED';
const SET_FLIGHT_AREAS_NAME = 'SET_FLIGHT_AREAS_NAME';
const SET_FLIGHT_AREAS_MEASUREMENTS_INFO = 'SET_FLIGHT_AREAS_MEASUREMENTS_INFO';
const SET_FLIGHT_CENTERS = 'SET_FLIGHT_CENTERS';
const SET_FLIGHT_CENTERS_HOVER = 'SET_FLIGHT_CENTERS_HOVER';
const SET_FLIGHT_CENTERS_SELECTED = 'SET_FLIGHT_CENTERS_SELECTED';
const SET_FLIGHT_AREAS = 'SET_FLIGHT_AREAS';
const SET_FLIGHT_HOVER = 'SET_FLIGHT_HOVER';
const SET_FLIGHT_SELECTED = 'SET_FLIGHT_SELECTED';
const SET_APPROVAL_FLIGHT_HOVER = 'SET_APPROVAL_FLIGHT_HOVER';
const SET_FLIGHT_AREA_SELECTED_HOVER = 'SET_FLIGHT_AREA_SELECTED_HOVER';
const SET_APPROVAL_FLIGHT_AREAS_HOVER = 'SET_APPROVAL_FLIGHT_AREAS_HOVER';
const SET_APPROVAL_FLIGHT_SELECTED = 'SET_APPROVAL_FLIGHT_SELECTED';
const SET_APPROVAL_HOVER = 'SET_APPROVAL_HOVER';
const SET_APPROVAL_SELECTED = 'SET_APPROVAL_SELECTED';
const SET_ACTIVATION_SELECTED = 'SET_ACTIVATION_SELECTED';
const SET_APPROVAL_OR_ACTIVATION_SELECTED = 'SET_APPROVAL_OR_ACTIVATION_SELECTED';
const SET_APPROVAL_AREA_HOVERS = 'SET_APPROVAL_AREA_HOVERS';
const SET_OPINION_SELECTED = 'SET_OPINION_SELECTED';
const SET_MAP_DRAW_AREA = 'SET_MAP_DRAW_AREA';
const SELECT_MAP_DRAW_AREA = 'SELECT_MAP_DRAW_AREA';
const DELETE_MAP_DRAW_AREA = 'DELETE_MAP_DRAW_AREA';
const SET_FLIGHT_EXCLUSION_ZONE = 'SET_FLIGHT_EXCLUSION_ZONE';
const ADD_EXCLUSION_ZONE_MARKER = 'ADD_EXCLUSION_ZONE_MARKER';
const REMOVE_LAST_EXCLUSION_ZONE_MARKER = 'REMOVE_LAST_EXCLUSION_ZONE_MARKER';
const CLEAR_EXCLUSION_ZONE_MARKERS = 'CLEAR_EXCLUSION_ZONE_MARKERS';
const UPDATE_EXCLUSION_ZONE_MARKER_COUNT = 'UPDATE_EXCLUSION_ZONE_MARKER_COUNT';
const SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES = 'SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES';
const SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE = 'SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE';
const SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_RADIUS = 'SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_RADIUS';
const SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP = 'SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP';
const SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE = 'SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE';
const SET_FLIGHT_EXCLUSION_DISPLAY_FORBIDDEN_ZONE = 'SET_FLIGHT_EXCLUSION_DISPLAY_FORBIDDEN_ZONE';
const HIDE_FLIGHTS = 'HIDE_FLIGHTS';
const SHOW_FLIGHTS = 'SHOW_FLIGHTS';
const SET_LOADED_KML = 'SET_LOADED_KML';
const FORMAT_DRONES_LAST_POSITION = 'FORMAT_DRONES_LAST_POSITION';
const SET_DRONES_TRACKING = 'SET_DRONES_LAST_POSITION';
const SET_DRONES_TRACKING_POSITIONS = 'SET_DRONES_TRACKING_POSITIONS';
const SET_TRACKERS_ALERTS = 'SET_TRACKERS_ALERTS';
const MARK_ALERTS_READ = 'MARK_ALERTS_READ';
const OPEN_DRONES_TRACKING_POPUP = 'OPEN_DRONES_TRACKING_POPUP';
const FORMAT_PLANES_LAST_POSITION = 'FORMAT_PLANES_LAST_POSITION';
const SET_PLANES_TRACKING = 'SET_PLANES_TRACKING';
const ZOOM_TO_CENTER = 'ZOOM_TO_CENTER';
const ZOOM_TO_AREA = 'ZOOM_TO_AREA';
const SET_BOUNDING_BOX = 'SET_BOUNDING_BOX';
const SET_BBOX_IS_UPDATING = 'SET_BBOX_IS_UPDATING';
const RESET_MAP = 'RESET_MAP';
const RESIZE_MAP = 'RESIZE_MAP';
const SET_MAP_STATUS = 'SET_MAP_STATUS';
const SET_MAP_LOADED = 'SET_MAP_LOADED';
const SET_MAP_AREA = 'SET_MAP_AREA';
const SELECT_OBJECT_FROM_FEATURE = 'SELECT_OBJECT_FROM_FEATURE';
const SELECT_OBJECT_FROM_FLIGHT_FEATURE = 'SELECT_OBJECT_FROM_FLIGHT_FEATURE';
const SET_MAP_FEATURE_SELECTED = 'SET_MAP_FEATURE_SELECTED';
const SET_MAP_FEATURE_HOVERED = 'SET_MAP_FEATURE_HOVERED';
const SET_CONSTRAINTS_POINT = 'SET_CONSTRAINTS_POINT';
const SET_CONSTRAINTS_CLIENT_STRUCTURE_ID = 'SET_CONSTRAINTS_CLIENT_STRUCTURE_ID';
const ADD_MAP_LAYER_POPUPS_ID = 'ADD_MAP_LAYER_POPUPS_ID';
const REMOVE_MAP_LAYER_POPUPS_ID = 'REMOVE_MAP_LAYER_POPUPS_ID';
const REFRESH_LAYER_POP_UP = 'REFRESH_LAYER_POP_UP';
const REFRESH_MAP_DATA = 'REFRESH_MAP_DATA';
const emptySourceData = { type: 'FeatureCollection', features: [] };
const customLayersDisplayPriority = 10;
const SHOW_CUSTOM_LAYER = 'SHOW_CUSTOM_LAYER';
const HIDE_CUSTOM_LAYER = 'HIDE_CUSTOM_LAYER';
const DISABLE_CUSTOM_LAYER = 'DISABLE_CUSTOM_LAYER';
const ENABLE_CUSTOM_LAYER = 'ENABLE_CUSTOM_LAYER';
const CHANGE_DRAW_LAYER_STYLE = 'CHANGE_DRAW_LAYER_STYLE';
const SHOW_MOBILE_NETWORK_LAYERS_IMPORT_DIALOG = 'SHOW_MOBILE_NETWORK_LAYERS_IMPORT_DIALOG';
const SHOW_GEOPORTAIL_LAYERS_IMPORT_DIALOG = 'SHOW_GEOPORTAIL_LAYERS_IMPORT_DIALOG';
const SHOW_LAYER_IMPORT_DIALOG = 'SHOW_LAYER_IMPORT_DIALOG';
const SHOW_LAYER_EDIT_DIALOG = 'SHOW_LAYER_EDIT_DIALOG';
const SHOW_CUSTOM_DATA_LAYERS_EDIT_DIALOG = 'SHOW_CUSTOM_DATA_LAYERS_EDIT_DIALOG';
const SHOW_LAYER_DELETE_DIALOG = 'SHOW_LAYER_DELETE_DIALOG';
const CHECK_AREA_CAN_BE_DELETED = 'CHECK_AREA_CAN_BE_DELETED';
const SHOW_TRACES = 'SHOW_TRACES';
const SET_TRACES_CENTERS = 'SET_TRACES_CENTERS';
const SET_TRACES_LINES = 'SET_TRACES_LINES';
const SET_TRACES_DASH_LINES = 'SET_TRACES_DASH_LINES';
const SET_TRACES_DASH_LINES_SELECTED = 'SET_TRACES_DASH_LINES_SELECTED';
const SET_TRACE_HOVER_OR_SELECTED = 'SET_TRACE_HOVER_OR_SELECTED';
const SET_STRUCTURES_AIP_GEOMETRIES = 'SET_STRUCTURES_AIP_GEOMETRIES';

export const SET_BOUNDING_BOX_NS = `${namespace}/${SET_BOUNDING_BOX}`;
export const RESET_MAP_NS = `${namespace}/${RESET_MAP}`;
export const RESIZE_MAP_NS = `${namespace}/${RESIZE_MAP}`;
export const SET_MAP_STATUS_NS = `${namespace}/${SET_MAP_STATUS}`;
export const REFRESH_MAP_DATA_NS = `${namespace}/${REFRESH_MAP_DATA}`;
export const SET_MAP_AREA_NS = `${namespace}/${SET_MAP_AREA}`;
export const SET_MAP_FEATURE_SELECTED_NS = `${namespace}/${SET_MAP_FEATURE_SELECTED}`;
export const SET_MAP_FEATURE_HOVERED_NS = `${namespace}/${SET_MAP_FEATURE_HOVERED}`;
export const SET_FLIGHT_AREAS_NS = `${namespace}/${SET_FLIGHT_AREAS}`;
export const SET_MAP_APPROVAL_SELECTED_NS = `${namespace}/${SET_APPROVAL_SELECTED}`;
export const SET_MAP_ACTIVATION_SELECTED_NS = `${namespace}/${SET_ACTIVATION_SELECTED}`;
export const SET_FLIGHT_AREAS_SELECTED_NS = `${namespace}/${SET_FLIGHT_AREAS_SELECTED}`;
export const SET_FLIGHT_CENTERS_SELECTED_NS = `${namespace}/${SET_FLIGHT_CENTERS_SELECTED}`;
export const SET_FLIGHT_AREAS_NAME_NS = `${namespace}/${SET_FLIGHT_AREAS_NAME}`;
export const SET_FLIGHT_AREAS_MEASUREMENTS_INFO_NS = `${namespace}/${SET_FLIGHT_AREAS_MEASUREMENTS_INFO}`;
export const SET_MAP_DRAW_AREA_NS = `${namespace}/${SET_MAP_DRAW_AREA}`;
export const SELECT_MAP_DRAW_AREA_NS = `${namespace}/${SELECT_MAP_DRAW_AREA}`;
export const DELETE_MAP_DRAW_AREA_NS = `${namespace}/${DELETE_MAP_DRAW_AREA}`;
export const SET_FLIGHT_EXCLUSION_ZONE_NS = `${namespace}/${SET_FLIGHT_EXCLUSION_ZONE}`;
export const ADD_EXCLUSION_ZONE_MARKER_NS = `${namespace}/${ADD_EXCLUSION_ZONE_MARKER}`;
export const REMOVE_LAST_EXCLUSION_ZONE_MARKER_NS = `${namespace}/${REMOVE_LAST_EXCLUSION_ZONE_MARKER}`;
export const CLEAR_EXCLUSION_ZONE_MARKERS_NS = `${namespace}/${CLEAR_EXCLUSION_ZONE_MARKERS}`;
export const SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_NS = `${namespace}/${SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES}`;
export const SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_NS = `${namespace}/${SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE}`;
export const SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP_NS = `${namespace}/${SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP}`;
export const SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE_NS = `${namespace}/${SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE}`;
export const HIDE_FLIGHTS_NS = `${namespace}/${HIDE_FLIGHTS}`;
export const SHOW_FLIGHTS_NS = `${namespace}/${SHOW_FLIGHTS}`;
export const ZOOM_TO_CENTER_NS = `${namespace}/${ZOOM_TO_CENTER}`;
export const ZOOM_TO_AREA_NS = `${namespace}/${ZOOM_TO_AREA}`;
export const SET_LOADED_KML_NS = `${namespace}/${SET_LOADED_KML}`;
export const SHOW_CUSTOM_LAYER_NS = `${namespace}/${SHOW_CUSTOM_LAYER}`;
export const HIDE_CUSTOM_LAYER_NS = `${namespace}/${HIDE_CUSTOM_LAYER}`;
export const DISABLE_CUSTOM_LAYER_NS = `${namespace}/${DISABLE_CUSTOM_LAYER}`;
export const ENABLE_CUSTOM_LAYER_NS = `${namespace}/${ENABLE_CUSTOM_LAYER}`;
export const CHANGE_DRAW_LAYER_STYLE_NS = `${namespace}/${CHANGE_DRAW_LAYER_STYLE}`;
export const SET_TRACKERS_ALERTS_NS = `${namespace}/${SET_TRACKERS_ALERTS}`;
export const FORMAT_DRONES_LAST_POSITION_NS = `${namespace}/${FORMAT_DRONES_LAST_POSITION}`;
export const MARK_ALERTS_READ_NS = `${namespace}/${MARK_ALERTS_READ}`;
export const OPEN_DRONES_TRACKING_POPUP_NS = `${namespace}/${OPEN_DRONES_TRACKING_POPUP}`;
export const FORMAT_PLANES_LAST_POSITION_NS = `${namespace}/${FORMAT_PLANES_LAST_POSITION}`;
export const SHOW_MOBILE_NETWORK_LAYERS_IMPORT_DIALOG_NS = `${namespace}/${SHOW_MOBILE_NETWORK_LAYERS_IMPORT_DIALOG}`;
export const SHOW_GEOPORTAIL_LAYERS_IMPORT_DIALOG_NS = `${namespace}/${SHOW_GEOPORTAIL_LAYERS_IMPORT_DIALOG}`;
export const SHOW_LAYER_IMPORT_DIALOG_NS = `${namespace}/${SHOW_LAYER_IMPORT_DIALOG}`;
export const SHOW_LAYER_EDIT_DIALOG_NS = `${namespace}/${SHOW_LAYER_EDIT_DIALOG}`;
export const SHOW_CUSTOM_DATA_LAYERS_EDIT_DIALOG_NS = `${namespace}/${SHOW_CUSTOM_DATA_LAYERS_EDIT_DIALOG}`;
export const SHOW_LAYER_DELETE_DIALOG_NS = `${namespace}/${SHOW_LAYER_DELETE_DIALOG}`;
export const REMOVE_MAP_LAYER_POPUPS_ID_NS = `${namespace}/${REMOVE_MAP_LAYER_POPUPS_ID}`;
export const REFRESH_LAYER_POP_UP_NS = `${namespace}/${REFRESH_LAYER_POP_UP}`;
export const SET_FLIGHT_AREA_HOVER_NS = `${namespace}/${SET_FLIGHT_AREA_HOVER}`;
export const SET_APPROVAL_FLIGHT_HOVER_NS = `${namespace}/${SET_APPROVAL_FLIGHT_HOVER}`;
export const SET_APPROVAL_FLIGHT_AREAS_HOVER_NS = `${namespace}/${SET_APPROVAL_FLIGHT_AREAS_HOVER}`;
export const SET_APPROVAL_AREA_HOVERS_NS = `${namespace}/${SET_APPROVAL_AREA_HOVERS}`;
export const SHOW_TRACES_NS = `${namespace}/${SHOW_TRACES}`;

export const MAP_STATUS = {
  READ: 'read',
  WRITE: 'write',
};

export const SELECTABLE_FEATURE_KEYS = {
  flight: 'flight',
  trace: 'trace',
};

const SELECTABLE_LAYERS = {
  [SELECTABLE_FEATURE_KEYS.flight]: FLIGHT_CENTERS_SOURCE_NAME,
  [SELECTABLE_FEATURE_KEYS.trace]: DRONE_TRACKING_TRACE_CENTERS_SOURCE_NAME,
};

const SELECTABLE_LAYERS_TO_FEATURE_KEY = {
  [FLIGHT_CENTERS_SOURCE_NAME]: SELECTABLE_FEATURE_KEYS.flight,
  [DRONE_TRACKING_TRACE_CENTERS_SOURCE_NAME]: SELECTABLE_FEATURE_KEYS.trace,
};

export const FLIGHT_EXCLUSION_LAYER_IDENTIFIERS = [
  FLIGHT_EXCLUSION_LAYER_SOURCE_NAME,
  FLIGHT_EXCLUSION_OUTLINE_LAYER_SOURCE_NAME,
  FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_SOURCE_NAME,
  FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_CENTERS_SOURCE_NAME,
  FLIGHT_EXCLUSION_FORBIDDEN_ZONE_LAYER,
  FLIGHT_EXCLUSION_FORBIDDEN_ZONE_OUTLINE_LAYER,
];

function computeBoundingBox(geom) {
  return turf.bbox(geom);
}

function compareFeaturesAltitudes(features) {
  // Compare last and first altitude to determine vertical direction
  let difference = 0;
  if (features.length) {
    const lastAltitude = features[0].properties.altitude;
    const firstAltitude = features[features.length - 1].properties.altitude;
    difference = lastAltitude - firstAltitude;
  }
  // eslint-disable-next-line no-nested-ternary
  return difference > 0 ? 'up' : difference < 0 ? 'down' : 'still';
}

function bufferPilots(markers, bufferRadius) {
  const buffers = [];
  markers.forEach((marker) => {
    const { lng, lat } = marker.getLngLat();
    const point = turf.point([lng, lat]);
    const buffer = turf.buffer(point, bufferRadius / 1000, { units: 'kilometers' });
    buffers.push(buffer);
  });
  if (buffers.length === 1) {
    return buffers[0];
  } if (buffers.length === 2) {
    const union = turf.union(buffers[0], buffers[1]);
    return union;
  }
  let union = turf.union(buffers[0], buffers[1]);
  for (let i = 2; i < buffers.length; i += 1) {
    union = turf.union(union, buffers[i]);
  }
  return union;
}

function flightAreaToPolygon(flightArea, fromMap = false) {
  const areas = [];
  if (fromMap) {
    flightArea.features.forEach((feature) => {
      let polygon;
      if (feature.geometry.type === 'MultiPolygon') {
        feature.geometry.coordinates.forEach((element) => {
          polygon = turf.polygon(element);
          areas.push(polygon);
        });
      } else {
        polygon = turf.polygon(feature.geometry.coordinates);
        areas.push(polygon);
      }
    });
  } else {
    flightArea.coordinates.forEach((c) => {
      const polygon = turf.polygon(c);
      areas.push(polygon);
    });
  }
  if (areas.length === 1) {
    return areas[0];
  } if (areas.length === 2) {
    const union = turf.union(areas[0], areas[1]);
    return union;
  }
  let union = turf.union(areas[0], areas[1]);
  for (let i = 2; i < areas.length; i += 1) {
    union = turf.union(union, areas[i]);
  }
  return union;
}

function getFlightCenterFeature(mapHelper, id) {
  let centerSourceData = emptySourceData;
  if (mapHelper && id) {
    const { features: centerFeatures } = mapHelper.sourcesData[FLIGHT_CENTERS_SOURCE_NAME];
    const centerFeature = centerFeatures.find((feat) => feat.id === id);
    if (centerFeature) {
      centerSourceData = { type: 'FeatureCollection', features: [centerFeature] };
    }
  }
  return centerSourceData;
}

function getFlightAreasFeatures(mapHelper, id, withFligthId = true) {
  let areaSourceData = emptySourceData;
  if (mapHelper && id) {
    const { features: areaFeatures } = mapHelper.sourcesData[FLIGHT_AREAS_SOURCE_NAME];
    let flightAreasFeature = [];
    if (withFligthId) {
      flightAreasFeature = areaFeatures.filter((f) => f.properties.flight_id === id);
    } else {
      flightAreasFeature = areaFeatures.filter((f) => f.id === id);
    }
    if (flightAreasFeature.length) {
      areaSourceData = {
        type: 'FeatureCollection',
        features: flightAreasFeature,
      };
    }
  }
  return areaSourceData;
}

function initialState() {
  return {
    map_status: MAP_STATUS.READ,
    // flight centers around which the map is supposed to be centered.
    mapCenters: [],

    // bounding box of data that the map is supposed to be centered on.
    // Default value around France
    boundingBox: [[-1, 43], [4, 51]],

    boundingBoxIsUpdating: undefined,

    isMapDataLoaded: false,

    featureIdSelected: {
      flight: null, // Can be a flight, approval, activation or opinion
      trace: null,
    },

    featureIdHovered: {
      flight: null, // Can be a flight, approval, activation or opinion
      trace: null,
    },

    // lng lat of map point for which to get constraints
    contraintsPoint: null,

    // Id of client structure for which to get constraints
    contraintsClientStructureId: null,

    // used to store openned popups id for a given layer (key: layerId, value: list of popups ids)
    layerPopupIds: {},

    // area currently being edited
    currentArea: null,

    // drones last positions
    trackingPositions: [],

    // trackers last active alerts
    trackingAlerts: [],

    // flight exclusion zone markers
    exclusionZoneMarkers: {
      pilotPosition: {
        id: 'pilot-position',
        description: 'Position du télépilote',
        restrictToCurrentArea: false,
        icon: 'http://maps.google.com/mapfiles/kml/shapes/man.png',
        localIcon: `${process.env.BASE_URL}ZET/man.png`,
        anchor: 'center',
        mapMarkersCount: 0,
        updateForbiddenZone: true,
      },
      takeOffPoint: {
        id: 'take-off-point',
        description: 'Point de décollage',
        restrictToCurrentArea: true,
        icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-circle.png',
        localIcon: `${process.env.BASE_URL}ZET/grn-circle.png`,
        anchor: 'bottom',
        mapMarkersCount: 0,
        updateForbiddenZone: false,
      },
      landingPoint: {
        id: 'landing-point',
        description: "Point d'atterrissage",
        restrictToCurrentArea: true,
        icon: 'http://maps.google.com/mapfiles/kml/paddle/red-circle.png',
        localIcon: `${process.env.BASE_URL}ZET/red-circle.png`,
        anchor: 'bottom',
        mapMarkersCount: 0,
        updateForbiddenZone: false,
      },
    },

    // display forbidden zone during flight exclusion zone editing
    displayForbiddenZone: true,

    // buffer radius of pilots' markers
    pilotMarkerBufferRadius: null,

    // percentage of flight area in the forbidden zone during flight exclusion zone editing
    exclusionZoneForbiddenAreaPercentage: null,

    // Open dialog for network layers import
    showMobileNetworkLayersImportDialog: false,

    // Open dialog for geoportail layers import
    showGeoportailLayersImportDialog: false,

    // Open dialog for custom data layer import
    showLayerImportDialog: false,

    // Open edit geoportail or data layer popup when is not undefined
    editLayer: undefined,

    // Open edit custom data layers popup when is not undefined
    editCustomDataLayers: undefined,

    // Open delete layer popup when is not undefined
    deleteLayerGroup: undefined,

    // Determine if the update of the exclusion is from an action on the map (used for pilot
    // marker drag)
    exclusionZoneUpdatedFromMap: false,
  };
}

// will contain the mapbox helper instance
let mapHelper = null;

export default {
  namespaced: true,
  state: initialState,
  getters: {
    flightPopupId: (state) => (
      state.layerPopupIds[FLIGHT_CENTERS_SOURCE_NAME]
        ? state.layerPopupIds[FLIGHT_CENTERS_SOURCE_NAME][0] : null
    ),
    constraintsPointPopup: (state) => (
      state.layerPopupIds[CONSTRAINTS_POINT_POPUP]
        ? state.layerPopupIds[CONSTRAINTS_POINT_POPUP][0] : null
    ),
    constraintsClientStructurePopup: (state) => (
      state.layerPopupIds[CONSTRAINTS_CLIENT_STRUCTURE_POPUP]
        ? state.layerPopupIds[CONSTRAINTS_CLIENT_STRUCTURE_POPUP][0] : null
    ),
    tracePopup: (state) => (
      state.layerPopupIds[DRONE_TRACKING_TRACE_CENTERS_SOURCE_NAME]
        ? state.layerPopupIds[DRONE_TRACKING_TRACE_CENTERS_SOURCE_NAME][0] : null
    ),
    trackingPopups: (state) => state.layerPopupIds[DRONE_TRACKING_SOURCE_NAME],
    trackersCount: (state, getters, rootState, rootGetters) => {
      if (rootGetters['authentication/isUserAuthority']) {
        const authorityStructures = rootState.structures.items;
        if (authorityStructures.length) {
          const structuresFeatureCollection = turf.featureCollection(
            authorityStructures.map((s) => s.zones.features).flat(),
          );
          const trackerPoints = turf.points(
            state.trackingPositions.map((p) => [p.longitude, p.latitude]),
          );
          const trackersWithinAuthorityStructures = turf.pointsWithinPolygon(
            trackerPoints, structuresFeatureCollection,
          );
          return trackersWithinAuthorityStructures.features.length;
        }
        return undefined;
      }
      return undefined;
    },
    nbUnreadTrackingAlerts: (state) => state.trackingAlerts.filter((t) => t.read === false).length,
    mapMarkers: () => {
      if (mapHelper) {
        return mapHelper.getMapMarkers();
      }
      return null;
    },
    bufferRadiusPilotMarker: (state) => state.pilotMarkerBufferRadius,
  },
  mutations: {
    [RESET_MAP](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [SET_BOUNDING_BOX](state, newBoundingBox) {
      Vue.set(state, 'boundingBox', newBoundingBox);
    },
    [SET_BBOX_IS_UPDATING](state, value) {
      Vue.set(state, 'boundingBoxIsUpdating', value);
    },
    [SET_MAP_FEATURE_HOVERED](state, { featureId, key }) {
      Vue.set(state.featureIdHovered, key, featureId);
    },
    [SET_MAP_FEATURE_SELECTED](state, { featureId, key }) {
      Vue.set(state.featureIdSelected, key, featureId);
    },
    [SET_CONSTRAINTS_POINT](state, contraintsPoint) {
      Vue.set(state, 'contraintsPoint', contraintsPoint);
    },
    [SET_CONSTRAINTS_CLIENT_STRUCTURE_ID](state, id) {
      Vue.set(state, 'contraintsClientStructureId', id);
    },
    [SET_MAP_STATUS](state, newStatus) {
      Vue.set(state, 'map_status', newStatus);
    },
    [SET_MAP_AREA](state, newArea) {
      Vue.set(state, 'currentArea', newArea);
    },
    [SET_MAP_LOADED](state, isMapDataLoaded) {
      Vue.set(state, 'isMapDataLoaded', isMapDataLoaded);
    },
    [ADD_MAP_LAYER_POPUPS_ID](state, { layerId, popupId }) {
      if (!state.layerPopupIds[layerId]) {
        Vue.set(state.layerPopupIds, layerId, []);
      }
      const layerPopupIds = [...state.layerPopupIds[layerId]];
      layerPopupIds.push(popupId);
      Vue.set(state.layerPopupIds, layerId, layerPopupIds);
    },
    [REMOVE_MAP_LAYER_POPUPS_ID](state, { layerId, popupId }) {
      if (state.layerPopupIds[layerId]) {
        const index = state.layerPopupIds[layerId].findIndex((p) => p === popupId);
        if (index !== -1) {
          const layerPopupIds = [...state.layerPopupIds[layerId]];
          layerPopupIds.splice(index, 1);
          Vue.set(state.layerPopupIds, layerId, layerPopupIds);
        }
      }
    },
    [SET_DRONES_TRACKING_POSITIONS](state, features) {
      Vue.set(state, 'trackingPositions', features);
    },
    [SET_TRACKERS_ALERTS](state, data) {
      const { trackingAlerts } = state;
      const alertIds = trackingAlerts.map((trackingAlert) => trackingAlert.id);
      const newAlerts = data.filter((trackingAlert) => !alertIds.includes(trackingAlert.id));
      newAlerts.forEach((newAlert) => {
        trackingAlerts.unshift(newAlert);
      });
      Vue.set(state, 'trackingAlerts', trackingAlerts);
    },
    [MARK_ALERTS_READ](state, ids) {
      for (let i = 0; i < state.trackingAlerts.length; i += 1) {
        if (ids.includes(state.trackingAlerts[i].id)) {
          Vue.set(state.trackingAlerts[i], 'read', true);
        }
      }
    },
    [UPDATE_EXCLUSION_ZONE_MARKER_COUNT](state, { marker, value, reset = false }) {
      let count;
      if (reset) {
        count = 0;
      } else {
        count = marker.mapMarkersCount + value;
      }
      Vue.set(marker, 'mapMarkersCount', count);
    },
    [SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_RADIUS](state, bufferRadius) {
      Vue.set(state, 'pilotMarkerBufferRadius', bufferRadius);
    },
    [SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE](state, percentage) {
      Vue.set(state, 'exclusionZoneForbiddenAreaPercentage', percentage);
    },
    [SET_FLIGHT_EXCLUSION_DISPLAY_FORBIDDEN_ZONE](state, value) {
      Vue.set(state, 'displayForbiddenZone', value);
    },
    [SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP](state, value) {
      Vue.set(state, 'exclusionZoneUpdatedFromMap', value);
    },
    [SHOW_MOBILE_NETWORK_LAYERS_IMPORT_DIALOG](state, value) {
      Vue.set(state, 'showMobileNetworkLayersImportDialog', value);
    },
    [SHOW_GEOPORTAIL_LAYERS_IMPORT_DIALOG](state, value) {
      Vue.set(state, 'showGeoportailLayersImportDialog', value);
    },
    [SHOW_LAYER_IMPORT_DIALOG](state, value) {
      Vue.set(state, 'showLayerImportDialog', value);
    },
    [SHOW_LAYER_EDIT_DIALOG](state, value) {
      Vue.set(state, 'editLayer', value);
    },
    [SHOW_CUSTOM_DATA_LAYERS_EDIT_DIALOG](state, value) {
      Vue.set(state, 'editCustomDataLayers', value);
    },
    [SHOW_LAYER_DELETE_DIALOG](state, value) {
      Vue.set(state, 'deleteLayerGroup', value);
    },
  },
  actions: {
    [RESET_MAP]({ commit }) {
      commit(RESET_MAP);
    },
    [SET_BOUNDING_BOX]({ commit }, { boundingBox, fromGeom }) {
      let newBoundingBox;
      if (boundingBox === undefined) {
        newBoundingBox = computeBoundingBox(fromGeom);
      } else {
        newBoundingBox = { ...boundingBox };
      }
      if (mapHelper) {
        mapHelper.updateBoundingBox(newBoundingBox);
      }
      commit(SET_BOUNDING_BOX, newBoundingBox);
    },
    async [REFRESH_MAP_DATA]({ state, commit, dispatch, rootGetters, rootState }) {
      commit(SET_MAP_LOADED, false);
      const response = await APIService.getMapLayers();
      const { data } = response;
      const layerGroups = [];
      const layers = {};
      const sourcesData = {};
      data.layer_groups.forEach((layerGroup) => {
        const transformedLayerGroup = {};
        transformedLayerGroup.defaultVisibility = layerGroup.default_visibility;
        transformedLayerGroup.layerIds = layerGroup.layer_ids;
        transformedLayerGroup.displayPriority = layerGroup.display_priority;
        transformedLayerGroup.accessGranted = layerGroup.access_granted;
        transformedLayerGroup.category = layerGroup.category;
        transformedLayerGroup.description = layerGroup.description;
        transformedLayerGroup.identifier = layerGroup.identifier;
        transformedLayerGroup.name = layerGroup.name;
        transformedLayerGroup.editable = layerGroup.editable;
        transformedLayerGroup.deletable = layerGroup.deletable;
        transformedLayerGroup.status = layerGroup.status;
        transformedLayerGroup.opacity = layerGroup.opacity;
        layerGroups.push(transformedLayerGroup);
      });
      Object.keys(data.layers).forEach((layerId) => {
        const layer = data.layers[layerId];
        const transformedLayer = {};
        const group = layerGroups.find((g) => g.layerIds.includes(layerId));
        transformedLayer.name = layer.name;
        transformedLayer.layerType = layer.layer_type;
        transformedLayer.sourceType = layer.source_type;
        transformedLayer.sourceName = layer.source_name;
        transformedLayer.displayPriority = layer.display_priority;
        transformedLayer.description = layer.description;
        transformedLayer.visible = group.defaultVisibility;
        transformedLayer.url = layer.url;
        transformedLayer.thumbnailUrl = layer.thumbnail_url;
        transformedLayer.style = layer.style;
        transformedLayer.status = group.status;
        transformedLayer.opacity = group.opacity;
        transformedLayer.category = group.category;
        group.informationUrl = layer.information_url;
        layers[layerId] = transformedLayer;
      });

      data.custom_layers.forEach((customLayer) => {
        const customLayerVisibility = customLayer.default_visibility === true;

        // Make a layer for the polygons and link it to the group
        const polygonLayer = {};
        const polygonsLayerIdentifier = customLayer.identifier;
        polygonLayer.name = customLayer.name;
        polygonLayer.layerType = 'custom';
        polygonLayer.sourceType = 'geojson';
        polygonLayer.sourceName = customLayer.identifier;
        polygonLayer.displayPriority = customLayersDisplayPriority;
        polygonLayer.description = customLayer.description;
        polygonLayer.visible = customLayerVisibility;
        polygonLayer.styleIdentifier = CUSTOM_LAYER_AREA_STYLE_IDENTIFIER;
        polygonLayer.category = 'custom';
        layers[polygonsLayerIdentifier] = polygonLayer;

        // Make a layer for the polygons and link it to the group
        const outlineLayer = {};
        const outlineLayerIdentifier = formatCustomLayerOutlineIdentifier(customLayer.identifier);
        outlineLayer.name = customLayer.name;
        outlineLayer.layerType = 'custom';
        outlineLayer.sourceType = 'geojson';
        outlineLayer.sourceName = customLayer.identifier;
        outlineLayer.displayPriority = customLayersDisplayPriority + 1;
        outlineLayer.description = customLayer.description;
        outlineLayer.visible = customLayerVisibility;
        outlineLayer.styleIdentifier = CUSTOM_LAYER_OUTLINE_STYLE_IDENTIFIER;
        outlineLayer.category = 'custom';
        layers[outlineLayerIdentifier] = outlineLayer;

        // Make a layer for the labels and link it to the group
        const labelsLayer = {};
        const labelsLayerIdentifier = formatCustomLayerLabelsIdentifier(customLayer.identifier);
        labelsLayer.name = customLayer.name;
        labelsLayer.layerType = 'custom';
        labelsLayer.sourceType = 'geojson';
        labelsLayer.sourceName = customLayer.identifier;
        labelsLayer.displayPriority = customLayersDisplayPriority + 2;
        labelsLayer.description = customLayer.description;
        labelsLayer.visible = customLayerVisibility;
        labelsLayer.category = 'custom';
        if (customLayer.legend_center) {
          labelsLayer.styleIdentifier = LABELS_CENTER_POLYGON_STYLE_IDENTIFIER;
        } else {
          labelsLayer.styleIdentifier = LABELS_POLYGON_STYLE_IDENTIFIER;
        }
        layers[labelsLayerIdentifier] = labelsLayer;

        // build the source geojson from the areas property
        const sourceGeojson = {
          type: 'FeatureCollection',
          features: [],
        };
        customLayer.areas.forEach((customArea) => {
          const feature = {
            geometry: customArea.geometry,
            properties: {
              name: customArea.name,
              groupName: customLayer.name,
              fillColor: customLayer.fill_color_string,
              strokeColor: customLayer.stroke_color_string,
            },
            type: 'Feature',
          };
          sourceGeojson.features.push(feature);
        });

        // Also set up the data source for both layers
        sourcesData[polygonsLayerIdentifier] = sourceGeojson;
        sourcesData[outlineLayerIdentifier] = sourceGeojson;
        sourcesData[labelsLayerIdentifier] = sourceGeojson;

        // Make a layer group from the custom layer
        const transformedLayerGroup = {};
        transformedLayerGroup.defaultVisibility = customLayerVisibility;
        transformedLayerGroup.layerIds = [
          polygonsLayerIdentifier,
          outlineLayerIdentifier,
          labelsLayerIdentifier,
        ];
        transformedLayerGroup.displayPriority = customLayersDisplayPriority;
        transformedLayerGroup.category = 'custom';
        transformedLayerGroup.description = customLayer.description;
        transformedLayerGroup.identifier = customLayer.identifier;
        transformedLayerGroup.name = customLayer.name;
        layerGroups.push(transformedLayerGroup);
      });
      const availableMapsData = layerGroups.filter(
        (group) => (
          group.category === 'data'
          || group.category === 'custom'
          || group.category === 'custom_data'
          || group.category === 'geoportail_wmts'
          || group.category === 'tracking'
          || group.category === 'mobile_network'
        ),
      );
      const isUserDronist = rootGetters['authentication/isUserDronist'];
      const isSubscriptionActive = rootGetters['authentication/isSubscriptionActive'];
      const isUserEnterprise = rootGetters['authentication/isUserEnterprise'];
      const isUserGeneralist = rootGetters['authentication/isGeneralistPilotOrManager'];
      const isMobileBreakpoint = rootGetters['application/isMobileBreakpoint'];
      mapHelper = new MapHelper('map', {
        sourcesData,
        isUserDronist,
        isSubscriptionActive,
        isUserEnterprise,
        isUserGeneralist,
        isMobileBreakpoint,
        unitsDisplayed: () => rootGetters['structures/unitsDisplayed'],
        checkClientStructuresGeocodingAllowed: () => rootGetters[
          'exploitants/clientStructuresGeocodingAllowed'
        ],
        addPopupCallback: (layerId, popupId) => commit(
          ADD_MAP_LAYER_POPUPS_ID, { layerId, popupId },
        ),
        removePopupCallback: (layerId, popupId) => commit(
          REMOVE_MAP_LAYER_POPUPS_ID, { layerId, popupId },
        ),
        selectableLayerIds: Object.values(SELECTABLE_LAYERS),
        updateFeatureHovered: ({ featureId, layerId }) => dispatch(
          SET_MAP_FEATURE_HOVERED, { featureId, key: SELECTABLE_LAYERS_TO_FEATURE_KEY[layerId] },
        ),
        updateFeatureSelected: ({ featureId, layerId }) => dispatch(
          SELECT_OBJECT_FROM_FEATURE, { featureId, key: SELECTABLE_LAYERS_TO_FEATURE_KEY[layerId] },
        ),
        updateConstraintsPoint: (lngLat) => dispatch(SET_CONSTRAINTS_POINT, lngLat),
        updateOnSpecificMapClic: (id, coordinates) => dispatch(
          SET_CONSTRAINTS_CLIENT_STRUCTURE_ID, { id, coordinates },
        ),
        updateAreaFromClientStructure: (area) => {
          if (rootState.flights.simplifiedFlightFormOpen && area) {
            dispatch(SET_MAP_AREA, area);
            mapHelper.setArea(area);
          }
        },
        setMapDataLoaded: (isloaded) => dispatch(SET_MAP_LOADED, isloaded),
        updateArea: (area) => dispatch(SET_MAP_AREA, area),
        availableMaps: data.base_maps,
        availableMapsData,
        customLayers: data.custom_layers,
        clickableLayerNames: data.clickable_layers,
        setBoundingBoxIsUpdating: (value) => commit(SET_BBOX_IS_UPDATING, value),
        updateForbiddenZone: (flightArea) => dispatch(
          SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE,
          { flightArea, buffer: state.pilotMarkerBufferRadius, fromMap: true },
        ),
        updateMarkerCount: (id, value) => commit(
          UPDATE_EXCLUSION_ZONE_MARKER_COUNT,
          { marker: Object.values(state.exclusionZoneMarkers).find((m) => m.id === id), value },
        ),
        showMobileNetworkLayersImportDialog: () => commit(
          SHOW_MOBILE_NETWORK_LAYERS_IMPORT_DIALOG,
          true,
        ),
        showGeoportailLayersImportDialog: () => commit(SHOW_GEOPORTAIL_LAYERS_IMPORT_DIALOG, true),
        showLayerImportDialog: () => commit(SHOW_LAYER_IMPORT_DIALOG, true),
        openEditLayers: (value) => {
          if (['data', 'geoportail_wmts', 'mobile_network'].includes(value.category)) {
            commit(SHOW_LAYER_EDIT_DIALOG, value);
          } else if (value.category === 'custom_data') {
            commit(SHOW_CUSTOM_DATA_LAYERS_EDIT_DIALOG, value);
          }
        },
        openDeleteLayers: (value) => commit(SHOW_LAYER_DELETE_DIALOG, value),
        checkAreaCanBeDeleted: (feature) => dispatch(CHECK_AREA_CAN_BE_DELETED, { feature }),
      });
      // init some stuff
      mapHelper.setLayers(layers);

      const mobileNetworkLayers = mapHelper.layers.filter(
        (layer) => layer.category === 'mobile_network',
      );

      mapHelper.setLayerCategoryVisibility(
        'mobile_network',
        mobileNetworkLayers.map((layer) => layer.identifier),
      );

      if (state.boundingBox) {
        mapHelper.updateBoundingBox(state.boundingBox);
      }
      // handle window resize
      let fullScreenChange;
      if ('onfullscreenchange' in window.document) {
        fullScreenChange = 'fullscreenchange';
      } else if ('onmozfullscreenchange' in window.document) {
        fullScreenChange = 'mozfullscreenchange';
      } else if ('onwebkitfullscreenchange' in window.document) {
        fullScreenChange = 'webkitfullscreenchange';
      } else if ('onmsfullscreenchange' in window.document) {
        fullScreenChange = 'MSFullscreenChange';
      }
      function onFullScreenChange() {
        setTimeout(() => mapHelper.resize(), 1000);
      }
      window.document.addEventListener(fullScreenChange, onFullScreenChange);
      window.addEventListener('resize', onFullScreenChange);
      // set realtime tracking if activated
      if (rootGetters['authentication/trackingActivated']) {
        if (rootGetters['authentication/isUserAuthority']) {
          dispatch(GET_AUTHORITY_STRUCTURES_NS, {}, { root: true });
          dispatch('traces/GET_TRACES', {}, { root: true });
        }
        mapHelper._map.onLoad(() => {
          mapHelper.setLayerPopupOnClick({
            layerId: DRONE_TRACKING_SOURCE_NAME,
            propertyId: 'tracker_id',
          });
        });
      }
      // set map click event for showing constraints popups
      if (rootGetters['authentication/isUserDronist']) {
        mapHelper._map.onLoad(() => {
          mapHelper.setMapPointConstraintsEvents();
        });
      }
      if (rootGetters['authentication/isUserAuthority']) {
        dispatch(GET_COTATIONS_NS, {}, { root: true });
      }
    },
    [SET_MAP_LOADED]({ commit }, isloaded) {
      commit(SET_MAP_LOADED, isloaded);
    },
    [SET_LAYERS_DATA](ctx, { layersIds, data }) {
      if (mapHelper) {
        layersIds.forEach((layerId) => {
          mapHelper.updateSourcesData(layerId, data);
        });
      }
    },
    [SET_TRACKERS_ALERTS]({ commit }, data) {
      commit(SET_TRACKERS_ALERTS, data);
    },
    [FORMAT_DRONES_LAST_POSITION]({ commit, dispatch }, geojson) {
      // Group positions by tracker device id
      const featuresByTracker = geojson.features.reduce((result, feature) => {
        const trackerId = feature.properties.tracker_id;
        const trackerFeatures = result.find((e) => e.trackerId === trackerId);
        if (!trackerFeatures) {
          result.push({ trackerId, features: [feature] });
        } else {
          trackerFeatures.features.push(feature);
        }
        return result;
      }, []);
      const lastPositions = featuresByTracker.map((e) => {
        // Only keep last position for each tracker
        const feature = { ...e.features[0] };
        feature.properties.tracker_id = `tracker-${feature.properties.tracker_id}`;
        const { altitude } = feature.properties;
        feature.properties.altitude_map = (
          altitude
            ? `${Math.round(altitude * 3.2808)} ft AMSL`
            : ''
        );
        return {
          ...feature,
          // Smooth altitude differences on last five positions
          direction_z: compareFeaturesAltitudes(e.features.slice(0, 4)),
        };
      });
      commit(SET_DRONES_TRACKING_POSITIONS, lastPositions.map((f) => (
        { id: f.id, direction_z: f.direction_z, ...f.properties }
      )));
      dispatch(SET_DRONES_TRACKING, { type: 'FeatureCollection', features: lastPositions });
      // Update traces
      dispatch('traces/UPDATE_TRACES', lastPositions.map((f) => ({ ...f.properties })), { root: true });
    },
    [SET_DRONES_TRACKING]({ dispatch, rootGetters }, data) {
      if (mapHelper) {
        const currentLivestreams = rootGetters['livestreams/currentLivestreams'];
        const dronesWithLivestreams = currentLivestreams.map((live) => live.drone_id);
        if (dronesWithLivestreams.length) {
          data.features.forEach((tracker) => {
            if (dronesWithLivestreams.includes(tracker.properties.drone_id)) {
              const track = tracker;
              track.properties.has_livestream = true;
            }
          });
        }
        dispatch(
          SET_LAYERS_DATA,
          { layersIds: [DRONE_TRACKING_SOURCE_NAME, DRONE_TRACKING_DIRECTION_SOURCE_NAME], data },
        );
        mapHelper.moveOpenedLayerPopups({
          layerId: DRONE_TRACKING_SOURCE_NAME,
          propertyId: 'tracker_id',
          newfeatures: data.features,
        });
      }
    },
    [MARK_ALERTS_READ]({ commit }, { structureId, ids }) {
      commit(MARK_ALERTS_READ, ids);
      APIService.markTrackingAlertsRead(structureId, ids);
    },
    [OPEN_DRONES_TRACKING_POPUP]({ getters, dispatch }, trackerIds) {
      const layer = mapHelper?.sourcesData[DRONE_TRACKING_SOURCE_NAME];
      if (layer) {
        const areaExtent = [];
        const { features } = layer;
        trackerIds.forEach((trackerId) => {
          if (getters.trackingPopups?.includes(trackerId)) return;
          const feature = features.find((f) => f.properties?.tracker_id === trackerId);
          if (feature) {
            mapHelper.toggleLayerMultiplePopups(
              {
                layerId: DRONE_TRACKING_SOURCE_NAME,
                featureId: trackerId,
                coordinates: feature.geometry.coordinates,
              },
            );
            areaExtent.push(feature.geometry);
          }
        });
        if (areaExtent.length) {
          const multiPoints = turf.multiPoint(areaExtent.map((area) => area.coordinates));
          const bufferedPoints = turf.buffer(multiPoints, 100, { units: 'meters' });
          dispatch(ZOOM_TO_AREA, bufferedPoints);
        }
      }
    },
    [FORMAT_PLANES_LAST_POSITION]({ dispatch }, geojson) {
      // Group positions by plane device id
      const featuresByPlane = geojson.features.reduce((result, feature) => {
        const deviceId = feature.properties.device_id;
        const deviceFeatures = result.find((e) => e.deviceId === deviceId);
        if (!deviceFeatures) {
          // eslint-disable-next-line no-param-reassign
          result.push({ deviceId, features: [feature] });
        } else {
          deviceFeatures.features.push(feature);
        }
        return result;
      }, []);
      const lastPositions = featuresByPlane.map((e) => {
        // Only keep last position for each plane
        const feature = { ...e.features[0] };
        const { altitude } = feature.properties;
        feature.properties.altitude_map = (
          altitude
            ? `${Math.round(altitude * 3.2808)} ft AMSL`
            : ''
        );
        return feature;
      });
      dispatch(SET_PLANES_TRACKING, { type: 'FeatureCollection', features: lastPositions });
    },
    [SET_PLANES_TRACKING]({ dispatch }, data) {
      if (mapHelper) {
        dispatch(
          SET_LAYERS_DATA,
          { layersIds: [PLANE_TRACKING_SOURCE_NAME], data },
        );
      }
    },
    [SET_CTR_AREAS]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [CTR_AREAS_SOURCE_NAME], data });
    },
    [SET_CTR_AREAS_ONLY_FOR_SUBSCRIBERS]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [CTR_AREAS_ONLY_FOR_SUBSCRIBERS_SOURCE_NAME], data });
    },
    [SET_FLIGHT_AREAS]({ dispatch }, data) {
      dispatch(
        SET_LAYERS_DATA,
        { layersIds: [FLIGHT_AREAS_SOURCE_NAME, FLIGHT_AREAS_OUTLINE_SOURCE_NAME], data },
      );
    },
    [SET_CONSTRAINT_HOVER]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [CONSTRAINT_HOVER_SOURCE_NAME], data });
    },
    [SET_CONSTRAINT_SELECTED]({ dispatch }, data) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [CONSTRAINT_SELECTED_SOURCE_NAME, CONSTRAINT_SELECTED_LABEL_SOURCE_NAME],
          data,
        },
      );
    },
    [SET_NOTAM_SELECTED]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [NOTAM_SELECTED_SOURCE_NAME], data });
    },
    [SET_FLIGHT_AREAS_HOVER]({ dispatch }, data) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [FLIGHT_AREAS_HOVER_SOURCE_NAME, FLIGHT_AREAS_OUTLINE_HOVER_SOURCE_NAME],
          data,
        },
      );
    },
    [SET_FLIGHT_AREAS_SELECTED]({ dispatch }, data) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [
            FLIGHT_AREAS_SELECTED_SOURCE_NAME,
            FLIGHT_AREAS_OUTLINE_SELECTED_SOURCE_NAME,
          ],
          data,
        },
      );
    },
    [SET_FLIGHT_AREAS_NAME]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [FLIGHT_AREAS_SELECTED_SOURCE_NAME_LABEL], data });
      dispatch(
        SET_LAYERS_DATA, { layersIds: [FLIGHT_AREAS_SELECTED_SOURCE_NAME_HEIGHT_LABEL], data },
      );
    },
    [SET_FLIGHT_AREAS_MEASUREMENTS_INFO]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [FLIGHT_AREA_MEASUREMENTS_INFO_SOURCE_NAME], data });
    },
    [SET_FLIGHT_CENTERS]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [FLIGHT_CENTERS_SOURCE_NAME], data });
    },
    [SET_FLIGHT_CENTERS_HOVER]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [FLIGHT_CENTERS_HOVER_SOURCE_NAME], data });
    },
    [SET_FLIGHT_CENTERS_SELECTED]({ dispatch }, data) {
      dispatch(SET_LAYERS_DATA, { layersIds: [FLIGHT_CENTERS_SELECTED_SOURCE_NAME], data });
    },
    [SET_FLIGHT_AREA_HOVER]({ dispatch }, areaId) {
      const areaHoverSourceData = getFlightAreasFeatures(mapHelper, areaId, false);
      dispatch(SET_FLIGHT_AREA_SELECTED_HOVER, areaHoverSourceData);
    },
    [SET_FLIGHT_HOVER]({ dispatch, state }, { flightId }) {
      if (mapHelper && state.isMapDataLoaded) {
        if (flightId && state.idSelected === flightId) {
          return;
        }
        // CENTERS
        const centerHoverSourceData = getFlightCenterFeature(mapHelper, flightId);
        dispatch(SET_FLIGHT_CENTERS_HOVER, centerHoverSourceData);
        // AREAS
        const areaHoverSourceData = getFlightAreasFeatures(mapHelper, flightId);
        dispatch(SET_FLIGHT_AREAS_HOVER, areaHoverSourceData);
      }
    },
    [SET_FLIGHT_SELECTED]({ dispatch }, { flightId }) {
      if (mapHelper) {
        dispatch(SET_FLIGHT_HOVER, { flightId: null });
        // CENTERS
        const centerSelectedSourceData = getFlightCenterFeature(mapHelper, flightId);
        dispatch(SET_FLIGHT_CENTERS_SELECTED, centerSelectedSourceData);
        // AREAS
        const areaSelectedSourceData = getFlightAreasFeatures(mapHelper, flightId);
        dispatch(SET_FLIGHT_AREAS_SELECTED, areaSelectedSourceData);
        if (
          areaSelectedSourceData.features.length > 1
          || areaSelectedSourceData.features.length === 0
        ) {
          dispatch(SET_FLIGHT_AREAS_NAME, areaSelectedSourceData);
        }
      }
    },
    [SET_FLIGHT_AREA_SELECTED_HOVER]({ dispatch }, data) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [
            FLIGHT_AREA_SELECTED_HOVER_SOURCE_NAME,
            FLIGHT_AREA_SELECTED_OUTLINE_HOVER_SOURCE_NAME,
          ],
          data,
        },
      );
    },
    [SET_APPROVAL_FLIGHT_HOVER]({ dispatch, state }, { flightAreasIds }) {
      if (mapHelper && state.isMapDataLoaded) {
        let areaHoverSourceData = emptySourceData;
        if (flightAreasIds.length) {
          const features = [];
          flightAreasIds.forEach((id) => {
            const flightFeatures = getFlightAreasFeatures(mapHelper, id, false);
            flightFeatures.features.forEach((feature) => {
              features.push(feature);
            });
          });
          if (features.length) {
            areaHoverSourceData = {
              type: 'FeatureCollection',
              features,
            };
          }
        }
        dispatch(SET_FLIGHT_AREA_SELECTED_HOVER, areaHoverSourceData);
      }
    },
    [SET_APPROVAL_FLIGHT_SELECTED]({ dispatch }, data) {
      if (data.features.length) {
        dispatch(SET_FLIGHT_SELECTED, { flightId: null });
      }
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [
            APPROVAL_AREAS_SELECTED_SOURCE_NAME,
            APPROVAL_AREAS_SELECTED_OUTLINE_SOURCE_NAME,
          ],
          data,
        },
      );
      if (data.features.length) {
        dispatch(SET_FLIGHT_AREAS_NAME, data);
      }
    },
    [SET_APPROVAL_FLIGHT_AREAS_HOVER]({ dispatch, rootGetters }, areaIds) {
      const { features } = rootGetters['flights/mapApprovalAreas'];
      const flightAreas = features.filter((f) => areaIds.includes(f.id));
      dispatch(SET_FLIGHT_AREAS_HOVER, { type: 'FeatureCollection', features: flightAreas });
    },
    [SET_APPROVAL_HOVER]({ dispatch, state }, { approvalId }) {
      if (mapHelper && state.isMapDataLoaded) {
        // CENTERS
        const { features: centerFeatures } = mapHelper.sourcesData[FLIGHT_CENTERS_SOURCE_NAME];
        const centerFeature = centerFeatures.filter((feat) => feat.id === approvalId);
        dispatch(
          SET_FLIGHT_CENTERS_HOVER,
          { type: 'FeatureCollection', features: centerFeature },
        );
        // AREAS
        const { features: areaFeatures } = mapHelper.sourcesData[FLIGHT_AREAS_SOURCE_NAME];
        const approvalAreasFeature = areaFeatures.filter(
          (f) => f.properties.approval_id === approvalId,
        );
        dispatch(
          SET_FLIGHT_AREAS_HOVER,
          { type: 'FeatureCollection', features: approvalAreasFeature },
        );
      }
    },
    [SET_APPROVAL_SELECTED]({ dispatch, rootGetters }, data) {
      if (mapHelper) {
        const approvalSelected = rootGetters['approvals/approvalSelected'];
        dispatch(
          SET_APPROVAL_OR_ACTIVATION_SELECTED,
          { approvalOrActivationSelected: approvalSelected, data },
        );
      }
    },
    [SET_ACTIVATION_SELECTED]({ dispatch, rootGetters }, data) {
      if (mapHelper) {
        const activationSelected = rootGetters['activations/activationSelected'];
        dispatch(
          SET_APPROVAL_OR_ACTIVATION_SELECTED,
          { approvalOrActivationSelected: activationSelected, data },
        );
      }
    },
    [SET_APPROVAL_OR_ACTIVATION_SELECTED]({ dispatch }, { approvalOrActivationSelected, data }) {
      let center = { type: 'FeatureCollection', features: [] };
      if (approvalOrActivationSelected) {
        // Remove popup on hover
        dispatch(
          SET_MAP_FEATURE_HOVERED,
          { featureId: null, key: SELECTABLE_FEATURE_KEYS.flight },
        );
        // Compute center and set properties for authorities
        center = turf.center(data);
        center.properties = {
          is_authority: true,
          status: approvalOrActivationSelected.status,
        };
      }
      dispatch(
        SET_LAYERS_DATA,
        { layersIds: [FLIGHT_CENTERS_SELECTED_SOURCE_NAME], data: center },
      );
      // AREAS
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [
            APPROVAL_AREAS_SELECTED_SOURCE_NAME,
            APPROVAL_AREAS_SELECTED_OUTLINE_SOURCE_NAME,
          ],
          data,
        },
      );
      dispatch(SET_FLIGHT_AREAS_NAME, data);
    },
    [SET_APPROVAL_AREA_HOVERS]({ dispatch, rootGetters }, areaIds) {
      const { features } = rootGetters['approvals/approvalArea'];
      const areas = features.filter((f) => areaIds.includes(f.id));
      dispatch(SET_FLIGHT_AREAS_HOVER, { type: 'FeatureCollection', features: areas });
    },
    [SET_OPINION_SELECTED]({ dispatch, rootGetters }, data) {
      if (mapHelper) {
        const opinionSelected = rootGetters['opinions/opinionSelected'];
        let center = { type: 'FeatureCollection', features: [] };
        if (opinionSelected) {
          // Remove popup on hover
          dispatch(
            SET_MAP_FEATURE_HOVERED,
            { featureId: null, key: SELECTABLE_FEATURE_KEYS.flight },
          );
          // Get center and set properties for authorities
          center = {
            type: 'Feature',
            id: opinionSelected.id,
            properties: {
              status: opinionSelected.status,
              company_name: opinionSelected.approval.company_name,
              display_identifier: opinionSelected.approval.display_identifier,
              is_closed: opinionSelected.approval.is_closed,
            },
            geometry: opinionSelected.approval.center,
          };
        }
        dispatch(
          SET_LAYERS_DATA,
          { layersIds: [FLIGHT_CENTERS_SELECTED_SOURCE_NAME], data: center },
        );
        // AREAS
        dispatch(
          SET_LAYERS_DATA,
          {
            layersIds: [
              APPROVAL_AREAS_SELECTED_SOURCE_NAME,
              APPROVAL_AREAS_SELECTED_OUTLINE_SOURCE_NAME,
            ],
            data,
          },
        );
        dispatch(SET_FLIGHT_AREAS_NAME, data);
      }
    },
    [SET_MAP_DRAW_AREA](ctx, area) {
      if (mapHelper) {
        mapHelper.setArea(area);
      }
    },
    [SELECT_MAP_DRAW_AREA](ctx, areaId) {
      if (mapHelper) {
        mapHelper.selectArea(areaId);
      }
    },
    [DELETE_MAP_DRAW_AREA](ctx, areaId) {
      if (mapHelper) {
        mapHelper.deleteArea(areaId);
      }
    },
    [SET_FLIGHT_EXCLUSION_ZONE]({ dispatch }, data) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [
            FLIGHT_EXCLUSION_LAYER_SOURCE_NAME,
            FLIGHT_EXCLUSION_OUTLINE_LAYER_SOURCE_NAME,
          ],
          data: data || emptySourceData,
        },
      );
    },
    [SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE]({ commit }, percentage) {
      commit(SET_FLIGHT_EXCLUSION_FORBIDDEN_AREAS_PERCENTAGE, percentage);
    },
    [ADD_EXCLUSION_ZONE_MARKER]({ state, commit }, { markerId, point }) {
      if (mapHelper) {
        const marker = Object.values(state.exclusionZoneMarkers).find((m) => m.id === markerId);
        if (marker) {
          mapHelper.addMarker(
            marker.id,
            marker.localIcon,
            marker.anchor,
            point,
            marker.restrictToCurrentArea,
            marker.updateForbiddenZone,
          );
          commit(UPDATE_EXCLUSION_ZONE_MARKER_COUNT, { marker, value: 1 });
        }
      }
    },
    [REMOVE_LAST_EXCLUSION_ZONE_MARKER]({ state, commit }, { markerId }) {
      if (mapHelper) {
        const marker = Object.values(state.exclusionZoneMarkers).find((m) => m.id === markerId);
        if (marker) {
          mapHelper.removeLastMarker(marker.id);
          commit(UPDATE_EXCLUSION_ZONE_MARKER_COUNT, { marker, value: -1 });
        }
      }
    },
    [CLEAR_EXCLUSION_ZONE_MARKERS]({ state, commit }) {
      if (mapHelper) {
        Object.values(state.exclusionZoneMarkers).forEach((marker) => {
          mapHelper.removeAllMarkers(marker.id);
          commit(UPDATE_EXCLUSION_ZONE_MARKER_COUNT, { marker, reset: true });
        });
      }
    },
    [SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES]({ dispatch }, { lines, centers }) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_SOURCE_NAME],
          data: lines || emptySourceData,
        },
      );
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_CENTERS_SOURCE_NAME],
          data: centers || emptySourceData,
        },
      );
    },
    [SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE](
      { dispatch, commit, getters },
      { flightArea, buffer = null, fromMap = false },
    ) {
      if (mapHelper) {
        const markers = mapHelper.getMapMarkers();
        if (flightArea && buffer && markers['pilot-position'] && markers['pilot-position'].length) {
          commit(SET_FLIGHT_EXCLUSION_FORBIDDEN_ZONE_RADIUS, buffer);
          const unionBufferPilots = bufferPilots(
            markers['pilot-position'], getters.bufferRadiusPilotMarker,
          );
          const flightAreaPolygon = flightAreaToPolygon(flightArea, fromMap);
          const difference = turf.difference(flightAreaPolygon, unionBufferPilots);
          commit(SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP, fromMap);
          if (difference === null) {
            commit(SET_FLIGHT_EXCLUSION_DISPLAY_FORBIDDEN_ZONE, false);
            dispatch(
              SET_LAYERS_DATA,
              {
                layersIds: [
                  FLIGHT_EXCLUSION_FORBIDDEN_ZONE_LAYER,
                  FLIGHT_EXCLUSION_FORBIDDEN_ZONE_OUTLINE_LAYER,
                ],
                data: emptySourceData,
              },
            );
          } else {
            commit(SET_FLIGHT_EXCLUSION_DISPLAY_FORBIDDEN_ZONE, true);
            dispatch(
              SET_LAYERS_DATA, {
                layersIds: [
                  FLIGHT_EXCLUSION_FORBIDDEN_ZONE_LAYER,
                  FLIGHT_EXCLUSION_FORBIDDEN_ZONE_OUTLINE_LAYER,
                ],
                data: difference,
              },
            );
          }
        } else {
          commit(SET_FLIGHT_EXCLUSION_DISPLAY_FORBIDDEN_ZONE, false);
          dispatch(
            SET_LAYERS_DATA,
            {
              layersIds: [
                FLIGHT_EXCLUSION_FORBIDDEN_ZONE_LAYER,
                FLIGHT_EXCLUSION_FORBIDDEN_ZONE_OUTLINE_LAYER,
              ],
              data: emptySourceData,
            },
          );
        }
      }
    },
    [SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP]({ commit }, newValue) {
      commit(SET_FLIGHT_EXCLUSION_UPDATED_FROM_MAP, newValue);
    },
    [HIDE_FLIGHTS]() {
      if (mapHelper) {
        mapHelper.hideFlights();
      }
    },
    [SHOW_FLIGHTS]() {
      if (mapHelper) {
        mapHelper.showFlights();
      }
    },
    [SET_COTATIONS]({ dispatch }, cotations) {
      let cotationsGeojson = emptySourceData;
      if (
        cotations !== undefined
        && cotations.features !== undefined
        && cotations.features.length > 0
      ) {
        cotationsGeojson = {
          type: 'FeatureCollection',
          features: cotations.features,
        };
      }
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [PSA_ELEVATIONS_SOURCE_NAME, PSA_OUTLINES_SOURCE_NAME],
          data: cotationsGeojson,
        },
      );
    },
    [RESIZE_MAP]() {
      if (mapHelper) {
        mapHelper.resize();
      }
    },
    async [SET_MAP_STATUS]({ dispatch, commit, state, rootState }, newStatus) {
      try {
        if (mapHelper) {
          switch (newStatus) {
            case MAP_STATUS.READ:
              if (!(state.map_status === MAP_STATUS.READ)) {
                await mapHelper.setMapMode({
                  mode: MODE.READ,
                  tab: rootState.application.activeTab,
                });
              }
              break;
            default:
              await mapHelper.setMapMode({
                mode: MODE.WRITE,
                tab: rootState.application.activeTab,
              });
              mapHelper.setArea(state.currentArea);
          }
        }
        commit(SET_MAP_STATUS, newStatus);
      } catch (error) {
        dispatch(
          SET_SNACKBAR_MESSAGE_NS,
          {
            message: `Une erreur imprévue s'est produite au chargement de la carte. Veuillez
              actualiser la page web.`,
            color: 'error',
          },
          { root: true },
        );
        dispatch(SHOW_SNACKBAR_NS, null, { root: true });
        throw error;
      }
    },
    [SET_MAP_AREA]({ commit }, newArea) {
      commit(SET_MAP_AREA, newArea);
      if (mapHelper) {
        mapHelper.moveLayer(FLIGHT_AREAS_SELECTED_SOURCE_NAME_LABEL);
      }
    },
    [SELECT_OBJECT_FROM_FEATURE]({ state, dispatch, rootGetters }, { featureId, key }) {
      if (rootGetters['applications/status'] !== APPLICATION_STATUS.CREATE) {
        dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ, { root: true });
      }
      let featureToSelectId = featureId;
      // Unselect object if it's the same
      if (state.featureIdSelected[key] === featureId) {
        featureToSelectId = null;
      }
      // Select corresponding listing object
      if (key === SELECTABLE_FEATURE_KEYS.flight) {
        dispatch(SELECT_OBJECT_FROM_FLIGHT_FEATURE, featureToSelectId);
      } else if (key === SELECTABLE_FEATURE_KEYS.trace) {
        dispatch('traces/SET_TRACE_SELECTED', featureToSelectId, { root: true });
      } else {
        throw Error('Unkown selectable layer. Unable to select feature.');
      }
    },
    [SELECT_OBJECT_FROM_FLIGHT_FEATURE]({ dispatch, rootGetters }, featureId) {
      if (
        rootGetters['authentication/isUserDronist']
        || rootGetters['authentication/isDronistUSSP']
      ) {
        if (!rootGetters['flights/isTableItemsLoading']) {
          dispatch(
            SET_FLIGHT_APPROVAL_ID_SELECTED_NS,
            { flightId: null, approvalId: null },
            { root: true },
          );
          dispatch(
            SET_FLIGHT_SELECTED_NS,
            { flightId: featureId, reload: featureId !== null },
            { root: true },
          );
        }
      } else if (rootGetters['authentication/isUserAuthority']) {
        if (rootGetters['application/activeTab'] === APPLICATION_TAB.ACTIVATION) {
          dispatch('activations/SET_ACTIVATION_SELECTED', featureId, { root: true });
        } else if (!rootGetters['approvals/isTableItemsLoading']) {
          if (featureId == null && !rootGetters['authentication/isAuthorityFullScreenInterface']) {
            dispatch(SET_APPROVAL_SELECTED_NS, featureId, { root: true });
          } else {
            dispatch(SET_APPROVAL_SELECTED_NS, featureId, { root: true });
          }
        }
      } else if (rootGetters['authentication/isUserVisitor']) {
        dispatch(SET_OPINION_SELECTED_NS, featureId, { root: true });
      } else {
        throw Error('Unkown user type. Unable to select feature.');
      }
    },
    [SET_MAP_FEATURE_SELECTED]({ commit, dispatch, rootGetters }, { featureId, key }) {
      commit(SET_MAP_FEATURE_SELECTED, { featureId, key });
      if (rootGetters['authentication/isUserDronist']) {
        dispatch(SET_FLIGHT_HOVER, { flightId: null });
      }
    },
    [SET_MAP_FEATURE_HOVERED]({ state, commit }, { featureId, key }) {
      if (mapHelper && state.isMapDataLoaded) {
        if (state.featureIdHovered[key] !== featureId) {
          commit(SET_MAP_FEATURE_HOVERED, { featureId, key });
          const layerId = SELECTABLE_LAYERS[key];
          const feature = mapHelper.getFeatureById(layerId, featureId);
          mapHelper.toggleLayerSinglePopup({
            layerId,
            featureId: feature ? feature.id : undefined,
            coordinates: feature ? feature.geometry.coordinates : undefined,
          });
        }
      }
    },
    [SET_CONSTRAINTS_POINT]({ state, commit, rootGetters }, contraintsPoint) {
      if (state.contraintsPoint !== contraintsPoint && mapHelper) {
        commit(SET_CONSTRAINTS_POINT, contraintsPoint);
        if (!rootGetters['application/isMobileBreakpoint']) {
          mapHelper.toggleLayerSinglePopup({
            layerId: CONSTRAINTS_POINT_POPUP,
            featureId: contraintsPoint ? CONSTRAINTS_POINT_POPUP : undefined,
            coordinates: contraintsPoint || undefined,
            withMarker: true,
            movable: false,
          });
        } else if (contraintsPoint) {
          commit(
            ADD_MAP_LAYER_POPUPS_ID,
            { popupId: CONSTRAINTS_POINT_POPUP, layerId: CONSTRAINTS_POINT_POPUP },
          );
        }
      }
    },
    [SET_CONSTRAINTS_CLIENT_STRUCTURE_ID]({ commit, rootGetters }, { id, coordinates }) {
      commit(SET_CONSTRAINTS_CLIENT_STRUCTURE_ID, id);
      if (!rootGetters['application/isMobileBreakpoint']) {
        mapHelper.toggleLayerSinglePopup({
          layerId: CONSTRAINTS_CLIENT_STRUCTURE_POPUP,
          featureId: id,
          coordinates: coordinates || undefined,
        });
      } else if (id) {
        commit(
          ADD_MAP_LAYER_POPUPS_ID,
          {
            popupId: CONSTRAINTS_CLIENT_STRUCTURE_POPUP,
            layerId: CONSTRAINTS_CLIENT_STRUCTURE_POPUP,
          },
        );
      }
    },
    [REFRESH_LAYER_POP_UP](ctx, { layerId, popupId }) {
      if (mapHelper) {
        mapHelper.refreshLayerPopup({ layerId, popupId });
      }
    },
    [ZOOM_TO_CENTER](ctx, center) {
      if (center && center.coordinates && mapHelper) {
        const { coordinates } = center;
        mapHelper.zoomTo([coordinates[0], coordinates[1]], 12);
      }
    },
    [ZOOM_TO_AREA]({ dispatch }, area) {
      if (area && mapHelper) {
        const bboxCoords = turf.bbox(area);
        const bbox = [
          [bboxCoords[0], bboxCoords[1]],
          [bboxCoords[2], bboxCoords[3]],
        ];
        dispatch(SET_BOUNDING_BOX, { boundingBox: bbox });
      }
    },
    [SET_LOADED_KML]({ dispatch }, { features, multiple = false }) {
      if (mapHelper) {
        let geojson = null;
        if (multiple) {
          geojson = turf.featureCollection(features);
        } else {
          geojson = features[0].geometry;
        }
        // Remove z coordinates if necessary
        geojson = turf.truncate(geojson, { coordinates: 2 });
        mapHelper.setArea(geojson);
        const bboxCoords = turf.bbox(geojson);
        const bbox = [
          [bboxCoords[0], bboxCoords[1]],
          [bboxCoords[2], bboxCoords[3]],
        ];
        dispatch(SET_BOUNDING_BOX, { boundingBox: bbox });
      }
    },
    [SHOW_CUSTOM_LAYER](ctx, { layerId }) {
      if (mapHelper) {
        mapHelper.showCustomLayer(layerId);
      }
    },
    [HIDE_CUSTOM_LAYER](ctx, { layerId }) {
      if (mapHelper) {
        mapHelper.hideCustomLayer(layerId);
      }
    },
    [DISABLE_CUSTOM_LAYER](ctx, { layerId }) {
      if (mapHelper) {
        mapHelper.disableCustomLayer(layerId);
      }
    },
    [ENABLE_CUSTOM_LAYER](ctx, { layerId }) {
      if (mapHelper) {
        mapHelper.enableCustomLayer(layerId);
      }
    },
    [CHANGE_DRAW_LAYER_STYLE](ctx, { fillColor, strokeColor }) {
      if (mapHelper) {
        if (fillColor) {
          mapHelper.setDrawLayerStyleProperty(
            { property: 'fillColor', value: fillColor },
          );
        }
        if (strokeColor) {
          mapHelper.setDrawLayerStyleProperty(
            { property: 'strokeColor', value: strokeColor },
          );
        }
      }
    },
    async [CHECK_AREA_CAN_BE_DELETED]({ commit, dispatch, rootGetters }, { feature }) {
      if (
        rootGetters['authentication/isUserDronist']
        && rootGetters['flights/updatingFlight']
        && rootGetters['flights/updatingFlight'].approvals?.length
        && feature.properties?.id
      ) {
        commit(SET_LOADING_SNACKBAR_NS, true, { root: true });
        const response = await APIService.checkAreaCanBeDeleted(
          rootGetters['flights/updatingFlight'].id,
          feature.properties.id,
        );
        if (response.status === 200) {
          const { data: canBeDeleted } = response;
          if (!canBeDeleted) {
            const message = `Vous ne pouvez pas supprimer la dernière zone liée à une demande
              d'accord.`;
            dispatch(
              SET_SNACKBAR_MESSAGE_NS,
              {
                message,
                color: 'error',
              },
              { root: true },
            );
            dispatch(SHOW_SNACKBAR_NS, null, { root: true });
          }
          commit(SET_LOADING_SNACKBAR_NS, false, { root: true });
          return canBeDeleted;
        }
        commit(SET_LOADING_SNACKBAR_NS, false, { root: true });
      }
      return true;
    },
    [SHOW_TRACES]() {
      if (mapHelper) {
        mapHelper.showTraces();
      }
    },
    [SET_TRACES_CENTERS]({ dispatch }, traces) {
      const tracesGeojson = traces || emptySourceData;
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [
            DRONE_TRACKING_TRACE_CENTERS_SOURCE_NAME,
            DRONE_TRACKING_TRACE_LABELS_SOURCE_NAME,
          ],
          data: tracesGeojson,
        },
      );
    },
    [SET_TRACES_LINES]({ dispatch }, traces) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [DRONE_TRACKING_TRACE_LINES_SOURCE_NAME],
          data: traces,
        },
      );
    },
    [SET_TRACES_DASH_LINES]({ dispatch }, traces) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [DRONE_TRACKING_TRACE_DASH_LINES_SOURCE_NAME],
          data: traces,
        },
      );
    },
    [SET_TRACES_DASH_LINES_SELECTED]({ dispatch }, traces) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [DRONE_TRACKING_TRACE_DASH_LINES_SELECTED_SOURCE_NAME],
          data: traces,
        },
      );
    },
    [SET_TRACE_HOVER_OR_SELECTED]({ dispatch, state }, traceId) {
      if (mapHelper && state.isMapDataLoaded) {
        const idsToSelect = traceId ? [traceId] : [];
        if (state.featureIdSelected.trace !== traceId) {
          idsToSelect.push(state.featureIdSelected.trace);
        }
        // CENTERS
        const { features: centerFeatures } = mapHelper.sourcesData[
          DRONE_TRACKING_TRACE_CENTERS_SOURCE_NAME
        ];
        const selectedCenters = centerFeatures.filter((f) => idsToSelect.includes(f.id));
        dispatch(
          SET_LAYERS_DATA,
          {
            layersIds: [DRONE_TRACKING_TRACE_CENTERS_SELECTED_SOURCE_NAME],
            data: { type: 'FeatureCollection', features: selectedCenters },
          },
        );
        // LINES
        const { features: linesFeatures } = mapHelper.sourcesData[
          DRONE_TRACKING_TRACE_LINES_SOURCE_NAME
        ];
        const selectedLines = linesFeatures.filter((f) => idsToSelect.includes(f.id));
        dispatch(
          SET_LAYERS_DATA,
          {
            layersIds: [
              DRONE_TRACKING_TRACE_LINES_SELECTED_SOURCE_NAME,
              DRONE_TRACKING_TRACE_POINTS_SELECTED_SOURCE_NAME,
            ],
            data: { type: 'FeatureCollection', features: selectedLines },
          },
        );
      }
    },
    [SET_STRUCTURES_AIP_GEOMETRIES]({ dispatch }, featuresCollection) {
      dispatch(
        SET_LAYERS_DATA,
        {
          layersIds: [
            STRUCTURES_AIP_SOURCE_NAME,
            STRUCTURES_AIP_OUTLINE_SOURCE_NAME,
            STRUCTURES_AIP_LABEL_SOURCE_NAME,
          ],
          data: featuresCollection,
        },
      );
    },
  },
};
