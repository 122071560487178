<template>
  <div class="primary--form mr-2">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row
        no-gutters
        align="center"
        class="primary--form__header px-4 py-2"
      >
        <v-col
          cols="7"
          sm="8"
          class="text-left subtitle-1"
        >
          <template v-if="isEditing">
            <translate>Update zone</translate>
          </template>
          <template v-else>
            <translate>New zone</translate>
          </template>
        </v-col>
        <v-col
          cols="5"
          sm="4"
          class="text-right pr-1"
        >
          <span v-translate>Cancel</span>
          <v-btn
            icon
            dark
            @click="close()"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-container class="primary--form__container px-4">
        <!-- Name -->
        <v-row
          no-gutters
          align="center"
          key="name"
        >
          <v-col
            cols="12"
            md="4"
          >
            <span class="primary--text font-weight-medium">
              {{ labels.name }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="layerName"
              :hint="hints.name"
              persistent-hint
              :rules="rules.required"
              class="pa-0"
              required
            />
          </v-col>
        </v-row>
        <!-- Description -->
        <v-row
          no-gutters
          align="center"
          key="description"
        >
          <v-col
            cols="12"
            md="4"
          >
            <span class="primary--text font-weight-medium">
              {{ labels.description }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-textarea
              v-model="layerDescription"
              :hint="hints.description"
              rows="2"
              class="pa-0"
              required
            />
          </v-col>
        </v-row>
        <!-- Zone color -->
        <v-row
          no-gutters
          align="center"
          key="zoneColor"
        >
          <v-col
            cols="12"
            md="6"
            class="pr-1"
          >
            <span class="primary--text font-weight-medium pb-2">
              {{ labels.fillColor }}
            </span>
            <div class="align-self-start">
              <v-color-picker
                v-model="fillColor"
                mode="rgba"
                hide-inputs
                canvas-height="80"
                class="mt-2"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pl-1"
          >
            <span class="primary--text font-weight-medium pb-2">
              {{ labels.strokeColor }}
            </span>
            <div class="align-self-start">
              <v-color-picker
                v-model="strokeColor"
                mode="rgba"
                hide-inputs
                canvas-height="80"
                class="mt-2"
              />
            </div>
          </v-col>
        </v-row>
        <!-- Default visibility and legend style -->
        <v-row
          no-gutters
          align="center"
          justify="start"
          key="visibilityAndLegend"
          class="mt-4 map-checkbox"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <div class="d-flex">
              <v-checkbox
                v-model="layerVisibility"
                color="primary"
                hide-details
              >
                <template v-slot:label>
                  <span class="body-2 primary--text font-weight-medium">
                    {{ labels.layerVisibility }}
                  </span>
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <div class="d-flex align-center">
              <v-checkbox
                v-model="legendCenter"
                color="primary"
                hide-details
              >
                <template v-slot:label>
                  <span class="body-2 primary--text font-weight-medium">
                    {{ labels.legendCenter }}
                  </span>
                </template>
              </v-checkbox>
              <v-tooltip
                bottom
                max-width="220px"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="ml-2"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  {{ texts.legendCenter }}
                </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-6" />
        <!-- Automatic actions -->
        <v-row
          no-gutters
          align="center"
          class="mt-2"
        >
          <span class="primary--text body-1 font-weight-medium">
            {{ texts.automaticActions }}
          </span>
        </v-row>
        <v-alert
          color="primary"
          icon="mdi-information-outline"
          outlined
          border="left"
          class="text-sm-body-2 mt-2 font-weight-medium"
        >
          {{ texts.automaticActionsRule }}
        </v-alert>
        <!-- Dates of validity -->
        <v-row
          no-gutters
          class="px-4 py-2"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <div class="d-flex align-end">
              <div class="flex-lg-shrink-0">
                <span class="primary--text font-weight-medium">
                  <translate>Validity starting</translate>
                </span>
              </div>
              <div class="flex-grow-1 flex-lg-shrink-1">
                <v-menu
                  ref="dateStartMenu"
                  v-model="dateStartMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dateStart"
                  transition="scale-transition"
                  min-width="auto"
                  :disabled="!automaticActionsActivated"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateStart"
                      class="ma-0 px-4"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click.clear="dateStart = null"
                      hide-details
                      :disabled="!automaticActionsActivated"
                      placeholder="dd/mm/aaaa"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateStart"
                    no-title
                    scrollable
                    locale="fr"
                    first-day-of-week="1"
                    :min="today"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dateStartMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateStartMenu.save(dateStart)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <div class="d-flex align-end">
              <div class="flex-lg-shrink-0">
                <span class="primary--text font-weight-medium">
                  <translate>Validity ending</translate>
                </span>
              </div>
              <div class="flex-grow-1 flex-lg-shrink-1">
                <v-menu
                  ref="dateEndMenu"
                  v-model="dateEndMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dateEnd"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :disabled="!automaticActionsActivated"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateEnd"
                      class="ma-0 px-4"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click.clear="dateEnd = null"
                      hide-details
                      :disabled="!automaticActionsActivated"
                      placeholder="dd/mm/aaaa"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateEnd"
                    no-title
                    scrollable
                    locale="fr"
                    first-day-of-week="1"
                    :min="today"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dateEndMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateEndMenu.save(dateEnd)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- Height reference -->
        <v-row
          no-gutters
          align="center"
          class="px-4 py-2"
          v-if="!isUserPrefecture"
        >
          <v-col cols="12">
            <v-row
              no-gutters
              align="center"
            >
              <span class="primary--text font-weight-medium">
                <translate>Floors and ceils reference</translate>
              </span>
              <v-tooltip
                :disabled="!automaticDecisionsActivated"
                max-width="620"
                nudge-top="20"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-radio-group
                      v-model="heightReference"
                      :disabled="automaticDecisionsActivated"
                      class="px-4"
                      mandatory
                      row
                    >
                      <v-radio
                        label="AGL"
                        value="AGL"
                      />
                      <v-radio
                        label="AMSL"
                        value="AMSL"
                      />
                    </v-radio-group>
                  </div>
                </template>
                <span>
                  {{ texts.updateHeightReference }}
                </span>
              </v-tooltip>
            </v-row>
          </v-col>
        </v-row>
        <!-- Auto accept -->
        <v-card
          class="mb-2 mt-0"
          key="accept"
        >
          <v-list-group
            v-model="expandAccept"
            color=""
          >
            <template v-slot:activator>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
              >
                <div class="body-1 d-flex justify-center align-center">
                  <div>
                    <StatusIcon
                      status="accepted"
                      linear
                      :transparent-background="false"
                    />
                  </div>
                  <div class="pl-2">
                    {{ texts.autoAccept }}
                  </div>
                  <div
                    class="pl-2"
                    v-if="autoAccept"
                  >
                    {{ labels.autoAcceptFloorHeight }}
                  </div>
                </div>
                <v-switch
                  v-model="autoAccept"
                  @click.stop="expand('accept')"
                />
              </v-row>
            </template>
            <div class="px-6 pb-3">
              <v-row
                align="center"
                no-gutters
              >
                <v-textarea
                  v-model="autoAcceptMessage"
                  :label="labels.autoAcceptMessage"
                  :disabled="!autoAccept"
                  outlined
                  hide-details
                  rows="3"
                  class="py-2"
                />
              </v-row>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  sm="12"
                  class="py-0"
                >
                  <span class="primary--text font-weight-medium">
                    {{ labels.autoAcceptFloorHeightLimit }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="autoAcceptFloorHeightLimit"
                    :hint="hints.autoAcceptFloorHeightLimit"
                    :disabled="!autoAccept"
                  >
                    <template slot="append">
                      <v-select
                        v-model="heightUnit"
                        :items="heightUnitItems"
                        class="height-unit__select"
                        hide-details
                        dense
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  sm="12"
                  class="py-0"
                >
                  <span class="primary--text font-weight-medium">
                    {{ labels.autoAcceptHeightLimit }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="autoAcceptHeightLimit"
                    :hint="hints.autoAcceptHeightLimit"
                    :disabled="!autoAccept"
                  >
                    <template slot="append">
                      <v-select
                        v-model="heightUnit"
                        :items="heightUnitItems"
                        class="height-unit__select"
                        hide-details
                        dense
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-list-group>
        </v-card>
        <!-- Auto restriction -->
        <v-card
          class="mb-2 mt-3"
          key="restrict"
        >
          <v-list-group
            v-model="expandRestriction"
            color=""
          >
            <template v-slot:activator>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
              >
                <div class="body-1 d-flex justify-center align-center">
                  <div>
                    <StatusIcon
                      status="reserves"
                      linear
                      :transparent-background="false"
                    />
                  </div>
                  <div class="pl-2">
                    {{ texts.autoRestriction }}
                  </div>
                  <div
                    class="pl-2"
                    v-if="autoRestriction"
                  >
                    {{ labels.autoRestrictionFloorHeight }}
                  </div>
                </div>
                <v-switch
                  v-model="autoRestriction"
                  @click.stop="expand('restrict')"
                />
              </v-row>
            </template>
            <div class="px-6 pb-3">
              <div class="d-flex py-2">
                <v-textarea
                  v-model="autoRestrictionMessage"
                  :label="labels.autoRestrictionMessage"
                  :disabled="!autoRestriction"
                  outlined
                  hide-details
                  rows="3"
                />
                <div class="d-flex flex-column justify-end">
                  <SavedMessagesMenu
                    isParticularRequirements
                    :text.sync="autoRestrictionMessage"
                    :disabled="!autoRestriction"
                  />
                </div>
              </div>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  sm="12"
                  class="py-0"
                >
                  <span class="primary--text font-weight-medium">
                    {{ labels.autoRestrictionFloorHeightLimit }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="autoRestrictionFloorHeightLimit"
                    :hint="hints.autoRestrictionFloorHeightLimit"
                    :disabled="!autoRestriction"
                  >
                    <template slot="append">
                      <v-select
                        v-model="heightUnit"
                        :items="heightUnitItems"
                        class="height-unit__select"
                        hide-details
                        dense
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  sm="12"
                  class="py-0"
                >
                  <span class="primary--text font-weight-medium">
                    {{ labels.autoRestrictionHeightLimit }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="autoRestrictionHeightLimit"
                    :hint="hints.autoRestrictionHeightLimit"
                    :disabled="!autoRestriction"
                  >
                    <template slot="append">
                      <v-select
                        v-model="heightUnit"
                        :items="heightUnitItems"
                        class="height-unit__select"
                        hide-details
                        dense
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="heightRestrictionActivated"
                    color="primary"
                    :disabled="!autoRestriction"
                  >
                    <template v-slot:label>
                      <span class="body-2 font-weight-medium">
                        {{ labels.heightRestrictionActivated }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="heightRestriction"
                    :hint="hints.heightRestriction"
                    :disabled="!autoRestriction || !heightRestrictionActivated"
                    suffix="m"
                  />
                </v-col>
              </v-row>
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="tacticalContactActivated"
                  color="primary"
                  :disabled="!autoRestriction"
                  @click="writeTacticalContactProtocols()"
                  class="mr-2"
                >
                  <template v-slot:label>
                    <span class="body-2 font-weight-medium">
                      {{ labels.tacticalContact }}
                    </span>
                  </template>
                </v-checkbox>
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="tooltip">mdi-information-outline</v-icon>
                  </template>
                  <span>
                    {{ texts.tacticalContactProtocolTooltip }}
                  </span>
                </v-tooltip>
              </div>
            </div>
          </v-list-group>
        </v-card>
        <!-- Auto refuse -->
        <v-card
          class="mb-2 mt-3"
          key="refuse"
        >
          <v-list-group
            v-model="expandRefuse"
            color=""
          >
            <template v-slot:activator>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
              >
                <div class="body-1 d-flex justify-center align-center">
                  <div>
                    <StatusIcon
                      status="refused"
                      linear
                      :transparent-background="false"
                    />
                  </div>
                  <div class="pl-2">
                    {{ texts.autoRefuse }}
                  </div>
                  <div
                    class="pl-2"
                    v-if="autoRefuse"
                  >
                    {{ labels.autoRefuseFloorHeight }}
                  </div>
                </div>
                <v-switch
                  v-model="autoRefuse"
                  @click.stop="expand('refuse')"
                />
              </v-row>
            </template>
            <div class="px-6 pb-3">
              <v-row
                align="center"
                no-gutters
              >
                <v-textarea
                  v-model="autoRefuseMessage"
                  :label="labels.autoRefuseMessage"
                  :disabled="!autoRefuse"
                  outlined
                  hide-details
                  rows="3"
                  class="py-2"
                />
              </v-row>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  class="py-0"
                >
                  <span class="primary--text font-weight-medium">
                    {{ labels.autoRefuseFloorHeightLimit }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="autoRefuseFloorHeightLimit"
                    :hint="hints.autoRefuseFloorHeightLimit"
                    :disabled="!autoRefuse"
                  >
                    <template slot="append">
                      <v-select
                        v-model="heightUnit"
                        :items="heightUnitItems"
                        class="height-unit__select"
                        hide-details
                        dense
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  sm="12"
                  class="py-0"
                >
                  <span class="primary--text font-weight-medium">
                    {{ labels.autoRefuseHeightLimit }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="autoRefuseHeightLimit"
                    :hint="hints.autoRefuseHeightLimit"
                    :disabled="!autoRefuse"
                  >
                    <template slot="append">
                      <v-select
                        v-model="heightUnit"
                        :items="heightUnitItems"
                        class="height-unit__select"
                        hide-details
                        dense
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-list-group>
        </v-card>
        <!-- Auto inform contact -->
        <v-card
          class="mb-2 mt-3"
          key="inform-contact"
        >
          <v-list-group
            v-model="expandInformContact"
            color=""
          >
            <template v-slot:activator>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
              >
                <div class="body-1 d-flex justify-center align-center">
                  <div>
                    <v-icon>mdi-email-outline</v-icon>
                  </div>
                  <div class="pl-2">
                    {{ texts.autoInformContact }}
                  </div>
                </div>
                <v-switch
                  v-model="autoInformContact"
                  @click.stop="expand('inform-contact')"
                />
              </v-row>
            </template>
            <div class="px-6 pb-3">
              <v-radio-group v-model="autoInformContactInsideArea">
                <template v-slot:label>
                  <div class="primary--text font-weight-medium">
                    {{ labels.insideAreaRadioGroup }}
                  </div>
                </template>
                <v-radio
                  v-for="(radio, index) in radioGroupInsideArea"
                  :key="index"
                  :value="radio.value"
                >
                  <template v-slot:label>
                    <div>
                      {{ radio.label }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-row
                align="center"
                no-gutters
              >
                <span>
                  {{ texts.autoInformContactText }}
                </span>
              </v-row>
              <v-row
                align="center"
                no-gutters
                class="mt-2"
              >
                <v-combobox
                  v-model="autoInformContactEmails.selected"
                  :search-input.sync="autoInformContactEmails.search"
                  :items="autoInformContactEmails.items"
                  hide-selected
                  multiple
                  small-chips
                  class="pt-2 ma-0"
                  @click:append="autoInformContactEmails.search = null"
                  outlined
                >
                  <template v-slot:label>
                    <span class="font-weight-bold">
                      {{ labels.autoInformContactTextMails }}
                    </span>
                  </template>
                  <template
                    v-slot:no-data
                    v-if="isSearching"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-html="texts.searchMail" />
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, parent, selected }">
                    <v-chip
                      v-if="item"
                      :input-value="selected"
                    >
                      <span class="pr-2"> {{ item }} </span>
                      <v-icon
                        small
                        @click="parent.selectItem(item)"
                      >
                        close
                      </v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-chip>
                        {{ item }}
                      </v-chip>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-row>
            </div>
          </v-list-group>
        </v-card>
        <!-- Auto ATS internal note -->
        <v-card
          class="mb-2 mt-3"
          key="ats-internal-note"
          v-if="!isUserPrefecture"
        >
          <v-list-group
            v-model="expandAtsInternalNote"
            color=""
          >
            <template v-slot:activator>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
              >
                <div class="body-1 d-flex justify-center align-center">
                  <div>
                    <v-icon>mdi-clipboard-text</v-icon>
                  </div>
                  <div class="pl-2">
                    {{ texts.autoAtsInternalNote }}
                  </div>
                </div>
                <v-switch
                  v-model="autoAtsInternalNote"
                  @click.stop="expand('ats-internal-note')"
                />
              </v-row>
            </template>
            <div class="px-6 pb-3">
              <v-row
                align="center"
                no-gutters
              >
                <span>
                  {{ texts.autoAtsInternalNoteText }}
                </span>
              </v-row>
              <v-radio-group v-model="autoAtsInternalNoteInsideArea">
                <template v-slot:label>
                  <div class="primary--text font-weight-medium">
                    {{ labels.insideAreaRadioGroup }}
                  </div>
                </template>
                <v-radio
                  v-for="(radio, index) in radioGroupInsideArea"
                  :key="index"
                  :value="radio.value"
                >
                  <template v-slot:label>
                    <div>
                      {{ radio.label }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <div class="d-flex py-2">
                <v-textarea
                  v-model="autoAtsInternalNoteMessage"
                  :label="labels.autoAtsInternalNoteMessage"
                  :disabled="!autoAtsInternalNote"
                  outlined
                  hide-details
                  rows="3"
                />
                <div class="d-flex flex-column justify-end">
                  <SavedMessagesMenu
                    isATSInternalNotes
                    :text.sync="autoAtsInternalNoteMessage"
                    :disabled="!autoAtsInternalNote"
                  />
                </div>
              </div>
            </div>
          </v-list-group>
        </v-card>
        <!-- Auto impacting zone -->
        <v-card
          v-if="!isUserPrefecture"
          class="mb-2 mt-3"
          key="impacting-zone"
        >
          <v-list-group
            v-model="expandImpactingZone"
            color=""
          >
            <template v-slot:activator>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
              >
                <div class="body-1 d-flex justify-center align-center">
                  <div>
                    <v-icon color="red">mdi-alert-circle</v-icon>
                  </div>
                  <div class="pl-2">
                    {{ texts.autoImpactingZone }}
                  </div>
                  <div
                    v-if="autoImpactingZone"
                    class="pl-2"
                  >
                    {{ labels.autoImpactingZoneFloorHeight }}
                  </div>
                </div>
                <v-switch
                  v-model="autoImpactingZone"
                  @click.stop="expand('impacting-zone')"
                />
              </v-row>
            </template>
            <div class="px-6 pb-3">
              <v-row
                align="center"
                no-gutters
              >
                <span>
                  {{ texts.autoImpactingZoneText }}
                </span>
              </v-row>
              <v-radio-group v-model="autoImpactingZoneInsideArea">
                <template v-slot:label>
                  <div class="primary--text font-weight-medium">
                    {{ labels.insideAreaRadioGroup }}
                  </div>
                </template>
                <v-radio
                  v-for="(radio, index) in radioGroupInsideArea"
                  :key="index"
                  :value="radio.value"
                >
                  <template v-slot:label>
                    <div>
                      {{ radio.label }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  class="py-0"
                >
                  <span class="primary--text font-weight-medium">
                    {{ labels.autoImpactingZoneFloorHeightLimit }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="autoImpactingZoneFloorHeightLimit"
                    :hint="hints.autoImpactingZoneFloorHeightLimit"
                    :disabled="!autoImpactingZone"
                  >
                    <template slot="append">
                      <v-select
                        v-model="heightUnit"
                        :items="heightUnitItems"
                        class="height-unit__select"
                        hide-details
                        dense
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                align="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                  sm="12"
                  class="py-0"
                >
                  <span class="primary--text font-weight-medium">
                    {{ labels.autoImpactingZoneHeightLimit }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model.number="autoImpactingZoneHeightLimit"
                    :hint="hints.autoImpactingZoneHeightLimit"
                    :disabled="!autoImpactingZone"
                  >
                    <template slot="append">
                      <v-select
                        v-model="heightUnit"
                        :items="heightUnitItems"
                        class="height-unit__select"
                        hide-details
                        dense
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-list-group>
        </v-card>
        <!-- Geometry -->
        <v-row align="center">
          <v-col cols="12">
            <span class="error--text font-italic">
              <span
                v-if="isEditing"
                v-translate
              >
                You can update the zone geometry on the map or
              </span>
              <span
                v-else
                v-translate
              >
                To create a new zone, draw its shape on the map or
              </span>
              <label
                for="kml-file-input"
                class="layer-form__kml-file-input-label ml-1"
              >
                <translate>upload a KML file.</translate>
              </label>
            </span>
            <input
              id="kml-file-input"
              accept=".kml"
              type="file"
              class="d-none"
              @change="(event) => parseKMLFile(event.target.files[0])"
            >
          </v-col>
        </v-row>
        <!-- Confirm -->
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            class="mt-2"
          >
            <v-btn
              v-if="!isEditing"
              block
              class="white--text"
              color="green"
              @click="displayDialog()"
              :disabled="!valid"
              :loading="loading"
            >
              <translate>CREATE ZONE</translate>
            </v-btn>
            <v-btn
              v-if="isEditing"
              block
              class="white--text"
              color="green"
              @click="displayDialog()"
              :disabled="!valid"
              :loading="loading"
            >
              <translate>UPDATE ZONE</translate>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <!-- Dialog summary automatic process -->
    <v-dialog
      v-model="dialogAutomaticProcessSummary"
      max-width="820"
      :retain-focus="false"
      persistent
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate
            v-if="validate"
            key="summary"
          >
            Summary of automatic actions of this zone
          </translate>
          <translate
            v-else
            key="errorTitle"
          >
            Errors in form
          </translate>
        </v-card-title>
        <v-card-text>
          <div
            v-if="validate"
            key="validate"
          >
            <v-alert
              v-if="isIntersectingDecisionCustomLayer"
              elevation="0"
              class="body-2"
              icon="mdi-alert"
              color="warning"
              text
            >
              {{ texts.intersectingDecisionLayers }}
            </v-alert>
            <div
              v-if="dateStart || dateEnd"
              key="dateStartOrDateEnd"
            >
              <span v-translate>
                Activating automatic actions
              </span>
              <span
                v-if="dateStart && dateEnd"
                key="dateStartAndDateEnd"
              >
                <translate>from</translate>
                <span>
                  {{ dateStart | dateShort }}
                </span>
                <translate>to</translate>
                <span>
                  {{ dateEnd | dateShort }}
                </span>
              </span>
              <span
                v-else-if="dateStart"
                key="dateStart"
              >
                <translate>starting from</translate>
                <span>
                  {{ dateStart | dateShort }}
                </span>
              </span>
              <span
                v-else-if="dateEnd"
                key="dateEnd"
              >
                <translate>until</translate>
                <span>
                  {{ dateEnd | dateShort }}
                </span>
              </span>
              <v-divider />
            </div>
            <div
              v-if="autoAccept"
              key="infoAccept"
            >
              <translate>Approval request automatically accepted if:</translate>
              <ul>
                <li>
                  {{ summaryTexts.autoAccept.floor }}
                </li>
                <li>
                  {{ summaryTexts.autoAccept.height }}
                </li>
              </ul>
              <span v-if="autoAcceptMessage">
                <translate>Message sent to pilots:</translate>
                <span>
                  {{ autoAcceptMessage }}
                </span>
              </span>
              <v-divider />
            </div>
            <div
              v-if="autoRestriction"
              key="infoRestict"
            >
              <translate>Approval request automatically accepted with restrictions if:</translate>
              <ul>
                <li>
                  {{ summaryTexts.autoRestriction.floor }}
                </li>
                <li>
                  {{ summaryTexts.autoRestriction.height }}
                </li>
              </ul>
              <div v-if="heightRestrictionActivated">
                {{ summaryTexts.autoRestriction.heightRestriction }}
              </div>
              <translate>Message sent to pilots:</translate>
              <span>
                {{ autoRestrictionMessage }}
              </span>
              <div v-if="tacticalContactActivated">
                <translate>Tactical contact activated</translate>
              </div>
              <v-divider />
            </div>
            <div
              v-if="autoRefuse"
              key="infoRefuse"
            >
              <translate>Approval request automatically refused if:</translate>
              <ul>
                <li>
                  {{ summaryTexts.autoRefuse.floor }}
                </li>
                <li>
                  {{ summaryTexts.autoRefuse.height }}
                </li>
              </ul>
              <translate>Message sent to pilots:</translate>
              <span>
                {{ autoRefuseMessage }}
              </span>
              <v-divider />
            </div>
            <div
              v-if="autoInformContact"
              key="infoInformContact"
            >
              <div>
                <span v-if="autoInformContactInsideArea">
                  {{ texts.withinLayers }}
                </span>
                <span v-else>
                  {{ texts.intersectingLayers }}
                </span>
              </div>
              <translate>Contacts to inform automatically:</translate>
              <ul>
                <li
                  v-for="(email, index) in emails"
                  :key="index"
                >
                  {{ email }}
                </li>
              </ul>
              <v-divider />
            </div>
            <div
              v-if="autoAtsInternalNote"
              key="infoAtsInternalNote"
            >
              <div>
                <span v-if="autoAtsInternalNoteInsideArea">
                  {{ texts.withinLayers }}
                </span>
                <span v-else>
                  {{ texts.intersectingLayers }}
                </span>
              </div>
              <translate>ATS internal note:</translate>
              <span>
                {{ autoAtsInternalNoteMessage }}
              </span>
              <v-divider />
            </div>
            <div
              v-if="autoImpactingZone"
              key="infoImpactingZone"
            >
              <div>
                <span v-if="autoImpactingZoneInsideArea">
                  {{ texts.withinLayers }}
                </span>
                <span v-else>
                  {{ texts.intersectingLayers }}
                </span>
              </div>
              <translate>Approval request automatically marked as in impacting zone if:</translate>
              <ul>
                <li>
                  {{ summaryTexts.autoImpactingZone.floor }}
                </li>
                <li>
                  {{ summaryTexts.autoImpactingZone.height }}
                </li>
              </ul>
              <v-divider />
            </div>
          </div>
          <div
            v-else
            key="errors"
          >
            <div
              v-if="validateActivationPeriod.length"
              key="errorAvctivationPeriod"
            >
              <span v-translate>Errors in activations period:</span>
              <ul>
                <li
                  v-for="(error, index) in validateActivationPeriod"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              v-if="validateAutoAccept.length"
              key="errorAccept"
            >
              <translate>Errors in automatic approval request accepted:</translate>
              <ul>
                <li
                  v-for="(error, index) in validateAutoAccept"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              v-if="validateAutoRestriction.length"
              key="errorRestriction"
            >
              <translate>
                Errors in automatic approval request accepted with restriction:
              </translate>
              <ul>
                <li
                  v-for="(error, index) in validateAutoRestriction"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              v-if="validateAutoRefuse.length"
              key="errorRefuse"
            >
              <translate>Errors in automatic approval request refused:</translate>
              <ul>
                <li
                  v-for="(error, index) in validateAutoRefuse"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              v-if="validateAutoInformContact.length"
              key="errorInformContact"
            >
              <translate>Errors in inform contacts automatic:</translate>
              <ul>
                <li
                  v-for="(error, index) in validateAutoInformContact"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              v-if="validateAtsInternalNote.length"
              key="errorAtsInternalNote"
            >
              <translate>Errors in automatic ATS internal note:</translate>
              <ul>
                <li
                  v-for="(error, index) in validateAtsInternalNote"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              v-if="validateImpactingZone.length"
              key="errorAtsInternalNote"
            >
              <translate>Errors in automatic impacting zone:</translate>
              <ul>
                <li
                  v-for="(error, index) in validateImpactingZone"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              v-if="incompatibleFloorAndHeightLimit.length"
              key="errorIntersection"
            >
              <translate>Intersection of automatic actions' heights:</translate>
              <ul>
                <li
                  v-for="(error, index) in incompatibleFloorAndHeightLimit"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              class="mt-4"
              v-translate
              key="errorFix"
            >
              Please correct these errors to validate form.
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="dialogAutomaticProcessSummary=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="!validate"
            @click="sendLayer()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { kml } from '@tmcw/togeojson';

import {
  APPLICATION_STATUS,
  SET_STATUS_NS,
} from '@/store/application';
import {
  MAP_STATUS,
  SET_MAP_STATUS_NS,
  SET_LOADED_KML_NS,
  REFRESH_MAP_DATA_NS,
  CHANGE_DRAW_LAYER_STYLE_NS,
  ENABLE_CUSTOM_LAYER_NS,
  SHOW_CUSTOM_LAYER_NS,
} from '@/store/map';

import API from '@/services/api';

import SavedMessagesMenu from '@/components/SavedMessagesMenu.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import { featureCollectionToMultiPolygon } from '@/components/Map/MapboxGL.helper';

const FOOT = 0.3048;

export default {
  name: 'CustomLayerForm',
  components: {
    SavedMessagesMenu,
    StatusIcon,
  },
  props: { customLayerToEdit: { type: Object } },
  data() {
    return {
      valid: true,
      rules: {
        required: [
          (value) => !!value || this.$gettext('A value is required'),
        ],
      },
      layerName: null,
      layerDescription: null,
      fillColor: {
        r: 0,
        g: 0,
        b: 255,
        a: 0.2,
      },
      strokeColor: {
        r: 0,
        g: 40,
        b: 110,
        a: 0.7,
      },
      layerVisibility: true,
      legendCenter: false,
      expandAccept: false,
      heightReference: 'AGL',
      heightUnit: 'm',
      heightUnitItems: ['m', 'ft'],
      autoAccept: false,
      autoAcceptMessage: '',
      autoAcceptHeightLimit: '',
      autoAcceptFloorHeightLimit: '',
      expandRestriction: false,
      autoRestriction: false,
      autoRestrictionMessage: '',
      autoRestrictionHeightLimit: '',
      autoRestrictionFloorHeightLimit: '',
      heightRestrictionActivated: false,
      heightRestriction: 0,
      tacticalContactActivated: false,
      expandRefuse: false,
      autoRefuse: false,
      autoRefuseMessage: '',
      autoRefuseHeightLimit: '',
      autoRefuseFloorHeightLimit: '',
      expandInformContact: false,
      autoInformContact: false,
      autoInformContactInsideArea: false,
      autoInformContactEmails: {
        selected: [],
        search: null,
        items: [],
      },
      expandAtsInternalNote: false,
      autoAtsInternalNote: false,
      autoAtsInternalNoteInsideArea: false,
      autoAtsInternalNoteMessage: '',
      expandImpactingZone: false,
      autoImpactingZone: false,
      autoImpactingZoneInsideArea: false,
      autoImpactingZoneHeightLimit: '',
      autoImpactingZoneFloorHeightLimit: '',
      dateStartMenu: false,
      dateEndMenu: false,
      dateStart: null,
      dateEnd: null,
      dialogAutomaticProcessSummary: false,
      loading: false,
      today: format(new Date(), 'yyyy-MM-dd'),
      radioGroupInsideArea: [
        {
          label: this.$gettext('Intersecting zone'),
          value: false,
        },
        {
          label: this.$gettext('Within zone'),
          value: true,
        },
      ],
    };
  },
  computed: {
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    isEditing() {
      return this.customLayerToEdit !== null;
    },
    texts() {
      return {
        legendCenter: this.$gettext(`If checked, the name of the zone will be displayed in the
          center of the area. Else, it will be displayed along the outline of area.`),
        automaticActions: this.$gettext('Automatic actions on approvals'),
        automaticActionsRule: this.$gettext(`Actions on approvals request status (accept, accept
          with restriction and refuse) will be available only for in sight approval and approval
          zone completely within this zone.`),
        autoAccept: this.$gettext('Accept approval requests automatically'),
        autoRestriction: this.$gettext('Accept approval requests with restriction automatically'),
        tacticalContactProtocolTooltip: this.$gettext(`Tactical contact protocols defined in
          settings will be written in restriction message and transmitted to controllers.`),
        autoRefuse: this.$gettext('Refuse approval requests automatically'),
        autoInformContact: this.$gettext('Inform contact automatically'),
        autoInformContactText: this.$gettext(`Inform contacts of decisions on approvals in the
          zone. An email with the mission sheet will be send to the emails inputed below. This
          action is triggered after modification automatic or manual of a request for approval.`),
        autoAtsInternalNote: this.$gettext('ATS internal note automatically'),
        autoAtsInternalNoteText: this.$gettext(`Add automatically the text below in the ATS
          internal note to controllers. This action is triggered after creation or modification of
          an approval.`),
        autoImpactingZone: this.$gettext('Impacting zone'),
        autoImpactingZoneText: this.$gettext('Mark approvals as in impacting zone automatically.'),
        updateHeightReference: this.$gettext(`Please disable all automatic decisions to change
          ceiling and floor references.`),
        searchMail: this.$gettextInterpolate(
          this.$gettext('No result for %{search}. Input <kbd>Enter</kbd> to add it.'),
          { search: this.autoInformContactEmails.search || '' },
        ),
        intersectingDecisionLayers: this.$gettext(`This zone intersects other zones with automatic
          decisions. Be aware that there is a priority order in automatic decisions process if an
          approval is contained in many automatic decisions: refusal decisions are first, then
          accepted with restrictions and finally accepted.`),
        intersectingLayers: this.$gettext(`This action is applied when approval area intersecting
          the zone.`),
        withinLayers: this.$gettext(`This action is applied when approval area is totally inside
          the zone.`),
      };
    },
    labels() {
      return {
        name: this.$gettext('Zone name'),
        description: this.$gettext('Zone description'),
        fillColor: this.$gettext('Fill color'),
        strokeColor: this.$gettext('Stroke color'),
        layerVisibility: this.$gettext('Visible by default'),
        legendCenter: this.$gettext('Display legend in center of area'),
        autoAcceptMessage: this.$gettext('Message send to pilots'),
        autoAcceptHeightLimit: this.$gettextInterpolate(
          this.$gettext('Auto approval ceiling (%{ unit } %{ ref })'),
          { unit: this.heightUnit, ref: this.heightReference },
        ),
        autoAcceptFloorHeightLimit: this.$gettextInterpolate(
          this.$gettext('Auto approval floor (%{ unit } %{ ref })'),
          { unit: this.heightUnit, ref: this.heightReference },
        ),
        autoAcceptFloorHeight: (
          `(${this.autoAcceptFloorHeightLimit} - ${this.autoAcceptHeightLimit}
          ${this.heightUnit} ${this.heightReference})`
        ),
        autoRestrictionMessage: this.$gettext('Message send to pilots'),
        autoRestrictionHeightLimit: this.$gettextInterpolate(
          this.$gettext('Auto approval with restrictions ceiling (%{ unit } %{ ref })'),
          { unit: this.heightUnit, ref: this.heightReference },
        ),
        autoRestrictionFloorHeightLimit: this.$gettextInterpolate(
          this.$gettext('Auto approval with restrictions floor (%{ unit } %{ ref })'),
          { unit: this.heightUnit, ref: this.heightReference },
        ),
        autoRestrictionFloorHeight: (
          `(${this.autoRestrictionFloorHeightLimit} - ${this.autoRestrictionHeightLimit}
          ${this.heightUnit} ${this.heightReference})`
        ),
        heightRestrictionActivated: this.$gettext('Restrict flying height'),
        heightRestriction: this.$gettext('Default flying height'),
        tacticalContact: this.$gettext('Tactical contact'),
        autoRefuseMessage: this.$gettext('Message send to pilots'),
        autoRefuseHeightLimit: this.$gettextInterpolate(
          this.$gettext('Auto refuse ceiling (%{ unit } %{ ref })'),
          { unit: this.heightUnit, ref: this.heightReference },
        ),
        autoRefuseFloorHeightLimit: this.$gettextInterpolate(
          this.$gettext('Auto refuse floor (%{ unit } %{ ref })'),
          { unit: this.heightUnit, ref: this.heightReference },
        ),
        autoRefuseFloorHeight: (
          `(${this.autoRefuseFloorHeightLimit} - ${this.autoRefuseHeightLimit}
          ${this.heightUnit} ${this.heightReference})`
        ),
        autoInformContactTextMails: this.$gettext('Emails sent to this contacts'),
        autoAtsInternalNoteMessage: this.$gettext('Note to controllers'),
        autoImpactingZoneHeightLimit: this.$gettextInterpolate(
          this.$gettext('Auto impacting zone ceiling (%{ unit } %{ ref })'),
          { unit: this.heightUnit, ref: this.heightReference },
        ),
        autoImpactingZoneFloorHeightLimit: this.$gettextInterpolate(
          this.$gettext('Auto impacting zone floor (%{ unit } %{ ref })'),
          { unit: this.heightUnit, ref: this.heightReference },
        ),
        autoImpactingZoneFloorHeight: (
          `(${this.autoImpactingZoneFloorHeightLimit} - ${this.autoImpactingZoneHeightLimit}
          ${this.heightUnit} ${this.heightReference})`
        ),
        insideAreaRadioGroup: this.$gettext('Apply this action when approval area'),
      };
    },
    hints() {
      return {
        name: this.$gettext('This will be displayed on the map.'),
        description: this.$gettext('Zone description, for internal reference.'),
        autoAcceptHeightLimit: this.$gettext(`Approval requests above this height will not be
          automatically accepted.`),
        autoAcceptFloorHeightLimit: this.$gettext(`Approval requests under this height will not be
          automatically accepted.`),
        autoRestrictionHeightLimit: this.$gettext(`Approval requests above this height will not be
          automatically accepted with restrictions.`),
        autoRestrictionFloorHeightLimit: this.$gettext(`Approval under above this height will not
          be automatically accepted with restrictions.`),
        heightRestriction: this.$gettext(`The height of flights that will be automatically accepted
          with restrictions will be limited to this value.`),
        autoRefuseHeightLimit: this.$gettext(`Approval requests above this height will not be
          automatically refused.`),
        autoRefuseFloorHeightLimit: this.$gettext(`Approval requests under this height will not be
          automatically refused.`),
        autoImpactingZoneHeightLimit: this.$gettext(`Approval requests above this height will not
          be mark automatically as inside impacting zone.`),
        autoImpactingZoneFloorHeightLimit: this.$gettext(`Approval requests under this height will
          not be mark automatically as inside impacting zone.`),
      };
    },
    currentArea() {
      const featureCollection = this.$store.state.map.currentArea;
      return featureCollectionToMultiPolygon(featureCollection);
    },
    fillColorString() {
      return `rgba(${this.fillColor.r}, ${this.fillColor.g}, ${this.fillColor.b}, ${this.fillColor.a})`;
    },
    strokeColorString() {
      return `rgba(${this.strokeColor.r}, ${this.strokeColor.g}, ${this.strokeColor.b}, ${this.strokeColor.a})`;
    },
    validateAutoAccept() {
      const message = [];
      if (this.autoAccept) {
        if (typeof this.autoAcceptHeightLimit !== 'number') {
          message.push(this.$gettext('A ceilling height is required.'));
        }
        if (typeof this.autoAcceptFloorHeightLimit !== 'number') {
          message.push(this.$gettext('A floor height is required.'));
        }
        if (this.autoAcceptFloorHeightLimit >= this.autoAcceptHeightLimit) {
          message.push(this.$gettext('Floor height can not be above ceil height.'));
        }
      }
      return message;
    },
    validateAutoRestriction() {
      const message = [];
      if (this.autoRestriction) {
        if (typeof this.autoRestrictionHeightLimit !== 'number') {
          message.push(this.$gettext('A ceilling height is required.'));
        }
        if (typeof this.autoRestrictionHeightLimit !== 'number') {
          message.push(this.$gettext('A floor height is required.'));
        }
        if (this.autoRestrictionFloorHeightLimit >= this.autoRestrictionHeightLimit) {
          message.push(this.$gettext('Floor height can not be above ceil height.'));
        }
        if (this.heightRestrictionActivated && typeof this.heightRestriction !== 'number') {
          message.push(
            this.$gettext('A restricted height is required when the restrict height is activated.'),
          );
        }
        if (!this.autoRestrictionMessage.length) {
          message.push(this.$gettext('A message is required.'));
        }
      }
      return message;
    },
    validateAutoRefuse() {
      const message = [];
      if (this.autoRefuse) {
        if (typeof this.autoRefuseHeightLimit !== 'number') {
          message.push(this.$gettext('A ceilling height is required.'));
        }
        if (typeof this.autoRefuseFloorHeightLimit !== 'number') {
          message.push(this.$gettext('A floor height is required.'));
        }
        if (this.autoRefuseFloorHeightLimit >= this.autoRefuseHeightLimit) {
          message.push(this.$gettext('Floor height can not be above ceil height.'));
        }
        if (!this.autoRefuseMessage.length) {
          message.push(this.$gettext('A message to explain your refuse is required.'));
        }
      }
      return message;
    },
    validateAutoInformContact() {
      const message = [];
      if (this.autoInformContact) {
        if (!this.emails.length) {
          message.push(this.$gettext('At least one email is required.'));
        }
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailIsValid = true;
        this.emails.forEach((value) => {
          if (!pattern.test(value)) {
            emailIsValid = false;
          }
        });
        if (!emailIsValid) {
          message.push(this.$gettext('Invalid emails are inputted.'));
        }
      }
      return message;
    },
    validateAtsInternalNote() {
      const message = [];
      if (this.autoAtsInternalNote) {
        if (!this.autoAtsInternalNoteMessage.length) {
          message.push(this.$gettext(`A message is required for the internal note ATS for air
            traffic controls.`));
        }
      }
      return message;
    },
    validateImpactingZone() {
      const message = [];
      if (this.autoImpactingZone) {
        if (typeof this.autoImpactingZoneHeightLimit !== 'number') {
          message.push(this.$gettext('A ceilling height is required.'));
        }
        if (typeof this.autoImpactingZoneFloorHeightLimit !== 'number') {
          message.push(this.$gettext('A floor height is required.'));
        }
        if (this.autoImpactingZoneFloorHeightLimit >= this.autoImpactingZoneHeightLimit) {
          message.push(this.$gettext('Floor height can not be above ceil height.'));
        }
      }
      return message;
    },
    incompatibleFloorAndHeightLimit() {
      const message = [];
      if (this.autoAccept && this.autoRestriction) {
        if (
          !(
            this.autoAcceptHeightLimit < this.autoRestrictionFloorHeightLimit
              || this.autoAcceptFloorHeightLimit > this.autoRestrictionHeightLimit
          )
        ) {
          message.push(this.$gettext(`Heights intersecting themselves between accept and accept
            with restriction areas.`));
        }
      }
      if (this.autoAccept && this.autoRefuse) {
        if (
          !(
            this.autoAcceptHeightLimit < this.autoRefuseFloorHeightLimit
              || this.autoAcceptFloorHeightLimit > this.autoRefuseHeightLimit
          )
        ) {
          message.push(
            this.$gettext('Heights intersecting themselves between accept and refuse areas.'),
          );
        }
      }
      if (this.autoRestriction && this.autoRefuse) {
        if (
          !(
            this.autoRestrictionHeightLimit < this.autoRefuseFloorHeightLimit
              || this.autoRestrictionFloorHeightLimit > this.autoRefuseHeightLimit
          )
        ) {
          message.push(this.$gettext(`Heights intersecting themselves between accept with
            restriction and refuse areas.`));
        }
      }
      return message;
    },
    validateActivationPeriod() {
      const message = [];
      if (this.dateStart && this.dateEnd) {
        if (new Date(this.dateStart) > new Date(this.dateEnd)) {
          message.push(this.$gettext('The start date is after end date.'));
        }
      }
      return message;
    },
    validate() {
      return Boolean(
        !this.validateAutoAccept.length
        && !this.validateAutoRestriction.length
        && !this.validateAutoRefuse.length
        && !this.incompatibleFloorAndHeightLimit.length
        && !this.validateAutoInformContact.length
        && !this.validateActivationPeriod.length
        && !this.validateAtsInternalNote.length
        && !this.validateImpactingZone.length,
      );
    },
    isSearching() {
      return (
        this.autoInformContactEmails.search !== null && this.autoInformContactEmails.search !== ''
      );
    },
    emails() {
      return this.autoInformContactEmails.selected.filter((e) => e !== '');
    },
    automaticDecisionsActivated() {
      return (
        this.autoAccept
        || this.autoRestriction
        || this.autoRefuse
      );
    },
    automaticActionsActivated() {
      return (
        this.automaticDecisionsActivated
        || this.autoInformContact
        || this.autoAtsInternalNote
        || this.autoImpactingZone
      );
    },
    autoAcceptHeightLimitInMeters() {
      return (
        this.heightUnit === 'm' ? this.autoAcceptHeightLimit
          : this.feetToMeters(this.autoAcceptHeightLimit)
      );
    },
    autoAcceptFloorHeightLimitInMeters() {
      return (
        this.heightUnit === 'm' ? this.autoAcceptFloorHeightLimit
          : this.feetToMeters(this.autoAcceptFloorHeightLimit)
      );
    },
    autoRestrictionHeightLimitInMeters() {
      return (
        this.heightUnit === 'm' ? this.autoRestrictionHeightLimit
          : this.feetToMeters(this.autoRestrictionHeightLimit)
      );
    },
    autoRestrictionFloorHeightLimitInMeters() {
      return (
        this.heightUnit === 'm' ? this.autoRestrictionFloorHeightLimit
          : this.feetToMeters(this.autoRestrictionFloorHeightLimit)
      );
    },
    autoRefuseHeightLimitInMeters() {
      return (
        this.heightUnit === 'm' ? this.autoRefuseHeightLimit
          : this.feetToMeters(this.autoRefuseHeightLimit)
      );
    },
    autoRefuseFloorHeightLimitInMeters() {
      return (
        this.heightUnit === 'm' ? this.autoRefuseFloorHeightLimit
          : this.feetToMeters(this.autoRefuseFloorHeightLimit)
      );
    },
    autoImpactingZoneHeightLimitInMeters() {
      return (
        this.heightUnit === 'm' ? this.autoImpactingZoneHeightLimit
          : this.feetToMeters(this.autoImpactingZoneHeightLimit)
      );
    },
    autoImpactingZoneFloorHeightLimitInMeters() {
      return (
        this.heightUnit === 'm' ? this.autoImpactingZoneFloorHeightLimit
          : this.feetToMeters(this.autoImpactingZoneFloorHeightLimit)
      );
    },
    summaryTexts() {
      return {
        autoAccept: {
          floor: this.$gettextInterpolate(
            this.$gettext('Flying height over %{floor} %{unit} %{ref}'),
            {
              floor: this.autoAcceptFloorHeightLimit,
              unit: this.heightUnit,
              ref: this.heightReference,
            },
          ),
          height: this.$gettextInterpolate(
            this.$gettext('Flying height under %{height} %{unit} %{ref}'),
            {
              height: this.autoAcceptHeightLimit === null ? '' : this.autoAcceptHeightLimit,
              unit: this.heightUnit,
              ref: this.heightReference,
            },
          ),
        },
        autoRestriction: {
          floor: this.$gettextInterpolate(
            this.$gettext('Flying height over %{floor} %{unit} %{ref}'),
            {
              floor: this.autoRestrictionFloorHeightLimit,
              unit: this.heightUnit,
              ref: this.heightReference,
            },
          ),
          height: this.$gettextInterpolate(
            this.$gettext('Flying height under %{height} %{unit} %{ref}'),
            {
              height: this.autoRestrictionHeightLimit,
              unit: this.heightUnit,
              ref: this.heightReference,
            },
          ),
          heightRestriction: this.$gettextInterpolate(
            this.$gettext('Height flights constraint of %{heightRestriction} m.'),
            { heightRestriction: this.heightRestriction === null ? '' : this.heightRestriction },
          ),
        },
        autoRefuse: {
          floor: this.$gettextInterpolate(
            this.$gettext('Flying height over %{floor} %{unit} %{ref}'),
            {
              floor: this.autoRefuseFloorHeightLimit,
              unit: this.heightUnit,
              ref: this.heightReference,
            },
          ),
          height: this.$gettextInterpolate(
            this.$gettext('Flying height under %{height} %{unit} %{ref}'),
            {
              height: this.autoRefuseHeightLimit,
              unit: this.heightUnit,
              ref: this.heightReference,
            },
          ),
        },
        autoImpactingZone: {
          floor: this.$gettextInterpolate(
            this.$gettext('Flying height over %{floor} %{unit} %{ref}'),
            {
              floor: this.autoImpactingZoneFloorHeightLimit,
              unit: this.heightUnit,
              ref: this.heightReference,
            },
          ),
          height: this.$gettextInterpolate(
            this.$gettext('Flying height under %{height} %{unit} %{ref}'),
            {
              height: this.autoImpactingZoneHeightLimit,
              unit: this.heightUnit,
              ref: this.heightReference,
            },
          ),
        },
      };
    },
    tacticalContactProtocols() {
      return this.$store.getters['structures/tacticalContactProtocols'];
    },
    isIntersectingDecisionCustomLayer() {
      if (!this.automaticDecisionsActivated) return false;

      let customLayers = this.$store.state.structures.layers.filter((layer) => (
        (
          layer.auto_accept_procedure
          || layer.auto_restrict_procedure
          || layer.auto_refuse_procedure
        ) && (
          this.currentArea
          && this.$turf.intersect(this.getLayerArea(layer), this.currentArea)
        )
      ));
      if (this.customLayerToEdit) {
        customLayers = customLayers.filter((layer) => layer.id !== this.customLayerToEdit.id);
      }
      return customLayers.length;
    },
  },
  watch: {
    fillColor() {
      this.updateFillColor(this.fillColorString);
    },
    strokeColor() {
      this.updateStrokeColor(this.strokeColorString);
    },
    currentArea() {
      this.updateFillColor(this.fillColorString);
      this.updateStrokeColor(this.strokeColorString);
    },
    automaticActionsActivated(newValue) {
      if (!newValue) {
        this.dateStart = null;
        this.dateEnd = null;
      }
    },
    autoAccept(newValue) {
      if (!newValue) {
        this.autoAcceptHeightLimit = '';
        this.autoAcceptFloorHeightLimit = '';
      }
    },
    autoRestriction(newValue) {
      if (!newValue) {
        this.autoRestrictionHeightLimit = '';
        this.autoRestrictionFloorHeightLimit = '';
        this.tacticalContactActivated = false;
      }
    },
    autoRefuse(newValue) {
      if (!newValue) {
        this.autoRefuseHeightLimit = '';
        this.autoRefuseFloorHeightLimit = '';
      }
    },
    autoImpactingZone(newValue) {
      if (!newValue) {
        this.autoImpactingZoneFloorHeightLimit = '';
        this.autoImpactingZoneHeightLimit = '';
      }
    },
    heightUnit(newValue) {
      const heightValues = [
        'autoAcceptHeightLimit', 'autoAcceptFloorHeightLimit',
        'autoRestrictionHeightLimit', 'autoRestrictionFloorHeightLimit',
        'autoRefuseHeightLimit', 'autoRefuseFloorHeightLimit',
        'autoImpactingZoneHeightLimit', 'autoImpactingZoneFloorHeightLimit',
      ];
      if (newValue === 'm') {
        heightValues.forEach((heightValue) => {
          if (this[heightValue]) {
            this[heightValue] = this.feetToMeters(this[heightValue]);
          }
        });
      }
      if (newValue === 'ft') {
        heightValues.forEach((heightValue) => {
          if (this[heightValue]) {
            this[heightValue] = this.metersToFeet(this[heightValue]);
          }
        });
      }
    },
  },
  created() {
    if (this.isUserPrefecture) {
      this.heightUnitItems = ['m'];
    }
    if (this.customLayerToEdit) {
      this.layerName = this.customLayerToEdit.name;
      this.layerDescription = this.customLayerToEdit.description;
      this.fillColor = this.customLayerToEdit.fill_color;
      this.strokeColor = this.customLayerToEdit.stroke_color;
      this.layerVisibility = this.customLayerToEdit.default_visibility;
      this.legendCenter = this.customLayerToEdit.legend_center;
      this.autoAccept = Boolean(this.customLayerToEdit.auto_accept_procedure);
      if (this.autoAccept) {
        this.autoAcceptMessage = this.customLayerToEdit.auto_accept_procedure.acceptance_message;
        this.autoAcceptHeightLimit = this.customLayerToEdit.auto_accept_procedure.height_limit;
        this.autoAcceptFloorHeightLimit = this.customLayerToEdit.auto_accept_procedure.floor_limit;
      }
      this.autoRestriction = Boolean(this.customLayerToEdit.auto_restrict_procedure);
      if (this.autoRestriction) {
        const autoRestrictProcedure = this.customLayerToEdit.auto_restrict_procedure;
        this.autoRestrictionMessage = autoRestrictProcedure.restriction_message;
        this.autoRestrictionHeightLimit = autoRestrictProcedure.height_limit;
        this.autoRestrictionFloorHeightLimit = autoRestrictProcedure.floor_limit;
        this.heightRestrictionActivated = autoRestrictProcedure.flying_height_restriction_activated;
        this.heightRestriction = autoRestrictProcedure.flying_height_restriction;
        this.tacticalContactActivated = autoRestrictProcedure.tactical_contact_activated;
      }
      this.autoRefuse = Boolean(this.customLayerToEdit.auto_refuse_procedure);
      if (this.autoRefuse) {
        this.autoRefuseMessage = this.customLayerToEdit.auto_refuse_procedure.refuse_message;
        this.autoRefuseHeightLimit = this.customLayerToEdit.auto_refuse_procedure.height_limit;
        this.autoRefuseFloorHeightLimit = this.customLayerToEdit.auto_refuse_procedure.floor_limit;
      }
      this.autoInformContact = Boolean(this.customLayerToEdit.auto_inform_contact_procedure);
      if (this.autoInformContact) {
        const autoInformContactProcedure = this.customLayerToEdit.auto_inform_contact_procedure;
        this.autoInformContactInsideArea = autoInformContactProcedure.inside_area;
        this.autoInformContactEmails.selected = autoInformContactProcedure.emails;
        this.autoInformContactEmails.items = autoInformContactProcedure.emails;
      }
      this.autoAtsInternalNote = Boolean(this.customLayerToEdit.auto_ats_internal_note_procedure);
      if (this.autoAtsInternalNote) {
        const autoInternalNoteProcedure = this.customLayerToEdit.auto_ats_internal_note_procedure;
        this.autoAtsInternalNoteInsideArea = autoInternalNoteProcedure.inside_area;
        this.autoAtsInternalNoteMessage = autoInternalNoteProcedure.ats_internal_note;
      }
      this.autoImpactingZone = Boolean(this.customLayerToEdit.auto_impacting_zone_procedure);
      if (this.autoImpactingZone) {
        const autoImpactingZoneProcedure = this.customLayerToEdit.auto_impacting_zone_procedure;
        this.autoImpactingZoneInsideArea = autoImpactingZoneProcedure.inside_area;
        this.autoImpactingZoneFloorHeightLimit = autoImpactingZoneProcedure.floor_limit;
        this.autoImpactingZoneHeightLimit = autoImpactingZoneProcedure.height_limit;
      }
      this.dateStart = this.customLayerToEdit.date_start;
      this.dateEnd = this.customLayerToEdit.date_end;
      this.heightReference = this.customLayerToEdit.height_reference;
      this.heightUnit = this.customLayerToEdit.height_display_unit;
    }
  },
  methods: {
    updateFillColor(fillColor) {
      this.$store.dispatch(CHANGE_DRAW_LAYER_STYLE_NS, { fillColor });
    },
    updateStrokeColor(strokeColor) {
      this.$store.dispatch(CHANGE_DRAW_LAYER_STYLE_NS, { strokeColor });
    },
    feetToMeters(valueInFeet) {
      return Math.round(valueInFeet * FOOT);
    },
    metersToFeet(valueInMeters) {
      return Math.round(valueInMeters / FOOT);
    },
    buildCustomLayersParams() {
      const areas = this.currentArea.coordinates.map((polygon) => {
        const area = this.$turf.polygon(polygon, { name: this.layerName });
        return area;
      });

      let autoAcceptProcedure;
      if (this.autoAccept) {
        autoAcceptProcedure = {
          acceptance_message: this.autoAcceptMessage,
          height_limit: this.autoAcceptHeightLimitInMeters,
          floor_limit: this.autoAcceptFloorHeightLimitInMeters,
        };
      }

      let autoRestrictionProcedure;
      if (this.autoRestriction) {
        autoRestrictionProcedure = {
          restriction_message: this.autoRestrictionMessage,
          height_limit: this.autoRestrictionHeightLimitInMeters,
          floor_limit: this.autoRestrictionFloorHeightLimitInMeters,
          height_restriction_activated: this.heightRestrictionActivated,
          height_restriction: this.heightRestriction,
          tactical_contact_activated: this.tacticalContactActivated,
        };
      }

      let autoRefuseProcedure;
      if (this.autoRefuse) {
        autoRefuseProcedure = {
          refuse_message: this.autoRefuseMessage,
          height_limit: this.autoRefuseHeightLimitInMeters,
          floor_limit: this.autoRefuseFloorHeightLimitInMeters,
        };
      }

      let autoInformContactProcedure;
      if (this.autoInformContact) {
        autoInformContactProcedure = {
          inside_area: this.autoInformContactInsideArea,
          emails: this.autoInformContactEmails.selected,
        };
      }

      let autoAtsInternalNoteProcedure;
      if (this.autoAtsInternalNote) {
        autoAtsInternalNoteProcedure = {
          inside_area: this.autoAtsInternalNoteInsideArea,
          ats_internal_note: this.autoAtsInternalNoteMessage,
        };
      }

      let autoImpactingZoneProcedure;
      if (this.autoImpactingZone) {
        autoImpactingZoneProcedure = {
          inside_area: this.autoImpactingZoneInsideArea,
          height_limit: this.autoImpactingZoneHeightLimitInMeters,
          floor_limit: this.autoImpactingZoneFloorHeightLimitInMeters,
        };
      }

      return {
        name: this.layerName,
        description: this.layerDescription,
        fill_color: this.fillColor,
        stroke_color: this.strokeColor,
        default_visibility: this.layerVisibility,
        legend_center: this.legendCenter,
        areas,
        auto_accept_procedure: autoAcceptProcedure,
        auto_restrict_procedure: autoRestrictionProcedure,
        auto_refuse_procedure: autoRefuseProcedure,
        auto_inform_contact_procedure: autoInformContactProcedure,
        auto_ats_internal_note_procedure: autoAtsInternalNoteProcedure,
        auto_impacting_zone_procedure: autoImpactingZoneProcedure,
        date_start: this.dateStart,
        date_end: this.dateEnd,
        height_reference: this.heightReference,
        height_display_unit: this.heightUnit,
      };
    },
    async createLayer() {
      if (!this.currentArea) {
        this.showMessage(
          this.$gettext(`Please indicate the zone area, using draw tool or by uploading a KML
            file.`),
          'error',
        );
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        const params = this.buildCustomLayersParams();
        await API.createCustomLayer(params)
          .then(() => {
            this.showMessage(this.$gettext('Zone created successfully.'), 'success');
            this.close();
            this.$store.dispatch(REFRESH_MAP_DATA_NS);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async updateLayer() {
      if (!this.currentArea) {
        this.showMessage(
          this.$gettext(`Please indicate the zone area, using draw tool or by uploading a KML
            file.`),
          'error',
        );
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        const params = this.buildCustomLayersParams();
        await API.editCustomLayer(this.customLayerToEdit.id, params)
          .then(() => {
            this.showMessage(this.$gettext('Zone updated successfully.'), 'success');
            this.close();
            this.$store.dispatch(REFRESH_MAP_DATA_NS);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    close() {
      this.$emit('reset-custom-layer-to-edit');
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
      if (this.customLayerToEdit) {
        this.$store.dispatch(ENABLE_CUSTOM_LAYER_NS, { layerId: this.customLayerToEdit.id });
        if (this.customLayerToEdit.default_visibility) {
          this.$store.dispatch(SHOW_CUSTOM_LAYER_NS, { layerId: this.customLayerToEdit.id });
        }
      }
    },
    parseKMLFile(kmlFile) {
      const fileReader = new FileReader();
      fileReader.readAsText(kmlFile);
      fileReader.addEventListener('load', () => {
        const parsedKML = new DOMParser().parseFromString(fileReader.result, 'text/xml');
        const kmlToGeoJson = kml(parsedKML);
        const flatten = this.$turf.flatten(kmlToGeoJson);
        let { features } = flatten;
        const hasLineString = features.some((f) => f.geometry.type === 'LineString');
        features = features.filter(
          (f) => ['LineString', 'Polygon'].includes(f.geometry.type),
        ).map((f) => {
          if (f.geometry.type === 'LineString') {
            return this.$turf.lineToPolygon(f);
          }
          return f;
        });
        if (features.length === 0) {
          this.showMessage(
            this.$gettext(`Impossible to read this file. It must be a KML file with one or more
              polygon or line objects.`),
            'error',
          );
          return;
        }
        if (hasLineString) {
          this.showMessage(
            this.$gettext(`Any lines included in the KML file have been transformed from a line to
              a polygon and displayed on the map.`),
            'info',
          );
        }
        this.$store.dispatch(
          SET_LOADED_KML_NS,
          { features, multiple: true },
        );
      });
    },
    expand(type) {
      switch (type) {
        case 'accept':
          if (this.autoAccept) {
            this.expandAccept = true;
          }
          break;
        case 'restrict':
          if (this.autoRestriction) {
            this.expandRestriction = true;
          }
          break;
        case 'refuse':
          if (this.autoRefuse) {
            this.expandRefuse = true;
          }
          break;
        case 'inform-contact':
          if (this.autoInformContact) {
            this.expandInformContact = true;
          }
          break;
        case 'ats-internal-note':
          if (this.autoAtsInternalNote) {
            this.expandAtsInternalNote = true;
          }
          break;
        case 'impacting-zone':
          if (this.autoImpactingZone) this.expandImpactingZone = true;
          break;
        default:
          break;
      }
    },
    displayDialog() {
      if (this.automaticActionsActivated) {
        this.dialogAutomaticProcessSummary = true;
      } else {
        this.sendLayer();
      }
    },
    sendLayer() {
      this.dialogAutomaticProcessSummary = false;
      if (this.isEditing) {
        this.updateLayer();
      } else {
        this.createLayer();
      }
    },
    writeTacticalContactProtocols() {
      if (this.tacticalContactActivated && this.tacticalContactProtocols.length) {
        this.tacticalContactProtocols.forEach((tacticalContactProtocol) => {
          const message = `\n\n${tacticalContactProtocol}`;
          if (!this.autoRestrictionMessage.includes(message)) {
            this.autoRestrictionMessage += message;
          }
        });
      }
    },
    getLayerArea(layer) {
      return this.$turf.multiPolygon(layer.areas.map((area) => area.geometry.coordinates[0]));
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.map-checkbox ::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.layer-form__kml-file-input-label {
  text-decoration: underline;
  cursor: pointer;
}
.height-unit__select {
  max-width: 48px;
}

</style>
