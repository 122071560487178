<template>
  <div>
    <v-alert
      v-if="moreInformationsRequired"
      color="orange lighten-1"
      outlined
      text
      type="info"
    >
      <span>
        {{ informationsRequiredAlertText }}
      </span>
    </v-alert>
    <v-alert
      v-if="stripeVerification"
      color="grey"
      outlined
      text
      type="info"
    >
      <span>
        {{ stripeVerificationAlertText }}
      </span>
    </v-alert>
    <v-row v-if="showButton">
      <v-col
        cols="9"
        lg="8"
        md="6"
        sm="3"
        xs="0"
      />
      <v-col
        cols="3"
        align-self="center"
      >
        <v-skeleton-loader
          :loading="loading"
          type=heading
        >
          <v-btn
            color="primary"
            class="px-8"
            :loading="buttonLoading"
            @click="getOnboardingLink()"
          >
            <translate>Configure Payments</translate>
          </v-btn>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from '@/services/api';

export default {
  name: 'StripeOnboarding',
  data() {
    return {
      loading: false,
      buttonLoading: false,
      onBoardingStatus: 'pending',
    };
  },
  props: {
    structureId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    showButton() {
      return !(this.onBoardingStatus === 'completed');
    },
    moreInformationsRequired() {
      return this.onBoardingStatus === 'awaiting_informations';
    },
    stripeVerification() {
      return this.onBoardingStatus === 'stripe_verification';
    },
    informationsRequiredAlertText() {
      return this.$gettext(`Your payment configuration need more informations. Please complete your
        inscription by clicking on the button "Configure payments".`);
    },
    stripeVerificationAlertText() {
      return this.$gettext(`Stripe is verifying your informations. You may need to complete your
        configuration after the verification.`);
    },
  },
  created() {
    this.isOnboardingCompleted();
  },
  methods: {
    async isOnboardingCompleted() {
      this.loading = true;
      await API.getOnboardingInformations(this.structureId)
        .then(({ data }) => {
          this.onBoardingStatus = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getOnboardingLink() {
      this.buttonLoading = true;
      await API.getOnboardingLink(this.structureId)
        .then(({ data }) => {
          this.urlOnboarding = data;
          window.open(data, '_self');
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
</style>
