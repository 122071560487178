<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>AlphaTango Notification</translate>
    </v-card-title>
    <v-card-text v-if="canRequestNotifications">
      <div class="mt-2">
        {{ texts.notifInfoIntro }}
        {{ texts.notifInfoSendDate }}
      </div>
    </v-card-text>
    <v-card-text v-else>
      <NotifAlphaTangoRequirements
        :recipient="$gettext('to Ministry of the Armed Forces')"
        :missingData="missingData"
        :flightIsHSS="false"
      />
    </v-card-text>
    <!-- Action buttons -->
    <v-card-actions class="py-3">
      <v-spacer />
      <v-btn
        class="mx-3"
        @click="$emit('close-form')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        class="white--text mx-3"
        color="primary"
        :disabled="isConfirmButtonDisabled"
        :loading="sendingFlightNotifyRequest"
        @click="notifyFlightArmy()"
      >
        <translate>Send</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { GET_EXPLOITATION_DETAILS_NS } from '@/store/exploitants';
import { GET_FLIGHT_DETAILS_NS, GET_FLIGHT_STATUS_NS } from '@/store/flights';

import NotifAlphaTangoRequirements from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoRequirements.vue';

export default {
  name: 'NotifAlphaTangoArmyForm',
  components: { NotifAlphaTangoRequirements },
  props: {
    flight: {
      type: Object,
      required: true,
    },
    update: {
      type: Boolean,
      default: false,
    },
    notificationsToUpdate: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return { sendingFlightNotifyRequest: false };
  },
  computed: {
    texts() {
      return {
        notifInfoIntro: this.$gettext(`By clicking on send, Clearance can schedule notifications
          to the Ministry of the Armed Forces for this mission.`),
        notifInfoSendDate: this.$gettext(`Notifications are generally sent one day or two days
          before the flight day.`),
      };
    },
    exploitationAlphaTangoUids() {
      return this.$store.state.exploitants.exploitationDetails.alphatango_uids;
    },
    flightHasPilots() {
      return this.flight.pilots.length > 0;
    },
    flightHasDrones() {
      return this.flight.drones.length > 0;
    },
    missingData() {
      return {
        missingATNumber: !this.exploitationAlphaTangoUids.length,
        missingDrone: !this.flightHasDrones,
        missingPilot: !this.flightHasPilots,
      };
    },
    hasMissingData() {
      return Object.values(this.missingData).includes(true);
    },
    canRequestNotifications() {
      return !this.hasMissingData;
    },
    isConfirmButtonDisabled() {
      return !this.canRequestNotifications || this.sendingFlightNotifyRequest;
    },
  },
  created() {
    this.fetchExploitationEdNumber();
  },
  methods: {
    notifyFlightArmy() {
      this.sendingFlightNotifyRequest = true;
      let promise;
      if (this.update) {
        promise = APIService.updateArmyNotifs(
          this.flight.id,
          this.notificationsToUpdate.map((n) => n.id),
          {},
          false,
        );
      } else {
        promise = APIService.notifyArmy(this.flight.id, {}, false);
      }
      promise
        .then(({ data }) => {
          this.showMessage(data, 'success');
        })
        .finally(() => {
          this.sendingFlightNotifyRequest = false;
          this.$emit('close-form');
          this.$store.dispatch(GET_FLIGHT_DETAILS_NS, { flightId: this.flight.id });
          this.$store.dispatch(GET_FLIGHT_STATUS_NS, { flightId: this.flight.id });
        });
    },
    fetchExploitationEdNumber() {
      const { exploitationDetails } = this.$store.state.exploitants;
      if (Object.keys(exploitationDetails).length === 0) {
        const exploitationId = this.$store.state.authentication.user.exploitation_id;
        this.$store.dispatch(GET_EXPLOITATION_DETAILS_NS, exploitationId);
      }
    },
  },
};

</script>
