<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Change my subscription</translate>
    </v-card-title>
    <v-card-text>
      <v-alert
        v-if="userOldPlans"
        dense
        text
        type="info"
        color="primary"
        class="body-2 py-3 mb-0"
      >
        {{ oldPlanChangeAlert }}
      </v-alert>
      <v-list
        two-line
        disabled
        class="pb-0"
      >
        <!-- Current formule -->
        <v-list-item class="disabled">
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center flex-wrap mb-2">
              <translate class="formule-title">
                Current formule:
              </translate>
              <v-icon
                color="primary"
                class="ml-2 mr-1 mdi-rotate-90"
              >
                {{ currentFormule.icon }}
              </v-icon>
              <span>
                {{ currentFormule.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div v-html="currentFormule.description" />
              <div v-html="currentFormule.price" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <!-- Target formule -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center flex-wrap mb-2">
              <translate class="formule-title">
                New formule:
              </translate>
              <v-icon
                color="primary"
                class="ml-2 mr-1 mdi-rotate-90"
              >
                {{ targetFormule.icon }}
              </v-icon>
              <span>
                {{ targetFormule.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div v-html="targetFormule.description" />
              <div v-html="targetFormule.price" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-skeleton-loader
        v-if="prorateLoading"
        :loading="prorateLoading"
        type="text"
        class="px-4"
      />
      <template v-else>
        <!-- Promo code -->
        <v-row v-if="!promoCodeApplied">
          <v-col :class="showPromoCodeForm ? 'py-0' : ''">
            <template v-if="!showPromoCodeForm">
              <div
                @click="showPromoCodeForm=true"
                class="subtitle-1 info--text font-italic text-decoration-underline cursor-pointer"
              >
                <translate>Apply a coupon code</translate>
              </div>
            </template>
            <template v-else>
              <v-text-field
                v-model="promoCode"
                outlined
                dense
                class="details-no-margin"
              >
                <template v-slot:append>
                  <v-btn
                    small
                    text
                    color="primary"
                    :loading="prorateLoading"
                    @click="getPreviewChangeSubscriptionPlan()"
                  >
                    <translate>Apply</translate>
                  </v-btn>
                </template>
                <template v-slot:append-outer>
                  <v-icon
                    small
                    class="pt-1"
                    @click="showPromoCodeForm=false"
                  >
                    mdi-close
                  </v-icon>
                </template>
              </v-text-field>
            </template>
          </v-col>
        </v-row>
        <div
          v-else
          class="d-flex align-center mb-4"
        >
          <span
            v-translate
            class="info--text"
          >
            Promotion code applied:
          </span>
          <div class="primary--text ml-1">
            <span>
              {{ discount.name }}
            </span>
            <span v-if="discount.percent_off">
              (-{{ discount.percent_off }}%)
            </span>
            <translate>for an amount of</translate>
            <span>
              {{ discount.amount }} €
            </span>
            <translate v-if="discount.permanent">
              on all next invoices
            </translate>
            <translate v-else>
              only for the next invoice
            </translate>
            <v-icon
              small
              @click="promoCode=null;getPreviewChangeSubscriptionPlan();"
              class="ml-1"
            >
              mdi-close
            </v-icon>
          </div>
        </div>
        <span
          v-if="prorateError"
          v-translate
          class="font-italic red--text text--darken-4"
        >
          An error has occured while calculating prorate.
        </span>
        <span
          v-else
          class="info--text multilines"
        >
          {{ prorateDetails }}
        </span>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        class="px-3"
        @click="$emit('change-cancelled')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="px-3"
        @click="changeSubscriptionPlan()"
        :loading="changeLoading"
      >
        <translate>Confirm the change</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { isToday } from 'date-fns';

import API from '@/services/api';

import { REFRESH_USER_DATA_NS } from '@/store/authentication';

export default {
  name: 'SubscriptionPlanChange',
  props: {
    currentFormule: {
      type: Object,
      required: true,
    },
    targetFormule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      prorateLoading: true,
      prorateError: false,
      quantity: null,
      immediateAmountHT: null,
      immediateAmountTTC: null,
      subscriptionAmountHTInitial: null,
      subscriptionAmountHT: null,
      subscriptionAmountTTC: null,
      nextInvoiceDateISO: null,
      discount: null,
      showPromoCodeForm: false,
      promoCode: null,
      changeLoading: false,
    };
  },
  computed: {
    renewalPeriod() {
      if (this.targetFormule.formule === 'monthly') {
        return this.$gettext('every month');
      }
      if (this.targetFormule.formule === 'yearly') {
        return this.$gettext('every year');
      }
      return '';
    },
    immediateAmountString() {
      const immediateAmountHTString = `${Math.abs(this.immediateAmountHT)}`.replace('.', ',');
      const immediateAmountTTCString = `${Math.abs(this.immediateAmountTTC)}`.replace('.', ',');
      return `${immediateAmountHTString} € HT (${immediateAmountTTCString} € TTC)`;
    },
    subscriptionAmountString() {
      const subscriptionAmountHTString = `${this.subscriptionAmountHT}`.replace('.', ',');
      const subscriptionAmountTTCString = `${this.subscriptionAmountTTC}`.replace('.', ',');
      if (this.discount?.permanent === false) {
        const subscriptionAmountHTInitialString = `${this.subscriptionAmountHTInitial}`.replace(
          '.', ',',
        );
        return `${subscriptionAmountHTInitialString} € HT`;
      }
      return `${subscriptionAmountHTString} € HT (${subscriptionAmountTTCString} € TTC)`;
    },
    nextInvoiceDate() {
      return new Date(this.nextInvoiceDateISO);
    },
    nextInvoiceDateLocale() {
      return this.nextInvoiceDate.toLocaleDateString(
        this.$store.getters['application/currentLanguage'],
      );
    },
    prorateDetails() {
      return this.getProrateDetails();
    },
    userOldPlans() {
      return ['FF_M', 'FF_Y', 'INDEP_M', 'INDEP_Y'].includes(this.currentFormule.code);
    },
    oldPlanChangeAlert() {
      return this.$gettext(`Please note that your current subscription plan will no longer be
        available. If you choose to change to the new pricing you will not be able to return to
        your current plan.`);
    },
    promoCodeApplied() {
      return this.discount !== null;
    },
  },
  mounted() {
    this.getPreviewChangeSubscriptionPlan();
  },
  methods: {
    changeSubscriptionPlan() {
      this.changeLoading = true;
      API.changeSubscriptionPlan(
        { code: this.targetFormule.code, quantity: this.quantity, promo_code: this.promoCode },
      )
        .then(() => {
          this.showMessage(this.$gettext('Subscription changed successfully.'), 'success');
          this.$store.dispatch(REFRESH_USER_DATA_NS);
          this.$emit('change-confirmed');
        })
        .catch(() => {
          this.$emit('change-cancelled');
        })
        .finally(() => {
          this.changeLoading = false;
        });
    },
    getProrateDetails() {
      if (this.immediateAmountTTC > 0) {
        // Case 1: Immediate proration charge + no change in billing cycle.
        return this.$gettextInterpolate(
          this.$gettext(`You will be charged today %{immediateAmount} for the subscription
            change.\nYour new subscription for %{quantity} pilot will be renewed %{renewalPeriod}
            and your next invoice of %{subscriptionAmount} will be due %{nextInvoiceDateLocale}.`),
          {
            immediateAmount: this.immediateAmountString,
            quantity: this.quantity,
            renewalPeriod: this.renewalPeriod,
            subscriptionAmount: this.subscriptionAmountString,
            nextInvoiceDateLocale: this.nextInvoiceDateLocale,
          },
        );
      }
      if (this.immediateAmountTTC <= 0) {
        // Case 2: Change in billing cycle: immediate payment for subscription + proration
        if (isToday(this.nextInvoiceDate)) {
          if (Math.abs(this.immediateAmountTTC) > this.subscriptionAmountTTC) {
            const credit = (
              Math.abs(this.immediateAmountTTC) - this.subscriptionAmountTTC
            ).toFixed(2);
            return this.$gettextInterpolate(
              this.$gettext(`The amount of your new subscription for %{quantity} pilot is
                %{subscriptionAmount}.\nYour subscription will be renewed %{renewalPeriod}. You
                have a credit of %{credit} that will be deducted from your next payments.`),
              {
                quantity: this.quantity,
                subscriptionAmount: this.subscriptionAmountString,
                renewalPeriod: this.renewalPeriod,
                credit: `${credit} €`,
              },
            );
          }
          const todayPayment = (
            this.subscriptionAmountTTC - Math.abs(this.immediateAmountTTC)
          ).toFixed(2);
          return this.$gettextInterpolate(
            this.$gettext(`For your new subscription for %{quantity} pilot, you will be charged
              %{todayPayment} today. You will then be charged %{subscriptionAmount}
              %{renewalPeriod}.`),
            {
              quantity: this.quantity,
              todayPayment: `${todayPayment} €`,
              subscriptionAmount: this.subscriptionAmountString,
              renewalPeriod: this.renewalPeriod,
            },
          );
        }
        // Case 3: credit deducted from next invoice no change in billing cycle no immediat payment.
        let detailsNextInvoice = '';
        if (this.immediateAmountTTC === 0) {
          detailsNextInvoice = this.$gettextInterpolate(
            this.$gettext(`Your next invoice of %{subscriptionAmount} is due on
              %{nextInvoiceDateLocale}.\n`),
            {
              subscriptionAmount: this.subscriptionAmountString,
              nextInvoiceDateLocale: this.nextInvoiceDateLocale,
            },
          );
        } else {
          detailsNextInvoice = this.$gettextInterpolate(
            this.$gettext(`You have a credit of %{immediateAmount} that will be deducted from your
              next invoice of %{subscriptionAmount} due on %{nextInvoiceDateLocale}.\n`),
            {
              immediateAmount: this.immediateAmountString,
              subscriptionAmount: this.subscriptionAmountString,
              nextInvoiceDateLocale: this.nextInvoiceDateLocale,
            },
          );
        }
        return this.$gettextInterpolate(
          this.$gettext(`There is nothing due today for the subscription change.\n
            %{detailsNextInvoice} Your new subscription for %{quantity} pilot will be renewed
            %{renewalPeriod}.`),
          {
            detailsNextInvoice,
            quantity: this.quantity,
            renewalPeriod: this.renewalPeriod,
          },
        );
      }
      this.prorateError = true;
      return '';
    },
    async getPreviewChangeSubscriptionPlan() {
      this.prorateLoading = true;
      await API.previewChangeSubscriptionPlan(
        { code: this.targetFormule.code, promo_code: this.promoCode },
      )
        .then(({ data }) => {
          ({
            quantity: this.quantity,
            immediate_amount_ht: this.immediateAmountHT,
            immediate_amount_ttc: this.immediateAmountTTC,
            subscription_amount_ht_initial: this.subscriptionAmountHTInitial,
            subscription_amount_ht: this.subscriptionAmountHT,
            subscription_amount_ttc: this.subscriptionAmountTTC,
            next_invoice_due_date: this.nextInvoiceDateISO,
            discount: this.discount,
          } = data);
        })
        .catch(() => {
          this.prorateError = true;
        })
        .finally(() => {
          this.showPromoCodeForm = false;
          this.prorateLoading = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.disabled {
  opacity: 0.55;
}
.formule-title {
  text-transform: uppercase;
}
.details-no-margin ::v-deep .v-text-field__details {
  margin: 0px;
}

</style>
