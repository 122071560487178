import { LAYER_STYLES } from '@/components/Map/MapLayerStyles';

/**
 * Create HTML element add adds it to parent container
 * @param {String} tagName HTML tag
 * @param {Object} properties element properties
 * @param {Object} container element container
 */
const create = (tagName, properties = {}, container) => {
  const el = window.document.createElement(tagName);
  Object.keys(properties).forEach((p) => { el[p] = properties[p]; });
  if (container) container.appendChild(el);
  return el;
};

const DEFAULT_OPTIONS = {
  iconClass: 'icon material-icons',
  iconMaterialClass: 'layers',
  checkboxName: 'map-control-sources',
  radioName: 'map-control-layer',
  radioInputCategoryStyle: 'margin-right: 0.5rem; vertical-align: middle;',
  radioInputStyle: 'margin-inline: 0.5rem; vertical-align: middle;',
  disabledStyle: 'color: grey;',
  buttonAddStyle: 'color: #1d6ff2; text-decoration: underline; cursor: pointer;',
  formStyle: 'padding: 1rem; width: 22rem; max-height: 400px; margin-left: 0.5rem;',
  tooltipStyle: 'position: relative; width: 29px; height: 29px; cursor: pointer;',
  tooltipTextStyle: `visibility: hidden; width: 240px; background-color: #555; color: #fff;
    text-align: center; border-radius: 6px; padding: 5px 0; position: absolute; z-index: 1;
    right: 120%; bottom: auto; top: auto; opacity: 0; transition: opacity 0.3s; cursor: auto;
    font-size: .75rem; letter-spacing: .0333333333em; line-height: 1.25rem; font-weight: 400;`,
  baseLayers: [], // array of { label, mapboxUrl }
  mapsData: [],
  persistentLayerIds: Object.keys(LAYER_STYLES).filter((key) => LAYER_STYLES[key].persistent),
};

class MapLayersControl {
  constructor(options) {
    this._options = { ...DEFAULT_OPTIONS, ...options };

    this._container = create('div', { className: 'mapboxgl-ctrl mapboxgl-ctrl-group' });
    this._container.addEventListener('contextmenu', (e) => e.preventDefault());

    this.radios = [];
    this.categoryCheckboxes = {};
    this.checkboxes = {};
    this.labels = {};
    this.infoIcons = {};
    this.expandIcons = {};
    this.editIcons = {};
    this.deleteIcons = {};
    this._baseLayers = this._options.baseLayers;
    this._mapsData = this._options.mapsData;
    this.persistentLayerIds = this._options.persistentLayerIds;
    this.isUserDronist = this._options.isUserDronist;
    this.isSubscriptionActive = this._options.isSubscriptionActive;
    this.isUserEnterprise = this._options.isUserEnterprise;
    this.isUserGeneralist = this._options.isUserGeneralist;
    this.recentImportedLayers = this._options.recentImportedLayers;
    this._createMainForm();
    this._createMainButton();
    this.activatedLayer = this._baseLayers.reduce(
      (prev, current) => (
        prev.displayPriority > current.displayPriority ? prev : current
      ),
      {},
    );
  }

  activateLayer(map, identifier) {
    if (this.activatedLayer.identifier !== identifier) {
      this.activatedLayer.deactivate(map);
      const layer = this._baseLayers.find((obj) => obj.identifier === identifier);
      layer.activate({ map });
      this.activatedLayer = layer;
    }
  }

  _createMainButton() {
    this._mainButton = create(
      'button',
      { className: 'mapboxgl-ctrl-icon list-maps-button' },
      this._container,
    );

    this._mainButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.openSelectionMenu();
    });
    this._mainButton.addEventListener('mouseenter', (e) => {
      e.preventDefault();
      this.openSelectionMenu();
    });

    create('i', {
      className: this._options.iconClass,
      innerText: this._options.iconMaterialClass,
    }, this._mainButton);
  }

  hideMainFormOnMouseLeaveAfterDelay() {
    let interval;
    this._mainForm.addEventListener('mouseleave', (event) => {
      event.preventDefault();
      interval = setInterval(() => {
        this.closeSelectionMenu();
        clearInterval(interval);
      }, 800);
    });
    this._mainForm.addEventListener('mouseenter', () => {
      clearInterval(interval);
    });
  }

  _createMainForm() {
    this._mainForm = create('form', { id: 'main-form', style: this._options.formStyle });
    // Basemaps section (radios)
    this.createBaseMapLegend();
    // Data layers section (checkboxes)
    // Checkboxes for layer groups (action on non persistent layers only)
    this.createDataLayersLegend();
    // Tracking layers of authorities (checkboxes)
    this.createTrackingLayersLegend();
    // Custom layers of authorities (checkboxes)
    this.createCustomLayersLegend();
    if (this.isUserDronist) {
      // Network site and coverage layers
      this.createNetworkLayersLegend();
      // Geoportail layers (checkboxes)
      this.createGeoportailLayersLegend();
      // Custom data layer of dronists (checkboxes)
      this.createCustomDataLayersLegend();
    }
    this.hideMainFormOnMouseLeaveAfterDelay();
  }

  createBaseMapLegend() {
    if (this._baseLayers && this._baseLayers.length > 0) {
      const titleDiv = create('div', { className: 'd-flex align-center' }, this._mainForm);
      create('h3', { innerText: 'Fonds de carte' }, titleDiv);
      if (this.isUserDronist) {
        this.addIconInfo({
          identifier: 'articleHandleMapLayers',
          informationUrl: 'https://clearance.aero/fonds-de-carte-drone-pour-preparer-votre-mission/',
        }, titleDiv);
      }
      this._baseLayers.forEach((currentLayer, index) => {
        const div = create('div', {}, this._mainForm);
        const radio = create('input', {
          type: 'radio',
          id: currentLayer.name,
          value: currentLayer.identifier,
          name: this._options.radioName,
          style: this._options.radioInputStyle,
          checked: index === 0,
          disabled: !currentLayer.accessGranted,
        }, div);

        let radioLabelStyle = '';
        if (!currentLayer.accessGranted) {
          radioLabelStyle = this._options.disabledStyle;
        }
        const label = create('label', {
          for: currentLayer.id,
          innerText: currentLayer.name,
          style: radioLabelStyle,
        }, div);
        if (!currentLayer.accessGranted) {
          create('span', {
            className: 'font-italic',
            innerText: ' - ABONNÉS',
          }, label);
        }
        this.radios.push(radio);
      });
    }
  }

  createNetworkLayersLegend() {
    const networkLayers = this._mapsData.filter((group) => group.category === 'mobile_network');

    if (networkLayers) {
      const parent = this.addCategoryTitle({
        title: 'Réseau mobile',
        category: 'mobile_network',
        layerIdentifiers: networkLayers.map((layerGroup) => layerGroup.identifier),
        layerIds: networkLayers.flatMap((layerGroup) => layerGroup.layerIds),
      });
      networkLayers.forEach((layerGroup) => {
        this.addActionButtons({
          key: layerGroup.identifier,
          label: layerGroup.name,
          layerIds: layerGroup.layerIds,
          visibility: layerGroup.defaultVisibility,
          accessGranted: layerGroup.accessGranted,
          category: layerGroup.category,
          informationUrl: layerGroup.informationUrl,
          editable: layerGroup.editable,
          deletable: layerGroup.deletable,
          status: layerGroup.status,
          parent,
          defaultIsOpen: false,
        });
      }, this);
      this.addNetworkLayersButton = this._createAddNetworkLayersButton(parent);
    }
  }

  createDataLayersLegend() {
    const dataLayers = this._mapsData.filter((group) => group.category === 'data');
    if (dataLayers && dataLayers.length > 0) {
      const parent = this.addCategoryTitle({
        title: 'Données',
        category: 'data',
        layerIdentifiers: dataLayers.map((layerGroup) => layerGroup.identifier),
        layerIds: dataLayers.flatMap((layerGroup) => layerGroup.layerIds),
      });
      dataLayers.forEach((layerGroup) => {
        this.addActionButtons({
          key: layerGroup.identifier,
          label: layerGroup.name,
          layerIds: layerGroup.layerIds,
          visibility: layerGroup.defaultVisibility,
          accessGranted: layerGroup.accessGranted,
          category: layerGroup.category,
          informationUrl: layerGroup.informationUrl,
          editable: layerGroup.editable,
          deletable: layerGroup.deletable,
          status: layerGroup.status,
          parent,
          defaultIsOpen: true,
        });
      }, this);
    }
  }

  createTrackingLayersLegend() {
    const trackingLayers = this._mapsData.filter((group) => group.category === 'tracking');
    if (trackingLayers && trackingLayers.length > 0) {
      const parent = this.addCategoryTitle({
        title: 'Détection',
        category: 'tracking',
        layerIdentifiers: trackingLayers.map((layerGroup) => layerGroup.identifier),
        layerIds: trackingLayers.flatMap((layerGroup) => layerGroup.layerIds),
      });
      trackingLayers.forEach((layerGroup) => {
        this.addActionButtons({
          key: layerGroup.identifier,
          label: layerGroup.name,
          layerIds: layerGroup.layerIds,
          visibility: layerGroup.defaultVisibility,
          accessGranted: true,
          category: layerGroup.category,
          informationUrl: layerGroup.informationUrl,
          editable: layerGroup.editable,
          deletable: layerGroup.deletable,
          status: layerGroup.status,
          parent,
          defaultIsOpen: false,
        });
      }, this);
    }
  }

  createCustomLayersLegend() {
    const customLayers = this._mapsData.filter((group) => group.category === 'custom');
    if (customLayers && customLayers.length > 0) {
      const parent = this.addCategoryTitle({
        title: 'Zones',
        category: 'custom',
        layerIdentifiers: customLayers.map((layerGroup) => layerGroup.identifier),
        layerIds: customLayers.flatMap((layerGroup) => layerGroup.layerIds),
      });
      customLayers.forEach((layerGroup) => {
        this.addActionButtons({
          key: layerGroup.identifier,
          label: layerGroup.name,
          layerIds: layerGroup.layerIds,
          visibility: layerGroup.defaultVisibility,
          accessGranted: true,
          category: layerGroup.category,
          informationUrl: layerGroup.informationUrl,
          editable: layerGroup.editable,
          deletable: layerGroup.deletable,
          status: layerGroup.status,
          parent,
          defaultIsOpen: false,
        });
      }, this);
    }
  }

  createGeoportailLayersLegend() {
    const customGeoportailLayers = this._mapsData.filter(
      (group) => group.category === 'geoportail_wmts',
    ).sort((a, b) => a.name > b.name);
    const parent = this.addCategoryTitle({
      title: 'Géoportail',
      category: 'geoportail_wmts',
      layerIdentifiers: customGeoportailLayers.map((layerGroup) => layerGroup.identifier),
      layerIds: customGeoportailLayers.flatMap((layerGroup) => layerGroup.layerIds),
    });
    customGeoportailLayers.forEach((layerGroup) => {
      this.addActionButtons({
        key: layerGroup.identifier,
        label: layerGroup.name,
        layerIds: layerGroup.layerIds,
        visibility: layerGroup.defaultVisibility,
        accessGranted: layerGroup.accessGranted,
        category: layerGroup.category,
        informationUrl: layerGroup.informationUrl,
        editable: layerGroup.editable,
        deletable: layerGroup.deletable,
        status: layerGroup.status,
        parent,
        defaultIsOpen: false,
      });
    }, this);
    if (!this.isUserGeneralist) {
      this.addGeoportailLayersButton = this._createAddGeoportailLayerButton(parent);
    }
  }

  createCustomDataLayersLegend() {
    const customDataLayers = this._mapsData.filter((group) => group.category === 'custom_data');
    if (customDataLayers && customDataLayers.length > 0) {
      const parent = this.addCategoryTitle({
        title: 'Mes données',
        category: 'custom_data',
        layerIdentifiers: customDataLayers.map((layerGroup) => layerGroup.identifier),
        layerIds: customDataLayers.flatMap((layerGroup) => layerGroup.layerIds),
      });
      customDataLayers.forEach((layerGroup) => {
        this.addActionButtons({
          key: layerGroup.identifier,
          label: layerGroup.name,
          layerIds: layerGroup.layerIds,
          visibility: layerGroup.defaultVisibility,
          accessGranted: layerGroup.accessGranted,
          category: layerGroup.category,
          informationUrl: layerGroup.informationUrl,
          editable: layerGroup.editable,
          deletable: layerGroup.deletable,
          status: layerGroup.status,
          parent,
          defaultIsOpen: false,
        });
      }, this);
    }
    if (!this.isUserGeneralist) {
      this.addCustomLayerButton = this._createAddCustomLayerButton();
    }
  }

  addCategoryTitle({ title, category, layerIdentifiers, layerIds }) {
    const div = create('div', { className: 'd-flex align-center mt-1' }, this._mainForm);
    const isChecked = category !== 'mobile_network';
    const checkbox = create('input', {
      type: 'checkbox',
      id: category,
      value: category,
      name: this._options.checkboxName,
      style: this._options.radioInputCategoryStyle,
      checked: isChecked,
    }, div);
    this.categoryCheckboxes[category] = {
      checkbox,
      layerIdentifiers,
      layerIds,
    };
    create('h3', { innerText: title, style: 'vertical-align: middle;' }, div);
    const colDiv = create('div', { className: 'flex-direction: column' }, this._mainForm);
    if (category === 'data') this.addMenuDownButton(category.toLowerCase(), div, colDiv, true);
    else this.addMenuDownButton(category.toLowerCase(), div, colDiv, false);
    return colDiv;
  }

  _createAddNetworkLayersButton(parent) {
    const div = create(
      'div', { className: 'flex-column justify-center mt-2', style: 'display: none;' }, parent,
    );
    let style = this._options.buttonAddStyle;
    if (!this.isSubscriptionActive) {
      style += this._options.disabledStyle;
    }
    create('input', {
      type: 'button',
      value: '+ Ajouter des couches de réseau mobile',
      name: 'buttonAddMobileNetworkLayer',
      style,
    }, div);
    if (!this.isSubscriptionActive) {
      create('span', {
        className: 'd-flex justify-center font-italic',
        innerText: 'ABONNÉS',
        style,
      }, div);
    }
    return div;
  }

  _createAddGeoportailLayerButton(parent) {
    const div = create(
      'div', { className: 'flex-column justify-center mt-2', style: 'display: none;' }, parent,
    );
    let style = this._options.buttonAddStyle;
    if (!this.isSubscriptionActive) {
      style += this._options.disabledStyle;
    }
    create('input', {
      type: 'button',
      value: '+ Ajouter des couches Géoportail',
      name: 'buttonAddGeoportailLayer',
      style,
    }, div);
    if (!this.isSubscriptionActive) {
      create('span', {
        className: 'd-flex justify-center font-italic',
        innerText: 'ABONNÉS',
        style,
      }, div);
    }
    return div;
  }

  _createAddCustomLayerButton() {
    const div = create(
      'div', { className: 'd-flex flex-column justify-center mt-2' }, this._mainForm,
    );
    let style = this._options.buttonAddStyle;
    if (!this.isUserEnterprise) {
      style += this._options.disabledStyle;
    }
    create('input', {
      type: 'button',
      value: '+ Ajouter mes données',
      name: 'buttonAddMyData',
      style,
    }, div);
    if (!this.isUserEnterprise) {
      create('span', {
        className: 'd-flex justify-center font-italic',
        innerText: 'ENTREPRISE',
        style,
      }, div);
    }
    return div;
  }

  /**
   * Create action buttons like add a checkbox to _mainForm to toggle the visibility of the
   * associated layers.
   * @param {Object}
   *    key: id for the checkbox
   *    label: text displayed on the form
   *    layers: list of layers associated with the checkbox
   *    visibility: define if the checkbox if checked or not
   *    accessGranted: disable the checkbox
   *    category: category of the group layers
   *    informationUrl: information of the group layers (used for Geoportail layers)
   *    editable: display an edit icon to edit group layers
   *    deletable: display a bin icon to delete group layers
   *    status: status of the group layers (used for layers uploaded on MapBox if failed)
   */
  addActionButtons(
    {
      key,
      label,
      layerIds,
      visibility,
      accessGranted,
      category,
      informationUrl,
      editable,
      deletable,
      status,
      parent,
      defaultIsOpen,
    } = {},
  ) {
    const div = create('div', { className: 'pa-1', style: `display: ${defaultIsOpen === true ? 'flex' : 'none'};` }, parent);
    this.addCheckboxButton({ key, accessGranted, visibility, layerIds }, div);
    this.addLabel({ label, accessGranted, key }, div, category === 'mobile_network');
    this.addFailedInfo({ identifier: key, status }, div);
    this.addIconInfo({ identifier: key, informationUrl }, div);
    if (!this.isUserGeneralist) {
      this.addEditButton(
        {
          editable,
          accessGranted,
          status,
          category,
          identifier: key,
          name: label,
          layerIds,
          visibility,
        },
        div,
      );
      this.addDeleteIcon({ deletable, category, identifier: key, name: label }, div);
    }
  }

  addCheckboxButton({ key, accessGranted, visibility, layerIds }, container) {
    const checkbox = create('input', {
      type: 'checkbox',
      id: key,
      value: key,
      name: this._options.checkboxName,
      style: this._options.radioInputStyle,
      disabled: !accessGranted,
    }, container);
    if (visibility === true) {
      checkbox.checked = true;
    }
    this.checkboxes[key] = {
      checkbox,
      layerIds,
    };
  }

  addLabel({ label, accessGranted, key }, container, isDisabled) {
    const labelButton = create('label', {
      for: key,
      innerText: label,
      className: 'flex-grow-1 flex-shrink-1 align-self-center',
      style: `${isDisabled ? this._options.disabledStyle : ''};`,
    }, container);
    if (!accessGranted) {
      create('span', {
        className: 'font-italic',
        innerText: ' - ABONNÉS',
        style: this._options.disabledStyle,
      }, labelButton);
    }
    this.labels[key] = {
      label: labelButton,
      accessGranted,
    };
  }

  updateDisabledLabels({ categoryChecked, layerIdentifiers }) {
    layerIdentifiers.forEach((layerIdentifier) => {
      const { label, accessGranted } = this.labels[layerIdentifier];
      if (!accessGranted) return;
      label.style = categoryChecked ? '' : `${this._options.disabledStyle}`;
    });
  }

  addFailedInfo({ identifier, status }, container) {
    if (status === 'failed') {
      const errorSpan = create('span', {
        className: 'mdi mdi-alert-outline d-flex align-center justify-center error--text text-h6',
        style: this._options.tooltipStyle,
        id: `${identifier}-failed`,
      }, container);
      const errorTooltip = create('span', {
        className: 'px-2',
        style: this._options.tooltipTextStyle,
        innerText: "Une erreur est survenue lors de l'import de cette couche. Veuillez nous contacter à support@clearance.aero.",
      }, errorSpan);
      errorSpan.addEventListener('mouseover', () => {
        errorTooltip.style.visibility = 'visible';
        errorTooltip.style.opacity = 1;
      });
      errorSpan.addEventListener('mouseleave', () => {
        errorTooltip.style = this._options.tooltipTextStyle;
      });
    }
  }

  addMenuDownButton(identifier, container, block, defaultIsOpen) {
    const icon = create('button', {
      className: 'mdi mdi-menu-down mdi-18px',
      id: `icon-${identifier}`,
    }, container);

    this.expandIcons[identifier] = {
      identifier,
      icon,
      defaultIsOpen,
      block,
    };
    return icon;
  }

  addIconInfo({ identifier, informationUrl }, container) {
    if (informationUrl) {
      const icon = create('button', {
        className: 'mdi mdi-information-outline mdi-18px',
        id: `icon-${identifier}`,
      }, container);
      this.infoIcons[identifier] = {
        icon,
        identifier,
        informationUrl,
      };
    }
  }

  addEditButton(
    { editable, accessGranted, status, category, identifier, name, layerIds, visibility },
    container,
  ) {
    if (
      editable
      && accessGranted
      && status !== 'failed'
      && ['data', 'geoportail_wmts', 'custom_data', 'mobile_network'].includes(category)
    ) {
      // Add a div to delete Mapbox default style
      const div = create('div', {}, container);
      const icon = create('button', {
        className: 'mdi mdi-pencil-outline mdi-18px',
        id: `edit-${identifier}`,
      }, div);
      this.editIcons[identifier] = {
        icon,
        category,
        identifier,
        name,
        layerIds,
        visibility,
      };
    }
  }

  addDeleteIcon({ deletable, category, identifier, name }, container) {
    if (deletable) {
      // Add a div to delete Mapbox default style
      const div = create('div', {}, container);
      const icon = create('button', {
        className: 'mdi mdi-trash-can-outline mdi-18px',
        id: `delete-${identifier}`,
      }, div);
      this.deleteIcons[identifier] = {
        category,
        icon,
        identifier,
        name,
      };
    }
  }

  openSelectionMenu() {
    this._container.removeChild(this._mainButton);
    this._container.appendChild(this._mainForm);
  }

  closeSelectionMenu() {
    this._container.removeChild(this._mainForm);
    this._container.appendChild(this._mainButton);
  }

  onAdd(map) {
    Object.entries(this.expandIcons).forEach((obj) => {
      obj[1].icon.addEventListener('click', (e) => {
        e.preventDefault();
        for (let i = 0; i < obj[1].block.children.length; i += 1) {
          if (obj[1].isOpen === true) {
            obj[1].block.children[i].style.display = 'none';
          } else {
            obj[1].block.children[i].style.display = 'flex';
          }
        }
        obj[1].isOpen = !obj[1].isOpen;
      });
    });
    // Event listeners on radios to change basemap
    this.radios.forEach((r) => r.addEventListener('click', (e) => {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      map.customLayersLoaded = false;
      this.activateLayer(map, e.target.value);
    }));

    // Event listeners on checkboxes to toggle data layers visibility
    Object.keys(this.checkboxes).forEach((key) => {
      const c = this.checkboxes[key];

      if (c && c.checkbox && c.layerIds) {
        c.checkbox.addEventListener('click', () => {
          const newVisibilityProperty = c.checkbox.checked ? 'visible' : 'none';
          const layersIds = c.layerIds.filter((id) => !this.persistentLayerIds.includes(id));
          map.parent.setLayerGroupVisibility(layersIds, newVisibilityProperty);
        });
      }
    });
    // Event listeners on icon info to open new window
    Object.keys(this.infoIcons).forEach((key) => {
      const c = this.infoIcons[key];
      if (c && c.icon && c.identifier && c.informationUrl) {
        c.icon.addEventListener('click', (e) => {
          e.preventDefault();
          window.open(c.informationUrl, '_blank');
        });
      }
    });
    // Event listeners on icon info to open popup of layer group edition
    Object.keys(this.editIcons).forEach((key) => {
      const c = this.editIcons[key];
      if (c && Object.keys(c).includes(
        'icon', 'category', 'identifier', 'name', 'layerIds', 'visibility',
      )
      ) {
        c.icon.addEventListener('click', (e) => {
          e.preventDefault();
          this.closeSelectionMenu();
          map.parent._openEditLayers(
            {
              identifier: c.identifier,
              name: c.name,
              category: c.category,
              layerIds: c.layerIds,
              visibility: c.visibility,
            },
          );
        });
      }
    });
    // Event listeners on icon info to open window of layer group deletion
    Object.keys(this.deleteIcons).forEach((key) => {
      const c = this.deleteIcons[key];
      if (c && c.category && c.icon && c.identifier && c.name) {
        c.icon.addEventListener('click', (e) => {
          e.preventDefault();
          this.closeSelectionMenu();
          map.parent._openDeleteLayers(
            { identifier: c.identifier, name: c.name, category: c.category },
          );
        });
      }
    });
    // Event listeners on category checkboxes to toggle category data layers visibility
    Object.keys(this.categoryCheckboxes).forEach((category) => {
      const {
        checkbox: categoryCheckbox,
        layerIdentifiers,
        layerIds: categoryLayerIds,
      } = this.categoryCheckboxes[category];

      categoryCheckbox.addEventListener('click', () => {
        let layerIdsUnvisible = [];
        if (!categoryCheckbox.checked) {
          layerIdsUnvisible = categoryLayerIds.filter(
            (id) => !this.persistentLayerIds.includes(id),
          );
        }
        // Update layers global visibility in map helper
        map.parent.setLayerCategoryVisibility(category, layerIdsUnvisible);

        if (!categoryCheckbox.checked) {
          // Undisplay sub layers of the category
          map.parent.setLayerGroupVisibility(layerIdsUnvisible, null);
        } else {
          // Display sub layers of the category
          const layerIdsVisible = [];
          layerIdentifiers.forEach((identifier) => {
            const { checkbox, layerIds } = this.checkboxes[identifier];
            if (checkbox.checked) {
              layerIdsVisible.push(...layerIds);
            }
          });
          map.parent.setLayerGroupVisibility(layerIdsVisible, 'visible');
        }
        // Update style of sub labels of the category
        this.updateDisabledLabels({ categoryChecked: categoryCheckbox.checked, layerIdentifiers });
      });
    });

    map.parent._setMapDataLoaded(true);
    if (this.addCustomLayerButton) {
      this.addCustomLayerButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.closeSelectionMenu();
        if (this.isUserEnterprise) {
          map.parent._showLayerImportDialog();
        } else {
          window.open('https://clearance.aero/preparation-missions-drone/tarifs/', '_blank');
        }
      });
    }
    if (this.addGeoportailLayersButton) {
      this.addGeoportailLayersButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.closeSelectionMenu();
        if (this.isSubscriptionActive) {
          map.parent._showGeoportailLayersImportDialog();
        } else {
          window.open('https://clearance.aero/preparation-missions-drone/tarifs/', '_blank');
        }
      });
    }

    if (this.addNetworkLayersButton) {
      this.addNetworkLayersButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.closeSelectionMenu();
        if (this.isSubscriptionActive) {
          map.parent._showMobileNetworkLayersImportDialog();
        } else {
          window.open('https://clearance.aero/preparation-missions-drone/tarifs/', '_blank');
        }
      });
    }

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
  }
}

export default MapLayersControl;
