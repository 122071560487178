<template>
  <div>
    <v-overlay
      :value="!collapsed"
      class="player--overlay"
    />
    <v-hover v-slot="{ hover }">
      <v-card
        class="player--popup"
        :class="collapsed ? 'collapsed' : 'expanded'"
        v-click-outside="{
          handler: collapse,
          collapseConditional,
        }"
      >
        <LivestreamPlayer
          ref="LivestreamPlayer"
          :livestream="livestream"
          :collapsed="collapsed"
          @video-played="videoPlaying=true;"
          @video-paused="videoPlaying=false"
        >
          <template slot="player-overlay">
            <!-- Control Buttons for collaped state -->
            <div
              v-if="collapsed"
              v-show="hover"
            >
              <v-overlay
                :value="hover"
                class="player--overlay"
                absolute
              >
                <div class="top--buttons">
                  <v-btn
                    @click="expand"
                    icon
                  >
                    <v-icon color="white">mdi-arrow-expand</v-icon>
                  </v-btn>
                  <v-btn
                    @click="close"
                    icon
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </div>
                <div class="center--buttons">
                  <v-btn
                    v-if="videoPlaying"
                    @click="pauseVideo"
                    icon
                    x-large
                  >
                    <v-icon
                      x-large
                      color="white"
                    >mdi-pause</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    @click="playVideo"
                    icon
                    x-large
                  >
                    <v-icon
                      x-large
                      color="white"
                    >mdi-play</v-icon>
                  </v-btn>
                </div>
              </v-overlay>
            </div>
            <!-- Control Buttons for expanded state -->
            <div
              v-else
              class="top--buttons"
            >
              <v-btn
                @click="collapse"
                icon
              >
                <v-icon color="white">mdi-arrow-collapse</v-icon>
              </v-btn>
              <v-btn
                @click="openInNewTab"
                icon
              >
                <v-icon color="white">mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </template>
        </LivestreamPlayer>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { SET_LIVESTREAM_PLAYING_NS } from '@/store/livestreams';

import LivestreamPlayer from '@/components/Livestreams/LivestreamPlayer.vue';

export default {
  name: 'LivestreamPlayerPopup',
  components: { LivestreamPlayer },
  props: {
    livestream: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      collapsed: true,
      videoPlaying: true,
    };
  },
  methods: {
    expand() {
      if (this.collapsed) {
        this.collapsed = false;
      }
    },
    collapse() {
      if (!this.collapsed) {
        this.collapsed = true;
      }
    },
    collapseConditional() {
      return !this.collapsed;
    },
    playVideo() {
      this.$refs.LivestreamPlayer.playVideo();
    },
    pauseVideo() {
      this.$refs.LivestreamPlayer.pauseVideo();
    },
    openInNewTab() {
      const routeData = this.$router.resolve({
        name: 'livestream',
        params: { name: this.livestream.public_name },
      });
      window.open(routeData.href, '_blank');
    },
    close() {
      this.$store.commit(SET_LIVESTREAM_PLAYING_NS, undefined);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.player--overlay {
  z-index: 201 !important;
  ::v-deep .v-overlay__content {
    width: 100%;
    height: 100%;
  }
}

.player--popup {

  &.collapsed {
    z-index: 110;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 32px;
    max-height: 400px;
    max-width: 400px;
  }

  &.expanded {
    z-index: 202;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 18px;
    max-width: 85vw;
    max-height: 85vh;
  }
}

.top--buttons {
  z-index: 203;
  position: absolute;
  top: 12px;
  right: 12px;
  color: white;
}

.center--buttons {
  z-index: 203;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

</style>
