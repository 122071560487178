<template>
  <div>
    <!-- -------- Documents List per category -------- -->
    <div
      v-for="obj in documentsPerCategory"
      :key="obj.value"
    >
      <template v-if="obj.documents.length !== 0">
        <v-row
          no-gutters
          align="center"
          class="my-2"
        >
          <v-col
            cols="12"
            lg="5"
          >
            <LayoutTooltip
              :disabled="obj.categoryName !== 'Protocoles'"
              attachSelector="#categoryName"
              :left="true"
            >
              <template
                slot="activator"
                slot-scope="{on}"
              >
                <div
                  v-on="on"
                  id="categoryName"
                >
                  <span :class="getClassLabel">
                    {{ obj.categoryName }} :
                  </span>
                </div>
              </template>
              <template slot="tooltip-content">
                <span class="caption">
                  {{ tooltipContentProtocolsText }}
                </span>
              </template>
            </LayoutTooltip>
          </v-col>
          <v-col
            cols="12"
            lg="7"
            class="pl-lg-5"
          >
            <template v-for="(document, index) in obj.documents">
              <v-chip
                class="documents__chip caption"
                :close="!readOnly"
                :key="index"
                @click:close="
                  documentToDelete = document.id;
                  deletionDialog = true;
                "
              >
                <v-tooltip
                  top
                  color="info"
                  open-delay="500"
                >
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                      class="documents__link-label"
                      @click="downloadDocument(document.id, document.name)"
                    >
                      {{ document.name }}
                    </span>
                  </template>
                  <span class="documents">
                    {{ document.name }}
                  </span>
                </v-tooltip>
                <span
                  v-if="document.expiration_date"
                  class="ml-1"
                >
                  ({{ document.expiration_date }})
                </span>
                <v-tooltip
                  top
                  color="info"
                  open-delay="500"
                >
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                      v-if="document.version"
                      class="documents__truncated ml-1"
                    >
                      (version : {{ document.version }})
                    </span>
                  </template>
                  <span class="documents">
                    (version : {{ document.version }})
                  </span>
                </v-tooltip>
              </v-chip>
            </template>
          </v-col>
        </v-row>
        <v-divider />
      </template>
      <template v-else-if="obj.documents.length === 0 && obj.value !== ''">
        <v-row
          no-gutters
          class="my-2"
          align="center"
        >
          <v-col
            cols="12"
            lg="5"
          >
            <LayoutTooltip
              :disabled="obj.categoryName !== 'Protocoles'"
              attachSelector="#categoryName"
              :left="true"
            >
              <template
                slot="activator"
                slot-scope="{on}"
              >
                <div
                  v-on="on"
                  id="categoryName"
                >
                  <span :class="getClassLabel">
                    {{ obj.categoryName }} :
                  </span>
                </div>
              </template>
              <template slot="tooltip-content">
                <span class="caption">
                  {{ tooltipContentProtocolsText }}
                </span>
              </template>
            </LayoutTooltip>
          </v-col>
          <v-col
            cols="12"
            lg="7"
            class="pl-lg-5"
          >
            <translate class="font-italic info--text">
              No document have been uploaded yet
            </translate>
          </v-col>
        </v-row>
        <v-divider />
      </template>
    </div>
    <!-- -------- Upload new document button -------- -->
    <template v-if="!readOnly">
      <v-btn
        depressed
        color="primary"
        class="px-4 my-1"
        @click="uploadDialog = true"
        :disabled="documents.length>=maxNumberDocuments"
      >
        <translate>Upload document</translate>
      </v-btn>
      <div
        v-if="documents.length>=maxNumberDocuments"
        class="caption info--text text--lighten-1 font-italic pt-1"
      >
        <translate :translate-params="{number: maxNumberDocuments}">
          Only %{number} documents can be uploaded.
        </translate>
      </div>
    </template>
    <!-- -------- Upload new document dialog -------- -->
    <v-dialog
      v-model="uploadDialog"
      max-width="500px"
      persistent
      :retain-focus="false"
    >
      <v-form v-model="validForm">
        <v-card class="default--dialog__card">
          <v-card-title>
            <translate>Upload document</translate>
          </v-card-title>
          <v-card-text>
            <v-select
              :items="documentCategories"
              v-model="documentCategory"
              :label="labels.documentCategory"
              item-text="label"
              item-value="value"
              hide-details
              class="py-4"
              prepend-icon="mdi-file-outline"
              dense
            />
            <v-menu
              v-model="showCalendar"
              :close-on-content-click="false"
              offset-y
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="expirationDate"
                  :label="labels.expirationDate"
                  hide-details
                  prepend-icon="event"
                  v-bind="attrs"
                  v-on="on"
                  class="py-4"
                  readonly
                />
              </template>
              <v-date-picker
                v-model="expirationDate"
                @input="showCalendar=false"
                no-title
                scrollable
                locale="fr"
                first-day-of-week="1"
                class="elevation-0"
              />
            </v-menu>
            <v-text-field
              v-if="isDocumentManexOrMap"
              v-model="version"
              :label="labels.version"
              prepend-icon="mdi-numeric"
            />
            <v-file-input
              v-model="fileInput"
              :label="labels.fileInput"
              :rules="uploadedFilesRules"
              class="py-4"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="info"
              class="px-2"
              @click="closeUploadDialog()"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              text
              color="primary"
              class="px-2"
              @click="uploadDocument()"
              :disabled="!validForm"
              :loading="uploadLoading"
            >
              <translate>Confirm</translate>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Dialog deletation documents -->
    <v-dialog
      v-model="deletionDialog"
      max-width="420"
      persistent
      :retain-focus="false"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <translate>Confirmation required</translate>
        </v-card-title>
        <v-card-text>
          <translate>Are you sure you want to delete this document?</translate>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="documentToDelete=null;deletionDialog=false;"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            :loading="deletionLoading"
            text
            color="primary"
            @click="deleteDocument()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LayoutTooltip from '@/layouts/LayoutTooltip.vue';

import APIService from '@/services/api';

import { MAX_DOCUMENT_SIZE_MB, MAX_NUMBER_OF_DOCUMENTS } from '@/settings';

import {
  ADD_EXPLOITANT_DOCUMENT_NS,
  DELETE_EXPLOITANT_DOCUMENT_NS,
} from '@/store/exploitants';

export default {
  name: 'ExploitationDocuments',
  components: { LayoutTooltip },
  props: {
    exploitantId: {
      type: Number,
      required: true,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadDialog: false,
      validForm: true,
      uploadLoading: false,
      documentCategory: '',
      showCalendar: false,
      expirationDate: null,
      fileInput: null,
      documentToDelete: null,
      deletionDialog: false,
      deletionLoading: false,
      version: null,
    };
  },
  computed: {
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    getClassLabel() {
      return (
        this.isUserPrefecture
          ? 'details-card__label text-uppercase'
          : 'info--text text--lighten-1'
      );
    },
    documents() {
      return this.$store.getters['exploitants/documents'];
    },
    documentCategories() {
      return this.$store.state.exploitants.documentCategories;
    },
    documentsPerCategory() {
      return this.$store.getters['exploitants/documentsPerCategory'];
    },
    subscribed() {
      return !this.$store.getters['authentication/userNotSubscribed'];
    },
    maxDocumentSize() {
      return this.subscribed ? MAX_DOCUMENT_SIZE_MB.exploitant_subscriber
        : MAX_DOCUMENT_SIZE_MB.exploitant_non_subscriber;
    },
    maxNumberDocuments() {
      return this.subscribed ? MAX_NUMBER_OF_DOCUMENTS.exploitant_subscriber
        : MAX_NUMBER_OF_DOCUMENTS.exploitant_non_subscriber;
    },
    labels() {
      return {
        documentCategory: this.$gettext('Choose document category'),
        expirationDate: this.$gettext('Document expiration date'),
        version: this.$gettext('Manuel version'),
        fileInput: this.$gettext('Choose a file'),
      };
    },
    uploadedFilesRules() {
      return [
        (value) => !!value || this.$gettext('You need to choose a file!'),
        (value) => !value || value.size < this.maxDocumentSize * 1000000
          || this.$gettextInterpolate(
            this.$gettext('File size should be less than %{ size } MB !'),
            { size: this.maxDocumentSize },
          ),
      ];
    },
    isDocumentManexOrMap() {
      return this.documentCategory === 'manex_map';
    },
    tooltipContentProtocolsText() {
      return this.$gettext(`Some authorities make annual or multi-annual protocols, also called
        protocols or agreements. These documents are valid for several months or years, so we
        suggest you to keep them here for your future missions.`);
    },
  },
  watch: {
    isDocumentManexOrMap() {
      if (!this.isDocumentManexOrMap) {
        this.version = null;
      }
    },
  },
  methods: {
    uploadDocument() {
      if (this.fileInput) {
        const formData = new FormData();
        formData.append('file', this.fileInput);
        formData.append('related_id', this.exploitantId);
        formData.append('related_type', 'exploitant');
        formData.append('category', this.documentCategory);
        if (this.expirationDate) {
          formData.append('expiration_date', this.expirationDate);
        }
        if (this.version) {
          formData.append('version', this.version);
        }
        this.uploadLoading = true;
        APIService.uploadDocument(formData)
          .then(({ data }) => {
            this.$store.dispatch(ADD_EXPLOITANT_DOCUMENT_NS, data)
              .then(() => {
                this.showMessage(this.$gettext('Document upload successfully.'), 'success');
              });
          })
          .finally(() => {
            this.uploadLoading = false;
            this.closeUploadDialog();
          });
      }
    },
    downloadDocument(documentId, documentName) {
      APIService.downloadDocument(documentId)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    deleteDocument() {
      this.deletionLoading = true;
      APIService.deleteDocument(this.documentToDelete)
        .then(() => {
          this.$store.dispatch(DELETE_EXPLOITANT_DOCUMENT_NS, this.documentToDelete)
            .then(() => {
              this.showMessage(this.$gettext('Document deleted.'), 'success');
            });
        })
        .finally(() => {
          this.documentToDelete = null;
          this.deletionLoading = false;
          this.deletionDialog = false;
        });
    },
    closeUploadDialog() {
      this.uploadDialog = false;
      this.documentCategory = '';
      this.showCalendar = false;
      this.expirationDate = null;
      this.fileInput = null;
      this.version = null;
    },
  },
};
</script>
