<template>
  <div v-if="constraint.clearance_comment || suggestionPublicTipsAvailable">
    <div class="mb-2">
      <v-alert
        class="body-2 ma-0 px-1 py-2"
        color="primary"
        text
      >
        <div class="d-flex align-center info--text pb-2">
          <v-icon color="info">icon-logo_clearance_flat clearance-logo__icon</v-icon>
          <span
            v-translate
            class="font-weight-medium mx-1"
          >
            Clearance comment
          </span>
          <span
            v-if="clearanceCommentDate"
            v-translate="{date: clearanceCommentDate }"
          >
            (updated on %{ date })
          </span>
        </div>
        <!-- Clearance comment -->
        <template v-if="constraint.clearance_comment">
          <div
            ref="comment"
            class="comment pl-7 pr-2"
            :class="{
              'with-overflow': commentOverflowing,
              'collapsed': commentCollapsed,
            }"
          >
            <vue-markdown
              :source="constraint.clearance_comment.comment"
              html
              xhtml-out
              typographer
              linkify
              breaks
              show
              :anchorAttributes="{ target: '_blank' }"
              class="markdown info--text"
            />
            <div
              v-if="constraint.clearance_comment.documents.length"
              class="mb-2"
            >
              <v-chip
                v-for="(document, index) in constraint.clearance_comment.documents"
                :key="index"
                outlined
                class="documents__chip caption"
              >
                <v-icon
                  small
                  color="info"
                  class="pr-1"
                >
                  mdi-paperclip
                </v-icon>
                <span
                  class="documents__link-label"
                  @click.stop="downloadPublicTipDocument(document.id, document.name)"
                >
                  {{ document.name }}
                </span>
              </v-chip>
            </div>
            <div
              v-if="commentOverflowing && !commentCollapsed"
              class="cursor-pointer text-right pt-1"
              @click="commentCollapsed = true"
            >
              <span
                class="info--text text-decoration-underline"
                v-translate
              >
                see less
              </span>
            </div>
          </div>
          <div
            v-if="commentOverflowing && commentCollapsed"
            class="cursor-pointer text-right pt-1 pr-2"
            @click="commentCollapsed = false"
          >
            <span
              class="info--text text-decoration-underline"
              v-translate
            >
              see all
            </span>
          </div>
        </template>
        <!-- Clearance no comment -->
        <template v-else>
          <div class="text--secondary font-italic pl-7 pr-2 pb-2">
            {{ texts.contactSuggestionTip }}
          </div>
        </template>
        <!-- Clearance comment suggestion -->
        <template v-if="suggestionPublicTipsAvailable">
          <div class="d-flex justify-end mt-2 px-2">
            <span
              @click="showSuggestionPublicTipsDialog=true"
              key="suggestionClearanceComment"
              class="font-italic cursor-pointer caption text-right text--secondary hover-underline"
            >
              <span
                v-if="constraint.clearance_comment"
                key="updateSuggest"
                v-translate
              >
                Suggest a modification
              </span>
              <span
                v-else
                key="newSuggestion"
                v-translate
              >
                Suggest a contact information
              </span>
            </span>
          </div>
        </template>
      </v-alert>
    </div>
    <!-- Clearance Comment Suggestion Dialog -->
    <v-dialog
      v-model="showSuggestionPublicTipsDialog"
      width="820px"
      :retain-focus="false"
      persistent
    >
      <v-card
        class="default--dialog__card"
        max-height="800px"
        elevation="0"
      >
        <v-card-title>
          <span v-translate>Clearance comment suggestion</span>
        </v-card-title>
        <v-card-text>
          <span>
            {{ texts.contactInfosExplanation }}
          </span>
          <v-form
            v-model="valid"
            class="mt-2"
          >
            <v-textarea
              outlined
              :label="labels.suggestion"
              v-model="suggestion"
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="emailSuggestion"
              :label="labels.emailSuggestion"
              :rules="[rules.emailRequired, rules.emailValid]"
              outlined
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="closeSuggestionPublicTipsDialog()"
          >
            <span v-translate>Cancel</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="!valid"
            :loading="publicTipsLoading"
            @click="sendSuggestionPublicTip()"
          >
            <span v-translate>Send</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';

import APIService from '@/services/api';

import { CAPABILITY_CATEGORY } from '@/settings';

export default {
  name: 'ClearanceComment',
  components: { VueMarkdown },
  props: {
    constraint: Object,
    capability: Object,
    flightId: Number,
  },
  data() {
    return {
      commentOverflowing: undefined,
      commentCollapsed: true,
      showSuggestionPublicTipsDialog: false,
      emailSuggestion: this.$store.state.authentication.user.email,
      suggestion: '',
      valid: false,
      publicTipsLoading: false,
      rules: {
        required: (v) => v.length !== 0 || this.$gettext('Please fill in this field.'),
        emailRequired: (v) => v.length !== 0 || this.$gettext(
          'Please fill in an email.',
        ),
        emailValid: (
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailIsValid = true;
            if (!pattern.test(value)) {
              emailIsValid = this.$gettext('Email invalid.');
            }
            return emailIsValid;
          }
        ),
      },
    };
  },
  computed: {
    labels() {
      return {
        suggestion: this.$gettext('Suggestion'),
        emailSuggestion: this.$gettext('Email where you want to receive a reply'),
      };
    },
    texts() {
      return {
        contactSuggestionTip: this.$gettext(`We don't seem to have any contact information for the
          authority responsible for this area. If you know how to address a request for
          authorization for this zone, please let us know!`),
        contactInfosExplanation: this.$gettext(`Click on send will only send an email to Clearance.
          Please use it only about contact information suggestion that you want to be displayed on
          the platform.`),
      };
    },
    clearanceCommentDate() {
      if (this.constraint.clearance_comment?.date) {
        const date = new Date(this.constraint.clearance_comment.date);
        // Handle timezones differences
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        return date.toLocaleDateString(
          this.$store.getters['application/currentLanguage'],
        );
      }
      return '';
    },
    suggestionPublicTipsAvailable() {
      const codeTypeUnavailable = ['OBS', 'HW', 'RW', 'U-SPACE'];
      // Display the suggestion if constraint has an email request button or if the constraint code
      // type is not contained in the list below and the contraint has no action button
      return (
        this.capability?.category === CAPABILITY_CATEGORY.EMAIL_REQUEST
        || (
          !codeTypeUnavailable.includes(this.constraint.code_type)
          && this.constraint.process_through === ''
        )
      );
    },
  },
  mounted() {
    const commentElement = this.$refs.comment;
    if (commentElement) {
      setTimeout(() => {
        this.commentOverflowing = commentElement.offsetHeight < commentElement.scrollHeight;
      }, 100);
    }
  },
  methods: {
    closeSuggestionPublicTipsDialog() {
      this.showSuggestionPublicTipsDialog = false;
      this.suggestion = '';
    },
    sendSuggestionPublicTip() {
      this.publicTipsLoading = true;
      const payload = {
        email: this.emailSuggestion,
        suggestion: this.suggestion,
        structure_id: this.constraint.structure_id,
      };
      APIService.postSuggestionPuclicTips(payload)
        .finally(() => {
          this.publicTipsLoading = false;
        });
      this.showSuggestionPublicTipsDialog = false;
      this.suggestion = '';
      this.showMessage(this.$gettext('Thank you for your suggestion.'), 'success');
    },
    downloadPublicTipDocument(documentId, documentName) {
      APIService.downloadPublicTipDocument(this.flightId, documentId)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.comment {
  &.with-overflow {
    cursor: pointer;
  }
  &.collapsed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

</style>
