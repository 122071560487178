<template>
  <div class="approval-listing">
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="approvals"
      :loading="loading"
      :server-items-length="itemsLength"
      :options.sync="options"
      single-expand
      :expanded.sync="expanded"
      :hide-default-footer="smallDisplay"
      :class="{bigDisplay: bigDisplay, smallDisplay: !bigDisplay, transparent: true}"
      :footer-props="{
        'items-per-page-text': texts.itemsPerPage,
        'items-per-page-all-text': texts.itemsAll,
        'items-per-page-options': [25, 50, 100, -1],
      }"
      :loading-text="texts.itemsLoading"
      :no-data-text="texts.itemsEmpty"
      :no-results-text="texts.itemsEmpty"
      item-key="id"
      dark
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <tr
          v-if="props && props.item"
          :class="getStatusClass(props)"
          class="cursor-pointer"
          @click="selectItem(props)"
          @mouseenter="idHover = props.item.id"
          @mouseleave="idHover = null"
        >
          <!-- Automatically processed icon -->
          <td v-if="headers.find(e => e.value == 'is_automatically_processed')">
            <div class="text-center">
              <v-badge
                v-if="props.item.is_automatically_processed"
                color="white"
                bordered
              >
                <span
                  slot="badge"
                  class="badge_automatic_process"
                >
                  A
                </span>
              </v-badge>
            </div>
          </td>
          <!-- Status icon -->
          <td
            v-if="headers.find(e => e.value == 'status')"
            class="text-center"
          >
            <StatusIcon
              :status="props.item.status"
              :isClosed="props.item.is_closed"
              :breachedPSAs="props.item.is_above_psa && !isUserPrefecture"
              :noMaxElevation="props.item.max_altitude === null  && !isUserPrefecture"
              :isInsideImpactingZone="props.item.inside_impacting_zone"
              :transparentBackground="props.isExpanded && !flightEnRoute(props)"
              withTooltip
              class="approval-listing__icon"
            />
          </td>
          <!-- Approval identifier -->
          <td
            v-if="headers.find(e => e.value == 'identifier')"
            class="approval-listing__item-identifier"
          >
            <span v-if="props.item.display_identifier">
              {{ props.item.display_identifier }}
            </span>
            <v-badge
              v-if="numbersOfSubApprovals(props) > 1 && !smallDisplay"
              left
              overlap
              class="small-badge ml-3"
            >
              <span
                slot="badge"
                class="d-flex justify-center align-center"
              >
                {{ numbersOfSubApprovals(props) }}
              </span>
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-icon
                    v-on="on"
                    :color="props.isExpanded ? 'white' : 'info'"
                  >
                    mdi-shape-polygon-plus
                  </v-icon>
                </template>
                <translate :translate-params="{ nb: numbersOfSubApprovals(props) }">
                  This approval request has %{ nb } areas.
                </translate>
              </v-tooltip>
            </v-badge>
          </td>
          <!-- En route by superviser -->
          <td v-if="headers.find(e => e.value == 'en_route_by_superviser')">
            <v-tooltip
              v-if="props.item.en_route_by_superviser"
              top
            >
              <template v-slot:activator="{on}">
                <v-icon
                  v-on="on"
                  :color="props.isExpanded && !flightEnRoute(props) ? 'white' : 'info'"
                >
                  mdi-airplane-takeoff
                </v-icon>
              </template>
              <span v-translate>You mark this approval as en route</span>
            </v-tooltip>
          </td>
          <!-- Take off authorization or tactical contact -->
          <td v-if="headers.find(
            e => ['take_off_authorization', 'tactical_contact_required'].includes(e.value),
          )">
            <!-- Take off authorization -->
            <template v-if="props.item.take_off_authorization">
              <v-btn
                icon
                @click.stop="
                  openTakeOffAuthorizationDialogUpdateStatus(props.item.take_off_authorization)
                "
              >
                <TakeOffAuthorizationStatusIcon
                  :status="props.item.take_off_authorization.status"
                  :transparentBackground="props.isExpanded && !flightEnRoute(props)"
                />
              </v-btn>
            </template>
            <!-- Tactical contact -->
            <template v-else-if="props.item.tactical_contact_required">
              <TakeOffAuthorizationStatusIcon
                :tooltip="props.item.extra_requirements"
                :transparentBackground="props.isExpanded && !flightEnRoute(props)"
              />
            </template>
          </td>
          <!-- Company name -->
          <td
            v-if="headers.find(e => e.value == 'company_name')"
            class="px-1"
          >
            {{ props.item.company_name }}
          </td>
          <!-- Category name -->
          <td
            v-if="headers.find(e => e.value == 'category_name')"
            class="px-1"
          >
            <v-chip
              v-if="props.item.category_name"
              x-small
              :color="props.isExpanded ? 'white' : 'primary'"
            >
              <span
                class="font-weight-medium"
                :class="props.isExpanded ? 'primary--text' : 'white--text'"
              >
                {{ props.item.category_name }}
              </span>
            </v-chip>
          </td>
          <!-- Commune -->
          <td
            v-if="headers.find(e => e.value == 'commune')"
            class="px-1"
          >
            {{ props.item.commune }}
          </td>
          <!-- Period -->
          <td
            v-if="headers.find(e => e.value == 'period')"
            class="px-1"
          >
            {{ props.item.flight.date_start | dateShort }} -
            {{ props.item.flight.date_end | dateShort }}
          </td>
          <!-- Hour -->
          <td
            v-if="headers.find(e => e.value == 'hour')"
            class="px-1"
          >
            {{ props.item.flight.time_start | hour }} - {{ props.item.flight.time_end | hour }}
          </td>
          <!-- Max Height -->
          <td
            v-if="headers.find(e => e.value == 'max_height')"
            class="px-1"
          >
            <template v-if="isUserPrefecture">
              {{ props.item.max_flying_height | meter }}
            </template>
            <template v-else>
              {{ props.item.max_flying_height | meterAndFeet }}
            </template>
          </td>
          <!-- Elevation -->
          <td
            v-if="headers.find(e => e.value == 'max_altitude')"
            class="px-1"
          >
            <template v-if="props.item.max_altitude === null">
              <v-badge
                color="red"
                left
                inline
                content="!"
                class="small-badge"
              />
              <translate>
                Unknown Elevation
              </translate>
            </template>
            <template v-else>
              <div>
                {{ props.item.max_altitude | meterAndFeet }}
              </div>
            </template>
          </td>
          <!-- QDR -->
          <td
            v-if="headers.find(e => e.value == 'qdr')"
            class="px-1"
          >
            {{ props.item.radial | degree }} - {{ props.item.distance_to_structure | mile }}
          </td>
          <!-- Unread message -->
          <td
            v-if="headers.find(e => e.value == 'unread_message_for_authority')"
            class="px-1"
          >
            <v-tooltip
              v-if="props.item.unread_message_for_authority"
              top
            >
              <template v-slot:activator="{on}">
                <v-icon
                  v-on="on"
                  :color="props.isExpanded ? 'white' : 'info'"
                >
                  mdi-comment-text-outline
                </v-icon>
              </template>
              <span v-translate>Unread messages</span>
            </v-tooltip>
          </td>
          <!-- Actions -->
          <td
            v-if="headers.find(e => e.value == 'actions')"
            class="text-right action-expand"
          >
            <v-btn icon>
              <v-icon
                v-if="!props.isExpanded"
                color="black"
              >
                keyboard_arrow_down
              </v-icon>
              <v-icon
                v-else
                color="white"
              >
                keyboard_arrow_up
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          v-if="item"
          :colspan="headers.length"
          class="pa-0"
        >
          <v-skeleton-loader
            v-if="loadingDetails"
            light
            type="list-item-three-line"
          />
          <Approval
            v-else
            :approval="item"
            :key="`approval-${item.id}`"
            @center-on-approval="() => $emit('center-on-approval')"
            @center-on-area="(area) => $emit('center-on-area', area)"
          />
        </td>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        <span v-translate="
          {pageStart: items.pageStart, pageStop: items.pageStop, length: items.itemsLength}
        ">
          %{ pageStart } - %{ pageStop } of %{ length }
        </span>
      </template>
    </v-data-table>
    <v-dialog
      v-if="takeOffAuthorizationUpdateStatus"
      v-model="takeOffAuthorizationUpdateStatus"
      persistent
      width="650"
      height="800"
      :retain-focus="false"
    >
      <ChangeTakeOffAuthorizationStatusForm
        :takeOffAuthorization="takeOffAuthorizationUpdateStatus"
        retrieveTakeOffAuthorizationDetails
        @close="() => takeOffAuthorizationUpdateStatus = null"
      />
    </v-dialog>
  </div>
</template>

<script>
import { APPLICATION_DISPLAY, SET_DISPLAY_NS } from '@/store/application';
import {
  SET_DEFAULT_PAGINATION_NS,
  SET_APPROVAL_SELECTED_NS,
  SET_APPROVAL_HOVER_NS,
  SET_PAGINATION_NS,
  GET_APPROVAL_DETAILS_NS,
  SET_FILTERS_NS,
} from '@/store/approvals';
import { ACCEPTED, CLOSED, REFUSED, SUBMITTED } from '@/store/status';

import Approval from '@/components/Approvals/Approval.vue';
import makeApprovalListingHeaders from '@/components/Approvals/ApprovalListingHeaders';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import TakeOffAuthorizationStatusIcon from '@/components/StatusIcon/TakeOffAuthorizationStatusIcon.vue';
import ChangeTakeOffAuthorizationStatusForm from '@/components/TakeOffAuthorizations/ChangeTakeOffAuthorizationStatusForm.vue';

export default {
  name: 'ApprovalListing',
  components: {
    Approval,
    StatusIcon,
    TakeOffAuthorizationStatusIcon,
    ChangeTakeOffAuthorizationStatusForm,
  },
  data() {
    return {
      smallHeaders: [],
      mediumHeaders: [],
      bigHeaders: [],
      takeOffAuthorizationUpdateStatus: null,
    };
  },
  computed: {
    loadingDetails() {
      return this.$store.state.approvals.approvalDetailsLoading;
    },
    display() {
      return this.$store.state.application.display;
    },
    bigDisplay() {
      return this.display === APPLICATION_DISPLAY.BIG;
    },
    mediumDisplay() {
      return this.display === APPLICATION_DISPLAY.MEDIUM;
    },
    smallDisplay() {
      return this.display === APPLICATION_DISPLAY.SMALL;
    },
    headers() {
      if (this.bigDisplay) return this.bigHeaders;
      if (this.smallDisplay) return this.smallHeaders;
      return this.mediumHeaders;
    },
    itemsLength() {
      return this.$store.state.approvals.itemsLength;
    },
    options: {
      get() {
        return this.$store.state.approvals.pagination;
      },
      set(newValue) {
        this.$store.dispatch(SET_PAGINATION_NS, newValue);
      },
    },
    approvals() {
      if (!this.loading) {
        return this.$store.getters['approvals/tableItems'];
      }
      return [];
    },
    loading() {
      return this.$store.state.approvals.tableItemsLoading;
    },
    expanded: {
      get() {
        return [this.$store.getters['approvals/approvalSelected']];
      },
      set() {},
    },
    idSelected: {
      get() {
        return this.$store.state.map.featureIdSelected.flight;
      },
      set(newValue) {
        this.$store.dispatch(SET_APPROVAL_SELECTED_NS, newValue);
      },
    },
    idHover: {
      get() {
        return this.$store.state.map.featureIdHovered.flight;
      },
      set(newValue) {
        if (!this.idSelected || this.idSelected !== newValue) {
          this.$store.dispatch(SET_APPROVAL_HOVER_NS, newValue);
        }
      },
    },
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    isAuthorityController() {
      return this.$store.getters['authentication/isAuthorityController'];
    },
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
    takeOffAuthorizationActivated() {
      return this.$store.getters['structures/takeOffAuthorizationActivated'];
    },
    texts() {
      return {
        itemsPerPage: this.$gettext('Lines per page:'),
        itemsAll: this.$gettext('All'),
        itemsLoading: this.$gettext('Loading approvals...'),
        itemsEmpty: (
          this.activeFilters > 0
            ? this.$gettext('No approval found for selected filters')
            : this.$gettext('No approval found')
        ),
      };
    },
    activeFilters() {
      return this.$store.getters['approvals/activeFilters'];
    },
  },
  watch: {
    display(newValue) {
      if (newValue === 'SMALL') {
        this.idSelected = null;
      }
    },
    takeOffAuthorizationActivated() {
      this.setHeaders();
    },
  },
  created() {
    this.setHeaders();
    this.$store.dispatch(SET_DEFAULT_PAGINATION_NS);
  },
  mounted() {
    this.selectApprovalFromRoute();
  },
  methods: {
    setHeaders() {
      const headers = makeApprovalListingHeaders(
        this.$gettext,
        this.isUserPrefecture,
        this.isAuthorityController,
        this.takeOffAuthorizationActivated,
      );
      this.smallHeaders = headers.smallHeaders;
      this.mediumHeaders = headers.mediumHeaders;
      this.bigHeaders = headers.bigHeaders;
    },
    numbersOfSubApprovals(props) {
      return props?.item?.sub_approvals?.length || 0;
    },
    flightEnRoute(props) {
      return props?.item?.flight?.en_route && this.isAuthorityController;
    },
    getStatusClass(props) {
      return {
        selected: props.isExpanded && !this.flightEnRoute(props),
        [props?.item?.is_closed ? CLOSED : props?.item?.status || SUBMITTED]: props.isExpanded,
        highlighted: this.flightEnRoute(props),
      };
    },
    selectItem(props) {
      if (this.loading) {
        return;
      }
      if (this.isAuthorityFullScreenInterface) {
        this.$router.push({
          name: 'approval-page',
          params: { id: props.item.id },
        });
        return;
      }
      this.smallDisplay && this.$store.dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.MEDIUM);
      if (props.isExpanded === false) {
        this.idSelected = props.item.id;
      } else {
        this.idSelected = null;
      }
    },
    selectApprovalFromRoute() {
      if (this.$route.query.idSelected !== undefined) {
        const approvalId = parseInt(this.$route.query.idSelected, 10);
        this.$store.dispatch(GET_APPROVAL_DETAILS_NS, { approvalId })
          .then(() => {
            this.idSelected = approvalId;
          })
          .catch(() => {
            // If approval not found try to look in the archives
            this.$store.dispatch(SET_FILTERS_NS, {
              ...this.$store.state.approvals.filters,
              isClosed: null,
            })
              .then(() => {
                this.$store.dispatch(GET_APPROVAL_DETAILS_NS, { approvalId, reload: true })
                  .then(() => {
                    this.idSelected = approvalId;
                  })
                  .catch(() => {
                    this.showMessage(
                      this.$gettextInterpolate(
                        this.$gettext('Approval n°%{ approvalId } can not be found'),
                        { approvalId },
                      ),
                      'error',
                    );
                  });
              });
          });
      }
    },
    openTakeOffAuthorizationDialogUpdateStatus(takeOffAuthorization) {
      if ([SUBMITTED, ACCEPTED, REFUSED].includes(takeOffAuthorization.status)) {
        this.takeOffAuthorizationUpdateStatus = takeOffAuthorization;
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.approval-listing ::v-deep .v-data-table__wrapper>table> {
  tbody>tr,
  tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: white;
    color: $color-secondary;
    &.selected {
      color: $color-white;
      background-color: $color-primary;
      &.accepted {
        background-color: $accepted-background-color;
      }
      &.incomplete {
        background-color: $incomplete-background-color;
      }
      &.refused {
        background-color: $refused-background-color;
      }
      &.submitted, &.awaiting_validation {
        background-color: $submitted-background-color;
      }
      &.reserves {
        background-color: $reserves-background-color;
      }
      &.pending, &.closed {
        background-color: $pending-background-color;
      }
    }
    &.highlighted {
      background-color: $flight-highlighted-background-color;
    }
  }
  tbody>tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 15px solid $color-secondary;
      height: 60px;
    }
    &:first-child td {
      height: 45px;
    }
  }
  tbody>tr:hover:not(.highlighted):not(.selected) {
    background-color: lighten($color: $color-info, $amount: 50);
  }
  tbody>tr>td, thead>tr>th {
    &.approval-listing__item-identifier {
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }
}

.approval-listing__icon {
  padding: 10px;
}

.badge_automatic_process {
  color: black;
  font-weight: bold;
}

</style>
