<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <span>
        <v-icon
          class="mr-2"
          color="warning"
        >
          mdi-alert
        </v-icon>
        <span v-translate>
          Request the deletion of the account
        </span>
      </span>
    </v-card-title>
    <v-card-text>
      <p>
        {{ disclaimerText }}
      </p>
      <p v-if="recapLoading">
        <v-skeleton-loader
          class="pt-2 pr-8"
          type="paragraph, text"
        />
      </p>
      <p v-else>
      <ul>
        <li>
          <b class="mr-1">{{ flightsCount }}</b>
          <translate>flights</translate>
        </li>
        <li>
          <b class="mr-1">{{ pilotsCount }}</b>
          <translate>pilots</translate>
        </li>
        <li>
          <b class="mr-1">{{ dronesCount }}</b>
          <translate>drones</translate>
        </li>
        <li>
          <b class="mr-1">{{ approvalsCount }}</b>
          <translate>approval requests</translate>
        </li>
      </ul>
      </p>
      <p>
        {{ requestExplanationText }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        :disabled="requestDeletionLoading"
        @click="close()"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        color="primary"
        :disabled="recapLoading || requestDeletionLoading"
        :loading="requestDeletionLoading"
        @click="confirmRequest()"
      >
        <translate>Confirm</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from '@/services/api';

export default {
  name: 'RequestAccountDeletion',
  data() {
    return {
      recapLoading: true,
      requestDeletionLoading: false,
      flightsCount: null,
      pilotsCount: null,
      dronesCount: null,
      approvalsCount: null,
    };
  },
  computed: {
    exploitantId() {
      return this.$store.state.authentication.user.exploitation_id;
    },
    disclaimerText() {
      return this.$gettext(`Are you sure you want to delete your account? This action will result
        in the deletion of :`);
    },
    requestExplanationText() {
      return this.$gettext(`This request will be processed in the next few days, and a confirmation
        email will be sent to you after the effective deletion of your account.`);
    },
  },
  mounted() {
    API.getExploitantAccountRecap(this.exploitantId)
      .then(({ data }) => {
        this.flightsCount = data.flights_count;
        this.pilotsCount = data.pilots_count;
        this.dronesCount = data.drones_count;
        this.approvalsCount = data.approvals_count;
      })
      .finally(() => {
        this.recapLoading = false;
      });
  },
  methods: {
    confirmRequest() {
      this.requestDeletionLoading = true;
      API.requestExploitantAccountDeletion(this.exploitantId)
        .then(() => {
          this.showMessage(
            this.$gettext('The account deletion request was sent successfully.'), 'success',
          );
        })
        .finally(() => {
          this.requestDeletionLoading = false;
          this.close();
        });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
