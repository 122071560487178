// approvals status
export const SUBMITTED = 'submitted';
export const INCOMPLETE = 'incomplete';
export const REFUSED = 'refused';
export const RESERVES = 'reserves';
export const ACCEPTED = 'accepted';
export const CLOSED = 'closed';
export const PENDING = 'pending';
export const AWAITING_PAYMENT = 'awaiting_payment';
export const AWAITING_VALIDATION = 'awaiting_validation';

export const CHECKED = 'checked';

// flights status
export const PLANNED = 'planned';

export const PROCESSING = 'processing';
export const ACTION_NEEDED = 'action_needed';

export const CONFIRMED = 'confirmed';
export const CLEAR = 'clear';
export const DONE = 'done';

export const CANCELED = 'canceled';
export const CANCELLED = 'cancelled';
// export const REFUSED = 'refused';  // already defined
export const ERROR = 'error';

// status constraints
export const NOT_HANDLED = 'not_handled';
export const HANDLED = 'handled';
export const IRRELEVANT = 'irrelevant';
// export const REFUSED = 'refused'  // already defined
// export const CLEAR = 'clear'  // already defined
// export const PROCESSING = 'processing'  // already defined
// export const INCOMPLETE = 'incomplete'  // already defined
// export const RESERVES = 'reserves'  // already defined

// Email request status
export const FAILED = 'failed';
export const TODO = 'todo';
// export const DONE = 'done';  // already defined

// Notification status
export const TO_SEND = 'to_send';
export const SENT = 'sent';
export const OUTDATED = 'outdated';

// Take off authorization status
export const REQUIRED = 'required';
export const LANDING_REQUIRED = 'landing_required';

export default {
  namespaced: true,
  state: {
    nomenclatura: [
      SUBMITTED,
      INCOMPLETE,
      REFUSED,
      RESERVES,
      ACCEPTED,
      CLOSED,
      DONE,
      CHECKED,
      PLANNED,
      PROCESSING,
      ACTION_NEEDED,
      CONFIRMED,
      CLEAR,
      CANCELED,
      ERROR,
      NOT_HANDLED,
      PENDING,
      AWAITING_VALIDATION,
    ],
    flightsStatusDict: [
      {
        value: PLANNED,
        text: 'Planifié',
      },
      {
        value: PROCESSING,
        text: 'En cours',
      },
      {
        value: ACTION_NEEDED,
        text: 'Action requise',
      },
      {
        value: RESERVES,
        text: 'Accepté sous condition',
      },
      {
        value: CLEAR,
        text: 'Accepté',
      },
      {
        value: CONFIRMED,
        text: 'Confirmé',
      },
      {
        value: REFUSED,
        text: 'Refusé',
      },
      {
        value: CANCELED,
        text: 'Annulé',
      },
      {
        value: ERROR,
        text: 'En erreur',
      },
    ],
    approvalsStatusDict: [
      {
        value: SUBMITTED,
        text: 'Déposé',
      },
      {
        value: ACCEPTED,
        text: 'Accepté',
      },
      {
        value: RESERVES,
        text: 'Accepté sous condition',
      },
      {
        value: REFUSED,
        text: 'Refusé',
      },
      {
        value: INCOMPLETE,
        text: 'Incomplet',
      },
      {
        value: PENDING,
        text: 'En cours de traitement',
      },
      {
        value: AWAITING_VALIDATION,
        text: 'En attente de validation',
      },
    ],
    activationsStatusDict: [
      {
        value: ACCEPTED,
        text: 'Accepté',
      },
      {
        value: REFUSED,
        text: 'Refusé',
      },
      {
        value: SUBMITTED,
        text: 'Déposé',
      },
    ],
  },
};
