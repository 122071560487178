<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Note ATS</translate>
    </v-card-title>
    <v-card-text>
      <div class="mb-2">
        {{ texts.internalNote }}
      </div>
      <div class="d-flex">
        <v-textarea
          v-model="atsNote"
          hide-details
          outlined
          rows="4"
        />
        <div class="d-flex flex-column justify-end">
          <SavedMessagesMenu
            isATSInternalNotes
            :text.sync="atsNote"
          />
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="$emit('close-form')">
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        class="white--text"
        color="primary"
        :loading="loading"
        @click="confirmATSNoteEdit()"
      >
        <translate>Confirm</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from '@/services/api';

import { SET_APPROVAL_DETAILS_NS } from '@/store/approvals';

import SavedMessagesMenu from '@/components/SavedMessagesMenu.vue';

export default {
  name: 'EditAtsNoteForm',
  components: { SavedMessagesMenu },
  props: {
    approval: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      atsNote: this.approval.ats_internal_note,
      loading: false,
    };
  },
  computed: {
    texts() {
      return {
        internalNote: this.$gettext(`You can enter a note for air traffic control. This will appear
          in the mission sheet, but will not be visible either in the interface or in the version
          of the mission sheet accessible to exploitants.`),
      };
    },
  },
  methods: {
    async confirmATSNoteEdit() {
      this.loading = true;
      await API.updateApprovalATSNote(this.approval.id, this.atsNote)
        .then(({ data }) => {
          this.$emit('close-form');
          this.$store.dispatch(
            SET_APPROVAL_DETAILS_NS,
            {
              approvalId: this.approval.id,
              data: { ats_internal_note: this.atsNote },
            },
          );
          this.showMessage(data.message, 'success');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
