var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3"},[(_vm.areasWithoutDecision.length)?_c('v-row',{staticClass:"my-2 outlined submitted",attrs:{"align":"center"},on:{"mouseenter":function($event){_vm.setAreasHover(_vm.areasWithoutDecision.map(function (a) { return a.id; }))},"mouseleave":function($event){return _vm.setAreasHover([])}}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1","order":"1"}},[_c('FlightStatusIcon',{attrs:{"status":"submitted","theme":"approvals"}})],1),_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","sm":"10","order":"3","order-sm":"2"}},[_c('translate',{attrs:{"translate-n":_vm.areasWithoutDecision.length,"translate-params":{ name: _vm.areasWithoutDecision.map(function (a) { return a.name; }).join(', ') },"translate-plural":"Zones awaiting treatments: %{ name }"}},[_vm._v(" Zone awaiting treatment: %{ name } ")])],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1","order":"2","order-sm":"3"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.centerOnAreasWithoutDecisions()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("icon-geoloc_line")])],1)],1)],1):_vm._e(),_vm._l((_vm.decisions),function(decision,index){
var _obj;
return _c('v-row',{key:index,staticClass:"my-2 outlined",class:( _obj = {}, _obj[decision.status] = true, _obj ),attrs:{"align":"center"},on:{"mouseenter":function($event){return _vm.setDecisionHover(decision.sub_approvals)},"mouseleave":function($event){return _vm.setDecisionHover([])}}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1","order":"1"}},[_c('FlightStatusIcon',{attrs:{"status":decision.status,"theme":"approvals"}})],1),_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","sm":"10","order":"3","order-sm":"2"}},[_c('div',[_vm._v(" "+_vm._s(_vm.getDecisionText(decision))+" ")]),(decision.flying_height)?_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-weight-medium"},[_vm._v(" Flying height restriction: ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("meter")(decision.flying_height))+" ")])]):_vm._e(),(decision.date_start || decision.date_end)?_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-weight-medium"},[_vm._v(" Period allowed: ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(decision.date_start || _vm.flight.date_start))+" - "+_vm._s(_vm._f("date")(decision.date_end || _vm.flight.date_end))+" ")])]):_vm._e(),(decision.time_start || decision.time_end)?_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-weight-medium"},[_vm._v(" Time range allowed: ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("hour")(decision.time_start || _vm.flight.time_start))+" - "+_vm._s(_vm._f("hour")(decision.time_end || _vm.flight.time_end))+" ")])]):_vm._e(),(decision.comment)?_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-weight-medium"},[_vm._v(" Authority comment: ")]),_c('p',{ref:("comment-" + (decision.id)),refInFor:true,staticClass:"requirements",class:{
            'with-overflow': _vm.commentsOverflowing[index],
            'collapsed': _vm.commentsCollapsed[index],
          },on:{"click":function($event){_vm.commentsCollapsed[index] = !_vm.commentsCollapsed[index]; _vm.$forceUpdate();}}},[_vm._v(" "+_vm._s(decision.comment)+" ")]),(_vm.commentsOverflowing[index] && _vm.commentsCollapsed[index])?_c('div',{staticClass:"cursor-pointer text-right pt-1",on:{"click":function($event){_vm.commentsCollapsed[index] = false; _vm.$forceUpdate();}}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-italic info--text text-decoration-underline"},[_vm._v(" see all ")])]):_vm._e()]):_vm._e(),(decision.extra_requirements)?_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-weight-medium"},[_vm._v(" Particular requirements: ")]),_c('p',{ref:("requirement-" + (decision.id)),refInFor:true,staticClass:"requirements",class:{
            'with-overflow': _vm.requirementsOverflowing[index],
            'collapsed': _vm.requirementsCollapsed[index],
          },on:{"click":function($event){_vm.requirementsCollapsed[index] = !_vm.requirementsCollapsed[index]; _vm.$forceUpdate();}}},[_vm._v(" "+_vm._s(decision.extra_requirements)+" ")]),(_vm.requirementsOverflowing[index] && _vm.requirementsCollapsed[index])?_c('div',{staticClass:"cursor-pointer text-right pt-1",on:{"click":function($event){_vm.requirementsCollapsed[index] = false; _vm.$forceUpdate();}}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-italic info--text text-decoration-underline"},[_vm._v(" see all ")])]):_vm._e()]):_vm._e()]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1","order":"2","order-sm":"3"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.centerOnDecision(decision.sub_approvals)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("icon-geoloc_line")])],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }