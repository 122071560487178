<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Delete the selected area?</translate>
    </v-card-title>
    <v-card-text>
      <translate>Are you sure you want to delete this zone?</translate>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close')"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="deleteCustomLayer()"
        :loading="deletionLoading"
      >
        <translate>Confirm</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from '@/services/api';

import { REFRESH_MAP_DATA_NS } from '@/store/map';
import { GET_AUTHORITY_LAYERS_NS } from '@/store/structures';

export default {
  name: 'CustomLayerDelete',
  props: { layerId: Number },
  data() {
    return { deletionLoading: false };
  },
  methods: {
    async deleteCustomLayer() {
      this.deletionLoading = true;
      await API.deleteCustomLayer(this.layerId)
        .then(() => {
          this.showMessage(this.$gettext('Zone deleted.'), 'success');
          this.$store.dispatch(GET_AUTHORITY_LAYERS_NS);
          this.$store.dispatch(REFRESH_MAP_DATA_NS);
        })
        .finally(() => {
          this.deletionLoading = false;
          this.$emit('close');
        });
    },
  },
};
</script>
