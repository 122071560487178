/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

import { SET_ACTIVATION_SELECTED_NS } from '@/store/activations';
import { APPLICATION_TAB } from '@/store/application';

import Notification from '@/components/Header/notifications/Notification';

/**
 * AuthorityActivationNotification class derived from Notification,
 * represent a notification about a new activation request.
 *
 * @class AuthorityActivationNotification
 * @extends {Notification}
 */
class AuthorityActivationNotification extends Notification {
  constructor(rawNotification) {
    super(rawNotification);
    const { payload } = rawNotification;
    this._activationId = payload.activation_id;
    this._tab = APPLICATION_TAB.ACTIVATION;
    this._route = 'activations';
    this._fullScreenRoute = 'activation-page';
  }

  async atClick(dispatchStoreAction, subscribeStoreMutation, navigate) {
    navigate(this._tab, this._route, this._fullScreenRoute, { id: this._activationId });
    dispatchStoreAction(SET_ACTIVATION_SELECTED_NS, this._activationId);
  }
}

export default AuthorityActivationNotification;
