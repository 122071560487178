<template>
  <div>
    <!-- Map Static Preview  -->
    <v-card class="pa-2">
      <v-img :src="mapPreviewSrc">
        <div class="d-flex justify-end pa-2">
          <v-btn
            icon
            elevation="2"
            class="white"
            :loading="mapLoading"
            @click="showMapDialog({ centerOnflight: false })"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </v-img>
    </v-card>
    <!-- Mapbox Dialog  -->
    <v-dialog
      v-model="map"
      content-class="map-dialog mr-6"
      width="1200"
      eager
    >
      <div id="map-container">
        <v-overlay
          :value="mapExclusionZoneLoading"
          :absolute="true"
        >
          <v-progress-circular indeterminate />
        </v-overlay>
        <!-- Mapbox Map -->
        <MapboxComponent>
          <!-- Exclusion Zone Legend -->
          <template
            v-if="exclusionZoneLoaded"
            slot="map-legend"
            slot-scope="{className}"
          >
            <div :class="className">
              <div class="d-flex py-2 align-center">
                <div :class="style.legendSymbolFlightArea" />
                <p
                  :class="style.legendLabelFlightArea"
                  v-translate
                >
                  Flying area
                </p>
              </div>
              <div class="d-flex py-2 align-center">
                <div :class="style.legendSymbolExclusionZone" />
                <p
                  :class="style.legendLabelExclusionZone"
                  v-translate
                >
                  Third party exclusion zone
                </p>
              </div>
              <template v-if="hasExclusionZoneMarkersType(markers.pilotPosition.id)">
                <div class="d-flex py-2 align-center">
                  <v-img
                    max-height="32"
                    max-width="32"
                    :src="markers.pilotPosition.localIcon"
                  />
                  <div
                    :class="style.mapMarkerLabel"
                    v-translate
                  >
                    Pilot positions
                  </div>
                </div>
              </template>
              <template v-if="hasExclusionZoneMarkersType(markers.takeOffPoint.id)">
                <div class="d-flex py-2 align-center">
                  <v-img
                    max-height="32"
                    max-width="32"
                    :src="markers.takeOffPoint.localIcon"
                  />
                  <div
                    :class="style.mapMarkerLabel"
                    v-translate
                  >
                    Take off points
                  </div>
                </div>
              </template>
              <template v-if="hasExclusionZoneMarkersType(markers.landingPoint.id)">
                <div class="d-flex py-2 align-center">
                  <v-img
                    max-height="32"
                    max-width="32"
                    :src="markers.landingPoint.localIcon"
                  />
                  <div
                    :class="style.mapMarkerLabel"
                    v-translate
                  >
                    Landing points
                  </div>
                </div>
              </template>
            </div>
          </template>
        </MapboxComponent>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import {
  ZOOM_TO_CENTER_NS,
  HIDE_FLIGHTS_NS,
  ZOOM_TO_AREA_NS,
  RESIZE_MAP_NS,
  SET_MAP_APPROVAL_SELECTED_NS,
  SET_FLIGHT_AREAS_SELECTED_NS,
  SET_FLIGHT_CENTERS_SELECTED_NS,
  SET_FLIGHT_EXCLUSION_ZONE_NS,
  ADD_EXCLUSION_ZONE_MARKER_NS,
  CLEAR_EXCLUSION_ZONE_MARKERS_NS,
  SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_NS,
} from '@/store/map';
import { CLOSED } from '@/store/status';
import { GET_STRUCTURES_AIP_GEOMETRIES_NS } from '@/store/structures';

import variables from '@/styles/variables.scss';

import { parseExclusionZoneKml } from '@/components/Flights/FlightExclusionZoneForm.vue';
import { getStaticImageUrl } from '@/components/Map/MapboxGL.helper';

import Map from '@/components/Map/Map.vue';

const STATUS_MATCHING_COLOR = {
  submitted: variables.flightSubmittedBackgroundColor,
  incomplete: variables.flightIncompleteAuthorityBackgroundColor,
  accepted: variables.flightAcceptedBackgroundColor,
  refused: variables.flightRefusedBackgroundColor,
  reserves: variables.flightReservesBackgroundColor,
  action_needed: variables.flightReservesBackgroundColor,
  pending: variables.flightPendingBackgroundColor,
  closed: variables.flightArchivedBackgroundColor,
};

export default {
  name: 'ApprovalMap',
  components: { MapboxComponent: Map },
  props: {
    approval: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      map: false,
      initialDialogOpen: true,
      mapExclusionZoneLoading: false,
      exclusionZoneData: null,
      emptyData: { type: 'FeatureCollection', features: [] },
      style: {
        legendSymbolFlightArea: 'mr-2 flex-shrink-0 zet-legend-symbol-flight-area',
        legendLabelFlightArea: 'mb-0 zet-legend-label-flight-area',
        legendSymbolExclusionZone: 'mr-2 flex-shrink-0 zet-legend-symbol-exclusion-zone',
        legendLabelExclusionZone: 'mb-0 zet-legend-label-exclusion-zone',
        mapMarkerLabel: 'pl-2',
      },
    };
  },
  computed: {
    approvalId() {
      return this.approval.id;
    },
    approvalArea() {
      return this.$store.getters['approvals/approvalArea'];
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    exclusionZoneAvailable() {
      return (
        this.isUserPrefecture
        && this.approval.flight.exclusion_zone
      );
    },
    exclusionZoneLoaded() {
      return this.exclusionZoneData;
    },
    mapLoading() {
      return (
        this.$store.state.approvals.approvalDetailsLoading
        || !this.$store.state.map.isMapDataLoaded
      );
    },
    mapPreviewGeojson() {
      let geojson = {};
      if (!this.exclusionZoneAvailable && this.approvalArea) {
        geojson = JSON.parse(JSON.stringify(this.approvalArea));
      } else if (this.exclusionZoneLoaded) {
        geojson = this.exclusionZoneData.flightArea;
        if (geojson.type === 'Feature') {
          geojson.properties = this.mapPreviewProperties();
        }
      }
      if (geojson.type === 'FeatureCollection') {
        for (let i = 0; i < geojson.features.length; i += 1) {
          geojson.features[i].properties = this.mapPreviewProperties(geojson.features[i].id);
        }
      }
      return geojson;
    },
    mapPreviewSrc() {
      if (this.mapPreviewGeojson) {
        return getStaticImageUrl({
          overlayGeojson: this.mapPreviewGeojson,
          clearProperties: false,
          width: 800,
          height: 400,
        });
      }
      return '';
    },
    markers() {
      return this.$store.state.map.exclusionZoneMarkers;
    },
  },
  watch: {
    approvalId() {
      this.downloadExclusionZone();
    },
    exclusionZoneData() {
      this.setMapData();
    },
    map(newValue) {
      if (newValue && this.$store.state.structures.structuresAIPGeometries.features.length === 0) {
        this.$store.dispatch(GET_STRUCTURES_AIP_GEOMETRIES_NS);
      }
    },
  },
  mounted() {
    this.downloadExclusionZone();
  },
  methods: {
    mapPreviewProperties(subApprovalId) {
      let color;
      if (this.approval.is_closed) {
        color = STATUS_MATCHING_COLOR[CLOSED];
      } else if (!subApprovalId) {
        color = STATUS_MATCHING_COLOR[this.approval.status];
      } else {
        const feature = this.approvalArea.features.find((f) => f.id === subApprovalId);
        color = STATUS_MATCHING_COLOR[feature.properties.status];
      }
      color = color || variables.flightSubmittedBackgroundColor;
      return {
        stroke: color,
        'stroke-width': 3,
        'stroke-opacity': 1,
        fill: color,
        'fill-opacity': 0.2,
      };
    },
    showMapDialog({ centerOnflight = false, selectedSubApprovalGeometry = null }) {
      this.map = true;
      this.$nextTick(() => {
        if (this.initialDialogOpen || centerOnflight || selectedSubApprovalGeometry) {
          if (this.initialDialogOpen) {
            this.$store.dispatch(RESIZE_MAP_NS);
            this.$store.dispatch(HIDE_FLIGHTS_NS);
            this.setMapData();
          }
          if (centerOnflight) {
            this.centerOnFlight();
          } else if (selectedSubApprovalGeometry) {
            this.$store.dispatch(ZOOM_TO_AREA_NS, selectedSubApprovalGeometry);
          } else if (this.initialDialogOpen) {
            this.centerOnFlight();
          }
          this.initialDialogOpen = false;
        }
      });
    },
    centerOnFlight() {
      if (this.exclusionZoneLoaded) {
        this.$store.dispatch(ZOOM_TO_AREA_NS, this.exclusionZoneData.exclusionArea);
      } else {
        this.$store.dispatch(ZOOM_TO_CENTER_NS, this.approval.center);
      }
    },
    hasExclusionZoneMarkersType(markerId) {
      if (this.exclusionZoneLoaded) {
        return Boolean(
          this.exclusionZoneData.markers.find((marker) => marker.markerId === markerId),
        );
      }
      return false;
    },
    setMapData() {
      this.clearMapExclusionZone();
      if (this.exclusionZoneLoaded) {
        this.setMapExclusionZone();
      } else {
        this.setMapSelectedApproval();
      }
    },
    setMapSelectedApproval() {
      this.$store.dispatch(SET_MAP_APPROVAL_SELECTED_NS, this.approvalArea);
    },
    clearMapExclusionZone() {
      this.$store.dispatch(SET_FLIGHT_CENTERS_SELECTED_NS, this.emptyData);
      this.$store.dispatch(SET_FLIGHT_AREAS_SELECTED_NS, this.emptyData);
      this.$store.dispatch(SET_FLIGHT_EXCLUSION_ZONE_NS, null);
      this.$store.dispatch(CLEAR_EXCLUSION_ZONE_MARKERS_NS);
      this.$store.dispatch(
        SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_NS, { lines: null, centers: null },
      );
    },
    setMapExclusionZone() {
      this.$store.dispatch(SET_FLIGHT_CENTERS_SELECTED_NS, this.emptyData);
      this.$store.dispatch(SET_FLIGHT_AREAS_SELECTED_NS, this.exclusionZoneData.flightArea);
      this.$store.dispatch(SET_FLIGHT_EXCLUSION_ZONE_NS, this.exclusionZoneData.exclusionArea);
      this.exclusionZoneData.markers.forEach((marker) => {
        this.$store.dispatch(ADD_EXCLUSION_ZONE_MARKER_NS, marker);
      });
      this.$store.dispatch(
        SET_FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_NS,
        {
          lines: this.exclusionZoneData.radiusLines,
          centers: this.exclusionZoneData.radiusLinesCenters,
        },
      );
    },
    downloadExclusionZone() {
      if (this.exclusionZoneAvailable) {
        this.mapExclusionZoneLoading = true;
        APIService.authorityDownloadFlightExclusionZone(this.approval.id)
          .then(({ data }) => {
            const fileReader = new FileReader();
            fileReader.readAsText(data);
            fileReader.addEventListener('load', () => {
              const markerIds = Object.values(this.markers).map((m) => m.id);
              this.exclusionZoneData = parseExclusionZoneKml(
                fileReader.result, markerIds, this.$turf,
              );
            });
          })
          .finally(() => {
            this.mapExclusionZoneLoading = false;
          });
      } else {
        this.exclusionZoneData = null;
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
#map-container {
  height: 800px;
  position: relative;
}

.v-dialog__content:has(> .map-dialog) {
  justify-content: end;
}

</style>
