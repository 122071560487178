<template>
  <div>
    <v-card
      v-if="tooManyActivePilots"
      class="default--dialog__card pa-2"
    >
      <v-card-title>
        {{ planName }}
      </v-card-title>
      <v-card-text>
        <span v-html="tooManyActivePilotsText" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="info"
          class="px-3"
          @click="$emit('close')"
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          color="primary"
          min-width="120"
          class="px-8"
          disabled
        >
          <translate>Confirm</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      v-else
      class="default--dialog__card pa-2"
    >
      <v-alert
        v-if="oldSubscriptionPlan"
        dense
        text
        type="info"
        color="primary"
        class="body-2 py-3 mb-2"
      >
        {{ oldPlanChangeAlert }}
      </v-alert>
      <!-- Title -->
      <div class="text-center">
        <span class="primary--text font-weight-medium text-h6">
          {{ planName }}
        </span>
      </div>
      <!-- Formule -->
      <div class="d-flex align-center justify-center my-2">
        <!-- Two formula choices possible -->
        <div class="body-1 text-capitalize px-3">
          <span>
            {{ formules[0].label }}
          </span>
        </div>
        <v-switch
          v-model="secondFormuleSelected"
          color="grey lighten-1"
          class="my-2 ml-2"
          hide-details
        />
        <div class="body-1 text-capitalize px-3">
          <span>
            {{ formules[1].label }}
          </span>
        </div>
      </div>
      <v-divider />
      <!-- Details -->
      <v-card-text class="body-1 flex-grow-1">
        <div class="d-flex flex-column align-center">
          <p
            v-if="planDescription"
            class="mb-4 px-lg-5 text-center multilines"
          >
            {{ planDescription }}
          </p>
          <p
            v-if="monthlyPrice"
            class="text-h5 primary--text font-weight-bold mb-0"
          >
            {{ monthlyPrice }}
          </p>
          <p
            v-if="priceDetail"
            class="mb-4"
          >
            {{ priceDetail }}
          </p>
          <p
            v-if="annualPrice"
            class="mb-1"
          >
            <translate>Therefore</translate>
            {{ annualPrice }}
            {{ priceDetail }}
          </p>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          text
          color="info"
          class="px-3"
          @click="$emit('close')"
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          color="primary"
          min-width="120"
          class="px-8"
          @click="confirm()"
        >
          <translate>Confirm</translate>
        </v-btn>
      </v-card-actions>
      <div class="text-center caption info--text mt-2">
        {{ asteriskInfoCancellation }}
      </div>
      <!-- Billing Info -->
      <v-dialog
        v-model="dialogBillingInformation"
        max-width="780"
      >
        <v-card class="pt-3 px-3">
          <v-container class="px-1 px-md-3">
            <div>
              <span
                v-translate
                class="headline"
              >
                Billing information - Optional
              </span>
            </div>
            <div>
              <p class="mt-3 mb-0 caption info--text">
                <translate>
                  We invite you to fill your billing information before proceeding to checkout.
                </translate>
                <br>
                {{ translatedOptionalStep }}
              </p>
            </div>
            <BillingInformationForm
              :optional="true"
              :show-messages="false"
              :action-button="$gettext('Continue to secure payment')"
              @completed="checkout()"
            />
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Checkout Overlay -->
      <v-overlay :value="preparingCheckout" />
      <!-- Checkout -->
      <v-dialog
        v-if="showCheckoutDialog"
        v-model="showCheckoutDialog"
        max-width="1200"
      >
        <AskCookieStripe
          v-if="!hasAcceptedCookieStripe"
          :title="$gettext('Subscription Payment')"
          :dialogText="texts.stripeDialog"
          @close-form="showCheckoutDialog=false"
        />
        <Checkout
          v-if="hasAcceptedCookieStripe"
          :planName="planNameLong"
          :planCode="planCode"
          :upcomingInvoice="upcomingInvoice"
        />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import API from '@/services/api';

import BillingInformationForm from '@/components/Settings/Dronists/Subscription/BillingInformationForm.vue';
import Checkout from '@/components/Settings/Dronists/Subscription/Checkout.vue';
import AskCookieStripe from '@/components/Flights/RequestApproval/AskCookieStripe.vue';

export default {
  name: 'SubscriptionPlan',
  components: {
    BillingInformationForm,
    Checkout,
    AskCookieStripe,
  },
  props: {
    formules: {
      type: Array,
      required: true,
    },
    oldSubscriptionPlan: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      secondFormuleSelected: false,
      formulesLabelsElements: null,
      dialogBillingInformation: false,
      preparingCheckout: false,
      showCheckoutDialog: false,
      upcomingInvoice: null,
    };
  },
  computed: {
    texts() {
      return {
        stripeDialog: this.$gettext(`To access your subscription's payment form, please accept the
        cookies from our partner Stripe.`),
      };
    },
    selectedFormule() {
      if (this.secondFormuleSelected) {
        return 1;
      }
      return 0;
    },
    formuleData() {
      const selected = this.formules[this.selectedFormule];
      if (selected) {
        return selected.data;
      }
      return null;
    },
    planName() {
      if (this.formuleData) {
        return this.formuleData.name;
      }
      return null;
    },
    planNameLong() {
      if (this.formuleData) {
        return `${this.formuleData.name} ${this.formules[this.selectedFormule].label}`;
      }
      return null;
    },
    planCode() {
      if (this.formuleData) {
        return this.formuleData.code;
      }
      return null;
    },
    planDescription() {
      if (this.formuleData) {
        return this.$gettextInterpolate(
          this.$gettext('%{description}\nCancel your subscription.*'),
          { description: this.formuleData.description },
          true,
        );
      }
      return null;
    },
    monthlyPrice() {
      if (this.formuleData) {
        return this.formuleData.priceForPlanCard.monthlyPrice;
      }
      return '';
    },
    annualPrice() {
      if (this.formuleData) {
        return this.formuleData.priceForPlanCard.annualPrice;
      }
      return '';
    },
    priceDetail() {
      if (this.formuleData) {
        return this.formuleData.priceForPlanCard.priceDetail;
      }
      return '';
    },
    translatedOptionalStep() {
      return this.$gettext(`This step is optionnal, you can fill this information later in your
        subscription settings.`);
    },
    asteriskInfoCancellation() {
      if (this.secondFormuleSelected) {
        return this.$gettext(`* If you cancel, your subscription will stop after one month and
          won't renew.`);
      }

      return this.$gettext(`* If you cancel, your subscription will stop after 12 months and won't
        renew.`);
    },
    oldPlanChangeAlert() {
      return this.$gettext(`Please note that your current subscription plan will no longer be
        available. If you choose to change to the new pricing you will not be able to return to
        your current plan.`);
    },
    tooManyActivePilots() {
      return this.$store.getters['pilots/activePilots'].length > 1;
    },
    tooManyActivePilotsText() {
      return this.$gettext(`This formula is available only for exploitant with only one active
        pilot.<br>If you want this formula, you can archive pilots in your account.<br>You can also
        take the enterprise formula.`);
    },
    hasAcceptedCookieStripe() {
      return this.$store.getters['authentication/hasAcceptedCookieStripe'];
    },
  },
  methods: {
    confirm() {
      if (this.oldSubscriptionPlan) {
        const targetFormule = this.formuleData;
        targetFormule.formule = this.formules[this.selectedFormule].value;
        this.$emit('change-subscription', targetFormule);
      } else {
        this.prepareCheckout();
      }
    },
    prepareCheckout() {
      this.dialogBillingInformation = true;
    },
    checkout() {
      if (this.planCode) {
        this.dialogBillingInformation = false;
        this.preparingCheckout = true;
        API.previewSubscriptionInvoice(this.formuleData.code)
          .then(({ data }) => {
            this.upcomingInvoice = data;
            this.showCheckoutDialog = true;
          })
          .finally(() => {
            this.preparingCheckout = false;
          });
      }
    },
  },
};
</script>
