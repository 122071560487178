<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>Information</translate>
    </v-card-title>
    <div v-if="announcement">
      <v-card-text>
        <v-sheet
          light
          outlined
          class="pa-12 mt-2"
        >
          <vue-markdown
            :source="announcement.text"
            html
            xhtml-out
            typographer
            linkify
            breaks
            show
            :emoji="false"
            :anchorAttributes="{ target: '_blank' }"
            class="markdown"
          />
        </v-sheet>
      </v-card-text>
    </div>
    <v-card-text
      v-else
      class="text-center"
      key="no-notif"
    >
      <translate>No information currently.</translate>
    </v-card-text>
    <v-card-actions class="d-flex align-center">
      <v-checkbox
        v-if="announcement"
        v-model="stopDisplayAnnouncementDialog"
        :label="texts.stopDisplayDialog"
        color="primary"
        class="px-3 py-0 ma-0"
        hide-details
      />
      <v-btn
        text
        color="info"
        @click="closeAnnouncements()"
      >
        <translate>Close</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { STOP_DISPLAY_ANNOUNCEMENT_NS } from '@/store/exploitants';
import VueMarkdown from 'vue-markdown';

export default {
  name: 'Announcement',
  components: { VueMarkdown },
  data() {
    return { stopDisplayAnnouncementDialog: false };
  },
  computed: {
    texts() {
      return { stopDisplayDialog: this.$gettext('Do not display this message anymore') };
    },
    announcement() {
      return this.$store.state.exploitants.announcement;
    },
  },
  methods: {
    closeAnnouncements() {
      if (this.stopDisplayAnnouncementDialog) {
        this.$store.dispatch(STOP_DISPLAY_ANNOUNCEMENT_NS, this.announcement.id);
      }
      this.$emit('close-announcements');
    },
  },
};
</script>
