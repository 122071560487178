<template>
  <Chat
    :events="opinion.events"
    :newMessageDisabled="newMessageDisabled"
    :atSendMessage="sendMessage"
    :maxAttachmentSize="maxAttachmentSize"
  />
</template>

<script>
import API from '@/services/api';

import { MAX_DOCUMENT_SIZE_MB } from '@/settings';

import Chat from '@/components/Chat.vue';

const MAX_OPINION_DOCUMENT_SIZE_MB = MAX_DOCUMENT_SIZE_MB.opinion;

export default {
  name: 'OpinionChat',
  components: { Chat },
  props: {
    opinion: Object,
    newMessageDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { maxAttachmentSize: MAX_OPINION_DOCUMENT_SIZE_MB };
  },
  methods: {
    async sendMessage(message, attachedFiles) {
      const payload = { message };
      return API.sendOpinionMessage(this.opinion.id, payload, attachedFiles)
        .then(({ data }) => data.events);
    },
  },
};
</script>
