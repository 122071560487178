<template>
  <div>
    <div
      class="headline mb-5 black--text"
      v-translate
    >
      Reset password
    </div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model="email"
        :label="$gettext('Email')"
        :placeholder="$gettext('Email address')"
        :rules="rules.email"
        @keyup.enter="resetPassword()"
      />
    </v-form>
    <v-row
      no-gutters
      justify="end"
      class="flex-wrap-reverse"
    >
      <v-btn
        text
        class="mt-5 px-0 primary--text"
        @click="$emit('back-to-login')"
      >
        <translate>Back</translate>
      </v-btn>
      <v-btn
        color="primary"
        class="mt-5 ml-5"
        @click="resetPassword()"
        :loading="loading"
        :disabled="loading"
      >
        <translate>RESET</translate>
      </v-btn>
    </v-row>

  </div>
</template>

<script>
import API from '@/services/api';

export default {
  name: 'ResetPassword',
  data() {
    return {
      valid: true,
      idEditing: null,
      requirements: {},
      loading: false,
      email: '',
      showPassword: false,
      rules: {
        email: [
          (v) => !!v || this.$gettext('An email is required.'),
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$gettext('Email invalid.');
          },
        ],
      },
    };
  },
  methods: {
    async resetPassword() {
      if (this.$refs.form.validate()) {
        const payload = { email: this.email };
        this.loading = true;
        await API.resetPassword(payload)
          .then(() => {
            this.showMessage(
              this.$gettextInterpolate(
                this.$gettext('Reset password email sent to %{email}'),
                { email: this.email },
              ),
              'success',
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
