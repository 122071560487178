<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <translate>KML file import</translate>
    </v-card-title>
    <v-card-text class="py-0">
      <v-stepper
        v-model="e1"
        class="kml-import__stepper"
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            :editable="!sendingBulkImportRequest"
            :rules="[() => valid]"
            step="1"
            class="pl-1"
          >
            <translate>Data</translate>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="e1 > 2"
            step="2"
            class="pr-2"
          >
            <translate>Validation</translate>
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <!-- ---------------- Step 1 ---------------- -->
          <v-stepper-content
            step="1"
            class="pa-0 mb-2"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-card
                color="grey lighten-4"
                min-height="280px"
                elevation="0"
              >
                <p class="pl-4 pt-4 pb-1">
                  <translate>Elements found in the file</translate>
                  <span class="font-weight-medium">
                    '{{ fileName }}'
                  </span>
                  <br>
                  <span
                    class="caption font-italic grey--text text--darken-1"
                    v-translate
                  >
                    Please select entities to import
                  </span>
                </p>
                <!-- --------- MultiPolygons ----------- -->
                <template>
                  <v-checkbox
                    v-if="multiPolygonFeatures.length"
                    key="multipolygon-selection"
                    v-model="selectedTypes"
                    :label="`${multiPolygonFeatures.length} ${labels.multiPolygons}`"
                    :rules="[selectedTypeRequired]"
                    value="multipolygon"
                    class="pl-4 pb-2"
                    color="primary"
                    hide-details
                  />
                  <div
                    v-if="selectedTypes.includes('multipolygon')"
                    class="flex-wrap section container pb-0"
                  >
                    <v-radio-group
                      v-model="multiPolygonRadio"
                      :disabled="onlyMultiZonesRadio"
                    >
                      <v-radio
                        key="single"
                        :label="labels.singleMultipolygons"
                        value="single"
                      />
                      <v-radio
                        key="multi"
                        :label="labels.multiMultipolygons"
                        value="multi"
                      />
                    </v-radio-group>
                  </div>
                </template>
                <!-- --------- Polygons ----------- -->
                <v-checkbox
                  v-if="polygonFeatures.length"
                  key="polygon-selection"
                  v-model="selectedTypes"
                  :label="`${polygonFeatures.length} ${labels.polygons}`"
                  :rules="[selectedTypeRequired]"
                  value="polygon"
                  class="pl-4 pb-2"
                  color="primary"
                  hide-details
                />
                <!-- --------- Lines ----------- -->
                <template>
                  <v-checkbox
                    v-if="lineFeatures.length"
                    key="line-selection"
                    v-model="selectedTypes"
                    :label="`${lineFeatures.length} ${labels.lines}`"
                    :rules="[selectedTypeRequired]"
                    value="line"
                    class="pl-4 text--black"
                    color="primary"
                    hide-details
                  />
                  <div
                    v-if="selectedTypes.includes('line')"
                    class="flex-wrap section container pb-0"
                  >
                    <v-radio-group
                      v-model="lineRadio"
                      :rules=[linesCanBePolygonized]
                    >
                      <v-radio
                        key="lineToPolygon"
                        :label="labels.lineToPolygon"
                        value="polygon"
                      />
                      <v-radio
                        key="lineToItinerary"
                        :label="labels.lineToItinerary"
                        value="itinerary"
                      />
                    </v-radio-group>
                    <div v-if="lineRadio === 'itinerary'">
                      <v-text-field
                        v-model="lineRadius"
                        :placeholder="placeholders.radius"
                        :rules="[linesRadiusRequired]"
                        type="number"
                        suffix="m"
                        class="pr-4"
                      />
                    </div>
                  </div>
                </template>
                <!-- --------- Points ----------- -->
                <template>
                  <v-checkbox
                    v-if="pointFeatures.length"
                    key="point-selection"
                    v-model="selectedTypes"
                    :label="`${pointFeatures.length} ${labels.points}`"
                    :rules="[selectedTypeRequired]"
                    value="point"
                    class="pl-4 text--black"
                    color="primary"
                    hide-details
                  />
                  <!-- Radius -->
                  <v-row
                    v-if="selectedTypes.includes('point')"
                    class="flex-wrap section container pb-0"
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="11"
                      sm="3"
                    >
                      <v-text-field
                        v-model="radius"
                        :placeholder="placeholders.radius"
                        :rules="[radiusRequired]"
                        type="number"
                        class="pt-1"
                        suffix="m"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                      md="6"
                    >
                      <span class="caption font-italic grey--text text--darken-1">
                        {{ hints.radius }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <!-- Selection required -->
                <p
                  v-if="!valid && selectedTypeRequired !== true"
                  class="mt-3 mb-0 px-4 caption error--text"
                >
                  {{ texts.selectedTypeRequired }}
                </p>
                <!-- Radio to select type of mission creation (merged or per polygon) -->
                <v-radio-group
                  v-if="polygonFeatures.length || pointFeatures.length || lineFeatures.length"
                  v-model="onlyMultiZonesRadio"
                  :rules="rules.typeMissionCreation"
                  class="mt-8 pl-4"
                  row
                >
                  <v-radio :value="true">
                    <template v-slot:label>
                      <div
                        :class="onlyMultiZonesRadio === true ? 'primary--text' : 'inherit'"
                        v-translate
                      >
                        Group all geometries in 1 mission
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <div
                        key="slot-label"
                        :class="onlyMultiZonesRadio === false ? 'primary--text' : 'inherit-color'"
                      >
                        <translate
                          :translate-n="nbFlightsMultipleMissions"
                          :translate-params="{ nbFlightsMultipleMissions }"
                          translate-plural="Create %{ nbFlightsMultipleMissions } missions"
                        >
                          Create %{ nbFlightsMultipleMissions } mission
                        </translate>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <div class="pa-4">
                  <!-- ----- Flying Height ---- -->
                  <v-text-field
                    v-model="flight.flying_height"
                    :label="labels.flyingHeight"
                    :rules="rules.flyingHeight"
                    type="number"
                    suffix="m"
                  />
                  <!-- ----- Off Sight - In Sight  ---- -->
                  <v-radio-group
                    v-model="flight.pilot_in_sight"
                    :rules="rules.inSight"
                    class="mt-1"
                    row
                  >
                    <v-radio :value="true">
                      <template v-slot:label>
                        <div
                          class="primary--form__label"
                          :class="flight.pilot_in_sight === true ? '' : 'inherit-color'"
                          v-translate
                        >
                          FLIGHT IN SIGHT
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="false">
                      <template v-slot:label>
                        <div
                          class="primary--form__label"
                          :class="flight.pilot_in_sight === false ? '' : 'inherit-color'"
                          v-translate
                        >
                          FLIGHT OFF SIGHT
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <!-- --------- Entities ----------- -->
                  <v-select
                    v-if="subExploitantsAvailable"
                    :label="labels.entities"
                    v-model="flight.subExploitant"
                    :items="subExploitants"
                    :rules="rules.subExploitant"
                    item-text="name"
                    item-value="id"
                  />
                  <!-- --------- Tags ----------- -->
                  <FlightTags
                    :selected="flight.tags"
                    :placeholder="placeholders.tags"
                    :label="labels.tags"
                    @update-selected="(selected) => flight.tags=selected"
                  />
                </div>
              </v-card>
            </v-form>
            <div class="d-flex justify-end align-center">
              <!-- ---------------- Actions ---------------- -->
              <v-btn
                text
                color="info"
                class="mt-3"
                @click.native="close()"
              >
                <translate>Cancel</translate>
              </v-btn>
              <v-btn
                :disabled="!valid"
                text
                color="primary"
                class="mt-3"
                @click="proceed()"
              >
                <translate>Proceed</translate>
              </v-btn>
            </div>
          </v-stepper-content>
          <!-- ---------------- Step 2 ---------------- -->
          <v-stepper-content
            step="2"
            class="pa-0 mb-2"
          >
            <v-card
              color="grey lighten-4"
              min-height="280px"
              elevation="0"
            >
              <v-row
                align="center"
                justify="space-between"
              >
                <v-col>
                  <p class="pl-4 pt-4 pb-1 mb-1">
                    <translate>
                      Summary of missions that will be created after this import:
                    </translate>
                  </p>
                </v-col>
                <v-col
                  cols="1"
                  class="pl-2"
                >
                  <v-tooltip
                    bottom
                    color="info"
                    max-width="460"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="small-icon"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <template v-for="(line, index) in texts.tooltipText.split('<br>')">
                      <div :key="index">
                        {{ line }}
                      </div>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>
              <ul class="ml-4 mt-0 mb-1 pr-3">
                <li>
                  <translate>Total missions number:</translate>
                  <span class="font-weight-medium ml-1">
                    {{ nbFlightsCreated }}
                  </span>
                </li>
                <li>
                  <translate>Mission names:</translate>
                  <span class="font-weight-medium ml-1">
                    {{ namesUsed }}
                  </span>
                </li>
                <li>
                  <translate>Dates:</translate>
                  <span
                    v-if="flight.date_end === flight.date_start"
                    key="single-date"
                    class="font-weight-medium ml-1"
                  >
                    <translate :translate-params="{date: formatDate(flight.date_start)}">
                      On %{date}
                    </translate>
                  </span>
                  <span
                    v-else
                    key="date-range"
                    class="font-weight-medium ml-1"
                  >
                    <translate :translate-params="{
                        date_start: formatDate(flight.date_start),
                        date_end: formatDate(flight.date_end)
                      }">
                      From %{date_start} to %{date_end}
                    </translate>
                  </span>
                </li>
                <li>
                  <translate>Hours:</translate>
                  <span class="font-weight-medium ml-1">
                    <translate :translate-params="{
                        time_start: flight.time_start,
                        time_end: flight.time_end
                      }">
                      From %{time_start} to %{time_end}
                    </translate>
                  </span>
                </li>
                <li>
                  <translate>Duration:</translate>
                  <span class="font-weight-medium ml-1">
                    {{ flight.expected_duration }}
                  </span>
                </li>
                <li>
                  <translate>Maximum flying height:</translate>
                  <span class="font-weight-medium ml-1">
                    {{ flight.flying_height }}
                  </span> m
                </li>
                <li>
                  <span class="font-weight-medium">
                    {{ flightOffSight }}
                  </span>
                </li>
                <li v-if="flight.details">
                  <translate>Note:</translate>
                  <span class="font-weight-medium ml-1">
                    {{ flight.details }}
                  </span>
                </li>
                <li v-if="restrictFlight !== null">
                  <span
                    v-if="restrictFlight === true"
                    class="font-weight-medium"
                    v-translate
                    key="restrict"
                  >
                    Areas will be clipped according to network.
                  </span>
                  <span
                    v-if="restrictFlight === false"
                    class="font-weight-medium"
                    v-translate
                    key="disable-restrict"
                  >
                    Areas will not be clipped according to network.
                  </span>
                </li>
              </ul>
              <v-alert
                type="info"
                text
                class="mt-3 mb-0 py-2 mx-3 grey--text text--darken-2 caption"
              >
                <translate>Constrains analysis will processing after import</translate>
              </v-alert>
            </v-card>
            <div class="d-flex align-center justify-end">
              <!-- ---------------- Actions ---------------- -->
              <v-btn
                :disabled="sendingBulkImportRequest"
                text
                color="info"
                class="mt-3"
                @click.native="close()"
              >
                <translate>Cancel</translate>
              </v-btn>
              <v-btn
                :loading="sendingBulkImportRequest"
                :disabled="!valid"
                text
                color="primary"
                class="mt-3"
                @click="importKML()"
              >
                <translate>Import</translate>
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import API from '@/services/api';

import { APPLICATION_STATUS, SET_STATUS_NS } from '@/store/application';
import { FETCH_TAG_NAMES_NS } from '@/store/exploitants';
import {
  ADD_FLIGHT_CREATED_NS,
  GET_ALL_FLIGHTS_NS,
  UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS,
} from '@/store/flights';
import { MAP_STATUS, SET_MAP_STATUS_NS } from '@/store/map';

import FlightTags from '@/components/Flights/FlightTags.vue';

export default {
  name: 'KmlImportForm',
  components: { FlightTags },
  props: {
    features: Array,
    flight: Object,
    initialPilotInSight: Boolean,
    fileName: String,
    restrictFlight: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      e1: 1,
      valid: true,
      selectedTypes: [],
      radius: null,
      sendingBulkImportRequest: false,
      multiPolygonRadio: 'single',
      onlyMultiZonesRadio: false,
      lineRadio: 'polygon',
      lineRadius: null,
      rules: {
        flyingHeight: [
          (v) => v > 0 || this.$gettext('A positive number greater than zero is required'),
        ],
        inSight: [
          (v) => v !== null || this.$gettext('The flight visibility must be selected'),
        ],
        typeMissionCreation: [
          (v) => v !== null || this.$gettext('The type of mission creation must be selected'),
        ],
        subExploitant: [
          (v) => !!v || this.$gettextInterpolate(
            this.$gettext('%{ entity } to select'),
            { entity: this.entityName.singular },
          ),
        ],
      },
    };
  },
  computed: {
    labels() {
      return {
        multiPolygons: this.$ngettext(
          'MultiPolygon',
          'MultiPolygons',
          this.polygonFeatures.length,
        ),
        polygons: this.$ngettext(
          'Polygon',
          'Polygons',
          this.polygonFeatures.length,
        ),
        lines: this.$ngettext(
          'Line',
          'Lines',
          this.lineFeatures.length,
        ),
        points: this.$ngettext(
          'Point',
          'Points',
          this.pointFeatures.length,
        ),
        singleMultipolygons: this.multiPolygonFeatures.length
          ? this.$gettextInterpolate(
            this.$ngettext(
              'Create %{ nb } mission (one by multipolygons)',
              'Create %{ nb } missions (one by multipolygons)',
              this.multiPolygonFeatures.length,
            ),
            { nb: this.multiPolygonFeatures.length },
          ) : '',
        multiMultipolygons: this.multiPolygonFeatures.length
          ? this.$gettextInterpolate(
            this.$ngettext(
              'Create %{ nb } mission (one by polygon in multipolygons)',
              'Create %{ nb } missions (one by polygon in multipolygons)',
              this.nbMultiPolygons,
            ),
            { nb: this.nbMultiPolygons },
          ) : '',
        lineToPolygon: this.$gettext('Transform line to polygon'),
        lineToItinerary: this.$gettext('Transform line to an itinerary'),
        flyingHeight: this.$gettext('Max flying height'),
        entities: this.$gettextInterpolate(
          this.$gettext('%{ entity } to link to the flight'),
          { entity: this.entityName.singular },
        ),
        tags: this.$gettext('Tags'),
      };
    },
    placeholders() {
      return {
        radius: this.$gettext('Radius'),
        tags: this.$gettext('Tags for created missons'),
      };
    },
    hints() {
      return {
        radius: this.$gettext(`Please indicate the flight area radius around the points (in
          meters)`),
      };
    },
    texts() {
      return {
        selectedTypeRequired: this.$gettext('A choice is required!'),
        tooltipText: this.$gettext(`Created missions use the form data as parameters, and the names
          are determined using:<br>1- A name field in the KML file;<br>2- A name indicated in the
          mission form, followed by a number (mission-1, mission-2, ...);<br>3- The file name,
          followed by a number (filename-1, filename-2, ...).`),
        notEnoughtPointsLinestring: this.$gettext(
          'Lines need to have at least 3 points to be convert into a polygon',
        ),
      };
    },
    multiPolygonFeatures() {
      if (this.features) {
        const features = this.$turf.featureCollection(
          this.features.filter((feature) => feature.geometry.type === 'GeometryCollection'),
        );
        // Remove z coordinates if necessary
        return this.$turf.truncate(features, { coordinates: 2 }).features;
      }
      return [];
    },
    nbMultiPolygons() {
      if (this.multiPolygonFeatures.length) {
        return this.multiPolygonFeatures.map((f) => f.geometry.geometries).flat().length;
      }
      return 0;
    },
    polygonFeatures() {
      if (this.features) {
        const features = this.$turf.featureCollection(
          this.features.filter((feature) => feature.geometry.type === 'Polygon'),
        );
        // Remove z coordinates if necessary
        return this.$turf.truncate(features, { coordinates: 2 }).features;
      }
      return [];
    },
    lineFeatures() {
      const features = this.$turf.featureCollection(
        this.features.filter((feature) => feature.geometry.type === 'LineString'),
      );
        // Remove z coordinates if necessary
      return this.$turf.truncate(features, { coordinates: 2 }).features;
    },
    pointFeatures() {
      const features = this.$turf.featureCollection(
        this.features.filter((feature) => feature.geometry.type === 'Point'),
      );
        // Remove z coordinates if necessary
      return this.$turf.truncate(features, { coordinates: 2 }).features;
    },
    radiusRequired() {
      let required = true;
      if (this.selectedTypes.includes('point')) {
        required = !!this.radius;
      }
      return required || this.$gettext('A numeric value is required!');
    },
    linesRadiusRequired() {
      let required = true;
      if (this.selectedTypes.includes('line')) {
        required = !!this.lineRadius;
      }
      return required || this.$gettext('A numeric value is required!');
    },
    linesCanBePolygonized() {
      const enoughPoints = (
        this.lineRadio === 'itinerary'
        || this.lineFeatures.every((feature) => feature.geometry.coordinates.length > 2)
      );
      return enoughPoints || this.texts.notEnoughtPointsLinestring;
    },
    selectedTypeRequired() {
      return this.selectedTypes.length > 0 || this.texts.selectedTypeRequired;
    },
    selectedFeatures() {
      let selectedFeatures = [];
      if (this.selectedTypes.includes('polygon')) {
        selectedFeatures = selectedFeatures.concat(this.polygonFeatures);
      }
      if (this.selectedTypes.includes('line')) {
        selectedFeatures = selectedFeatures.concat(this.lineFeatures);
      }
      if (this.selectedTypes.includes('point')) {
        selectedFeatures = selectedFeatures.concat(this.pointFeatures);
      }
      if (this.selectedTypes.includes('multipolygon')) {
        selectedFeatures = selectedFeatures.concat(this.multiPolygonFeatures);
      }
      return selectedFeatures;
    },
    nbFlightsCreated() {
      return this.onlyMultiZonesRadio ? 1 : this.nbFlightsMultipleMissions;
    },
    nbFlightsMultipleMissions() {
      if (this.selectedTypes.includes('multipolygon') && this.multiPolygonRadio === 'multi') {
        return this.selectedFeatures.length + this.nbMultiPolygons - 1;
      }
      return this.selectedFeatures.length;
    },
    namesUsed() {
      if (this.selectedFeatures.length) {
        if (this.flight?.name) {
          return `${this.flight.name}-N`;
        }
        const isEmptyNames = this.selectedFeatures.some((feature) => !feature.properties.name);
        if (isEmptyNames) {
          const name = this.fileName.split('.kml')[0];
          return `${name}-N`;
        }
        return this.$gettext('Field "Name" of the KML file');
      }
      return '';
    },
    flightOffSight() {
      if (this.flight.pilot_in_sight === true) {
        return this.$gettext('In sight flight');
      }
      if (this.flight.pilot_in_sight === false) {
        return this.$gettext('Off sight flight');
      }
      return this.$gettext('Unspecified pilot in sight');
    },
    subExploitantsAvailable() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    subExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  watch: {
    onlyMultiZonesRadio(newValue) {
      if (newValue) {
        this.multiPolygonRadio = 'single';
      }
    },
  },
  created() {
    this.flight.flying_height = this.$store.state.authentication.user.default_flying_height;
    this.flight.pilot_in_sight = this.initialPilotInSight;
  },
  methods: {
    proceed() {
      if (this.$refs.form.validate()) {
        this.e1 = 2;
      }
    },
    formatDate(date) {
      return format(new Date(date), 'd MMMM yyyy', { locale: fr });
    },
    validatePayload() {
      return (
        this.flight.pilot_in_sight !== null
        && this.flight.flying_height !== null
        && this.flight.flying_height !== ''
        && this.flight.date_start.length
        && this.flight.date_end.length
        && this.flight.expected_duration.length > 4
      );
    },
    importKML() {
      this.sendingBulkImportRequest = true;
      if (!this.validatePayload()) {
        this.sendingBulkImportRequest = false;
        this.close();
        this.$emit('validate-form');
        this.showMessage(
          this.$gettext('Errors found in form. Please correct them.'),
          'error',
        );
        return;
      }
      const payload = {
        flight_data: this.flight,
        features: this.selectedFeatures,
        point_buffer_radius: this.radius,
        source: this.fileName.split('.kml')[0],
        one_mission_by_multi_polygon: this.multiPolygonRadio === 'single',
        one_mission_for_all_geometries: this.onlyMultiZonesRadio,
        line_buffer_radius: this.lineRadius,
        line_to_polygon: this.lineRadio === 'polygon',
      };
      if (this.flight.subExploitant) {
        payload.flight_data.sub_exploitant = this.flight.subExploitant;
      } else if (this.subExploitants.length === 1) {
        payload.flight_data.sub_exploitant = this.subExploitants[0].id;
      } else {
        delete payload.flight_data.sub_exploitant;
      }
      delete payload.flight_data.subExploitant;
      if (this.restrictFlight !== null) {
        payload.disable_restrict_flights = !this.restrictFlight;
      }
      API.flightBulkImport(payload)
        .then(({ data }) => {
          this.sendingBulkImportRequest = false;
          this.close();
          const nmessage = this.$ngettext(
            // singular
            '%{nbFlightsCreated} flight created.',
            // plural
            '%{nbFlightsCreated} flights created.',
            data.length,
          );
          const message = this.$gettextInterpolate(
            nmessage,
            { nbFlightsCreated: data.length },
          );
          this.showMessage(message, 'success');
          this.$emit('import');
          data.forEach((f) => {
            this.$store.commit(ADD_FLIGHT_CREATED_NS, { flightId: f.id });
          });
          this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
          this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
          this.$store.commit(UPDATE_FLIGHT_ID_UPDATED_OR_CREATED_NS, { flightId: data[0].id });
          this.$store.dispatch(GET_ALL_FLIGHTS_NS, { updateBoundingBox: true });
          const oldTags = this.$store.state.exploitants.tagNames;
          if (this.flight.tags?.find((t) => !oldTags.includes(t))) {
            this.$store.dispatch(FETCH_TAG_NAMES_NS);
          }
        })
        .catch((e) => {
          this.sendingBulkImportRequest = false;
          this.close();
          this.$emit('error', e);
        });
    },
    close() {
      this.$emit('close');
      this.e1 = 1;
      this.selectedTypes = [];
      this.radius = null;
    },
  },
};

</script>

<style
  lang="scss"
  scoped
>
.kml-import__stepper {
  box-shadow: none !important
}

.section {
  margin-left: 2em;
  margin-right: 2em;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0em;
}

</style>
