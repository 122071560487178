<template>
  <v-container class="pa-0">
    <!-- Approval details -->
    <ActivationDetails
      :activation="activation"
      @center-on-approval="centerOnApproval()"
      @center-on-area="(area) => $emit('center-on-area', area)"
    />
    <v-row
      no-gutters
      class="details-card px-4 pb-2"
    >
      <hr class="separator" />
      <!-- Activation chat -->
      <v-expansion-panels
        light
        v-model="chatPanel"
        class="chat_panel"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="details-card__label">
              <translate>DISCUSSIONS</translate>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content expand-icon="keyboard_arrow_down">
            <ActivationChat
              v-if="chatLoaded"
              :activation="activation"
              :newMessageDisabled="newMessageDisabled"
            />
            <v-col
              v-else
              class="text-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                :size="20"
                :width="2"
              />
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <!-- Footer activation -->
    <v-row
      no-gutters
      wrap
      class="details-card__footer px-3 py-1"
      :class="getStatusClass"
    >
      <v-col cols="4">
        <StatusIcon
          :status="activation.status"
          withTooltip
          withText
          transparentBackground=""
        />
      </v-col>
      <!-- Actions on activation status -->
      <v-col
        cols="8"
        class="d-flex align-center justify-end"
      >
        <v-tooltip
          top
          color="primary"
          v-if="activation.status !== 'refused'"
          key="refused"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="openDialogStatus('refused')"
            >
              <StatusIcon
                status="refused"
                linear
                transparentBackground=""
              />
            </v-btn>
          </template>
          <translate>Refuse the activation.</translate>
        </v-tooltip>
        <v-tooltip
          top
          color="primary"
          v-if="activation.status !== 'accepted'"
          key="accepted"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="openDialogStatus('accepted')"
            >
              <StatusIcon
                status="accepted"
                linear
                transparentBackground=""
              />
            </v-btn>
          </template>
          <translate>Accept the request.</translate>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- Dialog to change status -->
    <v-dialog
      v-model="dialogStatus"
      width="650"
      height="800"
      persistent
    >
      <ChangeActivationStatusForm
        :activation="activation"
        :statusForUpdate="statusForUpdate"
        @close-form="closeDialogStatus()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { CANCELLED } from '@/store/status';

import ActivationDetails from '@/components/Activations/ActivationDetails.vue';
import ActivationChat from '@/components/Activations/ActivationChat.vue';
import ChangeActivationStatusForm from '@/components/Activations/ChangeActivationStatusForm.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'Activation',
  components: {
    ActivationDetails,
    ActivationChat,
    ChangeActivationStatusForm,
    StatusIcon,
  },
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogStatus: false,
      statusForUpdate: '',
      chatPanel: 0,
    };
  },
  computed: {
    approval() {
      return this.activation.approval;
    },
    getStatusClass() {
      return { [this.activation.status]: true };
    },
    chatLoaded() {
      return this.activation.events?.length;
    },
    newMessageDisabled() {
      return this.activation.status === CANCELLED;
    },
  },
  methods: {
    openDialogStatus(status) {
      this.statusForUpdate = status;
      this.dialogStatus = true;
    },
    closeDialogStatus() {
      this.dialogStatus = false;
      this.statusForUpdate = null;
    },
    centerOnApproval() {
      const area = this.$turf.multiPolygon(
        this.approval.sub_approvals.map((s) => s.geometry.coordinates),
      );
      this.$emit('center-on-area', area);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.chat_panel ::v-deep .v-expansion-panel-content__wrap {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
}

</style>
