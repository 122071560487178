<template>
  <!-- Create approval button -->
  <div v-if="canRequestApproval || canRequestActivation">
    <RequestApprovalActionsCreate
      v-if="canRequestApproval"
      :flight="flight"
      :constraint="constraint"
      :capability="capability"
      :sendingApproval="sendingApproval"
      @create-approval="(payload) => createApproval(payload)"
      @create-awaiting-payment-approval="(payload) => createApprovalAwaitingPayment(payload)"
      @update-approval-awaiting-payment="
        (approvalId, payload) => updateApprovalAwaitingPayment(approvalId, payload)
      "
      @retry-create-external-approval="
        (approvalId, payload) => retryCreateExternalApproval(approvalId, payload)
      "
      class="pb-2"
    />
    <RequestActivationActionCreate
      v-if="canRequestActivation"
      :flight="flight"
      :constraint="constraint"
      :sendingApproval="sendingApproval"
      @action-on-approval="() => reloadAnalysis()"
      class="pb-2"
    />
  </div>
</template>

<script>
import APIService from '@/services/api';

import {
  GET_CONSTRAINTS_ANALYSIS_NS,
  UPDATE_FLIGHT_APPROVALS_NS,
} from '@/store/flights';
import { ACCEPTED, ERROR, NOT_HANDLED, RESERVES } from '@/store/status';

import RequestActivationActionCreate from '@/components/Flights/RequestApproval/RequestActivationActionCreate.vue';
import RequestApprovalActionsCreate from '@/components/Flights/RequestApproval/RequestApprovalActionsCreate.vue';

export default {
  name: 'RequestApprovalActions',
  components: {
    RequestActivationActionCreate,
    RequestApprovalActionsCreate,
  },
  props: {
    flight: Object,
    constraint: Object,
    capability: Object,
  },
  data() {
    return { sendingApproval: false };
  },
  computed: {
    canRequestApproval() {
      return this.constraint.status === NOT_HANDLED;
    },
    approvals() {
      return this.flight.approvals?.filter((a) => a.constraint_id === this.constraint.id) || [];
    },
    canRequestActivation() {
      return (
        this.approvals.some((a) => [ACCEPTED, RESERVES].includes(a.status))
        && this.approvals.some((a) => a.activation_required)
      );
    },
  },
  methods: {
    retryCreateExternalApproval(
      approvalId,
      {
        pilotId,
        approvalDetails = '',
        flightAreasIds = [],
        externalPayload = {},
        activityDeclarationFile = null,
        myDocumentsIds = [],
        attachedFiles = [],
      },
    ) {
      this.sendingApproval = true;
      this.showMessage(this.$gettext('Sending approval request...'), 'success');
      APIService.retrySubmitApproval({
        approvalId,
        constraintId: this.constraint.id,
        pilotId,
        approvalDetails,
        flightAreasIds,
        externalPayload,
        activityDeclarationFile,
        myDocumentsIds,
        attachedFiles,
      })
        .then(({ data }) => {
          const { approval } = data;
          this.displayMessageAndReloadAnalysis(approval);
        })
        .finally(() => {
          this.sendingApproval = false;
        });
    },
    createApproval({
      pilotId,
      droneIds = [],
      approvalDetails = '',
      flightAreasIds = [],
      declaredScenario = '',
      externalPayload = {},
      activityDeclarationFile = null,
      prefectureNotifPayload = {},
      myDocumentsIds = [],
      attachedFiles = [],
    }) {
      this.sendingApproval = true;
      this.showMessage(this.$gettext('Sending approval request...'), 'success');
      APIService.createApprovalFromFlight({
        flightId: this.flight.id,
        constraintId: this.constraint.id,
        pilotId,
        droneIds,
        approvalDetails,
        flightAreasIds,
        declaredScenario,
        externalPayload,
        activityDeclarationFile,
        prefectureNotifPayload,
        myDocumentsIds,
        attachedFiles,
      })
        .then(({ data }) => {
          const { approval } = data;
          this.displayMessageAndReloadAnalysis(approval);
        })
        .finally(() => {
          this.sendingApproval = false;
        });
    },
    createApprovalAwaitingPayment({
      pilotId,
      droneIds = [],
      approvalDetails = '',
      flightAreasIds = [],
      externalPayload = {},
      activityDeclarationFile = null,
      prefectureNotifPayload = {},
      myDocumentsIds = [],
      attachedFiles = [],
    }) {
      APIService.createApprovalFromFlight({
        flightId: this.flight.id,
        constraintId: this.constraint.id,
        pilotId,
        droneIds,
        approvalDetails,
        flightAreasIds,
        externalPayload,
        activityDeclarationFile,
        prefectureNotifPayload,
        myDocumentsIds,
        attachedFiles,
      })
        .then(({ data }) => {
          const { approval } = data;
          approval.constraint_id = this.constraint.id;
          this.$store.commit(UPDATE_FLIGHT_APPROVALS_NS, {
            flightId: this.flight.id,
            approvals: [{ ...approval }],
          });
        });
    },
    updateApprovalAwaitingPayment(
      approvalId, { pilotId, droneIds = [], approvalDetails = '', flightAreasIds = [], declaredScenario = '' },
    ) {
      this.sendingApproval = true;
      this.showMessage(this.$gettext('Sending approval request...'), 'success');
      APIService.updateApprovalFromFlight({
        approvalId,
        constraintId: this.constraint.id,
        pilotId,
        droneIds,
        approvalDetails,
        flightAreasIds,
        declaredScenario,
      })
        .then(({ data }) => {
          const { approval } = data;
          this.displayMessageAndReloadAnalysis(approval);
        })
        .finally(() => {
          this.sendingApproval = false;
        });
    },
    displayMessageAndReloadAnalysis(approval) {
      if (approval.status === ERROR) {
        let errorMessage = this.$gettext('An error occurred, retry later.');
        const messages = approval.events.filter((e) => !e.is_user_event);
        if (messages.length) {
          errorMessage = messages[0].message;
        }
        this.showMessage(errorMessage, 'error');
      } else {
        this.showMessage(this.$gettext('Approval submitted.'), 'success');
      }
      this.reloadAnalysis();
    },
    reloadAnalysis() {
      this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id });
    },
  },
};
</script>
