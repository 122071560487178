<template>
  <div class="custom-layers-list">
    <div class="mt-4">
      <v-text-field
        v-model="search"
        :append-icon="search ? null : 'search'"
        class="searchbox"
        :placeholder="$gettext('Search')"
        dark
        solo
        flat
        clearable
        hide-details
      />
    </div>
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="layers"
      :loading="!layersLoaded"
      :search="search"
      :class="{ transparent: true }"
      :expanded="expanded"
      dark
      single-expand
      calculate-widths
      :loading-text="$gettext('Loading custom layers...')"
      :no-data-text="$gettext('No custom layers.')"
      item-key="id"
      :no-results-text="$gettext('No custom layers found.')"
      :footer-props="{
        'items-per-page-text': $gettext('Lines per page:'),
        'items-per-page-all-text': $gettext('All')
      }"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <tr
          :class="getStatusClass(props)"
          class="cursor-pointer"
          @click="selectItem(props)"
        >
          <td>
            <div class="d-flex align-center px-3">
              <div class="d-flex align-center flex-grow-1">
                <span>
                  {{ props.item.name }}
                </span>
                <span
                  v-if="props.item.auto_accept_procedure"
                  class="ml-3"
                >
                  <StatusIcon
                    status="accepted"
                    linear
                    :transparent-background="darkThemeIcon(props)"
                  />
                </span>
                <span
                  v-if="props.item.auto_restrict_procedure"
                  class="ml-3"
                >
                  <StatusIcon
                    status="reserves"
                    linear
                    :transparent-background="darkThemeIcon(props)"
                  />
                </span>
                <span
                  v-if="props.item.auto_refuse_procedure"
                  class="ml-3"
                >
                  <StatusIcon
                    status="refused"
                    linear
                    :transparent-background="darkThemeIcon(props)"
                  />
                </span>
                <span
                  v-if="props.item.auto_inform_contact_procedure"
                  class="ml-3"
                >
                  <v-icon :color="colorIcon(props)">
                    mdi-email-outline
                  </v-icon>
                </span>
                <span
                  v-if="props.item.auto_ats_internal_note_procedure"
                  class="ml-3"
                >
                  <v-icon :color="colorIcon(props)">
                    mdi-clipboard-text
                  </v-icon>
                </span>
                <span
                  v-if="props.item.auto_impacting_zone_procedure"
                  class="ml-3"
                >
                  <v-icon :color="colorIcon(props)">mdi-alert-circle</v-icon>
                </span>
              </div>
              <div
                v-if="props.item.date_start || props.item.date_end"
                class="d-flex justify-end"
              >
                <span v-if="props.item.date_start && props.item.date_end">
                  {{ props.item.date_start | dateShort }} - {{ props.item.date_end | dateShort }}
                </span>
                <span v-else-if="props.item.date_start">
                  <translate>À partir du</translate>&nbsp;{{ props.item.date_start | dateShort }}
                </span>
                <span v-else-if="props.item.date_end">
                  <translate>Jusqu'au</translate>&nbsp;{{ props.item.date_end | dateShort }}
                </span>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pa-0"
          :class="isArchived(item) ? 'archived' : ''"
        >
          <CustomLayer
            :layer="item"
            @edit-custom-layer="layer => { editLayer(layer) }"
          />
        </td>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }}
        <span v-translate>de</span>
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  HIDE_CUSTOM_LAYER_NS,
  SET_BOUNDING_BOX_NS,
  SHOW_CUSTOM_LAYER_NS,
} from '@/store/map';
import { GET_AUTHORITY_LAYERS_NS } from '@/store/structures';

import CustomLayer from '@/components/CustomLayers/CustomLayer.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'CustomLayerListing',
  components: {
    CustomLayer,
    StatusIcon,
  },
  data() {
    return {
      headers: [
        {
          text: this.$gettext('Name of the zone'),
          value: 'name',
          width: '100%',
          class: 'pl-3',
        },
      ],
      search: '',
      expanded: [],
      today: new Date(),
    };
  },
  computed: {
    layers() {
      return this.$store.state.structures.layers;
    },
    layersLoaded() {
      return this.$store.state.structures.layersLoaded;
    },
  },
  created() {
    this.getAuthorityLayers();
  },
  methods: {
    isArchived(item) {
      return item.date_end ? new Date(item.date_end) < this.today : false;
    },
    getStatusClass(props) {
      const archived = this.isArchived(props.item);
      return {
        selected: archived ? false : props.isExpanded,
        submitted: true,
        archived,
      };
    },
    darkThemeIcon(props) {
      return props.isExpanded || this.isArchived(props.item);
    },
    colorIcon(props) {
      if (this.darkThemeIcon(props)) return 'white';
      if (props.item.auto_impacting_zone_procedure) return 'red';
      return 'black';
    },
    async getAuthorityLayers() {
      await this.$store.dispatch(GET_AUTHORITY_LAYERS_NS);
    },
    getLayerArea(layer) {
      return this.$turf.multiPolygon(layer.areas.map((area) => area.geometry.coordinates[0]));
    },
    selectItem(props) {
      if (this.expanded.length === 0) {
        this.expanded = [props.item];
        this.$store.dispatch(SHOW_CUSTOM_LAYER_NS, { layerId: props.item.id });
        this.$store.dispatch(
          SET_BOUNDING_BOX_NS,
          { fromGeom: this.getLayerArea(props.item) },
        );
      } else {
        if (props.item.default_visibility === false) {
          this.$store.dispatch(HIDE_CUSTOM_LAYER_NS, { layerId: props.item.id });
        }
        if (!props.isExpanded) {
          this.expanded = [props.item];
        } else {
          this.expanded = [];
        }
      }
    },
    editLayer(layer) {
      this.$store.dispatch(SET_BOUNDING_BOX_NS, { fromGeom: this.getLayerArea(layer) });
      this.$emit('edit-layer', layer);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.custom-layers-list ::v-deep .v-data-table__wrapper>table>tbody> {
  tr,
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: white;
    color: $color-secondary;
    &.selected {
      color: $color-white;
      background-color: $color-primary;
    }
    &.archived {
      background-color: $color-blue-grey;
      color: $color-white;
    }
  }
  tr:hover:not(.archived):not(.selected) {
    background-color: lighten($color: $color-info, $amount: 50);
  }
  tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 10px solid $color-secondary;
      height: 50px;
    }
    &:first-child td {
      height: 40px;
    }
  }
  td.archived {
    .details-card__footer {
      background-color: $color-blue-grey;
    }
  }
}
.custom-layers-list ::v-deep .v-data-table table {
  border-collapse: initial;
}

</style>
