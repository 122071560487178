<template>
  <v-row
    no-gutters
    class="details-card"
  >
    <!-- Activation details -->
    <v-col
      cols="12"
      class="px-4 pt-4"
    >
      <!-- Inside impacting zone -->
      <div
        v-if="approval.inside_impacting_zone"
        class="mb-2"
      >
        <v-icon color="red">mdi-alert-circle</v-icon>
        <span
          v-translate
          class="ml-1"
        >
          This approval is located in an impacting zone
        </span>
      </div>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            MISSION
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.display_identifier }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1 d-flex align-center"
        >
          <span
            class="details-card__label"
            v-translate
          >
            ADDRESS
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
          class="d-flex align-center justify-space-between"
        >
          <span v-if="approval.address">
            {{ approval.address }}
          </span>
          <span v-else>
            {{ approval.commune }}
          </span>
          <v-btn
            text
            icon
            small
            @click="$emit('center-on-approval')"
          >
            <v-icon color="primary">icon-geoloc_line</v-icon>
          </v-btn>
        </v-col>
        <template v-if="hasManySubApprovals">
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              LATITUDE
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ approval.lat | coord }} ({{ approval.lat | coordDMS(false) }})
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              LONGITUDE
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ approval.lng | coord }} ({{ approval.lng | coordDMS(true) }})
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              ALTITUDE MAX
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ maxAltitude | meterAndFeet  }}
          </v-col>
        </template>
        <template>
          <v-col
            cols="12"
            md="2"
            class="pr-1"
          >
            <span
              class="details-card__label"
              v-translate
            >
              DATES
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <span>
              {{ activation.date_start | date }} - {{ activation.date_end | date }}
            </span>
          </v-col>
        </template>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            LOCAL HOUR
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ activation.time_start | hour }} - {{ activation.time_end | hour }} loc.
        </v-col>
        <!-- UTC hour -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            UTC HOUR
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ activation.time_start_utc | hour }} - {{ activation.time_end_utc | hour }} UTC
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EXPECTED DURATION
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          {{ approval.flight.expected_duration | hour }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            FLIGHT
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <span
            v-if="approval.flight.pilot_in_sight"
            v-translate
          >
            in sight
          </span>
          <span
            v-else
            class="error--text font-weight-bold"
            v-translate
          >
            off sight
          </span>
        </v-col>
        <template v-if="approval.flight.night_flying">
          <v-col cols="2">
            <span
              class="details-card__label"
              v-translate
            >
              NIGHT FLIGHT
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <translate>yes</translate>
          </v-col>
        </template>
        <!-- Scenario -->
        <template v-if="approval.declared_scenario">
          <v-col cols="2">
            <span
              class="details-card__label"
              v-translate
            >
              SCENARIO
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ declaredScenario }}
          </v-col>
        </template>
        <hr class="separator" />
        <!-- Zone information -->
        <v-col cols="12">
          <SubApprovalsDetails
            :approval="approval"
            @center-on-sub-approval="(geom) => $emit('center-on-sub-approval', geom)"
          />
        </v-col>
        <hr class="separator" />
        <!-- Pilot information -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PILOT
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_name }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EMAIL
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_email }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PHONE NUMBER
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.pilot_phone_number | phone }}
        </v-col>
        <hr class="separator" />
        <!-- Exploitant information -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            EXPLOITANT
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.company_name }}
        </v-col>
        <!-- Exploitant phone number -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            PHONE NUMBER
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.exploitant.phone_number | phone }}
        </v-col>
        <!-- Exploitant AT UID -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            ALPHATANGO UID
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <span>
            {{ approval.exploitant.alphatango_uids.join(", ") }}
          </span>
        </v-col>
        <hr class="separator" />
        <!-- Details gave by dronist -->
        <v-col
          cols="12"
          md="2"
          class="pr-1"
        >
          <span
            class="details-card__label"
            v-translate
          >
            DETAILS
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          {{ approval.details }}
        </v-col>
        <!-- Mission sheet -->
        <template v-if="missionSheet">
          <hr class="separator" />
          <v-col
            cols="12"
            md="2"
            class="pr-1 d-flex align-center"
          >
            <span
              class="details-card__label"
              v-translate
            >
              MISSION SHEET
            </span>
          </v-col>
          <v-col
            cols="12"
            md="10"
          >
            <div class="d-flex">
              <v-menu
                absolute
                style="max-width: 600px"
              >
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on: tooltip }">
                      <div v-on="tooltip">
                        <v-btn
                          text
                          icon
                          v-on="menu"
                          class="m-0 p-0"
                          :loading="loadingMissionSheetDownload"
                          :disabled="!missionSheetAvailable"
                        >
                          <v-icon>icon-download</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <translate
                      v-if="missionSheetAvailable"
                      key="missionSheetAvailable"
                    >
                      Download mission sheet.
                    </translate>
                    <translate
                      v-else
                      key="missionSheetUnAvailable"
                    >
                      The mission sheet is being generated. You will download it quickly.
                    </translate>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(format, index) in missionSheetFormat"
                    :key="index"
                  >
                    <v-list-item-title
                      class="cursor-pointer"
                      @click="downloadMissionSheet(format.format)"
                    >
                      {{ format.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-col>
    <!-- Decisions -->
    <v-col
      cols="12"
      class="px-4"
    >
      <hr class="separator" />
      <AuthorityDecisionsDetails
        :approval="approval"
        :subApprovals="approval.sub_approvals"
        :forActivationId="activation.id"
        fullLabel
      />
    </v-col>
  </v-row>
</template>

<script>
import APIService from '@/services/api';

import { DONE } from '@/store/status';

import SubApprovalsDetails from '@/components/Approvals/SubApprovalsDetails.vue';
import AuthorityDecisionsDetails from '@/components/Approvals/AuthorityDecisionsDetails.vue';

export default {
  name: 'ApprovalDetails',
  components: {
    SubApprovalsDetails,
    AuthorityDecisionsDetails,
  },
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scenarios: {
        s0: this.$gettext('Scenario S0'),
        s1: this.$gettext('Scenario S1'),
        s2: this.$gettext('Scenario S2'),
        s3: this.$gettext('Scenario S3'),
        a1: this.$gettext('Open category A1'),
        a2: this.$gettext('Open category A2'),
        a3: this.$gettext('Open category A3'),
        sts01: this.$gettext('Scenario STS-01'),
        sts02: this.$gettext('Scenario STS-02'),
        hss: this.$gettext('Non-standard scenarios'),
      },
      missionSheetFormat: [
        { title: this.$gettext('Mission sheet (PDF)'), format: 'PDF' },
        { title: this.$gettext('Mission sheet (Word)'), format: 'Word' },
      ],
      loadingMissionSheetDownload: false,
    };
  },
  computed: {
    approval() {
      return this.activation.approval;
    },
    hasManySubApprovals() {
      return this.approval.sub_approvals?.length > 1;
    },
    maxAltitude() {
      return Math.max(...(this.approval.sub_approvals.map((s) => s.elevation + s.height)));
    },
    declaredScenario() {
      return this.scenarios[this.approval.declared_scenario] || this.$gettext('Unknown scenario');
    },
    missionSheet() {
      return this.approval.mission_sheet;
    },
    missionSheetAvailable() {
      return this.missionSheet?.status === DONE;
    },
  },
  methods: {
    downloadWord() {
      return APIService.downloadApprovalWordSheet(this.approval.id);
    },
    downloadPDF() {
      return APIService.downloadApprovalPDFSheet(this.approval.id);
    },
    downloadMissionSheet(format) {
      const downloadAction = (format === 'Word' ? this.downloadWord() : this.downloadPDF());
      const defaultName = (
        format === 'Word' ? this.$gettext('mission_sheet.docx')
          : this.$gettext('mission_sheet.pdf')
      );
      this.loadingMissionSheetDownload = true;
      downloadAction
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = (
            this.extractFileName(response.headers['content-disposition']) || defaultName
          );
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.loadingMissionSheetDownload = false;
        });
    },
  },
};
</script>
