<template>
  <v-app>
    <Header />
    <v-main class="fill_height">
      <v-container
        fluid
        fill-height
        pa-0
      >
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {
  SET_CURRENT_BREAKPOINT_NS,
  hideHubspotChatBot,
  restoreHubspotChatBot,
} from '@/store/application';

import Header from '@/components/Header/Header.vue';

export default {
  components: { Header },
  computed: {
    breakpoints() {
      const { xs, sm, md, lg, xl } = this.$vuetify.breakpoint;
      return { xs, sm, md, lg, xl };
    },
    activeBreakpoint() {
      return Object.keys(this.breakpoints).find((key) => this.breakpoints[key]);
    },
    currentBreakpoint: {
      get() {
        return this.$store.state.application.currentBreakpoint;
      },
      set(value) {
        this.$store.commit(SET_CURRENT_BREAKPOINT_NS, value);
        this.setHubspotChatBot();
      },
    },
  },
  watch: {
    activeBreakpoint(newValue) {
      this.currentBreakpoint = newValue;
    },
    currentBreakpoint(newValue) {
      if (newValue !== this.activeBreakpoint) {
        this.currentBreakpoint = this.activeBreakpoint;
      }
    },
  },
  mounted() {
    this.currentBreakpoint = this.activeBreakpoint;
  },
  methods: {
    setHubspotChatBot() {
      const isMobileBreakpoint = this.$store.getters['application/isMobileBreakpoint'];
      if (isMobileBreakpoint) {
        hideHubspotChatBot();
      } else {
        restoreHubspotChatBot();
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
#app {
  background: $color-secondary;
}
.fill_height {
  max-height: 100vh;
  max-width: 100vw;
  .row {
    max-width: 100vw;
  }
}

.v-content {
  height: calc(100vh - 64px);
}

</style>

<style lang="scss">
// Top margin or padding for header placement
.v-dialog {
  @media only screen and (max-width: 960px) {
    margin: 56px 12px 0px 12px;
    &--fullscreen {
      padding: 56px 0px 0px 0px;
      margin: 0px;
    }
  }
  @media only screen and (min-width: 960px) {
    margin: 64px 0px 0px 0px;
    &--fullscreen {
      padding: 64px 0px 0px 0px;
      margin: 0px;
    }
  }
  background: white;
}

</style>
