<template>
  <div class="invoice-list">
    <div class="headline mb-4">
      <translate>Invoices listing</translate>
    </div>
    <StripeOnboarding
      v-if="showStripeOnboarding"
      :structureId="structure.id"
    />
    <v-text-field
      v-model="search"
      append-icon="search"
      :label="$gettext('Search')"
      single-line
      hide-details
    />
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="invoices"
      :loading="loading"
      :search="search"
      single-expand
      calculate-widths
      :loading-text="$gettext('Invoices loading...')"
      :no-data-text="$gettext('No invoices found')"
      item-key="number"
      :no-results-text="$gettext('No invoices found')"
      :mobile-breakpoint="singleColumnBreakpoint"
      :footer-props="{
        'items-per-page-text': $gettext('Lines per page:'),
        'items-per-page-all-text': $gettext('All'),
        'items-per-page-options': [25, 50, 100, -1],
      }"
    >
      <template v-slot:[`item.download`]="{ item }">
        <v-btn
          icon
          :loading="loadingDownload(item.id)"
          @click="downloadInvoice(item)"
        >
          <v-icon color="info">icon-download</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import API from '@/services/api';

import StripeOnboarding from '@/components/Settings/Authorities/StripeOnboarding.vue';

export default {
  name: 'ApprovalInvoiceListing',
  props: {
    structure: {
      type: Object,
      required: true,
    },
  },
  components: { StripeOnboarding },
  data() {
    return {
      headers: [
        {
          text: this.$gettext('Invoice number'),
          value: 'number',
          width: '10%',
          class: 'pl-3',
        },
        {
          text: this.$gettext('Approval number'),
          value: 'approval_identifier',
          width: '15%',
        },
        {
          text: this.$gettext('Exploitant'),
          value: 'exploitant',
          width: '15%',
        },
        {
          text: this.$gettext('Amount'),
          value: 'amount',
          width: '10%',
        },
        {
          text: this.$gettext('Creation date'),
          value: 'created_at',
          width: '10%',
        },
        {
          text: this.$gettext('Payment date'),
          value: 'paid_at',
          width: '10%',
        },
        {
          text: this.$gettext('Download'),
          value: 'download',
          width: '5%',
          sortable: false,
          align: 'center',
        },
      ],
      invoices: [],
      search: '',
      loading: false,
      downloadingInvoiceId: null,
      singleColumnBreakpoint: this.$vuetify.breakpoint.thresholds.sm,
    };
  },
  computed: {
    showStripeOnboarding() {
      return !this.structure.onboarding_completed && this.structure.has_connected_account;
    },
  },
  created() {
    this.getApprovalsInvoicesAuthority(this.structure.id);
  },
  methods: {
    async getApprovalsInvoicesAuthority(structureId) {
      this.loading = true;
      await API.getApprovalsInvoices(structureId)
        .then((r) => {
          this.invoices = r.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async downloadInvoice(item) {
      this.downloadingInvoiceId = item.id;
      await API.downloadAuthorityInvoice(this.structure.id, item.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = (
            this.extractFileName(response.headers['content-disposition'])
            || this.$gettext('facture.pdf')
          );
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.downloadingInvoiceId = null;
        });
    },
    loadingDownload(invoiceId) {
      return invoiceId === this.downloadingInvoiceId;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.invoice-list table.v-data-table {
  border-collapse: initial;
}

.invoice-list {
  tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 10px solid $color-secondary;
      height: 50px !important;
    }
    &:first-child td {
      height: 40px !important;
    }
  }
}

</style>
