<template>
  <v-tooltip
    v-if="!disableTooltip"
    top
  >
    <template v-slot:activator="{on, attrs}">
      <div
        v-on="on"
        class="px-1"
      >
        <v-badge
          bottom
          overlap
          dot
          :color="badgeColor"
          :value="!!status"
        >
          <v-icon
            v-bind="attrs"
            :color="iconColor"
            :class="getClass"
          >
            mdi-cellphone-basic
          </v-icon>
        </v-badge>
      </div>
    </template>
    <span>
      {{ tooltipText }}
    </span>
  </v-tooltip>
  <div
    v-else
    class="px-1"
  >
    <v-badge
      bottom
      overlap
      dot
      :color="badgeColor"
      :value="!!status"
    >
      <v-icon
        :color="iconColor"
        :class="getClass"
      >
        mdi-cellphone-basic
      </v-icon>
    </v-badge>
  </div>
</template>

<script>
import { ACCEPTED, LANDING_REQUIRED, REFUSED, REQUIRED, SUBMITTED } from '@/store/status';

export default {
  name: 'TakeOffAuthorizationStatusIcon',
  props: {
    status: String,
    tooltip: String,
    disableTooltip: Boolean,
    transparentBackground: Boolean,
  },
  computed: {
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    badgeColor() {
      switch (this.status) {
        case SUBMITTED:
          return 'primary';
        case ACCEPTED:
          return 'success';
        case REFUSED:
          return 'error';
        case LANDING_REQUIRED:
          return 'warning';
        case REQUIRED:
        default:
          return 'grey';
      }
    },
    tooltipText() {
      if (this.tooltip) {
        return this.tooltip;
      }
      if (this.status) {
        switch (this.status) {
          case SUBMITTED:
            return this.$gettext('Take off authorization submitted');
          case ACCEPTED:
            return this.$gettext('Take off authorization granted');
          case REFUSED:
            return this.$gettext('Take off authorization refused');
          case LANDING_REQUIRED:
            return this.$gettext('Landing required');
          case REQUIRED:
            return this.$gettext('Take off authorization required');
          default:
            return this.$gettext('Take off authorization status unknown');
        }
      }
      return undefined;
    },
    iconColor() {
      return this.transparentBackground ? 'white' : 'info';
    },
    getClass() {
      return { animated: this.status === SUBMITTED && this.isUserAuthority };
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.animated {
  animation: bounce 1s ease infinite;
}

@keyframes bounce {
  from {
    transform: translateY(-1px);
  }
  to {
    transform: translateY(1px);
  }
}

</style>
