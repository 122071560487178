<template>
  <v-row
    no-gutters
    class="d-flex flex-column fill-height"
  >
    <!-- CONTROL ARROW BUTTONS FOR LISTING SPLIT -->
    <div
      id="split-arrows"
      class="secondary d-none d-md-inline"
      :style="styles.splitArrows"
    >
      <v-btn
        :disabled="isSmallDisplay"
        @click="toggleSmallerDisplay"
        small
        icon
        dark
      >
        <v-icon>mdi mdi-menu-left</v-icon>
      </v-btn>
      <v-btn
        :disabled="isBigDisplay"
        @click="toggleBiggerDisplay"
        small
        icon
        dark
      >
        <v-icon>mdi mdi-menu-right</v-icon>
      </v-btn>
    </div>
    <!-- ---------- ADD BUTTON ---------- -->
    <v-btn
      v-if="addButtonVisible"
      data-tour="addButton"
      class="d-inline"
      fab
      dark
      bottom
      right
      fixed
      float
      color="red"
      @click="setStatusCreate"
      :disabled="!isMapDataLoaded"
      :style="styles.addButton"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <!-- --------- LISTING SPLIT AND MAP SPLIT ---------- -->
    <v-row
      no-gutters
      id="splits-container"
    >
      <!-- LISTING SLOT -->
      <v-col
        v-show="activeSplit.listing"
        cols="12"
        :md="splitsDisplay.listing.md"
        :lg="splitsDisplay.listing.lg"
        :xl="splitsDisplay.listing.xl"
        id="listing-slot"
        class="split flex-grow-0 secondary"
        :class="{'with-border': !isMobileBreakpoint}"
        :style="styles.listingSplit"
      >
        <slot
          :display="display"
          :status="status"
          :toggleBiggerDisplay="toggleBiggerDisplay"
          :toggleSmallerDisplay="toggleSmallerDisplay"
          :toggleMap="toggleMap"
          name="listing"
        />
      </v-col>
      <!-- MAP SLOT -->
      <v-col
        v-show="activeSplit.map"
        cols="12"
        :md="splitsDisplay.map.md"
        :lg="splitsDisplay.map.lg"
        :xl="splitsDisplay.map.xl"
        class="split flex-grow-0"
        :style="styles.mapSplit"
      >
        <slot
          :display="display"
          :toggleListing="toggleListing"
          :splitActive="activeSplit.map"
          :setStatusCreate="setStatusCreate"
          name="map"
        />
      </v-col>
    </v-row>
    <!-- ------- NAVIGATION BETWEEN SPLITS FOR MOBILE -------- -->
    <v-bottom-navigation
      v-if="isMobileBreakpoint"
      v-model="split"
      class="justify-start pl-5"
      color="primary"
      height="50"
      horizontal
      fixed
    >
      <v-btn value="listing">
        <template v-if="mapStatusWrite">
          <translate key="form-split">Form</translate>
        </template>
        <template v-else>
          <translate key="list-split">List</translate>
        </template>
        <v-icon>mdi-view-list</v-icon>
      </v-btn>
      <v-btn value="map">
        <translate key="map-split">Map</translate>
        <v-icon>mdi-map</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-row>
</template>

<script>
import { display } from '@/settings';

import {
  SET_STATUS_NS,
  SET_DISPLAY_NS,
  SET_ACTIVE_SPLIT_NS,
  APPLICATION_STATUS,
  APPLICATION_DISPLAY,
  APPLICATION_SPLIT,
  APPLICATION_TAB,
} from '@/store/application';
import {
  MAP_STATUS,
  SET_MAP_STATUS_NS,
} from '@/store/map';

import colors from '@/styles/colors';

/**
 * This component manage a listing / map layout
 * At the left could be inserted a listing of items
 * At the right could be inserted a map with these items
 * The layout allows resize with arrows available at the frontier of left panel
 * We have special events in this layout :
 *   * when a component emit the '' event, we trigger toggleBiggerDisplay()
 *   * when the display change, we bind the `display` property to the children,
 *     they can react to the change of this property
 */
export default {
  name: 'LayoutListingMap',
  props: {
    displayButtonAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { colors };
  },
  computed: {
    currentBreakpoint() {
      return this.$store.state.application.currentBreakpoint;
    },
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    display() {
      return this.$store.state.application.display;
    },
    isSmallDisplay() {
      return this.$store.state.application.display === APPLICATION_DISPLAY.SMALL;
    },
    isBigDisplay() {
      return this.$store.state.application.display === APPLICATION_DISPLAY.BIG;
    },
    isMediumDisplay() {
      return this.$store.state.application.display === APPLICATION_DISPLAY.MEDIUM;
    },
    isMapDataLoaded() {
      return this.$store.state.map.isMapDataLoaded;
    },
    splitsDisplay() {
      /* eslint-disable no-nested-ternary */
      return {
        listing: {
          md: this.isBigDisplay ? 7 : this.isSmallDisplay ? 2 : 6,
          lg: this.isBigDisplay ? 7 : this.isSmallDisplay ? 1 : 5,
          xl: this.isBigDisplay ? 7 : this.isSmallDisplay ? 1 : 5,
        },
        map: {
          md: this.isBigDisplay ? 5 : this.isSmallDisplay ? 10 : 6,
          lg: this.isBigDisplay ? 5 : this.isSmallDisplay ? 11 : 7,
          xl: this.isBigDisplay ? 5 : this.isSmallDisplay ? 11 : 7,
        },
      };
    },
    activeSplit() {
      return {
        listing: (!this.isMobileBreakpoint
          || this.$store.state.application.activeSplit === APPLICATION_SPLIT.LISTING),
        map: (!this.isMobileBreakpoint
          || this.$store.state.application.activeSplit === APPLICATION_SPLIT.MAP),
      };
    },
    split: {
      get() {
        return this.$store.state.application.activeSplit;
      },
      set(newValue) {
        this.$store.dispatch(SET_ACTIVE_SPLIT_NS, newValue);
      },
    },
    status() {
      return this.$store.state.application.status;
    },
    mapStatusWrite() {
      return this.$store.state.map.map_status === MAP_STATUS.WRITE;
    },
    addButtonVisible() {
      return this.status === APPLICATION_STATUS.READ && this.displayButtonAdd === true;
    },
    styles() {
      return {
        splitArrows: {
          position: 'absolute',
          top: '0.5rem',
          left: this.calculateLeftValue('2rem'),
          'border-radius': '15px',
          'z-index': 100,
        },
        addButton: {
          position: 'fixed',
          bottom: '16px',
          left: this.calculateLeftValue('84px'),
          'z-index': 200,
        },
        listingSplit: {
          width: this.isSmallDisplay ? '150px' : '',
          '--banners-height': this.isMobileBreakpoint ? '106px' : '64px',
          'overflow-y': 'auto',
          'overflow-x': 'hidden',
        },
        mapSplit: { '--banners-height': this.isMobileBreakpoint ? '106px' : '64px' },
      };
    },
  },
  methods: {
    toggleBiggerDisplay() {
      switch (this.display) {
        case display.SMALL:
          this.$store.dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.MEDIUM);
          break;
        default:
          this.$store.dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.BIG);
          break;
      }
    },
    toggleSmallerDisplay() {
      switch (this.display) {
        case display.BIG:
          this.$store.dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.MEDIUM);
          break;
        default:
          this.$store.dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.SMALL);
          break;
      }
    },
    toggleListing() {
      this.$store.dispatch(SET_ACTIVE_SPLIT_NS, APPLICATION_SPLIT.LISTING);
    },
    toggleMap() {
      this.$store.dispatch(SET_ACTIVE_SPLIT_NS, APPLICATION_SPLIT.MAP);
    },
    setStatusCreate() {
      if (this.isSmallDisplay) {
        this.toggleBiggerDisplay();
      }
      if (this.$store.state.application.activeTab === APPLICATION_TAB.MISSION
        || this.$store.state.application.activeTab === APPLICATION_TAB.LAYER) {
        this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.WRITE);
      }
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.CREATE);
      this.$emit('clear-selected');
    },
    calculateLeftValue(padding = '2rem') {
      // calculate left value to align an element at the right of the listing split
      if (!this.isMobileBreakpoint) {
        return `calc(100%/12*${this.splitsDisplay.listing[this.currentBreakpoint]} - ${padding})`;
      }
      return `calc(100% - ${padding})`;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
#splits-container {
  max-width: 100%;
}

// do not exceed available remaining screen height to allow for scroll
.split {
  max-height: calc(100vh - var(--banners-height));
}
@supports (max-height: 100dvh) {
  .split {
    max-height: calc(100dvh - var(--banners-height));
  }
}

#listing-slot {
  &.with-border {
    border-right: 8px solid $color-secondary;
    ::v-deep .tab-item {
      padding-right: 8px;
    }
  }
  &:not(.with-border) {
    ::v-deep .tab-item {
      padding-right: 12px;
    }
  }
  ::v-deep .tab-item {
    padding-left: 12px;
    padding-bottom: 12px;
  }
}
// padding right added in mozilla browser only to compensate for scrollbar overlap
@-moz-document url-prefix() {
  #listing-slot {
    padding-right: 6px;
  }
}

</style>
