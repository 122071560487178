<template>
  <div>
    <!-- ------- Request Revalidation Button ------- -->
    <LayoutFlightConstraintAction
      id="request-revalidation"
      :flight="flight"
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <v-btn
          color="success"
          class="main-button__font mt-2"
          :disabled="disabled"
          @click.stop="dialog=true"
          block
        >
          <span v-translate>Send a validation request</span>
        </v-btn>
      </template>
    </LayoutFlightConstraintAction>
    <!-- ------- Request Revalidation Dialog ------- -->
    <v-dialog
      v-model="dialog"
      width="820px"
      :retain-focus="false"
      persistent
    >
      <v-card
        class="default--dialog__card"
        max-height="800px"
        elevation="0"
      >
        <v-card-title>
          <span
            v-translate
            class="warning--text"
          >
            Validation request
          </span>
        </v-card-title>
        <v-card-text>
          <span v-if="processThroughUSK">
            {{ texts.uskRevalidation }}
          </span>
          <v-form
            v-else
            v-model="valid"
          >
            <v-select
              v-if="incompleteDecisions.length > 1"
              v-model="decisionSelected"
              :items="incompleteDecisions"
              :label="$gettext('Select decision')"
              item-value="id"
            >
              <template v-slot:selection="{ item }">
                {{ getZoneNames(item.sub_approvals) }}
              </template>
              <template v-slot:item="{ item }">
                {{ getZoneNames(item.sub_approvals) }}
              </template>
            </v-select>
            <v-alert
              elevation="2"
              class="body-2"
              icon="mdi-alert-circle"
              color="warning"
              text
            >
              <span class="multilines">
                {{ decisionComment }}
              </span>
            </v-alert>
            <span>
              {{ texts.internalRevalidation }}
            </span>
            <v-textarea
              v-model="message"
              :label="texts.internalAuthority"
              outlined
              hide-details
              rows="3"
              class="py-2"
              :rules="rules.required"
            />
          </v-form>
          <v-file-input
            v-model="fileInput"
            :label="$gettext('Choose a file')"
            truncate-length="700"
            class="mt-4"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="dialog=false"
          >
            <span v-translate>Cancel</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="loading"
            :disabled="!valid || isDisabledForUSK"
            @click="revalidateApproval()"
          >
            <span v-translate>Send</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import { INCOMPLETE } from '@/store/status';

import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

export default {
  name: 'RequestRevalidation',
  components: { LayoutFlightConstraintAction },
  props: {
    flight: Object,
    approval: Object,
    processThroughUSK: Boolean,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      fileInput: undefined,
      message: '',
      valid: true,
      decisionSelected: null,
      rules: {
        required: [
          (value) => !!value || this.$gettext('A value is required'),
        ],
      },
    };
  },
  computed: {
    texts() {
      return {
        uskRevalidation: this.$gettext(`Before confirming your validation request, did you complete
          the protocol received in the chat of the approval request and sign it. Can you fill in
          the signed document?`),
        internalAuthority: this.$gettext('Message to the authority'),
        internalRevalidation: this.$gettext(`Before confirming your validation request, be sure
          you have given all the information requested by the authority in the requirements
          above. If a document is requested, you can find it in the discussion thread or in the
          documents section`),
      };
    },
    isDisabledForUSK() {
      return this.processThroughUSK && this.fileInput === undefined;
    },
    incompleteDecisions() {
      return this.approval?.decisions?.filter((d) => d.status === INCOMPLETE) || [];
    },
    decisionComment() {
      const decision = this.incompleteDecisions.find((d) => d.id === this.decisionSelected);
      return decision ? decision.comment || decision.extra_requirements : '';
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.decisionSelected = this.incompleteDecisions.at(0).id;
      } else {
        this.decisionSelected = null;
      }
    },
  },
  methods: {
    getZoneNames(subApprovals) {
      const { features } = this.$store.getters['flights/mapApprovalAreas'];
      return subApprovals.map(
        (s) => features.find((f) => s.flight_area_id === f.id)?.properties?.name || '',
      ).join(', ');
    },
    revalidateApproval() {
      this.loading = true;
      const formData = new FormData();
      if (this.fileInput) {
        formData.append('file', this.fileInput);
      }
      const message = (
        this.processThroughUSK
          ? this.$gettext('Revalidation request')
          : this.message
      );
      formData.append('message', message);
      formData.append('decision_id', this.decisionSelected);
      APIService.revalidateApproval(this.approval.id, formData)
        .then(({ data }) => {
          this.$emit('update-approval', data);
          this.showMessage(this.$gettext('Validation sent'), 'success');
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
          this.fileInput = undefined;
          this.message = '';
        });
    },
  },

};
</script>
