<template>
  <div class="d-flex">
    <v-tooltip
      :top="!tooltipBottom"
      :bottom="tooltipBottom"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          icon
          dark
          small
          class="flight-listing__action-button"
          v-on="on"
          @click="$emit('weather-flight', flight)"
          :disabled="disabledActions"
        >
          <v-icon>mdi-weather-partly-cloudy</v-icon>
        </v-btn>
      </template>
      <span v-translate>Météo</span>
    </v-tooltip>
    <v-tooltip
      :top="!tooltipBottom"
      :bottom="tooltipBottom"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          icon
          dark
          small
          class="flight-listing__action-button"
          v-on="on"
          @click="$emit('center-on-flight', flight)"
          :disabled="disabledActions"
        >
          <v-icon>mdi-map-marker-outline</v-icon>
        </v-btn>
      </template>
      <span v-if="flight.address">
        {{ flight.address }}
      </span>
      <span v-else-if="flight.commune">
        {{ flight.commune }}
      </span>
      <span
        v-translate
        v-else
      >
        Zoom to mission
      </span>
    </v-tooltip>
    <v-tooltip
      v-if="!flight.is_archived"
      :top="!tooltipBottom"
      :bottom="tooltipBottom"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          icon
          dark
          small
          class="flight-listing__action-button"
          v-on="on"
          @click="$emit('edit-flight', flight)"
          :loading="isSelectedFlightDetailsLoading"
          :disabled="disabledActions"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
      <span v-translate>Edit</span>
    </v-tooltip>
    <v-tooltip
      v-if="!simplifiedActions"
      :top="!tooltipBottom"
      :bottom="tooltipBottom"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          icon
          dark
          small
          class="flight-listing__action-button"
          v-on="on"
          @click="$emit('duplicate-flight', flight)"
          :loading="isSelectedFlightDetailsLoading"
          :disabled="disabledActions"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span v-translate>Duplicate mission</span>
    </v-tooltip>
    <v-tooltip
      :top="!tooltipBottom"
      :bottom="tooltipBottom"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          icon
          dark
          small
          class="flight-listing__action-button"
          v-on="on"
          @click="$emit('close-flight', flight)"
          :loading="isSelectedFlightDetailsLoading"
          :disabled="disabledActions"
        >
          <v-icon>mdi-archive-arrow-down-outline</v-icon>
        </v-btn>
      </template>
      <span v-translate>Archive</span>
    </v-tooltip>
    <v-tooltip
      v-if="!isGeneralistPilot"
      :top="!tooltipBottom"
      :bottom="tooltipBottom"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          icon
          dark
          small
          class="flight-listing__action-button"
          v-on="on"
          @click="$emit('delete-flights', flight)"
          :loading="isSelectedFlightDetailsLoading"
          :disabled="disabledActions"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      <span v-translate>Delete</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'FlightActions',
  props: {
    flight: {
      type: Object,
      required: true,
    },
    tooltipBottom: {
      type: Boolean,
      default: false,
    },
    actionFromFlightListing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isSelectedFlightDetailsLoading() {
      return (
        this.$store.state.flights.isSelectedFlightDetailsLoading
        && this.actionFromFlightListing
      );
    },
    disabledActions() {
      return (
        this.$store.state.flights.flightsCollectionLoading
        || this.$store.state.flights.isSelectedFlightDetailsLoading
      );
    },
    simplifiedActions() {
      return this.$store.getters['authentication/isGeneralistPilotOrManager'];
    },
    isGeneralistPilot() {
      return this.$store.getters['authentication/isGeneralistPilot'];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight-listing__action-button {
  margin-left: 6px;
  margin-right: 6px;
  color: $color-white  !important;
}

</style>
