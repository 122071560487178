<template>
  <div
    class="primary--form"
    :class="{ 'mr-2': !isMobileBreakpoint }"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <!-- Header -->
      <v-row
        no-gutters
        class="primary--form__header px-4"
      >
        <v-col
          cols="7"
          sm="8"
          class="text-left"
        >
          <translate v-if="isEditing">Pilot update</translate>
          <translate v-else>New pilot</translate>
        </v-col>
        <v-col
          cols="5"
          sm="4"
          class="text-right"
          :class="{ 'pr-1': !isMobileBreakpoint }"
        >
          <translate>Cancel</translate>
          <v-btn
            icon
            dark
            @click="close()"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-container class="primary--form__container">
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            sm="2"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              NAME *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="pr-0 pr-sm-5"
          >
            <v-text-field
              v-model="pilot.last_name"
              :rules="rules.last_name"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="2"
            class="pl-0 pl-sm-3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              FIRST NAME *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="pilot.first_name"
              :rules="rules.first_name"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="2"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              GENDER *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="pr-0 pr-sm-5"
          >
            <v-select
              v-model="pilot.gender"
              :items="genders"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="2"
            class="pl-0 pl-sm-3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              OPERATION *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="pilot.function"
              :rules="rules.function"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="2"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              EMAIL *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="10"
          >
            <v-text-field
              v-model="pilot.email"
              :rules="rules.email"
              class="p-0"
              :disabled="!canEditConnectionInformations"
            />
          </v-col>
          <v-col
            cols="12"
            sm="2"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              BIRTHDAY
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="10"
          >
            <v-row no-gutters>
              <v-menu
                v-model="birthdayMenu"
                :close-on-content-click="false"
                offset-y
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="pilot.birthday"
                    :label="labels.birthday"
                    :rules="rules.birthday"
                    prepend-icon="event"
                    v-bind="attrs"
                    v-on="on"
                    class="py-4"
                    @blur="birthday = parseDate(pilot.birthday)"
                    readonly
                  />
                </template>
                <v-date-picker
                  v-model="birthday"
                  @input="birthdayMenu=false"
                  no-title
                  scrollable
                  locale="fr"
                  first-day-of-week="1"
                  class="elevation-0"
                />
              </v-menu>
              <v-tooltip
                bottom
                color="info"
                max-width=580
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="small-icon pl-2"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <div>
                  {{ labels.tooltipBirth }}
                </div>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="2"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              BIRTHDAY PLACE
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="10"
          >
            <v-row no-gutters>
              <v-text-field
                v-model="pilot.birth_place"
                class="p-0"
              />
              <v-tooltip
                bottom
                color="info"
                max-width=580
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="small-icon pl-2"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <div>
                  {{ labels.tooltipBirth }}
                </div>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="2"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              PHONE NUMBER *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="10"
          >
            <v-row no-gutters>
              <v-text-field
                v-model="pilot.phone_number"
                :rules="rules.phone_number"
                class="p-0"
              />
            </v-row>
          </v-col>
          <v-col
            v-if="showEntityField"
            cols="12"
            sm="2"
          >
            <span class="primary--form__label text-uppercase">
              {{ entityName.plural }}
            </span>
          </v-col>
          <v-col
            v-if="showEntityField"
            cols="12"
            sm="10"
          >
            <v-row no-gutters>
              <v-autocomplete
                class="p-0"
                :items="subExploitants"
                v-model="pilot.subExploitants"
                :rules="subExploitantsRequired"
                multiple
                item-text="name"
                item-value="id"
                :no-data-text="$gettext('No result found')"
                :loading="loadingAllSubExploitants"
                :disabled="canNotEditHimself"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
        >
          <template v-if="isExploitantAdmin">
            <v-col
              cols="12"
              sm="2"
            >
              <span
                class="primary--form__label"
                v-translate
              >
                PROFILE *
              </span>
            </v-col>
            <v-col
              cols="12"
              sm="10"
            >
              <v-select
                v-model="profile"
                :rules="rules.profile"
                :items="contactProfiles"
                item-text="label"
                item-value="value"
                :disabled="profileDisabled"
                class="p-0"
              />
            </v-col>
          </template>
          <template>
            <v-col
              cols="12"
              class="pl-0 d-flex flex-row align-center"
            >
              <v-row
                no-gutters
                align="center"
                v-if="canEditConnectionInformations"
              >
                <v-col cols="11">
                  <div class="d-flex align-center">
                    <div
                      class="primary--form__label pr-3"
                      :class="{
                        'disabled-color': hasWebAccountDisabled,
                      }"
                    >
                      <translate>ACTIVE USER ACCOUNT</translate>
                    </div>
                    <v-switch
                      v-model="isPilotAccountActive"
                      :disabled="hasWebAccountDisabled"
                      class="my-2"
                      hide-details
                    />
                  </div>
                </v-col>
                <v-col cols="1">
                  <v-tooltip
                    bottom
                    color="info"
                    max-width="580"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="small-icon d-flex justify-end"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      {{ hasWebAccountTooltip }}
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </template>
          <template v-if="isEditing && canEditConnectionInformations">
            <v-col
              cols="12"
              class="pl-0 d-flex flex-row align-center"
            >
              <div
                class="primary--form__label pr-3"
                :class="{
                  'disabled-color': isMainContact,
                }"
              >
                <translate>CONSIDER FOR FUTURE MISSIONS</translate>
              </div>
              <v-tooltip
                bottom
                max-width="560"
              >
                <template v-slot:activator="{ on }">
                  <v-switch
                    v-model="isPilotForFutureMission"
                    v-on="on"
                    :disabled="isMainContact"
                    class="my-2"
                    hide-details
                  />
                </template>
                <span>
                  {{ archivePilotTooltip }}
                </span>
              </v-tooltip>
            </v-col>
          </template>
          <template v-if="pilotsCanBeRestricted">
            <v-col
              cols="12"
              class="pl-0 d-flex flex-row align-center"
            >
              <div
                class="primary--form__label pr-3"
                :class="{
                  'disabled-color': pilot.admin_privileges,
                }"
              >
                <translate>Restricted Pilot</translate>
              </div>
              <v-switch
                v-model="pilot.restricted_account"
                :disabled="pilot.admin_privileges"
                class="my-2"
                hide-details
              />
            </v-col>
          </template>
        </v-row>
        <v-alert
          v-if="!isEditing && isPerSeatSubscription"
          dense
          text
          type="info"
          color="primary"
          class="body-2 py-3"
        >
          {{ perSeatChangeAlert }}
        </v-alert>
        <v-row
          no-gutters
          class="mt-2"
        >
          <v-btn
            v-if="!isEditing"
            block
            class="white--text"
            color="green"
            @click="attemptCreatePilot()"
            :disabled="!valid"
            :loading="loading"
          >
            <translate>CREATE PILOT</translate>
          </v-btn>
          <v-btn
            v-if="isEditing"
            block
            class="white--text"
            color="green"
            @click="attemptUpdatePilot()"
            :disabled="!valid"
            :loading="loading"
          >
            <translate>UPDATE PILOT</translate>
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
    <!-- Edit email user and contact dialog -->
    <v-dialog
      v-model="editEmailDialog"
      max-width="580"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span>
            <v-icon
              class="mr-2"
              color="warning"
            >
              mdi-alert
            </v-icon>
            <span>
              {{ labels.editEmailTitle }}
            </span>
          </span>
        </v-card-title>
        <v-card-text>
          {{ labels.editEmailText }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="editEmailDialog=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            @click="updatePilot()"
            :loading="loading"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Prorate dialog -->
    <v-dialog
      v-model="prorateDialog"
      max-width="580"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          {{ pilotChangeProrateTitle }}
        </v-card-title>
        <v-card-text>
          <template>
            <PilotChangeProrate
              :pilotToRemoveId="pilotToArchiveId"
              :addPilot="addPilot"
              :archiveMode="isEditing"
              @prorate-loading="prorateLoaded=false"
              @prorate-loaded="prorateLoaded=true"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="prorateDialog=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!prorateLoaded"
            :loading="loading"
            @click="confirmProrationChange()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Enterprise subscription dialog -->
    <v-dialog
      max-width="580"
      persistent
      v-model="notEnterpriseDialog"
    >
      <SubscriptionPlanEnterprise
        :fromSubscription="false"
        @close="$emit('close-dialog-enterprise')"
      />
    </v-dialog>
    <!-- Manager change a pilot to a team he do not handle dialog -->
    <v-dialog
      v-model="notHandleTeamDialog"
      max-width="580"
    >
      <v-card class="default--dialog__card">
        <v-card-title>
          <span>
            <v-icon
              class="mr-2"
              color="warning"
            >
              mdi-alert
            </v-icon>
            {{ labels.notHandleTeamTitle }}
          </span>
        </v-card-title>
        <v-card-text>
          {{ labels.notHandleTeamText }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="info"
            @click="notHandleTeamDialog=false"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            color="primary"
            @click="updatePilot()"
            :loading="loading"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from '@/services/api';

import { CONTACT_PROFILES, CUSTOM_CONTACT_PROFILES } from '@/settings';

import { APPLICATION_STATUS, SET_STATUS_NS } from '@/store/application';
import { GET_ALL_SUB_EXPLOITANTS_NS } from '@/store/exploitants';
import { MAP_STATUS, SET_MAP_STATUS_NS } from '@/store/map';

import PilotChangeProrate from '@/components/Pilots/PilotChangeProrate.vue';
import SubscriptionPlanEnterprise from '@/components/Settings/Dronists/Subscription/SubscriptionPlanEnterprise.vue';

const initialPilot = {
  first_name: '',
  last_name: '',
  gender: '',
  email: '',
  function: '',
  phone_number: '',
  has_web_account: true,
  birthday: '',
  birth_place: '',
  admin_privileges: false,
  is_manager: false,
  restricted_account: false,
};

export default {
  name: 'PilotForm',
  props: { pilotToEdit: { type: Object } },
  components: {
    PilotChangeProrate,
    SubscriptionPlanEnterprise,
  },
  data() {
    return {
      birthday: null,
      birthdayMenu: false,
      valid: true,
      loading: false,
      pilotInitiallyActive: null,
      pilotChangeProrateTitle: '',
      pilotToArchiveId: null,
      addPilot: false,
      prorateLoaded: false,
      prorateDialog: false,
      editEmailDialog: false,
      pilot: { ...initialPilot },
      rules: {
        first_name: [
          (v) => !!v || this.$gettext('First name is required'),
        ],
        last_name: [
          (v) => !!v || this.$gettext('A name is required'),
        ],
        phone_number: [
          (v) => !!v || this.$gettext('Phone number is required.'),
          (value) => {
            const pattern = /^((\+|00)33\s?|0)[1-9](\s?\d{2}){4}$/; // https://manual.limesurvey.org/Using_regular_expressions/fr#French_phone_number
            const patternNC = /^(\s?\d{2}){3}$/;
            // eslint-disable-next-line
            const patternInternational = /^((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/;
            return (
              pattern.test(value)
              || patternNC.test(value)
              || patternInternational.test(value)
              || this.$gettext('Phone number invalid.')
            );
          },
        ],
        email: [
          (v) => !!v || this.$gettext('Email invalid.'),
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$gettext('Email invalid.');
          },
        ],
        birthday: [
          (value) => {
            const pattern = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$|null/;
            return (
              pattern.test(value)
              || this.$gettext('Invalid date (awaiting JJ/MM/AAAA).')
            );
          },
        ],
        function: [
          (value) => (
            value.length < 50
            || this.$gettext('Max length of 50 characters exceeded')
          ),
        ],
      },
      genders: ['H', 'F'],
      notEnterpriseDialog: false,
      notHandleTeamDialog: false,
      profile: 'standard',
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    labels() {
      return {
        birthday: this.$gettext('Birthday (DD-MM-YYYY)'),
        tooltipBirth: this.$gettext(`Some approvals need more informations about your birth date
          and location. Fill this informations will help you next in your approval requests.`),
        editEmailTitle: this.$gettext('Changment of pilot email'),
        editEmailText: this.$gettext(`By changing this mail, you will also change the login mail of
          this pilot.\n Are you sure to continue the update of this pilot?`),
        notHandleTeamTitle: this.$gettext('Move to out of responsability team'),
        notHandleTeamText: this.$gettext(`By changing this pilot's team, he will also no longer
          be accessible on your pilot list. Are you sure to continue the update of this pilot?`),
      };
    },
    subExploitantsRequired() {
      return [
        (v) => (
          ((v && !!v.length) || this.profile !== 'is_manager')
          || this.$gettextInterpolate(
            this.$gettext('%{ entity } required for managers'),
            { entity: this.entityName.singular },
          )
        ),
      ];
    },
    isEditing() {
      return this.pilotToEdit !== null;
    },
    isMainContact() {
      if (this.isEditing) return this.pilotToEdit.is_main_contact;
      return false;
    },
    hasManySubExploitants() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    showEntityField() {
      if (this.isSubExploitantManager && this.pilotToEdit) {
        return true;
      }
      return this.hasManySubExploitants;
    },
    userSubExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    isExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    isSubExploitantManager() {
      return this.$store.getters['authentication/isUserExploitantManager'];
    },
    hasWebAccountTooltip() {
      if (!this.pilot.has_web_account) {
        return this.$gettext(`Activate this option if you want this pilot to have a personal user
          account to access Clearance plateform. The credentials of this account will be sent to
          the pilot's email address.`);
      }
      if (this.isEditing) {
        return this.$gettext(`Disable this option if you want to disable the Clearance user account
          of this pilot.`);
      }
      return this.$gettext(`Disable this option if you don't want to create a personal Clearance
        user account for this pilot.`);
    },
    archivePilotTooltip() {
      if (!this.pilot.is_archived) {
        let tooltip = this.$gettext(`By disabling this option, this pilot will not be concidered in
          your future missions`);
        if (this.isPerSeatSubscription) {
          tooltip += ' ';
          tooltip += this.$gettext(`and will no longer be concidered as well in your subscription
            fee`);
        }
        tooltip += '.';
        if (this.pilot.has_web_account) {
          tooltip += ' ';
          tooltip += this.$gettext("This pilot's personal user account will also be disabled.");
        }
        return tooltip;
      }
      let tooltip = this.$gettext(`By enabling this option, this pilot will be reconcidered in your
        future missions`);
      if (this.isPerSeatSubscription) {
        tooltip += ' ';
        tooltip += this.$gettext('and will be concidered as well in your subscription fee');
      }
      tooltip += '.';
      return tooltip;
    },
    isPilotForFutureMission: {
      get() {
        return !this.pilot.is_archived;
      },
      set(newValue) {
        this.pilot.has_web_account = newValue;
        this.pilot.is_archived = !newValue;
        if (!newValue) {
          this.profile = 'standard';
        }
      },
    },
    isPilotAccountActive: {
      get() {
        return this.pilot.has_web_account;
      },
      set(newValue) {
        this.pilot.has_web_account = newValue;
        if (!newValue) {
          this.profile = 'standard';
        }
      },
    },
    hasAdminPrivilegesDisabled() {
      if (this.isMainContact) return true;
      if (!this.isPilotAccountActive) return true;
      return false;
    },
    hasWebAccountDisabled() {
      if (this.isMainContact) return true;
      return this.pilot.is_archived;
    },
    profileDisabled() {
      return this.isMainContact || !this.isPilotForFutureMission || !this.isPilotAccountActive;
    },
    contactProfiles() {
      const accessibleProfiles = ['standard'];
      if (this.isExploitantAdmin) {
        accessibleProfiles.push('admin_privileges');
      }
      if (this.hasManySubExploitants) {
        accessibleProfiles.push('is_manager');
      }
      const profilesList = CONTACT_PROFILES.filter(
        (c) => accessibleProfiles.includes(c.value),
      );
      if (this.hasCustomPilotProfile) {
        profilesList.pop((profile) => (profile.value === 'standard'));
        CUSTOM_CONTACT_PROFILES.forEach((profile) => profilesList.push(profile));
      }
      return profilesList;
    },
    userSubscriptionPlanCode() {
      return this.$store.state.authentication.user.subscription.subscription_plan.code;
    },
    isPerSeatSubscription() {
      return !this.$store.state.authentication.user.subscription.trial
        && ['PER_SEAT_M', 'PER_SEAT_Y'].includes(this.userSubscriptionPlanCode);
    },
    seatPrice() {
      return {
        PER_SEAT_M: this.$gettext('29 € HT / month'),
        PER_SEAT_Y: this.$gettext('290 € HT / year'),
      };
    },
    perSeatChangeAlert() {
      return this.$gettextInterpolate(
        this.$gettext(
          'For each new pilot created your subscription fee will increase by %{seatPrice}',
        ),
        { seatPrice: this.seatPrice[this.userSubscriptionPlanCode] },
      );
    },
    isEmailChange() {
      return (
        this.isEditing
        && this.pilotToEdit.has_web_account
        && this.pilot.has_web_account
        && this.pilotToEdit.email !== this.pilot.email
      );
    },
    canAddPilot() {
      return this.$store.state.authentication.user.can_add_pilots;
    },
    openNotEnterpriseDialog() {
      return (
        !this.pilotInitiallyActive
        && !this.canAddPilot
        && this.isPilotForFutureMission
        && this.pilotToEdit
      );
    },
    canNotEditHimself() {
      return (
        !this.isExploitantAdmin
        && this.pilot.id === this.$store.state.authentication.user.contact_id
      );
    },
    canEditConnectionInformations() {
      return (
        this.isExploitantAdmin
        || this.isSubExploitantManager
      );
    },
    loadingAllSubExploitants() {
      return this.$store.state.exploitants.allSubExploitantsLoading;
    },
    allSubExploitants() {
      return this.$store.state.exploitants.allSubExploitants;
    },
    subExploitants() {
      if (this.isExploitantAdmin || this.isSubExploitantManager) {
        return this.pilotToEdit ? this.allSubExploitants : this.userSubExploitants;
      }
      return this.userSubExploitants;
    },
    hasSubExploitants() {
      return this.$store.getters['exploitants/hasSubExploitants'];
    },
    hasCustomPilotProfile() {
      return this.$store.getters['exploitants/hasCustomPilotProfile'];
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
    pilotsCanBeRestricted() {
      return (
        this.$store.getters['exploitants/pilotsCanBeRestricted']
        && this.canEditConnectionInformations
      );
    },
  },
  watch: {
    birthday(val) {
      this.pilot.birthday = this.formatDate(val);
    },
    openNotEnterpriseDialog(newValue) {
      if (newValue) {
        this.notEnterpriseDialog = true;
      }
    },
    profile(newValue) {
      this.pilot.admin_privileges = (newValue === 'admin_privileges');
    },
  },
  created() {
    if (this.pilotToEdit) {
      this.$set(this.$data, 'pilot', { ...this.pilotToEdit });
      this.pilot.subExploitants = this.pilot.sub_exploitants;
      this.pilotInitiallyActive = !this.pilotToEdit.is_archived;
      if (this.pilot.admin_privileges) {
        this.profile = 'admin_privileges';
      } else if (this.pilot.is_manager) {
        this.profile = 'is_manager';
      } else if (this.hasCustomPilotProfile) {
        this.profile = this.pilot.profile;
      } else {
        this.profile = 'standard';
      }
      if (this.hasSubExploitants && (this.isExploitantAdmin || this.isSubExploitantManager)) {
        this.getAllSubExploitants();
      }
    } else {
      this.$set(this.$data, 'pilot', { ...initialPilot });
      this.profile = 'standard';
      if (!this.hasManySubExploitants) {
        this.pilot.subExploitants = this.userSubExploitants.map((s) => s.id);
      }
      if (this.pilotsCanBeRestricted) {
        this.pilot.restricted_account = true;
      }
    }
  },
  mounted() {
    this.birthday = this.pilot.birthday;
  },
  methods: {
    async getAllSubExploitants() {
      await this.$store.dispatch(GET_ALL_SUB_EXPLOITANTS_NS);
    },
    buildPilot() {
      const payload = {};
      // we build payload only with non null values
      // because API trigger error if they're sent
      Object.keys(this.pilot).forEach((key) => {
        if (key === 'subExploitants') {
          if (this.hasManySubExploitants) {
            payload.sub_exploitants = this.userSubExploitants.filter(
              (s) => this.pilot.subExploitants.includes(s.id),
            ).map((s) => s.id);
          } else {
            payload.sub_exploitants = this.userSubExploitants.map((s) => s.id);
          }
        } else if (key === 'has_web_account') {
          payload.set_has_web_account = this.pilot[key];
        } else if (this.pilot[key] !== null) {
          payload[key] = this.pilot[key];
        }
      });
      payload.birthday = this.birthday;
      if (payload.birthday === '') {
        payload.birthday = null;
      }
      payload.admin_privileges = this.profile === 'admin_privileges';
      payload.set_is_manager = this.profile === 'is_manager';
      if (!(payload.admin_privileges || payload.set_is_manager)) {
        payload.profile = this.profile;
      }
      return payload;
    },
    attemptCreatePilot() {
      if (this.$refs.form.validate()) {
        if (this.isPerSeatSubscription) {
          this.pilotChangeProrateTitle = this.$gettext('Create pilot');
          this.addPilot = true;
          this.prorateDialog = true;
        } else {
          this.createPilot();
        }
      }
    },
    async createPilot() {
      // we first validate the form before submitting to API
      const payload = this.buildPilot();
      this.loading = true;
      await API.createPilot(payload)
        .then(() => {
          this.showMessage(this.$gettext('Pilot created.'), 'success');
          this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
        })
        .finally(() => {
          this.loading = false;
          this.prorateDialog = false;
        });
    },
    preparePilotToUpdate() {
      const payload = {};
      // we build payload only with non null values
      // because API trigger error if they're sent
      payload.first_name = this.pilot.first_name;
      payload.last_name = this.pilot.last_name;
      payload.gender = this.pilot.gender;
      payload.email = this.pilot.email;
      payload.phone_number = this.pilot.phone_number;
      payload.set_has_web_account = this.pilot.has_web_account;
      payload.is_archived = this.pilot.is_archived;
      payload.birthday = this.birthday;
      payload.function = this.pilot.function;
      payload.restricted_account = this.pilot.restricted_account;
      if (payload.birthday === '') {
        payload.birthday = null;
      }
      payload.birth_place = this.pilot.birth_place;
      payload.sub_exploitants = this.subExploitants.filter(
        (s) => this.pilot.subExploitants.includes(s.id),
      ).map((s) => s.id);
      payload.admin_privileges = this.profile === 'admin_privileges';
      payload.set_is_manager = this.profile === 'is_manager';
      if (payload.admin_privileges) {
        payload.profile = 'standard';
      } else if (!payload.set_is_manager) {
        payload.profile = this.profile;
      }
      delete payload.documents;
      return payload;
    },
    attemptUpdatePilot() {
      if (this.$refs.form.validate()) {
        if (this.isEmailChange) {
          this.editEmailDialog = true;
        } else if (this.isPerSeatSubscription
            && this.pilotInitiallyActive && this.pilot.is_archived) {
          this.pilotChangeProrateTitle = this.$gettext('Confirm change');
          this.pilotToArchiveId = this.pilot.id;
          this.prorateDialog = true;
        } else if (this.isPerSeatSubscription
            && !this.pilotInitiallyActive && !this.pilot.is_archived) {
          this.pilotChangeProrateTitle = this.$gettext('Confirm change');
          this.addPilot = true;
          this.prorateDialog = true;
        } else if (
          !this.isExploitantAdmin
          && this.hasSubExploitants
          && !this.pilot.subExploitants.some(
            (r) => this.userSubExploitants.map((s) => s.id).includes(r),
          )
        ) {
          this.notHandleTeamDialog = true;
        } else {
          this.updatePilot();
        }
      }
    },
    async updatePilot() {
      // we first validate the form before submitting to API
      if (this.$refs.form.validate()) {
        const payload = this.preparePilotToUpdate();
        this.loading = true;
        await API.updatePilot(this.pilot.id, payload)
          .then(() => {
            this.showMessage(
              this.$gettext('The pilot update has been taken into consideration.'),
              'success',
            );
            this.close();
          })
          .finally(() => {
            this.loading = false;
            this.editEmailDialog = false;
          });
      }
    },
    confirmProrationChange() {
      if (this.isEditing) {
        this.updatePilot();
      } else {
        this.createPilot();
      }
    },
    close() {
      this.$emit('reset-pilot-to-edit');
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
  },
};

</script>
