<template>
  <div>
    <v-btn
      v-if="!isEditing"
      :disabled="!valid"
      :loading="loading"
      block
      class="white--text"
      color="green"
      @click="$emit('create-flight')"
    >
      <translate>CREATE MISSION</translate>
    </v-btn>
    <v-btn
      v-if="isEditing"
      :disabled="!valid"
      :loading="loading"
      block
      class="white--text"
      color="green"
      @click="$emit('update-flight')"
    >
      <translate>UPDATE MISSION</translate>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'FlightFormActions',
  props: {
    isEditing: { type: Boolean },
    valid: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
