<template>
  <v-dialog
    v-model="openDialog"
    max-width="620"
    persistent
  >
    <v-card
      class="default--dialog__card"
      max-height="800px"
    >
      <v-card-title>
        <translate v-if="isGeoportailLayer">Edit Geoportail layer</translate>
        <translate v-else-if="isNetworkLayer">Edit Network layer</translate>
        <translate v-else>Edit layer</translate>
      </v-card-title>
      <v-card-text>
        <span>
          {{ texts.explain }}
        </span>
        <div>
          <div class="d-flex align-center justify-space-between mt-4">
            <div class="mr-4">
              <v-subheader v-translate>Modify opacity</v-subheader>
              <v-slider
                v-model="opacity"
                max="100"
                min="0"
                thumb-label="always"
                thumb-size="25"
                class="mt-4"
              />
            </div>
            <div class="d-flex flex-grow-1 mx-4">
              <v-img
                v-if="isGeoportailLayer"
                :src="thumbnailUrl"
                height="125"
                width="150"
                :style="opacityStyle"
              />
              <v-img
                v-if="isNetworkLayer"
                :src="thumbnailPath"
                height="125"
                width="150"
                :style="opacityStyle"
              />
            </div>
          </div>
          <div class="d-flex">
            <v-checkbox v-model="defaultVisibility">
              <template v-slot:label>
                <v-input hide-details>
                  <span
                    class="mb-0"
                    v-translate
                  >
                    Layer visible by default
                  </span>
                </v-input>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="info"
          @click="close()"
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading"
          @click="editLayer()"
        >
          <translate>Confirm</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import APIService from '@/services/api';

import { RESET_DATA_STORES_NS } from '@/store/application';
import { SHOW_LAYER_EDIT_DIALOG_NS } from '@/store/map';

export default {
  name: 'EditLayer',
  data() {
    return {
      loading: false,
      opacity: null,
      defaultVisibility: false,
    };
  },
  computed: {
    thumbnailPath() {
      return `${process.env.BASE_URL}thumbnail_map_layer.png`;
    },
    texts() {
      return {
        explain: this.$gettextInterpolate(
          this.$gettext(`You can edit the default visibility and the opacity of the
            layer '%{ name }.'`),
          { name: this.layerToEditName },
        ),
      };
    },
    layerToEdit() {
      return this.$store.state.map.editLayer;
    },
    isNetworkLayer() {
      return this.layerToEdit?.category === 'mobile_network';
    },
    isGeoportailLayer() {
      return this.layerToEdit?.category === 'geoportail_wmts';
    },
    openDialog() {
      return this.layerToEdit !== undefined;
    },
    layerToEditName() {
      return this.openDialog ? this.layerToEdit.name : '';
    },
    layer() {
      return this.openDialog && this.layerToEdit.layers[0];
    },
    thumbnailUrl() {
      return this.layer ? this.layer.thumbnailUrl : null;
    },
    opacityStyle() {
      return this.openDialog ? `opacity: ${this.opacityPercentage};` : '';
    },
    opacityPercentage() {
      return this.opacity / 100;
    },
  },
  watch: {
    openDialog(newValue) {
      if (newValue) {
        this.opacity = this.layer.opacity * 100;
        this.defaultVisibility = this.layerToEdit.visibility;
      } else {
        this.opacity = null;
        this.defaultVisibility = false;
      }
    },
  },
  methods: {
    close() {
      this.$store.commit(SHOW_LAYER_EDIT_DIALOG_NS, undefined);
    },
    editLayer() {
      this.loading = true;
      APIService.editLayer(
        this.layerToEdit.identifier, this.opacityPercentage, this.defaultVisibility,
      )
        .then(() => {
          this.showMessage(this.$gettext('Layer updated successfully.'), 'success');
          this.close();
          this.$store.dispatch(RESET_DATA_STORES_NS);
          this.$router.push({ path: '/' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

</script>

<style scoped>
.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

</style>
