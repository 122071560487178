<template>
  <v-card>
    <v-card-title>
      <div class="pt-2 pl-2 text-subtitle-1 text-md-h5">
        <span>
          {{ planName }}
        </span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <!-- Checkout details -->
        <v-col
          cols="12"
          md="6"
          class="checkout-details px-5"
        >
          <!-- Invoice lines -->
          <v-row
            v-for="(line, i) in upcomingInvoiceData.lines"
            :key="i"
          >
            <v-col class="d-flex justify-space-between align-center">
              <div :class="{'discount': line.class === 'discount'}">
                {{ line.description }}
              </div>
              <div
                class="text-right font-weight-medium"
                :class="{'discount': line.class === 'discount'}"
              >
                <div class="d-flex align-center">
                  <div>
                    {{ formatPrice(line.amount) }}
                  </div>
                  <div v-show="line.class === 'discount'">
                    <v-progress-circular
                      v-if="removeDiscountLoading"
                      indeterminate
                      :size="18"
                      :width="2"
                      class="ml-2"
                    />
                    <v-icon
                      v-else
                      small
                      class="pl-2"
                      @click="removeDiscount()"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- Sub-total -->
          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <div class="font-weight-medium">
                <translate>Total HT</translate>
              </div>
              <div class="text-right font-weight-bold">
                {{ formatPrice(upcomingInvoiceData.subtotal) }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="mt-0" />
          <!-- Tax -->
          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <div class="font-weight-regular">
                <span>
                  <translate>TVA</translate>
                  ({{ upcomingInvoiceData.tax_percent }} %)
                </span>
              </div>
              <div class="text-right font-weight-regular">
                {{ formatPrice(upcomingInvoiceData.tax_amount) }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="mt-0" />
          <!-- Total -->
          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <div class="font-weight-bold">
                <translate>Total TTC</translate>
              </div>
              <div class="body-1 text-right font-weight-bold">
                {{ formatPrice(upcomingInvoiceData.total) }}
              </div>
            </v-col>
          </v-row>
          <!-- Total with balance -->
          <template v-if="upcomingInvoiceData.balance">
            <v-divider class="mt-0" />
            <v-row>
              <v-col class="d-flex justify-space-between align-center">
                <div class="font-weight-regular">
                  <translate>Solde appliqué</translate>
                </div>
                <div class="text-right font-weight-regular">
                  {{ formatPrice(upcomingInvoiceData.balance) }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-0" />
            <v-row>
              <v-col class="d-flex justify-space-between align-center">
                <div class="font-weight-bold">
                  <translate>Montant dû</translate>
                </div>
                <div class="body-1 text-right font-weight-bold">
                  {{ formatPrice(upcomingInvoiceData.amount_due) }}
                </div>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col class="pt-0">
              {{ texts.outsideMetropolitan }}
            </v-col>
          </v-row>
          <span>
          </span>
          <v-row v-if="!promoCodeApplied && !affiliateCodeApplied">
            <v-col :class="showPromoCodeForm ? 'py-0' : 'pt-3 pb-4'">
              <template v-if="!showPromoCodeForm">
                <div
                  @click="showPromoCodeForm=true"
                  class="subtitle-1 info--text font-italic cursor-pointer text-decoration-underline"
                >
                  <translate>Apply a coupon code</translate>
                </div>
              </template>
              <template v-else>
                <v-text-field
                  v-model="promoCode"
                  outlined
                  dense
                  class="details-no-margin"
                  :placeholder="placeholders.promoCode"
                  :error="promoCodeError"
                  :error-messages="promoCodeErrorMessage"
                >
                  <template v-slot:append>
                    <v-btn
                      small
                      text
                      color="primary"
                      :loading="promoCodeLoading"
                      @click="applyPromoCode"
                    >
                      <translate>Apply</translate>
                    </v-btn>
                  </template>
                  <template v-slot:append-outer>
                    <v-icon
                      small
                      class="pt-1"
                      @click="showPromoCodeForm=false"
                    >
                      mdi-close
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
            </v-col>
          </v-row>
          <v-row v-if="!promoCodeApplied && !affiliateCodeApplied">
            <v-col :class="showAffiliateCodeForm ? 'py-0' : 'pt-3 pb-4'">
              <span
                v-if="!showAffiliateCodeForm"
                class="subtitle-1 font-italic text-decoration-underline"
              >
                <v-tooltip
                  v-if="alreadyHaveASubscription"
                  right
                  key="alreadyHaveASubscription"
                >
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                      class="disabled"
                    >
                      <translate>Apply an affiliate code</translate>
                    </span>
                  </template>
                  <translate>Affiliate code can only be used for a first subscription</translate>
                </v-tooltip>
                <span
                  v-else
                  @click="showAffiliateCodeForm=true"
                  class="info--text cursor-pointer"
                  key="firstSubscription"
                >
                  <translate>Apply an affiliate code</translate>
                </span>
              </span>
              <template v-else>
                <v-text-field
                  v-model="affiliateCode"
                  outlined
                  dense
                  class="details-no-margin"
                  :placeholder="placeholders.affiliateCode"
                  :error="affiliateCodeError"
                  :error-messages="affiliateCodeErrorMessage"
                >
                  <template v-slot:append>
                    <v-btn
                      small
                      text
                      color="primary"
                      :loading="affiliateCodeLoading"
                      @click="applyAffiliateCode"
                    >
                      <translate>Apply</translate>
                    </v-btn>
                  </template>
                  <template v-slot:append-outer>
                    <v-icon
                      small
                      class="pt-1"
                      @click="showAffiliateCodeForm=false"
                    >
                      mdi-close
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <!-- Payment form -->
        <v-col
          cols="12"
          md="6"
          class="px-5"
        >
          <CheckoutForm
            :plan-code="planCode"
            :quantity="upcomingInvoiceData.quantity"
            :promo-code="appliedPromoCode"
            :affiliate-code="appliedAffiliateCode"
            :expected-total="upcomingInvoiceData.total"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/services/api';

import CheckoutForm from '@/components/Settings/Dronists/Subscription/CheckoutForm.vue';

export default {
  name: 'Checkout',
  components: { CheckoutForm },
  props: {
    planName: String,
    planCode: String,
    upcomingInvoice: Object,
  },
  data() {
    return {
      upcomingInvoiceData: this.upcomingInvoice,
      showPromoCodeForm: false,
      promoCode: null,
      promoCodeLoading: false,
      promoCodeApplied: false,
      promoCodeError: false,
      promoCodeErrorMessage: null,
      showAffiliateCodeForm: false,
      affiliateCode: null,
      affiliateCodeLoading: false,
      affiliateCodeApplied: false,
      affiliateCodeError: false,
      affiliateCodeErrorMessage: null,
      removeDiscountLoading: false,
    };
  },
  computed: {
    appliedPromoCode() {
      if (this.promoCodeApplied) {
        return this.promoCode;
      }
      return null;
    },
    appliedAffiliateCode() {
      if (this.affiliateCodeApplied) {
        return this.affiliateCode;
      }
      return null;
    },
    texts() {
      return {
        outsideMetropolitan: this.$gettext(`If your company is based outside metropolitan France,
          please contact us at contact@clearance.aero.`),
      };
    },
    placeholders() {
      return {
        promoCode: this.$gettext('Promotion code'),
        affiliateCode: this.$gettext('Affiliate code'),
      };
    },
    alreadyHaveASubscription() {
      return this.$store.state.authentication.user.subscription.sub_date !== null;
    },
  },
  methods: {
    formatPrice(number) {
      if (!Number.isNaN(number)) {
        return `${number.toFixed(2)} €`;
      }
      return '';
    },
    applyPromoCode() {
      this.promoCodeLoading = true;
      API.previewSubscriptionInvoice(this.planCode, this.promoCode, null)
        .then(({ data }) => {
          this.upcomingInvoiceData = data;
          this.showPromoCodeForm = false;
          this.promoCodeApplied = true;
        })
        .catch((e) => {
          this.promoCodeErrorMessage = e.response.data.detail;
          this.promoCodeError = true;
        })
        .finally(() => {
          this.promoCodeLoading = false;
        });
    },
    applyAffiliateCode() {
      this.affiliateCodeLoading = true;
      API.previewSubscriptionInvoice(this.planCode, null, this.affiliateCode)
        .then(({ data }) => {
          this.upcomingInvoiceData = data;
          this.showAffiliateCodeForm = false;
          this.affiliateCodeApplied = true;
        })
        .catch((e) => {
          this.affiliateCodeErrorMessage = e.response.data.detail;
          this.affiliateCodeError = true;
        })
        .finally(() => {
          this.affiliateCodeLoading = false;
        });
    },
    removeDiscount() {
      this.removeDiscountLoading = true;
      API.previewSubscriptionInvoice(this.planCode, null, null)
        .then(({ data }) => {
          this.upcomingInvoiceData = data;
          this.promoCode = null;
          this.showPromoCodeForm = false;
          this.promoCodeApplied = false;
          this.affiliateCode = null;
          this.showAffiliateCodeForm = false;
          this.affiliateCodeApplied = false;
        })
        .finally(() => {
          this.removeDiscountLoading = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.checkout-details {
  @media only screen and (min-width: 960px) {
    border-right: 1px #e0e0e0 solid;
  }
}
.disabled {
  color: lighten($color-info, 25%);
  cursor: default;
}
.discount {
  color: $color-primary  !important;
  font-weight: 700 !important;
}
.details-no-margin ::v-deep .v-text-field__details {
  margin: 0px;
}

</style>
