<template>
  <v-card class="default--dialog__card">
    <!-- Title  -->
    <v-card-title>
      <span>
        <div>
          <template v-if="isLivestreamLive">
            <span key="live-title">
              <v-icon
                color="red"
                class="pr-2"
              >
                mdi-play-circle
              </v-icon>
              <translate>Livestream live</translate>
            </span>
          </template>
          <template v-else>
            <span key="ended-title">
              <v-icon
                color="primary"
                class="pr-2"
              >
                mdi-play-circle-outline
              </v-icon>
              <translate>Livestream ended</translate>
            </span>
          </template>
        </div>
      </span>
      <v-icon @click="close()">
        close
      </v-icon>
    </v-card-title>
    <!-- Form -->
    <v-card-text>
      <div v-if="isLivestreamStarted">
        <div v-if="isLivestreamFlightAutomaticallyFound">
          {{ texts.customizeStartedLivestreamFlightAutomaticallyFound }}
        </div>
        <div v-else>
          {{ texts.customizeStartedLivestream }}
        </div>
      </div>
      <div v-else-if="isLivestreamLive">
        <div v-if="flightName.length">
          {{ texts.customizeLiveLivestreamFlightAlreadySet }}
        </div>
        <div v-else>
          {{ texts.customizeLiveLivestream }}
        </div>
      </div>
      <div v-else>
        <div v-if="isLivestreamFlightAutomaticallyFound">
          {{ texts.customizeEndLivestreamFlightAutomaticallyFound }}
        </div>
        <div v-else-if="flightName.length">
          {{ texts.customizeEndLivestreamFlightAlreadySet }}
        </div>
        <div v-else>
          {{ texts.customizeEndLivestream }}
        </div>
      </div>
      <v-form class="px-2 mt-2">
        <v-text-field
          v-model="livestreamLabel"
          :label="labels.livestreamLabel"
          :placeholder="placeholders.livestreamLabel"
          maxlength="56"
        />
        <v-autocomplete
          v-model="livestreamFlight"
          :items="flights"
          :loading="flightsLoading"
          :label="labels.livestreamFlight"
          :placeholder="placeholders.livestreamFlight"
          :item-text="getItemText"
          item-value="id"
          :search-input.sync="flightSearch"
          :no-data-text="texts.emptyResults"
          :menu-props="{ maxWidth: '520' }"
          return-object
          clearable
        />
      </v-form>
      <template v-if="livestream">
        <div class="d-flex align-center">
          <span v-translate>Import thumbnail:</span>
          <v-file-input
            v-model="thumbnailInputFile"
            :label="$gettext('Upload an image')"
            accept="image/*"
          />
        </div>
        <div
          v-if="isLivestreamLive"
          class="mt-2"
        >
          {{ texts.watchLivestream }}
        </div>
        <div v-else>
          {{ texts.watchLivestreamLater }}
        </div>
        <div>
          <a
            id="livestream-url"
            :href="livestream.front_livestream_url"
            target="_blank"
          >
            {{ livestream.front_livestream_url }}
          </a>
          <v-icon
            class="ml-2"
            color="primary"
            @click="copyLiveUrl()"
          >
            mdi-content-copy
          </v-icon>
        </div>
      </template>
    </v-card-text>
    <!-- Actions  -->
    <v-card-actions>
      <v-btn
        @click="save()"
        :loading="livestreamUpdateLoading"
        color="primary"
      >
        <translate>Validate</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';
import { debounce } from '@/services/api.helper';

import {
  SET_SELECTED_LIVESTREAM_NS,
  ADD_OR_UPDATE_LIVESTREAM_NS,
  SET_LIVESTREAM_STARTED_NS,
} from '@/store/livestreams';

export default {
  name: 'LivestreamDetails',
  data() {
    return {
      livestreamLabel: undefined,
      livestreamFlight: undefined,
      flights: [],
      flightsLoading: false,
      flightSearch: undefined,
      livestreamUpdateLoading: false,
      flightName: '',
      thumbnailInputFile: undefined,
    };
  },
  computed: {
    labels() {
      return {
        livestreamLabel: this.$gettext('Livestream name'),
        livestreamFlight: this.$gettext('Flight linked to the livestream'),
      };
    },
    placeholders() {
      return {
        livestreamLabel: this.$gettext('Livestream name'),
        livestreamFlight: this.$gettext('Type to search a flight'),
      };
    },
    texts() {
      return {
        emptyResults: this.$gettext('No result'),
        customizeStartedLivestream: this.$gettextInterpolate(
          this.$gettext(`The drone %{droneLabel} is starting a livestream. You can customize its
            name and link it to a flight to find it later easier.`),
          { droneLabel: this.droneLabel },
          true,
        ),
        customizeStartedLivestreamFlightAutomaticallyFound: this.$gettextInterpolate(
          this.$gettext(`The drone %{droneLabel} is starting a livestream. It has been
            automatically linked to the flight %{flightName}, you can change or delete this link,
            and customize the livestream's name to find it later easier.`),
          {
            droneLabel: this.droneLabel,
            flightName: this.flightName,
          },
          true,
        ),
        customizeLiveLivestream: this.$gettext(`Livestream is in live. You can customize its name
          and link it to a flight yo find it later easier.`),
        customizeLiveLivestreamFlightAlreadySet: this.$gettext(`Livestream is in live. You can
          modify or delete linked flight, and customize its name to find it later easier.`),
        customizeEndLivestream: this.$gettext(`Livestream is ended. You can customize its name and
          link it to a mission to find it later easier.`),
        customizeEndLivestreamFlightAutomaticallyFound: this.$gettextInterpolate(
          this.$gettext(`Livestream is ended. It has been automatically linked to the
            flight %{flightName}, you can change or delete this link, and customize the
            livestream's name to find it later easier.`),
          { flightName: this.flightName },
          true,
        ),
        customizeEndLivestreamFlightAlreadySet: this.$gettextInterpolate(
          this.$gettext(`Livestream is ended. It has been linked to the flight %{flightName}, you
            can change or delete this link, and customize the livestream's name to find it later
            easier.`),
          { flightName: this.flightName },
          true,
        ),
        watchLivestream: this.$gettext('Public link of watching:'),
        watchLivestreamLater: this.$gettext(`This livestream is always watchable on the URL
          below:`),
      };
    },
    livestream() {
      return this.$store.getters['livestreams/selectedLivestream'];
    },
    isLivestreamLive() {
      return this.livestream && this.livestream.status === 'live';
    },
    isLivestreamStarted() {
      return this.isLivestreamLive && this.livestream.started;
    },
    isLivestreamFlightAutomaticallyFound() {
      return this.livestream && this.livestream.flight_selected_automatically;
    },
    droneLabel() {
      return this.livestream ? this.livestream.drone_label : '';
    },
  },
  watch: {
    flightSearch(value) {
      if (value && value !== this.getItemText(this.livestreamFlight)) {
        this.flightsLoading = true;
        this.debouncedSearchFlight({ that: this });
      }
    },
    livestream(newValue) {
      if (newValue !== undefined) {
        this.setLivestreamValues();
      }
    },
  },
  mounted() {
    this.setLivestreamValues();
  },
  methods: {
    debouncedSearchFlight: debounce(
      ({ that }) => that.searchFlight(),
      1000, // debounce for 1s
    ),
    searchFlight() {
      APIService.searchFlights({ q: this.flightSearch })
        .then(({ data }) => {
          if (data) {
            this.flights = data.results.map((flight) => ({
              id: flight.id,
              name: flight.name,
            }));
          }
        })
        .finally(() => {
          this.flightsLoading = false;
        });
    },
    setLivestreamValues() {
      this.livestreamLabel = this.livestream.label;
      if (this.livestream.flight) {
        const flight = {
          id: this.livestream.flight,
          name: this.livestream.flight_name,
        };
        if (!this.flights.find((f) => f.id === flight.id)) {
          this.flights.unshift(flight);
        }
        this.livestreamFlight = flight;
        this.flightName = this.livestreamFlight.name;
      }
    },
    getItemText(item) {
      if (item) {
        return `${item.id} - ${item.name}`;
      }
      return undefined;
    },
    save() {
      this.livestreamUpdateLoading = true;
      const formData = new FormData();
      formData.append('label', this.livestreamLabel);
      formData.append('flight_selected_automatically', false);
      if (this.livestreamFlight) {
        formData.append('flight', this.livestreamFlight.id);
      }
      if (this.thumbnailInputFile) {
        formData.append('file', this.thumbnailInputFile);
      }
      APIService.updateLivestream(this.livestream.id, formData)
        .then(({ data }) => {
          this.showMessage(this.$gettext('Livestream was updated successfully'), 'success');
          this.$store.commit(ADD_OR_UPDATE_LIVESTREAM_NS, { livestream: data });
          this.close();
        })
        .finally(() => {
          this.thumbnailInputFile = undefined;
          this.livestreamUpdateLoading = false;
        });
    },
    close() {
      if (this.isLivestreamStarted) {
        this.$store.commit(SET_LIVESTREAM_STARTED_NS, this.livestream.id);
      }
      this.$store.commit(SET_SELECTED_LIVESTREAM_NS, undefined);
      this.livestreamLabel = undefined;
      this.livestreamFlight = undefined;
      this.flights = [];
      this.flightsLoading = false;
      this.flightSearch = undefined;
      this.livestreamUpdateLoading = false;
      this.thumbnailInputFile = undefined;
      this.flightName = '';
    },
    copyLiveUrl() {
      const elem = document.getElementById('livestream-url');
      const range = document.createRange();
      range.selectNode(elem);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      this.showMessage(this.$gettext('URL copied in the clipboard'), 'success');
    },
  },
};
</script>
