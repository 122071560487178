import Vue from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const filterHour = (value) => {
  if (!value) return '';
  const result = value.toString();
  return result.substr(0, result.lastIndexOf(':'));
};

export const filterHour2 = (value) => {
  if (!value) return '';
  const result = value.toString();
  const [HH, mm] = result.split(':');
  return `${HH} : ${mm}`;
};

Vue.filter('hour', filterHour);
Vue.filter('hour2', filterHour2);
