<template>
  <v-skeleton-loader
    :loading="prorateLoading"
    type="text"
  >
    <template v-if="prorateError">
      <span
        v-translate
        class="font-italic error--text text--darken-4"
      >
        An error has occured while calculating subscription prorate due to pilot deletion.
      </span>
    </template>
    <template v-else>
      <span class="info--text multilines">
        {{ prorateDetails }}
      </span>
    </template>
  </v-skeleton-loader>
</template>

<script>
import API from '@/services/api';

export default {
  name: 'PilotChangeProrate',
  props: {
    // Id of pilot to remove from subscription
    pilotToRemoveId: {
      type: Number,
      required: false,
      default: null,
    },
    // True if the change is adding a pilot to subscription
    addPilot: {
      type: Boolean,
      required: false,
      default: false,
    },
    // In archive mode remove pilot means archive pilot and add pilot means unarchive pilot
    // Otherwise remove pilot means delete pilot and add pilot means create new pilot
    archiveMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      prorateLoading: false,
      prorateError: false,
      quantity: '',
      immediateAmountHT: '',
      immediateAmountTTC: '',
      subscriptionAmountHT: '',
      subscriptionAmountTTC: '',
      nextInvoiceDateISO: '',
    };
  },
  computed: {
    immediateAmountString() {
      return `${Math.abs(this.immediateAmountHT)} € HT
        (${Math.abs(this.immediateAmountTTC)} € TTC)`;
    },
    subscriptionAmountString() {
      return `${this.subscriptionAmountHT} € HT (${this.subscriptionAmountTTC} € TTC)`;
    },
    nextInvoiceDate() {
      return new Date(this.nextInvoiceDateISO);
    },
    nextInvoiceDateLocale() {
      return this.nextInvoiceDate.toLocaleDateString(this.$store.getters['application/currentLanguage']);
    },
    prorateDetails() {
      return this.buildProrationDetails();
    },
  },
  watch: {
    pilotToDeleteId() {
      this.getProration();
    },
  },
  mounted() {
    this.getProration();
  },
  methods: {
    getProration() {
      this.prorateLoading = true;
      let params = {};
      if (this.pilotToRemoveId) {
        params = { remove_pilot: this.pilotToRemoveId };
      } else if (this.addPilot) {
        params = { add_pilot: true };
      }
      this.$emit('prorate-loading');
      API.previewChangeSubscriptionPlan(params)
        .then(({ data }) => {
          ({
            quantity: this.quantity,
            immediate_amount_ht: this.immediateAmountHT,
            immediate_amount_ttc: this.immediateAmountTTC,
            subscription_amount_ht: this.subscriptionAmountHT,
            subscription_amount_ttc: this.subscriptionAmountTTC,
            next_invoice_due_date: this.nextInvoiceDateISO,
          } = data);
        })
        .catch(() => {
          this.prorateError = true;
        })
        .finally(() => {
          this.prorateLoading = false;
          this.$emit('prorate-loaded');
        });
    },
    buildProrationDetails() {
      if (this.pilotToRemoveId && this.immediateAmountTTC <= 0) {
        return this.$gettextInterpolate(
          this.$gettext(`%{pilotAction} you will be bringing down your total pilots to %{quantity}.
            There is nothing due today for this change.
            You will have a credit of %{immediateAmount} that will be deducted from your next
            invoice of %{subscriptionAmount} due on %{nextInvoiceDateLocale}.`),
          {
            pilotAction: !this.archiveMode ? this.$gettext('By deleting this pilot')
              : this.$gettext('By removing this pilot from future missions'),
            quantity: this.quantity,
            immediateAmount: this.immediateAmountString,
            subscriptionAmount: this.subscriptionAmountString,
            nextInvoiceDateLocale: this.nextInvoiceDateLocale,
          },
        );
      }
      if (this.addPilot && this.immediateAmountTTC >= 0) {
        return this.$gettextInterpolate(
          this.$gettext(`%{pilotAction} you will be bringing up your total pilots to %{quantity}.\n
            You will be charged today %{immediateAmount} for this change.
            Your next invoice of %{subscriptionAmount} is due on %{nextInvoiceDateLocale}.`),
          {
            pilotAction: !this.archiveMode ? this.$gettext('By adding this pilot')
              : this.$gettext('By proposing this pilot for your future missions'),
            quantity: this.quantity,
            immediateAmount: this.immediateAmountString,
            subscriptionAmount: this.subscriptionAmountString,
            nextInvoiceDateLocale: this.nextInvoiceDateLocale,
          },
        );
      }
      this.prorateError = true;
      return '';
    },
  },
};
</script>
