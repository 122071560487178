<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        class="py-2 px-3"
        :class="highlighted"
      >
        <div>
          <v-icon color="info">
            mdi-email-outline
          </v-icon>
          <translate
            class="pl-3"
            :translate-n="constraintEmailRequestsCount"
            translate-plural="Emails requests sent"
          >
            Email request sent
          </translate>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="emailRequestContainer">
        <v-list
          dense
          class="pt-0"
        >
          <template v-for="(email, index) in constraintEmailRequests">
            <div
              :key="'div-' + index"
              class="px-3"
            >
              <v-divider class="ma-0" />
            </div>
            <v-list-item
              :key="'item-' + index"
              class="px-0 py-2 mx-3"
            >
              <EmailRequest :emailRequest="email" />
            </v-list-item>
          </template>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import EmailRequest from '@/components/Flights/EmailRequest/EmailRequest.vue';

export default {
  name: 'EmailRequestList',
  components: { EmailRequest },
  props: {
    flight: {
      type: Object,
      required: true,
    },
    constraint: {
      type: Object,
      required: true,
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    highlight() {
      if (this.highlighted) return 'highlight';
      return '';
    },
    constraintEmailRequests() {
      return this.flight.emailRequests.filter(
        (email) => email.constraint_id === this.constraint.id,
      );
    },
    constraintEmailRequestsCount() {
      return this.constraintEmailRequests.length;
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
.emailRequestContainer {
  max-height: 500px;
  overflow-y: scroll
}

</style>
