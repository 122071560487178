<template>
  <div>
    <!-- Send new approval request -->
    <LayoutFlightConstraintAction
      id="send-approval"
      :flight="flight"
      :disabledExtra="requestApprovalDisabledExtra"
      :disabledExtraTooltip="requestApprovalDisabledExtraTooltip"
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <v-btn
          :color="requestApprovalButtonColorClass"
          class="main-button__font my-0"
          @click.stop="displayApprovalDialog()"
          :loading="sendingApproval"
          :disabled="disabled || sendingApproval"
          block
        >
          <template>
            <span
              v-if="requestApprovalFromPrefecture"
              v-translate
            >
              Notify from Clearance
            </span>
            <span
              v-else
              v-translate
            >
              Request approval
            </span>
          </template>
          <template
            v-slot:loader
            v-if="sendingApproval"
          >
            <span v-translate>
              Sending approval...
            </span>
            <v-progress-circular
              class="ml-2"
              indeterminate
              :size="20"
              :width="2"
            />
          </template>
        </v-btn>
      </template>
    </LayoutFlightConstraintAction>
    <!-- Validate terms and conditions dialog -->
    <template v-if="hasTermsAndConditions">
      <v-dialog
        v-if="showApprovalDialog"
        v-model="showApprovalDialog"
        max-width="800px"
        :retain-focus="false"
        persistent
      >
        <RequestApprovalFormTermsAndConditions
          :capability="capability"
          :structureId="constraint.structure_id"
          @agree="() => termsAndConditionsAgreed()"
          @close="() => closeTermsAndConditionsDialog()"
        />
      </v-dialog>
    </template>
    <!-- Thales: send new approval request -->
    <template v-else-if="canBeProcessedThroughThales">
      <v-dialog
        v-if="showApprovalDialog"
        v-model="showApprovalDialog"
        max-width="1000px"
        persistent
        :retain-focus="false"
      >
        <RequestApprovalFormThales
          :flight="flight"
          :constraintId="constraint.id"
          :structureId="constraint.structure_id"
          :flightPilots="flightPilots"
          :flightDrones="flightDrones"
          :approvalPeriodIsValid="approvalPeriodIsValid"
          :approvalMaxDays="approvalMaxDays"
          @create-approval="(payload) => createApproval(payload)"
          @retry-create-approval="
            (approvalId, payload) => retryCreateExternalApproval(approvalId, payload)
          "
          @close-form="() => showApprovalDialog = false"
        />
      </v-dialog>
    </template>
    <!-- USK: send new approval request -->
    <template v-else-if="canBeProcessedThroughUSK">
      <v-dialog
        v-if="showApprovalDialog"
        v-model="showApprovalDialog"
        max-width="1000px"
        persistent
        :retain-focus="false"
      >
        <RequestApprovalFormUspacekeeper
          :flight="flight"
          :constraintId="constraint.id"
          :flightPilots="flightPilots"
          :flightDrones="flightDrones"
          :capability="capability"
          :structureId="constraint.structure_id"
          @create-approval="(payload) => createApproval(payload)"
          @retry-create-approval="
            (approvalId, payload) => retryCreateExternalApproval(approvalId, payload)
          "
          @close-form="() => showApprovalDialog = false"
        />
      </v-dialog>
    </template>
    <!-- Prefecture: send new approval request -->
    <template v-else-if="requestApprovalFromPrefecture">
      <v-dialog
        v-if="showApprovalDialog"
        v-model="showApprovalDialog"
        width="880px"
        persistent
        :retain-focus="false"
      >
        <NotifAlphaTangoPrefectureForm
          :flight="flight"
          :asApproval="true"
          @create-approval="(payload) => createApproval(payload)"
          @close-form="() => showApprovalDialog = false"
        />
      </v-dialog>
    </template>
    <!-- Airport: send new approval request -->
    <template v-else>
      <v-dialog
        v-if="showApprovalDialog"
        v-model="showApprovalDialog"
        :max-width="requestApprovalDialogWidth"
        persistent
        :retain-focus="false"
      >
        <RequestApprovalForm
          v-if="!requireStripe"
          :flight="flight"
          :constraint="constraint"
          :capability="capability"
          :flightAreas="flight.areas"
          :flightPilots="flightPilots"
          :flightDrones="flightDrones"
          :approvalPeriodIsValid="approvalPeriodIsValid"
          :approvalMaxDays="approvalMaxDays"
          :structureProcess="structureProcess"
          :discount="discount"
          @create-approval="(payload) => createApproval(payload)"
          @create-awaiting-payment-approval="(payload) => createApprovalAwaitingPayment(payload)"
          @update-approval-awaiting-payment="
            (approvalId, payload) => updateApprovalAwaitingPayment(approvalId, payload)
          "
          @approval-payment-succeeded="() => reloadConstraintsAnalysis()"
          @close-form="() => showApprovalDialog = false"
        />
        <div v-else>
          <AskCookieStripe
            :title="$gettext('Approval Request')"
            :dialogText="texts.stripeDialog"
            @close-form="() => showApprovalDialog = false"
          />
        </div>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { differenceInCalendarDays } from 'date-fns';

import { AUTHORITY_TYPES, CONSTRAINTS_CATEGORY, CONSTRAINTS_PROCESS_THROUGH } from '@/settings';

import APIService from '@/services/api';

import { GET_CONSTRAINTS_ANALYSIS_NS } from '@/store/flights';

import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

import NotifAlphaTangoPrefectureForm from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoPrefectureForm.vue';
import RequestApprovalForm from '@/components/Flights/RequestApproval/RequestApprovalForm.vue';
import RequestApprovalFormTermsAndConditions from '@/components/Flights/RequestApproval/RequestApprovalFormTermsAndConditions.vue';
import RequestApprovalFormThales from '@/components/Flights/RequestApproval/RequestApprovalFormThales.vue';
import RequestApprovalFormUspacekeeper from '@/components/Flights/RequestApproval/RequestApprovalFormUspacekeeper.vue';
import AskCookieStripe from '@/components/Flights/RequestApproval/AskCookieStripe.vue';

export default {
  name: 'RequestApprovalActionsCreate',
  components: {
    LayoutFlightConstraintAction,
    NotifAlphaTangoPrefectureForm,
    RequestApprovalForm,
    RequestApprovalFormTermsAndConditions,
    RequestApprovalFormThales,
    RequestApprovalFormUspacekeeper,
    AskCookieStripe,
  },
  props: {
    flight: Object,
    constraint: Object,
    capability: Object,
    sendingApproval: Boolean,
  },
  data() {
    return {
      alreadyAgree: undefined,
      discount: 0,
      approvalMaxDays: null,
      approvalPeriodIsValid: false,
      structureProcess: '',
      showApprovalDialog: false,
    };
  },
  computed: {
    texts() {
      return {
        stripeDialog: this.$gettext(`To access your approval's payment form, please accept the
          cookies from our payment solution Stripe.`),
      };
    },
    flightPilots() {
      if (this.flight.pilots.length) {
        return this.flight.pilots;
      }
      return this.$store.getters['pilots/activePilots'];
    },
    flightDrones() {
      if (this.flight.drones.length) {
        return this.flight.drones;
      }
      return this.$store.getters['drones/activeDrones'];
    },
    isNearby() {
      return this.constraint.category === CONSTRAINTS_CATEGORY.NEARBY;
    },
    requestApprovalButtonColorClass() {
      return this.isNearby ? 'grey lighten-2' : 'success';
    },
    requestApprovalFromPrefecture() {
      return this.capability.authority_type === AUTHORITY_TYPES.PREFECTURE;
    },
    requestApprovalDisabledExtra() {
      return !this.requestApprovalNoticeDaysRespected;
    },
    requestApprovalDisabledExtraTooltip() {
      if (this.requestApprovalDisabledExtra) {
        return this.$gettextInterpolate(
          this.$gettext(
            `This approval request must be submitted no later than %{noticeDays} days before the
            scheduled flight date.`,
          ),
          { noticeDays: this.capability.notice_days || '' },
        );
      }
      return '';
    },
    requestApprovalDialogWidth() {
      return this.capability.payment_required ? 1200 : 800;
    },
    hasTermsAndConditions() {
      return (
        !this.alreadyAgree
        && this.capability.terms_and_conditions !== ''
        && this.capability.validate_conditions_before_first_approval
      );
    },
    requestApprovalNoticeDaysRespected() {
      if (this.capability.notice_days !== null) {
        const flightStart = new Date(this.flight.date_start);
        // Handle timezones differences
        flightStart.setMinutes(flightStart.getMinutes() + flightStart.getTimezoneOffset());
        const noticeDays = differenceInCalendarDays(flightStart, new Date());
        return noticeDays >= this.capability.notice_days;
      }
      return true;
    },
    hasAcceptedCookieStripe() {
      return this.$store.getters['authentication/hasAcceptedCookieStripe'];
    },
    requireStripe() {
      return (
        this.capability.payment_required
        && !this.hasAcceptedCookieStripe
        && this.discount !== 100
      );
    },
    canBeProcessedThroughClearance() {
      return this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.CLEARANCE;
    },
    canBeProcessedThroughThales() {
      return this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.THALES;
    },
    canBeProcessedThroughUSK() {
      return this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.USK;
    },
  },
  async created() {
    if (this.canBeProcessedThroughClearance) {
      await this.checkValidationTermsConditions();
      if (this.capability.payment_required) {
        await this.getExploitantDiscount();
      }
    } else {
      this.termsAndConditionsAgreed();
    }
  },
  methods: {
    termsAndConditionsAgreed() {
      this.alreadyAgree = true;
    },
    closeTermsAndConditionsDialog() {
      this.showApprovalDialog = false;
    },
    async checkValidationTermsConditions() {
      if (!this.capability.validate_conditions_before_first_approval) {
        return;
      }
      await APIService.checkValidationTermsConditions(this.constraint.structure_id)
        .then(({ data }) => {
          this.alreadyAgree = data;
        });
    },
    async getExploitantDiscount() {
      await APIService.getExploitantDiscount(
        this.$store.state.exploitants.exploitationDetails.id,
        this.constraint.structure_id,
      )
        .then(({ data }) => {
          this.discount = data.discount_percentage;
        });
    },
    checkApprovalPeriod() {
      if (this.approvalMaxDays == null) {
        return true;
      }
      const missionPeriodInDays = differenceInCalendarDays(
        new Date(this.flight.date_end),
        new Date(this.flight.date_start),
      );
      return missionPeriodInDays < this.approvalMaxDays;
    },
    displayApprovalDialog() {
      this.showApprovalDialog = true;
      this.approvalMaxDays = this.capability.max_nb_days || null;
      this.approvalPeriodIsValid = this.checkApprovalPeriod();
      this.structureProcess = this.capability.process || '';
    },
    reloadConstraintsAnalysis() {
      this.$store.dispatch(GET_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id });
    },
    createApproval(payload) {
      this.showApprovalDialog = false;
      this.$emit('create-approval', payload);
    },
    createApprovalAwaitingPayment(payload) {
      this.$emit('create-awaiting-payment-approval', payload);
    },
    updateApprovalAwaitingPayment(approvalId, payload) {
      this.showApprovalDialog = false;
      this.$emit('update-approval-awaiting-payment', approvalId, payload);
    },
    retryCreateExternalApproval(approvalId, payload) {
      this.showApprovalDialog = false;
      this.$emit('retry-create-external-approval', approvalId, payload);
    },
  },
};
</script>
