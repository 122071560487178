/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */

import { NOTIFICATION_TYPES } from '@/settings';

import { SET_APPROVAL_EN_ROUTE_NS } from '@/store/approvals';

import AuthorityApprovalNotification from '@/components/Header/notifications/AuthorityApprovalNotification';

/**
 * AuthorityFlightEnRouteNotification class derived from Notification,
 * represent a notification about an approval with a flight en route.
 *
 * @class AuthorityFlightEnRouteNotification
 * @extends {Notification}
 */
class AuthorityFlightEnRouteNotification extends AuthorityApprovalNotification {
  constructor(rawNotification) {
    super(rawNotification);
    this.enRoute = (
      rawNotification.payload_type === NOTIFICATION_TYPES.AUTHORITY_FLIGHT_EN_ROUTE
    );
  }

  atReceive(dispatchStoreAction) {
    dispatchStoreAction(
      SET_APPROVAL_EN_ROUTE_NS,
      { approvalId: this._approvalId, enRoute: this.enRoute },
      { root: true },
    );
  }
}

export default AuthorityFlightEnRouteNotification;
