<template>
  <div
    class="flex-column black"
    :class="fullPage ? 'full-height-width' : 'inherit-height-width'"
  >
    <!-- Video Player  -->
    <div
      class="flex-column"
      :class="{ 'inherit-height-width': !fullPage }"
    >
      <slot name="player-overlay" />
      <video
        ref="videoPlayer"
        class="flex-column"
        :class="{ 'inherit-height-width': !fullPage }"
        :controls="!collapsed"
        :muted="initiallyCollapsed"
        autoplay
      />
    </div>
    <!-- Video Footer  -->
    <div class="video-player__footer">
      <div
        v-if="collapsed"
        class="simplified__footer"
      >
        <div class="body-2">
          <span>
            {{ livestreamTitle }}
          </span>
        </div>
        <v-btn
          @click="copyUrl()"
          icon
          x-small
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </div>
      <div
        v-else
        class="default__footer flex-wrap"
      >
        <div>
          <div class="text-h6 font-weight-regular title">
            {{ livestreamTitle }}
          </div>
          <div class="mt-1 body-2 info--text">
            {{ livestreamStart | toLocaleDatetime }}
          </div>
          <div class="mt-1 body-2 info--text">
            {{ livestreamFlight }}
          </div>
        </div>
        <v-btn
          @click="copyUrl()"
          class="px-1"
          text
        >
          <v-icon class="pr-2">mdi-content-copy</v-icon>
          <translate>Copy video link</translate>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LivestreamPlayer',
  props: {
    livestream: {
      type: Object,
      required: false,
    },
    fullPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    demoUrl: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      initiallyCollapsed: this.collapsed,
      videoPlayer: undefined,
    };
  },
  computed: {
    livestreamTitle() {
      if (this.livestream) {
        return this.livestream.label;
      }
      if (this.demoUrl) {
        return this.$gettext('Livestream tutorial video');
      }
      return '';
    },
    livestreamStart() {
      if (this.livestream) {
        return this.livestream.start;
      }
      return '';
    },
    livestreamFlight() {
      if (this.livestream) {
        return `${this.livestream.flight || ''} ${this.livestream.flight_name || ''}`;
      }
      return '';
    },
    livestreamPublicUrl() {
      if (this.livestream) {
        return this.livestream.front_livestream_url;
      }
      if (this.demoUrl) {
        return window.location.href;
      }
      return undefined;
    },
    livestreamUrl() {
      if (this.livestream) {
        return this.livestream.livestream_https_url;
      }
      return undefined;
    },
  },
  watch: {
    livestreamUrl(newValue) {
      if (newValue) {
        this.setVideoSrc();
      }
    },
  },
  mounted() {
    this.setVideoSrc();
  },
  methods: {
    setVideoSrc(retry = 5) {
      this.videoPlayer = this.$refs.videoPlayer;
      if (this.livestreamUrl && retry > 0) {
        /* eslint-disable-next-line no-undef */
        if (Hls.isSupported()) {
          if (window.hls) {
            window.hls.destroy();
          }
          /* eslint-disable-next-line no-undef */
          window.hls = new Hls();
          window.hls.loadSource(this.livestreamUrl);
          window.hls.attachMedia(this.videoPlayer);
          window.hls.on('hlsError', (event, data) => {
            if (data.type === 'networkError') {
              setTimeout(() => {
                if (this.livestreamUrl) {
                  this.setVideoSrc(retry - 1);
                }
              }, 3000);
            }
          });
        } else if (this.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
          this.videoPlayer.src = this.livestreamUrl;
        }
      } else if (this.demoUrl) {
        this.videoPlayer.src = this.demoUrl;
      }
      if (this.videoPlayer) {
        this.videoPlayer.addEventListener('play', () => this.$emit('video-played'));
        this.videoPlayer.addEventListener('pause', () => this.$emit('video-paused'));
      }
    },
    playVideo() {
      this.videoPlayer.play();
    },
    pauseVideo() {
      this.videoPlayer.pause();
    },
    copyUrl() {
      const elem = document.createElement('textarea');
      elem.value = this.livestreamPublicUrl;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);
      this.showMessage(this.$gettext('URL copied in the clipboard'), 'success');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.full-height-width {
  width: 100%;
  height: 100%;
}

.inherit-height-width {
  max-width: inherit;
  max-height: inherit;
}

.flex-column {
  flex-direction: column;
  display: flex;
  position: relative;
  min-height: 0;
}

@mixin flex-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 32px;
  row-gap: 8px;
}

.flex-row {
  @include flex-row;
}

.video-player__footer {
  background: white;
  color: black;
  flex-grow: 1;

  >div {

    @include flex-row;

    &.simplified__footer {
      margin: 8px;
      padding: 0 6px;
    }

    &.default__footer {
      margin: 16px;
      padding: 0 12px;

      .title {
        line-height: 1.6rem;
      }
    }

    >div:first-child {
      flex-grow: 1;
    }
  }
}

</style>
