<template>
  <v-container class="pa-0">
    <div class="details-card">
      <!-- Tracker seen info -->
      <div
        v-if="displayTrackerSeenInfo"
        class="px-4 pt-4"
      >
        {{ texts.trackerSeenInfo }}
      </div>
      <!-- Trace info -->
      <v-row no-gutters>
        <v-col
          cols="12"
          class="pa-4"
        >
          <v-row
            no-gutters
            align="center"
          >
            <v-col
              cols="12"
              md="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                Eletronic id
              </span>
            </v-col>
            <v-col
              cols="12"
              md="10"
              class="d-flex align-center flex-grow-1"
            >
              <span>
                {{ trace.device_id }}
              </span>
              <div class="d-flex flex-grow-1 justify-end">
                <v-btn
                  text
                  icon
                  small
                  @click="$emit('center-on-trace')"
                >
                  <v-icon color="primary">icon-geoloc_line</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                First latitude
              </span>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              {{ trace.latitude_first_detection | coord }}
              ({{ trace.latitude_first_detection | coordDMS(false) }})
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                First longitude
              </span>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              {{ trace.longitude_first_detection | coord }}
              ({{ trace.longitude_first_detection | coordDMS(false) }})
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                First detection
              </span>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              {{ trace.first_detection | toLocaleDatetime }}
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                Last detection
              </span>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <span>
                {{ trace.last_detection | toLocaleDatetime }}
              </span>
              <span v-if="distanceLastDetectionToNow">
                ({{ distanceLastDetectionToNow }})
              </span>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span
                class="details-card__label"
                v-translate
              >
                Altitude max
              </span>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              {{ trace.altitude_max | meterAndFeet }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Approvals info -->
      <template v-if="trace.approvals.length">
        <div
          v-for="(approval, index) in trace.approvals"
          :key="index"
        >
          <hr class="separator" />
          <v-row no-gutters>
            <v-col
              cols="12"
              class="pa-4"
            >
              <v-row
                no-gutters
                align="center"
              >
                <!-- Identifier -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    Approval
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-chip
                    light
                    small
                    text-color="white"
                    class="approval-chip mx-1"
                    :class="approval.status"
                    @click="selectApproval(approval.id)"
                  >
                    <template v-if="approval.identifier">
                      {{ approval.identifier }}
                    </template>
                  </v-chip>
                </v-col>
                <!-- Exploitant name -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    Exploitant
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  {{ approval.company_name }}
                </v-col>
                <!-- Dates -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    DATES
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  {{ approval.flight.date_start | date }}
                  -
                  {{ approval.flight.date_end | date }}
                </v-col>
                <!-- Local hours -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    LOCAL HOUR
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  {{ approval.flight.time_start | hour }}
                  - {{ approval.flight.time_end | hour }} loc.
                </v-col>
                <!-- Duration -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    EXPECTED DURATION
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  {{ approval.flight.expected_duration | hour }}
                </v-col>
                <!-- In/Off Sight -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    FLIGHT
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <span
                    v-if="approval.flight.pilot_in_sight"
                    v-translate
                  >
                    in sight
                  </span>
                  <span
                    v-else
                    class="error--text font-weight-bold"
                    v-translate
                  >
                    off sight
                  </span>
                </v-col>
                <!-- Flying height -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    FLYING HEIGHT
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  {{ approval.max_flying_height | meterAndFeet }}
                </v-col>
                <!-- Altitude -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    ALTITUDE
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  {{ approval.max_altitude | meterAndFeet }}
                </v-col>
                <!-- Pilot name -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    PILOT
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  {{ approval.pilot_name }}
                </v-col>
                <!-- Pilot phone number -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    PHONE NUMBER
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  {{ approval.pilot_phone_number | phone }}
                </v-col>
                <!-- Details gave by dronist -->
                <v-col
                  cols="12"
                  md="2"
                >
                  <span
                    class="details-card__label"
                    v-translate
                  >
                    DETAILS
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                >
                  {{ approval.details }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </v-container>
</template>

<script>
import { format, formatDistanceToNow } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { SET_APPROVAL_SELECTED_NS } from '@/store/approvals';

export default {
  name: 'TrackerTrace',
  props: { trace: Object },
  data() {
    return { distanceLastDetectionToNow: null };
  },
  computed: {
    texts() {
      return {
        trackerSeenInfo: this.$gettextInterpolate(
          this.$gettext('Tracker seen %{ count } times between %{ start } and %{ end }.'),
          {
            count: this.trace.tracker_seen_info?.count || '',
            start: this.formatDate(this.trace.tracker_seen_info?.first_seen),
            end: this.formatDate(this.trace.tracker_seen_info?.last_seen),
          },
        ),
      };
    },
    displayTrackerSeenInfo() {
      const { tracker_seen_info: trackerSeenInfo } = this.trace;
      return trackerSeenInfo && trackerSeenInfo.first_seen && trackerSeenInfo.last_seen;
    },
  },
  mounted() {
    this.formatDistanceLastDetectionToNow();
    setInterval(() => { this.formatDistanceLastDetectionToNow(); }, 20000); // every 20s
  },
  methods: {
    formatDate(date) {
      if (!date) return '';

      return format(new Date(date), 'dd/MM/yyyy');
    },
    formatDistanceLastDetectionToNow() {
      if (!this.trace.last_detection) {
        this.distanceLastDetectionToNow = null;
      } else {
        this.distanceLastDetectionToNow = formatDistanceToNow(
          new Date(this.trace.last_detection),
          { addSuffix: true, locale: fr },
        );
      }
    },
    selectApproval(approvalId) {
      this.$store.dispatch(SET_APPROVAL_SELECTED_NS, approvalId);
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
.approval-chip {
  &.accepted {
    background-color: $accepted-background-color;
  }
  &.incomplete {
    background-color: $incomplete-background-color;
  }
  &.refused {
    background-color: $refused-background-color;
  }
  &.submitted {
    background-color: $submitted-background-color;
  }
  &.reserves {
    background-color: $reserves-background-color;
  }
  &.pending {
    background-color: $pending-background-color;
  }
}

</style>
