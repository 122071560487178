<template>
  <v-card
    class="default--dialog__card"
    max-height="600px"
    elevation="0"
  >
    <v-card-title>
      <template v-if="downloadDocuments">
        {{ texts.downloadDocumentsTitle }}
      </template>
      <template v-else>
        {{ texts.attachDocumentsTitle }}
      </template>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="search"
        :label="$gettext('Search document')"
        light
        hide-details
        clearable
        color="primary"
        outlined
        clear-icon="mdi-close-circle-outline"
        prepend-inner-icon="mdi-magnify"
        class="mt-3"
      />
      <v-treeview
        ref="mydocuments"
        hoverable
        shaped
        v-model="myDocumentsSelection"
        :items="documents"
        selectable
        return-object
        :open-all="expanded"
        :search="search"
        selected-color="primary"
        class="outlined mt-4 pa-2 mx-1"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="closeMyDocumentsDialog()"
      >
        <translate>Cancel</translate>
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="chooseMyDocuments()"
        :disabled="!myDocumentsSelection.length"
      >
        <template v-if="downloadDocuments">
          <translate>Download</translate>
        </template>
        <template v-else>
          <translate>Confirm</translate>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DONE } from '@/store/status';
import { GET_DRONES_NS } from '@/store/drones';
import { GET_PILOTS_NS } from '@/store/pilots';

export default {
  name: 'MyDocuments',
  props: {
    downloadDocuments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      search: null,
      myDocumentsSelection: [],
      expanded: false,
    };
  },
  computed: {
    texts() {
      return {
        attachDocumentsTitle: this.$gettext('Add from my documents'),
        downloadDocumentsTitle: this.$gettext('Choose documents to download'),
      };
    },
    flight() {
      return this.$store.getters['flights/flightSelected'];
    },
    flightDocuments() {
      return this.flight?.documents || [];
    },
    flightConstraintSheet() {
      return this.flight?.constraint_sheet;
    },
    constraintSheetName() {
      return this.$gettextInterpolate(
        this.$gettext('%{id}_constraint_sheet.docx generated at %{date}'),
        { id: this.flight.id, date: this.dateMissionSheetGenerated },
      );
    },
    dateMissionSheetGenerated() {
      if (this.flightConstraintSheet) {
        return this.datetimeLocalFormat(this.flightConstraintSheet.updated_at);
      }
      return '';
    },
    approvalsDocuments() {
      return this.$store.getters['flights/approvalsDocuments'];
    },
    exploitantDocuments() {
      return this.$store.getters['exploitants/documents'];
    },
    drones() {
      return this.$store.getters['drones/activeDrones'];
    },
    allDronesWithDocuments() {
      return this.drones.filter((d) => d.documents.length).sort((a, b) => a.label > b.label);
    },
    flightDronesWithDocuments() {
      if (this.flight?.drones?.length) {
        const flightDronesIds = this.flight.drones.map((d) => d.id);
        return this.allDronesWithDocuments.filter((d) => flightDronesIds.includes(d.id));
      }
      return [];
    },
    dronesWithDocuments() {
      return this.downloadDocuments ? this.flightDronesWithDocuments : this.allDronesWithDocuments;
    },
    pilots() {
      return this.$store.getters['pilots/activePilots'];
    },
    allPilotsWithDocuments() {
      return this.pilots.filter((p) => p.documents.length).sort(
        (a, b) => a.full_name > b.full_name,
      );
    },
    flightPilotsWithDocuments() {
      if (this.flight?.pilots?.length) {
        const flightPilotsIds = this.flight.pilots.map((p) => p.id);
        return this.allPilotsWithDocuments.filter((p) => flightPilotsIds.includes(p.id));
      }
      return [];
    },
    pilotsWithDocuments() {
      return this.downloadDocuments ? this.flightPilotsWithDocuments : this.allPilotsWithDocuments;
    },
    documents() {
      const tree = [];
      const index = 1;
      // Flight Documents
      const {
        flightDocumentsTree,
        indexAfterFlightDocuments,
      } = this.createFlightDocumentsTree(index);
      if (flightDocumentsTree) {
        tree.push(flightDocumentsTree);
      }
      // Approvals Documents
      const {
        approvalsDocumentsTree,
        indexAfterApprovalsDocuments,
      } = this.createApprovalsDocumentsTree(indexAfterFlightDocuments);
      if (approvalsDocumentsTree) {
        tree.push(approvalsDocumentsTree);
      }
      // Exploitant Documents
      const {
        exploitantDocumentsTree,
        indexAfterExploitantDocuments,
      } = this.createExploitantDocumentsTree(indexAfterApprovalsDocuments);
      if (exploitantDocumentsTree) {
        tree.push(exploitantDocumentsTree);
      }
      // Pilots Documents
      const {
        pilotsDocumentsTree,
        indexAfterPilotsDocuments,
      } = this.createPilotsDocumentsTree(indexAfterExploitantDocuments);
      if (pilotsDocumentsTree) {
        tree.push(pilotsDocumentsTree);
      }
      // Drones Documents
      const dronesDocumentsTree = this.createDronesDocumentsTree(indexAfterPilotsDocuments);
      if (dronesDocumentsTree) {
        tree.push(dronesDocumentsTree);
      }
      return tree;
    },
  },
  created() {
    if (!this.drones.length) {
      this.$store.dispatch(GET_DRONES_NS);
    }
    if (!this.pilots.length) {
      this.$store.dispatch(GET_PILOTS_NS);
    }
  },
  methods: {
    createFlightDocumentsTreeFromDownload(index) {
      if (
        this.flightDocuments.length
        || this.flightConstraintSheet
      ) {
        let flightIndex = index;
        const flightDocumentsTree = {
          id: flightIndex,
          name: this.$gettext('Mission'),
          children: [],
        };
        flightIndex += 1;
        if (this.flightConstraintSheet) {
          const constraintSheet = {
            id: flightIndex,
            name: this.$gettext('Constraint sheet'),
            children: [],
          };
          flightIndex += 1;
          constraintSheet.children.push({
            id: flightIndex,
            name: this.constraintSheetName,
            documentId: this.flightConstraintSheet.id,
            type: 'constraintSheet',
          });
          flightIndex += 1;
          flightDocumentsTree.children.push(constraintSheet);
        }
        if (this.flightDocuments.length) {
          const documentsTree = {
            id: flightIndex,
            name: this.$gettext('Documents'),
            children: [],
          };
          flightIndex += 1;
          this.flightDocuments.forEach((document) => {
            documentsTree.children.push({
              id: flightIndex,
              name: document.name,
              documentId: document.id,
              type: 'myDocuments',
            });
            flightIndex += 1;
          });
          flightDocumentsTree.children.push(documentsTree);
        }
        return { flightDocumentsTree, indexAfterFlightDocuments: flightIndex };
      }
      return { flightDocumentsTree: undefined, indexAfterFlightDocuments: index };
    },
    createFlightDocumentsTree(index) {
      if (this.downloadDocuments) {
        return this.createFlightDocumentsTreeFromDownload(index);
      }
      if (this.flightDocuments.length) {
        let flightIndex = index;
        const flightDocumentsTree = {
          id: flightIndex,
          name: this.$gettext('Mission'),
          children: [],
        };
        flightIndex += 1;
        this.flightDocuments.forEach((document) => {
          flightDocumentsTree.children.push({
            id: flightIndex,
            name: document.name,
            documentId: document.id,
            type: 'myDocuments',
          });
          flightIndex += 1;
        });
        return { flightDocumentsTree, indexAfterFlightDocuments: flightIndex };
      }
      return { flightDocumentsTree: undefined, indexAfterFlightDocuments: index };
    },
    createApprovalsDocumentsTree(index) {
      if (this.approvalsDocuments.length) {
        let approvalsIndex = index;
        const approvalsDocumentsTree = {
          id: approvalsIndex,
          name: this.$gettext('Approval requests'),
          children: [],
        };
        approvalsIndex += 1;
        this.approvalsDocuments.forEach((approvalDocuments) => {
          const approvalDocumentsTree = {
            id: approvalsIndex,
            name: approvalDocuments.approval_identifier,
            children: [],
          };
          approvalsIndex += 1;
          if (approvalDocuments.approval_mission_sheet?.status === DONE) {
            approvalDocumentsTree.children.push({
              id: approvalsIndex,
              name: approvalDocuments.approval_mission_sheet.name,
              documentId: approvalDocuments.approval_mission_sheet.approval_id,
              type: 'missionSheet',
            });
            approvalsIndex += 1;
          }
          approvalDocuments.approval_events_documents?.forEach((document) => {
            approvalDocumentsTree.children.push({
              id: approvalsIndex,
              name: document.name,
              documentId: document.id,
              type: 'myDocuments',
            });
            approvalsIndex += 1;
          });
          approvalsDocumentsTree.children.push(approvalDocumentsTree);
        });
        return { approvalsDocumentsTree, indexAfterApprovalsDocuments: approvalsIndex };
      }
      return { approvalsDocumentsTree: undefined, indexAfterApprovalsDocuments: index };
    },
    createExploitantDocumentsTree(index) {
      if (this.exploitantDocuments.length) {
        let exploitantIndex = index;
        const exploitantDocumentsTree = {
          id: exploitantIndex,
          name: this.$gettext('Exploitant'),
          children: [],
        };
        exploitantIndex += 1;
        const categories = this.exploitantDocuments.map((d) => ({
          category: d.category,
          categoryDisplayed: d.category_displayed,
        })).sort((a, b) => a.categoryDisplayed > b.categoryDisplayed);
        categories.forEach((category) => {
          const categoryDocumentsTree = {
            id: exploitantIndex,
            name: category.categoryDisplayed,
            children: [],
          };
          exploitantIndex += 1;
          this.exploitantDocuments.filter((d) => d.category === category.category).forEach(
            (document) => {
              categoryDocumentsTree.children.push({
                id: exploitantIndex,
                name: document.name,
                documentId: document.id,
                type: 'myDocuments',
              });
              exploitantIndex += 1;
            },
          );
          exploitantDocumentsTree.children.push(categoryDocumentsTree);
        });
        return { exploitantDocumentsTree, indexAfterExploitantDocuments: exploitantIndex };
      }
      return { exploitantDocumentsTree: undefined, indexAfterExploitantDocuments: index };
    },
    createPilotsDocumentsTree(index) {
      if (this.pilotsWithDocuments.length) {
        let pilotsIndex = index;
        const pilotsDocumentsTree = {
          id: pilotsIndex,
          name: this.$gettext('Télépilotes'),
          children: [],
        };
        pilotsIndex += 1;
        this.pilotsWithDocuments.forEach((pilot) => {
          const pilotDocumentsTree = {
            id: pilotsIndex,
            name: pilot.full_name,
            children: [],
          };
          pilotsIndex += 1;
          const categories = pilot.documents.map((d) => ({
            category: d.category,
            categoryDisplayed: d.category_displayed,
          })).sort((a, b) => a.categoryDisplayed > b.categoryDisplayed);
          categories.forEach((category) => {
            const categoryDocumentsTree = {
              id: pilotsIndex,
              name: category.categoryDisplayed,
              children: [],
            };
            pilotsIndex += 1;
            pilot.documents.filter((d) => d.category === category.category).forEach((document) => {
              categoryDocumentsTree.children.push({
                id: pilotsIndex,
                name: document.name,
                documentId: document.id,
                type: 'myDocuments',
              });
              pilotsIndex += 1;
            });
            pilotDocumentsTree.children.push(categoryDocumentsTree);
          });
          pilotsDocumentsTree.children.push(pilotDocumentsTree);
        });
        return { pilotsDocumentsTree, indexAfterPilotsDocuments: pilotsIndex };
      }
      return { pilotsDocumentsTree: undefined, indexAfterPilotsDocuments: index };
    },
    createDronesDocumentsTree(index) {
      if (this.dronesWithDocuments.length) {
        let dronesIndex = index;
        const dronesDocumentsTree = {
          id: index,
          name: this.$gettext('Drones'),
          children: [],
        };
        dronesIndex += 1;
        this.dronesWithDocuments.forEach((drone) => {
          const droneDocumentsTree = {
            id: dronesIndex,
            name: drone.label,
            children: [],
          };
          dronesIndex += 1;
          const categories = drone.documents.map((d) => ({
            category: d.category,
            categoryDisplayed: d.category_displayed,
          })).sort((a, b) => a.categoryDisplayed > b.categoryDisplayed);
          categories.forEach((category) => {
            const categoryDocumentsTree = {
              id: dronesIndex,
              name: category.categoryDisplayed,
              children: [],
            };
            dronesIndex += 1;
            drone.documents.filter((d) => d.category === category.category).forEach((document) => {
              categoryDocumentsTree.children.push({
                id: dronesIndex,
                name: document.name,
                documentId: document.id,
                type: 'myDocuments',
              });
              dronesIndex += 1;
            });
            droneDocumentsTree.children.push(categoryDocumentsTree);
          });
          dronesDocumentsTree.children.push(droneDocumentsTree);
        });
        return dronesDocumentsTree;
      }
      return undefined;
    },
    closeMyDocumentsDialog() {
      this.$emit('close-mydocuments');
      this.search = null;
      this.myDocumentsSelection = [];
      this.expanded = false;
      this.$refs.mydocuments.updateAll(this.expanded);
    },
    chooseMyDocuments() {
      this.$emit('choose-mydocuments', this.myDocumentsSelection);
      this.closeMyDocumentsDialog();
    },
    datetimeLocalFormat(datetimeToFormat) {
      const datetimeFormatted = new Date(datetimeToFormat);
      const optionDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return datetimeFormatted.toLocaleString(
        this.$store.getters['application/currentLanguage'],
        optionDate,
      );
    },
  },
};
</script>

<style scoped>
.outlined {
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, .42);
  max-height: 360px;
  overflow-y: auto;
  min-height: 240px;
}

</style>
