<template>
  <div>
    <v-expansion-panels
      v-model="panelExpanded"
      class="dense__expansion-panel mt-2"
      light
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="row flex-nowrap no-gutters align-center">
            <span class="dense__expansion-panel__header-content flex-grow-1">
              <v-icon
                class="mr-2"
                color="info"
              >
                mdi-file-document-outline
              </v-icon>
              <translate>Mission file</translate>
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-3">
          <!-- Flight constraint sheet already available -->
          <div
            v-if="flightConstraintSheetAvailable"
            key="constraintSheetAvailable"
          >
            <v-chip
              class="documents__chip caption"
              :disabled="downloadLoading"
            >
              <span
                class="documents__link-label"
                @click="downloadConstraintSheet(flight.id)"
              >
                {{ flightConstraintSheetFilename }}
              </span>
              <span class="font-italic info--text text--lighten-2 pl-1">
                <translate>generated:</translate>
                <span>
                  {{ flightConstraintSheetGenerationDate }}
                </span>
              </span>
            </v-chip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="primary"
                  depressed
                  x-small
                  fab
                  :disabled="flightConstraintSheetAsked"
                  :loading="generationLoading"
                  @click.stop.prevent="generateFlightConstraintSheet"
                >
                  <v-icon class="mdi-18px">
                    {{ detailsConstraintSheet.buttonIcon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ detailsConstraintSheet.description }}
              </span>
            </v-tooltip>
            <v-tooltip
              v-if="flightConstraintSheet.to_update"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="warning"
                  class="ml-1"
                >
                  mdi-alert-outline
                </v-icon>
              </template>
              <span>
                {{ texts.updateConstraintSheet }}
              </span>
            </v-tooltip>
          </div>
          <!-- Flight constraint sheet not generated yet -->
          <div
            v-else
            key="constraintSheetNotAvailable"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="primary"
                  rounded
                  depressed
                  max-height="32px"
                  :disabled="flightConstraintSheetAsked || generationLoading"
                  :loading="generationLoading"
                  @click.stop.prevent="generateFlightConstraintSheet"
                >
                  <div class="d-flex align-center">
                    <v-icon class="mr-1 mdi-18px">
                      {{ detailsConstraintSheet.buttonIcon }}
                    </v-icon>
                    <span class="caption">
                      {{ detailsConstraintSheet.buttonText }}
                    </span>
                  </div>
                </v-btn>
              </template>
              <span>
                {{ detailsConstraintSheet.description }}
              </span>
            </v-tooltip>
            <v-tooltip
              v-if="flightConstraintSheetFailed"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  class="ml-1"
                >
                  mdi-alert
                </v-icon>
              </template>
              <translate>Mission file genetation failed, you can try it later.</translate>
            </v-tooltip>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import APIService from '@/services/api';
import { SET_FLIGHT_CONSTRAINT_SHEET_STATUS_NS } from '@/store/flights';

export default {
  name: 'FlightDocumentsSimplified',
  props: {
    flightId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      panelExpanded: undefined,
      generationLoading: false,
      downloadLoading: false,
    };
  },
  computed: {
    flight() {
      return this.$store.getters['flights/flightSelected'];
    },
    flightConstraintSheet() {
      return this.flight?.constraint_sheet;
    },
    flightConstraintSheetAsked() {
      return ['todo', 'processing'].includes(this.flightConstraintSheet?.status);
    },
    flightConstraintSheetAvailable() {
      return this.flightConstraintSheet?.status === 'done';
    },
    flightConstraintSheetFailed() {
      return this.flightConstraintSheet?.status === 'failed';
    },
    flightConstraintSheetFilename() {
      return this.$gettextInterpolate(
        this.$gettext('%{id}_dossier_mission.docx'),
        { id: this.flight?.id || '' },
      );
    },
    flightConstraintSheetGenerationDate() {
      if (this.flightConstraintSheetAvailable) {
        return this.datetimeLocalFormat(this.flightConstraintSheet.updated_at);
      }
      return '';
    },
    detailsConstraintSheet() {
      if (this.flightConstraintSheetAsked) {
        return {
          description: this.$gettext('Constraint sheet being generated...'),
          buttonIcon: 'mdi-file-word',
          buttonText: this.$gettext('Constraint sheet being generated...'),
        };
      }
      if (this.flightConstraintSheetAvailable) {
        return {
          description: this.$gettext('Update constraint sheet'),
          buttonIcon: 'mdi-refresh',
          buttonText: '',
        };
      }
      return {
        description: this.$gettext('Generate constraint sheet'),
        buttonIcon: 'mdi-file-word',
        buttonText: this.$gettext('Generate constraint sheet'),
      };
    },
    texts() {
      return {
        updateConstraintSheet: this.$gettext(`You need to update your constraint sheet because of
          some updates on your flight`),
      };
    },
  },
  methods: {
    downloadConstraintSheet(flightId) {
      this.downloadLoading = true;
      APIService.downloadFlightWordSheet(flightId)
        .then(({ data }) => {
          this.navigatorDownloadDocuments(data, this.flightConstraintSheetFilename);
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    generateFlightConstraintSheet() {
      this.generationLoading = true;
      APIService.generateFlightConstraintSheet(
        this.flight.id,
        this.scenarioDeclared,
      )
        .then(() => {
          this.$store.dispatch(SET_FLIGHT_CONSTRAINT_SHEET_STATUS_NS, {
            flightId: this.flight.id,
            constraintSheetStatus: 'processing',
          });
          this.showMessage(this.$gettext('The document is being generated'), 'success');
          this.$emit('close');
        })
        .finally(() => {
          this.generationLoading = false;
        });
    },
    navigatorDownloadDocuments(data, filename) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    datetimeLocalFormat(datetimeToFormat) {
      const datetimeFormatted = new Date(datetimeToFormat);
      const optionDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return datetimeFormatted.toLocaleString(
        this.$store.getters['application/currentLanguage'],
        optionDate,
      );
    },
  },
};
</script>
