<template>
  <v-card
    max-height="800px"
    elevation="0"
    class="default--dialog__card"
  >
    <v-card-title>
      <translate>REQUEST ANOTHER OPINION</translate>
      <v-icon @click="closeOpinionDialog()">close</v-icon>
    </v-card-title>
    <v-card-text class="pb-0">
      <p class="pb-1">
        {{ texts.explain }}
      </p>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-combobox
          v-model="emails.selected"
          :items="emails.items"
          :search-input.sync="emails.search"
          :rules="[rules.emailRequired, rules.emailValid]"
          hide-selected
          multiple
          small-chips
          @click:append="emails.search=null"
          outlined
          :label="labels.emails"
          :loading="loadingOpinionsEmails"
        >
          <template
            v-slot:no-data
            v-if="isSearching"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-translate>Aucun résultat pour</span>&nbsp;
                  <v-chip>
                    {{ emails.search }}
                  </v-chip>
                  <span>. Tapez</span>&nbsp;
                  <kbd>
                    <span v-translate>Entrée</span>
                  </kbd>&nbsp;
                  <span v-translate>pour l'ajouter.</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, parent, selected }">
            <v-chip
              v-if="item"
              :input-value="selected"
            >
              <span class="pr-2">
                {{ item }}
              </span>
              <v-icon
                small
                @click="parent.selectItem(item)"
              >
                close
              </v-icon>
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-chip>
                {{ item }}
              </v-chip>
            </v-list-item-content>
          </template>
        </v-combobox>
        <!-- Saved messages -->
        <v-expansion-panels class="mb-8">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <translate>Saved chat messages</translate>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list
                dense
                subheader
                max-height="120"
                class="overflow-y-auto"
              >
                <v-list-item
                  v-for="(item, index) in savedChatMessages"
                  :key="index"
                >
                  <v-list-item-title
                    class="body-2 cursor-pointer"
                    @click="insertContent(item.content)"
                  >
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-textarea
          :label="labels.emailContent"
          v-model="emailContent"
          :placeholder="placeholders.emailContent"
          :rules="[rules.required]"
          outlined
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="px-6 pb-6">
      <v-btn
        block
        color="primary"
        @click="requestOpinion()"
        :loading="loadingSendEmail"
      >
        <translate>Send</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import { ADD_OPINION_NS } from '@/store/opinions';

export default {
  name: 'OpinionForm',
  data() {
    return {
      valid: true,
      emails: {
        selected: [],
        items: [],
        search: null,
      },
      emailContent: '',
      rules: {
        required: (v) => v.length !== 0 || this.$gettext('Please fill in this field.'),
        emailRequired: (v) => (
          v.length !== 0 || this.$gettext('Please fill in at least one email.')
        ),
        emailValid: (
          (values) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailIsValid = true;
            values.forEach((value) => {
              if (!pattern.test(value)) {
                emailIsValid = this.$gettext('Email invalid.');
              }
            });
            return emailIsValid;
          }
        ),
      },
      loadingSendEmail: false,
      loadingOpinionsEmails: false,
    };
  },
  computed: {
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    labels() {
      return {
        emails: this.$gettext('Emails'),
        emailContent: this.$gettext('Message'),
      };
    },
    placeholders() {
      return {
        emailContent: this.$gettext(`Hello,\nI want to request your opinion about this approval
          request received.`),
      };
    },
    texts() {
      return {
        explain: this.$gettext(`You can ask opinions of other authorities. They will receive an
          email with approval details and they can response in personal interface of this
          application.`),
      };
    },
    isSearching() {
      return this.emails.search !== null && this.emails.search !== '';
    },
    approval() {
      return this.$store.getters['approvals/approvalSelected'];
    },
    savedChatMessages() {
      return this.$store.getters['structures/structureDetails'].airspace_saved_chat_messages || [];
    },
  },
  created() {
    this.getOpinionsEmails();
  },
  methods: {
    insertContent(content) {
      this.emailContent = this.emailContent.concat('\n').concat(content);
    },
    async getOpinionsEmails() {
      this.loadingOpinionsEmails = true;
      await APIService.getOpinionsEmails()
        .then(({ data }) => {
          this.emails.items = data;
        })
        .finally(() => {
          this.loadingOpinionsEmails = false;
        });
    },
    async requestOpinion() {
      if (this.$refs.form.validate()) {
        this.loadingSendEmail = true;
        const payload = {
          approval_id: this.approval.id,
          emails: this.emails.selected,
          email_content: this.emailContent,
        };
        await APIService.createOpinion(payload)
          .then(({ data }) => {
            data.forEach((opinion) => {
              this.$store.dispatch(ADD_OPINION_NS, opinion);
            });
          })
          .finally(() => {
            this.closeOpinionDialog();
            this.getOpinionsEmails();
          });
      }
    },
    closeOpinionDialog() {
      this.$emit('close-form');
      this.emails.selected = [];
      this.emails.search = null;
      this.emailContent = '';
      this.loadingSendEmail = false;
    },
  },
};
</script>
