<template>
  <div>
    <v-row
      no-gutters
      v-for="(subApproval, index) in subApprovals"
      :key="index"
      align="center"
      class="my-4"
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-row
          no-gutters
          align="center"
        >
          <!-- Zone -->
          <v-col
            cols="12"
            md="4"
          >
            <span
              class="details-card__label"
              v-translate
            >
              ZONE
            </span>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <span>
              {{ subApproval.identifier }}
            </span>
            <v-btn
              icon
              small
              @click="centerOnSubApproval(subApproval)"
            >
              <v-icon color="primary">icon-geoloc_line</v-icon>
            </v-btn>
          </v-col>
          <!-- Latitude -->
          <template v-if="!isUserVisitor">
            <v-col
              cols="12"
              md="4"
            >
              <span
                class="details-card__label"
                v-translate
              >
                LATITUDE
              </span>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              {{ subApproval.lat | coord }} ({{ subApproval.lat | coordDMS(false) }})
            </v-col>
          </template>
          <!-- Longitude -->
          <template v-if="!isUserVisitor">
            <v-col
              cols="12"
              md="4"
            >
              <span
                class="details-card__label"
                v-translate
              >
                LONGITUDE
              </span>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              {{ subApproval.lng | coord }} ({{ subApproval.lng | coordDMS(false) }})
            </v-col>
          </template>
          <!-- QDR -->
          <template v-if="isUserAuthority && !isUserPrefecture">
            <v-col
              cols="12"
              md="4"
            >
              <span
                class="details-card__label"
                v-translate
              >
                QDR
              </span>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              {{ subApproval.radial | degree }} - {{ subApproval.distance_to_structure | mile }}
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-row
          no-gutters
          align="center"
        >
          <!-- Flying height -->
          <v-col
            cols="12"
            md="4"
          >
            <span
              class="details-card__label"
              v-translate
            >
              FLYING HEIGHT
            </span>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <span v-if="isUserAuthority && !isUserPrefecture">
              {{ subApproval.height | meterAndFeet }}
            </span>
            <span v-else>
              {{ subApproval.height | meter }}
            </span>
          </v-col>
          <!-- Ground elevation -->
          <template v-if="isUserAuthority && !isUserPrefecture">
            <v-col
              cols="12"
              md="4"
            >
              <span
                class="details-card__label"
                v-translate
              >
                GROUND ELEVATION
              </span>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <span v-if="subApproval.elevation===null">
                <v-badge
                  color="red"
                  left
                  inline
                  content="!"
                  class="small-badge"
                />
                <translate>
                  Unknown Elevation
                </translate>
              </span>
              <div v-else>
                <span v-if="isUserAuthority && !isUserPrefecture">
                  {{ subApproval.elevation | meterAndFeet }}
                </span>
                <span v-else>
                  {{ subApproval.elevation | meter }}
                </span>
              </div>
            </v-col>
          </template>
          <!-- Altitude -->
          <template v-if="isUserAuthority && !isUserPrefecture">
            <v-col
              cols="12"
              md="4"
            >
              <span
                class="details-card__label"
                v-translate
              >
                MAX ALTITUDE
              </span>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              {{ subApproval.elevation + subApproval.height | meterAndFeet }}
            </v-col>
          </template>
          <!-- PSA -->
          <template v-if="!(isUserPrefecture || isUserVisitor)">
            <v-col
              cols="12"
              md="4"
            >
              <span
                class="details-card__label"
                v-translate
              >
                BREACHING OF PSA
              </span>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <PSAComparison :subApproval="subApproval" />
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ZOOM_TO_AREA_NS } from '@/store/map';

import PSAComparison from '@/components/Approvals/PSAComparison.vue';

export default {
  name: 'SubApprovalsDetails',
  components: { PSAComparison },
  props: {
    approval: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
    isUserVisitor() {
      return this.$store.getters['authentication/isUserVisitor'];
    },
    subApprovals() {
      return this.approval.sub_approvals;
    },
  },
  methods: {
    centerOnSubApproval(subApproval) {
      if (this.isAuthorityFullScreenInterface) {
        this.$emit('center-on-sub-approval', subApproval.geometry);
      } else {
        this.$store.dispatch(ZOOM_TO_AREA_NS, subApproval.geometry);
      }
    },
  },
};
</script>
