<template>
  <div class="flight-approval-details">
    <!-- Summary -->
    <div
      v-if="approval.id && approval.authority_type !== 'prefecture'"
      class="mt-1"
    >
      <template v-if="approval.external_id && approval.status !== 'error'">
        <div v-if="approval.display_identifier">
          {{ texts.identifier }}
        </div>
        <span>
          {{ texts.sentOn }}
        </span>
        <span v-if="wasUpdated">
          {{ texts.updatedOn }}
        </span>
        <span
          @click="showExternalApprovalDetailsDialog=true"
          class="font-italic cursor-pointer"
        >
          <u v-translate>
            Approval details
          </u>
        </span>
      </template>
      <template v-else-if="approval.display_identifier">
        <span v-translate="{id: approval.display_identifier}">
          Your approval request identifier is %{ id }.
        </span>
      </template>
    </div>
    <!-- Decisions -->
    <div>
      <div
        class="mt-4 font-weight-medium pb-1 black--text body-2"
        v-translate
      >
        Decisions
      </div>
      <v-skeleton-loader
        v-if="loadingDecisions"
        light
        type="list-item-three-line"
      />
      <template v-else>
        <FlightApprovalDecisions
          :approval="approval"
          :flight="flight"
          :areas="approval.areas"
          :decisions="approval.decisions"
        />
      </template>
    </div>
    <!-- General requirements -->
    <div v-if="capability.extra_airspace_requirements">
      <div
        class="mt-4 font-weight-medium pb-1 black--text body-2"
        v-translate
      >
        General requirements to respect
      </div>
      <v-alert
        elevation="2"
        class="body-2 ma-0"
        icon="mdi-information"
        color="primary"
        text
      >
        <p
          ref="generalRequirements"
          class="requirements"
          :class="{
            'with-overflow': generalRequirementsOverflowing,
            'collapsed': generalRequirementsCollapsed,
          }"
          @click="generalRequirementsCollapsed = !generalRequirementsCollapsed"
        >
          <span>
            {{ capability.extra_airspace_requirements }}
          </span>
        </p>
        <div
          v-if="generalRequirementsOverflowing && generalRequirementsCollapsed"
          class="cursor-pointer text-right pt-1"
          @click="generalRequirementsCollapsed = false"
        >
          <span
            v-translate
            class="font-italic info--text text-decoration-underline"
          >
            see all
          </span>
        </div>
      </v-alert>
    </div>
    <!-- Activations -->
    <div v-if="canRequestActivation">
      <div
        class="mt-4 font-weight-medium pb-1 black--text body-2"
        v-translate
      >
        Activations
      </div>
      <v-card
        class="pa-3"
        color="grey lighten-3"
      >
        <v-badge
          v-if="needActivation"
          left
          inline
          color="red"
          class="small-badge mb-2"
        >
          <span slot="badge">!</span>
          <span class="font-italic pl-1 error--text font-weight-medium caption">
            {{ texts.activationAlert }}
          </span>
        </v-badge>
        <template v-if="displayActivationsList">
          <FlightActivationList
            :flight="flight"
            :approval="approval"
            :constraint="constraint"
            @action-on-approval="() => $emit('action-on-approval')"
          />
        </template>
        <RequestActivationActionCreate
          :approval="approval"
          :flight="flight"
          :constraint="constraint"
          @action-on-approval="() => $emit('action-on-approval')"
          class="mt-3"
        />
      </v-card>
    </div>
    <!-- Revalidation -->
    <div v-if="canRequestRevalidation">
      <div
        class="mt-4 font-weight-medium pb-1 black--text body-2"
        v-translate
      >
        Action required
      </div>
      <v-card
        class="pa-3"
        color="grey lighten-3"
      >
        <div class="px-1 mb-1">
          <span v-if="processThroughUSK">
            {{ texts.validationRequestUSK }}
          </span>
          <span v-else>
            {{ texts.validationRequestInternalAuthority }}
          </span>
        </div>
        <RequestRevalidation
          :flight="flight"
          :approval="approval"
          :processThroughUSK="processThroughUSK"
          @update-approval="(approval) => updateApproval(approval)"
          class="mt-3"
        />
      </v-card>
    </div>
    <!-- Take off authorization -->
    <div v-if="takeOffAuthorization">
      <div
        class="mt-4 font-weight-medium pb-1 black--text body-2"
        v-translate
      >
        Take off authorization
      </div>
      <v-card
        class="pa-3"
        color="grey lighten-3"
      >
        <!-- Take off authorization status -->
        <v-expansion-panels
          class="dense__expansion-panel"
          :value="canRequestTakeOffAuthorization ? undefined : 0"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="dense__expansion-panel__header-content">
                <TakeOffAuthorizationStatusIcon
                  :status="takeOffAuthorization.status"
                  disableTooltip
                />
                <span class="pl-1">
                  {{ takeOffAuthorizationDisplayStatus }}
                </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TakeOffAuthorizationDetails
                :takeOffAuthorization="takeOffAuthorization"
                :newMessageDisabled="canRequestTakeOffAuthorization"
                @update-take-off-authorization="(data) => updateTakeOffAuthorization(data)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Take off authorization submit button -->
        <LayoutFlightConstraintAction
          v-if="canRequestTakeOffAuthorization"
          id="take-off-authorization-submit-btn"
          :flight="flight"
        >
          <template
            slot="action-button"
            slot-scope="{disabled}"
          >
            <div class="pt-3">
              <v-btn
                color="success"
                class="main-button__font my-0"
                @click.stop="showTakeOffAuthorizationDialog = true"
                :disabled="disabled"
                block
              >
                <span v-translate>Request a take off authorization</span>
              </v-btn>
            </div>
          </template>
        </LayoutFlightConstraintAction>
      </v-card>
    </div>
    <!-- ---------- Documents ------------ -->
    <div v-if="displayApprovalDocuments">
      <div
        class="mt-4 font-weight-medium pb-1 black--text body-2"
        v-translate
      >
        Documents
      </div>
      <FlightApprovalDocuments
        :approvalId="approval.id"
        :missionSheet="approvalMissionSheet"
        :eventsDocuments="approvalEventsDocuments"
        withUpload
        @message-sent="(data) => updateApprovalEvents(data)"
      />
    </div>
    <!-- ---------- Chat bloc ------------ -->
    <div class="mt-4 font-weight-medium pb-1 black--text body-2">
      <span
        v-if="processThroughThales"
        v-translate
      >
        Tracking information
      </span>
      <span
        v-translate
        v-else
      >
        Discussions
      </span>
    </div>
    <v-expansion-panels
      class="dense__expansion-panel"
      :value="(canRequestActivation || takeOffAuthorization) ? undefined : 0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="dense__expansion-panel__header-content">
            {{ texts.chatPanel }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ApprovalChat
            :approval="approval"
            :newMessageDisabled="newMessageDisabled"
            @messages-sent="(data) => updateApprovalEvents(data)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Cancel approval -->
    <div class="mt-4 last-approval-actions">
      <RequestApprovalActionsCancel
        v-if="canCancelApproval"
        :approvalId="approval.id"
        :flight="flight"
        @update-approval="(approval) => updateApproval(approval)"
      />
    </div>
    <v-dialog
      v-model="showExternalApprovalDetailsDialog"
      width="820px"
      :retain-focus="false"
      persistent
    >
      <ExternalApprovalDetails
        :approval="approval"
        :capability="capability"
        @close-form="() => showExternalApprovalDetailsDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="showTakeOffAuthorizationDialog"
      width="820px"
      :retain-focus="false"
      persistent
    >
      <TakeOffAuthorizationDronist
        :takeOffAuthorization="takeOffAuthorization"
        @update-take-off-authorization="(data) => updateTakeOffAuthorization(data)"
        @close="() => showTakeOffAuthorizationDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import { CONSTRAINTS_PROCESS_THROUGH } from '@/settings';

import { SET_FLIGHT_APPROVAL_PROPERTY_NS, UPDATE_FLIGHT_APPROVALS_NS } from '@/store/flights';
import {
  SUBMITTED,
  ACCEPTED,
  RESERVES,
  CANCELLED,
  INCOMPLETE,
  ERROR,
  DONE,
  REFUSED,
  LANDING_REQUIRED,
  REQUIRED,
} from '@/store/status';

import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

import ApprovalChat from '@/components/Approvals/ApprovalChat.vue';
import FlightApprovalDocuments from '@/components/Flights/Documents/FlightApprovalDocuments.vue';
import ExternalApprovalDetails from '@/components/Flights/ExternalApprovalDetails.vue';
import FlightActivationList from '@/components/Flights/FlightActivationList.vue';
import FlightApprovalDecisions from '@/components/Flights/FlightApprovalDecisions.vue';
import RequestActivationActionCreate from '@/components/Flights/RequestApproval/RequestActivationActionCreate.vue';
import RequestApprovalActionsCancel from '@/components/Flights/RequestApproval/RequestApprovalActionsCancel.vue';
import RequestRevalidation from '@/components/Flights/RequestApproval/RequestRevalidation.vue';
import TakeOffAuthorizationStatusIcon from '@/components/StatusIcon/TakeOffAuthorizationStatusIcon.vue';
import TakeOffAuthorizationDetails from '@/components/TakeOffAuthorizations/TakeOffAuthorizationDetails.vue';
import TakeOffAuthorizationDronist from '@/components/TakeOffAuthorizations/TakeOffAuthorizationDronist.vue';

export default {
  name: 'FlightApprovalDetails',
  components: {
    LayoutFlightConstraintAction,
    ApprovalChat,
    FlightApprovalDocuments,
    ExternalApprovalDetails,
    FlightActivationList,
    FlightApprovalDecisions,
    RequestActivationActionCreate,
    RequestApprovalActionsCancel,
    RequestRevalidation,
    TakeOffAuthorizationStatusIcon,
    TakeOffAuthorizationDetails,
    TakeOffAuthorizationDronist,
  },
  props: {
    approval: Object,
    flight: Object,
    constraint: Object,
    capability: Object,
  },
  data() {
    return {
      showExternalApprovalDetailsDialog: false,
      showTakeOffAuthorizationDialog: false,
      generalRequirementsCollapsed: true,
      generalRequirementsOverflowing: undefined,
    };
  },
  computed: {
    texts() {
      return {
        sentOn: this.$gettextInterpolate(
          this.$gettext('Sent on %{ date } at %{ time }.'),
          {
            date: this.getDateFromTimestamp(this.approval.created_at),
            time: this.getTimeFromTimestamp(this.approval.created_at),
          },
        ),
        identifier: this.$gettextInterpolate(
          this.$gettext('Your approval request identifier is %{ identifier }.'),
          { identifier: this.approval.display_identifier || '' },
        ),
        updatedOn: this.$gettextInterpolate(
          this.$gettext('Updated on %{ date } at %{ time }.'),
          {
            date: this.getDateFromTimestamp(this.approval.updated_at),
            time: this.getTimeFromTimestamp(this.approval.updated_at),
          },
        ),
        chatPanel: this.$gettext('Discussions and events related to the request'),
        activationAlert: this.$gettext(
          'It is necessary to send an activation request for your next flight days',
        ),
        validationRequestUSK: this.$gettext(
          'Please complete and sign the agreement protocol received, and request a revalidation.',
        ),
        validationRequestInternalAuthority: this.$gettext(
          'Please provide the information required by the authority, and request a revalidation.',
        ),
      };
    },
    wasUpdated() {
      return this.approval.events.map(
        (event) => event.origin.includes('operation modification'),
      ).includes(true);
    },
    newMessageDisabled() {
      return (
        this.processThroughThales
        || this.approval.status === CANCELLED
        || this.approval.is_closed
        || (
          this.processThroughUSK
          && !this.approval.external_id
        )
      );
    },
    approvalMissionSheet() {
      return this.approval.mission_sheet;
    },
    displayApprovalDocuments() {
      return this.approvalMissionSheet?.status === DONE || this.approvalEventsDocuments.length;
    },
    approvalEventsDocuments() {
      return this.getApprovalEventsDocuments(this.approval.events);
    },
    processThroughThales() {
      return this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.THALES;
    },
    processThroughUSK() {
      return this.constraint.process_through === CONSTRAINTS_PROCESS_THROUGH.USK;
    },
    canCancelApproval() {
      return (
        ![CANCELLED, ERROR].includes(this.approval.status)
        && !this.approval.is_closed
      );
    },
    canRequestRevalidation() {
      return this.approval.decisions?.some((d) => d.status === INCOMPLETE) || false;
    },
    canRequestActivation() {
      return (
        [ACCEPTED, RESERVES].includes(this.approval.status)
        && this.approval.activation_required
      );
    },
    displayActivationsList() {
      return (
        this.approval.activations?.length
        && ![CANCELLED, ERROR].includes(this.approval.status)
      );
    },
    needActivation() {
      return this.canRequestActivation && this.constraint.status === INCOMPLETE;
    },
    loadingDecisions() {
      return this.approval.decisions === undefined;
    },
    takeOffAuthorization() {
      return this.approval.take_off_authorization;
    },
    takeOffAuthorizationDisplayStatus() {
      if (this.takeOffAuthorization) {
        switch (this.takeOffAuthorization.status) {
          case SUBMITTED:
            return this.$gettext('Take off authorization submitted');
          case ACCEPTED:
            return this.$gettext('Take off authorization granted');
          case REFUSED:
            return this.$gettext('Take off authorization refused');
          case LANDING_REQUIRED:
            return this.$gettext('Landing required');
          case REQUIRED:
            return this.$gettext('Take off authorization required');
          default:
            return this.$gettext('Take off authorization status unknown');
        }
      }
      return undefined;
    },
    canRequestTakeOffAuthorization() {
      return this.takeOffAuthorization?.status === REQUIRED;
    },
  },
  mounted() {
    if (this.$refs.generalRequirements) {
      this.generalRequirementsOverflowing = (
        this.checkVerticalOverflow(this.$refs.generalRequirements)
      );
    }
  },
  methods: {
    getDateFromTimestamp(timestamp) {
      return new Date(timestamp).toLocaleDateString(
        this.$store.getters['application/currentLanguage'],
      );
    },
    getTimeFromTimestamp(timestamp) {
      return new Date(timestamp).toLocaleTimeString(
        this.$store.getters['application/currentLanguage'], { hour: '2-digit', minute: '2-digit' },
      );
    },
    getApprovalEventsDocuments(events) {
      return events.filter((event) => event.document && !event.document.unavailable).map(
        (event) => ({ event_id: event.id, ...event.document }),
      );
    },
    updateApprovalEvents(newEvents) {
      this.$store.commit(
        SET_FLIGHT_APPROVAL_PROPERTY_NS,
        {
          flightId: this.flight.id,
          approvalId: this.approval.id,
          propertyKey: 'events',
          propertyValue: newEvents,
        },
      );
      this.$store.commit(
        SET_FLIGHT_APPROVAL_PROPERTY_NS,
        {
          flightId: this.flight.id,
          approvalId: this.approval.id,
          propertyKey: 'events_documents',
          propertyValue: this.getApprovalEventsDocuments(newEvents),
        },
      );
    },
    checkVerticalOverflow(element) {
      return element.offsetHeight < element.scrollHeight;
    },
    async updateApproval(approval) {
      const approvalUpdated = approval;
      approvalUpdated.constraint_id = this.approval.constraint_id;
      approvalUpdated.capability = this.approval.capability;
      if (approvalUpdated.external_id && approvalUpdated.status !== CANCELLED) {
        (
          { data: approvalUpdated.events } = (
            await APIService.synchronizeApprovalExternalDocuments(approvalUpdated.id)
          )
        );
      }
      if (approvalUpdated.events) {
        approvalUpdated.events_documents = approvalUpdated.events.filter(
          (event) => event.document && !event.document.unavailable,
        ).map((event) => ({ event_id: event.id, ...event.document }));
      }
      this.$store.commit(
        UPDATE_FLIGHT_APPROVALS_NS, { flightId: this.flight.id, approvals: [approvalUpdated] },
      );
      this.updateDecisions();
      this.$emit('action-on-approval');
    },
    updateDecisions() {
      APIService.getApprovalDecisions(this.approval.id)
        .then(({ data }) => {
          this.$store.commit(
            SET_FLIGHT_APPROVAL_PROPERTY_NS,
            {
              flightId: this.flight.id,
              approvalId: this.approval.id,
              propertyKey: 'decisions',
              propertyValue: data,
            },
          );
        });
    },
    updateTakeOffAuthorization(data) {
      this.$store.commit(
        SET_FLIGHT_APPROVAL_PROPERTY_NS,
        {
          flightId: this.flight.id,
          approvalId: data.approval_id,
          propertyKey: 'take_off_authorization',
          propertyValue: data,
        },
      );
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight-approval-details,
.last-approval-actions {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}
.requirements {
  margin: 0px;
  color: black;
  white-space: pre-line;
  &.with-overflow {
    cursor: pointer;
  }
  &.collapsed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}

</style>
