<template>
  <div>
    <!-- Update notification -->
    <LayoutFlightConstraintAction
      v-if="outdatedPrefecturesNotifs.length && canUpdateOutdatedPrefecturesNotifs"
      id="pref-btn-update"
      :flight="flight"
      :disabledExtra="notifAlphaTangoPrefectureDisabledExtra"
      :disabledExtraTooltip="notifAlphaTangoPrefectureDisabledExtraTooltip"
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <div class="pb-2">
          <v-btn
            :color="notifAlphaTangoPrefectureButtonColorClass"
            class="main-button__font"
            @click.stop="notifyAlphaTangoPrefecture({ update: true })"
            :disabled="disabled"
            block
          >
            <template>
              {{ updateAlphaTangoPrefectureNotifsBtnText }}
            </template>
          </v-btn>
        </div>
      </template>
    </LayoutFlightConstraintAction>
    <!-- Send new notification -->
    <LayoutFlightConstraintAction
      id="pref-btn-new"
      :flight="flight"
      :disabledExtra="notifAlphaTangoPrefectureDisabledExtra"
      :disabledExtraTooltip="notifAlphaTangoPrefectureDisabledExtraTooltip"
    >
      <template
        slot="action-button"
        slot-scope="{disabled}"
      >
        <div class="pb-2">
          <v-btn
            :color="notifAlphaTangoPrefectureButtonColorClass"
            class="main-button__font"
            @click.stop="notifyAlphaTangoPrefecture({ update: false })"
            :disabled="disabled"
            block
          >
            <template>
              {{ sendNewAlphaTangoPrefectureNotifsBtnText }}
            </template>
          </v-btn>
        </div>
      </template>
    </LayoutFlightConstraintAction>
    <!-- ---- Notify AlphaTango Prefecture popup: update notif ----- -->
    <v-dialog
      v-model="showUpdateNotifAlphaTangoPrefectureDialog"
      width="880px"
      persistent
      :retain-focus="false"
    >
      <NotifAlphaTangoPrefectureForm
        :flight="flight"
        :update="true"
        :notificationsToUpdate="outdatedPrefecturesNotifs"
        @close-form="() => showUpdateNotifAlphaTangoPrefectureDialog = false"
      />
    </v-dialog>
    <!-- ---- Notify AlphaTango Prefecture popup: new notif ----- -->
    <v-dialog
      v-model="showNewNotifAlphaTangoPrefectureDialog"
      width="880px"
      persistent
      :retain-focus="false"
    >
      <NotifAlphaTangoPrefectureForm
        :flight="flight"
        @close-form="() => showNewNotifAlphaTangoPrefectureDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { CONSTRAINTS_CATEGORY } from '@/settings';

import { CANCELLED, OUTDATED } from '@/store/status';

import LayoutFlightConstraintAction from '@/layouts/LayoutFlightConstraintAction.vue';

import NotifAlphaTangoPrefectureForm from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoPrefectureForm.vue';

export default {
  name: 'NotifAlphaTangoPrefectureActions',
  components: {
    LayoutFlightConstraintAction,
    NotifAlphaTangoPrefectureForm,
  },
  props: {
    flight: Object,
    constraint: Object,
  },
  data() {
    return {
      showNewNotifAlphaTangoPrefectureDialog: false,
      showUpdateNotifAlphaTangoPrefectureDialog: false,
      communeNotFound: !this.flight.commune,
    };
  },
  computed: {
    numberOfAreas() {
      return this.flight.areas.length;
    },
    notifAlphaTangoPrefectureButtonColorClass() {
      return (
        this.constraint.category === CONSTRAINTS_CATEGORY.NEARBY
          ? 'grey lighten-2'
          : 'success'
      );
    },
    notifAlphaTangoPrefectureDisabledExtra() {
      return this.communeNotFound;
    },
    notifAlphaTangoPrefectureDisabledExtraTooltip() {
      if (this.notifAlphaTangoPrefectureDisabledExtra) {
        return this.$gettext(`S3 notification via Clearance are unfortunately not possible in this
          area, you can send a flight notification directly on AlphaTango.`);
      }
      return '';
    },
    prefectureNotifications() {
      return this.flight.prefectureNotifications || [];
    },
    sendNewAlphaTangoPrefectureNotifsBtnText() {
      if (this.prefectureNotifications.filter((notif) => notif.status !== CANCELLED).length) {
        return this.$ngettext(
          'Send a new notification',
          'Send new notifications',
          this.numberOfAreas,
        );
      }
      return this.$ngettext(
        'Send a flight notification',
        'Send flight notifications',
        this.numberOfAreas,
      );
    },
    updateAlphaTangoPrefectureNotifsBtnText() {
      return this.$ngettext(
        'Update notification',
        'Update notifications',
        this.outdatedPrefecturesNotifs.length,
      );
    },
    canUpdateOutdatedPrefecturesNotifs() {
      if (this.outdatedPrefecturesNotifs.length) {
        const notificationDate = Math.max(
          ...this.outdatedPrefecturesNotifs.map((n) => new Date(n.datetime_start)),
        );
        return new Date(notificationDate) > new Date();
      }
      return false;
    },
    outdatedPrefecturesNotifs() {
      return this.prefectureNotifications.filter((notif) => notif.status === OUTDATED);
    },
  },
  methods: {
    notifyAlphaTangoPrefecture({ update = false }) {
      if (
        !(this.$store.state.authentication.user.subscription.active || this.flight.was_entrusted)
      ) {
        this.$emit('show-subscribe-dialog');
      } else if (update) {
        this.showUpdateNotifAlphaTangoPrefectureDialog = true;
      } else {
        this.showNewNotifAlphaTangoPrefectureDialog = true;
      }
    },
  },
};
</script>
