<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="py-2 px-3"
          :class="highlighted"
        >
          <v-img
            :src="alphaTangoLogoPath"
            max-width="24"
          />
          <span
            v-translate="{count: notificationsCount}"
            :translate-n="notificationsCount"
            translate-plural="%{ count } AlphaTango Notifications"
            class="pl-2"
          >
            %{ count } AlphaTango Notification
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="notificationContainer">
          <v-list
            dense
            class="pt-0"
          >
            <template v-for="(notification, notifIndex) in notifications">
              <div
                :key="notifIndex"
                class="px-3"
              >
                <v-divider class="ma-0" />
              </div>
              <v-list-item
                :key="notification.id"
                class="pa-0 mx-3"
              >
                <v-list-item-content>
                  <v-row
                    align="center"
                    no-gutters
                    class="py-2"
                  >
                    <v-col
                      :cols="getSizeColumn[0]"
                      class="px-3"
                    >
                      <v-row
                        align="center"
                        no-gutters
                      >
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <v-row
                            align="center"
                            no-gutters
                          >
                            <v-icon
                              small
                              :color="getIconColor(notification.status)"
                            >
                              {{ getIcon(notification.status) }}
                            </v-icon>
                            <span
                              class="notification-status pl-2"
                              :class="`${getIconColor(notification.status)}--text`"
                            >
                              {{ getNotificationStatusText(notification.status) }}
                            </span>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="9"
                          lg="10"
                          class="notification-dates"
                          :class="paddingForArmyInterface"
                        >
                          {{ getNotificationDatesText(notification) }}
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      :cols="getSizeColumn[1]"
                      class="pr-3"
                    >
                      <v-row
                        no-gutters
                        align="center"
                        justify="end"
                      >
                        <template v-if="!isUserArmy">
                          <!-- ---- Cancel AlphaTango Army Notification button ----- -->
                          <v-tooltip
                            bottom
                            v-if="notification.status !== 'cancelled'"
                          >
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                <v-btn
                                  icon
                                  x-small
                                  @click.stop="openCancelNotificationDialog(notification.id)"
                                  :disabled="new Date(notification.datetime_start) <=
                                  new Date()"
                                >
                                  <v-icon small>mdi-cancel</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <template v-if="new Date(notification.datetime_start) <= new Date()">
                              <span v-translate>This notification can no longer be updated
                              </span>
                            </template>
                            <template v-else>
                              <span v-translate>Cancel notification</span>
                            </template>
                          </v-tooltip>
                          <!-- ---- AlphaTango Army Notification link ----- -->
                          <v-tooltip
                            bottom
                            v-if="notification.status === 'sent'"
                          >
                            <template v-slot:activator="{ on }">
                              <a
                                :href="notification.alpha_tango_url"
                                target="_blank"
                                class="text-decoration-none pl-2"
                                v-on="on"
                              >
                                <v-icon small>mdi-launch</v-icon>
                              </a>
                            </template>
                            <span v-translate>Open on AlphaTango</span>
                          </v-tooltip>
                        </template>
                        <!-- ---- AlphaTango Army Notification link ----- -->
                        <v-tooltip
                          bottom
                          v-if="notification.document"
                        >
                          <template v-slot:activator="{ on }">
                            <div
                              v-on="on"
                              class="pl-2"
                            >
                              <v-btn
                                icon
                                x-small
                                @click.stop="downloadDocument(notification.document)"
                                :loading="downloadDocumentLoading"
                              >
                                <v-icon small>mdi-download</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span v-translate>Download pdf</span>
                        </v-tooltip>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- ---- Cancel AlphaTango Army Notification popup ----- -->
    <v-dialog
      v-model="showCancelAlphaTangoArmyDialog"
      width="700px"
      :retain-focus="false"
      persistent
    >
      <NotifAlphaTangoArmyCancel
        :flight="flight"
        :notificationId="notificationIdToCancel"
        @close-form="() => closeCancelNotificationDialog()"
      />
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import APIService from '@/services/api';

import NotifAlphaTangoArmyCancel from '@/components/Flights/NotifAlphaTango/NotifAlphaTangoArmyCancel.vue';

const NOTIFICATION_ICON_STYLE = {
  sent: {
    icon: 'mdi-checkbox-marked-circle-outline',
    color: 'success',
  },
  to_send: {
    icon: 'mdi-checkbox-marked-circle-outline',
    color: 'blue',
  },
  outdated: {
    icon: 'mdi-alert-circle-outline',
    color: 'warning',
  },
  cancelled: {
    icon: 'mdi-close-circle-outline',
    color: 'grey',
  },
  failed: {
    icon: 'mdi-alert-circle-outline',
    color: 'error',
  },
};

export default {
  name: 'NotifAlphaTangoArmyList',
  components: { NotifAlphaTangoArmyCancel },
  props: {
    flight: {
      type: Object,
      required: true,
    },
    notifications: {
      type: Array,
      required: true,
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showCancelAlphaTangoArmyDialog: false,
      showNotifyAlphaTangoArmyDialog: false,
      notificationIdToCancel: null,
      downloadDocumentLoading: false,
    };
  },
  computed: {
    notificationsCount() {
      return this.notifications.length;
    },
    alphaTangoLogoPath() {
      return `${process.env.BASE_URL}alphatango_orange_100.png`;
    },
    notificationStatusTranslations() {
      return {
        sent: this.$gettext('Sent'),
        to_send: this.$gettext('To send'),
        outdated: this.$gettext('Outdated'),
        cancelled: this.$gettext('Cancelled'),
        failed: this.$gettext('Failed'),
      };
    },
    isUserArmy() {
      return this.$store.getters['authentication/isUserArmy'];
    },
    getSizeColumn() {
      return [
        this.isUserArmy ? 11 : 10,
        this.isUserArmy ? 1 : 2,
      ];
    },
    paddingForArmyInterface() {
      return this.isUserArmy ? 'pl-4' : '';
    },
    highlighted() {
      if (this.highlight) {
        return 'highlight';
      }
      return '';
    },
  },
  methods: {
    getIcon(status) {
      const style = NOTIFICATION_ICON_STYLE[status];
      if (style) {
        return style.icon;
      }
      return null;
    },
    getIconColor(status) {
      const style = NOTIFICATION_ICON_STYLE[status];
      if (style) {
        return style.color;
      }
      return null;
    },
    getNotificationStatusText(status) {
      return this.notificationStatusTranslations[status];
    },
    getNotificationDatesText(notification) {
      const flightDateStart = format(
        new Date(notification.datetime_start),
        'dd MMMM yyyy', { locale: fr },
      );

      const flightHourStart = format(
        new Date(notification.datetime_start),
        'HH:mm', { locale: fr },
      );

      const flightHourEnd = format(
        new Date(notification.datetime_end),
        'HH:mm', { locale: fr },
      );

      if (notification.status === 'sent') {
        if (!notification.notification_datetime) {
          return this.$gettextInterpolate(
            this.$gettext(`Submitted to fly on %{flightDate} from %{flightHourStart} to
              %{flightHourEnd}`),
            { flightDate: flightDateStart, flightHourStart, flightHourEnd },
          );
        }
        const sendDate = format(
          new Date(notification.notification_datetime),
          'dd MMMM yyyy', { locale: fr },
        );

        return this.$gettextInterpolate(
          this.$gettext(`Submitted on %{sendDate} to fly on %{flightDate} from %{flightHourStart}
            to %{flightHourEnd}`),
          { sendDate, flightDate: flightDateStart, flightHourStart, flightHourEnd },
        );
      }

      if (notification.status === 'failed') {
        return this.$gettextInterpolate(
          this.$gettext(`The notification for %{flightDate} from %{flightHourStart} to
            %{flightHourEnd} could not be sent`),
          { flightDate: flightDateStart, flightHourStart, flightHourEnd },
        );
      }

      return this.$gettextInterpolate(
        this.$gettext(`Intended to be sent to fly on %{flightDate} from %{flightHourStart} to
          %{flightHourEnd}`),
        { flightDate: flightDateStart, flightHourStart, flightHourEnd },
      );
    },
    openCancelNotificationDialog(notifId) {
      this.notificationIdToCancel = notifId;
      this.showCancelAlphaTangoArmyDialog = true;
    },
    closeCancelNotificationDialog() {
      this.showCancelAlphaTangoArmyDialog = false;
      this.notificationIdToCancel = null;
    },
    downloadDocument(notificationDocument) {
      this.downloadDocumentLoading = true;
      APIService.downloadDocument(notificationDocument.id)
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', notificationDocument.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.downloadDocumentLoading = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.notification-status::before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  opacity: .12;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
}
.notification-dates {
  opacity: 0.75;
}
.disabled {
  cursor: not-allowed;
  color: gray
}
.v-expansion-panel-content ::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
.notificationContainer {
  max-height: 500px;
  overflow-y: scroll
}

</style>
