<template>
  <v-card
    width="360"
    height="360"
    class="pa-5 d-flex flex-column"
  >
    <v-card-title class="d-flex justify-center">
      <div class="d-flex align-center">
        <v-icon
          class="mr-3"
          color="primary"
        >
          mdi-umbrella
        </v-icon>
        <translate>Are you insured?</translate>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <span class="black--text body-1">
        {{ airCourtageInsuranceDescription }}
      </span>
    </v-card-text>
    <v-spacer />
    <v-card-actions class="d-flex justify-center">
      <v-btn
        class="px-8"
        color="primary"
        :href="partnerLink()"
        target="_blank"
      >
        <translate>Read more</translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { URL_AIRCOURTAGE_PARTNER, URL_WELCOME_AIRCOURTAGE } from '@/settings';

export default {
  name: 'InsurancePartner',
  props: {
    insuranceCode: String,
    userSubscribed: Boolean,
  },
  computed: {
    airCourtageInsuranceDescription() {
      return this.$gettext(`With your subscription, you can benefit of a special price for your
        drone insurance to Air Courtage.`);
    },
  },
  methods: {
    partnerLink() {
      let link = '';
      if (this.userSubscribed) {
        link = `${URL_AIRCOURTAGE_PARTNER}${this.insuranceCode}`;
      } else {
        link = URL_WELCOME_AIRCOURTAGE;
      }
      return link;
    },
  },
};
</script>
