<template>
  <div>
    <div class="d-flex">
      <!-- Approval totals -->
      <div class="approval-filters flex-grow-1">
        <span>
          <strong>
            {{ totalItems }}
          </strong>&nbsp;
          <span>
            {{ texts.totalItems }}
          </span>
        </span>
        <span
          v-if="isAuthorityAdminOrManager"
          class="ml-2"
        >
          <strong>
            {{ totalItemsToProcess }}
          </strong>&nbsp;
          <span>
            {{ texts.totalItemsToProcess }}
          </span>
        </span>
      </div>
      <!-- Search field -->
      <div class="approval-filters flex-grow-1 d-flex justify-end">
        <v-text-field
          v-if="mediumDisplay || bigDisplay"
          v-model="q"
          :append-icon="q ? null : 'search'"
          class="searchbox"
          :placeholder="$gettext('Search')"
          dark
          solo
          flat
          clearable
          hide-details
          @keyup.enter="updateResult()"
          @click:append="updateResult()"
        />
      </div>
      <!-- Filters button -->
      <div
        class="approval-filters"
        v-if="mediumDisplay"
      >
        <v-btn
          text
          small
          dark
          pa-0
          @click="toggleFilter()"
        >
          <span
            class="text-decoration-underline"
            v-translate
          >
            Filters
          </span>
          <span
            v-if="activeFilters"
            class="ml-1"
          >
            ({{ activeFilters }})
          </span>
        </v-btn>
      </div>
    </div>
    <!-- Filters big display -->
    <div
      class="d-flex"
      v-if="bigDisplay"
    >
      <!-- Status -->
      <div class="approval-filters flex-grow-1">
        <v-select
          :items="statusItems"
          v-model="status"
          dark
          :label="$gettext('Status')"
          hide-details
          class="small-dense__input"
          :no-data-text="$gettext('No status found')"
        />
      </div>
      <!-- Exploitants -->
      <div
        v-if="isUserAuthority"
        class="approval-filters flex-grow-1"
      >
        <v-select
          :items="exploitantsItems"
          v-model="exploitant"
          dark
          item-value="id"
          item-text="commercial_name"
          :label="$gettext('Enterprise')"
          hide-details
          class="small-dense__input"
          :no-data-text="$gettext('No enterprise found')"
        />
      </div>
      <!-- Date from -->
      <div class="approval-filters flex-grow-1">
        <v-menu
          ref="menuFrom"
          :close-on-content-click="true"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          content-class="white text-center"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFrom"
              :label="$gettext('Start')"
              append-icon="arrow_drop_down"
              dark
              readonly
              v-on="on"
              hide-details
              class="small-dense__input"
            />
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
            locale="fr"
            first-day-of-week="1"
            @input="$refs.menuFrom.save(dateFrom)"
          />
        </v-menu>
      </div>
      <!-- Date to -->
      <div class="approval-filters flex-grow-1">
        <v-menu
          ref="menuTo"
          :close-on-content-click="true"
          :return-value.sync="dateTo"
          transition="scale-transition"
          content-class="white text-center"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateTo"
              :label="$gettext('End')"
              append-icon="arrow_drop_down"
              dark
              readonly
              v-on="on"
              hide-details
              class="small-dense__input"
            />
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
            locale="fr"
            first-day-of-week="1"
            @input="$refs.menuTo.save(dateTo)"
          />
        </v-menu>
      </div>
    </div>
    <div
      class="d-flex"
      v-if="bigDisplay"
    >
      <!-- Show only approvals processed -->
      <div class="approval-filters">
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-switch
                v-model="onlyApprovalsToProcess"
                color="primary"
                :label="$gettext('Approvals processed')"
                class="small-dense__switch"
                dark
                hide-details
              />
            </div>
          </template>
          <translate>Display only approvals to process.</translate>
        </v-tooltip>
      </div>
      <!-- Inside impacting zone -->
      <div class="approval-filters">
        <v-switch
          v-model="insideImpactingZone"
          color="primary"
          :label="$gettext('Impacting zone')"
          class="small-dense__switch"
          dark
          hide-details
        />
      </div>
      <!-- With archives -->
      <div class="approval-filters">
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-switch
                v-model="withArchives"
                color="primary"
                :label="$gettext('Include archives')"
                class="small-dense__switch"
                dark
                hide-details
              />
            </div>
          </template>
          <translate>Display all approvals, including archived.</translate>
        </v-tooltip>
      </div>
      <!-- Tactical contact -->
      <div
        v-if="displayTacticalContactFilter"
        class="approval-filters"
      >
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-switch
                v-model="onlyTacticalContactRequired"
                color="primary"
                :label="$gettext('Tactical agreement')"
                class="small-dense__switch"
                dark
                hide-details
              />
            </div>
          </template>
          <translate>Display only approvals requiring a contact with aerial control.</translate>
        </v-tooltip>
      </div>
      <!-- Take off authorization -->
      <div
        v-if="displayTakeOffAuthorizationFilter"
        class="approval-filters"
      >
        <v-switch
          v-model="onlyTakeOffAuthorization"
          color="primary"
          :label="$gettext('Take off authorization')"
          dark
          hide-details
          class="small-dense__switch"
        />
      </div>
      <!-- Flights en route -->
      <div
        v-if="isAuthorityController"
        class="approval-filters"
      >
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-switch
                v-model="onlyEnRoute"
                color="primary"
                :label="$gettext('Flying')"
                class="small-dense__switch"
                dark
                hide-details
              />
            </div>
          </template>
          <translate>Display only taken off approvals.</translate>
        </v-tooltip>
      </div>
      <!-- Cancel filters -->
      <div class="approval-filters flex-grow-1 d-flex justify-end">
        <v-btn
          text
          small
          dark
          @click="resetFilters()"
        >
          <span
            class="text-decoration-underline"
            v-translate
          >
            Cancel filters
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/services/api.helper';

import { display } from '@/settings';

import {
  SET_FILTERS_NS,
  GET_ALL_APPROVALS_NS,
  RESET_FILTERS_NS,
  SET_APPROVAL_SELECTED_NS,
} from '@/store/approvals';

import { GET_EXPLOITANTS_NS } from '@/store/exploitants';
import { AWAITING_VALIDATION, ERROR, INCOMPLETE, PENDING, SUBMITTED } from '@/store/status';

export default {
  name: 'ApprovalFilters',
  props: {
    display: String,
    isUserAuthority: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { defaultFiltersLoading: true };
  },
  computed: {
    bigDisplay() {
      return this.display === display.BIG;
    },
    mediumDisplay() {
      return this.display === display.MEDIUM;
    },
    statusItems() {
      return this.$store.state.status.approvalsStatusDict;
    },
    isAuthorityController() {
      return this.$store.getters['authentication/isAuthorityController'];
    },
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
    takeOffAuthorizationActivated() {
      return this.$store.getters['structures/takeOffAuthorizationActivated'];
    },
    displayTacticalContactFilter() {
      return (
        this.isAuthorityController
        && this.$store.getters['structures/tacticalContactProtocols'].length
        && !this.isUserPrefecture
        && !this.takeOffAuthorizationActivated
      );
    },
    displayTakeOffAuthorizationFilter() {
      return (
        this.isAuthorityController
        && !this.isUserPrefecture
        && this.takeOffAuthorizationActivated
      );
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    activeFilters() {
      return this.$store.getters['approvals/activeFilters'];
    },
    status: {
      get() {
        return this.$store.state.approvals.filters.status;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          status: newValue,
        });
      },
    },
    exploitantsItems() {
      return this.$store.state.exploitants.exploitants;
    },
    exploitant: {
      get() {
        return this.$store.state.approvals.filters.exploitant;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          exploitant: newValue,
        });
      },
    },
    dateFrom: {
      get() {
        return this.$store.state.approvals.filters.dateFrom;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          dateFrom: newValue,
        });
      },
    },
    dateTo: {
      get() {
        return this.$store.state.approvals.filters.dateTo;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          dateTo: newValue,
        });
      },
    },
    onlyApprovalsToProcess: {
      get() {
        return this.$store.state.approvals.filters.showOnlyApprovalsToProcess;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          showOnlyApprovalsToProcess: newValue,
        });
      },
    },
    q: {
      get() {
        return this.$store.state.approvals.filters.q;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          q: newValue || null,
        });
      },
    },
    withArchives: {
      get() {
        return this.$store.state.approvals.filters.isClosed === null;
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          isClosed: newValue === true ? null : 'False',
        });
      },
    },
    onlyTacticalContactRequired: {
      get() {
        return this.$store.state.approvals.filters.tacticalContactRequired === 'True';
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          tacticalContactRequired: newValue === true ? 'True' : null,
        });
      },
    },
    onlyTakeOffAuthorization: {
      get() {
        return this.$store.state.approvals.filters.takeOffAuthorization === 'True';
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          takeOffAuthorization: newValue === true ? 'True' : null,
        });
      },
    },
    onlyEnRoute: {
      get() {
        return this.$store.state.approvals.filters.enRoute === 'True';
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          enRoute: newValue === true ? 'True' : null,
        });
      },
    },
    insideImpactingZone: {
      get() {
        return this.$store.state.approvals.filters.insideImpactingZone === 'True';
      },
      set(newValue) {
        this.$store.dispatch(SET_FILTERS_NS, {
          ...this.$store.state.approvals.filters,
          insideImpactingZone: newValue === true ? 'True' : null,
        });
      },
    },
    totalItems() {
      return this.$store.state.approvals.itemsLength;
    },
    totalItemsToProcess() {
      const statusToProcess = [SUBMITTED, INCOMPLETE, PENDING, ERROR, AWAITING_VALIDATION];
      return this.$store.state.approvals.approvalsCollection.filter(
        (approval) => statusToProcess.includes(approval.status)
        || approval.unread_message_for_authority === true,
      ).length;
    },
    texts() {
      return {
        totalItems: this.$ngettext(
          // singular
          'flight',
          // plural
          'flights',
          this.totalItems,
        ),
        totalItemsToProcess: this.$ngettext(
          // singular
          'flight to process',
          // plural
          'flights to process',
          this.totalItemsToProcess,
        ),
      };
    },
  },
  watch: {
    status() {
      this.debouncedUpdateResult({ that: this });
    },
    exploitant() {
      this.debouncedUpdateResult({ that: this });
    },
    dateFrom() {
      this.debouncedUpdateResult({ that: this });
    },
    dateTo() {
      this.debouncedUpdateResult({ that: this });
    },
    q() {
      this.debouncedUpdateResult({ that: this });
    },
    withArchives() {
      this.debouncedUpdateResult({ that: this });
    },
    onlyTacticalContactRequired() {
      this.debouncedUpdateResult({ that: this });
    },
    onlyTakeOffAuthorization() {
      this.debouncedUpdateResult({ that: this });
    },
    onlyEnRoute() {
      this.debouncedUpdateResult({ that: this });
    },
    onlyApprovalsToProcess() {
      this.debouncedUpdateResult({ that: this });
    },
    insideImpactingZone() {
      this.debouncedUpdateResult({ that: this });
    },
  },
  created() {
    if (this.isUserAuthority) {
      this.getExploitants();
    }
    if (this.isAuthorityController) {
      this.dateTo = this.currentLocaleDateFormattedLikeISO();
      this.dateFrom = this.currentLocaleDateFormattedLikeISO();
    }
    this.$nextTick(() => {
      this.defaultFiltersLoading = false;
    });
  },
  methods: {
    debouncedUpdateResult: debounce(
      ({ that }) => that.updateResult(),
      1000, // debounce for 1s
    ),
    getExploitants() {
      this.$store.dispatch(GET_EXPLOITANTS_NS);
    },
    async updateResult() {
      if (!this.defaultFiltersLoading) {
        this.$store.dispatch(SET_APPROVAL_SELECTED_NS, null);
        await this.$store.dispatch(GET_ALL_APPROVALS_NS, { updateBoundingBox: true });
      }
    },
    resetFilters() {
      this.$store.dispatch(RESET_FILTERS_NS);
    },
    toggleFilter() {
      this.$emit('toggle-filter');
    },
    currentLocaleDateFormattedLikeISO() {
      const date = new Date();
      const offsetMs = date.getTimezoneOffset() * 60 * 1000;
      const msLocal = date.getTime() - offsetMs;
      const dateLocal = new Date(msLocal);
      const iso = dateLocal.toISOString();
      const isoLocal = iso.slice(0, 10);
      return isoLocal;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.approval-filters {
  margin: 14px 8px 4px 0px;
}

</style>
