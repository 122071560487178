import Vue from 'vue';

import APIService from '@/services/api';

import { AUTHORITY_TYPES } from '@/settings';

// eslint-disable-next-line import/no-cycle
import { getPageFromNextPreviousURL } from '@/store/application';

const namespace = 'exploitants';

const FETCH_EXPLOITANTS = 'FETCH_EXPLOITANTS';
const GET_EXPLOITANTS = 'GET_EXPLOITANTS';
const GET_EXPLOITATION_DETAILS = 'GET_EXPLOITATION_DETAILS';
const SET_EXPLOITANTS = 'SET_EXPLOITANTS';
const SET_EXPLOITATION_DETAILS = 'SET_EXPLOITATION_DETAILS';
const GET_ALL_SUB_EXPLOITANTS = 'GET_ALL_SUB_EXPLOITANTS';
const SET_ALL_SUB_EXPLOITANTS = 'SET_ALL_SUB_EXPLOITANTS';
const SET_LOADING_ALL_SUB_EXPLOITANTS = 'SET_LOADING_ALL_SUB_EXPLOITANTS';
const RESET_EXPLOITANTS = 'RESET_EXPLOITANTS';
const GET_EXPLOITANT_DOCUMENTS = 'GET_EXPLOITANT_DOCUMENTS';
const ADD_EXPLOITANT_DOCUMENT = 'ADD_EXPLOITANT_DOCUMENT';
const DELETE_EXPLOITANT_DOCUMENT = 'DELETE_EXPLOITANT_DOCUMENT';
const SET_EXPLOITANT_DOCUMENTS = 'SET_EXPLOITANT_DOCUMENTS';
const REFRESH_SUBSCRIPTION_DATA = 'REFRESH_SUBSCRIPTION_DATA';
const SET_SUBSCRIPTION_DETAILS_ERROR = 'SET_SUBSCRIPTION_DETAILS_ERROR';
const SET_SUBSCRIPTION_DETAILS_LOADING = 'SET_SUBSCRIPTION_DETAILS_LOADING';
const SET_SUBSCRIPTION_DETAILS = 'SET_SUBSCRIPTION_DETAILS';
const SET_PAGINATION = 'SET_PAGINATION';
const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
const GET_EXPLOITANTS_LIST = 'GET_EXPLOITANTS_LIST';
const RESET_EXPLOITANTS_LIST = 'RESET_EXPLOITANTS_LIST';
const SET_TABLE_ITEMS = 'SET_TABLE_ITEMS';
const SET_LOADING_EXPLOITANTS_LIST = 'SET_LOADING_EXPLOITANTS_LIST';
const SET_FILTERS = 'SET_FILTERS';
const ADD_ALPHATANGO_UID = 'ADD_ALPHATANGO_UID';
const REMOVE_ALPHATANGO_UID = 'REMOVE_ALPHATANGO_UID';
const WARN_MANAGERS_UPGRADE_TO_ENTERPRISE = 'WARN_MANAGERS_UPGRADE_TO_ENTERPRISE';
const SET_LOADING_WARN_UPGRADE_ENTERPRISE = 'SET_LOADING_WARN_UPGRADE_ENTERPRISE';
const GET_LATEST_ANNOUNCEMENT = 'GET_LATEST_ANNOUNCEMENT';
const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT';
const STOP_DISPLAY_ANNOUNCEMENT = 'STOP_DISPLAY_ANNOUNCEMENT';
const CHANGE_GENERALIST_FLIGHT_TIMES = 'CHANGE_GENERALIST_FLIGHT_TIMES';
const FETCH_TAG_NAMES = 'FETCH_TAG_NAMES';
const SET_TAG_NAMES = 'SET_TAG_NAMES';

export const GET_EXPLOITANTS_NS = `${namespace}/${GET_EXPLOITANTS}`;
export const GET_EXPLOITATION_DETAILS_NS = `${namespace}/${GET_EXPLOITATION_DETAILS}`;
export const GET_ALL_SUB_EXPLOITANTS_NS = `${namespace}/${GET_ALL_SUB_EXPLOITANTS}`;
export const RESET_EXPLOITANTS_NS = `${namespace}/${RESET_EXPLOITANTS}`;
export const GET_EXPLOITANT_DOCUMENTS_NS = `${namespace}/${GET_EXPLOITANT_DOCUMENTS}`;
export const ADD_EXPLOITANT_DOCUMENT_NS = `${namespace}/${ADD_EXPLOITANT_DOCUMENT}`;
export const DELETE_EXPLOITANT_DOCUMENT_NS = `${namespace}/${DELETE_EXPLOITANT_DOCUMENT}`;
export const REFRESH_SUBSCRIPTION_DATA_NS = `${namespace}/${REFRESH_SUBSCRIPTION_DATA}`;
export const UPDATE_PAGINATION_NS = `${namespace}/${UPDATE_PAGINATION}`;
export const SET_PAGINATION_NS = `${namespace}/${SET_PAGINATION}`;
export const SET_FILTERS_NS = `${namespace}/${SET_FILTERS}`;
export const RESET_EXPLOITANTS_LIST_NS = `${namespace}/${RESET_EXPLOITANTS_LIST}`;
export const GET_EXPLOITANTS_LIST_NS = `${namespace}/${GET_EXPLOITANTS_LIST}`;
export const ADD_ALPHATANGO_UID_NS = `${namespace}/${ADD_ALPHATANGO_UID}`;
export const REMOVE_ALPHATANGO_UID_NS = `${namespace}/${REMOVE_ALPHATANGO_UID}`;
export const WARN_MANAGERS_UPGRADE_TO_ENTERPRISE_NS = `${namespace}/${WARN_MANAGERS_UPGRADE_TO_ENTERPRISE}`;
export const GET_LATEST_ANNOUNCEMENT_NS = `${namespace}/${GET_LATEST_ANNOUNCEMENT}`;
export const STOP_DISPLAY_ANNOUNCEMENT_NS = `${namespace}/${STOP_DISPLAY_ANNOUNCEMENT}`;
export const CHANGE_GENERALIST_FLIGHT_TIMES_NS = `${namespace}/${CHANGE_GENERALIST_FLIGHT_TIMES}`;
export const FETCH_TAG_NAMES_NS = `${namespace}/${FETCH_TAG_NAMES}`;

function initialState() {
  return {
    exploitants: [],
    loadingExploitants: false,
    loadingExploitantsError: null,
    exploitationDetails: {},
    subscriptionSeatsNumber: null,
    subscriptionPriceWithTax: null,
    subscriptionNextPaymentAttemptDate: null,
    subscriptionDetailsLoaded: false,
    subscriptionDetailsLoading: false,
    subscriptionDetailsError: false,
    subscriptionWarnUpgradeLoading: false,
    allSubExploitantsLoading: false,
    allSubExploitants: [],
    documentCategoriesForPrefecture: [
      {
        label: 'Assurance',
        value: 'insurance',
      },
      {
        label: 'Relevé de situation',
        value: 'activity_declaration',
      },
      {
        label: "Extrait du registre des exploitants d'UAS",
        value: 'register',
      },
      {
        label: "Manuel d'exploitation/manuel d'activités particulières",
        value: 'manex_map',
      },
    ],
    documentCategories: [
      {
        label: 'Assurance',
        value: 'insurance',
      },
      {
        label: 'Relevé de situation',
        value: 'activity_declaration',
      },
      {
        label: "Extrait du registre des exploitants d'UAS",
        value: 'register',
      },
      {
        label: "Manuel d'exploitation/manuel d'activités particulières",
        value: 'manex_map',
      },
      {
        label: 'Protocoles',
        value: 'protocol',
      },
      {
        label: 'Autre type de document',
        value: '',
      },
    ],
    exploitantsCollection: [],
    loadingExploitantsCollection: false,
    itemsLength: 0,
    pagination: {
      itemsPerPage: 25,
      page: 1,
      sortBy: ['last_approval_date'],
      sortDesc: [true],
    },
    filters: { q: '' },
    announcement: undefined,
    tagNames: [],
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    documents: (state) => {
      const exploitantDetails = state.exploitationDetails;
      if (exploitantDetails && exploitantDetails.documents) {
        return exploitantDetails.documents;
      }
      return [];
    },
    documentsPerCategory: (state, getters, rootState) => {
      let categories;
      if (rootState.authentication.user?.authority_type === AUTHORITY_TYPES.PREFECTURE) {
        categories = state.documentCategoriesForPrefecture;
      } else {
        categories = state.documentCategories;
      }
      const documentsPerCategory = categories.map((c) => ({
        categoryName: c.label,
        value: c.value,
        documents: getters.documents
          .filter((d) => d.category === c.value)
          .sort((a, b) => (new Date(a.created_at) - new Date(b.created_at))),
      }));
      return documentsPerCategory;
    },
    tableItems: (state) => state.exploitantsCollection,
    hasManyAlphatangoUids: (state) => {
      const exploitantDetails = state.exploitationDetails;
      if (exploitantDetails && exploitantDetails.alphatango_uids) {
        return (exploitantDetails.alphatango_uids.length >= 2);
      }
      return false;
    },
    hasSubExploitants: (state) => state.exploitationDetails?.custom_config?.has_sub_exploitants,
    hasAllSubExploitants: (state) => state.allSubExploitants.length > 1,
    hasCustomPilotProfile: (state) => (
      state.exploitationDetails?.custom_config?.has_custom_contact_profiles
    ),
    allowFlyingTime: (state) => state.exploitationDetails?.custom_config?.allow_flying_time,
    clientStructuresGeocodingAllowed: (state) => (
      state.exploitationDetails?.custom_config?.allow_client_structures_geocoding
    ),
    entityName: (state) => state.exploitationDetails?.custom_config?.sub_exploitant_name || {
      singular: 'Entité',
      plural: 'Entités',
    },
    displayAnnouncements: (state) => (
      state.exploitationDetails?.custom_config?.display_announcements
    ),
    hasExploitantHssAuthorization: (state) => (
      state.exploitationDetails?.custom_config?.has_hss_exploitant_authorization
    ),
    hideLivestreamsFeature: (state) => (
      state.exploitationDetails?.custom_config?.hide_livestreams_feature
    ),
    pilotsCanBeRestricted: (state) => (
      state.exploitationDetails?.custom_config?.restricted_pilots
    ),
    openFlightSimplifiedFormForManager: (state) => (
      state.exploitationDetails?.custom_config?.open_flight_simplified_form_for_manager
    ),
    generalistFlightTimeStart: (state) => (
      state.exploitationDetails?.custom_config?.generalist_flight_time_start
    ),
    generalistFlightTimeEnd: (state) => (
      state.exploitationDetails?.custom_config?.generalist_flight_time_end
    ),
    hasRteStructureAnalysis: (state) => (
      state.exploitationDetails?.custom_config?.rte_structure_analysis
    ),
    hasGeneralistFlightTimes: (state, getters) => (
      getters.generalistFlightTimeStart && getters.generalistFlightTimeEnd
    ),
  },
  mutations: {
    [RESET_EXPLOITANTS](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [FETCH_EXPLOITANTS](state) {
      Vue.set(state, 'loadingExploitants', true);
    },
    [SET_EXPLOITANTS](state, { exploitants = [], loadingExploitantsError }) {
      Vue.set(state, 'loadingExploitants', false);
      Vue.set(state, 'exploitants', exploitants);
      Vue.set(state, 'loadingExploitantsError', loadingExploitantsError);
    },
    [SET_EXPLOITATION_DETAILS](state, { exploitation = {}, loadingExploitationError }) {
      Vue.set(state, 'exploitationDetails', exploitation);
      Vue.set(state, 'loadingExploitantsError', loadingExploitationError);
    },
    [SET_ALL_SUB_EXPLOITANTS](state, allSubExploitants) {
      Vue.set(state, 'allSubExploitants', allSubExploitants);
      Vue.set(state, 'allSubExploitantsLoading', false);
    },
    [SET_LOADING_ALL_SUB_EXPLOITANTS](state, value) {
      Vue.set(state, 'allSubExploitantsLoading', value);
    },
    [SET_EXPLOITANT_DOCUMENTS](state, { documents }) {
      Vue.set(state.exploitationDetails, 'documents', documents);
    },
    [ADD_EXPLOITANT_DOCUMENT](state, document) {
      const { documents } = state.exploitationDetails;
      documents.push(document);
      Vue.set(state.exploitationDetails, 'documents', documents);
    },
    [DELETE_EXPLOITANT_DOCUMENT](state, documentId) {
      const { documents } = state.exploitationDetails;
      const index = documents.findIndex((d) => d.id === documentId);
      if (index !== -1) {
        documents.splice(index, 1);
        Vue.set(state.exploitationDetails, 'documents', documents);
      }
    },
    [SET_SUBSCRIPTION_DETAILS_ERROR](state, hasError) {
      Vue.set(state, 'subscriptionDetailsError', hasError);
    },
    [SET_SUBSCRIPTION_DETAILS_LOADING](state, isLoading) {
      Vue.set(state, 'subscriptionDetailsLoading', isLoading);
    },
    [SET_SUBSCRIPTION_DETAILS](state, { data, rootGetters }) {
      Vue.set(state, 'subscriptionSeatsNumber', data.quantity);
      Vue.set(state, 'subscriptionPriceWithTax', data.total);
      const paymentDate = new Date(Date.parse(data.next_payment_attempt));
      const formattedDate = paymentDate.toLocaleDateString(rootGetters['application/currentLanguage']);
      Vue.set(state, 'subscriptionNextPaymentAttemptDate', formattedDate);
      Vue.set(state, 'subscriptionDetailsError', false);
    },
    [SET_PAGINATION](state, payload) {
      Vue.set(state, 'pagination', payload);
    },
    [UPDATE_PAGINATION](state, { itemsLength, page }) {
      Vue.set(state, 'itemsLength', itemsLength);
      if (page !== undefined) {
        Vue.set(state.pagination, 'page', page);
      }
    },
    [SET_TABLE_ITEMS](state, items) {
      Vue.set(state, 'exploitantsCollection', items);
    },
    [SET_LOADING_EXPLOITANTS_LIST](state, value) {
      Vue.set(state, 'loadingExploitantsCollection', value);
    },
    [RESET_EXPLOITANTS_LIST](state) {
      Vue.set(state, 'exploitantsCollection', []);
      Vue.set(state, 'loadingExploitantsCollection', false);
    },
    [SET_FILTERS](state, payload) {
      Vue.set(state, 'filters', payload.filters);
    },
    [SET_LOADING_WARN_UPGRADE_ENTERPRISE](state, value) {
      Vue.set(state, 'subscriptionWarnUpgradeLoading', value);
    },
    [ADD_ALPHATANGO_UID](state, alphatangoUid) {
      const { alphatango_uids: alphatangoUids } = state.exploitationDetails;
      alphatangoUids.push(alphatangoUid);
      Vue.set(state.exploitationDetails, 'alphatango_uids', alphatangoUids);
    },
    [REMOVE_ALPHATANGO_UID](state, alphatangoUid) {
      const { alphatango_uids: alphatangoUids } = state.exploitationDetails;
      const index = alphatangoUids.findIndex((d) => d === alphatangoUid);
      if (index !== -1) {
        alphatangoUids.splice(index, 1);
        Vue.set(state.exploitationDetails, 'alphatango_uids', alphatangoUids);
      }
    },
    [SET_ANNOUNCEMENT](state, announcement) {
      Vue.set(state, 'announcement', announcement);
    },
    [CHANGE_GENERALIST_FLIGHT_TIMES](state, data) {
      const config = {
        ...state.exploitationDetails.custom_config,
        generalist_flight_time_start: data.generalist_flight_time_start,
        generalist_flight_time_end: data.generalist_flight_time_end,
      };
      Vue.set(state.exploitationDetails, 'custom_config', config);
    },
    [SET_TAG_NAMES](state, tagNames) {
      Vue.set(state, 'tagNames', tagNames);
    },
  },
  actions: {
    [RESET_EXPLOITANTS]({ commit }) {
      commit(RESET_EXPLOITANTS);
    },
    [REFRESH_SUBSCRIPTION_DATA]({ commit, rootGetters }) {
      commit(SET_SUBSCRIPTION_DETAILS_LOADING, true);
      APIService.previewNextInvoice()
        .then(({ data }) => {
          commit(SET_SUBSCRIPTION_DETAILS, { data, rootGetters });
        })
        .catch(() => {
          commit(SET_SUBSCRIPTION_DETAILS_ERROR, true);
        })
        .finally(() => {
          commit(SET_SUBSCRIPTION_DETAILS_LOADING, false);
        });
    },
    [GET_EXPLOITANTS]({ commit }) {
      commit(FETCH_EXPLOITANTS);
      APIService.getExploitants()
        .then(({ data }) => {
          commit(SET_EXPLOITANTS, { exploitants: data });
        })
        .catch((error) => {
          commit(SET_EXPLOITANTS, { loadingExploitantsError: error });
        });
    },
    async [GET_EXPLOITATION_DETAILS]({ commit }, exploitationId) {
      return APIService.getExploitationDetails(exploitationId)
        .then(({ data }) => {
          commit(SET_EXPLOITATION_DETAILS, { exploitation: data });
        })
        .catch((error) => {
          commit(SET_EXPLOITANTS, { loadingExploitantsError: error });
        });
    },
    async [GET_ALL_SUB_EXPLOITANTS]({ commit }) {
      commit(SET_LOADING_ALL_SUB_EXPLOITANTS, true);
      await APIService.getAllSubExploitants()
        .then(({ data }) => {
          commit(SET_ALL_SUB_EXPLOITANTS, data);
        });
    },
    async [GET_EXPLOITANT_DOCUMENTS]({ commit }, exploitationId) {
      await APIService.getExploitantDocuments(exploitationId).then(({ data }) => {
        commit(SET_EXPLOITANT_DOCUMENTS, { documents: data });
      });
    },
    [ADD_EXPLOITANT_DOCUMENT]({ commit }, document) {
      commit(ADD_EXPLOITANT_DOCUMENT, document);
    },
    [DELETE_EXPLOITANT_DOCUMENT]({ commit }, documentId) {
      commit(DELETE_EXPLOITANT_DOCUMENT, documentId);
    },
    [ADD_ALPHATANGO_UID]({ commit }, alphatangoUid) {
      commit(ADD_ALPHATANGO_UID, alphatangoUid);
    },
    [REMOVE_ALPHATANGO_UID]({ commit }, alphatangoUid) {
      commit(REMOVE_ALPHATANGO_UID, alphatangoUid);
    },
    [SET_PAGINATION]({ commit, dispatch }, newValue) {
      commit(SET_LOADING_EXPLOITANTS_LIST, true);
      const { pagination, structureId } = newValue;
      commit(SET_PAGINATION, pagination);
      dispatch(GET_EXPLOITANTS_LIST, structureId);
    },
    async [GET_EXPLOITANTS_LIST]({ commit, state }, structureId) {
      commit(SET_LOADING_EXPLOITANTS_LIST, true);
      const { itemsPerPage, sortBy, sortDesc, page } = state.pagination;
      const { q } = state.filters;
      let ordering;
      if (!sortBy.length) {
        ordering = '-last_approval_date';
      } else {
        ordering = (sortDesc[0] ? '-' : '') + sortBy[0];
      }
      const getExploitantsParams = {
        ordering,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        q: q !== null ? q : '',
      };
      await APIService.getStructureStatistics(structureId, getExploitantsParams)
        .then(({ data }) => {
          const newPage = getPageFromNextPreviousURL(
            data.next,
            data.previous,
            data.count,
            itemsPerPage,
          );
          commit(SET_TABLE_ITEMS, data.results);
          commit(UPDATE_PAGINATION, { itemsLength: data.count, page: newPage });
        })
        .finally(() => {
          commit(SET_LOADING_EXPLOITANTS_LIST, false);
        });
    },
    [RESET_EXPLOITANTS_LIST]({ commit }) {
      commit(SET_LOADING_EXPLOITANTS_LIST, true);
      commit(RESET_EXPLOITANTS_LIST);
    },
    [SET_FILTERS]({ commit }, filters) {
      commit(SET_FILTERS, { filters });
    },
    async [WARN_MANAGERS_UPGRADE_TO_ENTERPRISE]({ commit, rootState }, { message = '' }) {
      commit(SET_LOADING_WARN_UPGRADE_ENTERPRISE, true);
      await APIService.warnManagersUpdateSubscription(
        rootState.authentication.user.exploitation_id, message,
      )
        .finally(() => {
          commit(SET_LOADING_WARN_UPGRADE_ENTERPRISE, false);
        });
    },
    async [GET_LATEST_ANNOUNCEMENT]({ commit }) {
      await APIService.getLatestAnnouncement()
        .then(({ data }) => {
          commit(SET_ANNOUNCEMENT, data || undefined);
        });
    },
    [STOP_DISPLAY_ANNOUNCEMENT]({ commit }, id) {
      APIService.stopDisplayAnnouncement(id)
        .then(({ data }) => {
          commit(SET_ANNOUNCEMENT, data);
        });
    },
    [FETCH_TAG_NAMES]({ commit }) {
      APIService.getFlightTagsNames()
        .then(({ data }) => {
          commit(SET_TAG_NAMES, data);
        });
    },
  },
};
