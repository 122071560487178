<template>
  <LivestreamPlayer
    v-if="livestream || demoUrl"
    :livestream="livestream"
    :fullPage="true"
    :demoUrl="demoUrl"
  />
</template>

<script>
import APIService from '@/services/api';
import { hideHubspotChatBot, restoreHubspotChatBot } from '@/store/application';

import LivestreamPlayer from '@/components/Livestreams/LivestreamPlayer.vue';

export default {
  name: 'LivestreamPage',
  components: { LivestreamPlayer },
  data() {
    return {
      livestream: undefined,
      demoUrl: undefined,
    };
  },
  mounted() {
    if (this.$route) {
      if (this.$route.params && this.$route.params.name) {
        const livestreamName = this.$route.params.name;
        APIService.getLivestreamByName(livestreamName)
          .then(({ data }) => {
            this.livestream = data;
          });
      }
      if (this.$route.name === 'livestream-demo') {
        APIService.getLivestreamDemoVideoUrl()
          .then(({ data }) => {
            this.demoUrl = data;
          });
      }
    }
    hideHubspotChatBot();
  },
  destroyed() {
    restoreHubspotChatBot();
  },
};
</script>
