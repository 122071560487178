<template>
  <LayoutSettings :activate-content="activateContent">
    <template
      slot="navigation-drawer"
      slot-scope="{switchDisplay}"
    >
      <v-list
        class="settings-categories"
        subheader
      >
        <v-subheader>
          <translate>Settings</translate>
        </v-subheader>
        <!-- Settings categories -->
        <v-list-item-group
          color="primary"
          v-model="settingsCategory"
        >
          <div
            v-for="(category, index) in settingsCategories"
            :key="index"
          >
            <!-- Settings simple category with no sub-categories -->
            <v-list-item
              v-if="!category.subCategories"
              :value="category.value"
              class="simple-category"
              @click="switchDisplay()"
            >
              <!-- Category title and icon  -->
              <v-list-item-icon>
                <v-icon>
                  {{ category.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ category.title }}
              </v-list-item-title>
            </v-list-item>
            <!-- Settings list category with sub-categories -->
            <v-list-group
              v-if="category.subCategories"
              :value="categoryGroupExpanded"
              class="list-category"
            >
              <!-- Category title and icon  -->
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>
                    {{ category.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ category.title }}
                </v-list-item-title>
              </template>
              <!-- Sub-categories  -->
              <v-list-item
                v-for="subCategory in category.subCategories"
                :key="subCategory.title"
                :value="subCategory.value"
                class="sub-category"
                @click="switchDisplay()"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subCategory.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list-item-group>
      </v-list>
    </template>
    <template slot="contents">
      <!-- User settings -->
      <UserSettings v-if="settingsCategoryActivated === 'user'" />
      <!-- Flight settings -->
      <FlightSettings v-if="settingsCategoryActivated === 'flights'" />
      <!-- Company settings -->
      <Exploitation v-if="settingsCategoryActivated === 'exploitation'" />
      <!-- Subscription settings -->
      <Subscription
        v-if="settingsCategoryActivated === 'subscription'"
        :subscription-state="state['subscription'] || null"
      />
      <PaymentMethod
        v-if="settingsCategoryActivated === 'payment-method'"
        :card-change-state="state['payment-method'] || null"
      />
      <BillingInformation v-if="settingsCategoryActivated === 'billing'" />
      <!-- Invoice listing -->
      <InvoiceListing v-if="settingsCategoryActivated === 'invoices'" />
      <!-- SubExploitant settings -->
      <SubExploitantsSettings v-if="settingsCategoryActivated === 'subexploitants'" />
      <!-- Livestream emails settings -->
      <LivestreamSettings v-if="settingsCategoryActivated === 'livestreams'" />
      <!-- Data settings -->
      <DataSettings v-if="settingsCategoryActivated === 'data-settings'" />
      <!-- Flying time settings -->
      <FlyingTime v-if="settingsCategoryActivated === 'flying-time'" />
      <!-- Structure Impact settings -->
      <StructureImpacting v-if="settingsCategoryActivated === 'restricted-zones'" />
      <!-- Flight incidents settings -->
      <FlightIncidents v-if="settingsCategoryActivated === 'flight-incidents'" />
    </template>
  </LayoutSettings>
</template>

<script>
import LayoutSettings from '@/layouts/LayoutSettings.vue';

import UserSettings from '@/components/Settings/UserSettings.vue';
import Exploitation from '@/components/Settings/Dronists/Exploitation.vue';
import FlightIncidents from '@/components/Settings/Dronists/FlightIncidents.vue';
import FlyingTime from '@/components/Settings/Dronists/FlyingTime.vue';
import LivestreamSettings from '@/components/Settings/Dronists/LivestreamSettings.vue';
import StructureImpacting from '@/components/Settings/Dronists/StructureImpacting.vue';
import SubExploitantsSettings from '@/components/Settings/Dronists/SubExploitantsSettings.vue';
import BillingInformation from '@/components/Settings/Dronists/Subscription/BillingInformation.vue';
import InvoiceListing from '@/components/Settings/Dronists/Subscription/InvoiceListing.vue';
import Subscription from '@/components/Settings/Dronists/Subscription/Subscription.vue';
import PaymentMethod from '@/components/Settings/Dronists/Subscription/PaymentMethod.vue';
import DataSettings from '@/components/Settings/DataSettings.vue';
import FlightSettings from '@/components/Settings/Dronists/FlightSettings.vue';

export default {
  name: 'SettingsDronists',
  components: {
    LayoutSettings,
    UserSettings,
    Exploitation,
    FlightIncidents,
    FlyingTime,
    LivestreamSettings,
    StructureImpacting,
    SubExploitantsSettings,
    BillingInformation,
    InvoiceListing,
    Subscription,
    PaymentMethod,
    DataSettings,
    FlightSettings,
  },
  data() {
    return {
      activateContent: false,
      settingsCategoryActivated: null,
      categoryGroupExpanded: false,
      state: {},
    };
  },
  computed: {
    settingsCategory: {
      get() {
        return this.settingsCategoryActivated;
      },
      set(newValue) {
        if (this.settingsCategoriesValues.includes(newValue)) {
          this.settingsCategoryActivated = newValue;
        }
      },
    },
    settingsCategories() {
      const categories = [
        {
          title: this.$gettext('My account'),
          icon: 'mdi-account',
          value: 'user',
        },
        {
          title: this.$gettext('Personnal data'),
          icon: 'mdi-folder-account',
          value: 'data-settings',
        },
      ];
      if (this.$store.getters['authentication/isUserDronist']) {
        categories.push({
          title: this.$gettext('Mission settings'),
          icon: 'icon-fly_wait_flat',
          value: 'flights',
        });
        categories.push({
          title: this.$gettext('My company'),
          icon: 'mdi-factory',
          value: 'exploitation',
        });
      }
      if (
        this.$store.getters['authentication/isUserExploitantAdmin']
        && this.$store.getters['exploitants/hasSubExploitants']
      ) {
        categories.push({
          title: this.entityName.plural,
          icon: 'mdi-home-circle',
          value: 'subexploitants',
        });
      }
      if (this.$store.getters['exploitants/allowFlyingTime']) {
        categories.push({
          title: this.$gettext('Flying times'),
          icon: 'mdi-timer',
          value: 'flying-time',
        });
      }
      if (this.$store.getters['authentication/isUserEnterprise']) {
        categories.push({
          title: this.$gettext('Flight incidents and VAS'),
          icon: 'mdi-traffic-cone',
          value: 'flight-incidents',
        });
      }
      if (this.$store.getters['authentication/isUserExploitantAdmin']) {
        categories.push({
          title: this.$gettext('Subscription'),
          icon: 'mdi-tag',
          subCategories: [
            { title: this.$gettext('My formula'), value: 'subscription' },
            { title: this.$gettext('Payment method'), value: 'payment-method' },
            { title: this.$gettext('Billing information'), value: 'billing' },
            { title: this.$gettext('My invoices'), value: 'invoices' },
          ],
        });
      }
      if (!this.$store.getters['exploitants/hideLivestreamsFeature']) {
        categories.push({
          title: this.$gettext('Livestream'),
          icon: 'mdi-video-wireless-outline',
          value: 'livestreams',
        });
      }
      if (
        this.$store.getters['authentication/isUserExploitantAdmin']
        && this.$store.getters['exploitants/hasRteStructureAnalysis']
      ) {
        categories.push({
          title: this.$gettext('Restricted zones'),
          icon: 'mdi-map-marker-circle',
          value: 'restricted-zones',
        });
      }
      return categories;
    },
    settingsCategoriesValues() {
      return this.settingsCategories.map(
        (c) => c.value || c.subCategories.map((s) => s.value),
      ).flat();
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  created() {
    if (this.$route.query.tabSelected !== undefined) {
      const category = this.$route.query.tabSelected;
      this.selectTab(category);
      this.state[category] = this.$route.query.state;
      this.activateContent = true;
    } else {
      // Automatically select the first category on non mobile screens
      const { xs } = this.$vuetify.breakpoint;
      if (!xs) {
        [this.settingsCategory] = this.settingsCategoriesValues;
      }
    }
  },
  methods: {
    selectTab(value) {
      this.settingsCategory = value;
      this.categoryGroupExpanded = true;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.settings-categories {
  .v-list-item {
    padding-left: 0.6em;
  }
  .v-list-item__icon {
    margin-right: 1.2em;
  }
  .list-category {
    /deep/ .v-list-group__header {
      padding-left: 0.6em;
      color: rgba(0, 0, 0, .54);
      .v-list-item__title {
        color: rgba(0, 0, 0, .87) !important;
      }
    }
    .sub-category {
      min-height: 40px;
      padding-left: 3.6em;
      .v-list-item__content {
        padding: 0px;
      }
      .v-list-item__title {
        font-size: 1em;
      }
    }
  }
}

</style>
